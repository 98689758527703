import React, { useEffect, useState } from "react"
import { Row, Col } from "reactstrap"
import Render_Center_Avatar from "../../../Operator/Table_data/Render_Center_Avatar"
import { useTranslation } from "react-i18next"

export default function Overview(props) {
  const { t } = useTranslation()
  const [data_custo, setData_custo] = useState([])
  const text_header = {
    fontWeight: "bold",
    fontSize: "15px",
  }

  useEffect(() => {
    if (props.detail_customer) {
      const data_map = [
        {
          header1: t("name"),
          dataContract1: checkData(props.detail_customer.cus_name),
          header2: t("tax id"),
          dataContract2: checkData(props.detail_customer.cus_taxid),
        },
        {
          header1: t("code"),
          dataContract1: checkData(props.detail_customer.cus_code),
          header2: t("credit (day)"),
          dataContract2: checkData(props.detail_customer.cus_credit),
        },
        {
          header1: t("fax"),
          dataContract1: checkData(props.detail_customer.cus_fax),
          header2: t("dealer"),
          dataContract2: checkData(props.detail_customer.dea_name),
        },
        {
          header1: t("phone"),
          dataContract1: checkData(props.detail_customer.cus_phone),
          header2: t("email"),
          dataContract2: checkData(props.detail_customer.cus_email),
        },
        {
          header1: t("address"),
          dataContract1: checkData(props.detail_customer.cus_address),
          header2: t("sub-district"),
          dataContract2: checkData(props.detail_customer.subdis_name),
        },
        {
          header1: t("district"),
          dataContract1: checkData(props.detail_customer.dis_name),
          header2: t("province"),
          dataContract2: checkData(props.detail_customer.pro_name),
        },
        {
          header1: t("postcode"),
          dataContract1: checkData(props.detail_customer.zip_code),
          header2: t("dealer email") ,
          dataContract2: checkData(props.detail_customer.dea_email),
        },
      ]
      setData_custo(data_map)
    }

    return () => {
      setData_custo([])
    }
  }, [props.detail_customer])

  function checkData(data) {
    if (data) {
      return data
    } else {
      return "N/A"
    }
  }

  return (
    <div>
      <p
        style={{
          textAlign: "center",
          fontWeight: "bold",
          marginTop: "10px",
          fontSize: "15px",
        }}
      >
        {" "}
        {t("customer information")}{" "}
      </p>
      <Render_Center_Avatar path_image={props.detail_customer.cus_avatar} />
      <br /> <br />
      {data_custo.map((data, index) => {
        return (
          <Row key={index}>
            <Col sm={6} md={2} lg={2} xl={2}>
              <p style={text_header}>{data.header1}</p>
            </Col>
            <Col sm={6} md={4} lg={4} xl={4}>
              <p style={{ fontSize: "15px" }}>
                {" "}
                <span className="tet">
                  :
                </span> <span className="tetTwo">-</span> {data.dataContract1}
              </p>
            </Col>
            <Col sm={6} md={2} lg={2} xl={2}>
              <p style={text_header}>{data.header2}</p>
            </Col>
            <Col sm={6} md={4} lg={4} xl={4}>
              <p style={{ fontSize: "15px" }}>
                {" "}
                <span className="tet">
                  :
                </span> <span className="tetTwo">-</span> {data.dataContract2}
              </p>
            </Col>
          </Row>
        )
      })}
    </div>
  )
}
