import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import {
  Col,
  Container,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import { getProjectDetail } from "store/projects/actions"
import ProjectDetail from "./ProjectDetail"
import TeamMembers from "./TeamMembers"
import OverviewChart from "./overviewChart"
import { options, series } from "common/data/projects"
import AttachedFiles from "./attachedFiles"
import Comments from "./comments"

const ProjectsOverview = props => {
  const {
    projectDetail,
    match: { params },
    onGetProjectDetail,
  } = props

  const [settings_Menu, setSettings_Menu] = useState(false)

  useEffect(() => {
    if (params && params.id) {
      onGetProjectDetail(params.id)
    } else {
      onGetProjectDetail(0) //remove this after full integration
    }
  }, [params, onGetProjectDetail])

  function toggleSettings() {
    setSettings_Menu(!settings_Menu)
  }

  return (
    <>
    <br />
      {!isEmpty(projectDetail) && (
        <>
          <Row>
            <Col lg="8">
              <ProjectDetail
                project={projectDetail}
                detail_project={props.detail}
              />
            </Col>

            <Col lg="4">
              <TeamMembers
                team={projectDetail.team}
                detail_project={props.detail}
              />
            </Col>
          </Row>

          <Row>
            <Col lg="4">
              <OverviewChart
                options={options}
                series={series}
                detail_project={props.detail}
              />
            </Col>

            <Col lg="4">
              <AttachedFiles
                files={projectDetail.files}
                detail_project={props.detail}
              />
            </Col>

            <Col lg="4">
              <Comments
                comments={projectDetail.comments}
                detail_project={props.detail}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

ProjectsOverview.propTypes = {
  projectDetail: PropTypes.any,
  match: PropTypes.object,
  onGetProjectDetail: PropTypes.func,
}

const mapStateToProps = ({ projects }) => ({
  projectDetail: projects.projectDetail,
})

const mapDispatchToProps = dispatch => ({
  onGetProjectDetail: id => dispatch(getProjectDetail(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProjectsOverview))
