import React, { useState, useEffect, useCallback } from "react"
import { Row, Col } from "reactstrap"
import { Tabs, Button, Modal, notification, Select } from "antd"
import { FileAddOutlined } from "@ant-design/icons"
import FormDragAndDrop from "./generateForm/FormDragAndDrop"
import RenderForm from "./renderForm"
import * as api from "../../../service/Api_Edit_Service"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import axios from "axios"
import RenderForm_hast_form from "./renderForm_hast_form"
import './Task_select.css'
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import helpers from '../../Function_Redirect/Redirect_login'

const { Option } = Select;
const { TabPane } = Tabs
const { confirm } = Modal

export default function CreateNewForm(props) {
  const token = localStorage.getItem("token")
  const { t } = useTranslation();
  const history = useHistory()
  const [stepField, setStepField] = useState([])
  const [module, setModule] = useState([])
  const [priority, setPriority] = useState([])
  const [loadings, setLoadings] = useState(false)
  const [before_test, setBefore_test] = useState([])
  const [task_hast_form, setTask_hast_form] = useState([])
  const [template_task_all, setTemplate_task_all] = useState([])

  useEffect(() => {
    check_task_template()
    list_module()
    list_priority()
    Check_service_form()

    return () => {
      setModule([])
      setPriority([])
      setStepField([])
      setBefore_test([])
      setTask_hast_form([])
    }
  }, [])

  function check_task_template() {
    const list_task_template = api.LIST_TASK_TEMPLATE(token)
    axios(list_task_template)
    .then( res => {
      setTemplate_task_all(res.data.message)
    })
    .catch(error => {
      if(error){
        if(error.response){
          if(error.response.status === 401){
            helpers.re_login()
            history.push('/login')          
            }
        }
      }

      // console.log(error)
    })
  }

  function render_task_template_all() {
    return template_task_all.map( data => {
      return  <Option key={data.task_group_id} value={data.task_group_id}>{data.task_group_name}</Option>
    })
  }
  
  function onChange(value) {
    if(value){
      const list_task_group = api.LIST_TASK_GROUP(token , value)
      axios(list_task_group)
      .then( res => {
        const sortDetailForm = res.data.data.sort(compareFirst_task_global_index)
        mapping_task(sortDetailForm)
      })
      .catch( error => {
        // console.log(error);
        if(error){
          if(error.response){
            if(error.response.status === 401){
              helpers.re_login()
              history.push('/login')          
              }
          }
        }

      })
    } else {
      setStepField([])
    }
  }


  function mapping_task(sortDetailForm) {
    let values = []
    sortDetailForm.map( (data , index) => {
      values[data.task_stepper-1] = {
        fields : []
      }
    })

    values.map( (data , index) => {
      sortDetailForm.map( Detail_Form => {
        if(Detail_Form.task_stepper -1 === index){
          data.fields.push({
            task_detail: Detail_Form.task_detail ,
            task_name: Detail_Form.task_name ,
            task_priority_id: Detail_Form.task_priority_id ,
            task_type_module_id: Detail_Form.task_type_module_id ,
            text_header: Detail_Form.task_type_module_name ,
            type: Detail_Form.task_type_module_name ,
          })
        }
      })
    })
    setStepField(values)
  }
  
  function list_module() {
    const list_module_info = api.LIST_MODULE(token)
    axios(list_module_info)
      .then(res => {
        setModule(res.data.data)
      })
      .catch(error => {
        if(error){
          if(error.response){
            if(error.response.status === 401){
              helpers.re_login()
              history.push('/login')          
              }
          }
        }
      })
  }
  function list_priority() {
    const list_priority_info = api.LIST_PRIORITY(token)
    axios(list_priority_info)
      .then(res => {
        setPriority(res.data.data)
      })
      .catch(error => {
        if(error){
          if(error.response){
            if(error.response.status === 401){
              helpers.re_login()
              history.push('/login')          
              }
          }
        }

        // console.log(error)
      })
  }

  function addStepField() {
    const values = [...stepField]
    values.push({
      fields: [],
    })
    setStepField(values)
  }

  function removeStep(index) {
    const values = [...stepField]
    values.splice(index, 1)
    setStepField(values)
  }

  function sendDataForm() {
    return confirm({
      title: t("Confirm create task"),
      icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
      centered: true,
      okText: t("Confirm"),
      cancelText: t("no"),
      onOk() {
        setLoadings(true)
        let step_page = 1
        stepField.map( data => {
          if (data.fields.length !== 0) {
            data.fields.map(async (data_instep , index) => {
              let data_form = {
                task_method_id: 1,
                task_type_id: 1,
                task_status_id: 1,
                task_stepper: step_page,
                task_type_module_id: data_instep.task_type_module_id,
                task_priority_id: data_instep.task_priority_id,
                task_detail: data_instep.task_detail,
                task_name: data_instep.task_name,
                task_index : index
              }
              await send_task(data_form)
            })
            step_page++
          }
        })

        setTimeout(() => {
          props.list_service_overview()
          setStepField([])
          // openNotificationWithIcon("success", "Insert task success")
          setLoadings(false)
          Check_service_form()
        }, 2000);
      },
      onCancel() {},
    })
  }

  const send_task = async data_task => {
    const create_task = api.INSERT_TASK(token, props.ser_id, data_task)
    const fetcher = await axios(create_task)
    if (fetcher.status === 201) {
      return fetcher
    } else {
      openNotificationWithIcon("error", t("Insert task error"))
      return fetcher
    }
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  function compareFirst_task_global_index(a, b) {
    if (a.task_global_index > b.task_global_index) {
      return 1
    }
    if (a.task_global_index < b.task_global_index) {
      return -1
    }
    return 0
  }

  function compareFirst(a, b) {
    if (a.task_index > b.task_index) {
      return 1
    }
    if (a.task_index < b.task_index) {
      return -1
    }
    return 0
  }

  function Check_service_form() {
    const create_task = api.CHECK_SERVICE_HAST_TASK(token, props.ser_id)
    axios(create_task)
      .then(res => {
        const sortDetailForm = res.data.data.sort(compareFirst)
        setBefore_test(sortDetailForm)
      })
      .catch(error => {
        if(error){
          if(error.response){
            if(error.response.status === 401){
              helpers.re_login()
              history.push('/login')          
              }
          }
        }
      })
  }

  useEffect(() => {
    mapping_data_array()

    return () => {}
  }, [before_test])

  function mapping_data_array() {
    let stepField = []
    let number_page = 0
    before_test.map(data => {
      if (number_page <= data.task_stepper - 1) {
        stepField.push({
          fields: [],
        })
        number_page++
      }
    })
    before_test.map(data => {
      stepField[data.task_stepper - 1].fields.push(data)
    })
    setTask_hast_form(stepField)
  }

  function remove_allTask() {
    return confirm({
      title: t("Are you sure delete all task"),
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      centered: true,
      okText: t("Delete"),
      okType: "danger",
      cancelText: t("no"),
      onOk() {
        setLoadings(true)

        const promisesRemoveAllTaskData = before_test.map(async data => {
          await  remove_a_task(data.task_id)
        })

        setTimeout(() => {
          props.list_service_overview()
          Check_service_form()
          setLoadings(false)
        }, 2000);
      },
      onCancel() {},
    })
  }

  async function remove_a_task(task_id) {
    const remove_task = api.REMOVE_TASK(token, task_id)
    await axios(remove_task)
      .then(res => {
        return res.data
      })
      .catch(error => {
        return error
      })
  }

  // const remove_a_task = async task_id => {
  //   const remove_task = api.REMOVE_TASK(token, task_id)
  //   const fetcher = await axios(remove_task)
  //   if (fetcher.status === 404) {
  //     openNotificationWithIcon("success", t("Remove task success"))
  //   } else {
  //     openNotificationWithIcon("error", t("Remove task error"))
  //   }
  //   return fetcher
  // }

  return (
    <div>
      {task_hast_form.length === 0 && (
        <div>
        <Row>
          <Col>
          <p className="text-bold"> {t("Select task template")} </p>
          <Select
          showSearch
          allowClear
          className="select-task-template"
          placeholder={t("Select task template")}
          optionFilterProp="children"
          onChange={onChange}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
        { render_task_template_all() }
        </Select>
          </Col>
        </Row>
        <br />
        <Row>
        <Col sm={12} xs={12} md={12} lg={6}>
          <Button
            type="primary"
            icon={<FileAddOutlined />}
            onClick={() => addStepField()}
          >
            {t("Add step form")}
          </Button>

          <Tabs defaultActiveKey="1">
            {stepField.map((step, index) => {
              return (
                <TabPane tab={`Step ${index + 1}`} key={index}>
                  <Button
                    danger
                    onClick={() => removeStep(index)}
                    style={{
                      marginLeft: "auto",
                      marginRight: "0px",
                      display: "flex",
                    }}
                  >
                    {t("Remove step")} {index + 1}
                  </Button>
                  <hr />

                  <FormDragAndDrop
                    priority={priority}
                    module={module}
                    setStepField={setStepField}
                    stepField={stepField}
                    index={index}
                  />
                </TabPane>
              )
            })}
          </Tabs>
          {stepField.length !== 0 && (
            <Button
              type="primary"
              onClick={() => sendDataForm()}
              style={{ width: "200px" }}
              loading={loadings}
            >
              {t("Create new form")}
            </Button>
          )}
        </Col>

        <Col sm={12} xs={12} md={12} lg={6}>
          {stepField.length !== 0 && (
            <p style={{ fontWeight: "bold" }}> {t("Preview form")} </p>
          )}
          <RenderForm priority={priority} stepField={stepField} />
        </Col>
      </Row>
        </div>
      )}

      {task_hast_form.length !== 0 && (
        <Row>
          <Col>
            <Button
            loading={loadings}
              danger
              style={{ width: "200px", float: "right" }}
              onClick={() => remove_allTask()}
            >
              {t("Delete all task")}
            </Button>
          </Col>
          <br /> <br />
        </Row>
      )}

      {task_hast_form.length !== 0 && (
        <Row>
          <Col>
            {stepField.length !== 0 && (
              <p style={{ fontWeight: "bold" }}> {t("Preview form")} </p>
            )}
            <RenderForm_hast_form
              priority={priority}
              stepField={task_hast_form}
            />
          </Col>
        </Row>
      )}
    </div>
  )
}
