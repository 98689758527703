import React, { useEffect, useState, Fragment } from "react"
import { Row, Col } from "reactstrap"
import { AiOutlineDelete, AiOutlineCheckCircle } from "react-icons/ai"
import { Input , Select } from "antd"
import { useTranslation } from "react-i18next";

const { Option } = Select;

export default function TextField(props) {
  const { t } = useTranslation();
const header_text = {
  color:'red'
}

  function onChange(value) {
    if(value){
      props.handleInputChange_Priority(props.index, value)
    } else {
      props.handleInputChange_Priority(props.index, null)
    }
  }

  useEffect(() => {
    render_select_priority()
    return () => {
      
    }
  }, [props.priority])

  function render_select_priority() {
    return props.priority.map( data => {
      return <Option key={data.task_priority_id} value={data.task_priority_id}>{t(data.task_priority_name)}</Option>
    })
  }

  function renderData() {
    return (
      <Fragment key={`${props.inputField}~${props.index}`}>
        <Row>
          <Col sm={10} xs={10} md={10} lg={10}>
            <label htmlFor="id" style={{ color:'rgb(73, 80, 87)' }}> {t("Name field")} {props.inputField.text_header} <span style={header_text}>*</span> </label>
            <Input
              type="text"
              className="form-control"
              placeholder={`${props.inputField.text_header}`}
              value={props.inputField.task_name}
              onChange={event => props.handleInputChange(props.index, event)}
            />
          </Col>
          <Col sm={2} xs={2} md={2} lg={2}>
            <a>
              <AiOutlineDelete
                color="#E22323"
                onClick={() => props.handleRemoveFields(props.index)}
                size="25"
                style={{ marginTop: "35px" }}
              />
            </a>
          </Col>
        </Row>
        <Row>
          <Col>
          <label style={{ marginTop:'10px', color:'#727272' }} > {t("Detail field")} {props.inputField.text_header}</label>
          <Input
            type="text"
            className="form-control"
            placeholder={`${props.inputField.text_header}`}
            value={props.inputField.task_detail}
            onChange={(event) =>
              props.handleInputChange_Detail(props.index, event)
            }
          />
          </Col>
        </Row>
        <Row>
        <Col>
        <label style={{ marginTop:'10px', color:'#727272' }} > {t("Select priority")} {props.inputField.text_header}</label>
        <Select
        showSearch
        allowClear
        style={{ width: '100%' }}
        placeholder={t("Select priority")}
        optionFilterProp="children"
        onChange={onChange}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
      {
        render_select_priority()
      }
      </Select>
        </Col>
        </Row>
        <br />
        <hr />
      </Fragment>
    )
  }

  return <>{renderData()}</>
}
