import React, { useState, useEffect } from "react"
import { Form, Table, Modal, Tooltip } from "antd"
import Detail_car from "./Detail_car"
import { FcSearch, FcSettings } from "react-icons/fc"
import { useHistory } from "react-router-dom"
import paper from "../../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/paper.png"
import Send_Action from "./Send_Action"
import { useTranslation } from "react-i18next";

export default function Table_list_car(props) {
  const history = useHistory()
  const { t } = useTranslation();
  const [form_select] = Form.useForm()
  const [list_data_table, setList_data_table] = useState([])
  const [data_car, setData_car] = useState({})
  const [visible, setVisible] = useState(false)
  const [modal_action, setModal_action] = useState(false)
  const [car_id, setCar_id] = useState(null)

  useEffect(() => {
    setList_data_table(props.data_list)
    return () => {
      setList_data_table([])
    }
  }, [props.data_list])

  const columns = [
    {
      title: t("car code"),
      dataIndex: "car_code",
      key: "car_code",
      render: text => checkData(text),
      width:'130px'
    },
    {
      title: t("license"),
      dataIndex: "car_license_number",
      key: "car_license_number",
      width:'130px',
      render: text => checkData(text),
    },
    {
      title: t("province"),
      dataIndex: "pro_name",
      key: "pro_name",
      render: text => checkData(text),
      width:'130px'
    },
    {
      title: t("name"),
      dataIndex: "car_name",
      key: "car_name",
      render: text => checkData(text),
      width:'150px'
    },
    {
      title: t("Car group"),
      dataIndex: "car_group_name",
      key: "car_group_name",
      render: text => checkData(text),
      width:'150px'
    },
    {
      title: t("brand"),
      dataIndex: "car_brand_name",
      key: "car_brand_name",
      render: text => checkData(text),
      width:'130px'
    },
    {
      title: t("color"),
      dataIndex: "car_color_name",
      key: "car_color_name",
      render: text => checkData(text),
      width:'130px'
    },
    {
      title: t("branch"),
      dataIndex: "cus_branch_name",
      key: "cus_branch_name",
      render: text => checkData(text),
      width:'130px'
    },
    {
      title: t("car remark"),
      dataIndex: "car_remark",
      key: "car_remark",
      render: text => checkData(text),
    },
    {
      title: "",
      render: text => (
        <Tooltip title={t("view car")}>
          <a>
            <FcSearch
              onClick={() => {
                setVisible(true)
                setData_car(text)
              }}
              size={20}
            />
          </a>
        </Tooltip>
      ),
    },
    {
      title: t("action"),
      render: text => (
        <Tooltip title={t("send notification")}>
          <a>
            <img
              src={paper}
              alt="paper"
              style={{ width: "30px", marginLeft: "10px" }}
              onClick={() => {
                setModal_action(true)
                setCar_id(text.car_id)
                // setData_car(text)
              }}
            />
          </a>
        </Tooltip>
      ),
    },
    {
      title: "",
      render: text => (
        <Tooltip title={t("edit car")}>
          <a>
            <FcSettings
              onClick={() =>
                history.push(`/customers/${props.id}/edit-car/${text.car_id}`)
              }
              size={20}
            />
          </a>
        </Tooltip>
      ),
    },
  ]

  function checkData(data) {
    if (data) {
      return data
    } else {
      return "N/A"
    }
  }

  const onReset = () => {
    form_select.resetFields()
  }

  return (
    <div>
      <Table
        columns={columns}
        dataSource={list_data_table}
        pagination={false}
        loading={props.loading}
        rowKey="car_id"
        scroll={{ x: 800 }}
      />

      <Modal
        title={false}
        centered
        open={modal_action}
        onCancel={() => {
          setModal_action(false)
          setCar_id(null)
          onReset()
        }}
        // height={500}
        width={1000}
        footer={false}
      >
        <Form form={form_select} name="control-hooks" >
          <Send_Action
            id={props.id}
            car_id={car_id}
            setCar_id={setCar_id}
            setModal_action={setModal_action}
            modal_action={modal_action}
            notification_list={props.notification_list}
          />
        </Form>
      </Modal>

      <Modal
        title={false}
        centered
        open={visible}
        onCancel={() => {
          setVisible(false)
          setData_car({})
        }}
        footer={false}
        height={500}
        width={1000}
      >
        <Detail_car data_car={data_car} />
      </Modal>
    </div>
  )
}
