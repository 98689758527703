import React, { useState, useEffect } from "react"
import { Tabs, Button, Modal } from "antd"
import TextForm from "./TextForm"
import { useTranslation } from "react-i18next"
import { ExclamationCircleOutlined } from "@ant-design/icons"

const { TabPane } = Tabs
const { confirm } = Modal

export default function index(props) {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState("0")
  const [enableEdit, setEnableEdit] = useState(false)

  const handleInputChange_Detail = (index_step, index_fields, event) => {
    const values = [...props.task_hast_form]
    values[index_step].fields[index_fields].value = event.target.value
    props.setTask_hast_form(values)
  }

  function checkStepPass(data) {
    let checkPassStep = false
    data.fields.map(data => {
      checkPassStep = data.pass_step
    })
    return checkPassStep
  }

  function editTask(index) {
    setEnableEdit(true)
    const dataStepAll = [...props.stepField]
    let dataNewStepEdit = dataStepAll
    let arrayStepOne = []
    dataStepAll[index].fields.map(data => {
      if (
        data.task_type_module_name !== "checkinField" &&
        data.task_type_module_name !== "checkoutField"
      ) {
        const dataStep = {
          image: null,
          multi_image: [],
          pass_step: false,
          task_detail: data.task_detail,
          task_id: data.task_id,
          task_index: data.task_index,
          task_name: data.task_name,
          task_no: data.task_no,
          task_priority_name: data.task_priority_name,
          task_stepper: data.task_stepper,
          task_type_module_name: data.task_type_module_name,
          task_type_name: data.task_type_name,
          value: null,
        }
        arrayStepOne.push(dataStep)
      } else {
        arrayStepOne.push(data)
      }
    })
    dataNewStepEdit[index].fields = arrayStepOne
    props.setTask_hast_form(dataNewStepEdit)
  }

  function cancelEditTask() {
    setEnableEdit(false)
    props.mapping_data_array()
  }

  function previousBtn(index) {
    if (enableEdit === true) {
      return confirm({
        title: "Confirm cancel edit ?",
        icon: <ExclamationCircleOutlined />,
        centered : true ,
        onOk() {
          cancelEditTask()
          setActiveTab((index - 1).toString())
        },

        onCancel() {},
      })
    } else {
      setActiveTab((index - 1).toString())
    }
  }

  function nextBtn(index) {
    if (enableEdit === true) {
      return confirm({
        title: "Confirm cancel edit ?",
        icon: <ExclamationCircleOutlined />,
        centered : true ,
        onOk() {
          cancelEditTask()
          setActiveTab((index + 1).toString())
        },

        onCancel() {},
      })
    } else {
      setActiveTab((index + 1).toString())
    }
  }

  return (
    <>
      <Tabs
        defaultActiveKey="0"
        onTabClick={e => {
          setActiveTab(e)
        }}
        activeKey={activeTab}
      >
        {props.stepField.map((data, index) => {
          const stepPass = checkStepPass(data)
          return (
            <TabPane tab={`${t("Step")} ${index + 1}`} key={index}>
              {/* <button onClick={() => console.log(data)}>
                debug step {index + 1}
              </button> */}
              {stepPass === true && (
                <div>
                  {enableEdit === false && (
                    <Button
                      style={{ float: "right" }}
                      onClick={() => editTask(index)}
                    >
                      Edit step {index + 1}
                    </Button>
                  )}
                </div>
              )}
              {enableEdit === true && stepPass === false && (
                <Button
                  style={{ float: "right" }}
                  onClick={() => cancelEditTask()}
                >
                  Cancel edit step {index + 1}
                </Button>
              )}
              <div style={{ clear: "both" }} />
              <br />
              <TextForm
                ser_id={props.ser_id}
                setTask_hast_form={props.setTask_hast_form}
                task_hast_form={props.task_hast_form}
                handleInputChange_Detail={handleInputChange_Detail}
                fields={data.fields}
                priority={props.priority}
                check_data_submit={props.check_data_submit}
                Check_service_form={props.Check_service_form}
                last_step={props.stepField.length}
                index_step={index}
                setActiveTab={props.setActiveTab}
                check_new_data_edit={props.check_new_data_edit}
                check_activity_log={props.check_activity_log}
                list_service_overview={props.list_service_overview}
                setEnableEdit={setEnableEdit}
              />
              <hr />
              {index > 0 && index < props.stepField.length - 1 && (
                <div>
                  <Button
                    onClick={() => previousBtn(index)}
                    style={{ float: "left", width: "80px" }}
                  >
                    {t("Previous")}
                  </Button>
                  <Button
                    onClick={() => nextBtn(index)}
                    style={{ float: "right", width: "80px" }}
                  >
                    {t("Next")}
                  </Button>
                </div>
              )}

              {index === 0 && (
                <Button
                  onClick={() => nextBtn(index)}
                  style={{ float: "right", width: "80px" }}
                >
                  {t("Next")}
                </Button>
              )}
              {index === props.stepField.length - 1 && (
                <Button
                  onClick={() => previousBtn(index)}
                  style={{ float: "left", width: "80px" }}
                >
                  {t("Previous")}
                </Button>
              )}
            </TabPane>
          )
        })}
      </Tabs>
    </>
  )
}
