import React,{ useState, useEffect } from "react"
import { Row, Col  } from "reactstrap"
import Render_Card from './components/Render_Card'
import "../Dashboard_style.css"
import { List, Avatar, Space , Pagination } from 'antd';
import { MessageOutlined, LikeOutlined, StarOutlined } from '@ant-design/icons';

export default function Employee(props) {
    const [data_pagination, setData_pagination] = useState({
        page : 1 ,
        minValue: 0,
        maxValue: 4
    })
    useEffect(() => {
        setImageSize()
        return () => {
            setData_pagination({
                ...data_pagination,
                page : 1 ,
                minValue: 0,
                maxValue: 4
            })
        }
    }, [])

    const setImageSize = () => {
        if(window.innerWidth < 768) {
            return "small"
         
          } else {
            return null
          }  
        }

    const handleChange = (page, pageSize) => {
        setData_pagination({
            ...data_pagination,
            page : page ,
            minValue: (page - 1) * pageSize ,
            maxValue: page * pageSize
        })
      };

    return (
        <div style={{ width:'100%' }}>
      <div>
     

      <Row>
      {props.all_employee &&
        props.all_employee.length > 0 &&
        props.all_employee.slice(data_pagination.minValue, data_pagination.maxValue).map((val , index) => (
            <Col xs={12} sm={12} md={4} lg={3} xl={3} key={index}>
            <Render_Card 
            avatar={val.emp_avatar}
            firstname={val.emp_firstname}
            lastname={val.emp_lastname}
            role={val.role}
            />
            </Col>
        ))}
        </Row>
        <Pagination
        size={setImageSize()}
        style={{ float:'right', marginTop:'20px' }}
        defaultPageSize={4}
        defaultCurrent={1}
        current={data_pagination.page}
          onChange={handleChange}
          total={props.all_employee.length}
          pageSizeOptions={["4","10", "20", "50", "100"]}
        />
    </div>
        </div>
    )
}
