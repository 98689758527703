import React from 'react'
import { RiDeleteBin6Line } from 'react-icons/ri';
import { Modal, Table, Tag, notification } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import * as api from '../../../../service/Api_Edit_Operator'
import axios from 'axios'
import { FcDataSheet } from "react-icons/fc"
import { useHistory } from "react-router-dom";
import helpers from '../../../Function_Redirect/Redirect_login'
import { useTranslation } from "react-i18next";

const { confirm } = Modal;

export default function List_Location(props) {
    const token = localStorage.getItem('token')
    const { t } = useTranslation();
    const history = useHistory()
    
    const columns  = [
        {
            title: t("Zone"),
            dataIndex: 'geo_name',
            key: 'geo_name',
            render: text => <p>{check_data(text)}</p>,
          },
          {
            title: t("Province"),
            dataIndex: 'pro_name',
            key: 'pro_name',
            render: text => <p>{check_data(text)}</p>,
          },
          {
            title: t("District"),
            dataIndex: 'dis_name',
            key: 'dis_name',
            render: text => <p>{check_data(text)}</p>,
          },
          {
            title: t("Sub district"),
            dataIndex: 'subdis_name',
            key: 'subdis_name',
            render: text => <p>{check_data(text)}</p>,
          },
          {
            title: '',
            render: text => <a><RiDeleteBin6Line size={20} style={{ color:'red' }} onClick={() => remove_location(text.ope_location_id)} /></a>,
          },
    ]

    function check_data(data) {
        if(data){
            return data
        } else {
            return <Tag color="success"> {t("Operate all")} </Tag>
        }
    }

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
          message: message,
        });
      };

    function remove_location(location) {
        return   confirm({
            title: t("Delete location"),
            icon: <ExclamationCircleOutlined />,
            centered: true,
            okText: t("Delete"),
            okType: 'danger',
            cancelText: t("no"),
            onOk() {
                const remove_location = api.REMOVE_LOCATION(token, location) 
                axios(remove_location)
                .then( res => {
                    // console.log(res.data);
                })
                .catch( error => {
                  if(error){
                    if(error.response){
                      if(error.response.status === 401){
                        helpers.re_login()
                        history.push('/login')          
                        } else if(error.response.status === 404){
                          openNotificationWithIcon('success', t("Remove location success"))
                          props.Check_location_operator()
                        } else {
                          openNotificationWithIcon('error', t("Remove location error"))
                        }
                    }
                  }
   
                })
            },
            onCancel() {
            },
          });
    }

    return (
        <div>
        <Table 
        loading={props.loading}
        columns={columns} 
        dataSource={props.location_data} 
        rowKey="ope_location_id"
        scroll={{x : 800}} 
        />
        <p style={{ fontSize:'15px' , marginTop:'-45px' }}> <FcDataSheet size={20} style={{ marginBottom:'5px' }} /> {t("total data")} : {props.location_data.length}</p>
        </div>
    )
}
