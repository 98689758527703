import React, { useEffect, useState } from "react"
import * as api from "../../../service/Api_Edit_Sub_Product_In_Car"
import axios from "axios"
import { Table, Button, Modal, Tooltip, notification } from "antd"
import "./Table_data.css"
import { RiDeleteBin6Line } from "react-icons/ri"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { FcSettings } from "react-icons/fc"
import { useHistory } from "react-router-dom";
import helpers from '../../Function_Redirect/Redirect_login'
import { useTranslation } from "react-i18next";

const { confirm } = Modal

export default function Table_Data(props) {
  const { t } = useTranslation();
  const token = localStorage.getItem("token")
  const history = useHistory()
  const [sub_product_data_table, setSub_product_data_table] = useState([])
  const [list_product_model_all, setList_product_model_all] = useState([])
  const [list_warranty_all, setList_warranty_all] = useState([])
  const [loading, setLoading] = useState(false)
  const [visible_create, setVisible_create] = useState(false)
  const [
    create_new_sub_product_data,
    setCreate_new_sub_product_data,
  ] = useState({})
  const [group_id, setGroup_id] = useState(null)
  const [visible_edit, setVisible_edit] = useState(false)
  const [ edit_sub_product_data, setEdit_sub_product_data, ] = useState({})

  useEffect(() => {
    list_sub_product_data()
    list_product_model()
    list_warranty()
    return () => {

    }
  }, [])

  function list_warranty() {
    const list_warranty_data = api.LIST_WARRANTY(token)
    axios(list_warranty_data)
      .then(res => {
        setList_warranty_all(res.data.data)
      })
      .catch(error => {
          if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }

      })
  }
  function list_product_model() {
    const list_data = api.LIST_PRODUCT_MODEL(token)
    axios(list_data)
      .then(res => {
        setList_product_model_all(res.data.data)
      })
      .catch(error => {
          if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }

      })
  }

  function list_sub_product_data() {
    setLoading(true)
    const list_data = api.LIST_SUB_PRODUCT_ID(token, props.product_id)
    axios(list_data)
      .then(res => {
        setLoading(false)
        setSub_product_data_table(res.data.data)
      })
      .catch(error => {
        setLoading(false)
        if(error){
          if(error.response){
            if(error.response.status === 401){
              helpers.re_login()
              history.push('/login')          
              }
          }
        }

      })
  }

  const columns = [
    {
      title: t("Product group"),
      dataIndex: "pro_group_name",
      key: "pro_group_name",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Product model"),
      dataIndex: "pro_model_name",
      key: "pro_model_name",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Product number"),
      dataIndex: "pro_no",
      key: "pro_no",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Product serial number"),
      dataIndex: "pro_serial_number",
      key: "pro_serial_number",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Product sub serial number"),
      dataIndex: "pro_sub_serial_number",
      key: "pro_sub_serial_number",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Product remark"),
      dataIndex: "pro_remark",
      key: "pro_remark",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Warranty"),
      dataIndex: "warranty_name",
      key: "warranty_name",
      render: text => <p>{text}</p>,
    }
  ]

  return (
    <div>

      <Table
        loading={loading}
        rowKey="sup_pro_id"
        pagination={false}
        columns={columns}
        dataSource={sub_product_data_table}
        scroll={{ x: 800 }}
      />


    </div>
  )
}
