import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Card, CardBody, Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next"
import { MdKeyboardBackspace } from "react-icons/md"
import { DatePicker, Table } from "antd"
import "../Style_Report.css"
import axios from "axios"
import * as api from "../../../service/Api_Report_All"
const FileDownload = require("js-file-download")
import Input_Search from "../../components/report/Input_Search"
import { useHistory } from "react-router-dom"
import helpers from "../../Function_Redirect/Redirect_login"

export default function ReportProduct() {
  const { t } = useTranslation()
  const history = useHistory()
  const [date_select, setDate_select] = useState({
    date_from: null,
    date_to: null,
  })
  const [data_report, setData_report] = useState([])
  const [search_report, setSearch_report] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loading_excel, setLoading_excel] = useState(false)
  const token = localStorage.getItem("token")

  const style_DatePicker = {
    width: "100%",
  }

  useEffect(() => {
    check_report()
    return () => {
      setData_report([])
      setLoading(false)
      setDate_select({
        date_from: null,
        date_to: null,
      })
    }
  }, [])

  function onChangeDateFrom(date, dateString) {
    if (dateString) {
      setDate_select({
        ...date_select,
        date_from: dateString,
      })
    } else {
      setDate_select({
        ...date_select,
        date_from: null,
      })
    }
  }

  function onChangeDateTo(date, dateString) {
    if (dateString) {
      setDate_select({
        ...date_select,
        date_to: dateString,
      })
    } else {
      setDate_select({
        ...date_select,
        date_to: null,
      })
    }
  }

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day

    return [year, month, day].join("-")
  }

  const check_data = (params, data) => {
    if (data) {
      return params + data
    } else {
      return ""
    }
  }

  const check_date_time = (data_date_start, data_date_end) => {
    let date_toString = new Date()
    let date_now = formatDate(date_toString)
    if (data_date_start !== null && data_date_end !== null) {
      return "start_time=" + data_date_start + "&end_time=" + data_date_end
    } else {
      return "start_time=" + "2000-01-01" + "&end_time=" + date_now
    }
  }

  function check_report() {
       setLoading(true)
    let url = `apireport/product/customer?${check_date_time(
      date_select.date_from,
      date_select.date_to
    )}${check_data("&q=", search_report)}`
    const check_report = api.NEW_CHECK_REPORT(token, url)
    axios(check_report)
      .then(res => {
        setLoading(false)
        setData_report(res.data.data)
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  function checkData(data) {
    if (data) {
      return data
    } else {
      return null
    }
  }

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T + "+7")
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return null
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  function downloadExcel() {
    setLoading_excel(true)
    let url = `apireport/product/customer/excel?${check_date_time(
      date_select.date_from,
      date_select.date_to
    )}${check_data("&q=", search_report)}`
    const excel_report = api.NEW_DOWNLOAD_REPORT_EXCEL(token, url)
    axios(excel_report)
      .then(res => {
        setLoading_excel(false)
        FileDownload(res.data, "report product.csv")
      })
      .catch(error => {
        setLoading_excel(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  const columns = [
    {
      title: t("Product group"),
      dataIndex: "pro_group_name",
      key: "pro_group_name",
      render: text => <p>{checkData(text)}</p>,
      width: "150px",
    },
    {
      title: t("Product model"),
      dataIndex: "productmodel",
      key: "productmodel",
      render: text => <p>{checkData(text)}</p>,
      width: "150px",
    },
    {
      title: t("Product serial number"),
      dataIndex: "pro_serial_number",
      key: "pro_serial_number",
      render: text => <p>{checkData(text)}</p>,
      width: "150px",
    },
    {
      title: t("Car license number"),
      dataIndex: "car_license_number",
      key: "car_license_number",
      render: text => <p>{checkData(text)}</p>,
      width: "150px",
    },
    {
      title: t("dealer"),
      dataIndex: "dea_name",
      key: "dea_name",
      render: text => <p>{checkData(text)}</p>,
      width: "150px",
    },
    {
      title: t("Date install"),
      dataIndex: "sto_date_install",
      key: "sto_date_install",
      render: text => <p>{cutTimeZone(text)}</p>,
      width: "150px",
    },
    {
      title: t("Warranty"),
      dataIndex: "warranty_name",
      key: "warranty_name",
      render: text => <p>{checkData(text)}</p>,
      width: "150px",
    },

  ]

    return (
        <React.Fragment>
        <MetaTags>
          <title> {t("report product")} | Distar</title>
        </MetaTags>
        <div className="page-content">
          <Container fluid>
            
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/reports")}
              />
            </a>{" "}
            {t("report product")}
          </h3>
  
            <Card>
              <CardBody>
              <Row>
                <Col>
                  <Input_Search
                    setSearch_report={setSearch_report}
                    check_report={check_report}
                    downloadExcel={downloadExcel}
                    loading_excel={loading_excel}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                  <p className="text-bold"> {t("Date from")} </p>
                  <DatePicker
                    onChange={onChangeDateFrom}
                    allowClear
                    style={style_DatePicker}
                  />
                </Col>
                <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                  <p className="text-bold"> {t("Date to")} </p>
                  <DatePicker
                    onChange={onChangeDateTo}
                    allowClear
                    style={style_DatePicker}
                  />
                </Col>
              </Row>
              <br />
              <br />
              <p>
                {" "}
                <span className="text-bold text-header-report-all font-size-report">
                  {" "}
                  {t("Total")}{" "}
                </span>{" "}
                :{" "}
                <span className="text-total-report-all font-size-report">
                  {data_report.length}
                </span>{" "}
              </p>

              <hr />

                   <Table
                loading={loading}
                rowKey="pro_id"
                columns={columns}
                dataSource={data_report}
                scroll={{ x: 400 }}
              />
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    )
}
