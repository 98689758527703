import React from "react"
import { Row, Col } from "reactstrap"
import { Collapse, Card } from "antd"
import Col_Device from "./components/Col_Device"
import { useTranslation } from "react-i18next"

import product_info from "../../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/product_info.png"

const { Panel } = Collapse

export default function Device_Info(props) {
  const { t } = useTranslation()

  const icons_info_header = {
    width: "25px",
    marginRight: "20px",
  }

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return null
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  // props.list_device_all
  return (
    <div>
      <Card
        title={
          <p>
            {" "}
            <img
              src={product_info}
              alt="product_info"
              style={icons_info_header}
            />{" "}
            {t("Device")}
          </p>
        }
      >
        <Collapse>
          {props.list_device_all.map((data, index) => {
            return (
              <Panel header={data["t_devtype.title"]} key={index}>
                <Row>
                  <p className="text-header-information">
                    {data["t_devtype.title"]}
                  </p>
                  <Col_Device
                    text_header="Product model name"
                    data={data["t_devtype.title"]}
                  />

                  <Col_Device
                    text_header="Product model code"
                    data={data["t_devtype.gid"]}
                  />

                  <Col_Device
                    text_header="Product remark"
                    data={data["t_devtype.remark"]}
                  />

                  <Col_Device text_header="gid" data={data.gid} />

                  <Col_Device text_header="typeid" data={data.typeid} />

                  <Col_Device text_header="userid" data={data.userid} />

                  <Col_Device text_header="carid" data={data.carid} />

                  <Col_Device text_header="protocol" data={data.protocol} />

                  <Col_Device text_header="cid" data={data.cid} />

                  <Col_Device text_header="license" data={data.license} />

                  <Col_Device text_header="grpid" data={data.grpid} />

                  <Col_Device text_header="title" data={data.title} />

                  <Col_Device text_header="fueltank" data={data.fueltank} />

                  <Col_Device text_header="fuelkm" data={data.fuelkm} />

                  <Col_Device text_header="fuelhours" data={data.fuelhours} />

                  <Col_Device text_header="remark" data={data.remark} />

                  <Col_Device text_header="factory" data={data.factory} />

                  <Col_Device
                    text_header="ctime"
                    data={cutTimeZone(data.ctime)}
                  />
                  <hr />
                  <p className="text-header-information">
                    {" "}
                    {t("Sin information")}{" "}
                  </p>

                  <Col_Device text_header="simid" data={data.simid} />

                  <Col_Device text_header="sim" data={data.sim} />
                </Row>
              </Panel>
            )
          })}
        </Collapse>
      </Card>
    </div>
  )
}
