import React from "react"
import Map_Service from "../../Edit_Service/Detail_Service/components/Map_Service"
import { useTranslation } from "react-i18next"

export default function Map_Repair(props) {
    const { t } = useTranslation()
    return (
        <div>
        {props.detail_repair.ser_lat !== null && props.detail_repair.ser_lat !== "None"  && 
          props.detail_repair.ser_long !== null &&  props.detail_repair.ser_long !== "None" && (
            <div>
              <p className="text-bold"> {t("Service location")} </p>
              <Map_Service
                ser_lat={props.detail_repair.ser_lat}
                ser_long={props.detail_repair.ser_long}
              />
            </div>
          )}
      </div>
    )
}