import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next";

export default function Detail_Sale_dealer_db(props) {
  const [data_dealer, setData_dealer] = useState(null)
  const { t } = useTranslation();

  useEffect(() => {
    setData_dealer(props.data_dealer_db)
    return () => {
      setData_dealer(null)
    }
  }, [props.data_dealer_db])

  return (
    <div className="scroll-modal-display-mobile-app" >
      <p
        style={{
          textAlign: "center",
          fontWeight: "bold",
          marginTop: "10px",
          fontSize: "15px",
        }}
      >
        {t("Dealer information")}
      </p>
      {data_dealer !== null && (
        <Row>
          <Col sm={12} md={6} lg={2} xl={2}>
            <p className="text-bold"> {t("Dealer code")} </p>
          </Col>
          <Col sm={12} md={6} lg={4} xl={4}>
            <p style={{ fontSize: "15px" }}>
              <span className="tet">:</span> <span className="tetTwo">-</span>{" "}
              {data_dealer.dea_code}
            </p>
          </Col>

          <Col sm={12} md={6} lg={2} xl={2}>
            <p className="text-bold"> {t("Dealer name")} </p>
          </Col>
          <Col sm={12} md={6} lg={4} xl={4}>
            <p style={{ fontSize: "15px" }}>
              <span className="tet">:</span> <span className="tetTwo">-</span>{" "}
              {data_dealer.dea_name}
            </p>
          </Col>

          <Col sm={12} md={6} lg={2} xl={2}>
            <p className="text-bold"> {t("Dealer TAX ID")} </p>
          </Col>
          <Col sm={12} md={6} lg={4} xl={4}>
            <p style={{ fontSize: "15px" }}>
              <span className="tet">:</span> <span className="tetTwo">-</span>{" "}
              {data_dealer.dea_taxid}
            </p>
          </Col>

          <Col sm={12} md={6} lg={2} xl={2}>
            <p className="text-bold"> {t("Dealer credit")} </p>
          </Col>
          <Col sm={12} md={6} lg={4} xl={4}>
            <p style={{ fontSize: "15px" }}>
              <span className="tet">:</span> <span className="tetTwo">-</span>{" "}
              {data_dealer.dea_credit}
            </p>
          </Col>

          <Col sm={12} md={6} lg={2} xl={2}>
            <p className="text-bold"> {t("Dealer phone")} </p>
          </Col>
          <Col sm={12} md={6} lg={4} xl={4}>
            <p style={{ fontSize: "15px" }}>
              <span className="tet">:</span> <span className="tetTwo">-</span>{" "}
              {data_dealer.dea_phone}
            </p>
          </Col>

          <Col sm={12} md={6} lg={2} xl={2}>
            <p className="text-bold"> {t("Dealer address")} </p>
          </Col>
          <Col sm={12} md={6} lg={4} xl={4}>
            <p style={{ fontSize: "15px" }}>
              <span className="tet">:</span> <span className="tetTwo">-</span>{" "}
              {data_dealer.dea_address}
            </p>
          </Col>

          <Col sm={12} md={6} lg={2} xl={2}>
            <p className="text-bold"> {t("Dealer email")} </p>
          </Col>
          <Col sm={12} md={6} lg={4} xl={4}>
            <p style={{ fontSize: "15px" }}>
              <span className="tet">:</span> <span className="tetTwo">-</span>{" "}
              {data_dealer.dea_email}
            </p>
          </Col>

          <Col sm={12} md={6} lg={2} xl={2}>
            <p className="text-bold"> {t("Dealer fax")} </p>
          </Col>
          <Col sm={12} md={6} lg={4} xl={4}>
            <p style={{ fontSize: "15px" }}>
              <span className="tet">:</span> <span className="tetTwo">-</span>{" "}
              {data_dealer.dea_fax}
            </p>
          </Col>

          <Col sm={12} md={6} lg={2} xl={2}>
            <p className="text-bold"> {t("Dealer contact firstname")} </p>
          </Col>
          <Col sm={12} md={6} lg={4} xl={4}>
            <p style={{ fontSize: "15px" }}>
              <span className="tet">:</span> <span className="tetTwo">-</span>{" "}
              {data_dealer.dea_contact_firstname}
            </p>
          </Col>

          <Col sm={12} md={6} lg={2} xl={2}>
            <p className="text-bold"> {t("Dealer contact lastname")} </p>
          </Col>
          <Col sm={12} md={6} lg={4} xl={4}>
            <p style={{ fontSize: "15px" }}>
              <span className="tet">:</span> <span className="tetTwo">-</span>{" "}
              {data_dealer.dea_contact_lastname}
            </p>
          </Col>

          <Col sm={12} md={6} lg={2} xl={2}>
            <p className="text-bold"> {t("Dealer contact mobile")} </p>
          </Col>
          <Col sm={12} md={6} lg={4} xl={4}>
            <p style={{ fontSize: "15px" }}>
              <span className="tet">:</span> <span className="tetTwo">-</span>{" "}
              {data_dealer.dea_contact_mobile}
            </p>
          </Col>

          <Col sm={12} md={6} lg={2} xl={2}>
            <p className="text-bold"> {t("Dealer contact email")} </p>
          </Col>
          <Col sm={12} md={6} lg={4} xl={4}>
            <p style={{ fontSize: "15px" }}>
              <span className="tet">:</span> <span className="tetTwo">-</span>{" "}
              {data_dealer.dea_contact_email}
            </p>
          </Col>
        </Row>
      )}
    </div>
  )
}
