import React, { useRef, useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { Form, Button, notification, Avatar, Switch, Modal } from "antd"
import Input_Comp from "./Input_Comp"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import * as api from "../../service/Api_New_Customer"
import axios from "axios"
import { QuestionOutlined } from "@ant-design/icons"
import Render_Center_Avatar from "../Operator/Table_data/Render_Center_Avatar"
import { useHistory } from "react-router-dom"
import helpers from "../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"

const { confirm } = Modal

export default function Edit_User(props) {
  const token = localStorage.getItem("token")
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const history = useHistory()
  const imageUploader = useRef(null)
  const [edit, setEdit] = useState(true)
  const [loading, setLoading] = useState(false)
  const [image_select_upload, setImage_select_upload] = useState(null)
  const [user_edit, setUser_edit] = useState({
    cus_user_firstname: null,
    cus_user_lastname: null,
    cus_user_phone: null,
  })

  const handleImageUpload = e => {
    if (e.target.files[0] !== undefined) {
      if (e.target.files[0].type !== "image/jpeg") {
        openNotificationWithIcon(
          "error",
          t("please upload jpg or jpeg image only")
        )
      } else {
        let img_upload = e.target.files[0]
        setImage_select_upload(img_upload)
      }
    }
  }

  const openNotificationWithIcon = (type, description) => {
    notification[type]({
      message: description,
    })
  }

  useEffect(() => {
    setUser_edit({
      ...user_edit,
      cus_user_firstname: props.data_user.cus_user_firstname,
      cus_user_lastname: props.data_user.cus_user_lastname,
      cus_user_phone: props.data_user.cus_user_phone,
    })
    onFill()

    return () => {}
  }, [props.data_user])

  const onFill = () => {
    setUser_edit({
      ...user_edit,
      cus_user_firstname: props.data_user.cus_user_firstname,
      cus_user_lastname: props.data_user.cus_user_lastname,
      cus_user_phone: props.data_user.cus_user_phone,
    })
    form.setFieldsValue({
      cus_user_firstname: props.data_user.cus_user_firstname,
      cus_user_lastname: props.data_user.cus_user_lastname,
      cus_user_phone: props.data_user.cus_user_phone,
    })
  }

  const onFinish = value => {
    return confirm({
      title: t("edit user info"),
      icon: <QuestionOutlined style={{ color: "#1890ff" }} />,
      centered: true,
      okText: t("edit"),
      cancelText: t("no"),
      onOk() {
        setLoading(true)
        const data = {
          cus_user_firstname: user_edit.cus_user_firstname,
          cus_user_lastname: user_edit.cus_user_lastname,
          cus_user_phone: user_edit.cus_user_phone,
        }
        if (image_select_upload !== null) {
          const formData = new FormData()
          formData.append("file", image_select_upload)
          const upload_image = api.UPLOAD_PROFILE(token, formData)
          axios(upload_image)
            .then(res => {
              let add_image_path = {
                cus_user_avatar: res.data.path,
                ...data,
              }
              edit_user(add_image_path)
            })
            .catch(error => {
              setLoading(false)
              if (error) {
                if (error.response) {
                  if (error.response.status === 401) {
                    helpers.re_login()
                    history.push("/login")
                  } else {
                    openNotificationWithIcon("error", t("edit user error"))
                  }
                }
              }
            })
        } else {
          edit_user(data)
        }
      },
      onCancel() {},
    })
  }

  function edit_user(data) {
    const edit_ = api.EDIT_USER(token, props.user_id, data)
    axios(edit_)
      .then(res => {
        setLoading(false)
        props.check_detail_user()
        openNotificationWithIcon("success", t("edit user success"))
        Cancel_Edit()
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            } else {
              openNotificationWithIcon("error", t("edit user error"))
            }
          }
        }
      })
  }

  function Cancel_Edit() {
    setEdit(!edit)
    setImage_select_upload(null)
    onFill()
  }


  return (
    <div>
      <Form form={form} name="control-hooks" onFinish={onFinish}>
        <Row>
          <Col>
            <div style={{ display: "inherit" }}>
              <p
                className="text-bold"
                style={{
                  color: "#495057",
                  textAlign: "center",
                  fontSize: "15px",
                }}
              >
                {t("user information")}
              </p>
              {edit === true && (
                <Button
                  style={{ float: "right", width: "150px" }}
                  onClick={() => setEdit(!edit)}
                >
                  {t("edit")}
                </Button>
              )}
              {edit === false && (
                <Button
                  type="primary"
                  style={{ float: "right", width: "150px" }}
                  onClick={() => Cancel_Edit()}
                >
                  {t("cancel")}
                </Button>
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <input
              type="file"
              accept="image/jpeg"
              onChange={handleImageUpload}
              ref={imageUploader}
              style={{
                display: "none",
              }}
            />
            {image_select_upload === null && (
              <Render_Center_Avatar
                path_image={props.data_user.cus_user_avatar}
                size={120}
              />
            )}
            {image_select_upload !== null && (
              <div className="image-upload">
                <Avatar
                  src={URL.createObjectURL(image_select_upload)}
                  size={120}
                  style={{ display: "block", margin: "auto" }}
                />
              </div>
            )}

            {edit !== true && (
              <Button
                type="primary"
                style={{
                  width: "150px",
                  display: "block",
                  margin: "20px auto 20px auto",
                }}
                onClick={() => imageUploader.current.click()}
              >
                {t("upload photo")}
              </Button>
            )}
            <br />
          </Col>
        </Row>

        <br />
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("firstname")}
              placeholder={props.data_user.cus_user_firstname}
              headState={setUser_edit}
              nameState={user_edit}
              disabled={edit}
              keyState="cus_user_firstname"
              type="text"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("lastname")}
              placeholder={props.data_user.cus_user_lastname}
              headState={setUser_edit}
              nameState={user_edit}
              disabled={edit}
              keyState="cus_user_lastname"
              type="text"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("phone")}
              placeholder={props.data_user.cus_user_phone}
              headState={setUser_edit}
              nameState={user_edit}
              disabled={edit}
              keyState="cus_user_phone"
              type="number"
            />
          </Col>

        </Row>

        <br />
        {edit === false && (
          <Button
            type="primary"
            htmlType="submit"
            style={{ display: "block", margin: "auto", width: "150px" }}
            loading={loading}
          >
            {t("edit")}
          </Button>
        )}
      </Form>
    </div>
  )
}
