import React, { useEffect, useState } from "react"
import { Form, Pagination, Table, Button, Input } from "antd"
import * as api from "../../../service/Api_New_Stock"
import axios from "axios"
import { Row, Col } from "reactstrap"
import { EditOutlined } from "@ant-design/icons"
import Search_customer from "./Search_customer"
import Select_Car_In_Customer from "./Select_Car_In_Customer"
import Select_Contract_In_Customer from "./Select_Contract_In_Customer"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { FcDataSheet } from "react-icons/fc"
import helpers from "../../Function_Redirect/Redirect_login"

export default function Find_All_Product_Form_Group(props) {
  const token = localStorage.getItem("token")
  const history = useHistory()
  const { t } = useTranslation()
  const [data_search_table_customer, setData_search_table_customer] = useState(
    null
  )
  const [customer_table, setCustomer_table] = useState([])
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 10,
    page_Size: 10,
  })

  useEffect(() => {
    search_customer_data()
    return () => {
      setCustomer_table([])
      setData_search_table_customer(null)
    }
  }, [])

  function search_customer_data() {
    if (data_search_table_customer) {
      const list_data = api.LIST_ALL_CUSTOMER_DATA(
        token,
        1,
        page_limit.page_Size,
        data_search_table_customer
      )
      axios(list_data)
        .then(res => {
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setCustomer_table(res.data.data)
        })
        .catch(error => {
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else {
      const list_no_data = api.LIST_ALL_CUSTOMER_NO_DATA(
        token,
        1,
        page_limit.page_Size
      )
      axios(list_no_data)
        .then(res => {
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setCustomer_table(res.data.data)
        })
        .catch(error => {
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  function changePagination(page, pageSize) {
    if (data_search_table_customer) {
      const list_data = api.LIST_ALL_CUSTOMER_DATA(
        token,
        page,
        pageSize,
        data_search_table_customer
      )
      axios(list_data)
        .then(res => {
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setCustomer_table(res.data.data)
        })
        .catch(error => {
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else {
      const list_no_data = api.LIST_ALL_CUSTOMER_NO_DATA(token, page, pageSize)
      axios(list_no_data)
        .then(res => {
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setCustomer_table(res.data.data)
        })
        .catch(error => {
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  const columns = [
    {
      title: t("Customer name"),
      dataIndex: "cus_name",
      key: "cus_name",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Customer code"),
      dataIndex: "cus_code",
      key: "cus_code",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Customer phone"),
      dataIndex: "cus_phone",
      key: "cus_phone",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Customer email"),
      dataIndex: "cus_email",
      key: "cus_email",
      render: text => <p>{text}</p>,
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      props.setCustomer_data({
        ...props.customer_data,
        cus_id: selectedRows[0].cus_id,
        cus_name: selectedRows[0].cus_name,
      })
    },
    getCheckboxProps: record => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  }

  return (
    <div>
      <p className="text-bold ">
        {t("Select car and contract in customer")}
        <span style={{ color: "red" }}>*</span>
      </p>
      {props.customer_data.cus_id === null && props.customer_create === false && (
        <div>
          <p className="text-bold ">
            - {t("Customer")} <span style={{ color: "red" }}>*</span>
          </p>
          <Search_customer
            customer_create={props.customer_create}
            create_new_contract={props.create_new_contract}
            setCreate_new_contract={props.setCreate_new_contract}
            setCreate_new_car={props.setCreate_new_car}
            create_new_car={props.create_new_car}
            setCustomer_create={props.setCustomer_create}
            setCreate_new_customer={props.setCreate_new_customer}
            create_new_customer={props.create_new_customer}
            search_customer_data={search_customer_data}
            setData_search_table_customer={setData_search_table_customer}
          />
          <br />
          <Form.Item
            name="table_select_car_info"
            rules={[
              {
                required: false,
                message: "Please select branch",
              },
            ]}
          >
            <Table
              rowSelection={{
                type: "radio",
                ...rowSelection,
              }}
              rowKey="cus_id"
              pagination={false}
              columns={columns}
              dataSource={customer_table}
              scroll={{ x: 800 }}
            />
          </Form.Item>
          <div style={{ float: "right", marginTop: "10px" }}>
            <Pagination
              current={page_limit.page}
              total={page_limit.limit}
              onChange={changePagination}
            />
          </div>
          <p style={{ fontSize: "15px", marginTop: "20px" }}>
            {" "}
            <FcDataSheet size={20} style={{ marginBottom: "5px" }} />{" "}
            {t("total data")} : {page_limit.limit}
          </p>
        </div>
      )}

      {props.customer_data.cus_id !== null && props.customer_create === false && (
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div style={{ display: "flex" }}>
              <p className="text-bold " style={{ marginTop: "7px" }}>
                {t("Customer")}
              </p>
              <Input
                style={{ marginLeft: "50px", height: "35px" }}
                value={props.customer_data.cus_name}
                placeholder={props.customer_data.cus_name}
                disabled
              />
              <a>
                <EditOutlined
                  style={{ fontSize: "20px", marginLeft: "10px" }}
                  onClick={() => {
                    setData_search_table_customer(null)
                    props.setCar_create_only(false)
                    props.setCreate_new_car({})
                    props.setCreate_new_contract({})
                    props.setContract_create_only(false)
                    props.setCreate_new_car({})
                    props.setStock_new({
                      ...props.stock_new,
                      car_id: null,
                      con_id: null,
                    })
                    props.setCustomer_data({
                      ...props.customer_data,
                      cus_id: null,
                      cus_name: null,
                    })
                    props.setCar_data({
                      ...props.car_data,
                      car_id: null,
                      car_name: null,
                    })
                    props.setContract_data({
                      ...props.contract_data,
                      con_id: null,
                      con_name: null,
                    })
                  }}
                />
              </a>
            </div>
          </Col>
        </Row>
      )}

      {props.customer_create === true && (
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div style={{ display: "flex" }}>
              <p className="text-bold " style={{ marginTop: "7px" }}>
                {t("Customer")}
              </p>
              <Input
                style={{ marginLeft: "50px", height: "35px" }}
                value={props.create_new_customer.cus_name}
                placeholder={props.create_new_customer.cus_name}
                disabled
              />
              <a>
                <EditOutlined
                  style={{ fontSize: "20px", marginLeft: "10px" }}
                  onClick={() => {
                    props.setCreate_new_customer({})
                    props.setCreate_new_car({})
                    props.setCreate_new_contract({})
                    props.setCustomer_create(false)
                  }}
                />
              </a>
            </div>
          </Col>
        </Row>
      )}

      {props.customer_create === true && props.car_create_only === false && (
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div style={{ display: "flex" }}>
              <p className="text-bold " style={{ marginTop: "7px" }}>
                {t("Car")}
              </p>
              <Input
                style={{ marginLeft: "85px", height: "35px" }}
                value={props.create_new_car.car_license_number}
                placeholder={props.create_new_car.car_license_number}
                disabled
              />
            </div>
          </Col>
        </Row>
      )}

      {props.customer_create === true && props.contract_create_only === false && (
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div style={{ display: "flex" }}>
              <p className="text-bold " style={{ marginTop: "7px" }}>
                {t("Contract")}
              </p>
              <Input
                style={{ marginLeft: "48px", height: "35px" }}
                value={props.create_new_contract.con_name}
                placeholder={props.create_new_contract.con_name}
                disabled
              />
            </div>
          </Col>
        </Row>
      )}

      {props.customer_data.cus_id !== null &&
        props.customer_create === false && (
          <Select_Car_In_Customer
            setCreate_new_car={props.setCreate_new_car}
            create_new_car={props.create_new_car}
            car_create_only={props.car_create_only}
            setCar_create_only={props.setCar_create_only}
            customer_data={props.customer_data}
            setCar_data={props.setCar_data}
            car_data={props.car_data}
            setStock_new={props.setStock_new}
            stock_new={props.stock_new}
          />
        )}

      {props.customer_data.cus_id !== null &&
        props.customer_create === false && (
          <Select_Contract_In_Customer
            create_new_contract={props.create_new_contract}
            setCreate_new_contract={props.setCreate_new_contract}
            contract_create_only={props.contract_create_only}
            setContract_create_only={props.setContract_create_only}
            setContract_data={props.setContract_data}
            contract_data={props.contract_data}
            customer_data={props.customer_data}
            setStock_new={props.setStock_new}
            stock_new={props.stock_new}
          />
        )}
    </div>
  )
}
