import React from 'react'
import { Table, Modal, notification } from "antd"
import { RiDeleteBin6Line } from 'react-icons/ri';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import * as api from '../../../service/Api_Edit_Service'
import axios from 'axios'
import { useTranslation } from "react-i18next";

const { confirm } = Modal;

export default function Table_service_hast_manager(props) {
    const token = localStorage.getItem('token')
    const { t } = useTranslation();

    const columns = [
        {
          title: t("Manager code"),
          dataIndex: "emp_code",
          key: "emp_code",
          render: text => <p>{text}</p>,
        },
        {
          title: t("Firstname"),
          dataIndex: "emp_firstname",
          key: "emp_firstname",
          render: text => <p>{text}</p>,
        },
        {
          title: t("Lastname"),
          dataIndex: "emp_lastname",
          key: "emp_lastname",
          render: text => <p>{text}</p>,
        },
        {
          title: t("Email"),
          dataIndex: "emp_email",
          key: "emp_email",
          render: text => <p>{text}</p>,
        },
        {
            dataIndex: "",
            key: "",
            render: text => <a><RiDeleteBin6Line onClick={() => remove_manager(text.spm_id)} size={20} style={{ color:'red' }} /></a>
        }
      ]

      function remove_manager(spm_id) {
          return   confirm({
            title: t("Are you sure delete manager"),
            icon: <ExclamationCircleOutlined />,
            okText: t("Delete"),
            okType: "danger",
            cancelText: t("no"),
            centered:true ,
            onOk() {
                const remove = api.REMOVE_MANAGER(token,spm_id)
                axios(remove)
                .then( res => {
          
                })
                .catch( error => {
                    if(error.response.status === 404){
                        props.check_service_hast_manager()
                        props.search_manager()
                        props.check_activity_log()
                        openNotificationWithIcon('success', t("Remove manager success"))
                        props.list_service_overview()
                    } else {
                        openNotificationWithIcon('error', t("Remove manager error"))
                    }
                })
            },
            onCancel() {
            //   console.log('Cancel');
            },
          });
      }

      const openNotificationWithIcon = (type, message) => {
        notification[type]({
          message: message,
        })
      }

    return (
        <div>
        <Table
        rowKey="emp_id"
        columns={columns}
        dataSource={props.service_manager}
        scroll={{ x: 800 }}
      />
        </div>
    )
}
