import React, { useEffect, useState } from "react"
import { Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next";

export default function Overview_User_Info(props) {
  const [user_detail, setUser_detail] = useState([])
  const { t } = useTranslation();

  useEffect(() => {
    if (props.select_user) {
      setUser_detail([
        {
          head_data1: "name",
          value_data1: check_data(props.select_user.name),
          head_data2: "nickname",
          value_data2: check_data(props.select_user.nickname),
        },
        {
          head_data1: "email",
          value_data1: check_data(props.select_user.email),
          head_data2: "phone",
          value_data2: check_data(props.select_user.phone),
        },
        {
          head_data1: "gid",
          value_data1: check_data(props.select_user.gid),
          head_data2: "pid",
          value_data2: check_data(props.select_user.pid),
        },
        {
          head_data1: "pfid",
          value_data1: check_data(props.select_user.pfid),
          head_data2: "cid",
          value_data2: check_data(props.select_user.cid),
        },
        {
          head_data1: "remark",
          value_data1: check_data(props.select_user.remark),
          head_data2: "addr",
          value_data2: check_data(props.select_user.addr),
        },
      ])
    }
    return () => {
      setUser_detail([])
    }
  }, [props.select_user])

  function check_data(data) {
    if (data) {
      return data
    } else {
      return null
    }
  }

  return (
    <div>
      {props.select_user && (
        <div className="border-card-info">
          <p className="text-header-information">
            {t("User information from distar")}
          </p>

          {user_detail.map((data, index) => {
            return (
              <Row key={index}>
                <Col sm={12} md={6} lg={2} xl={2}>
                  <p className="text-bold">{data.head_data1}</p>
                </Col>
                <Col sm={12} md={6} lg={4} xl={4}>
                  <p style={{ fontSize: "15px" }}>
                    {" "}
                    <span className="tet">:</span>{" "}
                    <span className="tetTwo">-</span> {data.value_data1}{" "}
                  </p>
                </Col>
                <Col sm={12} md={6} lg={2} xl={2}>
                  <p className="text-bold">{data.head_data2}</p>
                </Col>
                <Col sm={12} md={6} lg={4} xl={4}>
                  <p style={{ fontSize: "15px" }}>
                    {" "}
                    <span className="tet">:</span>{" "}
                    <span className="tetTwo">-</span> {data.value_data2}{" "}
                  </p>
                </Col>
              </Row>
            )
          })}
        </div>
      )}
    </div>
  )
}
