import React from 'react'
import { TimePicker, Form } from 'antd';

export default function Input_Time({ header, keyState, placeholder, headState, nameState, required  }) {
    
function onChange(time, timeString) {
    if(timeString){
        headState({ ...nameState, [keyState]: timeString })
    } else {
        headState({ ...nameState, [keyState]: null })
    }
  }
    return (
        <div>
        <p className="text-bold">
          {header} <span style={{ color:'red' }}>*</span>
        </p>
        <Form.Item
          name={keyState}
          rules={[
            {
              required: required,
              message: `${placeholder}`,
            },
          ]}
        >
        <TimePicker onChange={onChange} placeholder={placeholder} style={{ width:'100%' }} />
        </Form.Item>
      </div>
    )
}
