import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <React.Fragment>
      <footer >
        <Container fluid={true}>
          <Row>

            <Col >
              <div className=" d-none d-sm-block" style={{ textAlign:'center', height:'50px', color:'#495057'}}>
              Powered by Nok Soft Co .,Ltd .
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
