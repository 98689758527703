import React from "react"
import { Col } from "reactstrap"

export default function Col_Device({ text_header, data }) {
  return (
    <>
      <Col sm={12} md={6} lg={2} xl={2}>
        <p className="text-bold"> {text_header} </p>
      </Col>
      <Col sm={12} md={6} lg={4} xl={4}>
        <p style={{ fontSize: "15px" }}>
          <span className="tet">:</span> <span className="tetTwo">-</span>{" "}
          {data}
        </p>
      </Col>
    </>
  )
}
