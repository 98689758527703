import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Container, Card, CardBody, Row, Col } from "reactstrap"
import { useHistory } from "react-router-dom"
import { MdKeyboardBackspace } from "react-icons/md"
import axios from "axios"
import * as api from "../../service/Api_New_Stock"
import { Form, Button, Modal, notification } from "antd"
import Input_Comp from "./Components/Input_Comp"
import Input_Date_Comp from "./Components/Input_Date_Comp"
import Find_Model_InGroup from "./Components/Find_Model_InGroup"
import Select_Product from "./Select_Product"
import Select_Car from "./Select_Car"
import Select_Dealer from "./Select_Dealer"
import Select_Operator from "./Select_Operator"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import Select_data from "./Components/Select_data"
import no_permi from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png"
import helpers from "../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const { confirm } = Modal

const New_Stock = props => {
  const { t } = useTranslation()
  const history = useHistory()
  const [loading_button, setLoading_button] = useState(false)
  const token = localStorage.getItem("token")
  const [form] = Form.useForm()
  const [list_warranty_all, setList_warranty_all] = useState([])
  const [list_product_model_all, setList_product_model_all] = useState([])
  const [stock_new, setStock_new] = useState({})
  const [group_id, setGroup_id] = useState(null)
  const [customer_create, setCustomer_create] = useState(false)
  const [car_create_only, setCar_create_only] = useState(false)
  const [contract_create_only, setContract_create_only] = useState(false)
  const [create_new_customer, setCreate_new_customer] = useState({})
  const [create_new_contract, setCreate_new_contract] = useState({})
  const [create_new_car, setCreate_new_car] = useState({})
  const [customer_data, setCustomer_data] = useState({
    cus_id: null,
    cus_name: null,
  })
  const [car_data, setCar_data] = useState({
    car_id: null,
    car_name: null,
  })
  const [contract_data, setContract_data] = useState({
    con_id: null,
    con_name: null,
  })
  const [dealer_data, setDealer_data] = useState({
    dea_id: null,
    dea_name: null,
  })
  const { permissionAllService } = useSelector(({ Distar }) => Distar)
  const permi = localStorage.getItem("permi")
  const [permi_t, setPermi_t] = useState(false)

  useEffect(() => {
    if (group_id !== null) {
      form.resetFields(["product_model"])
    }
    return () => {}
  }, [group_id])

  function list_warranty() {
    const list_warranty_data = api.LIST_WARRANTY(token)
    axios(list_warranty_data)
      .then(res => {
        setList_warranty_all(res.data.data)
      })
      .catch(error => {
        // console.log(error)
      })
  }

  function list_product_model() {
    const list_data = api.LIST_PRODUCT_MODEL(token)
    axios(list_data)
      .then(res => {
        setList_product_model_all(res.data.data)
      })
      .catch(error => {
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  useEffect(() => {
    if (permissionAllService) {
      if (permissionAllService.stock) {
        setPermi_t(permissionAllService.stock.write)
      }
    }
    return () => {}
  }, [permissionAllService])

  useEffect(() => {
    list_warranty()
    list_product_model()
    return () => {
      setStock_new({})
      setCustomer_data({})
      setCar_data({})
      setDealer_data({})
      setContract_data({})
      setLoading_button(false)
      setList_warranty_all([])
      setList_product_model_all([])
      setCreate_new_customer({})
      setCreate_new_contract({})
      setCreate_new_car({})
      setCustomer_create(false)
      setGroup_id(null)
      setCar_create_only(false)
      setContract_create_only(false)
    }
  }, [])

  const onFinish = values => {
    return confirm({
      title: t("Confirm create new stock"),
      icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
      okText: t("Create"),
      cancelText: t("no"),
      centered: true,
      onOk() {
        setLoading_button(true)
        if (dealer_data.dea_id === null || !dealer_data.dea_id) {
          setLoading_button(false)
          return error(t("Please select dealer"))
        } else {
          if (customer_create === true) {
            new_create_customer_one()
          } else if (customer_create === false) {
            if (customer_data.cus_id === null || !customer_data.cus_id) {
              setLoading_button(false)
              return error(t("Please select customer"))
            } else {
              if (car_create_only === true && contract_create_only === true) {
                new_create_car_one(customer_data.cus_id)
              } else if (
                car_create_only === false &&
                contract_create_only === true
              ) {
                if (car_data.car_id === null || !car_data.car_id) {
                  setLoading_button(false)
                  return error(t("Please select car"))
                } else {
                  new_create_contract_one(customer_data.cus_id, car_data.car_id)
                }
              } else if (
                car_create_only === true &&
                contract_create_only === false
              ) {
                if (contract_data.con_id === null || !contract_data.con_id) {
                  setLoading_button(false)
                  return error(t("Please select contract"))
                } else {
                  new_create_car_only(
                    customer_data.cus_id,
                    contract_data.con_id
                  )
                }
              } else if (
                car_create_only === false &&
                contract_create_only === false
              ) {
                if (car_data.car_id === null || !car_data.car_id) {
                  setLoading_button(false)
                  return error(t("Please select car"))
                } else if (
                  contract_data.con_id === null ||
                  !contract_data.con_id
                ) {
                  setLoading_button(false)
                  return error(t("Please select contract"))
                } else {
                  new_create_product_one(
                    customer_data.cus_id,
                    car_data.car_id,
                    contract_data.con_id
                  )
                }
              }
            }
          }
        }
      },
      onCancel() {},
    })
  }

  async function new_create_car_only(cus_id, con_id) {
    let data_car = {
      ...create_new_car,
      cus_id: cus_id,
    }
    const create_car = api.CREATE_NEW_CAR(token, data_car)
    await axios(create_car)
      .then(res => {
        new_create_product_one(cus_id, res.data.car_id, con_id)
      })
      .catch(error => {
        setLoading_button(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            } else {
              openNotificationWithIcon("error", t("Create new stock error"))
            }
          }
        }
      })
  }

  async function new_create_customer_one() {
    const create_customer = api.CREATE_CUSTOMER(token, create_new_customer)
    await axios(create_customer)
      .then(res => {
        new_create_car_one(res.data.cus_id)
      })
      .catch(error => {
        setLoading_button(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            } else {
              openNotificationWithIcon("error", t("Create new stock error"))
            }
          }
        }
      })
  }

  async function new_create_car_one(cus_id) {
    let data_car = {
      ...create_new_car,
      cus_id: cus_id,
    }
    const create_car = api.CREATE_NEW_CAR(token, data_car)
    await axios(create_car)
      .then(res => {
        new_create_contract_one(cus_id, res.data.car_id)
      })
      .catch(error => {
        setLoading_button(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            } else {
              openNotificationWithIcon("error", t("Create new stock error"))
            }
          }
        }
      })
  }

  async function new_create_contract_one(cus_id, car_id) {
    let data_contract = {
      ...create_new_contract,
      cus_id: cus_id,
    }
    const create_contract = api.CREATE_NEW_CONTRACT(token, data_contract)
    await axios(create_contract)
      .then(res => {
        new_create_product_one(cus_id, car_id, res.data.con_id)
      })
      .catch(error => {
        setLoading_button(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            } else {
              openNotificationWithIcon("error", t("Create new stock error"))
            }
          }
        }
      })
  }

  async function new_create_product_one(cus_id, car_id, con_id) {
    let data_product_new = {
      pro_serial_number: stock_new.pro_serial_number,
      pro_model_id: stock_new.pro_model_id,
      pro_remark: stock_new.pro_remark,
      warranty_id: stock_new.warranty_id,
    }
    const create_product = api.CREATE_NEW_PRODUCT(token, data_product_new)
    await axios(create_product)
      .then(res => {
        new_create_stock_one(cus_id, car_id, con_id, res.data.pro_id)
      })
      .catch(error => {
        setLoading_button(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            } else {
              openNotificationWithIcon("error", t("Create new stock error"))
            }
          }
        }
      })
  }

  async function new_create_stock_one(cus_id, car_id, con_id, pro_id) {
    let data_stock = {
      cus_id: cus_id,
      car_id: car_id,
      con_id: con_id,
      pro_id: pro_id,
      dea_id: dealer_data.dea_id,
      sto_date_install: stock_new.sto_date_install,
    }
    const create_stock = api.CREATE_NEW_STOCK(token, data_stock)
    await axios(create_stock)
      .then(res => {
        // console.log("cus_id = " + cus_id)
        // console.log("car_id = " + car_id)
        // console.log("con_id = " + con_id)
        // console.log("pro_id = " + pro_id)

        // console.log(res.data)
        // console.log("stock success")
        setLoading_button(false)
        history.push(`/stock/${res.data.sto_id}`)
        openNotificationWithIcon("success", t("Create new stock success"))
      })
      .catch(error => {
        // console.log("create stock error")
        // console.log(error.response)
        // openNotificationWithIcon("error", t("Create new stock error"))
        setLoading_button(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            } else {
              openNotificationWithIcon("error", t("Create new stock error"))
            }
          }
        }
      })
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  function error(message) {
    Modal.error({
      title: message,
      centered: true,
    })
  }

  function cancelForm() {
    setGroup_id(null)

    form.resetFields()
    setCreate_new_customer({})
    setCreate_new_contract({})
    setCreate_new_car({})
    setCustomer_create(false)
    setCar_create_only(false)
    setContract_create_only(false)
    setStock_new({})
    setCustomer_data({
      cus_id: null,
      cus_name: null,
    })
    setCar_data({
      car_id: null,
      car_name: null,
    })
    setContract_data({
      con_id: null,
      con_name: null,
    })
    setDealer_data({
      dea_id: null,
      dea_name: null,
    })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Create new stock")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/stock")}
              />
            </a>{" "}
            {t("Create new stock")}
          </h3>
          {permi_t === true && (
            <Card>
              <CardBody>
                <Form
                  form={form}
                  name="create_new_stock"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                >
                  <Row>
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <Input_Comp
                        header={t("Product serial number")}
                        placeholder={t("Product serial number")}
                        headState={setStock_new}
                        nameState={stock_new}
                        type="text"
                        keyState="pro_serial_number"
                      />
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <Input_Date_Comp
                        header={t("Installation date")}
                        placeholder={t("Installation date")}
                        headState={setStock_new}
                        nameState={stock_new}
                        keyState="sto_date_install"
                        required={false}
                      />
                    </Col>

                    <Col sm={12} md={6} lg={6} xl={6}>
                      <Select_data
                        data_map={list_product_model_all}
                        header={t("Product group")}
                        placeholder={t("Product group")}
                        keyState="group_id"
                        headState={setGroup_id}
                        nameState={group_id}
                        map_value="pro_group_id"
                        map_name="pro_group_name"
                      />
                    </Col>

                    <Col sm={12} md={6} lg={6} xl={6}>
                      <Find_Model_InGroup
                        setGroup_id={setGroup_id}
                        group_id={group_id}
                        setStock_new={setStock_new}
                        stock_new={stock_new}
                      />
                    </Col>

                    <Col sm={12} md={6} lg={6} xl={6}>
                      <Select_data
                        data_map={list_warranty_all}
                        header={t("Warranty")}
                        placeholder={t("Warranty")}
                        keyState="warranty_id"
                        headState={setStock_new}
                        nameState={stock_new}
                        map_value="warranty_id"
                        map_name="warranty_name"
                      />
                    </Col>

                    <Col sm={12} md={6} lg={6} xl={6}>
                      <Input_Comp
                        header={t("Product remark")}
                        placeholder={t("Product remark")}
                        headState={setStock_new}
                        nameState={stock_new}
                        type="text"
                        keyState="pro_remark"
                      />
                    </Col>
                  </Row>

                  <br />
                  <Row>
                    <Col>
                      <Select_Car
                        contract_create_only={contract_create_only}
                        setContract_create_only={setContract_create_only}
                        car_create_only={car_create_only}
                        setCar_create_only={setCar_create_only}
                        create_new_contract={create_new_contract}
                        setCreate_new_contract={setCreate_new_contract}
                        setCreate_new_car={setCreate_new_car}
                        create_new_car={create_new_car}
                        setCustomer_create={setCustomer_create}
                        customer_create={customer_create}
                        setCreate_new_customer={setCreate_new_customer}
                        create_new_customer={create_new_customer}
                        setStock_new={setStock_new}
                        stock_new={stock_new}
                        setCustomer_data={setCustomer_data}
                        customer_data={customer_data}
                        setCar_data={setCar_data}
                        car_data={car_data}
                        setContract_data={setContract_data}
                        contract_data={contract_data}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <Select_Dealer
                        setDealer_data={setDealer_data}
                        dealer_data={dealer_data}
                        setStock_new={setStock_new}
                        stock_new={stock_new}
                      />
                    </Col>
                  </Row>
                  <br />

                  <Row>
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <div className="div_create_new_car">
                        <Button
                          className="btn_cancel_create_new_car"
                          onClick={() => cancelForm()}
                        >
                          {t("Cancel")}
                        </Button>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <div className="div_create_new_car">
                        <Button
                          className="btn_submit_create_new_car"
                          type="primary"
                          htmlType="submit"
                          loading={loading_button}
                        >
                          {t("Create")}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          )}
          {permi_t === false && (
            <div>
              <img
                src={no_permi}
                alt="no permission"
                style={{ display: "block", margin: "50px auto" }}
              />
              <p style={{ textAlign: "center", fontSize: "20px" }}>
                {t("no permission read module")}
              </p>
              <p style={{ textAlign: "center", fontSize: "20px" }}>
                {" "}
                {t("please contract administrator")}{" "}
              </p>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
export default New_Stock
