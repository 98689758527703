import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { Form, Button, Checkbox, Modal, notification, Tabs } from "antd"
import axios from "axios"
import * as api from "../../../service/Api_Edit_Operator"
import Select_no_search from "./Components/Select_no_search"
import Select_after_select_geo from "./Components/Select_after_select_geo"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import List_Location from "./List_Location"
import "./Location_css.css"
import { useHistory } from "react-router-dom"
import helpers from "../../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"

const { TabPane } = Tabs
const { confirm } = Modal

export default function Location(props) {
  const { t } = useTranslation()
  const history = useHistory()
  const [activeTab_all, setActiveTab_all] = useState("1")
  const [firstState, setFirstState] = useState(false)
  const token = localStorage.getItem("token")
  const [location_select, setLocation_select] = useState({
    all_geo: false,
    all_pro: false,
    all_dis: false,
    all_subdis: false,
    geo_id: null,
    pro_id: null,
    subdis_id: null,
    dis_id: null,
  })
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    return () => {
      setLocation_select({})
      setFirstState(false)
    }
  }, [])

  const onFinish = values => {
    return confirm({
      title: t("Confirm location"),
      icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
      centered: true,
      okText: t("create"),
      cancelText: t("no"),
      onOk() {
        setLoading(true)
        const data_edit = {
          ope_id: props.ope_id,
          ...location_select,
        }
        // console.log(data_edit);
        const create_new_location = api.CREATE_NEW_LOCATION(token, data_edit)
        axios(create_new_location)
          .then(res => {
            openNotificationWithIcon(
              "success",
              t("Create new location success")
            )
            cancelForm()
            props.Check_location_operator()
            setActiveTab_all("1")
            setLoading(false)
          })
          .catch(error => {
            setLoading(false)
            if (error) {
              if (error.response) {
                if (error.response.status === 401) {
                  helpers.re_login()
                  history.push("/login")
                } else {
                  openNotificationWithIcon(
                    "error",
                    t("Create new location error")
                  )
                }
              }
            }
          })
      },
      onCancel() {},
    })
  }

  function cancelForm() {
    setFirstState(false)
    form.resetFields()
    setLocation_select({
      all_geo: false,
      all_pro: false,
      all_dis: false,
      all_subdis: false,
      geo_id: null,
      pro_id: null,
      subdis_id: null,
      dis_id: null,
    })
  }

  function onChange_all_geo(e) {
    if (e.target.checked === true) {
      setFirstState(true)
      setLocation_select({
        all_geo: true,
        all_pro: false,
        all_dis: false,
        all_subdis: false,

        geo_id: null,
        pro_id: null,
        subdis_id: null,
        dis_id: null,
      })
      form.resetFields()
    } else {
      setFirstState(false)
      setLocation_select({
        all_geo: false,
        all_pro: false,
        all_dis: false,
        all_subdis: false,

        geo_id: null,
        pro_id: null,
        subdis_id: null,
        dis_id: null,
      })
    }
  }

  function onChange_all_province(e) {
    if (e.target.checked === true) {
      setLocation_select({
        geo_id: location_select.geo_id,
        all_pro: true,
        all_geo: false,
        all_dis: false,
        all_subdis: false,
        pro_id: null,
        subdis_id: null,
        dis_id: null,
      })
      form.resetFields(["pro_id", "dis_id", "subdis_id"])
    } else {
      setLocation_select({
        geo_id: location_select.geo_id,
        all_geo: false,
        all_pro: false,
        all_dis: false,
        all_subdis: false,
        pro_id: null,
        subdis_id: null,
        dis_id: null,
      })
    }
  }

  function onChange_all_district(e) {
    if (e.target.checked === true) {
      setLocation_select({
        geo_id: location_select.geo_id,
        pro_id: location_select.pro_id,
        all_dis: true,
        all_geo: false,
        all_pro: false,
        all_subdis: false,
        subdis_id: null,
        dis_id: null,
      })
      form.resetFields(["dis_id", "subdis_id"])
    } else {
      setLocation_select({
        geo_id: location_select.geo_id,
        pro_id: location_select.pro_id,
        all_dis: false,
        all_geo: false,
        all_pro: false,
        all_subdis: false,
        subdis_id: null,
        dis_id: null,
      })
    }
  }
  function onChange_all_sub_district(e) {
    if (e.target.checked === true) {
      setLocation_select({
        geo_id: location_select.geo_id,
        pro_id: location_select.pro_id,
        dis_id: location_select.dis_id,
        all_subdis: true,
        all_geo: false,
        all_pro: false,
        all_dis: false,
        subdis_id: null,
      })
      form.resetFields(["subdis_id"])
    } else {
      setLocation_select({
        geo_id: location_select.geo_id,
        pro_id: location_select.pro_id,
        dis_id: location_select.dis_id,
        all_subdis: false,
        all_geo: false,
        all_pro: false,
        all_dis: false,
        subdis_id: null,
      })
    }
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  return (
    <div>
      <Tabs
        defaultActiveKey="1"
        onTabClick={e => {
          setActiveTab_all(e)
        }}
        activeKey={activeTab_all}
      >
        <TabPane tab={t("Location")} key="1">
          <List_Location
            loading={props.loading}
            ope_id={props.ope_id}
            Check_location_operator={props.Check_location_operator}
            location_data={props.location_data}
          />
        </TabPane>
        <TabPane tab={t("Create new location")} key="2">
          <Form
            form={form}
            name="new_location_operator"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Row>
              <Col sm={12} md={6} lg={6} xl={6}>
                <Row>
                  <Col sm={12} md={6} lg={6} xl={8}>
                    {location_select.all_geo === false && (
                      <Select_no_search
                        header={t("Operator zone")}
                        placeholder={t("Operator zone")}
                        name_api="LIST_ALL_GEO_LOCATION"
                        map_name="geo_name"
                        map_value="geo_id"
                        keyState="geo_id"
                        data_response="message"
                        All_select="all_geo"
                        headState={setLocation_select}
                        nameState={location_select}
                        disabled={false}
                        require={true}
                      />
                    )}
                    {location_select.all_geo === true && (
                      <Select_no_search
                        header={t("Operator zone")}
                        placeholder={t("Operator zone")}
                        name_api="LIST_ALL_GEO_LOCATION"
                        map_name="geo_name"
                        map_value="geo_id"
                        keyState="geo_id"
                        data_response="message"
                        All_select="all_geo"
                        headState={setLocation_select}
                        nameState={location_select}
                        disabled={true}
                        require={false}
                      />
                    )}
                  </Col>
                  <Col sm={12} md={6} lg={6} xl={4}>
                    <Checkbox
                      checked={firstState}
                      onChange={onChange_all_geo}
                      className="checkbox_location"
                    >
                      {t("Operate all zone")}
                    </Checkbox>
                  </Col>
                </Row>
              </Col>
              <Col sm={12} md={6} lg={6} xl={6}>
                <Row>
                  <Col sm={12} md={6} lg={6} xl={8}>
                    {location_select.geo_id &&
                      location_select.all_geo === false &&
                      location_select.all_pro === false && (
                        <Select_after_select_geo
                          header={t("Operator Province")}
                          placeholder={t("Operator Province")}
                          name_api="LIST_LOCATION_PROVINCE_BY_GEOID"
                          map_name="pro_name"
                          map_value="pro_id"
                          keyState="pro_id"
                          data_response="message"
                          headState={setLocation_select}
                          nameState={location_select}
                          data_select_before={location_select.geo_id}
                          All_select="all_pro"
                          disabled={false}
                          require={true}
                        />
                      )}
                    {location_select.all_pro === true && (
                      <Select_after_select_geo
                        header={t("Operator Province")}
                        placeholder={t("Operator Province")}
                        name_api="LIST_LOCATION_PROVINCE_BY_GEOID"
                        map_name="pro_name"
                        map_value="pro_id"
                        keyState="pro_id"
                        data_response="message"
                        headState={setLocation_select}
                        nameState={location_select}
                        data_select_before={location_select.geo_id}
                        All_select="all_pro"
                        disabled={true}
                        require={false}
                      />
                    )}
                  </Col>
                  <Col sm={12} md={6} lg={6} xl={4}>
                    {location_select.geo_id && (
                      <Checkbox
                        onChange={onChange_all_province}
                        className="checkbox_location"
                      >
                        {t("Operate all province")}
                      </Checkbox>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm={12} md={6} lg={6} xl={6}>
                <Row>
                  <Col sm={12} md={6} lg={6} xl={8}>
                    {location_select.all_dis === false &&
                      location_select.pro_id && (
                        <Select_after_select_geo
                          header={t("Operator District")}
                          placeholder={t("Operator District")}
                          name_api="LIST_LOCATION_DIS_BY_PROVINCE_ID"
                          map_name="dis_name"
                          map_value="dis_id"
                          keyState="dis_id"
                          data_response="message"
                          headState={setLocation_select}
                          nameState={location_select}
                          data_select_before={location_select.pro_id}
                          All_select="all_dis"
                          disabled={false}
                          require={true}
                        />
                      )}
                    {location_select.all_dis === true && (
                      <Select_after_select_geo
                        header={t("Operator District")}
                        placeholder={t("Operator District")}
                        name_api="LIST_LOCATION_DIS_BY_PROVINCE_ID"
                        map_name="dis_name"
                        map_value="dis_id"
                        keyState="dis_id"
                        data_response="message"
                        headState={setLocation_select}
                        nameState={location_select}
                        data_select_before={location_select.pro_id}
                        All_select="all_dis"
                        disabled={true}
                        require={false}
                      />
                    )}
                  </Col>
                  <Col sm={12} md={6} lg={6} xl={4}>
                    {location_select.pro_id && (
                      <Checkbox
                        onChange={onChange_all_district}
                        className="checkbox_location"
                      >
                        {t("Operate all district")}
                      </Checkbox>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col sm={12} md={6} lg={6} xl={6}>
                <Row>
                  <Col sm={12} md={6} lg={6} xl={8}>
                    {location_select.all_subdis === false &&
                      location_select.dis_id && (
                        <Select_after_select_geo
                          header={t("Operator sub-district")}
                          placeholder={t("Operator sub-district")}
                          name_api="LIST_LOCATION_SUB_DISTRICT"
                          map_name="subdis_name"
                          map_value="subdis_id"
                          keyState="subdis_id"
                          data_response="message"
                          headState={setLocation_select}
                          nameState={location_select}
                          data_select_before={location_select.dis_id}
                          All_select="all_subdis"
                          disabled={false}
                          require={true}
                        />
                      )}
                    {location_select.all_subdis === true && (
                      <Select_after_select_geo
                        header={t("Operator sub-district")}
                        placeholder={t("Operator sub-district")}
                        name_api="LIST_LOCATION_SUB_DISTRICT"
                        map_name="subdis_name"
                        map_value="subdis_id"
                        keyState="subdis_id"
                        data_response="message"
                        headState={setLocation_select}
                        nameState={location_select}
                        data_select_before={location_select.dis_id}
                        All_select="all_subdis"
                        disabled={true}
                        require={false}
                      />
                    )}
                  </Col>
                  <Col sm={12} md={6} lg={6} xl={4}>
                    {location_select.dis_id && (
                      <Checkbox
                        onChange={onChange_all_sub_district}
                        className="checkbox_location"
                      >
                        {t("Operate all sub-district")}
                      </Checkbox>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm={12} md={6} lg={6} xl={6}>
                <div className="div_create_new_car">
                  <Button
                    className="btn_cancel_create_new_car"
                    onClick={() => cancelForm()}
                  >
                    {t("Cancel")}
                  </Button>
                </div>
              </Col>
              <Col sm={12} md={6} lg={6} xl={6}>
                <div className="div_create_new_car">
                  <Button
                    className="btn_submit_create_new_car"
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                  >
                    {t("Create")}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </TabPane>
      </Tabs>
    </div>
  )
}
