import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { useTranslation } from "react-i18next"
import axios from "axios"
import { Row, Col, Container, Card, CardBody } from "reactstrap"
import { useHistory, useParams } from "react-router-dom"
import { FcSettings } from "react-icons/fc"
import { MdKeyboardBackspace } from "react-icons/md"
import { Table, Button, Modal, Tooltip, notification, Form } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { RiDeleteBin6Line } from "react-icons/ri"
import * as api from "../../service/Api_Admin"
import helpers from "../Function_Redirect/Redirect_login"
import Render_Image_Data from "../Employee/Table/Render_Image_Data"

const { confirm } = Modal

export default function Admin_Group() {
  const history = useHistory()
  const { t } = useTranslation()
  const token = localStorage.getItem("token")
  const [data_list, setData_list] = useState([])
  const [loading, setLoading] = useState(false)
  const [loading_remove, setLoading_remove] = useState(false)
  const { admin_group_id } = useParams()
  useEffect(() => {
    check_admin_in_group()
    return () => {}
  }, [])

  function check_admin_in_group() {
    setLoading(true)
    const list_admin_in_group = api.LIST_ADMIN_ALL_IN_GROUP(
      token,
      parseInt(admin_group_id)
    )
    axios(list_admin_in_group)
      .then(res => {
        setLoading(false)
        setData_list(res.data.message)
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }
  
  function remove_group() {
    return confirm({
        title: t("Confirm remove admin group"),
        icon: <ExclamationCircleOutlined />,
        centered: true,
        okText: t("Confirm"),
        okType: "danger",
        cancelText: t("Cancel"),
        onOk() {
          setLoading_remove(true)
          const delete_data = api.DELETE_ADMIN_GROUP(token, parseInt(admin_group_id))
          axios(delete_data)
            .then(res => {
              setLoading_remove(false)
              openNotificationWithIcon("error", t("Remove admin group error"))
            })
            .catch(error => {
              setLoading_remove(false)
              if (error) {
                if (error.response) {
                  if (error.response.status === 401) {
                    helpers.re_login()
                    history.push("/login")
                  } else if (error.response.status === 404) {
                    openNotificationWithIcon(
                      "success",
                      t("Remove admin group success")
                    )
                    history.push("/admin/group")
                  } else {
                    openNotificationWithIcon(
                      "error",
                      t("Remove admin group error")
                    )
                  }
                }
              }
            })
        },
        onCancel() {},
      })
  }

  const columns = [
    {
      title: "",
      dataIndex: "admin_avatar",
      key: "admin_avatar",
      render: text => <Render_Image_Data path_image={text} />,
    },
    {
      title: t("code"),
      dataIndex: "admin_code",
      key: "admin_code",
    },
    {
      title: t("firstname"),
      dataIndex: "admin_firstname",
      key: "admin_firstname",
    },
    {
      title: t("lastname"),
      dataIndex: "admin_lastname",
      key: "admin_lastname",
    },
    {
      title: t("Email"),
      dataIndex: "admin_email",
      key: "admin_email",
    },
  ]

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Admin group")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/admin/group")}
              />
            </a>{" "}
            {t("Admin group")}
          </h3>

          <Card>
            <CardBody>
              <Button
                className="btn-create-new-sub-product"
                type="primary"
                danger
                loading={loading_remove}
                onClick={() => remove_group()}
              >
                {t("Remove admin group")}
              </Button>
              <Table
                columns={columns}
                dataSource={data_list}
                loading={loading}
                rowKey="admin_id"
                scroll={{ x: 800 }}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
