import React, { useEffect, useState } from "react"
import { Form, Pagination, Table, Button, Input } from "antd"
import * as api from "../../../service/Api_New_Stock"
import axios from "axios"
import { Row, Col } from "reactstrap"
import { EditOutlined } from "@ant-design/icons"
import Search_Dealer from "./Search_Dealer"
import { useTranslation } from "react-i18next"
import { FcDataSheet } from "react-icons/fc"
import { useHistory } from "react-router-dom"
import helpers from "../../Function_Redirect/Redirect_login"

export default function Select_Dealer(props) {
  const token = localStorage.getItem("token")
  const { t } = useTranslation()
  const history = useHistory()
  const [data_search_table_dealer, setData_search_table_dealer] = useState(null)
  const [dealer_table, setDealer_table] = useState([])
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 10,
    page_Size: 10,
  })

  useEffect(() => {
    search_data_dealer()
    return () => {
      setDealer_table([])
      setData_search_table_dealer(null)
    }
  }, [])

  function search_data_dealer() {
    if (data_search_table_dealer) {
      const list_data = api.LIST_DEALER_DATA(
        token,
        1,
        page_limit.page_Size,
        data_search_table_dealer
      )
      axios(list_data)
        .then(res => {
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setDealer_table(res.data.data)
        })
        .catch(error => {
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else {
      const list_np_data = api.LIST_DEALER_NO_DATA(
        token,
        1,
        page_limit.page_Size
      )
      axios(list_np_data)
        .then(res => {
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setDealer_table(res.data.data)
        })
        .catch(error => {
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  function changePagination(page, pageSize) {
    if (data_search_table_dealer) {
      const list_data = api.LIST_DEALER_DATA(
        token,
        page,
        pageSize,
        data_search_table_dealer
      )
      axios(list_data)
        .then(res => {
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setDealer_table(res.data.data)
        })
        .catch(error => {
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else {
      const list_np_data = api.LIST_DEALER_NO_DATA(token, page, pageSize)
      axios(list_np_data)
        .then(res => {
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setDealer_table(res.data.data)
        })
        .catch(error => {
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  const columns = [
    {
      title: t("Dealer name"),
      dataIndex: "dea_name",
      key: "dea_name",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Dealer code"),
      dataIndex: "dea_code",
      key: "dea_code",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Dealer phone"),
      dataIndex: "dea_phone",
      key: "dea_phone",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Dealer email"),
      dataIndex: "dea_email",
      key: "dea_email",
      render: text => <p>{text}</p>,
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      props.setStock_new({
        ...props.stock_new,
        dea_id: selectedRows[0].dea_id,
      })
      props.setDealer_data({
        ...props.dealer_data,
        dea_id: selectedRows[0].dea_id,
        dea_name: selectedRows[0].dea_name,
      })
    },
    getCheckboxProps: record => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  }

  return (
    <div>
      <p className="text-bold ">
        {t("Select dealer")} <span style={{ color: "red" }}>*</span>
      </p>
      {props.dealer_data.dea_name === null && (
        <div>
          <Search_Dealer
            search_data_dealer={search_data_dealer}
            setData_search_table_dealer={setData_search_table_dealer}
          />
          <br />
          <Table
            rowSelection={{
              type: "radio",
              ...rowSelection,
            }}
            rowKey="dea_id"
            pagination={false}
            columns={columns}
            dataSource={dealer_table}
            scroll={{ x: 800 }}
          />

          <div style={{ float: "right", marginTop: "10px" }}>
            <Pagination
              current={page_limit.page}
              total={page_limit.limit}
              onChange={changePagination}
            />
          </div>
          <p style={{ fontSize: "15px", marginTop: "15px" }}>
            {" "}
            <FcDataSheet size={20} style={{ marginBottom: "5px" }} />{" "}
            {t("total data")} : {page_limit.limit}
          </p>
        </div>
      )}

      {props.dealer_data.dea_name !== null && (
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div style={{ display: "flex" }}>
              <p
                className="text-bold "
                style={{ marginTop: "7px", marginRight: "22px" }}
              >
                {t("Dealer")}
              </p>
              <Input
                style={{ marginLeft: "45px", height: "35px" }}
                value={props.dealer_data.dea_name}
                placeholder={props.dealer_data.dea_name}
                disabled
              />
              <a>
                <EditOutlined
                  style={{ fontSize: "20px", marginLeft: "10px" }}
                  onClick={() => {
                    setData_search_table_dealer(null)
                    props.setStock_new({
                      ...props.stock_new,
                      dea_id: null,
                    })
                    props.setDealer_data({
                      ...props.dealer_data,
                      dea_id: null,
                      dea_name: null,
                    })
                  }}
                />
              </a>
            </div>
          </Col>
        </Row>
      )}
    </div>
  )
}
