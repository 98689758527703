import React, { useState, useEffect } from "react"
import Search_data from "./Search_data"
import * as api from "../../../../service/Api_Edit_Contract"
import axios from "axios"
import { Pagination, Table, Modal } from "antd"
import { AiOutlineEye } from "react-icons/ai"
import Overview_device from "./Overview_device"
import { FcSearch, FcDataSheet } from "react-icons/fc"
import Render_Image from "../../../New_Customer/Devices/Render_Image"
import moment from "moment"
import { useHistory } from "react-router-dom"
import helpers from "../../../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next";

export default function Device(props) {
  const { t } = useTranslation();
  const token = localStorage.getItem("token")
  const history = useHistory()
  const [data_search_device, setData_search_device] = useState(null)
  const [data_device, setData_device] = useState([])
  const [detail_device, setDetail_device] = useState({})
  const [visible, setVisible] = useState(false)
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    search_data_device()
    return () => {}
  }, [props.con_id])

  function search_data_device() {
    setLoading(true)
    if (data_search_device) {
      const list_device_data = api.LIST_DEVICE_DATA(
        token,
        props.con_id,
        1,
        page_limit.page_Size,
        data_search_device
      )
      axios(list_device_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setData_device(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else {
      const list_device_no_data = api.LIST_DEVICE_NO_DATA(
        token,
        props.con_id,
        1,
        page_limit.page_Size,
        props.con_id
      )
      axios(list_device_no_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setData_device(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  function changePagination(page, pageSize) {
    setLoading(true)
    if (data_search_device) {
      const list_device_data = api.LIST_DEVICE_DATA(
        token,
        props.con_id,
        page,
        pageSize,
        data_search_device,
        props.con_id
      )
      axios(list_device_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setData_device(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else {
      const list_device_no_data = api.LIST_DEVICE_NO_DATA(
        token,
        props.con_id,
        page,
        pageSize,
        props.con_id
      )
      axios(list_device_no_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setData_device(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  const columns = [
    {
      title: "",
      dataIndex: "pro_model_img",
      key: "pro_model_img",
      render: text => <Render_Image path_image={text} size={80} />,
    },
    {
      title: t("Serial number"),
      dataIndex: "pro_serial_number",
      key: "pro_serial_number",
      render: text => checkData(text),
    },
    {
      title: t("Group name"),
      dataIndex: "pro_group_name",
      key: "pro_group_name",
      render: text => checkData(text),
    },
    {
      title: t("Model name"),
      dataIndex: "pro_model_name",
      key: "pro_model_name",
      render: text => checkData(text),
    },
    {
      title: t("Asset number"),
      dataIndex: "sto_asset_number",
      key: "sto_asset_number",
      render: text => checkData(text),
    },
    {
      title: t("Install"),
      dataIndex: "sto_date_install",
      key: "sto_date_install",
      render: text => cutTimeZone(text),
    },
    {
      title: t("End of warranty"),
      dataIndex: "warranty_name",
      key: "warranty_name",
      render: text => checkData(text),
    },
    {
      title: "",
      render: text => (
        <a>
          <FcSearch
            onClick={() => {
              setVisible(true)
              setDetail_device(text)
            }}
            size={20}
          />
        </a>
      ),
    },
  ]

  function checkData(data) {
    if (data) {
      return data
    } else {
      return "N/A"
    }
  }

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1) //months (0-11)
      var day = pad2(date.getDate()) //day (1-31)
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
      //    const currentDate =  new Date(data).toLocaleDateString('en-US', {
      //             day: '2-digit',
      //             month: '2-digit',
      //             year: 'numeric',
      //           })
      //         return currentDate
    } else {
      return "N/A"
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  // console.log(props.con_id);
  return (
    <div >
      <Search_data
        setData_search_device={setData_search_device}
        search_data_device={search_data_device}
      />
      <Table
        loading={loading}
        columns={columns}
        dataSource={data_device}
        pagination={false}
        rowKey="sto_id"
        scroll={{ x: 800 }}
      />

      <Pagination
        current={page_limit.page}
        total={page_limit.limit}
        onChange={changePagination}
        style={{ marginTop: "20px", float: "right" }}
      />
      <p style={{ fontSize: "15px", marginTop: "20px" }}>
        {" "}
        <FcDataSheet size={20} style={{ marginBottom: "5px" }} /> {t("total data")} :{" "}
        {page_limit.limit}
      </p>

      <Modal
        title={false}
        centered
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={() => {
          setVisible(false)
          setDetail_device({})
        }}
        footer={false}
        width={1000}
      >
        <Overview_device detail_device={detail_device} />
      </Modal>
    </div>
  )
}
