var Ip = require('../service/Ip')

export const SEARCH_ALL = (token, url) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}${url}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const GET_DETAIL_STOCK = (token,sto_id) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/stock/${sto_id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_STOCK_HAST_PRODUCT = (token,sto_id) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/stock/stock-has-contract/${sto_id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_SUB_PRODUCT = (token,pro_id) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/stock/product/is-product-has-sup-product/${pro_id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_ALL_SERVICE_DATA = (token, page, pageSize, data) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/service?page=${page}&limit=${pageSize}&order_by=DESC&q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_ALL_SERVICE_NO_DATA = (token, page, pageSize) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/service?page=${page}&limit=${pageSize}&order_by=DESC`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_ALL_SERVICE_DATA_CUSTOMER = (token, page, pageSize, data , cus_id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer/service/${cus_id}?page=${page}&limit=${pageSize}&order_by=DESC&q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_ALL_SERVICE_NO_DATA_CUSTOMER = (token, page, pageSize, cus_id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer/service/${cus_id}?page=${page}&limit=${pageSize}&order_by=DESC`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_PRIORITY = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/service/priority`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_TYPE = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/sertype`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_STATUS = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/service/status`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_REQUEST_SERVICE_ALL = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/requestservice`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CHECK_DETAIL_REPAIR = (token, rrs_id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/requestservice/${rrs_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const REJECT_REPAIR = (token, data , rrs_id) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/requestservice/reject/${rrs_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const DELETE_REPAIR = (token, rrs_id) => {
    return {
      method: 'delete',
      url: `${Ip.getIP()}api/v1/requestservice/${rrs_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };