import React, { useState, useEffect, useCallback } from "react"
import { Row, Col } from "reactstrap"
import { Tabs, Modal } from "antd"
import * as api from "../../../../../service/service_operator/Api_Edit_Service"
import axios from "axios"
import RenderForm_hast_form from './renderForm_hast_form'
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import helpers from '../../../../Function_Redirect/Redirect_login'

export default function Render_Task_After_Operator_Sending(props) {
    const token = localStorage.getItem("token")
    const { t } = useTranslation();
    const history = useHistory()
    const [priority, setPriority] = useState([])
    const [before_test, setBefore_test] = useState([])
    const [task_hast_form, setTask_hast_form] = useState([])
    const [form_success, setForm_success] = useState([])
  
    useEffect(() => {
      list_priority()
      check_data_submit()
      Check_service_form()
      return () => {
        setPriority([])
        setBefore_test([])
        setTask_hast_form([])
      }
    }, [])
  
  
    function list_priority() {
      const list_priority_info = api.LIST_PRIORITY(token)
      axios(list_priority_info)
        .then(res => {
          setPriority(res.data.data)
        })
        .catch(error => {
          // console.log(error)
          if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }

        })
    }
  
    function compareFirst(a, b) {
      if (a.task_index > b.task_index) {
        return 1
      }
      if (a.task_index < b.task_index) {
        return -1
      }
      return 0
    }
  
    function check_data_submit() {
      const check = api.CHECK_DATA_SUBMIT(token, props.ser_id)
      axios(check)
      .then( res => {
        setForm_success(res.data.message)
      })
      .catch( error => {
        // console.log(error);
        if(error){
          if(error.response){
            if(error.response.status === 401){
              helpers.re_login()
              history.push('/login')          
              }
          }
        }

      })
    }
  
    function Check_service_form() {
      const create_task = api.CHECK_SERVICE_HAST_TASK(token, props.ser_id)
      axios(create_task)
        .then(res => {
          const sortDetailForm = res.data.data.sort(compareFirst)
          setBefore_test(sortDetailForm)
        })
        .catch(error => {
          // console.log(error)
          if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }

        })
    }
  
    useEffect(() => {
      mapping_data_array()
  
      return () => {}
    }, [before_test, form_success])
  
    function mapping_data_array() {
      let stepField = []
      let form_value = []
      let number_page = 0
      before_test.map(data => {
        if (number_page <= data.task_stepper - 1) {
          stepField.push({
            fields: [],
          })
          number_page++
        }
      })
  
      for (let index = 0; index < number_page; index++) {
        if(form_success[index+1] !== undefined){
          if(form_success[index+1].length !== undefined){
            form_success[index+1].map( data => {
              form_value.push(data)
            })
          } else {
            form_value.push(form_success[index+1])
          }
        }
      }
  
      before_test.map(data => {
        var indexFever = form_value.findIndex(
          (std) => std.task_id === data.task_id
        );
        if(indexFever !== -1){
          stepField[data.task_stepper - 1].fields.push({
            ...data,
            value : form_value[indexFever].value ? form_value[indexFever].value : null,
            pass_step : form_value[indexFever].value ? true : false,
            image : null,
            multi_image : []
        })
        } else {
          stepField[data.task_stepper - 1].fields.push({
            ...data,
            value : null,
            pass_step : false,
            image : null,
            multi_image : []
        }) 
        }
        
      })
      setTask_hast_form(stepField)
    }

    return (
        <div style={{ height:'500px', overflowY: 'scroll' , overflowX: 'hidden', padding:"10px" , marginTop:'30px', boxShadow:'rgb(99 99 99 / 20%) 0px 2px 8px 0px' }}>
         <p style={{ textAlign:'center', fontSize:'20px', color:'rgb(73, 80, 87)', fontWeight:'bold' }}> {t("Task data")} </p>
        {task_hast_form.length !== 0 && (
            <Row>
              <Col>
                <RenderForm_hast_form
                ser_id={props.ser_id}
                setTask_hast_form={setTask_hast_form}
                task_hast_form={task_hast_form}
                  priority={priority}
                  stepField={task_hast_form}
                  check_data_submit={check_data_submit}
                  Check_service_form={Check_service_form}
                  setActiveTab={props.setActiveTab}
                />
              </Col>
            </Row>
                )}
        </div>
    )
}
