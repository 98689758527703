import React from "react"
import { Row, Col } from "reactstrap"
import Render_Center_Avatar from "../../../Operator/Table_data/Render_Center_Avatar"
import { useTranslation } from "react-i18next"

export default function Detail_User(props) {
  const { t } = useTranslation()
  const text_header = {
    fontWeight: "bold",
    fontSize: "15px",
  }

  const data_detail = [
    {
      headerDetail1: t("firstname"),
      data1: check_data(props.detail_user_info.dea_user_firstname),
      headerDetail2: t("lastname"),
      data2: check_data(props.detail_user_info.dea_user_lastname),
    },
    {
      headerDetail1: t("phone"),
      data1: check_data(props.detail_user_info.dea_user_phone),
      headerDetail2: t("e-mail"),
      data2: check_data(props.detail_user_info.dea_user_email),
    }
  ]

  function check_data(data) {
    if (data === null) {
      return "N/A"
    } else {
      return data
    }
  }

  return (
    <div className="scroll-modal-display-mobile-app">
      <p
        style={{
          textAlign: "center",
          fontWeight: "bold",
          marginTop: "10px",
          fontSize: "15px",
        }}
      >
        {t("Dealer information")}
      </p>
      <hr />
      <Render_Center_Avatar
        path_image={props.detail_user_info.dea_user_avatar}
        size={100}
      />
      <br />
      {data_detail.map((data, index) => {
        return (
          <Row key={index}>
            <Col sm={6} md={3} lg={3} xl={3}>
              <p style={text_header}> {data.headerDetail1} </p>
            </Col>
            <Col sm={6} md={3} lg={3} xl={3}>
              <p style={{ fontSize: "15px" }}>
                {" "}
                <span className="tet">
                  :
                </span> <span className="tetTwo">-</span> {data.data1}{" "}
              </p>
            </Col>
            <Col sm={6} md={3} lg={3} xl={3}>
              <p style={text_header}> {data.headerDetail2} </p>
            </Col>
            <Col sm={6} md={3} lg={3} xl={3}>
              <p style={{ fontSize: "15px" }}>
                {" "}
                <span className="tet">
                  :
                </span> <span className="tetTwo">-</span> {data.data2}{" "}
              </p>
            </Col>
          </Row>
        )
      })}
    </div>
  )
}
