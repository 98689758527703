import React, { useEffect, useState } from "react"
const liff = window.liff
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import { withRouter, Link, useHistory } from "react-router-dom"
import { Form, Button, notification, Modal } from "antd"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import logo_distar from "../../assets/Distar/logo_login.jpeg"
import axios from "axios"
import * as api from "../../service/Api_Login"
import { useTranslation } from "react-i18next"

export default function Login_Line_Liff(props) {
  const [error_res, setError_res] = useState(null)
  const [success_res, setSuccess_res] = useState(null)
  const history = useHistory()
  const { t } = useTranslation()

  const [test_data, setTest_data] = useState({
    displayName: null,
    pictureUrl: null,
    statusMessage: null,
    userId: null,
  })

  useEffect(() => {
    return () => {
      setError_res(null)
      setSuccess_res(null)
      setTest_data({
        displayName: null,
        pictureUrl: null,
        statusMessage: null,
        userId: null,
      })
    }
  }, [])

  const openNotificationWithIcon = (type, description) => {
    notification[type]({
      message: description,
    })
  }

  useEffect(async () => {
    await liff
      .init({ liffId: process.env.REACT_APP_LINE_LIFF_ID })
      .catch(err => {
        throw err
      })
    if (liff.isLoggedIn()) {
      let getProfile = await liff.getProfile()
      setTest_data({
        ...test_data,
        displayName: getProfile.displayName,
        pictureUrl: getProfile.pictureUrl,
        statusMessage: getProfile.statusMessage,
        userId: getProfile.userId,
      })
    } else {
      liff.login()
    }
    return () => {}
  }, [])

  const handleValidSubmit = (event, values) => {
    const data = {
      ...values,
      lineuid: test_data.userId,
    }
    // const data = {
    //     ...values,
    //     lineuid : "Uc37b456d76ab5e33ef74f245516d1a13"
    // }
    const login_line = api.LOGIN_LINE(data)
    axios(login_line)
      .then(res => {
        setError_res(null)
        setSuccess_res("Login line success")
        openNotificationWithIcon("success", "Login line success")
        history.push("/")
      })
      .catch(error => {
        // console.log(error.response);
        if (error) {
          if (error.response) {
            setError_res(error.response.data.msg)
          }
        }
      })
  }

  return (
    <div>
      <MetaTags>
        <title>Login | Distar</title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft">
                  <Row>
                    <img
                      src={logo_distar}
                      alt="logo"
                      style={{
                        width: "80%",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: "50px",
                        marginTop: "100px",
                      }}
                    />
                  </Row>
                </div>
                {success_res === null && (
                  <CardBody className="pt-0">
                    <div className="p-2">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={(e, v) => {
                          handleValidSubmit(e, v)
                        }}
                      >
                        {props.error && typeof props.error === "string" ? (
                          <Alert color="danger">{props.error}</Alert>
                        ) : null}

                        <div className="mb-3">
                          <AvField
                            name="email"
                            label={t("email")}
                            className="form-control"
                            placeholder={t("input email")}
                            type="email"
                            required
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="password"
                            label={t("password")}
                            type="password"
                            required
                            placeholder={t("input password")}
                          />
                        </div>
                        <br />
                        {error_res && (
                          <p
                            style={{
                              color: "red",
                              textAlign: "center",
                              fontSize: "15px",
                            }}
                          >
                            {error_res}
                          </p>
                        )}

                        <div className="mt-3 d-grid">
                          <button
                            style={{
                              backgroundColor: "#0084d1",
                              color: "#FFF",
                            }}
                            className="btn btn-block waves-effect waves-light"
                            type="submit"
                          >
                            {t("log in")}
                          </button>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                )}
                {success_res !== null && (
                  <p
                    style={{
                      fontSize: "20px",
                      textAlign: "center",
                      color: "#07b53b",
                    }}
                  >
                    Login line success
                  </p>
                )}
              </Card>
              <div className="mt-5 text-center">
                <p>Powered by Nok Soft Co .,Ltd .</p>
                {/*  <button  onClick={() => liff.logout()}>debug Logout</button>  */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
    /*<div>
        <button  onClick={() => liff.logout()}>Logout</button>
        <button  onClick={() => console.log(test_data)}>test_data</button>
        <p>Welcome to the react-liff demo app, {displayName}!</p>
        <p>displayName, {test_data.displayName}!</p>
        <p>pictureUrl, {test_data.pictureUrl}!</p>
        <p>statusMessage, {test_data.statusMessage}!</p>
        <p>userId, {test_data.userId}!</p>

        <hr />


        </div>*/
  )
}
