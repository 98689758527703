import React from "react"
import { Row, Col } from "reactstrap"
import { Button, Input, Form, Space } from "antd"
import { useHistory } from "react-router-dom"
import { RiNewspaperLine } from "react-icons/ri"
import { useTranslation } from "react-i18next";

export default function Search_device(props) {
    const history = useHistory()
    const { t } = useTranslation();

    const onFinish = values => {
      props.search_data()
    }

    return (
        <>
        <Form
          name="Search_contract_data_table"
          initialValues={{
            remember: false,
          }}
          onFinish={onFinish}
        >
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <Form.Item
                name="search"
                rules={[
                  {
                    required: false,
                    message: "Please input name,department",
                  },
                ]}
              >
              <Input
                  placeholder={`${t("Product")} , ${t("Asset")} , ${t("Serial")}`}
                  onChange={e => props.setData_search_device(e.target.value)}
              /> 
              </Form.Item>
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
              <Button
                type="primary"
                className="button-search-employee"
                htmlType="submit"
              >
                {t("Search")}
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    )
}
