import React from 'react'
import { Row, Col } from "reactstrap"
import { Button, Input } from "antd"
import MUIDataTable from "mui-datatables";
import "./Profile_New_Employee.css"

export default function Use_AD_Server(props) {
    const columns = [
        {
         name: "firstname",
         label: "Firstname",
         options: {
          filter: true,
          sort: false,
         }
        },
        {
         name: "lastname",
         label: "Lastname",
         options: {
          filter: true,
          sort: false,
         }
        },
        {
         name: "username",
         label: "Username",
         options: {
          filter: true,
          sort: false,
         }
        },
        {
          name: "department",
          label: "Department",
          options: {
           filter: true,
           sort: false,
          }
         },
        {
          name: '',
          options: {
              filter: true,
              customBodyRender: (value) => {
                  return (
                    <Button 
                      type="primary" className="button-and" 
                      onClick={() => props.setVisible(false)}
                    >
                      Select 
                    </Button>
                  )
              },
          },
      },
       ];
    
       const data = [
         [ "Joe", "James", "@mdo", "Account" , 1  ],
         [ "Mark", "Otto", "@Otto", "Account" , 2  ]
       ];
       
       const options = {
        filterType: 'dropdown',
        // responsive: 'stacked',
        print: false,
        filter: false,
        selectableRows: false,
        download: false,
        viewColumns: false,
        search: false,
        elevation: 0,
        rowsPerPageOptions: [],
    };
    return (
    <div style={{ marginTop:'20px' }}>
        <Row>
            <Col sm={12} md={8} lg={8} xl={8}>
                <Input
                placeholder="Firstname AD"
                style={{ marginBottom: "15px" }}
               
                />
            </Col>
            <Col sm={12} md={4} lg={4} xl={4}>
                <Button
                type="primary"
                className="button-and"
                >
                Search
                </Button>
            </Col>
        </Row>

        <Row>
            <Col>
                <MUIDataTable
                    data={data}
                    columns={columns}
                    options={options}
                />
            </Col>
        </Row>
    </div>
    )
}
