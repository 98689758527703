import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"
import { Avatar, Button } from "antd"

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg"
import avatar4 from "../../../assets/images/users/avatar-4.jpg"
import * as api from "../../../service/Api_Profile_Menu"
import axios from "axios"
//i18n
import { useTranslation } from "react-i18next"

import {
  UserOutlined,
  SolutionOutlined,
  LoadingOutlined,
  SmileOutlined,
} from "@ant-design/icons"
import { BiTaskX, BiTask } from "react-icons/bi"
import { RiTimeLine } from "react-icons/ri"
import { GrCompliance } from "react-icons/gr"
import { VscLoading } from "react-icons/vsc"
import { BsFileEarmarkCheck } from "react-icons/bs"
import { AiOutlineCloseCircle } from "react-icons/ai"

export default function NotificationDropdown(props) {
  const { t } = useTranslation()
  const [menu, setMenu] = useState(false)
  const token = localStorage.getItem("token")
  const [list_noti, setList_noti] = useState([])

  const style_icons = { marginTop: "-5px", marginLeft: "1px" }
  useEffect(() => {
    if (menu === true) {
      check_noti_limit()
      clear_count()
    }
    return () => {}
  }, [menu])

  useEffect(() => {
    checkOldCount()
  
    return () => {
      
    }
  }, [])
  

  function clear_count() {
    const clear_count = api.CLEAR_COUNT(token)
    axios(clear_count)
      .then(res => {
        props.setCount_notification(0)
      })
      .catch(error => {
        // console.log(error.response);
      })
  }

  function check_noti_limit() {
    const noti_limit = api.LIST_NOTI_LIMIT(token)
    axios(noti_limit)
      .then(res => {
        setList_noti(res.data.data)
      })
      .catch(error => {
        // console.log(error.response);
      })
  }

  function checkOldCount() {
    const check = api.CHECK_OLD_NOTIFICATION(token)
    axios(check)
    .then(res => {
      props.setCount_notification(res.data.data);
    })
    .catch(error => {
      // console.log(error.response);
    })
  }

  function render_status_icon(status) {
    switch (status) {
      case "unassigned":
        return (
          <Avatar
            style={{
              backgroundColor: "#d9d9d9",
            }}
            size="large"
          >
            <BiTaskX size={25} style={style_icons} />
          </Avatar>
        )
        break
      case "assigned":
        return (
          <Avatar
            style={{
              backgroundColor: "rgb(220, 194, 0)",
            }}
            size="large"
          >
            <BiTask size={25} style={style_icons} />
          </Avatar>
        )
        break
      case "inprogress":
        return (
          <Avatar
            style={{
              backgroundColor: "#91d5ff",
            }}
            size="large"
          >
            <VscLoading size={25} style={style_icons} />
          </Avatar>
        )
        break
      case "pending":
        return (
          <Avatar
            style={{
              backgroundColor: "rgb(234, 159, 230)",
            }}
            size="large"
          >
            <RiTimeLine size={25} style={style_icons} />
          </Avatar>
        )
        break
      case "cancel":
        return (
          <Avatar
            style={{
              backgroundColor: "#ffa39e",
            }}
            size="large"
          >
            <AiOutlineCloseCircle size={25} style={style_icons} />
          </Avatar>
        )
        break
      case "waiting for admin approve":
        return (
          <Avatar
            style={{
              backgroundColor: "rgb(234, 159, 230)",
            }}
            size="large"
          >
            <RiTimeLine size={25} style={style_icons} />
          </Avatar>
        )
        break
      case "waiting for manager approve":
        return (
          <Avatar
            style={{
              backgroundColor: "rgb(234, 159, 230)",
            }}
            size="large"
          >
            <RiTimeLine size={25} style={style_icons} />
          </Avatar>
        )
        break
      case "complete":
        return (
          <Avatar
            style={{
              backgroundColor: "#b7eb8f",
            }}
            size="large"
          >
            <BsFileEarmarkCheck size={25} style={style_icons} />
          </Avatar>
        )
        break
      case "resolved":
        return (
          <Avatar
            style={{
              backgroundColor: "#b7eb8f",
            }}
            size="large"
          >
            <BsFileEarmarkCheck size={25} style={style_icons} />
          </Avatar>
        )
        break
      default:
        return (
          <Avatar
            style={{
              backgroundColor: "#ffd591",
            }}
            size="large"
          >
            <RiTimeLine size={25} style={style_icons} />
          </Avatar>
        )
        break
    }
  }

  return (
    <div>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell" />
          {props.count_notification !== 0 && (
            <span className="badge bg-danger rounded-pill">
              {props.count_notification}
            </span>
          )}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-right">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {t("notifications")} </h6>
              </Col>
              <div className="col-auto"></div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {list_noti.map((data, index) => {
              return (
                <Link
                  to={`/service/${parseInt(data.noti_link_module_id)}`}
                  className="text-reset notification-item"
                  key={index}
                  onClick={() => setMenu(!menu)}
                >
                  <div className="media">
                    <div className="avatar-xs me-3">
                      {render_status_icon(data.noti_type)}
                    </div>
                    <div className="media-body">
                      <h6 className="mt-0 mb-1">{data.noti_link_module}</h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">{data.noti_detail}</p>
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline" />{" "}
                          {data.time_ago}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              )
            })}
          </SimpleBar>

          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="/notification"
              onClick={() => setMenu(!menu)}
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
              <span style={{ color: "#0084d1" }}> {t("view all")}</span>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}
