import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import Status_Service from "./components/Status_Service"
import Customer from "./Customer"
import Service_Info from "./Service_Info"
import Show_Gps_Car from "./Show_Gps_Car"
import Console_Service from "./Console_Service"
import Activity_Log from "./Activity_Log"
import Control_Service from "./Control_Service"
import { Button, Modal, Form, notification, Dropdown, Menu } from "antd"
import Input_Comp from "./components/Input_Comp"
import { ExclamationCircleOutlined, EllipsisOutlined } from "@ant-design/icons"
import axios from "axios"
import * as api from "../../../../service/service_operator/Api_Service"
import "./components/style_detail_service.css"
import Service_Status_Pending_Noti from "./Service_Status_Pending_Noti"
import Render_Task_After_Operator_Sending from "./Render_Task_After_Operator_Sending"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import helpers from "../../../Function_Redirect/Redirect_login"
import StatusCustomerService from "../../../Edit_Service/Detail_Service/StatusCustomerService"
import Show_Location_Service from "../../../Edit_Service/Detail_Service/Show_Location_Service"
import Detail_SLA from '../../../Edit_Service/Detail_Service/Detail_SLA'
import Car_Information from '../../../Status_Service_Public/Car_Information'

import RenderFileUpload from '../../../Edit_Service/Detail_Service/RenderFileUpload'

const { confirm } = Modal

export default function Detail_Service(props) {
  const { t } = useTranslation()
  const history = useHistory()
  const token = localStorage.getItem("token")
  const [form] = Form.useForm()
  const [Form_cancel_after_assigned] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [
    visible_cancel_after_assigned,
    setVisible_cancel_after_assigned,
  ] = useState(false)
  const [loading_cancel_service, setLoading_cancel_service] = useState(false)
  const [loading, setLoading] = useState(false)
  const [Cancel_service, setCancel_service] = useState({
    data_cancel: null,
  })

  useEffect(() => {
    return () => {
      setLoading_cancel_service(false)
      setCancel_service({
        data_cancel: null,
      })
    }
  }, [])

  function accept_service() {
    return confirm({
      title: t("Confirm accept service"),
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: t("Confirm"),
      cancelText: t("no"),
      onOk() {
        setLoading(true)
        const change_status = api.CHANGE_STATUS_ACCEPT_SERVICE_HAS_DATA(
          token,
          props.ser_id,
          "inprogress",
          "accept service job"
        )
        axios(change_status)
          .then(res => {
            setLoading(false)
            setLoading_cancel_service(false)
            openNotificationWithIcon("success", t("Accept service success"))
            props.check_activity_log()
            props.check_new_data_edit()
            props.setActiveTab("2")
          })
          .catch(error => {
            setLoading(false)
            setLoading_cancel_service(false)
            if (error) {
              if (error.response) {
                if (error.response.status === 401) {
                  helpers.re_login()
                  history.push("/login")
                } else {
                  openNotificationWithIcon("error", t("Accept service error"))
                }
              }
            }
          })
      },
      onCancel() {},
    })
  }

  const onFinish = values => {
    return confirm({
      title: t("Confirm cancel service"),
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: t("Confirm"),
      cancelText: t("no"),
      onOk() {
        setLoading_cancel_service(true)
        if (Cancel_service.data_cancel === null) {
          const change_status = api.CHANGE_STATUS_SERVICE(
            token,
            props.ser_id,
            "reject"
          )
          axios(change_status)
            .then(res => {
              setLoading_cancel_service(false)
              openNotificationWithIcon("success", t("Reject service success"))
              props.check_activity_log()
              props.check_new_data_edit()
              cancel_form()
            })
            .catch(error => {
              setLoading_cancel_service(false)
              if (error) {
                if (error.response) {
                  if (error.response.status === 401) {
                    helpers.re_login()
                    history.push("/login")
                  } else {
                    openNotificationWithIcon("error", t("Reject service error"))
                  }
                }
              }
            })
        } else {
          const change_status_data = api.CHANGE_STATUS_SERVICE_HAS_DATA(
            token,
            props.ser_id,
            "reject",
            Cancel_service.data_cancel
          )
          axios(change_status_data)
            .then(res => {
              setLoading_cancel_service(false)
              openNotificationWithIcon("success", t("Reject service success"))
              props.check_activity_log()
              props.check_new_data_edit()
              cancel_form()
            })
            .catch(error => {
              setLoading_cancel_service(false)
              if (error) {
                if (error.response) {
                  if (error.response.status === 401) {
                    helpers.re_login()
                    history.push("/login")
                  } else {
                    openNotificationWithIcon("error", t("Reject service error"))
                  }
                }
              }
            })
        }
      },
      onCancel() {
        cancel_form()
      },
    })
  }

  const onFinish_cancel_after_assigned = value => {
    return confirm({
      title: t("Confirm cancel service"),
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: t("Confirm"),
      cancelText: t("no"),
      onOk() {
        setLoading_cancel_service(true)
        if (Cancel_service.data_cancel === null) {
          const change_status = api.CHANGE_STATUS_SERVICE(
            token,
            props.ser_id,
            "reject"
          )
          axios(change_status)
            .then(res => {
              setLoading_cancel_service(false)
              openNotificationWithIcon("success", t("Reject service success"))
              props.check_activity_log()
              props.check_new_data_edit()
              cancel_form()
            })
            .catch(error => {
              setLoading_cancel_service(false)
              if (error) {
                if (error.response) {
                  if (error.response.status === 401) {
                    helpers.re_login()
                    history.push("/login")
                  } else {
                    openNotificationWithIcon("error", t("Reject service error"))
                  }
                }
              }
            })
        } else {
          const change_status_data = api.CHANGE_STATUS_SERVICE_HAS_DATA(
            token,
            props.ser_id,
            "reject",
            Cancel_service.data_cancel
          )
          axios(change_status_data)
            .then(res => {
              setLoading_cancel_service(false)
              openNotificationWithIcon("success", t("Reject service success"))
              props.check_activity_log()
              props.check_new_data_edit()
              cancel_form()
            })
            .catch(error => {
              setLoading_cancel_service(false)
              if (error) {
                if (error.response) {
                  if (error.response.status === 401) {
                    helpers.re_login()
                    history.push("/login")
                  } else {
                    openNotificationWithIcon("error", t("Reject service error"))
                  }
                }
              }
            })
        }
      },
      onCancel() {
        cancel_form()
      },
    })
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  const onReset = () => {
    form.resetFields()
    Form_cancel_after_assigned.resetFields()
  }

  function cancel_form() {
    onReset()
    setVisible(false)
    setVisible_cancel_after_assigned(false)
    setCancel_service({
      data_cancel: null,
    })
  }

  function handleMenuClick(e) {
    setVisible_cancel_after_assigned(true)
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item danger key="1">
        {t("Cancel service")}
      </Menu.Item>
    </Menu>
  )

  return (
    <div>
      <Row>
        <Col xs={0} sm={0} md={4} lg={4} xl={4}></Col>
        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
          {
            props.data_service !== null && (
              <p
              style={{
                fontWeight: "bold",
                fontSize: "30px",
                textAlign: "center",
              }}
            >
              {props.data_service.ser_no ? props.data_service.ser_no : "-"}{" "}
            </p>
            )
          }
  
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
          <Control_Service
            service_status_name={props.service_status_name}
            list_service_overview={props.list_service_overview}
            check_new_data_edit={props.check_new_data_edit}
            ser_id={props.ser_id}
          />
        </Col>
      </Row>

      {props.service_status_name === "pending" && (
        <Status_Service data_service={props.data_service} />
      )}

      {props.service_status_name === "pending" && (
        <Service_Status_Pending_Noti />
      )}

      {props.service_status_name === "complete" && (
        <Render_Task_After_Operator_Sending ser_id={props.ser_id} />
      )}

      <Show_Gps_Car
        data_service={props.data_service}
        data_location={props.data_location}
      />
      <br />
      {props.service_status_name === "inprogress" && (
        <div className="btn-work-assigned">
          <Button
            style={{
              marginRight: "-8px",
              width: "150px",
              backgroundColor: "#4BB543",
              color: "#FFF",
              borderColor: "#4BB543",
            }}
            onClick={() => props.setActiveTab("2")}
          >
            {t("Assigned tasks")}
          </Button>
          <Dropdown overlay={menu}>
            <Button>
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        </div>

        /*<Button
          className="btn-let-work"
          style={{
            color: "#FFF",
            backgroundColor: "#4BB543",
            borderColor: "#4BB543",
          }}
          onClick={() => props.setActiveTab("2")}
        >
          {" "}
          {t("Assigned tasks")}{" "}
        </Button>*/
      )}
      {props.service_status_name === "assigned" && (
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div className="div_create_new_car">
              <Button
                className="btn_cancel_create_new_car"
                type="primary"
                onClick={() => accept_service()}
                loading={loading}
              >
                {t("Accept")}
              </Button>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div className="div_create_new_car">
              <Button
                className="btn_submit_create_new_car"
                danger
                onClick={() => setVisible(true)}
              >
                {t("Cancel")}
              </Button>
            </div>
          </Col>
        </Row>
      )}
      <br />
      <StatusCustomerService data_service={props.data_service} />
      <br />
      <Show_Location_Service service_location={props.data_service} />
      <br />
      <Service_Info data_service={props.data_service} />
      <br />
      <Car_Information data_all={props.data_service} />
      <br />
      <Detail_SLA data_service={props.data_service} />
      <br />
      <Activity_Log activity_all={props.activity_all} />
      <br />
      { props.data_service !== null && props.data_service.ser_status_name === "unassigned" && (
        <Console_Service
          check_activity_log={props.check_activity_log}
          data_service={props.data_service}
          check_new_data_edit={props.check_new_data_edit}
          list_service_overview={props.list_service_overview}
        />
      )}
      <br />
      <Customer data_service={props.data_service} />
      <br />
        {
          props.fileUpload.length !== 0 && (
            <RenderFileUpload fileUpload={props.fileUpload} />
          )
        }

      <Modal
        centered
        open={visible}
        onCancel={() => {
          setVisible(false)
          onReset()
          cancel_form()
        }}
        footer={false}
      >
        <Form form={form} name="cancel-service" onFinish={onFinish}>
          <Row>
            <Input_Comp
              header={t("Remark cancel service")}
              placeholder={t("Remark cancel service")}
              headState={setCancel_service}
              nameState={Cancel_service}
              keyState="data_cancel"
              type="text"
              maxLength={null}
            />
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <div className="div_create_new_car">
                <Button
                  className="btn_cancel_create_new_car"
                  onClick={() => cancel_form()}
                >
                  {t("Cancel")}
                </Button>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
              <div className="div_create_new_car">
                <Button
                  className="btn_submit_create_new_car"
                  type="primary"
                  htmlType="submit"
                  loading={loading_cancel_service}
                >
                  {t("Submit")}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        centered
        open={visible_cancel_after_assigned}
        onCancel={() => {
          setVisible_cancel_after_assigned(false)
          onReset()
          cancel_form()
        }}
        footer={false}
      >
        <Form
          form={Form_cancel_after_assigned}
          name="cancel-service-after-assigned"
          onFinish={onFinish_cancel_after_assigned}
        >
          <Row>
            <Input_Comp
              header={t("Remark cancel service")}
              placeholder={t("Remark cancel service")}
              headState={setCancel_service}
              nameState={Cancel_service}
              keyState="data_cancel"
              type="text"
              maxLength={null}
            />
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <div className="div_create_new_car">
                <Button
                  className="btn_cancel_create_new_car"
                  onClick={() => cancel_form()}
                >
                  {t("Cancel")}
                </Button>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
              <div className="div_create_new_car">
                <Button
                  className="btn_submit_create_new_car"
                  type="primary"
                  htmlType="submit"
                  loading={loading_cancel_service}
                >
                  {t("Submit")}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  )
}
