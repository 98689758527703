import React,{ useState, useEffect } from "react"
import { Button, Form, notification, Modal } from "antd"
import { Row, Col } from "reactstrap"
import Input_Comp from './Components/Input_Comp'
import Input_Date_Comp from './Components/Input_Date_Comp'
import Select_no_search from './Components/Select_no_search'
import axios from 'axios'
import * as api from '../../../service/Api_Edit_Contract'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Input_Comp_Show_Only from './Components/Input_Comp_Show_Only'
import { useHistory } from "react-router-dom";
import helpers from '../../Function_Redirect/Redirect_login'
import { useTranslation } from "react-i18next";

const { confirm } = Modal;

export default function Edit_Contract(props) {
  const { t } = useTranslation();
    const token = localStorage.getItem('token')
    const [form] = Form.useForm();
    const history = useHistory()
    const [data_Edit, setData_Edit] = useState({})
    const [check_code_dup, setCheck_code_dup] = useState(false)
    const [edit, setEdit] = useState(true)

    useEffect(() => {
      function check_duplicate_contract() {
        if(data_Edit.con_no){
          const check_duplicate = api.CHECK_DUPLICATE_CONTRACT(token, data_Edit.con_no)
          axios(check_duplicate)
          .then( res => {
            if(res.data === 'True'){
              setCheck_code_dup(true)
             } else {
              setCheck_code_dup(false)
             }
          })
          .catch( error => {
            // console.log(error.response);
          })
        } else {
    
        }
      }

      check_duplicate_contract()
      return () => {
        setCheck_code_dup(false)
      }
    }, [data_Edit.con_no])

    useEffect(() => {
        onFill()

        return () => {
            setData_Edit({})
        }
    }, [props.detail_Contract])

  const onFill = () => {
    form.setFieldsValue({
        con_no : props.detail_Contract.con_no,
        con_name : props.detail_Contract.con_name,
        con_contact_position_id : props.detail_Contract.con_contact_position_id,
        con_contact_firstname : props.detail_Contract.con_contact_firstname,
        con_contact_lastname : props.detail_Contract.con_contact_lastname,
        con_contact_mobile : props.detail_Contract.con_contact_mobile,
        con_contact_email : props.detail_Contract.con_contact_email
    });
  };

  const onReset = () => {
    form.resetFields();
  };

  function cancel() {
    onReset()
    onFill()
    setEdit(!edit)
    setData_Edit({})
  }

  const onFinish = values => {
    let data_last_edit = {}
    for (const [key, value] of Object.entries(data_Edit)) {
      if (!value) {
      } else {
        data_last_edit[key] = value
      }
    }
    if (Object.keys(data_last_edit)[0] === undefined) {
        openNotificationWithIcon("info", t("No data is change"))
      } else {
        confirm({
            title: t("Confirm edit contract"),
            icon: <ExclamationCircleOutlined style={{ color:"#1890ff" }} />,
            okText : t("Confirm"),
            cancelText: t("no"),
            centered:true,
            onOk() {
                let last_data ={
                    cus_id : props.cus_id ,
                    ...data_last_edit
                }
                const edit = api.EDIT_CONTRACT(token, last_data , props.detail_Contract.con_id )
                axios(edit)
                .then( res => {
                    openNotificationWithIcon("success", t("Edit success"))
                    cancel()
                    check_contract_detail()
                })
                .catch( error =>{
                  if(error.response.status === 401){
                    helpers.re_login()
                    history.push('/login')          
          }
          
                })
            },
            onCancel() {
        
            },
          });
          
      }
  }

  function check_contract_detail() {
      const check = api.CHECK_CONTRACT(token, props.detail_Contract.con_id)
      axios(check)
      .then( res => {
            props.setDetail_Contract(res.data.data[0])
    })
    .catch( error =>{

        if(error){
          if(error.response.status === 401){
            helpers.re_login()
            history.push('/login')          
            }
        }
    })
  }

  const openNotificationWithIcon = (type, description) => {
    notification[type]({
      message: description,
    })
  }

  return (
    <Form
      name="Edit_data_contract_detail_info"
      initialValues={{
        remember: true,
      }}
      form={form} 
      onFinish={onFinish}
    >
    <Row>
    <Col>
      <div style={{ display: "inherit" }}>
        <p
          className="text-bold"
          style={{
            color: "#495057",
            textAlign: "center",
            fontSize: "15px",
          }}
        >
          {t("Contract information")}
        </p>
        {edit === true && (
          <Button
            style={{ float: "right", width: "150px" }}
            onClick={() => setEdit(!edit)}
          >
            {t("Edit")}
          </Button>
        )}
        {edit === false && (
          <Button
            type="primary"
            style={{ float: "right", width: "150px" }}
            onClick={() => cancel()}
          >
            {t("Cancel")}
          </Button>
        )}
      </div>
    </Col>
  </Row>
  <br />

    <Row>
    <Col sm={12} md={6} lg={6} xl={6}>
        <Input_Comp_Show_Only 
            header={t("Contract number")} 
            placeholder={props.detail_Contract.con_no}
            headState={setData_Edit}
            nameState={data_Edit}
            type="text"
            keyState="con_no"
            disabled={true}
        />
        {
          check_code_dup && (
              <p style={{ color:'red' }}> {t("Contract number in duplicate")} </p>
          )
      }
    </Col>
    <Col sm={12} md={6} lg={6} xl={6}>
        <Input_Comp 
            header={t("Contract name")} 
            placeholder={props.detail_Contract.con_name}
            headState={setData_Edit}
            nameState={data_Edit}
            type="text"
            keyState="con_name"
            disabled={edit}
        />
    </Col>
    </Row>

    <Row>
        <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Date_Comp 
                header={t("Date start")}
                keyState='con_start_date'
                placeholder={props.detail_Contract.con_start_date}
                headState={setData_Edit}
                nameState={data_Edit}
                disabled={edit}
            />
        </Col>
        <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Date_Comp 
                header={t("Date end")}
                keyState='con_end_date'
                placeholder={props.detail_Contract.con_end_date}
                headState={setData_Edit}
                nameState={data_Edit}
                disabled={edit}
            />
        </Col>
    </Row>

    <Row>
        <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search 
                header={t("Contract position")}
                placeholder={t("Contract position")}
                name_api='LIST_POSITION'
                map_name="pos_name"
                map_value="pos_id"
                keyState="con_contact_position_id"
                headState={setData_Edit}
                nameState={data_Edit}
                disabled={edit}
            />
        </Col>
    </Row>

    <Row>
    <Col sm={12} md={6} lg={6} xl={6}>
        <Input_Comp 
            header={t("Contract firstname")} 
            placeholder={props.detail_Contract.con_contact_firstname}
            headState={setData_Edit}
            nameState={data_Edit}
            type="text"
            keyState="con_contact_firstname"
            disabled={edit}
        />
    </Col>
    <Col sm={12} md={6} lg={6} xl={6}>
        <Input_Comp 
            header={t("Contract lastname")} 
            placeholder={props.detail_Contract.con_contact_lastname}
            headState={setData_Edit}
            nameState={data_Edit}
            type="text"
            keyState="con_contact_lastname"
            disabled={edit}
        />
    </Col>
    </Row>

    <Row>
    <Col sm={12} md={6} lg={6} xl={6}>
        <Input_Comp 
            header={t("Contract mobile")} 
            placeholder={props.detail_Contract.con_contact_mobile}
            headState={setData_Edit}
            nameState={data_Edit}
            type="text"
            keyState="con_contact_mobile"
            disabled={edit}
        />
    </Col>
    <Col sm={12} md={6} lg={6} xl={6}>
        <Input_Comp 
            header={t("Contract email")} 
            placeholder={props.detail_Contract.con_contact_email}
            headState={setData_Edit}
            nameState={data_Edit}
            type="text"
            keyState="con_contact_email"
            disabled={edit}
        />
    </Col>
    </Row>


<br />
{
    edit === false && (
        <Row> 
        <Col sm={12} md={6} lg={6} xl={6}>
            <div className="div_create_new_car">
                <Button className="btn_cancel_create_new_car" onClick={() => cancel()} > {t("Cancel")} </Button>   
            </div>
        </Col>
        <Col sm={12} md={6} lg={6} xl={6}>
            <div className="div_create_new_car">
                <Button className="btn_submit_create_new_car" type="primary" htmlType="submit" disabled={check_code_dup} > {t("Edit")} </Button>
            </div>
        </Col>
        </Row>
    )
}
    </Form>
  )
}
