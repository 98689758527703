import React, { useState, useEffect } from "react"
import { Select, Form } from "antd"
import axios from "axios"
import * as api from "../../../service/Api_Edit_Sub_Product_In_Car"
import { useTranslation } from "react-i18next";

const { Option } = Select

export default function Find_Model_InGroup(props) {
  const token = localStorage.getItem("token")
  const { t } = useTranslation();
  const [data_product_model, setData_product_model] = useState([])

  useEffect(() => {
    if (props.group_id !== null) {
      find_product_model()
    }
    return () => {}
  }, [props.group_id])

  useEffect(() => {
    if(props.edit_sub_product_data){
      if(props.edit_sub_product_data.pro_group_id){
        default_product_model()
      }
    }
    return () => {
      
    }
  }, [props.edit_sub_product_data])

  function default_product_model() {
    const find_data = api.FIND_PRODUCT_MODEL(token, props.edit_sub_product_data.pro_group_id)
    axios(find_data)
      .then(res => {
        setData_product_model(res.data.data)
      })
      .catch(error => {
        console.log(error.response)
      })
  }

  function find_product_model() {
    const find_data = api.FIND_PRODUCT_MODEL(token, props.group_id.group_id)
    axios(find_data)
      .then(res => {
        setData_product_model(res.data.data)
      })
      .catch(error => {
        console.log(error.response)
      })
  }

  useEffect(() => {
    render_select_data()
    return () => {

    }
  }, [data_product_model])

  function render_select_data() {
    return data_product_model.map(data => {
      return (
        <Option key={data.pro_model_id} value={data.pro_model_id}>
          {data.pro_model_name}
        </Option>
      )
    })
  }

  function onChange(value) {
    if (value) {
      props.setEdit_sub_product({
        ...props.edit_sub_product,
        pro_model_id: value,
      })
    }
  }

  return (
    <div>
      <p className="text-bold">
        {t("Product model")} 
      </p>
      <Form.Item
        name="product_model"
        rules={[
          {
            required: false,
            message: `Please select product model`,
          },
        ]}
      >
        <Select
          showSearch
          placeholder={t("Product model")} 
          style={{ width: "100%" }}
          optionFilterProp="children"
          onChange={onChange}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {render_select_data()}
        </Select>
      </Form.Item>
    </div>
  )
}
