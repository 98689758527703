import React,{ useEffect } from "react"
import { Input } from "antd"

export default function Input_Confirm_Pass({
  header,
  placeholder,
  keyState,
  index_user,
  confirm_pass_all,
  setConfirm_pass_all,
}) {

  useEffect(() => {
    if(confirm_pass_all !== undefined){
      render_input()
    }
    return () => {
      
    }
  }, [confirm_pass_all])

  function render_input() {
    return (
      <Input.Password
      type="password"
      placeholder={placeholder}
      onChange={e => handle_field_password(e)}
      value={confirm_pass_all[index_user] ? confirm_pass_all[index_user][keyState] : null}
  />
    )
  }

  function handle_field_password(e) {
    const values = [...confirm_pass_all]
    values[index_user][keyState] = e.target.value
    setConfirm_pass_all(values)
  }

  return (
    <div>
      <p className="text-bold" style={{ marginTop: "20px" }}>
        {header} <span style={{ color: "red" }}>*</span>
      </p>
        {render_input()}
    </div>
  )
}
