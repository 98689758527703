import React, { useState, useEffect } from "react"
import { Container, Card, CardBody, Row, Col } from "reactstrap"
import { MdKeyboardBackspace } from "react-icons/md"
import { useHistory, useParams } from "react-router-dom"
import MetaTags from "react-meta-tags"
import { Tabs, Button, Modal, notification } from "antd"
import { FileAddOutlined } from "@ant-design/icons"
import FormDragAndDrop from "./generateForm/FormDragAndDrop"
import RenderForm from "./renderForm"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import axios from "axios"
import RenderForm_hast_form from "./renderForm_hast_form"

import * as api from "../../service/Api_Edit_Task_System_Setting"
import helpers from "../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const { TabPane } = Tabs
const { confirm } = Modal

export default function Edit_Task_System_Setting(props) {
  const { t } = useTranslation()
  const history = useHistory()
  const token = localStorage.getItem("token")
  const [stepField, setStepField] = useState([])
  const [module, setModule] = useState([])
  const [priority, setPriority] = useState([])
  const [loadings, setLoadings] = useState(false)
  const [before_test, setBefore_test] = useState([])
  const [task_hast_form, setTask_hast_form] = useState([])
  const [saveSuccessTask, setSaveSuccessTask] = useState([])
  const [loadingEditTaskMapping, setLoadingEditTaskMapping] = useState(false)
  const [enableEditTask, setEnableEditTask] = useState(false)

  const { task_group_id } = useParams()
  const { permissionAllService } = useSelector(({ Distar }) => Distar)
  const [permi_t_edit, setPermi_t_edit] = useState(false)

  useEffect(() => {
    if (permissionAllService) {
      if (permissionAllService.system_setting) {
        setPermi_t_edit(permissionAllService.system_setting.edit)
      }
    }
    return () => {}
  }, [permissionAllService])

  useEffect(() => {
    list_module()
    list_priority()
    Check_service_form()
    return () => {
      setModule([])
      setPriority([])
      setStepField([])
      setBefore_test([])
      setTask_hast_form([])
    }
  }, [])

  function list_module() {
    const list_module_info = api.LIST_MODULE(token)
    axios(list_module_info)
      .then(res => {
        setModule(res.data.data)
      })
      .catch(error => {
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }
  function list_priority() {
    const list_priority_info = api.LIST_PRIORITY(token)
    axios(list_priority_info)
      .then(res => {
        setPriority(res.data.data)
      })
      .catch(error => {
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  function addStepField() {
    const values = [...stepField]
    values.push({
      fields: [],
    })
    setStepField(values)
  }

  function removeStep(index) {
    const values = [...stepField]
    values.splice(index, 1)
    setStepField(values)
  }

  function sendDataForm() {
    if (enableEditTask === false) {
      return confirm({
        title: t("Confirm create task"),
        icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
        okText: t("Confirm"),
        cancelText: t("no"),
        centered: true,
        onOk() {
          setLoadings(true)
          startLoop()
        },
        onCancel() {},
      })
    } else {
      return confirm({
        title: t("Confirm edit task"),
        icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
        okText: t("Confirm"),
        cancelText: t("no"),
        centered: true,
        onOk() {
          setLoadings(true)
          editTaskLoop()
        },
        onCancel() {},
      })
    }
  }

  // start

  async function startLoop() {
    let step_page = 1
    const promises = stepField.map(async data => {
      if (data.fields.length !== 0) {
        data.fields.map(async (data_instep, index) => {
          let data_form = {
            task_method_id: 1,
            task_type_id: 1,
            task_status_id: 1,
            task_stepper: step_page,
            task_type_module_id: data_instep.task_type_module_id,
            task_priority_id: data_instep.task_priority_id,
            task_detail: data_instep.task_detail,
            task_name: data_instep.task_name,
            task_global_index: index,
          }
          await send_task(data_form)
        })
      }
      step_page++
    })
    await Promise.all(promises)
    setTimeout(() => {
      setStepField([])
      setLoadings(false)
      Check_service_form()
    }, 2000)
  }

  async function editTaskLoop() {
    let step_page = 1
    const promisesRemoveAll = saveSuccessTask.map(removeStep => {
      removeStep.fields.map(async dataTaskRemove => {
        await removeOneTask(dataTaskRemove.task_id)
      })
    })
    await Promise.all(promisesRemoveAll)
    const promisesCreate = stepField.map(async data => {
      if (data.fields.length !== 0) {
        data.fields.map(async (data_instep, index) => {
          let data_form = {
            task_method_id: 1,
            task_type_id: 1,
            task_status_id: 1,
            task_stepper: step_page,
            task_type_module_id: data_instep.task_type_module_id,
            task_priority_id: data_instep.task_priority_id,
            task_detail: data_instep.task_detail,
            task_name: data_instep.task_name,
            task_global_index: index,
          }
          await send_task(data_form)
        })
      }
      step_page++
    })
    await Promise.all(promisesCreate)
    setTimeout(() => {
      setStepField([])
      setLoadings(false)
      setEnableEditTask(false)
      Check_service_form()
    }, 2000)
  }

  async function removeOneTask(task_id) {
    const removeOne = api.REMOVE_ONE_TASK(token, task_id)
    await axios(removeOne)
      .then(res => {
        return res.data
      })
      .catch(error => {
        return error
      })
  }

  //end

  const send_task = async data_task => {
    const create_task = api.INSERT_TASK(
      token,
      parseInt(task_group_id),
      data_task
    )
    const fetcher = await axios(create_task)
    if (fetcher.status === 201) {
      return fetcher
    } else if (fetcher.status === 401) {
      helpers.re_login()
      history.push("/login")
      return fetcher
    } else {
      openNotificationWithIcon("error", t("Insert task error"))
      return fetcher
    }
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  function compareFirst(a, b) {
    if (a.task_global_index > b.task_global_index) {
      return 1
    }
    if (a.task_global_index < b.task_global_index) {
      return -1
    }
    return 0
  }

  function Check_service_form() {
    const create_task = api.CHECK_SERVICE_HAST_TASK(
      token,
      parseInt(task_group_id)
    )
    axios(create_task)
      .then(res => {
        const sortDetailForm = res.data.data.sort(compareFirst)
        setBefore_test(sortDetailForm)
      })
      .catch(error => {
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  useEffect(() => {
    mapping_data_array()

    return () => {}
  }, [before_test])

  function mapping_data_array() {
    let stepField = []
    let number_page = 0
    before_test.map(data => {
      if (number_page <= data.task_stepper - 1) {
        stepField.push({
          fields: [],
        })
        number_page++
      }
    })
    before_test.map(data => {
      stepField[data.task_stepper - 1].fields.push(data)
    })
    setTask_hast_form(stepField)
    setSaveSuccessTask(stepField)
  }

  function checkTypeTask(type) {
    switch (type) {
      case "inputField":
        return "text"
        break
      case "imageField":
        return "photo"
        break
      case "signatureField":
        return "signature"
        break
      case "checkinField":
        return "checkIn"
        break
      case "checkoutField":
        return "checkOut"
        break
      case "uploadFileField":
        return "uploadFile"
        break
      default:
        break
    }
  }

  function checkHeaderTask(type) {
    switch (type) {
      case "inputField":
        return t("Text")
        break
      case "imageField":
        return t("Photo")
        break
      case "signatureField":
        return t("Signature")
        break
      case "checkinField":
        return t("Check in")
        break
      case "checkoutField":
        return t("Check out")
        break
      case "uploadFileField":
        return t("upload file")
        break
      default:
        break
    }
  }

  function editTask() {
    setLoadingEditTaskMapping(true)
    let values = []
    saveSuccessTask.map((data, index) => {
      values[index] = {
        fields: [],
      }
    })

    values.map((data, index) => {
      saveSuccessTask[index].fields.map((dataTask, index) => {
        data.fields.push({
          task_detail: dataTask.task_detail,
          task_name: dataTask.task_name,
          task_priority_id: dataTask.task_priority_id,
          task_type_module_id: dataTask.task_type_module_id,
          text_header: checkHeaderTask(dataTask.task_type_module_name),
          type: checkTypeTask(dataTask.task_type_module_name),
        })
      })
    })
    setStepField(values)
    setTask_hast_form([])
    setLoadingEditTaskMapping(false)
    setEnableEditTask(true)
  }

  function cancelEditTaskALl() {
    setEnableEditTask(false)
    mapping_data_array()
  }
 
  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Manage task")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/system-setting/task")}
              />
            </a>{" "}
            {t("Manage task")}
          </h3>

          <Card>
            <CardBody>
              <div>
                {enableEditTask === true && (
                  <Button
                    style={{ float: "right" }}
                    onClick={() => cancelEditTaskALl()}
                  >
                    {" "}
                    {t("Cancel edit task")}{" "}
                  </Button>
                )}
                {task_hast_form.length === 0 && (
                  <Row>
                    <Col sm={12} xs={12} md={12} lg={6}>
                      {permi_t_edit === true && (
                        <Button
                          type="primary"
                          icon={<FileAddOutlined />}
                          onClick={() => addStepField()}
                        >
                          {t("Add step form")}
                        </Button>
                      )}

                      {permi_t_edit === false && (
                        <div>
                          <p
                            style={{
                              color: "red",
                              textAlign: "center",
                              fontSize: "20px",
                            }}
                          >
                            {t("no permission edit module")}
                          </p>
                          <p
                            style={{
                              color: "red",
                              textAlign: "center",
                              fontSize: "20px",
                            }}
                          >
                            {" "}
                            {t("please contract administrator")}{" "}
                          </p>
                        </div>
                      )}

                      <Tabs defaultActiveKey="0">
                        {stepField.map((step, index) => {
                          return (
                            <TabPane tab={`Step ${index + 1}`} key={index}>
                              <Button
                                danger
                                onClick={() => removeStep(index)}
                                style={{
                                  marginLeft: "auto",
                                  marginRight: "0px",
                                  display: "flex",
                                }}
                              >
                                {t("Remove step")} {index + 1}
                              </Button>
                              <hr />

                              <FormDragAndDrop
                                priority={priority}
                                module={module}
                                setStepField={setStepField}
                                stepField={stepField}
                                index={index}
                              />
                            </TabPane>
                          )
                        })}
                      </Tabs>
                      {stepField.length !== 0 && enableEditTask === false && (
                        <Button
                          type="primary"
                          onClick={() => sendDataForm()}
                          style={{ width: "200px" }}
                          loading={loadings}
                        >
                          {t("Create new form")}
                        </Button>
                      )}
                              {stepField.length !== 0 && enableEditTask === true && (
                        <Button
                          type="primary"
                          onClick={() => sendDataForm()}
                          style={{ width: "200px" }}
                          loading={loadings}
                        >
                          {t("Confirm edit")}
                        </Button>
                      )}
                    </Col>

                    <Col sm={12} xs={12} md={12} lg={6}>
                      {stepField.length !== 0 && (
                        <p style={{ fontWeight: "bold" }}>
                          {" "}
                          {t("Preview form")}{" "}
                        </p>
                      )}
                      <RenderForm priority={priority} stepField={stepField} />
                    </Col>
                  </Row>
                )}

                {task_hast_form.length !== 0 && (
                  <>
                    <Row>
                      <Col>
                        {permi_t_edit === true && (
                          <Button
                            loading={loadingEditTaskMapping}
                            style={{ float: "right" }}
                            onClick={() => editTask()}
                          >
                            {" "}
                            {t("Edit task")}{" "}
                          </Button>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {stepField.length !== 0 && (
                          <p style={{ fontWeight: "bold" }}>
                            {" "}
                            {t("Preview form")}{" "}
                          </p>
                        )}
                        <RenderForm_hast_form
                          priority={priority}
                          stepField={task_hast_form}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
