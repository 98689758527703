import React, { useState, useEffect, useRef } from "react"
import { Row, Col, Container, Card, CardBody } from "reactstrap"
import MetaTags from "react-meta-tags"
import { useHistory } from "react-router-dom"
import axios from "axios"
import * as api from "../../service/Api_System_Settings"
import { FcSettings } from "react-icons/fc"
import { MdKeyboardBackspace } from "react-icons/md"
import {
  Table,
  Button,
  Modal,
  Tooltip,
  notification,
  Form,
  Avatar,
  Spin,
} from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { RiDeleteBin6Line } from "react-icons/ri"
import Input_Comp from "./Components/Input_Comp"
import Render_Image from "../New_Customer/Devices/Render_Image"
import Select_no_search from "./Components/Select_no_search"
import { FiBox } from "react-icons/fi"
import no_permi from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png"
import helpers from "../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const { confirm } = Modal

export default function Product_model(props) {
  const { t } = useTranslation()
  const history = useHistory()
  const imageUploader = useRef(null)
  const [form_create] = Form.useForm()
  const [form_edit] = Form.useForm()
  const token = localStorage.getItem("token")
  const [data_list, setData_list] = useState([])
  const [data_list_all_group, setData_list_all_group] = useState([])
  const [visible_edit, setVisible_edit] = useState(false)
  const [visible_create, setVisible_create] = useState(false)
  const [data_edit, setData_edit] = useState({})
  const [data_create, setData_create] = useState({})
  const [loading, setLoading] = useState(false)
  const [image_upload, setImage_upload] = useState(null)
  const [image_preview, setImage_preview] = useState(null)
  const [product_model_id, setProduct_model_id] = useState(null)
  const [loading_image, setLoading_image] = useState(false)
  const { permissionAllService } = useSelector(({ Distar }) => Distar)
  const permi = localStorage.getItem("permi")
  const [permi_t_read, setPermi_t_read] = useState(false)
  const [permi_t_write, setPermi_t_write] = useState(false)
  const [permi_t_edit, setPermi_t_edit] = useState(false)
  const [permi_t_delete, setPermi_t_delete] = useState(false)

  useEffect(() => {
    if (permissionAllService) {
      if (permissionAllService.system_setting) {
        setPermi_t_read(permissionAllService.system_setting.read)
        setPermi_t_write(permissionAllService.system_setting.write)
        setPermi_t_edit(permissionAllService.system_setting.edit)
        setPermi_t_delete(permissionAllService.system_setting.delete)
      }
    }
    return () => {}
  }, [permissionAllService])

  useEffect(() => {
    check_data()
    check_data_group()
    return () => {
      setData_list([])
      setData_list_all_group([])
      setVisible_edit(false)
      setVisible_create(false)
      setData_edit({})
      setData_create({})
      setLoading(false)
      setImage_upload(null)
    }
  }, [])

  function check_data_group() {
    const check_data_all = api.FIND_ALL_GROUP(token)
    axios(check_data_all)
      .then(res => {
        setData_list_all_group(res.data.data)
      })
      .catch(error => {
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  function check_data() {
    setLoading(true)
    const check_data_all = api.FIND_ALL_MODEL(token)
    axios(check_data_all)
      .then(res => {
        setData_list(res.data.data)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  const columns = [
    {
      title: t("Product group name"),
      dataIndex: "pro_group_name",
      key: "pro_group_name",
      render: text => <p>{text}</p>,
    },
    {
      title: "",
      dataIndex: "pro_model_img",
      key: "pro_model_img",
      render: text => <Render_Image path_image={text} size={60} />,
    },
    {
      title: t("Product model name"),
      dataIndex: "pro_model_name",
      key: "pro_model_name",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Product model code"),
      dataIndex: "pro_model_code",
      key: "pro_model_code",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Product model factory"),
      dataIndex: "pro_model_factory",
      key: "pro_model_factory",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Product model protocol"),
      dataIndex: "pro_model_protocol",
      key: "pro_model_protocol",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Product model remark"),
      dataIndex: "pro_model_remark",
      key: "pro_model_remark",
      render: text => <p>{text}</p>,
    },
    {
      title: "",
      dataIndex: "pro_model_id",
      key: "pro_model_id",
      render: text => (
        <Tooltip title={t("Product model name")}>
          <a>
            <RiDeleteBin6Line
              style={{ color: "red" }}
              size={20}
              onClick={() => remove_data(text)}
            />
          </a>
        </Tooltip>
      ),
    },
    {
      title: "",
      render: text => (
        <Tooltip title={t("Edit product model name")}>
          <a>
            <FcSettings
              size={20}
              onClick={() => {
                setProduct_model_id(text.pro_model_id)
                setVisible_edit(true)
                get_image(text.pro_model_img)
                setData_edit({
                  pro_model_name: text.pro_model_name,
                  pro_group_id: text.pro_group_id,
                  pro_model_code: text.pro_model_code,
                  pro_model_factory: text.pro_model_factory,
                  pro_model_protocol: text.pro_model_protocol,
                  pro_model_remark: text.pro_model_remark,
                })
                form_edit.setFieldsValue({
                  pro_model_name: text.pro_model_name,
                  pro_group_id: text.pro_group_id,
                  pro_model_code: text.pro_model_code,
                  pro_model_factory: text.pro_model_factory,
                  pro_model_protocol: text.pro_model_protocol,
                  pro_model_remark: text.pro_model_remark,
                })
              }}
            />
          </a>
        </Tooltip>
      ),
    },
  ]

  async function get_image(path_image) {
    setLoading_image(true)
    const get_data_image = api.GET_IMAGE(token, path_image)
    await axios(get_data_image)
      .then(res => {
        setLoading_image(false)
        const blob = new Blob([res.data], {
          type: "image/jpeg",
        })
        setImage_preview(blob)
      })
      .catch(error => {
        setLoading_image(false)
        // console.log(error.response);
      })
  }

  const handleImageUpload = e => {
    if (e.target.files[0] !== undefined) {
      if (e.target.files[0].type !== "image/jpeg") {
        openNotificationWithIcon(
          "error",
          t("please upload jpg or jpeg image only")
        )
      } else {
        let img_upload = e.target.files[0]
        setImage_upload(img_upload)
      }
    }
  }

  const openNotificationWithIcon = (type, description) => {
    notification[type]({
      message: description,
    })
  }

  const onFinish_edit = value => {
    return confirm({
      title: t("Confirm edit product model"),
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: t("Confirm"),
      cancelText: t("Cancel"),
      onOk() {
        setLoading(true)
        if (image_upload !== null) {
          const formData = new FormData()
          formData.append("file", image_upload)
          const upload_image = api.UPLOAD_IMAGE(token, formData)
          axios(upload_image)
            .then(res => {
              let add_image_path = {
                pro_model_img: res.data.path,
                ...data_edit,
              }
              edit_product_model(product_model_id, add_image_path)
            })
            .catch(error => {
              setLoading(false)
              if (error) {
                if (error.response) {
                  if (error.response.status === 401) {
                    helpers.re_login()
                    history.push("/login")
                  } else {
                    openNotificationWithIcon(
                      "error",
                      t("Edit product model error")
                    )
                  }
                }
              }
            })
        } else {
          edit_product_model(product_model_id, data_edit)
        }
      },
      onCancel() {},
    })
  }

  function edit_product_model(pro_model_id, data) {
    const edit_pro_model = api.EDIT_PRODUCT_MODEL(token, pro_model_id, data)
    axios(edit_pro_model)
      .then(res => {
        cancel_edit_Form()
        check_data()
        openNotificationWithIcon("success", t("Edit product model success"))
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            } else {
              openNotificationWithIcon("error", t("Edit product model error"))
            }
          }
        }
      })
  }

  const cancel_edit_Form = () => {
    form_edit.resetFields()
    setVisible_edit(false)
    setData_edit({})
    setImage_upload(null)
    setImage_preview(null)
    setProduct_model_id(null)
  }

  const cancel_create_Form = () => {
    form_create.resetFields()
    setVisible_create(false)
    setData_create({})
    setImage_upload(null)
  }

  function remove_data(id) {
    if (permi_t_delete === true) {
      return confirm({
        title: t("Confirm remove product model"),
        icon: <ExclamationCircleOutlined />,
        centered: true,
        okText: t("Confirm"),
        okType: "danger",
        cancelText: t("Cancel"),
        onOk() {
          setLoading(true)
          const delete_data = api.DELETE_PRODUCT_MODEL(token, id)
          axios(delete_data)
            .then(res => {
              setLoading(false)
              openNotificationWithIcon("error", t("Remove product model error"))
            })
            .catch(error => {
              setLoading(false)
              if (error) {
                if (error.response) {
                  if (error.response.status === 401) {
                    helpers.re_login()
                    history.push("/login")
                  } else if (error.response.status === 404) {
                    openNotificationWithIcon(
                      "success",
                      t("Remove product model success")
                    )
                    check_data()
                  } else {
                    openNotificationWithIcon(
                      "error",
                      t("Remove product model error")
                    )
                  }
                }
              }
            })
        },
        onCancel() {},
      })
    } else if (permi_t_delete === false) {
      return Modal.error({
        title: t("no permission delete module"),
        centered: true,
      })
    }
  }

  const onFinish_create = value => {
    return confirm({
      title: t("Confirm create product model"),
      icon: <ExclamationCircleOutlined />,
      centered: true,
      onOk() {
        setLoading(true)
        if (image_upload !== null) {
          const formData = new FormData()
          formData.append("file", image_upload)
          const upload_image = api.UPLOAD_IMAGE(token, formData)
          axios(upload_image)
            .then(res => {
              let add_image_path = {
                pro_model_img: res.data.path,
                ...data_create,
              }
              create_new_product_model(add_image_path)
            })
            .catch(error => {
              setLoading(false)
              if (error) {
                if (error.response) {
                  if (error.response.status === 401) {
                    helpers.re_login()
                    history.push("/login")
                  } else {
                    openNotificationWithIcon(
                      "error",
                      t("Create new product model error")
                    )
                  }
                }
              }
            })
        } else {
          const data_create_no_image = {
            pro_model_img: "-",
            ...data_create,
          }
          create_new_product_model(data_create_no_image)
        }
      },
      onCancel() {},
    })
  }

  function create_new_product_model(data) {
    const create = api.CREATE_PRODUCT_MODEL(token, data)
    axios(create)
      .then(res => {
        setVisible_create(false)
        setData_create({})
        cancel_create_Form()
        check_data()
        openNotificationWithIcon(
          "success",
          t("Create new product model success")
        )
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            } else {
              openNotificationWithIcon(
                "error",
                t("Create new product model error")
              )
            }
          }
        }
      })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Product model settings")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/system-setting")}
              />
            </a>{" "}
            {t("Product model settings")}
          </h3>
          {permi_t_read === false && (
            <div>
              <img
                src={no_permi}
                alt="no permission"
                style={{ display: "block", margin: "50px auto" }}
              />
              <p style={{ textAlign: "center", fontSize: "20px" }}>
                {t("no permission read module")}
              </p>
              <p style={{ textAlign: "center", fontSize: "20px" }}>
                {" "}
                {t("please contract administrator")}{" "}
              </p>
            </div>
          )}
          {permi_t_read === true && (
            <Card>
              <CardBody>
                {permi_t_write === true && (
                  <Button
                    className="btn-create-new-sub-product"
                    type="primary"
                    onClick={() => {
                      setVisible_create(true)
                      setData_create({
                        pro_model_name: null,
                        pro_group_id: null,
                        pro_model_code: null,
                        pro_model_factory: null,
                        pro_model_protocol: null,
                        pro_model_remark: null,
                      })
                    }}
                  >
                    {t("Create new product model")}
                  </Button>
                )}

                <Table
                  loading={loading}
                  rowKey="pro_model_id"
                  columns={columns}
                  dataSource={data_list}
                  scroll={{ x: 800 }}
                />

                <Modal
                  centered
                  open={visible_edit}
                  footer={false}
                  onCancel={() => {
                    setVisible_edit(false)
                    setData_edit({})
                    cancel_edit_Form()
                  }}
                  width={1000}
                >
                  {permi_t_edit === true && (
                    <div className="modal-product-model-setting">
                      <Form
                        form={form_edit}
                        name="form_edit_data"
                        onFinish={onFinish_edit}
                      >
                        <Row>
                          <Col>
                            <p
                              className="text-bold"
                              style={{ textAlign: "center" }}
                            >
                              {t("Photo product model")}
                            </p>
                            {loading_image === true && (
                              <Spin
                                size="large"
                                style={{
                                  display: "block",
                                  margin: "0px auto 20px auto",
                                }}
                              />
                            )}
                            {image_preview === null &&
                              image_upload === null &&
                              loading_image === false && (
                                <Avatar
                                  size={120}
                                  icon={
                                    <FiBox style={{ marginBottom: "10px" }} />
                                  }
                                  style={{ display: "block", margin: "auto" }}
                                />
                              )}

                            {image_preview !== null &&
                              image_upload === null &&
                              loading_image === false && (
                                <Avatar
                                  size={120}
                                  src={URL.createObjectURL(image_preview)}
                                  style={{ display: "block", margin: "auto" }}
                                />
                              )}

                            {image_preview === null &&
                              image_upload !== null &&
                              loading_image === false && (
                                <Avatar
                                  size={120}
                                  src={URL.createObjectURL(image_upload)}
                                  style={{ display: "block", margin: "auto" }}
                                />
                              )}

                            {image_preview !== null &&
                              image_upload !== null &&
                              loading_image === false && (
                                <Avatar
                                  size={120}
                                  src={URL.createObjectURL(image_upload)}
                                  style={{ display: "block", margin: "auto" }}
                                />
                              )}

                            <input
                              type="file"
                              accept="image/jpeg"
                              onChange={handleImageUpload}
                              ref={imageUploader}
                              style={{
                                display: "none",
                              }}
                            />
                            {loading_image === false && (
                              <Button
                                type="primary"
                                style={{
                                  display: "block",
                                  margin: "20px auto 20px auto",
                                }}
                                onClick={() => imageUploader.current.click()}
                              >
                                {t("Upload photo")}
                              </Button>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={6} lg={6} xl={6}>
                            <Input_Comp
                              placeholder={data_edit.pro_model_code}
                              header={t("Product model code")}
                              headState={setData_edit}
                              nameState={data_edit}
                              keyState="pro_model_code"
                              type="text"
                              required={false}
                            />
                          </Col>
                          <Col sm={12} md={6} lg={6} xl={6}>
                            <Input_Comp
                              placeholder={data_edit.pro_model_name}
                              header={t("Product model name")}
                              headState={setData_edit}
                              nameState={data_edit}
                              keyState="pro_model_name"
                              type="text"
                              required={false}
                            />
                          </Col>
                          <Col sm={12} md={6} lg={6} xl={6}>
                            <Input_Comp
                              placeholder={data_edit.pro_model_factory}
                              header={t("Product model factory")}
                              headState={setData_edit}
                              nameState={data_edit}
                              keyState="pro_model_factory"
                              type="text"
                              required={false}
                            />
                          </Col>
                          <Col sm={12} md={6} lg={6} xl={6}>
                            <Input_Comp
                              placeholder={data_edit.pro_model_protocol}
                              header={t("Product model protocol")}
                              headState={setData_edit}
                              nameState={data_edit}
                              keyState="pro_model_protocol"
                              type="text"
                              required={false}
                            />
                          </Col>
                          <Col sm={12} md={6} lg={6} xl={6}>
                            <Input_Comp
                              placeholder={data_edit.pro_model_remark}
                              header={t("Product model remark")}
                              headState={setData_edit}
                              nameState={data_edit}
                              keyState="pro_model_remark"
                              type="text"
                              required={false}
                            />
                          </Col>
                          <Col sm={12} md={6} lg={6} xl={6}>
                            <Select_no_search
                              header={t("Product group")}
                              keyState="pro_group_id"
                              headState={setData_edit}
                              nameState={data_edit}
                              map_name="pro_group_name"
                              placeholder={t("Product group")}
                              map_value="pro_group_id"
                              disabled={false}
                              required={false}
                              data_array_map={data_list_all_group}
                            />
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col sm={12} md={6} lg={6} xl={6}>
                            <div className="div_create_new_car">
                              <Button
                                className="btn_cancel_create_new_car"
                                onClick={() => cancel_edit_Form()}
                              >
                                {t("Cancel")}
                              </Button>
                            </div>
                          </Col>
                          <Col sm={12} md={6} lg={6} xl={6}>
                            <div className="div_create_new_car">
                              <Button
                                className="btn_submit_create_new_car"
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                              >
                                {t("Edit")}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  )}
                  {permi_t_edit === false && (
                    <div>
                      <p
                        style={{
                          color: "red",
                          textAlign: "center",
                          fontSize: "20px",
                        }}
                      >
                        {t("no permission edit module")}
                      </p>
                      <p
                        style={{
                          color: "red",
                          textAlign: "center",
                          fontSize: "20px",
                        }}
                      >
                        {t("please contract administrator")}
                      </p>
                    </div>
                  )}
                </Modal>

                <Modal
                  centered
                  open={visible_create}
                  footer={false}
                  onCancel={() => {
                    setVisible_create(false)
                    setData_create({})
                    cancel_create_Form()
                  }}
                  // width={1000}
                >
                  <div className="modal-product-model-setting">
                    <Form
                      form={form_create}
                      name="form_create_data"
                      onFinish={onFinish_create}
                    >
                      <Row>
                        <Col>
                          <p
                            className="text-bold"
                            style={{ textAlign: "center" }}
                          >
                            {t("Photo product model")}
                          </p>
                          {image_upload === null && (
                            <Avatar
                              size={120}
                              icon={<FiBox style={{ marginBottom: "10px" }} />}
                              style={{ display: "block", margin: "auto" }}
                            />
                          )}

                          {image_upload !== null && (
                            <Avatar
                              size={120}
                              src={URL.createObjectURL(image_upload)}
                              style={{ display: "block", margin: "auto" }}
                            />
                          )}

                          <input
                            type="file"
                            accept="image/jpeg"
                            onChange={handleImageUpload}
                            ref={imageUploader}
                            style={{
                              display: "none",
                            }}
                          />
                          <Button
                            type="primary"
                            style={{
                              display: "block",
                              margin: "20px auto 20px auto",
                            }}
                            onClick={() => imageUploader.current.click()}
                          >
                            {t("Upload photo")}
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={6} lg={6} xl={6}>
                          <Input_Comp
                            placeholder={t("Product model code")}
                            header={t("Product model code")}
                            headState={setData_create}
                            nameState={data_create}
                            keyState="pro_model_code"
                            type="text"
                            required={true}
                          />
                        </Col>
                        <Col sm={12} md={6} lg={6} xl={6}>
                          <Input_Comp
                            placeholder={t("Product model name")}
                            header={t("Product model name")}
                            headState={setData_create}
                            nameState={data_create}
                            keyState="pro_model_name"
                            type="text"
                            required={true}
                          />
                        </Col>
                        <Col sm={12} md={6} lg={6} xl={6}>
                          <Input_Comp
                            placeholder={t("Product model factory")}
                            header={t("Product model factory")}
                            headState={setData_create}
                            nameState={data_create}
                            keyState="pro_model_factory"
                            type="text"
                            required={true}
                          />
                        </Col>
                        <Col sm={12} md={6} lg={6} xl={6}>
                          <Input_Comp
                            placeholder={t("Product model protocol")}
                            header={t("Product model protocol")}
                            headState={setData_create}
                            nameState={data_create}
                            keyState="pro_model_protocol"
                            type="text"
                            required={true}
                          />
                        </Col>
                        <Col sm={12} md={6} lg={6} xl={6}>
                          <Input_Comp
                            placeholder={t("Product model remark")}
                            header={t("Product model remark")}
                            headState={setData_create}
                            nameState={data_create}
                            keyState="pro_model_remark"
                            type="text"
                            required={true}
                          />
                        </Col>
                        <Col sm={12} md={6} lg={6} xl={6}>
                          <Select_no_search
                            header={t("Product group")}
                            keyState="pro_group_id"
                            headState={setData_create}
                            nameState={data_create}
                            map_name="pro_group_name"
                            placeholder={t("Product group")}
                            map_value="pro_group_id"
                            disabled={false}
                            required={true}
                            data_array_map={data_list_all_group}
                          />
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col sm={12} md={6} lg={6} xl={6}>
                          <div className="div_create_new_car">
                            <Button
                              className="btn_cancel_create_new_car"
                              onClick={() => cancel_create_Form()}
                            >
                              {t("Cancel")}
                            </Button>
                          </div>
                        </Col>
                        <Col sm={12} md={6} lg={6} xl={6}>
                          <div className="div_create_new_car">
                            <Button
                              className="btn_submit_create_new_car"
                              type="primary"
                              htmlType="submit"
                              loading={loading}
                            >
                              {t("Create")}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Modal>
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
