var Ip = require('../service/Ip')

export const CHECK_SERVICE_STATUS = (data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/service/verification-token`,
      headers: {
        'Content-Type': 'application/json'
      },
      data : data
    };
  };

  export const USER_ACCEPT_SERVICE = (token , ser_id , data) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/service/${ser_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const NEW_API_EDIT_SERVICE_STATUS_FROM_USER_PUBLIC = (token , ser_id , data) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/service/cancelfromuser/${ser_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };