import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import {
  Table,
  Button,
  Modal,
  Tooltip,
  notification,
  List,
  Comment,
} from "antd"
import axios from "axios"
import * as api from "../../../service/Api_System_Settings"
import Create_New_SLA from "./Create_New_SLA"
import { RiDeleteBin6Line } from "react-icons/ri"
import { useHistory } from "react-router-dom"
import helpers from "../../Function_Redirect/Redirect_login"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import ReactHtmlParser from "react-html-parser"
import { FcSearch, FcSettings, FcDataSheet } from "react-icons/fc"
import Detail_SLA from "./Detail_SLA"
import EditSLA from "./EditSLA"

const { confirm } = Modal

export default function Sla_Comp({ permi_t_write , permi_t_edit , permi_t_delete }) {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [visible_create, setVisible_create] = useState(false)
  const [visible_edit, setVisible_edit] = useState(false)
  const [sla_edit, setSla_edit] = useState(null)
  const [visible_detail, setVisible_detail] = useState(false)
  const [sla_detail, setSla_detail] = useState(null)
  const { t } = useTranslation()
  const [priority_all, setPriority_all] = useState([])
  const [province_all, setProvince_all] = useState([])
  const [sla_all, setSla_all] = useState([])
  const token = localStorage.getItem("token")

  useEffect(() => {
    check_all_sal()
    list_all_priority()
    list_province_all()
    return () => {}
  }, [])

  function check_all_sal() {
    setLoading(true)
    const check_sla = api.LIST_ALL_SLA(token)
    axios(check_sla)
      .then(res => {
        setLoading(false)
        setSla_all(res.data.data)
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  function list_all_priority() {
    const list_pri_all = api.LIST_PRIORITY(token)
    axios(list_pri_all)
      .then(res => {
        setPriority_all(res.data.data)
      })
      .catch(error => {})
  }

  function list_province_all() {
    const get_province_info = api.LIST_PROVINCE_ALL(token)
    axios(get_province_info)
      .then(res => {
        setProvince_all(res.data.message)
      })
      .catch(error => {})
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  function remove_data(sla_service_id) {
    if(permi_t_delete === true){
      return confirm({
        title: t("Confirm remove SLA"),
        icon: <ExclamationCircleOutlined />,
        centered: true,
        okType: "danger",
        okText: t("Confirm"),
        cancelText: t("no"),
        onOk() {
          const remove = api.REMOVE_SLA(token, sla_service_id)
          axios(remove)
            .then(res => {
              openNotificationWithIcon("success", t("Remove SLA error"))
            })
            .catch(error => {
              if (error) {
                if (error.response) {
                  if (error.response.status === 401) {
                    helpers.re_login()
                    history.push("/login")
                  } else if (error.response.status === 404) {
                    check_all_sal()
                    openNotificationWithIcon("success", t("Remove SLA success"))
                  } else {
                    openNotificationWithIcon("error", t("Remove SLA error"))
                  }
                }
              }
            })
        },
        onCancel() {},
      })
    } else {
      return Modal.error({
        title: t("no permission delete module"),
        centered: true,
      })
    }
  }

  const columns = [
    {
      title: t("SLA name"),
      dataIndex: "sla_service_name",
      key: "sla_service_name",
      render: text => <p>{text}</p>,
    },
    {
      title: t("province"),
      dataIndex: "pro_name",
      key: "pro_name",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Priority"),
      dataIndex: "pri_name",
      key: "pri_name",
      render: text => <p>{t(text)}</p>,
    },
    {
      title: "",
      render: text => (
        <Tooltip title={t("View SLA")}>
          <a>
            <FcSearch
              onClick={() => {
                setVisible_detail(true)
                setSla_detail(text)
              }}
              size={20}
            />
          </a>
        </Tooltip>
      ),
    },
    {
      title: "",
      render: data => (
        <Tooltip title={t("Edit SLA")}>
          <a>
            <FcSettings
              onClick={() => {
                setVisible_edit(true)
                setSla_edit(data)
              }}
              size={20}
            />
          </a>
        </Tooltip>
      ),
    },
    {
      title: "",
      dataIndex: "sla_service_id",
      key: "sla_service_id",
      render: text => (
        <Tooltip title={t("Remove SLA")}>
          <a>
            <RiDeleteBin6Line
              style={{ color: "red" }}
              size={20}
              onClick={() => remove_data(text)}
            />
          </a>
        </Tooltip>
      ),
    },
  ]

  return (
    <div>
      {
        permi_t_write === true && (
          <Button
          className="btn-create-new-sub-product"
          type="primary"
          onClick={() => setVisible_create(true)}
        >
          {t("Create new SLA")}
        </Button>
        )
      }

      <Table
        loading={loading}
        rowKey="sla_service_id"
        columns={columns}
        dataSource={sla_all}
        scroll={{ x: 700 }}
      />

      <Modal
        centered
        open={visible_create}
        footer={false}
        onCancel={() => setVisible_create(false)}
        width={800}
      >
        <Create_New_SLA
          setVisible_create={setVisible_create}
          check_all_sal={check_all_sal}
          priority_all={priority_all}
          province_all={province_all}
        />
      </Modal>

      <Modal
        centered
        open={visible_detail}
        footer={false}
        onCancel={() => {
          setVisible_detail(false)
          setSla_detail(null)
        }}
        width={800}
      >
        <Detail_SLA sla_detail={sla_detail} />
      </Modal>

      <Modal
        centered
        open={visible_edit}
        footer={false}
        onCancel={() => {
          setVisible_edit(false)
          setSla_edit(null)
        }}
        width={800}
      >
        {
          permi_t_edit === true && (
            <EditSLA
            setVisible_edit={setVisible_edit}
            check_all_sal={check_all_sal}
            setSla_edit={setSla_edit}
            priority_all={priority_all}
            province_all={province_all}
            sla_edit={sla_edit}
          />
          )
        }
        {
          permi_t_edit === false && (
            <div>
            <p
              style={{
                color: "red",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              {t("no permission edit module")}
            </p>
            <p
              style={{
                color: "red",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              {" "}
              {t("please contract administrator")}{" "}
            </p>
          </div>
          )
        }
      </Modal>
    </div>
  )
}
