const ma_asset = [
    {
      code: "xd7461",
      history: [
        {
          id: "1",
          date_setup: "01/02/64",
          name_asset: "เครื่องบันทึกข้อมูล DVR",
          sn:'SN001',
          imei:'IMEI0001',
          name_ma: "คุณบุญชู",
        },
        {
          id: "2",
          date_setup: "01/02/65",
          name_asset: "เครื่องบันทึกข้อมูล DVR",
          sn:'SN002',
          imei: "IMEI0002",
          name_ma: "คุณบุญชู",
        },
        {
          id: "3",
          date_setup: "01/02/66",
          name_asset: "เครื่องบันทึกข้อมูล DVR",
          sn:'SN003',
          imei: "IMEI0003",
          name_ma: "คุณบุญชู",
        },
        {
          id: "4",
          date_setup: "01/02/67",
          name_asset: "เครื่องบันทึกข้อมูล DVR",
          sn:'SN004',
          imei: "IMEI0004",
          name_ma: "คุณบุญชู",
        },
      ],
    },
    {
      code: "dd4648",
      history: [
          {
            id: "1",
            date_setup: "02/04/60",
            name_asset: "เครื่องบันทึกข้อมูล DVR",
            sn:'SN001',
            imei: "IMEI0001",
            name_ma: "คุณภัทรพล",
          },
          {
            id: "2",
            date_setup: "01/02/65",
            name_asset: "เครื่องบันทึกข้อมูล DVR",
            sn:'SN002',
            imei: "IMEI0002",
            name_ma: "คุณภัทรพล",
          },
          {
            id: "3",
            date_setup: "01/02/66",
            name_asset: "เครื่องบันทึกข้อมูล DVR",
            sn:'SN003',
            imei: "IMEI0003",
            name_ma: "คุณภัทรพล",
          },
          {
            id: "4",
            date_setup: "14/02/67",
            name_asset: "เครื่องบันทึกข้อมูล DVR",
            sn:'SN004',
            imei: "IMEI0004",
            name_ma: "คุณภัทรพล",
          },
        ],
    },
  ]
  
  export default ma_asset
  