import React, { useState, useEffect } from "react"
import { Link, useHistory } from "react-router-dom"
import classname from "classnames"
import { BiHome } from "@react-icons/all-files/bi/BiHome"
import { BiBriefcase } from "@react-icons/all-files/bi/BiBriefcase"
import { BiGroup } from "@react-icons/all-files/bi/BiGroup"
import { BiCalculator } from "@react-icons/all-files/bi/BiCalculator"
import { BiReceipt } from "@react-icons/all-files/bi/BiReceipt"
import { BiPackage } from "@react-icons/all-files/bi/BiPackage"
import { BiUserCircle } from "@react-icons/all-files/bi/BiUserCircle"
import { BiCar } from "@react-icons/all-files/bi/BiCar"
import { BsPeopleCircle } from "@react-icons/all-files/bs/BsPeopleCircle"
import { RiNewspaperLine } from "@react-icons/all-files/ri/RiNewspaperLine"
import { BsPeople } from "@react-icons/all-files/bs/BsPeople"
import { CgImport } from "@react-icons/all-files/cg/CgImport"
import { AiOutlineSetting } from "@react-icons/all-files/ai/AiOutlineSetting"
import { RiListSettingsLine } from "@react-icons/all-files/ri/RiListSettingsLine"
import { useTranslation } from "react-i18next"
import helpers from "../../pages/Function_Redirect/Redirect_login"
import { FiChevronDown } from "react-icons/fi"
import { connect, useSelector, useDispatch } from "react-redux"
import { toggleLeftmenu } from "../../store/actions"
import useWindowDimensions from "./UseWindowDimensions";
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

import axios from "axios"
import * as api from "../../service/Api_List_Menu"

import { PERMISSION_ALL_SERVICE } from "../../store/Distar/actionTypes"

const List_Menu = props => {
  const { t } = useTranslation()
  const style_icon = {
    marginBottom: "5px",
    marginRight: "3px",
  }
  const { height, width } = useWindowDimensions();
  const [employee, setEmployee] = useState(false)
  const [customers, setCustomers] = useState(false)
  const [dealer, setDealer] = useState(false)
  const [stock, setStock] = useState(false)
  const [dashboards, setDashboards] = useState(false)
  const [admin, setAdmin] = useState(false)
  const [project, setProject] = useState(false)
  const [assets, setAssets] = useState(false)
  const [branch_customers, setBranch_Customers] = useState(false)
  const [supplier, setSupplier] = useState(false)
  const [report, setReport] = useState(false)
  const [cars, setCars] = useState(false)
  const [import_data, setImport_data] = useState(false)
  const [operator, setOperator] = useState(false)
  const [contract, setContract] = useState(false)
  const [service, setService] = useState(false)
  const [setting, setSetting] = useState(false)
  const [system_setting, setSystem_setting] = useState(false)
  const [device, setDevice] = useState(false)
  const [repair, setRepair] = useState(false)

  const [data_before_mapping_role, setData_before_mapping_role] = useState([])
  const [role_list_menu, setRole_list_menu] = useState({})
  const token = localStorage.getItem("token")
  const r = localStorage.getItem("r")
  const role = localStorage.getItem("role")
  const permi = localStorage.getItem("permi")

  const { isAdminRoot, isRole } = useSelector(({ Distar }) => Distar)
  const dispatch = useDispatch()
  const action = (type, payload) => dispatch({ type, payload })
  
  const history = useHistory()
  const [menu_list, setMenu_list] = useState([
    {
      name: "dashboards",
      title: "dashboards",
      state: dashboards,
      setState: setDashboards,
      url: "/dashboards",
      icon: <BiHome style={style_icon} />,
      sub_menu: [
        { name_sub: "dashboards", url: "/dashboards" },
        { name_sub: "sla", url: "/sla-dashboards" },
      ],
    },
  ])

  useEffect(() => {
    if (isRole === "employee") {
      get_user_info()
    }
    return () => {}
  }, [isRole])

  function get_user_info() {
    const get_userInfo = api.CHECK_USER_INFO(token)
    axios(get_userInfo)
      .then(res => {
        get_role(res.data.user_infomation.emp_level_id)
      })
      .catch(error => {})
  }

  function get_role(id) {
    const check_role = api.CHECK_ROLE_PERMISSION(token, id)
    axios(check_role)
      .then(res => {
        if (res.data.employee_role.length === 0) {
          set_data_default()
        } else {
          setData_before_mapping_role(res.data.employee_role)
        }
      })
      .catch(error => {
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  function set_data_default() {
    let default_role = {
      system_setting: {
        read: false,
        write: false,
        edit: false,
        delete: false,
      },
      employee: {
        read: false,
        write: false,
        edit: false,
        delete: false,
      },
      customer: {
        read: false,
        write: false,
        edit: false,
        delete: false,
      },
      car: {
        read: false,
        write: false,
        edit: false,
        delete: false,
      },
      import: {
        read: false,
        write: false,
        edit: false,
        delete: false,
      },
      contract: {
        read: false,
        write: false,
        edit: false,
        delete: false,
      },
      service: {
        read: false,
        write: false,
        edit: false,
        delete: false,
      },
      operator: {
        read: false,
        write: false,
        edit: false,
        delete: false,
      },
      dealer: {
        read: false,
        write: false,
        edit: false,
        delete: false,
      },
      stock: {
        read: false,
        write: false,
        edit: false,
        delete: false,
      },
    }
    setRole_list_menu(default_role)
  }

  useEffect(() => {
    mapping_data()
    return () => {}
  }, [data_before_mapping_role])

  function mapping_data() {
    let data_obj_all = {}
    let data_one_obj = {}
    data_before_mapping_role.map((data, index) => {
      data_one_obj = {
        read: check_true_false(data.can_read),
        write: check_true_false(data.can_write),
        edit: check_true_false(data.can_edit),
        delete: check_true_false(data.can_delete),
      }
      data_obj_all[data.emp_module_name] = data_one_obj
    })
    setRole_list_menu(data_obj_all)
    //  console.log(data_obj_all);
  }

  function check_true_false(value) {
    switch (value) {
      case 1:
        return true
        break
      case 0:
        return false
        break
      default:
        break
    }
  }

  useEffect(() => {
    check_permi()

    // action(PERMISSION_ALL_SERVICE, permi_admin)
    return () => {}
  }, [role_list_menu])

  function check_permi() {
    const menu = [
      {
        name: "dashboards",
        title: "dashboards",
        state: dashboards,
        setState: setDashboards,
        icon: <BiHome style={style_icon} />,
        url: "/dashboards",
        sub_menu: [
          { name_sub: "dashboards", url: "/dashboards" },
          { name_sub: "sla", url: "/sla-dashboards" },
        ],
      },
      // {
      //   name: "report",
      //   title: "report",
      //   state: report,
      //   setState: setReport,
      //   icon: <RiNewspaperLine style={style_icon} />,
      //   url: "/reports",
      //   sub_menu: [{ name_sub: "report", url: "/reports" }],
      // },
    ]

    if (role_list_menu.employee) {
      if (role_list_menu.customer.read) {
        menu.push({
          name: "customer",
          title: "customer",
          state: customers,
          setState: setCustomers,
          url: "/customers",
          icon: <BiUserCircle style={style_icon} />,
          sub_menu: [],
          // sub_menu: [{ name_sub: "customer", url: "/customers" }],
        })
      }

      // car
      if (role_list_menu.car.read) {
        menu.push({
          name: "car",
          title: "car",
          state: cars,
          setState: setCars,
          icon: <BiCar style={style_icon} />,
          url: "/cars",
          sub_menu: [],
          // sub_menu: [{ name_sub: "car", url: "/cars" }],
        })
      }

      // import
      if (role_list_menu.import.read) {
        menu.push({
          name: "import data",
          title: "import data",
          state: import_data,
          setState: setImport_data,
          url: "/import-data",
          icon: <CgImport style={style_icon} />,
          sub_menu: [],
          // sub_menu: [{ name_sub: "import data", url: "/import-data" }],
        })
      }

      // contract
      if (role_list_menu.contract.read) {
        menu.push({
          name: "contract",
          title: "contract",
          state: contract,
          setState: setContract,
          url: "/contract",
          icon: <RiNewspaperLine style={style_icon} />,
          sub_menu: [],
          // sub_menu: [{ name_sub: "contract", url: "/contract" }],
        })
      }

      // service
      if (role_list_menu.service.read) {
        menu.push({
          name: "service",
          title: "service",
          state: service,
          setState: setService,
          url: "/service",
          icon: <BiPackage style={style_icon} />,
          sub_menu: [
            { name_sub: "service", url: "/service" },
            { name_sub: "requested repair", url: "/requested-repair" },
          ],
          // sub_menu: [{ name_sub: "service", url: "/service" }],
        })
      }

      // operator
      if (role_list_menu.operator.read) {
        menu.push({
          name: "operator",
          title: "operator",
          state: operator,
          setState: setOperator,
          url: "/operator",
          icon: <BsPeopleCircle style={style_icon} />,
          sub_menu: [],
          // sub_menu: [{ name_sub: "operator", url: "/operator" }],
        })
      }

      // dealer
      if (role_list_menu.dealer.read) {
        menu.push({
          name: "dealer",
          title: "dealer",
          state: dealer,
          setState: setDealer,
          url: "/dealer",
          icon: <BsPeople style={style_icon} />,
          sub_menu: [],
          // sub_menu: [{ name_sub: "dealer", url: "/dealer" }],
        })
      }

      // stock
      if (role_list_menu.stock.read) {
        menu.push({
          name: "stock",
          title: "stock",
          state: stock,
          setState: setStock,
          url: "/stock",
          icon: <BiCalculator style={style_icon} />,
          sub_menu: [],
          // sub_menu: [{ name_sub: "stock", url: "/stock" }],
        })
      }

      // report
      menu.push({
        name: "report",
        title: "report",
        state: report,
        setState: setReport,
        url: "/reports",
        icon: <RiNewspaperLine style={style_icon} />,
        sub_menu: [],
        // sub_menu: [{ name_sub: "report", url: "/reports" }],
      })

      // system_setting
      if (role_list_menu.system_setting.read) {
        menu.push({
          name: "system_setting",
          title: "system setting",
          state: system_setting,
          setState: setSystem_setting,
          url: "/system-setting",
          icon: <RiListSettingsLine style={style_icon} />,
          sub_menu: [],
        })
      }

      menu.push({
        name: "setting",
        title: "setting",
        state: setting,
        setState: setSetting,
        icon: <AiOutlineSetting style={style_icon} />,
        url: "/setting",
        sub_menu: [],
        // sub_menu: [{ name_sub: "setting", url: "/setting" }],
      })

      setMenu_list(menu)
    }
  }

  const menu_list_admin_root = [
    {
      name: "dashboards",
      title: "dashboards",
      state: dashboards,
      setState: setDashboards,
      icon: <BiHome style={style_icon} />,
      url: "/dashboards",
      sub_menu: [
        { name_sub: "dashboards", url: "/dashboards" },
        { name_sub: "sla", url: "/sla-dashboards" },
      ],
    },
    {
      name: "admin management",
      title: "admin management",
      state: admin,
      setState: setAdmin,
      url: "/admin-management",
      icon: <BiGroup style={style_icon} />,
      sub_menu: [],
    },
    {
      name: "employee",
      title: "employee",
      state: employee,
      setState: setEmployee,
      url: "/employee",
      icon: <BiGroup style={style_icon} />,
      sub_menu: [],
    },
    {
      name: "customer",
      title: "customer",
      state: customers,
      setState: setCustomers,
      url: "/customers",
      icon: <BiUserCircle style={style_icon} />,
      sub_menu: [],
    },
    {
      name: "car",
      title: "car",
      state: cars,
      setState: setCars,
      icon: <BiCar style={style_icon} />,
      url: "/cars",
      sub_menu: [],
    },
    {
      name: "import data",
      title: "import data",
      state: import_data,
      setState: setImport_data,
      url: "/import-data",
      icon: <CgImport style={style_icon} />,
      sub_menu: [],
    },
    {
      name: "contract",
      title: "contract",
      state: contract,
      setState: setContract,
      url: "/contract",
      icon: <RiNewspaperLine style={style_icon} />,
      sub_menu: [],
    },
    {
      name: "service",
      title: "service",
      state: service,
      setState: setService,
      url: "/service",
      icon: <BiPackage style={style_icon} />,
      sub_menu: [
        { name_sub: "service", url: "/service" },
        { name_sub: "requested repair", url: "/requested-repair" },
      ],
    },
    {
      name: "operator",
      title: "operator",
      state: operator,
      setState: setOperator,
      url: "/operator",
      icon: <BsPeopleCircle style={style_icon} />,
      sub_menu: [],
    },
    {
      name: "dealer",
      title: "dealer",
      state: dealer,
      setState: setDealer,
      url: "/dealer",
      icon: <BsPeople style={style_icon} />,
      sub_menu: [],
    },
    {
      name: "stock",
      title: "stock",
      state: stock,
      setState: setStock,
      url: "/stock",
      icon: <BiCalculator style={style_icon} />,
      sub_menu: [],
    },
    {
      name: "report",
      title: "report",
      state: report,
      setState: setReport,
      url: "/reports",
      icon: <RiNewspaperLine style={style_icon} />,
      sub_menu: [],
    },
    {
      name: "system_setting",
      title: "system setting",
      state: system_setting,
      setState: setSystem_setting,
      url: "/system-setting",
      icon: <RiListSettingsLine style={style_icon} />,
      sub_menu: [],
    },
    {
      name: "setting",
      title: "setting",
      state: setting,
      setState: setSetting,
      icon: <AiOutlineSetting style={style_icon} />,
      url: "/setting",
      sub_menu: [],
    },
  ]

  const menu_list_admin = [
    {
      name: "dashboards",
      title: "dashboards",
      state: dashboards,
      setState: setDashboards,
      icon: <BiHome style={style_icon} />,
      url: "/dashboards",
      sub_menu: [
        { name_sub: "dashboards", url: "/dashboards" },
        { name_sub: "sla", url: "/sla-dashboards" },
      ],
    },
    {
      name: "employee",
      title: "employee",
      state: employee,
      setState: setEmployee,
      url: "/employee",
      icon: <BiGroup style={style_icon} />,
      sub_menu: [],
    },
    {
      name: "customer",
      title: "customer",
      state: customers,
      setState: setCustomers,
      url: "/customers",
      icon: <BiUserCircle style={style_icon} />,
      sub_menu: [],
    },
    {
      name: "car",
      title: "car",
      state: cars,
      setState: setCars,
      icon: <BiCar style={style_icon} />,
      url: "/cars",
      sub_menu: [],
    },
    {
      name: "import data",
      title: "import data",
      state: import_data,
      setState: setImport_data,
      url: "/import-data",
      icon: <CgImport style={style_icon} />,
      sub_menu: [],
    },
    {
      name: "contract",
      title: "contract",
      state: contract,
      setState: setContract,
      url: "/contract",
      icon: <RiNewspaperLine style={style_icon} />,
      sub_menu: [],
    },
    {
      name: "service",
      title: "service",
      state: service,
      setState: setService,
      url: "/service",
      icon: <BiPackage style={style_icon} />,
      sub_menu: [
        { name_sub: "service", url: "/service" },
        { name_sub: "requested repair", url: "/requested-repair" },
      ],
    },
    {
      name: "operator",
      title: "operator",
      state: operator,
      setState: setOperator,
      url: "/operator",
      icon: <BsPeopleCircle style={style_icon} />,
      sub_menu: [],
    },
    {
      name: "dealer",
      title: "dealer",
      state: dealer,
      setState: setDealer,
      url: "/dealer",
      icon: <BsPeople style={style_icon} />,
      sub_menu: [],
    },
    {
      name: "stock",
      title: "stock",
      state: stock,
      setState: setStock,
      url: "/stock",
      icon: <BiCalculator style={style_icon} />,
      sub_menu: [],
    },
    {
      name: "report",
      title: "report",
      state: report,
      setState: setReport,
      url: "/reports",
      icon: <RiNewspaperLine style={style_icon} />,
      sub_menu: [],
    },
    {
      name: "system_setting",
      title: "system setting",
      state: system_setting,
      setState: setSystem_setting,
      url: "/system-setting",
      icon: <RiListSettingsLine style={style_icon} />,
      sub_menu: [],
    },
    {
      name: "setting",
      title: "setting",
      state: setting,
      setState: setSetting,
      icon: <AiOutlineSetting style={style_icon} />,
      url: "/setting",
      sub_menu: [],
    },
  ]

  const list_menu = [
    {
      name: "dashboards",
      title: "dashboards",
      state: dashboards,
      setState: setDashboards,
      icon: <BiHome style={style_icon} />,
      url: "/dashboards",
      sub_menu: [{ name_sub: "dashboards", url: "/dashboards" }],
    },
    {
      name: "employee",
      title: "employee",
      state: employee,
      setState: setEmployee,
      icon: <BiGroup style={style_icon} />,
      url: "/employee",
      sub_menu: [{ name_sub: "employee", url: "/employee" }],
    },
    {
      name: "customer",
      title: "customer",
      state: customers,
      setState: setCustomers,
      url: "/customers",
      icon: <BiUserCircle style={style_icon} />,
      sub_menu: [{ name_sub: "customer", url: "/customers" }],
    },
    {
      name: "car",
      title: "car",
      state: cars,
      setState: setCars,
      icon: <BiCar style={style_icon} />,
      url: "/cars",
      sub_menu: [{ name_sub: "car", url: "/cars" }],
    },
    {
      name: "import data",
      title: "import data",
      state: import_data,
      setState: setImport_data,
      icon: <CgImport style={style_icon} />,
      url: "/import-data",
      sub_menu: [{ name_sub: "import data", url: "/import-data" }],
    },
    {
      name: "contract",
      title: "contract",
      state: contract,
      setState: setContract,
      icon: <RiNewspaperLine style={style_icon} />,
      url: "/contract",
      sub_menu: [{ name_sub: "contract", url: "/contract" }],
    },
    {
      name: "service",
      title: "service",
      state: service,
      setState: setService,
      icon: <BiPackage style={style_icon} />,
      url: "/service",
      sub_menu: [{ name_sub: "service", url: "/service" }],
    },
    {
      name: "operator",
      title: "operator",
      state: operator,
      setState: setOperator,
      url: "/operator",
      icon: <BsPeopleCircle style={style_icon} />,
      sub_menu: [{ name_sub: "operator", url: "/operator" }],
    },
    {
      name: "dealer",
      title: "dealer",
      state: dealer,
      setState: setDealer,
      url: "/dealer",
      icon: <BsPeople style={style_icon} />,
      sub_menu: [{ name_sub: "dealer", url: "/dealer" }],
    },
    {
      name: "stock",
      title: "stock",
      state: stock,
      setState: setStock,
      url: "/stock",
      icon: <BiCalculator style={style_icon} />,
      sub_menu: [{ name_sub: "stock", url: "/stock" }],
    },
    {
      name: "setting",
      title: "setting",
      state: setting,
      setState: setSetting,
      url: "/setting",
      icon: <AiOutlineSetting style={style_icon} />,
      sub_menu: [{ name_sub: "setting", url: "/setting" }],
    },
    {
      name: "system_setting",
      title: "system setting",
      state: system_setting,
      setState: setSystem_setting,
      icon: <RiListSettingsLine style={style_icon} />,
      url: "/system-setting",
      sub_menu: [{ name_sub: "system setting", url: "/system-setting" }],
    },
  ]

  const list_menu_customer = [
    {
      name: "dashboards",
      title: "dashboards",
      state: dashboards,
      setState: setDashboards,
      url: "/dashboards",
      icon: <BiHome style={style_icon} />,
      sub_menu: [{ name_sub: "dashboards", url: "/dashboards" }],
    },
    {
      name: "car",
      title: "car",
      state: cars,
      setState: setCars,
      url: "/cars",
      icon: <BiCar style={style_icon} />,
      sub_menu: [{ name_sub: "car", url: "/cars" }],
    },
    {
      name: "device",
      title: "device",
      state: device,
      setState: setDevice,
      url: "/device",
      icon: <BiCalculator style={style_icon} />,
      sub_menu: [{ name_sub: "device", url: "/device" }],
    },
    {
      name: "contract",
      title: "contract",
      state: contract,
      setState: setContract,
      url: "/contract",
      icon: <RiNewspaperLine style={style_icon} />,
      sub_menu: [{ name_sub: "contract", url: "/contract" }],
    },
    {
      name: "service",
      title: "service",
      state: service,
      setState: setService,
      url: "/service",
      icon: <BiPackage style={style_icon} />,
      sub_menu: [{ name_sub: "service", url: "/service" }],
    },
    {
      name: "repair",
      title: "repair",
      state: repair,
      setState: setRepair,
      url: "/repair",
      icon: <BiPackage style={style_icon} />,
      sub_menu: [{ name_sub: "repair", url: "/repair" }],
    },
    {
      name: "report",
      title: "report",
      state: report,
      setState: setReport,
      url: "/reports",
      icon: <RiNewspaperLine style={style_icon} />,
      sub_menu: [{ name_sub: "report", url: "/reports" }],
    },
    {
      name: "setting",
      title: "setting",
      state: setting,
      setState: setSetting,
      url: "/setting",
      icon: <AiOutlineSetting style={style_icon} />,
      sub_menu: [{ name_sub: "setting", url: "/setting" }],
    },
  ]

  const list_menu_dealer = [
    {
      name: "dashboards",
      title: "dashboards",
      state: dashboards,
      setState: setDashboards,
      icon: <BiHome style={style_icon} />,
      url: "/dashboards",
      sub_menu: [{ name_sub: "dashboards", url: "/dashboards" }],
    },
    {
      name: "customer",
      title: "customer",
      state: customers,
      setState: setCustomers,
      url: "/customers",
      icon: <BiUserCircle style={style_icon} />,
      sub_menu: [{ name_sub: "customer", url: "/customers" }],
    },
    {
      name: "repair",
      title: "repair",
      state: repair,
      setState: setRepair,
      url: "/repair",
      icon: <BiPackage style={style_icon} />,
      sub_menu: [{ name_sub: "repair", url: "/repair" }],
    },
    {
      name: "report",
      title: "report",
      state: report,
      setState: setReport,
      url: "/reports",
      icon: <RiNewspaperLine style={style_icon} />,
      sub_menu: [{ name_sub: "report", url: "/reports" }],
    },
    {
      name: "setting",
      title: "setting",
      state: setting,
      setState: setSetting,
      url: "/setting",
      icon: <AiOutlineSetting style={style_icon} />,
      sub_menu: [{ name_sub: "setting", url: "/setting" }],
    },
  ]

  const list_menu_operator = [
    {
      name: "dashboards",
      title: "dashboards",
      state: dashboards,
      setState: setDashboards,
      url: "/dashboards",
      icon: <BiHome style={style_icon} />,
      sub_menu: [{ name_sub: "dashboards", url: "/dashboards" }],
    },
    {
      name: "report",
      title: "report",
      state: report,
      setState: setReport,
      url: "/reports",
      icon: <RiNewspaperLine style={style_icon} />,
      sub_menu: [{ name_sub: "report", url: "/reports" }],
    },
    {
      name: "setting",
      title: "setting",
      state: setting,
      setState: setSetting,
      url: "/setting",
      icon: <AiOutlineSetting style={style_icon} />,
      sub_menu: [{ name_sub: "setting", url: "/setting" }],
    },
  ]

  // console.log(width);
  
  return (
    <>
      {isRole === "administrator" && isAdminRoot === true && (
        <>
          {menu_list_admin_root.map((data, index) => {
            if (data.sub_menu.length > 0) {
              return (
                <li className="nav-item dropdown" key={index}>
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault()
                      data.setState(!data.state)
                    }}
                    to={data.url}
                  >
                    {data.icon}
                    {props.t(data.title)} {props.menuOpen}
                    <FiChevronDown />
                  </Link>
                  <div
                    // className={classname("dropdown-menu", { show: data.state })}
                    className={classname("dropdown-menu", { show: width <= 992 ? true : data.state })}
                  >
                    {data.sub_menu.map((data_sub, index_sub) => {
                      return (
                        <Link
                          key={index_sub}
                          to={data_sub.url}
                          className="dropdown-item"
                          onClick={e => {
                            data.setState(false)
                            setDashboards(false)
                            setService(false)

                            props.toggleLeftmenu(false)
                          }}
                        >
                          {props.t(data_sub.name_sub)}
                        </Link>
                      )
                    })}
                  </div>
                </li>
              )
            } else {
              return (
                <li key={index}>
                  <Link
                    to={data.url}
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      setDashboards(false)
                      setService(false)

                      props.toggleLeftmenu(false)
                    }}
                    // onClick={() => props.toggleLeftmenu(!props.leftMenu)}
                  >
                    {data.icon}
                    {props.t(data.title)}
                  </Link>
                </li>
              )
            }
          })}
        </>
      )}

      {isRole === "administrator" && isAdminRoot === false && (
        <>
          {menu_list_admin.map((data, index) => {
            if (data.sub_menu.length > 0) {
              return (
                <li className="nav-item dropdown" key={index}>
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault()
                      data.setState(!data.state)
                    }}
                    to={data.url}
                  >
                    {data.icon}
                    {props.t(data.title)} {props.menuOpen}
                    <FiChevronDown />
                  </Link>
                  <div
                    className={classname("dropdown-menu", { show: width <= 992 ? true : data.state })}
                    // className={classname("dropdown-menu", { show: data.state })}
                  >
                    {data.sub_menu.map((data_sub, index_sub) => {
                      return (
                        <Link
                          key={index_sub}
                          to={data_sub.url}
                          className="dropdown-item"
                          onClick={e => {
                            data.setState(false)
                            setDashboards(false)
                            setService(false)
                            
                            props.toggleLeftmenu(false)
                          }}
                        >
                          {props.t(data_sub.name_sub)}
                        </Link>
                      )
                    })}
                  </div>
                </li>
              )
            } else {
              return (
                <li key={index}>
                  <Link
                    to={data.url}
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      setDashboards(false)
                      setService(false)

                      props.toggleLeftmenu(false)
                    }}
                    // onClick={() => props.toggleLeftmenu(!props.leftMenu)}
                  >
                    {data.icon}
                    {props.t(data.title)}
                  </Link>
                </li>
              )
            }
          })}
        </>
      )}
      {isRole === "employee" && (
        <>
          {menu_list.map((data, index) => {
            if (data.sub_menu.length > 0) {

              return (
                <li className="nav-item dropdown" key={index}>
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault()

                      data.setState(!data.state)
                    }}
                    to={data.url}
                  >
                    {data.icon}
                    {props.t(data.title)} {props.menuOpen}
                    <FiChevronDown />
                  </Link>
                  <div
                    className={classname("dropdown-menu", { show: width <= 992 ? true : data.state })}
                    // className={classname("dropdown-menu", { show: data.state })}
                  >
                    {data.sub_menu.map((data_sub, index_sub) => {
                      return (
                        <Link
                          key={index_sub}
                          to={data_sub.url}
                          className="dropdown-item"
                          onClick={e => {
                            data.setState(false)
                            setDashboards(false)
                            setService(false)

                            props.toggleLeftmenu(false)
                          }}
                        >
                          {props.t(data_sub.name_sub)}
                        </Link>
                      )
                    })}
                  </div>
                </li>
              )
            } else {
              return (
                <li key={index}>
                  <Link
                    to={data.url}
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      setDashboards(false)
                      setService(false)

                      props.toggleLeftmenu(false)
                    }}
                    // onClick={() => props.toggleLeftmenu(!props.leftMenu)}
                  >
                    {data.icon}
                    {props.t(data.title)}
                  </Link>
                </li>
              )
            }
          })}
        </>
      )}

      {isRole === "operator" && (
        <>
          {list_menu_operator.map((data, index) => {
            return (
              <Link
                key={index}
                to={data.url}
                className="nav-link dropdown-toggle arrow-none"
                onClick={() =>  props.toggleLeftmenu(false) }
              >
                <li className="nav-item">
                  {data.icon}
                  {props.t(data.title)}
                </li>
              </Link>
            )
          })}
        </>
      )}

      {isRole === "customer_user" && (
        <>
          {list_menu_customer.map((data, index) => {
            return (
              <Link
                key={index}
                to={data.url}
                className="nav-link dropdown-toggle arrow-none"
                onClick={() =>  props.toggleLeftmenu(false) }
              >
                <li className="nav-item">
                  {data.icon}
                  {props.t(data.title)}
                </li>
              </Link>
            )
          })}
        </>
      )}

      {isRole === "dealer_user" && (
        <>
          {list_menu_dealer.map((data, index) => {
            return (
              <Link
                key={index}
                to={data.url}
                className="nav-link dropdown-toggle arrow-none"
              >
                <li className="nav-item">
                  {data.icon}
                  {props.t(data.title)}
                </li>
              </Link>
            )
          })}
        </>
      )}
    </>
  )
}

List_Menu.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout
  return { layoutType, showRightSidebar, leftMenu }
}

export default connect(mapStatetoProps, {
  toggleLeftmenu,
})(withTranslation()(List_Menu))
