import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import MetaTags from "react-meta-tags"
import { Container, Card, CardBody, Row, Col } from "reactstrap"
import { MdKeyboardBackspace } from "react-icons/md"
import { Pagination, Table, Button, Modal, Tag, Tooltip } from "antd"
import { FcDataSheet } from "react-icons/fc"
import {
  FcShipped,
  FcImport,
  FcViewDetails,
  FcSearch,
  FcAcceptDatabase,
  FcDeleteDatabase,
} from "react-icons/fc"
import axios from "axios"
import * as api from "../../service/Api_New_Car"
import Search_data from "./Search_data"
import Detail_Car_From_DB from "./Detail_Car_From_DB"
import Detail_Sale_dealer_db from "./Detail_Sale_dealer_db"
import Detail_Sale_no_dealer_db from "./Detail_Sale_no_dealer_db"
import no_permi from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png"
import helpers from "../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"
import FilterIsImportCar from "./FilterIsImportCar"
import { useSelector } from "react-redux"

const { confirm } = Modal

export default function Table_Car_From_db(props) {
  const history = useHistory()
  const { t } = useTranslation()
  const token = localStorage.getItem("token")
  const [search_car, setSearch_car] = useState(null)
  const [filterIsImport, setFilterIsImport] = useState(null)
  const [data_list, setData_list] = useState([])
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [detail_car, setDetail_car] = useState({})
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })
  const [car_select, setCar_select] = useState([])
  const [visible_dealer_db, setVisible_dealer_db] = useState(false)
  const [data_dealer_db, setData_dealer_db] = useState({})
  const { permissionAllService } = useSelector(({ Distar }) => Distar)
  const [visible_no_dealer_db, setVisible_no_dealer_db] = useState(false)
  const [data_no_dealer_db, setData_no_dealer_db] = useState({})

  const permi = localStorage.getItem("permi")
  const [permi_t_read, setPermi_t_read] = useState(false)

  useEffect(() => {
    if (permissionAllService) {
      if (permissionAllService.import) {
        setPermi_t_read(permissionAllService.import.read)
      }
    }
    return () => {}
  }, [permissionAllService])

  useEffect(() => {
    search_car_all()
    return () => {}
  }, [filterIsImport])

  useEffect(() => {
    search_car_all()
    return () => {
      setVisible(false)
      setFilterIsImport(null)
      setDetail_car({})
      setData_list([])
      setCar_select([])
    }
  }, [])

  const check_data = (params, data) => {
    if (data) {
      return params + data
    } else {
      return ""
    }
  }

  function search_car_all() {
    setLoading(true)
    let url = `distar/cars?page=1&limit=${
      page_limit.page_Size
    }&order_by=DESC${check_data("&q=", search_car)}${check_data(
      "&is_import=",
      filterIsImport
    )}`
    const search = api.SEARCH_CAR_IMPORT(token, url)
    axios(search)
      .then(res => {
        setLoading(false)
        setPage_limit({
          ...page_limit,
          page: 1,
          limit: res.data.total_data,
        })
        setData_list(res.data.data)
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  function changePagination(page, pageSize) {
    setLoading(true)
    let url = `distar/cars?page=${page}&limit=${pageSize}&order_by=DESC${check_data(
      "&q=",
      search_car
    )}${check_data("&is_import=", filterIsImport)}`
    const search = api.SEARCH_CAR_IMPORT(token, url)
    axios(search)
      .then(res => {
        setLoading(false)
        setPage_limit({
          ...page_limit,
          page: page,
          page_Size: pageSize,
          limit: res.data.total_data,
        })
        setData_list(res.data.data)
      })
      .catch(error => {
        // console.log(error)
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  function check_dealer(dealer) {
    if (dealer === true) {
      return (
        <Tooltip title={t("This dealer already exists in the database")}>
          <FcAcceptDatabase
            size={25}
            style={{ color: "#52c41a", display: "block", margin: "auto" }}
          />
        </Tooltip>
      )
    } else {
      return (
        <Tooltip title={t("This dealer was not found in the database")}>
          <FcDeleteDatabase
            size={25}
            style={{ color: "#B6B6B6", display: "block", margin: "auto" }}
          />
        </Tooltip>
      )
    }
  }

  const columns = [
    {
      title: "title",
      dataIndex: "title",
      key: "title",
      render: text => <p>{render_data(text)}</p>,
    },
    {
      title: "lpn",
      dataIndex: "lpn",
      key: "lpn",
      render: text => <p>{render_data(text)}</p>,
    },
    {
      title: "Sale info",
      width: "180px",
      render: text => <span>{render_sale_info(text)}</span>,
    },
    {
      title: t("Dealer in database"),
      width: "150px",
      render: text => <p>{check_dealer(text.is_dealer_in_db)}</p>,
    },
    {
      title: "gid",
      dataIndex: "gid",
      key: "gid",
      render: text => <p>{render_data(text)}</p>,
    },
    {
      title: "remark",
      dataIndex: "remark",
      key: "remark",
      render: text => <p>{render_data(text)}</p>,
    },
    {
      title: "",
      render: text => (
        <Tooltip title={t("View car")}>
          <a>
            <FcViewDetails
              size={20}
              onClick={() => {
                setVisible(true)
                setDetail_car(text)
              }}
            />
          </a>
        </Tooltip>
      ),
    },
    {
      title: "",
      render: text => <span>{check_car_in_db(text)}</span>,
    },
  ]

  function check_car_in_db(is_ca) {
    if (is_ca.is_car_in_db === true) {
      return (
        <Tooltip title={t("Car exist in database")}>
          <FcShipped size={20} />
        </Tooltip>
      )
    } else {
      return (
        <Tooltip title={t("Import car")}>
          <a>
            <FcImport
              size={20}
              onClick={() => history.push(`/import-data/car/${is_ca.gid}`)}
            />
          </a>
        </Tooltip>
      )
    }
  }

  function render_sale_info(data_sale) {
    if (data_sale.is_dealer_in_db === true) {
      return data_sale.sale_info.map((data, index) => {
        return (
          <p key={index}>
            {data.dea_name}{" "}
            <Tooltip title={t("View sale info")}>
              <a>
                <FcSearch
                  size={20}
                  style={{ marginTop: "-5px", marginLeft: "10px" }}
                  onClick={() => {
                    setVisible_dealer_db(true)
                    setData_dealer_db(data)
                  }}
                />
              </a>
            </Tooltip>
          </p>
        )
      })
    } else {
      if(data_sale.sale_info !== null){
        return data_sale.sale_info.map((data, index) => {
          return (
            <p key={index}>
              {data.nickname}{" "}
              <Tooltip title={t("View sale info")}>
                <a>
                  <FcSearch
                    size={20}
                    style={{ marginTop: "-5px", marginLeft: "10px" }}
                    onClick={() => {
                      setVisible_no_dealer_db(true)
                      setData_no_dealer_db(data)
                    }}
                  />
                </a>{" "}
              </Tooltip>
            </p>
          )
        })
      }
    }
  }

  function render_data(data) {
    if (data) {
      return data
    } else {
      return "N/A"
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Import car")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/import-data")}
              />
            </a>{" "}
            {t("Import car")}
          </h3>
          <br />
          <Card>
            <CardBody>
              {permi_t_read === true && (
                <div>
                  <Search_data
                    setSearch_car={setSearch_car}
                    search_car_all={search_car_all}
                  />
                  <Row>
                    <Col sm={12} md={3} lg={3} xl={3}>
                      <FilterIsImportCar 
                      headState={setFilterIsImport} 
                      headerField={t("status import car")}
                      />
                    </Col>
                  </Row>
                  <br />
                  <div>
                    <p>
                      {" "}
                      <FcShipped size={25} /> {t("Car exist in database")}
                      <span style={{ marginLeft: "10px" }}>/</span>
                      <FcImport size={25} style={{ marginLeft: "10px" }} />{" "}
                      {t("Able to import to database")}
                    </p>
                  </div>
                  <br />
                  <Table
                    loading={loading}
                    rowKey="id"
                    pagination={false}
                    columns={columns}
                    dataSource={data_list}
                    scroll={{ x: 800 }}
                  />
                  <div>
                    <Pagination
                      current={page_limit.page}
                      total={page_limit.limit}
                      onChange={changePagination}
                      style={{ marginTop: "20px", float: "right" }}
                    />
                  </div>
                  <p style={{ fontSize: "15px", marginTop: "20px" }}>
                    {" "}
                    <FcDataSheet
                      size={20}
                      style={{ marginBottom: "5px" }}
                    />{" "}
                    {t("total data")} : {page_limit.limit}
                  </p>

                  <Modal
                    title=""
                    centered
                    footer={null}
                    open={visible}
                    onOk={() => {
                      setVisible(false)
                      setDetail_car({})
                    }}
                    onCancel={() => {
                      setVisible(false)
                      setDetail_car({})
                    }}
                    width={1000}
                    height={400}
                  >
                    <Detail_Car_From_DB data={detail_car} />
                  </Modal>

                  <Modal
                    title=""
                    centered
                    footer={null}
                    open={visible_dealer_db}
                    onOk={() => {
                      setVisible_dealer_db(false)
                      setData_dealer_db({})
                    }}
                    onCancel={() => {
                      setVisible_dealer_db(false)
                      setData_dealer_db({})
                    }}
                    width={1000}
                    height={400}
                  >
                    <Detail_Sale_dealer_db data_dealer_db={data_dealer_db} />
                  </Modal>

                  <Modal
                    title=""
                    centered
                    footer={null}
                    open={visible_no_dealer_db}
                    onOk={() => {
                      setVisible_no_dealer_db(false)
                      setData_no_dealer_db({})
                    }}
                    onCancel={() => {
                      setVisible_no_dealer_db(false)
                      setData_no_dealer_db({})
                    }}
                    width={1000}
                    height={400}
                  >
                    <Detail_Sale_no_dealer_db
                      data_no_dealer_db={data_no_dealer_db}
                    />
                  </Modal>
                </div>
              )}

              {permi_t_read === false && (
                <div>
                  <img
                    src={no_permi}
                    alt="no permission"
                    style={{ display: "block", margin: "50px auto" }}
                  />
                  <p style={{ textAlign: "center", fontSize: "20px" }}>
                    {t("no permission read module")}
                  </p>
                  <p style={{ textAlign: "center", fontSize: "20px" }}>
                    {" "}
                    {t("please contract administrator")}{" "}
                  </p>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
