var Ip = require('./Ip')

export const LIST_DETAIL_OPERATOR = (token,ope_id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/operator/${ope_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_POSITION = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/position`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

export const LIST_DEPARTMENT = (token) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/department`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_OPERATOR_GROUP = (token) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/operator/group`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_TYPE_OPERATOR = (token) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/operator/types`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_LEVEL = (token) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/operator/level`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_ROLE_PERMISSION = (token, id) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/operator/list-role-permission/${id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const EDIT_OPERATOR = (token, data, ope_id) => {
  return {
    method: 'put',
    url: `${Ip.getIP()}api/v1/operator/${ope_id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    },
    data : data
  };
};

export const LIST_ALL_GEO_LOCATION = () => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/location/geo`,
    headers: {
      'Content-Type': 'application/json'
    }
  };
};

export const LIST_LOCATION_PROVINCE_BY_GEOID = (geo_id) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/location/province-by-geoid/${geo_id}`,
    headers: {
      'Content-Type': 'application/json'
    }
  };
};

export const LIST_LOCATION_DIS_BY_PROVINCE_ID = (pro_id) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/location/dis-by-proid/${pro_id}`,
    headers: {
      'Content-Type': 'application/json'
    }
  };
};

export const LIST_LOCATION_SUB_DISTRICT = (dis_id) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/location/subdis-by-disid/${dis_id}`,
    headers: {
      'Content-Type': 'application/json'
    }
  };
};

export const CHECK_LOCATION_OPERATOR = (token, ope_id) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/operator/location/${ope_id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const CREATE_NEW_LOCATION = (token, data) => {
  return {
    method: 'post',
    url: `${Ip.getIP()}api/v1/operator/location`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    },
    data : data
  };
};

export const REMOVE_LOCATION = (token, ope_location_id) => {
  return {
    method: 'delete',
    url: `${Ip.getIP()}api/v1/operator/location/${ope_location_id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const DELETE_OPERATOR = (token, ope_id) => {
  return {
    method: 'delete',
    url: `${Ip.getIP()}api/v1/operator/${ope_id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const GET_IMAGE = (token, image) => {
  return {
    method: 'get',
    responseType: 'arraybuffer',
    url: `${Ip.getIP()}storage/file/${image}`,
    headers: {
      'x-access-token' : token
    }
  };
};

export const UPLOAD_PROFILE = (token, formData) => {
  return {
    method: 'post',
    url: `${Ip.getIP()}storage/upload`,
    headers: {
      "Content-type": "multipart/form-data",
      'x-access-token' : token
    },
    data : formData
  };
};

export const FIND_ALL_ADMIN_GROUP = (token) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/admin/group`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};