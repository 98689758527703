import React from 'react'
import { Col } from "reactstrap"

export default function Colum({ header_text, value_text }) {
    const text_header = {
        fontWeight: "bold",
        fontSize: "15px",
      }
    return (
        <>
        <Col sm={6} md={2} lg={2} xl={2}>
        <p style={text_header}> {header_text} </p>
      </Col>
      <Col sm={6} md={4} lg={4} xl={4}>
        <p style={{ fontSize: "15px" }}>
          {" "}
          <span className="tet">
            :
          </span> <span className="tetTwo">-</span>{" "}
          {value_text}{" "}
        </p>
      </Col>
        </>
    )
}
