import React, { useEffect, useState } from "react"
import { Row, Col } from "reactstrap"
import { Tag } from "antd"
import { useTranslation } from "react-i18next";

export default function Overview_Car(props) {
//   console.log(props.car_infomation)
  // console.log(props.car_detail)
  const { t } = useTranslation();
  const [car_info, setCar_info] = useState([])
  const [car_data_device, setCar_data_device] = useState([])

  useEffect(() => {
    if (props.car_infomation !== null) {
      setCar_data_device([
        {
          head_data1: "carid",
          value_data1: check_data(props.car_infomation.carid),
          head_data2: "title",
          value_data2: check_data(props.car_infomation.title),
        },
        {
          head_data1: "userid",
          value_data1: check_data(props.car_infomation.userid),
          head_data2: "typeid",
          value_data2: check_data(props.car_infomation.typeid),
        },
        {
          head_data1: "devtype_title",
          value_data1: check_data(props.car_infomation.devtype_title),
          head_data2: "channel",
          value_data2: check_data(props.car_infomation.channel),
        },
        {
          head_data1: "simid",
          value_data1: check_data(props.car_infomation.simid),
          head_data2: "gid",
          value_data2: check_data(props.car_infomation.gid),
        },
        {
          head_data1: "cid",
          value_data1: check_data(props.car_infomation.cid),
          head_data2: "protocol",
          value_data2: check_data(props.car_infomation.protocol),
        },
        {
          head_data1: "grpid",
          value_data1: check_data(props.car_infomation.grpid),
          head_data2: "remark",
          value_data2: check_data(props.car_infomation.remark),
        },
        ,
        {
          head_data1: "factory",
          value_data1: check_data(props.car_infomation.factory),
          head_data2: "tags",
          value_data2: check_data(props.car_infomation.tags),
        },
      ])
    }
    return () => {
      setCar_data_device([])
    }
  }, [props.car_infomation])

  useEffect(() => {
    if (props.car_detail !== null) {
      setCar_info([
        {
          head_data1: "lpn",
          value_data1: check_data(props.car_detail.lpn),
          head_data2: "title",
          value_data2: check_data(props.car_detail.title),
        },
        {
          head_data1: "gid",
          value_data1: check_data(props.car_detail.gid),
          head_data2: "grpid",
          value_data2: check_data(props.car_detail.grpid),
        },
        {
          head_data1: "license",
          value_data1: check_data(props.car_detail.license),
          head_data2: "saleid",
          value_data2: check_dealer(
            props.car_detail.saleid,
            props.found_my_dealer
          ),
        },
        {
          head_data1: "uid",
          value_data1: check_data(props.car_detail.uid),
          head_data2: "userid",
          value_data2: check_data(props.car_detail.userid),
        },
        {
          head_data1: "cid",
          value_data1: check_data(props.car_detail.cid),
          head_data2: "vin",
          value_data2: check_data(props.car_detail.vin),
        },
        {
          head_data1: "fueltank",
          value_data1: check_data(props.car_detail.fueltank),
          head_data2: "fuelkm",
          value_data2: check_data(props.car_detail.fuelkm),
        },
        {
          head_data1: "fuelhours",
          value_data1: check_data(props.car_detail.fuelhours),
          head_data2: "remark",
          value_data2: check_data(props.car_detail.remark),
        },
        {
          head_data1: "installtime",
          value_data1: cutTimeZone(props.car_detail.installtime),
          head_data2: "offtime",
          value_data2: cutTimeZone(props.car_detail.offtime),
        },
        {
          head_data1: "btime",
          value_data1: cutTimeZone(props.car_detail.btime),
          head_data2: "ctime",
          value_data2: cutTimeZone(props.car_detail.ctime),
        },
        {
          head_data1: "regtime",
          value_data1: cutTimeZone(props.car_detail.regtime),
          head_data2: "saleduration",
          value_data2: check_data(props.car_detail.saleduration),
        },
      ])
    }
    return () => {
      setCar_info([])
    }
  }, [props.car_detail, props.found_my_dealer])

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return null
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  function check_data(data) {
    if (data) {
      return data
    } else {
      return null
    }
  }

  function check_dealer(saleid, dealer) {
    if (dealer === true) {
      return (
        <span>
          {saleid} <Tag color="success"> {t("Already exists in the database")} </Tag>
        </span>
      )
    } else {
      return (
        <span>
          {saleid}{" "}
          <Tag color="default"> {t("This Dealer was Not found in the database")} </Tag>
        </span>
      )
    }
  }

  //   console.log(car_data_device);

  return (
    <div>

      {props.car_detail !== null && (
        <div className="border-card-info" >
          <p className="text-header-information" >
            {t("Car information")}
          </p>
          {car_info.map((data, index) => {
            return (
              <Row key={index}>
                <Col sm={12} md={6} lg={2} xl={2}>
                  <p className="text-bold">{data.head_data1}</p>
                </Col>
                <Col sm={12} md={6} lg={4} xl={4}>
                  <p style={{ fontSize: "15px" }}>
                    {" "}
                    <span className="tet">:</span>{" "}
                    <span className="tetTwo">-</span> {data.value_data1}{" "}
                  </p>
                </Col>
                <Col sm={12} md={6} lg={2} xl={2}>
                  <p className="text-bold">{data.head_data2}</p>
                </Col>
                <Col sm={12} md={6} lg={4} xl={4}>
                  <p style={{ fontSize: "15px" }}>
                    {" "}
                    <span className="tet">:</span>{" "}
                    <span className="tetTwo">-</span> {data.value_data2}{" "}
                  </p>
                </Col>
              </Row>
            )
          })}
        </div>
      )}

      {props.car_infomation !== null && (
        <div className="border-card-info" >
        <p className="text-header-information" >
            {t("Device information")}
          </p>
          {car_data_device.map((data, index) => {
            return (
              <Row key={index}>
                <Col sm={12} md={6} lg={2} xl={2}>
                  <p className="text-bold">{data.head_data1}</p>
                </Col>
                <Col sm={12} md={6} lg={4} xl={4}>
                  <p style={{ fontSize: "15px" }}>
                    {" "}
                    <span className="tet">:</span>{" "}
                    <span className="tetTwo">-</span> {data.value_data1}{" "}
                  </p>
                </Col>
                <Col sm={12} md={6} lg={2} xl={2}>
                  <p className="text-bold">{data.head_data2}</p>
                </Col>
                <Col sm={12} md={6} lg={4} xl={4}>
                  <p style={{ fontSize: "15px" }}>
                    {" "}
                    <span className="tet">:</span>{" "}
                    <span className="tetTwo">-</span> {data.value_data2}{" "}
                  </p>
                </Col>
              </Row>
            )
          })}
        </div>
      )}

    </div>
  )
}
