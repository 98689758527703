var Ip = require('../service/Ip')

export const CHECK_USER_INFO = (token) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}auth/verification-token`,
      headers: {
        'Content-Type': 'application/json'
      },
      data : token
    };
  };

  export const CREATE_PASSWORD = (data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}auth/password-create-email`,
      headers: {
        'Content-Type': 'application/json'
      },
      data : data
    };
  };

  export const LOGIN_PASSWORD = (data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}auth/login`,
      headers: {
        'Content-Type': 'application/json',
      },
      data : data
    };
  };

  export const RESET_NEW_PASSWORD_BY_EMAIL = (data) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}auth/resetpassword`,
      headers: {
        'Content-Type': 'application/json',
      },
      data : data
    };
  };

  export const LOGIN = (data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}auth/login`,
      headers: {
        'Content-Type': 'application/json',
      },
      data : data
    };
  };