import React, { useEffect, useState } from "react"
import * as api from "../../service/Api_Edit_Sub_Product_In_Car"
import axios from "axios"
import { Table, Button, Modal, Tooltip, notification } from "antd"
import Create_New_Sub_Product from "./Create_New_Sub_Product"
import "./Table_data.css"
import { RiDeleteBin6Line } from "react-icons/ri"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { FcSettings } from "react-icons/fc"
import Edit_Sub_Product from './Edit_Sub_Product'
import { useHistory } from "react-router-dom";
import helpers from '../Function_Redirect/Redirect_login'
import { useTranslation } from "react-i18next";

const { confirm } = Modal

export default function Table_Data(props) {
  const token = localStorage.getItem("token")
  const history = useHistory()
  const { t } = useTranslation();
  const [sub_product_data_table, setSub_product_data_table] = useState([])
  const [list_product_model_all, setList_product_model_all] = useState([])
  const [list_warranty_all, setList_warranty_all] = useState([])
  const [loading, setLoading] = useState(false)
  const [visible_create, setVisible_create] = useState(false)
  const [
    create_new_sub_product_data,
    setCreate_new_sub_product_data,
  ] = useState({})
  const [group_id, setGroup_id] = useState(null)
  const [visible_edit, setVisible_edit] = useState(false)
  const [ edit_sub_product_data, setEdit_sub_product_data, ] = useState({})

  useEffect(() => {
    list_sub_product_data()
    list_product_model()
    list_warranty()
    return () => {

    }
  }, [])

  function list_warranty() {
    const list_warranty_data = api.LIST_WARRANTY(token)
    axios(list_warranty_data)
      .then(res => {
        setList_warranty_all(res.data.data)
      })
      .catch(error => {
        if(error.response.status === 401){
          helpers.re_login()
          history.push('/login')          
}

      })
  }
  function list_product_model() {
    const list_data = api.LIST_PRODUCT_MODEL(token)
    axios(list_data)
      .then(res => {
        setList_product_model_all(res.data.data)
      })
      .catch(error => {
        // console.log(error)
      })
  }

  function list_sub_product_data() {
    setLoading(true)
    const list_data = api.LIST_SUB_PRODUCT_ID(token, props.product_id)
    axios(list_data)
      .then(res => {
        setLoading(false)
        setSub_product_data_table(res.data.data)
      })
      .catch(error => {
        setLoading(false)
        // console.log(error)
      })
  }

  const columns = [
    {
      title: t("Product group"),
      dataIndex: "pro_group_name",
      key: "pro_group_name",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Product model"),
      dataIndex: "pro_model_name",
      key: "pro_model_name",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Product number"),
      dataIndex: "pro_no",
      key: "pro_no",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Product serial number"),
      dataIndex: "pro_serial_number",
      key: "pro_serial_number",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Product sub serial number"),
      dataIndex: "pro_sub_serial_number",
      key: "pro_sub_serial_number",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Product remark"),
      dataIndex: "pro_remark",
      key: "pro_remark",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Warranty"),
      dataIndex: "warranty_name",
      key: "warranty_name",
      render: text => <p>{text}</p>,
    },
    {
      title: "",
      dataIndex: "pro_id",
      key: "pro_id",
      render: text => (
        <Tooltip title={t("Remove sub product")}>
          <a>
            <RiDeleteBin6Line
              style={{ color: "red" }}
              size={20}
              onClick={() => remove_sub_product(text)}
            />
          </a>
        </Tooltip>
      ),
    },  {
        title: "",
        render: text => (
          <Tooltip title={t("Edit sub product")}>
            <a>
              <FcSettings
                size={20}
                onClick={() => {
                    setVisible_edit(true)
                    setEdit_sub_product_data(text)
                }}
              />
            </a>
          </Tooltip>
        ),
      },
  ]

  function remove_sub_product(pro_id) {
    return confirm({
      title: t("Confirm remove sub product"),
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: t("Confirm"),
      okType: "danger",
      cancelText: t("Cancel"),
      onOk() {
        const remove_data = api.REMOVE_SUB_PRODUCT(token, pro_id)
        axios(remove_data)
          .then(res => {
        
          })
          .catch(error => {
            if (error.response.status === 404) {
              openNotificationWithIcon("success", t("Remove sub product success"))
              list_sub_product_data()
            } else if(error.response.status === 401){
              helpers.re_login()
              history.push('/login')          
          } else {
            openNotificationWithIcon("error", t("Remove sub product error"))
          }
    
          })
      },
      onCancel() {},
    })
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  return (
    <div>
      <Button
        className="btn-create-new-sub-product"
        type="primary"
        onClick={() => {
          setVisible_create(true)
          setCreate_new_sub_product_data({
            pro_serial_number: null,
            pro_sub_serial_number: null,
            pro_model_id: null,
            pro_remark: null,
            warranty_id: null,
          })
        }}
      >
        {t("Create new sub product")}
      </Button>

      <Table
        loading={loading}
        rowKey="sup_pro_id"
        pagination={false}
        columns={columns}
        dataSource={sub_product_data_table}
        scroll={{ x: 800 }}
      />

   <Modal
      centered
      open={visible_edit}
      footer={false}
      onCancel={() => {
        setVisible_edit(false)
        setEdit_sub_product_data({})
      }}
      width={1000}
    >
      <Edit_Sub_Product
      list_sub_product_data={list_sub_product_data}
      visible_edit={visible_edit}
      setVisible_edit={setVisible_edit}
      setEdit_sub_product_data={setEdit_sub_product_data}
      edit_sub_product_data={edit_sub_product_data}
      list_warranty_all={list_warranty_all}
      list_product_model_all={list_product_model_all}
      />
    </Modal>

      <Modal
        centered
        open={visible_create}
        footer={false}
        onCancel={() => {
          setVisible_create(false)
          setCreate_new_sub_product_data({})
          setGroup_id(null)
        }}
        width={1000}
      >
        <Create_New_Sub_Product
          list_sub_product_data={list_sub_product_data}
          list_product_model_all={list_product_model_all}
          list_warranty_all={list_warranty_all}
          setCreate_new_sub_product_data={setCreate_new_sub_product_data}
          create_new_sub_product_data={create_new_sub_product_data}
          setVisible_create={setVisible_create}
          product_id={props.product_id}
          setGroup_id={setGroup_id}
          group_id={group_id}
        />
      </Modal>
    </div>
  )
}
