import React, { useEffect, useState } from "react"
import { Form, Checkbox, Button, Select,Modal, notification } from "antd"
import { QuestionOutlined } from "@ant-design/icons"
import { Row, Col } from "reactstrap"
import { useHistory } from "react-router-dom"
import axios from "axios"
import * as api from '../../../service/Api_Edit_Employee'
import "../Profile/Profile_Edit.css"
import { useTranslation } from "react-i18next"
import { ExclamationCircleOutlined } from '@ant-design/icons';
import helpers from '../../Function_Redirect/Redirect_login'


const { Option } = Select
const { confirm } = Modal;

export default function Level(props) {
  const { t } = useTranslation()
  const token = localStorage.getItem("token")
  const [loading, setLoading] = useState(false)
  const [data_before_mapping_role, setData_before_mapping_role] = useState([])
  const [data_role_all, setData_role_all] = useState({})
  const history = useHistory()

  const permi = localStorage.getItem('permi')
  const [permi_t, setPermi_t] = useState(false)

useEffect(() => {
  if(permi){
    if(JSON.parse(permi).employee){
      setPermi_t(JSON.parse(permi).employee.edit ? JSON.parse(permi).employee.edit : false)
    }
  }
  return () => {
    // setPermi_t(false)
  }
}, [permi])
  
  useEffect(() => {
    check_role_per()
    return () => {
      
    }
  }, [props.level_id])

  useEffect(() => {
    mapping_data()
    return () => {
      
    }
  }, [data_before_mapping_role])

  function mapping_data() {
let data_obj_all = {}
let data_one_obj = {}
    data_before_mapping_role.map( (data , index) => {
      if(data.emp_module_name !== "employee" ){
        data_one_obj = {
          read: check_true_false(data.can_read) ,
          write: check_true_false(data.can_write) ,
          edit: check_true_false(data.can_edit) ,
          delete: check_true_false(data.can_delete) 
        }
        data_obj_all[data.emp_module_name] = data_one_obj
      }
    })
    setData_role_all(data_obj_all)
  }

  useEffect(() => {
    render_Check_Info()
    return () => {
      
    }
  }, [data_role_all])

  function check_true_false(value) {
    switch (value) {
      case 1:
        return true
        break;
        case 0:
          return false
          break;
      default:
        break;
    }
  }

  function check_role_per() {
    const check_role = api.CHECK_ROLE_PERMISSION(token , props.level_id)
    axios(check_role)
    .then( res => {
      if(res.data.employee_role.length === 0){
        set_data_default()
      } else {
        setData_before_mapping_role(res.data.employee_role)
      }
    })
    .catch( error => {
      // console.log(error.response);
      if(error){
        if(error.response){
          if(error.response.status === 401){
            helpers.re_login()
            history.push('/login')          
    }
        }
      }

    })
  }

  function set_data_default() {
    setData_role_all({
      system_setting : {
        read: false,
        write: false,
        edit: false,
        delete: false
      },
      employee: {
        read: false,
        write: false,
        edit: false,
        delete: false
    },
    customer: {
        read: false,
        write: false,
        edit: false,
        delete: false
    },
    car : {
      read: false,
      write: false,
      edit: false,
      delete: false
    },
    import : {
      read: false,
      write: false,
      edit: false,
      delete: false
    },
    contract : {
      read: false,
      write: false,
      edit: false,
      delete: false
    },
    service : {
      read: false,
      write: false,
      edit: false,
      delete: false
    },
    operator : {
      read: false,
      write: false,
      edit: false,
      delete: false
    },
    dealer : {
      read: false,
      write: false,
      edit: false,
      delete: false
    },
    stock : {
      read: false,
      write: false,
      edit: false,
      delete: false
    }
    })
  }

  function render_Check_Info() {
    return Object.keys(data_role_all).map((key , index) => {
      return (
        <tr key={index}>
          <td> {t(key)}</td>
          <td>
            <Checkbox onChange={e => onChange(e,key, 'read' ) } checked={data_role_all[key].read} />
          </td>
          <td>
            <Checkbox onChange={e => onChange(e,key, 'write' ) } checked={data_role_all[key].write} />
          </td>
          <td>
            <Checkbox onChange={e => onChange(e,key, 'edit' ) } checked={data_role_all[key].edit} />
          </td>
          <td>
            <Checkbox onChange={e => onChange(e,key, 'delete' ) } checked={data_role_all[key].delete} />
          </td>
        </tr>
      )
    });
  }

  function onChange(e , key , module) {
    const test = {...data_role_all}
    test[key][module] = e.target.checked
    setData_role_all(test)
  }

  function edit_data_employee() {
    return confirm({
      title: t("confirm edit employee") ,
      icon: <ExclamationCircleOutlined />,
      centered :true ,
      okText: t("ok"),
      cancelText: t("cancel"),
      onOk() {
        setLoading(true)
        let dataNewLow = {
          ...data_role_all,
          employee: {
            read: false,
            write: false,
            edit: false,
            delete: false
        },
        }
        create_role_permission_new(dataNewLow)
        // create_role_permission_new(data_role_all)
      },
      onCancel() {

      },
    });
  }

  function create_role_permission_new(data_role) {
    const new_role = api.CREATE_NEW_ROLE_PERMISSION(token , data_role)
    axios(new_role)
    .then( res => {
      edit_role(res.data.emp_level_id)
    })
    .catch( error => {
      setLoading(false)
      if(error){
        if(error.response){
          if(error.response.status === 401){
            helpers.re_login()
            history.push('/login')          
          } else {
            openNotificationWithIcon('error', t("edit error please try again")) 
          }
        }
      }
    })
  }

  function edit_role(level_id_new) {
    const data = {
      emp_level_id: level_id_new
    }
    const edit_ = api.EDIT_DATA(token,props.id,data)
    axios(edit_)
    .then( res => {
      openNotificationWithIcon('success',t("edit success"))
      setLoading(false)
    })
    .catch( error => {
      setLoading(false)
      if(error){
        if(error.response){
          if(error.response.status === 401){
            helpers.re_login()
            history.push('/login')          
          } else {
            openNotificationWithIcon('error', t("edit error please try again")) 
          }
        }
      }
    })
  }

  const openNotificationWithIcon =( type, message ) => {
    notification[type]({
      message: message,
    });
  };

  return (
    <div>
        <div className="mt-bottom-all" style={{ overflowX: "scroll" }}>
          <p className="text-bold"> {t("role and Permission Setting")} </p>
<br />
          <div>
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>{t("module")}</th>
                <th>{t("read")}</th>
                <th>{t("write")}</th>
                <th>{t("edit")}</th>
                <th>{t("delete")}</th>
              </tr>
            </thead>
            <tbody>{render_Check_Info()}</tbody>
          </table>
        </div>
        </div>
        <br />
        <div>

            <Button
            type="primary"
            className="button-edit-employee"
            loading={loading}
            style={{
              width:'150px',
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              // backgroundColor : '#0084d1', 
              // borderColor:'#0084d1'
            }}
            onClick={() => edit_data_employee()}
          >
          {t("edit")}
          </Button>

        </div>

    </div>
  )
}