import React, { useEffect, useState } from "react"
import * as api from "../../../../service/API_Edit_Car"
import axios from "axios"
import List_Product_Group from "./List_Product_Group"
import { Row, Col } from "reactstrap"
import { Input, Pagination, Table, Tooltip } from "antd"
import { FcDataSheet } from "react-icons/fc"
import Render_Image from "../../../New_Customer/Devices/Render_Image"
import { useHistory } from "react-router-dom"
import { FcViewDetails } from "react-icons/fc"
import helpers from "../../../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next";

const { Search } = Input

export default function Device(props) {
  const { t } = useTranslation();
  const token = localStorage.getItem("token")
  const history = useHistory()
  const [select_product_group, setSelect_product_group] = useState(null)
  const [data_search_device_in_car, setData_search_device_in_car] = useState(
    null
  )
  const [device_table, setDevice_table] = useState([])
  const [loading, setLoading] = useState(false)
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })

  useEffect(() => {
    check_device_car_all()
    return () => {
      setDevice_table([])
      setData_search_device_in_car(null)
      setSelect_product_group(null)
    }
  }, [props.car_id])

  useEffect(() => {
    check_device_car_all()
    return () => {}
  }, [select_product_group])

  function set_data_search(e) {
    if (e) setData_search_device_in_car(e)
    else setData_search_device_in_car(null)
  }

  function onSearch() {
    check_device_car_all()
  }

  function check_device_car_all() {
    setLoading(true)
    if (data_search_device_in_car !== null && select_product_group === null) {
      const check_device_data = api.LIST_DEVICE_DATA_NO_PRODUCT_ID(
        token,
        props.car_id,
        1,
        page_limit.page_Size,
        data_search_device_in_car
      )
      axios(check_device_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setDevice_table(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else if (
      data_search_device_in_car === null &&
      select_product_group === null
    ) {
      const check_device_no_data = api.LIST_DEVICE_NO_DATA_NO_PRODUCT_ID(
        token,
        props.car_id,
        1,
        page_limit.page_Size
      )
      axios(check_device_no_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setDevice_table(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else if (
      data_search_device_in_car !== null &&
      select_product_group !== null
    ) {
      const device_has_product_id = api.LIST_DEVICE_DATA_PRODUCT_ID(
        token,
        props.car_id,
        select_product_group,
        1,
        page_limit.page_Size,
        data_search_device_in_car
      )
      axios(device_has_product_id)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setDevice_table(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else if (
      data_search_device_in_car === null &&
      select_product_group !== null
    ) {
      const device_no_search_has_product_id = api.LIST_DEVICE_NO_DATA_PRODUCT_ID(
        token,
        props.car_id,
        select_product_group,
        1,
        page_limit.page_Size
      )
      axios(device_no_search_has_product_id)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setDevice_table(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  function changePagination(page, pageSize) {
    setLoading(true)
    if (data_search_device_in_car !== null && select_product_group === null) {
      const check_device_data = api.LIST_DEVICE_DATA_NO_PRODUCT_ID(
        token,
        props.car_id,
        page,
        pageSize,
        data_search_device_in_car
      )
      axios(check_device_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setDevice_table(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else if (
      data_search_device_in_car === null &&
      select_product_group === null
    ) {
      const check_device_no_data = api.LIST_DEVICE_NO_DATA_NO_PRODUCT_ID(
        token,
        props.car_id,
        page,
        pageSize
      )
      axios(check_device_no_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setDevice_table(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else if (
      data_search_device_in_car !== null &&
      select_product_group !== null
    ) {
      const device_has_product_id = api.LIST_DEVICE_DATA_PRODUCT_ID(
        token,
        props.car_id,
        select_product_group,
        page,
        pageSize,
        data_search_device_in_car
      )
      axios(device_has_product_id)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setDevice_table(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else if (
      data_search_device_in_car === null &&
      select_product_group !== null
    ) {
      const device_no_search_has_product_id = api.LIST_DEVICE_NO_DATA_PRODUCT_ID(
        token,
        props.car_id,
        select_product_group,
        page,
        pageSize
      )
      axios(device_no_search_has_product_id)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setDevice_table(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  const columns = [
    {
      title: t("Product group"),
      dataIndex: "pro_group_name",
      key: "pro_group_name",
      render: text => <p>{text}</p>,
    },
    {
      title: "",
      dataIndex: "pro_model_img",
      key: "pro_model_img",
      render: text => <Render_Image path_image={text} size={60} />,
    },
    {
      title: t("Product serial number"),
      dataIndex: "pro_serial_number",
      key: "pro_serial_number",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Product model name"),
      dataIndex: "pro_model_name",
      key: "pro_model_name",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Product model protocol"),
      dataIndex: "pro_model_protocol",
      key: "pro_model_protocol",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Product model code"),
      dataIndex: "pro_model_code",
      key: "pro_model_code",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Asset number"),
      dataIndex: "sto_asset_number",
      key: "sto_asset_number",
      render: text => <p>{text}</p>,
    },
    {
      title: "",
      dataIndex: "pro_id",
      key: "pro_id",
      render: text => (
        <Tooltip title={t("View sub product")} >
          <a>
            <FcViewDetails
              size={20}
              style={{ marginTop: "-15px" }}
              onClick={() =>
                history.push(
                  `/customers/${props.cus_id}/view-car/${props.car_id}/sub-product/${text}`
                )
              }
            />
          </a>
        </Tooltip>
      ),
    },
  ]

  return (
    <div>
      <List_Product_Group setSelect_product_group={setSelect_product_group} />
      <br />
      <Row>
        <Col sm={12} md={8} lg={6} xl={6}>
          <Search
            style={{ width: "100%" }}
            placeholder={t("Product serial number")}
            enterButton={t("Search")}
            size="medium"
            onSearch={onSearch}
            onChange={e => set_data_search(e.target.value)}
          />
        </Col>
      </Row>
      <br />
      <Table
        loading={loading}
        rowKey="sto_id"
        pagination={false}
        columns={columns}
        dataSource={device_table}
        scroll={{ x: 800 }}
      />

      <div style={{ float: "right", marginTop: "10px" }}>
        <Pagination
          current={page_limit.page}
          total={page_limit.limit}
          onChange={changePagination}
        />
      </div>
      <p style={{ fontSize: "15px", marginTop: "10px" }}>
        {" "}
        <FcDataSheet size={20} style={{ marginBottom: "5px" }} /> {t("total data")} :{" "}
        {page_limit.limit}
      </p>
    </div>
  )
}
