import React from "react"
import { Form, Input } from "antd"

export default function InputPassword({ header , keyForm , setState }) {
    return (
        <div>
            <p style={{ fontWeight: "bold" }}>
          {header} <span style={{ color: "red" }}>*</span>
            </p>
            <Form.Item
              label=""
              name={keyForm}
              rules={[
                {
                  required: true,
                  message: header,
                },
              ]}
            >
              <Input.Password onChange={e => setState(e.target.value)} />
            </Form.Item>
        </div>
    )
}
