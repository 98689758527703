import React, { useEffect, useState } from "react"
import { Select, Form, Button, Modal, notification } from "antd"
import { Row, Col } from "reactstrap"
import axios from "axios"
import * as api from "../../../../service/Api_New_Car"
import Select_Filter_User from "./Select_Filter_User"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { useHistory } from "react-router-dom"
import helpers from "../../../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"

const { confirm } = Modal

const { Option } = Select

export default function Send_Action(props) {
  const { t } = useTranslation()
  const token = localStorage.getItem("token")
  const [context, setContext] = useState(null)
  const history = useHistory()
  const [id_message, setId_message] = useState(null)
  const [user_data, setUser_data] = useState({
    cus_user_id: null,
    cus_user_firstname: null,
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setUser_data({
      cus_user_id: null,
      cus_user_firstname: null,
    })
    setContext(null)
    setId_message(null)
    return () => {}
  }, [props.car_id])

  useEffect(() => {
    render_notification_list()
    return () => {}
  }, [props.notification_list])

  function onChange(value) {
    if (value) {
      setId_message(value)
      const list_message = api.LIST_MESSAGE_ACTION(token, value)
      axios(list_message)
        .then(res => {
          setContext({
            msg_context: res.data.data.msg_context,
            msg_footer: res.data.data.msg_footer,
            msg_header: res.data.data.msg_header,
          })
        })
        .catch(error => {
          console.log(res.data)
        })
    } else {
      setId_message(null)
    }
  }

  function render_notification_list() {
    return props.notification_list.map(data => {
      return (
        <Option value={data.id} key={data.id}>
          {data.type_name}
        </Option>
      )
    })
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  function send_Message() {
    return confirm({
      title: t("confirm send message"),
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: t("send"),
      cancelText: t("no"),
      onOk() {
        setLoading(true)
        const send_data = api.SEND_MESSAGE_NOTIFICATION(
          token,
          user_data.cus_user_id,
          id_message,
          props.car_id
        )
        axios(send_data)
          .then(res => {
            setLoading(false)
            openNotificationWithIcon("success", t("send message email success"))
            setUser_data({
              cus_user_id: null,
              cus_user_firstname: null,
            })
            setContext(null)
            setId_message(null)
            props.setCar_id(null)
            props.setModal_action(false)
          })
          .catch(error => {
            setLoading(false)
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            } else {
              openNotificationWithIcon("error", t("send message email error"))
            }
          })
      },
      onCancel() {
        // console.log("Cancel")
      },
    })
  }

  return (
    <div className="scroll-modal-display-mobile-app">
      <p className="text-bold">
        {t("select action")} <span style={{ color: "red" }}>*</span>{" "}
      </p>
      <Form.Item
        name="action"
        rules={[
          {
            required: false,
          },
        ]}
      >
        <Select
          showSearch
          style={{ width: "100%" }}
          placeholder={t("select action")}
          optionFilterProp="children"
          onChange={onChange}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {render_notification_list()}
        </Select>
      </Form.Item>

      <Select_Filter_User
        id={props.id}
        setUser_data={setUser_data}
        user_data={user_data}
      />
      <br />
      {context !== null && user_data.cus_user_firstname !== null && (
        <div>
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <p style={{ fontSize: "20px" }}>{t("message example")}</p>
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
              <Button
                loading={loading}
                type="primary"
                className="btn-create-new-noti"
                onClick={() => send_Message()}
              >
                {t("send message")}
              </Button>
            </Col>
          </Row>
          <div style={{ border: "1px solid #D9D9D9", padding: "20px" }}>
            <p style={{ fontSize: "25px", fontWeight: "bold" }}>
              {t("dear")} {user_data.cus_user_firstname}
            </p>
            <p style={{ fontSize: "30px" }}>{context.msg_header}</p>
            <p style={{ fontSize: "20px" }}>{context.msg_context}</p>
            <p style={{ fontSize: "20px" }}>{context.msg_footer}</p>
          </div>
        </div>
      )}
    </div>
  )
}
