import React from 'react'
import { Col } from "reactstrap"
import ReactHtmlParser from 'react-html-parser';

export default function Col_Resolution_SLA({ header_text, value_text }) {
    const text_header = {
        fontWeight: "bold",
        fontSize: "15px",
      }
    return (
<>
        <Col sm={6} md={2} lg={2} xl={2}>
        <p style={text_header}> {header_text} </p>
      </Col>
      <Col sm={6} md={10} lg={10} xl={10}>
      {ReactHtmlParser(value_text)}
      </Col>
        </>
    )
}
