import React, { useState, useEffect } from "react"
import { Select, Form } from "antd"
import * as api from "../../../service/Api_New_Car"
import axios from "axios"

const { Option } = Select
export default function Select_Data_Search_Filter({
  header,
  keyState,
  name_api,
  headState,
  nameState,
  map_name,
  placeholder,
  map_value,
  name_api_all
}) {
  const token = localStorage.getItem("token")
  const [data_select, setData_select] = useState([])

  useEffect(() => {
    onSearch()
    return () => {
      setData_select([])
    }
  }, [])

  function onChange(value) {
    if (value) {
      headState({ ...nameState, [keyState]: value })
    } else {
      headState({ ...nameState, [keyState]: null })
    }
  }

  function onSearch(val) {
    if (val) {
        const get_province_info = api[name_api](token, val)
        axios(get_province_info)
          .then(res => {
            setData_select(res.data.message)
          })
          .catch(error => {
            // console.log(error)
          })
      } else {
        const get_province_info = api[name_api_all](token)
        axios(get_province_info)
          .then(res => {
            setData_select(res.data.message)
          })
          .catch(error => {
            // console.log(error)
          })
      }
  }

  useEffect(() => {
    render_select_data()
    return () => {}
  }, [data_select])

  function render_select_data() {
    return data_select.map(data => {
      return (
        <Option key={data[map_value]} value={data[map_value]}>
          {data[map_name]}
        </Option>
      )
    })
  }
  return (
    <div>
      <p className="text-bold">{header}</p>
      <Select
      showSearch
      allowClear
      placeholder={placeholder}
      style={{ width: "100%" }}
      optionFilterProp="children"
      onChange={onChange}
      onSearch={onSearch}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {render_select_data()}
    </Select>
    </div>
  )
}
