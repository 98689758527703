import React,{ useState, useEffect } from "react"
import { Button, Form, Modal } from "antd"
import { Row, Col } from "reactstrap"
import Input_Comp from './components/Input_Comp'
import Select_Search_Comp from './components/Select_Search_Comp'
import Select_no_search from './components/Select_no_search'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";

const { confirm } = Modal;


export default function Create_New_Car_Only(props) {
    const [form_create_car_only] = Form.useForm();
    const { t } = useTranslation();

    useEffect(() => {
        if(props.customer_create === false){
            form_create_car_only.resetFields()
        }
        return () => {
            
        }
    }, [props.car_create_only])

    const onFinish = value => {
        return confirm({
            title: t("Confirm create data"),
            icon: <ExclamationCircleOutlined style={{ color:"#1890ff" }} />,
            okText: t("create"),
            cancelText: t("no"),
            centered:true,
            onOk() {
                props.setVisible(false)
                props.setCar_create_only(true)
            },
            onCancel() {
             
            },
          });
    }

    const cancel = value => {
        props.setVisible(false)
        form_create_car_only.resetFields()
        props.setCar_create_only(false)
        props.setCreate_new_car({})
    }


    // setCreate_new_car={props.setCreate_new_car}
    // create_new_car={props.create_new_car}

    // car_create_only={props.car_create_only}           false or true
    // setCar_create_only={props.setCar_create_only}     set false or true



    return (
        <div>
        <Form
        form={form_create_car_only}
        name="create_new_car_in_customer"
        initialValues={{
            remember: true,
        }}
        onFinish={onFinish}
    >
    <Row>
        <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp 
            header={t("License no")}
            placeholder={t("License no")}
            headState={props.setCreate_new_car}
            nameState={props.create_new_car}
            keyState='car_license_number'
            />
        </Col>
        <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp 
                header={t("Car name")}
                placeholder={t("Car name")}
                headState={props.setCreate_new_car}
                nameState={props.create_new_car}
                keyState='car_name'
            />
        </Col>
    </Row>

    <Row>
        <Col sm={12} md={6} lg={6} xl={6}>
            <Select_Search_Comp 
                header={t("Province")}
                placeholder={t("Province")}
                headState={props.setCreate_new_car}
                nameState={props.create_new_car}
                keyState='car_license_province_id'
            />
        </Col>
        <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search 
                header={t("Color")}
                placeholder={t("Color")}
                name_api='LIST_CAR_COLOR'
                keyState="car_color_id"
                map_name="car_color_name"
                headState={props.setCreate_new_car}
                nameState={props.create_new_car}
            />
        </Col>
    </Row>

    <Row>
        <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp 
                header={t("Chassis number")}
                placeholder={t("Chassis number")}
                headState={props.setCreate_new_car}
                nameState={props.create_new_car}
                keyState='car_chassis_number'
            />
        </Col>
        <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search 
                header={t("Body type")}
                name_api='LIST_CAR_BODY_TYPE'
                map_name="car_body_type_name"
                keyState="car_body_type_id"
                placeholder={t("Body type")}
                headState={props.setCreate_new_car}
                nameState={props.create_new_car}
            />
        </Col>
    </Row>

    <Row>
        <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search 
                header={t("Brand")}
                name_api='LIST_CAR_BRAND'
                map_name="car_brand_name"
                keyState="car_brand_id"
                placeholder={t("Brand")}
                headState={props.setCreate_new_car}
                nameState={props.create_new_car}
            />
        </Col>
        <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search 
                header={t("Model")}
                name_api='LIST_CAR_MODEL'
                map_name="car_model_name"
                keyState="car_model_id"
                placeholder={t("Model")}
                headState={props.setCreate_new_car}
                nameState={props.create_new_car}
            />
        </Col>
    </Row>

    <Row>
        <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search 
                header={t("Year")}
                name_api='LIST_CAR_YEAR'
                map_name="car_model_year_name"
                keyState="car_model_year_id"
                placeholder={t("Year")}
                headState={props.setCreate_new_car}
                nameState={props.create_new_car}
            />
        </Col>
    </Row>

    <br />
    <Row>
    <Col sm={12} md={6} lg={6} xl={6}>
        <div className="div_create_new_car">
            <Button className="btn_cancel_create_new_car" onClick={() => cancel()} > {t("Cancel")} </Button>   
        </div>
    </Col>
    <Col sm={12} md={6} lg={6} xl={6}>
        <div className="div_create_new_car">
            <Button className="btn_submit_create_new_car" type="primary" htmlType="submit"  > {t("Confirm")} </Button>
        </div>
    </Col>
    </Row>
  
    </Form>
        </div>
    )
}
