import React from "react"
import { Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next"
import "./Service_Style.css"

export default function Detail_Service(props) {
  const { t } = useTranslation()
  const text_header = {
    fontWeight: "bold",
    fontSize: "15px",
  }

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return "N/A"
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  function checkData(data) {
    if (data) {
      return data
    } else {
      return "N/A"
    }
  }

  function check_Status(data) {
    if (data) {
      return t(data)
    } else {
      return "N/A"
    }
  }
  
  const data_map = [
    {
      header1: t("Service number"),
      dataService1: checkData(props.data_Service_modal.ser_no),
      header2: t("Create on"),
      dataService2: cutTimeZone(props.data_Service_modal.created_on),
    },
    {
      header1: t("Service status"),
      dataService1: check_Status(props.data_Service_modal.ser_status_name),
      header2: t("Priority"),
      dataService2: check_Status(props.data_Service_modal.pri_name),
    },
    {
      header1: t("Service booking date"),
      dataService1: cutTimeZone(props.data_Service_modal.ser_booking_date),
      header2: t("Service booking time"),
      dataService2: checkData(props.data_Service_modal.ser_booking_time),
    },
    {
      header1: t("Car license"),
      dataService1: checkData(props.data_Service_modal.car_license_number),
      header2: t("Car name"),
      dataService2: checkData(props.data_Service_modal.car_name),
    },
    {
      header1: t("Customer name"),
      dataService1: checkData(props.data_Service_modal.cus_name),
      header2: t("Customer code"),
      dataService2: checkData(props.data_Service_modal.cus_code),
    },
  ]

  function renderStatusFromUser(status) {
    if(status.is_user_cancel === 1 && status.is_user_confirm === 0){
        return (
            <Row >
            <Col sm={6} md={2} lg={2} xl={2}>
            <p style={text_header}>{t("Customer confirmation status")}</p>
            </Col>
            <Col sm={6} md={4} lg={4} xl={4}>
              <p style={{ fontSize: "15px" }}>
                {" "}
                <span className="tet">
                  :
                </span> <span className="tetTwo">-</span> {t("Customer cancel")}
              </p>
            </Col>
            <Col sm={6} md={2} lg={2} xl={2}>
              <p style={text_header}>{t("Customer cancel remark")}</p>
            </Col>
            <Col sm={6} md={4} lg={4} xl={4}>
              <p style={{ fontSize: "15px" }}>
                {" "}
                <span className="tet">
                  :
                </span> <span className="tetTwo">-</span> {status.is_user_remark}
              </p>
            </Col>
          </Row>
        )
      } else if(status.is_user_cancel === 0 && status.is_user_confirm === 1){
        return (
            <Row >
            <Col sm={6} md={2} lg={2} xl={2}>
              <p style={text_header}>{t("Customer confirmation status")}</p>
            </Col>
            <Col sm={6} md={4} lg={4} xl={4}>
              <p style={{ fontSize: "15px" }}>
                {" "}
                <span className="tet">
                  :
                </span> <span className="tetTwo">-</span> {t("Customer confirm")}
              </p>
            </Col>
          </Row>
        )
      } else {
        return (
            <Row >
            <Col sm={6} md={2} lg={2} xl={2}>
              <p style={text_header}>{t("Customer confirmation status")}</p>
            </Col>
            <Col sm={6} md={4} lg={4} xl={4}>
              <p style={{ fontSize: "15px" }}>
                {" "}
                <span className="tet">
                  :
                </span> <span className="tetTwo">-</span> {t("Waiting for customer confirmation")}
              </p>
            </Col>
          </Row>
        )
      }
  }

  return (
    <div className="modal-detail-service-admin" >
      <p
        style={{
          textAlign: "center",
          fontWeight: "bold",
          marginTop: "10px",
          fontSize: "15px",
        }}
      >
        {" "}
        {t("Service information")}{" "}
      </p>
      <hr />

      {data_map.map((data, index) => {
        return (
          <Row key={index}>
            <Col sm={6} md={2} lg={2} xl={2}>
              <p style={text_header}>{data.header1}</p>
            </Col>
            <Col sm={6} md={4} lg={4} xl={4}>
              <p style={{ fontSize: "15px" }}>
                {" "}
                <span className="tet">
                  :
                </span> <span className="tetTwo">-</span> {data.dataService1}
              </p>
            </Col>
            <Col sm={6} md={2} lg={2} xl={2}>
              <p style={text_header}>{data.header2}</p>
            </Col>
            <Col sm={6} md={4} lg={4} xl={4}>
              <p style={{ fontSize: "15px" }}>
                {" "}
                <span className="tet">
                  :
                </span> <span className="tetTwo">-</span> {data.dataService2}
              </p>
            </Col>
          </Row>
        )
      })}

      {renderStatusFromUser(props.data_Service_modal)}

    </div>
  )
}
