import PropTypes from "prop-types"
import React from "react"

// import { Redirect , Route, Switch, BrowserRouter as Router } from "react-router-dom"
import { Switch, Route, Redirect } from "react-router-dom"

import { connect, useSelector } from "react-redux"

// Import Routes all
import { userRoutes, authRoutes, No_Token } from "./routes/allRoutes"
import { operator_Routes } from "./routes/All_Operator"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

// layouts Format

import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"
import Auth from "./Auth"
import Auth_Employee from "./Auth_Employee"
// auth
import Login from "./pages/Authentication/Login"

//admin
import AdminPage from "./pages/AdminPage"
import Admin_Group from "./pages/Admin_Group"
import Admin_In_Group from "./pages/Admin_In_Group"
import Create_New_Admin from "./pages/Create_New_Admin"
import Edit_Admin from "./pages/Edit_Admin"
import Request_Repair_Admin from "./pages/Request_Repair"
import Profile_Admin from "./pages/Profile_Admin"
import CreateMultipleService from './pages/CreateMultipleService'

// employee
import Profile_Employee from "./pages/Profile_Employee"
import Dashboard from "./pages/Dashboard"
import DashboardsSLA from "./pages/DashboardsSLA"
import Employee from "./pages/Employee"
import Verification_Email from "./pages/Verification_Email"
import New_Employee from "./pages/New_Employee"
import Edit_Employee from "./pages/Edit_Employee"
import New_Customer from "./pages/New_Customer"
import Customers from "./pages/Customers"
import Edit_car_in_customer from "./pages/Edit_car_in_customer"
import Edit_Car from "./pages/Edit_Car"
import New_Car from "./pages/New_Car"
import Cars from "./pages/Cars"
import Edit_Device from "./pages/Edit_Device"
import Contract from "./pages/Contract"
import Edit_Contract from "./pages/Edit_Contract"
import New_Contract from "./pages/New_Contract"
import Create_new_customer from "./pages/Create_new_customer"
import Edit_contract_in_customer from "./pages/Edit_contract_in_customer"
import Edit_service_in_contract_in_customer from "./pages/Edit_service_in_contract_in_customer"
import Edit_service_in_contact from "./pages/Edit_service_in_contact"
import Operator from "./pages/Operator"
import New_Operator from "./pages/New_Operator"
import Edit_Operator from "./pages/Edit_Operator"
import Dealer from "./pages/Dealer"
import Create_new_dealer from "./pages/Create_new_dealer"
import Edit_Dealer from "./pages/Edit_Dealer"
import Stock from "./pages/Stock"
import New_Stock from "./pages/New_Stock"
import Edit_Stock from "./pages/Edit_Stock"
import Service from "./pages/Service"
import New_Service from "./pages/New_Service"
import Edit_Service from "./pages/Edit_Service"
import Select_Dealer_From_DB_Distar from "./pages/Select_Dealer_From_DB_Distar"
import Select_Car_From_DB_Distar from "./pages/Select_Car_From_DB_Distar"
import Import_Car_From_DB_Distar from "./pages/Import_Car_From_DB_Distar"
import Select_Customer_From_Distar_DB from "./pages/Select_Customer_From_Distar_DB"
import Import_Data_Select from "./pages/Import_Data_Select"
import Import_Customer_From_DB_Distar from "./pages/Import_Customer_From_DB_Distar"
import Import_Dealer_From_db_distar from "./pages/Import_Dealer_From_db_distar"
import Import_Stock_From_DB_Distar from "./pages/Import_Stock_From_DB_Distar"
import Setting from "./pages/Setting"
import Edit_Sub_Product_In_Car from "./pages/Edit_Sub_Product_In_Car"
import Edit_Sub_Product_In_Customer from "./pages/Edit_Sub_Product_In_Customer"
import Edit_User_In_Customer from "./pages/Edit_User_In_Customer"
import New_Service_Has_Stock_Id from "./pages/New_Service_Has_Stock_Id"
import New_Service_Has_Stock_From_Distar from "./pages/New_Service_Has_Stock_From_Distar"
import System_Settings from "./pages/System_Settings"
import Product_group from "./pages/System_Settings/Product_group"
import product_model from "./pages/System_Settings/product_model"
import Warranty from "./pages/System_Settings/Warranty"
import Car_Body_Type from "./pages/System_Settings/Car_Body_Type"
import Car_Brand from "./pages/System_Settings/Car_Brand"
import Car_Color from "./pages/System_Settings/Car_Color"
import Car_Model from "./pages/System_Settings/Car_Model"
import Car_Group from "./pages/System_Settings/Car_Group"
import Car_Model_Year from "./pages/System_Settings/Car_Model_Year"
import Department from "./pages/System_Settings/Department"
import Position from "./pages/System_Settings/Position"
import Trigger_Event from "./pages/System_Settings/Trigger_Event"
import Operator_Group from "./pages/System_Settings/Operator_Group"
import Fileupload from "./pages/System_Settings/Fileupload"
import Service_type from "./pages/System_Settings/Service_type"
import Employee_Group from "./pages/System_Settings/Employee_Group"
import Notification from "./pages/Notification"
import Edit_Dealer_User from "./pages/Edit_Dealer_User"
import Scheduler from "./pages/Scheduler"
import Email_Notification from "./pages/System_Settings/Email_Notification"
import Task from "./pages/System_Settings/Task"
import Edit_Task_System_Setting from "./pages/Edit_Task_System_Setting"
import Sla from "./pages/System_Settings/SLA"
import Report_Admin from "./pages/Report_Admin"
import Report_Admin_Car from "./pages/Report_Admin/Car"
import Report_Admin_Contract from "./pages/Report_Admin/Contract"
import Report_Admin_Operator from "./pages/Report_Admin/Operator"
import Report_Admin_Product from "./pages/Report_Admin/Product"
import Report_Admin_Repair from "./pages/Report_Admin/Repair"
import Check_Request_Repair from "./pages/Check_Request_Repair"
import Repair_History from "./pages/Repair_History"
import Line_Setting from "./pages/System_Settings/Line"

// operator
import Profile_Operator from "./pages/Profile_Operator"
import Dashboard_Operator from "./pages/Operator_All/Dashboard"
import Service_Operator from "./pages/Operator_All/Service_Operator"
import Report_Operator from "./pages/Report_Operator"
import Report_Operator_Car from "./pages/Report_Operator/Car"
import Report_Operator_Contract from "./pages/Report_Operator/Contract"
import Report_Operator_Product from "./pages/Report_Operator/Product"
import Report_Operator_Repair from "./pages/Report_Operator/Repair"

// customer
import Profile_Customer from "./pages/Profile_Customer"
import Dashboard_Customer from "./pages/Customer_All/Dashboard"
import Cars_Customer from "./pages/Customer_All/Cars"
import Detail_Car_Customer from "./pages/Customer_All/Detail_Car"
import Sub_Product_Car_Customer from "./pages/Customer_All/Sub_Product_Car"
import Device_Customer from "./pages/Customer_All/Device"
import Contract_Customer from "./pages/Customer_All/Contract"
import Detail_Contract_Customer from "./pages/Customer_All/Detail_Contract"
import Service_Customer from "./pages/Customer_All/Service"
import Report_Customer from "./pages/Report_Customer"
import Report_Customer_Car from "./pages/Report_Customer/Car"
import Report_Customer_Contract from "./pages/Report_Customer/Contract"
import Report_Customer_Product from "./pages/Report_Customer/Product"
import Report_Customer_Repair from "./pages/Report_Customer/Repair"
import Repair_Customer from "./pages/Customer_All/Repair"
import Create_New_Repair_Customer from "./pages/Customer_All/Create_New_Repair"
import Form_Create_Service_Customer from "./pages/Customer_All/Form_Create_Service"
import Detail_Repair_Customer from "./pages/Customer_All/Detail_Repair"

// dealer
import Profile_Dealer from "./pages/Profile_Dealer"
import Dashboard_Dealer from "./pages/Dealer_All/Dashboard"
import Customer_Dealer from "./pages/Dealer_All/Customer"
import View_Customer_Dealer from "./pages/Dealer_All/View_Customer"
import View_Car_In_Customer_Dealer from "./pages/Dealer_All/View_Car_In_Customer"
import View_Sub_Product_In_Customer from "./pages/Dealer_All/View_Sub_Product_In_Customer"
import View_Contract_In_Customer_Customer from "./pages/Dealer_All/View_Contract_In_Customer"
import Report_Dealer from "./pages/Report_Dealer"
import Report_Dealer_Car from "./pages/Report_Dealer/Car"
import Report_Dealer_Contract from "./pages/Report_Dealer/Contract"
import Report_Dealer_Product from "./pages/Report_Dealer/Product"
import Report_Dealer_Repair from "./pages/Report_Dealer/Repair"
import Repair_Dealer from "./pages/Dealer_All/Repair"
import Create_New_Repair_Dealer from "./pages/Dealer_All/Create_New_Repair"
import Form_Create_Service_Dealer from "./pages/Dealer_All/Form_Create_Service_Dealer"
import Detail_Repair_Dealer from "./pages/Dealer_All/Detail_Repair"

import Status_Service_Public from "./pages/Status_Service_Public"

import Notification_Customer_User_Dealer from "./pages/Notification_Customer_User_Dealer"

import Login_Line_Liff from "./pages/Login_Line_Liff"

import ResetPasswordFromToken from "./pages/ResetPasswordFromToken"

import Read_Excel from "./pages/ReaderPage/Excel"
import Read_PDF from "./pages/ReaderPage/PDF"
import Read_Word from "./pages/ReaderPage/Word"
import Read_Image from "./pages/ReaderPage/Image"

import Visitor_No_Token from "./pages/Visitor_No_Token"
import "./App.css"
const App = props => {
  function getLayout() {
    let layoutCls = HorizontalLayout
    return layoutCls
  }
  const role = localStorage.getItem("role")
  const r = localStorage.getItem("r")
  const { isAdminRoot, isRole } = useSelector(({ Distar }) => Distar)
  const Layout = getLayout()

  return (
    // <Router>
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route
        exact
        path="/verification-email/:email"
        component={Verification_Email}
      />
      <Route
        exact
        path="/resetpassword-email/:email"
        component={ResetPasswordFromToken}
      />
      <Route
        exact
        path="/service-status/:status"
        component={Status_Service_Public}
      />
      <Route exact path="/login-line" component={Login_Line_Liff} />

      <Route exact path="/read-excel/:fup_path" component={Read_Excel} />
      <Route exact path="/read-pdf/:fup_path" component={Read_PDF} />
      <Route exact path="/read-word/:fup_path" component={Read_Word} />
      <Route exact path="/read-image/:fup_path" component={Read_Image} />
      <Auth_Employee>
        {isRole === "administrator" && (
          <>
            <Route
              exact
              path="/"
              component={() => <Redirect to="/dashboards" />}
            />

            <Route exact path="/dashboards" component={Dashboard} />
            <Route exact path="/sla-dashboards" component={DashboardsSLA} />
            {isAdminRoot === true && (
              <>
                <Route exact path="/admin-management" component={AdminPage} />
                <Route
                  exact
                  path="/admin-management/:admin_id"
                  component={Edit_Admin}
                />
                <Route exact path="/admin/group" component={Admin_Group} />
                <Route
                  exact
                  path="/admin/group/:admin_group_id"
                  component={Admin_In_Group}
                />
                <Route
                  exact
                  path="/create-new-admin"
                  component={Create_New_Admin}
                />
              </>
            )}

            <Route exact path="/profile" component={Profile_Admin} />

            <Route exact path="/employee" component={Employee} />
            <Route exact path="/new-employee" component={New_Employee} />
            <Route
              exact
              path="/employee/:employee_id"
              component={Edit_Employee}
            />

            <Route exact path="/cars" component={Cars} />
            <Route exact path="/new-cars" component={New_Car} />
            <Route exact path="/cars/:car_id" component={Edit_Car} />
            <Route
              exact
              path="/cars/:car_id/sub-product/:product_id"
              component={Edit_Sub_Product_In_Car}
            />

            <Route exact path="/import-data" component={Import_Data_Select} />
            {/* <Route exact path="/import-data/dealer" component={Import_Dealer_From_db_distar} />
<Route exact path="/import-data/dealer/select-dealer" component={Select_Dealer_From_DB_Distar} /> */}
            <Route
              exact
              path="/import-data/car"
              component={Import_Car_From_DB_Distar}
            />
            <Route
              exact
              path="/import-data/car/:gid"
              component={Select_Car_From_DB_Distar}
            />
            <Route
              exact
              path="/import-data/stock"
              component={Import_Stock_From_DB_Distar}
            />
            <Route
              exact
              path="/import-data/customer"
              component={Import_Customer_From_DB_Distar}
            />
            <Route
              exact
              path="/import-data/customer/select-customer"
              component={Select_Customer_From_Distar_DB}
            />

            <Route exact path="/contract" component={Contract} />
            <Route exact path="/contract/:con_id" component={Edit_Contract} />
            <Route exact path="/new-contract" component={New_Contract} />
            <Route
              exact
              path="/contract/:con_id/edit-service/:ser_id"
              component={Edit_service_in_contact}
            />

            <Route
              exact
              path="/create-new-customer"
              component={Create_new_customer}
            />
            <Route exact path="/customers" component={Customers} />
            <Route
              exact
              path="/customers/:profile_id"
              component={New_Customer}
            />
            <Route
              exact
              path="/customers/:profile_id/edit-user/:user_id"
              component={Edit_User_In_Customer}
            />
            <Route
              exact
              path="/customers/:profile_id/edit-contract/:con_id"
              component={Edit_contract_in_customer}
            />
            <Route
              exact
              path="/customers/:profile_id/edit-car/:car_id"
              component={Edit_car_in_customer}
            />
            <Route
              exact
              path="/customers/:profile_id/edit-car/:car_id/sub-product/:product_id"
              component={Edit_Sub_Product_In_Customer}
            />
            <Route
              exact
              path="/customers/:profile_id/edit-device/:sto_id"
              component={Edit_Device}
            />
            <Route
              exact
              path="/customers/:profile_id/edit-contract/:con_id/edit-service/:ser_id"
              component={Edit_service_in_contract_in_customer}
            />

            <Route exact path="/operator" component={Operator} />
            <Route exact path="/new-operator" component={New_Operator} />
            <Route exact path="/operator/:ope_id" component={Edit_Operator} />

            <Route exact path="/dealer" component={Dealer} />
            <Route exact path="/new-dealer" component={Create_new_dealer} />
            <Route exact path="/dealer/:dea_id" component={Edit_Dealer} />
            <Route
              exact
              path="/dealer/:dea_id/edit-user/:dea_user_id"
              component={Edit_Dealer_User}
            />

            <Route exact path="/stock" component={Stock} />
            <Route exact path="/new-stock" component={New_Stock} />
            <Route exact path="/stock/:sto_id" component={Edit_Stock} />

            <Route exact path="/service" component={Service} />
            <Route exact path="/new-service" component={New_Service} />
            
            <Route exact path="/new-service-multiple" component={CreateMultipleService} />

            <Route
              exact
              path="/new-service/:sto_id"
              component={New_Service_Has_Stock_Id}
            />
            <Route
              exact
              path="/new-service/from-distar/:sto_id"
              component={New_Service_Has_Stock_From_Distar}
            />
            <Route exact path="/service/:ser_id" component={Edit_Service} />

            <Route
              exact
              path="/requested-repair"
              component={Request_Repair_Admin}
            />
            <Route exact path="/repair-history" component={Repair_History} />
            <Route
              exact
              path="/requested-repair/:rrs_id"
              component={Check_Request_Repair}
            />

            <Route exact path="/setting" component={Setting} />

            <Route exact path="/system-setting" component={System_Settings} />
            <Route
              exact
              path="/system-setting/product-group"
              component={Product_group}
            />
            <Route
              exact
              path="/system-setting/product-model"
              component={product_model}
            />
            <Route exact path="/system-setting/warranty" component={Warranty} />
            <Route
              exact
              path="/system-setting/car-body-type"
              component={Car_Body_Type}
            />
            <Route
              exact
              path="/system-setting/car-brand"
              component={Car_Brand}
            />
            <Route
              exact
              path="/system-setting/car-color"
              component={Car_Color}
            />
            <Route
              exact
              path="/system-setting/car-model"
              component={Car_Model}
            />
            <Route
              exact
              path="/system-setting/car-model-year"
              component={Car_Model_Year}
            />
            <Route
              exact
              path="/system-setting/car-group"
              component={Car_Group}
            />
            <Route
              exact
              path="/system-setting/department"
              component={Department}
            />
            <Route exact path="/system-setting/position" component={Position} />
            <Route
              exact
              path="/system-setting/trigger-event"
              component={Trigger_Event}
            />
            <Route
              exact
              path="/system-setting/file-upload"
              component={Fileupload}
            />
            <Route
              exact
              path="/system-setting/service-type"
              component={Service_type}
            />
            <Route
              exact
              path="/system-setting/employee-group"
              component={Employee_Group}
            />
            <Route
              exact
              path="/system-setting/operator-group"
              component={Operator_Group}
            />
            <Route
              exact
              path="/system-setting/scheduler"
              component={Scheduler}
            />
            <Route
              exact
              path="/system-setting/email-notification"
              component={Email_Notification}
            />
            <Route exact path="/system-setting/task" component={Task} />
            <Route
              exact
              path="/system-setting/task/:task_group_id"
              component={Edit_Task_System_Setting}
            />
            <Route exact path="/system-setting/sla" component={Sla} />
            <Route
              exact
              path="/system-setting/setting-line"
              component={Line_Setting}
            />

            <Route exact path="/notification" component={Notification} />

            <Route exact path="/reports" component={Report_Admin} />
            <Route
              exact
              path="/reports/report-repair"
              component={Report_Admin_Repair}
            />
            <Route
              exact
              path="/reports/report-car"
              component={Report_Admin_Car}
            />
            <Route
              exact
              path="/reports/report-contract"
              component={Report_Admin_Contract}
            />
            <Route
              exact
              path="/reports/report-product"
              component={Report_Admin_Product}
            />
            <Route
              exact
              path="/reports/report-operator"
              component={Report_Admin_Operator}
            />
          </>
        )}

        {isRole === "employee" && (
          <>
            <Route
              exact
              path="/"
              component={() => <Redirect to="/dashboards" />}
            />

            <Route exact path="/profile" component={Profile_Employee} />

            <Route exact path="/dashboards" component={Dashboard} />
            <Route exact path="/sla-dashboards" component={DashboardsSLA} />

            {/* <Route exact path="/employee" component={Employee} />
              <Route exact path="/new-employee" component={New_Employee} />
              <Route exact path="/employee/:employee_id" component={Edit_Employee} /> */}

            <Route exact path="/cars" component={Cars} />
            <Route exact path="/new-cars" component={New_Car} />
            <Route exact path="/cars/:car_id" component={Edit_Car} />
            <Route
              exact
              path="/cars/:car_id/sub-product/:product_id"
              component={Edit_Sub_Product_In_Car}
            />

            <Route exact path="/import-data" component={Import_Data_Select} />
            {/* <Route exact path="/import-data/dealer" component={Import_Dealer_From_db_distar} />
              <Route exact path="/import-data/dealer/select-dealer" component={Select_Dealer_From_DB_Distar} /> */}
            <Route
              exact
              path="/import-data/car"
              component={Import_Car_From_DB_Distar}
            />
            <Route
              exact
              path="/import-data/car/:gid"
              component={Select_Car_From_DB_Distar}
            />
            <Route
              exact
              path="/import-data/stock"
              component={Import_Stock_From_DB_Distar}
            />
            <Route
              exact
              path="/import-data/customer"
              component={Import_Customer_From_DB_Distar}
            />
            <Route
              exact
              path="/import-data/customer/select-customer"
              component={Select_Customer_From_Distar_DB}
            />

            <Route exact path="/contract" component={Contract} />
            <Route exact path="/contract/:con_id" component={Edit_Contract} />
            <Route exact path="/new-contract" component={New_Contract} />
            <Route
              exact
              path="/contract/:con_id/edit-service/:ser_id"
              component={Edit_service_in_contact}
            />

            <Route
              exact
              path="/create-new-customer"
              component={Create_new_customer}
            />
            <Route exact path="/customers" component={Customers} />
            <Route
              exact
              path="/customers/:profile_id"
              component={New_Customer}
            />
            <Route
              exact
              path="/customers/:profile_id/edit-user/:user_id"
              component={Edit_User_In_Customer}
            />
            <Route
              exact
              path="/customers/:profile_id/edit-contract/:con_id"
              component={Edit_contract_in_customer}
            />
            <Route
              exact
              path="/customers/:profile_id/edit-car/:car_id"
              component={Edit_car_in_customer}
            />
            <Route
              exact
              path="/customers/:profile_id/edit-car/:car_id/sub-product/:product_id"
              component={Edit_Sub_Product_In_Customer}
            />
            <Route
              exact
              path="/customers/:profile_id/edit-device/:sto_id"
              component={Edit_Device}
            />
            <Route
              exact
              path="/customers/:profile_id/edit-contract/:con_id/edit-service/:ser_id"
              component={Edit_service_in_contract_in_customer}
            />

            <Route exact path="/operator" component={Operator} />
            <Route exact path="/new-operator" component={New_Operator} />
            <Route exact path="/operator/:ope_id" component={Edit_Operator} />

            <Route exact path="/dealer" component={Dealer} />
            <Route exact path="/new-dealer" component={Create_new_dealer} />
            <Route exact path="/dealer/:dea_id" component={Edit_Dealer} />
            <Route
              exact
              path="/dealer/:dea_id/edit-user/:dea_user_id"
              component={Edit_Dealer_User}
            />

            <Route exact path="/stock" component={Stock} />
            <Route exact path="/new-stock" component={New_Stock} />
            <Route exact path="/stock/:sto_id" component={Edit_Stock} />

            <Route exact path="/service" component={Service} />
            <Route exact path="/new-service" component={New_Service} />
            <Route exact path="/new-service-multiple" component={CreateMultipleService} />
            <Route
              exact
              path="/new-service/:sto_id"
              component={New_Service_Has_Stock_Id}
            />
            <Route
              exact
              path="/new-service/from-distar/:sto_id"
              component={New_Service_Has_Stock_From_Distar}
            />
            <Route exact path="/service/:ser_id" component={Edit_Service} />

            <Route
              exact
              path="/requested-repair"
              component={Request_Repair_Admin}
            />
            <Route exact path="/repair-history" component={Repair_History} />
            <Route
              exact
              path="/requested-repair/:rrs_id"
              component={Check_Request_Repair}
            />

            <Route exact path="/setting" component={Setting} />

            <Route exact path="/system-setting" component={System_Settings} />
            <Route
              exact
              path="/system-setting/product-group"
              component={Product_group}
            />
            <Route
              exact
              path="/system-setting/product-model"
              component={product_model}
            />
            <Route exact path="/system-setting/warranty" component={Warranty} />
            <Route
              exact
              path="/system-setting/car-body-type"
              component={Car_Body_Type}
            />
            <Route
              exact
              path="/system-setting/car-brand"
              component={Car_Brand}
            />
            <Route
              exact
              path="/system-setting/car-color"
              component={Car_Color}
            />
            <Route
              exact
              path="/system-setting/car-model"
              component={Car_Model}
            />
            <Route
              exact
              path="/system-setting/car-model-year"
              component={Car_Model_Year}
            />
            <Route
              exact
              path="/system-setting/car-group"
              component={Car_Group}
            />
            <Route
              exact
              path="/system-setting/department"
              component={Department}
            />
            <Route exact path="/system-setting/position" component={Position} />
            <Route
              exact
              path="/system-setting/trigger-event"
              component={Trigger_Event}
            />
            <Route
              exact
              path="/system-setting/file-upload"
              component={Fileupload}
            />
            <Route
              exact
              path="/system-setting/service-type"
              component={Service_type}
            />
            <Route
              exact
              path="/system-setting/employee-group"
              component={Employee_Group}
            />
            <Route
              exact
              path="/system-setting/operator-group"
              component={Operator_Group}
            />
            <Route
              exact
              path="/system-setting/scheduler"
              component={Scheduler}
            />
            <Route
              exact
              path="/system-setting/email-notification"
              component={Email_Notification}
            />
            <Route exact path="/system-setting/task" component={Task} />
            <Route
              exact
              path="/system-setting/task/:task_group_id"
              component={Edit_Task_System_Setting}
            />
            <Route exact path="/system-setting/sla" component={Sla} />
            <Route
              exact
              path="/system-setting/setting-line"
              component={Line_Setting}
            />

            {/* <Route exact path="/system-setting" component={System_Settings} />
              <Route exact path="/system-setting/product-group" component={Product_group} />
              <Route exact path="/system-setting/product-model" component={product_model} />
              <Route exact path="/system-setting/warranty" component={Warranty} />
              <Route exact path="/system-setting/car-body-type" component={Car_Body_Type} />
              <Route exact path="/system-setting/car-brand" component={Car_Brand} />
              <Route exact path="/system-setting/car-color" component={Car_Color} />
              <Route exact path="/system-setting/car-model" component={Car_Model} />
              <Route exact path="/system-setting/car-model-year" component={Car_Model_Year} />
              <Route exact path="/system-setting/car-group" component={Car_Group} />
              <Route exact path="/system-setting/department" component={Department} />
              <Route exact path="/system-setting/position" component={Position} />
              <Route exact path="/system-setting/trigger-event" component={Trigger_Event} />
              <Route exact path="/system-setting/file-upload" component={Fileupload} />
              <Route exact path="/system-setting/service-type" component={Service_type} />
              <Route exact path="/system-setting/employee-group" component={Employee_Group} />
              <Route exact path="/system-setting/operator-group" component={Operator_Group} />
              <Route exact path="/system-setting/scheduler" component={Scheduler} />
              <Route exact path="/system-setting/email-notification" component={Email_Notification} />
              <Route exact path="/system-setting/task" component={Task} />
              <Route exact path="/system-setting/task/:task_group_id" component={Edit_Task_System_Setting} />
              <Route exact path="/system-setting/sla" component={Sla} /> */}

            <Route exact path="/notification" component={Notification} />

            <Route exact path="/reports" component={Report_Admin} />
            <Route
              exact
              path="/reports/report-repair"
              component={Report_Admin_Repair}
            />
            <Route
              exact
              path="/reports/report-car"
              component={Report_Admin_Car}
            />
            <Route
              exact
              path="/reports/report-contract"
              component={Report_Admin_Contract}
            />
            <Route
              exact
              path="/reports/report-product"
              component={Report_Admin_Product}
            />
            <Route
              exact
              path="/reports/report-operator"
              component={Report_Admin_Operator}
            />
          </>
        )}

        {isRole === "operator" && (
          <>
            <Route
              exact
              path="/"
              component={() => <Redirect to="/dashboards" />}
            />
            <Route exact path="/profile" component={Profile_Operator} />

            <Route exact path="/dashboards" component={Dashboard_Operator} />
            <Route exact path="/service/:ser_id" component={Service_Operator} />
            <Route
              exact
              path="/notification"
              component={Notification_Customer_User_Dealer}
            />
            <Route exact path="/setting" component={Setting} />

            <Route exact path="/reports" component={Report_Operator} />
            <Route
              exact
              path="/reports/report-repair"
              component={Report_Operator_Repair}
            />
            <Route
              exact
              path="/reports/report-car"
              component={Report_Operator_Car}
            />
            <Route
              exact
              path="/reports/report-contract"
              component={Report_Operator_Contract}
            />
            <Route
              exact
              path="/reports/report-product"
              component={Report_Operator_Product}
            />
          </>
        )}

        {isRole === "customer_user" && (
          <>
            <Route exact path="/profile" component={Profile_Customer} />

            <Route
              exact
              path="/"
              component={() => <Redirect to="/dashboards" />}
            />
            <Route
              exact
              path="/service/:ser_id"
              component={() => <Redirect to="/service" />}
            />

            <Route exact path="/dashboards" component={Dashboard_Customer} />

            <Route exact path="/cars" component={Cars_Customer} />
            <Route exact path="/cars/:car_id" component={Detail_Car_Customer} />
            <Route
              exact
              path="/cars/:car_id/sub-product/:pro_id"
              component={Sub_Product_Car_Customer}
            />

            <Route exact path="/device" component={Device_Customer} />

            <Route exact path="/contract" component={Contract_Customer} />
            <Route
              exact
              path="/contract/:con_id"
              component={Detail_Contract_Customer}
            />

            <Route exact path="/service" component={Service_Customer} />

            <Route
              exact
              path="/notification"
              component={Notification_Customer_User_Dealer}
            />

            <Route exact path="/repair" component={Repair_Customer} />
            <Route
              exact
              path="/repair/:rrs_id"
              component={Detail_Repair_Customer}
            />
            <Route
              exact
              path="/create-new-repair"
              component={Create_New_Repair_Customer}
            />
            <Route
              exact
              path="/create-new-repair/:sto_id"
              component={Form_Create_Service_Customer}
            />

            <Route exact path="/setting" component={Setting} />

            <Route exact path="/reports" component={Report_Customer} />
            <Route
              exact
              path="/reports/report-repair"
              component={Report_Customer_Repair}
            />
            <Route
              exact
              path="/reports/report-car"
              component={Report_Customer_Car}
            />
            <Route
              exact
              path="/reports/report-contract"
              component={Report_Customer_Contract}
            />
            <Route
              exact
              path="/reports/report-product"
              component={Report_Customer_Product}
            />
          </>
        )}

        {isRole === "dealer_user" && (
          <>
            <Route exact path="/profile" component={Profile_Dealer} />

            <Route
              exact
              path="/"
              component={() => <Redirect to="/dashboards" />}
            />

            <Route exact path="/dashboards" component={Dashboard_Dealer} />

            <Route exact path="/customers" component={Customer_Dealer} />
            <Route
              exact
              path="/customers/:profile_id"
              component={View_Customer_Dealer}
            />
            <Route
              exact
              path="/customers/:profile_id/view-car/:car_id"
              component={View_Car_In_Customer_Dealer}
            />
            <Route
              exact
              path="/customers/:profile_id/view-car/:car_id/sub-product/:pro_id"
              component={View_Sub_Product_In_Customer}
            />
            <Route
              exact
              path="/customers/:profile_id/contract/:con_id"
              component={View_Contract_In_Customer_Customer}
            />

            <Route exact path="/repair" component={Repair_Dealer} />
            <Route
              exact
              path="/repair/:rrs_id"
              component={Detail_Repair_Dealer}
            />
            <Route
              exact
              path="/create-new-repair"
              component={Create_New_Repair_Dealer}
            />
            <Route
              exact
              path="/create-new-repair/:sto_id"
              component={Form_Create_Service_Dealer}
            />

            <Route exact path="/setting" component={Setting} />

            <Route exact path="/reports" component={Report_Dealer} />
            <Route
              exact
              path="/reports/report-repair"
              component={Report_Dealer_Repair}
            />
            <Route
              exact
              path="/reports/report-car"
              component={Report_Dealer_Car}
            />
            <Route
              exact
              path="/reports/report-contract"
              component={Report_Dealer_Contract}
            />
            <Route
              exact
              path="/reports/report-product"
              component={Report_Dealer_Product}
            />
          </>
        )}

        {/* {
          isRole === null && (
           
         <>
     <Route exact path="/" component={() => <Redirect to="/login" />} />
              <Route exact path="/login" component={Login} />
         </>
            
          )
        } */}
      </Auth_Employee>
      {/*
          {authRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}
          
          {
            role === 'employee' && (
              <>
              {userRoutes.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  exact
                />
              ))}
              </>
            )
          }
          {
            role === 'operator' && (
              <>
              {operator_Routes.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  exact
                />
              ))}
              </>
            )
          }
          {
            role === null && (
              <>
              {No_Token.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  exact
                />
              ))}
              </>
            )
          }
        */}
    </Switch>
    // </Router>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
