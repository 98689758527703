import React,{ useEffect , useState } from 'react'
import { Row } from "reactstrap"
import Col_Device from './Col_Device'
import { useTranslation } from "react-i18next";

export default function Detail_car(props) {
    const { t } = useTranslation();
    const [detail_car, setDetail_car] = useState([])

    useEffect(() => {
        if(props.detail_car !== null){
            setDetail_car([
                {
                    header_car : 'title',
                    value_car : props.detail_car.title
                },
                {
                    header_car : 'lpn',
                    value_car : props.detail_car.lpn
                },
                {
                    header_car : 'license',
                    value_car : props.detail_car.license
                },
                {
                    header_car : 'gid',
                    value_car : props.detail_car.gid
                },
                {
                    header_car : 'grpid',
                    value_car : props.detail_car.grpid
                },
                {
                    header_car : 'saleid',
                    value_car : props.detail_car.saleid
                },
                {
                    header_car : 'uid',
                    value_car : props.detail_car.uid
                },
                {
                    header_car : 'userid',
                    value_car : props.detail_car.userid
                },
                {
                    header_car : 'fueltank',
                    value_car : props.detail_car.fueltank
                },
                {
                    header_car : 'fuelhours',
                    value_car : props.detail_car.fuelhours
                },
                {
                    header_car : 'fuelkm',
                    value_car : props.detail_car.fuelkm
                },
                {
                    header_car : 'cid',
                    value_car : props.detail_car.cid
                },
                {
                    header_car : 'installtime',
                    value_car : props.detail_car.installtime
                },
                {
                    header_car : 'offtime',
                    value_car : props.detail_car.offtime
                },
                {
                    header_car : 'regtime',
                    value_car : props.detail_car.regtime
                },
                {
                    header_car : 'ctime',
                    value_car : props.detail_car.ctime
                },
                {
                    header_car : 'btime',
                    value_car : props.detail_car.btime
                },
                {
                    header_car : 'vin',
                    value_car : props.detail_car.vin
                },
                {
                    header_car : 'remark',
                    value_car : props.detail_car.remark
                }

            ])
        }
        return () => {
            
        }
    }, [props.detail_car])

    return (
        <div>
            <p className="text-bold" style={{ textAlign:'center' }}> {t("Car information")} </p>
            {
                props.detail_car !== null && (
                    <Row>
                        {
                            detail_car.map( (data , index) => {
                                return <Col_Device key={index} text_header={data.header_car} data={data.value_car} />
                            } )
                        }
                    </Row>
                )
            }
            <Row>
            
            </Row>
        </div>
    )
}
