import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import axios from "axios"
import * as api from "../../../service/Api_Profile_Menu"
//i18n
import { withTranslation, useTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// users
import user1 from "../../../assets/images/users/avatar-1.jpg"
import user from "../../../assets/Distar/user/user.jpeg"
import { Avatar, Modal } from "antd"
import { UserOutlined } from "@ant-design/icons"
import { ExclamationCircleOutlined } from "@ant-design/icons"

import { FaUser } from 'react-icons/fa';

import {useDispatch , useSelector } from "react-redux"
import { ADD_PRIVATE_ID , ADD_NAME_USER , ADD_IMAGE_AVATAR , _EMP_ID , IS_ADMIN_ROOT , FIND_IS_ROLE , EMAIL_USER_CUSTOMER , PERMISSION_ALL_SERVICE , _CUSTOMER_USER_ID , _DEALER_USER_ID } from '../../../store/Distar/actionTypes';
import jwt_decode from "jwt-decode";

const { confirm } = Modal

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const history = useHistory()
  const { t } = useTranslation()
  const token = localStorage.getItem("token")
  const role = localStorage.getItem("role")
  const _id = localStorage.getItem("_id")
  const [menu, setMenu] = useState(false)
  const [username, setUsername] = useState(null)
  const [img, setImg] = useState(null)
  const [data_before_mapping_role, setData_before_mapping_role] = useState([])
  const [user_id_check, setUser_id_check] = useState(null)

  const dispatch = useDispatch()
  const action = (type,payload)=>dispatch({type, payload})
  const { nameUser , avatarUser , isRole , permissionAllService } = useSelector(({ Distar }) => Distar)
  // console.log(permissionAllService);
  // localStorage.setItem('_id',res.data.user_id)
  useEffect(() => {
    if (token !== null) {
      var decodedToken = jwt_decode(token);
      action(FIND_IS_ROLE, decodedToken.role)
    }
    return () => {
      setImg(null)
      setUsername(null)
    }
  }, [])

  useEffect(() => {
    if (isRole === "administrator") {
      get_admin_info()
      let permi_admin = {
        system_setting: {
          read: true,
          write: true,
          edit: true,
          delete: true,
        },
        employee: {
          read: true,
          write: true,
          edit: true,
          delete: true,
        },
        customer: {
          read: true,
          write: true,
          edit: true,
          delete: true,
        },
        car: {
          read: true,
          write: true,
          edit: true,
          delete: true,
        },
        import: {
          read: true,
          write: true,
          edit: true,
          delete: true,
        },
        contract: {
          read: true,
          write: true,
          edit: true,
          delete: true,
        },
        service: {
          read: true,
          write: true,
          edit: true,
          delete: true,
        },
        operator: {
          read: true,
          write: true,
          edit: true,
          delete: true,
        },
        dealer: {
          read: true,
          write: true,
          edit: true,
          delete: true,
        },
        stock: {
          read: true,
          write: true,
          edit: true,
          delete: true,
        },
      }
      action(PERMISSION_ALL_SERVICE, permi_admin)
      localStorage.setItem("permi", JSON.stringify(permi_admin))
    } else if (isRole === "employee") {
      get_user_info()
    } else if (isRole === "operator") {
      var decodedToken = jwt_decode(token);
      get_detail_operator(decodedToken.user_id)
      action(ADD_PRIVATE_ID, decodedToken.user_id)
    } else if (isRole === "customer_user") {
      var decodedToken = jwt_decode(token);
      get_detail_customer(decodedToken.user_id)
      action(ADD_PRIVATE_ID, decodedToken.user_id)

    } else if (isRole === "dealer_user") {
      var decodedToken = jwt_decode(token);
      action(ADD_PRIVATE_ID, decodedToken.user_id)
      get_detail_dealer(decodedToken.user_id)
      get_dealer_id()
    }
    return () => {
      
    }
  }, [isRole])

  function get_detail_dealer(user_id) {
    const dealer_detail = api.DETAIL_DEALER_INFO(token, user_id)
    axios(dealer_detail)
      .then(res => {
        setUsername(res.data.data.dea_user_firstname)
        action(ADD_NAME_USER, res.data.data.dea_user_firstname)
        get_image(res.data.data.dea_user_avatar)
      })
      .catch(error => {
        // console.log(error.response);
      })
  }

  function get_dealer_id() {
    const dealer_detail = api.CHECK_USER_DEALER(token)
    axios(dealer_detail)
      .then(res => {
        localStorage.setItem("dea_id", res.data.data.dea_id)
        action(_DEALER_USER_ID, res.data.data.dea_id)
      })
      .catch(error => {
        // console.log(error.response);
      })
  }

  function get_detail_customer(user_id) {
    const get_user_customer = api.CHECK_USER_CUSTOMER(token, user_id)
    axios(get_user_customer)
      .then(res => {
        localStorage.setItem("cus_id", res.data.data[0].cus_id)
        action(_CUSTOMER_USER_ID, res.data.data[0].cus_id)
        action(ADD_NAME_USER, res.data.data[0].cus_user_firstname)
        action(EMAIL_USER_CUSTOMER, res.data.data[0].cus_user_email)

        setUsername(res.data.data[0].cus_user_firstname)
        get_image(res.data.data[0].cus_user_avatar)
      })
      .catch(error => {
        // console.log(error.response);
      })
  }

  function get_detail_operator(user_id) {
    const get_user_operator = api.CHECK_USER_OPERATOR(token, user_id)
    axios(get_user_operator)
      .then(res => {
        localStorage.setItem("ope_id", res.data.data[0].ope_id)
        action(ADD_NAME_USER, res.data.data[0].ope_firstname)
        setUsername(res.data.data[0].ope_firstname)
        get_image(res.data.data[0].ope_avatar)
      })
      .catch(error => {
        // console.log(error.response);
      })
  }

  function get_admin_info() {
    const get_userInfo = api.CHECK_USER_INFO(token)
    axios(get_userInfo)
      .then(res => {
        get_image(res.data.user_infomation.admin_avatar)
        setUsername(res.data.user_infomation.admin_firstname)
        action(ADD_NAME_USER, res.data.user_infomation.admin_firstname)
        if(res.data.user_infomation.is_root === 1){
          action(IS_ADMIN_ROOT, true)
        } else {
          action(IS_ADMIN_ROOT, false)
        }
      })
      .catch(error => {
        // console.log(error.response)
      })
  }

  function get_user_info() {
    const get_userInfo = api.CHECK_USER_INFO(token)
    axios(get_userInfo)
      .then(res => {
        action(_EMP_ID, res.data.user_infomation.emp_id)
        get_role(res.data.user_infomation.emp_level_id)
        get_image(res.data.user_infomation.emp_avatar)
        setUsername(res.data.user_infomation.emp_firstname)
        action(ADD_NAME_USER, res.data.user_infomation.emp_firstname)
      })
      .catch(error => {
        // console.log(error.response)
        // if(error.response.status === 401){
        //   localStorage.clear()
        //   history.push('/login')
        // }
      })
  }

  function get_role(id) {
    const check_role = api.CHECK_ROLE_PERMISSION(token, id)
    axios(check_role)
      .then(res => {
        if (res.data.employee_role.length === 0) {
          set_data_default()
        } else {
          setData_before_mapping_role(res.data.employee_role)
        }
      })
      .catch(error => {
        // console.log(error.response);
      })
  }

  function set_data_default() {
    let test = {
      system_setting: {
        read: false,
        write: false,
        edit: false,
        delete: false,
      },
      employee: {
        read: false,
        write: false,
        edit: false,
        delete: false,
      },
      customer: {
        read: false,
        write: false,
        edit: false,
        delete: false,
      },
      car: {
        read: false,
        write: false,
        edit: false,
        delete: false,
      },
      import: {
        read: false,
        write: false,
        edit: false,
        delete: false,
      },
      contract: {
        read: false,
        write: false,
        edit: false,
        delete: false,
      },
      service: {
        read: false,
        write: false,
        edit: false,
        delete: false,
      },
      operator: {
        read: false,
        write: false,
        edit: false,
        delete: false,
      },
      dealer: {
        read: false,
        write: false,
        edit: false,
        delete: false,
      },
      stock: {
        read: false,
        write: false,
        edit: false,
        delete: false,
      },
    }
    action(PERMISSION_ALL_SERVICE, test)
    localStorage.setItem("permi", JSON.stringify(test))
  }

  useEffect(() => {
    mapping_data()
    return () => {}
  }, [data_before_mapping_role])

  function mapping_data() {
    if(isRole === "employee"){
      let data_obj_all = {}
      let data_one_obj = {}
      data_before_mapping_role.map((data, index) => {
        data_one_obj = {
          read: check_true_false(data.can_read),
          write: check_true_false(data.can_write),
          edit: check_true_false(data.can_edit),
          delete: check_true_false(data.can_delete),
        }
        data_obj_all[data.emp_module_name] = data_one_obj
      })
      action(PERMISSION_ALL_SERVICE, data_obj_all)
      localStorage.setItem("permi", JSON.stringify(data_obj_all))
    }
    //  console.log(data_obj_all);
  }

  function check_true_false(value) {
    switch (value) {
      case 1:
        return true
        break
      case 0:
        return false
        break
      default:
        break
    }
  }

  useEffect(() => {
    if(avatarUser !== null){
      get_image(avatarUser)
    }
    return () => {
      
    }
  }, [avatarUser])

  async function get_image(path_image) {
    const get_data_image = api.GET_IMAGE(token, path_image)
    await axios(get_data_image)
      .then(res => {
        const blob = new Blob([res.data], {
          type: "image/jpeg",
        })
        var objectURL = URL.createObjectURL(blob)
        setImg(objectURL)
      })
      .catch(error => {
        // console.log(error.response);
      })
  }

  function logout() {
    return confirm({
      title: t("confirm logout ?"),
      icon: <ExclamationCircleOutlined />,
      centered: "true",
      okText: t("confirm"),
      cancelText: t("cancel"),
      onOk() {
        localStorage.removeItem("token")
        localStorage.removeItem("role")
        localStorage.removeItem("_id")
        localStorage.removeItem("cus_id")
        localStorage.removeItem("dea_id")
        localStorage.removeItem("permi")
        localStorage.removeItem("ope_id")
        localStorage.removeItem("r")
        history.push("/login")
      },
      onCancel() {},
    })
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          {img === null && <Avatar size={30} icon={<UserOutlined />} />}
          {img !== null && <Avatar size={30} src={img} />}

          <span className="d-none d-xl-inline-block ms-2 me-1">
            {nameUser ? nameUser : "N/A"}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/*   <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1"/>
            {props.t("Profile")}{" "}
          </DropdownItem>
          <DropdownItem tag="a" href="#">
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1"/>
            {props.t("Settings")}
          </DropdownItem>
  <div className="dropdown-divider"/> */}
   
          <Link to="/profile" onClick={() => setMenu(!menu)}>
          <div className="dropdown-item" >
            <FaUser size={12} style={{ marginLeft:'2px' , marginRight:'6px' , color:'#808080' }} />
              <span>{t("Profile")}</span>
            </div>
          </Link>
      
       
          <a>
            <div className="dropdown-item" onClick={() => logout()}>
              <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
              <span>{t("logout")}</span>
            </div>
          </a>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)

// import React,{ useEffect } from 'react'
// import user from '../../../assets/Distar/user/user.jpeg';

// export default function ProfileMenu() {

//   return (
//     <div>
//     <img
//                 className="rounded-circle header-profile-user"
//                 src={user}
//                 alt="Header Avatar"
//               />
//     </div>
//   )
// }
