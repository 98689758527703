import React, { useEffect, useState } from "react"
import { Avatar, Tag } from "antd"
import { Row, Col } from "reactstrap"
import { AiOutlineUser } from "react-icons/ai"
import { useHistory } from "react-router-dom"
import Render_Image_Data from './Render_Image_Data'
import "./Table_Customer.css"
import { useTranslation } from "react-i18next";

export default function Table_Customer(props) {
  const history = useHistory()
  const { t } = useTranslation();
  const token = localStorage.getItem("token")
  const [data_table, setData_table] = useState([])

  useEffect(() => {
    setData_table(props.data_list)
    return () => {
    
      setData_table([])
    }
  }, [props.data_list])

  useEffect(() => {
    render_customer()
    return () => {

    }
  }, [data_table])

  function render_customer() {
    return data_table.map((data, index) => {
      return (
        <Col sm={12} md={6} lg={4} xl={3} key={index}>
          <a onClick={() => history.push('/customers/'+data.cus_id)
        }>
            <div
              className="table-customer"
            
            >
              <div style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    border: "1px solid #f0f0f0",
                    width: "100%",
                    top: "40px",
                  }}
                />
                { render_status(data.is_active) }
                <Render_Image_Data 
                  cus_avatar={data.cus_avatar}
                />
              </div>
              <br />      <br />
              <div style={{ position: "relative", top:"40px", left:'10px', right:"10px" }}>
                <p
                  style={{
                    fontWeight:'bold',
                    fontSize: "15px",
                    color: "#495057",
                   
                  }}
                >
                  {data.cus_name}
                </p>
                <p style={{   
                    fontSize: "15px",
                    color: "#495057"
                }}>{t("tax id")} : {data.cus_taxid}</p>
                <p style={{   
                  fontSize: "15px",
                  color: "#495057"
              }}>{t("dealer")} : {data.dea_name}</p>
                <br />
              </div>
            </div>
          </a>
        </Col>
      )
    })
  }

  function render_status(status) {
    if(status === 1){
      return  <Tag color="green" style={{ float:'right' }}>{t("active")}</Tag>
    } else {
      return  <Tag style={{ float:'right' }}> {t("disable")} </Tag>
    }
  }

  return (
    <div>
      <Row>{render_customer()}</Row>
    </div>
  )
}
