import React, { useState, useEffect } from "react"
import { Form, Input, Button, Table, Modal, Pagination, Tooltip } from "antd"
import { Row, Col } from "reactstrap"
import { FcSettings, FcDataSheet } from "react-icons/fc"
import { useTranslation } from "react-i18next"

export default function Table_Branch(props) {
  const { t } = useTranslation()
  const [data_table_branch, setData_table_branch] = useState([])
  const [branch_edit, setBranch_edit] = useState({})
  const [branch, setBranch] = useState({})
  const [visible, setVisible] = useState(false)
  const [branch_id, setBranch_id] = useState(null)

  useEffect(() => {
    setData_table_branch(props.branch_table)
    return () => {
      setData_table_branch([])
    }
  }, [props.branch_table])

  const onFinish = values => {
    props.search_data_branch()
  }

  const columns = [
    {
      title: t("code"),
      dataIndex: "cus_branch_code",
      key: "cus_branch_code",
      render: text => <p>{text}</p>,
    },
    {
      title: t("name"),
      dataIndex: "cus_branch_name",
      key: "cus_branch_name",
      render: text => <p>{text}</p>,
    },
    {
      title: t("address"),
      dataIndex: "cus_branch_address",
      key: "cus_branch_address",
      render: text => <p>{text}</p>,
    },
  ]

  function cancelForm() {
    setVisible(false)
    setBranch_edit({})
    setBranch_id(null)
  }

  return (
    <div>
      <Form
        name="List_History_Branch_form"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Form.Item
              name="search_list_History_Branch_form"
              rules={[
                {
                  required: false,
                  message: "Please input data search",
                },
              ]}
            >
              <Input
                placeholder={`${t("code")} ,${t("name")} `}
                onChange={e => props.setData_search_branch(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={1} lg={1} xl={1}>
            <Button type="primary" htmlType="submit" className="button-search-employee" >
              {t("search")}
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        loading={props.loading_branch}
        columns={columns}
        dataSource={data_table_branch}
        rowKey="cus_branch_code"
        scroll={{ x: 800 }}
        pagination={false}
      />
      <Pagination
        current={props.page_limit_branch.page_branch}
        total={props.page_limit_branch.limit_branch}
        onChange={props.changePagination}
        style={{ marginTop: "20px", float: "right" }}
      />
      <p style={{ fontSize: "15px", marginTop: "20px" }}>
        {" "}
        <FcDataSheet size={20} style={{ marginBottom: "5px" }} /> {t('total data')} :{" "}
        {props.page_limit_branch.limit_branch}
      </p>

      
    </div>
  )
}
