import React from "react"
import { Input, Form, Checkbox } from "antd"
import { Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next"

export default function Input_Car_Lpn({
  no_lpn_car,
  setNo_lpn_car,
  setData_car,
  data_car,
}) {
  const { t } = useTranslation()
  function onChange_car_no_lpn(e) {
    if (e.target.checked === true) {
      setNo_lpn_car(true)
      setData_car({
        ...data_car,
        car_license_number: null,
        car_lpn_mode: true,
      })
    } else {
      setNo_lpn_car(false)
      setData_car({
        ...data_car,
        car_lpn_mode: false,
      })
    }
  }
  function reverse_boolean(boolean) {
    if (boolean === true) {
      return false
    } else {
      return true
    }
  }

  return (
    <Row>
      <Col>
        <p className="text-bold">
          {t("License no")} <span style={{ color: "red" }}>*</span>
        </p>
        <Form.Item
          name="car_license_number"
          rules={[
            {
              required: reverse_boolean(no_lpn_car),
              message: t("License no"),
            },
          ]}
        >
          <Input
            placeholder={t("License no")}
            disabled={no_lpn_car}
            onChange={e =>
              setData_car({ ...data_car, car_license_number: e.target.value })
            }
          />
        </Form.Item>
      </Col>
      <Col>
        <Checkbox
          checked={no_lpn_car}
          onChange={onChange_car_no_lpn}
          className="checkbox_location"
        >
          {t("No license plate")}
        </Checkbox>
      </Col>
    </Row>
  )
}
