import React, { useState, useEffect } from "react"
import { Button, Modal, Form, Input, Table } from "antd"
import { Row, Col } from "reactstrap"
import * as api from "../../../../service/Api_Admin"
import { useTranslation } from "react-i18next"
import { EditOutlined } from "@ant-design/icons"
import axios from "axios"
import { FcDataSheet } from "react-icons/fc"
import { useHistory } from "react-router-dom"
import helpers from "../../../Function_Redirect/Redirect_login"

export default function FilterAdmin(props) {
  const { t } = useTranslation()
  const history = useHistory()
  const token = localStorage.getItem("token")
  const [search_admin, setSearch_admin] = useState(null)
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [admin_all, setAdmin_all] = useState([])
  const [array_for_search, setArray_for_search] = useState([])

  useEffect(() => {
    list_all_admin()
    return () => {
      setAdmin_all([])
      setArray_for_search([])
    }
  }, [])

  const search = values => {
    search_data()
  }

  function list_all_admin() {
    setLoading(true)
    const list_all = api.LIST_ALL_ADMIN(token)
    axios(list_all)
      .then(res => {
        setLoading(false)
        setAdmin_all(res.data.data)
        setArray_for_search(res.data.data)
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
        // console.log(error)
      })
  }

  function search_data() {
    if (search_admin) {
      const after_search = handleSearch(array_for_search, search_admin)
      setAdmin_all(after_search)
    } else {
      list_all_admin()
    }
  }

  const handleSearch = (arr, searchInput) =>
    arr.filter(obj =>
      Object.values(obj)
        .flat()
        .some(v =>
          `${v}`.toLowerCase().includes(`${searchInput}`.toLowerCase())
        )
    )

  const columns = [
    {
      title: t("firstname"),
      dataIndex: "admin_firstname",
      key: "admin_firstname",
    },
    {
      title: t("Email"),
      dataIndex: "admin_email",
      key: "admin_email",
    },
    {
      title: t("Group"),
      dataIndex: "admin_group_name",
      key: "admin_group_name",
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      props.setFilter_Role({
        ...props.filter_Role,
        request_by_id: selectedRows[0].admin_id,
        request_by_name: selectedRows[0].admin_firstname,
      })
      setVisible(false)
    },
  }

  return (
    <div>
      <p className="text-bold header-filter header-mt-filter-service"> {t("Select admin")} </p>
      {props.filter_Role.request_by_id === null &&
        props.filter_Role.request_by_name === null && (
          <div>
            <Button
              onClick={() => setVisible(true)}
              className="btn-select-customer"
            >
              {" "}
              {t("Select admin")}{" "}
            </Button>
          </div>
        )}
      {props.filter_Role.request_by_id !== null &&
        props.filter_Role.request_by_name !== null && (
          <Row>
            <Col sm={12} md={12} lg={12} xl={12}>
              <div style={{ display: "flex" }}>
                <p
                  className="text-bold "
                  style={{ marginTop: "7px", width: "150px" }}
                >
                  {t("Admin")}
                </p>
                <Input
                  style={{ marginLeft: "0px", height: "35px" }}
                  value={props.filter_Role.request_by_name}
                  placeholder={props.filter_Role.request_by_name}
                  disabled
                />
                <a>
                  <EditOutlined
                    style={{ fontSize: "20px", marginLeft: "10px" }}
                    onClick={() => {
                      props.setFilter_Role({
                        ...props.filter_Role,
                        request_by_id: null,
                        request_by_name: null,
                      })
                    }}
                  />
                </a>
              </div>
            </Col>
          </Row>
        )}

      <Modal
        centered
        open={visible}
        footer={false}
        width={1000}
        onOk={() => {
          setVisible(false)
        }}
        onCancel={() => {
          setVisible(false)
        }}
      >
        <div
          style={{ height: "500px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <p className="text-bold"> {t("Select admin")} </p>
          <Form name="Search_customer_info" onFinish={search}>
            <Row>
              <Col sm={12} md={9} lg={9} xl={9}>
                <Form.Item
                  name="search"
                  rules={[
                    {
                      required: false,
                      message: "Please input name customer",
                    },
                  ]}
                >
                  <Input
                    placeholder={t("Search")}
                    style={{ marginBottom: "15px", width: "100%" }}
                    onChange={e => setSearch_admin(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col sm={12} md={3} lg={3} xl={3}>
                <Button
                  type="primary"
                  className="button-search-employee"
                  htmlType="submit"
                >
                  {t("search")}
                </Button>
              </Col>
            </Row>
          </Form>
          <Table
            rowSelection={{
              type: "radio",
              ...rowSelection,
            }}
            columns={columns}
            dataSource={admin_all}
            loading={loading}
            rowKey="admin_id"
            scroll={{ x: 800 }}
          />
        </div>
      </Modal>
    </div>
  )
}
