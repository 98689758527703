import React from "react"
import { Row, Col } from "reactstrap"
import { Button, Input, Form } from "antd"
import { useHistory } from "react-router-dom"
import { BiListUl , BiUserPlus } from "react-icons/bi"
import "../Employee/Search/Search_Employee.css"
import { useTranslation } from "react-i18next"

export default function Search({ search_data , setSearch_data_user }) {
  const history = useHistory()
  const { t } = useTranslation()

  const onFinish = values => {
    search_data()
  }

  return (
    <div>
      <Form
        name="Search_employee"
        initialValues={{
          remember: false,
        }}
        onFinish={onFinish}
      >
        <Row>
          <Col sm={12} md={4} lg={4} xl={4}>
            <Form.Item
              name="search"
              rules={[
                {
                  required: false,
                  message: "Please input name,department",
                },
              ]}
            >
              <Input
                placeholder={t("search")}
                style={{ marginBottom: "15px" }}
                onChange={e => setSearch_data_user(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8} lg={8} xl={8}>
            <Button
              type="primary"
              className="button-search-employee"
              htmlType="submit"
            >
              {t("search")}
            </Button>
            <Button
              className="button-search-New-Employee"
              onClick={() => history.push("/create-new-admin")}
              icon={<BiUserPlus size={20} style={{ marginRight: "10px", marginBottom:'3px' }} />}
            >
              {t("New admin")}
            </Button>
            <Button
              className="button-search-New-Employee"
              onClick={() => history.push("/admin/group")}
              icon={<BiListUl size={20} style={{ marginRight: "10px", marginBottom:'3px' }} />}
            >
              {t("Admin group")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
