import React, { useState, useEffect } from "react"
import axios from "axios"
import * as api from "../../../../service/Api_Car"
import { useTranslation } from "react-i18next"
import { Input, Table, Pagination, Button } from "antd"
import { Row, Col } from "reactstrap"
import helpers from "../../../Function_Redirect/Redirect_login"
import { useHistory } from "react-router-dom"
import { FcDataSheet } from "react-icons/fc"
import { EditOutlined } from "@ant-design/icons"

const { Search } = Input

export default function SelectCustomer({ customerSelect, setCustomerSelect }) {
  const [loading, setLoading] = useState(false)
  const token = localStorage.getItem("token")
  const { t } = useTranslation()
  const history = useHistory()
  const [searchCustomer, setSearchCustomer] = useState(null)
  const [customer_table, setCustomer_table] = useState([])
  const [page_limit_customer, setPage_limit_customer] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })

  useEffect(() => {
    searchCustomerAll()

    return () => {}
  }, [])

  function onSearch(value) {
    searchCustomerAll()
  }

  const check_data = (params, data) => {
    if (data) {
      return params + data
    } else {
      return ""
    }
  }

  function searchCustomerAll() {
    setLoading(true)
    const url = `api/v1/customer?page=1&limit=${
      page_limit_customer.page_Size
    }&order_by=DESC${check_data("&q=", searchCustomer)}`
    const search = api.SEARCH_ALL(token, url)
    axios(search)
      .then(res => {
        setLoading(false)
        setPage_limit_customer({
          ...page_limit_customer,
          page: 1,
          limit: res.data.total_data,
        })
        setCustomer_table(res.data.data)
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  function changePagination(page, pageSize) {
    setLoading(true)
    const url = `api/v1/customer?page=${page}&limit=${pageSize}&order_by=DESC${check_data(
      "&q=",
      searchCustomer
    )}`
    const search = api.SEARCH_ALL(token, url)
    axios(search)
    axios(search)
      .then(res => {
        setLoading(false)
        setPage_limit_customer({
          ...page_limit_customer,
          page: page,
          page_Size: pageSize,
          limit: res.data.total_data,
        })
        setCustomer_table(res.data.data)
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  const columns = [
    {
      title: t("Customer code"),
      dataIndex: "cus_code",
      key: "cus_code",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Customer name"),
      dataIndex: "cus_name",
      key: "cus_name",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Customer dealer"),
      dataIndex: "dea_name",
      key: "dea_name",
      render: text => <p>{text}</p>,
    },
    {
      render: data => (
        <Button
          type="link"
          onClick={() => {
            setCustomerSelect({
              ...customerSelect,
              cus_id: data.cus_id,
              cus_name: data.cus_name,
            })
          }}
        >
          {" "}
          {t("Select")}
        </Button>
      ),
    },
  ]

  return (
    <div>
      {customerSelect.cus_id === null && (
        <div>
          <p className="text-bold ">
            {t("Select customer")} <span style={{ color: "red" }}>*</span>
          </p>
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <Search
                style={{ width: "100%" }}
                placeholder={`${t("Search")}`}
                allowClear
                enterButton={t("Search")}
                size="medium"
                onSearch={onSearch}
                onChange={e =>
                  setSearchCustomer(e.target.value ? e.target.value : null)
                }
              />
            </Col>
          </Row>
          <br />
          <Table
            loading={loading}
            rowKey="cus_id"
            pagination={false}
            columns={columns}
            dataSource={customer_table}
            scroll={{ x: 800 }}
          />
          <div style={{ float: "right", marginTop: "10px" }}>
            <Pagination
              current={page_limit_customer.page}
              total={page_limit_customer.limit}
              onChange={changePagination}
            />
          </div>
          <p style={{ fontSize: "15px", marginTop: "10px" }}>
            {" "}
            <FcDataSheet size={20} style={{ marginBottom: "5px" }} />{" "}
            {t("total data")} : {page_limit_customer.limit}
          </p>
        </div>
      )}

      {customerSelect.cus_id !== null && (
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div style={{ display: "flex" }}>
              <p className="text-bold " style={{ marginTop: "7px" }}>
                {t("Customer")}
              </p>
              <Input
                style={{ marginLeft: "10px", height: "35px" }}
                value={customerSelect.cus_name}
                placeholder={customerSelect.cus_name}
                disabled
              />
              <a>
                <EditOutlined
                  style={{ fontSize: "20px", marginLeft: "10px" }}
                  onClick={() => {
                    setCustomerSelect({
                      ...customerSelect,
                      cus_id: null,
                      cus_name: null,
                      cus_user_id: null
                    })
                  }}
                />
              </a>
            </div>
          </Col>
        </Row>
      )}
    </div>
  )
}
