import React, { useEffect, useState } from "react"
import { Progress } from "antd"
import { Doughnut } from "react-chartjs-2"
import { useTranslation } from "react-i18next"
import contract from '../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/contract.png'

export default function Contract_count(props) {
  const [chart_count, setChart_count] = useState({})
  const { t } = useTranslation()
  const Header_text = {
    fontSize: "20px",
    marginBottom: "0px",
    color: "#A5A5A5",
  }
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      labels: {
        usePointStyle: true,
      },
      display: true,
      position: "right",
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  }

  useEffect(() => {
    setChart_count({
      labels: [t("available"), t("expired")],
      datasets: [
        {
          data: [
            props.contract_expired.available,
            props.contract_expired.expired,
          ],
          backgroundColor: ["#76CE86", "#ff6961"],
          hoverBackgroundColor: ["#7CE890", "#FF9893"],
        },
      ],
    })

    return () => {
      setChart_count({})
    }
  }, [props.contract, props.contract_expired])

  function render_percent(contract, contract_list) {
    let number = 0
    number = (contract_list / contract) * 100
    return number
  }

  return (
    <div>
      <p style={{ fontSize: "20px", marginBottom: "15px", color: "#495057" }}>
      <img src={contract} alt="contract" style={{ width:'25px', height:'25px' , marginRight:'20px', marginTop:'-10px' }} />
        {t("contract")}
      </p>
      <div>
        <p style={Header_text}>
          {t("available")}
          <span
            style={{ float: "right", color: "#495057", fontWeight: "bold" }}
          >
            {props.contract_expired.available}
          </span>{" "}
        </p>
        <Progress
          percent={render_percent(
            props.contract,
            props.contract_expired.available
          )}
          showInfo={false}
          status="active"
          strokeColor="#76CE86"
        />
        <br />
        <br />
        <p style={Header_text}>
          {t("expired")}
          <span
            style={{ float: "right", color: "#495057", fontWeight: "bold" }}
          >
            {props.contract_expired.expired}
          </span>{" "}
        </p>
        <Progress
          percent={render_percent(
            props.contract,
            props.contract_expired.expired
          )}
          showInfo={false}
          status="active"
          strokeColor="#ff6961"
        />
      </div>
      <br />
      <div>
        <Doughnut data={chart_count} options={options} />
      </div>
    </div>
  )
}
