import React, { useState, useEffect } from "react"
import * as api from "../../../service/Api_New_Service"
import { useHistory } from "react-router-dom"
import axios from "axios"
import { Row, Col } from "reactstrap"
import { Input, Pagination, Table, Tooltip, Modal, Button } from "antd"
import { FcDataSheet, FcInspection, FcImport } from "react-icons/fc"
import Import_Stock from "./Import_Stock"
import Detail_Stock from "./Detail_Stock"
import Render_Image from "../../New_Customer/Devices/Render_Image"
import helpers from "../../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"
import { GrMultiple } from "react-icons/gr"
import "./btn_new_multiple_service.css"
import { useSelector } from "react-redux"

import car from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/car.png"
import ambulance from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/ambulance.png"
import vespa from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/vespa.png"
import bus from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/bus.png"
import bicycle from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/bicycle.png"
import van from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/van.png"
import truck from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/truck.png"
import taxi from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/taxi.png"
import pickup from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/pickup.png"
import motorboat from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/motorboat.png"
import motor from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/motor.png"
import no_car from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_car.png"
import no_permi from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png"

const { Search } = Input

export default function New_Create_New_Service() {
  const { permissionAllService } = useSelector(({ Distar }) => Distar)
  const token = localStorage.getItem("token")
  const history = useHistory()
  const { t } = useTranslation()
  const [data_stock_search, setData_stock_search] = useState(null)
  const [stock_table_my_db, setStock_table_my_db] = useState([])
  const [stock_table_distar_db, setStock_table_distar_db] = useState([])
  const [group_list, setGroup_list] = useState([])
  const [warranty_list, setWarranty_list] = useState([])
  const [page_limit_my_db, setPage_limit_my_db] = useState({
    page: 1,
    limit: 0,
    page_Size: 5,
  })
  const [page_limit_distar_db, setPage_limit_distar_db] = useState({
    page: 1,
    limit: 0,
    page_Size: 5,
  })
  const [permi_t, setPermi_t] = useState({
    read: false,
    write: false,
  })
  const [loading_my_db, setLoading_my_db] = useState(false)
  const [loading_distar_db, setLoading_distar_db] = useState(false)
  const [select_stock_modal, setSelect_stock_modal] = useState(false)
  const [import_stock_select, setImport_stock_select] = useState({})
  const [data_device, setData_device] = useState({})

  useEffect(() => {
    if (permissionAllService) {
      if (permissionAllService.service) {
        setPermi_t({
          ...permi_t,
          read: permissionAllService.service.read,
          write: permissionAllService.service.write,
        })
      }
    }
    return () => {}
  }, [permissionAllService])

  useEffect(() => {
    search_data_stock_in()
    list_group_warranty()
    list_group_product()
    return () => {
      setStock_table_my_db([])
      setStock_table_distar_db([])
      setData_stock_search(null)
      setGroup_list([])
      setWarranty_list([])
    }
  }, [])

  function list_group_warranty() {
    const list_group = api.LIST_PRODUCT_WARRANTY(token)
    axios(list_group)
      .then(res => {
        setWarranty_list(res.data.data)
      })
      .catch(error => {
        // console.log(error.response)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  function list_group_product() {
    const list_group = api.LIST_PRODUCT_GROUP(token)
    axios(list_group)
      .then(res => {
        setGroup_list(res.data.data)
      })
      .catch(error => {
        // console.log(error.response)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  function search_data_stock_in() {
    setLoading_my_db(true)
    setLoading_distar_db(true)
    if (data_stock_search) {
      const search_stock_my_db_data = api.FIND_ALL_STOCK_MY_DB_DATA(
        token,
        1,
        page_limit_my_db.page_Size,
        data_stock_search
      )
      const search_stock_distar_data = api.FIND_ALL_STOCK_DISTAR_DB_DATA(
        token,
        1,
        page_limit_distar_db.page_Size,
        data_stock_search
      )

      axios(search_stock_my_db_data)
        .then(res => {
          setLoading_my_db(false)
          setStock_table_my_db(res.data.db.data)
          setPage_limit_my_db({
            ...page_limit_my_db,
            page: 1,
            limit: res.data.db.total_data,
          })
        })
        .catch(error => {
          setLoading_my_db(false)
          // console.log(error.response)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })

      axios(search_stock_distar_data)
        .then(res => {
          setLoading_distar_db(false)
          setStock_table_distar_db(res.data.distar_db.data)
          setPage_limit_distar_db({
            ...page_limit_distar_db,
            page: 1,
            limit: res.data.distar_db.total_data,
          })
        })
        .catch(error => {
          setLoading_distar_db(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else {
      const search_stock_my_db_no_data = api.FIND_ALL_STOCK_MY_DB_NO_DATA(
        token,
        1,
        page_limit_my_db.page_Size
      )
      const search_stock_distar_no_data = api.FIND_ALL_STOCK_DISTAR_DB_NO_DATA(
        token,
        1,
        page_limit_distar_db.page_Size
      )

      axios(search_stock_my_db_no_data)
        .then(res => {
          setLoading_my_db(false)
          setStock_table_my_db(res.data.db.data)
          setPage_limit_my_db({
            ...page_limit_my_db,
            page: 1,
            limit: res.data.db.total_data,
          })
        })
        .catch(error => {
          setLoading_my_db(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })

      axios(search_stock_distar_no_data)
        .then(res => {
          setLoading_distar_db(false)
          setStock_table_distar_db(res.data.distar_db.data)
          setPage_limit_distar_db({
            ...page_limit_distar_db,
            page: 1,
            limit: res.data.distar_db.total_data,
          })
        })
        .catch(error => {
          setLoading_distar_db(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  function changePagination_my_db(page, pageSize) {
    setLoading_my_db(true)
    if (data_stock_search) {
      const search_stock_data = api.FIND_ALL_STOCK_MY_DB_DATA(
        token,
        page,
        pageSize,
        data_stock_search
      )
      axios(search_stock_data)
        .then(res => {
          setLoading_my_db(false)
          setStock_table_my_db(res.data.db.data)
          setPage_limit_my_db({
            ...page_limit_my_db,
            page: page,
            page_Size: pageSize,
            limit: res.data.db.total_data,
          })
        })
        .catch(error => {
          setLoading_my_db(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }

          // console.log(error)
        })
    } else {
      const search_stock_no_data = api.FIND_ALL_STOCK_MY_DB_NO_DATA(
        token,
        page,
        pageSize
      )
      axios(search_stock_no_data)
        .then(res => {
          setLoading_my_db(false)
          setStock_table_my_db(res.data.db.data)
          setPage_limit_my_db({
            ...page_limit_my_db,
            page: page,
            page_Size: pageSize,
            limit: res.data.db.total_data,
          })
        })
        .catch(error => {
          setLoading_my_db(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
          // console.log(error)
        })
    }
  }

  function changePagination_distar_db(page, pageSize) {
    setLoading_distar_db(true)
    if (data_stock_search) {
      const search_stock_data = api.FIND_ALL_STOCK_DISTAR_DB_DATA(
        token,
        page,
        pageSize,
        data_stock_search
      )
      axios(search_stock_data)
        .then(res => {
          setLoading_distar_db(false)
          setStock_table_distar_db(res.data.distar_db.data)
          setPage_limit_distar_db({
            ...page_limit_distar_db,
            page: page,
            page_Size: pageSize,
            limit: res.data.distar_db.total_data,
          })
        })
        .catch(error => {
          setLoading_distar_db(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
          // console.log(error)
        })
    } else {
      const search_stock_no_data = api.FIND_ALL_STOCK_DISTAR_DB_NO_DATA(
        token,
        page,
        pageSize
      )
      axios(search_stock_no_data)
        .then(res => {
          setLoading_distar_db(false)
          setStock_table_distar_db(res.data.distar_db.data)
          setPage_limit_distar_db({
            ...page_limit_distar_db,
            page: page,
            page_Size: pageSize,
            limit: res.data.distar_db.total_data,
          })
        })
        .catch(error => {
          setLoading_distar_db(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
          // console.log(error)
        })
    }
  }

  function render_Icon_Car(type_car) {
    switch (type_car) {
      case "car":
        return car
        break
      case "vespa":
        return vespa
        break
      case "van":
        return van
        break
      case "truck":
        return truck
        break
      case "taxi":
        return taxi
        break
      case "bus":
        return bus
        break
      case "pickup":
        return pickup
        break
      case "motorboat":
        return motorboat
        break
      case "motor":
        return motor
        break
      case "bicycle":
        return bicycle
        break
      case "ambulance":
        return ambulance
        break
      default:
        return no_car
        break
    }
  }

  const columns_my_db = [
    {
      title: "",
      dataIndex: "pro_model_img",
      key: "pro_model_img",
      render: text => <Render_Image path_image={text} size={60} />,
    },
    {
      title: t("Product model code"),
      dataIndex: "pro_model_code",
      key: "pro_model_code",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Product model name"),
      dataIndex: "pro_model_name",
      key: "pro_model_name",
      render: text => <p>{text}</p>,
      width: "150px",
    },
    {
      title: t("Product serial number"),
      dataIndex: "pro_serial_number",
      key: "pro_serial_number",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Car name"),
      dataIndex: "car_name",
      key: "car_name",
      render: text => <p>{text}</p>,
      width: "150px",
    },
    {
      title: t("Car license number"),
      dataIndex: "car_license_number",
      key: "car_license_number",
      render: text => <p>{text}</p>,
      width: "150px",
    },
    {
      title: t("Car type"),
      dataIndex: "car_body_type_name",
      key: "car_body_type_name",
      render: text => (
        <Tooltip title={text ? text : t("Invalid car type")}>
          <img
            src={render_Icon_Car(text)}
            alt="icon"
            style={{ width: "50px" }}
          />
        </Tooltip>
      ),
    },
    {
      title: t("Customer name"),
      dataIndex: "cus_name",
      key: "cus_name",
      render: text => <p>{text}</p>,
    },
    {
      title: "",
      dataIndex: "sto_id",
      key: "sto_id",
      render: text => (
        <Tooltip title={t("Select this id")}>
          <a>
            <FcInspection
              onClick={() => history.push(`/new-service/${text}`)}
              size={20}
            />
          </a>
        </Tooltip>
      ),
    },
  ]

  const columns_distar_db = [
    {
      title: t("Car id"),
      dataIndex: "car_id",
      key: "car_id",
      width: "100px",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Title"),
      dataIndex: "car_title",
      key: "car_title",
      render: text => <p>{text}</p>,
      width: "150px",
    },
    {
      title: t("Car name"),
      dataIndex: "car_name",
      key: "car_name",
      render: text => <p>{text}</p>,
      width: "150px",
    },
    {
      title: t("Device group"),
      dataIndex: "device_group",
      key: "device_group",
      render: text => <p>{text}</p>,
      width: "150px",
    },
    {
      title: t("Device serial number"),
      dataIndex: "device_serial_number",
      key: "device_serial_number",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Device remark"),
      dataIndex: "device_remark",
      key: "device_remark",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Product model code"),
      dataIndex: "pro_model_code",
      key: "pro_model_code",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Product model name"),
      dataIndex: "pro_model_name",
      key: "pro_model_name",
      render: text => <p>{text}</p>,
      width: "150px",
    },
    {
      title: t("Sim id"),
      dataIndex: "sim_id",
      key: "sim_id",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Sim phone"),
      dataIndex: "sim_phone",
      key: "sim_phone",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Sim remark"),
      dataIndex: "sim_remark",
      key: "sim_remark",
      render: text => <p>{text}</p>,
    },
    {
      title: "",
      render: text => (
        <Tooltip title={t("Select id")}>
          <a>
            <FcImport
              size={20}
              onClick={() => {
                setSelect_stock_modal(true)
                setImport_stock_select(text)
                setData_device(text)
              }}
            />
          </a>
        </Tooltip>
      ),
    },
  ]

  function onSearch(value) {
    search_data_stock_in()
  }

  function set_search_data(value) {
    if (value) {
      setData_stock_search(value)
    } else {
      setData_stock_search(null)
    }
  }

  return (
    <div>
      {permi_t.read === true && (
        <div>
          <Row>
            <Col sm={12} md={12} lg={10} xl={8}>
              <Row>
                <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                  <Search
                    style={{ width: "100%" }}
                    placeholder={`${t("Car license number")} , ${t(
                      "Customer name"
                    )}`}
                    enterButton={t("Search")}
                    size="medium"
                    onSearch={onSearch}
                    onChange={e => set_search_data(e.target.value)}
                  />
                </Col>
                {permi_t.write === true && (
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Button
                      className="button-new-multiple-service"
                      onClick={() => history.push("/new-service-multiple")}
                      icon={
                        <GrMultiple
                          style={{
                            fontSize: "15px",
                            marginRight: "10px",
                            marginBottom: "5px",
                          }}
                        />
                      }
                    >
                      {t("create multiple service")}
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
          <br />
          <Table
            loading={loading_my_db}
            rowKey="sto_id"
            pagination={false}
            columns={columns_my_db}
            dataSource={stock_table_my_db}
            scroll={{ x: 800 }}
          />
          <div style={{ float: "right", marginTop: "10px" }}>
            <Pagination
              current={page_limit_my_db.page}
              total={page_limit_my_db.limit}
              onChange={changePagination_my_db}
              defaultPageSize="5"
            />
          </div>
          <p style={{ fontSize: "15px", marginTop: "15px" }}>
            {" "}
            <FcDataSheet size={20} style={{ marginBottom: "5px" }} />{" "}
            {t("total data")} : {page_limit_my_db.limit}
          </p>

          <br />
          <hr />
          <br />
          <Table
            loading={loading_distar_db}
            rowKey={"id"}
            pagination={false}
            columns={columns_distar_db}
            dataSource={stock_table_distar_db}
            scroll={{ x: 800 }}
          />
          <div style={{ float: "right", marginTop: "10px" }}>
            <Pagination
              current={page_limit_distar_db.page}
              total={page_limit_distar_db.limit}
              onChange={changePagination_distar_db}
              defaultPageSize="5"
            />
          </div>
          <p style={{ fontSize: "15px", marginTop: "15px" }}>
            {" "}
            <FcDataSheet size={20} style={{ marginBottom: "5px" }} />{" "}
            {t("total data")} : {page_limit_distar_db.limit}
          </p>
        </div>
      )}

      <Modal
        title={false}
        centered
        open={select_stock_modal}
        onOk={() => setSelect_stock_modal(false)}
        onCancel={() => {
          setData_device({})
          setSelect_stock_modal(false)
          setImport_stock_select({})
        }}
        footer={false}
        height={500}
        width={1000}
      >
        <Detail_Stock data_device={data_device} />
        <Import_Stock
          setSelect_stock_modal={setSelect_stock_modal}
          setImport_stock_select={setImport_stock_select}
          select_stock_modal={select_stock_modal}
          group_list={group_list}
          warranty_list={warranty_list}
          import_stock_select={import_stock_select}
        />
      </Modal>

      {permi_t.read === false && (
        <div>
          <img
            src={no_permi}
            alt="no permission"
            style={{ display: "block", margin: "50px auto" }}
          />
          <p style={{ textAlign: "center", fontSize: "20px" }}>
            {t("no permission read module")}
          </p>
          <p style={{ textAlign: "center", fontSize: "20px" }}>
            {" "}
            {t("please contract administrator")}{" "}
          </p>
        </div>
      )}
    </div>
  )
}
