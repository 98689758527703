import React from "react"
import { Row, Col } from "reactstrap"
import { Collapse, Card } from "antd"
import Col_Device from "./components/Col_Device"
import customer_info from "../../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/customer_info.png"
import { useTranslation } from "react-i18next";

const { Panel } = Collapse

export default function Customer_Info(props) {
  const { t } = useTranslation();
  const icons_info_header = {
    width: "25px",
    marginRight: "20px",
  }

  return (
    <div>
    {
      props.customer_data && (
        <Card
        title={
          <p>
            {" "}
            <img
              src={customer_info}
              alt="customer_info"
              style={icons_info_header}
            />{" "}
            {t("Customer")}
          </p>
        }
      >
        <Collapse>
          <Panel
            header={
              props.customer_data.cus_name
            }
            key="1"
          >
            <Row>
              {props.customer_data.cus_name && (
                  <Col_Device
                    text_header={t("Customer name")}
                    data={
                      props.customer_data.cus_name
                    }
                  />
                )}

              {props.customer_data.cus_taxid && (
                  <Col_Device
                    text_header={t("Customer TAX ID")}
                    data={
                      props.customer_data.cus_taxid
                    }
                  />
                )}

              {props.customer_data.cus_code  && (
                  <Col_Device
                    text_header={t("Customer code")}
                    data={
                      props.customer_data.cus_code
                    }
                  />
                )}

              {props.customer_data.cus_credit && (
                  <Col_Device
                    text_header={t("Customer credit")}
                    data={
                      props.customer_data.cus_credit
                    }
                  />
                )}

              {props.customer_data.cus_phone  && (
                  <Col_Device
                    text_header={t("Customer phone")}
                    data={
                      props.customer_data.cus_phone
                    }
                  />
                )}

              {props.customer_data.cus_email  && (
                  <Col_Device
                    text_header={t("Customer email")}
                    data={
                      props.customer_data.cus_email
                    }
                  />
                )}

              {props.customer_data.cus_fax  && (
                  <Col_Device
                    text_header={t("Customer fax")}
                    data={
                      props.customer_data.cus_fax
                    }
                  />
                )}
              {props.customer_data.cus_address  && (
                  <Col_Device
                    text_header={t("Customer address")}
                    data={
                      props.customer_data.cus_address
                    }
                  />
                )}
              {props.customer_data.cus_remark && (
                  <Col_Device
                    text_header={t("Customer remark")}
                    data={
                      props.customer_data.cus_remark
                    }
                  />
                )}
            </Row>
          </Panel>
        </Collapse>
      </Card>
      )
    }

    {
      props.my_customer && (
        <Card
        title={
          <p>
            {" "}
            <img
              src={customer_info}
              alt="customer_info"
              style={icons_info_header}
            />{" "}
            {t("Customer")}
          </p>
        }
      >
        <Collapse>
          <Panel
            header={
               props.my_customer.cus_name
            }
            key="1"
          >
            <Row>
              {props.my_customer.cus_name && (
                  <Col_Device
                    text_header={t("Customer name")}
                    data={
                      props.my_customer.cus_name
                    }
                  />
                )}

              {props.my_customer.cus_taxid && (
                  <Col_Device
                    text_header={t("Customer TAX ID")}
                    data={
                       props.my_customer.cus_taxid
                    }
                  />
                )}

              {props.my_customer.cus_code && (
                  <Col_Device
                    text_header={t("Customer code")}
                    data={
                      props.my_customer.cus_code
                    }
                  />
                )}

              {props.my_customer.cus_credit && (
                  <Col_Device
                    text_header={t("Customer credit")}
                    data={
                     props.my_customer.cus_credit
                    }
                  />
                )}

              {props.my_customer.cus_phone && (
                  <Col_Device
                    text_header={t("Customer phone")}
                    data={
                      props.my_customer.cus_phone
                    }
                  />
                )}

              {props.my_customer.cus_email && (
                  <Col_Device
                    text_header={t("Customer email")}
                    data={
                      props.my_customer.cus_email
                    }
                  />
                )}

              {props.my_customer.cus_fax && (
                  <Col_Device
                    text_header={t("Customer fax")}
                    data={
                     props.my_customer.cus_fax
                    }
                  />
                )}
              {props.my_customer.cus_address && (
                  <Col_Device
                    text_header={t("Customer address")}
                    data={
                       props.my_customer.cus_address
                    }
                  />
                )}
              {props.my_customer.cus_remark && (
                  <Col_Device
                    text_header={t("Customer remark")}
                    data={
                      props.my_customer.cus_remark
                    }
                  />
                )}
            </Row>
          </Panel>
        </Collapse>
      </Card>
      )
    }
      
    </div>
  )
}
