import React, { useState, useEffect } from "react"
import { Container, Card, CardBody } from "reactstrap"
import MetaTags from "react-meta-tags"
import { useHistory, useParams } from "react-router-dom"
import { Tabs } from "antd"
import Edit from "./Edit"
import Detail from "./Detail"
import Delete from "./Setting/Delete"
import { MdKeyboardBackspace } from "react-icons/md"
import * as api from "../../service/API_Edit_Car"
import Device from "./Device"
import axios from "axios"
import helpers from "../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"

const { TabPane } = Tabs

export default function Edit_A_car(props) {
  const token = localStorage.getItem("token")
  const { t } = useTranslation()
  const [data_car, setData_car] = useState({})
  const [cus_id, setCus_id] = useState(null)
  const history = useHistory()
  const [activeTab_all_Customer, setActiveTab_all_Customer] = useState("1")
  let { profile_id, car_id } = useParams()

  useEffect(() => {
    check_detail_car()
    setActiveTab_all_Customer(
      props.location.page_local ? props.location.page_local : "1"
    )
    if (car_id === undefined) {
      history.push({
        pathname: `/customers/${profile_id}/`,
        page_local: "5",
      })
    }
    return () => {}
  }, [car_id])

  function check_detail_car() {
    const new_data = api.DETAIL_CAR(token, car_id)
    axios(new_data)
      .then(res => {
        if (!res.data.data[0]) {
          history.push({
            pathname: `/customers/${profile_id}/`,
            page_local: "5",
          })
        } else {
          setData_car(res.data.data[0])
        }
      })
      .catch(error => {
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("edit car")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() =>
                  history.push({
                    pathname: `/customers/${profile_id}/`,
                    // data: props.location.data.cus_id,
                    page_local: "5",
                  })
                }
              />
            </a>{" "}
            {t("edit car")}
          </h3>

          <Card>
            <CardBody>
              <Tabs
                defaultActiveKey="1"
                onTabClick={e => {
                  setActiveTab_all_Customer(e)
                }}
                activeKey={activeTab_all_Customer}
              >
                <TabPane tab={t("Detail")} key="1">
                  <Detail data_car={data_car} />
                </TabPane>
                <TabPane tab={t("Device")} key="2">
                  <Device car_id={parseInt(car_id)} cus_id={profile_id} />
                </TabPane>
                <TabPane tab={t("edit")} key="3">
                  <Edit
                    cus_id={profile_id}
                    data_car={data_car}
                    setData_car={setData_car}
                  />
                </TabPane>
                <TabPane tab={t("Setting")} key="4">
                  <Delete data_car={data_car} cus_id={profile_id} />
                </TabPane>
              </Tabs>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
