import React, { useState, useEffect } from "react"
import { Select, Form } from "antd"
import axios from "axios"
import * as api from "../../../service/Api_Edit_Operator"
import { useHistory } from "react-router-dom"
import helpers from "../../Function_Redirect/Redirect_login"

const { Option } = Select
export default function Select_no_search_Location({
  header,
  keyState,
  name_api,
  headState,
  nameState,
  map_name,
  placeholder,
  map_value,
  data_response,
  Only_select,
  disabled,
  require,
}) {
  const token = localStorage.getItem("token")
  const history = useHistory()
  const [data_select, setData_select] = useState([])

  useEffect(() => {
    check_data_select()
    return () => {
      setData_select([])
    }
  }, [])

  function check_data_select() {
    const check_data_sel = api[name_api]()
    axios(check_data_sel)
      .then(res => {
        setData_select(res.data[data_response])
      })
      .catch(error => {
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }

        // console.log(error);
      })
  }

  function onChange(value) {
    if (value) {
      headState({ ...nameState, [keyState]: value })
    }
  }

  useEffect(() => {
    render_select_data()
    return () => {}
  }, [data_select])

  function render_select_data() {
    return data_select.map(data => {
      return (
        <Option key={data[map_value]} value={data[map_value]}>
          {data[map_name]}
        </Option>
      )
    })
  }
  return (
    <div>
      <p className="text-bold">
        {header} <span style={{ color: "red" }}>*</span>
      </p>
      <Form.Item
        name={keyState}
        rules={[
          {
            required: require,
            message: `${placeholder}`,
          },
        ]}
      >
        <Select
          showSearch
          disabled={disabled}
          placeholder={`${placeholder}`}
          style={{ width: "100%" }}
          optionFilterProp="children"
          onChange={onChange}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {render_select_data()}
        </Select>
      </Form.Item>
    </div>
  )
}
