import React, { useState, useEffect } from "react"
import Search_data_Comp from "./Search_data_Comp"
import { Table, Button, Pagination, Tooltip } from "antd"
import Render_Image_Data from '../../../../Employee/Table/Render_Image_Data'
import { FcSearch, FcSettings, FcDataSheet } from "react-icons/fc"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next";

export default function Table_user(props) {
  const { t } = useTranslation();
  const history = useHistory()
  const [data_table, setData_table] = useState([])
  useEffect(() => {
    setData_table(props.data_User_All)
    return () => {
      setData_table([])
    }
  }, [props.data_User_All])

  const columns = [
    {
      title: "",
      dataIndex: "cus_user_avatar",
      key: "cus_user_avatar",
      render: text => <Render_Image_Data path_image={text} />,
    },
    {
      title: t("Firstname"),
      dataIndex: "cus_user_firstname",
      key: "cus_user_firstname",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Lastname"),
      dataIndex: "cus_user_lastname",
      key: "cus_user_lastname",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Branch name"),
      dataIndex: "cus_branch_name",
      key: "cus_branch_name",
      render: text => <p>{text}</p>,
    },
    {
      title: t("email"),
      dataIndex: "cus_user_email",
      key: "cus_user_email",
      render: text => <p>{text}</p>,
    },
  ]
  return (
    <div>
      <Search_data_Comp
        setSearch_data_user={props.setSearch_data_user}
        search_data_user_table={props.search_data_user_table}
      />

      <Table
      loading={props.loading}
        rowKey="cus_user_id"
        columns={columns}
        dataSource={data_table}
        pagination={false}
        scroll={{ x: 800 }}
      />

      <Pagination
        current={props.page_limit.page}
        total={props.page_limit.limit}
        onChange={props.changePagination_users}
        style={{ marginTop: "20px", float: "right" }}
      />
      <p style={{ fontSize:'15px' , marginTop:'25px'}}> <FcDataSheet size={20} style={{ marginBottom:'5px' }} /> {t("total data")} : {props.page_limit.limit}</p>
    </div>
  )
}
