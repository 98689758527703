import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Container, Card, CardBody } from "reactstrap"
import { Tabs } from "antd"
import axios from "axios"
import * as api from "../../service/Api_Profile_Menu"
import { useHistory } from "react-router-dom"
import helpers from "../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"
import Detail_Profile from "../components/Profile/Detail_Profile"
import Edit_User from './Edit_User'

import {useDispatch , useSelector } from "react-redux"
import { ADD_NAME_USER , ADD_IMAGE_AVATAR } from '../../store/Distar/actionTypes';
import ChangePassword from '../components/Profile/ChangePassword'

const { TabPane } = Tabs

const Profile_Customer = props => {
  const { t } = useTranslation()
  const token = localStorage.getItem("token")
  const history = useHistory()
  const [data_user, setData_user] = useState(null)
  const [img, setImg] = useState(null)
  const [detailUser, setDetailUser] = useState([])
  const [currentTab, setCurrentTab] = useState("1")
  const dispatch = useDispatch()
  const action = (type,payload)=>dispatch({type, payload})
  const { privateID } = useSelector(({ Distar }) => Distar)

  useEffect(() => {
    if(privateID !== null){
      checkDetailCustomer()
    }
    return () => {}
  }, [privateID])

  function checkDetailCustomer() {
    const get_user_customer = api.CHECK_USER_CUSTOMER(token, privateID)
    axios(get_user_customer)
      .then(res => {
        if (res.data.data[0] === undefined) {
          helpers.re_login()
          history.push("/login")
        } else {
          action(ADD_NAME_USER, res.data.data[0].cus_user_firstname)
          action(ADD_IMAGE_AVATAR, res.data.data[0].cus_user_avatar)
          get_image(res.data.data[0].cus_user_avatar)
          setData_user({
            cus_branch_id: res.data.data[0].cus_branch_id,
            cus_branch_name: res.data.data[0].cus_branch_name,
            cus_code: res.data.data[0].cus_code,
            cus_id: res.data.data[0].cus_id,
            cus_line_uid: res.data.data[0].cus_line_uid,
            cus_name: res.data.data[0].cus_name,
            cus_user_avatar: res.data.data[0].cus_user_avatar,
            cus_user_code: res.data.data[0].cus_user_code,
            cus_user_email: res.data.data[0].cus_user_email,
            cus_user_firstname: res.data.data[0].cus_user_firstname,
            cus_user_lastname: res.data.data[0].cus_user_lastname,
            cus_user_phone: res.data.data[0].cus_user_phone,
            cus_user_remark: res.data.data[0].cus_user_remark,
            dep_id: res.data.data[0].dep_id,
            dep_name: res.data.data[0].dep_name,
            not_send_email_notification:
              res.data.data[0].not_send_email_notification,
            not_send_line_notification:
              res.data.data[0].not_send_line_notification,
            pos_id: res.data.data[0].pos_id,
            pos_name: res.data.data[0].pos_name,
          })
          setDetailUser([
            {
              headerDetail: t("firstname"),
              data: check_data(res.data.data[0].cus_user_firstname),
            },
            {
              headerDetail: t("lastname"),
              data: check_data(res.data.data[0].cus_user_lastname),
            },
            {
              headerDetail: t("email"),
              data: check_data(res.data.data[0].cus_user_email),
            },
            {
              headerDetail: t("customer"),
              data: check_data(res.data.data[0].cus_name),
            },
            {
              headerDetail: t("branch"),
              data: check_data(res.data.data[0].cus_branch_name),
            },
            {
              headerDetail: t("phone"),
              data: check_data(res.data.data[0].cus_user_phone),
            },
            {
              headerDetail: t("department"),
              data: check_data(res.data.data[0].dep_name),
            },
            {
              headerDetail: t("position"),
              data: check_data(res.data.data[0].pos_name),
            },
          ])
        }
      })
      .catch(error => {
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  async function get_image(path_image) {
    const get_data_image = api.GET_IMAGE(token, path_image)
    await axios(get_data_image)
      .then(res => {
        const blob = new Blob([res.data], {
          type: "image/jpeg",
        })
        var objectURL = URL.createObjectURL(blob)
        setImg(objectURL)
      })
      .catch(error => {
        // console.log(error.response);
      })
  }

  function check_data(data) {
    if (data === null) {
      return "N/A"
    } else {
      return data
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Profile")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}> {t("Profile")} </h3>

          {data_user !== null && (
            <Card>
              <CardBody>
                <Tabs
                     defaultActiveKey="1"
                     onTabClick={e => {
                       setCurrentTab(e)
                     }}
                     activeKey={currentTab}
                >
                  <TabPane tab={t("Overview")} key="1">
                    <Detail_Profile
                      imageProfile={img}
                      detailUser={detailUser}
                      sizeImage={120}
                      nameHeader={t("Profile")}
                    />
                  </TabPane>
                  <TabPane tab={t("Edit")} key="2">
                    <Edit_User
                    data_user={data_user}
                    user_id={privateID}
                    check_detail_user={checkDetailCustomer}
                  />
                  </TabPane>
                  <TabPane tab={t("change password")} key="3">
                    <ChangePassword setCurrentTab={setCurrentTab} />
                  </TabPane>
                </Tabs>
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Profile_Customer
