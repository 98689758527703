import React from "react"
import { Row, Col } from "reactstrap"
import { Button, Input, Form } from "antd"
import { useTranslation } from "react-i18next";

export default function Data_car_search(props) {
  const { t } = useTranslation();

    const onFinish = values => {
      props.search_car()
    }

    return (
        <>
        <Form
          name="Search_car"
          initialValues={{
            remember: false,
          }}
          onFinish={onFinish}
        >
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <Form.Item
                name="search"
                rules={[
                  {
                    required: false,
                    message: "Please input name,department",
                  },
                ]}
              >
              <Input
                  placeholder={`${t("Name")} , ${t("License")}`}
                  style={{ marginBottom: "15px" }}
                  onChange={e => props.setData_car_search(e.target.value)}
              /> 
              </Form.Item>
            </Col>
            <Col sm={12} md={6} lg={4} xl={4}>
              <Button
                type="primary"
                className="button-search-employee"
                htmlType="submit"
       style={{ marginBottom:'15px' }}
              >
                {t("Search")}
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    )
}
