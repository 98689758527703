import React, { useState, useEffect } from "react"
import { Row, Col, Container, Card, CardBody } from "reactstrap"
import { Tabs } from "antd"
import Profile from "./Profile"
import Level from "./Level"
import { useHistory } from "react-router-dom"
import { MdKeyboardBackspace } from "react-icons/md"
import { useTranslation } from "react-i18next"
import no_permi from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png"

const { TabPane } = Tabs

const New_Employee = props => {
  const { t } = useTranslation()
  const [image_preview, setImage_preview] = useState(null)
  const [select_image, setSelect_image] = useState(null)
  const [key_Tabs, setKey_Tabs] = useState("1")
  const [data_new_employee, setData_new_employee] = useState({
    emp_code: null,
    emp_firstname: null,
    emp_lastname: null,
    dep_id: null,
    pos_id: null,
    emp_email: null,
    emp_email_password_condition: false,
    emp_level_id: null,
    emp_password: null,
  })
  const history = useHistory()

  const permi = localStorage.getItem("permi")
  const [permi_t, setPermi_t] = useState(false)

  useEffect(() => {
    if (permi) {
      if (JSON.parse(permi).employee) {
        setPermi_t(
          JSON.parse(permi).employee.write
            ? JSON.parse(permi).employee.write
            : false
        )
      }
    }
    return () => {}
  }, [permi])

  useEffect(() => {
    return () => {
      setImage_preview(null)
      setSelect_image(null)
      setData_new_employee({})
    }
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/employee")}
              />
            </a>{" "}
            {t("new Employee")}
          </h3>
          <Card>
            <CardBody>
     
                <Tabs defaultActiveKey="1" activeKey={key_Tabs}>
                  <TabPane tab={t("profile")} key="1" disabled>
                    <Profile
                      setImage_preview={setImage_preview}
                      setSelect_image={setSelect_image}
                      image_preview={image_preview}
                      setKey_Tabs={setKey_Tabs}
                      data_new_employee={data_new_employee}
                      setData_new_employee={setData_new_employee}
                    />
                  </TabPane>
                  <TabPane tab={t("level")} key="2" disabled>
                    <Level
                      select_image={select_image}
                      setKey_Tabs={setKey_Tabs}
                      data_new_employee={data_new_employee}
                      setData_new_employee={setData_new_employee}
                    />
                  </TabPane>
                </Tabs>
            
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default New_Employee
