import React,{ useEffect , useState} from "react"
import { Row, Col } from "reactstrap"
import { Button, Input, Form, Space } from "antd"
import { useHistory } from "react-router-dom"
import { RiNewspaperLine } from "react-icons/ri"
import Filter_Comp from './Filter_Comp'
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux"

const { Search } = Input;

export default function Search_contract(props) {
  const { t } = useTranslation();
    const history = useHistory()
    const permi = localStorage.getItem('permi')
    const [permi_t, setPermi_t] = useState(false)
    const { permissionAllService } = useSelector(({ Distar }) => Distar)

  useEffect(() => {
    if(permissionAllService){
      if(permissionAllService.contract){
        setPermi_t(permissionAllService.contract.write)
      }
    }
    return () => {
  
    }
  }, [permissionAllService])

    const onFinish = values => {
      props.search_data()
    }

    return (
        <div>
        <Form
          name="Search_contract_data_table"
          initialValues={{
            remember: false,
          }}
          onFinish={onFinish}
        >
          <Row>
            <Col sm={12} md={6} lg={6} xl={4}>
              <Form.Item
                name="search"
                rules={[
                  {
                    required: false,
                    message: "Please input name,department",
                  },
                ]}
              >
              <Input
                  placeholder={t("Name")}
                  style={{ marginBottom: "15px" }}
                  onChange={e => props.setData_search_contract(e.target.value)}
              /> 
              </Form.Item>
            </Col>
            <Col sm={12} md={6} lg={4} xl={4}>
            
              <Button
                type="primary"
                className="button-search-employee"
                htmlType="submit"
              >
                {t("Search")}
              </Button>
{
  permi_t === true && (
    <Button
    className="button-search-New-Employee"
    onClick={() => history.push("/new-contract")}
    icon={<RiNewspaperLine style={{ fontSize: "15px", marginRight:'10px' }} />}
  >
    {t("New contract")}
  </Button>
  )
}


            </Col>
          </Row>
        </Form>

        <Filter_Comp 
        filter_contract={props.filter_contract}
        setFilter_contract={props.setFilter_contract}
        />
<br />
      </div>
    )
}
