import React from 'react'
import { Form } from "antd"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

export default function Input_ckeditor({
    placeholder,
    header,
    headState,
    nameState,
    keyState
}) {

  function render_data(data) {
    if(data){
      return data
    } else {
      return ""
    }
  // if(data === undefined || data === null || !data){
  //     console.log('undefined');
  //     return ""
  //   } else {
  //     return data
  //   }
  }
    return (
        <div >
        <p className="text-bold">
          {header} <span style={{ color:'red' }}>*</span>
        </p>
        <Form.Item
          name={keyState}
          rules={[
            {
              required: true,
              message: `${placeholder}`,
            },
          ]}
        >
            <CKEditor
              editor={ClassicEditor}
              config={{
                removePlugins: [
                  "ImageUpload",
                  "EasyImage",
                  "MediaEmbed",
                  "Table",
                ],
              }}
              // data={
              //   nameState[keyState]
              //     ? nameState[keyState]
              //     : ""
              // }
              value={render_data(nameState[keyState])}
              onChange={(event, editor) => {
                const data = editor.getData()
                // console.log( { event, editor, data } );
                if (data) {
                  headState({
                    ...nameState,
                    [keyState]: data,
                  })
                } else {
                  headState({
                    ...nameState,
                    [keyState]: null,
                  })
                }
              }}
            />
        </Form.Item>
      </div>
    )
}
