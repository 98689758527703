import React from 'react'
import { Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next";

export default function Detail_car(props) {
    const { t } = useTranslation();
    const text_header = {
        fontWeight:'bold',
        fontSize:'15px'
    }
    const data_map = [
        {
            header : t("License no"),
            dataCar : props.data_car.car_license_number
        },
        {
            header : t("Car name"),
            dataCar : props.data_car.car_name,
        },
        {
            header : t("Province"),
            dataCar : props.data_car.pro_name,
        },
        {
            header : t("Color"),
            dataCar : props.data_car.car_color_name,
        },
        {
            header : t("Chassis number"),
            dataCar : props.data_car.car_chassis_number,
        },
        {
            header : t("Car type"),
            dataCar : props.data_car.car_body_type_name,
        },
        {
            header : t("Car group"), 
            dataCar : props.data_car.car_group_name,
        },
        {
            header : t("Brand"),
            dataCar : props.data_car.car_brand_name,
        },
        {
            header : t("Model"),
            dataCar : props.data_car.car_model_name,
        },
        {
            header : t("Year"),
            dataCar : props.data_car.car_model_year_name,
        },
        {
            header : t("Customer"),
            dataCar : props.data_car.cus_name,
        }, 
        {
            header : t("Branch code"),
            dataCar : props.data_car.cus_branch_code,
        },
        {
            header : t("Branch"),
            dataCar : props.data_car.cus_branch_name,
        },
        {
            header : t("Branch contact firstname"),
            dataCar : props.data_car.cus_branch_contact_firstname,
        },
        {
            header : t("Branch contact lastname"),
            dataCar : props.data_car.cus_branch_contact_lastname,
        },
        {
            header : t("Branch address"),
            dataCar : props.data_car.cus_branch_address,
        },
        {
            header : t("Branch phone"),
            dataCar : props.data_car.cus_branch_phone,
        },
        {
            header : t("Branch contact email"),
            dataCar : props.data_car.cus_branch_email,
        },
        {
            header : t("Branch contact fax"),
            dataCar : props.data_car.cus_branch_fax,
        },
        {
            header : t("Car remark"),
            dataCar : props.data_car.car_remark,
        }
        
    ]

    return (
        <div>
        <p style={{ textAlign:'center', fontWeight:'bold', marginTop:'10px', fontSize:'15px' }}> {t("Car information")} </p>
        <hr />
  
       <Row>
        {
            data_map.map( (data, index) => {
                return(
                <>
                    <Col sm={6} md={3} lg={3} xl={3}  >
                    <p style={text_header}>{data.header}</p>
                    </Col>
                    <Col sm={6} md={3} lg={3} xl={3}>
                    <p style={{ fontSize:'15px'}}> <span className="tet">:</span> <span className="tetTwo">-</span> {data.dataCar}</p>
                    </Col>
                </>
                )
            })
        }
       </Row>

        </div>
    )
}
