import React, { useState, useEffect } from "react"
import { Container, Card, CardBody } from "reactstrap"
import MetaTags from "react-meta-tags"
import { MdKeyboardBackspace } from "react-icons/md"
import { useHistory, useParams } from "react-router-dom"
import { Tabs } from "antd"
import Edit_Contract from "./Edit_Contract"
import Detail_Contract from "./Detail_Contract"
import Device from "./Device"
import axios from "axios"
import * as api from "../../service/Api_Edit_Contract"
import Service from "./Service"
import Setting from "./Setting"
import no_permi from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png"
import helpers from "../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const { TabPane } = Tabs

export default function Edit_contract(props) {
  const history = useHistory()
  const [activeTab_all, setActiveTab_all] = useState("1")
  const token = localStorage.getItem("token")
  const [detail_Contract, setDetail_Contract] = useState({})
  const { t } = useTranslation()
  const permi = localStorage.getItem("permi")
  const [permi_t_edit, setPermi_t_edit] = useState(false)
  const [permi_t_delete, setPermi_t_delete] = useState(false)
  const [permi_t_read, setPermi_t_read] = useState(false)
  const { permissionAllService } = useSelector(({ Distar }) => Distar)
  let { con_id } = useParams()

  useEffect(() => {
    if (permissionAllService) {
      if (permissionAllService.contract) {
        setPermi_t_read(permissionAllService.contract.read)
        setPermi_t_edit(permissionAllService.contract.edit)
        setPermi_t_delete(permissionAllService.contract.delete)
      }
    }
    return () => {}
  }, [permissionAllService])

  // console.log('con_id = '+con_id);

  useEffect(() => {
    check_contract_detail()
    if (props.location.page_local) {
      setActiveTab_all(props.location.page_local)
    }
    return () => {
      setDetail_Contract({})
    }
  }, [con_id])

  function check_contract_detail() {
    const check = api.CHECK_CONTRACT(token, con_id)
    axios(check)
      .then(res => {
        if (res.data.data[0] === undefined) {
          history.push("/contract")
        } else {
          setDetail_Contract(res.data.data[0])
        }
      })
      .catch(error => {
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Edit contract")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/contract")}
              />
            </a>{" "}
            {t("Edit contract")}
          </h3>

          {permi_t_read === true && (
            <Card>
              <CardBody>
                <Tabs
                  defaultActiveKey="1"
                  onTabClick={e => {
                    setActiveTab_all(e)
                  }}
                  activeKey={activeTab_all}
                >
                  <TabPane tab={t("Overview")} key="1">
                    <Detail_Contract detail_Contract={detail_Contract} />
                  </TabPane>
                  <TabPane tab={t("Device")} key="2">
                    <Device con_id={parseInt(con_id)} />
                  </TabPane>
                  {/*      <TabPane tab="Service" key="3">
                  <Service 
                    cus_id={detail_Contract.cus_id}
                    con_id={parseInt(con_id)}
                  />
            </TabPane>*/}
                  {permi_t_edit === true && (
                    <TabPane tab={t("Edit")} key="4">
                      <Edit_Contract
                        setDetail_Contract={setDetail_Contract}
                        detail_Contract={detail_Contract}
                      />
                    </TabPane>
                  )}

                  {permi_t_delete === true && (
                    <TabPane tab={t("Setting")} key="5">
                      <Setting con_id={parseInt(con_id)} />
                    </TabPane>
                  )}
                </Tabs>
              </CardBody>
            </Card>
          )}
          {permi_t_read === false && (
            <div>
              <img
                src={no_permi}
                alt="no permission"
                style={{ display: "block", margin: "50px auto" }}
              />
              <p style={{ textAlign: "center", fontSize: "20px" }}>
                {t("no permission read module")}
              </p>
              <p style={{ textAlign: "center", fontSize: "20px" }}>
                {" "}
                {t("please contract administrator")}{" "}
              </p>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
