import React, { useState, useEffect } from "react"
import { Row, Col, Container, Card, CardBody } from "reactstrap"
import { useHistory, useParams } from "react-router-dom"
import axios from "axios"
import MetaTags from "react-meta-tags"
import { Tabs } from "antd"
import * as api from "../../service/Api_Edit_Employee"
import { MdKeyboardBackspace } from "react-icons/md"
import Profile from "./Profile"
import Level from "./Level"
import Setting from "./Setting"
import { useTranslation } from "react-i18next"
import no_permi from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png"
import helpers from "../Function_Redirect/Redirect_login"
import ChangePasswordByAdmin from "../components/Profile/ChangePasswordByAdmin"
import { useSelector } from "react-redux"
import Button_ResetPassword from '../components/admin/Button_ResetPassword'

const { TabPane } = Tabs

const Edit = props => {
  const { t } = useTranslation()
  const history = useHistory()
  const token = localStorage.getItem("token")
  const [image_preview, setImage_preview] = useState(null)
  const [user_info, setUser_info] = useState({})
  const [user_save, setUser_save] = useState({
    // emp_code : null ,
    // emp_firstname : null ,
    // emp_lastname : null ,
    // dep_id : null ,
    // pos_id : null ,
    // emp_level_id : null,
    // emp_nickname : null
  })

  const permi = localStorage.getItem("permi")
  const [permi_t, setPermi_t] = useState(false)
  const [permi_t_read, setPermi_t_read] = useState(false)
  const { isRole , isAdminRoot } = useSelector(({ Distar }) => Distar)

  useEffect(() => {
    if (permi) {
      if (JSON.parse(permi).employee) {
        setPermi_t_read(
          JSON.parse(permi).employee.read
            ? JSON.parse(permi).employee.read
            : false
        )
        setPermi_t(
          JSON.parse(permi).employee.delete
            ? JSON.parse(permi).employee.delete
            : false
        )
      }
    }
    return () => {
      // setPermi_t(false)
    }
  }, [permi])

  let { employee_id } = useParams()

  useEffect(() => {
    check_detail_user()
    return () => {
      setUser_info({})
      setUser_save({})
      setImage_preview(null)
    }
  }, [employee_id])
  // console.log('employee_id = '+employee_id);

  async function Get_Image_User(emp_avatar) {
    const get_data_image = api.GET_IMAGE(token, emp_avatar)
    await axios(get_data_image)
      .then(res => {
        const blob = new Blob([res.data], {
          type: "image/jpeg",
        })
        var objectURL = URL.createObjectURL(blob)
        setImage_preview(objectURL)
      })
      .catch(error => {
        // console.log(error.response);
      })
  }

  function check_detail_user() {
    const User_info_preview = api.GET_INFO_USER(token, employee_id)
    axios(User_info_preview)
      .then(res => {
        if (
          res.data.data[0].emp_avatar !== "None" &&
          res.data.data[0].emp_avatar !== undefined &&
          res.data.data[0].emp_avatar !== null
        ) {
          Get_Image_User(res.data.data[0].emp_avatar)
        }
        setUser_info(res.data.data[0])
        setUser_save({
          ...user_save,
          emp_code: res.data.data[0].emp_code,
          emp_firstname: res.data.data[0].emp_firstname,
          emp_lastname: res.data.data[0].emp_lastname,
          dep_id: res.data.data[0].dep_id,
          pos_id: res.data.data[0].pos_id,
          emp_level_id: res.data.data[0].emp_level_id,
          emp_nickname: res.data.data[0].emp_nickname,
          emp_group_id: res.data.data[0].emp_group_id,
          admin_group_id: res.data.data[0].admin_group_id,
        })
      })
      .catch(error => {
        // console.log(error);
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("edit employee")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/employee")}
              />
            </a>{" "}
            {t("edit employee")}{" "}
          </h3>
          <Card>
            <CardBody>

                <Tabs defaultActiveKey="1">
                  <TabPane tab={t("profile")} key="1">
                    <Profile
                      setImage_preview={setImage_preview}
                      image_preview={image_preview}
                      user_info={user_info}
                      user_save={user_save}
                      setUser_save={setUser_save}
                      check_detail_user={check_detail_user}
                      id={employee_id}
                    />
                  </TabPane>
                  <TabPane tab={t("level")} key="2">
                    <Level
                      user_info={user_info}
                      user_save={user_save}
                      setUser_save={setUser_save}
                      id={parseInt(employee_id)}
                      level_id={user_save.emp_level_id}
                    />
                  </TabPane>
                    <TabPane tab={t("setting")} key="3">
                      {
                        isAdminRoot === true && isRole === "administrator" && (
                          <Button_ResetPassword
                          roleReset="employee" 
                          idReset={parseInt(employee_id)}
                          />
                        )
                      }
                      {
                        isRole === "administrator" && (
                          <ChangePasswordByAdmin
                          idIsChange={parseInt(employee_id)}
                          roleIsChange="employee"
                        />
                        )
                      }
                      <Setting id={employee_id} />
                    </TabPane>
             
                </Tabs>
    

            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Edit
