import React from "react"
import MetaTags from "react-meta-tags"
import { Container, Card, CardBody } from "reactstrap"
import { useTranslation } from "react-i18next"
import { Table, Tooltip } from "antd"
import { useHistory } from "react-router-dom"
import { FcSearch } from "react-icons/fc"

const Report = props => {
  const { t } = useTranslation()
  const history = useHistory()

  const columns = [
    {
      title: t("report"),
      dataIndex: "report",
      key: "report",
      render: text => <p>{text}</p>,
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      render: text => (
        <Tooltip title={`${text.report}`}>
          <a>
            <FcSearch
              size={20}
              onClick={() => history.push(`/reports/${text.path}`)}
            />
          </a>
        </Tooltip>
      ),
    },
  ]

  const data = [
    {
      report: t("report repair"),
      path: "report-repair",
    },
    {
      report: t("report car"),
      path: "report-car",
    },
    {
      report: t("report contract"),
      path: "report-contract",
    },
    {
      report: t("report product"),
      path: "report-product",
    }
  ]

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("report")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3>{t("report")}</h3>

          <Card>
            <CardBody>
              
              <Table
                columns={columns}
                dataSource={data}
                rowKey="path"
                scroll={{ x: 400 }}
              />
              
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Report
