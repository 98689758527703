import React, { useState, useEffect } from "react"
import { Row, Col, Container, Card, CardBody } from "reactstrap"
import MetaTags from "react-meta-tags"
import { useHistory } from "react-router-dom"
import axios from "axios"
import * as api from "../../service/Api_System_Settings"
import { FcSettings } from "react-icons/fc"
import { MdKeyboardBackspace } from "react-icons/md"
import { Table, Button, Modal, Tooltip, notification, Form } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { RiDeleteBin6Line } from "react-icons/ri"
import Input_Comp from "./Components/Input_Comp"

const { confirm } = Modal

export default function Trigger_Event(props) {
  const history = useHistory()
  const [form_create] = Form.useForm()
  const [form_edit] = Form.useForm()
  const token = localStorage.getItem("token")
  const [data_list, setData_list] = useState([])
  const [visible_edit, setVisible_edit] = useState(false)
  const [visible_create, setVisible_create] = useState(false)
  const [data_edit, setData_edit] = useState({})
  const [data_create, setData_create] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    check_data()
    return () => {}
  }, [])

  function check_data() {
    setLoading(true)
    const check_data_all = api.FIND_ALL_TRIGGER_EVENT(token)
    axios(check_data_all)
      .then(res => {
        setData_list(res.data.data)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
 
      })
  }

  function remove_data(id) {
    return confirm({
      title: "Confirm remove trigger event",
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: "Confirm",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        setLoading(true)
        const delete_data = api.DELETE_TRIGGER_EVENT(token, id)
        axios(delete_data)
          .then(res => {
            setLoading(false)
            openNotificationWithIcon("error", "Remove trigger event error")
          })
          .catch(error => {
            if (error.response.status === 404) {
              openNotificationWithIcon(
                "success",
                "Remove trigger event success"
              )
              cancel_all_Form()
              check_data()
            }
            setLoading(false)
          })
      },
      onCancel() {},
    })
  }

  const columns = [
    {
      title: "Trigger name",
      dataIndex: "trig_name",
      key: "trig_name",
      render: text => <p>{text}</p>,
    },
    {
      title: "Trigger detail",
      dataIndex: "trig_details",
      key: "trig_details",
      render: text => <p>{text}</p>,
    }, {
      title: "Trigger job id",
      dataIndex: "trig_job_id",
      key: "trig_job_id",
      render: text => <p>{text}</p>,
    }, {
      title: "Trigger type",
      dataIndex: "trig_type",
      key: "trig_type",
      render: text => <p>{text}</p>,
    },{
      title: "Trigger function",
      dataIndex: "trig_function_do",
      key: "trig_function_do",
      render: text => <p>{text}</p>,
    },{
      title: "Trigger condition",
      dataIndex: "trig_condition",
      key: "trig_condition",
      render: text => <p>{text}</p>,
    },
    {
      title: "Trigger hour",
      dataIndex: "trig_hour",
      key: "trig_hour",
      render: text => <p>{text}</p>,
    },
    {
      title: "Trigger minute",
      dataIndex: "trig_minute",
      key: "trig_minute",
      render: text => <p>{text}</p>,
    },
    {
      title: "",
      dataIndex: "trig_id",
      key: "trig_id",
      render: text => (
        <Tooltip title="Remove trigger event">
          <a>
            <RiDeleteBin6Line
              style={{ color: "red" }}
              size={20}
              onClick={() => remove_data(text)}
            />
          </a>
        </Tooltip>
      ),
    },
    {
      title: "",
      render: text => (
        <Tooltip title="Edit trigger event">
          <a>
            <FcSettings
              size={20}
              onClick={() => {
                setVisible_edit(true)
                setData_edit({
                  trig_name: text.trig_name,
                  trig_id: text.trig_id,
                  trig_condition : text.trig_condition ,
                  trig_details : text.trig_details ,
                  trig_function_do : text.trig_function_do ,
                  trig_hour: text.trig_hour,
                  trig_job_id: text.trig_job_id,
                  trig_minute: text.trig_minute,
                  trig_type: text.trig_type
                })
                form_edit.setFieldsValue({
                  trig_name: text.trig_name,
                  trig_condition : text.trig_condition ,
                  trig_details : text.trig_details ,
                  trig_function_do : text.trig_function_do ,
                  trig_hour: text.trig_hour,
                  trig_job_id: text.trig_job_id,
                  trig_minute: text.trig_minute,
                  trig_type: text.trig_type
                })
              }}
            />
          </a>
        </Tooltip>
      ),
    },
  ]

  const onFinish_create = values => {
    return confirm({
      title: "Confirm create trigger event",
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: "Confirm",
      cancelText: "Cancel",
      onOk() {
        setLoading(true)
        const create_trigger_event = api.CREATE_TRIGGER_EVENT(
          token,
          data_create
        )
        axios(create_trigger_event)
          .then(res => {
            openNotificationWithIcon(
              "success",
              "Create new trigger event success"
            )
            check_data()
            setLoading(false)
            cancel_create_Form()
          })
          .catch(error => {
            setLoading(false)
            openNotificationWithIcon(
              "error",
              "Create new trigger event error"
            )
          })
      },
      onCancel() {},
    })
  }

  const cancel_create_Form = () => {
    form_create.resetFields()
    setVisible_create(false)
    setData_create({})
  }

  const onFinish_edit = values => {
    return confirm({
      title: "Confirm edit trigger event",
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: "Confirm",
      cancelText: "Cancel",
      onOk() {
        setLoading(true)
        const data_edit_all = {
          trig_name: data_edit.trig_name,
        }
        const edit_trigger_event = api.EDIT_TRIGGER_EVENT(
          token,
          data_edit.trig_id,
          data_edit_all
        )
        axios(edit_trigger_event)
          .then(res => {
            openNotificationWithIcon(
              "success",
              "Edit trigger event success"
            )
            check_data()
            setLoading(false)
            cancel_edit_Form()
          })
          .catch(error => {
            setLoading(false)
            openNotificationWithIcon(
              "error",
              "Edit trigger event error"
            )
          })
      },
      onCancel() {},
    })
  }

  const cancel_edit_Form = () => {
    form_edit.resetFields()
    setVisible_edit(false)
    setData_edit({})
  }

  function cancel_all_Form() {
    cancel_create_Form()
    cancel_edit_Form()
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Trigger Event Settings | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/system-setting")}
              />
            </a>{" "}
            Trigger Event Settings
          </h3>
          <Card>
            <CardBody>
              <Button
                className="btn-create-new-sub-product"
                type="primary"
                onClick={() => {
                  setVisible_create(true)
                  setData_create({
                    trig_name: null,
                    trig_condition : null,
                    trig_details : null,
                    trig_function_do : null,
                    trig_hour: null,
                    trig_job_id: null,
                    trig_minute: null,
                    trig_type: null
                  })
                }}
              >
                Create new trigger Event
              </Button>

              <Table
                loading={loading}
                rowKey="trig_id"
                columns={columns}
                dataSource={data_list}
                scroll={{ x: 400 }}
              />

              <Modal
                centered
                open={visible_create}
                footer={false}
                onCancel={() => {
                  setVisible_create(false)
                  setData_create({})
                  cancel_create_Form()
                }}
                // width={1000}
              >
                <Form
                  form={form_create}
                  name="form_create_data"
                  onFinish={onFinish_create}
                >
                  <Input_Comp
                    placeholder="trigger event name"
                    header="Trigger event"
                    headState={setData_create}
                    nameState={data_create}
                    keyState="trig_name"
                    type="text"
                    required={true}
                  />

                  <br />
                  <Row>
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <div className="div_create_new_car">
                        <Button
                          className="btn_cancel_create_new_car"
                          onClick={() => cancel_create_Form()}
                        >
                          {" "}
                          Cancel{" "}
                        </Button>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <div className="div_create_new_car">
                        <Button
                          className="btn_submit_create_new_car"
                          type="primary"
                          htmlType="submit"
                          loading={loading}
                        >
                          {" "}
                          Create{" "}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Modal>

              <Modal
                centered
                open={visible_edit}
                footer={false}
                onCancel={() => {
                  setVisible_edit(false)
                  setData_edit({})
                  cancel_edit_Form()
                }}
                // width={1000}
              >
                <Form
                  form={form_edit}
                  name="form_edit_data"
                  onFinish={onFinish_edit}
                >
                  <Input_Comp
                    placeholder="trigger event"
                    header="Trigger event"
                    headState={setData_edit}
                    nameState={data_edit}
                    keyState="trig_name"
                    type="text"
                    required={false}
                  />
                  <br />
                  <Row>
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <div className="div_create_new_car">
                        <Button
                          className="btn_cancel_create_new_car"
                          onClick={() => cancel_edit_Form()}
                        >
                          {" "}
                          Cancel{" "}
                        </Button>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <div className="div_create_new_car">
                        <Button
                          className="btn_submit_create_new_car"
                          type="primary"
                          htmlType="submit"
                          loading={loading}
                        >
                          {" "}
                          Edit{" "}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Modal>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
