import React, { useState } from "react"
import { Row, Col } from "reactstrap"
import { Input, Button, Modal } from "antd"
import Create_New_Car_Only from "./Create_New_Car_Only"
import { useTranslation } from "react-i18next";

const { Search } = Input

export default function Search_customer(props) {
  const [visible, setVisible] = useState(false)
  const { t } = useTranslation();

  function onSearch(value) {
    props.search_data_car_in_customer()
  }

  return (
    <Row>
      <Col sm={12} md={6} lg={6} xl={6}>
        <Search
          style={{ width: "100%" }}
          placeholder={t("Car license")}
          allowClear
          enterButton={t("Search")}
          size="medium"
          onSearch={onSearch}
          onChange={e => props.setData_search_car_in_customer(e.target.value)}
        />
      </Col>
      <Col sm={12} md={1} lg={1} xl={1}>
        <Button onClick={() => setVisible(true)}> {t("Create new car")} </Button>
      </Col>

      <Modal
        centered
        open={visible}
        footer={false}
        onCancel={() => {
          setVisible(false)
        }}
        width={1000}
      >
        <Create_New_Car_Only
          setCreate_new_car={props.setCreate_new_car}
          create_new_car={props.create_new_car}
          car_create_only={props.car_create_only}
          setCar_create_only={props.setCar_create_only}
          setVisible={setVisible}
        />
      </Modal>
    </Row>
  )
}
