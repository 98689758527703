import React,{ useEffect, useState } from 'react'
import { PageHeader, Descriptions } from 'antd';
import { Tag } from 'antd';
import axios from 'axios'
import * as api from '../../service/Api_New_Service_Has_Stock'
import { useTranslation } from "react-i18next";

export default function Detail_Service_Create(props) {
    const token = localStorage.getItem('token')
    const role = localStorage.getItem('role')
    const { t } = useTranslation();
    const [service_number, setService_number] = useState(null)
    const [username, setUsername] = useState(null)

    useEffect(() => {
        function check_number_service() {
             const check = api.CHECK_SERVICE_NUMBER(token)
             axios(check)
             .then( res => {
                setService_number(res.data.code)
             })
             .catch( error => {
                //  console.log(error);
             })
        }

        function get_user_info() {
            const get_userInfo = api.CHECK_USER_INFO(token)
            axios(get_userInfo)
            .then( res => {
              if(role === "administrator"){
                setUsername(res.data.user_infomation.admin_firstname + ' ' + res.data.user_infomation.admin_lastname)
              } else {
                setUsername(res.data.user_infomation.emp_firstname + ' ' + res.data.user_infomation.emp_lastname)
              }
            })
            .catch( error =>{

            })
        }
        
        get_user_info()
        check_number_service()
        return () => {
            setUsername(null)
            setService_number(null)
        }
    }, [])

    function cutTimeZone(date_T) {
        if(date_T){
            var date = new Date(date_T);
            var month = pad2(date.getMonth()+1);
            var day = pad2(date.getDate());
            var year= date.getFullYear();
    
            var formattedDate =  day+"/"+month+"/"+year;
            return formattedDate
        } else {
            return 'N/A'
        }
    }
    function pad2(n) {
        return (n < 10 ? '0' : '') + n;
      }

    return (
        <div className="site-page-header-ghost-wrapper">
        <PageHeader
          ghost={false}
          title={<p> {t("Service no.")} {service_number}</p>}
        //   subTitle="This is a subtitle"
          extra={[
            <Tag key="1" color="success"> {t("Create new service")} </Tag>
          ]}
        >
          <Descriptions size="small" column={3}>
            <Descriptions.Item label={t("Creator")} >{username}</Descriptions.Item>
            <Descriptions.Item >
        
            </Descriptions.Item>
            <Descriptions.Item label={t("Creation Time")} >{cutTimeZone(new Date())}</Descriptions.Item>
          </Descriptions>
        </PageHeader>
      </div>
    )
}
