var Ip = require('../Ip')

export const GET_DETAIL_SERVICE = (token, ser_id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/service/${ser_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const SERVICE_OVERVIEW = (token, ser_id) => {
    return {
      method: 'get',       
      url: `${Ip.getIP()}api/v1/service/overview/${ser_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_PRIORITY = (token) => {
    return {
      method: 'get',       
      url: `${Ip.getIP()}api/v1/service/task/priority`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CHECK_SERVICE_HAST_TASK = (token, ser_id) => {
    return {
      method: 'get',       
      url: `${Ip.getIP()}api/v1/service/get-task-by-ser-id/${ser_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CHECK_DATA_SUBMIT = (token, ser_id) => {
    return {
      method: 'get',       
      url: `${Ip.getIP()}api/v1/data/service/${ser_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };