import React, { useEffect, useState } from "react"
import { Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next"
import {
  FcApproval,
  FcCancel,
  FcClock,
} from "react-icons/fc"

export default function StatusCustomerService(props) {
  const { t } = useTranslation()

  const text_header = {
    fontWeight: "bold",
    fontSize: "15px",
  }

  function checkData(data) {
    if (data) {
      return data
    } else {
      return "N/A"
    }
  }

  function render_time(date_time) {
    const date_data = new Date(date_time)
    let expiryDate = new Date(
      new Date(date_data).setHours(date_data.getHours() + 7)
    )
    const date_all = new Date(expiryDate)
    return date_all.toLocaleTimeString("en-GB")
  }

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return "N/A"
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }
  function timeDiffCalc(dateFuture, dateNow) {
    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000

    const days = Math.floor(diffInMilliSeconds / 86400)
    diffInMilliSeconds -= days * 86400

    const hours = Math.floor(diffInMilliSeconds / 3600) % 24
    diffInMilliSeconds -= hours * 3600

    const minutes = Math.floor(diffInMilliSeconds / 60) % 60
    diffInMilliSeconds -= minutes * 60

    let difference = ""
    if (days > 0) {
      difference += days === 1 ? `${days} ${t("day")}, ` : `${days} ${t("days")}, `
    }

    difference +=
      hours === 0 || hours === 1 ? `${hours} ${t("hour")}, ` : `${hours} ${t("hours")}, `

    difference +=
      minutes === 0 || hours === 1 ? `${minutes} ${t("minutes")}` : `${minutes} ${t("minutes")}`

    return difference
  }

  function renderStatusFromUser(status) {
    if (status.is_user_cancel === 1 && status.is_user_confirm === 0) {
      return (
        <Row>
          <Col sm={6} md={2} lg={2} xl={2}>
            <p style={text_header}>{t("Customer confirmation status")}</p>
          </Col>
          <Col sm={6} md={4} lg={4} xl={4}>
            <p style={{ fontSize: "15px", color: "red" }}>
              {" "}
              <span className="tet">
                :
              </span> <span className="tetTwo">-</span> {t("Customer cancel")}  <FcCancel size={20} />
            </p>
          </Col>

          <Col sm={6} md={2} lg={2} xl={2}>
            <p style={text_header}>{t("Customer cancel remark")}</p>
          </Col>
          <Col sm={6} md={4} lg={4} xl={4}>
            <p style={{ fontSize: "15px" }}>
              {" "}
              <span className="tet">
                :
              </span> <span className="tetTwo">-</span> {status.is_user_remark}
            </p>
          </Col>

          <Col sm={6} md={2} lg={2} xl={2}>
            <p style={text_header}>{t("Date confirmed")}</p>
          </Col>
          <Col sm={6} md={4} lg={4} xl={4}>
            <p style={{ fontSize: "15px" }}>
              {" "}
              <span className="tet">
                :
              </span> <span className="tetTwo">-</span> {cutTimeZone(status.is_user_active_at)}
            </p>
          </Col>
          <Col sm={6} md={2} lg={2} xl={2}>
            <p style={text_header}>{t("Time confirmed")}</p>
          </Col>
          <Col sm={6} md={4} lg={4} xl={4}>
            <p style={{ fontSize: "15px" }}>
              {" "}
              <span className="tet">
                :
              </span> <span className="tetTwo">-</span> {render_time(status.is_user_active_at)}
            </p>
          </Col>

        </Row>
      )
    } else if (status.is_user_cancel === 0 && status.is_user_confirm === 1) {
      return (
        <Row>
          <Col sm={6} md={2} lg={2} xl={2}>
            <p style={text_header}>{t("Customer confirmation status")}</p>
          </Col>
          <Col sm={6} md={4} lg={4} xl={4}>
            <p style={{ fontSize: "15px", color: "#03A70A" }}>
              {" "}
              <span className="tet">
                :
              </span> <span className="tetTwo">-</span> {t("Customer confirm")}{" "}
              <FcApproval size={20} />
            </p>
          </Col>

          <Col sm={6} md={2} lg={2} xl={2}>
            <p style={text_header}>{t("Date confirmed")}</p>
          </Col>
          <Col sm={6} md={4} lg={4} xl={4}>
            <p style={{ fontSize: "15px" }}>
              {" "}
              <span className="tet">
                :
              </span> <span className="tetTwo">-</span> {cutTimeZone(status.is_user_active_at)}
            </p>
          </Col>
          <Col sm={6} md={2} lg={2} xl={2}>
            <p style={text_header}>{t("Time confirmed")}</p>
          </Col>
          <Col sm={6} md={4} lg={4} xl={4}>
            <p style={{ fontSize: "15px" }}>
              {" "}
              <span className="tet">
                :
              </span> <span className="tetTwo">-</span> {render_time(status.is_user_active_at)}
            </p>
          </Col>
        </Row>
      )
    } else {
      let dateTime = timeDiffCalc(new Date(status.created_on), new Date())
      return (
        <Row>
          <Col sm={6} md={2} lg={2} xl={2}>
            <p style={text_header}>{t("Customer confirmation status")}</p>
          </Col>
          <Col sm={6} md={4} lg={4} xl={4}>
            <p style={{ fontSize: "15px" , color:'#848484' }}>
              {" "}
              <span className="tet">
                :
              </span> <span className="tetTwo">-</span>{" "}
              {t("Waiting for customer confirmation")} <FcClock size={20} />
            </p>
          </Col>

          <Col sm={6} md={2} lg={2} xl={2}>
            <p style={text_header}>{t("Created on")}</p>
          </Col>
          <Col sm={6} md={4} lg={4} xl={4}>
            <p style={{ fontSize: "15px" }}>
              {" "}
              <span className="tet">
                :
              </span> <span className="tetTwo">-</span> {dateTime}
            </p>
          </Col>
        </Row>
      )
    }
  }

  return (
    <div
      style={{
        boxShadow: "rgb(99 99 99 / 20%) 0px 2px 8px 0px",
        padding: "10px",
        margin: "10px",
      }}
    >
      {renderStatusFromUser(props.data_service)}
    </div>
  )
}
