import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { Button, Input, Form, Pagination, Table, Tooltip  } from "antd"
import axios from "axios"
import moment from "moment"
import * as api from "../../../service/Api_New_Customer"
import { IoIosSearch } from "react-icons/io"
import { FiEdit2 } from "react-icons/fi"
import { useHistory } from "react-router-dom"
import { AiOutlineEye } from "react-icons/ai"
import { FcSearch, FcSettings, FcDataSheet } from "react-icons/fc"
import Render_Image from './Render_Image'
import helpers from '../../Function_Redirect/Redirect_login'
import { useTranslation } from "react-i18next";

export default function List_device(props) {
  const { t } = useTranslation();
  const history = useHistory()
  const token = localStorage.getItem("token")
  const [data_search, setData_search] = useState(null)
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    search_data()
    return () => {
      props.setData_device([])
    }
  }, [])

  const onFinish = values => {
    search_data()
  }

  function search_data() {
    setLoading(true)
    if (data_search) {
      const list_device_data = api.LIST_DEVICE_DATA(
        token,
        props.cus_id,
        1,
        page_limit.page_Size,
        data_search
      )
      axios(list_device_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          props.setData_device(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }

        })
    } else {
      const list_device_no_data = api.LIST_DEVICE_NO_DATA(
        token,
        props.cus_id,
        1,
        page_limit.page_Size
      )
      axios(list_device_no_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          props.setData_device(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }
        })
    }
  }

  function changePagination(page, pageSize) {
    setLoading(true)
    if (data_search) {
      const list_device_data = api.LIST_DEVICE_DATA(
        token,
        props.cus_id,
        page,
        pageSize,
        data_search
      )
      axios(list_device_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          props.setData_device(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }
        })
    } else {
      const list_device_no_data = api.LIST_DEVICE_NO_DATA(
        token,
        props.cus_id,
        page,
        pageSize
      )
      axios(list_device_no_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          props.setData_device(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }
        })
    }
  }

  const columns = [
    {
      title: "",
      dataIndex: "pro_model_img",
      key: "pro_model_img",
      render: text => <Render_Image path_image={text} size={80} iconSelect={null} />,
    },
    {
      title: t("Serial number"),
      dataIndex: "pro_serial_number",
      key: "pro_serial_number",
      render: text => checkData(text),
    },
    {
      title: t("Group name"),
      dataIndex: "pro_group_name",
      key: "pro_group_name",
      render: text => checkData(text),
    },
    {
      title: t("Model name"),
      dataIndex: "pro_model_name",
      key: "pro_model_name",
      render: text => checkData(text),
    },
    {
      title: t("Asset number"),
      dataIndex: "sto_asset_number",
      key: "sto_asset_number",
      render: text => checkData(text),
    },
    {
      title: t("Install"),
      dataIndex: "sto_date_install",
      key: "sto_date_install",
      render: text => cutTimeZone(text),
    },
    {
      title: t("End of warranty"),
      dataIndex: "warranty_name",
      key: "warranty_name",
      render: text => checkData(text),
    },
    {
      title: "",
      render: text => (
        <Tooltip title={t("View device")} >
        <a>
        <FcSearch
          size={20}
          onClick={() => {
            props.setVisibleDetail(true)
            props.setDetail_modal(text)
          }}
        />
      </a>
      </Tooltip>
      ),
    },
  ]

  function checkData(data) {
    if (data) {
      return data
    } else {
      return "N/A"
    }
  }
  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return "N/A"
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }
  function checkTimeZone(data) {
    if (data) {
      return moment(data).format("LLLL")
    } else {
      return "N/A"
    }
  }

  return (
    <div>
      <Form
        name="Search_data_device"
        initialValues={{
          remember: false,
        }}
        onFinish={onFinish}
      >
        <Row>
          <Col sm={12} md={6} lg={6} xl={4}>
            <Form.Item
              name="search"
              rules={[{ required: false, message: "Please input device" }]}
            >
              <Input
                placeholder={`${t("Serial number")} , ${t("Asset number")}`}
                style={{ marginBottom: "15px" }}
                onChange={e => setData_search(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={6} lg={4} xl={4}>
            <Button
              type="primary"
              className="button-search-employee"
              htmlType="submit"
            >
              {t("Search")}
            </Button>
          </Col>
        </Row>
      </Form>



      <Table
        columns={columns}
        loading={loading}
        dataSource={props.data_device}
        pagination={false}
        rowKey="pro_id"
        scroll={{ x: 800 }}
      />

      <div>
        <Pagination
          current={page_limit.page}
          total={page_limit.limit}
          onChange={changePagination}
          style={{ marginTop: "20px", float: "right" }}
        />
      </div>
      <p style={{ fontSize:'15px' , marginTop:'20px'}}> <FcDataSheet size={20} style={{ marginBottom:'5px' }} /> {t("total data")} : {page_limit.limit}</p>
    </div>
  )
}
