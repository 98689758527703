import { Modal } from 'antd';

  const helpers = {
    re_login: function(){
        localStorage.removeItem('token')
        localStorage.removeItem('role')
        localStorage.removeItem('permi')
        localStorage.removeItem('dea_id')
        localStorage.removeItem('_id')
        localStorage.removeItem('cus_id')
        localStorage.removeItem('r');
        Modal.error({
            title: 'ID is expire please login again',
            centered : true ,
          });
    }
}

export default helpers;