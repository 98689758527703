import React, { useRef, useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import {
  Button,
  Avatar,
  Input,
  Select,
  Modal,
  Switch,
  Form,
  notification,
} from "antd"
import { UserOutlined } from "@ant-design/icons"
import { FaRegUser } from "react-icons/fa"
import Use_AD_Server from "./Use_AD_Server"
import axios from "axios"
import * as api from "../../../service/Api_New_Employee"
import "./Profile_New_Employee.css"
import { useTranslation } from "react-i18next"
import Input_Comp from "./Components/Input_Comp"
import Input_Comp_pass from "./Components/Input_Comp_pass"
import Select_no_search from "./Components/Select_no_search"
import Input_Comp_Email from "./Components/Input_Comp_Email"
import { useHistory } from "react-router-dom"
import helpers from "../../Function_Redirect/Redirect_login"

const { Option } = Select
const { TextArea } = Input
const style_req = {
  color: "red",
}
export default function Profile(props) {
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const imageUploader = useRef(null)
  const token = localStorage.getItem("token")
  const [visible, setVisible] = useState(false)
  const [error_pass, setError_pass] = useState(null)
  const [email_duplicate, setEmail_duplicate] = useState(null)
  const [email_duplicate_button, setEmail_duplicate_button] = useState(false)
  const history = useHistory()
  const r = localStorage.getItem("r")

  useEffect(() => {
    if (props.data_new_employee.emp_email) {
      check_email_emp_dup()
    }
    return () => {}
  }, [props.data_new_employee.emp_email])

  function check_email_emp_dup() {
    const check_email = api.CHECK_EMAIL_EMPLOYEE_DUPLICATE(
      token,
      props.data_new_employee.emp_email
    )
    axios(check_email)
      .then(res => {
        if (res.data.data === true) {
          setEmail_duplicate(t("email is duplicate"))
          setEmail_duplicate_button(true)
        } else {
          setEmail_duplicate(null)
          setEmail_duplicate_button(false)
        }
      })
      .catch(error => {
        // console.log(error);
      })
  }

  const handleImageUpload = e => {
    if (e.target.files[0] !== undefined) {
      if (e.target.files[0].type !== "image/jpeg") {
        openNotificationWithIcon(
          "error",
          t("please upload jpg or jpeg image only")
        )
      } else {
        let img_upload = e.target.files[0]
        props.setSelect_image(img_upload)
        props.setImage_preview(URL.createObjectURL(img_upload))
      }
    }
  }

  const openNotificationWithIcon = (type, description) => {
    notification[type]({
      message: description,
    })
  }

  function onChangeSwitchPass(checked) {
    if (checked) {
      onReset()
      setError_pass(null)
      props.setData_new_employee({
        ...props.data_new_employee,
        emp_password: null,
        emp_email_password_condition: checked,
      })
    } else {
      props.setData_new_employee({
        ...props.data_new_employee,
        emp_email_password_condition: checked,
      })
    }
  }

  const onReset = () => {
    form.resetFields(["emp_password", "confirm_password_info"])
  }

  const onFinish = values => {
    // console.log(props.data_new_employee);
    if (props.data_new_employee.emp_password) {
      if (
        props.data_new_employee.emp_password === values.confirm_password_info
      ) {
        setError_pass(null)
        props.setKey_Tabs("2")
      } else {
        setError_pass(t("password mismatch"))
      }
    } else {
      props.setKey_Tabs("2")
    }
  }

  return (
    <div>
      <div>
        <p className="text-bold" style={{ textAlign: "center" }}>
          {" "}
          {t("photo Profile")}{" "}
        </p>
        <input
          type="file"
          accept="image/jpeg"
          onChange={handleImageUpload}
          ref={imageUploader}
          style={{
            display: "none",
          }}
        />

        {props.image_preview !== null && (
          <div className="image-upload">
            <Avatar
              src={props.image_preview}
              size={100}
              style={{ display: "block", margin: "auto" }}
            />
          </div>
        )}
        {props.image_preview === null && (
          <div className="image-upload">
            <Avatar
              icon={<FaRegUser style={{ marginBottom: "5px" }} />}
              size={100}
              style={{ display: "block", margin: "auto" }}
            />
          </div>
        )}

        {props.image_preview === null && (
          <>
            <Button
              type="primary"
              style={{
                width: "150px",
                display: "block",
                margin: "10px auto 20px auto",
              }}
              onClick={() => imageUploader.current.click()}
            >
              {t("upload photo")}
            </Button>
            <p style={{ color: "red", textAlign: "center" }}>
              {" "}
              {t("please upload jpg or jpeg image only")}{" "}
            </p>
          </>
        )}

        {props.image_preview !== null && (
          <Button
            type="primary"
            style={{
              width: "150px",
              display: "block",
              margin: "10px auto 20px auto",
            }}
            onClick={() => {
              props.setSelect_image(null)
              props.setImage_preview(null)
            }}
          >
            {t("cancel photo")}
          </Button>
        )}
      </div>

      <Form
        form={form}
        name="basic_Profile"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("code")}
              placeholder={t("code")}
              headState={props.setData_new_employee}
              nameState={props.data_new_employee}
              keyState="emp_code"
              type="text"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp_Email
              header={t("email")}
              placeholder={t("email")}
              headState={props.setData_new_employee}
              nameState={props.data_new_employee}
              keyState="emp_email"
              type="email"
            />
            {email_duplicate !== null && (
              <p style={{ color: "red" }}>{email_duplicate}</p>
            )}
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("firstname")}
              placeholder={t("firstname")}
              headState={props.setData_new_employee}
              nameState={props.data_new_employee}
              keyState="emp_firstname"
              type="text"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("lastname")}
              placeholder={t("lastname")}
              headState={props.setData_new_employee}
              nameState={props.data_new_employee}
              keyState="emp_lastname"
              type="text"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("nickname")}
              placeholder={t("nickname")}
              headState={props.setData_new_employee}
              nameState={props.data_new_employee}
              keyState="emp_nickname"
              type="text"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("employee group")}
              placeholder={t("employee group")}
              name_api="LIST_EMPLOYEE_GROUP"
              map_name="emp_group_name"
              map_value="emp_group_id"
              keyState="emp_group_id"
              headState={props.setData_new_employee}
              nameState={props.data_new_employee}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("department")}
              placeholder={t("department")}
              name_api="LIST_DEPARTMENT"
              map_name="dep_name"
              map_value="dep_id"
              keyState="dep_id"
              headState={props.setData_new_employee}
              nameState={props.data_new_employee}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("position")}
              placeholder={t("position")}
              name_api="LIST_POSITION"
              map_name="pos_name"
              map_value="pos_id"
              keyState="pos_id"
              headState={props.setData_new_employee}
              nameState={props.data_new_employee}
            />
          </Col>

          {r === "true" && (
            <Col sm={12} md={6} lg={6} xl={6}>
              <Select_no_search
                header={t("Admin group")}
                placeholder={t("Admin group")}
                name_api="FIND_ALL_ADMIN_GROUP"
                map_name="admin_group_name"
                map_value="admin_group_id"
                keyState="admin_group_id"
                headState={props.setData_new_employee}
                nameState={props.data_new_employee}
              />
            </Col>
          )}
        </Row>

        <Row>
          <Col>
            <div className="mt-bottom-all">
              <p className="text-bold">
                {t("sending signup password by email")}
                <Switch
                  onChange={onChangeSwitchPass}
                  style={{ marginLeft: "10px" }}
                />{" "}
              </p>
            </div>
          </Col>
        </Row>

        {!props.data_new_employee.emp_email_password_condition && (
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <Input_Comp_pass
                header={t("password")}
                placeholder={t("password")}
                headState={props.setData_new_employee}
                nameState={props.data_new_employee}
                keyState="emp_password"
                type="password"
              />
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
              <div className="mt-bottom-all">
                <p className="text-bold">
                  {t("confirm Password")} <span style={style_req}>*</span>
                </p>
                <Form.Item
                  name="confirm_password_info"
                  rules={[
                    {
                      required: true,
                      message: `${t("please input")} ${t("confirm Password")}`,
                    },
                  ]}
                >
                  <Input.Password placeholder={t("confirm Password")} />
                </Form.Item>
              </div>
            </Col>
          </Row>
        )}

        {error_pass && (
          <p style={{ color: "red", textAlign: "center", fontSize: "15px" }}>
            {error_pass}
          </p>
        )}
        <div>
          <Button
            disabled={email_duplicate_button}
            style={{
              float: "right",
            }}
            htmlType="submit"
          >
            {t("next")}
          </Button>
        </div>
      </Form>
      <Modal
        title=""
        centered
        open={visible}
        footer={false}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
      >
        <Use_AD_Server setVisible={setVisible} />
      </Modal>
    </div>
  )
}
