import React,{ useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import { Container, Card, CardBody } from "reactstrap"
import { Tabs } from 'antd';
import axios from 'axios'
import { useHistory } from "react-router-dom";
import * as api from '../../service/Api_Operator'
import Table_data from './Table_data'
import no_permi from '../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png'
import helpers from '../Function_Redirect/Redirect_login'
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux"

const { TabPane } = Tabs;

const Operator = props => {
  const { t } = useTranslation();
  const token = localStorage.getItem("token")
  const history = useHistory()
  const [search_operator, setSearch_operator] = useState(null)
  const [data_operator_all, setData_operator_all] = useState([])
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })
  const [loading, setLoading] = useState(false)
  const { permissionAllService } = useSelector(({ Distar }) => Distar)
  const [filter_operator, setFilter_operator] = useState({
    operator_group_id : null ,
    operator_dep_id : null
  })

  const permi = localStorage.getItem("permi")
  const [permi_t, setPermi_t] = useState(false)

  useEffect(() => {
    if (permissionAllService) {
      if(permissionAllService.operator){
        setPermi_t(permissionAllService.operator.read)
      }
    }
    return () => {

    }
  }, [permissionAllService])

  useEffect(() => {
    search_operator_data()
    return () => {
    
    }
  }, [filter_operator])

  useEffect(() => {
    search_operator_data()
    return () => {
      setData_operator_all([])
    }
  }, [])

  const check_data = (params, data) => {
    if (data) {
      return params + data
    } else {
      return ""
    }
  }

  function search_operator_data() {
    setLoading(true)
    let url = `api/v1/operator?page=1&limit=${page_limit.page_Size}&order_by=asc${check_data("&q=", search_operator)}${check_data( "&operator_group_id=", filter_operator.operator_group_id)}${check_data( "&operator_dep_id=", filter_operator.operator_dep_id)}`
    const search = api.SEARCH_ALL(token, url)
    axios(search)
    .then(res => {
      setLoading(false)
      setPage_limit({
        ...page_limit,
        page: 1,
        limit: res.data.total_data,
      })
      setData_operator_all(res.data.data)
    })
    .catch(error => {
      setLoading(false)
      if(error){
        if(error.response){
          if(error.response.status === 401){
            helpers.re_login()
            history.push('/login')          
            }
        }
      }

      // console.log(error)
    })
  }

  function changePagination(page, pageSize) {
    setLoading(true)
    let url = `api/v1/operator?page=${page}&limit=${pageSize}&order_by=asc${check_data("&q=", search_operator)}${check_data( "&operator_group_id=", filter_operator.operator_group_id)}${check_data( "&operator_dep_id=", filter_operator.operator_dep_id)}`
    const search = api.SEARCH_ALL(token, url)
    axios(search)
    .then(res => {
      setLoading(false)
      setPage_limit({
        ...page_limit,
        page: page,
        page_Size: pageSize,
        limit: res.data.total_data,
      })
      setData_operator_all(res.data.data)
    })
    .catch(error => {
      setLoading(false)
      if(error){
        if(error.response){
          if(error.response.status === 401){
            helpers.re_login()
            history.push('/login')          
            }
        }
      }

      // console.log(error)
    })
  }

  return (
    <React.Fragment>
    <MetaTags>
      <title> {t("Operator")} | Distar</title>
    </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}> {t("Operator")} </h3>
          {
            permi_t === true && (
              <Card>
              <CardBody>
              
                <Table_data 
                filter_operator={filter_operator}
                setFilter_operator={setFilter_operator}
                  search_operator_data={search_operator_data}
                  setSearch_operator={setSearch_operator}
                  changePagination={changePagination}
                  data_operator_all={data_operator_all}
                  page_limit={page_limit}
                  loading={loading}
                />
    
              </CardBody>
            </Card>
            )
          }
          {
            permi_t === false && (
              <div>
                <img src={no_permi} alt="no permission" style={{ display:'block' , margin: '50px auto' }} />
                <p style={{ textAlign: "center", fontSize: "20px" }}>{t("no permission read module")}</p>
                <p style={{ textAlign:'center', fontSize:'20px' }}> {t("please contract administrator")} </p>
                </div>
            )
          }

        </Container>
      </div>
    </React.Fragment>
  )
}
export default Operator;