import React, { useState, useEffect } from "react"
import { Table, Tooltip } from "antd"
import { useHistory } from "react-router-dom"
import { FcSettings } from "react-icons/fc"
import Render_Image_Data from "./Render_Image_Data"
import { useTranslation } from "react-i18next"

export default function TableData(props) {
  const { t } = useTranslation()
  const history = useHistory()

  const permi = localStorage.getItem("permi")
  const [permi_t, setPermi_t] = useState(false)

  useEffect(() => {
    if (permi) {
      if (JSON.parse(permi).employee) {
        setPermi_t(
          JSON.parse(permi).employee.edit
            ? JSON.parse(permi).employee.edit
            : false
        )
      }
    }
    return () => {
      setPermi_t(false)
    }
  }, [])

  const columns = [
    {
      title: "",
      dataIndex: "emp_avatar",
      key: "emp_avatar",
      render: text => <Render_Image_Data path_image={text} />,
    },
    {
      title: t("code"),
      dataIndex: "emp_code",
      key: "emp_code",
    },
    {
      title: t("firstname"),
      dataIndex: "emp_firstname",
      key: "emp_firstname",
    },
    // {
    //   title: t("lastname"),
    //   dataIndex: "emp_lastname",
    //   key: "emp_lastname",
    // },
    {
      title: t("email"),
      dataIndex: "emp_email",
      key: "emp_email",
    },
    {
      title: t("nickname"),
      dataIndex: "emp_nickname",
      key: "emp_nickname",
    },
    {
      title: t("department"),
      dataIndex: "dep_name",
      key: "dep_name",
    },
    {
      title: t("employee group"),
      dataIndex: "emp_group_name",
      key: "emp_group_name",
    },
    {
      title: t("Admin group"),
      dataIndex: "admin_group_name",
      key: "admin_group_name",
    },
    {
      dataIndex: "emp_id",
      key: "emp_id",
      render: id => <div> {check_permi(id)}</div>,
    },
  ]

  function check_permi(id) {
    return (
      <Tooltip title={t("edit employee")}>
        <a>
          <FcSettings
            size={20}
            onClick={() => history.push(`/employee/${id}`)}
          >
            {t("edit")}
          </FcSettings>
        </a>
      </Tooltip>
    )
  }

  return (
    <div>
      <Table
        columns={columns}
        dataSource={props.data_list}
        pagination={false}
        loading={props.loading}
        rowKey="emp_email"
        // rowKey="emp_code"
        scroll={{ x: 800 }}
      />
    </div>
  )
}
