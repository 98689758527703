import React from 'react'
import { Row, Col } from "reactstrap"

export default function Detail_Service(props) {

    const text_header = {
        fontWeight:'bold',
        fontSize:'15px'
    }

    function cutTimeZone(date_T) {
        if(date_T){
            var date = new Date(date_T);
            var month = pad2(date.getMonth()+1);
            var day = pad2(date.getDate());
            var year= date.getFullYear();
    
            var formattedDate =  day+"/"+month+"/"+year;
            return formattedDate
        } else {
            return 'N/A'
        }
    }
    function pad2(n) {
        return (n < 10 ? '0' : '') + n;
      }
    
    function checkData(data) {
      if(data){
          return data
      } else {
          return 'N/A'
      }
    }

    const data_map = [
        {
            header1 : 'Service number',
            dataContract1 : checkData(props.data_service.ser_no) ,
            header2 : 'Service name',
            dataContract2 : checkData(props.data_service.ser_name)
        },{
            header1 : 'Service start date',
            dataContract1 : cutTimeZone(props.data_service.ser_plan_startdate) ,
            header2 : 'Service end date',
            dataContract2 : cutTimeZone(props.data_service.ser_plan_enddate)
        },{
            header1 : 'Service status',
            dataContract1 : checkData(props.data_service.ser_status_name) ,
            header2 : 'Contract end date',
            dataContract2 : cutTimeZone(props.data_service.con_end_date)
        },{
            header1 : 'Customer name',
            dataContract1 : checkData(props.data_service.cus_name) ,
            header2 : 'Contract name',
            dataContract2 : checkData(props.data_service.con_name)
        }
    ]

    return (
        <div>
        <p style={{ textAlign:'center', fontWeight:'bold', marginTop:'10px', fontSize:'15px' }}>Service information</p>
        <hr />
        {
            data_map.map( (data, index) => {
                return(
                   <Row key={index}>
                   <Col sm={6} md={2} lg={2} xl={2} >
                   <p style={text_header}>{data.header1}</p>
                   </Col>
                   <Col sm={6} md={4} lg={4} xl={4}>
                   <p style={{ fontSize:'15px'}}> <span className="tet">:</span> <span className="tetTwo">-</span> {data.dataContract1}</p>
                   </Col>
                   <Col sm={6} md={2} lg={2} xl={2} >
                   <p style={text_header}>{data.header2}</p>
                   </Col>
                   <Col sm={6} md={4} lg={4} xl={4}>
                   <p style={{ fontSize:'15px'}}> <span className="tet">:</span> <span className="tetTwo">-</span> {data.dataContract2}</p>
                   </Col>
                   </Row>
                )
            })
        }
        </div>
    )
}
