var Ip = require('../service/Ip')

export const LIST_DEPARTMENT = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/department`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_POSITION = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/position`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CHECK_EMAIL_OPERATOR_DUPLICATE = (token, email) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/operator/check-email-dup/${email}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_TYPE_OPERATOR = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/operator/types`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_OPERATOR_GROUP = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/operator/group`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_LEVEL = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/operator/level`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_ROLE_PERMISSION = (token, id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/operator/list-role-permission/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CREATE_NEW_OPERATOR = (token, data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/operator`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const UPLOAD_PROFILE = (token, formData) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}storage/upload`,
      headers: {
        "Content-type": "multipart/form-data",
        'x-access-token' : token
      },
      data : formData
    };
  };