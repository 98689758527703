import React, { useState } from "react"
import { Row, Col } from "reactstrap"
import Expand from "react-expand-animated"
import Custom_Branch from "./Custom_Branch"
import { FiEdit } from "react-icons/fi"
import { BiBuildings } from "react-icons/bi"
import Found_My_Branch from "./Found_My_Branch"
import { useTranslation } from "react-i18next";

export default function Create_New_Branch(props) {
  const [default_style, setDefault_style] = useState(
    "rgb(99 99 99 / 20%) 0px 2px 8px 0px"
  )
  const [custom_style, setCustom_style] = useState(null)
  const [default_icon_color, setDefault_icon_color] = useState("#1890ff")
  const [custom_icon_color, setCustom_icon_color] = useState("#C7C7C7")
  const [open_custom, setOpen_custom] = useState(false)
  const { t } = useTranslation();

  function setGender(event) {
    if (event.target.value === "DEFAULT") {
      setDefault_icon_color("#1890ff")
      setCustom_icon_color("#C7C7C7")
      props.setSelect_radio("DEFAULT")
      setCustom_style(null)
      setDefault_style("rgb(99 99 99 / 20%) 0px 2px 8px 0px")
      setOpen_custom(false)
      props.setBranch({
        cus_branch_address: null,
        cus_branch_code: "00001",
        cus_branch_contact_email: null,
        cus_branch_contact_firstname: null,
        cus_branch_contact_lastname: null,
        cus_branch_contact_mobile: null,
        cus_branch_contact_pos: null,
        cus_branch_email: null,
        cus_branch_fax: null,
        cus_branch_lat: null,
        cus_branch_long: null,
        cus_branch_mobile: null,
        cus_branch_name: null,
        cus_branch_phone: null,
        zip_id: null,
        default_branch: true,
        found_branch_db : false,
      })
    } else {
      setDefault_icon_color("#C7C7C7")
      setCustom_icon_color("#1890ff")
      props.setSelect_radio("CUSTOM")
      setCustom_style("rgb(99 99 99 / 20%) 0px 2px 8px 0px")
      setDefault_style(null)
      setOpen_custom(true)
      props.setBranch({
        cus_branch_address: null,
        cus_branch_code: null,
        cus_branch_contact_email: null,
        cus_branch_contact_firstname: null,
        cus_branch_contact_lastname: null,
        cus_branch_contact_mobile: null,
        cus_branch_contact_pos: null,
        cus_branch_email: null,
        cus_branch_fax: null,
        cus_branch_lat: null,
        cus_branch_long: null,
        cus_branch_mobile: null,
        cus_branch_name: null,
        cus_branch_phone: null,
        zip_id: null,
        default_branch: false,
        found_branch_db : false,
      })
    }
  }

  return (
    <div>
      {props.found_my_customer === false && (
        <div>
          <p
            style={{
              fontSize: "15px",
              fontWeight: "bold",
              color: "#495057",
              marginTop: "20px",
            }}
          >
            {t("Select branch")} <span style={{ color: "red" }}>*</span>{" "}
          </p>
          <div onChange={setGender}>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <div
                  style={{
                    width: "100%",
                    height: "150px",
                    boxShadow: default_style,
                  }}
                >
                  <Row>
                    <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                      <input
                        type="radio"
                        value="DEFAULT"
                        name="select_branch"
                        style={{
                          width: "20px",
                          height: "20px",
                          marginTop: "65px",
                          marginLeft: "20px",
                        }}
                        defaultChecked
                      />
                    </Col>
                    <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                      <BiBuildings
                        size={50}
                        style={{ marginTop: "45px", color: default_icon_color }}
                      />
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                      <p
                        style={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          color: "#495057",
                          marginTop: "20px",
                        }}
                      >
                        {t("Default branch")}
                      </p>
                      <p
                        style={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          color: "#495057",
                        }}
                      >
                        {t("Branch code")} :
                        <span style={{ fontWeight: "normal" }}>00001</span>
                      </p>
                      <p
                        style={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          color: "#495057",
                        }}
                      >
                        {t("Branch name")} :
                        <span style={{ fontWeight: "normal" }}>
                          สำนักงานใหญ่
                        </span>
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <div
                  style={{
                    width: "100%",
                    height: "150px",
                    boxShadow: custom_style,
                  }}
                >
                  <Row>
                    <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                      <input
                        type="radio"
                        value="CUSTOM"
                        name="select_branch"
                        style={{
                          width: "20px",
                          height: "20px",
                          marginTop: "65px",
                          marginLeft: "20px",
                        }}
                      />
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                      <FiEdit
                        size={50}
                        style={{ marginTop: "45px", color: custom_icon_color }}
                      />
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                      <p
                        style={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          marginTop: "55px",
                          color: "#495057",
                        }}
                      >
                     
                        {t("Custom branch")}
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>

          <br />
          <Expand open={open_custom} duration={500}>
            <hr />
            <br />
            <Custom_Branch
              setBranch={props.setBranch}
              branch={props.branch}
              select_radio={props.select_radio}
            />
          </Expand>
        </div>
      )}

      {props.found_my_customer === true && (
        <Found_My_Branch
        setSelect_branch_final={props.setSelect_branch_final}
          setFound_Branch_In_DB={props.setFound_Branch_In_DB}
          found_Branch_In_DB={props.found_Branch_In_DB}
          my_customer={props.my_customer}
          setBranch={props.setBranch}
          branch={props.branch}
        />
      )}
    </div>
  )
}
