import React, { useState, useEffect } from "react"
import { Table, Tooltip, Modal } from "antd"
import { FcSearch } from "react-icons/fc"
import Detail_Car_In_Customer from "./Detail_Car_In_Customer"
import { useTranslation } from "react-i18next";
import { FcDataSheet } from "react-icons/fc"

export default function Table_Car_In_Customer(props) {
  const [visible, setVisible] = useState(false)
  const [detail_car, setDetail_car] = useState(null)
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      setVisible(false)
      setDetail_car(null)
    }
  }, [])

  const columns = [
    {
      title: "title",
      dataIndex: "title",
      key: "title",
      width: 200,
      render: text => <p>{text}</p>,
    },
    {
      title: "gid",
      dataIndex: "gid",
      key: "gid",
      width: 100,
      render: text => <p>{text}</p>,
    },
    {
      title: "grpid",
      dataIndex: "grpid",
      key: "grpid",
      width: 100,
      render: text => <p>{text}</p>,
    },
    {
      title: "license",
      dataIndex: "license",
      key: "license",
      width: 100,
      render: text => <p>{text}</p>,
    },
    {
      title: "lpn",
      dataIndex: "lpn",
      key: "lpn",
      width: 150,
      render: text => <p>{text}</p>,
    },
    {
      title: "remark",
      dataIndex: "remark",
      key: "remark",
      render: text => <p>{text}</p>,
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      render: text => (
        <Tooltip title={t("Detail car")} >
          <a>
            <FcSearch
              size={20}
              onClick={() => {
                setVisible(true)
                setDetail_car(text)
              }}
            />
          </a>
        </Tooltip>
      ),
    },
  ]

  return (
    <div>
      <p className="text-bold"> {t("Car in customer")} </p>
      <Table
        columns={columns}
        dataSource={props.car_customer}
        loading={props.loading}
        scroll={{ x: 800 }}
        rowKey="id"
      />
      <p style={{ fontSize: "15px"}}>
      {" "}
      <FcDataSheet
        size={20}
        style={{ marginBottom: "5px" }}
      />{" "}
      {t("total data")} : {props.car_customer.length}
    </p>
      <Modal
        centered
        open={visible}
        footer={false}
        onCancel={() => {
          setVisible(false)
          setDetail_car(null)
        }}
        width={1000}
      >
        <Detail_Car_In_Customer detail_car={detail_car} />
      </Modal>
    </div>
  )
}
