import React, { useState, useEffect } from "react"
import * as api from "../../../service/Api_Edit_Service"
import Search_Data_Admin from "./Search_Data_Admin"
import { Form, Table, Pagination, Button, Modal, notification } from "antd"
import axios from "axios"
import { Row, Col } from "reactstrap"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import Table_service_hast_admin from "./Table_service_hast_admin"
import { FcDataSheet } from "react-icons/fc"
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import helpers from '../../Function_Redirect/Redirect_login'

const { confirm } = Modal

export default function Admin(props) {
  const [form] = Form.useForm()
  const history = useHistory()
  const { t } = useTranslation();
  const token = localStorage.getItem("token")
  const [loading, setLoading] = useState(false)
  const [data_search_table_admin, setData_search_table_admin] = useState(null)
  const [service_admin, setService_admin] = useState([])
  const [data_admin, setData_admin] = useState([])
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })
  const [loading_admin, setLoading_admin] = useState(false)

  useEffect(() => {
    search_admin()
    check_service_hast_admin()
    return () => {
      setData_search_table_admin(null)
      setData_admin([])
      setService_admin([])
    }
  }, [])

  function search_admin() {
    setLoading_admin(true)
    if (data_search_table_admin) {
      const list_data_admin = api.LIST_DATA_ADMIN_DATA(
        token,
        props.ser_id,
        1,
        page_limit.page_Size,
        data_search_table_admin
      )
      axios(list_data_admin)
        .then(res => {
          setLoading_admin(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setData_admin(res.data.data)
        })
        .catch(error => {
          setLoading_admin(false)
          if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }

        })
    } else {
      const list_no_data_admin = api.LIST_DATA_ADMIN_NO_DATA(
        token,
        props.ser_id,
        1,
        page_limit.page_Size
      )
      axios(list_no_data_admin)
        .then(res => {
          setLoading_admin(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setData_admin(res.data.data)
        })
        .catch(error => {
          setLoading_admin(false)
          if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }

        })
    }
  }

  function changePagination(page, pageSize) {
    setLoading_admin(true)
    if (data_search_table_admin) {
      const list_data_admin_pagination = api.LIST_DATA_ADMIN_DATA(
        token,
        props.ser_id,
        page,
        pageSize,
        data_search_table_admin
      )
      axios(list_data_admin_pagination)
        .then(res => {
          setLoading_admin(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setData_admin(res.data.data)
        })
        .catch(error => {
          setLoading_admin(false)
          if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }

        })
    } else {
      const list_data_admin_pagination = api.LIST_DATA_ADMIN_NO_DATA(
        token,
        props.ser_id,
        page,
        pageSize
      )
      axios(list_data_admin_pagination)
        .then(res => {
          setLoading_admin(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setData_admin(res.data.data)
        })
        .catch(error => {
          setLoading_admin(false)
          if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }

        })
    }
  }

  const columns = [
    {
      title: t("Admin code"),
      dataIndex: "emp_code",
      key: "emp_code",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Firstname"),
      dataIndex: "emp_firstname",
      key: "emp_firstname",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Lastname"),
      dataIndex: "emp_lastname",
      key: "emp_lastname",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Email"),
      dataIndex: "emp_email",
      key: "emp_email",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Position"),
      dataIndex: "pos_name",
      key: "pos_name",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Department"),
      dataIndex: "dep_name",
      key: "dep_name",
      render: text => <p>{text}</p>,
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      props.setAdmin(selectedRows)
    },
    getCheckboxProps: record => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  }

  function cancelForm() {
    form.resetFields()
    search_admin()
    check_service_hast_admin()
    props.setAdmin([])
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  async function fetchData(emp_id) {
    const data = {
      ser_id: props.ser_id,
      emp_id: emp_id,
    }
    const create_admin = api.INSERT_ADMIN(token, data)
    const fetcher = await axios(create_admin)
    if (fetcher.status === 201) {
      openNotificationWithIcon("success", t("Insert admin success"))
      return fetcher
    } else {
      openNotificationWithIcon("error", t("Insert admin error"))
      return fetcher
      // console.log(error.response)
    }
  }

  function add_admin() {
    if (props.admin.length === 0) {
      return Modal.error({
        title: t("Please select admin"),
        okText: t("Confirm"),
        centered: true,
      })
    } else {
      return confirm({
        title: t("Confirm select admin"),
        icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
        centered: true,
        okText: t("Confirm"),
        cancelText: t("no"),
        onOk() {
          setLoading(true)

          const promisesAddAdmin = props.admin.map(async data => {
          await  fetchData(data.emp_id)
          })

          
      setTimeout(() => {
       
        props.list_service_overview()
        props.check_activity_log()
        // openNotificationWithIcon("success", t("Insert admin success"))
        setLoading(false)
        cancelForm()
        search_admin()
        check_service_hast_admin()
      }, 1500);
        },
        onCancel() {},
      })
    }
  }

  function check_service_hast_admin() {
    const check_service_admin = api.LIST_SERVICE_HAST_ADMIN(token, props.ser_id)
    axios(check_service_admin)
      .then(res => {
        setService_admin(res.data.data)
      })
      .catch(error => {
        if(error){
          if(error.response){
            if(error.response.status === 401){
              helpers.re_login()
              history.push('/login')          
              }
          }
        }
      })
  }

  return (
    <div>
      <p
        className="text-bold"
        style={{ textAlign: "center", fontSize: "15px", marginBottom: "10px" }}
      >
        {t("Admin")}
      </p>
      <Table_service_hast_admin
      list_service_overview={props.list_service_overview}
        service_admin={service_admin}
        check_service_hast_admin={check_service_hast_admin}
        search_admin={search_admin}
        check_activity_log={props.check_activity_log}
      />
      <br />
      <div>
        <p className="text-bold ">
          {t("Select admin")} <span style={{ color: "red" }}>*</span>
        </p>
        <Form form={form} name="control-admin">
          <div>
            <Search_Data_Admin
              search_admin={search_admin}
              setData_search_table_admin={setData_search_table_admin}
            />
            <br />
            <Form.Item
              name="admin_info"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Table
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                loading={loading_admin}
                rowKey="emp_id"
                pagination={false}
                columns={columns}
                dataSource={data_admin}
                scroll={{ x: 800 }}
              />
            </Form.Item>

            <div style={{ float: "right", marginTop: "10px" }}>
              <Pagination
                current={page_limit.page}
                total={page_limit.limit}
                onChange={changePagination}
              />
            </div>
            <p style={{ fontSize:'15px' , marginTop:'20px'}}> <FcDataSheet size={20} style={{ marginBottom:'5px' }} /> {t("total data")} : {page_limit.limit}</p>
          </div>

          <br />
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <div className="div_create_new_car">
                <Button
                  className="btn_cancel_create_new_car"
                  onClick={() => cancelForm()}
                >
                  {t("Cancel")}
                </Button>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
              <div className="div_create_new_car">
                <Button
                  className="btn_submit_create_new_car"
                  type="primary"
                  onClick={() => add_admin()}
                  loading={loading}
                >
                  {t("Insert admin")}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  )
}
