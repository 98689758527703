import React, { useEffect, useState } from "react"
import { Container, Card, CardBody } from "reactstrap"
import { useHistory, useParams } from "react-router-dom"
import { Tabs } from "antd"
import New_Branch from "./New_Branch"
import Cars from "./Cars"
import Contract from "./Contract"
import Devices from "./Devices"
import Profile from "./Profile"
import Users from "./Users"
import { MdKeyboardBackspace } from "react-icons/md"
import * as api from "../../../service/Api_New_Customer"
import axios from "axios"
import New_Users from "./New_Users"
import New_Cars from "./New_Cars"
import Overview from "./Overview"
import Edit from "./Edit"
import { useTranslation } from "react-i18next"
import helpers from "../../Function_Redirect/Redirect_login"

const { TabPane } = Tabs

export default function New_Customer(props) {
  const history = useHistory()
  const { t } = useTranslation()
  const [id, setId] = useState(null)
  const [activeTab_all_Customer, setActiveTab_all_Customer] = useState("1")
  const [detail_customer, setDetail_customer] = useState({})
  const token = localStorage.getItem("token")
  const [data_search_branch, setData_search_branch] = useState(null)
  const [branch_table, setBranch_table] = useState([])
  const [page_limit_branch, setPage_limit_branch] = useState({
    page_branch: 1,
    limit_branch: 0,
    page_Size_branch: 10,
  })
  const [image_From_db, setImage_From_db] = useState(null)
  const [loading_branch, setLoading_branch] = useState(false)

  let { profile_id } = useParams()
  // profile_id = cus_id

  useEffect(() => {
    if (profile_id) {
      setActiveTab_all_Customer(props.location.page_local)
      setId(parseInt(profile_id))
    }
    return () => {}
  }, [profile_id])

  useEffect(() => {
    if (id) {
      search_data_branch()
      check_profile_customer()
    }
    return () => {}
  }, [id])

  useEffect(() => {
    return () => {
      setDetail_customer({})
      setData_search_branch(null)
      setBranch_table([])
      setImage_From_db(null)
    }
  }, [])

  function check_profile_customer() {
    const check_detail_customer = api.DETAIL_CUSTOMER(token, profile_id)
    axios(check_detail_customer)
      .then(res => {
        get_image_avatar(res.data.data[0].cus_avatar)
        setDetail_customer(res.data.data[0])
        if (res.data.data[0] === undefined) {
          history.push("/customers")
        }
      })
      .catch(error => {
        // console.log(error.response)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  function get_image_avatar(cus_avatar) {
    if (cus_avatar !== "None" && cus_avatar !== "-" && cus_avatar !== null) {
      const get_data_image = api.GET_IMAGE(token, cus_avatar)
      axios(get_data_image)
        .then(res => {
          const blob = new Blob([res.data], {
            type: "image/jpeg",
          })
          var objectURL = URL.createObjectURL(blob)
          setImage_From_db(objectURL)
        })
        .catch(error => {
          // console.log(error.response);
        })
    }
  }

  function search_data_branch() {
    setLoading_branch(true)
    if (data_search_branch) {
      const search_data = api.SEARCH_DATA(
        token,
        profile_id,
        1,
        page_limit_branch.page_Size_branch,
        data_search_branch
      )
      axios(search_data)
        .then(res => {
          setLoading_branch(false)
          setPage_limit_branch({
            ...page_limit_branch,
            page_branch: 1,
            limit_branch: res.data.total_data,
          })
          setBranch_table(res.data.data)
        })
        .catch(error => {
          setLoading_branch(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else {
      const search_no_data = api.SEARCH_NODATA(
        token,
        profile_id,
        1,
        page_limit_branch.page_Size_branch
      )
      axios(search_no_data)
        .then(res => {
          setLoading_branch(false)
          setPage_limit_branch({
            ...page_limit_branch,
            page_branch: 1,
            limit_branch: res.data.total_data,
          })
          setBranch_table(res.data.data)
        })
        .catch(error => {
          setLoading_branch(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  function changePagination(page, pageSize) {
    setLoading_branch(true)
    if (data_search_branch) {
      const search_data = api.SEARCH_DATA(
        token,
        profile_id,
        page,
        pageSize,
        data_search_branch
      )
      axios(search_data)
        .then(res => {
          setLoading_branch(false)
          setPage_limit_branch({
            ...page_limit_branch,
            page_branch: page,
            page_Size_branch: pageSize,
            limit_branch: res.data.total_data,
          })
          setBranch_table(res.data.data)
        })
        .catch(error => {
          setLoading_branch(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else {
      const search_no_data = api.SEARCH_NODATA(
        token,
        profile_id,
        page,
        pageSize
      )
      axios(search_no_data)
        .then(res => {
          setLoading_branch(false)
          setPage_limit_branch({
            ...page_limit_branch,
            page_branch: page,
            page_Size_branch: pageSize,
            limit_branch: res.data.total_data,
          })
          setBranch_table(res.data.data)
        })
        .catch(error => {
          setLoading_branch(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/customers")}
              />
            </a>{" "}
            {t("customer")}
          </h3>
          <Card>
            <CardBody>
              <Tabs
                defaultActiveKey="1"
                onTabClick={e => {
                  setActiveTab_all_Customer(e)
                }}
                activeKey={activeTab_all_Customer}
              >
                <TabPane tab={t("overview")} key="1">
                  <Overview detail_customer={detail_customer} />
                </TabPane>
                <TabPane tab={t("branch")} key="3">
                  <New_Branch
                    id={id}
                    branch_table={branch_table}
                    setData_search_branch={setData_search_branch}
                    page_limit_branch={page_limit_branch}
                    setPage_limit_branch={setPage_limit_branch}
                    search_data_branch={search_data_branch}
                    changePagination={changePagination}
                    loading_branch={loading_branch}
                  />
                </TabPane>
                <TabPane tab={t("users")} key="4">
                  <New_Users
                    id={id}
                    branch_table={branch_table}
                    setData_search_branch={setData_search_branch}
                    page_limit_branch={page_limit_branch}
                    setPage_limit_branch={setPage_limit_branch}
                    search_data_branch={search_data_branch}
                    changePagination={changePagination}
                    loading_branch={loading_branch}
                  />
                </TabPane>
                <TabPane tab={t("cars")} key="5">
                  <New_Cars
                    id={id}
                    branch_table={branch_table}
                    setData_search_branch={setData_search_branch}
                    page_limit_branch={page_limit_branch}
                    setPage_limit_branch={setPage_limit_branch}
                    search_data_branch={search_data_branch}
                    changePagination={changePagination}
                    loading_branch={loading_branch}
                  />
                </TabPane>
                <TabPane tab={t("devices")} key="6">
                  <Devices id={id} />
                </TabPane>
                <TabPane tab={t("contract")} key="7">
                  <Contract id={id} />
                </TabPane>
              </Tabs>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
