import React, { useState, useEffect } from "react"
import { Select, Form } from "antd"

const { Option } = Select

export default function Select_data({
    data_map,
    header ,
    placeholder,
    keyState,
    headState,
    nameState,
    map_value,
    map_name
    
}) {
    useEffect(() => {
        render_select_data()
        return () => {

        }
    }, [data_map])

    
    function render_select_data() {
     return data_map.map( data => {
        return  <Option key={data[map_value]} value={data[map_value]}>
                    {data[map_name]}
                </Option>
    })
      }

      function onChange(value) {
        if(value){
          headState({ ...nameState, [keyState]: value })
        }
      }
    
    return (
        <div>
        <p className="text-bold">
          {header} <span style={{ color:'red' }}>*</span>
        </p>
        <Form.Item
          name={keyState}
          rules={[
            {
              required: true,
              message: `Please input ${placeholder}`,
            },
          ]}
        >
        <Select
        showSearch
        placeholder={`select a ${placeholder}`}
        style={{ width: "100%" }}
        optionFilterProp="children"
        onChange={onChange}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        { render_select_data() }
      </Select>
        </Form.Item>
      </div>
    )
}
