import React, { useState, useEffect } from "react"
import { Form, Button, Modal, notification } from "antd"
import { Row, Col } from "reactstrap"
import Input_Comp from "./components/Input_Comp"
import Input_Date_Comp from "./components/Input_Date_Comp"
import Select_no_search from "./components/Select_no_search"
import Input_Time from "./components/Input_Time"
import Select_Contract from "./Select_Contract"
import axios from "axios"
import { useHistory } from "react-router-dom"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import * as api from "../../../service/Api_New_Service"
import Select_Email from './Select_Email'

const { confirm } = Modal

export default function Create_New_Service(props) {
  const token = localStorage.getItem("token")
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [form] = Form.useForm()
  const [create_service, setCreate_service] = useState({
    con_id: null,
    ser_status_id: 1,
    ser_booking_date: null,
    ser_booking_time: null,
  })
  const [customer_detail, setCustomer_detail] = useState({
    cus_name: null,
    cus_id: null,
  })
  const [contract_data, setContract_data] = useState({
    con_id: null,
    con_name: null,
  })
  const [send_email, setSend_email] = useState(null)
  const [select_email_customer, setSelect_email_customer] = useState(null)
  const [customer_table, setCustomer_table] = useState([])

  useEffect(() => {
    // email_customer_info
    if(send_email){
      setSelect_email_customer(send_email)
      form.setFieldsValue({
        email_customer_info: send_email
      });
    }
    return () => {
      
    }
  }, [send_email])

  const onFinish = values => {
    if (create_service.con_id === null) {
      return Modal.error({
        title: "Please select contract",
        centered: true,
      })
    } else {
      return confirm({
        title: "Confirm create new service",
        icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
        centered: true,
        onOk() {
          setLoading(true)
          const data_new_service = {
            ser_email_sub_notification : select_email_customer,
            ...create_service
          }
          const create_service_data = api.CREATE_NEW_SERVICE(
            token,
            data_new_service
          )
          axios(create_service_data)
            .then(res => {
              cancelForm()
              openNotificationWithIcon("success", "Create new service success")
              setLoading(false)
              history.push(`/service/${res.data.ser_id}`)
            })
            .catch(error => {
              openNotificationWithIcon("error", "Create error")
              setLoading(false)
              // console.log(error.response)
            })
        },
        onCancel() {
          //   console.log('Cancel');
        },
      })
    }
  }

  const cancelForm = () => {
    form.resetFields()
    setCreate_service({
      con_id: null,
      ser_status_id: 1,
      ser_booking_date: null,
      ser_booking_time: null,
    })
    setCustomer_detail({
      cus_name: null,
      cus_id: null,
    })
    setContract_data({
      con_id: null,
      con_name: null,
    })
    setSelect_email_customer(null)
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  useEffect(() => {
    return () => {
      form.resetFields()
      setCreate_service({})
      setCustomer_table([])
      setCustomer_detail({})
      setContract_data({})
      setSelect_email_customer(null)
      setSend_email(null)
    }
  }, [])

  return (
    <div>
      <Form
        form={form}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header="Service name"
              placeholder="service name"
              headState={setCreate_service}
              nameState={create_service}
              type="text"
              keyState="ser_name"
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header="Service issue"
              placeholder="service issue"
              headState={setCreate_service}
              nameState={create_service}
              type="text"
              keyState="ser_issue_reported"
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header="Contact name"
              placeholder="contact name"
              headState={setCreate_service}
              nameState={create_service}
              type="text"
              keyState="ser_contact_name"
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header="Contact phone"
              placeholder="contact phone"
              headState={setCreate_service}
              nameState={create_service}
              type="number"
              keyState="ser_contact_phone"
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Date_Comp
              header="Service date start"
              placeholder="service date start"
              headState={setCreate_service}
              nameState={create_service}
              keyState="ser_plan_startdate"
              required={true}
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Date_Comp
              header="Service date end"
              placeholder="service date end"
              headState={setCreate_service}
              nameState={create_service}
              keyState="ser_plan_enddate"
              required={true}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
          <Select_no_search
          header="Service type"
          placeholder="service type"
          name_api="LIST_SERVICE_TYPE"
          map_name="ser_type_name"
          map_value="ser_type_id"
          keyState="ser_type_id"
          headState={setCreate_service}
          nameState={create_service}
          name_array_data="data"
        />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header="Service priority"
              placeholder="service priority"
              name_api="LIST_PRIORITY"
              map_name="pri_name"
              map_value="pri_id"
              keyState="pri_id"
              headState={setCreate_service}
              nameState={create_service}
              name_array_data="data"
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Date_Comp
              header="Service booking date"
              placeholder="service booking date"
              headState={setCreate_service}
              nameState={create_service}
              keyState="ser_booking_date"
              required={false}
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Time
              header="Service booking time"
              placeholder="service booking time"
              headState={setCreate_service}
              nameState={create_service}
              keyState="ser_booking_time"
              required={false}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Select_Contract
              setCreate_service={setCreate_service}
              create_service={create_service}
              setCustomer_detail={setCustomer_detail}
              customer_detail={customer_detail}
              setCustomer_table={setCustomer_table}
              customer_table={customer_table}
              setContract_data={setContract_data}
              contract_data={contract_data}
            />
          </Col>
        </Row>
<br />
        <Row>
        <Col sm={12} md={6} lg={6} xl={6}>
          <Select_no_search
            header="Service received type"
            placeholder="service received type"
            name_api="LIST_RECEIVED_TYPE"
            map_name="ser_received_type_name"
            map_value="ser_received_type_id"
            keyState="ser_received_type_id"
            headState={setCreate_service}
            nameState={create_service}
            name_array_data="data"
          />
        </Col>
        <Col sm={12} md={6} lg={6} xl={6}>
        <Select_Email 
        customer_detail={customer_detail}
        setSend_email={setSend_email}
        setSelect_email_customer={setSelect_email_customer}
        />
        </Col>
        </Row>

        <br />
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div className="div_create_new_car">
              <Button
                className="btn_cancel_create_new_car"
                onClick={() => cancelForm()}
              >
                {" "}
                Cancel{" "}
              </Button>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div className="div_create_new_car">
              <Button
                className="btn_submit_create_new_car"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {" "}
                Create{" "}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

// "ser_name": "PM เครื่องคอม" ,               -------------------------------------- pass
// "ser_contact_name" :  "panudet panumas" , -------------------------------------- pass
// "ser_contact_phone" : "0863490407",  ------------------------------------------- pass
// "ser_issue_reported" : "เปิดไม่ติด",     ------------------------------------------ pass
// "ser_plan_startdate" : "2021-12-02",   ----------------------------------------- pass
// "ser_plan_enddate" :"2021-12-08",  --------------------------------------------- pass
// "ser_received_type_id" : 1 ,   FindOneService->ReceivedType  ------------------- pass
// "pri_id": 1 ,           FindOneService->Priority   ----------------------------- pass
// "ser_type_id" : 1 ,     FindOneService->Type    -------------------------------- pass
// "ser_status_id": 1 ,    FindOneService->Status   ------------------------------- pass
// "ser_booking_date":  "2021-12-02" ,                 ไม่บังคับ    ------------------ pass
// "ser_booking_time" : "16:47:38",  เวลานัดหมาย        ไม่บังคับ    ------------------ pass
// "con_id" :  16 ,      FindAllCustomer -> เอา customer id มาหา  FindAllCustomerContract  127.0.0.1:5002/api/v1/contract-customer/<customer id />?page=1&limit=12&order_by=asc&q=
