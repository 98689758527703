import React, { useState, useEffect } from "react"
import Search_contract from "./Search_contract"
import { Pagination, Table, Modal, Tag, Tooltip } from "antd"
import { useHistory } from "react-router-dom"
import * as api from "../../../service/Api_Contract"
import { AiOutlineEye } from "react-icons/ai"
import { FiEdit2 } from "react-icons/fi"
import axios from "axios"
import { FcSearch, FcSettings, FcDataSheet } from "react-icons/fc"
import Detail_Contract from "./Detail_Contract"
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons"
import helpers from "../../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"

export default function List_contract(props) {
  const { t } = useTranslation()
  const token = localStorage.getItem("token")
  const history = useHistory()
  const [data_search_contract, setData_search_contract] = useState(null)
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })
  const [data_list_contract, setData_list_contract] = useState([])
  const [visible, setVisible] = useState(false)
  const [data_Contract_modal, setData_Contract_modal] = useState({})
  const [loading, setLoading] = useState(false)
  const [filter_contract, setFilter_contract] = useState({
    expired: false,
    datestart: null,
    dateend: null,
    customer_id: null,
  })

  useEffect(() => {
    search_data()
    return () => {}
  }, [filter_contract])

  useEffect(() => {
    search_data()
    return () => {
      setData_list_contract([])
    }
  }, [])

  const check_data = (params, data) => {
    if (data) {
      return params + data
    } else {
      return ""
    }
  }

  function search_data() {
    setLoading(true)
    let url = `api/v1/contract?page=1&limit=${
      page_limit.page_Size
    }&order_by=DESC${check_data("&q=", data_search_contract)}${check_data(
      "&expired=",
      filter_contract.expired
    )}${check_data("&datestart=", filter_contract.datestart)}${check_data(
      "&dateend=",
      filter_contract.dateend
    )}${check_data("&customer_id=", filter_contract.customer_id)}`
    const search = api.SEARCH_ALL(token, url)
    axios(search)
      .then(res => {
        setLoading(false)
        setPage_limit({
          ...page_limit,
          page: 1,
          limit: res.data.total_data,
        })
        setData_list_contract(res.data.data)
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  function changePagination(page, pageSize) {
    setLoading(true)
    let url = `api/v1/contract?page=${page}&limit=${pageSize}&order_by=DESC${check_data(
      "&q=",
      data_search_contract
    )}${check_data("&expired=", filter_contract.expired)}${check_data(
      "&datestart=",
      filter_contract.datestart
    )}${check_data("&dateend=", filter_contract.dateend)}${check_data(
      "&customer_id=",
      filter_contract.customer_id
    )}`
    const search = api.SEARCH_ALL(token, url)
    axios(search)
      .then(res => {
        setLoading(false)
        setPage_limit({
          ...page_limit,
          page: page,
          page_Size: pageSize,
          limit: res.data.total_data,
        })
        setData_list_contract(res.data.data)
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  const columns = [
    {
      title: t("Contract"),
      dataIndex: "con_no",
      key: "con_no",
      width: "150px",
      render: text => checkData(text),
    },
    {
      title: t("Contract name"),
      dataIndex: "con_name",
      key: "con_name",
      render: text => checkData(text),
    },
    {
      title: t("Customer"),
      dataIndex: "cus_name",
      key: "cus_name",
      render: text => checkData(text),
    },
    {
      title: t("Start date"),
      dataIndex: "con_start_date",
      key: "con_start_date",
      render: text => cutTimeZone(text),
    },
    {
      title: t("End date"),
      dataIndex: "con_end_date",
      key: "con_end_date",
      render: text => cutTimeZone(text),
    },
    {
      title: t("Status"),
      dataIndex: "con_expired",
      key: "con_expired",
      render: text => cutStatus(text),
    },
    {
      title: "",
      render: text => (
        <Tooltip title={t("View contract")}>
          <a>
            <FcSearch
              onClick={() => {
                setVisible(true)
                setData_Contract_modal(text)
              }}
              size={20}
            />
          </a>
        </Tooltip>
      ),
    },
    {
      title: "",
      render: text => (
        <Tooltip title={t("Edit contract")}>
          <a>
            <FcSettings
              size={20}
              onClick={() => {
                history.push(`/contract/${text.con_id}`)
              }}
            />
          </a>
        </Tooltip>
      ),
    },
  ]

  function cutStatus(status) {
    if (status === true) {
      return (
        <Tag icon={<CloseCircleOutlined />} color="error">
          {t("Expired")}
        </Tag>
      )
    } else {
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          {t("Available")}
        </Tag>
      )
    }
  }

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return "N/A"
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  function checkData(data) {
    if (data) {
      return data
    } else {
      return "N/A"
    }
  }

  return (
    <div>
      <Search_contract
        filter_contract={filter_contract}
        setFilter_contract={setFilter_contract}
        search_data={search_data}
        setData_search_contract={setData_search_contract}
      />

      <Table
        loading={loading}
        columns={columns}
        dataSource={data_list_contract}
        pagination={false}
        rowKey="con_id"
        scroll={{ x: 800 }}
      />

      <Pagination
        current={page_limit.page}
        total={page_limit.limit}
        onChange={changePagination}
        style={{ marginTop: "20px", float: "right" }}
      />
      <p style={{ fontSize: "15px", marginTop: "20px" }}>
        {" "}
        <FcDataSheet size={20} style={{ marginBottom: "5px" }} />{" "}
        {t("total data")} : {page_limit.limit}
      </p>

      <Modal
        title={false}
        centered
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={() => {
          setVisible(false)
          setData_Contract_modal({})
        }}
        footer={false}
        height={500}
        width={1000}
      >
        <Detail_Contract data_Contract_modal={data_Contract_modal} />
      </Modal>
    </div>
  )
}
