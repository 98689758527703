import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { Pagination, Input, Button, Table, Modal, Tooltip } from "antd"
import * as api from "../../../service/Api_Import_Stock_From_DB_Distar"
import axios from "axios"
import Detail_Stock from "./Detail_Stock"
import Import_Stock from "./Import_Stock"
import { FcShipped, FcSearch, FcDeleteDatabase, FcImport, FcDataSheet } from "react-icons/fc"
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import helpers from '../../Function_Redirect/Redirect_login'
import { useSelector } from "react-redux"

import car from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/car.png"
import ambulance from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/ambulance.png"
import vespa from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/vespa.png"
import bus from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/bus.png"
import bicycle from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/bicycle.png"
import van from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/van.png"
import truck from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/truck.png"
import taxi from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/taxi.png"
import pickup from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/pickup.png"
import motorboat from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/motorboat.png"
import motor from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/motor.png"
import no_car from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_car.png"

export default function Stock_Table() {
  const token = localStorage.getItem("token")
  const { t } = useTranslation();
  const history = useHistory()
  const [search_data_stock, setSearch_data_stock] = useState(null)
  const [data_list, setData_list] = useState([])
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data_device, setData_device] = useState({})
  const [group_list, setGroup_list] = useState([])
  const [warranty_list, setWarranty_list] = useState([])
  const [import_stock_select, setImport_stock_select] = useState({})
  const [select_stock_modal, setSelect_stock_modal] = useState(false)
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })
  const [import_success, setImport_success] = useState(false)
  const { permissionAllService } = useSelector(({ Distar }) => Distar)
  const permi = localStorage.getItem('permi')
  const [permi_t, setPermi_t] = useState(false)

useEffect(() => {
  if(permissionAllService){
    if(permissionAllService.import){
      setPermi_t(permissionAllService.import.write)
    }
  }
  return () => {

  }
}, [permissionAllService])

  useEffect(() => {
    search_data()
    list_group_warranty()
    list_group_product()
    return () => {
      setData_list([])
      setWarranty_list([])
      setGroup_list([])
      setSearch_data_stock(null)
    }
  }, [])

  function list_group_warranty() {
    const list_group = api.LIST_PRODUCT_WARRANTY(token)
    axios(list_group)
      .then(res => {
        setWarranty_list(res.data.data)
      })
      .catch(error => {
        // console.log(error.response)
        if(error){
          if(error.response){
            if(error.response.status === 401){
              helpers.re_login()
              history.push('/login')          
              }
          }
        }

      })
  }

  function list_group_product() {
    const list_group = api.LIST_PRODUCT_GROUP(token)
    axios(list_group)
      .then(res => {
        setGroup_list(res.data.data)
      })
      .catch(error => {
        // console.log(error.response)
        if(error){
          if(error.response){
            if(error.response.status === 401){
              helpers.re_login()
              history.push('/login')          
              }
          }
        }

      })
  }

  function search_data() {
    setLoading(true)
    if (search_data_stock) {
      const list_data_stock_data = api.LIST_STOCK_DATA(
        token,
        1,
        page_limit.page_Size,
        search_data_stock
      )
      axios(list_data_stock_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          // console.log(error.response)
          if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }

        })
    } else {
      const list_data_stock_no_data = api.LIST_STOCK_NO_DATA(
        token,
        1,
        page_limit.page_Size
      )
      axios(list_data_stock_no_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }

          // console.log(error.response)
        })
    }
  }

  function changePagination(page, pageSize) {
    setLoading(true)
    if (search_data_stock) {
      const list_data_stock_data = api.LIST_STOCK_DATA(
        token,
        page,
        pageSize,
        search_data_stock
      )
      axios(list_data_stock_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }

          // console.log(error)
        })
    } else {
      const list_data_stock_no_data = api.LIST_STOCK_NO_DATA(
        token,
        page,
        pageSize
      )
      axios(list_data_stock_no_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }

          // console.log(error)
        })
    }
  }

  const columns = [
    {
      title: t("Car id"),
      dataIndex: "car_id",
      key: "car_id",
      render: text => checkData(text),
    },
    {
      title: t("Car title"),
      dataIndex: "car_title",
      key: "car_title",
      render: text => checkData(text),
    },
    {
      title: t("Car type"),
      dataIndex: "car_body_types",
      key: "car_body_types",
      render: text => (
        <Tooltip title={text ? text : t("Invalid car type")}>
          <img
            src={render_Icon_Car(text)}
            alt="icon"
            style={{ width: "50px" }}
          />
        </Tooltip>
      ),
    },
    {
      title: t("Car in database"),
      dataIndex: "is_car_in_db",
      key: "is_car_in_db",
      render: text => check_car(text),
    },
    {
      title: t("Device title"),
      dataIndex: "device_title",
      key: "device_title",
      width : '100px' ,
      render: text => checkData(text),
    },
    {
      title: t("Device group"),
      dataIndex: "device_group",
      key: "device_group",
      width : '150px' ,
      render: text => checkData(text),
    },
    {
      title: t("Device serial number"),
      dataIndex: "device_serial_number",
      key: "device_serial_number",
      render: text => checkData(text),
    },
    {
      title: t("Device remark"),
      dataIndex: "device_remark",
      key: "device_remark",
      render: text => checkData(text),
    },
    {
      title: t("Sim id"),
      dataIndex: "sim_id",
      key: "sim_id",
      render: text => checkData(text),
    },
    {
      title: t("Sim phone"),
      dataIndex: "sim_phone",
      key: "sim_phone",
      render: text => checkData(text),
    },
    {
      title: "",
      render: text => (
        <Tooltip title={t("View stock")} >
          <a>
            <FcSearch
              onClick={() => {
                setVisible(true)
                setData_device(text)
              }}
              size={20}
            />
          </a>
        </Tooltip>
      ),
    },
    {
      title: "",
      render: text => (
        <Tooltip title={t("Import stock")} >
        <a>
          <FcImport
            size={20}
            onClick={() => {
              setSelect_stock_modal(true)
              setImport_stock_select(text)
              setData_device(text)
            }}
          />
        </a>
      </Tooltip>
      ),
    }
  ]


  function render_Icon_Car(type_car) {
      switch (type_car) {
        case "car":
          return car
          break
        case "vespa":
          return vespa
          break
        case "van":
          return van
          break
        case "truck":
          return truck
          break
        case "taxi":
          return taxi
          break
        case "bus":
          return bus
          break
        case "pickup":
          return pickup
          break
        case "motorboat":
          return motorboat
          break
        case "motor":
          return motor
          break
        case "bicycle":
          return bicycle
          break
        case "ambulance":
          return ambulance
          break
        default:
          return no_car
          break
      }
  }

  function checkData(data) {
    if (data) {
      return data
    } else {
      return "N/A"
    }
  }

  function check_car(car) {
    if (car === true) {
      return (
        <Tooltip title={t("This car already exists in the database")} >
          <FcShipped
            size={25}
            style={{ color: "#52c41a", display: "block", margin: "auto" }}
          />
        </Tooltip>
      )
    } else {
      return (
        <Tooltip title={t("This car was not found in the database")} >
          <FcDeleteDatabase
            size={25}
            style={{ color: "#B6B6B6", display: "block", margin: "auto" }}
          />
        </Tooltip>
      )
    }
  }

  return (
    <div>
      <Row>
        <Col sm={12} md={6} lg={6} xl={4}>
          <Input
            placeholder={`${t("Car title")} ${t("Device title")} ${t("Device serial number")}`}
            style={{ marginBottom: "15px" }}
            onChange={e => setSearch_data_stock(e.target.value)}
          />
        </Col>
        <Col sm={12} md={6} lg={4} xl={4}>
          <Button
            type="primary"
            className="button-search-employee"
            htmlType="submit"
            onClick={() => search_data()}
          >
            {t("Search")}
          </Button>
        </Col>
      </Row>

      <div>
        <p>
          {" "}
          <FcShipped size={25} /> {t("Car already exists in the database")}
          <span style={{ marginLeft: "10px" }}>/</span>{" "}
          <FcDeleteDatabase size={25} style={{ marginLeft: "10px" }} /> {t("Car was not found in the database")}
        </p>
      </div>

      <Table
        loading={loading}
        columns={columns}
        dataSource={data_list}
        pagination={false}
        rowKey="device_serial_number"
        scroll={{ x: 800 }}
      />

      <Pagination
        current={page_limit.page}
        total={page_limit.limit}
        onChange={changePagination}
        style={{ marginTop: "20px", float: "right" }}
      />
      <p style={{ fontSize:'15px' , marginTop:'20px'}}> <FcDataSheet size={20} style={{ marginBottom:'5px' }} /> {t("total data")} : {page_limit.limit}</p>

      <Modal
        title={false}
        centered
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={() => {
          setVisible(false)
          setData_device({})
        }}
        footer={false}
        height={500}
        width={1000}
      >
      <Detail_Stock data_device={data_device} />
  
      </Modal>

      <Modal
        title={false}
        centered
        open={select_stock_modal}
        onOk={() => setSelect_stock_modal(false)}
        onCancel={() => {
          setImport_success(false)
          setData_device({})
          setSelect_stock_modal(false)
          setImport_stock_select({})
        }}
        footer={false}
        height={500}
        width={1000}
      >
      {
        import_success === false && (
          <Detail_Stock data_device={data_device} />
        )
      }
      {
        permi_t === true && (
          <Import_Stock
          setSelect_stock_modal={setSelect_stock_modal}
          setImport_stock_select={setImport_stock_select}
          select_stock_modal={select_stock_modal}
          group_list={group_list}
          warranty_list={warranty_list}
          import_stock_select={import_stock_select}
          changePagination={changePagination}
          page_limit={page_limit}
          setImport_success={setImport_success}
          import_success={import_success}
        />
        )
      }
      {
        permi_t === false && (
        <div>
        <p style={{ color:'red' , textAlign:'center', marginTop:'50px', fontSize:'20px' }}>{t("no permission write module")}</p>
        <p style={{ textAlign:'center', fontSize:'20px' }}> {t("please contract administrator")} </p>

        </div>
        )
      }
        
      </Modal>
    </div>
  )
}
