import React, { useEffect, useState } from "react"
import { Form, Checkbox, Select } from "antd"
import { Row, Col } from "reactstrap"
import axios from "axios"
import * as api from "../../../../../service/Api_New_Customer"

const { Option } = Select
const style_req = {
  color: "red",
}

export default function Role_permission_level(props) {
  const token = localStorage.getItem("token")
  const [role_all_info, setRole_all_info] = useState([])
  const [list_checkbox, setList_Checkbox] = useState([])

  useEffect(() => {
    function lest_role_all() {
      const list_role = api.LIST_ALL_ROLE(token)
      axios(list_role)
        .then(res => {
          setRole_all_info(res.data.data)
        })
        .catch(error => {
          console.log(error)
        })
    }
    lest_role_all()
    return () => {}
  }, [])

  useEffect(() => {
   
    if(!props.data_user.cus_user_level_id){
      setList_Checkbox([])
    }
    return () => {
      
    }
  }, [props.data_user.cus_user_level_id])

  useEffect(() => {
    render_select_role_all()
    return () => {}
  }, [role_all_info])

  function render_select_role_all() {
    return role_all_info.map(data => {
      return (
        <Option key={data.cus_level_id} value={data.cus_level_id}>
          {data.cus_level_name}
        </Option>
      )
    })
  }
  
  function onChange_select_permission_info(value) {
    if (value) {
      props.setData_user({
        ...props.data_user,
        cus_user_level_id: value,
      })
      const find_role = api.LIST_A_ROLE(token, value)
      axios(find_role)
        .then(res => {
          filter_Info_role(res.data)
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      setList_Checkbox([])
      props.setData_user({
        ...props.data_user,
        cus_user_level_id: null,
      })
    }
  }

  function filter_Info_role(role) {
    let role_all = []
    const default_permission = {
      delete: false,
      edit: false,
      export: false,
      full_control: false,
      import: false,
      manage_settings: false,
      read: false,
      write: false,
    }

    // system
    if (role.system.delete === true || role.system.delete === false) {
      role_all.push({
        module: "system",
        checkbox_info: role.system,
      })
    } else {
      role_all.push({
        module: "system",
        checkbox_info: default_permission,
      })
    }

    // branch
    if (role.branch.delete === true || role.system.delete === false) {
      role_all.push({
        module: "branch",
        checkbox_info: role.branch,
      })
    } else {
      role_all.push({
        module: "branch",
        checkbox_info: default_permission,
      })
    }
    // profile
    if (role.profile.delete === true || role.system.delete === false) {
      role_all.push({
        module: "profile",
        checkbox_info: role.profile,
      })
    } else {
      role_all.push({
        module: "profile",
        checkbox_info: default_permission,
      })
    }

    // user
    if (role.user.delete === true || role.system.delete === false) {
      role_all.push({
        module: "user",
        checkbox_info: role.user,
      })
    } else {
      role_all.push({
        module: "user",
        checkbox_info: default_permission,
      })
    }

    setList_Checkbox(role_all)
  }

  useEffect(() => {
    render_Check_Info()
    return () => {}
  }, [list_checkbox])

  function render_Check_Info() {
    return list_checkbox.map((data, index) => {
      return (
        <tr key={index}>
          <td>{data.module}</td>
          <td>
            <Checkbox defaultChecked={data.checkbox_info.read} disabled />
          </td>
          <td>
            <Checkbox defaultChecked={data.checkbox_info.write} disabled />
          </td>
          <td>
            <Checkbox defaultChecked={data.checkbox_info.edit} disabled />
          </td>
          <td>
            <Checkbox defaultChecked={data.checkbox_info.delete} disabled />
          </td>
          <td>
            <Checkbox defaultChecked={data.checkbox_info.import} disabled />
          </td>
          <td>
            <Checkbox defaultChecked={data.checkbox_info.export} disabled />
          </td>
          <td>
            <Checkbox
              defaultChecked={data.checkbox_info.manage_settings}
              disabled
            />
          </td>
          <td>
            <Checkbox
              defaultChecked={data.checkbox_info.full_control}
              disabled
            />
          </td>
        </tr>
      )
    })
  }

  return (
    <div>
      <Row>
        <Col>
          <p className="text-bold mt-4">
            Role and Permission Setting <span style={style_req}>*</span>
          </p>
          <Form.Item
            name="user_role_select_permission_info"
            rules={[
              {
                required: true,
                message: "Please select role and permission",
              },
            ]}
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select a role and permission"
              optionFilterProp="children"
              onChange={onChange_select_permission_info}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {render_select_role_all()}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      {list_checkbox.length !== 0 && (
        <Row>
          <Col>
            <div>
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>Module</th>
                    <th>Read</th>
                    <th>Write</th>
                    <th>Edit</th>
                    <th>Delete</th>
                    <th>Import</th>
                    <th>Export</th>
                    <th>Mange setting</th>
                    <th>Full control</th>
                  </tr>
                </thead>
                <tbody>{render_Check_Info()}</tbody>
              </table>
            </div>
          </Col>
        </Row>
      )}
    </div>
  )
}
