import React, { useState, useEffect } from "react"
import { Button, Input, Table, Pagination } from "antd"
import { Row, Col } from "reactstrap"
import * as api from "../../../../service/Api_Dealer"
import { useTranslation } from "react-i18next"
import { EditOutlined } from "@ant-design/icons"
import axios from "axios"
import { FcDataSheet } from "react-icons/fc"
import Render_Image_Data from "../../../Employee/Table/Render_Image_Data"

export default function Select_Filter_Dealer(props) {
  const { t } = useTranslation()
  const token = localStorage.getItem("token")
  const [search_customer, setSearch_customer] = useState(null)
  const [loading, setLoading] = useState(false)
  const [data_list, setData_list] = useState([])
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })

  useEffect(() => {
    search_data()
    setImageSize()
    return () => {
      setData_list([])
    }
  }, [])

  const search = values => {
    search_data()
  }

  const check_data = (params, data) => {
    if (data) {
      return params + data
    } else {
      return ""
    }
  }

  const setImageSize = () => {
    if (window.innerWidth < 768) {
      return "small"
    } else {
      return null
    }
  }

  function search_data() {
    setLoading(true)
    let url = `/api/v1/customer/${props.id}/users?page=1&limit=${
      page_limit.page_Size
    }&order_by=asc${check_data("&q=", search_customer)}`
    const search = api.SEARCH_ALL(token, url)
    axios(search)
      .then(res => {
        setLoading(false)
        setPage_limit({
          ...page_limit,
          page: 1,
          limit: res.data.total_data,
        })
        setData_list(res.data.data)
      })
      .catch(error => {
        setLoading(false)
        // console.log(error)
      })
  }

  function changePagination(page, pageSize) {
    setLoading(true)
    let url = `/api/v1/customer/${
      props.id
    }/users?page=${page}&limit=${pageSize}&order_by=asc${check_data(
      "&q=",
      search_customer
    )}`
    const search = api.SEARCH_ALL(token, url)
    axios(search)
      .then(res => {
        setLoading(false)
        setPage_limit({
          ...page_limit,
          page: page,
          page_Size: pageSize,
          limit: res.data.total_data,
        })
        setData_list(res.data.data)
      })
      .catch(error => {
        setLoading(false)
        // console.log(error)
      })
  }

  const columns = [
    {
      title: "",
      dataIndex: "cus_user_avatar",
      key: "cus_user_avatar",
      render: text => <Render_Image_Data path_image={text} />,
    },
    {
      title: t("firstname"),
      dataIndex: "cus_user_firstname",
      key: "cus_user_firstname",
      render: text => <p>{text}</p>,
    },
    {
      title: t("lastname"),
      dataIndex: "cus_user_lastname",
      key: "cus_user_lastname",
      render: text => <p>{text}</p>,
    },
    {
      title: t("branch name"), 
      dataIndex: "cus_branch_name",
      key: "cus_branch_name",
      render: text => <p>{text}</p>,
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      props.setUser_data({
        ...props.user_data,
        cus_user_id: selectedRows[0].cus_user_id,
        cus_user_firstname: selectedRows[0].cus_user_firstname,
      })
    },
    getCheckboxProps: record => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  }

  return (
    <div >
      <p className="text-bold header-filter">
        {" "}
        {t("search user")} <span style={{ color: "red" }}>*</span>{" "}
      </p>
      {props.user_data.cus_user_firstname === null &&
        props.user_data.cus_user_id === null && (
          <div>
            <Row>
              <Col sm={12} md={9} lg={9} xl={9}>
                <Input
                  placeholder={t("search")}
                  style={{ marginBottom: "15px", width: "100%" }}
                  onChange={e => setSearch_customer(e.target.value)}
                />
              </Col>
              <Col sm={12} md={3} lg={3} xl={3}>
                <Button
                  onClick={() => search()}
                  type="primary"
                  className="button-search-employee"
                >
                  {t("search")}
                </Button>
              </Col>
            </Row>
            <Table
              rowSelection={{
                type: "radio",
                ...rowSelection,
              }}
              loading={loading}
              pagination={false}
              scroll={{ x: 400 }}
              rowKey="cus_user_id"
              columns={columns}
              dataSource={data_list}
            />
            <div>
              <Pagination
                size={setImageSize()}
                current={page_limit.page}
                total={page_limit.limit}
                onChange={changePagination}
                style={{ marginTop: "20px", float: "right" }}
              />
            </div>
            <p style={{ fontSize:'15px' , marginTop:'20px'}}> <FcDataSheet size={20} style={{ marginBottom:'5px' }} /> {t("total data")} : {page_limit.limit}</p>
          </div>
        )}

      {props.user_data.cus_user_firstname !== null &&
        props.user_data.cus_user_id !== null && (
          <Row>
            <Col sm={12} md={12} lg={12} xl={12}>
              <div style={{ display: "flex" }}>
                <p className="text-bold " style={{ marginTop: "7px", width:'50px', marginRight:'20px' }}>
                  {" "}
                  {t("user")} 
                </p>
                <Input
                  style={{ height: "35px" }}
                  value={props.user_data.cus_user_firstname}
                  placeholder={props.user_data.cus_user_firstname}
                  disabled
                />
                <a>
                  <EditOutlined
                    style={{ fontSize: "20px", marginLeft: "20px" }}
                    onClick={() => {
                      props.setUser_data({
                        ...props.user_data,
                        cus_user_id: null,
                        cus_user_firstname: null,
                      })
                    }}
                  />
                </a>
              </div>
            </Col>
          </Row>
        )}
    </div>
  )
}
