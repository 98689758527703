import React, { useEffect, useState } from "react"
import * as api from "../../../../service/Api_Edit_Operator"
import { Form, Select, Checkbox } from "antd"
import axios from "axios"

const { Option } = Select

export default function Level(props) {
  const token = localStorage.getItem("token")
  const [list_level, setList_level] = useState([])
  const [list_all, setList_all] = useState([])

  useEffect(() => {
    if(props.operator_edit.ope_level_id){
      List_Role(props.operator_edit.ope_level_id)
    } else {

    }
    return () => {
      
    }
  }, [props.operator_edit.ope_level_id])

  useEffect(() => {
    List_level_ope()
    return () => {
      setList_level([])
      setList_all([])
    }
  }, [])

  useEffect(() => {
    render_select_level()
    return () => {}
  }, [list_level])

  function List_level_ope() {
    const list_data = api.LIST_LEVEL(token)
    axios(list_data)
      .then(res => {
        setList_level(res.data.operator_level)
      })
      .catch(error => {
        console.log(error)
      })
  }

  function render_select_level() {
    return list_level.map(data => {
      return (
        <Option key={data.ope_level_id} value={data.ope_level_id}>
          {data.ope_level_name}
        </Option>
      )
    })
  }
  
  function onSelect_Level(value) {
    if (value) {
      props.setOperator_Edit({
        ...props.operator_edit,
        ope_level_id: value,
      })
    } else {
      props.setOperator_Edit({
        ...props.operator_edit,
        ope_level_id: null,
      })
    }
  }

  function List_Role(value) {
      const list_role_per = api.LIST_ROLE_PERMISSION(token, value)
      axios(list_role_per)
        .then(res => {
          filter_Info_role(res.data)
        })
        .catch(error => {
          console.log(error)
        })
  }

  function filter_Info_role(role) {
    let role_all = []
    const default_permission = {
      delete: false,
      edit: false,
      export: false,
      full_control: false,
      import: false,
      manage_settings: false,
      read: false,
      write: false,
    }
    // system
    if (role.system.delete === true || role.system.delete === false) {
      role_all.push({
        module: "system",
        checkbox_info: role.system,
      })
    } else {
      role_all.push({
        module: "system",
        checkbox_info: default_permission,
      })
    }

    // operator
    if (role.operator.delete === true || role.operator.delete === false) {
      role_all.push({
        module: "operator",
        checkbox_info: role.operator,
      })
    } else {
      role_all.push({
        module: "operator",
        checkbox_info: default_permission,
      })
    }

    // profile
    if (role.profile.delete === true || role.profile.delete === false) {
      role_all.push({
        module: "profile",
        checkbox_info: role.profile,
      })
    } else {
      role_all.push({
        module: "profile",
        checkbox_info: default_permission,
      })
    }

    setList_all(role_all)
  }

  useEffect(() => {
    render_Check_Info()
    return () => {}
  }, [list_all])

  function render_Check_Info() {
    return list_all.map((data, index) => {
      return (
        <tr key={index}>
          <td>{data.module}</td>
          <td>
            <Checkbox defaultChecked={data.checkbox_info.read} disabled />
          </td>
          <td>
            <Checkbox defaultChecked={data.checkbox_info.write} disabled />
          </td>
          <td>
            <Checkbox defaultChecked={data.checkbox_info.edit} disabled />
          </td>
          <td>
            <Checkbox defaultChecked={data.checkbox_info.delete} disabled />
          </td>
          <td>
            <Checkbox defaultChecked={data.checkbox_info.import} disabled />
          </td>
          <td>
            <Checkbox defaultChecked={data.checkbox_info.export} disabled />
          </td>
          <td>
            <Checkbox
              defaultChecked={data.checkbox_info.manage_settings}
              disabled
            />
          </td>
          <td>
            <Checkbox
              defaultChecked={data.checkbox_info.full_control}
              disabled
            />
          </td>
        </tr>
      )
    })
  }

  return (
    <div>
      <p className="text-bold">Role and Permission Setting</p>
      <Form.Item
        name="level_operator"
        rules={[
          {
            required: false,
            message: `Please input level`,
          },
        ]}
      >
        <Select
          showSearch
          disabled={props.edit}
          allowClear
          style={{ width: "100%" }}
          placeholder="Select a level"
          optionFilterProp="children"
          onChange={onSelect_Level}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {render_select_level()}
        </Select>
      </Form.Item>
      
{
  props.operator_edit.ope_level_id && (
    <div>
    <table style={{ width: "100%" }}>
      <thead>
        <tr>
          <th>Module</th>
          <th>Read</th>
          <th>Write</th>
          <th>Edit</th>
          <th>Delete</th>
          <th>Import</th>
          <th>Export</th>
          <th>Mange setting</th>
          <th>Full control</th>
        </tr>
      </thead>
      <tbody>{render_Check_Info()}</tbody>
    </table>
  </div>
  )
}

    </div>
  )
}
