import React, { useState, useEffect } from "react"
import { Container, Card, CardBody } from "reactstrap"
import { MdKeyboardBackspace } from "react-icons/md"
import { useHistory } from "react-router-dom"
import MetaTags from "react-meta-tags"
import { Tabs } from "antd"
import Create_New_Contract from "./Create_New_Contract"
import { useTranslation } from "react-i18next"
import no_permi from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png"
import { useSelector } from "react-redux"

const { TabPane } = Tabs

export default function Edit_contract(props) {
  const history = useHistory()
  const permi = localStorage.getItem("permi")
  const { t } = useTranslation()
  const [permi_t, setPermi_t] = useState(false)
  const { permissionAllService } = useSelector(({ Distar }) => Distar)

  useEffect(() => {
    if (permissionAllService) {
      if (permissionAllService.contract) {
        setPermi_t(permissionAllService.contract.write)
      }
    }
    return () => {}
  }, [permissionAllService])

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Create new contract")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/contract")}
              />
            </a>{" "}
            {t("Create new contract")}
          </h3>

          <Card>
            <CardBody>
              {permi_t === true && <Create_New_Contract />}

              {permi_t === false && (
                <div>
                  <img
                    src={no_permi}
                    alt="no permission"
                    style={{ display: "block", margin: "50px auto" }}
                  />

                  <p style={{ textAlign: "center", fontSize: "20px" }}>
                    {t("no permission write module")}
                  </p>
                  <p style={{ textAlign: "center", fontSize: "20px" }}>
                    {" "}
                    {t("please contract administrator")}{" "}
                  </p>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
