import React from "react"
import Dealer_Info from "./Dealer_Info"
import Customer_Info from "./Customer_Info"
import Branch_Info from "./Branch_Info"
import User_Info from "./User_Info"
import Contract_Info from "./Contract_Info"
import Device_Info from "./Device_Info"

export default function Final_Step(props) {
  return (
    <div>
      <br />
      <Dealer_Info
        found_my_dealer={props.found_my_dealer}
        dealer_info={props.dealer_info}
        new_dealer={props.new_dealer}
      />
      <br />
      {
        props.customer_data.cus_name && (
          <Customer_Info
          customer_data={props.customer_data}
        />
        )
      }
      {
        props.my_customer.cus_name && (
          <Customer_Info
          my_customer={props.my_customer}
        />
        )
      }
      
      <br />
      {
        props.branch.cus_branch_name && (
          <Branch_Info
          branch_data={props.branch}
        />
        )
      }

      {
        props.select_branch_final.cus_branch_name && (
          <Branch_Info
          branch_data={props.select_branch_final}
        />
        )
      }
      <br />
      <User_Info user={props.user} />
      <br />
      <Contract_Info new_Contract={props.new_Contract} />
      <br />
      <Device_Info list_device_all={props.list_device_all} />
      <br />
    </div>
  )
}
