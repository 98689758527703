import React from "react"
import { Row, Col } from "reactstrap"
import { Card, Collapse } from "antd"
import product_info from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/product_info.png"
import { useTranslation } from "react-i18next"

const { Panel } = Collapse

export default function Product_Information(props) {
  const { t } = useTranslation()

    const text_header = {
        fontWeight: "bold",
        fontSize: "15px",
      }
    
      const icons_info_header = { 
        width:'25px', 
        marginRight:'20px' 
      }
    
      function cutTimeZone(date_T) {
        if (date_T) {
          var date = new Date(date_T)
          var month = pad2(date.getMonth() + 1)
          var day = pad2(date.getDate())
          var year = date.getFullYear()
    
          var formattedDate = day + "/" + month + "/" + year
          return formattedDate
        } else {
          return "N/A"
        }
      }
      function pad2(n) {
        return (n < 10 ? "0" : "") + n
      }
    
      function checkData(data) {
        if (data) {
          return data
        } else {
          return "N/A"
        }
      }
    
      const data_product = [
        {
          header1: t("Stock asset number"),
          dataContract1: checkData(props.data_all.sto_asset_number),
          header2: t("Stock date in"),
          dataContract2: cutTimeZone(props.data_all.sto_date_in),
        },
        {
          header1: t("Stock date install"),
          dataContract1: cutTimeZone(props.data_all.sto_date_install),
          header2: t("Product model code"),
          dataContract2: checkData(props.data_all.pro_model_code),
        },
        {
          header1: t("Product code"),
          dataContract1: checkData(props.data_all.pro_code),
          header2: t("Product model protocal"),
          dataContract2: checkData(props.data_all.pro_model_protocol),
        },
        {
          header1: t("Product remark"),
          dataContract1: checkData(props.data_all.pro_remark),
          header2: t("Product model factory"),
          dataContract2: checkData(props.data_all.pro_model_factory),
        },
      ]

    return (
       <Card title={ <p> <img src={product_info} alt="product_info" style={icons_info_header} /> {t("Product information")} </p>}>
        <Row>
          <Col sm={6} md={3} lg={3} xl={3}>
            <p style={text_header}> {t("Product number")} </p>
          </Col>
          <Col sm={6} md={3} lg={3} xl={3}>
            <p style={{ fontSize: "15px" }}>
              {" "}
              <span className="tet">
                :
              </span> <span className="tetTwo">-</span>{" "}
              {props.data_all.pro_no}
            </p>
          </Col>
          <Col sm={6} md={3} lg={3} xl={3}>
            <p style={text_header}> {t("Product group name")} </p>
          </Col>
          <Col sm={6} md={3} lg={3} xl={3}>
            <p style={{ fontSize: "15px" }}>
              {" "}
              <span className="tet">
                :
              </span> <span className="tetTwo">-</span>{" "}
              {props.data_all.pro_group_name}
            </p>
          </Col>
          <Col sm={6} md={3} lg={3} xl={3}>
            <p style={text_header}> {t("Product model name")} </p>
          </Col>
          <Col sm={6} md={3} lg={3} xl={3}>
            <p style={{ fontSize: "15px" }}>
              {" "}
              <span className="tet">
                :
              </span> <span className="tetTwo">-</span>{" "}
              {props.data_all.pro_model_name}
            </p>
          </Col>
          <Col sm={6} md={3} lg={3} xl={3}>
            <p style={text_header}> {t("Product serial number")} </p>
          </Col>
          <Col sm={6} md={3} lg={3} xl={3}>
            <p style={{ fontSize: "15px" }}>
              {" "}
              <span className="tet">
                :
              </span> <span className="tetTwo">-</span>{" "}
              {props.data_all.pro_serial_number}
            </p>
          </Col>
        </Row>
        <Collapse>
          <Panel header={t("More")} key="1">
            <br />
            {data_product.map((data, index) => {
              return (
                <Row key={index}>
                  <Col sm={6} md={3} lg={3} xl={3}>
                    <p style={text_header}>{data.header1}</p>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={3}>
                    <p style={{ fontSize: "15px" }}>
                      {" "}
                      <span className="tet">:</span>{" "}
                      <span className="tetTwo">-</span> {data.dataContract1}
                    </p>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={3}>
                    <p style={text_header}>{data.header2}</p>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={3}>
                    <p style={{ fontSize: "15px" }}>
                      {" "}
                      <span className="tet">:</span>{" "}
                      <span className="tetTwo">-</span> {data.dataContract2}
                    </p>
                  </Col>
                </Row>
              )
            })}
          </Panel>
        </Collapse>
      </Card>
    )
}
