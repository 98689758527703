import React, { useState, useEffect } from "react"
import { Row, Col, Container, Card, CardBody } from "reactstrap"
import MetaTags from "react-meta-tags"
import { useHistory } from "react-router-dom"
import { MdKeyboardBackspace } from "react-icons/md"
import { useTranslation } from "react-i18next"
import axios from "axios"
import * as api from "../../service/Api_System_Settings"
import { Button, Modal, notification, Form } from "antd"
import Input_Comp from "./Components/Input_Comp"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { useSelector } from "react-redux"
import no_permi from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png"

const { confirm } = Modal

export default function Line() {
  const history = useHistory()
  const [form_edit] = Form.useForm()
  const { t } = useTranslation()
  const token = localStorage.getItem("token")
  const [loading, setLoading] = useState(false)
  const [edit, setEdit] = useState(true)
  const [line_data, setLine_data] = useState({
    line_access_token: null,
    line_channel_name: null,
    line_secret: null,
  })
  const [line_data_edit, setLine_data_edit] = useState({
    line_access_token: null,
    line_channel_name: null,
    line_secret: null,
  })
  const [permi_t_read, setPermi_t_read] = useState(false)
  const [permi_t_edit, setPermi_t_edit] = useState(false)
  const { permissionAllService } = useSelector(({ Distar }) => Distar)

  useEffect(() => {
    if (permissionAllService) {
      if (permissionAllService.system_setting) {
        setPermi_t_read(permissionAllService.system_setting.read)
        setPermi_t_edit(permissionAllService.system_setting.edit)
      }
    }
    return () => {}
  }, [permissionAllService])

  useEffect(() => {
    check_line_data()
    return () => {}
  }, [])

  function check_line_data() {
    const check_line = api.LIST_LINE_SETTING(token)
    axios(check_line)
      .then(res => {
        setLine_data({
          ...line_data,
          line_access_token: res.data.message.line_access_token,
          line_channel_name: res.data.message.line_channel_name,
          line_secret: res.data.message.line_secret,
        })
        setLine_data_edit({
          ...line_data_edit,
          line_access_token: res.data.message.line_access_token,
          line_channel_name: res.data.message.line_channel_name,
          line_secret: res.data.message.line_secret,
        })
        form_edit.setFieldsValue({
          line_access_token: res.data.message.line_access_token,
          line_channel_name: res.data.message.line_channel_name,
          line_secret: res.data.message.line_secret,
        })
      })
      .catch(error => {
        // console.log(error.response)
      })
  }

  function onFill() {
    form_edit.setFieldsValue({
      line_access_token: line_data.line_access_token,
      line_channel_name: line_data.line_channel_name,
      line_secret: line_data.line_secret,
    })
  }

  function Cancel_Edit() {
    setEdit(!edit)
    onFill()
    setLine_data_edit({
      ...line_data_edit,
      line_access_token: line_data.line_access_token,
      line_channel_name: line_data.line_channel_name,
      line_secret: line_data.line_secret,
    })
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  function onFinish_edit() {
    return confirm({
      title: t("Confirm edit line setting"),
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: t("Confirm"),
      cancelText: t("Cancel"),
      onOk() {
        setLoading(true)
        const edit_line = api.EDIT_LINE_SETTING(token, line_data_edit)
        axios(edit_line)
          .then(res => {
            setLoading(false)
            setEdit(!edit)
            check_line_data()
            openNotificationWithIcon("success", t("Edit line setting success"))
          })
          .catch(error => {
            setLoading(false)
            openNotificationWithIcon("error", t("Edit line setting error"))
          })
      },
      onCancel() {},
    })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Line")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/system-setting")}
              />
            </a>{" "}
            {t("Line")}
          </h3>
          {permi_t_read == true && (
            <Card>
              <CardBody>
                <Form
                  form={form_edit}
                  name="form_edit_data"
                  onFinish={onFinish_edit}
                >
                  {
                    permi_t_edit === true && (
                      <Row>
                      <Col>
                        <div style={{ display: "inherit" }}>
                          {edit === true && (
                            <Button
                              style={{ float: "right", width: "150px" }}
                              onClick={() => setEdit(!edit)}
                            >
                              {t("Edit")}
                            </Button>
                          )}
                          {edit === false && (
                            <Button
                              type="primary"
                              style={{ float: "right", width: "150px" }}
                              onClick={() => Cancel_Edit()}
                            >
                              {t("Cancel")}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                    )
                  }
                 
                  <br />

                  <Input_Comp
                    placeholder="line channel name"
                    header="line channel name"
                    headState={setLine_data_edit}
                    nameState={line_data_edit}
                    keyState="line_channel_name"
                    type="text"
                    required={false}
                    disabled={edit}
                  />
                  <Input_Comp
                    placeholder="Line access token"
                    header="Line access token"
                    headState={setLine_data_edit}
                    nameState={line_data_edit}
                    keyState="line_access_token"
                    type="text"
                    required={false}
                    disabled={edit}
                  />
                  <Input_Comp
                    placeholder="Line secret"
                    header="Line secret"
                    headState={setLine_data_edit}
                    nameState={line_data_edit}
                    keyState="line_secret"
                    type="text"
                    required={false}
                    disabled={edit}
                  />
                  <br />
                  {edit === false && (
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        display: "block",
                        margin: "auto",
                        width: "150px",
                      }}
                      loading={loading}
                    >
                      {t("Edit")}
                    </Button>
                  )}
                </Form>
              </CardBody>
            </Card>
          )}
          {permi_t_read === false && (
            <div>
              <img
                src={no_permi}
                alt="no permission"
                style={{ display: "block", margin: "50px auto" }}
              />
              <p style={{ textAlign: "center", fontSize: "20px" }}>
                {t("no permission read module")}
              </p>
              <p style={{ textAlign: "center", fontSize: "20px" }}>
                {" "}
                {t("please contract administrator")}{" "}
              </p>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
