import React, { useState, useEffect } from "react"
import { Container, Card, CardBody, Row, Col } from "reactstrap"
import MetaTags from "react-meta-tags"
import Table from "./Table"
import * as api from "../../../service/Api_New_Customer"
import axios from "axios"
import { useHistory } from "react-router-dom"
import helpers from "../../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux"

const Contract = props => {
  const { t } = useTranslation();
  const token = localStorage.getItem("token")
  const cus_id = localStorage.getItem("cus_id")
  const history = useHistory()
  const [data_search_contract, setData_search_contract] = useState(null)
  const [data_contract, setData_contract] = useState([])
  const [page_limit_contract, setPage_limit_contract] = useState({
    page_contract: 1,
    limit_contract: 0,
    page_Size_contract: 10,
  })
  const [loading, setLoading] = useState(false)
  const { customer_user_id } = useSelector(({ Distar }) => Distar)

  useEffect(() => {
    search_data_contract()
    return () => {
      setData_contract([])
    }
  }, [customer_user_id])

  function search_data_contract() {
    setLoading(true)
    if (data_search_contract) {
      const search_contract = api.SEARCH_DATA_CONTRACT_IN_CUSTOMER(
        token,
        customer_user_id,
        1,
        page_limit_contract.page_Size_contract,
        data_search_contract
      )
      axios(search_contract)
        .then(res => {
          setLoading(false)
          setPage_limit_contract({
            ...page_limit_contract,
            page_contract: 1,
            limit_contract: res.data.total_data,
          })
          setData_contract(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else {
      const search_no_data = api.SEARCH_DATA_CONTRACT_IN_CUSTOMER_NODATA(
        token,
        customer_user_id,
        1,
        page_limit_contract.page_Size_contract
      )
      axios(search_no_data)
        .then(res => {
          setLoading(false)
          setPage_limit_contract({
            ...page_limit_contract,
            page_contract: 1,
            limit_contract: res.data.total_data,
          })
          setData_contract(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  function changePagination_contract(page, pageSize) {
    setLoading(true)
    if (data_search_contract) {
      const search_data = api.SEARCH_DATA_CONTRACT_IN_CUSTOMER(
        token,
        customer_user_id,
        page,
        pageSize,
        data_search_contract
      )
      axios(search_data)
        .then(res => {
          setLoading(false)
          setPage_limit_contract({
            ...page_limit_contract,
            page_contract: page,
            page_Size_contract: pageSize,
            limit_contract: res.data.total_data,
          })
          setData_contract(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else {
      const search_no_data = api.SEARCH_DATA_CONTRACT_IN_CUSTOMER_NODATA(
        token,
        customer_user_id,
        page,
        pageSize
      )
      axios(search_no_data)
        .then(res => {
          setLoading(false)
          setPage_limit_contract({
            ...page_limit_contract,
            page_contract: page,
            page_Size_contract: pageSize,
            limit_contract: res.data.total_data,
          })
          setData_contract(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>  {t("Contract")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}> {t("Contract")} </h3>
          <Card>
            <CardBody>
              <Table
                id={customer_user_id}
                setData_search_contract={setData_search_contract}
                search_data_contract={search_data_contract}
                changePagination_contract={changePagination_contract}
                page_limit_contract={page_limit_contract}
                data_contract={data_contract}
                loading={loading}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Contract
