import React from "react"
import { FiFileText } from "react-icons/fi"
import { StyleSheet, css } from "aphrodite"
import { BsPeople } from "react-icons/bs"
import { BiCar, BiCalculator } from "react-icons/bi"
import { FaRegHandshake } from "react-icons/fa"

export default function Card_Comp({ color, text_Header, icon_card }) {
  const styles = StyleSheet.create({
    hover: {
      width: "250px",
      height: "350px",
      display: "inline-block",
      margin: "1rem",
      backgroundColor: "white",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",

      ":hover": {
        boxShadow: `${color} 0px 2px 8px 0px`,
      },
    },
    div_icon: {
      height: "70%",
      position: "relative",
      marginBottom: "10px",
      display: "block",
      margin: "auto",
      backgroundColor: `${color}`,
    },
    div_border_icon: {
      width: "100px",
      height: "100px",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: "5",
      backgroundColor: `${color}`,
    },
  })

  function render_icons(icon) {
    switch (icon) {
      case "FaRegHandshake":
        return (
          <FaRegHandshake
            size={50}
            className="icon-header-dashboard"
            style={{ color: "white" }}
          />
        )
        break
      case "BsPeople":
        return (
          <BsPeople
            size={50}
            className="icon-header-dashboard"
            style={{ color: "white" }}
          />
        )
        break
      case "BiCar":
        return (
          <BiCar
            size={50}
            className="icon-header-dashboard"
            style={{ color: "white" }}
          />
        )
        break
      case "BiCalculator":
        return (
          <BiCalculator
            size={50}
            className="icon-header-dashboard"
            style={{ color: "white" }}
          />
        )
        break
      default:
        break
    }
  }

  return (
    <div className={css(styles.hover)}>
      <div className={css(styles.div_icon)}>
        <div className={css(styles.div_border_icon)}>
          {render_icons(icon_card)}
        </div>
      </div>

      <div>
        <p
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "15px",
            marginTop: "40px",
          }}
        >
          {text_Header}
        </p>
      </div>
    </div>
  )
}
