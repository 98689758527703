import React, { useState, useEffect } from "react"
import axios from "axios"
import * as api from "../../../../../../service/service_operator/Api_Service"
import { Row, Col } from "reactstrap"
import { InboxOutlined } from "@ant-design/icons"
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons"
import { Modal } from "antd"

export default function Render_img(props) {
  const [img, setImg] = useState(null)
  const [img_preview_modal, setImg_preview_modal] = useState(null)
  const [visible, setVisible] = useState(false)
  const token = localStorage.getItem("token")

  useEffect(() => {
    if (props.value) {
      get_image(props.value)
    }
    return () => {
      setImg(null)
    }
  }, [props.value])

  async function get_image(path_image) {
    const get_data_image = api.GET_IMAGE(token, path_image)
    await axios(get_data_image)
      .then(res => {
        const blob = new Blob([res.data], {
          type: "image/jpeg",
        })
        var objectURL = URL.createObjectURL(blob)
        setImg(objectURL)
      })
      .catch(error => {
        // console.log(error.response)
      })
  }

  return (
    <>
      <Col xs={12} sm={6} md={6} lg={4} xl={4}>
        <img
          style={{
            width: "150px",
            height: "150px",
            display: "flex",
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: "7px",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          }}
          src={img}
          alt="img"
        />
        <div style={{ marginTop: "10px", marginBottom: "20px" }}>
          <Row>
            <Col>
              <EyeOutlined
                style={{
                  fontSize: "25px",
                  color: "#08c",
                  display: "block",
                  margin: "auto",
                }}
                onClick={() => {
                  setImg_preview_modal(img)
                  setVisible(!visible)
                }}
              />
            </Col>
          </Row>
        </div>
      </Col>
      <Modal
        footer={null}
        centered
        open={visible}
        onOk={() => {
          setImg_preview_modal(null)
          setVisible(false)
        }}
        onCancel={() => {
          setImg_preview_modal(null)
          setVisible(false)
        }}
      >
        <div>
          <img
            src={img_preview_modal}
            alt="info"
            style={{
              width: "100%",
              height: "auto",
              maxWidth: "400px",
              display: "flex",
              margin: "auto",
            }}
          />
        </div>
      </Modal>
    </>
  )
}
