import React, { useState, useEffect } from "react"
import Search_branch_Comp from "./Search_branch_Comp"
import { Table, Pagination, Input } from "antd"
import { Row, Col } from "reactstrap"
import { EditOutlined } from "@ant-design/icons"
import { FcDataSheet } from "react-icons/fc"
import { useTranslation } from "react-i18next"

export default function Select_branch_comp(props) {
  const [customer_table, setCustomer_table] = useState([])
  const { t } = useTranslation()

  useEffect(() => {
    setCustomer_table(props.branch_table)
    return () => {
      setCustomer_table([])
    }
  }, [props.branch_table])

  const columns = [
    {
      title: t("Branch code"),
      dataIndex: "cus_branch_code",
      key: "cus_branch_code",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Branch name"),
      dataIndex: "cus_branch_name",
      key: "cus_branch_name",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Branch address"),
      dataIndex: "cus_branch_address",
      key: "cus_branch_address",
      render: text => <p>{text}</p>,
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      props.setData_car({
        ...props.data_car,
        cus_branch_id: selectedRows[0].cus_branch_id,
      })
      props.setBranch_name(selectedRows[0].cus_branch_name)
    },
    getCheckboxProps: record => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  }

  return (
    <div>
      <div>
        {props.data_car.cus_branch_id === null && (
          <div>
            <p className="text-bold ">
              {t("Select branch")} 
            </p>
            <Search_branch_Comp
              search_data_branch={props.search_data_branch}
              setData_search_branch={props.setData_search_branch}
            />
            <br />
            <Table
              rowSelection={{
                type: "radio",
                ...rowSelection,
              }}
              loading={props.loading_branch}
              rowKey="cus_branch_id"
              pagination={false}
              columns={columns}
              dataSource={customer_table}
              scroll={{ x: 800 }}
            />

            <div style={{ float: "right", marginTop: "10px" }}>
              <Pagination
                current={props.page_limit_branch.page_branch}
                total={props.page_limit_branch.limit_branch}
                onChange={props.changePagination}
              />
            </div>
            <p style={{ fontSize: "15px", marginTop: "20px" }}>
              {" "}
              <FcDataSheet size={20} style={{ marginBottom: "5px" }} />{" "}
              {t("total data")} : {props.page_limit_branch.limit_branch}
            </p>
          </div>
        )}
      </div>

      {props.data_car.cus_branch_id !== null && (
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div style={{ display: "flex" }}>
              <p className="text-bold " style={{ marginTop: "7px" }}>
                {t("Branch")}
              </p>
              <Input
                style={{ marginLeft: "28px", height: "35px" }}
                value={props.branch_name}
                placeholder={props.branch_name}
                disabled
              />
              <a>
                <EditOutlined
                  style={{ fontSize: "20px", marginLeft: "10px" }}
                  onClick={() => {
                    props.setData_car({
                      ...props.data_car,
                      cus_branch_id: null,
                    })
                    props.setData_search_branch(null)
                    props.setBranch_name(null)
                  }}
                />
              </a>
            </div>
          </Col>
        </Row>
      )}
    </div>
  )
}
