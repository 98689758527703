import React, { useState, useEffect } from "react"
import { Select, Form } from "antd"

const { Option } = Select

export default function Select_Province({
  province_all,
  header,
  placeholder,
  keyState,
  headState,
  nameState,
  required
}) {

    function onChange(value) {
        headState({ ...nameState, [keyState]: value })
      }
    
      useEffect(() => {
        render_select_province()
        return () => {}
      }, [province_all])
    
      function render_select_province() {
        return province_all.map(data => {
          return (
            <Option key={data.pro_id} value={data.pro_id}>
              {data.pro_name}
            </Option>
          )
        })
      }

      function checkRequired(required) {
        if(required){
          return <span style={{ color: "red" }}>*</span>
        }
      }
      
  return (
    <div>
    <p className="text-bold">
      {header} {checkRequired(required)}
    </p>
    <Form.Item
      name={keyState}
      rules={[
        {
          required: required,
          message: `${placeholder}`,
        },
      ]}
    >
      <Select
        showSearch
        style={{ width: "100%" }}
        placeholder={placeholder}
        optionFilterProp="children"
        onChange={onChange}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {render_select_province()}
      </Select>
    </Form.Item>
  </div>
  )
}