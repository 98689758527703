import React, { useState, useEffect } from "react"
import { Container, Card, CardBody } from "reactstrap"
import { useHistory, useParams } from "react-router-dom"
import { MdKeyboardBackspace } from "react-icons/md"
import MetaTags from "react-meta-tags"
import { Tabs } from "antd"
import Overview from "./Overview"
import axios from "axios"
import * as api from "../../service/Api_Edit_Dealer_User"
import Setting from "./Setting"
import Edit from "./Edit"
import helpers from "../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"
import ChangePasswordByAdmin from "../components/Profile/ChangePasswordByAdmin"
import { useSelector } from "react-redux"
import Button_ResetPassword from '../components/admin/Button_ResetPassword'

const { TabPane } = Tabs

export default function Edit_Dealer_User(props) {
  const token = localStorage.getItem("token")
  const { t } = useTranslation()
  const history = useHistory()
  const [data_dealer_user, setData_service] = useState({})
  const [image_From_db, setImage_From_db] = useState(null)
  const { isRole , isAdminRoot } = useSelector(({ Distar }) => Distar)
  let { dea_id, dea_user_id } = useParams()

  useEffect(() => {
    // console.log(dea_id);
    // console.log(dea_user_id);
    check_dealer_data_info()
    return () => {}
  }, [dea_user_id])

  function check_dealer_data_info() {
    const check_user_dealer = api.CHECK_DEALER_USER(
      token,
      parseInt(dea_user_id)
    )
    axios(check_user_dealer)
      .then(res => {
        if (res.data.data === undefined) {
          history.push({
            pathname: `/dealer/${dea_id}`,
            page_local: "3",
          })
        } else {
          setData_service(res.data.data)
          get_image_avatar(res.data.data.dea_user_avatar)
        }
      })
      .catch(error => {
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            } else {
              history.push({
                pathname: `/dealer/${dea_id}`,
                page_local: "3",
              })
            }
          }
        }
      })
  }

  function get_image_avatar(cus_avatar) {
    if (cus_avatar !== "None") {
      const get_data_image = api.GET_IMAGE(token, cus_avatar)
      axios(get_data_image)
        .then(res => {
          const blob = new Blob([res.data], {
            type: "image/jpeg",
          })
          var objectURL = URL.createObjectURL(blob)
          setImage_From_db(objectURL)
        })
        .catch(error => {
          // console.log(error.response)
        })
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Edit dealer user")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() =>
                  history.push({
                    pathname: `/dealer/${dea_id}`,
                    page_local: "3",
                  })
                }
              />
            </a>{" "}
            {t("Edit dealer user")}
          </h3>
          <Card>
            <CardBody>
              <Tabs defaultActiveKey="1">
                <TabPane tab={t("Overview")} key="1">
                  <Overview data_dealer_user={data_dealer_user} />
                </TabPane>
                <TabPane tab={t("Edit")} key="2">
                  <Edit
                    check_dealer_data_info={check_dealer_data_info}
                    image_From_db={image_From_db}
                    data_dealer_user={data_dealer_user}
                    dea_user_id={parseInt(dea_user_id)}
                  />
                </TabPane>
                  <TabPane tab={t("Setting")} key="3">
                  {
                        isAdminRoot === true && isRole === "administrator" && (
                          <Button_ResetPassword
                          roleReset="dealer_user" 
                          idReset={parseInt(dea_user_id)}
                          />
                        )
                      }
                    {
                      isRole === "administrator" && (
                        <ChangePasswordByAdmin
                        idIsChange={parseInt(dea_user_id)}
                        roleIsChange="dealer_user"
                      />
                      )
                    }
                    <Setting
                      dea_user_id={parseInt(dea_user_id)}
                      dea_id={parseInt(dea_id)}
                    />
                  </TabPane>
              </Tabs>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
