import React, { useState, useEffect } from "react"
import { Row, Col, Container, Card, CardBody } from "reactstrap"
import MetaTags from "react-meta-tags"
import Language from './Language'
import { useTranslation } from "react-i18next"

const Setting = props => {
  const { t, i18n } = useTranslation();

  return (
    <React.Fragment>
      <MetaTags>
        <title>{t("setting")}  | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>{t("setting")} </h3>
          <Card>
            <CardBody>
            <Language />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Setting
