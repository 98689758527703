import React, { useState, useEffect } from "react"
import { Form, Button, Modal, notification } from "antd"
import { Row, Col } from "reactstrap"
import Input_Comp from "./Components/Input_Comp"
import Input_Date_Comp from "./Components/Input_Date_Comp"
import Select_no_search from "./Components/Select_no_search"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import * as api from "../../../../service/API_New_Contract"
import axios from "axios"
import { useHistory } from "react-router-dom"
import helpers from "../../../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next";

const { confirm } = Modal

export default function Create_new_contract(props) {
  const { t } = useTranslation();
  const token = localStorage.getItem("token")
  const history = useHistory()
  const [data_new_contract, setData_new_contract] = useState({})
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setData_new_contract({
      ...data_new_contract,
      cus_id: props.cus_id,
    })
    return () => {}
  }, [props.cus_id])

  const onFinish = values => {
    return confirm({
      title: t("Create new contract"),
      icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
      okText: t("Create"),
      cancelText: t("no"),
      centered: true,
      onOk() {
        setLoading(true)
        const new_contract = api.CREATE_NEW_CONTRACT(token, data_new_contract)
        axios(new_contract)
          .then(res => {
            setLoading(false)
            props.search_data_contract()
            openNotificationWithIcon("success", t("Create new contract success"))
            cancelForm()
          })
          .catch(error => {
            setLoading(false)
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            } else {
              openNotificationWithIcon(
                "error",
                t("Create new contract error please try again")
              )
            }
          })
      },
      onCancel() {},
    })
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  function cancelForm() {
    setData_new_contract({})
    form.resetFields()
    props.setActiveTab_contract("1")
  }

  return (
    <Form
      form={form}
      name="create_new_contract_data_info"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
    >
      <Row>
        <Col sm={12} md={6} lg={6} xl={6}>
          <Input_Comp
            header={t("Contract name")}
            placeholder={t("Contract name")}
            headState={setData_new_contract}
            nameState={data_new_contract}
            type="text"
            keyState="con_name"
          />
        </Col>
        <Col sm={12} md={6} lg={6} xl={6}>
          <Input_Date_Comp
            header={t("Date start")}
            placeholder={t("Date start")}
            headState={setData_new_contract}
            nameState={data_new_contract}
            keyState="con_start_date"
          />
        </Col>
        <Col sm={12} md={6} lg={6} xl={6}>
          <Input_Date_Comp
            header={t("Date end")}
            placeholder={t("Date end")}
            headState={setData_new_contract}
            nameState={data_new_contract}
            keyState="con_end_date"
          />
        </Col>
        <Col sm={12} md={6} lg={6} xl={6}>
          <Input_Comp
            header={t("Contact firstname")}
            placeholder={t("Contact firstname")}
            headState={setData_new_contract}
            nameState={data_new_contract}
            keyState="con_contact_firstname"
            type="text"
          />
        </Col>
        <Col sm={12} md={6} lg={6} xl={6}>
          <Input_Comp
            header={t("Contact lastname")}
            placeholder={t("Contact lastname")}
            headState={setData_new_contract}
            nameState={data_new_contract}
            keyState="con_contact_lastname"
            type="text"
          />
        </Col>
        <Col sm={12} md={6} lg={6} xl={6}>
          <Select_no_search
            header={t("Contact position")}
            placeholder={t("Contact position")}
            name_api="LIST_POSITION"
            map_name="pos_name"
            map_value="pos_id"
            keyState="con_contact_position_id"
            headState={setData_new_contract}
            nameState={data_new_contract}
          />
        </Col>
        <Col sm={12} md={6} lg={6} xl={6}>
          <Input_Comp
            header={t("Contact mobile")}
            placeholder={t("Contact mobile")}
            headState={setData_new_contract}
            nameState={data_new_contract}
            keyState="con_contact_mobile"
            type="number"
          />
        </Col>
        <Col sm={12} md={6} lg={6} xl={6}>
          <Input_Comp
            header={t("Contact email")}
            placeholder={t("Contact email")}
            headState={setData_new_contract}
            nameState={data_new_contract}
            keyState="con_contact_email"
            type="email"
          />
        </Col>
      </Row>

      <br />
      <Row>
        <Col sm={12} md={6} lg={6} xl={6}>
          <div className="div_create_new_car">
            <Button
              className="btn_cancel_create_new_car"
              onClick={() => cancelForm()}
            >
            {t("Cancel")}
        
            </Button>
          </div>
        </Col>
        <Col sm={12} md={6} lg={6} xl={6}>
          <div className="div_create_new_car">
            <Button
              className="btn_submit_create_new_car"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
            {t("Create")}
              {" "}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}
