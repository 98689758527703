var Ip = require('../service/Ip')

export const LIST_DEPARTMENT = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/department`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CHECK_EMAIL_EMPLOYEE_DUPLICATE = (token, email) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/employee/check-email-dup/${email}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_POSITION = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/position`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_EMPLOYEE_GROUP = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/employee/group`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

export const LIST_PERMISSION_ROLE = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/employee/role-permission`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_ROLE_DETAIL = (token,id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/employee/list-role-permission/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CREATE_EMPLOYEE = (token,data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/employee`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const ADMIN_ROOT_CREATE_EMPLOYEE = (token,data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/admin/employee`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const UPLOAD_PROFILE = (token, formData) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}storage/upload`,
      headers: {
        "Content-type": "multipart/form-data",
        'x-access-token' : token
      },
      data : formData
    };
  };

  export const CREATE_NEW_ROLE_PERMISSION = (token, data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/employee/create-role-permission`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const FIND_ALL_ADMIN_GROUP = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/admin/group`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };