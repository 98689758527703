import React,{ useEffect, useState } from 'react'
import { Card, Avatar , Badge } from 'antd';
import * as api from '../../../../service/Api_Employee'
import { FaRegUser } from "react-icons/fa"
import axios from 'axios'


const { Meta } = Card;

export default function Render_Card(props) {
  const [img, setImg] = useState(null)
  const token =localStorage.getItem('token')

  useEffect(() => {
    
    return () => {
      setImg(null)
    }
  }, [])

  useEffect(() => {
      if(props.avatar !== null && props.avatar !== undefined && props.avatar !== "None" ){
          get_image(props.avatar)
      } else {
        setImg(null)
      }
      return () => {
        
      }
  }, [props.avatar])

  async function get_image(avatar) {
    const get_data_image = api.GET_IMAGE(token, avatar)
    await axios(get_data_image)
    .then( res => {
        const blob = new Blob([res.data], {
            type: 'image/jpeg',
        });
        var objectURL = URL.createObjectURL(blob)
        setImg(objectURL)
    })
    .catch( error => {
        // console.log(error.response);
    })
  }
  

    return (
        <Card style={{ width: '100%', marginTop: 16 }} >
        {
          img !== null && (
            <Meta
            avatar={
              <Avatar src={img} />
            }
            title={<Badge color="green" text={props.firstname} /> }
            description={props.role}
          />
          )
        }
        {
          img === null && (
            <Meta
            avatar={
              <Avatar icon={<FaRegUser style={{ marginBottom:'10px' }} />} />
            }
            title={<Badge color="green" text={props.firstname} /> }
            description={props.role}
          />
          )
        }

      </Card>
    )
}
