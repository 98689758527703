import React, { useState } from 'react'
import { withRouter, useHistory  } from "react-router-dom"
import Layout from './components/HorizontalLayout'
import jwt_decode from "jwt-decode";
import {useDispatch } from "react-redux"
import { FIND_IS_ROLE } from './store/Distar/actionTypes';

const Auth = ({children}) => {
    const history = useHistory()
    const userData = localStorage.getItem('token')
    // const [userData, setUserData] = useState(localStorage.getItem('token'))
    if(!userData) {
        history.push('/login')
    }

    if(userData === undefined) {
        return <p>Loading . . .</p>
    } else {
        // var decodedToken = jwt_decode(userData);
        // console.log(decodedToken.role);
        // action(FIND_IS_ROLE, "test")
        return <Layout>{children}</Layout>
    }

}
export default withRouter(Auth)