import React, { useEffect, useState } from "react"
import { Container, Card, CardBody } from "reactstrap"
import { useHistory, useParams } from "react-router-dom"
import MetaTags from "react-meta-tags"
import { MdKeyboardBackspace } from "react-icons/md"
import * as api from "../../../service/Api_Edit_Service"
import axios from "axios"
import { Tabs } from "antd"
import Detail_Service from './Detail_Service/index'
import Task from "./Task"
import Setting from "./Setting"
import helpers from '../../Function_Redirect/Redirect_login'
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs

export default function Service_New(props) {
  const { t } = useTranslation();
  const history = useHistory()
  const [activeTab, setActiveTab] = useState("1")
  const [data_service, setData_service] = useState(null)
  const [data_location, setData_location] = useState({})
  const token = localStorage.getItem("token")
  const [manager, setManager] = useState([])
  const [admin, setAdmin] = useState([])
  const [operator, setOperator] = useState([])
  const [service_overview, setService_overview] = useState({})
  const [count_task_status, setCount_task_status] = useState({})
  const [manager_check, setManager_check] = useState(0)
  const [admin_check, setAdmin_check] = useState(0)
  const [operator_check, setOperator_check] = useState(0)
  const [activity_all, setActivity_all] = useState([])
  const [task_status_check, setTask_status_check] = useState(0)
  const [service_status_name, setService_status_name] = useState(null)
  const [fileUpload, setFileUpload] = useState([])

  let { ser_id } = useParams()

  useEffect(() => {

    return () => {
      setFileUpload([])
      setData_service({})
      setManager([])
      setAdmin([])
      setOperator([])
      setService_overview({})
      setCount_task_status({})
      setManager_check(0)
      setAdmin_check(0)
      setOperator_check(0)
      setActivity_all([])
      setTask_status_check(0)
      setService_status_name(null)
      setData_location({})
    }
  }, [])

  useEffect(() => {
    check_new_data_edit()
    list_service_overview()
    check_activity_log()
    check_new_data_edit_location()
    return () => {
      setData_service({})
    }
  }, [ser_id])

  function check_new_data_edit_location() {
    const new_data = api.GET_DETAIL_SERVICE(token, parseInt(ser_id))
    axios(new_data)
      .then(res => {
        if (res.data.data[0] === undefined) {
          history.push("/dashboards")
        } else {
          setData_location(res.data.data[0])
          setFileUpload(res.data.data[0].fileupload)
        }
      })
      .catch(error => {

      })
  }

  function check_new_data_edit() {
    const new_data = api.GET_DETAIL_SERVICE(token, parseInt(ser_id))
    axios(new_data)
      .then(res => {
        if(res.data.data[0] === undefined){
          history.push("/dashboards")
        } else {
          setData_service(res.data.data[0])
          setService_status_name(res.data.data[0].ser_status_name)
        }
      })
      .catch(error => {
        // console.log(error.response)
      })
  }

  function list_service_overview() {
    const service_overview = api.SERVICE_OVERVIEW(token, parseInt(ser_id))
    axios(service_overview).then(res => {
      setService_overview(res.data)
      setCount_task_status(res.data.service.count_task_status)
      setAdmin_check(res.data.admin.length)
      setManager_check(res.data.manager.length)
      setOperator_check(res.data.operator.length)
      setTask_status_check(
        res.data.service.count_task_status.assigned +
          res.data.service.count_task_status.cancle +
          res.data.service.count_task_status.complete +
          res.data.service.count_task_status.inprogress +
          res.data.service.count_task_status.pending +
          res.data.service.count_task_status.resolved
      )
    })
  }

  useEffect(() => {
    let id = setInterval(() => {
      check_activity_log()
      check_new_data_edit()
    }, 10000)
    return () => {
      clearInterval(id)
    }
  })

  function check_activity_log() {
    const check_activity = api.LIST_ACTIVITY_LOG(token, parseInt(ser_id))
    axios(check_activity)
      .then(res => {
        setActivity_all(res.data.data)
      })
      .catch(error => {
        if(error){
          if(error.response){
            if(error.response.status === 401){
              helpers.re_login()
              history.push('/login')          
              }
          }
        }
      })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Service")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/dashboards")}
              />
            </a>{" "}
            {t("Service")}
          </h3>
          
          <Card>
          <CardBody>
          <Tabs  defaultActiveKey="1"
          onTabClick={e => {
            setActiveTab(e)
          }}
          activeKey={activeTab}>
            <TabPane tab={t("Overview")} key="1">
{
  data_service !== null && (
<Detail_Service
              fileUpload={fileUpload}
              setActiveTab={setActiveTab}
                data_location={data_location}
                ser_id={parseInt(ser_id)}
                count_task_status={count_task_status}
                data_service={data_service}
                service_overview={service_overview}
                task_status_check={task_status_check}
                manager_check={manager_check}
                admin_check={admin_check}
                operator_check={operator_check}
                check_new_data_edit={check_new_data_edit}
                list_service_overview={list_service_overview}
                activity_all={activity_all}
                check_activity_log={check_activity_log}
                service_status_name={service_status_name}
              />
  )
}
              
            </TabPane>

            {
              service_status_name === "inprogress" &&
                <TabPane tab={t("Task")} key="2">
                  <Task
                    ser_id={parseInt(ser_id)}
                    setActiveTab={setActiveTab}
                    check_new_data_edit={check_new_data_edit}
                    check_activity_log={check_activity_log}
                    list_service_overview={list_service_overview}
                  />
                </TabPane>
              
            }

            {/*<TabPane tab="Setting" key="3">
              <Setting ser_id={parseInt(ser_id)} />
          </TabPane>*/}
          </Tabs>
          </CardBody>
          </Card>

        </Container>
      </div>
    </React.Fragment>
  )
}
