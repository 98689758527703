import React from "react"
import { Row, Col } from "reactstrap"
import { Form, Input, Button } from "antd"
import { useTranslation } from "react-i18next";

export default function Search_user_all(props) {
  const { t } = useTranslation();

  function search_user(value) {
    props.search_data_user_table()
  }

  return (
      <Form
        name="Search_data_uses_info_table"
        initialValues={{
          remember: true,
        }}
        onFinish={search_user}
      >
        <Row>
          <Col sm={12} md={6} lg={6} xl={4}>
            <Form.Item
              name="search"
              rules={[
                {
                  required: false,
                  message: "Please input search",
                },
              ]}
            >
              <Input
                placeholder={`${t("Firstname")} , ${t("Lastname")}`}
                style={{ marginBottom: "15px" }}
                onChange={e => props.setSearch_data_user(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={6} lg={4} xl={4}>
            <Button
              type="primary"
              className="button-search-employee"
              htmlType="submit"
              // style={{
              //   backgroundColor: "#0084d1",
              //   borderColor: "#0084d1",
              // }}
            >
              {t("Search")}
            </Button>
          </Col>
        </Row>
      </Form>
  )
}
