import {
  ADD_PRIVATE_ID,
  ADD_NAME_USER,
  ADD_IMAGE_AVATAR,
  IS_ADMIN_ROOT,
  FIND_IS_ROLE,
  EMAIL_USER_CUSTOMER,
  PERMISSION_ALL_SERVICE,
  _CUSTOMER_USER_ID,
  _DEALER_USER_ID,
  _EMP_ID
} from "./actionTypes"

const INIT_STATE = {
  privateID: null,
  nameUser: null,
  avatarUser: null,
  isAdminRoot: false,
  isRole: null,
  emailUserCUstomer : null,
  customer_user_id : null,
  dealer_user_id : null,
  emp_user_id : null,
  permissionAllService : {}
}

export default (state = INIT_STATE, { type , payload }) => {
  switch (type) {
    case _EMP_ID:
      return {
        ...state,
        emp_user_id: payload,
      }
    case _DEALER_USER_ID:
      return {
        ...state,
        dealer_user_id: payload,
      }
    case _CUSTOMER_USER_ID:
      return {
        ...state,
        customer_user_id: payload,
      }
    case PERMISSION_ALL_SERVICE:
      return {
        ...state,
        permissionAllService: payload,
      }
    case EMAIL_USER_CUSTOMER:
      return {
        ...state,
        emailUserCUstomer: payload,
      }
    case FIND_IS_ROLE:
      return {
        ...state,
        isRole: payload,
      }
    case ADD_PRIVATE_ID:
      return {
        ...state,
        privateID: payload,
      }
      case ADD_NAME_USER:
      return {
        ...state,
        nameUser: payload,
      }
      case ADD_IMAGE_AVATAR:
      return {
        ...state,
        avatarUser: payload,
      }
      case IS_ADMIN_ROOT:
        return {
          ...state,
          isAdminRoot: payload,
        }
    default:
      return state
  }
}
