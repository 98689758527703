import React, { useEffect, useState } from "react"
import { Container, Card, CardBody } from "reactstrap"
import { useHistory, useParams } from "react-router-dom"
import { MdKeyboardBackspace } from "react-icons/md"
import * as api from "../../service/Api_Edit_Service_IN_Contract"
import { Tabs } from "antd"
import axios from "axios"
import Detail_Service from "./Detail_Service"
import Edit_Service from "./Edit_Service"
import Setting from './Setting'

const { TabPane } = Tabs

export default function Edit_Service_IN_Contract(props) {
  const token = localStorage.getItem("token")
  const history = useHistory()
  const [data_service, setData_service] = useState({})
  let { profile_id, con_id, ser_id } = useParams()
  // profile_id = customer
  // con_id = contract
  // ser_id = service id

  // console.log('customer = '+profile_id);
  // console.log('contract = '+con_id);
  // console.log('service id = '+ser_id);

  useEffect(() => {
    check_detail_service()
    return () => {
      setData_service({})
    }
  }, [ser_id])

  function check_detail_service() {
    const check_service_info = api.GET_INFO_SERVICE(token, ser_id)
    axios(check_service_info)
      .then(res => {
        if (res.data.data[0] === undefined) {
          history.push(`/customers/${profile_id}/edit-contract/${con_id}`)
        } else {
          setData_service(res.data.data[0])
        }
      })
      .catch(error => {
        console.log(error.response)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() =>
                  history.push({
                    pathname: `/customers/${profile_id}/edit-contract/${con_id}`,
                    // data: props.location.data.cus_id,
                    page_local: "3",
                  })
                }
              />
            </a>{" "}
            Edit service
          </h3>
          <Card>
            <CardBody>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Overview" key="1">
                  <Detail_Service data_service={data_service} />
                </TabPane>
                <TabPane tab="Edit" key="2">
                  <Edit_Service
                    data_service={data_service}
                    con_id={con_id}
                    cus_id={parseInt(profile_id)}
                    ser_id={parseInt(ser_id)}
                    check_detail_service={check_detail_service}
                  />
                </TabPane>
                <TabPane tab="Setting" key="3">
                    <Setting 
                      con_id={con_id}
                      cus_id={parseInt(profile_id)}
                      ser_id={parseInt(ser_id)}
                    />
                </TabPane>
              </Tabs>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
