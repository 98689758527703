import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { Button, Input, Form } from "antd"
import { useHistory } from "react-router-dom"
import { BiCalculator } from "@react-icons/all-files/bi/BiCalculator"
import { CarOutlined, SearchOutlined } from "@ant-design/icons"
import Filter_Stock from "./Filter_Stock"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

export default function Search_car(props) {
  const history = useHistory()
  const { t } = useTranslation()
  const permi = localStorage.getItem("permi")
  const [permi_t, setPermi_t] = useState(false)
  const { permissionAllService } = useSelector(({ Distar }) => Distar)
  useEffect(() => {
    if (permissionAllService) {
      if (permissionAllService.stock) {
        setPermi_t(permissionAllService.stock.write)
      }
    }
    return () => {}
  }, [permissionAllService])

  const onFinish = values => {
    props.search_data()
  }

  return (
    <div>
      <Form
        name="Search_car"
        initialValues={{
          remember: false,
        }}
        onFinish={onFinish}
      >
        <Row>
          <Col sm={12} md={6} lg={6} xl={4}>
            <Form.Item
              name="search"
              rules={[
                {
                  required: false,
                  message: "Please input name,department",
                },
              ]}
            >
              <Input
                placeholder={`${t("Name")} , ${t("Stock serial number")}`}
                style={{ marginBottom: "15px" }}
                onChange={e => props.setSearch_stock(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={6} lg={4} xl={4}>
            <Button
              type="primary"
              className="button-search-employee"
              htmlType="submit"
            >
              {t("Search")}
            </Button>
            {
              permi_t === true && (
                <Button
                className="button-search-New-Employee"
                onClick={() => history.push("/new-stock")}
                icon={
                  <BiCalculator
                    style={{
                      fontSize: "15px",
                      marginRight: "10px",
                      marginBottom: "5px",
                    }}
                  />
                }
              >
                {t("New stock")}
              </Button>
              )
            }
          </Col>
        </Row>
      </Form>

      <Filter_Stock
        filter_stock={props.filter_stock}
        setFilter_stock={props.setFilter_stock}
      />
      <br />
    </div>
  )
}
