import React, { useState, useEffect } from "react"
import { Row, Col, Container, Card, CardBody } from "reactstrap"
import Table from "./Table"
import MetaTags from "react-meta-tags"
import Search from "./Search"
import { FcDataSheet } from "react-icons/fc"
import { Pagination } from "antd"
import axios from "axios"
import * as api from "../../service/Api_Employee"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import helpers from "../Function_Redirect/Redirect_login"

import { useSelector } from "react-redux"

const Employee = props => {
  const { t } = useTranslation()
  const [search_data_user, setSearch_data_user] = useState(null)
  const [search_department_id, setSearch_department_id] = useState(null)
  const [search_group_id, setSearch_group_id] = useState(null)
  const token = localStorage.getItem("token")
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })
  const [data_list, setData_list] = useState([])
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const  { isAdminRoot } = useSelector(({ Distar }) => Distar)
  // console.log(isAdminRoot);

  useEffect(() => {
    search_data()
    return () => {}
  }, [search_department_id, search_group_id])

  useEffect(() => {
    search_data()
    return () => {
      setData_list([])
      setSearch_department_id(null)
    }
  }, [])

  const check_data = (params, data) => {
    if (data) {
      return params + data
    } else {
      return ""
    }
  }

  function search_data() {
    setLoading(true)
    let url = `api/v1/employee?page=1&limit=${
      page_limit.page_Size
    }&order_by=asc${check_data("&q=", search_data_user)}${check_data(
      "&department_id=",
      search_department_id
    )}${check_data("&group_id=", search_group_id)}`
    const search = api.SEARCH_ALL_EMPLOYEE(token, url)
    axios(search)
      .then(res => {
        setLoading(false)
        setPage_limit({
          ...page_limit,
          page: 1,
          limit: res.data.total_data,
        })
        // console.log(res.data.data)
        setData_list(res.data.data)
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  function changePagination(page, pageSize) {
    setLoading(true)
    let url = `api/v1/employee?page=${page}&limit=${pageSize}&order_by=asc${check_data(
      "&q=",
      search_data_user
    )}${check_data("&department_id=", search_department_id)}${check_data(
      "&group_id=",
      search_group_id
    )}`
    const search = api.SEARCH_ALL_EMPLOYEE(token, url)
    axios(search)
      .then(res => {
        setLoading(false)
        setPage_limit({
          ...page_limit,
          page: page,
          page_Size: pageSize,
          limit: res.data.total_data,
        })
        // console.log(res.data.data)
        setData_list(res.data.data)
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
        // console.log(error)
      })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("employee")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}> {t("employee")} </h3>
          <Card>
            <CardBody>
              <Search
                search_group_id={search_group_id}
                setSearch_group_id={setSearch_group_id}
                setSearch_department_id={setSearch_department_id}
                search_department_id={search_department_id}
                setSearch_data_user={setSearch_data_user}
                search_data={search_data}
              />
              <br />
              <Row>
                <Table
                  loading={loading}
                  data_list={data_list}
                  search_data={search_data}
                />
              </Row>
              <Row>
                <div>
                  <Pagination
                    current={page_limit.page}
                    total={page_limit.limit}
                    onChange={changePagination}
                    style={{ marginTop: "20px", float: "right" }}
                  />
                  <p style={{ fontSize: "15px", marginTop: "20px" }}>
                    {" "}
                    <FcDataSheet
                      size={20}
                      style={{ marginBottom: "5px" }}
                    />{" "}
                    {t("total data")} : {page_limit.limit}
                  </p>
                </div>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Employee
