import React, { useState, useEffect } from "react"
import { Button, Modal, Form, Input, Table, Pagination } from "antd"
import { Row, Col } from "reactstrap"
import * as api from "../../../service/Api_Customer"
import { useTranslation } from "react-i18next"
import { EditOutlined } from "@ant-design/icons"
import axios from "axios"
import { FcDataSheet } from "react-icons/fc"
import { useHistory } from "react-router-dom";
import helpers from '../../Function_Redirect/Redirect_login'

export default function Select_Filter_Customer({
  headState,
  nameState,
  keyState,
}) {
  const { t } = useTranslation()
  const history = useHistory()
  const token = localStorage.getItem("token")
  const [search_customer, setSearch_customer] = useState(null)
  const [name_customer, setName_customer] = useState(null)
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data_list, setData_list] = useState([])
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })

  useEffect(() => {
    search_data()
    setImageSize()
    return () => {
      setData_list([])
    }
  }, [])

  const search = values => {
    search_data()
  }

  const check_data = (params, data) => {
    if (data) {
      return params + data
    } else {
      return ""
    }
  }

  const setImageSize = () => {
    if (window.innerWidth < 768) {
      return "small"
    } else {
      return null
    }
  }

  function search_data() {
    setLoading(true)
    let url = `api/v1/customer?page=1&limit=${
      page_limit.page_Size
    }&order_by=asc${check_data("&q=", search_customer)}`
    const search = api.SEARCH_ALL(token, url)
    axios(search)
      .then(res => {
        setLoading(false)
        setPage_limit({
          ...page_limit,
          page: 1,
          limit: res.data.total_data,
        })
        setData_list(res.data.data)
      })
      .catch(error => {
        setLoading(false)
        if(error){
          if(error.response){
            if(error.response.status === 401){
              helpers.re_login()
              history.push('/login')          
              }
          }
        }
        // console.log(error)
      })
  }

  function changePagination(page, pageSize) {
    setLoading(true)
    let url = `api/v1/customer?page=${page}&limit=${pageSize}&order_by=asc${check_data(
      "&q=",
      search_customer
    )}`
    const search = api.SEARCH_ALL(token, url)
    axios(search)
      .then(res => {
        setLoading(false)
        setPage_limit({
          ...page_limit,
          page: page,
          page_Size: pageSize,
          limit: res.data.total_data,
        })
        setData_list(res.data.data)
      })
      .catch(error => {
        setLoading(false)
        if(error){
          if(error.response){
            if(error.response.status === 401){
              helpers.re_login()
              history.push('/login')          
              }
          }
        }
        // console.log(error)
      })
  }

  const columns = [
    {
      title: t("Customer name"),
      dataIndex: "cus_name",
      key: "cus_name",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Customer TAX ID"),
      dataIndex: "cus_taxid",
      key: "cus_taxid",
      render: text => <p>{text}</p>,
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setName_customer(selectedRows[0].cus_name)
      headState({ ...nameState, [keyState]: selectedRows[0].cus_id })
      setVisible(false)
    },
    getCheckboxProps: record => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  }

  return (
    <div>
      <p className="text-bold header-filter"> {t("Search customer")} </p>
      {name_customer === null && nameState[keyState] === null && (
        <div>
          <Button onClick={() => setVisible(true)} className="btn-select-customer" > {t("Select customer")} </Button>
        </div>
      )}

      {name_customer !== null && nameState[keyState] !== null && (
        <Row>
          <Col sm={12} md={12} lg={12} xl={12}>
            <div style={{ display: "flex" }}>
              <p className="text-bold " style={{ marginTop: "7px" }}>
              {t("Customer")}
              </p>
              <Input
                style={{ marginLeft: "10px", height: "35px" }}
                value={name_customer}
                placeholder={name_customer}
                disabled
              />
              <a>
                <EditOutlined
                  style={{ fontSize: "20px", marginLeft: "10px" }}
                  onClick={() => {
                    headState({ ...nameState, [keyState]: null })
                    setName_customer(null)
                  }}
                />
              </a>
            </div>
          </Col>
        </Row>
      )}

      <Modal
        centered
        open={visible}
        footer={false}
        width={1000}
        onOk={() => {
          setVisible(false)
        }}
        onCancel={() => {
          setVisible(false)
        }}
      >
        <div
          style={{ height: "500px", overflowY: "scroll", overflowX: "hidden" }}
        >
        <p className="text-bold" > {t("Select customer")} </p>
          <Form name="Search_customer_info" onFinish={search}>
            <Row>
              <Col sm={12} md={9} lg={9} xl={9}>
                <Form.Item
                  name="search"
                  rules={[
                    {
                      required: false,
                      message: "Please input name customer",
                    },
                  ]}
                >
                  <Input
                    placeholder={t("Search")}
                    style={{ marginBottom: "15px", width: "100%" }}
                    onChange={e => setSearch_customer(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col sm={12} md={3} lg={3} xl={3}>
                <Button
                  type="primary"
                  className="button-search-employee"
                  htmlType="submit"
                >
                  {t("search")}
                </Button>
              </Col>
            </Row>
          </Form>
          <Table
            rowSelection={{
              type: "radio",
              ...rowSelection,
            }}
            loading={loading}
            pagination={false}
            scroll={{ x: 400 }}
            rowKey="cus_id"
            columns={columns}
            dataSource={data_list}
          />
          <div>
            <Pagination
              size={setImageSize()}
              current={page_limit.page}
              total={page_limit.limit}
              onChange={changePagination}
              style={{ marginTop: "20px", float: "right" }}
            />
          </div>
          <p style={{ fontSize: "15px", marginTop: "20px" }}>
          {" "}
          <FcDataSheet size={20} style={{ marginBottom: "5px" }} /> {t("total data")} :{" "}
          {page_limit.limit}
        </p>
        </div>
      </Modal>
    </div>
  )
}
