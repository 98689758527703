import React, { useState, useEffect } from "react"
import {
  Input,
  Pagination,
  Table,
  Tooltip,
  Modal,
  Button,
  notification,
} from "antd"
import { useTranslation } from "react-i18next"
import * as api from "../../../../service/Api_New_Service"
import axios from "axios"
import Render_Image from "../../../New_Customer/Devices/Render_Image"
import { Row, Col } from "reactstrap"
import { FcDataSheet } from "react-icons/fc"
import { useHistory } from "react-router-dom"

import car from "../../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/car.png"
import ambulance from "../../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/ambulance.png"
import vespa from "../../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/vespa.png"
import bus from "../../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/bus.png"
import bicycle from "../../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/bicycle.png"
import van from "../../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/van.png"
import truck from "../../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/truck.png"
import taxi from "../../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/taxi.png"
import pickup from "../../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/pickup.png"
import motorboat from "../../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/motorboat.png"
import motor from "../../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/motor.png"
import no_car from "../../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_car.png"

const { Search } = Input

export default function SelectCarBtn({ selectCar, setSelectCar }) {
  const { t } = useTranslation()
  const history = useHistory()
  const [visible, setVisible] = useState(false)
  const token = localStorage.getItem("token")
  const [data_stock_search, setData_stock_search] = useState(null)
  const [loading_my_db, setLoading_my_db] = useState(false)
  const [stock_table_my_db, setStock_table_my_db] = useState([])
  const [page_limit_my_db, setPage_limit_my_db] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })

  useEffect(() => {
    searchCar()

    return () => {}
  }, [])

  function render_Icon_Car(type_car) {
    switch (type_car) {
      case "car":
        return car
        break
      case "vespa":
        return vespa
        break
      case "van":
        return van
        break
      case "truck":
        return truck
        break
      case "taxi":
        return taxi
        break
      case "bus":
        return bus
        break
      case "pickup":
        return pickup
        break
      case "motorboat":
        return motorboat
        break
      case "motor":
        return motor
        break
      case "bicycle":
        return bicycle
        break
      case "ambulance":
        return ambulance
        break
      default:
        return no_car
        break
    }
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  const columns_my_db = [
    {
      title: "",
      dataIndex: "pro_model_img",
      key: "pro_model_img",
      render: text => <Render_Image path_image={text} size={60} />,
    },
    {
      title: t("Product model code"),
      dataIndex: "pro_model_code",
      key: "pro_model_code",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Product model name"),
      dataIndex: "pro_model_name",
      key: "pro_model_name",
      render: text => <p>{text}</p>,
      width: "150px",
    },
    {
      title: t("Product serial number"),
      dataIndex: "pro_serial_number",
      key: "pro_serial_number",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Car license number"),
      dataIndex: "car_license_number",
      key: "car_license_number",
      render: text => <p>{text}</p>,
      width: "150px",
    },
    {
      title: t("Car type"),
      dataIndex: "car_body_type_name",
      key: "car_body_type_name",
      render: text => (
        <Tooltip title={text ? text : t("Invalid car type")}>
          <img
            src={render_Icon_Car(text)}
            alt="icon"
            style={{ width: "50px" }}
          />
        </Tooltip>
      ),
    },
    {
      title: t("Customer name"),
      dataIndex: "cus_name",
      key: "cus_name",
      render: text => <p>{text}</p>,
    },
    {
      fixed: "right",
      render: data => (
        <Button type="link" onClick={() => AddCar(data)}>
          {t("add car")}
        </Button>
      ),
    },
  ]

  function AddCar(data) {
    const dataSelectedCar = [...selectCar]
    var indexID = dataSelectedCar.findIndex(id => id.sto_id === data.sto_id)
    if (indexID === -1) {
      dataSelectedCar.push(data)
      openNotificationWithIcon(
        "success",
        t("add") + " " + data.car_license_number + " " + t("success")
      )
    } else {
      openNotificationWithIcon(
        "warning",
        data.car_license_number + " " + t("duplicate")
      )
    }
    setSelectCar(dataSelectedCar)
  }

  function onCloseModal() {
    setVisible(false)
  }

  const check_data = (params, data) => {
    if (data) {
      return params + data
    } else {
      return ""
    }
  }

  function searchCar() {
    setLoading_my_db(true)
    const url = `api/v1/service/db/find_all_stock?page=1&limit=${
      page_limit_my_db.page_Size
    }&order_by=DESC${check_data("&q=", data_stock_search)}`
    const search = api.SEARCH_DYNAMIC(token, url)
    axios(search)
      .then(res => {
        setLoading_my_db(false)
        setStock_table_my_db(res.data.db.data)
        setPage_limit_my_db({
          ...page_limit_my_db,
          page: 1,
          limit: res.data.db.total_data,
        })
      })
      .catch(error => {
        setLoading_my_db(false)
      })
  }

  function changePagination_my_db(page, pageSize) {
    setLoading_my_db(true)
    const url = `api/v1/service/db/find_all_stock?page=${page}&limit=${pageSize}&order_by=DESC${check_data(
      "&q=",
      data_stock_search
    )}`
    const search = api.SEARCH_DYNAMIC(token, url)
    axios(search)
      .then(res => {
        setLoading_my_db(false)
        setStock_table_my_db(res.data.db.data)
        setPage_limit_my_db({
          ...page_limit_my_db,
          page: page,
          page_Size: pageSize,
          limit: res.data.db.total_data,
        })
      })
      .catch(error => {
        setLoading_my_db(false)
      })
  }

  return (
    <div>
      <Button type="primary" onClick={() => setVisible(true)}>
        {t("Select car")}
      </Button>
      <Modal
        title={false}
        centered
        open={visible}
        onOk={() => onCloseModal()}
        onCancel={() => onCloseModal()}
        footer={false}
        height={500}
        width={1000}
      >
        <br /> <br />
        <div
          style={{ overflowY: "scroll", height: "500px", overflowX: "hidden" }}
        >
          <Row>
            <Col sm={12} md={12} lg={10} xl={8}>
              <Row>
                <Col>
                <p className="text-bold">
                    {t("Select cars added in webservice")}
                    <span style={{ color: "red" }}>*</span>
                  </p>
                  <p>
                    {t(
                      "All cars shown in table, are already added in webservice either or"
                    )}
                    <Button
                      type="link"
                      onClick={() => history.push("/new-service")}
                    >
                      {" "}
                      {t("Click Here")}
                    </Button>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={7} md={7} lg={7} xl={7}>
                  <Search
                    style={{ width: "100%" }}
                    placeholder={`${t("Car license number")} , ${t(
                      "Customer name"
                    )}`}
                    enterButton={t("Search")}
                    size="medium"
                    onSearch={searchCar}
                    onChange={e =>
                      setData_stock_search(
                        e.target.value ? e.target.value : null
                      )
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          <Table
            loading={loading_my_db}
            rowKey="sto_id"
            pagination={false}
            columns={columns_my_db}
            dataSource={stock_table_my_db}
            scroll={{ x: 800 }}
          />
          <div style={{ float: "right", marginTop: "10px" }}>
            <Pagination
              current={page_limit_my_db.page}
              total={page_limit_my_db.limit}
              onChange={changePagination_my_db}
              defaultPageSize="10"
            />
          </div>
          <p style={{ fontSize: "15px", marginTop: "15px" }}>
            {" "}
            <FcDataSheet size={20} style={{ marginBottom: "5px" }} />{" "}
            {t("total data")} : {page_limit_my_db.limit}
          </p>
        </div>
      </Modal>
    </div>
  )
}
