import React, { useEffect, useState } from "react"
import { Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next";

export default function Overview_customer_info(props) {
  const [customer_information, setCustomer_information] = useState([])
  const { t } = useTranslation();

  useEffect(() => {
    if (props.distar_customer !== null) {
      setCustomer_information([
        {
          head_data1: "gid",
          value_data1: check_data(props.distar_customer.gid),
          head_data2: "title",
          value_data2: check_data(props.distar_customer.title),
        },
        {
          head_data1: "pid",
          value_data1: check_data(props.distar_customer.pid),
          head_data2: "uid",
          value_data2: check_data(props.distar_customer.uid),
        },
        {
          head_data1: "remark",
          value_data1: check_data(props.distar_customer.remark),
          head_data2: "cid",
          value_data2: check_data(props.distar_customer.cid),
        },
      ])
    }
    return () => {
      setCustomer_information([])
    }
  }, [props.distar_customer])

  function check_data(data) {
    if (data) {
      return data
    } else {
      return null
    }
  }

  return (
    <div className="border-card-info" >
    <p className="text-header-information" >
        {t("Customer information")}
      </p>
      {customer_information.map((data, index) => {
        return (
          <Row key={index}>
            <Col sm={12} md={6} lg={2} xl={2}>
              <p className="text-bold">{data.head_data1}</p>
            </Col>
            <Col sm={12} md={6} lg={4} xl={4}>
              <p style={{ fontSize: "15px" }}>
                {" "}
                <span className="tet">
                  :
                </span> <span className="tetTwo">-</span> {data.value_data1}{" "}
              </p>
            </Col>
            <Col sm={12} md={6} lg={2} xl={2}>
              <p className="text-bold">{data.head_data2}</p>
            </Col>
            <Col sm={12} md={6} lg={4} xl={4}>
              <p style={{ fontSize: "15px" }}>
                {" "}
                <span className="tet">
                  :
                </span> <span className="tetTwo">-</span> {data.value_data2}{" "}
              </p>
            </Col>
          </Row>
        )
      })}
    </div>
  )
}
