import React from "react"
import { Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next";

export default function Overview_Customer_And_Branch_Info(props) {
  const { t } = useTranslation();
  return (
    <div>
      <div className="border-card-info">
        <p className="text-header-information">
          {t("Customer and branch information")}
        </p>

        <Row>
          <Col sm={12} md={6} lg={2} xl={2}>
            <p className="text-bold"> {t("Customer name")} </p>
          </Col>
          <Col sm={12} md={6} lg={4} xl={4}>
            <p style={{ fontSize: "15px" }}>
              <span className="tet">:</span>
              <span className="tetTwo">-</span> {props.customer_data.cus_name}
            </p>
          </Col>

          <Col sm={12} md={6} lg={2} xl={2}>
            <p className="text-bold"> {t("Customer code")} </p>
          </Col>
          <Col sm={12} md={6} lg={4} xl={4}>
            <p style={{ fontSize: "15px" }}>
              <span className="tet">:</span>
              <span className="tetTwo">-</span> {props.customer_data.cus_code}
            </p>
          </Col>

          <Col sm={12} md={6} lg={2} xl={2}>
            <p className="text-bold"> {t("Branch name")} </p>
          </Col>
          <Col sm={12} md={6} lg={4} xl={4}>
            <p style={{ fontSize: "15px" }}>
              <span className="tet">:</span>
              <span className="tetTwo">-</span> {props.branch.cus_branch_name}
            </p>
          </Col>

          <Col sm={12} md={6} lg={2} xl={2}>
            <p className="text-bold"> {t("Branch code")} </p>
          </Col>
          <Col sm={12} md={6} lg={4} xl={4}>
            <p style={{ fontSize: "15px" }}>
              <span className="tet">:</span>
              <span className="tetTwo">-</span> {props.branch.cus_branch_code}
            </p>
          </Col>
        </Row>
      </div>
    </div>
  )
}
