import React from "react"
import { Row } from "reactstrap"
import { Collapse, Card } from "antd"
import Col_Device from "./components/Col_Device"
import branch_info from "../../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/branch_info.png"
import { useTranslation } from "react-i18next";

const { Panel } = Collapse

export default function Branch_Info(props) {
  const { t } = useTranslation();

  const icons_info_header = {
    width: "25px",
    marginRight: "20px",
  }

  return (
    <div>
      <Card
        title={
          <p>
            {" "}
            <img
              src={branch_info}
              alt="branch_info"
              style={icons_info_header}
            />{" "}
            {t("Branch")}
          </p>
        }
      >
        <Collapse>
          <Panel
            header={
              props.branch_data.cus_branch_name
            }
            key="1"
          >
            <Row>
              {props.branch_data.cus_branch_name && (
                  <Col_Device
                    text_header={t("Branch name")}
                    data={
                      props.branch_data.cus_branch_name
                    }
                  />
                )}

              {props.branch_data.cus_branch_code  && (
                  <Col_Device
                    text_header={t("Branch code")}
                    data={
                      props.branch_data.cus_branch_code
                    }
                  />
                )}
                
              {props.branch_data.cus_branch_phone && (
                  <Col_Device
                    text_header={t("Branch phone")}
                    data={
                      props.branch_data.cus_branch_phone
                    }
                  />
                )}

              {props.branch_data.cus_branch_mobile && (
                  <Col_Device
                    text_header={t("Branch mobile")}
                    data={
                      props.branch_data.cus_branch_mobile
                    }
                  />
                )}

              {props.branch_data.cus_branch_email && (
                  <Col_Device
                    text_header={t("Email")}
                    data={
                      props.branch_data.cus_branch_email
                    }
                  />
                )}

              {props.branch_data.cus_branch_fax && (
                  <Col_Device
                    text_header={t("fax")}
                    data={
                      props.branch_data.cus_branch_fax
                    }
                  />
                )}

              {props.branch_data.cus_branch_address && (
                  <Col_Device
                    text_header={t("Branch address")}
                    data={
                      props.branch_data.cus_branch_address
                    }
                  />
                )}

              {props.branch_data.cus_branch_contact_firstname && (
                  <Col_Device
                    text_header={t("Branch contact firstname")}
                    data={
                      props.branch_data.cus_branch_contact_firstname
                    }
                  />
                )}

              {props.branch_data.cus_branch_contact_lastname && (
                  <Col_Device
                    text_header={t("Branch contact lastname")}
                    data={
                      props.branch_data.cus_branch_contact_lastname
                    }
                  />
                )}

              {props.branch_data.cus_branch_contact_mobile && (
                  <Col_Device
                    text_header={t("Branch contact mobile")}
                    data={
                      props.branch_data.cus_branch_contact_mobile
                    }
                  />
                )}

              {props.branch_data.cus_branch_contact_email && (
                  <Col_Device
                    text_header={t("Branch contact email")}
                    data={
                      props.branch_data.cus_branch_contact_email
                    }
                  />
                )}
            </Row>
          </Panel>
        </Collapse>
      </Card>
    </div>
  )
}
