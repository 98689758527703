import React, { useState, useEffect, useMemo } from "react"
import PropTypes from "prop-types"

import { connect } from "react-redux"

import { Link } from "react-router-dom"

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import logo from "../../assets/Distar/logo.png"

//i18n
import { withTranslation } from "react-i18next"
import "./Header.css"

import { w3cwebsocket as W3CWebSocket } from "websocket"
import { AiOutlineMenu } from "react-icons/ai"

var Ip = require("../../service/Ip")

const Header = props => {
  const [menu, setMenu] = useState(false)
  const [isSearch, setSearch] = useState(false)
  const [socialDrp, setsocialDrp] = useState(false)
  const [noti_list, setNoti_list] = useState([])
  const [Count, setCount] = useState(0)
  const [count_notification, setCount_notification] = useState(0)
  const role = localStorage.getItem("role")
  const _id = localStorage.getItem("_id")

  useEffect(() => {
    if (role && Count === 0) {
      const client = new W3CWebSocket(
        `${Ip.getIP_WebSocket()}init_socket/${_id}/${role}`
        // `ws://202.183.221.91/init_socket/${_id}/${role}`
      )
      
      setCount(+1)
      client.onopen = () => {
        console.log("WebSocket Client Connected")
      }
      client.onmessage = message => {
        var dataDetail_test = JSON.parse(message.data)
        if(!dataDetail_test.system_notification){
          var dataNoti = JSON.parse(dataDetail_test)
         if(dataNoti.ser_id){
          setCount_notification(dataNoti.count)
         }
        }
      }
    }
    return () => {
   
    }
  }, [])

  // function toggleFullscreen() {
  //   if (
  //     !document.fullscreenElement &&
  //     /* alternative standard method */ !document.mozFullScreenElement &&
  //     !document.webkitFullscreenElement
  //   ) {
  //     // current working methods
  //     if (document.documentElement.requestFullscreen) {
  //       document.documentElement.requestFullscreen()
  //     } else if (document.documentElement.mozRequestFullScreen) {
  //       document.documentElement.mozRequestFullScreen()
  //     } else if (document.documentElement.webkitRequestFullscreen) {
  //       document.documentElement.webkitRequestFullscreen(
  //         Element.ALLOW_KEYBOARD_INPUT
  //       )
  //     }
  //   } else {
  //     if (document.cancelFullScreen) {
  //       document.cancelFullScreen()
  //     } else if (document.mozCancelFullScreen) {
  //       document.mozCancelFullScreen()
  //     } else if (document.webkitCancelFullScreen) {
  //       document.webkitCancelFullScreen()
  //     }
  //   }
  // }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box hide-menu-mobile">
              <Link to="/" className="logo logo-dark">
                <span className="logo-lg">
                  <img
                    src={logo}
                    alt="logo"
                    height="17"
                    style={{ width: "150px", height: "30px" }}
                  />
                </span>
              </Link>
            </div>

            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
              data-toggle="collapse"
              onClick={() => {
                props.toggleLeftmenu(!props.leftMenu)
              }}
              data-target="#topnav-menu-content"
            >
              <AiOutlineMenu />
            </button>
          </div>

          <div className="d-flex">
          {
            role !== "dealer_user" && (
              <NotificationDropdown
              count_notification={count_notification}
              setCount_notification={setCount_notification}
            />
            )
          }
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout
  return { layoutType, showRightSidebar, leftMenu }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header))
