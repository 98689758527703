import React, { useState, useEffect } from "react"
import { Row, Col, Container, Card, CardBody } from "reactstrap"
import MetaTags from "react-meta-tags"
import { useHistory } from "react-router-dom"
import axios from "axios"
import * as api from "../../service/Api_System_Settings"
import { MdKeyboardBackspace } from "react-icons/md"
import { Table } from "antd"
import Render_Image from "../New_Customer/Devices/Render_Image"
import helpers from '../Function_Redirect/Redirect_login'
import { useTranslation } from "react-i18next";

export default function File_upload(props) {
  const { t } = useTranslation();
  const history = useHistory()
  const token = localStorage.getItem("token")
  const [data_list, setData_list] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    check_data()
    return () => {}
  }, [])

  function check_data() {
    setLoading(true)
    const check_data_all = api.FIND_ALL_FILE_UPLOAD(token)
    axios(check_data_all)
      .then(res => {
        setData_list(res.data.data)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        if(error){
          if(error.response){
            if(error.response.status === 401){
              helpers.re_login()
              history.push('/login')          
              }
          }
        }

      })
  }


  const columns = [
    {
      title: t("Created on date"),
      dataIndex: "created_on",
      key: "created_on",
      render: text => <p>{cutTimeZone(text)}</p>,
    },
    {
      title: t("Created on time"),
      dataIndex: "created_on",
      key: "created_on",
      render: text => <p>{render_time(text)}</p>,
    },
    // {
    //   title: "created by",
    //   dataIndex: "created_by",
    //   key: "created_by",
    //   render: text => <p>{create_by(text)}</p>,
    // },
    {
      title: t("File upload type"),
      dataIndex: "fup_extension_type",
      key: "fup_extension_type",
      render: text => <p>{text}</p>,
    },
    {
      title: t("File upload name"),
      dataIndex: "fup_oldname",
      key: "fup_oldname",
      render: text => <p>{text}</p>,
    },
    {
      title: t("File upload name"),
      dataIndex: "fup_path",
      key: "fup_path",
      render: text => <p>{text}</p>,
      // render: text => <Render_Image path_image={text} size={60} />,
    },
  ]

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return "N/A"
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  function render_time(date_time) {
    const date_data = new Date(date_time)
    let expiryDate = new Date(
      new Date(date_data).setHours(date_data.getHours() - 7)
    )
    const date_all = new Date(expiryDate)
    return date_all.toLocaleTimeString("en-GB")
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("File upload Settings")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/system-setting")}
              />
            </a>{" "}
             {t("File upload Settings")}
          </h3>
          <Card>
            <CardBody>

              <Table
                loading={loading}
                rowKey="fup_id"
                columns={columns}
                dataSource={data_list}
                scroll={{ x: 800 }}
              />

            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
