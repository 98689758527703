import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { Button, Input, Form, Pagination, Table, Tooltip } from "antd"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import * as api from "../../../service/Api_Service"
import axios from "axios"
import helpers from "../../Function_Redirect/Redirect_login"
import Render_Image from "../../Customer_All/Device/Render_Image"
import { FcDataSheet, FcInspection } from "react-icons/fc"

export default function Select_Device(props) {
    const { t } = useTranslation()
    const history = useHistory()
    const token = localStorage.getItem("token")
    const [page_limit, setPage_limit] = useState({
      page: 1,
      limit: 0,
      page_Size: 10,
    })
    const [data_list, setData_list] = useState([])
    const [loading, setLoading] = useState(false)
    const [search_data_device, setSearch_data_device] = useState(null)

    useEffect(() => {
        search_data()
        return () => {
            
        }
    }, [props.cus_id])

    const onFinish = value => {
        search_data()
      }
    
      function search_data() {
        setLoading(true)
        let url = `api/v1/customer/customer-has-stock/${props.cus_id}?page=1&limit=${
          page_limit.page_Size
        }&order_by=asc${check_data("&q=", search_data_device)}`
        const search = api.SEARCH_ALL(token, url)
        axios(search)
          .then(res => {
            setLoading(false)
            setPage_limit({
              ...page_limit,
              page: 1,
              limit: res.data.total_data,
            })
            setData_list(res.data.data)
          })
          .catch(error => {
            setLoading(false)
            if (error) {
              if (error.response) {
                if (error.response.status === 401) {
                  helpers.re_login()
                  history.push("/login")
                }
              }
            }
          })
      }
    
      function changePagination(page, pageSize) {
        setLoading(true)
        let url = `api/v1/customer/customer-has-stock/${props.cus_id}?page=${page}&limit=${pageSize}&order_by=asc${check_data(
          "&q=",
          search_data_device
        )}`
        const search = api.SEARCH_ALL(token, url)
        axios(search)
          .then(res => {
            setLoading(false)
            setPage_limit({
              ...page_limit,
              page: page,
              page_Size: pageSize,
              limit: res.data.total_data,
            })
            // console.log(res.data.data)
            setData_list(res.data.data)
          })
          .catch(error => {
            setLoading(false)
            if (error) {
              if (error.response) {
                if (error.response.status === 401) {
                  helpers.re_login()
                  history.push("/login")
                }
              }
            }
            // console.log(error)
          })
      }
    
      const check_data = (params, data) => {
        if (data) {
          return params + data
        } else {
          return ""
        }
      }
    
      const columns = [
        {
          title: "",
          dataIndex: "pro_model_img",
          key: "pro_model_img",
          render: text => <Render_Image path_image={text} size={80} />,
        },
        {
          title: t("Serial number"),
          dataIndex: "pro_serial_number",
          key: "pro_serial_number",
          render: text => checkData(text),
        },
        {
          title: t("Group name"),
          dataIndex: "pro_group_name",
          key: "pro_group_name",
          render: text => checkData(text),
        },
        {
          title: t("Model name"),
          dataIndex: "pro_model_name",
          key: "pro_model_name",
          render: text => checkData(text),
        },
        {
          title: t("Asset number"),
          dataIndex: "sto_asset_number",
          key: "sto_asset_number",
          render: text => checkData(text),
        },
        {
          title: t("Install"),
          dataIndex: "sto_date_install",
          key: "sto_date_install",
          render: text => cutTimeZone(text),
        },
        {
          title: t("Car license number"),
          dataIndex: "car_license_number",
          key: "car_license_number",
          render: text => checkData(text),
        },
        {
          title: t("End of warranty"),
          dataIndex: "warranty_name",
          key: "warranty_name",
          render: text => checkData(text),
        },
        {
          title: "",
          dataIndex: "sto_id",
          key: "sto_id",
          render: text => (
            <Tooltip title={t("Select this id")} >
              <a>
                <FcInspection onClick={() => history.push(`/create-new-repair/${text}`) } size={20} />
              </a>
            </Tooltip>
          ),
        }
      ]
    
      function checkData(data) {
        if (data) {
          return data
        } else {
          return "N/A"
        }
      }
      function cutTimeZone(date_T) {
        if (date_T) {
          var date = new Date(date_T)
          var month = pad2(date.getMonth() + 1)
          var day = pad2(date.getDate())
          var year = date.getFullYear()
    
          var formattedDate = day + "/" + month + "/" + year
          return formattedDate
        } else {
          return "N/A"
        }
      }
      function pad2(n) {
        return (n < 10 ? "0" : "") + n
      }

    useEffect(() => {
      
        return () => {
          setData_list([])
          setLoading(false)
          setSearch_data_device(null)
        }
      }, [])

    return (
        <div>
            <hr />
            <Row>
                <Col>
                  <Form
                    name="Search_employee"
                    initialValues={{
                      remember: false,
                    }}
                    onFinish={onFinish}
                  >
                    <Row>
                      <Col sm={12} md={6} lg={6} xl={4}>
                        <Form.Item
                          name="search"
                          rules={[
                            {
                              required: false,
                              message: "Please input name,department",
                            },
                          ]}
                        >
                          <Input
                            placeholder={t("search")}
                            style={{ marginBottom: "15px" }}
                            onChange={e =>
                              setSearch_data_device(e.target.value)
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col sm={12} md={6} lg={4} xl={4}>
                        <Button
                          type="primary"
                          className="button-search-employee"
                          htmlType="submit"
                        >
                          {t("search")}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>

              <Table
                columns={columns}
                loading={loading}
                dataSource={data_list}
                pagination={false}
                rowKey="pro_id"
                scroll={{ x: 800 }}
              />

              <div>
                <Pagination
                  current={page_limit.page}
                  total={page_limit.limit}
                  onChange={changePagination}
                  style={{ marginTop: "20px", float: "right" }}
                />
              </div>
              <p style={{ fontSize: "15px", marginTop: "20px" }}>
                {" "}
                <FcDataSheet size={20} style={{ marginBottom: "5px" }} />{" "}
                {t("total data")} : {page_limit.limit}
              </p>
        </div>
    )
}
