import React,{ useEffect } from 'react'
import { Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next"
import { Form , Input } from 'antd';
import Select_no_search_Location from "../../../New_Service_Has_Stock_Id/components/Select_no_search_Location"
import Select_after_select_geo from "../../../New_Service_Has_Stock_Id/components/Select_after_select_geo"

const { TextArea } = Input

export default function SelectGeolocation({ location_select , setLocation_select , form , createNewService , setCreateNewService }) {
  const { t } = useTranslation()
  const token = localStorage.getItem("token")

  useEffect(() => {
    form.resetFields(["pro_id", "dis_id", "subdis_id", "service_address"])
    setLocation_select({
      ...location_select,
      pro_id: null,
      subdis_id: null,
      dis_id: null,
    })

    setCreateNewService({
      ...createNewService,
      ser_onsite_address: null,
    })
    return () => {}
  }, [location_select.geo_id])

  useEffect(() => {
    form.resetFields(["dis_id", "subdis_id", "service_address"])
    setLocation_select({
      ...location_select,
      subdis_id: null,
      dis_id: null,
    })
    setCreateNewService({
      ...createNewService,
      ser_onsite_address: null,
    })
    return () => {}
  }, [location_select.pro_id])

  useEffect(() => {
    form.resetFields(["subdis_id", "service_address"])
    setLocation_select({
      ...location_select,
      subdis_id: null,
    })
    setCreateNewService({
      ...createNewService,
      ser_onsite_address: null,
    })
    return () => {}
  }, [location_select.dis_id])

  return (
    <div>
     <Row>
     <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search_Location
              header={t("Service zone")}
              placeholder={t("Service zone")}
              name_api="LIST_ALL_GEO_LOCATION"
              map_name="geo_name"
              map_value="geo_id"
              keyState="geo_id"
              data_response="message"
              headState={setLocation_select}
              nameState={location_select}
              disabled={false}
              require={true}
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_after_select_geo
              header={t("Service Province")}
              placeholder={t("Service Province")}
              name_api="LIST_LOCATION_PROVINCE_BY_GEOID"
              map_name="pro_name"
              map_value="pro_id"
              keyState="pro_id"
              data_response="message"
              headState={setLocation_select}
              nameState={location_select}
              data_select_before={location_select.geo_id}
              disabled={location_select.geo_id ? false : true}
              require={true}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_after_select_geo
              header={t("Service District")}
              placeholder={t("Service District")}
              name_api="LIST_LOCATION_DIS_BY_PROVINCE_ID"
              map_name="dis_name"
              map_value="dis_id"
              keyState="dis_id"
              data_response="message"
              headState={setLocation_select}
              nameState={location_select}
              data_select_before={location_select.pro_id}
              disabled={location_select.pro_id ? false : true}
              require={true}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_after_select_geo
              header={t("Service sub-district")}
              placeholder={t("Service sub-district")}
              name_api="LIST_LOCATION_SUB_DISTRICT"
              map_name="subdis_name"
              map_value="subdis_id"
              keyState="subdis_id"
              data_response="message"
              headState={setLocation_select}
              nameState={location_select}
              data_select_before={location_select.dis_id}
              disabled={location_select.dis_id ? false : true}
              require={true}
            />
          </Col>

          <Col sm={12} md={12} lg={12} xl={12}>
            <p className="text-bold">
              {t("On-Site address")} <span style={{ color: "red" }}>*</span>
            </p>
            <Form.Item
              name="service_address"
              rules={[
                {
                  required: true,
                  message: t("On-Site address"),
                },
              ]}
            >
              <TextArea
                placeholder={t("On-Site address")}
                disabled={location_select.subdis_id ? false : true}
                onChange={e =>
                  setCreateNewService({
                    ...createNewService,
                    ser_onsite_address: e.target.value,
                  })
                }
              />
            </Form.Item>
          </Col>
     </Row>
    </div>
  )
}
