var Ip = require('../service/Ip')

export const SEARCH_ALL_EMPLOYEE = (token, url) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}${url}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_DATA = (token, page, pageSize, data) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/employee?page=${page}&limit=${pageSize}&order_by=asc&q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_NO_DATA = (token, page, pageSize) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/employee?page=${page}&limit=${pageSize}&order_by=asc`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const REMOVE_EMPLOYEE = (token, id) => {
    return {
      method: 'delete',
      url: `${Ip.getIP()}api/v1/employee/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const GET_IMAGE = (token, image) => {
    return {
      method: 'get',
      responseType: 'arraybuffer',
      url: `${Ip.getIP()}storage/file/${image}`,
      headers: {
        'x-access-token' : token
      }
    };
  };

  export const CREATE_NEW_ADMIN = (token, data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/admin`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const UPLOAD_PROFILE = (token, formData) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}storage/upload`,
      headers: {
        "Content-type": "multipart/form-data",
        'x-access-token' : token
      },
      data : formData
    };
  };

  export const MOVE_EMPLOYEE_ALL_IN_GROUP = (token) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/setallemployeeingroup`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };