import React from 'react'
import { Steps } from "antd"
import { BiUserCircle, BiCalculator, BiBuildings } from "react-icons/bi"
import { RiNewspaperLine } from "react-icons/ri"
import { BsPeople, BsCardChecklist } from "react-icons/bs"
import { HiUserGroup } from "react-icons/hi"
import { AiOutlineFundView } from "react-icons/ai"
import { useTranslation } from "react-i18next";

  const { Step } = Steps

export default function Step_Status_Car_Import(props) {
  const { t } = useTranslation();
    const mt_icon = { 
        marginBottom:'7px' 
    }
    return (
      <div className="border-card-info">
        <Steps
          current={0}
          responsive={true}
        //   status="error"
        >
          <Step title={t("Overview")} icon={<AiOutlineFundView style={mt_icon} />} />
          <Step title={t("Dealer")} icon={<BsPeople style={mt_icon} />} />
          <Step title={t("Customer")} icon={<BiUserCircle style={mt_icon} />} />
          <Step title={t("Branch")} icon={<BiBuildings style={mt_icon} />} />
          <Step title={t("User")} icon={<HiUserGroup style={mt_icon} />} />
          <Step title={t("Contract")} icon={<RiNewspaperLine style={mt_icon} />} />
          <Step title={t("Device")} icon={<BiCalculator style={mt_icon} />} />
          <Step title={t("Finalize")} icon={<BsCardChecklist style={mt_icon} />} />
        </Steps>
      </div>

    )
}
