import React, { useEffect, useState } from "react"
import { Row, Col } from "reactstrap"
import Col_Device from "./components/Col_Device"
import { Switch } from "antd"
import Render_Center_Avatar from '../Operator/Table_data/Render_Center_Avatar'
import { useTranslation } from "react-i18next";

export default function Detail(props) {
  const { t } = useTranslation();
  const [detail_user, setDetail_user] = useState({
    cus_code: null,
    cus_id: null,
    cus_line_uid: null,
    cus_name: null,
    cus_user_avatar: null,
    cus_user_code: null,
    cus_user_email: null,
    cus_user_firstname: null,
    cus_user_lastname: null,
    cus_user_phone: null,
    cus_user_remark: null,
    dep_name: null,
    not_send_email_notification: null,
    not_send_line_notification: null,
    pos_name: null,
  })

  const icons_info_header = {
    width: "25px",
    marginRight: "20px",
  }
  function check_handle_info(check) {
    switch (check) {
      case true:
        return false
        break
      case false:
        return true
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (props.data_user !== undefined) {
      setDetail_user({
        ...detail_user,
        cus_code: props.data_user.cus_code,
        cus_id: props.data_user.cus_id,
        cus_line_uid: props.data_user.cus_line_uid,
        cus_name: props.data_user.cus_name,
        cus_user_avatar: props.data_user.cus_user_avatar,
        cus_user_code: props.data_user.cus_user_code,
        cus_user_email: props.data_user.cus_user_email,
        cus_user_firstname: props.data_user.cus_user_firstname,
        cus_user_lastname: props.data_user.cus_user_lastname,
        cus_user_phone: props.data_user.cus_user_phone,
        cus_user_remark: props.data_user.cus_user_remark,
        dep_name: props.data_user.dep_name,
        not_send_email_notification:
          props.data_user.not_send_email_notification,
        not_send_line_notification: props.data_user.not_send_line_notification,
        pos_name: props.data_user.pos_name,
      })
    }
    return () => {}
  }, [props.data_user])

  useEffect(() => {
    return () => {
      setDetail_user({})
    }
  }, [])

  return (
    <div>
    <Row>
    <Col>
    <Render_Center_Avatar path_image={detail_user.cus_user_avatar} />
      <br />
    </Col>
  </Row>
      <Row>
        <Col_Device
          text_header={t("user firstname")}
          data={detail_user.cus_user_firstname}
        />
        <Col_Device
          text_header={t("user lastname")}
          data={detail_user.cus_user_lastname}
        />

        <Col_Device
          text_header={t("user phone")}
          data={detail_user.cus_user_phone}
        />
        <Col_Device text_header={t("Department")} data={detail_user.dep_name} />
        <Col_Device text_header={t("Position")} data={detail_user.pos_name} />
        <Col_Device
          text_header={t("user email")}
          data={detail_user.cus_user_email}
        />
        <Col_Device
          text_header={t("user customer name")}
          data={detail_user.cus_name}
        />
      </Row>
      <Row>
      <Col sm={12} md={6} lg={6} xl={6}>
      <p className="text-bold"> {t("receive notifications")} </p>
      <p>
         {t("subscribe email notification")} 
        <Switch
          disabled
          style={{ marginLeft: "17px" }}
          checked={check_handle_info(
            detail_user.not_send_email_notification
          )}
        />
      </p>
      <p>
           {t("subscribe line notification")} 
        <Switch
          disabled
          style={{ marginLeft: "25px" }}
          checked={check_handle_info(
            detail_user.not_send_line_notification
          )}
        />
      </p>
    </Col>
      </Row>
    </div>
  )
}
