import React, { useState, useEffect } from "react"
import { Form, Button, Modal, notification, Input } from "antd"
import { Row, Col } from "reactstrap"
import { useHistory } from "react-router-dom"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import helpers from "../../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"
import Input_Comp from "../../New_Service_Has_Stock_Id/components/Input_Comp"
import Select_no_search from "../../New_Service_Has_Stock_Id/components/Select_no_search"
import Select_no_search_Location from "../../New_Service_Has_Stock_Id/components/Select_no_search_Location"
import Select_after_select_geo from "../../New_Service_Has_Stock_Id/components/Select_after_select_geo"
import Map_location from "../../New_Service_Has_Stock_Id/components/Map_location"
import Input_Show_Only from "../../New_Service_Has_Stock_Id/components/Input_Show_Only"
import axios from "axios"
import * as api from "../../../service/service_dealer/Api_Service"
import { useSelector } from "react-redux"

const { confirm } = Modal
const { TextArea } = Input

export default function Form_Service(props) {
  const token = localStorage.getItem("token")
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [form] = Form.useForm()
  const [create_service, setCreate_service] = useState({
    cus_id: null,
    rrs_status_id: 1,
    ser_contact_name: null,
    ser_contact_phone: null,
    ser_issue_reported: null,
    ser_onsite_address: null,
    ser_received_data: null,
    ser_received_type_id: null,
    sto_id: null,
    ser_lat: null,
    ser_long: null,
  })
  const [location_select, setLocation_select] = useState({
    geo_id: null,
    pro_id: null,
    subdis_id: null,
    dis_id: null,
  })
  const [type_filter, setType_filter] = useState(null)
  const [cus_line_uid, setCus_line_uid] = useState(null)
  const [disabledButton, setDisabledButton] = useState(false)
  const [type_received_all, setType_received_all] = useState([])
  const { emailUserCUstomer } = useSelector(({ Distar }) => Distar)
 
  useEffect(() => {
    check_received()
    check_user_connected_line_status()
    return () => {}
  }, [])

  useEffect(() => {
    if (props.data_stock) {
      setCreate_service({
        ...create_service,
        cus_id: props.data_stock.cus_id,
        ser_contact_name: props.data_stock.cus_name,
        sto_id: props.data_stock.sto_id,
      })
      form.setFieldsValue({
        ser_contact_name: props.data_stock.cus_name,
      })
    }
    return () => {}
  }, [props.data_stock])

  useEffect(() => {
    function check_type_received(type) {
      type_received_all.map(data => {
        if (data.ser_received_type_id === type)
          return setType_filter(data.ser_received_type_name)
      })
      form.resetFields(["ser_received_data"])
    }

    if (create_service.ser_received_type_id) {
      check_type_received(create_service.ser_received_type_id)
      setCreate_service({
        ...create_service,
        ser_received_data: null,
      })
    }

    if (create_service.ser_received_type_id === 1) {
      setCreate_service({
        ...create_service,
        ser_received_data: emailUserCUstomer,
      })
      setDisabledButton(false)
    }

    if (create_service.ser_received_type_id === 2) {
      setCreate_service({
        ...create_service,
        ser_received_data: cus_line_uid,
      })
      if (cus_line_uid === null) {
        setDisabledButton(true)
      }
    }
    return () => {}
  }, [create_service.ser_received_type_id])

  function check_user_connected_line_status() {
    const check_line = api.CHECK_USER_CONNECTED_LINE(token)
    axios(check_line).then(res => {
      setCus_line_uid(res.data.data.cus_line_uid)
    })
  }

  function check_received() {
    const check_ = api.LIST_RECEIVED_TYPE(token)
    axios(check_).then(res => {
      setType_received_all(res.data.data)
    })
  }

  useEffect(() => {
    form.resetFields(["pro_id", "dis_id", "subdis_id", "service_address"])
    setLocation_select({
      ...location_select,
      pro_id: null,
      subdis_id: null,
      dis_id: null,
    })
    setCreate_service({
      ...create_service,
      ser_onsite_address: null,
    })
    return () => {}
  }, [location_select.geo_id])

  useEffect(() => {
    form.resetFields(["dis_id", "subdis_id", "service_address"])
    setLocation_select({
      ...location_select,
      subdis_id: null,
      dis_id: null,
    })
    setCreate_service({
      ...create_service,
      ser_onsite_address: null,
    })
    return () => {}
  }, [location_select.pro_id])

  useEffect(() => {
    form.resetFields(["subdis_id", "service_address"])
    setLocation_select({
      ...location_select,
      subdis_id: null,
    })
    setCreate_service({
      ...create_service,
      ser_onsite_address: null,
    })
    return () => {}
  }, [location_select.dis_id])

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  const onFinish = value => {
    if (create_service.ser_issue_reported === null) {
      return Modal.error({
        title: t("Please input issue reported"),
        centered: true,
      })
    } else {
      // console.log(create_service);
      return confirm({
        title: t("Confirm create new repair"),
        icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
        centered: true,
        okText: t("create"),
        cancelText: t("no"),
        onOk() {
          setLoading(true)
          let data_new_repair = {
            ...create_service,
            ...location_select,
          }
          const new_repair = api.CREATE_NEW_REPAIR(token, data_new_repair)
          axios(new_repair)
            .then(res => {
              openNotificationWithIcon(
                "success",
                t("Create new repair success")
              )
              history.push("/repair")
              setLoading(false)
            })
            .catch(error => {
              setLoading(false)
              if (error) {
                if (error.response) {
                  if (error.response.status === 401) {
                    helpers.re_login()
                    history.push("/login")
                  } else {
                    openNotificationWithIcon(
                      "error",
                      t("Create new repair error")
                    )
                  }
                }
              }
            })
        },
        onCancel() {},
      })
    }
  }

  return (
    <div>
      <Form
        form={form}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Contact name")}
              placeholder={t("Contact name")}
              headState={setCreate_service}
              nameState={create_service}
              type="text"
              keyState="ser_contact_name"
              required={true}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Contact phone")}
              placeholder={t("Contact phone")}
              headState={setCreate_service}
              nameState={create_service}
              type="number"
              keyState="ser_contact_phone"
              required={true}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Service received type")}
              placeholder={t("Service received type")}
              name_api="LIST_RECEIVED_TYPE"
              map_name="ser_received_type_name"
              map_value="ser_received_type_id"
              keyState="ser_received_type_id"
              headState={setCreate_service}
              nameState={create_service}
              name_array_data="data"
            />
          </Col>

          {
              type_filter === "line" && disabledButton === true && (
                <p style={{ color:'red' , fontSize:'15px' , marginTop:'-15px' }}> {t("Can't select service received type line , Please register account with line")} </p>
              )
            }

          {type_filter === "email" &&
            create_service.ser_received_data === null && (
              <Col sm={12} md={6} lg={6} xl={6}>
                <Input_Comp
                  header={t("Email")}
                  placeholder={t("Email")}
                  headState={setCreate_service}
                  nameState={create_service}
                  type="text"
                  keyState="ser_received_data"
                  required={true}
                />
              </Col>
            )}

          <Col sm={12} md={12} lg={12} xl={12}>
            <p className="text-bold">
              {t("Issue reported")} <span style={{ color: "red" }}>*</span>
            </p>
            <CKEditor
              editor={ClassicEditor}
              config={{
                removePlugins: [
                  "ImageUpload",
                  "EasyImage",
                  "MediaEmbed",
                  "Table",
                ],
              }}
              data={
                create_service.ser_issue_reported
                  ? create_service.ser_issue_reported
                  : ""
              }
              onChange={(event, editor) => {
                const data = editor.getData()
                // console.log( { event, editor, data } );
                if (data) {
                  setCreate_service({
                    ...create_service,
                    ser_issue_reported: data,
                  })
                } else {
                  setCreate_service({
                    ...create_service,
                    ser_issue_reported: null,
                  })
                }
              }}
            />
          </Col>
          <br />
          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search_Location
              header={t("Service zone")}
              placeholder={t("Service zone")}
              name_api="LIST_ALL_GEO_LOCATION"
              map_name="geo_name"
              map_value="geo_id"
              keyState="geo_id"
              data_response="message"
              headState={setLocation_select}
              nameState={location_select}
              disabled={false}
              require={true}
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_after_select_geo
              header={t("Service Province")}
              placeholder={t("Service Province")}
              name_api="LIST_LOCATION_PROVINCE_BY_GEOID"
              map_name="pro_name"
              map_value="pro_id"
              keyState="pro_id"
              data_response="message"
              headState={setLocation_select}
              nameState={location_select}
              data_select_before={location_select.geo_id}
              disabled={location_select.geo_id ? false : true}
              require={true}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_after_select_geo
              header={t("Service District")}
              placeholder={t("Service District")}
              name_api="LIST_LOCATION_DIS_BY_PROVINCE_ID"
              map_name="dis_name"
              map_value="dis_id"
              keyState="dis_id"
              data_response="message"
              headState={setLocation_select}
              nameState={location_select}
              data_select_before={location_select.pro_id}
              disabled={location_select.pro_id ? false : true}
              require={true}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_after_select_geo
              header={t("Service sub-district")}
              placeholder={t("Service sub-district")}
              name_api="LIST_LOCATION_SUB_DISTRICT"
              map_name="subdis_name"
              map_value="subdis_id"
              keyState="subdis_id"
              data_response="message"
              headState={setLocation_select}
              nameState={location_select}
              data_select_before={location_select.dis_id}
              disabled={location_select.dis_id ? false : true}
              require={true}
            />
          </Col>

          <Col sm={12} md={12} lg={12} xl={12}>
            <p className="text-bold">
              {t("On-Site address")} <span style={{ color: "red" }}>*</span>
            </p>
            <Form.Item
              name="service_address"
              rules={[
                {
                  required: true,
                  message: t("On-Site address"),
                },
              ]}
            >
              <TextArea
                placeholder={t("On-Site address")}
                disabled={location_select.subdis_id ? false : true}
                onChange={e =>
                  setCreate_service({
                    ...create_service,
                    ser_onsite_address: e.target.value,
                  })
                }
              />
            </Form.Item>
          </Col>

          <Col sm={12} md={12} lg={12} xl={12}>
            <br />
            <p className="text-bold">{t("search location")}</p>
            <p> {t("search or click on map to select location")} </p>
            <Map_location
              setCreate_service={setCreate_service}
              create_service={create_service}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <br />
            <Input_Show_Only
              placeholder={t("latitude")}
              description={null}
              header={t("latitude")}
              keyState="ser_lat"
              required={false}
              value={create_service.ser_lat}
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <br />
            <Input_Show_Only
              placeholder={t("longitude")}
              description={null}
              header={t("longitude")}
              keyState="ser_long"
              required={false}
              value={create_service.ser_long}
            />
          </Col>
        </Row>

        <br />
        {
              type_filter === "line" && disabledButton === true && (
                <p style={{ color:'red' , fontSize:'15px' , marginTop:'-15px' , textAlign:'center' }}> {t("Can't select service received type line , Please register account with line")} </p>
              )
            }
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div className="div_create_new_car">
              <Button
                className="btn_cancel_create_new_car"
                onClick={() => history.push("/create-new-repair")}
              >
                {t("Cancel")}
              </Button>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div className="div_create_new_car">
              <Button
                className="btn_submit_create_new_car"
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={disabledButton}
              >
                {t("Create")}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
