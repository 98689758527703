import React,{ useState } from 'react'
import { useHistory } from 'react-router-dom';
import Expand from 'react-expand-animated';
import { FiChevronDown , FiChevronRight } from "react-icons/fi";
import { Button, Modal, notification } from "antd"
import axios from 'axios';
import { Row, Col } from "reactstrap"
import { ExclamationCircleOutlined } from '@ant-design/icons';
import * as api from '../../../../service/Api_Edit_Service'

const { confirm } = Modal;


export default function Delete_Service(props) {
    const history = useHistory();
    const token = localStorage.getItem('token')
    const [open, setOpen] = useState(false)
    const [icon, setIcon] = useState(<FiChevronRight size={20} style={{ marginBottom : '5px'}}/>)

    const setToggle = () =>{
        if(open===false){
            setOpen(true)
            setIcon(<FiChevronDown size={25} style={{ marginBottom : '5px'}}/>)
        } else {
            setOpen(false)
            setIcon(<FiChevronRight size={25} style={{ marginBottom : '5px'}}/>)
        }
    }
    const openNotificationWithIcon = (type, message) => {
        notification[type]({
          message: message,
          
        });
      };

    function delete_employee() {
        return confirm({
            title: 'Are you sure delete service ?',
            icon: <ExclamationCircleOutlined style={{ color:'red' }} />,
            centered : true,
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk() {
              const delete_a_service = api.DELETE_SERVICE(token, props.ser_id)
              axios(delete_a_service)
              .then( res => {
                  console.log(res.data);
              })
              .catch( error => {
                //   console.log(error.response);
                  if(error.response.status === 404){
                    history.push('/service')
                    openNotificationWithIcon('success', 'Remove service success')
                  } else {
                    openNotificationWithIcon('error', 'Remove error please remove again')
                  }
              
              })

              
            },
            onCancel() {
            //   console.log('Cancel');
            },
          });
    }

    return (
    <Row>
        <Col sm={12} md={6} lg={4} xl={4} onClick={() => setToggle()}>
            <a> <p style={{ fontSize:'20px' }}> {icon} Delete service </p> </a>
        </Col>
        <Col sm={12} md={6} lg={8} xl={8}>
            <p style={{ color:'rgb(108, 117, 125)' }}>Make sure your want delete service ?</p>

            <Expand open={open} duration={500}>
                <div style={{ width: '100%', borderRadius:'17px', padding:'10px' }}>
                        <p style={{ color:'red' }}>Danger !!! this action can't be undo</p>
                        <Button type="primary" danger style={{width:'200px'}} onClick={() => delete_employee()}>Delete service</Button>
                </div>
            </Expand>
        </Col>
    </Row> 
    )
}
