import React, { useState, useEffect } from "react"
import { Steps } from "antd"
import {
  UserOutlined,
  SolutionOutlined,
  LoadingOutlined,
  SmileOutlined,
} from "@ant-design/icons"
import { BiTaskX, BiTask } from "react-icons/bi"
import { RiTimeLine } from "react-icons/ri"
import { GrCompliance } from "react-icons/gr"
import { VscLoading } from "react-icons/vsc"
import { BsFileEarmarkCheck } from "react-icons/bs"
import { AiOutlineCloseCircle } from "react-icons/ai"
import { useTranslation } from "react-i18next";

const { Step } = Steps

export default function Status_Service(props) {
  const { t } = useTranslation();
  const [select_status, setSelect_status] = useState(0)
  const [overdue_cancle_status, setOverdue_cancle_status] = useState(null)

  useEffect(() => {
    if (props.data_service) {
    //   console.log(props.data_service.ser_status_name)
      if (
        props.data_service.ser_status_name === "overdue" ||
        props.data_service.ser_status_name === "cancel"  
      ) {
        setSelect_status(4)
        setOverdue_cancle_status("error")
      } else {
        render_priority(props.data_service.ser_status_name)
      }
    }

    return () => {
 
    }
  }, [props.data_service])

  function render_priority(ser_status_name) {
    if (ser_status_name) {
      switch (ser_status_name) {
        case "unassigned":
          setSelect_status(0)
          break
        case "assigned":
          setSelect_status(1)
          break
        case "inprogress":
          setSelect_status(2)
          break
        case "pending":
          setSelect_status(3)
          break

        case "waiting for admin approve":
          setSelect_status(3)
          break
        case "waiting for manager approve":
          setSelect_status(3)
          break

        case "complete":
          setSelect_status(4)
          break
        case "resolved":
          setSelect_status(4)
          break
        default:
          return null
      }
    }
  }

  return (
    <div
      style={{
        boxShadow: "rgb(99 99 99 / 20%) 0px 2px 8px 0px",
        padding: "10px",
        margin: "10px",
      }}
    >
      <Steps
        current={select_status}
        responsive={true}
        status={overdue_cancle_status}
      >
        <Step title={t("Unassigned")} icon={<BiTaskX />} />
        <Step title={t("Assigned")} icon={<BiTask />} />
        <Step title={t("Inprogress")} icon={<VscLoading />} />
        <Step title={t("Pending")} icon={<RiTimeLine />} />

        {overdue_cancle_status === null && (
          <Step title={t("Complete")} icon={<BsFileEarmarkCheck />} />
        )}

        {overdue_cancle_status !== null && (
          <Step title={t("Cancel")} icon={<AiOutlineCloseCircle />} />
        )}
      </Steps>
    </div>
  )
}
