import React, { useState, useEffect } from "react"
import { Container, Card, CardBody } from "reactstrap"
import MetaTags from "react-meta-tags"
import { useHistory } from "react-router-dom"
import { FcSettings } from "react-icons/fc"
import { Table, Tooltip } from 'antd'
import no_permi from '../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png'
import { useTranslation } from "react-i18next";
import './System_Setting.css'
import { useSelector } from "react-redux"

const System_Settings = props => {
  const { t } = useTranslation();
  const history = useHistory()
  const token = localStorage.getItem("token")
  const permi = localStorage.getItem("permi")
  const [permi_t, setPermi_t] = useState(false)
  const { permissionAllService } = useSelector(({ Distar }) => Distar)
 
  useEffect(() => {
    if (permissionAllService) {
      if(permissionAllService.system_setting){
        setPermi_t(permissionAllService.system_setting.read)
      }
    }
    return () => {

    }
  }, [permissionAllService])

  const data = [
      {
        module : t("Product group"),
        path : "product-group"
      },{
        module : t("Product model"),
        path : "product-model"
      },{
        module : t("Employee group"),
        path : "employee-group"
      },{
        module : t("Operator group"),
        path : "operator-group"
      },{
        module : t("SLA"),
        path : "sla"
      },{
        module : t("Line"),
        path : "setting-line"
      },
      {
        module : t("Warranty"),
        path : "warranty"
      },{
        module : t("Task"),
        path : "task"
      },{
        module : t("Email notification"),
        path : "email-notification"
      },{
        module : t("Scheduler"),    
        path : "scheduler"
      },{
        module : t("Car body type"),
        path : "car-body-type"
      },{
        module : t("Car brand"),
        path : "car-brand"
      },{
        module : t("Car color"),
        path : "car-color"
      },{
        module : t("Car model"),
        path : "car-model"
      },{
        module : t("Car group"),
        path : "car-group"
      },{
        module : t("Car model year"),
        path : "car-model-year"
      },{
        module : t("Department"),
        path : "department"
      },{
        module : t("Position"),
        path : "position"
      },{
        module : t("file upload"),
        path : "file-upload"
      },{
        module : t("Service type"),
        path : "service-type"
      }
  ]
  const columns = [
    {
      title: t("Module"),
      dataIndex: "module",
      key: "module",
      render: text => <p>{text}</p>,
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: text => (
        <Tooltip title={`${t("Setting")} ${text.module}`}>
        <a>
          <FcSettings
            size={20}
            onClick={() => history.push(`/system-setting/${text.path}`)}
          />
        </a>
        </Tooltip>
      ),
    }
  ];
  

  return (
    <React.Fragment>
      <MetaTags>
        <title>{t("System settings")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>{t("System settings")}</h3>
          {
            permi_t === true && (
              <Card>
              <CardBody>
                  <Table 
                      columns={columns} 
                      dataSource={data} 
                      rowKey="path"
                      scroll={{ x: 400 }}
                  />
              </CardBody>
            </Card>
            )
          }
          {
            permi_t === false && (
              <div>
                <img src={no_permi} alt="no permission" style={{ display:'block' , margin: '50px auto' }} />
                <p style={{ textAlign: "center", fontSize: "20px" }}>{t("no permission read module")}</p>
                <p style={{ textAlign:'center', fontSize:'20px' }}> {t("please contract administrator")} </p>
                </div>
            )
          }
 
        </Container>
      </div>
    </React.Fragment>
  )
}
export default System_Settings
