var Ip = require('../service/Ip')

export const LIST_CAR_BODY_TYPE = (token) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/car/body`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_ALL_PRODUCT_GROUP = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/stock/product-group`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_WARRANTY = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/product/warranty`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_PRODUCT_MODEL = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/stock/product-group`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_ALL_PRODUCT_DATA = (token, pro_group_id , page, pageSize , data) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/stock/product?page=${page}&limit=${pageSize}&order_by=asc&pro_group_id=${pro_group_id}&q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_ALL_PRODUCT_NO_DATA = (token, pro_group_id , page, pageSize ) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/stock/product?page=${page}&limit=${pageSize}&order_by=asc&pro_group_id=${pro_group_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_ALL_CUSTOMER_DATA = (token, page, pageSize , data) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer?page=${page}&limit=${pageSize}&order_by=asc&q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_ALL_CUSTOMER_NO_DATA = (token, page, pageSize ) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer?page=${page}&limit=${pageSize}&order_by=asc`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_CUSTOMER_CAR_DATA = (token, cus_id , page, pageSize, data ) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer/customer-car/${cus_id}?page=${page}&limit=${pageSize}&order_by=asc&q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_CUSTOMER_CAR_NO_DATA = (token, cus_id , page, pageSize ) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer/customer-car/${cus_id}?page=${page}&limit=${pageSize}&order_by=asc`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

export const LIST_DEALER_DATA = (token, page, pageSize, data ) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/dealer/paginate?page=${page}&limit=${pageSize}&order_by=asc&q=${data}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_DEALER_NO_DATA = (token, page, pageSize ) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/dealer/paginate?page=${page}&limit=${pageSize}&order_by=asc`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_CUSTOMER_CONTRACT_DATA = (token, cus_id , page, pageSize, data ) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/contract-customer/${cus_id}?page=${page}&limit=${pageSize}&order_by=asc&q=${data}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_CUSTOMER_CONTRACT_NO_DATA = (token, cus_id , page, pageSize ) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/contract-customer/${cus_id}?page=${page}&limit=${pageSize}&order_by=asc`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_OPERATOR_DATA = (token, page, pageSize, data ) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/operator?page=${page}&limit=${pageSize}&order_by=asc&q=${data}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_OPERATOR_NO_DATA = (token, page, pageSize ) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/operator?page=${page}&limit=${pageSize}&order_by=asc`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const CREATE_NEW_STOCK = (token, data ) => {
  return {
    method: 'post',
    url: `${Ip.getIP()}api/v1/stock`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    },
    data : data
  };
};

export const CREATE_CUSTOMER = (token, data) => {
  return {
    method: 'post',
    url: `${Ip.getIP()}api/v1/customer`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    },
    data : data
  };
};

export const CREATE_NEW_CAR = (token , data) => {
  return {
    method: 'post',
    url: `${Ip.getIP()}api/v1/car`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    },
    data : data
  };
};

export const CREATE_NEW_CONTRACT = (token, data) => {
  return {
    method: 'post',
    url: `${Ip.getIP()}api/v1/contract`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    },
    data : data
  };
};

export const CREATE_NEW_PRODUCT = (token, data) => {
  return {
    method: 'post',
    url: `${Ip.getIP()}api/v1/product`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    },
    data : data
  };
};
