import React, { useState, useEffect } from "react"
import { Form } from "antd"
import axios from "axios"
import * as api from "../../../../../../service/Api_New_Customer"
import Form_Data from './Form_Data'

export default function Create_New_Branch(props) {
    const [form] = Form.useForm()
    const [error_Branch, setError_Branch] = useState(null)
    const [postcode_select, setPostcode_select] = useState([])
    const [location_select, setLocation_select] = useState({
      dis_name: null,
      pro_name: null,
      subdis_name: null,
      zip_code: null,
    })

    useEffect(() => {
        
        return () => {
            setPostcode_select([])
            setLocation_select({})
        }
    }, [])

  useEffect(() => {
    if (props.found_Branch_In_DB === "NEW") {
      props.setBranch({
        cus_branch_address: null,
        cus_branch_code: null,
        cus_branch_contact_email: null,
        cus_branch_contact_firstname: null,
        cus_branch_contact_lastname: null,
        cus_branch_contact_mobile: null,
        cus_branch_contact_pos: null,
        cus_branch_email: null,
        cus_branch_fax: null,
        cus_branch_lat: null,
        cus_branch_long: null,
        cus_branch_mobile: null,
        cus_branch_name: null,
        cus_branch_phone: null,
        zip_id: null,
        default_branch: false,
        found_branch_db : false
      })
      props.setSelect_branch_final({})
    } else {
        onReset()
    }
    return () => {

    }
  }, [props.found_Branch_In_DB])

  const onReset = () => {
    form.resetFields()
    props.setBranch({
        cus_branch_address: null,
        cus_branch_code: null,
        cus_branch_contact_email: null,
        cus_branch_contact_firstname: null,
        cus_branch_contact_lastname: null,
        cus_branch_contact_mobile: null,
        cus_branch_contact_pos: null,
        cus_branch_email: null,
        cus_branch_fax: null,
        cus_branch_lat: null,
        cus_branch_long: null,
        cus_branch_mobile: null,
        cus_branch_name: null,
        cus_branch_phone: null,
        zip_id: null,
        default_branch: false,
        found_branch_db : false
    })
    props.setSelect_branch_final({})
    setLocation_select({
      ...location_select,
      dis_name: null,
      pro_name: null,
      subdis_name: null,
      zip_code: null,
    })
  }

  function check_postcode(postcode) {
    if (postcode.length === 0) {
      setLocation_select({
        dis_name: null,
        pro_name: null,
        subdis_name: null,
        zip_code: null,
      })
    } else if (postcode.length >= 4) {
      const check_zip_postcode = api.CHECK_POSTCODE(postcode)
      axios(check_zip_postcode)
        .then(res => {
          setPostcode_select(res.data.message)
        })
        .catch(error => {
          // console.log(error);
        })
    }
  }

  return (
    <div>
    <Form
    name="Branch_form"
    initialValues={{
      remember: true,
    }}
    form={form}
  >
    <Form_Data
      error_Branch={error_Branch}
      setError_Branch={setError_Branch}
      setBranch={props.setBranch}
      branch={props.branch}
      check_postcode={check_postcode}
      postcode_select={postcode_select}
      setPostcode_select={setPostcode_select}
      location_select={location_select}
      setLocation_select={setLocation_select}
      id={props.id}
    />

  </Form>
    </div>
  )
}
