import React, { useState, useEffect } from "react"
import { Container, Card, CardBody, Row , Col } from "reactstrap"
import { Tabs, Pagination } from "antd"
import MetaTags from "react-meta-tags"
import axios from 'axios'
import * as api from '../../../service/service_customer/Api_Cars'
import Data_car_search from './Table_Car/Data_car_search'
import Table_Car from './Table_Car'
import { FcDataSheet } from "react-icons/fc"
import { useHistory } from "react-router-dom";
import helpers from '../../Function_Redirect/Redirect_login'
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux"

const Cars = props => {
  const { t } = useTranslation();
  const token = localStorage.getItem('token')
  const cus_id = localStorage.getItem('cus_id')
  const history = useHistory()
  const [data_car_search, setData_car_search] = useState(null)
  const [data_list, setData_list] = useState([])
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })
  const [loading, setLoading] = useState(false)
  const { customer_user_id } = useSelector(({ Distar }) => Distar)
  
  useEffect(() => {
    search_car()
    return () => {
      setData_list([])
    }
  }, [customer_user_id])
 
  function search_car() {
    setLoading(true)
    if (data_car_search) {
      const list_data_car_data = api.LIST_CAR_IN_CUSTOMER_DATA(
        token,
        customer_user_id,
        1,
        page_limit.page_Size,
        data_car_search
      )
      axios(list_data_car_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }

        })
    } else {
      const list_data_car_no_data = api.LIST_CAR_IN_CUSTOMER_NO_DATA(
        token,
        customer_user_id,
        1,
        page_limit.page_Size
      )
      axios(list_data_car_no_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }

        })
    }
  }

  function changePagination_Car(page, pageSize) {
    setLoading(true)
    if (data_car_search) {
      const list_data_car_data = api.LIST_CAR_IN_CUSTOMER_DATA(
        token,
        customer_user_id,
        page,
        pageSize,
        data_car_search
      )
      axios(list_data_car_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }

        })
    } else {
      const list_data_car_no_data = api.LIST_CAR_IN_CUSTOMER_NO_DATA(
        token,
        customer_user_id,
        page,
        pageSize
      )
      axios(list_data_car_no_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }

        })
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Cars")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>{t("Cars")}</h3>
          <Card>
          <CardBody>
          <Data_car_search
          search_car={search_car}
          setData_car_search={setData_car_search}
        />
        <Table_Car data_list={data_list} id={customer_user_id} loading={loading} />

        <div>
          <Pagination
            current={page_limit.page}
            total={page_limit.limit}
            onChange={changePagination_Car}
            style={{ marginTop: "20px", float: "right" }}
          />
        </div>
        <p style={{ fontSize:'15px' , marginTop:'20px'}}> <FcDataSheet size={20} style={{ marginBottom:'5px' }} /> {t("total data")} : {page_limit.limit}</p>
        </CardBody>
        </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Cars
