import React, { useState, useEffect } from "react"
import { Container, Card, CardBody, Row, Col } from "reactstrap"
import { Button, Input, Form, Pagination, Table, Tooltip } from "antd"
import MetaTags from "react-meta-tags"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import { MdKeyboardBackspace } from "react-icons/md"
import * as api from "../../../service/Api_Service"
import axios from "axios"
import helpers from "../../Function_Redirect/Redirect_login"
import Render_Image from "../../Customer_All/Device/Render_Image"
import { FcDataSheet, FcInspection } from "react-icons/fc"
import Detail_Stock from "./Detail_Stock"
import Form_Service from './Form_Service'

const Form_Create_Service = props => {
  const { t } = useTranslation()
  const history = useHistory()
  const token = localStorage.getItem("token")
  const cus_id = localStorage.getItem("cus_id")
  const { sto_id } = useParams()
  const [data_stock, setData_stock] = useState({})
  const [contract_table, setContract_table] = useState([])

  useEffect(() => {
    check_data_stock()
    return () => {
      setData_stock({})
      setContract_table([])
    }
  }, [])

  function check_data_stock() {
    const new_data = api.GET_DETAIL_STOCK(token, parseInt(sto_id))
    axios(new_data)
      .then(res => {
        if (!res.data.data) {
          history.push("/create-new-repair")
        } else {
          setData_stock(res.data.data)
          list_sub_contract(res.data.data.sto_id)
        }
      })
      .catch(error => {
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  function list_sub_contract(sto_id) {
    const list = api.LIST_STOCK_HAST_PRODUCT(token, sto_id)
    axios(list)
      .then(res => {
        setContract_table(res.data.data)
      })
      .catch(error => {
        // console.log(error.response)
      })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Create new repair")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/create-new-repair")}
              />
            </a>{" "}
            {t("Create new repair")}
          </h3>
          <Card>
            <CardBody>
              <Detail_Stock
                data_stock={data_stock}
                contract_table={contract_table}
              />

                <Form_Service 
                   data_stock={data_stock}
                />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Form_Create_Service
