import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Container, Card, CardBody, Row, Col } from "reactstrap"
import { MdKeyboardBackspace } from "react-icons/md"
import { Form, Button, Input, Modal, notification, Avatar } from "antd"
import Input_Comp from "./Components/Input_Comp"
import Input_Number from "./Components/Input_Number"
import Select_no_search from "./Components/Select_no_search"
import axios from "axios"
import * as api from "../../service/Api_Customer"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { UserOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next"
import no_permi from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png"
import Input_Comp_Onblur from "./Components/Input_Comp_Onblur"
import helpers from "../Function_Redirect/Redirect_login"

const { confirm } = Modal
const { TextArea } = Input

export default function Form_Customer(props) {
    const { t } = useTranslation()
    const history = useHistory()
    const token = localStorage.getItem("token")
    const [form] = Form.useForm()
    const [customer_data, setCustomer_data] = useState({})
    const [error_tax_id, setError_tax_id] = useState(null)
    const [location_select, setLocation_select] = useState({})
    const [postcode_select, setPostcode_select] = useState([])

    const [loading, setLoading] = useState(false)
    const style_req = {
      color: "red",
    }
  
    const permi = localStorage.getItem("permi")
    const [permi_t, setPermi_t] = useState(false)
  
    useEffect(() => {
      if (permi) {
        if (JSON.parse(permi).import) {
          setPermi_t(
            JSON.parse(permi).import.write
              ? JSON.parse(permi).import.write
              : false
          )
        }
      }
      return () => {
        // setPermi_t(false)
      }
    }, [permi])

    useEffect(() => {
        form.setFieldsValue({
            cus_taxid : null ,  
            cus_credit : null ,  
            cus_name : props.data_customer.title  , 
            cus_phone : null ,  
            cus_fax : null ,  
            cus_email : null ,  
            zip_id : null ,  
            cus_address : null,  
            cus_remark : props.data_customer.remark ,  
            is_import : true ,  
            cus_code : props.data_customer.gid ,  
        });
        setCustomer_data({
            cus_taxid : null ,  
            cus_credit : null ,  
            cus_name : props.data_customer.title  , 
            cus_phone : null ,  
            cus_fax : null ,  
            cus_email : null ,  
            zip_id : null ,  
            cus_address : null,  
            cus_remark : props.data_customer.remark ,  
            is_import : true ,  
            cus_code : props.data_customer.gid ,  
        })
        return () => {
          setCustomer_data({})
          setError_tax_id(null)
          setLocation_select({})
          setPostcode_select([])

        }
      }, [])

      useEffect(() => {
        getFind(customer_data.cus_taxid)
        check_taxID()
        return () => {}
      }, [customer_data.cus_taxid])
    
      function getFind(obj) {
        if (obj) {
          if (isNaN(obj)) {
            setError_tax_id(t("TAX ID is number only"))
          } else {
            setError_tax_id(null)
            setCustomer_data({
              ...customer_data,
              cus_taxid: obj,
            })
          }
        }
      }
    
      function check_taxID() {
        if (customer_data.cus_taxid) {
          if (customer_data.cus_taxid.length === 13) {
            const check_tax = api.CHECK_TAX_ID(token, customer_data.cus_taxid)
            axios(check_tax)
              .then(res => {
                if (res.data === "True") {
                  setError_tax_id(t("tax id is duplicate"))
                } else if (res.data === "False") {
                  setError_tax_id(null)
                }
              })
              .catch(error => {
                console.log(error)
              })
          } else {
            setError_tax_id(t("tax id must equal 13 digit"))
          }
        }
      }
    
      function check_postcode(postcode) {
        if (postcode.length === 0) {
          setPostcode_select([])
          setLocation_select({
            dis_name: null,
            pro_name: null,
            subdis_name: null,
            zip_code: null,
          })
        } else if (postcode.length >= 4) {
          const check_zip_postcode = api.CHECK_POSTCODE(postcode)
          axios(check_zip_postcode)
            .then(res => {
              setPostcode_select(res.data.message)
            })
            .catch(error => {
              // console.log(error);
            })
        }
      }
    
      useEffect(() => {
        render_select_postcode()
        render_location_select()
        render_location_address()
        return () => {}
      }, [postcode_select])
    
      function render_select_postcode() {
        return postcode_select.map(data => {
          return (
            <div
              key={data.zip_id}
              style={{ borderBottom: "1px solid #f0f0f0", padding: "10px" }}
            >
              <a>
                <p
                  style={{ color: "#1890ff" }}
                  onClick={() => {
                    setCustomer_data({
                      ...customer_data,
                      zip_id: data.zip_id,
                    })
                    setPostcode_select([])
                    setLocation_select({
                      ...location_select,
                      dis_name: data.dis_name,
                      pro_name: data.pro_name,
                      subdis_name: data.subdis_name,
                      zip_code: data.zip_code,
                    })
                  }}
                >
                  {data.subdis_name} - {data.dis_name} - {data.pro_name} -{" "}
                  {data.zip_code}
                </p>
              </a>
            </div>
          )
        })
      }
      function render_location_select() {
        if (
          location_select.dis_name ||
          location_select.pro_name ||
          location_select.subdis_name ||
          location_select.zip_code
        ) {
          return (
            <Row>
              <Col sm={12} md={6} lg={6} xl={6}>
                <p className="text-bold">
                  {t("sub-district")} <span style={style_req}>*</span>
                </p>
                <Input placeholder={location_select.subdis_name} disabled />
              </Col>
    
              <Col sm={12} md={6} lg={6} xl={6}>
                <p className="text-bold">
                  {t("district")} <span style={style_req}>*</span>
                </p>
                <Input placeholder={location_select.dis_name} disabled />
              </Col>
    
              <Col sm={12} md={6} lg={6} xl={6}>
                <p className="text-bold mt-3">
                  {t("province")} <span style={style_req}>*</span>
                </p>
                <Input placeholder={location_select.pro_name} disabled />
              </Col>
    
              <Col sm={12} md={6} lg={6} xl={6}>
                <p className="text-bold mt-3">
                  {t("postcode")} <span style={style_req}>*</span>
                </p>
                <Input placeholder={location_select.zip_code} disabled />
              </Col>
            </Row>
          )
        } else {
          return null
        }
      }
    
      function render_location_address() {
        if (
          location_select.dis_name ||
          location_select.pro_name ||
          location_select.subdis_name ||
          location_select.zip_code
        ) {
          return (
            <Row>
              <Col>
                <p className="text-bold  mt-3">
                  {t("address")} <span style={style_req}>*</span>
                </p>
                <Form.Item
                  name="Branch_address"
                  rules={[
                    {
                      required: true,
                      message: t("please input address"),
                    },
                  ]}
                >
                  <TextArea
                    placeholder={t("address")}
                    onChange={e =>
                      setCustomer_data({
                        ...customer_data,
                        cus_address: e.target.value,
                      })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          )
        } else {
          return null
        }
      }
    
      const onFinish = values => {
      return  confirm({
          title: t("confirm import customer"),
          icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
          centered: true,
          okText: t("confirm"),
          cancelText: t("no"),
          onOk() {
            setLoading(true)
            create_new_customer(customer_data)
            setLoading(false)
          },
          onCancel() {},
        })
      }
    
      function create_new_customer(customer_data) {
        let customer_send = {
          customer : {
            ...customer_data
          }
        }
        const import_customer = api.IMPORT_CUSTOMER_FROM_DB_DISTAR(token, customer_data.cus_code , customer_send)
        axios(import_customer)
          .then(res => {
            setLoading(false)
            openNotificationWithIcon("success", t("import customer success"))
            // onReset()
   
            history.push(`/customers/${res.data.customer_id}`)
          })
          .catch(error => {
            setLoading(false)
            if(error){
                if(error.response){
                    if (error.response.status === 401) {
                        helpers.re_login()
                        history.push("/login")
                      } else {
                        openNotificationWithIcon(
                          "error",
                          t("create new customer error please try again")
                        )
                      }
                }
            }
          })
      }
    
      const openNotificationWithIcon = (type, message) => {
        notification[type]({
          message: message,
        })
      }
    
      const onReset = () => {
        form.resetFields();
        setCustomer_data({
            cus_taxid : null ,  
            cus_credit : null ,  
            cus_name : props.data_customer.title  , 
            cus_phone : null ,  
            cus_fax : null ,  
            cus_email : null ,  
            zip_id : null ,  
            cus_address : null,  
            cus_remark : props.data_customer.remark ,  
            is_import : true ,  
            cus_code : props.data_customer.gid ,  
        })
        setError_tax_id(null)
        setLocation_select({})
        setPostcode_select([])
        form.setFieldsValue({
            cus_taxid : null ,  
            cus_credit : null ,  
            cus_name : props.data_customer.title  , 
            cus_phone : null ,  
            cus_fax : null ,  
            cus_email : null ,  
            zip_id : null ,  
            cus_address : null,  
            cus_remark : props.data_customer.remark ,  
            is_import : true ,  
            cus_code : props.data_customer.gid ,  
        });
      }

    // console.log(props.data_customer);

    return (
        <div>
        <Form
        form={form}
        name="create_new_customer_form_info"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp_Onblur
              header={t("tax id")}
              placeholder={t("tax id")}
              headState={setCustomer_data}
              nameState={customer_data}
              keyState="cus_taxid"
              type="text"
              maxLength={13}
            />
            <p style={{ color: "red" }}>{error_tax_id}</p>
          </Col>
          <Col sm={12} md={2} lg={2} xl={2}>
            <Input_Number
              header={t("credit")}
              placeholder={t("credit")}
              headState={setCustomer_data}
              nameState={customer_data}
              keyState="cus_credit"
              maxLength={null}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("customer name")}
              placeholder={t("customer name")}
              headState={setCustomer_data}
              nameState={customer_data}
              keyState="cus_name"
              type="text"
              maxLength={null}
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("customer phone")}
              placeholder={t("customer phone")}
              headState={setCustomer_data}
              nameState={customer_data}
              keyState="cus_phone"
              type="number"
              maxLength={null}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("fax")}
              placeholder={t("fax")}
              headState={setCustomer_data}
              nameState={customer_data}
              keyState="cus_fax"
              type="text"
              maxLength={null}
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("email")}
              placeholder={t("email")}
              headState={setCustomer_data}
              nameState={customer_data}
              keyState="cus_email"
              type="email"
              maxLength={null}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <p className="text-bold">
              {t("postcode")} <span style={{ color: "red" }}>*</span>
            </p>
            <Form.Item
              name="select_postcode"
              rules={[
                {
                  required: true,
                  message: `${t("please input")} ${t("postcode")}`,
                },
              ]}
            >
              <Input
                type="number"
                placeholder={t("postcode")}
                onChange={e => check_postcode(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        {render_select_postcode()}
        {render_location_select()}
        {render_location_address()}

        <br />
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div className="div_create_new_car">
              <Button
                className="btn_cancel_create_new_car"
                onClick={() => {
                  onReset()
                }}
              >
                {" "}
                {t("cancel")}
              </Button>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div className="div_create_new_car">
              <Button
                className="btn_submit_create_new_car"
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={error_tax_id ? error_tax_id : false}
              >
                {t("Import customer")}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
        </div>
    )
}
