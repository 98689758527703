import React from "react"
import { useTranslation } from "react-i18next"
import { FcSearch, FcDownload } from "react-icons/fc"
import { Tooltip } from "antd"
import axios from "axios"
import * as api from "../../../../../../service/Api_Edit_Service"

export default function PreviewFileUpload(props) {
    const { t } = useTranslation()
    const token = localStorage.getItem("token")

    function downloadFile(file) {
        const download = api.DOWNLOAD_FILE_ONE(token, file.path)
        axios(download).then(res => {
          const type = res.headers["content-type"]
          const blob = new Blob([res.data], { type: type, encoding: "UTF-8" })
          const link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = file.fup_oldname
          link.click()
        })
      }

      function checkType(data) {
        if (
          data.fup_extension_type === "image/jpeg" ||
          data.fup_extension_type === "image/png"
        ) {
          return (
            <span style={{ marginLeft: "20px" }}>
              <Tooltip title={t("View file upload")}>
                <a>
                  <FcSearch
                    style={{ fontSize: "20px" }}
                    onClick={() => window.open(`/read-image/${data.path}`)}
                  />
                </a>
              </Tooltip>
            </span>
          )
        }
      }

    return (
<div
    >
      <p className="text-bold"> {t("File upload")} </p>
      {props.value.map((data, index) => {
        return (
          <div key={index}>
            <p>
              {index + 1} . {data.fup_oldname}
              {checkType(data)}
              <span style={{ marginLeft: "20px" }}>
                <Tooltip title={t("Download file")}>
                  <a>
                    <FcDownload
                      style={{ fontSize: "20px" }}
                      onClick={() => downloadFile(data)}
                    />
                  </a>
                </Tooltip>
              </span>
            </p>
          </div>
        )
      })}
    </div>
    )
}
