import React, { useState, useEffect } from "react"
import Table_Branch from "./Table_Branch"
import Form_Data from "./Table_Branch/Form_Data"
import { Form, Button, Tabs, Modal, notification } from "antd"
import { Row, Col } from "reactstrap"
import axios from "axios"
import * as api from "../../../../service/Api_New_Customer"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs
const { confirm } = Modal

export default function Branch_new(props) {
  const [form] = Form.useForm()
  const { t } = useTranslation();
  const [error_Branch, setError_Branch] = useState(null)
  const [activeTab_Branch, setActiveTab_Branch] = useState("1")
  const token = localStorage.getItem("token")
  const [loading, setLoading] = useState(false)
  const [postcode_select, setPostcode_select] = useState([])
  const [location_select, setLocation_select] = useState({
    dis_name: null,
    pro_name: null,
    subdis_name: null,
    zip_code: null,
  })
  const [branch, setBranch] = useState({
    cus_id: props.id,
  })

  useEffect(() => {
    function branch_check_data() {
      if (branch.cus_branch_code) {
        if (branch.cus_branch_code.length === 5) {
          const check = api.CHECK_BRANCH(
            token,
            props.id,
            branch.cus_branch_code
          )
          axios(check)
            .then(res => {
              if (res.data === "True") {
                setError_Branch(t("branch code is duplicate"))
              } else {
                setError_Branch(null)
              }
            })
            .catch(error => {
              //    console.log(error);
            })
        }
      }
    }

    branch_check_data()
    return () => {}
  }, [branch.cus_branch_code])

  function check_postcode(postcode) {
    if (postcode.length === 0) {
      setLocation_select({
        dis_name: null,
        pro_name: null,
        subdis_name: null,
        zip_code: null,
      })
    } else if (postcode.length >= 4) {
      const check_zip_postcode = api.CHECK_POSTCODE(postcode)
      axios(check_zip_postcode)
        .then(res => {
          setPostcode_select(res.data.message)
        })
        .catch(error => {
          // console.log(error);
        })
    }
  }

  const onFinish = values => {
    if (!branch.cus_branch_lat && !branch.cus_branch_long) {
      return Modal.error({
        title: t("please select location on map"),
        centered: true,
      })
    } else if (
      branch.cus_branch_lat === null &&
      branch.cus_branch_long === null
    ) {
      return Modal.error({
        title: t("please select location on map"),
        centered: true,
      })
    } else {
      return confirm({
        title: t("confirm create new branch"),
        icon: <ExclamationCircleOutlined />,
        okText: t("confirm"),
        cancelText: t("cancel") ,
        centered: true,
        onOk() {
        create_new_branch()
        },
        onCancel() {},
      })
    }
  }

  function create_new_branch() {
    setLoading(true)
    const create_ = api.CREATE_NEW_BRANCH(token, branch)
    axios(create_)
      .then(res => {
        setLoading(false)
        openNotificationWithIcon("success", t("create new branch success"))
        props.search_data_branch()
        onReset()
        setActiveTab_Branch('1')
      })
      .catch(error => {
        console.log(error.response)
        setLoading(false)
        openNotificationWithIcon("error", t("create new branch error") )
      })
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  const onReset = () => {
    form.resetFields()
    setBranch({
      ...branch,
      cus_id: props.id,
      cus_branch_long: null,
      cus_branch_lat: null,
    })
    setLocation_select({
      ...location_select,
      dis_name: null,
      pro_name: null,
      subdis_name: null,
      zip_code: null,
    })
  }

  return (
   <div>
   <Table_Branch
   id={props.id}
   branch_table={props.branch_table}
   setData_search_branch={props.setData_search_branch}
   page_limit_branch={props.page_limit_branch}
   search_data_branch={props.search_data_branch}
   changePagination={props.changePagination}
   loading_branch={props.loading_branch}
 />
   </div>
  )
}
