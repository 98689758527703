import React, { useState, useEffect } from "react"
import { Select, Form } from "antd"
import * as api from "../../../../service/Api_New_Employee"
import axios from "axios"

const { Option } = Select
export default function Select_no_search({
  header,
  keyState,
  name_api,
  headState,
  nameState,
  map_name,
  placeholder,
  map_value,
}) {
  const token = localStorage.getItem("token")
  const [data_select, setData_select] = useState([])

  useEffect(() => {
    check_data_select()
    return () => {
      setData_select([])
    }
  }, [])

  function check_data_select() {
    const check_data_sel = api[name_api](token)
    axios(check_data_sel)
      .then(res => {
        setData_select(res.data.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  function onChange(value) {
    if (value) {
      headState(value)
    } else {
        headState(null)
    }
  }

  useEffect(() => {
    render_select_data()
    return () => {}
  }, [data_select])

  function render_select_data() {
    return data_select.map(data => {
      return (
        <Option key={data[map_value]} value={data[map_value]}>
          {data[map_name]}
        </Option>
      )
    })
  }
  return (
    <div>
      <p className="text-bold header-filter">{header}</p>
      <Select
      showSearch
      allowClear
      placeholder={placeholder}
      style={{ width: "100%" }}
      optionFilterProp="children"
      onChange={onChange}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {render_select_data()}
    </Select>
    </div>
  )
}
