import React from "react";
import { Row, Col } from "reactstrap"
import { Input, Button, Tag } from "antd";
import SignatureCanvas from 'react-signature-canvas'
import UploadImage from './component/UploadImage'
import { useTranslation } from "react-i18next";

import './component/styles.module.sig.css'

export default function TextForm(props) {
  const { t } = useTranslation();

  function render_priority(priority) {
    return  props.priority.map( (data, index) => {
      if(priority === data.task_priority_id){
        switch (data.task_priority_name) {
          case "urgent":
            return <Tag color="error" key={index}> {t(data.task_priority_name)} </Tag>
            break
          case "high":
            return <Tag color="warning" key={index}>{t(data.task_priority_name)}</Tag>
            break
          case "medium":
            return <Tag color="processing" key={index}>{t(data.task_priority_name)}</Tag>
            break
          case "low":
            return <Tag color="success" key={index}>{t(data.task_priority_name)}</Tag>
            break
          default:
            return null
        }
      }
    })
  }

  function renderForm() {
    return props.fields.map((form, index) => {
      switch (form.type) {
        case "text":
          return (
            <Row key={index}>
              <Col sm={6} xs={6} md={4} lg={4}>
                <p>{form.task_name}</p>
                {render_priority(form.task_priority_id)}
              </Col>
              <Col sm={6} xs={6} md={4} lg={4}>
                <Input type={form.type} style={{ marginBottom: "10px" }} />
                <p>{form.task_detail}</p>
              </Col>
              <Col sm={0} xs={0} md={4} lg={4}></Col>
            </Row>
          );
          break;
        case "checkIn":
          return (
            <Row key={index}>
              <Col sm={6} xs={6} md={4} lg={4}>
                <p>{form.task_name}</p>
                {render_priority(form.task_priority_id)}
              </Col>
              <Col sm={6} xs={6} md={4} lg={4}>
                <Button
                  style={{
                    background: "#2FAC0A",
                    borderColor: "#2FAC0A",
                    color: "#FFFF",
                    borderRadius: "25px",
                    marginBottom: "10px",
                  }}
                >
                  {t("Check in here")}
                </Button>
                <p>{form.task_detail}</p>
              </Col>
              <Col sm={0} xs={0} md={4} lg={4}></Col>
            </Row>
          );
          break;
        case "checkOut":
          return (
            <Row key={index}>
              <Col sm={6} xs={6} md={4} lg={4}>
                <p>{form.task_name}</p>
                {render_priority(form.task_priority_id)}
              </Col>
              <Col sm={6} xs={6} md={4} lg={4}>
                <Button
                  style={{
                    background: "#D83A56",
                    borderColor: "#D83A56",
                    color: "#FFFF",
                    borderRadius: "25px",
                    marginBottom: "10px",
                  }}
                >
                  {t("Check out here")}
                </Button>
                <p>{form.task_detail}</p>
              </Col>
              <Col sm={0} xs={0} md={4} lg={4}></Col>
            </Row>
          );
          break;
          case "uploadFile":
            return (
              <Row key={index}>
                <Col sm={6} xs={6} md={4} lg={4}>
                  <p>{form.task_name}</p>
                  {render_priority(form.task_priority_id)}
                </Col>
                <Col sm={6} xs={6} md={4} lg={4}>
                  <Button >
                    {t("upload file")}
                  </Button>
                  <p>{form.task_detail}</p>
                </Col>
                <Col sm={0} xs={0} md={4} lg={4}></Col>
              </Row>
            );
            break;
        case "signature":
          return (
            <Row key={index}>
              <Col sm={6} xs={6} md={4} lg={4}>
                <p>{form.task_name}</p>
                {render_priority(form.task_priority_id)}
              </Col>
              <Col sm={6} xs={6} md={4} lg={4}>
                <div style={{ marginBottom:'20px' }} >
                  <SignatureCanvas
                    clearOnResize={false}
                    canvasProps={{ className: "sigPad", height: 300 }}
                    backgroundColor='#F1F0F0'
                  />
                </div>
                <p>{form.task_detail}</p>
              </Col>
              <Col sm={0} xs={0} md={4} lg={4}></Col>
            </Row>
          );
          break;
            case "photo":
              return (
                <Row key={index}>
                  <Col sm={6} xs={6} md={4} lg={4}>
                    <p>{form.task_name}</p>
                    {render_priority(form.task_priority_id)}
                  </Col>
                  <Col sm={6} xs={6} md={8} lg={8}>
                 <div style={{ marginBottom:'10px' }}>
                 <UploadImage />
                 </div>
                 <p>{form.task_detail}</p>
                  </Col>
       
                </Row>
              );
              break;
        default:
          return null;
      }
    });
  }

  return <>{renderForm()}</>;
}