import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { Tooltip } from "antd"
import { FcAcceptDatabase } from "react-icons/fc"
import { useTranslation } from "react-i18next";

export default function Detail_Dealer_My_DB(props) {
  const [dealer_info, setDealer_info] = useState([])
  const { t } = useTranslation();

  useEffect(() => {
    setDealer_info([
      {
        head_data1: t("Dealer code"),
        value_data1: check_data(props.dealer_info.dea_code),
        head_data2: t("Dealer name"),
        value_data2: check_data(props.dealer_info.dea_name),
      },
      {
        head_data1: t("Dealer TAX ID"),
        value_data1: check_data(props.dealer_info.dea_taxid),
        head_data2: t("Dealer credit"),
        value_data2: check_data(props.dealer_info.dea_credit),
      },
      {
        head_data1: t("Dealer phone"),
        value_data1: check_data(props.dealer_info.dea_phone),
        head_data2: t("Dealer email"),
        value_data2: check_data(props.dealer_info.dea_email),
      },
      {
        head_data1: t("Dealer fax"),
        value_data1: check_data(props.dealer_info.dea_fax),
        head_data2: t("Dealer address"),
        value_data2: check_data(props.dealer_info.dea_address),
      },
      {
        head_data1: t("Contact firstname"),
        value_data1: check_data(props.dealer_info.dea_contact_firstname),
        head_data2: t("Contact lastname"),
        value_data2: check_data(props.dealer_info.dea_contact_lastname),
      },
      {
        head_data1: t("Contact mobile"),
        value_data1: check_data(props.dealer_info.dea_contact_mobile),
        head_data2: t("Contact email"),
        value_data2: check_data(props.dealer_info.dea_contact_email),
      },
    ])

    return () => {
      setDealer_info([])
    }
  }, [props.dealer_info])

  function check_data(data) {
    if (data) {
      return data
    } else {
      return null
    }
  }

  return (
    <div className="border-card-info" style={{ marginBottom:'40px' }}>
      <p className="text-header-information"> {t("Dealer information")}  
        <Tooltip title={t("This dealer already exists in the database")}>
          <FcAcceptDatabase
            size={25}
            style={{ marginLeft: "5px" }}
          />
        </Tooltip> 
      </p>

      {dealer_info.map((data, index) => {
        return (
          <Row key={index}>
            <Col sm={12} md={6} lg={2} xl={2}>
              <p className="text-bold">{data.head_data1}</p>
            </Col>
            <Col sm={12} md={6} lg={4} xl={4}>
              <p style={{ fontSize: "15px" }}>
                {" "}
                <span className="tet">
                  :
                </span> <span className="tetTwo">-</span> {data.value_data1}{" "}
              </p>
            </Col>
            <Col sm={12} md={6} lg={2} xl={2}>
              <p className="text-bold">{data.head_data2}</p>
            </Col>
            <Col sm={12} md={6} lg={4} xl={4}>
              <p style={{ fontSize: "15px" }}>
                {" "}
                <span className="tet">
                  :
                </span> <span className="tetTwo">-</span> {data.value_data2}{" "}
              </p>
            </Col>
          </Row>
        )
      })}
    </div>
  )
}
