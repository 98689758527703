import React, { useState, useEffect } from "react"
import { Form, Button, Modal, notification } from "antd"
import { Row, Col } from "reactstrap"
import Input_Comp from "./Components/Input_Comp"
import Input_Date_Comp from "./Components/Input_Date_Comp"
import Select_no_search from "./Components/Select_no_search"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { stubFalse } from "lodash"
import { useTranslation } from "react-i18next"

const { confirm } = Modal

export default function Create_New_Contract(props) {
  const [form_create_new_contract_only] = Form.useForm()
  const { t } = useTranslation()

  useEffect(() => {
    if (props.customer_create === false) {
      form_create_new_contract_only.resetFields()
    }
    return () => {}
  }, [props.customer_create])

  const onFinish = values => {
    return confirm({
      title: t("Confirm create data"),
      icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
      okText: t("create"),
      cancelText: t("no"),
      centered: true,
      onOk() {
        props.setVisible(false)
        props.setContract_create_only(true)
      },
      onCancel() {},
    })
  }

  const cancel = value => {
    props.setVisible(false)
    form_create_new_contract_only.resetFields()
    props.setContract_create_only(false)
    props.setCreate_new_contract({})
  }

  return (
    <div>
      <Form
        form={form_create_new_contract_only}
        name="create_new_contract_data_info"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Contract name")}
              placeholder={t("Contract name")}
              headState={props.setCreate_new_contract}
              nameState={props.create_new_contract}
              type="text"
              keyState="con_name"
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Date_Comp
              header={t("Date start")}
              placeholder={t("Date start")}
              headState={props.setCreate_new_contract}
              nameState={props.create_new_contract}
              keyState="con_start_date"
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Date_Comp
              header={t("Date end")}
              placeholder={t("Date end")}
              headState={props.setCreate_new_contract}
              nameState={props.create_new_contract}
              keyState="con_end_date"
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Contact firstname")}
              placeholder={t("Contact firstname")}
              headState={props.setCreate_new_contract}
              nameState={props.create_new_contract}
              keyState="con_contact_firstname"
              type="text"
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Contact lastname")}
              placeholder={t("Contact lastname")}
              headState={props.setCreate_new_contract}
              nameState={props.create_new_contract}
              keyState="con_contact_lastname"
              type="text"
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Contact position")}
              placeholder={t("Contact position")}
              name_api="LIST_POSITION"
              map_name="pos_name"
              map_value="pos_id"
              keyState="con_contact_position_id"
              headState={props.setCreate_new_contract}
              nameState={props.create_new_contract}
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Contact mobile")}
              placeholder={t("Contact mobile")}
              headState={props.setCreate_new_contract}
              nameState={props.create_new_contract}
              keyState="con_contact_mobile"
              type="number"
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Contact email")}
              placeholder={t("Contact email")}
              headState={props.setCreate_new_contract}
              nameState={props.create_new_contract}
              keyState="con_contact_email"
              type="email"
            />
          </Col>
        </Row>

        <br />
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div className="div_create_new_car">
              <Button
                className="btn_cancel_create_new_car"
                onClick={() => cancel()}
              >
                {" "}
                {t("cancel")}{" "}
              </Button>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div className="div_create_new_car">
              <Button
                className="btn_submit_create_new_car"
                type="primary"
                htmlType="submit"
              >
                {" "}
                {t("Create")}{" "}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
