import React from "react"
import { Row, Col } from "reactstrap"
import { Avatar } from "antd"
import { UserOutlined } from "@ant-design/icons"
import Render_Center_Avatar from "../../Operator/Table_data/Render_Center_Avatar"
import Colum from "./components/Colum"
import { useTranslation } from "react-i18next";

export default function Datail_Operator(props) {
  const { t } = useTranslation();
  const text_header = {
    fontWeight: "bold",
    fontSize: "15px",
  }

  return (
    <div>
      <p
        style={{
          textAlign: "center",
          fontWeight: "bold",
          marginTop: "10px",
          fontSize: "15px",
        }}
      >
        {t("Operator information")}
      </p>

      <Row>
        <Col>
          <Render_Center_Avatar
            path_image={props.detail_Operator.ope_avatar}
            size={120}
          />
          <br />
        </Col>
      </Row>

      <Row>
        <Colum
          header_text={t("Operator firstname")}
          value_text={props.detail_Operator.ope_firstname}
        />

        <Colum
          header_text={t("Operator lastname")}
          value_text={props.detail_Operator.ope_lastname}
        />

        <Colum
          header_text={t("Operator nickname")}
          value_text={props.detail_Operator.ope_nickname}
        />

        <Colum
          header_text={t("Operator group")}
          value_text={props.detail_Operator.ope_group_name}
        />

        <Colum
          header_text={t("Operator code")}
          value_text={props.detail_Operator.ope_code}
        />

        <Colum
          header_text={t("Operator mobile")}
          value_text={props.detail_Operator.ope_mobile}
        />

        <Colum
          header_text={t("Operator email")}
          value_text={props.detail_Operator.ope_email}
        />
        <Colum
          header_text={t("Department")}
          value_text={props.detail_Operator.dep_name}
        />
        <Colum
          header_text={t("Position")}
          value_text={props.detail_Operator.pos_name}
        />
      </Row>
    </div>
  )
}
