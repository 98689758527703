import React,{ useState, useCallback, useRef, useEffect } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { AutoComplete, Form } from 'antd';
import img_ping from './ping.png'

const { Option } = AutoComplete;
const libraries = ["places"];
const mapContainerStyle = {
  height: "500px"
};
const options = {
  // styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
};

export default function Edit_Map_location(props) {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_APP,
        libraries,
      });
      const [center, setCenter] = useState({
        lat: 13.2753119,
        lng: 101.4843104,
      })
      const [map_marker, setMap_marker] = useState(null)
      const [zoom_gg_map, setZoom_gg_map] = useState(6)

      useEffect(() => {
        if(props.branch.cus_branch_long === null && props.branch.cus_branch_lat === null){
          setMap_marker(null)
          setZoom_gg_map(6)
          setCenter({
            ...center,
            lat: 13.2753119,
            lng: 101.4843104,
          })
        } else {
            
            setCenter({
                ...center,
                lat: parseFloat(props.branch.cus_branch_lat),
                lng: parseFloat(props.branch.cus_branch_long),
              })
            setZoom_gg_map(16)
            setMap_marker({
                lat: parseFloat(props.branch.cus_branch_lat),
                lng: parseFloat(props.branch.cus_branch_long),
                time: new Date()
              })
        }
        return () => {
          
        }
      }, [props.branch.cus_branch_lat,props.branch.cus_branch_long])

    const onMapClick = (e) => {
      let lat = e.latLng.lat()
      let lng = e.latLng.lng()
      // console.log(lat);
      // console.log(lng);
      // console.log('select location');
      props.setBranch_edit({
          ...props.branch_edit,
          cus_branch_lat: lat.toString(),
          cus_branch_long: lng.toString()
      })

      setMap_marker({
        lat: lat,
        lng: lng,
        time: new Date()
      })

    }
    
      // const onMapClick = useCallback((e) => {
      //   let lat = e.latLng.lat()
      //   let lng = e.latLng.lng()
      //   console.log(lat);
      //   console.log(lng);
      //   console.log('select location');
       
      //   setMap_marker({
      //     lat: lat,
      //     lng: lng,
      //     time: new Date()
      //   })

      // }, []);
    
      const mapRef = useRef();
      const onMapLoad = useCallback((map) => {
        mapRef.current = map;
      }, []);
    
      const panTo = useCallback(({ lat, lng }) => {
        mapRef.current.panTo({ lat, lng });
        mapRef.current.setZoom(14);
      }, []);

      if (loadError) return "Error";
      if (!isLoaded) return "Loading...";

    return (
<div>
      {/*<div>
        <button
          className="locate"
          onClick={() => {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                panTo({
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                });
              },
              () => null
            );
          }}
        >
          Location user
        </button>
        </div>*/}
      <Search 
        panTo={panTo} 
        setMap_marker={setMap_marker}
        setZoom_gg_map={setZoom_gg_map}
        setBranch_edit={props.setBranch_edit}
        branch_edit={props.branch_edit}
      />
<br />
      <GoogleMap
        id="map"
        mapContainerStyle={mapContainerStyle}
        zoom={zoom_gg_map}
        center={center}
        options={options}
        onClick={onMapClick}
        onLoad={onMapLoad}
        defaultZoom={6}
        defaultCenter={{ lat: 13.2753119, lng: 101.4843104 }}
      >

        { map_marker && (
          <Marker
            key={`${map_marker.lat}-${map_marker.lng}`}
            position={{ lat: map_marker.lat, lng: map_marker.lng }}
            // onClick={() => {
            //   setSelected(map_marker);
            // }}
            icon={{
              url: img_ping,
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              // scaledSize: new window.google.maps.Size(30, 30),
            }}
          />
        )
        }

      </GoogleMap>
    </div>
    )
}

function Search({ panTo, setMap_marker, setZoom_gg_map,setBranch_edit,branch_edit }) {
    const {
      ready,
      value,
      suggestions: { status, data },
      setValue,
      clearSuggestions,
    } = usePlacesAutocomplete({
      requestOptions: {
        location: { lat: () => 43.6532, lng: () => -79.3832 },
        radius: 100 * 1000,
      },
    });
  
    const handleInputTwo = (e) => {
      setValue(e);
    };
  
    const handleSelect = async (address) => {
      setValue(address, false);
      clearSuggestions();
  
      try {
        const results = await getGeocode({ address });
        // console.log(results);
        const { lat, lng } = await getLatLng(results[0]);
        panTo({ lat, lng });
        setMap_marker({
          lat: lat,
          lng: lng,
          time: new Date()
        })
        setZoom_gg_map(16)

        setBranch_edit({
            ...branch_edit,
            cus_branch_lat: lat.toString(),
            cus_branch_long: lng.toString()
          })
        // console.log(lat);
        // console.log(lng);
        // console.log('Location ');
      } catch (error) {
        console.log(error);
      }
    };
  
  
    return (
  <div>
  <Form.Item
            name="search_google_map"
            rules={[
              {
                required: false,
                message: "Please search location",
              },
            ]}
          >
          <AutoComplete
          style={{
            width: '100%',
          }}
          onSearch={handleInputTwo}
          onSelect={handleSelect}
          placeholder="Search location"
        >
          {
              data.map((detail, index) => (
              <Option key={index} value={detail.description}>
                {detail.description}
              </Option>
            ))
          }
          </AutoComplete>
          </Form.Item> 
        
      </div>
    );
  }
  