import React, { useRef, useState, useEffect } from "react"
import SignaturePad from "react-signature-canvas"
import "./component/styles.module.sig.css"
import { Button } from "antd"
import axios from "axios"
import * as api from "../../../../../service/service_operator/Api_Service"
import { useTranslation } from "react-i18next";

export default function SignaturePad_text(props) {
  const [img, setImg] = useState(null)
  const token = localStorage.getItem("token")
  const sigCanvas = useRef({})
  const { t } = useTranslation();

  useEffect(() => {
    if (props.value !== null) {
      get_image(props.value)
    }
    return () => {
      setImg(null)
    }
  }, [props.value])

  async function get_image(path_image) {
    const get_data_image = api.GET_IMAGE(token, path_image)
    await axios(get_data_image)
      .then(res => {
        const blob = new Blob([res.data], {
          type: "image/jpeg",
        })
        var objectURL = URL.createObjectURL(blob)
        setImg(objectURL)
      })
      .catch(error => {
        // console.log(error.response)
      })
  }



  const clear = () => {
    sigCanvas.current.clear()
    const image_import = [...props.task_hast_form]
    image_import[props.index_step].fields[props.index_field].image = null
    props.setTask_hast_form(image_import)
  }

  const setSignatureOnChange = () => {
    const dataURL = sigCanvas.current.toDataURL()
    const image_import = [...props.task_hast_form]
    image_import[props.index_step].fields[props.index_field].image = dataURL
    props.setTask_hast_form(image_import)
  }

  useEffect(() => {
    if(props.task_hast_form[props.index_step].fields[props.index_field].image === null){
      // console.log(sigCanvas.current.context);
      if (sigCanvas.current != null) {
        if(sigCanvas.current.context !== undefined){
          sigCanvas.current.clear();
        }
        // sigCanvas.current.clear();
      }
    }
    return () => {
      
    }
  }, [props.task_hast_form])

  return (
    <div>
      {props.value === null && (
        <div>
        <div  style={{

          backgroundColor: "#F1F0F0",
        }}>
        <SignaturePad
        ref={sigCanvas}
        clearOnResize={false}
        canvasProps={{ className: "sigPad" }}
        onEnd={setSignatureOnChange}
      />
        </div>
          <Button onClick={() => clear()}> {t("Clear Signature")} </Button>
        </div>
      )}

      {props.value !== null && (
        <div    style={{
          height: "400px",
          backgroundColor: "#F1F0F0",
        }}>
        <img src={img} alt="signature" style={{ width:'100%' , height:'400px' }} />
        </div>
      )}
    </div>
  )
}
