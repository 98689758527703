import React from "react"
import { Row, Col } from "reactstrap"
import { Input } from "antd"
import { useTranslation } from "react-i18next";

const { Search } = Input

export default function Search_data_branch_component(props) {
  const { t } = useTranslation();

  function onSearch(value) {
    props.search_data_branch()
  }

  return (
    <Row>
    <Col sm={12} md={6} lg={6} xl={6}>
      <Search
        style={{ width: "100%" }}
        placeholder="name , search , dealer"
        allowClear
        enterButton={t("search")}
        size="medium"
        onSearch={onSearch}
        onChange={e => props.setData_search_branch(e.target.value)}
      />
    </Col>
  </Row>
  )
}
