import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import * as api from "../../../service/Api_Dashboard"
import axios from "axios"
import All_User from "./All_User"
import Customer from "./Customer"
import Dealer from "./Dealer"
import Employee from "./Employee"
import Operator from "./Operator"
import Admin from "./Admin"
import { Select } from "antd"
import "../Dashboard_style.css"
import online_user from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/online_user.png"
import { useHistory } from "react-router-dom"
import helpers from "../../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"

const { Option } = Select

export default function Online_User(props) {
  const token = localStorage.getItem("token")
  const { t } = useTranslation()
  const [select_user_online, setSelect_user_online] = useState("user")
  const [all_user_count, setAll_user_count] = useState(0)
  const [all_user, setAll_user] = useState([])
  const [all_employee, setAll_employee] = useState([])
  const [all_customer, setAll_customer] = useState([])
  const [all_operator, setAll_operator] = useState([])
  const [all_admin, setAll_admin] = useState([])
  const [all_dealer, setAll_dealer] = useState([])
  const history = useHistory()

  useEffect(() => {
    check_user_online_count()
    all_user_online()
    all_employee_online()
    all_customer_online()
    all_operator_online()
    all_dealer_online()
    all_admin_online()
    return () => {
      setAll_user([])
      setAll_employee([])
      setAll_customer([])
      setAll_operator([])
      setAll_dealer([])
      setAll_admin([])
    }
  }, [])

  useEffect(() => {
    let id = setInterval(() => {
      all_admin_online()
      check_user_online_count()
      all_user_online()
      all_employee_online()
      all_customer_online()
      all_operator_online()
      all_dealer_online()
    }, 15000)
    return () => {
      clearInterval(id)
    }
  })

  function check_user_online_count() {
    const user_count = api.COUNT_ONLINE_USER(token)
    axios(user_count)
      .then(res => {
        setAll_user_count(res.data.data.user_online)
      })
      .catch(error => {
        // console.log(error.response)
      })
  }

  function all_user_online() {
    const user_count = api.SHOW_ALL_USER_ONLINE(token)
    axios(user_count)
      .then(res => {
        setAll_user(res.data.data)
      })
      .catch(error => {
        // console.log(error.response);
      })
  }

  function all_admin_online() {
    const user_count = api.SHOW_ADMIN_ONLINE(token)
    axios(user_count)
      .then(res => {
        setAll_admin(res.data.data)
      })
      .catch(error => {
        // console.log(error.response);
      })
  }

  function all_employee_online() {
    const user_count = api.SHOW_EMPLOYEE_ONLINE(token)
    axios(user_count)
      .then(res => {
        setAll_employee(res.data.data)
      })
      .catch(error => {
        // console.log(error.response);
        // if (error) {
        //   if (error.response) {
        //     if (error.response.status === 401) {
        //       helpers.re_login()
        //       history.push("/login")
        //     }
        //   }
        // }
      })
  }

  function all_customer_online() {
    const user_count = api.SHOW_CUSTOMER_ONLINE(token)
    axios(user_count)
      .then(res => {
        setAll_customer(res.data.data)
      })
      .catch(error => {
        // console.log(error.response);
      })
  }

  function all_operator_online() {
    const user_count = api.SHOW_OPERATOR_ONLINE(token)
    axios(user_count)
      .then(res => {
        setAll_operator(res.data.data)
      })
      .catch(error => {
        // console.log(error.response);
      })
  }

  function all_dealer_online() {
    const user_count = api.SHOW_DEALER_ONLINE(token)
    axios(user_count)
      .then(res => {
        setAll_dealer(res.data.data)
      })
      .catch(error => {
        // console.log(error.response);
      })
  }

  function onChange(value) {
    setSelect_user_online(value)
  }

  function render_header(value) {
    switch (value) {
      case "user":
        return t("online user")
        break
      case "employee":
        return t("online employee")
        break
      case "customer":
        return t("online customer")
        break
      case "operator":
        return t("online operator")
        break
      case "dealer":
        return t("online dealer")
        break
      default:
        return t("online user")
        break
    }
  }

  function render_count(value) {
    switch (value) {
      case "user":
        return all_user_count
        break
      case "employee":
        return all_employee.length
        break
      case "customer":
        return all_customer.length
        break
      case "operator":
        return all_operator.length
        break
      case "dealer":
        return all_dealer.length
        break
      default:
        return all_user_count
        break
    }
  }

  return (
    <div>
      <Row>
        <Col
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className="col-header-online-lg"
        >
          <p className="text-header-user-all-online">
            <img
              src={online_user}
              alt="online user"
              style={{
                width: "25px",
                height: "25px",
                marginRight: "20px",
                marginTop: "-10px",
              }}
            />
            {render_header(select_user_online)}
            <span className="number-user-all-online-header">
              {" "}
              {render_count(select_user_online)}
            </span>{" "}
          </p>
        </Col>

        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <Select
            className="online-user-select"
            defaultValue="user"
            showSearch
            placeholder="user online"
            optionFilterProp="children"
            onChange={onChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="user"> {t("all user")} </Option>
            <Option value="admin"> {t("Admin")} </Option>
            <Option value="employee"> {t("employee")} </Option>
            <Option value="customer">{t("customer")}</Option>
            <Option value="operator">{t("operator")}</Option>
            <Option value="dealer">{t("dealer")}</Option>
          </Select>
        </Col>

        <Col
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className="col-header-online-sm"
        >
          <p className="text-header-user-all-online">
            <img
              src={online_user}
              alt="online user"
              style={{ width: "25px", marginRight: "25px", marginTop: "-8px" }}
            />
            {render_header(select_user_online)}
            <span className="number-user-all-online-header">
              {" "}
              {render_count(select_user_online)}
            </span>{" "}
          </p>
        </Col>
      </Row>

      {select_user_online === "user" && (
        <All_User all_user_count={all_user_count} all_user={all_user} />
      )}
      {select_user_online === "employee" && (
        <Employee all_employee={all_employee} />
      )}
      {select_user_online === "customer" && (
        <Customer all_customer={all_customer} />
      )}
      {select_user_online === "dealer" && <Dealer all_dealer={all_dealer} />}
      {select_user_online === "operator" && (
        <Operator all_operator={all_operator} />
      )}
      {select_user_online === "admin" && <Admin all_admin={all_admin} />}
    </div>
  )
}
