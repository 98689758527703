var Ip = require('../service/Ip')

export const SEARCH_DYNAMIC = (token, url) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}${url}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_PRIORITY = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/service/priority`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_STATUS = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/service/status`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_RECEIVED_TYPE = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/service/received_type`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_CUSTOMER_HAS_STOCK_NO_DATA = (token, cus_id, page, pageSize) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer/customer-has-stock/${cus_id}?page=${page}&limit=${pageSize}&order_by=asc`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_CUSTOMER_HAS_STOCK_DATA = (token, cus_id, page, pageSize, data) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer/customer-has-stock/${cus_id}?page=${page}&limit=${pageSize}&order_by=asc&q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CREATE_NEW_SERVICE = (token, data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/service`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const GENERATE_SERVICE_CODE = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/service/generate-ser-no`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const FIND_DUPLICATE_CODE = (token, data) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/service/check-is-serno-duplicate/${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };
  
  export const LIST_SERVICE_TYPE = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/sertype`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_DATA = (token, page, pageSize, data) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer?page=${page}&limit=${pageSize}&order_by=asc&q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_NO_DATA = (token, page, pageSize) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer?page=${page}&limit=${pageSize}&order_by=asc`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_CUSTOMER_CONTRACT_DATA = (token, cus_id , page, pageSize, data ) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/contract-customer/${cus_id}?page=${page}&limit=${pageSize}&order_by=asc&q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };
  
  export const LIST_CUSTOMER_CONTRACT_NO_DATA = (token, cus_id , page, pageSize ) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/contract-customer/${cus_id}?page=${page}&limit=${pageSize}&order_by=asc`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CHECK_DETAIL_CUSTOMER = (token, cus_id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer/${cus_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const FIND_ALL_STOCK_MY_DB_NO_DATA = (token, page, pageSize) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/service/db/find_all_stock?page=${page}&limit=${pageSize}&order_by=DESC`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const FIND_ALL_STOCK_MY_DB_DATA = (token, page, pageSize, data) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/service/db/find_all_stock?page=${page}&limit=${pageSize}&order_by=DESC&q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const FIND_ALL_STOCK_DISTAR_DB_NO_DATA = (token, page, pageSize) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/service/distardb/find_all_stock?page=${page}&limit=${pageSize}&order_by=DESC`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const FIND_ALL_STOCK_DISTAR_DB_DATA = (token, page, pageSize, data) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/service/distardb/find_all_stock?page=${page}&limit=${pageSize}&order_by=DESC&q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_PRODUCT_GROUP = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/product/group`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };
  
  export const LIST_PRODUCT_WARRANTY = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/product/warranty`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const UPLOAD_IMAGE = (token, formData) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}storage/upload`,
      headers: {
        "Content-type": "multipart/form-data",
        'x-access-token' : token
      },
      data : formData
    };
  };

  export const CHECK_USER_IN_CUSTOMER = (token, cus_id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/service/check-is-customer-has-users/${cus_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const FIND_ALL_ADMIN_GROUP = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/admin/group`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CONFIRM_REPAIR = (token , data , rrs_id) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/requestservice/confirm/${rrs_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const UPLOAD_FILE_SERVICE = (token , formData , ser_id) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}storage/upload?fup_for_id=${ser_id}&fup_for_tb=service`,
      headers: {
        "Content-type": "multipart/form-data",
        'x-access-token' : token
      },
      data : formData
    };
  };