import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Row, Col, Container } from "reactstrap"
import { Button, notification, Modal, Form, Menu, Dropdown } from "antd"
import axios from "axios"
import * as api from "../../service/Api_Service_Status"
import Step_Service from "./Step_Service"
import logo_distar from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/logo_distar.png"
import service_expire from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/service_expire.png"
import Product_Information from "./Product_Information"
import Car_Information from "./Car_Information"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import Input_Comp from "../Operator_All/Service_Operator/Detail_Service/components/Input_Comp"
import cancel_color from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/cancel_color.png"
import Service_Information from "./Service_Information"
import { MdLanguage } from "react-icons/md"
import { useTranslation } from "react-i18next"

import en from "../Setting/image/en.png"
import th from "../Setting/image/th.png"

const { confirm } = Modal
const url_string = window.location.href
const url = new URL(url_string)
const token = url.searchParams.get("token_verification")

export default function Status_Service_Public() {
  const { t, i18n } = useTranslation()
  const [data_all, setData_all] = useState(null)
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [user_accept, setUser_accept] = useState(false)
  const [user_cancel, setUser_cancel] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loading_cancel, setLoading_cancel] = useState(false)
  const [Cancel_service, setCancel_service] = useState({
    data_cancel: null,
  })
  const [remark_after_cancel, setRemark_after_cancel] = useState(null)

  const changeLanguage = lng => {
    i18n.changeLanguage(lng)
    localStorage.setItem("I18N_LANGUAGE", lng)
  }

  function setLanguage(event) {
    if (event === "en") {
      changeLanguage("en")
    } else {
      changeLanguage("th")
    }
  }

  useEffect(() => {
    check_service_status()
    return () => {}
  }, [token])

  useEffect(() => {
    return () => {
      setData_all(null)
    }
  }, [])

  useEffect(() => {
    let id = setInterval(() => {
      check_service_status()
    }, 30000)
    return () => {
      clearInterval(id)
    }
  })

  function check_service_status() {
    const data = {
      token: token,
    }
    const check_status = api.CHECK_SERVICE_STATUS(data)
    axios(check_status)
      .then(res => {
        // console.log(res.data.data[0]);
        if (res.data.data[0].is_user_confirm === 1) {
          setUser_accept(true)
        }
        if (res.data.data[0].is_user_cancel === 1) {
          setUser_cancel(true)
        }
        // console.log(res.data.data[0].is_user_confirm);
        setRemark_after_cancel(res.data.data[0].is_user_remark)
        setData_all(res.data.data[0])
      })
      .catch(error => {
        // console.log(error.response);
        // console.log(error);
      })
  }

  function accept_service() {
    confirm({
      title: t("Confirm accept service"),
      icon: <ExclamationCircleOutlined />,
      centered: true,
      onOk() {
        setLoading(true)
        let time_user_accept = new Date()
        let data_accept = {
          is_user_confirm: 1,
          is_user_active_at : time_user_accept
        }
        const accept_status = api.NEW_API_EDIT_SERVICE_STATUS_FROM_USER_PUBLIC(
          token,
          data_all.ser_id,
          data_accept
        )
        axios(accept_status)
          .then(res => {
            setLoading(false)
            check_service_status()
            openNotificationWithIcon("success", t("Accept service success"))
          })
          .catch(error => {
            setLoading(false)
            openNotificationWithIcon("error", t("Accept service error"))
          })
      },
      onCancel() {},
    })
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  function cancel_form() {
    form.resetFields()
    setVisible(false)
    setCancel_service({
      ...Cancel_service,
      data_cancel: null,
    })
  }

  const onFinish = value => {
    return confirm({
      title: t("Confirm cancel service"),
      icon: <ExclamationCircleOutlined />,
      centered: true,
      onOk() {
        setLoading_cancel(true)
        let time_user_accept = new Date()
        let cancel_data = {
          ser_status_id : 6,
          is_user_cancel: 1,
          is_user_remark: Cancel_service.data_cancel,
          is_user_active_at : time_user_accept
        }
        const cancel_status = api.NEW_API_EDIT_SERVICE_STATUS_FROM_USER_PUBLIC(
          token,
          data_all.ser_id,
          cancel_data
        )
        axios(cancel_status)
          .then(res => {
            setLoading_cancel(false)
            setVisible(false)
            check_service_status()
            openNotificationWithIcon("success", t("Cancel service success"))
          })
          .catch(error => {
            setLoading_cancel(false)
            setVisible(false)
            openNotificationWithIcon("error", t("Cancel service error"))
          })
      },
      onCancel() {
        // console.log("Cancel")
      },
    })
  }

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setLanguage('en')}>
      <img src={en} alt="en" style={{ width:'30px', marginRight:'10px' }} /> English
        </Menu.Item>
      <Menu.Item onClick={() => setLanguage('th')}>
      <img src={th} alt="th" style={{ width:'30px', marginRight:'10px' }} /> ไทย
        </Menu.Item>
    </Menu>
  )

  return (
    <React.Fragment>
      <MetaTags>
        <title>{t("Service status")} | Distar</title>
      </MetaTags>
      <div
        style={{
          width: "100%",
          height: "60px",
          backgroundColor: "#0084d1",
          padding: "12px",
        }}
      >
        <img
          src={logo_distar}
          alt="logo_distar"
          className="img-header-logo-distar"
        />
        <div className="icon-setting-language">
        <Dropdown overlay={menu} placement="bottomRight" arrow >
          <a>
            <MdLanguage size={35} style={{ color:'#FFF' }} />
          </a>
        </Dropdown>
          </div>
      </div>

      <div className="account-pages">
        <Container>
          {data_all !== null && (
            <div>
              <p
                style={{
                  fontSize: "30px",
                  marginTop: "15px",
                  textAlign: "center",
                }}
              >
                {t("Service no.")} {data_all.ser_no}
              </p>
              {user_accept === true && user_cancel === false && (
                <Step_Service data_all={data_all} />
              )}
              {user_accept === false && user_cancel === true && (
                <div
                  style={{
                    boxShadow: "rgb(99 99 99 / 20%) 0px 2px 8px 0px",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  <div className="div-status-name">
                    <img src={cancel_color} alt="cancel_color" />
                    <p className="text-status-service-pass"> {t("Cancel")} </p>
                    {remark_after_cancel !== null && (
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "20px",
                          marginTop: "20px",
                        }}
                      >
                        {" "}
                        {t("Remark")} : {remark_after_cancel}
                      </p>
                    )}
                  </div>
                </div>
              )}

              {user_accept === false && user_cancel === false && (
                <Row>
                  <Col sm={12} md={6} lg={6} xl={6}>
                    <div className="div_create_new_car">
                      <Button
                        className="btn_cancel_create_new_car"
                        type="primary"
                        onClick={() => accept_service()}
                        loading={loading}
                      >
                        {t("Accept")}
                      </Button>
                    </div>
                  </Col>
                  <Col sm={12} md={6} lg={6} xl={6}>
                    <div className="div_create_new_car">
                      <Button
                        className="btn_submit_create_new_car"
                        danger
                        onClick={() => setVisible(true)}
                      >
                        {t("Cancel")}
                      </Button>
                    </div>
                  </Col>
                  <br /> <br />
                </Row>
              )}

              <br />
              <Service_Information data_all={data_all} />
              <br />
              <Product_Information data_all={data_all} />
              <br />
              <Car_Information data_all={data_all} />
              <br />
              <br />

              <Modal
                centered
                open={visible}
                onCancel={() => {
                  setVisible(false)
                  cancel_form()
                }}
                footer={false}
              >
                <Form form={form} name="cancel-service" onFinish={onFinish}>
                  <Row>
                    <Input_Comp
                      header="Remark cancel service"
                      placeholder="Remark cancel service"
                      headState={setCancel_service}
                      nameState={Cancel_service}
                      keyState="data_cancel"
                      type="text"
                      maxLength={null}
                    />
                  </Row>
                  <Row>
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <div className="div_create_new_car">
                        <Button
                          className="btn_cancel_create_new_car"
                          onClick={() => cancel_form()}
                        >
                          {t("Cancel")}
                        </Button>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <div className="div_create_new_car">
                        <Button
                          className="btn_submit_create_new_car"
                          type="primary"
                          htmlType="submit"
                          loading={loading_cancel}
                        >
                          {t("Submit")}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Modal>
            </div>
          )}
          {data_all === null && (
            <div>
              <p
                style={{
                  fontSize: "30px",
                  marginTop: "15px",
                  textAlign: "center",
                  marginTop: "50px",
                  fontWeight: "bold",
                }}
              >
                {t("Service is expire")}
              </p>
              <img
                src={service_expire}
                ale="service_expire"
                style={{
                  height: "150px",
                  display: "block",
                  margin: "20px auto",
                }}
              />
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
