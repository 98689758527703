import React, { useState, useEffect } from "react"
import axios from "axios"
import * as api from "../../../service/Api_System_Settings"
import { useTranslation } from "react-i18next"
import { Row, Col } from "reactstrap"
import Input_Comp from "../Components/Input_Comp"
import { Button, Modal, notification, Form } from "antd"
import Select_no_search from "./Select_no_search"
import Input_Number from "./Input_Number"
import Select_Province from "./Select_Province"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import helpers from "../../Function_Redirect/Redirect_login"
import { useHistory } from "react-router-dom"
import Input_Edit_ckeditor from "./Input_Edit_ckeditor"

const { confirm } = Modal

export default function EditSLA(props) {
  const { t } = useTranslation()
  const history = useHistory()
  const token = localStorage.getItem("token")
  const [form_edit] = Form.useForm()
  const [loading_Edit, setLoading_Edit] = useState(false)
  const [sla_service_id, setSla_service_id] = useState(null)
  const [data_edit_sla, setData_edit_sla] = useState({
    sla_priority_id: null,
    sla_resolution: null,
    sla_service_day: null,
    sla_service_hr: null,
    sla_service_min: null,
    sla_service_name: null,
    sla_service_pro_id: null,
    sla_service_remark: null,
  })

  useEffect(() => {
    onFill()
    return () => {}
  }, [props.sla_edit])

  function onFinish_Edit(value) {
      return confirm({
        title: t("Confirm edit SLA"),
        icon: <ExclamationCircleOutlined />,
        centered: true,
        okText: t("Edit"),
        cancelText: t("no"),
        onOk() {
            setLoading_Edit(true)
            const editData = api.EDIT_SLA(token , sla_service_id , data_edit_sla)
            axios(editData)
            .then( res => {
                setLoading_Edit(false)
                openNotificationWithIcon("success", t("Edit SLA success"))
                cancel_form()
                props.check_all_sal()
            })
            .catch( error => {
                setLoading_Edit(false)
                if (error) {
                    if (error.response) {
                      if (error.response.status === 401) {
                        helpers.re_login()
                        history.push("/login")
                      } else {
                        openNotificationWithIcon("error", t("Edit SLA error"))
                      }
                    }
                  }
            })
        },
        onCancel() {},
      })
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  const onFill = () => {
    setSla_service_id(props.sla_edit.sla_service_id)
    setData_edit_sla({
        ...data_edit_sla,
        sla_priority_id: props.sla_edit.sla_priority_id ,
        sla_resolution: props.sla_edit.sla_resolution ,
        sla_service_day: props.sla_edit.sla_service_day ,
        sla_service_hr: props.sla_edit.sla_service_hr ,
        sla_service_min: props.sla_edit.sla_service_min ,
        sla_service_name: props.sla_edit.sla_service_name ,
        sla_service_pro_id: props.sla_edit.sla_service_pro_id ,
        sla_service_remark: props.sla_edit.sla_service_remark ,
    })
    form_edit.setFieldsValue({
        sla_priority_id: props.sla_edit.sla_priority_id ,
        sla_resolution: props.sla_edit.sla_resolution ,
        sla_service_day: props.sla_edit.sla_service_day ,
        sla_service_hr: props.sla_edit.sla_service_hr ,
        sla_service_min: props.sla_edit.sla_service_min ,
        sla_service_name: props.sla_edit.sla_service_name ,
        sla_service_pro_id: props.sla_edit.sla_service_pro_id ,
        sla_service_remark: props.sla_edit.sla_service_remark ,
    });
  };

  function cancel_form() {
      props.setVisible_edit(false)
      props.setSla_edit(null)
      form_edit.resetFields()
      setSla_service_id(null)
      setData_edit_sla({
          ...data_edit_sla,
        sla_priority_id: null,
        sla_resolution: null,
        sla_service_day: null,
        sla_service_hr: null,
        sla_service_min: null,
        sla_service_name: null,
        sla_service_pro_id: null,
        sla_service_remark: null,
      })
  }

  return (
    <div
      className="modal-mobile-system-setting"
      style={{ overflowY: "scroll", overflowX: "hidden", height: "500px" }}
    >
      <Form
        form={form_edit}
        name="form_edit_data"
        onFinish={onFinish_Edit}
      >
           <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              placeholder={t("Name")}
              header={t("Name")}
              headState={setData_edit_sla}
              nameState={data_edit_sla}
              keyState="sla_service_name"
              type="text"
              required={false}
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Priority")}
              keyState="sla_priority_id"
              headState={setData_edit_sla}
              nameState={data_edit_sla}
              map_name="pri_name"
              placeholder={t("Priority")}
              map_value="pri_id"
              disabled={false}
              required={false}
              data_array_map={props.priority_all}
            />
          </Col>

          <Col sm={12} md={12} lg={12} xl={12}>
            <Input_Edit_ckeditor
              placeholder={t("Resolution")}
              header={t("Resolution")}
              headState={setData_edit_sla}
              nameState={data_edit_sla}
              keyState="sla_resolution"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_Province
              header={t("Province")}
              placeholder={t("Province")}
              headState={setData_edit_sla}
              nameState={data_edit_sla}
              keyState="sla_service_pro_id"
              province_all={props.province_all}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Number
              header={t("day")}
              placeholder={t("day")}
              headState={setData_edit_sla}
              nameState={data_edit_sla}
              keyState="sla_service_day"
              maxLength={null}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Number
              header={t("hr")}
              placeholder={t("hr")}
              headState={setData_edit_sla}
              nameState={data_edit_sla}
              keyState="sla_service_hr"
              maxLength={null}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Number
              header={t("min")}
              placeholder={t("min")}
              headState={setData_edit_sla}
              nameState={data_edit_sla}
              keyState="sla_service_min"
              maxLength={null}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              placeholder={t("Remark")}
              header={t("Remark")}
              headState={setData_edit_sla}
              nameState={data_edit_sla}
              keyState="sla_service_remark"
              type="text"
              required={false}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div className="div_create_new_car">
              <Button
                className="btn_cancel_create_new_car"
                onClick={() => {
                  cancel_form()
                }}
              >
                {t("Cancel")}
              </Button>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div className="div_create_new_car">
              <Button
                className="btn_submit_create_new_car"
                type="primary"
                htmlType="submit"
                loading={loading_Edit}
              >
                {t("Edit")}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
