import React from "react"
import { Input, Form } from "antd"

export default function Input_Email({
  placeholder,
  header,
  headState,
  nameState,
  keyState,
  type
}) {
  return (
    <div>
      <p className="text-bold">
        {header} <span style={{ color:'red' }}>*</span>
      </p>
      <Form.Item
        name={keyState}
        rules={[
          {
            required: true,
            message: `${placeholder}`,
          },
        ]}
      >
        <Input
          type={type}
          placeholder={placeholder}
          onBlur={e =>
            headState({ ...nameState, [keyState]: e.target.value })
          }
        />
      </Form.Item>
    </div>
  )
}
