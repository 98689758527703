import React, { useEffect, useState } from "react"
import { Tag } from "antd"
import service_info from "../../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/service_info.png"
import Caed_Detail_Service from "./components/Caed_Detail_Service"
import { useTranslation } from "react-i18next";

export default function Service_Info(props) {
    const [service_new, setService_new] = useState([])
    const [title_data, setTitle_data] = useState(null)
    const [service_issue, setService_issue] = useState("")
    const { t } = useTranslation();

    useEffect(() => {
        if (props.data_service) {
            setTitle_data(props.data_service.ser_no)
            setService_issue(props.data_service.ser_issue_reported)
            setService_new([
                {
                    header1: t("Service number"),
                    dataContract1: checkData(props.data_service.ser_no),
                    header2: t("Service priority"),
                    dataContract2: render_priority(props.data_service.pri_name),
                  },  {
                    header1: t("Service contact name"),
                    dataContract1: checkData(props.data_service.ser_contact_name),
                    header2: t("Service contact phone"),
                    dataContract2: checkData(props.data_service.ser_contact_phone),
                  },{
                    header1: t("Service appointment date"),
                    dataContract1: cutTimeZone(props.data_service.ser_booking_date),
                    header2: t("Service appointment time"),
                    dataContract2: checkData(props.data_service.ser_booking_time),
                  },{
                    header1: t("Service zone"),
                    dataContract1: checkData(props.data_service.geo_name),
                    header2: t("Service province"),
                    dataContract2: checkData(props.data_service.pro_name),
                  },
                  {
                    header1: t("Service district"),
                    dataContract1: checkData(props.data_service.dis_name),
                    header2: t("Service sub-district"),
                    dataContract2: checkData(props.data_service.subdis_name),
                  },  {
                    header1: t("On-Site address"),
                    dataContract1: checkData(props.data_service.ser_onsite_address),
                    header2: t("Service type"),
                    dataContract2: checkData(props.data_service.ser_type_name),
                  },{
                    header1: t("Service received"),
                    dataContract1: checkData(props.data_service.ser_received_data),
                    header2: t("Service remark"),
                    dataContract2: checkData(props.data_service.ser_remark),
                  },
            ])
        }
        return () => {
            
        }
    }, [props.data_service])

    useEffect(() => {
        
        return () => {
            setService_new([])
            setTitle_data(null)
            setService_issue("")
        }
    }, [])

    function checkData(data) {
        if (data) {
          return data
        } else {
          return "N/A"
        }
      }
    
  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return "N/A"
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

    function render_priority(priority) {
        if(priority){
            switch (priority) {
              case "urgent":
                return <Tag color="error" > {t(priority)} </Tag>
                break
              case "high":
                return <Tag color="warning" >{t(priority)}</Tag>
                break
              case "medium":
                return <Tag color="processing" >{t(priority)}</Tag>
                break
              case "low":
                return <Tag color="success" >{t(priority)}</Tag>
                break
              default:
                return null
            }
          }
      }

    return (
        <div>
        <p className="text-bold"> {t("Service information")} </p>
      <Caed_Detail_Service
      default_card={true}
        image_header={service_info}
        alt_image_header="service_info"
        text_header={t("Service information")}
        path_image={null}
        title_data={title_data}
        title_data_tow={null}
        data_map={service_new}
        type_avatar="product"
        use_table={false}
        data_table={null}
        ckeditor_value={service_issue}
      />
        </div>
    )
}
