import React, { useState, useCallback, useRef, useEffect } from "react"
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api"
import { AutoComplete } from "antd"
import Geocode from "react-geocode"

import car from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/car_2.png"
import ambulance from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/ambulance.png"
import vespa from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/vespa.png"
import bus from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/bus.png"
import bicycle from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/bicycle.png"
import van from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/van.png"
import truck from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/truck.png"
import taxi from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/taxi.png"
import pickup from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/pickup.png"
import motorboat from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/motorboat.png"
import motor from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/motor.png"
import no_car from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_car.png"

const libraries = ["places"]
const mapContainerStyle = {
  height: "250px",
}

const options = {
  // styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
}

export default function Edit_Map_location(props) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_APP,
    libraries,
  })
  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_APP)
  Geocode.setLanguage("th")
  const [map_marker, setMap_marker] = useState(null)
  const [zoom_gg_map, setZoom_gg_map] = useState(6)
  const [showingInfoWindow, setShowingInfoWindow] = useState(true)
  const [address_car, setAddress_car] = useState(null)
  const [devtype_title, setDevtype_title] = useState(null)

  useEffect(() => {
    if (props.car_infomation !== null) {
      setDevtype_title({
        devtype_title: props.car_infomation.devtype_title,
        gid: props.car_infomation.gid,
      })
    }
    return () => {
      setDevtype_title(null)
    }
  }, [props.car_infomation])

  useEffect(() => {
    setZoom_gg_map(16)
    setMap_marker({
      lat: parseFloat(props.location_car.lat),
      lng: parseFloat(props.location_car.lng),
      time: new Date(),
    })
    return () => {
      setMap_marker(null)
      setAddress_car(null)
    }
  }, [props.location_car])

  useEffect(() => {
    if (map_marker !== null) {
      map_popup_overview()
    }
    return () => {}
  }, [map_marker])

  const onMapClick = e => {}

  function render_Icon_Car(type_car) {
    if (type_car !== null) { 
      switch (type_car) {
        case "car":
          return {
            url: car,
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(0, 0),
          }
          break
        case "vespa":
           return {
            url: vespa,
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(0, 0),
          }
          break
        case "van":
           return {
            url: van,
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(0, 0),
          }
          break
        case "truck":
           return {
            url: truck,
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(0, 0),
          }
          break
        case "taxi":
           return {
            url: taxi,
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(0, 0),
          }
          break
        case "bus":
           return {
            url: bus,
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(0, 0),
          }
          break
          case "pickup":
             return {
            url: pickup,
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(0, 0),
          }
            break
        case "motorboat":
           return {
            url: motorboat,
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(0, 0),
          }
          break
          case "motor":
             return {
            url: motor,
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(0, 0),
          }
            break
            case "bicycle":
             return {
            url: bicycle,
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(0, 0),
          }
            break
            case "ambulance":
               return {
            url: ambulance,
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(0, 0),
          }
              break
        default:
          return {
            url: car,
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(0, 0),
          }
          break
      }

      // if(type_car === 'car'){
      // let icon_car_type = {
      //   url: car,
      //           origin: new window.google.maps.Point(0, 0),
      //           anchor: new window.google.maps.Point(0, 0),
      // }
      //   return icon_car_type
      // } else {
      //   let icon_car_type = {
      //     url: img_ping,
      //             origin: new window.google.maps.Point(0, 0),
      //             anchor: new window.google.maps.Point(0, 0),
      //   }
      //   return icon_car_type
      // }
    }
  }

  function map_popup_overview() {
    setShowingInfoWindow(true)
    Geocode.fromLatLng(map_marker.lat, map_marker.lng).then(
      response => {
        const address = response.results[0].formatted_address
        setAddress_car(address)
      },
      error => {
        console.error(error)
      }
    )
  }

  const mapRef = useRef()
  const onMapLoad = useCallback(map => {
    mapRef.current = map
  }, [])

  if (loadError) return "Error"
  if (!isLoaded) return "Loading..."

  return (
    <div className="border-card-info" >
      <GoogleMap
        id="map"
        mapContainerStyle={mapContainerStyle}
        zoom={zoom_gg_map}
        center={props.location_car}
        options={options}
        // onClick={onMapClick}
        onLoad={onMapLoad}
        defaultZoom={6}
        defaultCenter={{ lat: 13.2753119, lng: 101.4843104 }}
      >
        {map_marker && (
          <Marker
            onClick={map_popup_overview}
            key={`${map_marker.lat}-${map_marker.lng}`}
            position={{ lat: map_marker.lat, lng: map_marker.lng }}
            icon={render_Icon_Car(props.type_car)}
            // icon={{
            //   url: img_ping,
            //   origin: new window.google.maps.Point(0, 0),
            //   anchor: new window.google.maps.Point(0, 0),
            //   // scaledSize: new window.google.maps.Size(30, 30),
            // }}
          >
            {showingInfoWindow === true && (
              <InfoWindow
                onCloseClick={() => setShowingInfoWindow(false)}
                position={{
                  lat: map_marker.lat,
                  lng: map_marker.lng,
                }}
                // options={{ pixelOffset: new window.google.maps.Point(15, 15) }}
              >
                <div>
                  {devtype_title !== null && (
                    <>
                      <p>Device name : {devtype_title.devtype_title}</p>
                      <p>SN : {devtype_title.gid}</p>
                    </>
                  )}
                  <p>{address_car}</p>
                </div>
              </InfoWindow>
            )}
          </Marker>
        )}
      </GoogleMap>
    </div>
  )
}
