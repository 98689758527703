import React, { useState, useEffect } from "react"
import { Form, Button, Modal, notification, Input } from "antd"
import { Row, Col } from "reactstrap"
import Input_Comp from "../New_Service_Has_Stock_Id/components/Input_Comp"
import Input_Date_Comp from "../New_Service_Has_Stock_Id/components/Input_Date_Comp"
import Input_Time from "../New_Service_Has_Stock_Id/components/Input_Time"
import Select_no_search from "../New_Service_Has_Stock_Id/components/Select_no_search"
import axios from "axios"
import { useHistory } from "react-router-dom"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import * as api from "../../service/Api_New_Service"
import List_User from "../New_Service_Has_Stock_Id/components/List_User"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import Select_no_search_Location from "../New_Service_Has_Stock_Id/components/Select_no_search_Location"
import Select_after_select_geo from "../New_Service_Has_Stock_Id/components/Select_after_select_geo"
import helpers from "../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"
import Map_location from "../New_Service_Has_Stock_Id/components/Map_location"
import Input_Show_Only from "../New_Service_Has_Stock_Id/components/Input_Show_Only"
import SelectSLA from "../New_Service_Has_Stock_Id/components/SelectSLA"

const { confirm } = Modal

export default function Form_Request(props) {
  const token = localStorage.getItem("token")
  const r = localStorage.getItem("r")
  const role = localStorage.getItem("role")
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [form] = Form.useForm()
  const [confirm_request, setConfirm_request] = useState({
    pri_id: null,
    ser_booking_date: null,
    ser_booking_time: null,
    ser_remark: null,
  })

  const onFinish = value => {
    return confirm({
      title: t("Confirm approve repair"),
      icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
      centered: true,
      okText: t("create"),
      cancelText: t("no"),
      onOk() {
        setLoading(true)
        const confirm_repair_service = api.CONFIRM_REPAIR(token , confirm_request , props.rrs_id)
        axios(confirm_repair_service)
          .then(res => {
            openNotificationWithIcon(
              "success",
              t("Approve repair success")
            )
            setLoading(false)
            if (role !== "administrator") {
              history.push({
                pathname: `/service/${res.data.ser_id}`,
                import_admin: true,
              })
            } else {
              history.push(`/service/${res.data.ser_id}`)
            }
          })
          .catch(error => {
            setLoading(false)
            if (error) {
              if (error.response) {
                if (error.response.status === 401) {
                  helpers.re_login()
                  history.push("/login")
                } else {
                  openNotificationWithIcon(
                    "error",
                    t("Approve repair error")
                  )
                }
              }
            }
          })
      },
      onCancel() {},
    })
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  return (
    <div>
      <Form
        form={form}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Date_Comp
              header={t("Service appointment date")}
              placeholder={t("Service appointment date")}
              headState={setConfirm_request}
              nameState={confirm_request}
              keyState="ser_booking_date"
              required={true}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Time
              header={t("Service appointment time")}
              placeholder={t("Service appointment time")}
              headState={setConfirm_request}
              nameState={confirm_request}
              keyState="ser_booking_time"
              required={true}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Service priority")}
              placeholder={t("Service priority")}
              name_api="LIST_PRIORITY"
              map_name="pri_name"
              map_value="pri_id"
              keyState="pri_id"
              headState={setConfirm_request}
              nameState={confirm_request}
              name_array_data="data"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Service remark")}
              placeholder={t("Service remark")}
              headState={setConfirm_request}
              nameState={confirm_request}
              type="text"
              keyState="ser_remark"
              required={true}
            />
          </Col>

          {r === "true" && (
            <Col sm={12} md={6} lg={6} xl={6}>
              <Select_no_search
                header={t("Admin group")}
                placeholder={t("Admin group")}
                name_api="FIND_ALL_ADMIN_GROUP"
                map_name="admin_group_name"
                map_value="admin_group_id"
                keyState="admin_group_id"
                headState={setConfirm_request}
                nameState={confirm_request}
                name_array_data="data"
              />
            </Col>
          )}

<br />
          <Col sm={12} md={12} lg={12} xl={12}>
            <SelectSLA
              setCreate_service={setConfirm_request}
              create_service={confirm_request}
            />
          </Col>
        </Row>

        <br />
        <Row>
          <Col>
          <Button
                className="btn-accept-repair-service-form"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {t("Approve")} 
              </Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
