import React, { useState, useEffect } from "react"
import { Select } from "antd"
import { useTranslation } from "react-i18next"

const { Option } = Select;

export default function FilterIsImportCar({
    headState,
    headerField
}) {
    const { t } = useTranslation()
    
    function onChange(value) {
        if(value){
          headState(value)
        } else {
          headState(null)
        }
      }

    return (
        <div>
                <p className="text-bold header-filter"> {headerField} </p>
               <Select
          allowClear
            showSearch
            placeholder={t("status")}
            style={{ width: "100%" }}
            optionFilterProp="children"
            onChange={onChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
          <Option value="0">
                   {t("not import")}
                </Option>
                <Option value="1">
                    {t("imported")}
                </Option>
          </Select>
        </div>
    )
}