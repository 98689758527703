import React,{ useState, useEffect } from 'react'
import { Form } from 'antd';
import { Row, Col } from "reactstrap"
import moment from 'moment';
import Input_Comp from './Components/Input_Comp'
import Input_Date_Comp from './Components/Input_Date_Comp'
import * as api from '../../../../service/API_New_Contract'
import axios from 'axios';
import { useTranslation } from "react-i18next";

export default function Create_New_Contract(props) {
    const token = localStorage.getItem('token')
    const [form] = Form.useForm();
    const [check_code_dup, setCheck_code_dup] = useState(false)
    const { t } = useTranslation();

    useEffect(() => {
        function check_contract_dup() {
            if(props.new_Contract.con_code){ 
                const check_contract_code = api.CHECK_CONTRACT_CODE_DUPLICATE(token, props.new_Contract.con_code)
               axios(check_contract_code)
               .then( res => {
                   if(res.data === 'True'){
                    props.setError_form(true)
                    setCheck_code_dup(true)
                   } else {
                    setCheck_code_dup(false)
                    props.setError_form(false)
                   }
               })
               .catch( error => {
                   console.log(error.response);
               })
            }
        }

        check_contract_dup()
        return () => {
            setCheck_code_dup(false)
            props.setError_form(false)
        }
    }, [props.new_Contract.con_code])

    useEffect(() => {
        form.setFieldsValue({
            con_start_date: moment(props.car_detail.regtime) ,
            con_end_date: moment(props.car_detail.offtime) ,
          });
   
        return () => {
            
        }
    }, [props.car_detail])

    useEffect(() => {
        props.setNew_Contract({
            con_code: null ,
            con_end_date: formatDate(props.car_detail.offtime) ,
            con_name: null ,
            con_start_date: formatDate(props.car_detail.regtime) 
        })
        return () => {
            
        }
    }, [])

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    return (
        <div style={{ marginTop:'20px' }}>
        <Form
        form={form}
        name="create_new_contract_data_info"
        initialValues={{
          remember: true,
        }}
      >
        
      <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
              <Input_Comp 
                  header={t("Contract number")} 
                  placeholder={t("Contract number")}
                  headState={props.setNew_Contract}
                  nameState={props.new_Contract}
                  type="text"
                  keyState="con_code"
              />
              {
                  check_code_dup && (
                      <p style={{ color:'red' }}> {t("Contract number in duplicate")} </p>
                  )
              }
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
              <Input_Comp 
                  header={t("Contract name")} 
                  placeholder={t("Contract name")}
                  headState={props.setNew_Contract}
                  nameState={props.new_Contract}
                  type="text"
                  keyState="con_name"
              />
          </Col>
      </Row>
  
      <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
              <Input_Date_Comp 
                  header={t("Date start")} 
                  placeholder={t("Date start")}
                  headState={props.setNew_Contract}
                  nameState={props.new_Contract}
                  keyState="con_start_date"
              />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
              <Input_Date_Comp 
                  header={t("Date end")} 
                  placeholder={t("Date end")}
                  headState={props.setNew_Contract}
                  nameState={props.new_Contract}
                  keyState="con_end_date"
              />
          </Col>
      </Row>
  
      </Form>
        </div>
    )
}
