import React , { useEffect , useState } from "react"
import { Container, Card, CardBody } from "reactstrap"
import MetaTags from "react-meta-tags"
import { useHistory } from "react-router-dom"
import Create_newcar from "./Create_newcar"
import { MdKeyboardBackspace } from 'react-icons/md';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux"
import no_permi from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png"

const Create_Car = props => {
  const history = useHistory()
  const { t } = useTranslation();
  const [permi_t, setPermi_t] = useState(false)
  const { permissionAllService } = useSelector(({ Distar }) => Distar)

  useEffect(() => {
    if(permissionAllService){
      if(permissionAllService.car){
        setPermi_t(permissionAllService.car.write)
      }
    }
    return () => {
      // setPermi_t(false)
    }
  }, [permissionAllService])

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Create car")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}> <a><MdKeyboardBackspace style={{ marginRight:'15px', marginBottom:'3px' }}
          onClick={() =>
            history.push("/cars")
          } 
          /></a> {t("Create car")} </h3>
          <br />
          <Card>
            <CardBody>
{
  permi_t === true && (
    <Create_newcar 
                  
    />
  )
}
{permi_t === false && (
            <div>
              <img
                src={no_permi}
                alt="no permission"
                style={{ display: "block", margin: "50px auto" }}
              />
              <p style={{ textAlign: "center", fontSize: "20px" }}>
                {t("no permission write module")}
              </p>
              <p style={{ textAlign: "center", fontSize: "20px" }}>
                {" "}
                {t("please contract administrator")}{" "}
              </p>
            </div>
          )}

            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Create_Car
