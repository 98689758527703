var Ip = require('./Ip')

export const SEND_MESSAGE_NOTIFICATION = (token,cus_user_id , id_message, car_id) => {
  return {
    method: 'post',
    url: `${Ip.getIP()}api/v1/customer/customer_user/${cus_user_id}/send-email/${id_message}/car/${car_id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const SEARCH_CAR_IMPORT = (token, url) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}${url}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_MESSAGE_ACTION = (token,id) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/email/messagecontext/noti_type/${id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_PROVINCE = (token,data) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/location/province?q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_PROVINCE_ALL = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/location/province`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_CAR_BODY_TYPE = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/car/body`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_CAR_BRAND = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/car/brand`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_CAR_MODEL = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/car/model`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_CAR_COLOR = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/car/color`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };
  
  export const LIST_CAR_YEAR = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/car/year`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_DATA = (token, page, pageSize, data) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer?page=${page}&limit=${pageSize}&order_by=asc&q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_NO_DATA = (token, page, pageSize) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer?page=${page}&limit=${pageSize}&order_by=asc`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const SEARCH_DATA = (token, cus_id, page, pageSize, data) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer/${cus_id}/branch?page=${page}&limit=${pageSize}&order_by=asc&q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const SEARCH_NODATA = (token ,cus_id ,page , pageSize) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer/${cus_id}/branch?page=${page}&limit=${pageSize}&order_by=asc`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CREATE_NEW_CAR = (token , data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/car`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const LIST_CAR_FROM_DB_DATA = (token, page, pageSize, data) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}distar/cars?page=${page}&limit=${pageSize}&order_by=DESC&q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_CAR_FROM_DB_NO_DATA = (token, page, pageSize) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}distar/cars?page=${page}&limit=${pageSize}&order_by=DESC`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const FIND_ALL_CAR_GROUP = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/car/group`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };