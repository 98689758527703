import React, { useState, useEffect } from "react"
import Form_Data from "./Form_Data"
import { Form, Button, Pagination, Tabs, Modal, notification } from "antd"
import axios from "axios"
import * as api from "../../../service/Api_New_Customer"
import Table_History_Branch from "./Table_History_Branch"
import { ExclamationCircleOutlined } from "@ant-design/icons"

const { TabPane } = Tabs
const { confirm } = Modal

export default function Branch(props) {
  const [form] = Form.useForm()
  const [activeTab, setActiveTab] = useState("1")
  const [error_Branch, setError_Branch] = useState(null)
  const token = localStorage.getItem("token")
  const [postcode_select, setPostcode_select] = useState([])
  const [location_select, setLocation_select] = useState({
    dis_name: null,
    pro_name: null,
    subdis_name: null,
    zip_code: null,
  })
  const [branch, setBranch] = useState({
    cus_id: props.id,
    cus_branch_code: null,
    cus_branch_name: null,
    cus_branch_address: null,
    zip_id: null,
    cus_branch_phone: null,
    cus_branch_mobile: null,
    cus_branch_fax: null,
    cus_branch_email: null,
    cus_branch_contact_firstname: null,
    cus_branch_contact_lastname: null,
    cus_branch_contact_pos: null,
    cus_branch_contact_email: null,
    cus_branch_contact_mobile: null,
    cus_branch_lat: null,
    cus_branch_long: null,
  })

  {
    /* ตาราง customer history */
  }
  const [
    search_data_customer_created,
    setSearch_data_customer_created,
  ] = useState(null)
  const [customer_table, setCustomer_table] = useState([])
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 10,
    page_Size: 10,
  })

  useEffect(() => {
    function branch_check_data() {
      if (branch.cus_branch_code) {
        if (branch.cus_branch_code.length === 5) {
          const check = api.CHECK_BRANCH(
            token,
            props.id,
            branch.cus_branch_code
          )
          axios(check)
            .then(res => {
              if (res.data === "True") {
                setError_Branch("Branch code is duplicate")
              } else {
                setError_Branch(null)
              }
            })
            .catch(error => {
              //    console.log(error);
            })
        }
      }
    }

    branch_check_data()
    return () => {}
  }, [branch.cus_branch_code])

  function check_postcode(postcode) {
    if (postcode.length === 0) {
      setLocation_select({
        dis_name: null,
        pro_name: null,
        subdis_name: null,
        zip_code: null,
      })
    } else if (postcode.length >= 4) {
      const check_zip_postcode = api.CHECK_POSTCODE(postcode)
      axios(check_zip_postcode)
        .then(res => {
          setPostcode_select(res.data.message)
        })
        .catch(error => {
          // console.log(error);
        })
    }
  }

  useEffect(() => {
    search_customer_created()
    return () => {}
  }, [])

  function search_customer_created() {
    if (search_data_customer_created) {
      const search_data = api.SEARCH_DATA(
        token,
        branch.cus_id,
        1,
        page_limit.page_Size,
        search_data_customer_created
      )
      axios(search_data)
        .then(res => {
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setCustomer_table(res.data.data)
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      const search_no_data = api.SEARCH_NODATA(
        token,
        branch.cus_id,
        1,
        page_limit.page_Size
      )
      axios(search_no_data)
        .then(res => {
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setCustomer_table(res.data.data)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  function changePagination(page, pageSize) {
    setPage_limit({
      ...page_limit,
      page: page,
      page_Size: pageSize,
    })
    if (search_data_customer_created) {
      const search_data = api.SEARCH_DATA(
        token,
        branch.cus_id,
        page,
        pageSize,
        search_data_customer_created
      )
      axios(search_data)
        .then(res => {
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setCustomer_table(res.data.data)
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      const search_no_data = api.SEARCH_NODATA(
        token,
        branch.cus_id,
        page,
        pageSize
      )
      axios(search_no_data)
        .then(res => {
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setCustomer_table(res.data.data)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  const onFinish = values => {
    if (branch.cus_branch_lat === null && branch.cus_branch_long === null) {
      return Modal.error({
        title: "Please select location on map",
        centered: true,
      })
    } else {
      return confirm({
        title: "Confirm create new branch",
        icon: <ExclamationCircleOutlined />,
        okText: "Confirm",
        centered: true,
        onOk() {
          create_new_branch()
        },
        onCancel() {},
      })
    }
  }

  function create_new_branch() {
    const create_ = api.CREATE_NEW_BRANCH(token, branch)
    axios(create_)
      .then(res => {
        openNotificationWithIcon("success", "Create success")
        onReset()
        search_customer_created()
        setActiveTab("1")
        setLocation_select({
          ...location_select,
          dis_name: null,
          pro_name: null,
          subdis_name: null,
          zip_code: null,
        })
      })
      .catch(error => {
        console.log(error.response)
        openNotificationWithIcon("error", "Create error")
      })
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  const onReset = () => {
    form.resetFields()
    setBranch({
      ...branch,
      cus_id: props.id,
      cus_branch_code: null,
      cus_branch_name: null,
      cus_branch_address: null,
      zip_id: null,
      cus_branch_phone: null,
      cus_branch_mobile: null,
      cus_branch_fax: null,
      cus_branch_email: null,
      cus_branch_contact_firstname: null,
      cus_branch_contact_lastname: null,
      cus_branch_contact_pos: null,
      cus_branch_contact_email: null,
      cus_branch_contact_mobile: null,
      cus_branch_lat: null,
      cus_branch_long: null,
    })
  }

  return (
    <>
      <Tabs
        defaultActiveKey="1"
        onTabClick={e => setActiveTab(e)}
        activeKey={activeTab}
      >
        <TabPane tab="Branch" key="1">
          <Table_History_Branch
            customer_table={customer_table}
            setSearch_data_customer_created={setSearch_data_customer_created}
            search_customer_created={search_customer_created}
          />

          {customer_table.length !== 0 && (
            <div>
              <Pagination
                current={page_limit.page}
                total={page_limit.limit}
                onChange={changePagination}
                style={{ marginTop: "20px", float: "right" }}
              />
            </div>
          )}
        </TabPane>
        <TabPane tab="Create branch" key="2">
          <Form
            name="Branch_form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            form={form}
          >
            <Form_Data
              error_Branch={error_Branch}
              setError_Branch={setError_Branch}
              setBranch={setBranch}
              branch={branch}
              check_postcode={check_postcode}
              postcode_select={postcode_select}
              setPostcode_select={setPostcode_select}
              location_select={location_select}
              setLocation_select={setLocation_select}
              setActiveTab={setActiveTab}
              id={props.id}
            />

            <div>
              <Button
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                type="primary"
                htmlType="submit"
                disabled={error_Branch ? error_Branch : null}
              >
                Create new branch
              </Button>
            </div>
          </Form>
        </TabPane>
      </Tabs>
    </>
  )
}
