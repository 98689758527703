import React, { useState, useEffect } from "react"
import { Row, Col, Container, Card, CardBody } from "reactstrap"
import MetaTags from "react-meta-tags"
import { Pagination } from "antd"
import axios from "axios"
import Search_Stock from "./Search_Stock"
import * as api from "../../service/Api_Stock"
import Table_Stock from "./Table_Stock"
import { FcDataSheet } from "react-icons/fc"
import no_permi from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png"
import { useHistory } from "react-router-dom"
import helpers from "../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const Stock = props => {
  const { t } = useTranslation()
  const [search_stock, setSearch_stock] = useState(null)
  const token = localStorage.getItem("token")
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })
  const history = useHistory()
  const [data_list, setData_list] = useState([])
  const [loading, setLoading] = useState(false)
  const { permissionAllService } = useSelector(({ Distar }) => Distar)
  const [filter_stock, setFilter_stock] = useState({
    pro_group_id: null,
    pro_model_id: null,
    cus_id: null,
    dea_id: null,
    car_body_type_id: null,
  })

  const permi = localStorage.getItem("permi")
  const [permi_t_read, setPermi_t_read] = useState(false)

  useEffect(() => {
    if (permissionAllService) {
      if (permissionAllService.stock) {
        setPermi_t_read(permissionAllService.stock.read)
      }
    }
    return () => {}
  }, [permissionAllService])

  const check_data = (params, data) => {
    if (data) {
      return params + data
    } else {
      return ""
    }
  }

  useEffect(() => {
    search_data()
    return () => {}
  }, [filter_stock])

  useEffect(() => {
    search_data()
    return () => {
      setData_list([])
    }
  }, [])

  function search_data() {
    setLoading(true)
    let url = `api/v1/stock?page=1&limit=${
      page_limit.page_Size
    }&order_by=asc${check_data("&q=", search_stock)}${check_data(
      "&pro_group_id=",
      filter_stock.pro_group_id
    )}${check_data("&pro_model_id=", filter_stock.pro_model_id)}${check_data(
      "&cus_id=",
      filter_stock.cus_id
    )}${check_data(
      "&car_body_type_id=",
      filter_stock.car_body_type_id
    )}${check_data("&dea_id=", filter_stock.dea_id)}`
    const search = api.SEARCH_ALL(token, url)
    axios(search)
      .then(res => {
        setLoading(false)
        setPage_limit({
          ...page_limit,
          page: 1,
          limit: res.data.total_data,
        })
        // console.log(res.data.data)
        setData_list(res.data.data)
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  function changePagination(page, pageSize) {
    setLoading(true)
    let url = `api/v1/stock?page=${page}&limit=${pageSize}&order_by=asc${check_data(
      "&q=",
      search_stock
    )}${check_data("&pro_group_id=", filter_stock.pro_group_id)}${check_data(
      "&pro_model_id=",
      filter_stock.pro_model_id
    )}${check_data("&cus_id=", filter_stock.cus_id)}${check_data(
      "&car_body_type_id=",
      filter_stock.car_body_type_id
    )}${check_data("&dea_id=", filter_stock.dea_id)}`
    const search = api.SEARCH_ALL(token, url)
    axios(search)
      .then(res => {
        setLoading(false)
        setPage_limit({
          ...page_limit,
          page: page,
          page_Size: pageSize,
          limit: res.data.total_data,
        })
        // console.log(res.data.data)
        setData_list(res.data.data)
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Stock")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>{t("Stock")}</h3>
          {permi_t_read === true && (
            <Card>
              <CardBody>
                <Search_Stock
                  filter_stock={filter_stock}
                  setFilter_stock={setFilter_stock}
                  search_data={search_data}
                  setSearch_stock={setSearch_stock}
                />

                <Table_Stock data_list={data_list} loading={loading} />

                <div>
                  <Pagination
                    current={page_limit.page}
                    total={page_limit.limit}
                    onChange={changePagination}
                    style={{ marginTop: "20px", float: "right" }}
                  />
                </div>
                <p style={{ fontSize: "15px", marginTop: "20px" }}>
                  {" "}
                  <FcDataSheet size={20} style={{ marginBottom: "5px" }} />{" "}
                  {t("total data")} : {page_limit.limit}
                </p>
              </CardBody>
            </Card>
          )}
          {permi_t_read === false && (
            <div>
              <img
                src={no_permi}
                alt="no permission"
                style={{ display: "block", margin: "50px auto" }}
              />
              <p style={{ textAlign: "center", fontSize: "20px" }}>
                {t("no permission read module")}
              </p>
              <p style={{ textAlign: "center", fontSize: "20px" }}>
                {" "}
                {t("please contract administrator")}{" "}
              </p>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Stock
