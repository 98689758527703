import React, { useState } from "react"
import { Card, Button, Table, Tag } from "antd"
import { Row, Col } from "reactstrap"
import Expand from "react-expand-animated"
import Render_Image from "../../../../New_Customer/Devices/Render_Image"
import Render_Image_Data from '../../../../New_Service_Has_Stock_Id/components/Render_Image_Data'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { useTranslation } from "react-i18next";

export default function Card_Detail({
  image_header,
  alt_image_header,
  text_header,
  path_image,
  data_map,
  title_data,
  type_avatar,
  use_table,
  data_table,
  title_data_tow,
  ckeditor_value,
  default_card
}) {
  const [open, setOpen] = useState(default_card ? default_card : false)
  const { t } = useTranslation();

  const text_header_style = {
    fontWeight: "bold",
    fontSize: "15px",
  }
  const icons_info_header = {
    width: "25px",
    marginRight: "20px",
  }

  function render_button() {
    if (open === false) {
      return (
        <Button
          type="link"
          onClick={() => setOpen(!open)}
          style={{ float: "right" }}
        >
          More
        </Button>
      )
    } else {
      return (
        <Button type="link" onClick={() => setOpen(!open)}>
          Close
        </Button>
      )
    }
  }
  
  function checkData(data) {
    if (data) {
      return data
    } else {
      return "N/A"
    }
  }

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return "N/A"
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  function render_status_contract(status) {
    switch (status) {
      case false:
        return <Tag color="green"> {t("Active")} </Tag>
        break
      case true:
        return <Tag color="error"> {t("Expire")} </Tag>
        break
      default:
        break
    }
  }

  const columns = [
    {
      title: t("Contract name"),
      dataIndex: "con_name",
      key: "con_name",
      render: text => checkData(text),
    },
    {
      title: t("Contract number"),
      dataIndex: "con_no",
      key: "con_no",
      render: text => checkData(text),
    },
    {
      title: t("Contract start date"),
      dataIndex: "con_start_date",
      key: "con_start_date",
      render: text => cutTimeZone(text),
    },
    {
      title: t("Contract end date"),
      dataIndex: "con_end_date",
      key: "con_end_date",
      render: text => cutTimeZone(text),
    },
    {
      title: t("Contract status"),
      dataIndex: "contract_expired",
      key: "contract_expired",
      render: text => render_status_contract(text),
    },
  ]

  return (
    <Card>
      {open === false && (
        <Button
          type="link"
          onClick={() => setOpen(!open)}
          style={{ float: "right" }}
        >
          {t("More")}
        </Button>
      )}
      {open === true && (
        <Button
          type="link"
          onClick={() => setOpen(!open)}
          style={{ float: "right" }}
        >
          {t("Close")}
        </Button>
      )}
      <p>
        <img
          src={image_header}
          alt={alt_image_header}
          style={icons_info_header}
        />
        {text_header}  <span style={{ fontWeight:'bold', marginLeft:'20px' }}>{title_data}</span>
      </p>
      {
        title_data_tow !== null && (
          <p className="text-header-two" >{t("Serial number")} <span style={{fontWeight:'bold' , marginLeft:'60px'}}>{title_data_tow}</span></p>
        )
      }
      <Expand open={open} duration={500}>
        {path_image && type_avatar === "product" && (
          <div >
            <Render_Image path_image={path_image} size={100} />
          </div>
        )}
        {path_image && type_avatar === "user" && (
            <div >
              <Render_Image_Data path_image={path_image} size={100} />
            </div>
          )}
        <br />
        {
            use_table === true && (
                <div>
                <Table
          rowKey="con_id"
          columns={columns}
          dataSource={data_table}
          scroll={{ x: 800 }}
        />
                </div>
            )
        }

        <div>
        <p className="text-bold"> {t("Service issue reported")} </p>
        { ReactHtmlParser(ckeditor_value) }
        </div>

        {
            use_table === false && (
                <div>
                {data_map.map((data, index) => {
                    return (
                    <Row key={index}>
                        <Col sm={6} md={3} lg={3} xl={3}>
                        <p style={text_header_style}>{data.header1}</p>
                        </Col>
                        <Col sm={6} md={3} lg={3} xl={3}>
                        <p style={{ fontSize: "15px" }}>
                            {" "}
                            <span className="tet">:</span>{" "}
                            <span className="tetTwo">-</span> {data.dataContract1}
                        </p>
                        </Col>
                        <Col sm={6} md={3} lg={3} xl={3}>
                        <p style={text_header_style}>{data.header2}</p>
                        </Col>
                        <Col sm={6} md={3} lg={3} xl={3}>
                        <p style={{ fontSize: "15px" }}>
                            {" "}
                            <span className="tet">:</span>{" "}
                            <span className="tetTwo">-</span> {data.dataContract2}
                        </p>
                        </Col>
                    </Row>
                    )
                })}
                </div>
            )
        }
       
      </Expand>
    </Card>
  )
}
