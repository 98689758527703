import React from "react"
import { Row, Col } from "reactstrap"
import Select_Data_Filter from "./components/Select_Data_Filter"
import Select_Filter_Customer from '../../Cars/select_search/Select_Filter_Customer'
import { useTranslation } from "react-i18next";
import SelectRole from './components/SelectRole'
import { Button } from 'antd'
import FilterAdmin from './components/FilterAdmin'
import FilterEmployee from './components/FilterEmployee'

export default function Filter_service(props) {
  const { t } = useTranslation();
  return (
    <div>
      <Row>
        <Col sm={12} md={6} lg={3} xl={3}>
          <Select_Data_Filter
            header={t("Priority")}
            placeholder={t("Priority")}
            keyState="priority_id"
            name_api="LIST_PRIORITY"
            headState={props.setFilter_service}
            nameState={props.filter_service}
            map_name="pri_name"
            map_value="pri_id"
          />
        </Col>
        <Col sm={12} md={6} lg={3} xl={3}>
          <Select_Data_Filter
            header={t("Service type")}
            placeholder={t("Service type")}
            keyState="ser_type_id"
            name_api="LIST_TYPE"
            headState={props.setFilter_service}
            nameState={props.filter_service}
            map_name="ser_type_name"
            map_value="ser_type_id"
          />
        </Col>
        <Col sm={12} md={6} lg={3} xl={3}>
          <Select_Data_Filter
            header={t("Service status")}
            placeholder={t("Service status")}
            keyState="ser_status_id"
            name_api="LIST_STATUS"
            headState={props.setFilter_service}
            nameState={props.filter_service}
            map_name="ser_status_name"
            map_value="ser_status_id"
          />
        </Col>
        <Col sm={12} md={6} lg={3} xl={3}>
        <Select_Filter_Customer
        headState={props.setFilter_service}
        nameState={props.filter_service}
        keyState="customer_id"
      />
      </Col>
      </Row>

      <Row>
        <Col sm={12} md={6} lg={3} xl={3}>
        <SelectRole 
           filter_Role={props.filter_Role}
           setFilter_Role={props.setFilter_Role}
        />
        </Col>
        {
          props.filter_Role.request_by_role === "administrator" && (
            <Col sm={12} md={6} lg={4} xl={4}>
              <FilterAdmin 
                  filter_Role={props.filter_Role}
                  setFilter_Role={props.setFilter_Role}
              />
            </Col>
          )
        }
            {
          props.filter_Role.request_by_role === "employee" && (
            <Col sm={12} md={6} lg={4} xl={4}>
                <FilterEmployee 
                    filter_Role={props.filter_Role}
                    setFilter_Role={props.setFilter_Role}
                />
            </Col>
          )
        }
      </Row>
    </div>
  )
}
