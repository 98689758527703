import React, { useState, useEffect } from "react"
import { Row, Col, Container, Card, CardBody } from "reactstrap"
import MetaTags from "react-meta-tags"
import { useHistory } from "react-router-dom"
import { Table, Modal, Pagination, Tag, Tooltip , Avatar } from "antd"
import { AiOutlineEye } from "react-icons/ai"
import { FiEdit2 } from "react-icons/fi"
import { FcSearch, FcSettings, FcDataSheet , FcList } from "react-icons/fc"
import * as api from '../../service/Api_Profile_Menu'
import axios from 'axios'
import {
    UserOutlined,
    SolutionOutlined,
    LoadingOutlined,
    SmileOutlined,
  } from "@ant-design/icons"
  import { BiTaskX, BiTask } from "react-icons/bi"
  import { RiTimeLine } from "react-icons/ri"
  import { GrCompliance } from "react-icons/gr"
  import { VscLoading } from "react-icons/vsc"
  import { BsFileEarmarkCheck } from "react-icons/bs"
  import { AiOutlineCloseCircle } from "react-icons/ai"
  import helpers from '../Function_Redirect/Redirect_login'
  import { useTranslation } from "react-i18next";

const Notification = props => {
  const history = useHistory()
  const { t } = useTranslation();
  const token = localStorage.getItem("token")
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })
  const [data_list, setData_list] = useState([])
  const [visible, setVisible] = useState(false)
  const [data_Service_modal, setData_Service_modal] = useState({})
  const [loading, setLoading] = useState(false)

  const style_icons = { marginTop:'-5px', marginLeft:'1px' }

  useEffect(() => {
    search_data()
    return () => {
      setData_list([])
      setData_Service_modal({})
    }
  }, [])

  function search_data() {
    setLoading(true)
      const list_no_data_service = api.LIST_NOTIFICATION_ALL(
        token,
        1,
        page_limit.page_Size
      )
      axios(list_no_data_service)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
             if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }

        })
    
  }

  function changePagination(page, pageSize) {
    setLoading(true)
      const list_data_service = api.LIST_NOTIFICATION_ALL(
        token,
        page,
        pageSize
      )
      axios(list_data_service)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          // console.log(res.data.data)
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }

        })
    
  }

  function render_status_icon(status) {
    switch (status) {
        case "unassigned":
          return (
            <Avatar
            style={{
              backgroundColor: '#d9d9d9',
            }}
            size="large"
          >
           <BiTaskX size={25} style={style_icons} />
          </Avatar>
          )
          break
        case "assigned": 
          return (
            <Avatar
            style={{
              backgroundColor: 'rgb(220, 194, 0)',
            }}
            size="large"
          >
           <BiTask size={25} style={style_icons} />
          </Avatar>
          )
          break
        case "inprogress":
          return (
            <Avatar
            style={{
              backgroundColor: '#91d5ff',
            }}
            size="large"
          >
           <VscLoading size={25} style={style_icons} />
          </Avatar>
          )
          break
        case "pending":
          return (
            <Avatar
                style={{
                  backgroundColor: 'rgb(234, 159, 230)',
                }}
                size="large"
              >
               <RiTimeLine size={25} style={style_icons} />
              </Avatar>
          )
          break
          case "cancel":
            return (
              <Avatar
                  style={{
                    backgroundColor: '#ffa39e',
                  }}
                  size="large"
                >
                 <AiOutlineCloseCircle size={25} style={style_icons} />
                </Avatar>
            )
            break
        case "waiting for admin approve":
          return (
            <Avatar
                style={{
                  backgroundColor: 'rgb(234, 159, 230)',
                }}
                size="large"
              >
               <RiTimeLine size={25} style={style_icons} />
              </Avatar>
          )
          break
        case "waiting for manager approve":
          return (
            <Avatar
                style={{
                  backgroundColor: 'rgb(234, 159, 230)',
                }}
                size="large"
              >
               <RiTimeLine size={25} style={style_icons} />
              </Avatar>
          )
          break
        case "complete":
          return (
            <Avatar
                style={{
                  backgroundColor: '#b7eb8f',
                }}
                size="large"
              >
               <BsFileEarmarkCheck size={25} style={style_icons} />
              </Avatar>
          )
          break
        case "resolved":
          return (
            <Avatar
                style={{
                  backgroundColor: '#b7eb8f',
                }}
                size="large"
              >
               <BsFileEarmarkCheck size={25} style={style_icons} />
              </Avatar>
          )
          break
        default:
          return (
            <Avatar
                style={{
                  backgroundColor: '#ffd591',
                }}
                size="large"
              >
               <RiTimeLine size={25} style={style_icons} />
              </Avatar>
          )
          break;
    }
  }

  function checkData(data) {
    if (data) {
      return data
    } else {
      return "N/A"
    }
  }

  function render_time(date_time) {
    const date_data = new Date(date_time)
    let expiryDate = new Date(
      new Date(date_data).setHours(date_data.getHours() - 7)
    )
    const date_all = new Date(expiryDate)
    return date_all.toLocaleTimeString("en-GB")
  }

  const columns = [
    {
      title: "",
      dataIndex: "noti_type",
      key: "noti_type",
      render: text => render_status_icon(text),
    },
    {
      title: t("Module"),
      dataIndex: "noti_link_module",
      key: "noti_link_module",
      render: text => checkData(text),
    },
    {
        title: t("status"),
        dataIndex: "noti_type",
        key: "noti_type",
        render: text => render_status(text),
      },
    {
        title: t("Detail"),
        dataIndex: "noti_detail",
        key: "noti_detail",
        render: text => checkData(text),
      },
      {
        title: t("Time"),
        dataIndex: "time_ago",
        key: "time_ago",
        render: text => checkData(text),
      },
      {
        title: "",
        dataIndex: "noti_link_module_id",
        key: "noti_link_module_id",
        render: text => (
          <Tooltip title={t("View service")} >
            <a onClick={() => history.push('/service/'+parseInt(text)) }>
              <FcList size={20} />
            </a>
          </Tooltip>
        ),
      },
  ]

  function render_status(status) {
    switch (status) {
      case "cancel":
        return <Tag color="error"> {t(status)} </Tag>
        break
        case "reject":
          return <Tag color="error"> {t(status)} </Tag>
          break
      case "overdue":
        return <Tag color="error">{t(status)}</Tag>
        break
      case "resolved":
        return <Tag color="success">{t(status)}</Tag>
        break
      case "complete":
        return <Tag color="success">{t(status)}</Tag>
        break
      case "inprogress":
        return <Tag color="processing">{t(status)}</Tag>
        break
      case "unassigned":
        return <Tag color="default">{t(status)}</Tag>
        break
      case "pending":
        return (
          <Tag
            style={{
              color: "rgb(234, 159, 230)",
              borderColor: "rgb(234, 159, 230)",
              backgroundColor: "#FFF",
            }}
          >
          {t(status)}
          </Tag>
        )
        break
      case "assigned":
        return (
          <Tag
            style={{
              color: "#DCC200",
              borderColor: "#DCC200",
              backgroundColor: "#FFF",
            }}
          >
          {t(status)}
          </Tag>
        )
        break
      case "waiting for admin approve":
        return (
          <Tag
            style={{
              color: "#0071FF",
              borderColor: "#0071FF",
              backgroundColor: "#DFEDFF",
            }}
          >
          {t(status)}
          </Tag>
        )
        break
      case "waiting for manager approve":
        return (
          <Tag
            style={{
              color: "#0071FF",
              borderColor: "#0071FF",
              backgroundColor: "#DFEDFF",
            }}
          >
          {t(status)}
          </Tag>
        )
        break
      default:
        return <Tag color="default">{t(status)}</Tag>
        break
    }
  }

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return "N/A"
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Notification")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}> {t("Notification")} </h3>
          <Card>
            <CardBody>
            
            <Table
                loading={loading}
              columns={columns}
              dataSource={data_list}
              pagination={false}
              rowKey="id"
              scroll={{ x: 800 }}
            />

            <div>
            <Pagination
              current={page_limit.page}
              total={page_limit.limit}
              onChange={changePagination}
              style={{ marginTop: "20px", float: "right" }}
            />
          </div>
          <p style={{ fontSize:'15px' , marginTop:'20px'}}> <FcDataSheet size={20} style={{ marginBottom:'5px' }} /> {t("total data")} : {page_limit.limit}</p>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Notification
