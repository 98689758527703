import React from 'react'
import { Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next";

export default function Detail_dealer(props) {
    const { t } = useTranslation();
    const text_header = {
        fontWeight:'bold',
        fontSize:'15px'
    }

    const data_map = [
        {
            header1 : t("Dealer TAX ID"),
            dataContract1 : checkData(props.data_dealer.dea_taxid) ,
            header2 : t("Dealer code"),
            dataContract2 : checkData(props.data_dealer.dea_code)
        },{
            header1 : t("Dealer name"),
            dataContract1 : checkData(props.data_dealer.dea_name) ,
            header2 : t("Dealer phone"),
            dataContract2 : checkData(props.data_dealer.dea_phone)
        },{
            header1 : t("Dealer credit (day)"),
            dataContract1 : checkData(props.data_dealer.dea_credit) ,
            header2 : t("Dealer email"),
            dataContract2 : checkData(props.data_dealer.dea_email)
        },{
            header1 : t("Dealer fax"),
            dataContract1 : checkData(props.data_dealer.dea_fax) ,
            header2 : t("Dealer address"),
            dataContract2 : checkData(props.data_dealer.dea_address)
        },{
            header1 : t("Contact firstname"),
            dataContract1 : checkData(props.data_dealer.dea_contact_firstname) ,
            header2 : t("Contact firstname"),
            dataContract2 : checkData(props.data_dealer.dea_contact_lastname)
        },{
            header1 : t("Contact mobile"),
            dataContract1 : checkData(props.data_dealer.dea_contact_mobile) ,
            header2 : t("Contact email"),
            dataContract2 : checkData(props.data_dealer.dea_contact_email)
        },
    ]

    function checkData(data) {
        if(data){
            return data
        } else {
            return 'N/A'
        }
      }

    return (
        <div className="scroll-modal-display-mobile-app">
        <p style={{ textAlign:'center', fontWeight:'bold', marginTop:'10px', fontSize:'15px' }}> {t("Dealer information")} </p>
        <hr />
        
        {
            data_map.map( (data, index) => {
                return(
                   <Row key={index}>
                   <Col sm={6} md={2} lg={2} xl={2} >
                   <p style={text_header}>{data.header1}</p>
                   </Col>
                   <Col sm={6} md={4} lg={4} xl={4}>
                   <p style={{ fontSize:'15px'}}> <span className="tet">:</span> <span className="tetTwo">-</span> {data.dataContract1}</p>
                   </Col>
                   <Col sm={6} md={2} lg={2} xl={2} >
                   <p style={text_header}>{data.header2}</p>
                   </Col>
                   <Col sm={6} md={4} lg={4} xl={4}>
                   <p style={{ fontSize:'15px'}}> <span className="tet">:</span> <span className="tetTwo">-</span> {data.dataContract2}</p>
                   </Col>
                   </Row>
                )
            })
        }
        </div>
    )
}
