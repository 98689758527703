import React, { useRef, useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Container, Card, CardBody, Row, Col } from "reactstrap"
import { useHistory } from "react-router-dom"
import { MdKeyboardBackspace } from "react-icons/md"
import { Form, Button, Switch, Modal, notification, Avatar } from "antd"
import Input_Comp from "./components/Input_Comp"
import Select_no_search from "./components/Select_no_search"
import Input_Comp_pass from "./components/Input_Comp_pass"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import * as api from "../../service/Api_New_Operator"
import { UserOutlined } from "@ant-design/icons"
import axios from "axios"
import Input_Comp_Email from './components/Input_Comp_Email'
import helpers from '../Function_Redirect/Redirect_login'
import { useTranslation } from "react-i18next";
import no_permi from '../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png'
import { useSelector } from "react-redux"

const { confirm } = Modal

const Operator = props => {
  const { t } = useTranslation();
  const token = localStorage.getItem("token")
  const history = useHistory()
  const [form] = Form.useForm()
  const imageUploader = useRef(null)
  const [loading_button, setLoading_button] = useState(false)
  const [operator_new, setOperator_new] = useState({
    email_password_condition: false,
  })
  const [error_pass, setError_pass] = useState(null)
  const [select_image, setSelect_image] = useState(null)
  const [image_preview, setImage_preview] = useState(null)
  const [confirm_Pass, setConfirm_Pass] = useState({
    confirm_Pass: null,
  })
  const [email_ope_dup, setEmail_ope_dup] = useState(null)
  const [disable_button, setDisable_button] = useState(false)
  const permi = localStorage.getItem("permi")
  const [permi_t, setPermi_t] = useState(false)
  const { permissionAllService } = useSelector(({ Distar }) => Distar)

  useEffect(() => {
    if (permissionAllService) {
      if(permissionAllService.operator){
        setPermi_t(permissionAllService.operator.write)
      }
    }
    return () => {

    }
  }, [permissionAllService])

  useEffect(() => {
    if(operator_new.ope_email){
      function check_email() {
        const check_email_operator = api.CHECK_EMAIL_OPERATOR_DUPLICATE(token , operator_new.ope_email)
        axios(check_email_operator)
        .then( res => {
          if(res.data.data === true){
            setDisable_button(true)
            setEmail_ope_dup(t("Email is duplicate"))
          } else {
            setDisable_button(false)
            setEmail_ope_dup(null)
          }
        })
        .catch( error => {
          // console.log(error);
        })
      }

      check_email()
    }
    return () => {
      
    }
  }, [operator_new.ope_email])
  
  useEffect(() => {
    
    return () => {
      setSelect_image(null)
      setImage_preview(null)
      setError_pass(null)
    }
  }, [])

  useEffect(() => {
    if (confirm_Pass.confirm_Pass !== operator_new.ope_password) {
      setError_pass(t("Password mismatch"))
    } else {
      setError_pass(null)
    }
    return () => {}
  }, [confirm_Pass.confirm_Pass])

  function onChangeSwitchPass(checked) {
    if (checked) {
      setError_pass(null)
      form.resetFields(["ope_password", "confirm_Pass"])
      setOperator_new({
        ...operator_new,
        ope_password: null,
        email_password_condition: checked,
      })
    } else {
      setOperator_new({
        ...operator_new,
        email_password_condition: checked,
      })
    }
  }

  const handleImageUpload = e => {
    if (e.target.files[0] !== undefined) {
      if(e.target.files[0].type !== "image/jpeg"){
        openNotificationWithIcon("error", t("please upload photo type .jpeg only"))
      } else {
        let img_upload = e.target.files[0]
      setSelect_image(img_upload)
      setImage_preview(URL.createObjectURL(img_upload))
      }
    }
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  const onFinish = values => {
    if (confirm_Pass.confirm_Pass !== operator_new.ope_password) {
      return Modal.error({
        title: t("Password mismatch"),
        centered: true,
      })
    } else {
      return confirm({
        title: t("Confirm create new operator"),
        icon: <ExclamationCircleOutlined />,
        okText: t("Create"),
        cancelText: t("no"),
        centered: true,
        onOk() {
          setLoading_button(true)
          let data_send_pass = {}
          if (operator_new.ope_password === null) {
            data_send_pass = {
              ope_code: operator_new.ope_code,
              ope_firstname: operator_new.ope_firstname,
              ope_lastname: operator_new.ope_lastname,
              ope_department_id: operator_new.ope_department_id,
              ope_email: operator_new.ope_email,
              ope_mobile: operator_new.ope_mobile,
              email_password_condition: operator_new.email_password_condition,
              ope_group_id : operator_new.ope_group_id,
              ope_nickname : operator_new.ope_nickname,
              ope_position_id : operator_new.ope_position_id
            }
          } else {
            data_send_pass = {
              ope_code: operator_new.ope_code,
              ope_firstname: operator_new.ope_firstname,
              ope_lastname: operator_new.ope_lastname,
              ope_department_id: operator_new.ope_department_id,
              ope_email: operator_new.ope_email,
              ope_password: operator_new.ope_password,
              ope_mobile: operator_new.ope_mobile,
              email_password_condition: operator_new.email_password_condition,
              ope_group_id : operator_new.ope_group_id,
              ope_nickname : operator_new.ope_nickname,
              ope_position_id : operator_new.ope_position_id
            }
          }

          if(select_image !== null){
            const formData = new FormData();
            formData.append("file", select_image)
            const upload_image = api.UPLOAD_PROFILE(token, formData);
            axios(upload_image)
            .then( res => {
              let add_image_path = {
                ope_avatar : res.data.path,
                ...data_send_pass
              }
              create_new_operator_data(add_image_path)
            })
            .catch( error => {
              setLoading_button(false)
              if(error){
                if(error.response){
                  if(error.response.status === 401){
                    helpers.re_login()
                    history.push('/login')          
                    } else {
                      openNotificationWithIcon("error", t("Create new operator error"))
                    }
                }
              }
            })

          } else {
            create_new_operator_data(data_send_pass)
          }
        },
        onCancel() {},
      })
    }
  }

  function create_new_operator_data(data_send_pass) {
    const new_operator = api.CREATE_NEW_OPERATOR(token, data_send_pass)
          axios(new_operator)
            .then(res => {
              setLoading_button(false)
              onReset()
              openNotificationWithIcon("success", t("Create new operator success"))
              history.push(`/operator/${res.data.ope_id}`)
            })
            .catch(error => {
              setLoading_button(false)
              if(error){
                if(error.response){
                  if(error.response.status === 401){
                    helpers.re_login()
                    history.push('/login')          
                    } else {
                      openNotificationWithIcon("error", t("Create new operator error"))
                    }
                }
              }
            })
  }

  const onReset = () => {
    setError_pass(null)
    form.resetFields()
    setOperator_new({
      email_password_condition: false,
    })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("New operator")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/operator")}
              />
            </a>{" "}
            {t("New operator")}
          </h3>
          <br />
          {
            permi_t === true && (
              <Card>
              <CardBody>
              <div className="image-upload" style={{ marginBottom:'30px' }}>
              <p style={{ textAlign: "center", fontSize: "15px", fontWeight: "bold" }}>
                {t("photo profile")}
              </p>
              { image_preview === null &&(
                  <Avatar
                    icon={<UserOutlined />}
                    size={100}
                    style={{ display: "block", margin: "auto" }}
                  />
              )}
  
              { image_preview !== null &&(
                <Avatar
                  src={image_preview}
                  size={100}
                  style={{ display: "block", margin: "auto" }}
                />
            )}
  
              <input
              type="file"
              accept="image/jpeg"
              onChange={handleImageUpload}
              ref={imageUploader}
              style={{
                display: "none",
              }}
            />
  
            </div>
            <div>
            <Button
            type="primary"
            style={{
              display: "block",
              margin:'0 auto 20px auto',
              width: "150px",
            }}
            onClick={() => imageUploader.current.click()}
          >
            {t("Upload photo")}
          </Button>
          <p style={{ color:'red', textAlign:'center' }}> {t("please upload jpg or jpeg image only")} </p>
            </div>
  
                <Form form={form} name="control-hooks" onFinish={onFinish}>
                  <Row>
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <Input_Comp
                        header={t("Operator firstname")}
                        placeholder={t("Operator firstname")}
                        headState={setOperator_new}
                        nameState={operator_new}
                        keyState="ope_firstname"
                        type="text"
                      />
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <Input_Comp
                        header={t("Operator lastname")}
                        placeholder={t("Operator lastname")}
                        headState={setOperator_new}
                        nameState={operator_new}
                        keyState="ope_lastname"
                        type="text"
                      />
                    </Col>
     
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <Input_Comp
                        header={t("Operator code")}
                        placeholder={t("Operator code")}
                        headState={setOperator_new}
                        nameState={operator_new}
                        keyState="ope_code"
                        type="text"
                      />
                    </Col>
                    
                    <Col sm={12} md={6} lg={6} xl={6}>
                    <Input_Comp
                      header={t("Operator nickname")}
                      placeholder={t("Operator nickname")}
                      headState={setOperator_new}
                      nameState={operator_new}
                      keyState="ope_nickname"
                      type="text"
                    />
                  </Col>
  
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <Input_Comp_Email
                        header={t("Operator email")}
                        placeholder={t("Operator email")}
                        headState={setOperator_new}
                        nameState={operator_new}
                        keyState="ope_email"
                        type="email"
                      />
                      {
                        email_ope_dup !== null && (
                          <p style={{ color:'red' }}>{email_ope_dup}</p>
                        )
                      }
                    </Col>
       
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <Input_Comp
                        header={t("Operator mobile")}
                        placeholder={t("Operator mobile")}
                        headState={setOperator_new}
                        nameState={operator_new}
                        keyState="ope_mobile"
                        type="number"
                      />
                    </Col>
                    
                    <Col sm={12} md={6} lg={6} xl={6}>
                    <Select_no_search
                      header={t("Operator group")}
                      placeholder={t("Operator group")}
                      name_api="LIST_OPERATOR_GROUP"
                      map_name="ope_group_name"
                      map_value="ope_group_id"
                      keyState="ope_group_id"
                      Header_data="data"
                      headState={setOperator_new}
                      nameState={operator_new}
                    />
                  </Col>
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <Select_no_search
                        header={t("Operator department")}
                        placeholder={t("Operator department")}
                        name_api="LIST_DEPARTMENT"
                        map_name="dep_name"
                        map_value="dep_id"
                        keyState="ope_department_id"
                        Header_data="data"
                        headState={setOperator_new}
                        nameState={operator_new}
                      />
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={6}>
                    <Select_no_search
                      header={t("Operator position")}
                      placeholder={t("Operator position")}
                      name_api="LIST_POSITION"
                      map_name="pos_name"
                      map_value="pos_id"
                      keyState="ope_position_id"
                      Header_data="data"
                      headState={setOperator_new}
                      nameState={operator_new}
                    />
                  </Col>
                  </Row>
  
                  <Row>
                    <Col>
                      <div className="mt-bottom-all">
                        <p className="text-bold">
                          {t("Sending signup password by email")}
                          <Switch
                          checked={operator_new.email_password_condition}
                            onChange={onChangeSwitchPass}
                            style={{ marginLeft: "10px" }}
                          />{" "}
                        </p>
                      </div>
                    </Col>
                  </Row>
  
                  {!operator_new.email_password_condition && (
                    <Row>
                      <Col sm={12} md={6} lg={6} xl={6}>
                        <Input_Comp_pass
                          header={t("Password")}
                          placeholder={t("Password")}
                          headState={setOperator_new}
                          nameState={operator_new}
                          keyState="ope_password"
                          type="password"
                        />
                      </Col>
                      <Col sm={12} md={6} lg={6} xl={6}>
                        <Input_Comp_pass
                          header={t("Confirm password")}
                          placeholder={t("Confirm password")}
                          headState={setConfirm_Pass}
                          nameState={confirm_Pass}
                          keyState="confirm_Pass"
                          type="password"
                        />
                      </Col>
                    </Row>
                  )}
                  {operator_new.ope_password &&
                    confirm_Pass.confirm_Pass !== null && (
                      <p
                        style={{
                          textAlign: "center",
                          color: "red",
                          fontSize: "15px",
                        }}
                      >
                        {error_pass}
                      </p>
                    )}
  
                  <br />
                  <Row>
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <div className="div_create_new_car">
                        <Button
                          className="btn_cancel_create_new_car"
                          onClick={() => {
                            onReset()
                          }}
                        >
                       {t("Cancel")}
                        </Button>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <div className="div_create_new_car">
                        <Button
                          className="btn_submit_create_new_car"
                          type="primary"
                          htmlType="submit"
                          loading={loading_button}
                          disabled={disable_button}
                        >
                          {t("Create")}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
            )
          }
         
          {
            permi_t === false && (
              <div>
                <img src={no_permi} alt="no permission" style={{ display:'block' , margin: '50px auto' }} />
                <p style={{ textAlign: "center", fontSize: "20px" }}>{t("no permission write module")}</p>
                <p style={{ textAlign:'center', fontSize:'20px' }}> {t("please contract administrator")} </p>                
                </div>

            )
          }
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Operator
