import React,{ useEffect, useState } from "react"
import { Tabs } from "antd"
import Table_User from "./Table_User"
import axios from 'axios'
import * as api from '../../../service/Api_Edit_Dealer'
import Create_New_User from './Create_New_User'
import { useHistory } from "react-router-dom";
import helpers from '../../Function_Redirect/Redirect_login'
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs

export default function User_Dealer(props) {
  const { t } = useTranslation();
    const token = localStorage.getItem('token')
    const history = useHistory()
    const [activeTab, setActiveTab] = useState("1")
    const [data_user_all, setData_user_all] = useState([])

    useEffect(() => {
        if(props.dea_id){
            check_user_in_dealer()
        }
        return () => {
            
        }
    }, [props.dea_id])

    function check_user_in_dealer() {
        const list_user = api.USER_IN_DEALER(token , props.dea_id)
        axios(list_user)
        .then( res => {
            setData_user_all(res.data.data)
        })
        .catch( error => {
          if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }

        })
    }

  return (
    <Tabs defaultActiveKey="1"
    onTabClick={e => {
      setActiveTab(e)
    }}
    activeKey={activeTab}
    >
      <TabPane tab={t("Users")} key="1">
        <Table_User 
        data_user_all={data_user_all}
        dea_id={props.dea_id}
        />
      </TabPane>
      <TabPane tab={t("New user")} key="2">
       <Create_New_User 
       dea_id={props.dea_id}
       check_user_in_dealer={check_user_in_dealer}
       setActiveTab={setActiveTab}
       />
      </TabPane>
    </Tabs>
  )
}
