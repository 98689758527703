import React, { useState, useEffect } from "react"
import Customer from "./Customer"
import { Row, Col } from "reactstrap"
import axios from "axios"
import * as api from "../../../../service/Api_New_Service"
import Select_no_search from "../../../New_Service_Has_Stock_Id/components/Select_no_search"
import List_User from "../../../New_Service_Has_Stock_Id/components/List_User"
import Input_Comp from "../../../New_Service_Has_Stock_Id/components/Input_Comp"
import { useTranslation } from "react-i18next"

export default function SelectCustomer({
  setUser_in_customer,
  setCreateNewService,
  createNewService,
  user_in_customer,
  type_filter,
  setType_filter,
  type_received_all,
  setType_received_all,
  user_select,
  setUser_select,
  customerSelect,
  setCustomerSelect,
  form
}) {
  const { t } = useTranslation()
  const token = localStorage.getItem("token")

  useEffect(() => {
    if (customerSelect.cus_id !== null) {
      checkUserInCustomer(customerSelect.cus_id)
    }

    return () => {}
  }, [customerSelect])

  function checkUserInCustomer(cus_id) {
    const check_user = api.CHECK_USER_IN_CUSTOMER(token, cus_id)
    axios(check_user)
      .then(res => {
        if (res.data === "True") {
          setUser_in_customer(true)
        } else if (res.data === "False") {
          setUser_in_customer(false)
        }
      })
      .catch(error => {
        // console.log(error)
      })
  }

  useEffect(() => {
  if(customerSelect.cus_id === null){
    setUser_select(null)
    setCustomerSelect({
      ...customerSelect,
      cus_user_id: null,
      cus_name: null,
    })
    setCreateNewService({
      ...createNewService,
      ser_notification_email : null ,
      ser_notification_line : null
    })
    form.resetFields(["ser_notification_email", "ser_notification_line"])
  }
  
    return () => {
      
    }
  }, [customerSelect.cus_id])
  

  return (
    <div>
      <Customer
        customerSelect={customerSelect}
        setCustomerSelect={setCustomerSelect}
      />
      <br />
      {customerSelect.cus_id !== null && (
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Service received type")}
              placeholder={t("Service received type")}
              name_api="LIST_RECEIVED_TYPE"
              map_name="ser_received_type_name"
              map_value="ser_received_type_id"
              keyState="ser_received_type_id"
              headState={setCreateNewService}
              nameState={createNewService}
              name_array_data="data"
            />
          </Col>

          {user_in_customer === true &&
            createNewService.ser_received_type_id !== null && (
              <List_User
                cus_id={customerSelect.cus_id}
                setCreate_service={setCustomerSelect}
                create_service={customerSelect}
                setUser_select={setUser_select}
                user_select={user_select}
              />
            )}

          {user_in_customer === false && type_filter === "email" && (
            <Col sm={12} md={6} lg={6} xl={6}>
              <Input_Comp
                header={t("Email")}
                placeholder={t("Email")}
                headState={setCreateNewService}
                nameState={createNewService}
                type="text"
                keyState="ser_notification_email"
                required={true}
              />
            </Col>
          )}
          {user_in_customer === false && type_filter === "phone" && (
            <Col sm={12} md={6} lg={6} xl={6}>
              <Input_Comp
                header={t("phone")}
                placeholder={t("phone")}
                headState={setCreateNewService}
                nameState={createNewService}
                type="text"
                keyState="ser_notification_phone"
                required={true}
              />
            </Col>
          )}
          {user_in_customer === false && type_filter === "line" && (
            <Col sm={12} md={6} lg={6} xl={6}>
              <Input_Comp
                header={t("Line")}
                placeholder={t("Line")}
                headState={setCreateNewService}
                nameState={createNewService}
                type="text"
                keyState="ser_notification_line"
                required={true}
              />
            </Col>
          )}
        </Row>
      )}
    </div>
  )
}
