var Ip = require('../service/Ip')

export const LIST_NAVBAR = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}authorize/nav_menu`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CHECK_USER_INFO = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}authorize/user_info`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CHECK_ROLE_PERMISSION = (token, emp_id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/employee/role-permission/${emp_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };