import React, { useEffect, useState } from "react"
import { Container, Card, CardBody } from "reactstrap"
import { useHistory, useParams } from "react-router-dom"
import MetaTags from "react-meta-tags"
import { MdKeyboardBackspace } from "react-icons/md"
import * as api from "../../../service/service_operator/Api_Edit_Service"
import axios from "axios"
import { Tabs } from "antd"
import Detail_Service from "./Detail_Service"
import Task from './Task'

const { TabPane } = Tabs

const Edit_Service = props => {
  const history = useHistory()
  const [data_service, setData_service] = useState({})
  const token = localStorage.getItem("token")
  const [manager, setManager] = useState([])
  const [admin, setAdmin] = useState([])
  const [operator, setOperator] = useState([])
  const [service_overview, setService_overview] = useState({})
  const [count_task_status, setCount_task_status] = useState({})
  const [manager_check, setManager_check] = useState(0)
  const [admin_check, setAdmin_check] = useState(0)
  const [operator_check, setOperator_check] = useState(0)

  const [task_status_check, setTask_status_check] = useState(0)

  let { ser_id } = useParams()
  //   console.log("ser_id = " + ser_id)

  useEffect(() => {
    check_new_data_edit()
    list_service_overview()
    return () => {
      setData_service({})
    }
  }, [ser_id])

  function check_new_data_edit() {
    const new_data = api.GET_DETAIL_SERVICE(token, parseInt(ser_id))
    axios(new_data)
      .then(res => {
        if (res.data.data[0] === undefined) {
          history.push("/service")
        } else {
          setData_service(res.data.data[0])
          // console.log(res.data.data[0])
        }
      })
      .catch(error => {
        console.log(error.response)
      })
  }

  function list_service_overview() {
    const service_overview = api.SERVICE_OVERVIEW(token, parseInt(ser_id))
    axios(service_overview)
    .then( res => {
      setService_overview(res.data)
      // console.log(res.data.service.count_task_status)
      setCount_task_status(res.data.service.count_task_status)
      setAdmin_check(res.data.admin.length)
      setManager_check(res.data.manager.length)
      setOperator_check(res.data.operator.length)
      setTask_status_check(
        res.data.service.count_task_status.assigned+
        res.data.service.count_task_status.cancle+
        res.data.service.count_task_status.complete+
        res.data.service.count_task_status.inprogress+
        res.data.service.count_task_status.pending+
        res.data.service.count_task_status.resolved
      )
    })
  }

  //   console.log(data_service);
  return (
    <React.Fragment>
      <MetaTags>
        <title> Service | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/service")}
              />
            </a>{" "}
            Service
          </h3>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Overview" key="1">
                    <Detail_Service 
                    count_task_status={count_task_status}
                    data_service={data_service} 
                    service_overview={service_overview} 
                    task_status_check={task_status_check}
                    manager_check={manager_check}
                    admin_check={admin_check}
                    operator_check={operator_check}
                /> 
                </TabPane>
                <TabPane tab="Task" key="2">
                    <Task 
                        ser_id={parseInt(ser_id)}
                    />
                </TabPane>
            </Tabs>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Edit_Service