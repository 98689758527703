import React from 'react'
import { Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next";

export default function Detail(props) {
  const { t } = useTranslation();
    const text_header = {
        fontWeight:'bold',
        fontSize:'15px'
    }

  const data_detail = [
    { 
        headerDetail1: t("Serial number"),
        data1: props.detail_device.pro_serial_number,
        headerDetail2: t("Asset number"),
        data2: props.detail_device.sto_asset_number
    },
    {
        headerDetail1: t("Stock date install"),
        data1: cutTimeZone(props.detail_device.sto_date_install),
        headerDetail2: t("Stock date in"),
        data2: cutTimeZone(props.detail_device.sto_date_in)
      }
  ]

  function cutTimeZone(data) {
    if(data){
   const currentDate =  new Date(data).toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })
        return currentDate
    } else {
        return 'N/A'
    }
}

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

function cutTimeZone(date_T) {
  if(date_T){
      var date = new Date(date_T);
      var month = pad2(date.getMonth()+1);
      var day = pad2(date.getDate());
      var year= date.getFullYear();

      var formattedDate =  day+"/"+month+"/"+year;
      return formattedDate
  } else {
      return 'N/A'
  }
}
function pad2(n) {
  return (n < 10 ? '0' : '') + n;
}

    return (
    <div>
        <p style={{ textAlign:'center', fontWeight:'bold', marginTop:'10px', fontSize:'15px' }}> {t("Device information")} </p>

        {data_detail.map((data, index) => {
          return (
            <Row key={index}>
              <Col sm={6} md={3} lg={3} xl={3}>
                  <p style={text_header}> {data.headerDetail1} </p> 
              </Col>
              <Col sm={6} md={3} lg={3} xl={3}>
                  <p style={{ fontSize:'15px'}}> <span className="tet">:</span> <span className="tetTwo">-</span> {data.data1} </p>
              </Col>
              <Col sm={6} md={3} lg={3} xl={3}>
                  <p style={text_header}> {data.headerDetail2} </p>
              </Col>
              <Col sm={6} md={3} lg={3} xl={3}>
                  <p style={{ fontSize:'15px'}}> <span className="tet">:</span> <span className="tetTwo">-</span> {data.data2} </p>
              </Col>
            </Row>
          )
        })}
        <Row >
        <Col sm={6} md={3} lg={3} xl={3}>
            <p style={text_header}> {t("Stock date out")} </p> 
        </Col>
        <Col sm={6} md={3} lg={3} xl={3}>
            <p style={{ fontSize:'15px'}}> <span className="tet">:</span> <span className="tetTwo">-</span> { cutTimeZone(props.detail_device.sto_date_out) } </p>
        </Col>
      </Row>
    </div>
    )
}
