var Ip = require('../service/Ip')

export const LIST_MODULE = (token) => {
    return {
      method: 'get',       
      url: `${Ip.getIP()}api/v1/service/task/module`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };
  
  export const LIST_PRIORITY = (token) => {
    return {
      method: 'get',       
      url: `${Ip.getIP()}api/v1/service/task/priority`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const INSERT_TASK = (token , group_id , data) => {
    return {
      method: 'post',       
      url: `${Ip.getIP()}api/v1/service/task_group/${group_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const CHECK_SERVICE_HAST_TASK = (token , group_id ) => {
    return {
      method: 'get',       
      url: `${Ip.getIP()}api/v1/service/get-task-by-group-id/${group_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const REMOVE_ONE_TASK = (token , task_id ) => {
    return {
      method: 'delete',       
      url: `${Ip.getIP()}api/v1/service/task_global/${task_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };