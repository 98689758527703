import React, { useState, useEffect } from "react"
import { Container, Card, CardBody, Row , Col } from "reactstrap"
import MetaTags from "react-meta-tags"
import { useHistory, useParams } from "react-router-dom"
import { MdKeyboardBackspace } from 'react-icons/md';
import Detail_Contract from './Detail_Contract'
import * as api from '../../../service/Api_Edit_Contract'
import axios from 'axios'
import Device from './Device'
import Service from './Service'
import { Tabs } from "antd"
import helpers from '../../Function_Redirect/Redirect_login'
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs

const Contract = props => {
  const { t } = useTranslation();
    const history = useHistory()
  const token = localStorage.getItem('token')
  const cus_id = localStorage.getItem('cus_id')
  const [detail_Contract, setDetail_Contract] = useState({})
 
  let { con_id } = useParams()

  useEffect(() => {
    check_contract_detail()

    return () => {
      setDetail_Contract({})
    }
  }, [con_id])

  function check_contract_detail() {
    const check = api.CHECK_CONTRACT(token, parseInt(con_id))
    axios(check)
    .then( res => {
      if(res.data.data[0] === undefined){
        history.push(`/contract/`)
      } else {
        setDetail_Contract(res.data.data[0])
      }
      
  })
  .catch( error =>{
    if(error){
      if(error.response){
        if(error.response.status === 401){
          helpers.re_login()
          history.push('/login')          
          }
      }
    }

  })
}

  return (
    <React.Fragment>
      <MetaTags>
        <title>  {t("Contract detail")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>
          <a>
            <MdKeyboardBackspace
              style={{ marginRight: "15px", marginBottom: "3px" }}
              onClick={() => history.push("/contract")}
            />
          </a>
           {t("Contract detail")} </h3>

          <Card>
          <CardBody>

          <Tabs defaultActiveKey="1" >
                <TabPane tab={t("Overview")} key="1">
                <Detail_Contract 
                detail_Contract={detail_Contract}
                />
                </TabPane>
                <TabPane tab={t("Device")} key="2">
                <Device 
                  con_id={parseInt(con_id)}
                />
                </TabPane>
            </Tabs>

        </CardBody>
        </Card>

        </Container>
      </div>
    </React.Fragment>
  )
}
export default Contract
