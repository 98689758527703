import React from 'react'
import { Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next";

export default function Detail_Contract(props) {
    const { t } = useTranslation();
    const text_header = {
        fontWeight:'bold',
        fontSize:'15px'
    }

    function cutTimeZone(data) {
        if(data){
       const currentDate =  new Date(data).toLocaleDateString('en-US', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })
            return currentDate
        } else {
            return 'N/A'
        }
    }
    
    function checkData(data) {
      if(data){
          return data
      } else {
          return 'N/A'
      }
    }

    const data_map = [
        {
            header1 : t("Contract name"),
            dataContract1 : checkData(props.detail_Contract.con_name) ,
            header2 : t("Contract"),
            dataContract2 : checkData(props.detail_Contract.con_no)
        },{
            header1 : t("Contract start date"),
            dataContract1 : cutTimeZone(props.detail_Contract.con_start_date) ,
            header2 : t("Contract end date"),
            dataContract2 : cutTimeZone(props.detail_Contract.con_end_date)
        },{
            header1 : t("Contact firstname"),
            dataContract1 : checkData(props.detail_Contract.con_contact_firstname) ,
            header2 : t("Contact lastname"),
            dataContract2 : checkData(props.detail_Contract.con_contact_lastname)
        },{
            header1 : t("Contact mobile"),
            dataContract1 : checkData(props.detail_Contract.con_contact_mobile) ,
            header2 : t("Contact email"),
            dataContract2 : checkData(props.detail_Contract.con_contact_email)
        }
    ]

    return (
    <div>
        <p style={{ textAlign:'center', fontWeight:'bold', marginTop:'10px', fontSize:'15px' }}> {t("Contract information")} </p>
        <hr />

     
        {
            data_map.map( (data, index) => {
                return(
                   <Row key={index}>
                   <Col sm={6} md={2} lg={2} xl={2} >
                   <p style={text_header}>{data.header1}</p>
                   </Col>
                   <Col sm={6} md={4} lg={4} xl={4}>
                   <p style={{ fontSize:'15px'}}> <span className="tet">:</span> <span className="tetTwo">-</span> {data.dataContract1}</p>
                   </Col>
                   <Col sm={6} md={2} lg={2} xl={2} >
                   <p style={text_header}>{data.header2}</p>
                   </Col>
                   <Col sm={6} md={4} lg={4} xl={4}>
                   <p style={{ fontSize:'15px'}}> <span className="tet">:</span> <span className="tetTwo">-</span> {data.dataContract2}</p>
                   </Col>
                   </Row>
                )
            })
        }
     
    </div>
    )
}
