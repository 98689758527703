import React, { useState, useEffect } from "react"
import { Button, Form, notification, Modal } from "antd"
import { Row, Col } from "reactstrap"
import Input_Comp from "./Input_Comp"
import Select_Search_Comp from "./Select_Search_Comp"
import Select_no_search from "./Select_no_search"
import Customer_Comp from "./Customer_Comp"
import Branch_Comp from "./Branch_Comp"
import * as api from "../../../service/API_Edit_Car"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import axios from "axios"
import { useHistory } from "react-router-dom"
import helpers from "../../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"

const { confirm } = Modal

export default function Edit_a_car(props) {
  const { t } = useTranslation()
  const token = localStorage.getItem("token")
  const [form] = Form.useForm()
  const history = useHistory()
  const [edit, setEdit] = useState(true)
  const [data_edit, setData_edit] = useState({})
  const [customer_Info, setCustomer_Info] = useState({
    cus_id: null,
    cus_name: null,
    cus_branch_id: null,
    cus_branch_name: null,
    car_group_id: null,
  })
  const [loading, setLoading] = useState(false)

  const onFinish = values => {
    let data_last_edit = {}
    for (const [key, value] of Object.entries(data_edit)) {
      if (!value) {
      } else {
        data_last_edit[key] = value
      }
    }
    if (Object.keys(data_last_edit)[0] === undefined) {
      openNotificationWithIcon("info", t("No data is change"))
    } else if (data_last_edit.cus_id && !data_last_edit.cus_branch_id) {
      openNotificationWithIcon("warning", t("Please select branch"))
    } else {
      confirm({
        title: t("Confirm edit car information"),
        icon: <ExclamationCircleOutlined />,
        centered: true,
        okText: t("Confirm"),
        cancelText: t("no"),
        onOk() {
          setLoading(true)
          const edit_car_info = api.EDIT_CAR(
            token,
            data_last_edit,
            props.data_car.car_id
          )
          axios(edit_car_info)
            .then(res => {
              setLoading(false)
              Cancel_Edit()
              setEdit(!edit)
              check_new_data_edit()
              openNotificationWithIcon("success", t("Edit car success"))
            })
            .catch(error => {
              setLoading(false)
              if (error) {
                if (error.response) {
                  if (error.response.status === 401) {
                    helpers.re_login()
                    history.push("/login")
                  } else {
                    openNotificationWithIcon("error", t("Edit car error"))
                  }
                }
              }
            })
        },
        onCancel() {},
      })
    }
  }

  function check_new_data_edit() {
    const new_data = api.DETAIL_CAR(token, props.data_car.car_id)
    axios(new_data)
      .then(res => {
        props.setData_car(res.data.data[0])
      })
      .catch(error => {
        // console.log(error.response)
      })
  }

  const onFill = () => {
    if (props.data_car) {
      form.setFieldsValue({
        car_license_number: props.data_car.car_license_number,
        car_name: props.data_car.car_name,
        car_license_province_id: props.data_car.pro_name,
        car_color_id: props.data_car.car_color_id,
        car_chassis_number: props.data_car.car_chassis_number,
        car_body_type_id: props.data_car.car_body_type_id,
        car_brand_id: props.data_car.car_brand_id,
        car_model_id: props.data_car.car_model_id,
        car_model_year_id: props.data_car.car_model_year_id,
        car_group_id: props.data_car.car_group_id,
        car_remark : props.data_car.car_remark
      })
    }
  }

  function Cancel_Edit() {
    setData_edit({})
    setEdit(!edit)
    onFill()
    setCustomer_Info({
      ...customer_Info,
      cus_id: props.data_car.cus_id,
      cus_name: props.data_car.cus_name,

      cus_branch_id: props.data_car.cus_branch_id,
      cus_branch_name: props.data_car.cus_branch_name,
    })
  }

  useEffect(() => {
    onFill()
    setCustomer_Info({
      ...customer_Info,
      cus_id: props.data_car.cus_id,
      cus_name: props.data_car.cus_name,
   
      cus_branch_id: props.data_car.cus_branch_id,
      cus_branch_name: props.data_car.cus_branch_name,
    })

    return () => {
      setCustomer_Info({})
      setData_edit({})
    }
  }, [props.data_car])

  const openNotificationWithIcon = (type, description) => {
    notification[type]({
      message: description,
    })
  }

  return (
    <div>
      <Row>
        <Col>
          <div style={{ display: "inherit" }}>
            <p
              className="text-bold"
              style={{
                color: "#495057",
                textAlign: "center",
                fontSize: "15px",
              }}
            >
              {t("Car information")}
            </p>
            {edit === true && (
              <Button
                style={{ float: "right", width: "150px" }}
                onClick={() => setEdit(!edit)}
              >
                {t("Edit")}
              </Button>
            )}
            {edit === false && (
              <Button
                type="primary"
                style={{ float: "right", width: "150px" }}
                onClick={() => Cancel_Edit()}
              >
                {t("Cancel")}
              </Button>
            )}
          </div>
        </Col>
      </Row>

      <Form
        form={form}
        name="edit_car_form"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("License no")}
              message={t("License no")}
              placeholder={props.data_car.car_license_number}
              required={false}
              headState={setData_edit}
              nameState={data_edit}
              keyState="car_license_number"
              disabled={edit}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Car name")}
              placeholder={props.data_car.car_name}
              headState={setData_edit}
              nameState={data_edit}
              keyState="car_name"
              disabled={edit}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_Search_Comp
              header={t("Province")}
              placeholder={props.data_car.pro_name}
              keyState="car_license_province_id"
              headState={setData_edit}
              nameState={data_edit}
              disabled={edit}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Color")}
              name_api="LIST_CAR_COLOR"
              keyState="car_color_id"
              map_name="car_color_name"
              headState={setData_edit}
              nameState={data_edit}
              disabled={edit}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Chassis number")}
              placeholder={props.data_car.car_chassis_number}
              headState={setData_edit}
              nameState={data_edit}
              keyState="car_chassis_number"
              disabled={edit}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Car group")}
              name_api="FIND_ALL_CAR_GROUP"
              map_name="car_group_name"
              keyState="car_group_id"
              headState={setData_edit}
              nameState={data_edit}
              disabled={edit}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Car type")}
              name_api="LIST_CAR_BODY_TYPE"
              map_name="car_body_type_name"
              keyState="car_body_type_id"
              headState={setData_edit}
              nameState={data_edit}
              disabled={edit}
            />
          </Col>
  
          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Brand")}
              name_api="LIST_CAR_BRAND"
              map_name="car_brand_name"
              keyState="car_brand_id"
              headState={setData_edit}
              nameState={data_edit}
              disabled={edit}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Model")}
              name_api="LIST_CAR_MODEL"
              map_name="car_model_name"
              keyState="car_model_id"
              headState={setData_edit}
              nameState={data_edit}
              disabled={edit}
            />
          </Col>
 
          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Year")}
              name_api="LIST_CAR_YEAR"
              map_name="car_model_year_name"
              keyState="car_model_year_id"
              headState={setData_edit}
              nameState={data_edit}
              disabled={edit}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Car remark")}
              placeholder={props.data_car.car_remark}
              headState={setData_edit}
              nameState={data_edit}
              keyState="car_remark"
              disabled={edit}
            />
          </Col>
          </Row>
          
          <Row>
          <Col>
            <Customer_Comp
              customer_Info={customer_Info}
              setCustomer_Info={setCustomer_Info}
              setData_edit={setData_edit}
              data_edit={data_edit}
              disabled={edit}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            {customer_Info.cus_id && (
              <Branch_Comp
                customer_Info={customer_Info}
                setCustomer_Info={setCustomer_Info}
                setData_edit={setData_edit}
                data_edit={data_edit}
                disabled={edit}
              />
            )}
          </Col>
        </Row>
        <br />
        {edit === false && (
          <Button
            type="primary"
            htmlType="submit"
            style={{ display: "block", margin: "auto", width: "150px" }}
            loading={loading}
          >
            {t("Edit")}
          </Button>
        )}
      </Form>
    </div>
  )
}
