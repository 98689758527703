import React from 'react'
import Delete_Employee from './Delete_Employee'

export default function Setting(props) {
    return (
        <div>
            <Delete_Employee 
                id={props.id}
            />
        </div>
    )
}
