import React, { useEffect } from "react"
import { Container } from "reactstrap"
import { useLocation, useHistory, withRouter } from "react-router-dom"
import { Card, CardBody } from "reactstrap"
import { Tabs } from 'antd';
import Detail_setup from './detail/Detail_setup'
import Detail_repair from './detail/Detail_repair'
import Detail_header from "./detail/Detail_header"
import MetaTags from 'react-meta-tags';
const { TabPane } = Tabs;

const Detail_car = props => {
  let location = useLocation()
  let history = useHistory()
  const data_car = location.detail_car

  if (!data_car) {
    history.push("/cars")
  }

  useEffect(() => {
    render_detail()
    return () => {}
  }, [data_car])

  function render_detail() {
    if (data_car) {
      return (
        <div>
          <Card>
            <CardBody>
            <h3 style={{ color:'#495057', textAlign:'center', marginBottom:'30px' }}>Car infomation</h3>
              <Detail_header detail={data_car} />
              <hr />

             <Tabs defaultActiveKey="1" >
              <TabPane tab="Setup record" key="1">
                <Detail_setup data_car={data_car}/>
              </TabPane>
              <TabPane tab="Repair record" key="2">
                <Detail_repair data_car={data_car}/>
              </TabPane>
              <TabPane tab="Maintenance record" key="3">
                <Detail_setup data_car={data_car}/>
              </TabPane>
      </Tabs>

            </CardBody>
          </Card>
         
        </div>
      )
    }
  }
  
  return (
    <React.Fragment>
              <MetaTags>
          <title> Car infomation | Distar</title>
        </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb 

          <Detail_header detail={data_car} /> */}
          {render_detail()}
        </Container>
      </div>
    </React.Fragment>
  )
}
export default withRouter(Detail_car)
