var Ip = require('../service/Ip')

export const LIST_DASHBOARD = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}dashboard/main`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_USER_INFO = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}authorize/user_info`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const COUNT_ONLINE_USER = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}dashboard/countonlineuser`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const SHOW_ALL_USER_ONLINE = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}dashboard/showonlineuser`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const SHOW_EMPLOYEE_ONLINE = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}dashboard/showonlineuser/employee`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const SHOW_ADMIN_ONLINE = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}dashboard/showonlineuser/admin`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const SHOW_CUSTOMER_ONLINE = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}dashboard/showonlineuser/customer_user`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const SHOW_OPERATOR_ONLINE = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}dashboard/showonlineuser/operator_user`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const SHOW_DEALER_ONLINE = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}dashboard/showonlineuser/dealer`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };