import React from "react"
import { Row, Col } from "reactstrap"
import Render_Image from "./Render_Image"
import { useTranslation } from "react-i18next";

export default function Modal_comp(props) {
  const { t } = useTranslation();
  const text_header = {
    fontWeight: "bold",
    fontSize: "15px",
  }

  const data_detail = [
    {
      headerDetail1: t("License number"),
      data1: props.detail_modal.car_license_number,
      headerDetail2: t("Car name"),
      data2: props.detail_modal.car_name,
    },
    {
      headerDetail1: t("Product name"),
      data1: props.detail_modal.pro_model_name,
      headerDetail2: t("Product group"),
      data2: props.detail_modal.pro_group_name,
    },
    {
      headerDetail1: t("Serial number"),
      data1: props.detail_modal.pro_serial_number,
      headerDetail2: t("Asset number"),
      data2: props.detail_modal.sto_asset_number,
    },
    {
      headerDetail1: t("Install date"),
      data1: cutTimeZone(props.detail_modal.sto_date_install),
      headerDetail2: t("Warranty"),
      data2: props.detail_modal.warranty_name,
    },
  ]

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return "N/A"
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  return (
    <div className="scroll-modal-display-mobile-app">
      <p
        style={{
          textAlign: "center",
          fontWeight: "bold",
          marginTop: "10px",
          fontSize: "15px",
        }}
      >
        {t("Device information")}
      </p>
      <hr />
      <Render_Image 
        path_image={props.detail_modal.pro_model_img} 
        size={150} 
      />
        <br />
      {data_detail.map((data, index) => {
        return (
          <Row key={index}>
            <Col sm={6} md={3} lg={3} xl={3}>
              <p style={text_header}> {data.headerDetail1} </p>
            </Col>
            <Col sm={6} md={3} lg={3} xl={3}>
              <p style={{ fontSize: "15px" }}>
                {" "}
                <span className="tet">
                  :
                </span> <span className="tetTwo">-</span> {data.data1}{" "}
              </p>
            </Col>
            <Col sm={6} md={3} lg={3} xl={3}>
              <p style={text_header}> {data.headerDetail2} </p>
            </Col>
            <Col sm={6} md={3} lg={3} xl={3}>
              <p style={{ fontSize: "15px" }}>
                {" "}
                <span className="tet">
                  :
                </span> <span className="tetTwo">-</span> {data.data2}{" "}
              </p>
            </Col>
          </Row>
        )
      })}
    </div>
  )
}
