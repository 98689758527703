import React from "react"
import { Row, Col } from "reactstrap"
import Select_Data_Filter from "./Select_Data_Filter"
import Select_Data_Search_Filter from "./Select_Data_Search_Filter"
import Select_Filter_Customer from "./Select_Filter_Customer"
import { useTranslation } from "react-i18next";

export default function select_search(props) {
  const { t } = useTranslation();

  return (
    <div>
      <Row>
        <Col sm={12} md={3} lg={3} xl={3}>
          <Select_Data_Search_Filter
            header={t("Search province")}
            placeholder={t("Search province")}
            headState={props.setSearch_car_info}
            nameState={props.search_car_info}
            keyState="province_id"
            map_name="pro_name"
            map_value="pro_id"
            name_api="LIST_PROVINCE"
            name_api_all="LIST_PROVINCE_ALL"
          />
        </Col>

        <Col sm={12} md={3} lg={3} xl={3}>
          <Select_Data_Filter
            header={t("Search car body")}
            placeholder={t("Search car body")}
            name_api="LIST_CAR_BODY_TYPE"
            headState={props.setSearch_car_info}
            nameState={props.search_car_info}
            keyState="car_body_type_id"
            map_name="car_body_type_name"
            map_value="car_body_type_id"
          />
        </Col>

        <Col sm={12} md={3} lg={3} xl={3}>
          <Select_Filter_Customer
            headState={props.setSearch_car_info}
            nameState={props.search_car_info}
            keyState="customer_id"
          />
        </Col>
      </Row>
    </div>
  )
}
