import React, { useState, useEffect } from "react"
import { Row, Col, Container, Card, CardBody } from "reactstrap"
import MetaTags from "react-meta-tags"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import Search_Admin from "./Search_Admin"
import axios from "axios"
import * as api from "../../service/Api_Admin"
import { Table, Tooltip, Modal } from "antd"
import Render_Image_Data from "../Employee/Table/Render_Image_Data"
import { FcSettings, FcSearch } from "react-icons/fc"
import helpers from "../Function_Redirect/Redirect_login"
import Overview from "../Edit_Admin/Overview"

const Admin = props => {
  const { t } = useTranslation()
  const history = useHistory()
  const token = localStorage.getItem("token")
  const [search_data_user, setSearch_data_user] = useState(null)
  const [admin_all, setAdmin_all] = useState([])
  const [array_for_search, setArray_for_search] = useState([])
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [detail_admin, setDetail_admin] = useState(null)

  useEffect(() => {
    list_all_admin()
    return () => {
      setSearch_data_user(null)
      setAdmin_all([])
      setArray_for_search([])
      setLoading(false)
      setVisible(false)
      setDetail_admin(null)
    }
  }, [])

  function list_all_admin() {
    setLoading(true)
    const list_all = api.LIST_ALL_ADMIN(token)
    axios(list_all)
      .then(res => {
        setLoading(false)
        // console.log(res.data.data)
        setAdmin_all(res.data.data)
        setArray_for_search(res.data.data)
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
        // console.log(error.response)
      })
  }

  function search_data() {
    if (search_data_user) {
      const after_search = handleSearch(array_for_search, search_data_user)
      setAdmin_all(after_search)
    } else {
      list_all_admin()
    }
  }

  const handleSearch = (arr, searchInput) =>
    arr.filter(obj =>
      Object.values(obj)
        .flat()
        .some(v =>
          `${v}`.toLowerCase().includes(`${searchInput}`.toLowerCase())
        )
    )

  const columns = [
    {
      title: "",
      dataIndex: "admin_avatar",
      key: "admin_avatar",
      render: text => <Render_Image_Data path_image={text} />,
    },
    {
      title: t("firstname"),
      dataIndex: "admin_firstname",
      key: "admin_firstname",
    },
    {
      title: t("Email"),
      dataIndex: "admin_email",
      key: "admin_email",
    },
    {
      title: t("Group"),
      dataIndex: "admin_group_name",
      key: "admin_group_name",
    },
    {
      title: "",
      render: id => (
        <Tooltip title={t("View admin")}>
          <a>
            <FcSearch
              onClick={() => {
                setVisible(true)
                setDetail_admin(id)
              }}
              size={20}
            />
          </a>
        </Tooltip>
      ),
    },
    {
      title: "",
      dataIndex: "admin_id",
      key: "admin_id",
      render: id => (
        <Tooltip title={t("Edit admin")}>
          <a>
            <FcSettings
              size={20}
              onClick={() => history.push(`/admin-management/${id}`)}
            >
              {t("edit")}
            </FcSettings>
          </a>
        </Tooltip>
      ),
    },
  ]

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("admin management")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}> {t("admin management")} </h3>
          <Card>
            <CardBody>
              <Search_Admin
                search_data={search_data}
                setSearch_data_user={setSearch_data_user}
              />

              <Table
                columns={columns}
                dataSource={admin_all}
                loading={loading}
                rowKey="admin_id"
                scroll={{ x: 800 }}
              />

              <Modal
                title={false}
                centered
                open={visible}
                onOk={() => {
                  setVisible(false)
                  setDetail_admin(null)
                }}
                onCancel={() => {
                  setVisible(false)
                  setDetail_admin(null)
                }}
                footer={false}
                height={500}
                width={1000}
              >
                <div className="scroll-modal-display-mobile-app">
                  <Overview detail_admin={detail_admin} />
                </div>
              </Modal>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Admin
