import React, { useEffect, useState } from "react"
import { Form, Button, Modal, notification } from "antd"
import { Row, Col } from "reactstrap"
import Input_Comp from "./Components/Input_Comp"
import Input_Date_Comp from "./Components/Input_Date_Comp"
import Select_no_search from "./Components/Select_no_search"
import List_Stock from "./Components/List_Stock"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import axios from 'axios'
import * as api from '../../../service/Api_Edit_Service_IN_Contract'

const { confirm } = Modal

export default function Edit_service(props) {
  const [form] = Form.useForm()
  const token = localStorage.getItem('token')
  const [edit, setEdit] = useState(true)
  const [stock_name, setStock_name] = useState(null)
  const [service_edit, setService_edit] = useState({})

  useEffect(() => {
    onFill()
    return () => {
      setService_edit({})
    }
  }, [props.data_service])

  const Cancel_Edit = () => {
    onReset()
    onFill()
    setService_edit({})
    setEdit(!edit)
  }

  const onReset = () => {
    form.resetFields()
  }

  const onFill = () => {
    form.setFieldsValue({
      ser_name: props.data_service.ser_name,
      ser_contact_name: props.data_service.ser_contact_name,
      ser_contact_phone: props.data_service.ser_contact_phone,
      pri_id: props.data_service.pri_id,
      ser_status_id: props.data_service.ser_status_id,
      ser_received_type_id: props.data_service.ser_received_type_id,
    })
    setStock_name(props.data_service.sto_asset_number)
  }

  const openNotificationWithIcon = (type, description) => {
    notification[type]({
      message: description,
    })
  }

  const onFinish = values => {
    // if (service_edit.sto_id === null) {
    //   return Modal.error({
    //     title: "Please select stock",
    //     centered: true,
    //   })
    // } else {
      return confirm({
        title: "Confirm edit service",
        icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
        centered: true,
        onOk() {
            let last_edit_service = {}
            for (const [key, value] of Object.entries(service_edit)) {
              if (!value) {
              } else {
                last_edit_service[key] = value
              }
            }
            if (Object.keys(last_edit_service)[0] === undefined) {
                openNotificationWithIcon("info", "No data is change")
            } else {
                const edit_service = api.EDIT_SERVICE(token, last_edit_service, props.ser_id)
                axios(edit_service)
                .then( res => {
               
                    openNotificationWithIcon("success", "Edit success")
                    props.check_detail_service()
                    Cancel_Edit()
                })
                .catch( error => {
                    console.log(error.response);
                })
            }

        },
        onCancel() {
         
        },
      })
    // }
  }

  return (
    <Form form={form} name="edit_service_data_info" onFinish={onFinish}>
      <Row>
        <Col>
          <div style={{ display: "inherit" }}>
            <p
              className="text-bold"
              style={{
                color: "#495057",
                textAlign: "center",
                fontSize: "15px",
              }}
            >
              Service information
            </p>
            {edit === true && (
              <Button
                style={{ float: "right", width: "150px" }}
                onClick={() => setEdit(!edit)}
              >
                Edit
              </Button>
            )}
            {edit === false && (
              <Button
                type="primary"
                style={{ float: "right", width: "150px" }}
                onClick={() => Cancel_Edit()}
              >
                Cancel
              </Button>
            )}
          </div>
        </Col>
      </Row>
      <br />

      <Row>
        <Col sm={12} md={6} lg={6} xl={6}>
          <Input_Comp
            header="service number"
            placeholder={props.data_service.ser_no}
            headState={setService_edit}
            nameState={service_edit}
            keyState="ser_no"
            disabled={true}
            type="text"
          />
        </Col>
        <Col sm={12} md={6} lg={6} xl={6}>
          <Input_Comp
            header="service name"
            placeholder={props.data_service.ser_name}
            headState={setService_edit}
            nameState={service_edit}
            keyState="ser_name"
            disabled={edit}
            type="text"
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6} lg={6} xl={6}>
          <Input_Comp
            header="service contact name"
            placeholder={props.data_service.ser_contact_name}
            headState={setService_edit}
            nameState={service_edit}
            keyState="ser_contact_name"
            disabled={edit}
            type="text"
          />
        </Col>
        <Col sm={12} md={6} lg={6} xl={6}>
          <Input_Comp
            header="service contact phone"
            placeholder={props.data_service.ser_contact_phone}
            headState={setService_edit}
            nameState={service_edit}
            keyState="ser_contact_phone"
            disabled={edit}
            type="number"
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6} lg={6} xl={6}>
          <Input_Date_Comp
            header="Service date start"
            placeholder={props.data_service.ser_plan_startdate}
            headState={setService_edit}
            nameState={service_edit}
            keyState="ser_plan_startdate"
            disabled={edit}
          />
        </Col>
        <Col sm={12} md={6} lg={6} xl={6}>
          <Input_Date_Comp
            header="Service date end"
            placeholder={props.data_service.ser_plan_enddate}
            headState={setService_edit}
            nameState={service_edit}
            keyState="ser_plan_enddate"
            disabled={edit}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6} lg={6} xl={6}>
          <Select_no_search
            header="Priority"
            placeholder={props.data_service.pri_id}
            name_api="LIST_PRIORITY"
            map_name="pri_name"
            map_value="pri_id"
            keyState="pri_id"
            headState={setService_edit}
            nameState={service_edit}
            disabled={edit}
          />
        </Col>
        <Col sm={12} md={6} lg={6} xl={6}>
          <Select_no_search
            header="Status"
            placeholder={props.data_service.ser_status_id}
            name_api="LIST_STATUS"
            map_name="ser_status_name"
            map_value="ser_status_id"
            keyState="ser_status_id"
            headState={setService_edit}
            nameState={service_edit}
            disabled={edit}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6} lg={6} xl={6}>
          <Select_no_search
            header="Receive type"
            placeholder={props.data_service.ser_received_type_id}
            name_api="LIST_RECEIVED_TYPE"
            map_name="ser_received_type_name"
            map_value="ser_received_type_id"
            keyState="ser_received_type_id"
            headState={setService_edit}
            nameState={service_edit}
            disabled={edit}
          />
        </Col>
      </Row>

{/*      <Row>
        <Col>
          <List_Stock
            setStock_name={setStock_name}
            stock_name={stock_name}
            setService_edit={setService_edit}
            service_edit={service_edit}
            // setData_stock={setData_stock}
            // data_stock={data_stock}
            // setData_new={setData_new}
            // data_new={data_new}
            cus_id={props.cus_id}
            disabled={edit}
          />
        </Col>
</Row> */}

      <br />
      {edit === false && (
        <Button
          type="primary"
          htmlType="submit"
          style={{ display: "block", margin: "auto", width: "150px" }}
        >
          {" "}
          Edit{" "}
        </Button>
      )}
    </Form>
  )
}
