import React, { useState, useEffect } from "react"
import { Tabs } from "antd"
import Table_users from "./Table_users"
import * as api from "../../../../service/Api_New_Customer"
import axios from "axios"
import Create_new_user from "./Create_new_user"
import { useHistory } from "react-router-dom"
import helpers from "../../../Function_Redirect/Redirect_login"

const { TabPane } = Tabs

export default function Branch_new(props) {
  const token = localStorage.getItem("token")
  const history = useHistory()
  const [activeTab_Users, setActiveTab_Users] = useState("1")
  const [data_User_All, setData_User_All] = useState([])
  const [search_data_user, setSearch_data_user] = useState(null)
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    search_data_user_table()
    return () => {
      setData_User_All([])
    }
  }, [])

  function search_data_user_table() {
    setLoading(true)
    if (search_data_user) {
      const search_user_data = api.SEARCH_USER_DATA(
        token,
        props.id,
        1,
        page_limit.page_Size,
        search_data_user
      )
      axios(search_user_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setData_User_All(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else {
      const search_user_nodata = api.SEARCH_NO_USER_DATA(
        token,
        props.id,
        1,
        page_limit.page_Size
      )
      axios(search_user_nodata)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setData_User_All(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  function changePagination_users(page, pageSize) {
    setLoading(true)
    if (search_data_user) {
      const search_user_data = api.SEARCH_USER_DATA(
        token,
        props.id,
        page,
        pageSize,
        search_data_user
      )
      axios(search_user_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setData_User_All(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else {
      const search_user_nodata = api.SEARCH_NO_USER_DATA(
        token,
        props.id,
        page,
        pageSize
      )
      axios(search_user_nodata)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setData_User_All(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  return (
    <div>
      <Table_users
        id={props.id}
        data_User_All={data_User_All}
        setSearch_data_user={setSearch_data_user}
        search_data_user_table={search_data_user_table}
        changePagination_users={changePagination_users}
        page_limit={page_limit}
        loading={loading}
      />
    </div>
  )
}
