import React, { useState, useEffect } from "react"
import { Select, Form } from "antd"
import axios from "axios"
import * as api from "../../../../../service/Api_New_Customer"
import { useHistory } from "react-router-dom";
import helpers from '../../../../Function_Redirect/Redirect_login'

const { Option } = Select

export default function Select_Search_Comp({
  header,
  placeholder,
  keyState,
  headState,
  nameState,
}) {
  const [location, setLocation] = useState([])
  const token = localStorage.getItem("token")
  const history = useHistory()

  function onChange(value) {
    headState({ ...nameState, [keyState]: value })
  }

  function onSearch(val) {
    if (val) {
      const search_province_ = api.LIST_PROVINCE(token, val)
      axios(search_province_)
        .then(res => {
          setLocation(res.data.message)
        })
        .catch(error => {
              if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }
        })
    } else {
    }
  }

  function onSearch_First() {
    const search_province_ = api.LIST_PROVINCE_NO_DATA(token)
      axios(search_province_)
        .then(res => {
          setLocation(res.data.message)
        })
        .catch(error => {
              if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }
        })
  }

  useEffect(() => {
    onSearch_First()
    return () => {
    }
  }, [])

  useEffect(() => {
    render_select_province()
    return () => {
    }
  }, [location])

  function render_select_province() {
    return location.map(data => {
      return (
        <Option key={data.pro_id} value={data.pro_id}>
          {data.pro_name}
        </Option>
      )
    })
  }

  return (
    <div>
      <p className="text-bold">
        {header} <span style={{color:'red'}}>*</span>
      </p>
      <Form.Item
        name={keyState}
        rules={[
          {
            required: true,
            message: `${placeholder}`,
          },
        ]}
      >
        <Select
          showSearch
          style={{ width: "100%" }}
          placeholder={placeholder}
          optionFilterProp="children"
          onChange={onChange}
          onSearch={onSearch}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {render_select_province()}
        </Select>
      </Form.Item>
    </div>
  )
}
