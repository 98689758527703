import React, { useState, useEffect } from "react"
import { Container, Card, CardBody, Row, Col } from "reactstrap"
import MetaTags from "react-meta-tags"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import { MdKeyboardBackspace } from "react-icons/md"
import { Button, Modal, notification } from "antd"
import axios from "axios"
import * as api from "../../../service/Api_Service"
import helpers from "../../Function_Redirect/Redirect_login"
import Detail_Request_Repair from "../../Check_Request_Repair/Detail_Request_Repair"
import { ExclamationCircleOutlined } from "@ant-design/icons"

const { confirm } = Modal

const Detail_Repair = props => {
  const { t } = useTranslation()
  const token = localStorage.getItem("token")
  const history = useHistory()
  const { rrs_id } = useParams()
  const [detail_repair, setDetail_repair] = useState(null)
  const [reject_repair_status, setReject_repair_status] = useState(false)
  const [confirm_repair_status, setConfirm_repair_status] = useState(false)
  const [waiting_status, setWaiting_status] = useState(false)
  const [loading, setLoading] = useState(false)

  const text_detail = {
    textAlign: "center",
    fontSize: "20px",
  }

  useEffect(() => {
    check_detail_repair()
    return () => {}
  }, [rrs_id])

  function check_detail_repair() {
    const check_detail = api.CHECK_DETAIL_REPAIR(token, rrs_id)
    axios(check_detail)
      .then(res => {
        if (res.data.data[0] === undefined) {
          history.push("/repair")
        } else {
          setDetail_repair(res.data.data[0])
          if (res.data.data[0].rrs_status_name === "reject") {
            setReject_repair_status(true)
          } else if (res.data.data[0].rrs_status_name === "confirmed") {
            setConfirm_repair_status(true)
          } else if (
            res.data.data[0].rrs_status_name === "waiting for confirmation"
          ) {
            setWaiting_status(true)
          }
        }
      })
      .catch(error => {
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  function remove_repair() {
    return confirm({
      title: t("Confirm remove repair"),
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okType: "danger",
      okText: t("Confirm"),
      cancelText: t("no"),
      onOk() {
        setLoading(true)
        const remove_repair = api.DELETE_REPAIR(token, rrs_id)
        axios(remove_repair)
          .then(res => {
            setLoading(false)
            openNotificationWithIcon("error", t("Remove repair error"))
          })
          .catch(error => {
            setLoading(false)
            if (error) {
              if (error.response) {
                if (error.response.status === 401) {
                  helpers.re_login()
                  history.push("/login")
                } else if (error.response.status === 404) {
                  openNotificationWithIcon(
                    "success",
                    t("Remove repair success")
                  )
                  history.push("/repair")
                } else {
                  openNotificationWithIcon("error", t("Remove repair error"))
                }
              }
            }
          })
      },
      onCancel() {},
    })
  }

  useEffect(() => {
    render_approved_user()
    render_date_time_approved()
    return () => {}
  }, [detail_repair])

  function render_approved_user() {
    if (detail_repair !== null) {
      if (detail_repair.active_by_role === "administrator") {
        return (
          detail_repair.active_by.admin_firstname +
          " " +
          detail_repair.active_by.admin_lastname
        )
      } else if (detail_repair.active_by_role === "employee") {
        return (
          detail_repair.active_by.emp_firstname +
          " " +
          detail_repair.active_by.emp_lastname
        )
      }
    }
  }

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return "N/A"
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  function render_date_time_approved() {
    if (detail_repair !== null) {
      return (
        <p style={text_detail}>
          {t("date")} : {cutTimeZone(detail_repair.active_at)}
        </p>
      )
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Detail repair")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/repair")}
              />
            </a>{" "}
            {t("Detail repair")}
          </h3>

          {detail_repair !== null && (
            <Card>
              <CardBody>
                {waiting_status === true && (
                  <Row>
                    <Col>
                      <Button
                        loading={loading}
                        danger
                        style={{ float: "right", marginBottom: "20px" }}
                        onClick={() => remove_repair()}
                      >
                        {" "}
                        {t("Remove repair")}{" "}
                      </Button>
                    </Col>
                  </Row>
                )}

                {confirm_repair_status === true && (
                  <>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "30px",
                        color: "green",
                      }}
                    >
                      {" "}
                      {t("Approved")}{" "}
                    </p>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "20px",
                      }}
                    >
                      {" "}
                      {t("Approved by")} : {render_approved_user()}{" "}
                    </p>
                    {render_date_time_approved()}
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "20px",
                      }}
                    >
                      {" "}
                      {t("Service number")} : {detail_repair.ser_no}
                    </p>
                    <br />
                  </>
                )}
                {reject_repair_status === true && (
                  <>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "30px",
                        color: "red",
                      }}
                    >
                      {" "}
                      {t("Reject")}{" "}
                    </p>
                    <p style={{ textAlign: "center", fontSize: "25px" }}>
                      {" "}
                      {t("remark")} : {detail_repair.rrs_reject_detail}
                    </p>
                    <p style={text_detail}>
                      {" "}
                      {t("Reject by")} : {render_approved_user()}{" "}
                    </p>
                    {render_date_time_approved()}
                  </>
                )}
                {waiting_status === true && (
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "30px",
                      color: "#8B8B8B",
                    }}
                  >
                    {" "}
                    {t("waiting for confirmation")}{" "}
                  </p>
                )}

                <Detail_Request_Repair detail_repair={detail_repair} />
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Detail_Repair
