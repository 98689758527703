import React, { useEffect } from "react"
import { Row, Col } from "reactstrap"
import { Button } from "antd"
import TextField from "./TextField"
import { useTranslation } from "react-i18next";

export default function FormDragAndDrop(props) {
  const { t } = useTranslation();
  
  const handleInputChange = (index, event) => {
    const values = [...props.stepField]
    values[props.index].fields[index].task_name = event.target.value
    props.setStepField(values)
  }
  const handleInputChange_Detail = (index, event) => {
    const values = [...props.stepField]
    values[props.index].fields[index].task_detail = event.target.value
    props.setStepField(values)
  }
  const handleInputChange_Priority = (index, value) => {
    const values = [...props.stepField]
    values[props.index].fields[index].task_priority_id = value
    props.setStepField(values)
  }

  const handleAddFieldsText = task_type_module_id => {
    const values = [...props.stepField]
    values[props.index].fields.push({
      task_detail: "",
      task_name: "",
      type: "text",
      text_header: t("Text"),
      task_priority_id: null,
      task_type_module_id: task_type_module_id,
    })
    props.setStepField(values)
  }
  const handleAddFieldsPhoto = task_type_module_id => {
    const values = [...props.stepField]
    values[props.index].fields.push({
      task_detail: "",
      task_name: "",
      type: "photo",
      text_header: t("Photo"),
      task_priority_id: null,
      task_type_module_id: task_type_module_id,
    })
    props.setStepField(values)
  }
  const handleAddFieldsCheckIn = task_type_module_id => {
    const values = [...props.stepField]
    values[props.index].fields.push({
      task_detail: "",
      task_name: "",
      type: "checkIn",
      text_header: t("Check in"),
      task_priority_id: null,
      task_type_module_id: task_type_module_id,
    })
    props.setStepField(values)
  }
  const handleAddFieldsCheckOut = task_type_module_id => {
    const values = [...props.stepField]
    values[props.index].fields.push({
      task_detail: "",
      task_name: "",
      type: "checkOut",
      text_header: t("Check out"),
      task_priority_id: null,
      task_type_module_id: task_type_module_id,
    })
    props.setStepField(values)
  }
  const handleAddFieldsSignature = task_type_module_id => {
    const values = [...props.stepField]
    values[props.index].fields.push({
      task_detail: "",
      task_name: "",
      type: "signature",
      text_header: t("Signature"),
      task_priority_id: null,
      task_type_module_id: task_type_module_id,
    })
    props.setStepField(values)
  }

  const handleAddFieldsUploadFile = task_type_module_id => {
    const values = [...props.stepField]
    values[props.index].fields.push({
      task_detail: "",
      task_name: "",
      type: "uploadFile",
      text_header: t("upload file"),
      task_priority_id: null,
      task_type_module_id: task_type_module_id,
    })
    props.setStepField(values)
  }

  const handleRemoveFields = index => {
    const values = [...props.stepField]
    values[props.index].fields.splice(index, 1)

    props.setStepField(values)
  }

  function renderCreateForm(inputField, index) {
    if (
      inputField.type === "text" ||
      inputField.type === "photo" ||
      inputField.type === "checkIn" ||
      inputField.type === "checkOut" ||
      inputField.type === "signature" ||
      inputField.type === "uploadFile"
    ) {
      return (
        <TextField
          key={`${inputField}~${index}`}
          inputField={inputField}
          index={index}
          handleInputChange={handleInputChange}
          handleInputChange_Detail={handleInputChange_Detail}
          handleRemoveFields={handleRemoveFields}
          handleInputChange_Priority={handleInputChange_Priority}
          priority={props.priority}
        />
      )
    }
  }

  useEffect(() => {
    render_button_module()
    return () => {}
  }, [props.module])

  function render_button_module() {
    return props.module.map((data, index) => {
      switch (data.task_type_module_name) {
        case "inputField":
          return (
            <Button
              onClick={() => handleAddFieldsText(data.task_type_module_id)}
              style={buttonStyle}
              key={index}
            >
              {t("Text")}
            </Button>
          )
          break
        case "imageField":
          return (
            <Button
              onClick={() => handleAddFieldsPhoto(data.task_type_module_id)}
              style={buttonStyle}
              key={index}
            >
              {t("Photo")}
            </Button>
          )
          break
        case "signatureField":
          return (
            <Button
              onClick={() => handleAddFieldsSignature(data.task_type_module_id)}
              style={buttonStyle}
              key={index}
            >
              {t("Signature")}
            </Button>
          )
          break
        case "checkinField":
          return (
            <Button
              onClick={() => handleAddFieldsCheckIn(data.task_type_module_id)}
              style={buttonStyle}
              key={index}
            >
              {t("Check in")}
            </Button>
          )
          break
        case "checkoutField":
          return (
            <Button
              onClick={() => handleAddFieldsCheckOut(data.task_type_module_id)}
              style={buttonStyle}
              key={index}
            >
              {t("Check out")}
            </Button>
          )
          break
          case "uploadFileField":
            return (
              <Button
                onClick={() => handleAddFieldsUploadFile(data.task_type_module_id)}
                style={buttonStyle}
                key={index}
              >
                {t("upload file")}
              </Button>
            )
            break
        default:
          break
      }
    })
  }

  const buttonStyle = {
    display: "flex",
    width: "100%",
    marginBottom: "10px",
  }

  return (
    <Row>
      <Col sm={8} xs={8} md={8} lg={8}>
        <form>
          <div className="form-row">
            {props.stepField[props.index].fields.map((inputField, index) => {
              return renderCreateForm(inputField, index)
            })}
          </div>
        </form>
      </Col>
      <Col sm={4} xs={4} md={4} lg={4}>
        {render_button_module()}
      </Col>
    </Row>
  )
}
