import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next";

export default function Detail_Sale_no_dealer_db(props) {
    const [no_data_dealer, setNo_data_dealer] = useState(null)
    const { t } = useTranslation();

    useEffect(() => {
      setNo_data_dealer(props.data_no_dealer_db)
      return () => {
        setNo_data_dealer(null)
      }
    }, [props.data_no_dealer_db])

    return (
        <div className="scroll-modal-display-mobile-app"  >
        <p
            style={{
            textAlign: "center",
            fontWeight: "bold",
            marginTop: "10px",
            fontSize: "15px",
            }}
        >
            {t("Dealer information")}
        </p>

        {
            no_data_dealer !== null && (
                <Row>
                    <Col sm={12} md={6} lg={2} xl={2}>
                        <p className="text-bold"> name </p>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <p style={{ fontSize: "15px" }}>
                        <span className="tet">:</span> <span className="tetTwo">-</span>{" "}
                        {no_data_dealer.name}
                        </p>
                    </Col>

                    <Col sm={12} md={6} lg={2} xl={2}>
                        <p className="text-bold"> nickname </p>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <p style={{ fontSize: "15px" }}>
                        <span className="tet">:</span> <span className="tetTwo">-</span>{" "}
                        {no_data_dealer.nickname}
                        </p>
                    </Col>

                    <Col sm={12} md={6} lg={2} xl={2}>
                    <p className="text-bold"> pid </p>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <p style={{ fontSize: "15px" }}>
                        <span className="tet">:</span> <span className="tetTwo">-</span>{" "}
                        {no_data_dealer.pid}
                        </p>
                    </Col>

                    <Col sm={12} md={6} lg={2} xl={2}>
                    <p className="text-bold"> pfid </p>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <p style={{ fontSize: "15px" }}>
                        <span className="tet">:</span> <span className="tetTwo">-</span>{" "}
                        {no_data_dealer.pfid}
                        </p>
                    </Col>

                    <Col sm={12} md={6} lg={2} xl={2}>
                    <p className="text-bold"> gid </p>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <p style={{ fontSize: "15px" }}>
                        <span className="tet">:</span> <span className="tetTwo">-</span>{" "}
                        {no_data_dealer.gid}
                        </p>
                    </Col>

                    <Col sm={12} md={6} lg={2} xl={2}>
                    <p className="text-bold"> cid </p>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <p style={{ fontSize: "15px" }}>
                        <span className="tet">:</span> <span className="tetTwo">-</span>{" "}
                        {no_data_dealer.cid}
                        </p>
                    </Col>

                    <Col sm={12} md={6} lg={2} xl={2}>
                    <p className="text-bold"> phone </p>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <p style={{ fontSize: "15px" }}>
                        <span className="tet">:</span> <span className="tetTwo">-</span>{" "}
                        {no_data_dealer.phone}
                        </p>
                    </Col>

                    <Col sm={12} md={6} lg={2} xl={2}>
                    <p className="text-bold"> concurrent </p>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <p style={{ fontSize: "15px" }}>
                        <span className="tet">:</span> <span className="tetTwo">-</span>{" "}
                        {no_data_dealer.concurrent}
                        </p>
                    </Col>

                    <Col sm={12} md={6} lg={2} xl={2}>
                    <p className="text-bold"> email </p>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <p style={{ fontSize: "15px" }}>
                        <span className="tet">:</span> <span className="tetTwo">-</span>{" "}
                        {no_data_dealer.email}
                        </p>
                    </Col>

                    <Col sm={12} md={6} lg={2} xl={2}>
                    <p className="text-bold"> lip </p>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <p style={{ fontSize: "15px" }}>
                        <span className="tet">:</span> <span className="tetTwo">-</span>{" "}
                        {no_data_dealer.lip}
                        </p>
                    </Col>

                    <Col sm={12} md={6} lg={2} xl={2}>
                    <p className="text-bold"> addr </p>
                    </Col>
                    <Col sm={12} md={6} lg={10} xl={10}>
                        <p style={{ fontSize: "15px" }}>
                        <span className="tet">:</span> <span className="tetTwo">-</span>{" "}
                        {no_data_dealer.addr}
                        </p>
                    </Col>

                    <Col sm={12} md={6} lg={2} xl={2}>
                    <p className="text-bold"> remark </p>
                    </Col>
                    <Col sm={12} md={6} lg={10} xl={10}>
                        <p style={{ fontSize: "15px" }}>
                        <span className="tet">:</span> <span className="tetTwo">-</span>{" "}
                        {no_data_dealer.remark}
                        </p>
                    </Col>
                </Row>
            )
        }
        </div>
    )
}