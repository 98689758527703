import React from "react"
import Map_Service from "./components/Map_Service"
import { useTranslation } from "react-i18next"

export default function Show_Location_Service(props) {
  const { t } = useTranslation()
  return (
    <div>
      {
      props.service_location.ser_lat !== null &&
        props.service_location.ser_long !== null && 
        props.service_location.ser_lat !== "None" &&
        props.service_location.ser_long !== "None" &&(
          <div>
            <p className="text-bold"> {t("Service location")} </p>
            <Map_Service
              ser_lat={props.service_location.ser_lat}
              ser_long={props.service_location.ser_long}
            />
          </div>
        )}
    </div>
  )
}
