import React from 'react'
import moment from 'moment';
import { Form, DatePicker } from "antd"

const dateFormat = 'YYYY-MM-DD';

export default function Input_Date_Comp({ header, disabled, keyState, placeholder, nameState, headState }) {

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }
    
    function onChangeDateIn(date, dateString) {
        headState({ ...nameState, [keyState]: dateString })
    }

    function check_color_text(time) {
        const render_ = formatDate(time)
        if(disabled === true){
            return <p style={{ color:'#AAA9A9' }}>{render_} <span style={{ color:'#AAA9A9', fontSize:'12px' }}> ( YY/MM/DD ) </span> </p>
        } else if(disabled === false){
            return <p style={{ color:'black' }}>{render_} <span style={{ color:'#AAA9A9', fontSize:'12px' }}> ( YY/MM/DD ) </span> </p>
        }
    }

    return (
    <>
        <p className="text-bold">{header}</p>
        { check_color_text(placeholder) } 
       
        
        <Form.Item
        name={keyState}
        rules={[
          {
            required: false,
            message: `Please input ${placeholder}`,
          },
        ]}
      >
        <DatePicker    
            format={dateFormat} 
            onChange={onChangeDateIn} 
            style={{ width:'100%' }} 
            disabled={disabled}
            />
      </Form.Item>
    </>
    )
}
