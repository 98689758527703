import React, { useState, useEffect } from "react"
import axios from "axios"
import * as api from "../../../service/Api_System_Settings"
import { useTranslation } from "react-i18next"
import { Row, Col } from "reactstrap"
import Input_Comp from "../Components/Input_Comp"
import Input_TextArea from './Input_TextArea'
import { Button, Modal, notification, Form } from "antd"
import Select_no_search from "./Select_no_search"
import Input_Number from "./Input_Number"
import Select_Province from "./Select_Province"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import helpers from "../../Function_Redirect/Redirect_login"
import { useHistory } from "react-router-dom"
import Input_ckeditor from './Input_ckeditor'

const { confirm } = Modal

export default function Create_New_SLA(props) {
  const { t } = useTranslation()
  const history = useHistory()
  const [form_create] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const token = localStorage.getItem("token")
  const [data_sla, setData_sla] = useState({
    sla_priority_id: null,
    sla_resolution: null,
    sla_service_day: null,
    sla_service_hr: null,
    sla_service_min: null,
    sla_service_name: null,
    sla_service_pro_id: null,
    sla_service_remark: null,
  })

  useEffect(() => {
    return () => {
      setData_sla({})
    }
  }, [])

  function onFinish_create(value) {
    return confirm({
      title: t("Confirm create new SLA"),
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: t("Confirm"),
      cancelText: t("no"),
      onOk() {
        setLoading(true)
        const create_new = api.CREATE_NEW_SLA(token, data_sla)
        axios(create_new)
          .then(res => {
            setLoading(false)
            form_create.resetFields()
            setData_sla({
              ...data_sla,
              sla_priority_id: null,
              sla_resolution: null,
              sla_service_day: null,
              sla_service_hr: null,
              sla_service_min: null,
              sla_service_name: null,
              sla_service_pro_id: null,
              sla_service_remark: null,
            })
            props.check_all_sal()
            openNotificationWithIcon("success", t("Create new SLA success"))
            props.setVisible_create(false)
          })
          .catch(error => {
            setLoading(false)
            if (error) {
              if (error.response) {
                if (error.response.status === 401) {
                  helpers.re_login()
                  history.push("/login")
                } else {
                  openNotificationWithIcon("error", t("Create new SLA error"))
                }
              }
            }
          })
      },
      onCancel() {},
    })
  }

  function cancel_form() {
    form_create.resetFields()
    setData_sla({
      ...data_sla,
      sla_priority_id: null,
      sla_resolution: null,
      sla_service_day: null,
      sla_service_hr: null,
      sla_service_min: null,
      sla_service_name: null,
      sla_service_pro_id: null,
      sla_service_remark: null,
    })
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }
// console.log(data_sla);
  return (
    <div className="modal-mobile-system-setting" style={{ overflowY:'scroll' , overflowX : "hidden" , height:'500px' }}>
      <Form
        form={form_create}
        name="form_create_data"
        onFinish={onFinish_create}
      >
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              placeholder={t("Name")}
              header={t("Name")}
              headState={setData_sla}
              nameState={data_sla}
              keyState="sla_service_name"
              type="text"
              required={true}
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Priority")}
              keyState="sla_priority_id"
              headState={setData_sla}
              nameState={data_sla}
              map_name="pri_name"
              placeholder={t("Priority")}
              map_value="pri_id"
              disabled={false}
              required={true}
              data_array_map={props.priority_all}
            />
          </Col>

          <Col sm={12} md={12} lg={12} xl={12}>
            <Input_ckeditor
              placeholder={t("Resolution")}
              header={t("Resolution")}
              headState={setData_sla}
              nameState={data_sla}
              keyState="sla_resolution"
              type="text"
              required={true}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_Province
              header={t("Province")}
              placeholder={t("Province")}
              headState={setData_sla}
              nameState={data_sla}
              keyState="sla_service_pro_id"
              province_all={props.province_all}
              required={true}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Number
              header={t("day")}
              placeholder={t("day")}
              headState={setData_sla}
              nameState={data_sla}
              keyState="sla_service_day"
              maxLength={null}
              required={true}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Number
              header={t("hr")}
              placeholder={t("hr")}
              headState={setData_sla}
              nameState={data_sla}
              keyState="sla_service_hr"
              maxLength={null}
              required={true}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Number
              header={t("min")}
              placeholder={t("min")}
              headState={setData_sla}
              nameState={data_sla}
              keyState="sla_service_min"
              maxLength={null}
              required={true}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              placeholder={t("Remark")}
              header={t("Remark")}
              headState={setData_sla}
              nameState={data_sla}
              keyState="sla_service_remark"
              type="text"
              required={true}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div className="div_create_new_car">
              <Button
                className="btn_cancel_create_new_car"
                onClick={() => {
                  props.setVisible_create(false)
                  cancel_form()
                }}
              >
                {t("Cancel")}
              </Button>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div className="div_create_new_car">
              <Button
                className="btn_submit_create_new_car"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {t("Create")}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
