import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import Expand from "react-expand-animated"
import { FiChevronDown, FiChevronRight } from "react-icons/fi"
import { Button, Modal, notification } from "antd"
import axios from "axios"
import { Row, Col } from "reactstrap"
import * as api from "../../../service/Api_Profile_Menu"
import helpers from "../../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { FiSend } from 'react-icons/fi';

const { confirm } = Modal

export default function Button_ResetPassword({ roleReset , idReset }) {
  const { t } = useTranslation()
  const history = useHistory()
  const token = localStorage.getItem("token")
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [icon, setIcon] = useState(
    <FiChevronRight size={20} style={{ marginBottom: "5px" }} />
  )

  const setToggle = () => {
    if (open === false) {
      setOpen(true)
      setIcon(<FiChevronDown size={25} style={{ marginBottom: "5px" }} />)
    } else {
      setOpen(false)
      setIcon(<FiChevronRight size={25} style={{ marginBottom: "5px" }} />)
    }
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  function resetPassByEmail() {
    return confirm({
        title: t("confirm reset password by email"),
        icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
        centered: true,
        okText: t("Confirm"),
        cancelText: t("no"),
        onOk() {
          setLoading(true)
          let dataReset = {
            role : roleReset,
            user_id : idReset
          }
          const sendEmail = api.RESET_PASS_BY_EMAIL(token , dataReset )
          axios(sendEmail)
          .then( res => {
            setLoading(false)
            openNotificationWithIcon("success",t("send email success"))
          })
          .catch( error => {
            setLoading(false)
            if (error) {
              if (error.response) {
                if (error.response.status === 401) {
                  helpers.re_login()
                  history.push("/login")
                } else {
                  openNotificationWithIcon("error",t("send email error"))
                }
              }
            }
          })
        },
        onCancel() {},
      })
  }

  return (
    <Row>
      <Col sm={12} md={6} lg={4} xl={4} onClick={() => setToggle()}>
        <a>
          {" "}
          <p style={{ fontSize: "20px" }}>
            {" "}
            {icon} {t("reset password by email")}{" "}
          </p>{" "}
        </a>
      </Col>
      <Col sm={12} md={6} lg={8} xl={8}>
        <p style={{ color: "rgb(108, 117, 125)" }}>{t("reset password by email")}</p>

        <Expand open={open} duration={500}>
          <div style={{ width: "100%", borderRadius: "17px", padding: "10px" }}>
            <Row>
              <Col sm={12} md={12} lg={6} xl={6}>
               <Button icon={<FiSend style={{ marginRight:'10px', fontSize:'15px' }} />} type="primary" onClick={() => resetPassByEmail()} loading={loading} >{t("send email")}</Button>
              </Col>
              <Col sm={0} md={0} lg={6} xl={6}></Col>
            </Row>
          </div>
        </Expand>
      </Col>
      <hr />
    </Row>
  )
}
