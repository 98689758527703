var Ip = require('../service/Ip')

export const SEARCH_ALL = (token, url) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}${url}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_DATA_STOCK_DATA = (token, page, pageSize, data) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/stock?page=${page}&limit=${pageSize}&order_by=asc&q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_DATA_STOCK_NO_DATA = (token, page, pageSize) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/stock?page=${page}&limit=${pageSize}&order_by=asc`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };