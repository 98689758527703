import React, { useState, useEffect } from "react"
import { Container, Card, CardBody, Row , Col } from "reactstrap"
import { StyleSheet, css } from "aphrodite";
import MetaTags from "react-meta-tags"
import Card_Dashboard from './components/Card_Dashboard'
import axios from 'axios'
import * as api from '../../../service/service_operator/Api_Dashboard'
import './Dashboard_operator.css'
import Status_Service_All from './Status_Service_All'
import Job_Assgined from './Job_Assgined'
import { useTranslation } from "react-i18next";

const Operator = props => {
  const { t } = useTranslation();
const token = localStorage.getItem('token')

  const style_card = {
    borderRadius: "0px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("dashboard")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>{t("dashboard")}</h3>

          <Status_Service_All />
          <br />
          <Job_Assgined />

        </Container>
      </div>
    </React.Fragment>
  )
}
export default Operator
