import React from 'react'
import hourglass from "../../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/hourglass.png"
import { useTranslation } from "react-i18next";

export default function Service_Status_Pending_Noti(props) {
    const { t } = useTranslation();

    return (
        <div className="outer" style={{ marginTop:'50px' }}>
            <div style={{ width:'250px' , display : 'inline-block' }}>
                <img src={hourglass} />
                <p style={{ fontSize:'20px' , marginTop:'20px' }}> {t("Pending for admin approve")} </p>
            </div>
        </div>
    )
}
