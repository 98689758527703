var Ip = require('../service/Ip')

export const LIST_NOTIFICATION_ALL = (token) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/email/notification`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const CHECK_BRANCH = (token, cus_id, branch_code) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer/check_is_branch_code_duplicate/${cus_id}/${branch_code}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CHECK_POSTCODE = (postcode) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/location/zip/${postcode}`,
      headers: {
        'Content-Type': 'application/json'
      }
    };
  };

  export const CHECK_POSITION = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/position`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const SEARCH_NODATA = (token ,cus_id ,page , pageSize) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer/${cus_id}/branch?page=${page}&limit=${pageSize}&order_by=asc`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const SEARCH_DATA = (token, cus_id, page, pageSize, data) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer/${cus_id}/branch?page=${page}&limit=${pageSize}&order_by=asc&q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CREATE_NEW_BRANCH = (token, data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/customer/branch`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const SEARCH_CUSTOMER_DETAIL = (token, branch_id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer/branch/${branch_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const EDIT_BRANCH = (token, branch_id, data) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/customer/branch/${branch_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const SEARCH_NO_USER_DATA = (token,cus_id,page, pageSize) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer/${cus_id}/users?page=${page}&limit=${pageSize}&order_by=asc`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const SEARCH_USER_DATA = (token, cus_id, page, pageSize, data) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer/${cus_id}/users?page=${page}&limit=${pageSize}&order_by=asc&q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };
  
  export const CHECK_DEPARTMENT = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/department`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_ALL_ROLE = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer/list-role-permission`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_A_ROLE = (token, id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer/list-role-permission/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CREATE_NEW_A_USER = (token, data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/customer/branch/users`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };
  
  export const LIST_CAR_IN_CUSTOMER_NO_DATA = (token, cus_id, page, pageSize) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer/customer-car/${cus_id}?page=${page}&limit=${pageSize}&order_by=asc`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_CAR_IN_CUSTOMER_DATA = (token, cus_id, page, pageSize, data) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer/customer-car/${cus_id}?page=${page}&limit=${pageSize}&order_by=asc&q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_PROVINCE = (token,data) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/location/province?q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_PROVINCE_NO_DATA = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/location/province`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_CAR_BODY_TYPE = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/car/body`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_CAR_BRAND = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/car/brand`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_CAR_MODEL = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/car/model`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_CAR_COLOR = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/car/color`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };
  
  export const LIST_CAR_YEAR = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/car/year`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CREATE_NEW_CAR = (token,data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/car`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const LIST_DEVICE_NO_DATA = (token, cus_id , page, pageSize) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer/customer-has-stock/${cus_id}?page=${page}&limit=${pageSize}&order_by=asc`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_DEVICE_DATA = (token, cus_id , page, pageSize, data) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer/customer-has-stock/${cus_id}?page=${page}&limit=${pageSize}&order_by=asc&q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const DETAIL_CUSTOMER = (token, cus_id ) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer/${cus_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const SEARCH_DATA_CONTRACT_IN_CUSTOMER = (token, cus_id , page, pageSize , data) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/contract-customer/${cus_id}?page=${page}&limit=${pageSize}&order_by=asc&q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const SEARCH_DATA_CONTRACT_IN_CUSTOMER_NODATA = (token, cus_id , page, pageSize) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/contract-customer/${cus_id}?page=${page}&limit=${pageSize}&order_by=asc`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const DELETE_CUSTOMER = (token, cus_id ) => {
    return {
      method: 'delete',
      url: `${Ip.getIP()}api/v1/customer/${cus_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const GET_IMAGE = (token, image) => {
    return {
      method: 'get',
      responseType: 'arraybuffer',
      url: `${Ip.getIP()}storage/file/${image}`,
      headers: {
        'x-access-token' : token
      }
    };
  };

  export const UPLOAD_PROFILE = (token, formData) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}storage/upload`,
      headers: {
        "Content-type": "multipart/form-data",
        'x-access-token' : token
      },
      data : formData
    };
  };

  export const CHECK_EMAIL_USER_DUPLICATE = (token, cus_user_email) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer/check-email-is-duplicate/${cus_user_email}`,
      headers: {
        "Content-type": "multipart/form-data",
        'x-access-token' : token
      }
    };
  };

  export const CHECK_DETAIL_USER = (token ,user_id ) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer/users/${user_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const EDIT_USER = (token ,user_id , data ) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/customer/users/${user_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const DELETE_USER = (token ,user_id ) => {
    return {
      method: 'delete',
      url: `${Ip.getIP()}api/v1/customer/users/${user_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const FIND_ALL_CAR_GROUP = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/car/group`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const EDIT_DEALER_USER = (token, dea_user_id , data) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/dealer/user/${dea_user_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const EDIT_OPERATOR = (token, data, ope_id) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/operator/${ope_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };