import React from "react"
import { Row, Col } from "reactstrap"
import { Input } from "antd"

const { Search } = Input

export default function Search_data_branch_component(props) {

  function onSearch(value) {
    props.find_data_branch()
  }

  return (
    <Row>
    <Col sm={12} md={6} lg={6} xl={6}>
      <Search
        style={{ width: "100%" }}
        placeholder="name , search , dealer"
        allowClear
        enterButton="Search"
        size="medium"
        onSearch={onSearch}
        onChange={e => props.setSearch_data_branch(e.target.value)}
      />
    </Col>
  </Row>
  )
}
