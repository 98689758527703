import React, { useState } from "react"
import { Row, Col } from "reactstrap"
import { Input, Button, Modal } from "antd"
import Create_New_Contract_Only from "./Create_New_Contract_Only"
import { useTranslation } from "react-i18next"

const { Search } = Input

export default function Search_contract_customer(props) {
  const [visible, setVisible] = useState(false)
  const { t } = useTranslation()

  // create_new_contract={props.create_new_contract}           state
  // setCreate_new_contract={props.setCreate_new_contract}     state
  // contract_create_only={props.contract_create_only}         true false
  // setContract_create_only={props.setContract_create_only}   true false

  function onSearch(value) {
    props.search_data_contract_in()
  }

  return (
    <Row>
      <Col sm={12} md={6} lg={6} xl={6}>
        <Search
          style={{ width: "100%" }}
          placeholder={t("Contract")}
          allowClear
          enterButton={t("Search")}
          size="medium"
          onSearch={onSearch}
          onChange={e =>
            props.setData_search_contract_in_customer(e.target.value)
          }
        />
      </Col>
      <Col sm={12} md={1} lg={1} xl={1}>
        <Button onClick={() => setVisible(true)}>
          {t("Create new contract")}
        </Button>
      </Col>

      <Modal
        title=""
        centered
        footer={false}
        open={visible}
        onCancel={() => setVisible(false)}
        width={1000}
      >
        <Create_New_Contract_Only
          setVisible={setVisible}
          create_new_contract={props.create_new_contract}
          setCreate_new_contract={props.setCreate_new_contract}
          contract_create_only={props.contract_create_only}
          setContract_create_only={props.setContract_create_only}
        />
      </Modal>
    </Row>
  )
}
