import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Row, Col } from "reactstrap"
import { Tag } from "antd"
import { AiOutlineRight } from "react-icons/ai"
import { useTranslation } from "react-i18next"
import last_service from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/last_service.png"

export default function Last_Service_On_This_Month(props) {
  const history = useHistory()
  const [last_service_on_this_month, setLast_service_on_this_month] = useState(
    []
  )
  const { t } = useTranslation()

  useEffect(() => {
    setLast_service_on_this_month(props.last_service_on_this_month)
    return () => {
      setLast_service_on_this_month([])
    }
  }, [props.last_service_on_this_month])

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return "N/A"
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  function render_status(status) {
    switch (status) {
      case "cancel":
        return <Tag color="error">{t("cancel")}</Tag>
        break
        case "reject":
          return <Tag color="error">{t("reject")}</Tag>
          break
      case "overdue":
        return <Tag color="error">{t("overdue")}</Tag>
        break
      case "resolved":
        return <Tag color="success">{t("resolved")}</Tag>
        break
      case "complete":
        return <Tag color="success">{t("complete")}</Tag>
        break
      case "inprogress":
        return <Tag color="processing">{t("inprogress")}</Tag>
        break
      case "unassigned":
        return <Tag color="default">{t("unassigned")}</Tag>
        break
      case "pending":
        return (
          <Tag
            style={{
              color: "#DCC200",
              borderColor: "#DCC200",
              backgroundColor: "#FEFEF3",
            }}
          >
            {t("pending")}
          </Tag>
        )
        break
      case "assigned":
        return (
          <Tag
            style={{
              color: "#DCC200",
              borderColor: "#DCC200",
              backgroundColor: "#FEFEF3",
            }}
          >
            {t("assigned")}
          </Tag>
        )
        break
      case "waiting for admin approve":
        return (
          <Tag
            style={{
              color: "#0071FF",
              borderColor: "#0071FF",
              backgroundColor: "#DFEDFF",
            }}
          >
            {t("waiting for admin approve")}
          </Tag>
        )
        break
      case "waiting for manager approve":
        return (
          <Tag
            style={{
              color: "#0071FF",
              borderColor: "#0071FF",
              backgroundColor: "#DFEDFF",
            }}
          >
            {t("Waiting for manager approve")}
          </Tag>
        )
        break
      default:
        return <Tag color="default">{status}</Tag>
        break
    }
  }

  useEffect(() => {
    render_last_service()
    return () => {}
  }, [last_service_on_this_month])

  function render_last_service() {
    return last_service_on_this_month.map((data, index) => {
      return (
        <div key={index}>
          <Row>
            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <p style={{ fontSize: "17px", color: "#495057" }}>
                {" "}
                {data.ser_no}{" "}
              </p>
              <p style={{ color: "#A5A5A5" }}>
                {" "}
                {cutTimeZone(data.created_on)}{" "}
                <span style={{ marginLeft: "10px" }}>
                  {render_status(data.ser_status_name)}
                </span>{" "}
              </p>
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} xl={4}></Col>
            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
              <a onClick={() => history.push(`/service/${data.ser_id}`)}>
                <AiOutlineRight size={20} style={{ marginTop: "20px" }} />
              </a>
            </Col>
          </Row>
          <hr style={{ width: "98%", display: "block", margin: "10px auto" }} />
        </div>
      )
    })
  }

  return (
    <div style={{ height: "523px", overflowY: "scroll", overflowX: "hidden" }}>
      <p style={{ fontSize: "20px", marginBottom: "15px", color: "#495057" }}>
        <img
          src={last_service}
          alt="contract"
          style={{
            width: "25px",
            height: "25px",
            marginRight: "20px",
            marginTop: "-10px",
          }}
        />
        {t("last service")}
      </p>
      <br />

      {render_last_service()}
    </div>
  )
}
