import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Card, CardBody, Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { MdKeyboardBackspace } from "react-icons/md"
import { Button, DatePicker, Table, Modal, Input } from "antd"
import { EditOutlined } from "@ant-design/icons"
import "../Style_Report.css"
import axios from "axios"
import * as api from "../../../service/Api_Report_All"
const FileDownload = require("js-file-download")
import Col_Device from "../components/Col_Device"
import SelectOperator from "./SelectOperator"
import Render_Total_And_DoughnutChart from "../../components/report/Render_Total_And_DoughnutChart"
import service_info from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/service_info.png"
import helpers from '../../Function_Redirect/Redirect_login'

export default function ReportOperator() {
  const { t } = useTranslation()
  const history = useHistory()
  const [date_select, setDate_select] = useState({
    date_from: null,
    date_to: null,
  })
  const token = localStorage.getItem('token')
  const [data_report, setData_report] = useState([])
  const [loading, setLoading] = useState(false)
  const [loading_excel, setLoading_excel] = useState(false)
  const [visible, setVisible] = useState(false)
  const [operatorSelect, setOperatorSelect] = useState({
    operatorName: null,
    ope_id: null,
  })
  const [total_status_count, setTotal_status_count] = useState({
    complete: 0,
    inprogress: 0,
    pending: 0,
    total: 0,
    unassigned: 0
  })

  const style_DatePicker = {
    width: "100%",
  }

  useEffect(() => {
    // check_report()
    return () => {
      setData_report([])
      setLoading(false)
      setVisible(false)
      setLoading_excel(false)
      setOperatorSelect({
        operatorName: null,
        ope_id: null,
      })
      setDate_select({
        date_from: null,
        date_to: null,
      })
    }
  }, [])

  function onChangeDateFrom(date, dateString) {
    if (dateString) {
      setDate_select({
        ...date_select,
        date_from: dateString,
      })
    } else {
      setDate_select({
        ...date_select,
        date_from: null,
      })
    }
  }

  function onChangeDateTo(date, dateString) {
    if (dateString) {
      setDate_select({
        ...date_select,
        date_to: dateString,
      })
    } else {
      setDate_select({
        ...date_select,
        date_to: null,
      })
    }
  }

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day

    return [year, month, day].join("-")
  }

  function check_report() {
    if (
      date_select.date_from !== null &&
      date_select.date_to !== null &&
      operatorSelect.ope_id !== null
    ) {
      setLoading(true)
      const filter_date = api.NEW_CHECK_REPORT_OPERATOR(
        date_select.date_from,
        date_select.date_to,
        operatorSelect.ope_id,
        token
      )
      axios(filter_date)
        .then(res => {
          setLoading(false)
          setData_report(res.data.data)
          setTotal_status_count({
            ...total_status_count,
            complete: res.data.total_report_operator.complete ,
            inprogress: res.data.total_report_operator.inprogress ,
            pending: res.data.total_report_operator.pending ,
            total: res.data.total_report_operator.total ,
            unassigned: res.data.total_report_operator.unassigned
          })
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  function checkData(data) {
    if (data) {
      return data
    } else {
      return null
    }
  }

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T + "+7")
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return null
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  function downloadExcel() {
    if (
      date_select.date_from !== null &&
      date_select.date_to !== null &&
      operatorSelect.ope_id !== null
    ) {
      setLoading_excel(true)
      const filter_date = api.NEW_DOWNLOAD_REPORT_OPERATOR(
        date_select.date_from,
        date_select.date_to,
        operatorSelect.ope_id,
        token
      )
      axios(filter_date)
        .then(res => {
          setLoading_excel(false)
          FileDownload(res.data, "report operator.csv")
        })
        .catch(error => {
          setLoading_excel(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  const columns = [
    {
      title: t("Created on"),
      dataIndex: "created_on",
      key: "created_on",
      render: text => <p>{cutTimeZone(text)}</p>,
      width: "130px",
    },
    {
      title: t("Operator name"),
      dataIndex: "ope_firstname",
      key: "ope_firstname",
      render: text => <p>{checkData(text)}</p>,
      width: "150px",
    },
    {
      title: t("Car license"),
      dataIndex: "car_license_number",
      key: "car_license_number",
      render: text => <p>{checkData(text)}</p>,
      width: "150px",
    },
    {
      title: t("Device group"),
      dataIndex: "devicegroup",
      key: "devicegroup",
      render: text => <p>{checkData(text)}</p>,
      width: "150px",
    },
    {
      title: t("Product model"),
      dataIndex: "productmodel",
      key: "productmodel",
      render: text => <p>{checkData(text)}</p>,
      width: "150px",
    },
    {
      title: t("customer"),
      dataIndex: "cus_name",
      key: "cus_name",
      render: text => <p>{checkData(text)}</p>,
      width: "150px",
    },
    {
      title: t("service status"),
      dataIndex: "ser_status_name",
      key: "ser_status_name",
      render: text => <p>{t(text)}</p>,
      width: "150px",
    },
    {
      title: t("Time start travel"),
      dataIndex: "TimeStartTravel",
      key: "TimeStartTravel",
      render: text => <div>{renderTimeColumns(text)}</div>,
      width: "150px",
    },
    {
      title: t("Time start onsite"),
      dataIndex: "TimeStartOnsite",
      key: "TimeStartOnsite",
      render: text => <div>{renderTimeColumns(text)}</div>,
      width: "150px",
    },
    {
      title: t("Time start outside"),
      dataIndex: "TimeStartOutSite",
      key: "TimeStartOutSite",
      render: text => <div>{renderTimeColumns(text)}</div>,
      width: "150px",
    },
    {
      title: t("Time end"),
      dataIndex: "TimeEnd",
      key: "TimeEnd",
      render: text => <div>{renderTimeColumns(text)}</div>,
      width: "150px",
    },
  ]
  
  function renderTimeColumns(time) {
    if(time){
      var today = new Date(time+'+7');
      return (
        <div>
          <p>{t("date")} : {cutTimeZone(time)}</p>
          <p>{t("time")} : {today.toLocaleTimeString()}</p>
        </div>
      )
    }
    // if(time){
    //   var today = new Date(time+'+7');
    //   return (
    //     <div>
    //       <p>{t("date")} : {cutTimeZone(time)}</p>
    //       <p>{t("time")} : {today.toLocaleTimeString()}</p>
    //     </div>
    //   )
    // } else {
    //   return (
    //     <div>
    //       <p>{t("date")} : </p>
    //       <p>{t("time")} : </p>
    //     </div>
    //   )
    // }
  }

  function reFormatTime(Time) {
    if(Time){
      var today = new Date(Time+'+7');
      return today.toLocaleTimeString();
    } else {
      return null
    }

  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("report operator")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/reports")}
              />
            </a>{" "}
            {t("report operator")}
          </h3>

          <Card>
            <CardBody>
              <Row>
                <Col xs={12}  sm={12} md={6} lg={4} xl={4}>
                  {operatorSelect.operatorName === null && (
                    <Button
                      type="primary"
                      onClick={() => setVisible(true)}
                      style={{ marginTop: "35px", display:'block',marginLeft:'auto', marginRight:'auto' }}
                      className="btn-report-search-export"
                    >
                      {t("Select operator")}
                    </Button>
                  )}
                  {operatorSelect.operatorName !== null && (
                    <div style={{ display: "flex", marginTop: "35px" }}>
                      <p className="text-bold " style={{ marginTop: "7px" }}>
                        {t("Operator")}
                      </p>
                      <Input
                        style={{ marginLeft: "10px", height: "35px" }}
                        value={operatorSelect.operatorName}
                        placeholder={operatorSelect.operatorName}
                        disabled
                      />
                      <a>
                        <EditOutlined
                          style={{ fontSize: "20px", marginLeft: "10px" }}
                          onClick={() => {
                            setOperatorSelect({
                              ...operatorSelect,
                              operatorName: null,
                              ope_id: null,
                            })
                          }}
                        />
                      </a>
                    </div>
                  )}
                </Col>
                <Col xs={12}  sm={12} md={6} lg={8} xl={8}>
                  <Button
                    className="btn-report-search-export btn-report-search-export-mg-right"
                    type="primary"
                    onClick={() => check_report()}
                  >
                    {t("Search")}
                  </Button>
                  <Button
                    className="btn-report-search-export mt-btn-export"
                    loading={loading_excel}
                    onClick={() => downloadExcel()}
                  >
                    {t("Export excel")}
                  </Button>
                </Col>
              </Row>
<br />
              <Row>
              <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                  <p className="text-bold"> {t("Date from")} </p>
                  <DatePicker
                    onChange={onChangeDateFrom}
                    allowClear
                    style={style_DatePicker}
                  />
                </Col>
                <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                  <p className="text-bold"> {t("Date to")} </p>
                  <DatePicker
                    onChange={onChangeDateTo}
                    allowClear
                    style={style_DatePicker}
                  />
                </Col>
              </Row>
              <br />
              <br />
              <Row>
                <Col_Device
                 text_header={t("Total")}
                  data={data_report.length}
                  xsH={12}
                  smH={12}
                  mdH={3}
                  lgH={3}
                  xlH={3}
                  xsD={12}
                  smD={12}
                  mdD={9}
                  lgD={9}
                  xlD={9}
                />
              </Row>

              <Row>
                <Col>
              <Render_Total_And_DoughnutChart
                    text_header={t("Service status operator")}
                    img_header={service_info}
                    labels_status={[
                      t("complete"),
                      t("inprogress"),
                      t("pending"),
                      t("unassigned")
                    ]}
                    data_status={[
                      total_status_count.complete,
                      total_status_count.inprogress,
                      total_status_count.pending,
                      total_status_count.unassigned
                    ]}
                    color_chart={[
                      "#EBAE86",
                      "#6891C3",
                      "#76CE86",
                      "#ff6961"
                    ]}
                    total={
                      total_status_count.total
                    }
                    data_lineChart={[
                      {
                        text_header : t("Total"),
                        total_count : total_status_count.total,
                        color : "#F29496"
                      },
                      {
                        text_header : t("complete"),
                        total_count : total_status_count.complete,
                        color : "#EBAE86"
                      },
                      {
                        text_header : t("inprogress"),
                        total_count : total_status_count.inprogress,
                        color : "#6891C3"
                      },
                      {
                        text_header : t("pending"),
                        total_count : total_status_count.pending,
                        color : "#76CE86"
                      },
                      {
                        text_header : t("unassigned"),
                        total_count : total_status_count.unassigned,
                        color : "#ff6961"
                      }
                    ]}
                  />
                </Col>
              </Row>

              <hr />
              <Table
                loading={loading}
                rowKey="ser_id"
                columns={columns}
                dataSource={data_report}
                scroll={{ x: 400 }}
              />

              <Modal
                centered
                open={visible}
                onCancel={() => {
                  setVisible(false)
                }}
                width={1000}
                footer={null}
              >
                <SelectOperator
                  setVisible={setVisible}
                  setOperatorSelect={setOperatorSelect}
                  operatorSelect={operatorSelect}
                />
              </Modal>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}