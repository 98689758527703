import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { useTranslation } from "react-i18next";
import operator from '../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/operator.png'

export default function Operator(props) {
  const { t } = useTranslation();
  const [count_operator, setCount_operator] = useState([0, 0, 0, 0, 0, 0])
  const [all_operator, setAll_operator] = useState(0)
  const [series, setSeries] = useState([])
  const Header_text = {
    fontSize: "20px",
    marginBottom: "0px",
    color: "#A5A5A5",
  }

  useEffect(() => {
    if (props.service_by_geo[1]) {
      setCount_operator([
        props.service_by_geo[1].count,
        props.service_by_geo[2].count,
        props.service_by_geo[3].count,
        props.service_by_geo[4].count,
        props.service_by_geo[5].count,
        props.service_by_geo[6].count,
      ])
      setAll_operator(
        props.service_by_geo[1].count +
          props.service_by_geo[2].count +
          props.service_by_geo[3].count +
          props.service_by_geo[4].count +
          props.service_by_geo[5].count +
          props.service_by_geo[6].count
      )
    }
    return () => {
      setCount_operator([])
      setSeries([])
      setAll_operator(0)
    }
  }, [props.service_by_geo])

  useEffect(() => {
    setSeries([
      {
        name:  t("operator"),
        data: count_operator,
        // data : [ 1, 2, 3, 4, 5, 6 ]
      },
    ])
    return () => {}
  }, [count_operator])

  const options = {
    chart: {
      type: "bar",
      // height: 350,
      toolbar: {
        show: false,
      },
    },
    fill: {
      colors: [
        "#76CE86",
        "#ff6961",
        "#EBAE86",
        "#BE94E6",
        "#A5978B",
        "#7985E0",
        "#f9a3a4",
      ],
    },
    // plotOptions: {
    //   bar: {
    //     borderRadius: 4,
    //     horizontal: true,
    //   }
    // },
    plotOptions: {
      bar: {
        distributed: true,
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
      position: "bottom",
      onItemClick: {
        toggleDataSeries: true,
      },
      // offsetX: 0,
      // offsetY: 0,
    },
    xaxis: {
      categories: [
        t("northern"),
        t("central"),
        t("northeastern"),
        t("western"),
        t("eastern"),
        t("southern")
      ],
    },
    yaxis: [
      {
        labels: {
          formatter: function(val, opts) {
            return val
          }
        }
      }
    ],
  }

  return (
    <div>
      <p style={{ fontSize: "20px", marginBottom: "15px", color: "#495057" }}>
      <img src={operator} alt="contract" style={{ width:'25px', height:'25px', marginRight:'20px', marginTop:'-10px' }} />
        {t("service")}
      </p>
      <p style={Header_text}>
        {t("Service by region")}
        <span style={{ float: "right", color: "#495057", fontWeight: "bold" }}>
          {all_operator}
        </span>{" "}
      </p>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height="351"
        className="apex-charts"
      />
    </div>
  )
}
