import React, { useEffect, useState } from "react"
import { Container, Card, CardBody } from "reactstrap"
import { useHistory, useParams } from "react-router-dom"
import MetaTags from "react-meta-tags"
import Edit from "./Edit"
import { MdKeyboardBackspace } from "react-icons/md"
import Detail from "./Detail"
import Delete from "./Setting/Delete"
import Device from "./Device"
import axios from "axios"
import * as api from "../../service/API_Edit_Car"
import no_permi from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png"
import helpers from "../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Tabs } from "antd"

const { TabPane } = Tabs

export default function Edit_car(props) {
  const history = useHistory()
  const { t } = useTranslation()
  const [data_car, setData_car] = useState({})
  const token = localStorage.getItem("token")
  const [activeTab_all_Customer, setActiveTab_all_Customer] = useState("1")
  const { permissionAllService } = useSelector(({ Distar }) => Distar)
  const permi = localStorage.getItem("permi")
  const [permi_t_edit, setPermi_t_edit] = useState(false)
  const [permi_t_delete, setPermi_t_delete] = useState(false)
  const [permi_t_read, setPermi_t_read] = useState(false)

  useEffect(() => {
    if (permissionAllService) {
      if (permissionAllService.car) {
        setPermi_t_read(permissionAllService.car.read)
        setPermi_t_edit(permissionAllService.car.edit)
        setPermi_t_delete(permissionAllService.car.delete)
      }
    }
    return () => {
      // setPermi_t_edit(false)
      // setPermi_t_delete(false)
    }
  }, [permissionAllService])

  let { car_id } = useParams()

  // console.log('car_id = '+car_id);

  useEffect(() => {
    check_new_data_edit()
    setActiveTab_all_Customer(
      props.location.page_local ? props.location.page_local : "1"
    )
    return () => {
      setData_car({})
    }
  }, [car_id])

  function check_new_data_edit() {
    const new_data = api.DETAIL_CAR(token, car_id)
    axios(new_data)
      .then(res => {
        if (res.data.data[0] === undefined) {
          history.push("/cars")
        } else {
          setData_car(res.data.data[0])
        }
      })
      .catch(error => {
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Edit car")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/cars")}
              />
            </a>{" "}
            {t("Edit car")}
          </h3>
          {permi_t_read === true && (
            <Card>
              <CardBody>
                <Tabs
                  defaultActiveKey="1"
                  onTabClick={e => {
                    setActiveTab_all_Customer(e)
                  }}
                  activeKey={activeTab_all_Customer}
                >
                  <TabPane tab={t("Overview")} key="1">
                    <Detail data_car={data_car} />
                  </TabPane>
                  <TabPane tab={t("Device")} key="2">
                    <Device car_id={parseInt(car_id)} />
                  </TabPane>
                  {permi_t_edit === true && (
                    <TabPane tab={t("Edit")} key="3">
                      <Edit data_car={data_car} setData_car={setData_car} />
                    </TabPane>
                  )}

                  {permi_t_delete === true && (
                    <TabPane tab={t("Setting")} key="4">
                      <Delete data_car={data_car} />
                    </TabPane>
                  )}
                </Tabs>
              </CardBody>
            </Card>
          )}
          {permi_t_read === false && (
            <div>
              <img
                src={no_permi}
                alt="no permission"
                style={{ display: "block", margin: "50px auto" }}
              />
              <p style={{ textAlign: "center", fontSize: "20px" }}>
                {t("no permission read module")}
              </p>
              <p style={{ textAlign: "center", fontSize: "20px" }}>
                {" "}
                {t("please contract administrator")}{" "}
              </p>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
