import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import axios from "axios"
import * as api from "../../service/Api_Preview_File"
import { Spin } from "antd"
import noFileUpload from "../../assets/images/noFileUpload.svg"

var Ip = require("../../service/Ip")

export default function Image() {
  const { fup_path } = useParams()
  const [haveFile, setHaveFile] = useState(false)
  const [isCheck, setIsCheck] = useState(false)
  const [imageData, setImageData] = useState(null)
  // console.log(fup_path);

  useEffect(() => {
    const checkFile = api.CHECK_FILE(fup_path)
    axios(checkFile)
      .then(res => {
          // console.log(res);
          const type = res.headers['content-type']
          const blob = new Blob([res.data], {
            type: type,
        });
        var objectURL = URL.createObjectURL(blob)
        setImageData(objectURL)
        setHaveFile(true)
        setIsCheck(true)
      })
      .catch(error => {
        setHaveFile(false)
        setIsCheck(true)
      })
    return () => {}
  }, [fup_path])

  return (
    <div>
      {isCheck === false && (
        <Spin
          size="large"
          style={{ display: "block", margin: "20px auto 20px auto" }}
        />
      )}
      {haveFile === true && isCheck === true && imageData !== null && (
          <img src={imageData} alt="image" />
      )}
      {haveFile === false && isCheck === true && (
        <div>
          <img
            src={noFileUpload}
            alt="no file upload"
            style={{
              height: "250px",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "100px",
            }}
          />
          <p
            style={{ textAlign: "center", fontSize: "30px", marginTop: "20px" }}
          >
            No data file
          </p>
        </div>
      )}
    </div>
  )
}
