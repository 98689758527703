import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Container, Card, CardBody, Row, Col } from "reactstrap"
import { MdKeyboardBackspace } from "react-icons/md"
import { Form, Button, Input, Modal, notification } from "antd"
import Input_Comp from "./Components/Input_Comp"
import Input_Number from "./Components/Input_Number"
import Select_no_search from "./Components/Select_no_search"
import Select_no_search_true from "./Components/Select_no_search_true"
import * as api from "../../../../service/Api_Customer"
import axios from "axios"
import Overview_customer_info from './Overview_customer_info'
import { ExclamationCircleOutlined } from "@ant-design/icons"
import Input_Comp_Onblur from './Components/Input_Comp_Onblur'
import { useTranslation } from "react-i18next";

const { confirm } = Modal
const { TextArea } = Input

export default function New_Customer(props) {
  const history = useHistory()
  const { t } = useTranslation();
  const token = localStorage.getItem("token")
  const [form] = Form.useForm()
  const [error_tax_id, setError_tax_id] = useState(null)
  const [location_select, setLocation_select] = useState({})
  const [postcode_select, setPostcode_select] = useState([])

  useEffect(() => {
    onFill()
    props.setCustomer_data({
      ...props.customer_data,
      cus_name: props.distar_customer.title ? props.distar_customer.title : null,
      cus_code : props.distar_customer.gid ? props.distar_customer.gid : null,
      cus_remark : props.distar_customer.remark ? props.distar_customer.remark : null,
      is_import : true,
      cus_address: null,
      cus_credit: null,
      cus_email:  null,
      cus_fax:  null,
      cus_phone:  null,
      cus_taxid: null,
      zip_id: null,
      found_my_db: false
    })
    if(props.found_my_dealer === true){
      form.setFieldsValue({
        dea_id: props.dealer_info.dea_id
      });
    }
    return () => {
      
    }
  }, [props.distar_customer])

  const onFill = () => {
    form.setFieldsValue({
      cus_name: props.distar_customer.title,
      cus_code : props.distar_customer.gid,
      cus_remark : props.distar_customer.remark
    });
  };

  useEffect(() => {
    getFind(props.customer_data.cus_taxid)
    check_taxID()
    return () => {}
  }, [props.customer_data.cus_taxid])

  function getFind(obj) {
    if (obj) {
      if (isNaN(obj)) {
        setError_tax_id(t("TAX ID is number only"))
        props.setError_form(true)
      } else {
        setError_tax_id(null)
        props.setError_form(false)
        props.setCustomer_data({
          ...props.customer_data,
          cus_taxid: obj,
        })
      }
    }
  }

  function check_taxID() {
    if (props.customer_data.cus_taxid) {
      if (props.customer_data.cus_taxid.length === 13) {
        const check_tax = api.CHECK_TAX_ID(token, props.customer_data.cus_taxid)
        axios(check_tax)
          .then(res => {
            if (res.data === "True") {
              props.setError_form(true)
              setError_tax_id(t("TAX ID is duplicate"))
            } else if (res.data === "False") {
              props.setError_form(false)
              setError_tax_id(null)
            }
          })
          .catch(error => {
            // console.log(error)
          })
      } else {
        setError_tax_id(t("TAX ID must equal 13 digit"))
        props.setError_form(true)
      }
    }
  }

  function check_postcode(postcode) {
    if (postcode.length === 0) {
      setPostcode_select([])
      setLocation_select({
        dis_name: null,
        pro_name: null,
        subdis_name: null,
        zip_code: null,
      })
    } else if (postcode.length >= 4) {
      const check_zip_postcode = api.CHECK_POSTCODE(postcode)
      axios(check_zip_postcode)
        .then(res => {
          setPostcode_select(res.data.message)
        })
        .catch(error => {
          // console.log(error);
        })
    }
  }

  useEffect(() => {
    render_select_postcode()
    render_location_select()
    render_location_address()
    return () => {}
  }, [postcode_select])

  function render_select_postcode() {
    return postcode_select.map(data => {
      return (
        <div
          key={data.zip_id}
          style={{ borderBottom: "1px solid #f0f0f0", padding: "10px" }}
        >
          <a>
            <p
              style={{ color: "#1890ff" }}
              onClick={() => {
                props.setCustomer_data({
                  ...props.customer_data,
                  zip_id: data.zip_id,
                })
                setPostcode_select([])
                setLocation_select({
                  ...location_select,
                  dis_name: data.dis_name,
                  pro_name: data.pro_name,
                  subdis_name: data.subdis_name,
                  zip_code: data.zip_code,
                })
              }}
            >
              {data.subdis_name} - {data.dis_name} - {data.pro_name} -{" "}
              {data.zip_code}
            </p>
          </a>
        </div>
      )
    })
  }
  function render_location_select() {
    if (
      location_select.dis_name ||
      location_select.pro_name ||
      location_select.subdis_name ||
      location_select.zip_code
    ) {
      return (
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <p className="text-bold"> {t("Sub-district")} </p>
            <Input placeholder={location_select.subdis_name} disabled />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <p className="text-bold"> {t("District")} </p>
            <Input placeholder={location_select.dis_name} disabled />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <p className="text-bold mt-3"> {t("Province")} </p>
            <Input placeholder={location_select.pro_name} disabled />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <p className="text-bold mt-3"> {t("Postcode")} </p>
            <Input placeholder={location_select.zip_code} disabled />
          </Col>
        </Row>
      )
    } else {
      return null
    }
  }

  function render_location_address() {
    if (
      location_select.dis_name ||
      location_select.pro_name ||
      location_select.subdis_name ||
      location_select.zip_code
    ) {
      return (
        <Row>
          <Col>
            <p className="text-bold  mt-3"> {t("Address")}  <span style={{color:'red'}}>*</span> </p>
            <Form.Item
              name="Branch_address"
              rules={[
                {
                  required: false,
                  message: "Please input address",
                },
              ]}
            >
              <TextArea
                placeholder={t("Address")}
                onChange={e =>
                  props.setCustomer_data({
                    ...props.customer_data,
                    cus_address: e.target.value,
                  })
                }
              />
            </Form.Item>
          </Col>
        </Row>
      )
    } else {
      return null
    }
  }

  const onReset = () => {
    form.resetFields()
    props.setCustomer_data({})
    setError_tax_id(null)
    setLocation_select({})
    setPostcode_select([])
  }

  const onFinish = values => {
    // console.log(props.customer_data)
  }

  return (
    <div>
      <Overview_customer_info 
        distar_customer={props.distar_customer}
      />
      <br />

      <Form
        form={form}
        name="create_new_customer_in_car"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Row>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp_Onblur
              header={t("TAX ID")}
              placeholder={t("TAX ID")}
              headState={props.setCustomer_data}
              nameState={props.customer_data}
              keyState="cus_taxid"
              type="text"
              maxLength={13}
              disabled={false}
            />
            <p style={{ color: "red" }}>{error_tax_id}</p>
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
          <Input_Comp
            header={t("Customer code")}
            placeholder={t("Customer code")}
            headState={props.setCustomer_data}
            nameState={props.customer_data}
            keyState="cus_code"
            type="text"
            maxLength={null}
            disabled={true}
          />
        </Col>

          <Col sm={12} md={2} lg={2} xl={2}>
            <Input_Number
              header={t("Credit (Day)")}
              placeholder={t("Credit (Day)")}
              headState={props.setCustomer_data}
              nameState={props.customer_data}
              keyState="cus_credit"
              maxLength={null}
            />

          </Col>
        <Col sm={0} md={4} lg={4} xl={4}></Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Customer name")}
              placeholder={t("Customer name")}
              headState={props.setCustomer_data}
              nameState={props.customer_data}
              keyState="cus_name"
              type="text"
              maxLength={null}
              disabled={false}
            /> 
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Customer phone")}
              placeholder={t("Customer phone")}
              headState={props.setCustomer_data}
              nameState={props.customer_data}
              keyState="cus_phone"
              type="number"
              maxLength={null}
              disabled={false}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Fax")}
              placeholder={t("Fax")}
              headState={props.setCustomer_data}
              nameState={props.customer_data}
              keyState="cus_fax"
              type="text"
              maxLength={null}
              disabled={false}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Email")}
              placeholder={t("Email")}
              headState={props.setCustomer_data}
              nameState={props.customer_data}
              keyState="cus_email"
              type="email"
              maxLength={null}
              disabled={false}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
          <Input_Comp
            header={t("Remark")}
            placeholder={t("Remark")}
            headState={props.setCustomer_data}
            nameState={props.customer_data}
            keyState="cus_remark"
            type="text"
            maxLength={null}
            disabled={false}
          />
        </Col>
        </Row>

        <Row>
          <Col>
            <p className="text-bold"> {t("Postcode")} <span style={{color:'red'}}>*</span></p>
            <Form.Item
              name="select_postcode"
              rules={[
                {
                  required: false,
                  message: `Please input postcode`,
                },
              ]}
            >
              <Input
                type="number"
                placeholder={t("Postcode")}
                onChange={e => check_postcode(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        {render_select_postcode()}
        {render_location_select()}
        {render_location_address()}

        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>

          {
            props.found_my_dealer === true && (
              <Select_no_search_true
                header={t("Select dealer")}
                placeholder={t("Select dealer")}
                name_api="LIST_DEALER"
                map_name="dea_name"
                map_value="dea_id"
                keyState="dea_id"
                headState={props.setCustomer_data}
                nameState={props.customer_data}
                disabled={true}
                dealer_info={props.dealer_info}
              />
            )
          }

          </Col>
        </Row>
      </Form>

  </div>
  )
}