const set_up = [
  {
    code: "7W4E14S",
    history: [
      {
        id: "1",
        date_setup: "01/02/64",
        name: "เครื่องบันทึกข้อมูล DVR",
        sn: "SN001",
        report: "เปิดไม่ติด",
        contact_setup: "คุณบุญชู",
      },
      {
        id: "2",
        date_setup: "01/02/65",
        name: "เครื่องบันทึกข้อมูล DVR",
        sn: "SN002",
        report: "ไม่แสดงตำแหน่ง",
        contact_setup: "คุณบุญชู",
      },
      {
        id: "3",
        date_setup: "01/02/66",
        name: "เครื่องบันทึกข้อมูล DVR",
        sn: "SN003",
        report: "มีเสียงดัง",
        contact_setup: "คุณบุญชู",
      },
      {
        id: "4",
        date_setup: "01/02/67",
        name: "เครื่องบันทึกข้อมูล DVR",
        sn: "SN004",
        report: "เปิดไม่ติด",
        contact_setup: "คุณบุญชู",
      },
    ],
  },
  {
    code: "8DA4F13",
    history: [
        {
          id: "1",
          date_setup: "02/04/60",
          name: "เครื่องบันทึกข้อมูล DVR",
          sn: "SN001",
          report: "มีเสียงดัง",
          contact_setup: "คุณภัทรพล",
        },
        {
          id: "2",
          date_setup: "01/02/65",
          name: "เครื่องบันทึกข้อมูล DVR",
          sn: "SN002",
          report: "เปิดไม่ติด",
          contact_setup: "คุณภัทรพล",
        },
        {
          id: "3",
          date_setup: "01/02/66",
          name: "เครื่องบันทึกข้อมูล DVR",
          sn: "SN003",
          report: "เปิดไม่ติด",
          contact_setup: "คุณภัทรพล",
        },
        {
          id: "4",
          date_setup: "14/02/67",
          name: "เครื่องบันทึกข้อมูล DVR",
          sn: "SN004",
          report: "เปิดไม่ติด",
          contact_setup: "คุณภัทรพล",
        },
      ],
  },
]

export default set_up
