import React from 'react'
import { Col } from "reactstrap"

export default function Colum({ Header_Text, value_Text }) {
    const text_header = {
        fontWeight:'bold',
        fontSize:'15px'
    }

    return (
        <>
        <Col sm={6} md={3} lg={3} xl={3} >
        <p style={text_header}>{Header_Text}</p>
        </Col>
        <Col sm={6} md={3} lg={3} xl={3}>
        <p style={{ fontSize:'15px'}}> <span className="tet">:</span> <span className="tetTwo">-</span> {value_Text}</p>
        </Col>
        </>
    )
}
