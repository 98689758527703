import React, { useState, useEffect } from "react"
import { Container, Card, CardBody, Row, Col } from "reactstrap"
import MetaTags from "react-meta-tags"
import axios from "axios"
import * as api from "../../../service/service_dealer/Api_Customer"
import Search from "./Search"
import Table from "./Table"
import { FcDataSheet } from "react-icons/fc"
import { Pagination, Spin } from "antd"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import helpers from "../../Function_Redirect/Redirect_login"
import { useSelector } from "react-redux"

const Customer = props => {
  const { t } = useTranslation()
  const token = localStorage.getItem("token")
  const dea_id = localStorage.getItem("dea_id")
  const history = useHistory()
  const [search_data_user, setSearch_data_user] = useState(null)
  const [data_list, setData_list] = useState([])
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 12,
  })
  const [loading, setLoading] = useState(false)
  const { dealer_user_id } = useSelector(({ Distar }) => Distar)

  useEffect(() => {
    search_data()
    return () => {
      setData_list([])
    }
  }, [dealer_user_id])

  function search_data() {
    setLoading(true)
    if (search_data_user) {
      const list_data_customer = api.LIST_DATA_DEA_ID(
        token,
        1,
        page_limit.page_Size,
        search_data_user,
        dealer_user_id
      )
      axios(list_data_customer)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          // console.log(res.data.data)
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else {
      const list_no_data_customer = api.LIST_NO_DATA_DEA_ID(
        token,
        1,
        page_limit.page_Size,
        dealer_user_id
      )
      axios(list_no_data_customer)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          // console.log(res.data.data)
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  function changePagination(page, pageSize) {
    setLoading(true)
    if (search_data_user) {
      const list_data_customer = api.LIST_DATA_DEA_ID(
        token,
        page,
        pageSize,
        search_data_user,
        dealer_user_id
      )
      axios(list_data_customer)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          // console.log(res.data.data)
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else {
      const list_data_customer = api.LIST_NO_DATA_DEA_ID(
        token,
        page,
        pageSize,
        dealer_user_id
      )
      axios(list_data_customer)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          // console.log(res.data.data)
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("customer")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>{t("customer")}</h3>

          <div>
            <br />
            <Search
              setSearch_data_user={setSearch_data_user}
              setData_list={setData_list}
              search_data={search_data}
            />
          </div>
          <div>
            {loading === false && (
              <Table data_list={data_list} search_data={search_data} />
            )}
            {loading === true && (
              <Spin
                style={{ display: "block", margin: "20px auto 20px auto" }}
              />
            )}
          </div>
          <div>
            <Pagination
            defaultPageSize={12}
            pageSizeOptions={["12","20","52","100" ]}
              current={page_limit.page}
              total={page_limit.limit}
              onChange={changePagination}
              style={{ marginTop: "20px", float: "right" }}
            />
          </div>
          <p style={{ fontSize: "15px", marginTop: "20px" }}>
            {" "}
            <FcDataSheet size={20} style={{ marginBottom: "5px" }} />{" "}
            {t("total data")}: {page_limit.limit}
          </p>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Customer
