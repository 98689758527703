import React, { useState, useEffect } from "react"
import { Tabs, Pagination } from "antd"
import Table_list_car from "./Table_list_car"
import axios from "axios"
import * as api from "../../../../service/Api_New_Customer"
import Data_car_search from "./Data_car_search"
import Create_new_car from "./Create_new_car"

const { TabPane } = Tabs

export default function Cars(props) {
  const [activeTab_all, setActiveTab_all] = useState("1")
  const [data_car_search, setData_car_search] = useState(null)
  const token = localStorage.getItem("token")
  const [data_list, setData_list] = useState([])
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 10,
    page_Size: 10,
  })

  useEffect(() => {
    search_car()
    return () => {
      setData_list([])
    }
  }, [activeTab_all])

  // useEffect(() => {
  //       search_car()
  //     return () => {
  //       setData_list([])
  //     }
  // }, [])

  function search_car() {
    if (data_car_search) {
      const list_data_car_data = api.LIST_CAR_IN_CUSTOMER_DATA(
        token,
        props.id,
        1,
        page_limit.page_Size,
        data_car_search
      )
      axios(list_data_car_data)
        .then(res => {
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setData_list(res.data.data)
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      const list_data_car_no_data = api.LIST_CAR_IN_CUSTOMER_NO_DATA(
        token,
        props.id,
        1,
        page_limit.page_Size
      )
      axios(list_data_car_no_data)
        .then(res => {
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setData_list(res.data.data)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  function changePagination(page, pageSize) {
    setPage_limit({
      ...page_limit,
      page: page,
      page_Size: pageSize,
    })
    if (data_car_search) {
      const list_data_car_data = api.LIST_CAR_IN_CUSTOMER_DATA(
        token,
        props.id,
        page,
        pageSize,
        data_car_search
      )
      axios(list_data_car_data)
        .then(res => {
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setData_list(res.data.data)
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      const list_data_car_no_data = api.LIST_CAR_IN_CUSTOMER_DATA(
        token,
        props.id,
        page,
        pageSize
      )
      axios(list_data_car_no_data)
        .then(res => {
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setData_list(res.data.data)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  return (
    <Tabs
      defaultActiveKey="1"
      onTabClick={e => {
        setActiveTab_all(e)
      }}
      activeKey={activeTab_all}
    >
      <TabPane tab="Cars" key="1">
        <Data_car_search
          search_car={search_car}
          setData_car_search={setData_car_search}
        />
        <Table_list_car data_list={data_list} id={props.id} />

        <div>
          <Pagination
            current={page_limit.page}
            total={page_limit.limit}
            onChange={changePagination}
            style={{ marginTop: "20px", float: "right" }}
          />
        </div>
      </TabPane>
      <TabPane tab="Create new car" key="2">
        <Create_new_car
          setActiveTab_all={setActiveTab_all}
          activeTab_all_Customer={props.activeTab_all_Customer}
          id={props.id}
        />
      </TabPane>
    </Tabs>
  )
}
