var Ip = require('../Ip')

export const LIST_DASHBOARD = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}dashboard/dealer`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };