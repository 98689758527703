import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Container, Card, CardBody } from "reactstrap"
import { Tabs } from "antd"
import axios from "axios"
import * as api from "../../service/Api_Profile_Menu"
import { useHistory } from "react-router-dom"
import helpers from "../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"
import Detail_Profile from "../components/Profile/Detail_Profile"
import EditDetail from "./EditDetail"
import ChangePassword from '../components/Profile/ChangePassword'

import { useDispatch , useSelector } from "react-redux"
import { ADD_NAME_USER , ADD_IMAGE_AVATAR } from '../../store/Distar/actionTypes';

const { TabPane } = Tabs

const Profile_Admin = props => {
  const { t } = useTranslation()
  const token = localStorage.getItem("token")
  const history = useHistory()
  const [data_user, setData_user] = useState(null)
  const [img, setImg] = useState(null)
  const [detailUser, setDetailUser] = useState([])
  const [currentTab, setCurrentTab] = useState("1")
  const dispatch = useDispatch()
  const action = (type,payload)=>dispatch({type, payload})
  const { isAdminRoot } = useSelector(({ Distar }) => Distar)
  
  useEffect(() => {
    get_admin_info()
    return () => {}
  }, [])

  function get_admin_info() {
    const get_userInfo = api.CHECK_USER_INFO(token)
    axios(get_userInfo)
      .then(res => {
        action(ADD_NAME_USER, res.data.user_infomation.admin_firstname)
        action(ADD_IMAGE_AVATAR, res.data.user_infomation.admin_avatar)
        get_image(res.data.user_infomation.admin_avatar)
        setData_user({
          admin_avatar: res.data.user_infomation.admin_avatar,
          admin_code: res.data.user_infomation.admin_code,
          admin_email: res.data.user_infomation.admin_email,
          admin_firstname: res.data.user_infomation.admin_firstname,
          admin_group_id: res.data.user_infomation.admin_group_id,
          admin_lastname: res.data.user_infomation.admin_lastname,
          admin_id: res.data.user_infomation.admin_id,
        })
        setDetailUser([
          {
            headerDetail: t("firstname"),
            data: check_data(res.data.user_infomation.admin_firstname),
          },
          {
            headerDetail: t("lastname"),
            data: check_data(res.data.user_infomation.admin_lastname),
          }, 
          {
            headerDetail: t("email"),
            data: check_data(res.data.user_infomation.admin_email),
          },
          {
            headerDetail: t("code"),
            data: check_data(res.data.user_infomation.admin_code),
          },
          {
            headerDetail: t("group"),
            data: check_data(res.data.user_infomation.admin_group_name),
          },
        ])
      })
      .catch(error => {
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  async function get_image(path_image) {
    const get_data_image = api.GET_IMAGE(token, path_image)
    await axios(get_data_image)
      .then(res => {
        const blob = new Blob([res.data], {
          type: "image/jpeg",
        })
        var objectURL = URL.createObjectURL(blob)
        setImg(objectURL)
      })
      .catch(error => {
        // console.log(error.response);
      })
  }

  function check_data(data) {
    if (data === null) {
      return "N/A"
    } else {
      return data
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Profile")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}> {t("Profile")} </h3>

{
    data_user !== null && (
        <Card>
        <CardBody>
          <Tabs
            defaultActiveKey="1"
            onTabClick={e => {
              setCurrentTab(e)
            }}
            activeKey={currentTab}
          >
            <TabPane tab={t("Overview")} key="1">
              <Detail_Profile
                imageProfile={img}
                detailUser={detailUser}
                sizeImage={120}
                nameHeader={t("Profile")}
              />
            </TabPane>
            <TabPane tab={t("Edit")} key="2">
              <EditDetail img={img} data_user={data_user} get_admin_info={get_admin_info} />
            </TabPane>
            {
            isAdminRoot === false && (
              <TabPane tab={t("change password")} key="3">
               <ChangePassword setCurrentTab={setCurrentTab} />
            </TabPane>
            )
          }
          </Tabs>
        </CardBody>
      </Card>
    )
}

        </Container>
      </div>
    </React.Fragment>
  )
}
export default Profile_Admin
