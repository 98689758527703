import React, { useState, useEffect } from "react"
import { Card, CardBody } from "reactstrap"
import { Tabs } from "antd"
import axios from "axios"
import { useHistory } from "react-router-dom"
import * as api from "../../../../service/Api_Operator"
import Table_data from "./Table_data"
import helpers from "../../../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"

const { TabPane } = Tabs

const Operator = props => {
  const { t } = useTranslation()
  const token = localStorage.getItem("token")
  const history = useHistory()
  const [search_operator, setSearch_operator] = useState(null)
  const [data_operator_all, setData_operator_all] = useState([])
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })
  const [loading, setLoading] = useState(false)

  const [filter_operator, setFilter_operator] = useState({
    operator_group_id: null,
    operator_dep_id: null,
  })

  useEffect(() => {
    search_operator_data()
    return () => {}
  }, [filter_operator])

  useEffect(() => {
    search_operator_data()
    return () => {
      setData_operator_all([])
    }
  }, [])

  const check_data = (params, data) => {
    if (data) {
      return params + data
    } else {
      return ""
    }
  }

  function search_operator_data() {
    setLoading(true)
    let url = `api/v1/operator?page=1&limit=${
      page_limit.page_Size
    }&order_by=asc${check_data("&q=", search_operator)}${check_data(
      "&operator_group_id=",
      filter_operator.operator_group_id
    )}${check_data("&operator_dep_id=", filter_operator.operator_dep_id)}`
    const search = api.SEARCH_ALL(token, url)
    axios(search)
      .then(res => {
        setLoading(false)
        setPage_limit({
          ...page_limit,
          page: 1,
          limit: res.data.total_data,
        })
        setData_operator_all(res.data.data)
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }

        // console.log(error)
      })
  }

  function changePagination(page, pageSize) {
    setLoading(true)
    let url = `api/v1/operator?page=${page}&limit=${pageSize}&order_by=asc${check_data(
      "&q=",
      search_operator
    )}${check_data(
      "&operator_group_id=",
      filter_operator.operator_group_id
    )}${check_data("&operator_dep_id=", filter_operator.operator_dep_id)}`
    const search = api.SEARCH_ALL(token, url)
    axios(search)
      .then(res => {
        setLoading(false)
        setPage_limit({
          ...page_limit,
          page: page,
          page_Size: pageSize,
          limit: res.data.total_data,
        })
        setData_operator_all(res.data.data)
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }

        // console.log(error)
      })
  }

  return (
    <div style={{ overflowY:'scroll', height:'400px', overflowX : 'hidden' }}>
      <Card>
        <CardBody>
          <Table_data
          setVisible={props.setVisible}
          setOperatorSelect={props.setOperatorSelect}
          operatorSelect={props.operatorSelect}
            filter_operator={filter_operator}
            setFilter_operator={setFilter_operator}
            search_operator_data={search_operator_data}
            setSearch_operator={setSearch_operator}
            changePagination={changePagination}
            data_operator_all={data_operator_all}
            page_limit={page_limit}
            loading={loading}
          />
        </CardBody>
      </Card>
    </div>
  )
}
export default Operator
