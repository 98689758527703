import React from "react"
import { Row, Col } from "reactstrap"
import { Checkbox } from "antd"
import Input_Date_Comp from "./components/Input_Date_Comp"
import Select_Filter_Customer from "../../../Cars/select_search/Select_Filter_Customer"
import "./Header_Filter.css"
import { useTranslation } from "react-i18next";

export default function Filter_Comp(props) {
  const { t } = useTranslation();
  // filter_contract={props.filter_contract}
  // setFilter_contract={props.setFilter_contract}

  // const [filter_contract, setFilter_contract] = useState({
  //     expired : false ,
  //     datestart : null ,
  //     dateend : null ,
  //     customer_id : null
  //   })
  function onChange_Checkbox(e) {
    props.setFilter_contract({
      ...props.filter_contract,
      expired: e.target.checked,
    })
  }

  return (
    <div>
      <Row>
        <Col sm={12} md={6} lg={3} xl={3}>
          <Input_Date_Comp
            header={t("Date start")}
            placeholder={t("Date start")}
            keyState="datestart"
            nameState={props.filter_contract}
            headState={props.setFilter_contract}
          />
        </Col>
        <Col sm={12} md={6} lg={3} xl={3}>
          <Input_Date_Comp
            header={t("Date end")}
            placeholder={t("Date end")}
            keyState="dateend"
            nameState={props.filter_contract}
            headState={props.setFilter_contract}
          />
        </Col>

        <Col sm={12} md={6} lg={3} xl={3}>
          <Select_Filter_Customer
            headState={props.setFilter_contract}
            nameState={props.filter_contract}
            keyState="customer_id"
          />
        </Col>

        <Col sm={12} md={6} lg={3} xl={3}>
        <p className="text-bold header-filter"> {t("Contract expired")} </p>
        <span className="checkbox-filter">
          <Checkbox onChange={onChange_Checkbox} />
        </span>
      </Col>
      </Row>
    </div>
  )
}
