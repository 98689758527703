var Ip = require('../service/Ip')

export const CHECK_DEALER_USER = (token, dea_user_id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/dealer/user/${dea_user_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const GET_IMAGE = (token, image) => {
    return {
      method: 'get',
      responseType: 'arraybuffer',
      url: `${Ip.getIP()}storage/file/${image}`,
      headers: {
        'x-access-token' : token
      }
    };
  };
  
  export const UPLOAD_PROFILE = (token, formData) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}storage/upload`,
      headers: {
        "Content-type": "multipart/form-data",
        'x-access-token' : token
      },
      data : formData
    };
  };

  export const DELETE_DEALER_USER = (token, dea_user_id) => {
    return {
      method: 'delete',
      url: `${Ip.getIP()}api/v1/dealer/user/${dea_user_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const EDIT_DEALER_USER = (token, dea_user_id , data) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/dealer/user/${dea_user_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };
