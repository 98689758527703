import React from "react"
import {
  Menu,
  Dropdown,
  Button,
  Space,
  notification,
  Modal,
  message,
  Tooltip,
} from "antd"
import { DownOutlined, UserOutlined, EllipsisOutlined } from "@ant-design/icons"
import axios from "axios"
import * as api from "../../../../service/Api_Edit_Service"
import { useTranslation } from "react-i18next"
import { ExclamationCircleOutlined } from "@ant-design/icons"

const { confirm } = Modal

export default function Control_Service(props) {
  const token = localStorage.getItem("token")
  const { t } = useTranslation()

  function handleMenuClick(e) {
    return confirm({
      title: "Confirm cancel service",
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: "Confirm",
      okType: "danger",
      cancelText: "No",
      onOk() {
        const cancel_service = api.CHANGE_STATUS_SERVICE(
          token,
          props.ser_id,
          "cancel"
        )
        axios(cancel_service)
          .then(res => {
            openNotificationWithIcon("success", "Cancel service success")
            props.list_service_overview()
            props.check_new_data_edit()
          })
          .catch(error => {
            // console.log(error.response);
            openNotificationWithIcon("error", "Cancel service error")
          })
      },
      onCancel() {},
    })
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  return (
    <div style={{ float: "right" }}>
      {props.service_status_name === "complete" && (
        <p style={{ color: "#03A70A", fontSize: "25px" }}>
          {" "}
          {t("Service complete")}{" "}
        </p>
      )}

      {props.service_status_name === "reject" && (
        <p style={{ color: "red", fontSize: "25px" }}>
          {" "}
          {t("Reject service")}{" "}
        </p>
      )}

      {props.service_status_name === "cancel" && (
        <p style={{ color: "red", fontSize: "25px" }}>
          {" "}
          {t("Cancel service")}{" "}
        </p>
      )}

    </div>
  )
}
