import React, { useRef, useState } from "react"
import { Row, Col } from "reactstrap"
import { Input, Button, Tag, notification, Modal } from "antd"
// import SignatureCanvas from 'react-signature-canvas'
// import SignaturePad from "react-signature-canvas";
import UploadImage from "./component/UploadImage"
import SignaturePad from "./SignaturePad"
import Check_in_Comp from "./Check_in_Comp"
import Check_Out_Comp from "./Check_Out_Comp"
import Button_Check_In_Out from "./component/Button_Check_In_Out"
import "./component/styles.module.sig.css"
import axios from "axios"
import * as api from "../../../../../service/service_operator/Api_Service"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { FcApproval } from "react-icons/fc"
import Render_Image_After_Upload from "./component/Render_Image_After_Upload"
import { useHistory } from "react-router-dom"
import helpers from "../../../../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"
import UploadFileComp from "./component/UploadFileComp"
import PreviewFileUpload from "./component/PreviewFileUpload"

const { confirm } = Modal

export default function TextForm(props) {
  const { t } = useTranslation()
  const token = localStorage.getItem("token")
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [status_progress, setStatus_progress] = useState(null)

  const color_detail = {
    color: "#737373",
  }
  function render_priority(priority) {
    if (priority) {
      switch (priority) {
        case "urgent":
          return (
            <Tag color="error" style={{ marginBottom: "10px" }}>
              {t(priority)}
            </Tag>
          )
          break
        case "high":
          return (
            <Tag color="warning" style={{ marginBottom: "10px" }}>
              {t(priority)}
            </Tag>
          )
          break
        case "medium":
          return (
            <Tag color="processing" style={{ marginBottom: "10px" }}>
              {t(priority)}
            </Tag>
          )
          break
        case "low":
          return (
            <Tag color="success" style={{ marginBottom: "10px" }}>
              {t(priority)}
            </Tag>
          )
          break
        default:
          return null
      }
    }
  }

  function check_task_success(task_name, pass_step) {
    switch (pass_step) {
      case true:
        return (
          <p style={{ fontSize: "20px" }}>
            {task_name} <FcApproval size={25} />
          </p>
        )
        break
      case false:
        return <p style={{ fontSize: "20px" }}>{task_name} </p>
        break
      default:
        return <p style={{ fontSize: "20px" }}>{task_name} </p>
        break
    }
  }
  
  function renderForm() {
    return props.fields.map((form, index) => {
      switch (form.task_type_module_name) {
        case "inputField":
          return (
            <Row key={index}>
              <Col sm={12} xs={12} md={6} lg={6}>
                {check_task_success(form.task_name, form.pass_step)}
                {render_priority(form.task_priority_name)}
              </Col>
              <Col sm={12} xs={12} md={6} lg={6}>
                <Input
                  placeholder={form.value}
                  value={form.value}
                  disabled={form.pass_step}
                  onChange={event =>
                    props.handleInputChange_Detail(
                      props.index_step,
                      index,
                      event
                    )
                  }
                  type={form.type}
                  style={{ marginBottom: "10px" }}
                />
                <p style={color_detail}>{form.task_detail}</p>
              </Col>
            </Row>
          )
          break
        case "checkinField":
          return (
            <Row key={index}>
              <Col sm={12} xs={12} md={6} lg={6}>
                {check_task_success(form.task_name, form.pass_step)}
                {render_priority(form.task_priority_name)}
              </Col>
              <Col sm={12} xs={12} md={6} lg={6}>
                <Button_Check_In_Out
                  check_data_submit={props.check_data_submit}
                  Check_service_form={props.Check_service_form}
                  ser_id={props.ser_id}
                  setTask_hast_form={props.setTask_hast_form}
                  task_hast_form={props.task_hast_form}
                  index_step={props.index_step}
                  index_field={index}
                  value={form.value}
                  type="check_in"
                  text_button={t("Check in here")}
                  text_success={t("Check in success")}
                  color="#2FAC0A"
                  task_name={form.task_name}
                />
                <p style={color_detail}>{form.task_detail}</p>
              </Col>
            </Row>
          )
          break
        case "checkoutField":
          return (
            <Row key={index}>
              <Col sm={12} xs={12} md={6} lg={6}>
                {check_task_success(form.task_name, form.pass_step)}
                {render_priority(form.task_priority_name)}
              </Col>
              <Col sm={12} xs={12} md={6} lg={6}>
                <Button_Check_In_Out
                  check_data_submit={props.check_data_submit}
                  Check_service_form={props.Check_service_form}
                  ser_id={props.ser_id}
                  setTask_hast_form={props.setTask_hast_form}
                  task_hast_form={props.task_hast_form}
                  index_step={props.index_step}
                  index_field={index}
                  value={form.value}
                  type="check_out"
                  text_button={t("Check out here")}
                  text_success={t("Check out success")}
                  color="#D83A56"
                  task_name={form.task_name}
                />
                <p style={color_detail}>{form.task_detail}</p>
              </Col>
            </Row>
          )
          break
        case "signatureField":
          return (
            <Row key={index}>
              <Col sm={12} xs={12} md={6} lg={6}>
                {check_task_success(form.task_name, form.pass_step)}
                {render_priority(form.task_priority_name)}
              </Col>
              <Col sm={12} xs={12} md={6} lg={6}>
                <div
                  style={{
                    marginBottom: "80px",
                    // height: "400px",
                    // backgroundColor: "#F1F0F0",
                  }}
                >
                  <SignaturePad
                    setTask_hast_form={props.setTask_hast_form}
                    task_hast_form={props.task_hast_form}
                    index_step={props.index_step}
                    index_field={index}
                    value={form.value}
                  />
                  <p style={color_detail}>{form.task_detail}</p>
                </div>
              </Col>
            </Row>
          )
          break

        case "uploadFileField":
          return (
            <Row key={index}>
              <Col sm={12} xs={12} md={6} lg={6}>
                {check_task_success(form.task_name, form.pass_step)}
                {render_priority(form.task_priority_name)}
              </Col>
              <Col sm={12} xs={12} md={6} lg={6}>
                <div
                  style={{
                    marginBottom: "80px",
                  }}
                >
                  {form.value === null && (
                    <UploadFileComp
                      setTask_hast_form={props.setTask_hast_form}
                      task_hast_form={props.task_hast_form}
                      index_step={props.index_step}
                      index_field={index}
                    />
                  )}
                  {form.value !== null && (
                    <PreviewFileUpload value={form.value} />
                  )}
                  <p style={color_detail}>{form.task_detail}</p>
                </div>
              </Col>
            </Row>
          )
          break

        case "imageField":
          return (
            <Row key={index}>
              <Col sm={12} xs={12} md={6} lg={6}>
                {check_task_success(form.task_name, form.pass_step)}
                {render_priority(form.task_priority_name)}
              </Col>
              <Col sm={12} xs={12} md={6} lg={6}>
                <div style={{ marginBottom: "10px" }}>
                  {form.value === null && (
                    <UploadImage
                      setTask_hast_form={props.setTask_hast_form}
                      task_hast_form={props.task_hast_form}
                      index_step={props.index_step}
                      index_field={index}
                    />
                  )}

                  {form.value !== null && (
                    <Render_Image_After_Upload value={form.value} />
                  )}
                </div>
                <p style={color_detail}>{form.task_detail}</p>
              </Col>
            </Row>
          )
          break
        default:
          return null
      }
    })
  }

  function submit_step() {
    if (props.index_step === props.last_step - 1) {
      return confirm({
        title: t("Confirm submit final step"),
        content: t("this action can't be undo"),
        icon: <ExclamationCircleOutlined />,
        centered: true,
        cancelText: t("cancel"),
        okText: t("Confirm"),
        onOk() {
          submit_step_data()
        },
        onCancel() {},
      })
    } else {
      return confirm({
        title: `${t("Confirm submit step")} ${props.index_step + 1}`,
        icon: <ExclamationCircleOutlined />,
        centered: true,
        cancelText: t("cancel"),
        okText: t("Confirm"),
        onOk() {
          submit_step_data()
        },
        onCancel() {},
      })
    }
  }

  async function submit_step_data() {
    setLoading(true)
    const data_fields = []
    const promises = props.fields.map(async data => {
      if (
        data.task_type_module_name === "signatureField" &&
        data.image !== null
      ) {
        setStatus_progress(t("Uploading signature"))
        sendSignatureField(data, data_fields)
      } else if (
        data.task_type_module_name === "imageField" &&
        data.multi_image.length !== 0
      ) {
        const image_multi = []
        const promisesImage = data.multi_image.map(
          async (data_image, index) => {
            setStatus_progress(
              `Uploading image ${data.multi_image.length} / ${index + 1}`
            )
            const formData = new FormData()
            formData.append("file", data_image)
            const data_img = await upload_image(formData)
            image_multi.push(data_img)

            return data_img
          }
        )
        setStatus_progress(t("Upload photo success"))
        data_fields.push({
          image: null,
          pass_step: data.pass_step,
          task_detail: data.task_detail,
          task_id: data.task_id,
          task_name: data.task_name,
          task_no: data.task_no,
          task_priority_name: data.task_priority_name,
          task_stepper: data.task_stepper,
          task_type_module_name: data.task_type_module_name,
          task_type_name: data.task_type_name,
          value: image_multi,
        })
        await Promise.all(promisesImage)
      } else if (
        data.task_type_module_name === "uploadFileField" &&
        data.multi_image.length !== 0
      ) {
        setStatus_progress(t("Uploading file"))

        const fileUpload_multi = []
        const promisesFileUpload = data.multi_image.map(dataUpload =>
          uploadFileService(dataUpload, data.task_id, fileUpload_multi)
        )

        await Promise.all(promisesFileUpload)
        data_fields.push({
          image: null,
          pass_step: data.pass_step,
          task_detail: data.task_detail,
          task_id: data.task_id,
          task_name: data.task_name,
          task_no: data.task_no,
          task_priority_name: data.task_priority_name,
          task_stepper: data.task_stepper,
          task_type_module_name: data.task_type_module_name,
          task_type_name: data.task_type_name,
          value: fileUpload_multi,
        })
      } else {
        data_fields.push(data)
      }
    })

    await Promise.all(promises)

    if (props.index_step === props.last_step - 1) {
      props.setEnableEdit(false)
      setStatus_progress(`${t("Sending task step")} ${props.index_step + 1}`)
      setTimeout(() => {
        send_final_data_in_form(data_fields, props.index_step + 1)
      }, 2000)
    } else {
      props.setEnableEdit(false)
      setStatus_progress(`${t("Sending task step")} ${props.index_step + 1}`)
      setTimeout(() => {
        send_data_in_form(data_fields, props.index_step + 1)
      }, 2000)
    }
  }

  async function uploadFileService(data, task_id, fileUpload_multi) {
    const formData = new FormData()
    formData.append("file", data)
    const uploadFileOne = api.UPLOAD_FILE_DYNAMIC_TASK(token, task_id, formData)
    await axios(uploadFileOne)
      .then(res => {
        fileUpload_multi.push(res.data)
        return res.data
      })
      .catch(error => {
        return error
      })
  }

  async function sendSignatureField(data, data_fields) {
    const file_64 = DataURIToBlob(data.image)
    const formData = new FormData()
    formData.append("file", file_64, "signature.jpg")
    const data_img = await upload_image(formData)
    setStatus_progress(t("Upload signature success"))
    data_fields.push({
      image: null,
      pass_step: data.pass_step,
      task_detail: data.task_detail,
      task_id: data.task_id,
      task_name: data.task_name,
      task_no: data.task_no,
      task_priority_name: data.task_priority_name,
      task_stepper: data.task_stepper,
      task_type_module_name: data.task_type_module_name,
      task_type_name: data.task_type_name,
      value: data_img,
    })
    return data_img
  }

  const upload_image = async image => {
    const create_task = api.UPLOAD_PROFILE(token, image)
    const fetcher = await axios(create_task)
    return fetcher.data.path
  }

  const send_data_in_form = async (data_task, step) => {
    const create_task = api.INSERT_STEP(
      token,
      props.ser_id,
      props.index_step + 1,
      data_task
    )
    const fetcher = await axios(create_task)

    if (fetcher.status === 201) {
      setTimeout(() => {
        setLoading(false)
        openNotificationWithIcon(
          "success",
          `${t("Insert task step")} ${step} ${t("success")}`
        )
        setStatus_progress(null)
        props.check_data_submit()
        props.Check_service_form()
      }, 2000)
    } else if (fetcher.status === 401) {
      helpers.re_login()
      history.push("/login")
    } else {
      setLoading(false)
      openNotificationWithIcon(
        "error",
        `${t("Insert task step")} ${step} ${t("error")}`
      )
      setStatus_progress(`${t("Insert task step")} ${step} ${t("error")}`)
    }
  }

  const send_final_data_in_form = async (data_task, step) => {
    const create_task = api.INSERT_STEP(
      token,
      props.ser_id,
      props.index_step + 1,
      data_task
    )
    const fetcher = await axios(create_task)
    if (fetcher.status === 201) {
      change_status_service_final_step(step)
    } else if (fetcher.status === 401) {
      helpers.re_login()
      history.push("/login")
    } else {
      setLoading(false)
      openNotificationWithIcon("error", t("Insert task error"))
    }
  }

  function change_status_service_final_step(step) {
    const change_status = api.CHANGE_STATUS_ACCEPT_SERVICE_HAS_DATA(
      token,
      props.ser_id,
      "pending",
      "pending service job"
    )
    axios(change_status)
      .then(res => {
        localStorage.removeItem(`service_${props.ser_id}`)
        setLoading(false)
        setStatus_progress(null)
        openNotificationWithIcon(
          "success",
          `${t("Insert task step")} ${step} success`
        )
        props.check_new_data_edit()
        props.check_activity_log()
        props.list_service_overview()
        props.setActiveTab("1")
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            } else {
              openNotificationWithIcon(
                "error",
                `${t("Insert task step")} ${step} error`
              )
              setStatus_progress(null)
            }
          }
        }
      })
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(",")
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0]
    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i)
    return new Blob([ia], { type: mimeString })
  }

  return (
    <div>
      {/* <button onClick={() => console.log(props.fields)}>
        debug submit step
      </button> */}
      {renderForm()}
      <div style={{ width: "100%" }}>
        {status_progress !== null && (
          <p style={{ fontSize: "20px" }}>
            {t("Status")} : {status_progress}
          </p>
        )}
        {props.index_step !== props.last_step - 1 && (
          <Button
            type="primary"
            className="btn-submit-form-dynamic"
            onClick={() => submit_step()}
            loading={loading}
          >
            {t("Submit step")} {props.index_step + 1}
          </Button>
        )}
        {props.index_step === props.last_step - 1 && (
          <Button
            type="primary"
            className="btn-close-form-dynamic"
            onClick={() => submit_step()}
            loading={loading}
          >
            {t("Submit step")} {props.index_step + 1} {t("and close job")}
          </Button>
        )}
      </div>
    </div>
  )
}
