import React, { useState } from "react"
import { Table, Modal, Tooltip  } from "antd"
import { useHistory } from "react-router-dom"
import { AiOutlineEye } from "react-icons/ai"
import { FiEdit2 } from "react-icons/fi"
import Detail_dealer from "./Detail_dealer"
import { FcSearch, FcSettings } from "react-icons/fc"
import { useTranslation } from "react-i18next";

export default function Table_Dealer(props) {
  const history = useHistory()
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false)
  const [data_dealer, setData_dealer] = useState({})

  const columns = [
    {
      title: t("Dealer code"),
      dataIndex: "dea_code",
      key: "dea_code",
      render: text => checkData(text),
    },
    {
      title: t("Dealer TAX ID"),
      dataIndex: "dea_taxid",
      key: "dea_taxid",
      render: text => checkData(text),
    },
    {
      title: t("Dealer name"),
      dataIndex: "dea_name",
      key: "dea_name",
      render: text => checkData(text),
    },
    {
      title: t("Dealer phone"),
      dataIndex: "dea_phone",
      key: "dea_phone",
      render: text => checkData(text),
    },
    {
      title: "",
      render: text => (
        <Tooltip title={t("View dealer")} >
        <a>
          <FcSearch
            onClick={() => {
              setVisible(true)
              setData_dealer(text)
            }}
            size={20}
          />
        </a>
  </Tooltip>
      ),
    },
    {
      title: "",
      render: text => (
        <Tooltip title={t("Edit dealer")} >
        <a>
          <FcSettings
            onClick={() => history.push(`/dealer/${text.dea_id}`)}
            size={20}
          />
        </a>
        </Tooltip>
      ),
    },
  ]

  function checkData(data) {
    if (data) {
      return data
    } else {
      return "N/A"
    }
  }

  return (
    <div>
      <Table
      loading={props.loading}
        columns={columns}
        dataSource={props.data_list}
        pagination={false}
        rowKey="dea_code"
        scroll={{ x: 800 }}
      />

      <Modal
        title={false}
        centered
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={() => {
          setVisible(false)
          setData_dealer({})
        }}
        footer={false}
        height={500}
        width={1000}
      >
        <Detail_dealer data_dealer={data_dealer} />
      </Modal>
    </div>
  )
}
