import React, { useState, useEffect } from "react"
import { Form, Button, Modal, notification } from "antd"
import SelectCustomer from "./SelectCustomer"
import SelectGeolocation from "./SelectGeolocation"
import { Row, Col } from "reactstrap"
import SelectSLA from "../../New_Service_Has_Stock_Id/components/SelectSLA"
import Map_location from "../../New_Service_Has_Stock_Id/components/Map_location"
import Input_Show_Only from "../../New_Service_Has_Stock_Id/components/Input_Show_Only"
import { useTranslation } from "react-i18next"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { useSelector } from "react-redux"
import Input_Comp from "../../New_Service_Has_Stock_Id/components/Input_Comp"
import Select_no_search from "../../New_Service_Has_Stock_Id/components/Select_no_search"
import Input_Date_Comp from "../../New_Service_Has_Stock_Id/components/Input_Date_Comp"
import Input_Time from "../../New_Service_Has_Stock_Id/components/Input_Time"
import UploadDocsFile from "../../New_Service_Has_Stock_Id/components/UploadDocsFile"
import SelectCat from "./SelectCat"
import { useHistory } from "react-router-dom"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import * as api from "../../../service/Api_New_Service"
import axios from "axios"

const { confirm } = Modal

export default function FormCreate({
  createNewService,
  setCreateNewService,
  location_select,
  setLocation_select,
  type_received_all,
  setType_received_all,
  setFileUploadAll,
  fileUploadAll,
  user_select,
  setUser_select,
  customerSelect,
  setCustomerSelect,
  selectCar,
  setSelectCar,
}) {
  const { isAdminRoot, isRole } = useSelector(({ Distar }) => Distar)
  const [user_in_customer, setUser_in_customer] = useState(false)
  const [type_filter, setType_filter] = useState(null)
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const history = useHistory()
  const token = localStorage.getItem("token")

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  useEffect(() => {
    if (customerSelect.cus_name !== null) {
      form.setFieldsValue({
        ser_contact_name: customerSelect.cus_name,
      })
      setCreateNewService({
        ...createNewService,
        ser_contact_name: customerSelect.cus_name,
      })
    }

    return () => {}
  }, [customerSelect])

  useEffect(() => {
    function check_type_received(type) {
      type_received_all.map(data => {
        if (data.ser_received_type_id === type)
          return setType_filter(data.ser_received_type_name)
      })
      form.resetFields([
        "ser_notification_email",
        "ser_notification_phone",
        "ser_notification_line",
      ])
    }

    if (createNewService.ser_received_type_id) {
      check_type_received(createNewService.ser_received_type_id)
      setCreateNewService({
        ...createNewService,
        ser_notification_email: null,
        ser_notification_line: null,
        ser_notification_phone: null,
      })
    }
    return () => {}
  }, [createNewService.ser_received_type_id])

  function cancelForm() {
    history.push("/new-service")
  }

  const onFinish = values => {
    if (customerSelect.cus_id === null) {
      return Modal.error({
        title: t("Please select customer"),
        centered: true,
      })
    } else if (
      customerSelect.cus_id !== null &&
      user_in_customer === true &&
      customerSelect.cus_user_id === null
    ) {
      return Modal.error({
        title: t("Please select user"),
        centered: true,
      })
    } else if (selectCar.length === 0) {
      return Modal.error({
        title: t("Please select car"),
        centered: true,
      })
    } else {
      return confirm({
        title: t("Confirm create new service"),
        icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
        centered: true,
        okText: t("create"),
        cancelText: t("no"),
        onOk() {
          setLoading(true)
          startCreateLoopCar()
        },
        onCancel() {},
      })
    }
  }

  function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  async function startCreateLoopCar() {
    var interval = 2000
    const promises = selectCar.map(async (data, index) => {
      await timeout(interval * index)
      const loop = await createServiceOne(data)
      return loop
    })

    await Promise.all(promises).then(data => {
      // console.log(data);
    })
    setLoading(false)
    history.push("/service")
  }

  async function createServiceOne(data) {
    let statusCreateService = null
    let dataServiceOne = {
      ...createNewService,
      ...location_select,
      cus_user_id: customerSelect.cus_user_id,
      cus_id: customerSelect.cus_id,
      sto_id: data.sto_id,
    }
    const create_service_data = api.CREATE_NEW_SERVICE(token, dataServiceOne)
    await axios(create_service_data)
      .then(async res => {
        if (fileUploadAll.length !== 0) {
          const DataService = await loopUpload(res.data.ser_id, data)
          statusCreateService = DataService
        } else {
          let dataSuccess = {
            car_license_number: data.car_license_number,
            data: data,
            status: "success",
          }
          statusCreateService = dataSuccess
        }
        openNotificationWithIcon(
          "success",
          t("create service car license") +
            " " +
            data.car_license_number +
            " " +
            t("success")
        )
      })
      .catch(error => {
        let dataError = {
          car_license_number: data.car_license_number,
          data: error.response,
          status: "error",
        }
        statusCreateService = dataError
        openNotificationWithIcon(
          "error",
          t("create service car license") +
            " " +
            data.car_license_number +
            " " +
            t("error")
        )
      })

    return statusCreateService
  }

  async function loopUpload(ser_id, dataCar) {
    let dataLoopUpload = null
    const dataLoopPromises = []
    const promises = fileUploadAll.map(async data => {
      const loop = await uploadFileService(data, ser_id, dataCar)
      return loop
    })
    await Promise.all(promises).then(data => {
      dataLoopPromises.push(data)
    })
    dataLoopUpload = dataLoopPromises
    return dataLoopUpload
  }

  async function uploadFileService(file, ser_id, dataCar) {
    let statusUploadFile = null
    const formData = new FormData()
    formData.append("file", file)
    const uploadFileOne = api.UPLOAD_FILE_SERVICE(token, formData, ser_id)
    await axios(uploadFileOne)
      .then(res => {
        openNotificationWithIcon(
          "success",
          t("upload file car license") +
            " " +
            dataCar.car_license_number +
            " " +
            t("success")
        )
        let dataFile = {
          data: res.data,
          ser_id: ser_id,
          status: "success",
        }
        statusUploadFile = dataFile
      })
      .catch(error => {
        openNotificationWithIcon(
          "error",
          t("upload file car license") +
            " " +
            dataCar.car_license_number +
            " " +
            t("error")
        )
        let dataFile = {
          data: error.response,
          ser_id: ser_id,
          status: "success",
        }
        statusUploadFile = dataFile
      })
    return statusUploadFile
  }

  return (
    <div>
      <Form
        form={form}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <SelectCustomer
          form={form}
          customerSelect={customerSelect}
          setCustomerSelect={setCustomerSelect}
          createNewService={createNewService}
          setCreateNewService={setCreateNewService}
          setUser_in_customer={setUser_in_customer}
          user_in_customer={user_in_customer}
          type_filter={type_filter}
          setType_filter={setType_filter}
          type_received_all={type_received_all}
          setType_received_all={setType_received_all}
          user_select={user_select}
          setUser_select={setUser_select}
        />
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Contact name")}
              placeholder={t("Contact name")}
              headState={setCreateNewService}
              nameState={createNewService}
              type="text"
              keyState="ser_contact_name"
              required={true}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Contact phone")}
              placeholder={t("Contact phone")}
              headState={setCreateNewService}
              nameState={createNewService}
              type="number"
              keyState="ser_contact_phone"
              required={true}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Service type")}
              placeholder={t("Service type")}
              name_api="LIST_SERVICE_TYPE"
              map_name="ser_type_name"
              map_value="ser_type_id"
              keyState="ser_type_id"
              headState={setCreateNewService}
              nameState={createNewService}
              name_array_data="data"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Service priority")}
              placeholder={t("Service priority")}
              name_api="LIST_PRIORITY"
              map_name="pri_name"
              map_value="pri_id"
              keyState="pri_id"
              headState={setCreateNewService}
              nameState={createNewService}
              name_array_data="data"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Date_Comp
              header={t("Service appointment date")}
              placeholder={t("Service appointment date")}
              headState={setCreateNewService}
              nameState={createNewService}
              keyState="ser_booking_date"
              required={true}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Time
              header={t("Service appointment time")}
              placeholder={t("Service appointment time")}
              headState={setCreateNewService}
              nameState={createNewService}
              keyState="ser_booking_time"
              required={true}
            />
          </Col>

          {isAdminRoot === true && (
            <Col sm={12} md={6} lg={6} xl={6}>
              <Select_no_search
                header={t("Admin group")}
                placeholder={t("Admin group")}
                name_api="FIND_ALL_ADMIN_GROUP"
                map_name="admin_group_name"
                map_value="admin_group_id"
                keyState="admin_group_id"
                headState={setCreateNewService}
                nameState={createNewService}
                name_array_data="data"
              />
            </Col>
          )}

          <Col sm={12} md={6} lg={6} xl={6}>
            <UploadDocsFile setFileUploadAll={setFileUploadAll} />
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={12} lg={12} xl={12}>
            <p className="text-bold">
              {t("Issue reported")} <span style={{ color: "red" }}>*</span>
            </p>
            <CKEditor
              editor={ClassicEditor}
              config={{
                removePlugins: [
                  "ImageUpload",
                  "EasyImage",
                  "MediaEmbed",
                  "Table",
                ],
              }}
              data={
                createNewService.ser_issue_reported
                  ? createNewService.ser_issue_reported
                  : ""
              }
              onChange={(event, editor) => {
                const data = editor.getData()
                if (data) {
                  setCreateNewService({
                    ...createNewService,
                    ser_issue_reported: data,
                  })
                } else {
                  setCreateNewService({
                    ...createNewService,
                    ser_issue_reported: null,
                  })
                }
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={12} lg={12} xl={12}>
            <SelectSLA
              setCreate_service={setCreateNewService}
              create_service={createNewService}
            />
          </Col>
        </Row>

        <SelectGeolocation
          location_select={location_select}
          setLocation_select={setLocation_select}
          form={form}
          createNewService={createNewService}
          setCreateNewService={setCreateNewService}
        />
        <Row>
          <Col sm={12} md={12} lg={12} xl={12}>
            <p className="text-bold">{t("search location")}</p>
            <p> {t("search or click on map to select location")} </p>
            <Map_location
              setCreate_service={setCreateNewService}
              create_service={createNewService}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Show_Only
              placeholder={t("latitude")}
              description={null}
              header={t("latitude")}
              keyState="ser_lat"
              required={false}
              value={createNewService.ser_lat}
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Show_Only
              placeholder={t("longitude")}
              description={null}
              header={t("longitude")}
              keyState="ser_long"
              required={false}
              value={createNewService.ser_long}
            />
          </Col>
        </Row>

        <SelectCat selectCar={selectCar} setSelectCar={setSelectCar} />

        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Service remark")}
              placeholder={t("Service remark")}
              headState={setCreateNewService}
              nameState={createNewService}
              type="text"
              keyState="ser_remark"
              required={true}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div className="div_create_new_car">
              <Button
                className="btn_cancel_create_new_car"
                onClick={() => cancelForm()}
              >
                {t("Cancel")}
              </Button>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div className="div_create_new_car">
              <Button
                className="btn_submit_create_new_car"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {t("Create")}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
