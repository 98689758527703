import React,{ useState, useEffect } from 'react'
import { Card, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const { Meta } = Card;

export default function Operator(props) {
    const [operator, setOperator] = useState([])

    useEffect(() => {
        if(props.service_overview.operator){
            // console.log(props.service_overview.operator)
            setOperator(props.service_overview.operator)
        }
        return () => {
            // setOperator([])
        }
    }, [props.service_overview])

    useEffect(() => {
        if(operator){
            // console.log(operator);
            render_operator()
        }
        
        return () => {
            
        }
    }, [operator])

    function render_operator() {
        return operator.map( (data, index) => {
            return(
                <Card style={{ width: '100%', marginTop: 16 }} key={index} >
                <Meta
                  avatar={
                    <Avatar size={54} icon={<UserOutlined style={{ marginBottom:'13px' }} />} />
                  }
                  title={`${data.ope_firstname}   ${data.ope_lastname}`}
                  description={data.ope_code}
                />
              </Card>
            )
        })
    }

    return (
        <div style={{ padding:'10px', height:'250px', overflowY: "scroll", overflowX: "hidden" , boxShadow:'rgb(99 99 99 / 20%) 0px 2px 8px 0px', padding:'10px', margin:'10px'}}>
        <p style={{ fontSize: "20px", marginBottom: "15px", color: "#495057" }}>
        Operator
      </p>

      {
        render_operator()
    }

        </div>
    )
}
