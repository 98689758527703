// module.exports.getIP = function () {
//   return "http://192.168.1.154:5002/"
// }
// // http://25.12.132.163:5002/
// // http://10.1.2.55:5001
// module.exports.getIP_WebSocket = function () {
//   return "ws://192.168.1.154:5002/"
// }

module.exports.getIP = function () {
  return "https://webservice.distar.co.th/"
}
// http://25.12.132.163:5002/
// http://10.1.2.55:5001
module.exports.getIP_WebSocket = function () {
  return "wss://webservice.distar.co.th/"
}