import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { Input, Pagination, Table } from "antd"
import * as api from "../../../../../service/Api_New_Service"
import { EditOutlined } from "@ant-design/icons"
import axios from "axios"

const { Search } = Input

export default function List_Stock(props) {
  const token = localStorage.getItem("token")
  const [data_search_stock, setData_search_stock] = useState(null)
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 10,
    page_Size: 10,
  })

  useEffect(() => {
    search_data_stock()
    return () => {
      setData_search_stock(null)
    }
  }, [])

  function search_data_stock() {
    if (data_search_stock) {
      const list_stock_data_data = api.LIST_CUSTOMER_HAS_STOCK_DATA(
        token,
        props.cus_id,
        1,
        page_limit.page_Size,
        data_search_stock
      )
      axios(list_stock_data_data)
        .then(res => {
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          props.setData_stock(res.data.data)
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      const list_stock_data_no_data = api.LIST_CUSTOMER_HAS_STOCK_NO_DATA(
        token,
        props.cus_id,
        1,
        page_limit.page_Size
      )
      axios(list_stock_data_no_data)
        .then(res => {
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          props.setData_stock(res.data.data)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  function changePagination(page, pageSize) {
    if (data_search_stock) {
      const list_stock_data_data = api.LIST_CUSTOMER_HAS_STOCK_DATA(
        token,
        props.cus_id,
        page,
        pageSize,
        data_search_stock
      )
      axios(list_stock_data_data)
        .then(res => {
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          props.setData_stock(res.data.data)
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      const list_stock_data_no_data = api.LIST_CUSTOMER_HAS_STOCK_NO_DATA(
        token,
        props.cus_id,
        page,
        pageSize
      )
      axios(list_stock_data_no_data)
        .then(res => {
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          props.setData_stock(res.data.data)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  const columns = [
    {
      title: "Stock asset number",
      dataIndex: "sto_asset_number",
      key: "sto_asset_number",
      render: text => checkData(text),
    },
    {
      title: "Stock serial number",
      dataIndex: "sto_serial_number",
      key: "sto_serial_number",
      render: text => checkData(text),
    },
    {
      title: "Stock date install (DD/MM/YY)",
      dataIndex: "sto_date_install",
      key: "sto_date_install",
      render: text => cutTimeZone(text),
    },
  ]

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return "N/A"
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  function checkData(data) {
    if (data) {
      return data
    } else {
      return "N/A"
    }
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      props.setData_new({
        ...props.data_new,
        sto_id: selectedRows[0].sto_id,
      })
      props.setStock_name(selectedRows[0].sto_asset_number)
    },
    getCheckboxProps: record => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  }

  return (
    <div>
      {props.stock_name === null && (
        <div>
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <p className="text-bold">
                Select stock <span style={{ color: "red" }}>*</span>
              </p>
              <Search
                style={{ width: "100%" }}
                placeholder="asset , serial"
                allowClear
                enterButton="Search"
                size="medium"
                onSearch={search_data_stock}
                onChange={e => setData_search_stock(e.target.value)}
              />
            </Col>
          </Row>
          <br />
          <Table
            rowSelection={{
              type: "radio",
              ...rowSelection,
            }}
            rowKey="sto_id"
            pagination={false}
            columns={columns}
            dataSource={props.data_stock}
            scroll={{ x: 800 }}
          />

          <div style={{ float: "right", marginTop: "10px" }}>
            <Pagination
              current={page_limit.page}
              total={page_limit.limit}
              onChange={changePagination}
            />
          </div>
        </div>
      )}

      {props.stock_name !== null && (
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div style={{ display: "flex" }}>
              <p className="text-bold " style={{ marginTop: "7px" }}>
                {" "}
                Stock{" "}
              </p>
              <Input
                style={{ marginLeft: "28px", height: "35px" }}
                value={props.stock_name}
                placeholder={props.stock_name}
                disabled
              />
              <a>
                <EditOutlined
                  style={{ fontSize: "20px", marginLeft: "10px" }}
                  onClick={() => {
                    props.setData_new({
                      ...props.data_new,
                      sto_id: null,
                    })
                    props.setStock_name(null)
                  }}
                />
              </a>
            </div>
          </Col>
        </Row>
      )}
    </div>
  )
}
