import React, { useState, useEffect } from "react"
import { Form, Modal, Button, Progress , notification } from "antd"
import { Row, Col } from "reactstrap"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import InputPassword from "./InputPassword"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import axios from 'axios'
import * as api from '../../../service/Api_Profile_Menu'
import helpers from "../../Function_Redirect/Redirect_login"

const { confirm } = Modal

export default function ChangePassword({ setCurrentTab }) {
  const { t } = useTranslation()
  const history = useHistory()
  const token = localStorage.getItem('token')
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [pass, setPass] = useState(null)
  const [currentPass, setCurrentPass] = useState(null)
  const [retypePass, setRetypePass] = useState(null)
  const [passStrong, setPassStrong] = useState({
    passwordTet: "",
    passwordColor: "",
    passwordNumber: 0,
  })

  useEffect(() => {
    CheckPasswordStrength()
    return () => {}
  }, [pass])

  function CheckPasswordStrength() {
    if (pass) {
      var regex = new Array()
      regex.push("[A-Z]")
      regex.push("[a-z]")
      regex.push("[0-9]")
      regex.push("[$@$!%*#?&]")
      var passed = 0
      for (var i = 0; i < regex.length; i++) {
        if (new RegExp(regex[i]).test(pass)) {
          passed++
        }
      }
      if (passed > 2 && pass.length > 3) {
        passed++
      }
      var color = ""
      var passwordStrength = ""
      var number = 0
      switch (passed) {
        case 0:
          break
        case 1:
          passwordStrength = "Password is Weak."
          color = "Red"
          number = 25
          break
        case 2:
          passwordStrength = "Password is Good."
          color = "darkorange"
          number = 50
          break
        case 3:
          break
        case 4:
          passwordStrength = "Password is Strong."
          color = "Green"
          number = 75
          break
        case 5:
          passwordStrength = "Very Strong."
          color = "darkgreen"
          number = 100
          break
      }
      setPassStrong({
        passwordTet: passwordStrength,
        passwordColor: color,
        passwordNumber: number,
      })
    }
  }

  useEffect(() => {
    if (pass && retypePass) {
      if (pass !== retypePass) {
        setError(t("Password mismatch"))
      } else {
        setError(null)
      }
    }
    return () => {}
  }, [pass, retypePass , currentPass])

  const onReset = () => {
    form.resetFields()
  }

  const onFinish = values => {
    return confirm({
        title: t("Confirm change password"),
        icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
        centered: true,
        okText: t("Confirm"),
        cancelText: t("no"),
        onOk() {
            setLoading(true)
            let passwordIsChange = {
                old_password : currentPass,
                new_pass : pass
            }
            const change_pass = api.CHANGE_PASSWORD(token , passwordIsChange)
            axios(change_pass)
            .then( res => {
              setCurrentTab("1")
                cancelResetPassword()
                setLoading(false)
                openNotificationWithIcon("success", t("change password success"))
            })
            .catch( error => {
                setLoading(false)
                if (error) {
                  if (error.response) {
                    if (error.response.status === 401) {
                      helpers.re_login()
                      history.push("/login")
                    } else if(error.response.status === 400) {
                      openNotificationWithIcon("error", t("Current password mismatch"))
                      setError(t("Current password mismatch"))
                    } else {
                      openNotificationWithIcon("error", t("change password error"))
                    }
                  }
                }
            })
        },
        onCancel() {},
      })
  }

  function cancelResetPassword() {
    onReset()
    setError(null)
    setPass(null)
    setCurrentPass(null)
    setRetypePass(null)
    setPassStrong({
      ...passStrong,
      passwordTet: "",
      passwordColor: "",
      passwordNumber: 0,
    })
  }

  const openNotificationWithIcon = (type, description) => {
    notification[type]({
      message: description,
    })
}

  return (
    <div>
      <Row>
        <Col sm={0} md={0} lg={4} xl={4}></Col>
        <Col sm={12} md={12} lg={4} xl={4}>
          <Form form={form} name="control-hooks" onFinish={onFinish}>
            <InputPassword
              header={t("Current password")}
              keyForm="Current_password"
              setState={setCurrentPass}
            />

            <InputPassword
              header={t("New password")}
              keyForm="new_password"
              setState={setPass}
            />

            {passStrong.passwordNumber !== 0 && (
              <div>
                <Progress
                  percent={passStrong.passwordNumber}
                  size="small"
                  status="active"
                />
                <p
                  style={{
                    color: passStrong.passwordColor,
                    textAlign: "right",
                  }}
                >
                  {passStrong.passwordTet}
                </p>
              </div>
            )}

            <InputPassword
              header={t("Retype new password")}
              keyForm="retype_password"
              setState={setRetypePass}
            />

            {error && (
              <p style={{ textAlign: "center", color: "red" }}>{error}</p>
            )}

            <Row>
              <Col sm={12} md={6} lg={6} xl={6}>
                <div className="div_create_new_car">
                  <Button
                    className="btn_cancel_create_new_car"
                    onClick={() => cancelResetPassword()}
                  >
                    {t("Cancel")}
                  </Button>
                </div>
              </Col>
              <Col sm={12} md={6} lg={6} xl={6}>
                <div className="div_create_new_car">
                  <Button
                    className="btn_submit_create_new_car"
                    type="primary"
                    htmlType="submit"
                    disabled={error ? true : false}
                    loading={loading}
                  >
                    {t("Edit")}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col sm={0} md={0} lg={4} xl={4}></Col>
      </Row>
    </div>
  )
}
