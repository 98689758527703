import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { useHistory } from "react-router-dom"
import { Container, Card, CardBody, Row, Col } from "reactstrap"
import { MdKeyboardBackspace } from "react-icons/md"
import { Form, Button, Modal, notification } from "antd"
import axios from "axios"
import * as api from "../../service/Api_Create_new_dealer"
import Input_Comp from "./components/Input_Comp"
import Input_Number from "./components/Input_Number"
import Select_no_search from "./components/Select_no_search"
import Select_Postcode from "./components/Select_Postcode"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import Select_Data_Array from './components/Select_Data_Array'
import helpers from '../Function_Redirect/Redirect_login'
import { useTranslation } from "react-i18next";
import no_permi from '../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png'
import { useSelector } from "react-redux"

const { confirm } = Modal

export default function Contract() {
  const { t } = useTranslation();
  const history = useHistory()
  const [form] = Form.useForm()
  const token = localStorage.getItem("token")
  const [error_tax_id, setError_tax_id] = useState(null)
  const [data_dealer, setData_dealer] = useState({
    product_group : []
  })
  const [location_select, setLocation_select] = useState({})
  const [postcode_select, setPostcode_select] = useState([])
  const [loading, setLoading] = useState(false)
  const permi = localStorage.getItem("permi")
  const [permi_t, setPermi_t] = useState(false)
  const { permissionAllService } = useSelector(({ Distar }) => Distar)

  useEffect(() => {
    if (permissionAllService) {
      if(permissionAllService.dealer){
        setPermi_t(permissionAllService.dealer.write)
      }
    }
    return () => {

    }
  }, [permissionAllService])


  useEffect(() => {
    function check_dealer_duplicate() {
      if (data_dealer.dea_taxid) {
        const check_duplicate = api.CHECK_DEALER_TAX_ID_DUPLICATE(
          token,
          data_dealer.dea_taxid
        )
        axios(check_duplicate)
          .then(res => {
            if (res.data === "True") {
              setError_tax_id(t("TAX ID is duplicate"))
            } else {
              setError_tax_id(null)
            }
          })
          .catch(error => {
            // console.log(error)
          })
      }
    }
    check_dealer_duplicate()
    return () => {}
  }, [data_dealer.dea_taxid])

  const onFinish = values => {
    return confirm({
      title: t("Create new dealer"),
      icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
      centered: true,
      okText: t("Create"),
      cancelText: t("no"),
      onOk() {
        setLoading(true)
        const data_new = {
          ...data_dealer,
          is_import : false
        }
        const create_new_dealer = api.CREATE_DEALER(token, data_new)
        axios(create_new_dealer)
          .then(res => {
            setLoading(false)
            openNotificationWithIcon("success", t("Create dealer success"))
            cancelForm()
            history.push(`/dealer/${res.data.dea_id}`)
          })
          .catch(error => {
            setLoading(false)
            if(error){
              if(error.response){
                if(error.response.status === 401){
                  helpers.re_login()
                  history.push('/login')          
                  } else {
                    openNotificationWithIcon("error", t("Create dealer error"))
                  }
              }
            }
  
           
          })
      },
      onCancel() {},
    })
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  function cancelForm() {
    form.resetFields()
    setData_dealer({
      product_group : []
    })
    setLocation_select({})
    setPostcode_select([])
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Create new dealer")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/dealer")}
              />
            </a>{" "}
            {t("Create new dealer")}
          </h3>

          {
            permi_t === true && (
              <Card>
              <CardBody>
  
                    <Form
                      name="create_new_dealer"
                      initialValues={{
                        remember: true,
                      }}
                      onFinish={onFinish}
                      form={form}
                    >
                      <Row>
                        <Col sm={12} md={6} lg={6} xl={6}>
                          <Input_Comp
                            header={t("Dealer TAX ID")}
                            placeholder={t("Dealer TAX ID")}
                            headState={setData_dealer}
                            nameState={data_dealer}
                            type="text"
                            keyState="dea_taxid"
                          />
                          {error_tax_id && (
                            <p style={{ color: "red", fontSize: "15px" }}>
                              {error_tax_id}
                            </p>
                          )}
                        </Col>
  
                        <Col sm={12} md={6} lg={6} xl={6}>
                          <Input_Comp
                            header={t("Dealer name")}
                            placeholder={t("Dealer name")}
                            headState={setData_dealer}
                            nameState={data_dealer}
                            type="text"
                            keyState="dea_name"
                          />
                        </Col>
                       
                        <Col sm={12} md={2} lg={2} xl={2}>
                          <Input_Number
                            header={t("Dealer credit")}
                            placeholder={t("Dealer credit")}
                            headState={setData_dealer}
                            nameState={data_dealer}
                            keyState="dea_credit"
                          />
                        </Col>
                        <Col sm={0} md={4} lg={4} xl={4}></Col>
  
                        <Col sm={12} md={6} lg={6} xl={6}>
                          <Input_Comp
                            header={t("Dealer phone")}
                            placeholder={t("Dealer phone")}
                            headState={setData_dealer}
                            nameState={data_dealer}
                            type="number"
                            keyState="dea_phone"
                          />
                        </Col>
                        <Col sm={12} md={6} lg={6} xl={6}>
                          <Input_Comp
                            header={t("Dealer email")}
                            placeholder={t("Dealer email")}
                            headState={setData_dealer}
                            nameState={data_dealer}
                            type="email"
                            keyState="dea_email"
                          />
                        </Col>
                        <Col sm={12} md={6} lg={6} xl={6}>
                          <Input_Comp
                            header={t("Dealer fax")}
                            placeholder={t("Dealer fax")}
                            headState={setData_dealer}
                            nameState={data_dealer}
                            type="text"
                            keyState="dea_fax"
                          />
                        </Col>
  
                        <Col sm={12} md={6} lg={6} xl={6}>
                          <Input_Comp
                            header={t("Contact firstname")}
                            placeholder={t("Contact firstname")}
                            headState={setData_dealer}
                            nameState={data_dealer}
                            type="text"
                            keyState="dea_contact_firstname"
                          />
                        </Col>
                        <Col sm={12} md={6} lg={6} xl={6}>
                          <Input_Comp
                            header={t("Contact lastname")}
                            placeholder={t("Contact lastname")}
                            headState={setData_dealer}
                            nameState={data_dealer}
                            type="text"
                            keyState="dea_contact_lastname"
                          />
                        </Col>
                        <Col sm={12} md={6} lg={6} xl={6}>
                        <Select_no_search
                          header={t("Dealer position")}
                          placeholder={t("Dealer position")}
                          name_api="LIST_POSITION"
                          map_name="pos_name"
                          map_value="pos_id"
                          keyState="dea_pos_id"
                          headState={setData_dealer}
                          nameState={data_dealer}
                        />
                      </Col>
                        <Col sm={12} md={6} lg={6} xl={6}>
                          <Input_Comp
                            header={t("Contact email")}
                            placeholder={t("Contact email")}
                            headState={setData_dealer}
                            nameState={data_dealer}
                            type="email"
                            keyState="dea_contact_email"
                          />
                        </Col>
                        <Col sm={12} md={6} lg={6} xl={6}>
                          <Input_Comp
                            header={t("Contact mobile")}
                            placeholder={t("Contact mobile")}
                            headState={setData_dealer}
                            nameState={data_dealer}
                            type="number"
                            keyState="dea_contact_mobile"
                          />
                        </Col>

                        <Col sm={12} md={6} lg={6} xl={6}>
                        <Select_Data_Array
                          setData_dealer={setData_dealer}
                          data_dealer={data_dealer}
                        />
                      </Col>
                      </Row>
  
                      <Row>
                        <Col>
                          <Select_Postcode
                            setData_dealer={setData_dealer}
                            data_dealer={data_dealer}
                            location_select={location_select}
                            setLocation_select={setLocation_select}
                            postcode_select={postcode_select}
                            setPostcode_select={setPostcode_select}
                          />
                        </Col>
                      </Row>
  
                      <Row>
                        <Col sm={12} md={6} lg={6} xl={6}>
                          <div className="div_create_new_car">
                            <Button
                              className="btn_cancel_create_new_car"
                              onClick={() => cancelForm()}
                            >
                          {t("Cancel")}
                            </Button>
                          </div>
                        </Col>
                        <Col sm={12} md={6} lg={6} xl={6}>
                          <div className="div_create_new_car">
                            <Button
                              className="btn_submit_create_new_car"
                              type="primary"
                              htmlType="submit"
                              disabled={error_tax_id}
                              loading={loading}
                            >
                           {t("Create")}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
      
              </CardBody>
            </Card>
            )
          }

          {
            permi_t === false && (
              <div>
                <img src={no_permi} alt="no permission" style={{ display:'block' , margin: '50px auto' }} />
                  <p style={{ textAlign: "center", fontSize: "20px" }}>{t("no permission write module")}</p>
                  <p style={{ textAlign:'center', fontSize:'20px' }}> {t("please contract administrator")} </p>
                </div>

            )
          }
          
        </Container>
      </div>
    </React.Fragment>
  )
}
