import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { Button, Input, Form, Modal,Select, notification } from "antd"
import { UserAddOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import { useHistory } from "react-router-dom"
import * as api from '../../../service/Api_Customer'
import axios from 'axios'

const { confirm } = Modal;

const { Option } = Select;
const style_req = {
  color: "red",
}

export default function Creat_new_customer(props) {
    const token = localStorage.getItem("token")
    const history = useHistory()
    const [form] = Form.useForm();
    const [error_tax_id, setError_tax_id] = useState(null)
    const [dealer_data, setDealer_data] = useState([])
    const [customer_data, setCustomer_data] = useState({})

    useEffect(() => {
        function get_dealer_list() {
          const list_dealer = api.LIST_DEALER(token)
          axios(list_dealer)
          .then( res => {
            setDealer_data(res.data.data)
          })
          .catch( error => {
            console.log(error);
          })
        }
        get_dealer_list()
        return () => {
          
        }
      }, [])
    
    useEffect(() => {
      render_dealer_select()
      return () => {
        
      }
    }, [dealer_data])
    
      function render_dealer_select() {
        return dealer_data.map( (data, index) => {
          return <Option key={index} value={data.dea_id}>{data.dea_name}</Option>
        } )
      }

    function onChangeDealer(value) {
        setCustomer_data({
          ...customer_data,
          dea_id : value
        })
      }

    function getFind(obj) {
        if(isNaN(obj)){
           setError_tax_id('TAX ID is number only')
        } else {
           setError_tax_id(null)
           setCustomer_data({
            ...customer_data,
            cus_taxid : obj
          })
        }
      }
    
      useEffect(() => {
        function check_taxID() {
          if( customer_data.cus_taxid){
            if( customer_data.cus_taxid.length === 13){
              const check_tax = api.CHECK_TAX_ID(token, customer_data.cus_taxid)
              axios(check_tax)
              .then( res => {
                if(res.data === 'True'){
                    setError_tax_id('TAX ID is duplicate')
                } else if(res.data === 'False') {
                    setError_tax_id(null)
                }
              })
              .catch( error => {
                console.log(error);
              })   
            } else {
              setError_tax_id('TAX ID must equal 13 digit')
            }
          }
        }
        
        check_taxID()
        return () => {
          
        }
      }, [customer_data.cus_taxid])


    const create_new_customer = values => {
        confirm({
          title: 'Create new customer',
          icon: <QuestionCircleOutlined style={{ color:'#0084d1' }} />,
          content: '',
          centered: true,
          okText: 'Create',
          onOk() {
               const create_customer = api.CREATE_CUSTOMER(token,customer_data)
                axios(create_customer)
                .then( res => {
                  history.push({
                    pathname : '/new-customer',
                    data : res.data.cus_id
                  })
                  openNotificationWithIcon('success','Create new customer success')
                })
                .catch( error => {
                  console.log(error.response);
                })
          },
          onCancel() {
         
          },
        });
      }
    
      const openNotificationWithIcon = (type,text) => {
        notification[type]({
          message: text,
        });
      };

      const onReset = () => {
        form.resetFields();
        setCustomer_data({})
      };

    return (
        <Form
        form={form}
        name="new_customer"
        initialValues={{
          remember: true,
        }}
        onFinish={create_new_customer}
      >
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <p className="text-bold">
              TAX ID <span style={style_req}>*</span>
            </p>
            <Form.Item
              name="tax_id"
              rules={[
                {
                  required: true,
                  message: "Please input TAX ID"
                },
              ]}
            >
              <Input
                maxLength={13}
                placeholder="TAX ID"
                onChange={e => getFind(e.target.value)}
              />
            </Form.Item>
            <p style={{ color:'red' }}>{error_tax_id}</p>
          </Col>
          <Col sm={12} md={6} lg={4} xl={6}>
          <p className="text-bold">
              Credit <span style={style_req}>*</span>
            </p>
            <Form.Item
              name="credit"
              rules={[
                {
                  required: false,
                  message: "Please input Credit",
                },
              ]}
            >
              <Input
                  type="number"
                  placeholder="Credit"
                  addonAfter="Day"
                  // onChange={(e) => console.log(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col >
            <p className="text-bold">
              Company <span style={style_req}>*</span>
            </p>
            <Form.Item
              name="company_name"
              rules={[
                {
                  required: true,
                  message: "Please input company",
                },
              ]}
            >
              <Input
                placeholder="Company name"
                onChange={e => setCustomer_data({
                  ...customer_data,
                  cus_name : e.target.value
                })}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col >
            <p className="text-bold">
              Dealer <span style={style_req}>*</span>
            </p>
            <Form.Item
              name="dealer_name"
              rules={[
                {
                  required: true,
                  message: "Please input dealer",
                },
              ]}
            >
              <Select
                  showSearch
                  placeholder="Select a dealer"
                  optionFilterProp="children"
                  onChange={onChangeDealer}
                  filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
              >
              { render_dealer_select() }
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
        <Col sm={12} md={6} lg={6} xl={6}>
            <div className="div_create_new_car">
                <Button className="btn_cancel_create_new_car"  onClick={() => {
                    props.setVisible(false)
                    onReset()
                  }} > Cancel </Button>   
            </div>
        </Col>
        <Col sm={12} md={6} lg={6} xl={6}>
            <div className="div_create_new_car">
                <Button 
                    className="btn_submit_create_new_car" 
                    type="primary" 
                    htmlType="submit" 
                    disabled={ error_tax_id ? error_tax_id : false} > 
                    Create 
                </Button>
            </div>
        </Col>
        </Row>
      </Form>
    )
}
