import React,{ useState } from "react"
import { Button, Form, Modal } from "antd"
import { Row, Col } from "reactstrap"
import Input_Comp from './components/Input_Comp'
import Select_Search_Comp from './components/Select_Search_Comp'
import Select_no_search from './components/Select_no_search'
import Select_branch_comp from './components/Select_branch_comp'
import axios from "axios"
import * as api from '../../../../../service/Api_New_Customer'
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

export default function Edit_a_car(props) {
    const token = localStorage.getItem('token')
    const [form] = Form.useForm();
    const [branch_name, setBranch_name] = useState(null)
    const [loading, setLoading] = useState(false)
    const [data_car, setData_car] = useState({
        cus_id: props.id,
        cus_branch_id: null,
      })
    
    function onFinish(value) {
    if(data_car.cus_branch_id === null){
        return  Modal.error({
            title: 'Please select branch',
            centered: true
          });
    } else {
        return   confirm({
            title: 'Create new car',
            icon: <ExclamationCircleOutlined />,
            okText: 'Create',
            centered: true,
            onOk() {
                setLoading(true)
                const create_new_car = api.CREATE_NEW_CAR(token,data_car)
                axios(create_new_car)
                .then( res => {
                    onReset()
                    setLoading(false)
                    props.setActiveTab_all('1')
                    props.search_car()
                })
                .catch( error => {
                    setLoading(false)
                    console.log(error.response);
                    return  Modal.error({
                        title: 'Please try again',
                        centered: true
                      });
                })
            },
            onCancel() {
            },
          });
       
    }
        // CREATE_NEW_CAR
    }
 
    const onReset = () => {
        form.resetFields();
        setData_car({
            ...data_car,
            cus_id: props.id,
            cus_branch_id: null,
        })
        setBranch_name(null)
      };

  return (
    <Form
        form={form}
        name="create_new_car_in_customer"
        initialValues={{
            remember: true,
        }}
        onFinish={onFinish}
    >
    <Row>
        <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp 
            header="License no"
            placeholder="license no"
            headState={setData_car}
            nameState={data_car}
            keyState='car_license_number'
            />
        </Col>
        <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp 
                header="Car name"
                placeholder="car name" 
                headState={setData_car}
                nameState={data_car}
                keyState='car_name'
            />
        </Col>
    </Row>

    <Row>
        <Col sm={12} md={6} lg={6} xl={6}>
            <Select_Search_Comp 
                header="Province"
                placeholder="province"
                headState={setData_car}
                nameState={data_car}
                keyState='car_license_province_id'
            />
        </Col>
        <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search 
                header="Color"
                placeholder="color"
                name_api='LIST_CAR_COLOR'
                keyState="car_color_id"
                map_name="car_color_name"
                headState={setData_car}
                nameState={data_car}
            />
        </Col>
    </Row>

    <Row>
        <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp 
                header="Chassis number "
                placeholder="chassis number "
                headState={setData_car}
                nameState={data_car}
                keyState='car_chassis_number'
            />
        </Col>
        <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search 
                header="Body type"
                name_api='LIST_CAR_BODY_TYPE'
                map_name="car_body_type_name"
                keyState="car_body_type_id"
                placeholder="body type"
                headState={setData_car}
                nameState={data_car}
            />
        </Col>
    </Row>

    <Row>
        <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search 
                header="Brand"
                name_api='LIST_CAR_BRAND'
                map_name="car_brand_name"
                keyState="car_brand_id"
                placeholder="brand"
                headState={setData_car}
                nameState={data_car}
            />
        </Col>
        <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search 
                header="Model"
                name_api='LIST_CAR_MODEL'
                map_name="car_model_name"
                keyState="car_model_id"
                placeholder="model"
                headState={setData_car}
                nameState={data_car}
            />
        </Col>
    </Row>

    <Row>
        <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search 
                header="Year"
                name_api='LIST_CAR_YEAR'
                map_name="car_model_year_name"
                keyState="car_model_year_id"
                placeholder="year"
                headState={setData_car}
                nameState={data_car}
            />
        </Col>
    </Row>

    <Row>
      <Col>
            <Select_branch_comp 
                setData_car={setData_car}
                data_car={data_car}
                setBranch_name={setBranch_name}
                branch_name={branch_name}
                branch_table={props.branch_table}
                setData_search_branch={props.setData_search_branch}
                page_limit_branch={props.page_limit_branch}
                search_data_branch={props.search_data_branch}
                changePagination={props.changePagination}
                loading_branch={props.loading_branch}
            />
      </Col>
    </Row>

    <br />
    <Row>
    <Col sm={12} md={6} lg={6} xl={6}>
        <div className="div_create_new_car">
            <Button className="btn_cancel_create_new_car" onClick={() => onReset()} > Cancel </Button>   
        </div>
    </Col>
    <Col sm={12} md={6} lg={6} xl={6}>
        <div className="div_create_new_car">
            <Button className="btn_submit_create_new_car" type="primary" htmlType="submit" loading={loading} > Create </Button>
        </div>
    </Col>
    </Row>
  
    </Form>

  )
}
