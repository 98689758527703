import React from "react"
import { Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import Colum from "../Components/Colum"
import Col_Resolution_SLA from "../Components/Col_Resolution_SLA"
import Col_Day_Hour_Minute from "./Col_Day_Hour_Minute"

export default function Detail_SLA({ sla_detail }) {
  const { t } = useTranslation()

  return (
    <div
      className="modal-detail-service-admin"
    >
      <p
        style={{
          textAlign: "center",
          fontWeight: "bold",
          marginTop: "10px",
          fontSize: "15px",
        }}
      >
        {t("SLA information")}
      </p>
      <hr />

      <Row>
        <Colum
          header_text={t("SLA name")}
          value_text={sla_detail.sla_service_name}
        />
        <Colum
          header_text={t("Priority")}
          value_text={t(sla_detail.pri_name)}
        />
        <Col_Resolution_SLA
          header_text={t("SLA resolution")}
          value_text={t(sla_detail.sla_resolution)}
        />
        <Colum header_text={t("province")} value_text={sla_detail.pro_name} />

        <Colum
          header_text={t("remark")}
          value_text={sla_detail.sla_service_remark}
        />

        <Col_Day_Hour_Minute
          day={sla_detail.sla_service_day}
          hour={sla_detail.sla_service_hr}
          minute={sla_detail.sla_service_min}
        />
        {/*        <Colum
          header_text={t("day")}
          value_text={sla_detail.sla_service_day}
        />
        <Colum
          header_text={t("hour")}
          value_text={sla_detail.sla_service_hr}
        />
        <Colum
          header_text={t("minute")}
          value_text={sla_detail.sla_service_min}
/> **/}
      </Row>
    </div>
  )
}
