import React, { useState, useEffect } from "react"
import { Container, Card, CardBody, Row , Col } from "reactstrap"
import { useHistory, useParams } from "react-router-dom"
import MetaTags from "react-meta-tags"
import { Tabs } from "antd"
import { MdKeyboardBackspace } from "react-icons/md"
import axios from "axios"
import * as api from "../../../service/API_Edit_Car"
import Device from "./Device"
import Detail from "./Detail"
import helpers from '../../Function_Redirect/Redirect_login'
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux"

const { TabPane } = Tabs

const Detail_car = props => {
  const { t } = useTranslation();
    const history = useHistory()
  const token = localStorage.getItem('token')
  const cus_id = localStorage.getItem('cus_id')
  const [data_car, setData_car] = useState({})
  const { customer_user_id } = useSelector(({ Distar }) => Distar)
  let { car_id } = useParams()
  
  useEffect(() => {
      
      return () => {
        setData_car({})
      }
  }, [])

  useEffect(() => {
    check_detail_car()
    if (car_id === undefined) {
      history.push(`/cars/`)
    }
    return () => {

    }
  }, [car_id])


  function check_detail_car() {
    const new_data = api.DETAIL_CAR(token, car_id)
    axios(new_data)
      .then(res => {
        if (!res.data.data[0]) {
            history.push(`/cars/`)
        } else {
          setData_car(res.data.data[0])
        }
      })
      .catch(error => {
        if(error){
          if(error.response){
            if(error.response.status === 401){
              helpers.re_login()
              history.push('/login')          
              }
          }
        }

      })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Detail cars")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>
          <a>
            <MdKeyboardBackspace
              style={{ marginRight: "15px", marginBottom: "3px" }}
              onClick={() => history.push("/cars")}
            />
          </a>
          {t("Detail cars")} </h3>
    
        
          <Card>
          <CardBody>
            <Tabs
              defaultActiveKey="1"
            >
              <TabPane tab={t("Detail")} key="1">
                <Detail data_car={data_car} />
              </TabPane>
              <TabPane tab={t("Device")} key="2">
                <Device car_id={parseInt(car_id)} cus_id={customer_user_id} />
              </TabPane>
            </Tabs>
          </CardBody>
        </Card>

        </Container>
      </div>
    </React.Fragment>
  )
}
export default Detail_car
