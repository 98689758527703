import React, { useState, useEffect } from "react"
import { Container, Card, CardBody } from "reactstrap"
import { Tabs } from "antd"
import MetaTags from "react-meta-tags"
import { useHistory, useParams } from "react-router-dom"
import { MdKeyboardBackspace } from 'react-icons/md';
import Detail from './Detail'
import Edit from './Edit'
import axios from 'axios'
import * as api from '../../service/Api_Edit_device'
import helpers from '../Function_Redirect/Redirect_login'
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs

export default function  New_Employee(props) {
  const history = useHistory()
  const { t } = useTranslation();
  const token = localStorage.getItem('token')
  const [detail_device, setDetail_device] = useState({})

  let { sto_id, profile_id } = useParams();


useEffect(() => {
  check_stock()
  return () => {
    setDetail_device({})
  }
}, [sto_id])

function check_stock() {
  const check_Sto = api.CHECK_DETAIL_STOCK( token, sto_id )
  axios(check_Sto)
  .then( res => {
    if(res.data.data === undefined || res.data.data === null ){
      history.push({
        pathname: `/customers/${profile_id}/`,
        page_local: "6",
      })
    } else {
      setDetail_device(res.data.data)
    }
  })
  .catch( error => {
    if(error.response){
      if(error.response.status === 401){
        helpers.re_login()
        history.push('/login')          
  }
    }

  })
}

  return (
    <React.Fragment>
    <MetaTags>
    <title> {t("Edit device")} | Distar</title>
  </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}> <a><MdKeyboardBackspace style={{ marginRight:'15px', marginBottom:'3px' }}
          onClick={() =>
            history.push({
              pathname: `/customers/${profile_id}/`,
              // data: props.location.data.cus_id,
              page_local: "6",
            })
          } 
          /></a> {t("Edit device")} </h3>
          <Card>
            <CardBody>

              <Tabs defaultActiveKey="1">
                <TabPane tab={t("Detail")} key="1">
                  <Detail
                    detail_device={detail_device}
                  />
                </TabPane>
                <TabPane tab={t("Edit")} key="2">
                  <Edit 
                    detail_device={detail_device}
                    sto_id={sto_id}
                    setDetail_device={setDetail_device}
                  />
                </TabPane>
              </Tabs>

            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}