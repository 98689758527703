import React, { useState, useEffect } from "react"
import { Row, Col, Container, Card, CardBody } from "reactstrap"
import MetaTags from "react-meta-tags"
import { Button, Table, Tag, Pagination, Form, Input , Tooltip } from "antd"
import { useHistory } from "react-router-dom"
import helpers from "../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"
import * as api from '../../service/Api_Service'
import axios from 'axios'
import { FcDataSheet , FcFinePrint } from "react-icons/fc"
import { MdKeyboardBackspace } from "react-icons/md"

const Repair_History = props => {
  const { t } = useTranslation()
  const history = useHistory()
  const token = localStorage.getItem("token")
  const [data_repair, setData_repair] = useState([])
  const [loading, setLoading] = useState(false)
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })
  const [search_data_repair, setSearch_data_repair] = useState(null)
 
  useEffect(() => {
    check_repair()
    return () => {}
  }, [])

  const check_data = (params, data) => {
    if (data) {
      return params + data
    } else {
      return ""
    }
  }

  const onFinish = values => {
    check_repair()
  }

  function check_repair() {
    setLoading(true)
    let url = `api/v1/requestservice/admin/history?page=1&limit=${
      page_limit.page_Size
    }&order_by=DESC${check_data("&q=", search_data_repair)}`
    const list_repair = api.SEARCH_ALL(token, url)
    axios(list_repair)
      .then(res => {
        setLoading(false)
        setData_repair(res.data.data)
        setPage_limit({
          ...page_limit,
          page: 1,
          limit: res.data.total_data,
        })
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  function changePagination(page, pageSize) {
    setLoading(true)
    let url = `api/v1/requestservice/admin/history?page=${page}&limit=${pageSize}&order_by=DESC${check_data(
      "&q=",
      search_data_repair
    )}`
    const search = api.SEARCH_ALL(token, url)
    axios(search)
      .then(res => {
        setLoading(false)
        setData_repair(res.data.data)
        setPage_limit({
          ...page_limit,
          page: page,
          page_Size: pageSize,
          limit: res.data.total_data,
        })
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
        // console.log(error)
      })
  }

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T + "+7")
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return "N/A"
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  const columns = [
    {
      title: t("Repair number"),
      dataIndex: "rrs_no",
      key: "rrs_no",
      width : '160px',
      render: text => <p>{text}</p>,
    },
    {
      title: t("Created on"),
      dataIndex: "created_on",
      key: "created_on",
      align: "center",
      render: text => <p>{cutTimeZone(text)}</p>,
    },
    {
      title: t("Car license"),
      dataIndex: "car_license_number",
      key: "car_license_number",
      width : '100px',
      render: text => <p>{text}</p>,
    },
    {
      title: t("Customer name"),
      dataIndex: "cus_name",
      key: "cus_name",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Requested by"),
      render: text => <div>{render_columns_request_by(text)}</div>,
    },
    {
      title: t("position"),
      dataIndex: "request_by_role",
      key: "request_by_role",
      render: text => <p>{t(text)}</p>,
    },
    {
      title: t("Status"),
      dataIndex: "rrs_status_name",
      key: "rrs_status_name",
      align: "center",
      render: text => <div>{return_status_rrs(text)}</div>,
    },
    {
      title: t("remark"),
      dataIndex: "rrs_reject_detail",
      key: "rrs_reject_detail",
      align: "center",
      render: text => <p>{text}</p>,
    },
    {
      title: "",
      dataIndex: "rrs_id",
      key: "rrs_id",
      render: text => (
        <Tooltip title={t("View detail")} >
        <a>
          <FcFinePrint
            onClick={() => history.push(`/requested-repair/${text}`)}
            size={20}
          />
        </a>
        </Tooltip>
      ),
    },
  ]

  function render_columns_request_by(data) {
    switch (data.request_by_role) {
      case "customer":
        return <p>{data.request_by.cus_user_firstname}</p>
        break;
        case "dealer":
          return <p>{data.request_by.dea_user_firstname}</p>
          break;
      default:
        return <p>N/A</p>
        break;
    }
  }

  function return_status_rrs(status) {
    switch (status) {
      case "waiting for confirmation":
        return <Tag color="processing"> {t(status)} </Tag>
        break
      case "confirmed":
        return <Tag color="success"> {t(status)} </Tag>
        break
      case "reject":
        return <Tag color="error"> {t(status)} </Tag>
        break
      default:
        return <Tag color="processing"> {t(status)} </Tag>
        break
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Repair history")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/requested-repair")}
              />
            </a>{" "}
            {t("Repair history")}
          </h3>

          <Card>
            <CardBody>
            <Row>
                <Col>
                  <Form
                    name="Search_employee"
                    initialValues={{
                      remember: false,
                    }}
                    onFinish={onFinish}
                  >
                    <Row>
                      <Col sm={12} md={6} lg={6} xl={4}>
                        <Form.Item
                          name="search"
                          rules={[
                            {
                              required: false,
                              message: "Please input name,department",
                            },
                          ]}
                        >
                          <Input
                            placeholder={t("search")}
                            style={{ marginBottom: "15px" }}
                            onChange={e =>
                              setSearch_data_repair(e.target.value)
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col sm={12} md={6} lg={4} xl={4}>
                        <Button
                          type="primary"
                          className="button-search-employee"
                          htmlType="submit"
                        >
                          {t("search")}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Table
                    columns={columns}
                    dataSource={data_repair}
                    loading={loading}
                    rowKey="rrs_id"
                    scroll={{ x: 800 }}
                    pagination={false}
                  />
                     <div>
                    <Pagination
                      current={page_limit.page}
                      total={page_limit.limit}
                      onChange={changePagination}
                      style={{ marginTop: "20px", float: "right" }}
                    />
                    <p style={{ fontSize: "15px", marginTop: "20px" }}>
                      {" "}
                      <FcDataSheet
                        size={20}
                        style={{ marginBottom: "5px" }}
                      />{" "}
                      {t("total data")} : {page_limit.limit}
                    </p>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Repair_History
