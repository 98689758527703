import React from "react"
import { Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next"
import { Avatar } from 'antd'
import axios from 'axios'
import * as api from '../../../service/Api_Profile_Menu'
import { FaRegUser } from "react-icons/fa"
import Colum from '../admin/Colum'

export default function Detail_Profile({ imageProfile , detailUser , sizeImage , nameHeader }) {
    const { t } = useTranslation()
    const text_header = {
        fontWeight: "bold",
        fontSize: "15px",
    } 

  return (
    <div className="scroll-modal-display-mobile-app">
      <p
        style={{
          textAlign: "center",
          fontWeight: "bold",
          marginTop: "10px",
          fontSize: "15px",
        }}
      >
        {nameHeader}
      </p>
      <hr />

      <div>
        {
            imageProfile === null && (
                <Avatar size={sizeImage ? sizeImage : 80} icon={<FaRegUser style={{ marginBottom:'10px' }} />}   style={{ display: "block", margin: "auto" }} />
            )
        }
        {
            imageProfile !== null && (
                <Avatar size={sizeImage ? sizeImage : 80} src={imageProfile}   style={{ display: "block", margin: "auto" }} />
            )
        }
        </div>

      <br />
      <Row>
      {
        detailUser.map( (data , index) => {
          return (
            <Colum
            key={index}
            header_text={data.headerDetail}
            value_text={data.data}
            />
          )
        } )
      }
      </Row>

      {/* {detailUser.map((data, index) => {
        return (
          <Row key={index}>
            <Col sm={6} md={3} lg={3} xl={3}>
              <p style={text_header}> {data.headerDetail1} </p>
            </Col>
            <Col sm={6} md={3} lg={3} xl={3}>
              <p style={{ fontSize: "15px" }}>
                {" "}
                <span className="tet">
                  :
                </span> <span className="tetTwo">-</span> {data.data1}{" "}
              </p>
            </Col>
            <Col sm={6} md={3} lg={3} xl={3}>
              <p style={text_header}> {data.headerDetail2} </p>
            </Col>
            <Col sm={6} md={3} lg={3} xl={3}>
              <p style={{ fontSize: "15px" }}>
                {" "}
                <span className="tet">
                  :
                </span> <span className="tetTwo">-</span> {data.data2}{" "}
              </p>
            </Col>
          </Row>
        )
      })} */}
    </div>
  )
}
