import React from "react"
import { Row, Col } from "reactstrap"
import { Input } from "antd"
import { useTranslation } from "react-i18next";

const { Search } = Input

export default function Customer_Search(props) {
  const { t } = useTranslation();

    function onSearch(value) {
        props.search_data()
      }
    return (
        <Row>
        <Col sm={12} md={6} lg={6} xl={6}>
          <Search
            style={{ width: "100%" }}
            placeholder={t(`${t("Search")}`)}
            allowClear
            enterButton={t("Search")}
            size="medium"
            onSearch={onSearch}
            onChange={e => props.setSearch_data_customer(e.target.value)}
          />
        </Col>
      </Row>
    )
}
