import React from 'react'
import moment from 'moment';
import { Form, DatePicker } from "antd"
import '../Header_Filter.css'

const dateFormat = 'YYYY-MM-DD';

export default function Input_Date_Comp({ header,  keyState, placeholder ,nameState, headState }) {

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }
    
    function onChangeDateIn(date, dateString) {
    if(dateString){
      headState({ ...nameState, [keyState]: formatDate(dateString) })
    } else {
      headState({ ...nameState, [keyState]: null })
    }
    }

    return (
    <>
        <p className="text-bold header-filter">{header} </p>
        
        <DatePicker    
        format={dateFormat} 
        placeholder={placeholder}
        onChange={onChangeDateIn} 
        style={{ width:'100%' }} 
        />
    </>
    )
}
