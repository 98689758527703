import React from 'react'
import { Form, DatePicker } from "antd"
import { useTranslation } from "react-i18next";
const dateFormat = 'YYYY-MM-DD';

export default function Input_Date_Comp({ header,  keyState, placeholder, nameState, headState, disabled }) {
  const { t } = useTranslation();
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }
    
    function onChangeDateIn(date, dateString) {
        headState({ ...nameState, [keyState]: formatDate(dateString) })
    }

    function render_date(time) {
      const render_ = formatDate(time)
      if(disabled === true){
          return <p style={{ color:'#AAA9A9' }}>{render_} <span style={{ color:'#AAA9A9', fontSize:'12px' }}> ( {t("YY/MM/DD")} ) </span> </p>
      } else if(disabled === false){
          return <p style={{ color:'black' }}>{render_} <span style={{ color:'#AAA9A9', fontSize:'12px' }}> ( {t("YY/MM/DD")} ) </span> </p>
      }
    }

    return (
    <>
        <p className="text-bold">{header}</p>
{ render_date(placeholder) }
        <Form.Item
        name={keyState}
        rules={[
          {
            required: false,
            message: `N/A`,
          },
        ]}
      >
        <DatePicker    
            format={dateFormat} 
            onChange={onChangeDateIn} 
            style={{ width:'100%' }} 
            disabled={disabled}
            />
      </Form.Item>
    </>
    )
}
