import React from "react"
import { Row, Col } from "reactstrap"
import { Collapse, Card, Switch } from "antd"
import Col_Device from "./components/Col_Device"
import user_info from "../../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/user_info.png"
const { Panel } = Collapse
import { useTranslation } from "react-i18next";

export default function User_Info(props) {
  const { t } = useTranslation();
  const icons_info_header = {
    width: "25px",
    marginRight: "20px",
  }
  function check_handle_info(check) {
    switch (check) {
      case true:
        return false
        break
      case false:
        return true
        break
      default:
        break
    }
  }

  return (
    <div>
      <Card
        title={
          <p>
            {" "}
            <img
              src={user_info}
              alt="user_info"
              style={icons_info_header}
            />{" "}
            {t("User")}
          </p>
        }
      >
        {props.user && (
          <Collapse>
            {props.user.map((data, index) => {
              return (
                <Panel header={data.cus_user_firstname} key={index}>
                  <Row>
                    {data.cus_user_firstname && (
                      <Col_Device
                        text_header={t("User firstname")}
                        data={data.cus_user_firstname}
                      />
                    )}
                    {data.cus_user_lastname && (
                      <Col_Device
                        text_header={t("User lastname")}
                        data={data.cus_user_lastname}
                      />
                    )}
                    {data.cus_user_code && (
                      <Col_Device
                        text_header={t("User code")}
                        data={data.cus_user_code}
                      />
                    )}
                    {data.cus_user_phone && (
                      <Col_Device
                        text_header={t("User phone")}
                        data={data.cus_user_phone}
                      />
                    )}
                    {data.cus_user_email && (
                      <Col_Device
                        text_header={t("User email")}
                        data={data.cus_user_email}
                      />
                    )}

                    <Col sm={12} md={6} lg={6} xl={6}>
                      <p className="text-bold">
                        {t("Sending signup password by email")}
                        <Switch
                          style={{ marginLeft: "18px" }}
                          disabled
                          checked={data.cus_email_password_condition}
                        />
                      </p>
                    </Col>

                    <Col sm={12} md={6} lg={6} xl={6}>
                      <p className="text-bold"> {t("Receive notifications")} </p>
                      <p>
                        {t("Subscribe email notification")}
                        <Switch
                          disabled
                          style={{ marginLeft: "12px" }}
                          checked={check_handle_info(
                            data.not_send_email_notification
                          )}
                        />
                      </p>
                      <p>
                        {t("Subscribe line notification")}
                        <Switch
                          disabled
                          style={{ marginLeft: "25px" }}
                          checked={check_handle_info(
                            data.not_send_line_notification
                          )}
                        />
                      </p>
                    </Col>
                  </Row>
                </Panel>
              )
            })}
          </Collapse>
        )}
      </Card>
    </div>
  )
}
