import React, { useState, useEffect } from "react"
import { Container, Card, CardBody, Row , Col } from "reactstrap"
import { useHistory, useParams } from "react-router-dom"
import MetaTags from "react-meta-tags"
import { Tabs } from "antd"
import { MdKeyboardBackspace } from 'react-icons/md';
import Table_Data from './Table_Data'
import { useTranslation } from "react-i18next";

const Sub_Product = props => {
  const { t } = useTranslation();
    const history = useHistory()
  const token = localStorage.getItem('token')
  const [data_car, setData_car] = useState({})

  let { profile_id , car_id, pro_id } = useParams()

//   /cars/:car_id/sub-product/:pro_id
  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Sub product")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>
          <a>
            <MdKeyboardBackspace
              style={{ marginRight: "15px", marginBottom: "3px" }}
              onClick={() => history.push(`/customers/${profile_id}/view-car/${car_id}`)}
            />
          </a>
          {t("Sub product")} </h3>

        
          <Card>
          <CardBody>
            <Table_Data 
            product_id={parseInt(pro_id)}
            />
          </CardBody>
        </Card>


        </Container>
      </div>
    </React.Fragment>
  )
}
export default Sub_Product
