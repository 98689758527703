import React,{ useState, useEffect } from 'react'
import { Tabs, Button } from "antd";
import TextForm from './TextForm'

const { TabPane } = Tabs;

export default function index(props) {
    const [activeTab, setActiveTab] = useState("0")
    const [task_data, setTask_data] = useState([])

    const handleInputChange_Detail = (index_step,index_fields, event) => {
        const values = [...task_data]
        values[index_step].fields[index_fields].value = event.target.value
        setTask_data(values)
      }

        useEffect(() => {
            setTask_data(props.stepField)
            return () => {
                
            }
        }, [props.stepField])

    return (
    <>
        <Tabs defaultActiveKey="0"
        onTabClick={e => {
            setActiveTab(e)
        }}
        activeKey={activeTab}
        >
            {
            props.stepField.map( (data, index) => {
                return(
                    <TabPane tab={`Step ${index + 1}`} key={index}>
                        <TextForm 
                            index_step={index}
                            handleInputChange_Detail={handleInputChange_Detail}
                            fields={data.fields} 
                            priority={props.priority}
                        />
                        <hr />
                        {
                            index > 0 && index < props.stepField.length -1 && (
                                <div>
                                    <Button onClick={() => setActiveTab((index-1).toString())} style={{ float:'left', width:'80px' }} >Previous</Button>
                                    <Button onClick={() => setActiveTab((index+1).toString())} style={{ float:'right', width:'80px' }} >Next</Button>
                                </div>
                            )
                        }
                        {
                            index === 0 && (
                                <Button onClick={() => setActiveTab((index+1).toString())} style={{ float:'right', width:'80px' }} >Next </Button>
                            )
                        }
                        {
                            index === props.stepField.length -1 && (
                                <Button onClick={() => setActiveTab((index-1).toString())} style={{ float:'left', width:'80px' }} >Previous</Button>
                            )
                        }
   
                    </TabPane>
                    )
                })
            }
        </Tabs>
    </>
    )
}
