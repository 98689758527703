import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import axios from "axios"
import * as api from "../../service/Api_Preview_File"
import { Spin } from "antd"
import noFileUpload from "../../assets/images/noFileUpload.svg"
import {OutTable, ExcelRenderer} from 'react-excel-renderer';

var Ip = require("../../service/Ip")

export default function Excel() {
    const { fup_path } = useParams()
    const [haveFile, setHaveFile] = useState(false)
    const [isCheck, setIsCheck] = useState(false)
    const [excTest, setExcTest] = useState({
        cols: [],
        rows: []
      })
    // console.log(fup_path);

    useEffect(() => {
        const checkFile = api.CHECK_FILE(fup_path)
        axios(checkFile)
          .then(res => {
            const type = res.headers['content-type']
            const blob = new Blob([res.data], { type: type, encoding: 'UTF-8' })
            ExcelRenderer(blob, (err, resp) => {
              if(err){
                console.log(err);            
                // setHaveFile(false)
                // setIsCheck(true)
              }
              else{
                // console.log(resp);
                setExcTest({
                  ...excTest,
                  cols: resp.cols,
                  rows: resp.rows
                });
              }
            }); 
            setHaveFile(true)
            setIsCheck(true)
          })
          .catch(error => {
            setHaveFile(false)
            setIsCheck(true)
          })
        return () => {}
      }, [fup_path])
      
    return (
        <div>
        {isCheck === false && (
          <Spin
            size="large"
            style={{ display: "block", margin: "20px auto 20px auto" }}
          />
        )}
        {haveFile === true && isCheck === true && (
          <OutTable data={excTest.rows} columns={excTest.cols} tableClassName="ExcelTable2007" tableHeaderRowClass="heading" />
        )}
        {haveFile === false && isCheck === true && (
          <div>
            <img
              src={noFileUpload}
              alt="no file upload"
              style={{
                height: "250px",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "100px",
              }}
            />
            <p
              style={{ textAlign: "center", fontSize: "30px", marginTop: "20px" }}
            >
              No data file
            </p>
          </div>
        )}
      </div>
    )
}
