import React, { useState, useEffect } from "react"
import { Container, Card, CardBody, Row, Col } from "reactstrap"
import MetaTags from "react-meta-tags"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import { MdKeyboardBackspace } from "react-icons/md"
import { Tabs } from "antd"
import axios from "axios"
import * as api from "../../service/Api_Admin"
import helpers from "../Function_Redirect/Redirect_login"
import Overview from "./Overview"
import Edit from "./Edit"
import Setting from "./Setting"
import ChangePasswordByAdmin from '../components/Profile/ChangePasswordByAdmin'
import Button_ResetPassword from '../components/admin/Button_ResetPassword'

const { TabPane } = Tabs

const Edit_Admin = props => {
  const token = localStorage.getItem("token")
  const { t } = useTranslation()
  const { admin_id } = useParams()
  const history = useHistory()
  const [detail_admin, setDetail_admin] = useState(null)

  useEffect(() => {
    find_one_admin()
    return () => {}
  }, [])

  function find_one_admin() {
    const find_admin = api.FIND_ONE_ADMIN(token, parseInt(admin_id))
    axios(find_admin)
      .then(res => {
        setDetail_admin(res.data.message)
      })
      .catch(error => {
        if (error) {
          if (error.response) {
            if (error.response.status === 404) {
              history.push("/admin-management")
            } else if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Edit admin")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/admin-management")}
              />
            </a>{" "}
            {t("Admin group")}
          </h3>

          <Card>
            <CardBody>
              <Tabs defaultActiveKey="1">
                <TabPane tab={t("Overview")} key="1">
                  <Overview detail_admin={detail_admin} />
                </TabPane>
                <TabPane tab={t("Edit")} key="2">
                  <Edit
                    detail_admin={detail_admin}
                    admin_id={parseInt(admin_id)}
                    find_one_admin={find_one_admin}
                  />
                </TabPane>
                <TabPane tab={t("Setting")} key="3">
                  <Button_ResetPassword
                    roleReset="administrator"
                    idReset={parseInt(admin_id)}
                  />
                  <ChangePasswordByAdmin 
                    idIsChange={parseInt(admin_id)}
                    roleIsChange="administrator"
                  />
                  <Setting admin_id={parseInt(admin_id)} />
                </TabPane>
              </Tabs>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Edit_Admin
