import React from 'react'
import { Tabs } from "antd";
import TextForm from './TextForm'

const { TabPane } = Tabs;

export default function index(props) {

return (
<>
    <Tabs defaultActiveKey="1">
        {
        props.stepField.map( (data, index) => {
            return(
                <TabPane tab={`Step ${index + 1}`} key={index}>
                    <TextForm fields={data.fields} priority={props.priority}/>
                </TabPane>
                )
            })
        }
    </Tabs>
</>
)
}
