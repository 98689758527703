import React, { useEffect, useState } from "react"
import { Container, Card, CardBody } from "reactstrap"
import { useHistory, useParams } from "react-router-dom"
import MetaTags from "react-meta-tags"
import { MdKeyboardBackspace } from "react-icons/md"
import { Tabs } from "antd"
import Setting from "./Setting"
import axios from "axios"
import * as api from "../../service/Api_New_Customer"
import Detail from "./Detail"
import Edit_User from "./Edit_User"
import helpers from "../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"
import ChangePasswordByAdmin from "../components/Profile/ChangePasswordByAdmin"
import { useSelector } from "react-redux"
import Button_ResetPassword from '../components/admin/Button_ResetPassword'

const { TabPane } = Tabs

export default function Edit_user(props) {
  const history = useHistory()
  const { t } = useTranslation()
  const [data_user, setData_user] = useState({})
  const token = localStorage.getItem("token")
  const [activeTab_all_Customer, setActiveTab_all_Customer] = useState("1")
  const { isRole , isAdminRoot } = useSelector(({ Distar }) => Distar)
  let { profile_id, user_id } = useParams()

  useEffect(() => {
    check_detail_user(parseInt(user_id))
    return () => {}
  }, [user_id])

  useEffect(() => {
    return () => {
      setData_user({})
    }
  }, [])

  function check_detail_user(user_id) {
    const check_user = api.CHECK_DETAIL_USER(token, user_id)
    axios(check_user)
      .then(res => {
        if (res.data.data[0] === undefined) {
          history.push({
            pathname: `/customers/${profile_id}/`,
            // data: props.location.data.cus_id,
            page_local: "4",
          })
        } else {
          setData_user(res.data.data[0])
        }
      })
      .catch(error => {
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("edit user")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() =>
                  history.push({
                    pathname: `/customers/${profile_id}/`,
                    // data: props.location.data.cus_id,
                    page_local: "4",
                  })
                }
              />
            </a>{" "}
            {t("edit user")}
          </h3>
          <Card>
            <CardBody>
              <Tabs
                defaultActiveKey="1"
                onTabClick={e => {
                  setActiveTab_all_Customer(e)
                }}
                activeKey={activeTab_all_Customer}
              >
                <TabPane tab={t("detail")} key="1">
                  <Detail data_user={data_user} />
                </TabPane>
                <TabPane tab={t("edit")} key="3">
                  <Edit_User
                    data_user={data_user}
                    user_id={parseInt(user_id)}
                    check_detail_user={check_detail_user}
                  />
                </TabPane>
                  <TabPane tab={t("setting")} key="4">
                  {
                        isAdminRoot === true && isRole === "administrator" && (
                          <Button_ResetPassword
                          roleReset="customer_user" 
                          idReset={parseInt(user_id)}
                          />
                        )
                      }
                    {
                      isRole === "administrator" && (
                        <ChangePasswordByAdmin
                        idIsChange={parseInt(user_id)}
                        roleIsChange="customer_user"
                      />
                      )
                    }
                    <Setting
                      user_id={parseInt(user_id)}
                      cus_id={parseInt(profile_id)}
                    />
                  </TabPane>
              </Tabs>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
