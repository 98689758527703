import React, { useState, useEffect } from "react"
import { Container, Card, CardBody } from "reactstrap"
import MetaTags from "react-meta-tags"
import { useHistory, useParams } from "react-router-dom"
import { Tabs } from "antd"
import { MdKeyboardBackspace } from "react-icons/md"
import Detail_Contract from "./Detail_Contract"
import Edit_Contract from "./Edit_Contract"
import * as api from "../../service/Api_Edit_Contract"
import axios from "axios"
import Device from "./Device"
import Service from "./Service"
import Setting from "./Setting"
import { useTranslation } from "react-i18next"

const { TabPane } = Tabs

export default function Edit_A_car(props) {
  const token = localStorage.getItem("token")
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState("1")
  const [detail_Contract, setDetail_Contract] = useState({})
  const history = useHistory()

  let { profile_id, con_id } = useParams()

  // console.log('con_id = '+con_id);
  // console.log('cus_id = '+profile_id);

  useEffect(() => {
    check_contract_detail()
    if (props.location.page_local) {
      setActiveTab(props.location.page_local)
    }
    return () => {
      setDetail_Contract({})
    }
  }, [con_id])

  function check_contract_detail() {
    const check = api.CHECK_CONTRACT(token, con_id)
    axios(check)
      .then(res => {
        if (res.data.data[0] === undefined) {
          history.push({
            pathname: `/customers/${profile_id}`,
            page_local: "7",
          })
        } else {
          setDetail_Contract(res.data.data[0])
        }
      })
      .catch(error => {
        if (error) {
          if (error.response.status === 401) {
            helpers.re_login()
            history.push("/login")
          }
        }
      })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Edit contract")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() =>
                  history.push({
                    pathname: `/customers/${profile_id}`,
                    // data: props.location.data.cus_id,
                    page_local: "7",
                  })
                }
              />
            </a>{" "}
            {t("Edit contract")}{" "}
          </h3>

          <Card>
            <CardBody>
              <Tabs
                defaultActiveKey="1"
                onTabClick={e => {
                  setActiveTab(e)
                }}
                activeKey={activeTab}
              >
                <TabPane tab={t("Overview")} key="1">
                  <Detail_Contract detail_Contract={detail_Contract} />
                </TabPane>
                <TabPane tab={t("Device")} key="2">
                  <Device con_id={con_id} />
                </TabPane>
                <TabPane tab={t("Edit")} key="4">
                  <Edit_Contract
                    detail_Contract={detail_Contract}
                    setDetail_Contract={setDetail_Contract}
                    cus_id={parseInt(profile_id)}
                  />
                </TabPane>
                <TabPane tab={t("Setting")} key="5">
                  <Setting con_id={con_id} cus_id={parseInt(profile_id)} />
                </TabPane>
              </Tabs>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
