import React from "react"
import { Container } from "reactstrap"
import { useLocation, useHistory } from "react-router-dom"
import ProjectsOverview from "./components/ProjectsOverview"
import Table_record from "./Table_record"
import Report_page from "./Report_page"
import { Tabs } from "antd"
import MetaTags from "react-meta-tags"

const { TabPane } = Tabs

const Detail_project = props => {
  let location = useLocation()
  let history = useHistory()

  if (!location.state) {
    history.push("/project")
  }
  return (
    <React.Fragment>
      <MetaTags>
        <title> Project infomation | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Overview" key="1">
              <ProjectsOverview detail={location.state} />
            </TabPane>
            <TabPane tab="Create Job" key="2">
              <Report_page />
            </TabPane>
            <TabPane tab="Job record" key="3">
              <Table_record />
            </TabPane>
            <TabPane tab="Create form" key="4">
              create form
            </TabPane>
            <TabPane tab="Setting" key="5">
              Setting
            </TabPane>
          </Tabs>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Detail_project
