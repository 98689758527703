import React, { useState, useEffect } from "react"
import { Tabs, Pagination } from "antd"
import Table_Car from "./Table_Car"
import axios from "axios"
import * as api from "../../../../service/Api_New_Customer"
import Data_car_search from "./Table_Car/Data_car_search"
import Create_new_car from "./Create_new_car"
import { FcDataSheet } from "react-icons/fc"
import { useHistory } from "react-router-dom"
import helpers from "../../../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs

export default function Cars(props) {
  const { t } = useTranslation();
  const [activeTab_all, setActiveTab_all] = useState("1")
  const history = useHistory()
  const [data_car_search, setData_car_search] = useState(null)
  const token = localStorage.getItem("token")
  const [data_list, setData_list] = useState([])
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    search_car()
    return () => {
      setData_list([])
    }
  }, [])

  function search_car() {
    setLoading(true)
    if (data_car_search) {
      const list_data_car_data = api.LIST_CAR_IN_CUSTOMER_DATA(
        token,
        props.id,
        1,
        page_limit.page_Size,
        data_car_search
      )
      axios(list_data_car_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else {
      const list_data_car_no_data = api.LIST_CAR_IN_CUSTOMER_NO_DATA(
        token,
        props.id,
        1,
        page_limit.page_Size
      )
      axios(list_data_car_no_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  function changePagination_Car(page, pageSize) {
    setLoading(true)
    if (data_car_search) {
      const list_data_car_data = api.LIST_CAR_IN_CUSTOMER_DATA(
        token,
        props.id,
        page,
        pageSize,
        data_car_search
      )
      axios(list_data_car_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else {
      const list_data_car_no_data = api.LIST_CAR_IN_CUSTOMER_DATA(
        token,
        props.id,
        page,
        pageSize
      )
      axios(list_data_car_no_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  return (
    <div>
      <Data_car_search
        search_car={search_car}
        setData_car_search={setData_car_search}
      />
      <Table_Car data_list={data_list} id={props.id} loading={loading} />

      <div>
        <Pagination
          current={page_limit.page}
          total={page_limit.limit}
          onChange={changePagination_Car}
          style={{ marginTop: "20px", float: "right" }}
        />
      </div>
      <p style={{ fontSize: "15px", marginTop: "20px" }}>
        {" "}
        <FcDataSheet size={20} style={{ marginBottom: "5px" }} /> {t("total data")} :{" "}
        {page_limit.limit}
      </p>
    </div>
  )
}
