import React, { useEffect, useState } from "react"
import { Row, Col } from "reactstrap"
import Status_Service from "./components/Status_Service"
import Customer from "./Customer"
import Service_Info from "./Service_Info"
import Show_Gps_Car from "./Show_Gps_Car"
import Console_Service from "./Console_Service"
import Activity_Log from "./Activity_Log"
import Control_Service from "./Control_Service"
import Render_Task_After_Operator_Sending from "./Render_Task_After_Operator_Sending"
import Show_Location_Service from "./Show_Location_Service"
import StatusCustomerService from "./StatusCustomerService"
import Detail_SLA from './Detail_SLA'
import Car_Information from '../../Status_Service_Public/Car_Information'
import RenderFileUpload from './RenderFileUpload'

export default function Detail_Service(props) {
  const permi = localStorage.getItem("permi")
  const [permi_t_edit, setPermi_t_edit] = useState(false)

  useEffect(() => {
    if (permi) {
      if (JSON.parse(permi).service) {
        setPermi_t_edit(
          JSON.parse(permi).service.edit
            ? JSON.parse(permi).service.edit
            : false
        )
      }
    }
    return () => {}
  }, [permi])

  return (
    <div>
      <Row>
        <Col xs={0} sm={0} md={4} lg={4} xl={4}></Col>
        {
          props.data_service !== undefined && (
            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <p
              style={{
                fontWeight: "bold",
                fontSize: "30px",
                textAlign: "center",
              }}
            >
              {props.data_service.ser_no}{" "}
            </p>
          </Col>
          )
        }

        {permi_t_edit === true && (
          <Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <Control_Service
              check_activity_log={props.check_activity_log}
              list_service_overview={props.list_service_overview}
              check_new_data_edit={props.check_new_data_edit}
              service_status_name={props.service_status_name}
              ser_id={props.ser_id}
            />
          </Col>
        )}
      </Row>

      <Status_Service data_service={props.data_service} />

      <StatusCustomerService data_service={props.data_service} />

      {props.service_status_name === "pending" && (
        <Render_Task_After_Operator_Sending ser_id={props.ser_id} />
      )}

      {props.service_status_name === "complete" && (
        <Render_Task_After_Operator_Sending ser_id={props.ser_id} />
      )}

      <Show_Gps_Car
        data_service={props.data_service}
        data_location={props.data_location}
      />

      <br />
      <Show_Location_Service service_location={props.service_location} />
      <br />
      <Activity_Log activity_all={props.activity_all} />
      <br />
      {props.data_service.ser_status_name === "unassigned" && (
        <Console_Service
          check_activity_log={props.check_activity_log}
          data_service={props.data_service}
          check_new_data_edit={props.check_new_data_edit}
          list_service_overview={props.list_service_overview}
        />
      )}
      <br />
      <Customer data_service={props.data_service} />
      <br />
      <Service_Info data_service={props.data_service} />
      <br />
      <Detail_SLA data_service={props.data_service} />
        <br />
        <Car_Information data_all={props.data_service} />
        <br />
        {
          props.fileUpload.length !== 0 && (
            <RenderFileUpload fileUpload={props.fileUpload} />
          )
        }
    </div>
  )
}
