import React, { useState, useEffect } from "react"
import { Table, Modal, Tag, Tooltip } from "antd"
import { useHistory } from "react-router-dom"
import { AiOutlineEye } from "react-icons/ai"
import { FiEdit2 } from "react-icons/fi"
import Detail_Stock_modal from "./Detail_Stock_modal"
import { FcSearch, FcSettings } from "react-icons/fc"
import Render_Image from "../New_Customer/Devices/Render_Image"
import { useTranslation } from "react-i18next"
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons"

import car from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/car.png"
import ambulance from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/ambulance.png"
import vespa from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/vespa.png"
import bus from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/bus.png"
import bicycle from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/bicycle.png"
import van from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/van.png"
import truck from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/truck.png"
import taxi from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/taxi.png"
import pickup from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/pickup.png"
import motorboat from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/motorboat.png"
import motor from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/motor.png"
import no_car from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_car.png"

export default function Data_Table_Car(props) {
  const { t } = useTranslation()
  const history = useHistory()
  const [visible, setVisible] = useState(false)
  const [data_stock, setData_Stock] = useState({})

  const columns = [
    {
      title: t("Product group"),
      dataIndex: "pro_group_name",
      key: "pro_group_name",
      render: text => checkData(text),
    },
    {
      title: "",
      dataIndex: "pro_model_img",
      key: "pro_model_img",
      render: text => <Render_Image path_image={text} size={60} />,
    },
    {
      title: t("Product model"),
      dataIndex: "pro_model_name",
      key: "pro_model_name",
      width: "150px",
      render: text => checkData(text),
    },
    {
      title: t("Product serial number"),
      dataIndex: "pro_serial_number",
      key: "pro_serial_number",
      render: text => checkData(text),
      width: "170px",
    },
    {
      title: t("Customer"),
      dataIndex: "",
      key: "",
      width: "150px",
      render: text => <div style={{ marginTop:'12px' }}>{render_customer(text)}</div>,
    },
    {
      title: t("Dealer"),
      dataIndex: "",
      key: "",
      width: "150px",
      render: text => <div style={{ marginTop:'12px' }}>{render_dealer(text)}</div>,
    },
    {
      title: t("Car license place"),
      dataIndex: "car_license_number",
      key: "car_license_number",
      render: text => checkData(text),
      width: "150px",
    },
    {
      title: t("Car type"),
      dataIndex: "car_body_type_name",
      key: "car_body_type_name",
      render: text => (
        <Tooltip title={text ? text : t("Invalid car type")}>
          <img
            src={render_Icon_Car(text)}
            alt="icon"
            style={{ width: "50px" }}
          />
        </Tooltip>
      ),
    },
    {
      title: t("Stock asset number"),
      dataIndex: "sto_asset_number",
      key: "sto_asset_number",
      width: "150px",
      render: text => checkData(text),
    },
    {
      title: t("Stock date install"),
      dataIndex: "sto_date_install",
      key: "sto_date_install",
      render: text => cutTimeZone(text),
    },
    {
      title: t("Contract start date"),
      dataIndex: "con_start_date",
      key: "con_start_date",
      render: text => cutTimeZone(text),
    },
    {
      title: t("Contract end date"),
      dataIndex: "con_end_date",
      key: "con_end_date",
      render: text => cutTimeZone(text),
    },
    {
      title: t("Status contract"),
      dataIndex: "con_expired",
      key: "con_expired",
      render: text => cutStatus(text),
    },
    {
      title: t("Warranty"),
      dataIndex: "warranty_name",
      key: "warranty_name",
      render: text => checkData(text),
    },
    {
      title: "Stock remark",
      dataIndex: "sto_remark",
      key: "sto_remark",
      render: text => <p>{text}</p>,
      width: "300px",
    },
    {
      title: "Product remark",
      dataIndex: "pro_remark",
      key: "pro_remark",
      render: text => <p>{text}</p>,
    },
    {
      title: "",
      fixed: 'right',
      width: "50px",
      render: text => (
        <Tooltip title={t("View stock")}>
          <a>
            <FcSearch
              onClick={() => {
                setVisible(true)
                setData_Stock(text)
              }}
              size={20}
            />
          </a>
        </Tooltip>
      ),
    },
    {
      title: "",
      fixed: 'right',
      width: "50px",
      render: text => (
        <Tooltip title={t("Edit stock")}>
          <a>
            <FcSettings
              onClick={() => history.push(`/stock/${text.sto_id}`)}
              size={20}
            />
          </a>
        </Tooltip>
      ),
    },
  ]

  function cutStatus(status) {
    if (status === true) {
      return (
        <Tag icon={<CloseCircleOutlined />} color="error">
          {t("Expired")}
        </Tag>
      )
    } else {
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          {t("Available")}
        </Tag>
      )
    }
  }

  function render_customer(text) {
    if (text.cus_id) {
      return (
        <p>
          {checkData(text.cus_name)}
          <Tooltip title={`${t("View")} ${text.cus_name}`}>
            <a>
              <FcSearch
                onClick={() => history.push(`/customers/${text.cus_id}`)}
                size={20}
              />
            </a>
          </Tooltip>
        </p>
      )
    } else {
      return <p>N/A</p>
    }
  }

  function render_dealer(text) {
    if (text.dea_id) {
      return (
        <p>
          {checkData(text.dea_name)}
          <Tooltip title={`${t("View")} ${text.dea_name}`}>
            <a>
              <FcSearch
                onClick={() => history.push(`/dealer/${text.dea_id}`)}
                size={20}
              />
            </a>
          </Tooltip>
        </p>
      )
    } else {
      return <p>N/A</p>
    }
  }

  function render_Icon_Car(type_car) {
    switch (type_car) {
      case "car":
        return car
        break
      case "vespa":
        return vespa
        break
      case "van":
        return van
        break
      case "truck":
        return truck
        break
      case "taxi":
        return taxi
        break
      case "bus":
        return bus
        break
      case "pickup":
        return pickup
        break
      case "motorboat":
        return motorboat
        break
      case "motor":
        return motor
        break
      case "bicycle":
        return bicycle
        break
      case "ambulance":
        return ambulance
        break
      default:
        return no_car
        break
    }
  }

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return "N/A"
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  function checkData(data) {
    if (data) {
      return data
    } else {
      return "N/A"
    }
  }

  return (
    <div>
      <Table
        loading={props.loading}
        columns={columns}
        dataSource={props.data_list}
        pagination={false}
        rowKey="sto_id"
        scroll={{ x: 2500 }}
      />

      <Modal
        title={false}
        centered
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={() => {
          setVisible(false)
          setData_Stock({})
        }}
        footer={false}
        // height={500}
        width={1000}
      >
        <Detail_Stock_modal data_stock={data_stock} />
      </Modal>
    </div>
  )
}
