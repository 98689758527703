var Ip = require('../service/Ip')

export const LIST_SUB_PRODUCT_ID = (token, product_id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/stock/product/is-product-has-sup-product/${product_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_PRODUCT_MODEL = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/stock/product-group`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_WARRANTY = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/product/warranty`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CREATE_NEW_SUB_PRODUCT = (token , pro_id , data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/stock/pair-sub-product/${pro_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const FIND_PRODUCT_MODEL = (token , product_group ) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/stock/find-model-in-group/${product_group}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const REMOVE_SUB_PRODUCT = (token , pro_id ) => {
    return {
      method: 'delete',
      url: `${Ip.getIP()}api/v1/stock/product/${pro_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const EDIT_SUB_PRODUCT = (token , pro_id, data ) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/stock/product/${pro_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };