import React, { useState, useEffect } from "react"
import { Container, Card, CardBody, Row, Col } from "reactstrap"
import MetaTags from "react-meta-tags"
import { useHistory } from "react-router-dom"
import { MdKeyboardBackspace } from "react-icons/md"
import { Tabs, Button, Modal, notification } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import Old_DB_Distar from "./Old_DB_Distar"
import New_DB from "./New_DB"
import axios from "axios"
import * as api from "../../service/Api_Create_new_dealer"
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs
const { confirm } = Modal

const Select_Dealer_From_DB_Distar = props => {
  const history = useHistory()
  const { t } = useTranslation();
  const token = localStorage.getItem("token")
  const [detail_dealer, setDetail_dealer] = useState([])
  const [loading, setLoading] = useState(false)
  const [data_edit_dealer, setData_edit_dealer] = useState([])

  useEffect(() => {
    if (!props.location.data_dealer) {
      history.push("/import-data/dealer")
    } else {
      setDetail_dealer(props.location.data_dealer)
      let data_all = []
      props.location.data_dealer.map(data => {
        data_all.push({
          dea_address: data.addr,
          dea_code: data.gid,
          dea_name: data.nickname,
          dea_phone: data.phone,
          dea_email: data.email,
          dea_contact_email: null,
          dea_contact_firstname: null,
          dea_contact_lastname: null,
          dea_contact_mobile: null,
          dea_credit: null,
          dea_fax: null,
          dea_pos_id: null,
          dea_taxid: null,
          // zip_id: null
        })
      })
      setData_edit_dealer(data_all)
    }
    return () => {
      setDetail_dealer([])
      setData_edit_dealer([])
    }
  }, [props.location.data_dealer])

  function save_select_dealer() {
    return confirm({
      title: t("Confirm import dealer"),
      icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
      centered: true,
      okText: t("Confirm"),
      cancelText: t("no"),

      onOk() {
        setLoading(true)
        data_edit_dealer.map(async data => {
          await send_data(data)
        })

        setTimeout(() => {
          history.push(`/import-data/dealer`)
        }, 2000)
      },
      onCancel() {
        setLoading(false)
      },
    })
  }

  const send_data = async data => {
    const data_new_info = {
      ...data,
      is_import: true,
    }
    const create_new_dealer = api.CREATE_DEALER(token, data_new_info)
    const fetcher = await axios(create_new_dealer)
    if (fetcher.status === 201) {
      openNotificationWithIcon("success", t("Import dealer success"))
    } else {
      openNotificationWithIcon("error", t("Import dealer error"))
    }
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Select dealer")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/import-data/dealer")}
              />
            </a>{" "}
            {t("Select dealer")}
          </h3>
          <br />
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <Button
                    type="primary"
                    style={{ float: "right" }}
                    onClick={() => save_select_dealer()}
                    loading={loading}
                  >
                    {t("Confirm dealer all")}
                  </Button>
                </Col>
              </Row>
              <br />
              <Tabs defaultActiveKey="0">
                {detail_dealer.map((data, index) => {
                  return (
                    <TabPane tab={data.nickname} key={index}>
                      <Old_DB_Distar data={data} />
                      <New_DB
                        // detail_dealer={detail_dealer}
                        setData_edit_dealer={setData_edit_dealer}
                        data_edit_dealer={data_edit_dealer}
                        data={data}
                        index_state={index}
                      />
                    </TabPane>
                  )
                })}
              </Tabs>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Select_Dealer_From_DB_Distar
