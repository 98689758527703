import React, { useRef, useState, useEffect } from "react"
import { Form, Button, notification, Modal, Avatar } from "antd"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import * as api from "../../service/Api_Profile_Menu"
import axios from "axios"
import { UserOutlined } from "@ant-design/icons"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import Input_Comp from "../components/admin/Input_Comp"
import { Row, Col } from "reactstrap"
import { MdKeyboardBackspace } from "react-icons/md"
import helpers from "../Function_Redirect/Redirect_login"

const { confirm } = Modal


export default function EditDetail({ img , data_user , get_employee_info }) {
    const { t } = useTranslation()
    const token = localStorage.getItem("token")
    const history = useHistory()
    const [form] = Form.useForm()
    const imageUploader = useRef(null)
    const [edit, setEdit] = useState(true)
    const [loading, setLoading] = useState(false)
    const [
      image_preview_before_upload,
      setImage_preview_before_upload,
    ] = useState(null)
    const [image_select_upload, setImage_select_upload] = useState(null)
    const [employee_Edit, setEmployee_Edit] = useState({
        emp_firstname: null ,
        emp_lastname : null ,
        emp_code : null ,
    })

    useEffect(() => {
        if (img !== null) {
            setImage_preview_before_upload(img)
          }
        return () => {
            
        }
    }, [img])

    useEffect(() => {
        onFill()
        return () => {
            
        }
    }, [data_user])

    const onFill = () => {
        setEmployee_Edit({
            emp_firstname: data_user.emp_firstname ,
            emp_lastname : data_user.emp_lastname,
            emp_code : data_user.emp_code
        })
        form.setFieldsValue({
            emp_firstname: data_user.emp_firstname ,
            emp_lastname : data_user.emp_lastname,
            emp_code : data_user.emp_code
        })
      }

    const onFinish = value => {
        return confirm({
            title: t("Confirm edit profile"),
            icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
            centered: true,
            okText: t("Confirm"),
            cancelText: t("no"),
            onOk() {
                setLoading(true)
                if (image_select_upload !== null) {
                  const formData = new FormData()
                  formData.append("file", image_select_upload)
                  const upload_image = api.UPLOAD_PROFILE(token, formData)
                  axios(upload_image)
                    .then(res => {
                      let add_image_path = {
                        emp_avatar: res.data.path,
                        ...employee_Edit,
                      }
                      edit_employee_info(add_image_path)
                    })
                    .catch(error => {
                      setLoading(false)
                      if (error) {
                        if (error.response) {
                          if (error.response.status === 401) {
                            helpers.re_login()
                            history.push("/login")
                          } else {
                            openNotificationWithIcon(
                              "error",
                              t("edit profile error")
                            )
                          }
                        }
                      }
                    })
                } else {
                    edit_employee_info(employee_Edit)
                }
            },
            onCancel() {},
          })
    }

    function edit_employee_info(data) {
        const edit_employee = api.EDIT_EMPLOYEE(token, data_user.emp_id, data)
        axios(edit_employee)
          .then(res => {
            Cancel_Edit()
            setLoading(false)
            openNotificationWithIcon("success", t("edit profile success"))
            get_employee_info()
          })
          .catch(error => {
            setLoading(false)
            if (error) {
              if (error.response) {
                if (error.response.status === 401) {
                  helpers.re_login()
                  history.push("/login")
                } else {
                    openNotificationWithIcon(
                        "error",
                        t("edit profile error")
                      )
                }
              }
            }
          })
      }

    function Cancel_Edit() {
        setEdit(!edit)
        onFill()
        setImage_preview_before_upload(img ? img : null)
        setImage_select_upload(null)
    }

    const openNotificationWithIcon = (type, description) => {
        notification[type]({
          message: description,
        })
    }

    const handleImageUpload = e => {
        if (e.target.files[0] !== undefined) {
          if (e.target.files[0].type !== "image/jpeg") {
            openNotificationWithIcon(
              "error",
              t("please upload jpg or jpeg image only")
            )
          } else {
            let img_upload = e.target.files[0]
            setImage_preview_before_upload(URL.createObjectURL(img_upload))
            setImage_select_upload(img_upload)
          }
        }
      }
    
    return (
        <div>
            <Form form={form} name="edit-employee" onFinish={onFinish}>
            <Row>
          <Col>
            <div style={{ display: "inherit" }}>
              <p
                className="text-bold"
                style={{
                  color: "#495057",
                  textAlign: "center",
                  fontSize: "15px",
                }}
              >
                {t("Employee information")}
              </p>
              {edit === true && (
                <Button
                  style={{ float: "right", width: "150px" }}
                  onClick={() => setEdit(!edit)}
                >
                  {t("Edit")}
                </Button>
              )}
              {edit === false && (
                <Button
                  type="primary"
                  style={{ float: "right", width: "150px" }}
                  onClick={() => Cancel_Edit()}
                >
                  {t("Cancel")}
                </Button>
              )}
            </div>
          </Col>
        </Row>
        <br />
        <div
          className="image-upload"
          style={{ marginTop: "10px", marginBottom: "30px" }}
        >
          {image_preview_before_upload === null && (
            <Avatar
              icon={<UserOutlined />}
              size={100}
              style={{ display: "block", margin: "auto" }}
            />
          )}

          {image_preview_before_upload !== null && (
            <Avatar
              src={image_preview_before_upload}
              size={100}
              style={{ display: "block", margin: "auto" }}
            />
          )}
        </div>

        <input
          type="file"
          accept="image/jpeg"
          onChange={handleImageUpload}
          ref={imageUploader}
          style={{
            display: "none",
          }}
        />

        {edit === false && (
          <div>
            <Button
              type="primary"
              style={{ display: "block", margin: "10px auto 10px auto" }}
              onClick={() => imageUploader.current.click()}
            >
              {t("Upload photo")}
            </Button>
            <p style={{ color: "red", textAlign: "center" }}>
              {" "}
              {t("please upload jpg or jpeg image only")}{" "}
            </p>
          </div>
        )}

        <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("firstname")}
              placeholder={data_user.emp_firstname}
              headState={setEmployee_Edit}
              nameState={employee_Edit}
              disabled={edit}
              keyState="emp_firstname"
              type="text"
            />
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("lastname")}
              placeholder={data_user.emp_lastname}
              headState={setEmployee_Edit}
              nameState={employee_Edit}
              disabled={edit}
              keyState="emp_lastname"
              type="text"
            />
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("code")}
              placeholder={data_user.emp_code}
              headState={setEmployee_Edit}
              nameState={employee_Edit}
              disabled={edit}
              keyState="emp_code"
              type="text"
            />
            </Col>
        </Row>

        <br />
        {edit === false && (
          <Button
            type="primary"
            htmlType="submit"
            style={{ display: "block", margin: "auto", width: "150px" }}
            loading={loading}
          >
            {t("Edit")}
          </Button>
        )}
            </Form>
        </div>
    )
}
