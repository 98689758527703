import React from "react"
import { Row, Col } from "reactstrap"
import { Button, Input, Form } from "antd"
import { useTranslation } from "react-i18next";

export default function Search_car(props) {
  const { t } = useTranslation();
    const onFinish = values => {
      props.search_car_all()
    }

    return (
        <>
        <Form
          name="Search_car_info"
          initialValues={{
            remember: false,
          }}
          onFinish={onFinish}
        >
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <Form.Item
                name="search"
                rules={[
                  {
                    required: false,
                    message: "Please input name,department",
                  },
                ]}
              >
              <Input
                  placeholder="title, lpn"
                  onChange={e => props.setSearch_car(e.target.value)}
              /> 
              </Form.Item>
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
              <Button
                type="primary"
                className="button-search-employee"
                htmlType="submit"
              >
                {t("Search")}
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    )
}
