import React, { useState, useEffect } from "react"
import axios from "axios"
import { Card, CardBody, Row, Col } from "reactstrap"
import * as api from "../../../service/service_operator/Api_Dashboard"
import { useTranslation } from "react-i18next"
import Card_Dashboard from "./components/Card_Dashboard"
import { useHistory } from "react-router-dom";
import helpers from '../../Function_Redirect/Redirect_login'

export default function Status_Service_All(props) {
  const { t } = useTranslation()
  const token = localStorage.getItem("token")
  const [assigned, setAssigned] = useState(0)
  const history = useHistory()
  const [cancel, setCancel] = useState(0)
  const [complete, setComplete] = useState(0)
  const [inprogress, setInprogress] = useState(0)
  const [overdue, setOverdue] = useState(0)
  const [pending, setPending] = useState(0)
  const [pending_for_approval_admin, setPending_for_approval_admin] = useState(
    0
  )
  const [
    pending_for_approval_manager,
    setPending_for_approval_manager,
  ] = useState(0)

  const Header_text = {
    fontSize: "20px",
    marginBottom: "0px",
    color: "#A5A5A5",
  }

  const style_card = {
    borderRadius: "0px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  }

  useEffect(() => {
    check_dashboard()
    return () => {
      setAssigned(0)
      setCancel(0)
      setComplete(0)
      setInprogress(0)
      setOverdue(0)
      setPending(0)
      setPending_for_approval_admin(0)
      setPending_for_approval_manager(0)

    }
  }, [])

  function check_dashboard() {
    const check_dashboard = api.GET_DASHBOARD_ALL(token)
    axios(check_dashboard)
      .then(res => {
        setAssigned(res.data.dashboard.assigned)
        setCancel(res.data.dashboard.cancel)
        setComplete(res.data.dashboard.complete)
        setInprogress(res.data.dashboard.inprogress)
        setOverdue(res.data.dashboard.overdue)
        setPending(res.data.dashboard.pending)
        setPending_for_approval_admin(
          res.data.dashboard.pending_for_approval_admin
        )
        setPending_for_approval_manager(
          res.data.dashboard.pending_for_approval_manager
        )

      })
      .catch(error => {
        if(error){
          if(error.response){
            if(error.response.status === 401){
              helpers.re_login()
              history.push('/login')          
              }
          }
        }

      })
  }

  return (
    <Card style={style_card}>
      <CardBody>
        <Row>
          <Col sm={12} md={6} lg={6} xl={4}>
            <Card_Dashboard
              header={t("assigned")}
              number={assigned}
              color_border_icon="#6891C3"
              icon="AiOutlineFileText"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={4}>
            <Card_Dashboard
              header={t("inprogress")}
              number={inprogress}
              color_border_icon="#EA9FE6"
              icon="AiOutlineFileSearch"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={4}>
          <Card_Dashboard
            header={t("pending")}
            number={pending_for_approval_admin + pending_for_approval_manager + pending}
            color_border_icon="#7985E0"
            icon="AiOutlineFieldTime"
          />
        </Col>

          <Col sm={12} md={6} lg={6} xl={4}>
            <Card_Dashboard
              header={t("complete")}
              number={complete}
              color_border_icon="#76CE86"
              icon="AiOutlineFileDone"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={4}>
            <Card_Dashboard
              header={t("overdue")}
              number={overdue}
              color_border_icon="#F29496"
              icon="AiOutlineFileExclamation"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={4}>
            <Card_Dashboard
              header={t("cancel")}
              number={cancel}
              color_border_icon="#ff6961"
              icon="AiOutlineFileExcel"
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}
