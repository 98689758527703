import { call, put, takeEvery , all } from "redux-saga/effects"

import {
  ADD_PRIVATE_ID,
  ADD_NAME_USER,
  ADD_IMAGE_AVATAR,
  IS_ADMIN_ROOT,
  FIND_IS_ROLE,
  EMAIL_USER_CUSTOMER,
  PERMISSION_ALL_SERVICE,
  _CUSTOMER_USER_ID,
  _DEALER_USER_ID,
  _EMP_ID
} from "./actionTypes"

import {
  addPrivateID,
  addNameUser,
  addImageAvatar,
  checkAdminIsRoot,
  checkIsRole,
  addEmailUserCustomer,
  addPermissionAllServiceWeb,
  addCustomerUserID,
  addDealerUserID,
  addEmployeeUserID
} from "./actions"

// function* privateIDsaga() {
//   yield takeEvery(ADD_PRIVATE_ID, addPrivateID)
//   yield takeEvery(ADD_NAME_USER, addNameUser)
//   yield takeEvery(ADD_IMAGE_AVATAR, addImageAvatar)
// }

function* addEmployeeID(){
  yield takeEvery(_EMP_ID, addEmployeeUserID)
}

function* addDealerID(){
  yield takeEvery(_DEALER_USER_ID, addDealerUserID)
}

function* addCustomerID(){
  yield takeEvery(_CUSTOMER_USER_ID, addCustomerUserID)
}

function* addPermissionAllServiceWebData(){
  yield takeEvery(PERMISSION_ALL_SERVICE, addPermissionAllServiceWeb)
}

function* addEmailUserCustomerData(){
  yield takeEvery(EMAIL_USER_CUSTOMER, addEmailUserCustomer)
}

function* checkRole(){
  yield takeEvery(FIND_IS_ROLE, checkIsRole)
}

function* privateIDSaga(){
  yield takeEvery(ADD_PRIVATE_ID, addPrivateID)
}

function* addNameUserSaga(){
  yield takeEvery(ADD_NAME_USER, addNameUser)
}

function* addImageAvatarSaga(){
  yield takeEvery(ADD_IMAGE_AVATAR, addImageAvatar)
}

function* checkIsAdminRoot(){
  yield takeEvery(IS_ADMIN_ROOT, checkAdminIsRoot)
}

export default function* rootSaga(){
  yield all([
    privateIDSaga(),
    addNameUserSaga(),
    addImageAvatarSaga(),
    checkIsAdminRoot(),
    checkRole(),
    addEmailUserCustomerData(),
    addPermissionAllServiceWebData(),
    addCustomerID(),
    addDealerID(),
    addEmployeeID()
  ]
  )
}