import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import Expand from "react-expand-animated"
import { FiEdit } from "react-icons/fi"
import { BiBuildings } from "react-icons/bi"
import * as api from "../../../../../service/Api_New_Customer"
import axios from "axios"
import Select_Branch from "./Select_Branch"
import Create_New_Branch from './Create_New_Branch'
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import helpers from '../../../../Function_Redirect/Redirect_login'

export default function Found_My_Branch(props) {
  const [default_style, setDefault_style] = useState(
    "rgb(99 99 99 / 20%) 0px 2px 8px 0px"
  )
  const history = useHistory()
  const [custom_style, setCustom_style] = useState(null)
  const [default_icon_color, setDefault_icon_color] = useState("#1890ff")
  const [custom_icon_color, setCustom_icon_color] = useState("#C7C7C7")
  const [open_custom, setOpen_custom] = useState(false)
  const { t } = useTranslation();
  const token = localStorage.getItem("token")
  const [data_search_branch, setData_search_branch] = useState(null)
  const [loading_branch, setLoading_branch] = useState(false)
  const [branch_table, setBranch_table] = useState([])
  const [page_limit_branch, setPage_limit_branch] = useState({
    page_branch: 1,
    limit_branch: 0,
    page_Size_branch: 10,
  })

  const [select_role_branch, setSelect_role_branch] = useState({})

  useEffect(() => {
    if (props.my_customer.cus_id) {
      search_data_branch()
    }
    return () => {}
  }, [props.my_customer])

  function search_data_branch() {
    setLoading_branch(true)
    if (data_search_branch) {
      const search_data = api.SEARCH_DATA(
        token,
        props.my_customer.cus_id,
        1,
        page_limit_branch.page_Size_branch,
        data_search_branch
      )
      axios(search_data)
        .then(res => {
          setLoading_branch(false)
          setPage_limit_branch({
            ...page_limit_branch,
            page_branch: 1,
            limit_branch: res.data.total_data,
          })
          setBranch_table(res.data.data)
        })
        .catch(error => {
          setLoading_branch(false)
            if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }

        })
    } else {
      const search_no_data = api.SEARCH_NODATA(
        token,
        props.my_customer.cus_id,
        1,
        page_limit_branch.page_Size_branch
      )
      axios(search_no_data)
        .then(res => {
          setLoading_branch(false)
          setPage_limit_branch({
            ...page_limit_branch,
            page_branch: 1,
            limit_branch: res.data.total_data,
          })
          setBranch_table(res.data.data)
        })
        .catch(error => {
          setLoading_branch(false)
            if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }

        })
    }
  }

  function changePagination(page, pageSize) {
    setLoading_branch(true)
    if (data_search_branch) {
      const search_data = api.SEARCH_DATA(
        token,
        props.my_customer.cus_id,
        page,
        pageSize,
        data_search_branch
      )
      axios(search_data)
        .then(res => {
          setLoading_branch(false)
          setPage_limit_branch({
            ...page_limit_branch,
            page_branch: page,
            page_Size_branch: pageSize,
            limit_branch: res.data.total_data,
          })
          setBranch_table(res.data.data)
        })
        .catch(error => {
          setLoading_branch(false)
            if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }

        })
    } else {
      const search_no_data = api.SEARCH_NODATA(
        token,
        props.my_customer.cus_id,
        page,
        pageSize
      )
      axios(search_no_data)
        .then(res => {
          setLoading_branch(false)
          setPage_limit_branch({
            ...page_limit_branch,
            page_branch: page,
            page_Size_branch: pageSize,
            limit_branch: res.data.total_data,
          })
          setBranch_table(res.data.data)
        })
        .catch(error => {
          setLoading_branch(false)
            if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }

        })
    }
  }

  function setGender(event) {
    if (event.target.value === "SELECT") {
      props.setFound_Branch_In_DB('SELECT')
      setDefault_icon_color("#1890ff")
      setCustom_icon_color("#C7C7C7")
      setCustom_style(null)
      setDefault_style("rgb(99 99 99 / 20%) 0px 2px 8px 0px")
      setOpen_custom(false)
    } else {
      props.setFound_Branch_In_DB('NEW')
      setDefault_icon_color("#C7C7C7")
      setCustom_icon_color("#1890ff")
      setCustom_style("rgb(99 99 99 / 20%) 0px 2px 8px 0px")
      setDefault_style(null)
      setOpen_custom(true)
    }
  }

  return (
    <div>
      <div>
        <p
          style={{
            fontSize: "15px",
            fontWeight: "bold",
            color: "#495057",
            marginTop: "20px",
          }}
        >
          {t("Select branch")} <span style={{ color: "red" }}>*</span>{" "}
        </p>
        <div onChange={setGender}>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <div
                style={{
                  width: "100%",
                  height: "80px",
                  boxShadow: default_style,
                }}
              >
                <Row>
                  <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                    <input
                      type="radio"
                      value="SELECT"
                      name="select_branch"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginTop: "30px",
                        marginLeft: "20px",
                      }}
                      defaultChecked
                    />
                  </Col>
                  <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                    <BiBuildings
                      size={50}
                      style={{ marginTop: "15px", color: default_icon_color }}
                    />
                  </Col>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: "#495057",
                        marginTop: "27px",
                      }}
                    >
               
                      {t("Select branch")}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <div
                style={{
                  width: "100%",
                  height: "80px",
                  boxShadow: custom_style,
                }}
              >
                <Row>
                  <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                    <input
                      type="radio"
                      value="NEW"
                      name="select_branch"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginTop: "30px",
                        marginLeft: "20px",
                      }}
                    />
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                    <FiEdit
                      size={50}
                      style={{ marginTop: "15px", color: custom_icon_color }}
                    />
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: "#495057",
                        marginTop: "27px",
                      }}
                    >
                 
                      {t("Create new branch")}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>

        <br />

        <Expand open={!open_custom} duration={500}>
          <Select_Branch
          setSelect_branch_final={props.setSelect_branch_final}
          setSelect_role_branch={setSelect_role_branch}
          select_role_branch={select_role_branch}
            page_limit_branch={page_limit_branch}
            setPage_limit_branch={setPage_limit_branch}
            branch_table={branch_table}
            loading_branch={loading_branch}
            search_data_branch={search_data_branch}
            setData_search_branch={setData_search_branch}
            changePagination={changePagination}
            setBranch={props.setBranch}
            branch={props.branch}
          />
        </Expand>

        <Expand open={open_custom} duration={500}>
          <Create_New_Branch 
          setSelect_branch_final={props.setSelect_branch_final}
          found_Branch_In_DB={props.found_Branch_In_DB}
          setBranch={props.setBranch}
          branch={props.branch}
          />
        </Expand>
      </div>
    </div>
  )
}
