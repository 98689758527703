import React, { useState, useEffect } from "react"
import * as api from "../../../service/Api_Edit_Service"
import Search_Data_Operator from "./Search_Data_Operator"
import { Form, Table, Pagination, Button, Modal, notification } from "antd"
import axios from "axios"
import { Row, Col } from "reactstrap"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import Table_service_hast_operator from "./Table_service_hast_operator"
import { FcDataSheet } from "react-icons/fc"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import helpers from "../../Function_Redirect/Redirect_login"

const { confirm } = Modal

export default function Operator(props) {
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const history = useHistory()
  const token = localStorage.getItem("token")
  const [loading, setLoading] = useState(false)
  const [data_search_table_operator, setData_search_table_operator] = useState(
    null
  )
  const [service_operator, setService_operator] = useState([])
  const [data_operator, setData_operator] = useState([])
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })
  const [loading_operator, setLoading_operator] = useState(false)

  useEffect(() => {
    search_operator()
    check_service_hast_operator()
    return () => {
      setData_search_table_operator(null)
      setData_operator([])
      setService_operator([])
    }
  }, [])

  function search_operator() {
    setLoading_operator(true)
    if (data_search_table_operator) {
      const list_data_operator = api.LIST_DATA_OPERATOR_DATA(
        token,
        props.ser_id,
        1,
        page_limit.page_Size,
        data_search_table_operator
      )
      axios(list_data_operator)
        .then(res => {
          setLoading_operator(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setData_operator(res.data.data)
        })
        .catch(error => {
          setLoading_operator(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else {
      const list_no_data_operator = api.LIST_DATA_OPERATOR_NO_DATA(
        token,
        props.ser_id,
        1,
        page_limit.page_Size
      )
      axios(list_no_data_operator)
        .then(res => {
          setLoading_operator(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setData_operator(res.data.data)
        })
        .catch(error => {
          setLoading_operator(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  function changePagination(page, pageSize) {
    setLoading_operator(true)
    if (data_search_table_operator) {
      const list_data_operator_pagination = api.LIST_DATA_OPERATOR_DATA(
        token,
        props.ser_id,
        page,
        pageSize,
        data_search_table_operator
      )
      axios(list_data_operator_pagination)
        .then(res => {
          setLoading_operator(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setData_operator(res.data.data)
        })
        .catch(error => {
          setLoading_operator(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else {
      const list_data_operator_pagination = api.LIST_DATA_OPERATOR_NO_DATA(
        token,
        props.ser_id,
        page,
        pageSize
      )
      axios(list_data_operator_pagination)
        .then(res => {
          setLoading_operator(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setData_operator(res.data.data)
        })
        .catch(error => {
          setLoading_operator(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  const columns = [
    {
      title: t("Operator code"),
      dataIndex: "ope_code",
      key: "ope_code",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Firstname"),
      dataIndex: "ope_firstname",
      key: "ope_firstname",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Lastname"),
      dataIndex: "ope_lastname",
      key: "ope_lastname",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Mobile"),
      dataIndex: "ope_mobile",
      key: "ope_mobile",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Email"),
      dataIndex: "ope_email",
      key: "ope_email",
      render: text => <p>{text}</p>,
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      props.setOperator(selectedRows)
    },
    getCheckboxProps: record => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  }

  function cancelForm() {
    form.resetFields()
    search_operator()
    check_service_hast_operator()
    props.setOperator([])
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  async function fetchData(ope_id) {
    const data = {
      ser_id: props.ser_id,
      ope_id: ope_id,
    }
    const create_operator = api.INSERT_OPERATOR(token, data)
    const fetcher = await axios(create_operator)
    if (fetcher.status === 201) {
      openNotificationWithIcon("success", t("Insert operator success"))
      return fetcher
    } else {
      openNotificationWithIcon("error", t("Insert operator error"))
      return fetcher
      // console.log(error.response)
    }
  }

  function add_operator() {
    if (props.operator.length === 0) {
      return Modal.error({
        title: t("Please select operator"),
        okText: t("Confirm"),
        centered: true,
      })
    } else {
      return confirm({
        title: t("Confirm select operator"),
        icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
        centered: true,
        okText: t("Confirm"),
        cancelText: t("no"),
        onOk() {
          setLoading(true)
          const promisesAddOperator = props.operator.map(async data => {
            await fetchData(data.ope_id)
          })
    
          setTimeout(() => {
            // openNotificationWithIcon("success", "Insert operator success")
            props.check_activity_log()
            props.list_service_overview()
            setLoading(false)
            cancelForm()
            search_operator()
            check_service_hast_operator()
          }, 1500)
        },
        onCancel() {},
      })
    }
  }

  function check_service_hast_operator() {
    const check_service_operator = api.LIST_SERVICE_HAST_OPERATOR(
      token,
      props.ser_id
    )
    axios(check_service_operator)
      .then(res => {
        setService_operator(res.data.data)
      })
      .catch(error => {
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  return (
    <div>
      <p
        className="text-bold"
        style={{ textAlign: "center", fontSize: "15px", marginBottom: "10px" }}
      >
        {t("Operator")}
      </p>
      <Table_service_hast_operator
        list_service_overview={props.list_service_overview}
        service_operator={service_operator}
        check_service_hast_operator={check_service_hast_operator}
        search_operator={search_operator}
        check_activity_log={props.check_activity_log}
      />
      <br />
      <div>
        <p className="text-bold ">
          {t("Select operator")} <span style={{ color: "red" }}>*</span>
        </p>
        <Form form={form} name="control-operator">
          <div>
            <Search_Data_Operator
              search_operator={search_operator}
              setData_search_table_operator={setData_search_table_operator}
            />
            <br />
            <Form.Item
              name="operator_info"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Table
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                loading={loading_operator}
                rowKey="ope_id"
                pagination={false}
                columns={columns}
                dataSource={data_operator}
                scroll={{ x: 800 }}
              />
            </Form.Item>

            <div style={{ float: "right", marginTop: "10px" }}>
              <Pagination
                current={page_limit.page}
                total={page_limit.limit}
                onChange={changePagination}
              />
            </div>
            <p style={{ fontSize: "15px", marginTop: "20px" }}>
              {" "}
              <FcDataSheet size={20} style={{ marginBottom: "5px" }} />{" "}
              {t("total data")} : {page_limit.limit}
            </p>
          </div>

          <br />
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <div className="div_create_new_car">
                <Button
                  className="btn_cancel_create_new_car"
                  onClick={() => cancelForm()}
                >
                  {t("Cancel")}
                </Button>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
              <div className="div_create_new_car">
                <Button
                  className="btn_submit_create_new_car"
                  type="primary"
                  onClick={() => add_operator()}
                  loading={loading}
                >
                  {t("Insert operator")}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  )
}
