import React, { useRef, useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Container, Card, CardBody, Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next"
import { Button, Avatar, Input, Modal, Form, notification } from "antd"
import { FaRegUser } from "react-icons/fa"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import axios from "axios"
import { MdKeyboardBackspace } from "react-icons/md"
import * as api from "../../service/Api_Employee"
import "../New_Employee/Profile/Profile_New_Employee.css"
import Input_Comp from "../New_Employee/Profile/Components/Input_Comp"
import Input_Comp_pass from "../New_Employee/Profile/Components/Input_Comp_pass"
import Select_no_search from "../New_Employee/Profile/Components/Select_no_search"
import Input_Comp_Email from "../New_Employee/Profile/Components/Input_Comp_Email"
import { useHistory } from "react-router-dom"
import helpers from "../Function_Redirect/Redirect_login"

const { confirm } = Modal

export default function Create_New_Admin(props) {
  const [form] = Form.useForm()
  const history = useHistory()
  const { t } = useTranslation()
  const [select_image, setSelect_image] = useState(null)
  const imageUploader = useRef(null)
  const token = localStorage.getItem("token")
  const [error_pass, setError_pass] = useState(null)
  const [confirm_password_info, setConfirm_password_info] = useState(null)
  const [new_detail_admin, setNew_detail_admin] = useState({
    admin_code: null,
    admin_firstname: null,
    admin_lastname: null,
    admin_email: null,
    admin_password: null,
    admin_group: null,
  })
  const [loading, setLoading] = useState(false)
  const style_req = {
    color: "red",
  }

  useEffect(() => {
    
    return () => {
      setSelect_image(null)
      setError_pass(null)
      setConfirm_password_info(null)
      setNew_detail_admin({
        admin_code: null,
        admin_firstname: null,
        admin_lastname: null,
        admin_email: null,
        admin_password: null,
        admin_group: null,
      })
      setLoading(false)
    }
  }, [])

  const handleImageUpload = e => {
    if (e.target.files[0] !== undefined) {
      if (e.target.files[0].type !== "image/jpeg") {
        openNotificationWithIcon(
          "error",
          t("please upload jpg or jpeg image only")
        )
      } else {
        let img_upload = e.target.files[0]
        setSelect_image(img_upload)
        // setImage_preview(URL.createObjectURL(img_upload))
      }
    }
  }

  const openNotificationWithIcon = (type, description) => {
    notification[type]({
      message: description,
    })
  }

  const onFinish = values => {
    if (new_detail_admin.admin_password === values.confirm_password_info) {
      return confirm({
        title: t("Confirm create new admin"),
        icon: <ExclamationCircleOutlined />,
        centered: true,
        okText: t("ok"),
        cancelText: t("cancel"),
        onOk() {
          setLoading(true)
          setError_pass(null)
          if (select_image !== null) {
            const formData = new FormData()
            formData.append("file", select_image)
            const upload_image = api.UPLOAD_PROFILE(token, formData)
            axios(upload_image)
              .then(res => {
                let add_image_path = {
                  admin_avatar: res.data.path,
                  ...new_detail_admin,
                }
                create_new_admin(add_image_path)
              })
              .catch(error => {
                setLoading(false)
                if (error) {
                  if (error.response) {
                    if (error.response.status === 401) {
                      helpers.re_login()
                      history.push("/login")
                    } else {
                      openNotificationWithIcon(
                        "error",
                        t("Create new admin error")
                      )
                    }
                  }
                }
              })
          } else {
            let data_admin_no_img = {
              admin_avatar: null,
              ...new_detail_admin,
            }
            create_new_admin(data_admin_no_img)
          }
        },
        onCancel() {},
      })
    } else {
      setError_pass(t("password mismatch"))
    }
  }

  function create_new_admin(data) {
    const create_new_admin = api.CREATE_NEW_ADMIN(token, data)
    axios(create_new_admin)
      .then(res => {
        setLoading(false)
        history.push("/admin-management")
        openNotificationWithIcon("success", t("Create new admin success"))
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            } else {
              openNotificationWithIcon("error", t("Create new admin error"))
            }
          }
        }
      })
  }

  useEffect(() => {
    if (confirm_password_info !== null) {
      if (new_detail_admin.admin_password === confirm_password_info) {
        setError_pass(null)
      } else {
        setError_pass(t("password mismatch"))
      }
    }
    return () => {}
  }, [new_detail_admin.admin_password, confirm_password_info])

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Create new admin")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/admin-management")}
              />
            </a>{" "}
            {t("Create new admin")}
          </h3>

          <Card>
            <CardBody>
              <div>
                <p className="text-bold" style={{ textAlign: "center" }}>
                  {" "}
                  {t("photo Profile")}{" "}
                </p>
                <input
                  type="file"
                  accept="image/jpeg"
                  onChange={handleImageUpload}
                  ref={imageUploader}
                  style={{
                    display: "none",
                  }}
                />

                {select_image !== null && (
                  <div className="image-upload">
                    <Avatar
                      src={URL.createObjectURL(select_image)}
                      size={120}
                      style={{ display: "block", margin: "auto" }}
                    />
                  </div>
                )}
                {select_image === null && (
                  <div className="image-upload">
                    <Avatar
                      icon={<FaRegUser style={{ marginBottom: "5px" }} />}
                      size={120}
                      style={{ display: "block", margin: "auto" }}
                    />
                  </div>
                )}

                {select_image === null && (
                  <>
                    <Button
                      type="primary"
                      style={{
                        width: "150px",
                        display: "block",
                        margin: "10px auto 20px auto",
                      }}
                      onClick={() => imageUploader.current.click()}
                    >
                      {t("upload photo")}
                    </Button>
                    <p style={{ color: "red", textAlign: "center" }}>
                      {" "}
                      {t("please upload jpg or jpeg image only")}{" "}
                    </p>
                  </>
                )}

                {select_image !== null && (
                  <Button
                    type="primary"
                    style={{
                      width: "150px",
                      display: "block",
                      margin: "10px auto 20px auto",
                    }}
                    onClick={() => {
                      setSelect_image(null)
                    }}
                  >
                    {t("cancel photo")}
                  </Button>
                )}
              </div>

              <Form
                form={form}
                name="basic_Profile"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
              >
                <Row>
                  <Col sm={12} md={6} lg={6} xl={6}>
                    <Input_Comp
                      header={t("firstname")}
                      placeholder={t("firstname")}
                      headState={setNew_detail_admin}
                      nameState={new_detail_admin}
                      keyState="admin_firstname"
                      type="text"
                    />
                  </Col>

                  <Col sm={12} md={6} lg={6} xl={6}>
                    <Input_Comp
                      header={t("lastname")}
                      placeholder={t("lastname")}
                      headState={setNew_detail_admin}
                      nameState={new_detail_admin}
                      keyState="admin_lastname"
                      type="text"
                    />
                  </Col>

                  <Col sm={12} md={6} lg={6} xl={6}>
                    <Input_Comp_Email
                      header={t("email")}
                      placeholder={t("email")}
                      headState={setNew_detail_admin}
                      nameState={new_detail_admin}
                      keyState="admin_email"
                      type="email"
                    />
                  </Col>

                  <Col sm={12} md={6} lg={6} xl={6}>
                    <Input_Comp
                      header={t("Admin code")}
                      placeholder={t("Admin code")}
                      headState={setNew_detail_admin}
                      nameState={new_detail_admin}
                      keyState="admin_code"
                      type="text"
                    />
                  </Col>

                  <Col sm={12} md={6} lg={6} xl={6}>
                    <Select_no_search
                      header={t("Admin group")}
                      placeholder={t("Admin group")}
                      name_api="FIND_ALL_ADMIN_GROUP"
                      map_name="admin_group_name"
                      map_value="admin_group_id"
                      keyState="admin_group"
                      headState={setNew_detail_admin}
                      nameState={new_detail_admin}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} md={6} lg={6} xl={6}>
                    <Input_Comp_pass
                      header={t("password")}
                      placeholder={t("password")}
                      headState={setNew_detail_admin}
                      nameState={new_detail_admin}
                      keyState="admin_password"
                      type="password"
                    />
                  </Col>
                  <Col sm={12} md={6} lg={6} xl={6}>
                    <div className="mt-bottom-all">
                      <p className="text-bold">
                        {t("confirm Password")} <span style={style_req}>*</span>
                      </p>
                      <Form.Item
                        name="confirm_password_info"
                        rules={[
                          {
                            required: true,
                            message: `${t("please input")} ${t(
                              "confirm Password"
                            )}`,
                          },
                        ]}
                      >
                        <Input.Password
                          type="password"
                          placeholder={t("confirm Password")}
                          onChange={e =>
                            setConfirm_password_info(e.target.value)
                          }
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>

                {error_pass && (
                  <p
                    style={{
                      color: "red",
                      textAlign: "center",
                      fontSize: "15px",
                    }}
                  >
                    {error_pass}
                  </p>
                )}
                <Row>
                  <Col sm={12} md={6} lg={6} xl={6}>
                    <div className="div_create_new_car">
                      <Button
                        className="btn_cancel_create_new_car"
                        onClick={() => history.push("/admin-management")}
                      >
                        {" "}
                        {t("cancel")}
                      </Button>
                    </div>
                  </Col>
                  <Col sm={12} md={6} lg={6} xl={6}>
                    <div className="div_create_new_car">
                      <Button
                        className="btn_submit_create_new_car"
                        type="primary"
                        htmlType="submit"
                        disabled={error_pass ? true : false}
                        loading={loading}
                      >
                        {t("create")}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
