import React, { useEffect, useState } from "react"
import { Form, Switch } from "antd"
import { Row, Col } from "reactstrap"
import Input_Comp from "./Components/Input_Comp"
import Select_no_search from "./Components/Select_no_search"
import Input_Pass from "./Components/Input_Pass"
import Input_Confirm_Pass from "./Components/Input_Confirm_Pass"
import Input_Comp_Onblur from './Components/Input_Comp_Onblur'
import Role_permission_level from './Components/Role_permission_level'
import axios from 'axios'
import * as api from '../../../../service/Api_Select_Car_From_DB_Distar'
import { useTranslation } from "react-i18next";

export default function Form_user(props) {
  const [form] = Form.useForm()
  const token = localStorage.getItem('token')
  const { t } = useTranslation();

  useEffect(() => {
    if (
      props.user[props.index_user].cus_email_password_condition === false &&
      props.confirm_pass_all[props.index_user]
    ) {
      if (
        props.confirm_pass_all[props.index_user].confirm_pass &&
        props.confirm_pass_all[props.index_user].confirm_pass !==
          props.user[props.index_user].cus_user_password
      ) {
        const error_handle = [...props.error_all]
        error_handle[props.index_user].error = t("Password mismatch")
        props.setError_all(error_handle)
        props.setError_form(true)
      } else {
        const error_handle = [...props.error_all]
        error_handle[props.index_user].error = null
        props.setError_all(error_handle)
        props.setError_form(false)
      }
    }
    return () => {}
  }, [props.confirm_pass_all, props.user])

  function onChange_switch_send_email(checked) {
    if (checked) {
      const values = [...props.user]
      const values_confirm_pass = [...props.confirm_pass_all]
      values_confirm_pass[props.index_user].confirm_pass = null
      if(props.user[props.index_user].cus_user_code){
        values[props.index_user] = {
          cus_email_password_condition: checked,
          cus_user_code: props.user[props.index_user].cus_user_code,
          cus_user_email: props.user[props.index_user].cus_user_email,
          cus_user_firstname: props.user[props.index_user].cus_user_firstname,
          cus_user_lastname: props.user[props.index_user].cus_user_lastname,
          cus_user_phone: props.user[props.index_user].cus_user_phone,
          dep_id: props.user[props.index_user].dep_id,
          not_send_email_notification:
            props.user[props.index_user].not_send_email_notification,
          not_send_line_notification:
            props.user[props.index_user].not_send_line_notification,
          pos_id: props.user[props.index_user].pos_id,
        }
      } else {
        values[props.index_user] = {
          cus_email_password_condition: checked,
          cus_user_email: props.user[props.index_user].cus_user_email,
          cus_user_firstname: props.user[props.index_user].cus_user_firstname,
          cus_user_lastname: props.user[props.index_user].cus_user_lastname,
          cus_user_phone: props.user[props.index_user].cus_user_phone,
          dep_id: props.user[props.index_user].dep_id,
          not_send_email_notification:
            props.user[props.index_user].not_send_email_notification,
          not_send_line_notification:
            props.user[props.index_user].not_send_line_notification,
          pos_id: props.user[props.index_user].pos_id,
        }
      }
      props.setConfirm_pass_all(values_confirm_pass)
      props.setUser(values)
    } else {
      const values = [...props.user]
      if(props.user[props.index_user].cus_user_code){
        values[props.index_user] = {
          cus_email_password_condition: checked,
          cus_user_password: null,
          cus_user_code: props.user[props.index_user].cus_user_code,
          cus_user_email: props.user[props.index_user].cus_user_email,
          cus_user_firstname: props.user[props.index_user].cus_user_firstname,
          cus_user_lastname: props.user[props.index_user].cus_user_lastname,
          cus_user_phone: props.user[props.index_user].cus_user_phone,
          dep_id: props.user[props.index_user].dep_id,
          not_send_email_notification:
            props.user[props.index_user].not_send_email_notification,
          not_send_line_notification:
            props.user[props.index_user].not_send_line_notification,
          pos_id: props.user[props.index_user].pos_id,
        }
      } else {
        values[props.index_user] = {
          cus_email_password_condition: checked,
          cus_user_password: null,
          cus_user_email: props.user[props.index_user].cus_user_email,
          cus_user_firstname: props.user[props.index_user].cus_user_firstname,
          cus_user_lastname: props.user[props.index_user].cus_user_lastname,
          cus_user_phone: props.user[props.index_user].cus_user_phone,
          dep_id: props.user[props.index_user].dep_id,
          not_send_email_notification:
            props.user[props.index_user].not_send_email_notification,
          not_send_line_notification:
            props.user[props.index_user].not_send_line_notification,
          pos_id: props.user[props.index_user].pos_id,
        }
      }
      props.setUser(values)
    }
  }

  function onChange_notification_line(checked) {
    const values = [...props.user]
    if (checked === true) {
      values[props.index_user].not_send_line_notification = false
    } else if (checked === false) {
      values[props.index_user].not_send_line_notification = true
    }
    props.setUser(values)
  }

  function onChange_notification_email(checked) {
    const values = [...props.user]
    if (checked === true) {
      values[props.index_user].not_send_email_notification = false
    } else if (checked === false) {
      values[props.index_user].not_send_email_notification = true
    }
    props.setUser(values)
  }

  function check_handle_info(check) {
    switch (check) {
      case true:
        return false
        break
      case false:
        return true
        break
      default:
        break
    }
  }

  useEffect(() => {
    if(props.user[props.index_user].cus_user_email){
      const check_user = api.CHECK_EMAIL_USER_DUPLICATE(token , props.user[props.index_user].cus_user_email)
      axios(check_user)
      .then( res => {
        if(res.data.msg === true){
          const error_email_dup = [...props.error_email]
          error_email_dup[props.index_user].error = t("Email is duplicate")
          props.setError_email(error_email_dup)
          props.setError_form(true)
          // props.setError_email
          // props.error_email
        } else if(res.data.msg === false){
          const error_email_dup = [...props.error_email]
          error_email_dup[props.index_user].error = null
          props.setError_email(error_email_dup)
          props.setError_form(false)
        }
      })
    }
    return () => {
      
    }
  }, [props.user[props.index_user].cus_user_email])

  return (
    <div>
      <Form form={form} name={`form_name_${props.index_user}`}>
        <Row>
        {
          props.user[props.index_user].cus_user_code && (
            <Col sm={12} md={6} lg={6} xl={6}>
              <Input_Comp
                placeholder={t("user code")}
                header={t("user code")}
                keyState="cus_user_code"
                type="text"
                disabled={true}
                index_user={props.index_user}
                user={props.user}
                setUser={props.setUser}
              />
            </Col>
          )
        }
        
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              placeholder={t("user firstname")}
              header={t("user firstname")}
              keyState="cus_user_firstname"
              type="text"
              disabled={false}
              index_user={props.index_user}
              user={props.user}
              setUser={props.setUser}
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              placeholder={t("user lastname")}
              header={t("user lastname")}
              keyState="cus_user_lastname"
              type="text"
              disabled={false}
              index_user={props.index_user}
              user={props.user}
              setUser={props.setUser}
            />
          </Col>
          
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              placeholder={t("user phone")}
              header={t("user phone")}
              keyState="cus_user_phone"
              type="text"
              disabled={false}
              index_user={props.index_user}
              user={props.user}
              setUser={props.setUser}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
          <Input_Comp
            placeholder={t("user email")}
            header={t("user email")}
            keyState="cus_user_email"
            type="email"
            disabled={false}
            index_user={props.index_user}
            user={props.user}
            setUser={props.setUser}
          />
          {props.error_email[props.index_user] && (
            <p
              style={{
                color: "red",
                fontSize: "15px",
                marginTop: "10px",
              }}
            >
              {props.error_email[props.index_user].error}
            </p>
          )}
        </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Department")}
              placeholder={t("Department")}
              name_api="CHECK_DEPARTMENT"
              map_name="dep_name"
              map_value="dep_id"
              keyState="dep_id"
              Header_data="data"
              index_user={props.index_user}
              user={props.user}
              setUser={props.setUser}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Position")}
              placeholder={t("Position")}
              name_api="CHECK_POSITION"
              map_name="pos_name"
              map_value="pos_id"
              keyState="pos_id"
              Header_data="data"
              index_user={props.index_user}
              user={props.user}
              setUser={props.setUser}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <p className="text-bold" style={{ marginTop: "20px" }}>
   
              {t("Receive notifications")}
            </p>
            <p>
              {t("Subscribe email notification")}
              <Switch
                defaultChecked
                style={{ marginLeft: "15px" }}
                onChange={onChange_notification_email}
                checked={check_handle_info(
                  props.user[props.index_user].not_send_email_notification
                )}
              />
            </p>
            <p>
              {t("Subscribe line notification")}
              <Switch
                defaultChecked
                style={{ marginLeft: "22px" }}
                onChange={onChange_notification_line}
                checked={check_handle_info(
                  props.user[props.index_user].not_send_line_notification
                )}
              />
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <p className="text-bold" style={{ marginTop: "20px" }}>
              {t("Sending signup password by email")}
              <Switch
                checked={
                  props.user[props.index_user].cus_email_password_condition
                }
                onChange={onChange_switch_send_email}
                style={{ marginLeft: "10px" }}
              />
            </p>
          </Col>
        </Row>

        {props.user[props.index_user].cus_email_password_condition ===
          false && (
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <Input_Pass
                header={t("Password")}
                placeholder={t("Password")}
                keyState="cus_user_password"
                index_user={props.index_user}
                user={props.user}
                setUser={props.setUser}
              />
            </Col>

            <Col sm={12} md={6} lg={6} xl={6}>
              <Input_Confirm_Pass
                header={t("Confirm password")}
                placeholder={t("Confirm password")}
                keyState="confirm_pass"
                index_user={props.index_user}
                confirm_pass_all={props.confirm_pass_all}
                setConfirm_pass_all={props.setConfirm_pass_all}
              />
            </Col>
          </Row>
        )}

        {props.error_all[props.index_user] && (
          <p
            style={{
              color: "red",
              fontSize: "15px",
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            {props.error_all[props.index_user].error}
          </p>
        )}

{/*        <Row>
          <Col>
            <Role_permission_level 
            setList_Checkbox={props.setList_Checkbox}
            list_checkbox={props.list_checkbox}
            index_user={props.index_user}
            user={props.user}
            setUser={props.setUser}
            />
          </Col>
</Row> */}
      </Form>
    </div>
  )
}
