import React, { useState, useEffect } from "react"
import { Select, Form } from "antd"
import { useTranslation } from "react-i18next"

const { Option } = Select

export default function SelectRole(props) {
  const { t } = useTranslation()

  function handleChange(value) {
    if (value) {
      props.setFilter_Role({
        ...props.filter_Role,
        request_by_role: value,
        request_by_id: null,
        request_by_name: null,
      })
    } else {
      props.setFilter_Role({
        ...props.filter_Role,
        request_by_role: null,
        request_by_id: null,
        request_by_name: null,
      })
    }
  }

  return (
    <div>
      <p className="text-bold header-filter header-mt-filter-service"> {t("Confirmed by")} </p>
      <Select
        showSearch
        allowClear
        style={{ width: "100%" }}
        placeholder={t("Select confirmed")}
        optionFilterProp="children"
        onChange={handleChange}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        <Option value="administrator"> {t("administrator")} </Option>
        <Option value="employee"> {t("employee")} </Option>
      </Select>
    </div>
  )
}
