import React, { useEffect, useState } from "react"
import axios from "axios"
import * as api from "../../../service/service_operator/Api_Dashboard"
import { Table, Tag, Tooltip } from "antd"
import Render_Image_Data from "../../Employee/Table/Render_Image_Data"
import { useHistory } from "react-router-dom"
import { BsThreeDots } from 'react-icons/bs';
import { useTranslation } from "react-i18next";
import helpers from '../../Function_Redirect/Redirect_login'
import { FcApproval , FcCancel , FcClock } from "react-icons/fc"

export default function Job_Assgined() {
  const { t } = useTranslation();
    const history = useHistory()
  const token = localStorage.getItem("token")
  const [job_all, setJob_all] = useState([])
  const styleIcons = { 
    display:'block',
    marginLeft:'auto',
    marginRight : 'auto'
   }

  useEffect(() => {
    check_job_assigned()
    return () => {}
  }, [])

  function check_job_assigned() {
    const check_job = api.JOB_ASSIGNED(token)
    axios(check_job)
      .then(res => {
        setJob_all(res.data.data)
      })
      .catch(error => {

        // if(error){
        //   if(error.response){
        //     if(error.response.status === 401){
        //       helpers.re_login()
        //       history.push('/login')          
        //       }
        //   }
        // }
      })
  }

  const columns = [
    {
      title: t("Service number"),
      dataIndex: "ser_no",
      key: "ser_no",
      render: text => <p>{text}</p>,
      width: "150px",
    },
    {
      title: t("Service type"),
      dataIndex: "ser_type_name",
      key: "ser_type_name",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Service status"),
      dataIndex: "ser_status_name",
      key: "ser_status_name",
      render: text => render_status(text),
    },
    {
      title: t("Customer confirmation status"),
      render: text => statusUser(text),
      width: "180px",
    },
    {
      title: "",
      dataIndex: "ser_id",
      key: "ser_id",
      render: text => (
       <a onClick={() => history.push('/service/'+text)}>
       <BsThreeDots size={25} />
       </a>
      ),
    },
  ]

  function statusUser(status) {
    if(status.is_user_cancel === 1 && status.is_user_confirm === 0){
      return (
        <Tooltip title={t("Customer cancel")} placement="topRight">
        <a>
          <FcCancel
            size={30}
            style={styleIcons}
          />
        </a>
      </Tooltip>
      )
    } else if(status.is_user_cancel === 0 && status.is_user_confirm === 1){
      return (
        <Tooltip title={t("Customer confirm")} placement="topRight">
        <a>
          <FcApproval
            size={30}
            style={styleIcons}
          />
        </a>
      </Tooltip>
      )
    } else {
      return (
        <Tooltip title={t("Waiting for customer confirmation")} placement="topRight">
        <a>
          <FcClock
            size={30}
            style={styleIcons}
          />
        </a>
      </Tooltip>
      )
    }
  }

  function check_user_image(data) {
    return <Render_Image_Data path_image={data.emp_avatar} />
  }

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return "N/A"
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  function render_status(status) {
    switch (status) {
      case "cancel":
        return <Tag color="error"> {t(status)} </Tag>
        break
        case "reject":
          return <Tag color="error">{t(status)}</Tag>
          break
      case "overdue":
        return <Tag color="error">{t(status)}</Tag>
        break
      case "resolved":
        return <Tag color="success">{t(status)}</Tag>
        break
      case "complete":
        return <Tag color="success">{t(status)}</Tag>
        break
      case "inprogress":
        return <Tag color="processing">{t(status)}</Tag>
        break
      case "unassigned":
        return <Tag color="default">{t(status)}</Tag>
        break
      case "pending":
        return (
          <Tag
            style={{
              color: "rgb(234, 159, 230)",
              borderColor: "rgb(234, 159, 230)",
              backgroundColor: "#FFF",
            }}
          >
          {t(status)}
          </Tag>
        )
        break
      case "assigned":
        return (
          <Tag
            style={{
              color: "#DCC200",
              borderColor: "#DCC200",
              backgroundColor: "#FFF",
            }}
          >
          {t(status)}
          </Tag>
        )
        break
      case "waiting for admin approve":
        return (
          <Tag
            style={{
              color: "#0071FF",
              borderColor: "#0071FF",
              backgroundColor: "#DFEDFF",
            }}
          >
          {t(status)}
          </Tag>
        )
        break
      case "waiting for manager approve":
        return (
          <Tag
            style={{
              color: "#0071FF",
              borderColor: "#0071FF",
              backgroundColor: "#DFEDFF",
            }}
          >
          {t(status)}
          </Tag>
        )
        break
      default:
        return <Tag color="default">{t(status)}</Tag>
        break
    }
  }
  return (
    <div>
      <p className="text-bold"> {t("Service")} </p>
      <Table 
      columns={columns} 
      dataSource={job_all} 
      rowKey="ser_id" 
      scroll={{ x: 500 }}
      />
    </div>
  )
}
