import React, { useState, useEffect } from "react"
import { Container, Card, CardBody, Row , Col } from "reactstrap"
import MetaTags from "react-meta-tags"
import { FcDataSheet } from "react-icons/fc"
import axios from 'axios'
import * as api from '../../../service/service_customer/Api_Dashboard'
import Service_Status from './Service_Status'
import Cars_Header from './components/Cars_Header'
import { useTranslation } from "react-i18next"
import Contract_count from './Contract_count'
import { useHistory } from "react-router-dom";
import helpers from '../../Function_Redirect/Redirect_login'

const Dashboard = props => {
  const token = localStorage.getItem('token')
  const { t } = useTranslation()
  const history = useHistory()
  const [assigned, setAssigned] = useState(0)
  const [cancle, setCancle] = useState(0)
  const [complete, setComplete] = useState(0)
  const [inprogress, setInprogress] = useState(0)
  const [overdue, setOverdue] = useState(0)
  const [pending_for_approval_admin, setPending_for_approval_admin] = useState(0)
  const [pending_for_approval_manager, setPending_for_approval_manager] = useState(0)
  const [resolved, setResolved] = useState(0)
  const [unassigned, setUnassigned] = useState(0)
  const [count_all_status, setCount_all_status] = useState(0)
  const [pending, setPending] = useState(0)

  const [car, setCar] = useState(0)
  const [contract, setContract] = useState(0)
  const [service, setService] = useState(0)
  const [stock, setStock] = useState(0)

  const [contract_expired, setContract_expired] = useState({
    available: 0,
    expired: 0,
  })

  const style_card = {
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  }

  useEffect(() => {
    check_data_dashboard()
    return () => {
      setAssigned(0)
      setCancle(0)
      setComplete(0)
      setInprogress(0)
      setOverdue(0)
      setPending_for_approval_admin(0)
      setPending_for_approval_manager(0)
      setResolved(0)
      setPending(0)
      setUnassigned(0)
      setCount_all_status(0)
      setCar(0)
      setContract(0)
      setService(0)
      setStock(0)
      setContract_expired({
        available: 0,
        expired: 0,
      })
    }
  }, [])

  function check_data_dashboard() {
    const check_dashboard = api.LIST_DASHBOARD_CUSTOMER(token)
    axios(check_dashboard)
    .then( res => {

      setContract_expired({
        ...contract_expired,
        available: res.data.data.contract_expired.available,
        expired: res.data.data.contract_expired.expired,
      })

      setCar(res.data.data.count_car)
      setContract(res.data.data.count_contract)
      setService(res.data.data.count_service)
      setStock(res.data.data.count_stock)

    setAssigned(res.data.data.count_service_status.assigned)
    setCancle(res.data.data.count_service_status.cancle)
    setComplete(res.data.data.count_service_status.complete)
    setInprogress(res.data.data.count_service_status.inprogress)
    setOverdue(res.data.data.count_service_status.overdue)
    setPending_for_approval_admin(res.data.data.count_service_status.pending_for_approval_admin)
    setPending_for_approval_manager(res.data.data.count_service_status.pending_for_approval_manager)
    setResolved(res.data.data.count_service_status.resolved)
    setUnassigned(res.data.data.count_service_status.unassigned)

    setCount_all_status(
      res.data.data.count_service_status.assigned+res.data.data.count_service_status.cancle+res.data.data.count_service_status.complete+res.data.data.count_service_status.inprogress+res.data.data.count_service_status.overdue+
      res.data.data.count_service_status.pending_for_approval_admin+res.data.data.count_service_status.pending_for_approval_manager+res.data.data.count_service_status.resolved+res.data.data.count_service_status.unassigned
    )

    })
    .catch( error => {
      if(error){
        if(error.response){
          if(error.response.status === 401){
            helpers.re_login()
            history.push('/login')          
            }
        }
      }

    })
  }
 
  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("dashboard")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}> {t("dashboard")} </h3>
<br />
          <Card style={style_card}>
          <CardBody>
            <br />
            <Row>
              <Col sm={12} md={6} lg={6} xl={3}>
                <Cars_Header
                  header={t("contract")}
                  number={contract}
                  color_border_icon="#ff6961"
                  icon="AiOutlineFileDone"
                />
              </Col>
              <Col sm={12} md={6} lg={6} xl={3}>
                <Cars_Header
                  header={t("car")}
                  number={car}
                  color_border_icon="#EA9FE6"
                  icon="AiOutlineCar"
                />
              </Col>
              <Col sm={12} md={6} lg={6} xl={3}>
                <Cars_Header
                  header={t("service")}
                  number={service}
                  color_border_icon="#7985E0"
                  icon="VscTools"
                />
              </Col>
              <Col sm={12} md={6} lg={6} xl={3}>
                <Cars_Header
                  header={t("stock")}
                  number={stock}
                  color_border_icon="#76CE86"
                  icon="FiBox"
                />
              </Col>
            </Row>
          </CardBody>
        </Card>

            <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <Card style={style_card}>
                <CardBody>
                  <Service_Status 
                  assigned={assigned}
                  cancle={cancle}
                  complete={complete}
                  inprogress={inprogress}
                  overdue={overdue}
                  pending={pending}
                  pending_for_approval_admin={pending_for_approval_admin}
                  pending_for_approval_manager={pending_for_approval_manager}
                  resolved={resolved}
                  unassigned={unassigned}
                  count_all_status={count_all_status}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
            <Card style={style_card}>
              <CardBody>
              <Contract_count 
              contract={contract}
              contract_expired={contract_expired}
              />
              </CardBody>
            </Card>
          </Col>
          </Row>
            
  
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Dashboard
