var Ip = require('../service/Ip')

export const SEARCH_ALL = (token, url) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}${url}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const IMPORT_CUSTOMER_FROM_DB_DISTAR = (token, gid , data) => {
  return {
    method: 'post',
    url: `${Ip.getIP()}distar/import/customer/${gid}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    },
    data : data
  };
};

export const LIST_DATA = (token, page, pageSize, data) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer?page=${page}&limit=${pageSize}&order_by=desc&q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_NO_DATA = (token, page, pageSize) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer?page=${page}&limit=${pageSize}&order_by=desc`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CHECK_TAX_ID = (token, id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer/check-is-tax-duplicate/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CREATE_CUSTOMER = (token, data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/customer`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const REMOVE_CUSTOMER = (token, cus_id) => {
    return {
      method: 'delete',
      url: `${Ip.getIP()}api/v1/customer/${cus_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_DEALER = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/dealer`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CHECK_POSTCODE = (postcode) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/location/zip/${postcode}`,
      headers: {
        'Content-Type': 'application/json'
      }
    };
  };

  export const EDIT_CUSTOMER = (token , data , cus_id) => {
    return {
      method: 'put',  
      url: `${Ip.getIP()}api/v1/customer/${cus_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const UPLOAD_PROFILE = (token, formData) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}storage/upload`,
      headers: {
        "Content-type": "multipart/form-data",
        'x-access-token' : token
      },
      data : formData
    };
  };

  export const IMPORT_CUSTOMER_ALL_DATA = (token) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}distar/import-customer-data`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };