import React from 'react'
import { Form, Input, Button} from "antd"
import { Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next";

export default function Search_contract_table(props) {
  const { t } = useTranslation();

    const onFinish = values => {
        props.search_data_contract()
      }

    return (
        <Form
        name="Search_contract_table_info"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Form.Item
              name="name_search_contract_table_info"
              rules={[
                {
                  required: false,
                  message: "Please input data search",
                },
              ]}
            >
              <Input
                placeholder={t("name")}
                onChange={e => props.setData_search_contract(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={1} lg={1} xl={1}>
            <Button className="button-search-employee" type="primary" htmlType="submit" >
              {t("Search")}
            </Button>
          </Col>
        </Row>
      </Form>
    )
}
