import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { Form, Input, Select } from "antd"
import axios from "axios"
import * as api from "../../../service/Api_New_Customer"
import Edit_Map_location from './Edit_Map_location'

const { Option } = Select;
const { TextArea } = Input;

const style_req = {
    color :'red'
}
export default function Form_Edit_data(props) {
    const [position_, setPosition_] = useState([])
    const token = localStorage.getItem('token')

  useEffect(() => {
    render_select_postcode()
    return () => {}
  }, [props._list_location])

  function render_select_postcode() {
    return props._list_location.map(data => {
      return (
        <div
          key={data.zip_id}
          style={{ borderBottom: "1px solid #f0f0f0", padding: "10px" }}
        >
          <a>
            <p
              style={{ color: "#1890ff" }}
              onClick={() => {
                props.setBranch({
                  ...props.branch,
                  zip_id: data.zip_id,
                })
                props.setBranch_edit({
                    ...props.branch_edit,
                    zip_id: data.zip_id,
                })
                props.set_list_location([])
                   props.setLocation_select({
                        dis_name : data.dis_name ,
                        pro_name : data.pro_name ,
                        subdis_name : data.subdis_name ,
                        zip_code : data.zip_code
                   })
              }}
            >
              {data.subdis_name} - {data.dis_name} - {data.pro_name} -{" "}
              {data.zip_code}
            </p>
          </a>
        </div>
      )
    })
  }

  useEffect(() => {
    render_position_select()
    return () => {}
  }, [props.location_select])

  function render_position_select() {
    if (props.location_select.subdis_name || props.location_select.dis_name || props.location_select.pro_name || props.location_select.zip_code) {
      return (
        <div>
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <p className="text-bold mt-3">Sub-district</p>
              <Input disabled value={props.location_select.subdis_name} />
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
              <p className="text-bold mt-3">District</p>
              <Input disabled value={props.location_select.dis_name} />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <p className="text-bold mt-3">Province</p>
              <Input disabled value={props.location_select.pro_name} />
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
            <p className="text-bold mt-3">Postcode</p>
            <Input disabled value={props.location_select.zip_code} />
          </Col>
          </Row>
          <Row>
          <Col>
            <p className="text-bold mt-3">
              Address
            </p>
            <TextArea 
              placeholder={props.branch.cus_branch_address}
              value={props.branch.cus_branch_address}
              onChange={(e) => {
                props.setBranch({
                    ...props.branch,
                    cus_branch_address : e.target.value
                  })
                  props.setBranch_edit({
                    ...props.branch_edit,
                    cus_branch_address: e.target.value,
                })
              }
            }
            />
          </Col>
        </Row>
        </div>
      )
    } else {
      return null
    }
  }

  useEffect(() => {
    return_pos_id(props.branch.cus_branch_contact_pos_id)
      return () => {
          
      }
  }, [props.branch.cus_branch_contact_pos_id])

  function return_pos_id(id) {
  return  position_.map( data => {
        if(data.pos_id === id){
            return data.pos_name
        }
    })
  }
 
  useEffect(() => {
    check_position_info()
      return () => {
          
      }
  }, [])

  function check_position_info() {
      const list_position = api.CHECK_POSITION(token)
      axios(list_position)
      .then( res => {
        setPosition_(res.data.position);
      })
      .catch( error => {
          console.log(error);
      })
  }

useEffect(() => {
    render_position_info()
    return () => {
        
    }
}, [position_])

function render_position_info() {
    return position_.map( data => {
        return <Option value={data.pos_id} key={data.pos_id}>{data.pos_name}</Option>
    })
}

  function onChange_Select_Edit(value) {
    props.setBranch_edit({
        ...props.branch_edit,
        cus_branch_contact_pos_id: value,
    })
  }

  return (
    <div>
      <Row>
        <Col sm={12} md={3} lg={3} xl={3}>
          <p className="text-bold mt-3">Branch code</p>
          <Input disabled placeholder={props.branch.cus_branch_code} />
        </Col>
        <Col sm={12} md={9} lg={9} xl={9}>
          <p className="text-bold mt-3">Branch name</p>
          <Input
            value={props.branch.cus_branch_name}
            placeholder={props.branch.cus_branch_name}
            onChange={e => 
             { 
                 props.setBranch({
                ...props.branch,
                cus_branch_name: e.target.value,
              })
              props.setBranch_edit({
                  ...props.branch_edit,
                  cus_branch_name: e.target.value,
              })
            }
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="text-bold mt-3">Select postcode</p>
          <Input
            type="number"
            placeholder={props.branch.zip_code}
            value={props.branch.zip_code}
            onChange={ (e) => 
                props.edit_postcode(e.target.value) 
            }
          />
        </Col>
      </Row>
      { render_select_postcode() }
      { render_position_select() }

    <Row>
    <Col sm={12} md={6} lg={6} xl={6}>
    <p className="text-bold mt-3">Phone number</p>
    <Input 
    type="number"
    value={props.branch.cus_branch_phone}
    placeholder={props.branch.cus_branch_phone}
    onChange={e =>
        { 
            props.setBranch({
           ...props.branch,
           cus_branch_phone : e.target.value
         })
         props.setBranch_edit({
             ...props.branch_edit,
             cus_branch_phone : e.target.value
         })
       }
       }
    />
    </Col>
    <Col sm={12} md={6} lg={6} xl={6}>
    <p className="text-bold mt-3">Fax number</p>
    <Input 
    value={props.branch.cus_branch_fax}
    placeholder={props.branch.cus_branch_fax}
    onChange={e =>
        { 
            props.setBranch({
           ...props.branch,
           cus_branch_fax : e.target.value
         })
         props.setBranch_edit({
             ...props.branch_edit,
             cus_branch_fax : e.target.value
         })
       }
       }
    />
    </Col>
    </Row>

    <Row>
       <Col sm={12} md={6} lg={6} xl={6}>
       <p className="text-bold mt-3">Mobile number</p>
       <Input 
    type="number"
    value={props.branch.cus_branch_mobile}
    placeholder={props.branch.cus_branch_mobile}
    onChange={e =>
        { 
            props.setBranch({
           ...props.branch,
           cus_branch_mobile : e.target.value
         })
         props.setBranch_edit({
             ...props.branch_edit,
             cus_branch_mobile : e.target.value
         })
       }
       }
    />
       </Col>
       <Col sm={12} md={6} lg={6} xl={6}>
       <p className="text-bold mt-3">E-mail</p>
       <Form.Item
       name="edit_data_email_new"
       rules={[
         {
           required: false,
           message: 'Please input your E-mail',
         },
       ]}
     >
       <Input 
       type="email"
       value={props.branch.cus_branch_email}
       placeholder={props.branch.cus_branch_email}
       onChange={e =>
           { 
               props.setBranch({
              ...props.branch,
              cus_branch_email : e.target.value
            })
            props.setBranch_edit({
                ...props.branch_edit,
                cus_branch_email : e.target.value
            })
          }
          }
       />
     </Form.Item>
       </Col>
    </Row>
    
    <Row>
    <Col sm={12} md={6} lg={6} xl={6}>
    <p className="text-bold mt-3">Contact firstname</p>
    <Input 
    value={props.branch.cus_branch_contact_firstname}
    placeholder={props.branch.cus_branch_contact_firstname}
    onChange={e =>
        { 
            props.setBranch({
           ...props.branch,
           cus_branch_contact_firstname : e.target.value
         })
         props.setBranch_edit({
             ...props.branch_edit,
             cus_branch_contact_firstname : e.target.value
         })
       }
       }
    />
    </Col>
    <Col sm={12} md={6} lg={6} xl={6}>
    <p className="text-bold mt-3">Contact lastname</p>
    <Input 
    value={props.branch.cus_branch_contact_lastname}
    placeholder={props.branch.cus_branch_contact_lastname}
    onChange={e =>
        { 
            props.setBranch({
           ...props.branch,
           cus_branch_contact_lastname : e.target.value
         })
         props.setBranch_edit({
             ...props.branch_edit,
             cus_branch_contact_lastname : e.target.value
         })
       }
       }
    />
    </Col>
    </Row>
   
    <Row>
       <Col sm={12} md={6} lg={6} xl={6}>
       <p className="text-bold mt-3">Contact position</p>
       <Form.Item
                name="select_position_info"
                rules={[
                  {
                    required: false,
                    message: 'Please input position',
                  },
                ]}
              >
              <Select
              style={{ width:'100%' }}
              showSearch
              placeholder={ return_pos_id(props.branch.cus_branch_contact_pos_id) }
              optionFilterProp="children"
              onChange={onChange_Select_Edit}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              >
              { render_position_info() }
            </Select>
              </Form.Item>
       </Col>
    </Row>

    <Row>
    <Col sm={12} md={6} lg={6} xl={6}>
    <p className="text-bold mt-3"> Contact mobile number</p>
    <Input 
    value={props.branch.cus_branch_contact_mobile}
    placeholder={props.branch.cus_branch_contact_mobile}
    onChange={e =>
        { 
            props.setBranch({
           ...props.branch,
           cus_branch_contact_mobile : e.target.value
         })
         props.setBranch_edit({
             ...props.branch_edit,
             cus_branch_contact_mobile : e.target.value
         })
       }
       }
    />
    </Col>
    <Col sm={12} md={6} lg={6} xl={6}>
    <p className="text-bold mt-3">Contact email</p>
    <Form.Item
            name="Edit_branch_contact_email_new"
            rules={[
              {
                required: false,
                message: "Please input contact email",
              },
            ]}
          >
          <Input 
          type="email"
          value={props.branch.cus_branch_contact_email}
          placeholder={props.branch.cus_branch_contact_email}
          onChange={e =>
              { 
                  props.setBranch({
                 ...props.branch,
                 cus_branch_contact_email : e.target.value
               })
               props.setBranch_edit({
                   ...props.branch_edit,
                   cus_branch_contact_email : e.target.value
               })
             }
             }
          />
          </Form.Item>
    </Col>
    </Row>

    <Row>
    <p className="text-bold mt-3">Search location</p>
    <p style={style_req}>* search or click on map to select location</p>
        <Edit_Map_location 
            branch={props.branch}
            branch_edit={props.branch_edit}
            setBranch_edit={props.setBranch_edit}
        />
    </Row>

    <Row>
        <Col sm={12} md={6} lg={6} xl={6}>
            <p className="text-bold mt-3">Latitude</p>
            <p style={style_req}>* search or click on map to select location</p>
            <Input 
            disabled
            placeholder={props.branch.cus_branch_lat}
            value={props.branch_edit.cus_branch_lat ? props.branch_edit.cus_branch_lat : props.branch.cus_branch_lat}
            />
        </Col>
        <Col sm={12} md={6} lg={6} xl={6}>
            <p className="text-bold mt-3">Longitude</p>
            <p style={style_req}>* search or click on map to select location</p>
            <Input 
            disabled
            placeholder={props.branch.cus_branch_long}
            value={props.branch_edit.cus_branch_long ? props.branch_edit.cus_branch_long : props.branch.cus_branch_long}
            />
        </Col>
    </Row>

    </div>
  )
}
