import React, { useState, useEffect } from "react"
import { Row, Col, Container, Card, CardBody } from "reactstrap"
import MetaTags from "react-meta-tags"
import { useHistory } from "react-router-dom"
import axios from "axios"
import * as api from "../../service/Api_System_Settings"
import { FcSettings } from "react-icons/fc"
import { MdKeyboardBackspace } from "react-icons/md"
import { Table, Button, Modal, Tooltip, notification, Form } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { RiDeleteBin6Line } from "react-icons/ri"
import Input_Comp from "./Components/Input_Comp"
import no_permi from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png"
import helpers from "../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
const { confirm } = Modal

export default function Product_group(props) {
  const { t } = useTranslation()
  const history = useHistory()
  const [form_create] = Form.useForm()
  const [form_edit] = Form.useForm()
  const token = localStorage.getItem("token")
  const [data_list, setData_list] = useState([])
  const [visible_edit, setVisible_edit] = useState(false)
  const [visible_create, setVisible_create] = useState(false)
  const [data_edit, setData_edit] = useState({})
  const [data_create, setData_create] = useState({})
  const [loading, setLoading] = useState(false)
  const { permissionAllService } = useSelector(({ Distar }) => Distar)
  const permi = localStorage.getItem("permi")
  const [permi_t_read, setPermi_t_read] = useState(false)
  const [permi_t_write, setPermi_t_write] = useState(false)
  const [permi_t_edit, setPermi_t_edit] = useState(false)
  const [permi_t_delete, setPermi_t_delete] = useState(false)

  useEffect(() => {
    if (permissionAllService) {
      if (permissionAllService.system_setting) {
        setPermi_t_read(permissionAllService.system_setting.read)
        setPermi_t_write(permissionAllService.system_setting.write)
        setPermi_t_edit(permissionAllService.system_setting.edit)
        setPermi_t_delete(permissionAllService.system_setting.delete)
      }
    }
    return () => {}
  }, [permissionAllService])

  useEffect(() => {
    check_data()
    return () => {}
  }, [])

  function check_data() {
    setLoading(true)
    const check_data_all = api.FIND_ALL_GROUP(token)
    axios(check_data_all)
      .then(res => {
        setData_list(res.data.data)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  function remove_data(id) {
    if (permi_t_delete === true) {
      return confirm({
        title: t("Confirm remove product group name"),
        icon: <ExclamationCircleOutlined />,
        centered: true,
        okText: t("Confirm"),
        okType: "danger",
        cancelText: t("Cancel"),
        onOk() {
          setLoading(true)
          const delete_data = api.DELETE_PRODUCT_GROUP(token, id)
          axios(delete_data)
            .then(res => {
              setLoading(false)
              openNotificationWithIcon(
                "error",
                t("Remove product group name error")
              )
            })
            .catch(error => {
              setLoading(false)
              if (error) {
                if (error.response) {
                  if (error.response.status === 401) {
                    helpers.re_login()
                    history.push("/login")
                  } else if (error.response.status === 404) {
                    openNotificationWithIcon(
                      "success",
                      t("Remove product group name success")
                    )
                    cancel_all_Form()
                    check_data()
                  } else {
                    openNotificationWithIcon(
                      "error",
                      t("Remove product group name error")
                    )
                  }
                }
              }
            })
        },
        onCancel() {},
      })
    } else if (permi_t_delete === false) {
      return Modal.error({
        title: t("no permission delete module"),
        centered: true,
      })
    }
  }

  const columns = [
    {
      title: t("Product group name"),
      dataIndex: "pro_group_name",
      key: "pro_group_name",
      render: text => <p>{text}</p>,
    },
    {
      title: "",
      dataIndex: "pro_group_id",
      key: "pro_group_id",
      render: text => (
        <Tooltip title={t("Remove product group name")}>
          <a>
            <RiDeleteBin6Line
              style={{ color: "red" }}
              size={20}
              onClick={() => remove_data(text)}
            />
          </a>
        </Tooltip>
      ),
    },
    {
      title: "",
      render: text => (
        <Tooltip title={t("Edit product group name")}>
          <a>
            <FcSettings
              size={20}
              onClick={() => {
                setVisible_edit(true)
                setData_edit({
                  pro_group_name: text.pro_group_name,
                  pro_group_id: text.pro_group_id,
                })
                form_edit.setFieldsValue({
                  pro_group_name: text.pro_group_name,
                })
              }}
            />
          </a>
        </Tooltip>
      ),
    },
  ]

  const onFinish_create = values => {
    return confirm({
      title: t("Confirm create product group name"),
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: t("Confirm"),
      cancelText: t("Cancel"),
      onOk() {
        setLoading(true)
        const create_product_group = api.CREATE_PRODUCT_GROUP(
          token,
          data_create
        )
        axios(create_product_group)
          .then(res => {
            openNotificationWithIcon(
              "success",
              t("Create new product group name success")
            )
            check_data()
            setLoading(false)
            cancel_create_Form()
          })
          .catch(error => {
            setLoading(false)
            if (error) {
              if (error.response) {
                if (error.response.status === 401) {
                  helpers.re_login()
                  history.push("/login")
                } else {
                  openNotificationWithIcon(
                    "error",
                    t("Create new product group name error")
                  )
                }
              }
            }
          })
      },
      onCancel() {},
    })
  }

  const cancel_create_Form = () => {
    form_create.resetFields()
    setVisible_create(false)
    setData_create({})
  }

  const onFinish_edit = values => {
    return confirm({
      title: t("Confirm edit product group name"),
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: t("Confirm"),
      cancelText: t("Cancel"),
      onOk() {
        setLoading(true)
        const data_edit_all = {
          pro_group_name: data_edit.pro_group_name,
        }
        const edit_product_group = api.EDIT_PRODUCT_GROUP(
          token,
          data_edit.pro_group_id,
          data_edit_all
        )
        axios(edit_product_group)
          .then(res => {
            openNotificationWithIcon(
              "success",
              t("Edit new product group name success")
            )
            check_data()
            setLoading(false)
            cancel_edit_Form()
          })
          .catch(error => {
            setLoading(false)
            if (error) {
              if (error.response) {
                if (error.response.status === 401) {
                  helpers.re_login()
                  history.push("/login")
                } else {
                  openNotificationWithIcon(
                    "error",
                    t("Create new product group name error")
                  )
                }
              }
            }
          })
      },
      onCancel() {},
    })
  }

  const cancel_edit_Form = () => {
    form_edit.resetFields()
    setVisible_edit(false)
    setData_edit({})
  }

  function cancel_all_Form() {
    cancel_create_Form()
    cancel_edit_Form()
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Product group Settings")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/system-setting")}
              />
            </a>{" "}
            {t("Product group Settings")}
          </h3>
          {permi_t_read === true && (
            <Card>
              <CardBody>
                {permi_t_write === true && (
                  <Button
                    className="btn-create-new-sub-product"
                    type="primary"
                    onClick={() => {
                      setVisible_create(true)
                      setData_create({
                        pro_group_name: null,
                      })
                    }}
                  >
                    {t("Create new product group")}
                  </Button>
                )}

                <Table
                  loading={loading}
                  rowKey="pro_group_id"
                  columns={columns}
                  dataSource={data_list}
                  scroll={{ x: 400 }}
                />

                <Modal
                  centered
                  open={visible_create}
                  footer={false}
                  onCancel={() => {
                    setVisible_create(false)
                    setData_create({})
                    cancel_create_Form()
                  }}
                  // width={1000}
                >
                  <Form
                    form={form_create}
                    name="form_create_data"
                    onFinish={onFinish_create}
                  >
                    <Input_Comp
                      placeholder={t("Product group name")}
                      header={t("Product group name")}
                      headState={setData_create}
                      nameState={data_create}
                      keyState="pro_group_name"
                      type="text"
                      required={true}
                    />
                    <br />
                    <Row>
                      <Col sm={12} md={6} lg={6} xl={6}>
                        <div className="div_create_new_car">
                          <Button
                            className="btn_cancel_create_new_car"
                            onClick={() => cancel_create_Form()}
                          >
                            {t("Cancel")}
                          </Button>
                        </div>
                      </Col>
                      <Col sm={12} md={6} lg={6} xl={6}>
                        <div className="div_create_new_car">
                          <Button
                            className="btn_submit_create_new_car"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                          >
                            {t("Create")}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Modal>

                <Modal
                  centered
                  open={visible_edit}
                  footer={false}
                  onCancel={() => {
                    setVisible_edit(false)
                    setData_edit({})
                    cancel_edit_Form()
                  }}
                  // width={1000}
                >
                  {permi_t_edit === true && (
                    <Form
                      form={form_edit}
                      name="form_edit_data"
                      onFinish={onFinish_edit}
                    >
                      <Input_Comp
                        placeholder={t("Product group name")}
                        header={t("Product group name")}
                        headState={setData_edit}
                        nameState={data_edit}
                        keyState="pro_group_name"
                        type="text"
                        required={false}
                      />
                      <br />
                      <Row>
                        <Col sm={12} md={6} lg={6} xl={6}>
                          <div className="div_create_new_car">
                            <Button
                              className="btn_cancel_create_new_car"
                              onClick={() => cancel_edit_Form()}
                            >
                              {t("Cancel")}
                            </Button>
                          </div>
                        </Col>
                        <Col sm={12} md={6} lg={6} xl={6}>
                          <div className="div_create_new_car">
                            <Button
                              className="btn_submit_create_new_car"
                              type="primary"
                              htmlType="submit"
                              loading={loading}
                            >
                              {t("Edit")}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  )}
                  {permi_t_edit === false && (
                    <div>
                      <p
                        style={{
                          color: "red",
                          textAlign: "center",
                          fontSize: "20px",
                        }}
                      >
                        {t("no permission edit module")}
                      </p>
                      <p
                        style={{
                          color: "red",
                          textAlign: "center",
                          fontSize: "20px",
                        }}
                      >
                        {t("please contract administrator")}
                      </p>
                    </div>
                  )}
                </Modal>
              </CardBody>
            </Card>
          )}
          {permi_t_read === false && (
            <div>
              <img
                src={no_permi}
                alt="no permission"
                style={{ display: "block", margin: "50px auto" }}
              />
              <p style={{ textAlign: "center", fontSize: "20px" }}>
                {t("no permission read module")}
              </p>
              <p style={{ textAlign: "center", fontSize: "20px" }}>
                {" "}
                {t("please contract administrator")}{" "}
              </p>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
