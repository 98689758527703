var Ip = require('../service/Ip')

export const LIST_DEALER_NO_DATA = (token, page, pageSize) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/dealer/paginate?page=${page}&limit=${pageSize}&order_by=desc`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_DEALER_DATA = (token, page, pageSize, data) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/dealer/paginate?page=${page}&limit=${pageSize}&order_by=desc&q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const SEARCH_ALL = (token, url) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}${url}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };