import React, { useState } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { loginUser, apiError, socialLogin } from "../../store/actions"
import logo_distar from "../../assets/Distar/logo_login.jpeg"
import axios from 'axios'
import * as api from '../../service/Api_Login'
import { Spin } from 'antd'
import { useTranslation } from "react-i18next";

const Login = props => {
  const [error_res, setError_res] = useState(null)
  const [loading, setLoading] = useState(false)
  let history = useHistory();
  const { t } = useTranslation();
  
  const handleValidSubmit = (event, values) => {
    setLoading(true)
    const login_api = api.LOGIN(values);
    axios(login_api)
    .then( res => {
      setLoading(false)
      localStorage.setItem('token',res.data.token)
      localStorage.setItem('role',res.data.role)
      localStorage.setItem('_id',res.data.user_id)
      if(res.data.root !== undefined){
        localStorage.setItem('r',res.data.root)
      }

      history.push("/dashboards");
    })
    .catch( error => {
      setLoading(false)
      if(error){
        if(error.response){
          setError_res(error.response.data.message);
        } else {
          setError_res('Server error');
        }
      }
    })
  }

  const textColor = {
    color: "#0084d1",
  }

  return (
    <div>
      <MetaTags>
        <title> {t("Login")} | Distar</title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft">
                  <Row>
                    <img
                      src={logo_distar}
                      alt="logo"
                      style={{
                        width: "80%",
                        height:'100%',
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: "50px",
                        marginTop: "100px",
                      }}
                    />
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {props.error && typeof props.error === "string" ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label={t("email")}
                          className="form-control"
                          placeholder={t("email")}
                          type="email"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label={t("password")}
                          type="password"
                          required
                          placeholder={t("password")}
                        />
                      </div>
                    <br />
                      {
                        error_res && (
                          <p style={{ color:'red', textAlign:'center', fontSize:'15px' }}>{error_res}</p>
                        )
                      }

                      {
                        loading === true && (
                          <Spin style={{ display:'block' , margin:'auto' }} />
                        )
                      }

                      <div className="mt-3 d-grid">
                        <button
                          style={{ backgroundColor: "#0084d1", color: "#FFF" }}
                          className="btn btn-block waves-effect waves-light"
                          type="submit"
                        >
                        {t("log in")}
                        </button>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>Powered by Nok Soft Co .,Ltd .</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
}
