import React, { useEffect, useState } from "react"
import { Form, Input } from "antd"
import { Row, Col } from "reactstrap"
import * as api from "../../../../../service/Api_Create_new_dealer"
import axios from "axios"
import { useTranslation } from "react-i18next";

const { TextArea } = Input

export default function Select_Postcode(props) {
  const { t } = useTranslation();
  const style_req = {
    color: "red",
  }

  useEffect(() => {
      
      return () => {
        props.setLocation_select({})
        props.setPostcode_select([])
      }
  }, [])

  function check_postcode(postcode) {
    if (postcode.length === 0) {
        props.setPostcode_select([])
      props.setLocation_select({
        dis_name: null,
        pro_name: null,
        subdis_name: null,
        zip_code: null,
      })
    } else if (postcode.length >= 4) {
      const check_zip_postcode = api.CHECK_POSTCODE(postcode)
      axios(check_zip_postcode)
        .then(res => {
            props.setPostcode_select(res.data.message)
        })
        .catch(error => {
          // console.log(error);
        })
    }
  }

  function render_select_postcode() {
    return props.postcode_select.map(data => {
      return (
        <div
          key={data.zip_id}
          style={{ borderBottom: "1px solid #f0f0f0", padding: "10px" }}
        >
          <a>
            <p
              style={{ color: "#1890ff" }}
              onClick={() => {
                props.setData_dealer({
                  ...props.data_dealer,
                  zip_id: data.zip_id,
                })
                props.setPostcode_select([])
                props.setLocation_select({
                  ...props.location_select,
                  dis_name: data.dis_name,
                  pro_name: data.pro_name,
                  subdis_name: data.subdis_name,
                  zip_code: data.zip_code,
                })
              }}
            >
              {data.subdis_name} - {data.dis_name} - {data.pro_name} -{" "}
              {data.zip_code}
            </p>
          </a>
        </div>
      )
    })
  }
  function render_location_select() {
    if (
        props.location_select.dis_name ||
        props.location_select.pro_name ||
        props.location_select.subdis_name ||
        props.location_select.zip_code
    ) {
      return (
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <p className="text-bold">
              {t("Sub-district")} <span style={style_req}>*</span>
            </p>
            <Input placeholder={props.location_select.subdis_name} disabled />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <p className="text-bold">
              {t("District")} <span style={style_req}>*</span>
            </p>
            <Input placeholder={props.location_select.dis_name} disabled />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <p className="text-bold mt-3">
              {t("Province")} <span style={style_req}>*</span>
            </p>
            <Input placeholder={props.location_select.pro_name} disabled />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <p className="text-bold mt-3">
              {t("Postcode")} <span style={style_req}>*</span>
            </p>
            <Input placeholder={props.location_select.zip_code} disabled />
          </Col>
        </Row>
      )
    } else {
      return null
    }
  }

  function render_location_address() {
    if (
        props.location_select.dis_name ||
        props.location_select.pro_name ||
        props.location_select.subdis_name ||
        props.location_select.zip_code
    ) {
      return (
        <Row>
          <Col>
            <p className="text-bold  mt-3">
              {t("Address")} <span style={style_req}>*</span>
            </p>
            <Form.Item
              name="Branch_address"
              rules={[
                {
                  required: true,
                  message: t("Address"),
                },
              ]}
            >
              <TextArea
                placeholder={t("Address")}
                onChange={e =>
                  props.setData_dealer({
                    ...props.data_dealer,
                    dea_address: e.target.value,
                  })
                }
              />
            </Form.Item>
          </Col>
        </Row>
      )
    } else {
      return null
    }
  }

  useEffect(() => {
    render_select_postcode()
    render_location_select()
    render_location_address()
    return () => {

    }
  }, [props.postcode_select])

  return (
    <div>
      <p className="text-bold">
        {t("Postcode")} <span style={{ color: "red" }}>*</span>
      </p>
      <Form.Item
        name="select_postcode"
        rules={[
          {
            required: true,
            message: t("Postcode"),
          },
        ]}
      >
        <Input
          type="number"
          placeholder={t("Postcode")}
          onChange={e => check_postcode(e.target.value)}
        />
      </Form.Item>
      {render_select_postcode()}
      {render_location_select()}
      {render_location_address()}
    </div>
  )
}
