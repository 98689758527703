import React, { useRef, useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { Form, Button, notification, Avatar, Switch, Modal } from "antd"
import Input_Comp from "./components/Input_Comp"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import Select_no_search from "./components/Select_no_search"
import * as api from "../../service/Api_New_Customer"
import axios from "axios"
import { QuestionOutlined } from "@ant-design/icons"
import Render_Center_Avatar from "../Operator/Table_data/Render_Center_Avatar"
import { FaRegUser } from "react-icons/fa"
import Input_Comp_Show_Only from "./components/Input_Comp_Show_Only"
import { useHistory } from "react-router-dom"
import helpers from "../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"

const { confirm } = Modal

export default function Edit_User(props) {
  const token = localStorage.getItem("token")
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const history = useHistory()
  const imageUploader = useRef(null)
  const [edit, setEdit] = useState(true)
  const [loading, setLoading] = useState(false)
  const [image_select_upload, setImage_select_upload] = useState(null)
  const [user_edit, setUser_edit] = useState({
    not_send_email_notification: false,
    not_send_line_notification: false,
  })

  const handleImageUpload = e => {
    if (e.target.files[0] !== undefined) {
      if (e.target.files[0].type !== "image/jpeg") {
        openNotificationWithIcon(
          "error",
          t("please upload jpg or jpeg image only")
        )
      } else {
        let img_upload = e.target.files[0]
        setImage_select_upload(img_upload)
      }
    }
  }

  const openNotificationWithIcon = (type, description) => {
    notification[type]({
      message: description,
    })
  }

  function check_handle_info(check) {
    switch (check) {
      case true:
        return false
        break
      case false:
        return true
        break
      default:
        break
    }
  }

  useEffect(() => {
    setUser_edit({
      ...user_edit,
      not_send_line_notification: props.data_user.not_send_line_notification,
      not_send_email_notification: props.data_user.not_send_email_notification,
      cus_user_firstname: props.data_user.cus_user_firstname,
      cus_user_lastname: props.data_user.cus_user_lastname,
      cus_user_phone: props.data_user.cus_user_phone,
      dep_id: props.data_user.dep_id,
      pos_id: props.data_user.pos_id,
    })
    onFill()

    return () => {}
  }, [props.data_user])

  const onFill = () => {
    form.setFieldsValue({
      cus_user_firstname: props.data_user.cus_user_firstname,
      cus_user_lastname: props.data_user.cus_user_lastname,
      cus_user_phone: props.data_user.cus_user_phone,
      cus_user_email: props.data_user.cus_user_email,
      dep_id: props.data_user.dep_id,
      pos_id: props.data_user.pos_id,
    })
    setUser_edit({
      not_send_line_notification: props.data_user.not_send_line_notification,
      not_send_email_notification: props.data_user.not_send_email_notification,
    })
  }

  function check_before_edit(value) {
    switch (value) {
      case true:
        return 1
        break
      case false:
        return 0
        break
      default:
        break
    }
  }

  const onFinish = value => {
    return confirm({
      title: t("edit user info"),
      icon: <QuestionOutlined style={{ color: "#1890ff" }} />,
      centered: true,
      okText: t("edit"),
      cancelText: t("no"),
      onOk() {
        setLoading(true)
        const data = {
          cus_user_firstname: user_edit.cus_user_firstname,
          cus_user_lastname: user_edit.cus_user_lastname,
          cus_user_phone: user_edit.cus_user_phone,
          dep_id: user_edit.dep_id,
          pos_id: user_edit.pos_id,
          not_send_line_notification: check_before_edit(
            user_edit.not_send_line_notification
          ),
          not_send_email_notification: check_before_edit(
            user_edit.not_send_email_notification
          ),
        }
        if (image_select_upload !== null) {
          const formData = new FormData()
          formData.append("file", image_select_upload)
          const upload_image = api.UPLOAD_PROFILE(token, formData)
          axios(upload_image)
            .then(res => {
              let add_image_path = {
                cus_user_avatar: res.data.path,
                ...data,
              }
              edit_user(add_image_path)
            })
            .catch(error => {
              setLoading(false)
              if (error) {
                if (error.response) {
                  if (error.response.status === 401) {
                    helpers.re_login()
                    history.push("/login")
                  } else {
                    openNotificationWithIcon("error", t("edit user error"))
                  }
                }
              }
            })
        } else {
          edit_user(data)
        }
      },
      onCancel() {},
    })
  }

  function edit_user(data) {
    const edit_ = api.EDIT_USER(token, props.user_id, data)
    axios(edit_)
      .then(res => {
        setLoading(false)
        props.check_detail_user(props.user_id)
        openNotificationWithIcon("success", t("edit user success"))
        Cancel_Edit()
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            } else {
              openNotificationWithIcon("error", t("edit user error"))
            }
          }
        }
      })
  }

  function Cancel_Edit() {
    setEdit(!edit)
    setImage_select_upload(null)
    onFill()
  }

  function onChange_notification_line(checked) {
    if (checked === true) {
      setUser_edit({
        ...user_edit,
        not_send_line_notification: false,
      })
    } else if (checked === false) {
      setUser_edit({
        ...user_edit,
        not_send_line_notification: true,
      })
    }
  }

  function onChange_notification_email(checked) {
    if (checked === true) {
      setUser_edit({
        ...user_edit,
        not_send_email_notification: false,
      })
    } else if (checked === false) {
      setUser_edit({
        ...user_edit,
        not_send_email_notification: true,
      })
    }
  }

  return (
    <div>
      <Form form={form} name="control-hooks" onFinish={onFinish}>
        <Row>
          <Col>
            <div style={{ display: "inherit" }}>
              <p
                className="text-bold"
                style={{
                  color: "#495057",
                  textAlign: "center",
                  fontSize: "15px",
                }}
              >
                {t("user information")}
              </p>
              {edit === true && (
                <Button
                  style={{ float: "right", width: "150px" }}
                  onClick={() => setEdit(!edit)}
                >
                  {t("edit")}
                </Button>
              )}
              {edit === false && (
                <Button
                  type="primary"
                  style={{ float: "right", width: "150px" }}
                  onClick={() => Cancel_Edit()}
                >
                  {t("cancel")}
                </Button>
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <input
              type="file"
              accept="image/jpeg"
              onChange={handleImageUpload}
              ref={imageUploader}
              style={{
                display: "none",
              }}
            />
            {image_select_upload === null && (
              <Render_Center_Avatar
                path_image={props.data_user.cus_user_avatar}
                size={120}
              />
            )}
            {image_select_upload !== null && (
              <div className="image-upload">
                <Avatar
                  src={URL.createObjectURL(image_select_upload)}
                  size={120}
                  style={{ display: "block", margin: "auto" }}
                />
              </div>
            )}

            {edit !== true && (
              <Button
                type="primary"
                style={{
                  width: "150px",
                  display: "block",
                  margin: "20px auto 20px auto",
                }}
                onClick={() => imageUploader.current.click()}
              >
                {t("upload photo")}
              </Button>
            )}
            <br />
          </Col>
        </Row>

        <br />
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("firstname")}
              placeholder={props.data_user.cus_user_firstname}
              headState={setUser_edit}
              nameState={user_edit}
              disabled={edit}
              keyState="cus_user_firstname"
              type="text"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("lastname")}
              placeholder={props.data_user.cus_user_lastname}
              headState={setUser_edit}
              nameState={user_edit}
              disabled={edit}
              keyState="cus_user_lastname"
              type="text"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("phone")}
              placeholder={props.data_user.cus_user_phone}
              headState={setUser_edit}
              nameState={user_edit}
              disabled={edit}
              keyState="cus_user_phone"
              type="number"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp_Show_Only
              header={t("email")}
              placeholder={props.data_user.cus_user_email}
              headState={setUser_edit}
              nameState={user_edit}
              disabled={true}
              keyState="cus_user_email"
              type="email"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("department")}
              placeholder={t("department")}
              name_api="CHECK_DEPARTMENT"
              map_name="dep_name"
              map_value="dep_id"
              keyState="dep_id"
              Header_data="data"
              headState={setUser_edit}
              nameState={user_edit}
              disabled={edit}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("position")}
              placeholder={t("position")}
              name_api="CHECK_POSITION"
              map_name="pos_name"
              map_value="pos_id"
              keyState="pos_id"
              Header_data="data"
              headState={setUser_edit}
              nameState={user_edit}
              disabled={edit}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="text-bold mt-4">{t("receive notifications")}</p>
            <Form.Item
              name="email_notification"
              rules={[
                {
                  required: false,
                  message: "Please input position ",
                },
              ]}
            >
              <p>
                {t("subscribe email notification")}
                <Switch
                  disabled={edit}
                  checked={check_handle_info(
                    user_edit.not_send_email_notification
                  )}
                  style={{ marginLeft: "15px" }}
                  onChange={onChange_notification_email}
                />
              </p>
            </Form.Item>

            <Form.Item
              name="line_notification"
              rules={[
                {
                  required: false,
                  message: "Please input position ",
                },
              ]}
            >
              <p>
                {t("subscribe line notification")}
                <Switch
                  disabled={edit}
                  checked={check_handle_info(
                    user_edit.not_send_line_notification
                  )}
                  style={{ marginLeft: "20px" }}
                  onChange={onChange_notification_line}
                />
              </p>
            </Form.Item>
          </Col>
        </Row>

        <br />
        {edit === false && (
          <Button
            type="primary"
            htmlType="submit"
            style={{ display: "block", margin: "auto", width: "150px" }}
            loading={loading}
          >
            {t("edit")}
          </Button>
        )}
      </Form>
    </div>
  )
}
