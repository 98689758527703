import React, { useState, useEffect } from "react"
import { Tabs, Button } from "antd"
import TextForm from "./TextForm"
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs

export default function index(props) {
  const [activeTab, setActiveTab] = useState("0")
  const { t } = useTranslation();

  const handleInputChange_Detail = (index_step, index_fields, event) => {
    const values = [...props.task_hast_form]
    values[index_step].fields[index_fields].value = event.target.value
    props.setTask_hast_form(values)
  }

  return (
    <>
      <Tabs
        defaultActiveKey="0"
        onTabClick={e => {
          setActiveTab(e)
        }}
        activeKey={activeTab}
      >
        {props.stepField.map((data, index) => {
          return (
            <TabPane tab={`${t("Step")} ${index + 1}`} key={index}>
              <TextForm
              ser_id={props.ser_id}
              setTask_hast_form={props.setTask_hast_form}
                task_hast_form={props.task_hast_form}
                handleInputChange_Detail={handleInputChange_Detail}
                fields={data.fields}
                priority={props.priority}
                check_data_submit={props.check_data_submit}
                Check_service_form={props.Check_service_form}
                last_step={props.stepField.length}
                index_step={index}
                setActiveTab={props.setActiveTab}
              />
              <hr />
              {index > 0 && index < props.stepField.length - 1 && (
                <div>
                  <Button
                    onClick={() => setActiveTab((index - 1).toString())}
                    style={{ float: "left", width: "80px" }}
                  >
                    {t("Previous")} 
                  </Button>
                  <Button
                    onClick={() => setActiveTab((index + 1).toString())}
                    style={{ float: "right", width: "80px" }}
                  >
                    {t("Next")} 
                  </Button>
                </div>
              )}
              
              {index === 0 && (
                <Button
                  onClick={() => setActiveTab((index + 1).toString())}
                  style={{ float: "right", width: "80px" }}
                >
                {t("Next")} 
                </Button>
              )}
              {index === props.stepField.length - 1 && (
                <Button
                  onClick={() => setActiveTab((index - 1).toString())}
                  style={{ float: "left", width: "80px" }}
                >
                {t("Previous")}  
                </Button>
              )}

            </TabPane>
          )
        })}
      </Tabs>
    </>
  )
}
