var Ip = require('../Ip')

export const SEARCH_ALL = (token, url) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}${url}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_RECEIVED_TYPE = (token) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/service/received_type`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const CHECK_USER_CONNECTED_LINE = (token) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/customer/check_line_status`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const CHECK_USER_CUSTOMER = (token,user_id) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/customer/users/${user_id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const CREATE_NEW_REPAIR = (token , data) => {
  return {
    method: 'post',
    url: `${Ip.getIP()}api/v1/requestservice`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    },
    data : data
  };
};

export const LIST_REPAIR_ALL = (token) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/requestservice`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};