import React, { useState, useEffect } from "react"
import Search_data_Comp from "./Search_data_Comp"
import { Table, Button, Pagination, Tooltip, Modal } from "antd"
import Render_Image_Data from "../../../Employee/Table/Render_Image_Data"
import { FcSearch, FcSettings, FcDataSheet } from "react-icons/fc"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import Detail_User from "./Detail_User"

export default function Table_user(props) {
  const history = useHistory()
  const { t } = useTranslation()
  const [data_table, setData_table] = useState([])
  const [detail_user_info, setDetail_user_info] = useState(null)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setData_table(props.data_User_All)
    return () => {}
  }, [props.data_User_All])

  useEffect(() => {
    return () => {
      setData_table([])
      setDetail_user_info(null)
    }
  }, [])

  const columns = [
    {
      title: "",
      dataIndex: "cus_user_avatar",
      key: "cus_user_avatar",
      render: text => <Render_Image_Data path_image={text} />,
    },
    {
      title: t("firstname"),
      dataIndex: "cus_user_firstname",
      key: "cus_user_firstname",
      render: text => <p>{text}</p>,
    },
    {
      title: t("lastname"),
      dataIndex: "cus_user_lastname",
      key: "cus_user_lastname",
      render: text => <p>{text}</p>,
    },
    {
      title: t("branch name"),
      dataIndex: "cus_branch_name",
      key: "cus_branch_name",
      render: text => <div>{check_branch_name(text)}</div>,
    },
    {
      title: "",
      render: text => (
        <Tooltip title={t("View user")}>
          <a>
            <FcSearch
              onClick={() => {
                setVisible(true)
                setDetail_user_info(text)
              }}
              size={20}
            />
          </a>
        </Tooltip>
      ),
    },
    {
      title: "",
      dataIndex: "cus_user_id",
      key: "cus_user_id",
      render: text => (
        <Tooltip title={t("edit user")}>
          <a>
            <FcSettings
              onClick={() =>
                history.push(`/customers/${props.id}/edit-user/${text}`)
              }
              size={20}
            />
          </a>
        </Tooltip>
      ),
    },
  ]

  function check_branch_name(cus_branch_name) {
    if (cus_branch_name) {
      return <p>{cus_branch_name}</p>
    } else {
      return <p>N/A</p>
    }
  }

  return (
    <div>
      <Search_data_Comp
        setSearch_data_user={props.setSearch_data_user}
        search_data_user_table={props.search_data_user_table}
      />

      <Table
        loading={props.loading}
        rowKey="cus_user_id"
        columns={columns}
        dataSource={data_table}
        pagination={false}
        scroll={{ x: 800 }}
      />

      <Pagination
        current={props.page_limit.page}
        total={props.page_limit.limit}
        onChange={props.changePagination_users}
        style={{ marginTop: "20px", float: "right" }}
      />
      <p style={{ fontSize: "15px", marginTop: "25px" }}>
        {" "}
        <FcDataSheet size={20} style={{ marginBottom: "5px" }} />{" "}
        {t("total data")} : {props.page_limit.limit}
      </p>

      <Modal
        centered
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={() => {
          setVisible(false)
          setDetail_user_info(null)
        }}
        footer={false}
        width={1000}
        height={500}
      >
        <Detail_User detail_user_info={detail_user_info} />
      </Modal>
    </div>
  )
}
