import React, { useState, useEffect } from "react"
import { Form, Switch, Button, Modal, Collapse, Tooltip, Result } from "antd"
import { Row, Col } from "reactstrap"
import { useHistory } from "react-router-dom"
import Select_no_search from "./components/Select_no_search"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import axios from "axios"
import * as api from "../../../service/Api_Import_Stock_From_DB_Distar"
import { FcDeleteDatabase, FcAcceptDatabase } from "react-icons/fc"
import helpers from "../../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next";

const { Panel } = Collapse
const { confirm } = Modal

export default function Import_Stock(props) {
  const [form] = Form.useForm()
  const { t } = useTranslation();
  const history = useHistory()
  const [stock_import, setStock_import] = useState({})
  const [loading, setLoading] = useState(false)
  const token = localStorage.getItem("token")
  const [customer_info, setCustomer_info] = useState([])
  const [customer_name, setCustomer_name] = useState(null)
  const [customer_in_db, setCustomer_in_db] = useState(false)
  const [sto_id, setSto_id] = useState(0)

  const text_header = {
    fontWeight: "bold",
    fontSize: "15px",
  }

  useEffect(() => {
    return () => {
      setSto_id(0)
      setCustomer_name(null)
      setCustomer_info([])
      setStock_import({})
    }
  }, [])

  useEffect(() => {
    setStock_import({
      pro_model_code: props.import_stock_select.pro_model_code,
      pro_model_name: props.import_stock_select.device_group,
      pro_model_factory: props.import_stock_select.factory_group,
      pro_model_protocol: props.import_stock_select.pro_model_protocol,

      pro_model_remark: "-",
      pro_img: "img",
      pro_group_id: null, // select
      device_serial_number: props.import_stock_select.device_serial_number,

      pro_remark: props.import_stock_select.device_remark,
      pro_warranty_id: null, // select
      import_customer: true, // select
      create_group_name: "-",

      new_group_name: false,
      sim_id: props.import_stock_select.sim_id,
      iccid: props.import_stock_select.iccid,
      sim_remark: props.import_stock_select.sim_remark,

      phone: props.import_stock_select.sim_phone,
    })
    check_customer(props.import_stock_select.car_id)
    return () => {
      onReset()
      setSto_id(0)
      props.setImport_success(false)
      setCustomer_name(null)
      setCustomer_info([])
      setStock_import({})
    }
  }, [props.import_stock_select])

  function check_customer(car_id) {
    const check_cus = api.CHECK_CUSTOMER(token, car_id)
    axios(check_cus)
      .then(res => {
        setCustomer_name(res.data.distar_customer.title)
        setCustomer_info([
          {
            header1: "title",
            dataContract1: checkData(res.data.distar_customer.title),
            header2: "uid",
            dataContract2: checkData(res.data.distar_customer.uid),
          },
          {
            header1: "gid",
            dataContract1: checkData(res.data.distar_customer.gid),
            header2: "pid",
            dataContract2: checkData(res.data.distar_customer.pid),
          },
          {
            header1: "regtime",
            dataContract1: cutTimeZone(res.data.distar_customer.regtime),
            header2: "offtime",
            dataContract2: cutTimeZone(res.data.distar_customer.offtime),
          },
          {
            header1: "cid",
            dataContract1: checkData(res.data.distar_customer.cid),
            header2: "remark",
            dataContract2: checkData(res.data.distar_customer.remark),
          },
        ])
        setCustomer_in_db(res.data.found_my_customer)
      })
      .catch(error => {
        // console.log(error)
        if(error){
          if(error.response){
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  const onReset = () => {
    form.resetFields()
    setStock_import({})
  }

  function cancelForm() {
    onReset()
    props.setSelect_stock_modal(false)
    props.setImport_stock_select({})
  }

  function onChangeSwitchPass(checked) {
    setStock_import({
      ...stock_import,
      import_customer: checked,
    })
  }

  function checkData(data) {
    if (data) {
      return data
    } else {
      return "N/A"
    }
  }

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return "N/A"
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  const onFinish = values => {
    return confirm({
      title: t("Confirm import stock") ,
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: t("Confirm"),
      cancelText: t("no"),
      onOk() {
        setLoading(true)
        const import_stock_info = api.IMPORT_STOCK(token, stock_import)
        axios(import_stock_info)
          .then(res => {
            // console.log(res.data);
            setSto_id(res.data.sto_id)
            setLoading(false)
            props.setImport_success(true)
          })
          .catch(error => {
            setLoading(false)
            // console.log(error.response)
            if(error){
              if(error.response){
                if (error.response.status === 401) {
                  helpers.re_login()
                  history.push("/login")
                }
              }
            }
          })
      },
      onCancel() {},
    })
  }
  // setImport_success={setImport_success}
  // import_success={import_success}
  return (
    <div>
      {props.import_success === false && (
        <div>
          <p
            style={{
              textAlign: "center",
              fontWeight: "bold",
              marginTop: "10px",
              fontSize: "15px",
            }}
          >
            {t("Import stock")}
          </p>
          <Collapse>
            <Panel
              header={
                customer_name ? `${t("Customer")} : ${customer_name}` : t("No customer")
              }
              key="1"
            >
              {customer_info.map((data, index) => {
                return (
                  <Row key={index}>
                    <Col sm={6} md={2} lg={2} xl={2}>
                      <p style={text_header}>{data.header1}</p>
                    </Col>
                    <Col sm={6} md={4} lg={4} xl={4}>
                      <p style={{ fontSize: "15px" }}>
                        {" "}
                        <span className="tet">:</span>{" "}
                        <span className="tetTwo">-</span> {data.dataContract1}
                      </p>
                    </Col>
                    <Col sm={6} md={2} lg={2} xl={2}>
                      <p style={text_header}>{data.header2}</p>
                    </Col>
                    <Col sm={6} md={4} lg={4} xl={4}>
                      <p style={{ fontSize: "15px" }}>
                        {" "}
                        <span className="tet">:</span>{" "}
                        <span className="tetTwo">-</span> {data.dataContract2}
                      </p>
                    </Col>
                  </Row>
                )
              })}

              <Row>
                <Col sm={6} md={2} lg={2} xl={2}>
                  <p style={text_header}> {t("Customer in database")} </p>
                </Col>
                <Col sm={6} md={4} lg={4} xl={4}>
                  <p style={{ fontSize: "15px" }}>
                    {" "}
                    <span className="tet">:</span>{" "}
                    <span className="tetTwo">-</span>
                    {customer_in_db === true && (
                      <Tooltip title={t("This customer already exists in the database")} >
                        <FcAcceptDatabase size={25} />
                      </Tooltip>
                    )}
                    {customer_in_db === false && (
                      <Tooltip title={t("This customer was not found in the database")} >
                        <FcDeleteDatabase size={25} />
                      </Tooltip>
                    )}
                  </p>
                </Col>
              </Row>
            </Panel>
          </Collapse>
          <br />
          <Form form={form} name="control-hooks" onFinish={onFinish}>
            <Row>
              <Col sm={12} md={6} lg={6} xl={6}>
                <Select_no_search
                  header={t("Product group")}
                  placeholder={t("Product group")}
                  map_name="pro_group_name"
                  map_value="pro_group_id"
                  keyState="pro_group_id"
                  data_select_array={props.group_list}
                  headState={setStock_import}
                  nameState={stock_import}
                />
              </Col>
              <Col sm={12} md={6} lg={6} xl={6}>
                <Select_no_search
                  header={t("Warranty")}
                  placeholder={t("Warranty")}
                  map_name="warranty_name"
                  map_value="warranty_id"
                  keyState="pro_warranty_id"
                  data_select_array={props.warranty_list}
                  headState={setStock_import}
                  nameState={stock_import}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="mt-bottom-all">
                  <p className="text-bold">
                    {t("Import customer")}
                    <Switch
                      checked={stock_import.import_customer}
                      onChange={onChangeSwitchPass}
                      style={{ marginLeft: "10px" }}
                    />{" "}
                  </p>
                </div>
              </Col>
            </Row>

            <Row>
              <Col sm={12} md={6} lg={6} xl={6}>
                <div className="div_create_new_car">
                  <Button
                    className="btn_cancel_create_new_car"
                    onClick={() => cancelForm()}
                  >
                    {t("Cancel")}
                  </Button>
                </div>
              </Col>
              <Col sm={12} md={6} lg={6} xl={6}>
                <div className="div_create_new_car">
                  <Button
                    className="btn_submit_create_new_car"
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                  >
                   
                    {t("Import")}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      )}

      {props.import_success === true && (
        <Result
          status="success"
          title="Import data success"
          subTitle={
            <div className="mt-3">
              <p>{`Product model name : ${stock_import.pro_model_name}`}</p>
              <p>{`Product code : ${stock_import.pro_model_code}`}</p>
            </div>
          }
          // subTitle={`Product model name : ${stock_import.pro_model_name} `}
          extra={[
            <Button
              type="primary"
              onClick={() => history.push(`/stock/${sto_id}`)}
            >
              {t("Go console")}
            </Button>,
            <Button
              onClick={() => {
                props.setImport_success(false)
                props.changePagination(
                  props.page_limit.page,
                  props.page_limit.page_Size
                )
                props.setSelect_stock_modal(false)
                props.setImport_stock_select({})
              }}
            >
              {t("Close")}
            </Button>,
          ]}
        />
      )}
    </div>
  )
}
