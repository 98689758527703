import React,{ useState, useEffect } from 'react'
import Demo_setup_assets from '../../Assets/mockup_demo/Demo_setup_assets'
import { Table } from "antd"

export default function Detail_setup({ detail }) {
    const [data_history, setData_history] = useState(null)
    const data_setup = Demo_setup_assets

    function render_detail_setup() {
        let test = null
        data_setup.map( data =>{
            if(data.code === detail.code){
                test = data.history
              
            }
        })
        setData_history(test)
    }

    useEffect(() => {
        render_detail_setup()
        return () => {
    
        }
    }, [data_setup, detail])

    useEffect(() => {
        render_table()

    }, [data_history])

    function render_table() {
    return (
        <div>
        <Table columns={columns} dataSource={data_history} rowKey="id"  scroll={{ x: 1000 }} />
        </div>
    )
       
    }

    const columns = [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
        },
        {
          title: "Date setup",
          dataIndex: "date_setup",
          key: "date_setup",
        },
        {
          title: "Name customer",
          dataIndex: "name_customer",
          key: "name_customer",
        },
        {
          title: "License plate",
          dataIndex: "license_plate",
          key: "license_plate",
        },
        {
          title: "Contact setup",
          dataIndex: "contact_setup",
          key: "contact_setup",
        },
      ]

    return (
        <div>
        {render_table()}
        </div>
    )
}
