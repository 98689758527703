import React from "react"
import { Row } from "reactstrap"
import { Collapse, Card } from "antd"
import Col_Device from "./components/Col_Device"
import { useTranslation } from "react-i18next";

import dealer_info from "../../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/dealer_info.png"

const { Panel } = Collapse

export default function Dealer_Info(props) {
  const { t } = useTranslation();

  const icons_info_header = {
    width: "25px",
    marginRight: "20px",
  }

  return (
    <div>
      <Card
        title={
          <p>
            {" "}
            <img
              src={dealer_info}
              alt="dealer_info"
              style={icons_info_header}
            />{" "}
            {t("Dealer")}
          </p>
        }
      >
        {props.found_my_dealer === true && (
          <Collapse>
            <Panel header={props.dealer_info.dea_name} key="1">
              <Row>
                <Col_Device
                  text_header={t("Dealer name")}
                  data={props.dealer_info.dea_name}
                />
                <Col_Device
                  text_header={t("Dealer TAX ID")}
                  data={props.dealer_info.dea_taxid}
                />
                <Col_Device
                  text_header={t("Dealer code")}
                  data={props.dealer_info.dea_code}
                />
                <Col_Device
                  text_header={t("Dealer credit")}
                  data={props.dealer_info.dea_credit}
                />
                <Col_Device
                  text_header={t("Dealer phone")}
                  data={props.dealer_info.dea_phone}
                />
                <Col_Device
                  text_header={t("Dealer email")}
                  data={props.dealer_info.dea_email}
                />
                <Col_Device
                  text_header={t("Dealer fax")}
                  data={props.dealer_info.dea_fax}
                />
                <Col_Device
                  text_header={t("Dealer address")}
                  data={props.dealer_info.dea_address}
                />
                <Col_Device
                  text_header={t("Dealer contact firstname")}
                  data={props.dealer_info.dea_contact_firstname}
                />
                <Col_Device
                  text_header={t("Dealer contact lastname")}
                  data={props.dealer_info.dea_contact_lastname}
                />
                <Col_Device
                  text_header={t("Dealer contact mobile")}
                  data={props.dealer_info.dea_contact_mobile}
                />
                <Col_Device
                  text_header={t("Dealer contact email")}
                  data={props.dealer_info.dea_contact_email}
                />
                <Col_Device
                  text_header={t("Dealer remark")}
                  data={props.dealer_info.dea_remark}
                />
              </Row>
            </Panel>
          </Collapse>
        )}

        {props.found_my_dealer === false && (
          <Collapse>
            <Panel header={props.new_dealer.dea_name} key="1">
              <Row>
                <Col_Device
                  text_header={t("Dealer name")}
                  data={props.new_dealer.dea_name}
                />
                <Col_Device
                  text_header={t("Dealer TAX ID")}
                  data={props.new_dealer.dea_taxid}
                />
                {props.new_dealer.dea_code && (
                  <Col_Device
                    text_header={t("Dealer code")}
                    data={props.new_dealer.dea_code}
                  />
                )}
                <Col_Device
                  text_header={t("Dealer credit")}
                  data={props.new_dealer.dea_credit}
                />
                <Col_Device
                  text_header={t("Dealer phone")}
                  data={props.new_dealer.dea_phone}
                />
                <Col_Device
                  text_header={t("Dealer email")}
                  data={props.new_dealer.dea_email}
                />
                <Col_Device
                  text_header={t("Dealer fax")}
                  data={props.new_dealer.dea_fax}
                />
                <Col_Device
                  text_header={t("Dealer address")}
                  data={props.new_dealer.dea_address}
                />
                <Col_Device
                  text_header={t("Dealer remark")}
                  data={props.new_dealer.dea_remark}
                />
                <Col_Device
                  text_header={t("Dealer contact firstname")}
                  data={props.new_dealer.dea_contact_firstname}
                />
                <Col_Device
                  text_header={t("Dealer contact lastname")}
                  data={props.new_dealer.dea_contact_lastname}
                />
                <Col_Device
                  text_header={t("Dealer contact mobile")}
                  data={props.new_dealer.dea_contact_mobile}
                />
                <Col_Device
                  text_header={t("Dealer contact email")}
                  data={props.new_dealer.dea_contact_email}
                />
              </Row>
            </Panel>
          </Collapse>
        )}
      </Card>
    </div>
  )
}
