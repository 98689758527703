import React, { useState, useEffect } from "react"
import { Table, Button } from "antd"
import { Row, Col } from "reactstrap"
import Step_Create_User from "./Step_Create_User"
import { useTranslation } from "react-i18next";

export default function Create_New_User(props) {
  const { t } = useTranslation();
  // const [handle_button, setHandle_button] = useState(false)
  // const [select_user, setSelect_user] = useState([])
  // const [select_user_now, setSelect_user_now] = useState([])
  // const [confirm_pass_all, setConfirm_pass_all] = useState([])
  // const [error_all, setError_all] = useState([])
  // const [list_checkbox, setList_Checkbox] = useState([])

  useEffect(() => {
    
    return () => {
      props.setSelect_user([])
      props.setSelect_user_now([])
      props.setConfirm_pass_all([])
      props.setError_all([])
      props.setError_email([])
      props.setList_Checkbox([])
      props.setError_form(false)
    }
  }, [])

  const columns = [
    {
      title: "nickname",
      dataIndex: "nickname",
      key: "nickname",
      render: text => <p>{text}</p>,
    },
    {
      title: "name",
      dataIndex: "name",
      key: "name",
      render: text => <p>{text}</p>,
    },
    {
      title: "gid",
      dataIndex: "gid",
      key: "gid",
      render: text => <p>{text}</p>,
    },
    {
      title: "pfid",
      dataIndex: "pfid",
      key: "pfid",
      render: text => <p>{text}</p>,
    },
    {
      title: "phone",
      dataIndex: "phone",
      key: "phone",
      render: text => <p>{text}</p>,
    },
    {
      title: "pid",
      dataIndex: "pid",
      key: "pid",
      render: text => <p>{text}</p>,
    },
    {
      title: "remark",
      dataIndex: "remark",
      key: "remark",
      render: text => <p>{text}</p>,
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      props.setSelect_user(selectedRows)
      props.setSelect_user_now(selectedRows)
    },
    getCheckboxProps: record => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  }

  // function click_select_user() {
  //   let data_mapping = []
  //   let data_confirm_pass = []
  //   let error_password_mismatch = []
  //   let list_checkbox_role = []
  //   select_user.map(async data => {
  //     await  data_mapping.push({
  //       cus_email_password_condition: false,
  //       cus_user_code: data.gid ? data.gid : null,
  //       cus_user_email: data.email,
  //       cus_user_firstname: data.nickname,
  //       cus_user_lastname: null,
  //       cus_user_level_id: null,
  //       cus_user_password: null,
  //       cus_user_phone: data.phone,
  //       dep_id: null,
  //       pos_id: null,
  //       not_send_email_notification : false ,
  //       not_send_line_notification : false ,
  //     })
  //     data_confirm_pass.push({
  //       confirm_pass : null
  //     })
  //     error_password_mismatch.push({
  //       error : null
  //     })
  //     list_checkbox_role.push({
  //       listRole : null
  //     })
  //   })
  //   props.setUser(data_mapping)
  //   setConfirm_pass_all(data_confirm_pass)
  //   setError_all(error_password_mismatch)
  //   setList_Checkbox(list_checkbox_role)
  //   setHandle_button(true)
  // }

  // function create_user() {
  //   let data_mapping = []
  //   let data_confirm_pass = []
  //   let error_password_mismatch = []
  //   let list_checkbox_role = []
   
  //   data_mapping.push({
  //     cus_email_password_condition: false,
  //     // cus_user_code: null,
  //     cus_user_email: null,
  //     cus_user_firstname: null,
  //     cus_user_lastname: null,
  //     cus_user_level_id: null,
  //     cus_user_password: null,
  //     cus_user_phone: null,
  //     dep_id: null,
  //     pos_id: null,
  //     not_send_email_notification : false ,
  //     not_send_line_notification : false ,
  //   })
  //   data_confirm_pass.push({
  //     confirm_pass : null
  //   })
  //   error_password_mismatch.push({
  //     error : null
  //   })
  //   list_checkbox_role.push({
  //     listRole : null
  //   })

  //   props.setUser(data_mapping)
  //   setConfirm_pass_all(data_confirm_pass)
  //   setError_all(error_password_mismatch)
  //   setList_Checkbox(list_checkbox_role)
  //   setHandle_button(true)
  // }

  return (
    <div>
      {props.handle_button === false && (
        <div>
          <p
            style={{ fontSize: "15px", fontWeight: "bold", marginTop: "20px" }}
          >
            {t("Select user from distar")}
          </p>

          <Table
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            rowKey="id"
            pagination={false}
            columns={columns}
            dataSource={props.user_in_db_distar}
            scroll={{ x: 800 }}
          />
          {/*select_user.length !== 0 && (
            <Button
              className="btn-confirm-select-user-create-new-user"
              type="primary"
              onClick={() => click_select_user()}
            >
              Select user
            </Button>
          )}
          {select_user.length === 0 && (
            <Button
              className="btn-confirm-select-user-create-new-user"
              type="primary"
              onClick={() => create_user()}
            >
              Create user
            </Button>
          )*/}
        </div>
      )}

      {props.handle_button === true && (
        <Step_Create_User
          setSelect_user_now={props.setSelect_user_now}
          setSelect_user={props.setSelect_user}
          select_user_now={props.select_user_now}
          select_user={props.select_user}
          setHandle_button={props.setHandle_button}
          setUser={props.setUser}
          user={props.user}
          customer_data={props.customer_data}
          branch={props.branch}
          confirm_pass_all={props.confirm_pass_all}
          setConfirm_pass_all={props.setConfirm_pass_all}
          setError_all={props.setError_all}
          error_all={props.error_all}
          setError_email={props.setError_email}
          error_email={props.error_email}
          setList_Checkbox={props.setList_Checkbox}
          list_checkbox={props.list_checkbox}
          setError_form={props.setError_form}
        />
      )}

      <br />
    </div>
  )
}
