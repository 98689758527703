import React from "react"
import moment from "moment"
import { Form, DatePicker } from "antd"
import { useTranslation } from "react-i18next"

const dateFormat = "YYYY-MM-DD"

export default function Input_Date_Comp({
  header,
  keyState,
  placeholder,
  nameState,
  headState,
}) {
  const { t } = useTranslation()

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day

    return [year, month, day].join("-")
  }

  function onChangeDateIn(date, dateString) {
    headState({ ...nameState, [keyState]: formatDate(dateString) })
  }

  return (
    <>
      <p className="text-bold">
        {header} <span style={{ color: "red" }}>*</span>{" "}
        <span style={{ color: "rgb(170, 169, 169)", fontSize: "12px" }}>
        ( {t("YY/MM/DD")} )
        </span>{" "}
      </p>
      <Form.Item
        name={keyState}
        rules={[
          {
            required: true,
            message: `${placeholder}`,
          },
        ]}
      >
        <DatePicker
          format={dateFormat}
          onChange={onChangeDateIn}
          style={{ width: "100%" }}
        />
      </Form.Item>
    </>
  )
}
