import React, { useEffect } from "react"
import { useHistory, withRouter } from "react-router-dom"
import { Row, Col } from "reactstrap"

function Detail_header({ detail }) {
  const data_car = detail
  let history = useHistory()

  if (!detail) {
    history.push("/cars")
  }

  useEffect(() => {
    render_data()
    return () => {}
  }, [data_car])

  const text_table = [
    {
      header: "Type",
      dataText: detail.type,
    },
    {
      header: "Band",
      dataText: detail.brand,
    },
    {
      header: "Name product",
      dataText: detail.name_product,
    },
    {
      header: "Model",
      dataText: detail.model,
    },
    {
      header: "SN",
      dataText: detail.sn,
    },
    {
      header: "IMEI",
      dataText: detail.imei,
    },
    {
      header: "Text_Header_Detail_Creater",
      dataText: detail.name_create,
    },
    {
      header: "Name",
      dataText: detail.name_create,
    },
    {
      header: "Address",
      dataText: detail.address_create,
    },
    {
      header: "Contact",
      dataText: detail.contact,
    },
    {
      header: "Phone",
      dataText: detail.phone,
    },
  ]

  function render_data() {
    return (
      <div>
        <div style={{ fontSize: "15px",color:'#495057' }}>
          {text_table.map((data, index) => {
              if(data.header === "Text_Header_Detail_Creater"){
                return (
                    <div key={index} >
                    <hr />
                    <Row >
                    <p style={{ fontWeight: "bold", textAlign:'center' }}>Manufacturer information</p>
                  </Row>
                    </div>
                  )  
              } else {
                return (
                    <Row key={index}>
                      <Col xl="2" lg="2" md="2">
                        <p style={{ fontWeight: "bold" }}>{data.header}</p>
                      </Col>
                      <Col xl="10" lg="10" md="10">
                        {" "}
                        <p>{data.dataText}</p>
                      </Col>
                    </Row>
                  )
              }
            
          })}
        </div>
      </div>
    )
  }
  return <div>{render_data()}</div>
}

export default withRouter(Detail_header)
