import React from "react"
import { Table, Modal, notification } from "antd"
import { RiDeleteBin6Line } from "react-icons/ri"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import * as api from "../../../service/Api_Edit_Service"
import axios from "axios"
import { useTranslation } from "react-i18next";

const { confirm } = Modal

export default function Table_service_hast_operator(props) {
  const { t } = useTranslation();
  const token = localStorage.getItem("token")

  const columns = [
    {
      title: t("Operator code"),
      dataIndex: "ope_code",
      key: "ope_code",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Firstname"),
      dataIndex: "ope_firstname",
      key: "ope_firstname",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Lastname"),
      dataIndex: "ope_lastname",
      key: "ope_lastname",
      render: text => <p>{text}</p>,
    },
    // {
    //   title: t("Mobile"),
    //   dataIndex: "ope_mobile",
    //   key: "ope_mobile",
    //   render: text => <p>{text}</p>,
    // },
    {
      title: t("Email"),
      dataIndex: "ope_email",
      key: "ope_email",
      render: text => <p>{text}</p>,
    },
    {
      dataIndex: "",
      key: "",
      render: text => (
        <a>
          <RiDeleteBin6Line
            onClick={() => remove_operator(text.so_id)}
            size={20}
            style={{ color: "red" }}
          />
        </a>
      ),
    },
  ]

  function remove_operator(so_id) {
    return confirm({
      title: t("Are you sure delete operator"),
      icon: <ExclamationCircleOutlined />,
      okText: t("Delete"),
      okType: "danger",
      cancelText: t("no"),
      centered: true,
      onOk() {
        const remove = api.REMOVE_OPERATOR(token, so_id)
        axios(remove)
          .then(res => {})
          .catch(error => {
            if(error){
              if(error.response){
                if (error.response.status === 404) {
                  setTimeout(() => {
                    props.list_service_overview()
                    props.check_activity_log()
                    props.check_service_hast_operator()
                    props.search_operator()
                    openNotificationWithIcon("success", t("Remove operator success"))
                  }, 1500);
                } else {
                  openNotificationWithIcon("error", t("Remove operator error"))
                }
              }
            }
          })
      },
      onCancel() {
        //   console.log('Cancel');
      },
    })
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  return (
    <div>
      <Table
        rowKey="so_id"
        columns={columns}
        dataSource={props.service_operator}
        scroll={{ x: 800 }}
      />
    </div>
  )
}
