import React,{ useState } from 'react'
import List_device from './List_device'
import Modal_comp from './Modal_comp'
import { Modal, Button } from 'antd';

export default function Devices(props) {
    const [data_device, setData_device] = useState([])
    const [visibleDetail, setVisibleDetail] = useState(false);
    const [detail_modal, setDetail_modal] = useState({})

    return (
    <div>
       <List_device
        cus_id={props.id}
        setData_device={setData_device}
        data_device={data_device}
        setVisibleDetail={setVisibleDetail}
        setDetail_modal={setDetail_modal}
       />


       <Modal
        centered
        open={visibleDetail}
        onOk={() => setVisibleDetail(false)}
        onCancel={() => {
                setVisibleDetail(false)
                setDetail_modal({})
        }}
        footer={false}
        width={1000}
        height={500}
        >
            <Modal_comp 
                detail_modal={detail_modal}
            />
        </Modal>
    </div>
    )
}
