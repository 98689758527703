import React,{ useState, useEffect } from 'react'
import Demo_repair_car from '../../Cars/mockup_demo/Demo_repair_car'
import { Table } from "antd"

export default function Detail_repair({ data_car }) {
    const [data_history, setData_history] = useState(null)
    const data_repair = Demo_repair_car

    function render_detail_setup() {
        let test = null
        data_repair.map( data =>{
            if(data.code === data_car.code){
                test = data.history
              
            }
        })
        setData_history(test)
    }

    useEffect(() => {
        render_detail_setup()
        return () => {
    
        }
    }, [data_repair, data_car])

    useEffect(() => {
        render_table()

    }, [data_history])

    function render_table() {
    return (
        <div>
        <Table columns={columns} dataSource={data_history} rowKey="id"  scroll={{ x: 1000 }} />
        </div>
    )
       
    }

    const columns = [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
        },
        {
          title: "Date setup",
          dataIndex: "date_setup",
          key: "date_setup",
        },
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "SN",
          dataIndex: "sn",
          key: "sn",
        },
        {
          title: "Report",
          dataIndex: "report",
          key: "report",
        },
        {
          title: "Contact Setup",
          dataIndex: "contact_setup",
          key: "contact_setup",
        },
      ]

    return (
        <div>
        {render_table()}
        </div>
    )
}
