var Ip = require('./Ip')

export const LIST_PROVINCE = (token,data) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/location/province?q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_PROVINCE_NO_DATA = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/location/province`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_CAR_BODY_TYPE = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/car/body`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_CAR_BRAND = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/car/brand`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_CAR_MODEL = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/car/model`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_CAR_COLOR = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/car/color`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };
  
  export const LIST_CAR_YEAR = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/car/year`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_DATA = (token, page, pageSize, data) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer?page=${page}&limit=${pageSize}&order_by=asc&q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_NO_DATA = (token, page, pageSize) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer?page=${page}&limit=${pageSize}&order_by=asc`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const SEARCH_DATA = (token, cus_id, page, pageSize, data) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer/${cus_id}/branch?page=${page}&limit=${pageSize}&order_by=asc&q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const SEARCH_NODATA = (token ,cus_id ,page , pageSize) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer/${cus_id}/branch?page=${page}&limit=${pageSize}&order_by=asc`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CREATE_NEW_CAR = (token , data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/car`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const EDIT_CAR = (token , data, car_id) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/car/${car_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const DETAIL_CAR = (token , car_id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/car/${car_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const DELETE_CAR = (token , car_id) => {
    return {
      method: 'delete', 
      url: `${Ip.getIP()}api/v1/car/${car_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_PRODUCT_GROUP = (token) => {
    return {
      method: 'get', 
      url: `${Ip.getIP()}api/v1/stock/product-group`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_DEVICE_NO_DATA_NO_PRODUCT_ID = (token ,car_id ,page , pageSize) => {
    return {
      method: 'get', 
      url: `${Ip.getIP()}api/v1/car/car-has-device/${car_id}?page=${page}&limit=${pageSize}&order_by=asc`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_DEVICE_DATA_NO_PRODUCT_ID = (token ,car_id ,page , pageSize , data) => {
    return {
      method: 'get', 
      url: `${Ip.getIP()}api/v1/car/car-has-device/${car_id}?page=${page}&limit=${pageSize}&order_by=asc&q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };
/* ---- */
  export const LIST_DEVICE_NO_DATA_PRODUCT_ID = (token ,car_id, pro_group_id ,page , pageSize) => {
    return {
      method: 'get', 
      url: `${Ip.getIP()}api/v1/car/car-has-device/${car_id}?page=${page}&limit=${pageSize}&order_by=asc&pro_group_id=${pro_group_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_DEVICE_DATA_PRODUCT_ID = (token ,car_id , pro_group_id ,page , pageSize , data) => {
    return {
      method: 'get', 
      url: `${Ip.getIP()}api/v1/car/car-has-device/${car_id}?page=${page}&limit=${pageSize}&order_by=asc&q=${data}&pro_group_id=${pro_group_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const FIND_ALL_CAR_GROUP = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/car/group`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };