import React,{ useEffect, useState } from 'react'
import * as api from '../../service/Api_Create_new_dealer'
import axios from 'axios'
import { Select } from 'antd'
import { Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next";

const { Option } = Select

export default function Select_Role_Distar(props) {
    const token = localStorage.getItem('token')
    const [roleAll, setRoleAll] = useState([])
    const { t } = useTranslation();

    useEffect(() => {
        list_role_all()
        return () => {
            setRoleAll([])
        }
    }, [])

    function list_role_all() {
        const role_all = api.LIST_ROLE_ALL(token)
        axios(role_all)
        .then( res => {
            setRoleAll(res.data.data)
        })
        .catch( error => {
            // console.log(error.response)
        })
    }

    useEffect(() => {
        render_select_role()
        return () => {
            
        }
    }, [roleAll])

    function render_select_role() {
        return roleAll.map( data => {
            return <Option key={data.gid} value={data.gid}>{data.title}</Option>
        })
    }

    function onChange(value) {
        if(value){
            props.setRole_dealer_select(value)
        } else {
            props.setRole_dealer_select(null)
        }
      }

    return (
    <div>
        <p className="text-bold"> {t("Select dealer")} </p>
        <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Select a dealer"
                    optionFilterProp="children"
                    onChange={onChange}
                    filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    { render_select_role() }
                </Select>
            </Col>
        </Row>
    </div>
    )
}
