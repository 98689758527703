import React,{ useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { Form, Input, Button, Modal, notification } from "antd"
import * as api from '../../../../../service/Api_Customer'
import axios from "axios"

const { TextArea } = Input;

export default function Postcode(props) {
    const [_list_location, set_list_location] = useState([])

    useEffect(() => {
        function check_postcode_info() {
          if (props.detail_customer.zip_code) {
            if (props.detail_customer.zip_code.length === 0) {
              props.setLocation_select({})
            } else if (props.detail_customer.zip_code.length >= 4) {
              const check_postcode_ = api.CHECK_POSTCODE(props.detail_customer.zip_code)
              axios(check_postcode_)
                .then(res => {
                  let data = res.data.message
                  data.map(data => {
                    if (data.zip_id === props.detail_customer.zip_id) {
                        props.setLocation_select(data)
                    }
                  })
                })
                .catch(error => {
                  console.log(error)
                })
            }
          } else {
            props.setLocation_select({})
          }
        }
        check_postcode_info()
        return () => {}
      }, [props.detail_customer.zip_code])

      function edit_postcode(postcode) {
        if (postcode) {
          if (postcode.length === 0) {
            set_list_location([])
            props.setLocation_select({})
          } else if (postcode.length >= 4) {
            const _postcode_ = api.CHECK_POSTCODE(postcode)
            axios(_postcode_)
              .then(res => {
                set_list_location(res.data.message)
              })
              .catch(error => {
                console.log(error)
              })
          }
        } else {
          set_list_location([])
          props.setLocation_select({})
        }
      }

      useEffect(() => {
        render_select_postcode()
        return () => {}
      }, [_list_location])

      function render_select_postcode() {
        return _list_location.map(data => {
          return (
            <div
              key={data.zip_id}
              style={{ borderBottom: "1px solid #f0f0f0", padding: "10px" }}
            >
              <a>
                <p
                  style={{ color: "#1890ff" }}
                  onClick={() => {
                    set_list_location([])
                       props.setLocation_select({
                            dis_name : data.dis_name ,
                            pro_name : data.pro_name ,
                            subdis_name : data.subdis_name ,
                            zip_code : data.zip_code
                       })
                       props.setCustomer_edit({
                         ...props.customer_edit,
                         zip_id : data.zip_id
                       })
                  }}
                >
                  {data.subdis_name} - {data.dis_name} - {data.pro_name} -{" "}
                  {data.zip_code}
                </p>
              </a>
            </div>
          )
        })
      }

      useEffect(() => {
        render_position_select()
        return () => {}
      }, [props.location_select])

      function render_position_select() {
        if (props.location_select.subdis_name || props.location_select.dis_name || props.location_select.pro_name || props.location_select.zip_code) {
          return (
            <div>
              <Row>
                <Col sm={12} md={6} lg={6} xl={6}>
                  <p className="text-bold mt-3">Sub-district</p>
                  <Input disabled value={props.location_select.subdis_name} />
                </Col>
                <Col sm={12} md={6} lg={6} xl={6}>
                  <p className="text-bold mt-3">District</p>
                  <Input disabled value={props.location_select.dis_name} />
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6} lg={6} xl={6}>
                  <p className="text-bold mt-3">Province</p>
                  <Input disabled value={props.location_select.pro_name} />
                </Col>
                <Col sm={12} md={6} lg={6} xl={6}>
                <p className="text-bold mt-3">Postcode</p>
                <Input disabled value={props.location_select.zip_code} />
              </Col>
              </Row>
              <Row>
              <Col>
                <p className="text-bold mt-3">
                  Address
                </p>
                  <Form.Item
                    name="cus_branch_address"
                    rules={[
                      {
                        required: false,
                        message: `Please input postcode`,
                      },
                    ]}
                  >
                  <TextArea 
                    placeholder={props.detail_customer.cus_address}
                    // value={props.detail_customer.cus_address}
                    disabled={props.disabled}
                    onChange={(e) => {
                        props.setCustomer_edit({
                          ...props.customer_edit,
                          cus_address : e.target.value
                        })
                      }}
                  />
                  </Form.Item>
              </Col>
            </Row>
            </div>
          )
        } else {
          return null
        }
      }

  return (
    <div>
      <p className="text-bold">
        Postcode 
      </p>
      <Form.Item
        name="zip_id"
        rules={[
          {
            required: false,
            message: `Please input postcode`,
          },
        ]}
      >
        <Input
            disabled={props.edit}
            value={props.detail_customer.zip_code}
            placeholder={props.detail_customer.zip_code}
            type="number"
            onChange={e => edit_postcode(e.target.value)}
        />
      </Form.Item>

      { render_select_postcode() }
      { render_position_select() }
    </div>
  )
}

// customer_edit={customer_edit}
// setCustomer_edit={setCustomer_edit}
// setEdit={setEdit}
// edit={edit}
// location_select={location_select}
// setLocation_select={setLocation_select}
