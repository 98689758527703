import React, { useState, useRef , useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Button } from "antd"
import { DeleteOutlined } from "@ant-design/icons"

export default function UploadDocsFile({ setFileUploadAll }) {
  const { t } = useTranslation()
  const fileUpload = useRef(null)
  const [fileAll, setFileAll] = useState([])

  useEffect(() => {
    setFileUploadAll(fileAll)
      return () => {
          
      }
  }, [fileAll])

  useEffect(() => {
      
      return () => {
        setFileAll([])
      }
  }, [])

  const handleImageUpload = e => {
    setFileAll([...fileAll, ...e.target.files])
    e.target.value=null
  }

  function removeFile(index) {
    const fileRemove = [...fileAll]
    fileRemove.splice(index, 1)
    setFileAll(fileRemove)
  }

  return (
    <div style={{ marginBottom:'20px' }}>
      <p className="text-bold">
        {t("upload file")}{" "}
        <span style={{ color: "gray", marginLeft: "10px" }}>
          Word , Excel , PDF
        </span>{" "}
      </p>
      <input
        type="file"
        multiple
        onChange={handleImageUpload}
        ref={fileUpload}
        style={{
          display: "none",
        }}
      />
      <Button onClick={() => fileUpload.current.click()}>
        {t("upload file")}
      </Button>
      {fileAll.map((data, index) => {
        return (
          <p key={index} style={{ marginTop: "15px" }}>
            <a>
              <DeleteOutlined
                style={{
                  fontSize: "20px",
                  color: "#FF2D2D",
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
                onClick={() => removeFile(index)}
              />
            </a>
            {data.name}
          </p>
        )
      })}
    </div>
  )
}
