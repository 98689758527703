import React, { useRef, useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import Search_data_branch_component from "./Search_data_branch_component"
import {
  Table,
  Input,
  Form,
  Button,
  Pagination,
  Avatar,
  Switch,
  Select,
  Modal,
  notification,
} from "antd"
import { UserOutlined, ExclamationCircleOutlined } from "@ant-design/icons"
import axios from "axios"
import * as api from "../../../../service/Api_New_Customer"
import { EditOutlined } from "@ant-design/icons"
import { FcDataSheet } from "react-icons/fc"
import { useHistory } from "react-router-dom"
import helpers from "../../../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"

const { Option } = Select
const { confirm } = Modal
const style_req = {
  color: "red",
}

export default function Create_new_user(props) {
  const [form] = Form.useForm()
  const imageUploader = useRef(null)
  const history = useHistory()
  const { t } = useTranslation()
  const [data_branch, setData_branch] = useState([])
  const token = localStorage.getItem("token")
  const [display_branch_name, setDisplay_branch_name] = useState(null)
  const [confirm_pass, setConfirm_pass] = useState(null)
  const [error_pass, setError_pass] = useState(null)
  const [error_email, setError_email] = useState(null)
  const [department_state, setDepartment_state] = useState([])
  const [position_state, setPosition_state] = useState([])
  const [data_user, setData_user] = useState({
    cus_email_password_condition: false,
    cus_user_password: null,
    cus_id: props.cus_id,
    cus_branch_id: null,
    cus_user_email: null,
    not_send_email_notification: false,
    not_send_line_notification: false,
  })
  const [image_select, setImage_select] = useState(null)
  const [image_preview, setImage_preview] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (data_user.cus_user_email) {
      const check_email = api.CHECK_EMAIL_USER_DUPLICATE(
        token,
        data_user.cus_user_email
      )
      axios(check_email).then(res => {
        if (res.data.msg === true) {
          setError_email(t("email is duplicate"))
        } else if (res.data.msg === false) {
          setError_email(null)
        }
      })
    }
    return () => {}
  }, [data_user.cus_user_email])

  useEffect(() => {
    function check_department_info() {
      const check_depart = api.CHECK_DEPARTMENT(token)
      axios(check_depart)
        .then(res => {
          setDepartment_state(res.data.data)
        })
        .catch(error => {
          if (error.response.status === 401) {
            helpers.re_login()
            history.push("/login")
          }
        })
    }

    function check_position_info() {
      const check_posi = api.CHECK_POSITION(token)
      axios(check_posi)
        .then(res => {
          setPosition_state(res.data.data)
        })
        .catch(error => {
          // console.log(error)
        })
    }

    check_department_info()
    check_position_info()
    return () => {
      setData_user({})
      setPosition_state([])
      setDepartment_state([])
      setError_pass(null)
      setConfirm_pass(null)
      setDisplay_branch_name(null)
      setData_branch([])
      setImage_select(null)
      setImage_preview(null)
    }
  }, [])

  const handleImageUpload = e => {
    if (e.target.files[0] !== undefined) {
      if (e.target.files[0].type !== "image/jpeg") {
        openNotificationWithIcon(
          "error",
          t("please upload photo type .jpeg only")
        )
      } else {
        let img_upload = e.target.files[0]
        setImage_preview(URL.createObjectURL(img_upload))
        setImage_select(img_upload)
      }
    }
  }

  useEffect(() => {
    setData_branch(props.branch_table)
    return () => {
      setData_branch([])
    }
  }, [props.branch_table])

  const columns = [
    {
      title: t("branch code"),
      dataIndex: "cus_branch_code",
      key: "cus_branch_code",
      render: text => <p>{text}</p>,
    },
    {
      title: t("branch name"),
      dataIndex: "cus_branch_name",
      key: "cus_branch_name",
      render: text => <p>{text}</p>,
    },
    {
      title: t("branch address"),
      dataIndex: "cus_branch_address",
      key: "cus_branch_address",
      render: text => <p>{text}</p>,
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setData_user({
        ...data_user,
        cus_id: selectedRows[0].cus_id,
        cus_branch_id: selectedRows[0].cus_branch_id,
      })
      setDisplay_branch_name(selectedRows[0].cus_branch_name)
      //   console.log(selectedRows[0])
    },
    getCheckboxProps: record => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  }

  useEffect(() => {
    render_department()
    render_position()
    return () => {}
  }, [position_state, department_state])

  function render_department() {
    return department_state.map(data => {
      return (
        <Option key={data.dep_id} value={data.dep_id}>
          {data.dep_name}
        </Option>
      )
    })
  }
  function render_position() {
    return position_state.map(data => {
      return (
        <Option key={data.pos_id} value={data.pos_id}>
          {data.pos_name}
        </Option>
      )
    })
  }

  function onChange_select_department_info(value) {
    setData_user({
      ...data_user,
      dep_id: value,
    })
  }
  function onChange_select_position_info(value) {
    setData_user({
      ...data_user,
      pos_id: value,
    })
  }

  function onChange_switch_send_email(checked) {
    if (checked) {
      setError_pass(null)
      clear_password_field()
      setConfirm_pass(null)
      setData_user({
        ...data_user,
        cus_user_password: null,
        cus_email_password_condition: checked,
      })
    } else {
      setData_user({
        ...data_user,
        cus_email_password_condition: checked,
      })
    }
  }
  function clear_password_field() {
    form.resetFields(["user_password_confirm_info", "user_password_info"])
  }
  useEffect(() => {
    if (data_user.cus_user_password !== confirm_pass) {
      setError_pass(t("password mismatch"))
    } else {
      setError_pass(null)
    }
    return () => {}
  }, [confirm_pass])

  const onFinish = values => {
    return confirm({
      title: t("create new user"),
      icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
      centered: true,
      okText: t("create"),
      cancelText: t("no"),
      onOk() {
        setLoading(true)
        if (image_select !== null) {
          const formData = new FormData()
          formData.append("file", image_select)
          const upload_image = api.UPLOAD_PROFILE(token, formData)
          axios(upload_image)
            .then(res => {
              if (data_user.cus_email_password_condition === true) {
                let data_info_pass = {}
                data_info_pass = {
                  cus_user_firstname: data_user.cus_user_firstname,
                  cus_user_lastname: data_user.cus_user_lastname,
                  cus_user_avatar: res.data.path,
                  cus_user_phone: data_user.cus_user_phone,
                  dep_id: data_user.dep_id,
                  pos_id: data_user.pos_id,
                  cus_user_email: data_user.cus_user_email,
                  cus_email_password_condition:
                    data_user.cus_email_password_condition,
                  cus_id: data_user.cus_id,
                  cus_branch_id: data_user.cus_branch_id,
                  not_send_email_notification:
                    data_user.not_send_email_notification,
                  not_send_line_notification:
                    data_user.not_send_line_notification,
                }
                create_new_user(data_info_pass)
              } else {
                let data_pass = {}
                data_pass = {
                  cus_user_avatar: res.data.path,
                  ...data_user,
                }
                create_new_user(data_pass)
              }
            })
            .catch(error => {
              setLoading(false)
              if (error) {
                if (error.response) {
                  if (error.response.status === 401) {
                    helpers.re_login()
                    history.push("/login")
                  } else {
                    openNotificationWithIcon("error", t("create user error"))
                  }
                }
              }
            })
        } else {
          if (data_user.cus_email_password_condition === true) {
            let data_info_pass = {}
            data_info_pass = {
              cus_user_firstname: data_user.cus_user_firstname,
              cus_user_lastname: data_user.cus_user_lastname,
              cus_user_avatar: "None",
              cus_user_phone: data_user.cus_user_phone,
              dep_id: data_user.dep_id,
              pos_id: data_user.pos_id,
              cus_user_email: data_user.cus_user_email,
              cus_email_password_condition:
                data_user.cus_email_password_condition,
              cus_id: data_user.cus_id,
              cus_branch_id: data_user.cus_branch_id,
              not_send_email_notification:
                data_user.not_send_email_notification,
              not_send_line_notification: data_user.not_send_line_notification,
            }
            create_new_user(data_info_pass)
          } else {
            let data_pass = {}
            data_pass = {
              cus_user_avatar: "None",
              ...data_user,
            }
            create_new_user(data_pass)
          }
        }
      },
      onCancel() {
        // console.log('Cancel');
      },
    })
  }

  function create_new_user(data) {
    const create_new = api.CREATE_NEW_A_USER(token, data)
    axios(create_new)
      .then(res => {
        setLoading(false)
        props.setActiveTab_Users("1")
        props.search_data_user_table()
        openNotificationWithIcon("success", t("create success"))
        onReset()
        props.setPage_limit_branch({
          ...props.page_limit_branch,
          page_branch: 1,
          limit_branch: 10,
          page_Size_branch: 10,
        })
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            } else {
              openNotificationWithIcon("error", t("create user error"))
            }
          }
        }
      })
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  function onChange_notification_line(checked) {
    if (checked === true) {
      setData_user({
        ...data_user,
        not_send_line_notification: false,
      })
    } else if (checked === false) {
      setData_user({
        ...data_user,
        not_send_line_notification: true,
      })
    }
  }

  function onChange_notification_email(checked) {
    if (checked === true) {
      setData_user({
        ...data_user,
        not_send_email_notification: false,
      })
    } else if (checked === false) {
      setData_user({
        ...data_user,
        not_send_email_notification: true,
      })
    }
  }

  const onReset = () => {
    props.setData_search_branch(null)
    setError_pass(null)
    setImage_preview(null)
    setImage_select(null)
    form.resetFields()
    setError_email(null)
    setLoading(false)
    setData_user({
      cus_email_password_condition: false,
      cus_user_password: null,
      cus_id: props.cus_id,
      cus_branch_id: null,
      not_send_email_notification: false,
      not_send_line_notification: false,
    })
  }

  function check_email_and_pass_dup() {
    if (error_pass === null && error_email === null) {
      return false
    } else {
      return true
    }
  }

  useEffect(() => {
    check_email_and_pass_dup()
    return () => {}
  }, [error_pass, error_email])

  return (
    <div>
      <Form
        form={form}
        name="create_new_user_data_info"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Row>
          <Col sm={12} md={12} lg={12} xl={12}>
            <div>
              <p className="text-bold " style={{ textAlign: "center" }}>
                {" "}
                {t("photo profile")}
              </p>
              <div>
                <br />
                {image_select === null && (
                  <Avatar
                    size={84}
                    icon={<UserOutlined />}
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                )}
                {image_select !== null && (
                  <Avatar
                    size={84}
                    src={image_preview}
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                )}
              </div>

              <input
                type="file"
                accept="image/jpeg"
                onChange={handleImageUpload}
                ref={imageUploader}
                style={{
                  display: "none",
                }}
              />
              <Button
                type="primary"
                style={{
                  width: "150px",
                  display: "block",
                  margin: "20px auto 10px auto",
                }}
                onClick={() => imageUploader.current.click()}
              >
                {t("upload photo")}
              </Button>
              <p style={{ color: "red", textAlign: "center" }}>
                {t("please upload photo type .jpeg only")}
              </p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <p className="text-bold mt-4">
              {" "}
              {t("firstname")} <span style={style_req}>*</span>
            </p>
            <Form.Item
              name="user_firstname_info"
              rules={[
                {
                  required: true,
                  message: `${t("please input")} ${t("firstname")}`,
                },
              ]}
            >
              <Input
                placeholder={t("firstname")}
                onChange={e =>
                  setData_user({
                    ...data_user,
                    cus_user_firstname: e.target.value,
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <p className="text-bold mt-4">
              {" "}
              {t("lastname")} <span style={style_req}>*</span>
            </p>
            <Form.Item
              name="user_lastname_info"
              rules={[
                {
                  required: true,
                  message: `${t("please input")} ${t("lastname")}`,
                },
              ]}
            >
              <Input
                placeholder={t("lastname")}
                onChange={e =>
                  setData_user({
                    ...data_user,
                    cus_user_lastname: e.target.value,
                  })
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <p className="text-bold mt-4">
              {" "}
              {t("phone")} <span style={style_req}>*</span>
            </p>
            <Form.Item
              name="user_phone_info"
              rules={[
                {
                  required: true,
                  message: `${t("please input")} ${t("phone")}`,
                },
              ]}
            >
              <Input
                type="number"
                placeholder={t("phone")}
                onChange={e =>
                  setData_user({
                    ...data_user,
                    cus_user_phone: e.target.value,
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <p className="text-bold mt-4">
              {" "}
              {t("e-mail")} <span style={style_req}>*</span>
            </p>
            <Form.Item
              name="user_email_info"
              rules={[
                {
                  required: true,
                  message: `${t("please input")} ${t("e-mail")}`,
                },
              ]}
            >
              <Input
                type="email"
                placeholder={t("e-mail")}
                onBlur={e =>
                  setData_user({
                    ...data_user,
                    cus_user_email: e.target.value,
                  })
                }
              />
            </Form.Item>
            {error_email && (
              <p style={{ color: "red", fontSize: "15px", marginTop: "10px" }}>
                {error_email}
              </p>
            )}
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <p className="text-bold mt-4">
              {t("department")} <span style={style_req}>*</span>
            </p>
            <Form.Item
              name="user_department_info"
              rules={[
                {
                  required: true,
                  message: `${t("please input")} ${t("department")}`,
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder={t("department")}
                optionFilterProp="children"
                onChange={onChange_select_department_info}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {render_department()}
              </Select>
            </Form.Item>
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <p className="text-bold mt-4">
              {t("position")} <span style={style_req}>*</span>
            </p>
            <Form.Item
              name="user_position_info"
              rules={[
                {
                  required: true,
                  message: `${t("please input")} ${t("position")}`,
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder={t("position")}
                optionFilterProp="children"
                onChange={onChange_select_position_info}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {render_position()}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col>
            <p className="text-bold mt-4">{t("receive notifications")}</p>
            <Form.Item
              name="email_notification"
              rules={[
                {
                  required: false,
                  message: "Please input position ",
                },
              ]}
            >
              <p>
                {t("subscribe email notification")}
                <Switch
                  defaultChecked
                  style={{ marginLeft: "10px" }}
                  onChange={onChange_notification_email}
                />
              </p>
            </Form.Item>

            <Form.Item
              name="line_notification"
              rules={[
                {
                  required: false,
                  message: "Please input position ",
                },
              ]}
            >
              <p>
                {t("subscribe line notification")}
                <Switch
                  defaultChecked
                  style={{ marginLeft: "20px" }}
                  onChange={onChange_notification_line}
                />
              </p>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col>
            <p className="text-bold ">
              {t("sending signup password by email")}
              <Switch
                checked={data_user.cus_email_password_condition}
                onChange={onChange_switch_send_email}
                style={{ marginLeft: "10px" }}
              />
            </p>
          </Col>
        </Row>

        {data_user.cus_email_password_condition === false && (
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <p className="text-bold mt-4">
                {t("password")} <span style={style_req}>*</span>
              </p>
              <Form.Item
                name="user_password_info"
                rules={[
                  {
                    required: true,
                    message: `${t("please input")} ${t("password")}`,
                  },
                ]}
              >
                <Input.Password
                  type="password"
                  placeholder={t("password")}
                  onChange={e =>
                    setData_user({
                      ...data_user,
                      cus_user_password: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
              <p className="text-bold mt-4">
                {t("confirm password")} <span style={style_req}>*</span>
              </p>
              <Form.Item
                name="user_password_confirm_info"
                rules={[
                  {
                    required: true,
                    message: `${t("please input")} ${t("confirm password")}`,
                  },
                ]}
              >
                <Input.Password
                  type="password"
                  placeholder={t("confirm password")}
                  onChange={e => setConfirm_pass(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {error_pass && (
          <p style={{ textAlign: "center", color: "red", fontSize: "15px" }}>
            {error_pass}
          </p>
        )}

        {data_user.cus_branch_id === null && (
          <div>
            <p className="text-bold ">
              {t("select branch")} 
            </p>
            <Search_data_branch_component
              setData_search_branch={props.setData_search_branch}
              search_data_branch={props.search_data_branch}
            />
            <br />
            <Table
              loading={props.loading_branch}
              rowSelection={{
                type: "radio",
                ...rowSelection,
              }}
              rowKey="cus_branch_code"
              pagination={false}
              columns={columns}
              dataSource={data_branch}
              scroll={{ x: 800 }}
            />
            <div style={{ float: "right", marginTop: "10px" }}>
              <Pagination
                current={props.page_limit_branch.page_branch}
                total={props.page_limit_branch.limit_branch}
                onChange={props.changePagination}
              />
            </div>
            <p style={{ fontSize: "15px", marginTop: "15px" }}>
              {" "}
              <FcDataSheet size={20} style={{ marginBottom: "5px" }} />
              {t("total data")} : {props.page_limit_branch.limit_branch}
            </p>
          </div>
        )}

        {data_user.cus_branch_id !== null && (
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <div style={{ display: "flex" }}>
                <p className="text-bold " style={{ marginTop: "7px" }}>
                  {" "}
                  {t("branch")}
                </p>
                <Input
                  style={{ marginLeft: "28px", height: "35px" }}
                  value={display_branch_name}
                  placeholder={display_branch_name}
                  disabled
                />
                <a>
                  <EditOutlined
                    style={{ fontSize: "20px", marginLeft: "10px" }}
                    onClick={() => {
                      setData_user({
                        ...data_user,
                        cus_id: props.cus_id,
                        cus_branch_id: null,
                      })
                      props.setData_search_branch(null)
                      setDisplay_branch_name(null)
                    }}
                  />
                </a>
              </div>
            </Col>
          </Row>
        )}

        <br />
        <br />

        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div className="div_create_new_car">
              <Button
                htmlType="button"
                onClick={() => onReset()}
                className="btn_cancel_create_new_car"
              >
                {t("cancel")}
              </Button>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div className="div_create_new_car">
              <Button
                type="primary"
                htmlType="submit"
                className="btn_submit_create_new_car"
                disabled={check_email_and_pass_dup()}
                // disabled={error_pass ? error_pass : false}
                // disabled={error_email ? error_email : false}
                loading={loading}
              >
                {t("create new user")}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
