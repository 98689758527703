import React, { useState } from "react"
import { Tooltip , Modal } from "antd"
import "./styles.module.sig.css"
import { FcApproval, FcSearch } from "react-icons/fc"
import Map_Operator from './Map_Operator'
import { useTranslation } from "react-i18next";

export default function Render_Check_In_Out(props) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [location_check, setLocation_check] = useState({
    lat: 13.2753119,
    lng: 101.4843104,
  })

  const text_check = {
    fontSize: "20px",
  }
  function render_data(name_type) {
    if (props.value[0]) {
      return (
        <div>
          <p style={text_check}>
            {name_type} {t("date")} : {cutTimeZone(props.value[0].date)}
          </p>
          <p style={text_check}>
            {name_type} {t("time")} : {render_time(props.value[0].date)}
          </p>
        </div>
      )
    } else {
      return <p>{name_type} {t("no date")} / {t("time")}</p>
    }
  }

  function render_location(name_type) {
    if (props.value[0]) {
      if (props.value[0].lat && props.value[0].lng) {
        return (
          <div>
            <p style={text_check}>
              {name_type} {t("location")}
              <Tooltip title={t("View location")} >
                <a>
                  <FcSearch size={20} style={{ marginLeft:'10px' }} onClick={() => {
                    setLocation_check({
                      ...location_check,
                      lat: props.value[0].lat,
                      lng: props.value[0].lng,
                    })
                    setVisible(true)
                  }} />
                </a>
              </Tooltip>{" "}
            </p>
          </div>
        )
      } else {
        return <p> {t("No location")} </p>
      }
    } else {
      return <p>{t("No location")}</p>
    }
  }

  function render_time(date_time) {
    const date_data = new Date(date_time)
    let expiryDate = new Date(
      new Date(date_data).setHours(date_data.getHours() - 0)
    )
    const date_all = new Date(expiryDate)
    return date_all.toLocaleTimeString("en-GB")
  }

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return "N/A"
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  function cancel_map() {
    setVisible(false)
    setLocation_check({
      ...location_check,
      lat: 13.2753119,
      lng: 101.4843104,
    })
  }

  return (
    <div>
      {props.value !== null && props.type === "check_in" && (
        <div>
          {render_data("Check in")}
          {render_location("Check in")}
        </div>
      )}
      {props.value !== null && props.type === "check_out" && (
        <div>
          {render_data("Check out")}
          {render_location("Check out")}
        </div>
      )}
      {props.value === null && props.type === "check_in" && (
        <p style={{ fontSize: "20px" }}> {t("No check in")} </p>
      )}
      {props.value === null && props.type === "check_out" && (
        <p style={{ fontSize: "20px" }}> {t("No check out")} </p>
      )}


      <Modal
      centered
      open={visible}
      onOk={() => cancel_map()}
      onCancel={() => cancel_map()}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
        <Map_Operator 
          location_check={location_check}
        />
    </Modal>
    </div>
  )
}
