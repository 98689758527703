import React from "react"
import { Form, Input, Button, Select } from "antd"

const { Option } = Select

export default function Select_data(props) {
  function onChange(value) {
    props.setDisable(false)
    props.setNew_noti({
      ...props.new_noti,
      trig_type: value,
    })
  }

  return (
    <div>
      <p className="text-bold">
        Type <span style={{ color: "red" }}>*</span>{" "}
      </p>
      <Form.Item
        name="message: 'Please input your password!' "
        rules={[
          {
            required: true,
            message: "Please input your type",
          },
        ]}
      >
        <Select
          showSearch
          style={{ width: "100%" }}
          placeholder="Select a person"
          optionFilterProp="children"
          onChange={onChange}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Option value="email">email</Option>
          <Option value="line">line</Option>
        </Select>
      </Form.Item>
    </div>
  )
}
