var Ip = require('../service/Ip')

export const LIST_STOCK_DATA = (token, page, pageSize, data) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}distar/device/device-not-in-db?page=${page}&limit=${pageSize}&order_by=DESC&q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_STOCK_NO_DATA = (token, page, pageSize) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}distar/device/device-not-in-db?page=${page}&limit=${pageSize}&order_by=DESC`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_PRODUCT_GROUP = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/product/group`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };
  
  export const LIST_PRODUCT_WARRANTY = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/product/warranty`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };
  
  export const CHECK_CUSTOMER = (token, car_id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}distar/cars/customer/${car_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const IMPORT_STOCK = (token, data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/stock/product`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };