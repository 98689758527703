import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { useTranslation } from "react-i18next"
import axios from "axios"
import * as api from "../../service/Api_Admin"
import { Row, Col, Container, Card, CardBody } from "reactstrap"
import { useHistory } from "react-router-dom"
import { FcSettings, FcSearch } from "react-icons/fc"
import { MdKeyboardBackspace } from "react-icons/md"
import { Table, Button, Modal, Tooltip, notification, Form } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { RiDeleteBin6Line } from "react-icons/ri"
import Input_Comp from "../System_Settings/Components/Input_Comp"
import helpers from "../Function_Redirect/Redirect_login"

const { confirm } = Modal

export default function Admin_Group() {
  const history = useHistory()
  const { t } = useTranslation()
  const [form_create] = Form.useForm()
  const [form_edit] = Form.useForm()
  const token = localStorage.getItem("token")
  const [data_list, setData_list] = useState([])
  const [visible_edit, setVisible_edit] = useState(false)
  const [visible_create, setVisible_create] = useState(false)
  const [data_edit, setData_edit] = useState({})
  const [data_create, setData_create] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    check_data()
    return () => {}
  }, [])

  function check_data() {
    setLoading(true)
    const check_data_all = api.FIND_ALL_ADMIN_GROUP(token)
    axios(check_data_all)
      .then(res => {
        setData_list(res.data.data)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  function remove_data(id) {
    return confirm({
      title: t("Confirm remove admin group"),
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: t("Confirm"),
      okType: "danger",
      cancelText: t("Cancel"),
      onOk() {
        setLoading(true)
        const delete_data = api.DELETE_ADMIN_GROUP(token, id)
        axios(delete_data)
          .then(res => {
            setLoading(false)
            openNotificationWithIcon("error", t("Remove admin group error"))
          })
          .catch(error => {
            setLoading(false)
            if (error) {
              if (error.response) {
                if (error.response.status === 401) {
                  helpers.re_login()
                  history.push("/login")
                } else if (error.response.status === 404) {
                  openNotificationWithIcon(
                    "success",
                    t("Remove admin group success")
                  )
                  cancel_all_Form()
                  check_data()
                } else {
                  openNotificationWithIcon(
                    "error",
                    t("Remove admin group error")
                  )
                }
              }
            }
          })
      },
      onCancel() {},
    })
  }

  const onFinish_create = values => {
    return confirm({
      title: t("Confirm create admin group"),
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: t("Confirm"),
      cancelText: t("Cancel"),
      onOk() {
        setLoading(true)
        const create_admin_group = api.CREATE_NEW_ADMIN_GROUP(
          token,
          data_create
        )
        axios(create_admin_group)
          .then(res => {
            openNotificationWithIcon(
              "success",
              t("Create new admin group success")
            )
            check_data()
            setLoading(false)
            cancel_create_Form()
          })
          .catch(error => {
            setLoading(false)
            if (error) {
              if (error.response) {
                if (error.response.status === 401) {
                  helpers.re_login()
                  history.push("/login")
                } else {
                  openNotificationWithIcon(
                    "error",
                    t("Create new admin group error")
                  )
                }
              }
            }
          })
      },
      onCancel() {},
    })
  }

  const cancel_create_Form = () => {
    form_create.resetFields()
    setVisible_create(false)
    setData_create({})
  }

  const onFinish_edit = values => {
    return confirm({
      title: t("Confirm edit admin group"),
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: t("Confirm"),
      cancelText: t("Cancel"),
      onOk() {
        setLoading(true)
        const data_edit_all = {
          admin_group_name: data_edit.admin_group_name,
        }
        const edit_admin_group = api.EDIT_ADMIN_GROUP(
          token,
          data_edit.admin_group_id,
          data_edit_all
        )
        axios(edit_admin_group)
          .then(res => {
            openNotificationWithIcon("success", t("Edit admin group success"))
            check_data()
            setLoading(false)
            cancel_edit_Form()
          })
          .catch(error => {
            setLoading(false)
            if (error) {
              if (error.response) {
                if (error.response.status === 401) {
                  helpers.re_login()
                  history.push("/login")
                } else {
                  openNotificationWithIcon("error", t("Edit admin group error"))
                }
              }
            }
          })
      },
      onCancel() {},
    })
  }

  const cancel_edit_Form = () => {
    form_edit.resetFields()
    setVisible_edit(false)
    setData_edit({})
  }

  function cancel_all_Form() {
    cancel_create_Form()
    cancel_edit_Form()
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  const columns = [
    {
      title: t("Admin group name"),
      dataIndex: "admin_group_name",
      key: "admin_group_name",
      render: text => <p>{text}</p>,
    },
    // {
    //   title: "",
    //   dataIndex: "admin_group_id",
    //   key: "admin_group_id",
    //   render: text => (
    //     <Tooltip title={t("Remove admin group")}>
    //       <a>
    //         <RiDeleteBin6Line
    //           style={{ color: "red" }}
    //           size={20}
    //           onClick={() => remove_data(text)}
    //         />
    //       </a>
    //     </Tooltip>
    //   ),
    // },
    {
      title: "",
      dataIndex: "admin_group_id",
      key: "admin_group_id",
      render: text => (
        <Tooltip title={t("View admin in group")}>
          <a>
            <FcSearch onClick={() => history.push(`/admin/group/${text}`)} size={20} />
          </a>
        </Tooltip>
      ),
    },
    {
      title: "",
      render: text => (
        <Tooltip title={t("Edit name admin group")}>
          <a>
            <FcSettings
              size={20}
              onClick={() => {
                setVisible_edit(true)
                setData_edit({
                  admin_group_name: text.admin_group_name,
                  admin_group_id: text.admin_group_id,
                })
                form_edit.setFieldsValue({
                  admin_group_name: text.admin_group_name,
                })
              }}
            />
          </a>
        </Tooltip>
      ),
    },
  ]

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Admin group")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/admin-management")}
              />
            </a>{" "}
            {t("Admin group")}
          </h3>

          <Card>
            <CardBody>
              <Button
                className="btn-create-new-sub-product"
                type="primary"
                onClick={() => {
                  setVisible_create(true)
                  setData_create({
                    admin_group_name: null,
                  })
                }}
              >
                {t("Create new admin group")}
              </Button>

              <Table
                loading={loading}
                rowKey="admin_group_id"
                columns={columns}
                dataSource={data_list}
                scroll={{ x: 400 }}
              />

              <Modal
                centered
                open={visible_create}
                footer={false}
                onCancel={() => {
                  setVisible_create(false)
                  setData_create({})
                  cancel_create_Form()
                }}
                // width={1000}
              >
                <Form
                  form={form_create}
                  name="form_create_data"
                  onFinish={onFinish_create}
                >
                  <Input_Comp
                    placeholder={t("Admin group name")}
                    header={t("Admin group name")}
                    headState={setData_create}
                    nameState={data_create}
                    keyState="admin_group_name"
                    type="text"
                    required={true}
                  />
                  <br />
                  <Row>
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <div className="div_create_new_car">
                        <Button
                          className="btn_cancel_create_new_car"
                          onClick={() => cancel_create_Form()}
                        >
                          {t("Cancel")}
                        </Button>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <div className="div_create_new_car">
                        <Button
                          className="btn_submit_create_new_car"
                          type="primary"
                          htmlType="submit"
                          loading={loading}
                        >
                          {t("Create")}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Modal>

              <Modal
                centered
                open={visible_edit}
                footer={false}
                onCancel={() => {
                  setVisible_edit(false)
                  setData_edit({})
                  cancel_edit_Form()
                }}
                // width={1000}
              >
                <Form
                  form={form_edit}
                  name="form_edit_data"
                  onFinish={onFinish_edit}
                >
                  <Input_Comp
                    placeholder={t("Admin group name")}
                    header={t("Admin group name")}
                    headState={setData_edit}
                    nameState={data_edit}
                    keyState="admin_group_name"
                    type="text"
                    required={false}
                  />
                  <br />
                  <Row>
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <div className="div_create_new_car">
                        <Button
                          className="btn_cancel_create_new_car"
                          onClick={() => cancel_edit_Form()}
                        >
                          {t("Cancel")}
                        </Button>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <div className="div_create_new_car">
                        <Button
                          className="btn_submit_create_new_car"
                          type="primary"
                          htmlType="submit"
                          loading={loading}
                        >
                          {t("Edit")}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Modal>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
