import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { Button, Input, Form, Modal } from "antd"
import { UserAddOutlined } from "@ant-design/icons"
import Create_new_customer from '../Create_new_customer'
import { useHistory } from "react-router-dom"
import "./Search_Customer.css"
import { BiUserPlus} from "react-icons/bi"
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux"

export default function Search(props) {
  const [visible, setVisible] = useState(false)
  const history = useHistory()
  const { t } = useTranslation();
  const { permissionAllService } = useSelector(({ Distar }) => Distar)
  const permi = localStorage.getItem('permi')
  const [permi_t, setPermi_t] = useState(false)

useEffect(() => {
  if(permissionAllService){
    if(permissionAllService.customer){
      setPermi_t(permissionAllService.customer.write)
    }
  }
  return () => {
    // setPermi_t(false)
  }
}, [permissionAllService])

  const search = values => {
    props.search_data()
  }

  return (
    <div>
      <Form
        name="Search_customer"
        initialValues={{
          remember: true,
        }}
        onFinish={search}
      >
        <Row>
          <Col sm={12} md={6} lg={6} xl={4}>
            <Form.Item
              name="search"
              rules={[
                {
                  required: false,
                  message: "Please input name customer" ,
                },
              ]}
            >
              <Input
                placeholder={` ${t("name")} , ${t("tax id")}`}
                style={{ marginBottom: "15px" }}
                onChange={e => props.setSearch_data_user(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={6} lg={4} xl={4}>
            <Button
              type="primary"
              className="button-search-employee"
              htmlType="submit"
            >
             {t("search")}
            </Button>
            {
              permi_t === true && (
                <Button
                className="button-search-New-Employee"
                onClick={() => history.push('/create-new-customer')}
                icon={<BiUserPlus style={{ fontSize: "20px", marginRight:'10px', marginBottom:'3px' }} />}
              >
                {t("new customer")}
              </Button>
              )
              }

          </Col>
        </Row>
      </Form>

{/* Modal */}
      <Modal
        title="New customer"
        centered
        open={visible}
        footer={false}
        onCancel={() => setVisible(false) }
      >
        <Create_new_customer 
        setVisible={setVisible}
        />
      </Modal>

    </div>
  )
}
