import React from "react"
import { Row, Col } from "reactstrap"
import { Avatar } from "antd"
import { UserOutlined } from "@ant-design/icons"
import Render_Center_Avatar from '../../Operator/Table_data/Render_Center_Avatar'
import { useTranslation } from "react-i18next";

export default function Overview(props) {
  const { t } = useTranslation();

    const text_header = {
        fontWeight: "bold",
        fontSize: "15px",
      }

    return (
        <div>
          <p
        style={{
          textAlign: "center",
          fontWeight: "bold",
          marginTop: "10px",
          fontSize: "15px",
        }}
      >
        {t("Dealer user information")}
      </p>

      <Row>
        <Col>
        <Render_Center_Avatar path_image={props.data_dealer_user.dea_user_avatar} size={120} />
          <br />
        </Col>
      </Row>

      <Row>
        <Col sm={6} md={2} lg={2} xl={2}>
          <p style={text_header}> {t("Firstname")} </p>
        </Col>
        <Col sm={6} md={4} lg={4} xl={4}>
          <p style={{ fontSize: "15px" }}>
            {" "}
            <span className="tet">
              :
            </span> <span className="tetTwo">-</span>{" "}
            {props.data_dealer_user.dea_user_firstname}{" "}
          </p>
        </Col>

        <Col sm={6} md={2} lg={2} xl={2}>
          <p style={text_header}> {t("Lastname")} </p>
        </Col>
        <Col sm={6} md={4} lg={4} xl={4}>
          <p style={{ fontSize: "15px" }}>
            {" "}
            <span className="tet">
              :
            </span> <span className="tetTwo">-</span>{" "}
            {props.data_dealer_user.dea_user_lastname}{" "}
          </p>
        </Col>

        <Col sm={6} md={2} lg={2} xl={2}>
        <p style={text_header}> {t("Code")} </p>
      </Col>
      <Col sm={6} md={4} lg={4} xl={4}>
        <p style={{ fontSize: "15px" }}>
          {" "}
          <span className="tet">
            :
          </span> <span className="tetTwo">-</span>{" "}
          {props.data_dealer_user.dea_user_code}{" "}
        </p>
      </Col>

      <Col sm={6} md={2} lg={2} xl={2}>
      <p style={text_header}> {t("Email")} </p>
    </Col>
    <Col sm={6} md={4} lg={4} xl={4}>
      <p style={{ fontSize: "15px" }}>
        {" "}
        <span className="tet">
          :
        </span> <span className="tetTwo">-</span>{" "}
        {props.data_dealer_user.dea_user_email}{" "}
      </p>
    </Col>

    <Col sm={6} md={2} lg={2} xl={2}>
    <p style={text_header}> {t("Phone")} </p>
  </Col>
  <Col sm={6} md={4} lg={4} xl={4}>
    <p style={{ fontSize: "15px" }}>
      {" "}
      <span className="tet">
        :
      </span> <span className="tetTwo">-</span>{" "}
      {props.data_dealer_user.dea_user_phone}{" "}
    </p>
  </Col>
      </Row>

        </div>
    )
}
