import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { Tooltip, Tag, Table, Card, Collapse } from "antd"
import Render_Image from "../New_Customer/Devices/Render_Image"
import Render_Image_Data from "../Customers/Table/Render_Image_Data"
import Render_Customer_Avatar from "./components/Render_Customer_Avatar"
import Show_Gps_Car from "./Show_Gps_Car"
import Card_Detail from "./components/Card_Detail"
import { FcAcceptDatabase } from "react-icons/fc"
import Detail_Service_Create from "./Detail_Service_Create"
import { useTranslation } from "react-i18next";

import car from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/car.png"
import ambulance from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/ambulance.png"
import vespa from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/vespa.png"
import bus from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/bus.png"
import bicycle from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/bicycle.png"
import van from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/van.png"
import truck from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/truck.png"
import taxi from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/taxi.png"
import pickup from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/pickup.png"
import motorboat from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/motorboat.png"
import motor from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/motor.png"
import no_car from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_car.png"

import dealer_info from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/dealer_info.png"
import car_info from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/car_info.png"
import product_info from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/product_info.png"
import sub_product_info from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/sub_product_info.png"
import customer_info from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/customer_info.png"
import contract_info from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/contract_info.png"

const { Panel } = Collapse

export default function Detail_Stock_modal(props) {
  const { t } = useTranslation();
  const [con_name_data, setCon_name_data] = useState(null)
  const [data_sub_product, setData_sub_product] = useState([])
  const [data_sim, setData_sim] = useState(null)

  useEffect(() => {
    if(props.sim !== undefined){
      
      setData_sim(props.sim.pro_code)
      setData_sub_product([
        {
          header1: t("Sim code"),
          dataContract1: checkData(props.sim.pro_code),
          header2: t("Sim number"),
          dataContract2: checkData(props.sim.pro_no),
        },
        {
          header1: t("Sim name"),
          dataContract1: checkData(props.sim.pro_model_name),
          header2: t("Sim model code"),
          dataContract2: checkData(props.sim.pro_model_code),
        },
        {
          header1: t("Sim group name"),
          dataContract1: checkData(props.sim.pro_group_name),
          header2: t("Sim serial number"),
          dataContract2: checkData(props.sim.pro_serial_number),
        },
        {
          header1: t("Sim model factory"),
          dataContract1: checkData(props.sim.pro_model_factory),
          header2: t("Sim model protocal"),
          dataContract2: checkData(props.sim.pro_model_protocol),
        },
        {
          header1: t("Sim model remark"),
          dataContract1: checkData(props.sim.pro_model_remark),
          header2: t("Sim remark"),
          dataContract2: checkData(props.sim.pro_remark),
        }
      ])
    }
    return () => {
      
    }
  }, [props.sim])

  // const data_sub_product = [
  //   {
  //     header1: "Sim code",
  //     dataContract1: checkData(props.sim.pro_code),
  //     header2: "Sim number",
  //     dataContract2: checkData(props.sim.pro_no),
  //   },
  //   {
  //     header1: "Sim name",
  //     dataContract1: checkData(props.sim.pro_model_name),
  //     header2: "Sim model code",
  //     dataContract2: checkData(props.sim.pro_model_code),
  //   },
  //   {
  //     header1: "Sim group name",
  //     dataContract1: checkData(props.sim.pro_group_name),
  //     header2: "Sim serial number",
  //     dataContract2: checkData(props.sim.pro_serial_number),
  //   },
  //   {
  //     header1: "Sim model factory",
  //     dataContract1: checkData(props.sim.pro_model_factory),
  //     header2: "Sim model protocal",
  //     dataContract2: checkData(props.sim.pro_model_protocol),
  //   },
  //   {
  //     header1: "Sim model remark",
  //     dataContract1: checkData(props.sim.pro_model_remark),
  //     header2: "Sim remark",
  //     dataContract2: checkData(props.sim.pro_remark),
  //   }
  // ]

  useEffect(() => {
    if (props.contract_table) {
      if (props.contract_table[0]) {
        setCon_name_data(props.contract_table[0].con_name)
      }
    }
    return () => {
      setCon_name_data(null)
    }
  }, [props.contract_table])

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return "N/A"
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  function checkData(data) {
    if (data) {
      return data
    } else {
      return "N/A"
    }
  }

  function render_Icon_Header_Car(type_car) {
    switch (type_car) {
      case "car":
        return car
        break
      case "vespa":
        return vespa
        break
      case "van":
        return van
        break
      case "truck":
        return truck
        break
      case "taxi":
        return taxi
        break
      case "bus":
        return bus
        break
      case "pickup":
        return pickup
        break
      case "motorboat":
        return motorboat
        break
      case "motor":
        return motor
        break
      case "bicycle":
        return bicycle
        break
      case "ambulance":
        return ambulance
        break
      default:
        return no_car
        break
    }
  }

  function render_Icon_Car(type_car) {
    switch (type_car) {
      case "car":
        return (
          <Tooltip title="car">
            <img src={car} alt="icon" style={{ width: "50px" }} />
          </Tooltip>
        )
        break
      case "vespa":
        return (
          <Tooltip title="vespa">
            <img src={vespa} alt="icon" style={{ width: "50px" }} />
          </Tooltip>
        )
        break
      case "van":
        return (
          <Tooltip title="van">
            <img src={van} alt="icon" style={{ width: "50px" }} />
          </Tooltip>
        )
        break
      case "truck":
        return (
          <Tooltip title="truck">
            <img src={truck} alt="icon" style={{ width: "50px" }} />
          </Tooltip>
        )
        break
      case "taxi":
        return (
          <Tooltip title="taxi">
            <img src={taxi} alt="icon" style={{ width: "50px" }} />
          </Tooltip>
        )
        break
      case "bus":
        return (
          <Tooltip title="bus">
            <img src={bus} alt="icon" style={{ width: "50px" }} />
          </Tooltip>
        )
        break
      case "pickup":
        return (
          <Tooltip title="pickup">
            <img src={pickup} alt="icon" style={{ width: "50px" }} />
          </Tooltip>
        )
        break
      case "motorboat":
        return (
          <Tooltip title="motorboat">
            <img src={motorboat} alt="icon" style={{ width: "50px" }} />
          </Tooltip>
        )
        break
      case "motor":
        return (
          <Tooltip title="motor">
            <img src={motor} alt="icon" style={{ width: "50px" }} />
          </Tooltip>
        )
        break
      case "bicycle":
        return (
          <Tooltip title="bicycle">
            <img src={bicycle} alt="icon" style={{ width: "50px" }} />
          </Tooltip>
        )
        break
      case "ambulance":
        return (
          <Tooltip title="ambulance">
            <img src={ambulance} alt="icon" style={{ width: "50px" }} />
          </Tooltip>
        )
        break
      default:
        return (
          <Tooltip title={t("Invalid car type")} >
            <img src={no_car} alt="icon" style={{ width: "50px" }} />
          </Tooltip>
        )
        break
    }
  }

  function render_status(status) {
    if (status === 1) {
      return (
        <Tag color="green" style={{ float: "right" }}>
          Active
        </Tag>
      )
    } else {
      return <Tag style={{ float: "right" }}>Disable</Tag>
    }
  }

  function render_status_contract(status) {
    switch (status) {
      case false:
        return <Tag color="green">Active</Tag>
        break
      case true:
        return <Tag color="error">Expire</Tag>
        break
      default:
        break
    }
  }

  const data_product = [
    {
      header1: t("Product number"),
      dataContract1: checkData(props.data_stock.pro_no),
      header2: t("Product group name"),
      dataContract2: checkData(props.data_stock.pro_group_name),
    },
    {
      header1: t("Product model name"),
      dataContract1: checkData(props.data_stock.pro_model_name),
      header2: t("Product serial number"),
      dataContract2: checkData(props.data_stock.pro_serial_number),
    },
    {
      header1: t("Stock asset number"),
      dataContract1: checkData(props.data_stock.sto_asset_number),
      header2: t("Stock date in"),
      dataContract2: cutTimeZone(props.data_stock.sto_date_in),
    },
    {
      header1: t("Stock date install"),
      dataContract1: cutTimeZone(props.data_stock.sto_date_install),
      header2: t("Stock remark"),
      dataContract2: checkData(props.data_stock.sto_remark),
    },
    {
      header1: t("Product code"),
      dataContract1: checkData(props.data_stock.pro_code),
      header2: t("Product model code"),
      dataContract2: checkData(props.data_stock.pro_model_code),
    },
    {
      header1: t("Product remark"),
      dataContract1: checkData(props.data_stock.pro_remark),
      header2: t("Product model factory"),
      dataContract2: checkData(props.data_stock.pro_model_factory),
    },
  ]

  const data_car = [
    {
      header1: t("Car license number"),
      dataContract1: checkData(props.data_stock.car_license_number),
      header2: t("Car name"),
      dataContract2: checkData(props.data_stock.car_name),
    },
    {
      header1: t("Car code"),
      dataContract1: checkData(props.data_stock.car_code),
      header2: t("Car type"),
      dataContract2: render_Icon_Car(props.data_stock.car_body_type_name),
    },
    {
      header1: t("Car color name"),
      dataContract1: checkData(props.data_stock.car_color_name),
      header2: t("Car chassis number"),
      dataContract2: checkData(props.data_stock.car_chassis_number),
    },
    {
      header1: t("Car model name"),
      dataContract1: checkData(props.data_stock.car_model_name),
      header2: t("Car model year name"),
      dataContract2: checkData(props.data_stock.car_model_year_name),
    },
    {
      header1: t("Car brand name"),
      dataContract1: checkData(props.data_stock.car_brand_name),
      header2: t("Car fuel hours"),
      dataContract2: checkData(props.data_stock.car_fuelhours),
    },
    {
      header1: t("Car fuel tank"),
      dataContract1: checkData(props.data_stock.car_fueltank),
      header2: t("Car fuel km"),
      dataContract2: checkData(props.data_stock.car_fuelkm),
    },
  ]

  const data_dealer = [
    {
      header1: t("Dealer name"),
      dataContract1: checkData(props.data_stock.dea_name),
      header2: t("Dealer code"),
      dataContract2: checkData(props.data_stock.dea_code),
    },
    {
      header1: t("Dealer TAX ID"),
      dataContract1: checkData(props.data_stock.dea_taxid),
      header2: t("Dealer remark"),
      dataContract2: checkData(props.data_stock.dea_remark),
    },
    {
      header1: t("Dealer fax"),
      dataContract1: checkData(props.data_stock.dea_fax),
      header2: t("Dealer phone"),
      dataContract2: checkData(props.data_stock.dea_phone),
    },

    {
      header1: t("Dealer email"),
      dataContract1: checkData(props.data_stock.dea_email),
      header2: t("Dealer address"),
      dataContract2: checkData(props.data_stock.dea_address),
    },

    {
      header1: t("Dealer contact firstname"),
      dataContract1: checkData(props.data_stock.dea_contact_firstname),
      header2: t("Dealer contact lastname"),
      dataContract2: checkData(props.data_stock.dea_contact_lastname),
    },
    {
      header1: t("Dealer contact mobile"),
      dataContract1: checkData(props.data_stock.dea_contact_mobile),
      header2: t("Dealer contact email"),
      dataContract2: checkData(props.data_stock.dea_contact_email),
    },
  ]

  const customer_data = [
    {
      header1: t("Customer name"),
      dataContract1: checkData(props.data_stock.cus_name),
      header2: t("Customer number"),
      dataContract2: checkData(props.data_stock.cus_no),
    },
    {
      header1: t("Customer code"),
      dataContract1: checkData(props.data_stock.cus_code),
      header2: t("Customer credit"),
      dataContract2: checkData(props.data_stock.cus_credit),
    },
    {
      header1: t("Customer TAX ID"),
      dataContract1: checkData(props.data_stock.cus_taxid),
      header2: t("Customer phone"),
      dataContract2: checkData(props.data_stock.cus_phone),
    },
    {
      header1: t("Customer email"),
      dataContract1: checkData(props.data_stock.cus_email),
      header2: t("Customer fax"),
      dataContract2: checkData(props.data_stock.cus_fax),
    },
    {
      header1: t("Customer address"),
      dataContract1: checkData(props.data_stock.cus_address),
      header2: t("Customer remark"),
      dataContract2: checkData(props.data_stock.cus_remark),
    },
  ]

  return (
    <div>
    <Collapse>
    <Panel
      header={
        <p>
          <FcAcceptDatabase size={25} style={{ marginRight: "10px" }} />
          {t("Stock imported information")}
        </p>
      }
      key="1"
    >
      <p
        style={{
          textAlign: "center",
          fontWeight: "bold",
          marginTop: "10px",
          fontSize: "25px",
          marginBottom: "40px",
        }}
      >
        {" "}
        {props.data_stock.sto_asset_number}{" "}
      </p>
      <Card_Detail
        image_header={product_info}
        alt_image_header="product_info"
        text_header={t("Product information")}
        title_data={`${checkData(
          props.data_stock.pro_model_name
        )} ${checkData(props.data_stock.pro_serial_number)}`}
        title_data_tow={null}
        path_image={props.data_stock.pro_model_img}
        data_map={data_product}
        type_avatar="product"
        use_table={false}
        data_table={null}
      />
      <br />
      <Card_Detail
        image_header={render_Icon_Header_Car(
          props.data_stock.car_body_type_name
        )}
        alt_image_header="car_info"
        text_header={t("Car information")}
        path_image={null}
        title_data={checkData(props.data_stock.car_license_number)}
        title_data_tow={null}
        data_map={data_car}
        type_avatar={null}
        use_table={false}
        data_table={null}
      />
      <br />
      <Card_Detail
        image_header={dealer_info}
        alt_image_header="dealer_info"
        text_header={t("Dealer information")}
        title_data={checkData(props.data_stock.dea_name)}
        title_data_tow={null}
        path_image={null}
        data_map={data_dealer}
        type_avatar={null}
        use_table={false}
        data_table={null}
      />
      <br />
      <Card_Detail
        image_header={contract_info}
        alt_image_header="contract_info"
        text_header={t("Contract information")}
        title_data={con_name_data}
        title_data_tow={null}
        path_image={null}
        data_map={data_dealer}
        type_avatar={null}
        use_table={true}
        data_table={props.contract_table}
      />
      <br />
      <Card_Detail
        image_header={customer_info}
        alt_image_header="customer_info"
        text_header={t("Customer information")}
        title_data={checkData(props.data_stock.cus_name)}
        title_data_tow={null}
        path_image={props.data_stock.cus_avatar}
        data_map={customer_data}
        type_avatar="user"
        use_table={false}
        data_table={null}
      />
      <br />
      {
        props.sim !== undefined && (
          <Card_Detail
          image_header={sub_product_info}
          alt_image_header="sub_product_info"
          text_header={t("Sub product information")}
          title_data={checkData(data_sim)}
          title_data_tow={null}
          path_image={null}
          data_map={data_sub_product}
          type_avatar="user"
          use_table={false}
          data_table={null}
        />
        )
      }

    </Panel>
  </Collapse>
  <br /> <br />
      <Detail_Service_Create />
      <br />
      <Show_Gps_Car data_stock={props.data_stock} />
      <br />
      <p className="text-bold"> {t("Contract information")} </p>
      <Card_Detail
        image_header={contract_info}
        alt_image_header="contract_info"
        text_header={t("Contract information")}
        title_data={con_name_data}
        title_data_tow={null}
        path_image={null}
        data_map={data_dealer}
        type_avatar={null}
        use_table={true}
        data_table={props.contract_table}
      />
      <br />
      <p className="text-bold"> {t("Required part")} </p>
      <Card_Detail
        image_header={product_info}
        alt_image_header="product_info"
        text_header={t("Product information")}
        title_data={checkData(props.data_stock.pro_model_name)}
        title_data_tow={`${checkData(props.data_stock.pro_serial_number)}`}
        path_image={props.data_stock.pro_model_img}
        data_map={data_product}
        type_avatar="product"
        use_table={false}
        data_table={null}
      />
      <br />
      <p className="text-bold"> {t("Customer information")} </p>
      <Card_Detail
        image_header={customer_info}
        alt_image_header="customer_info"
        text_header={t("Customer information")}
        title_data={checkData(props.data_stock.cus_name)}
        title_data_tow={null}
        path_image={props.data_stock.cus_avatar}
        data_map={customer_data}
        type_avatar="user"
        use_table={false}
        data_table={null}
      />
      <br />
    </div>
  )
}
