import React, { useState, useEffect } from "react"
import { Container, Card, CardBody } from "reactstrap"
import MetaTags from "react-meta-tags"
import { useHistory, useParams } from "react-router-dom"
import { MdKeyboardBackspace } from "react-icons/md"
import axios from "axios"
import Detail_Stock from "./Detail_Stock"
import * as api from "../../service/Api_New_Service_Has_Stock"
import helpers from "../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"
import no_permi from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png"
// import Form_Service from "./Form_Service"
import Form_Service from '../New_Service_Has_Stock_Id/Form_Service'
import { useSelector } from "react-redux"

const New_Service = props => {
  const history = useHistory()
  const { t } = useTranslation()
  const token = localStorage.getItem("token")
  const [data_stock, setData_stock] = useState({})
  const [sim, setSim] = useState({})
  const [contract_table, setContract_table] = useState([])
  const permi = localStorage.getItem("permi")
  const [permi_t, setPermi_t] = useState(false)
  const { permissionAllService } = useSelector(({ Distar }) => Distar)
  const { sto_id } = useParams()

  useEffect(() => {
    check_new_data_edit()
    return () => {
      setData_stock({})
      setSim({})
      setContract_table([])
    }
  }, [sto_id])

  useEffect(() => {
    if (permissionAllService) {
      if (permissionAllService.service) {
        setPermi_t(permissionAllService.service.write)
      }
    }
    return () => {}
  }, [permissionAllService])

  function check_new_data_edit() {
    const new_data = api.GET_DETAIL_STOCK(token, parseInt(sto_id))
    axios(new_data)
      .then(res => {
        if (!res.data.data) {
          history.push("/new-service")
        } else {
          setData_stock(res.data.data)
          list_sub_product_info(res.data.data.pro_id)
          list_sub_contract(res.data.data.sto_id)
        }
      })
      .catch(error => {
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }

        // console.log(error.response)
      })
  }

  function list_sub_contract(sto_id) {
    const list = api.LIST_STOCK_HAST_PRODUCT(token, sto_id)
    axios(list)
      .then(res => {
        setContract_table(res.data.data)
      })
      .catch(error => {
        // console.log(error.response)
      })
  }

  function list_sub_product_info(pro_id) {
    const list = api.LIST_SUB_PRODUCT(token, pro_id)
    axios(list)
      .then(res => {
        setSim(res.data.data[0])
      })
      .catch(error => {
        // console.log(error.response)
      })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Create service")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/new-service")}
              />
            </a>{" "}
            {t("Create service")}{" "}
          </h3>
          <br />

          {permi_t === true && (
            <Card>
              <CardBody>
                <Detail_Stock
                  sim={sim}
                  data_stock={data_stock}
                  contract_table={contract_table}
                />

                <Form_Service data_stock={data_stock} />
              </CardBody>
            </Card>
          )}

          {permi_t === false && (
            <div>
              <img
                src={no_permi}
                alt="no permission"
                style={{ display: "block", margin: "50px auto" }}
              />
              <p style={{ textAlign: "center", fontSize: "20px" }}>
                {t("no permission write module")}
              </p>
              <p style={{ textAlign: "center", fontSize: "20px" }}>
                {" "}
                {t("please contract administrator")}{" "}
              </p>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
export default New_Service
