import React, { useState, useEffect } from "react"
import { Tabs } from "antd"
import Table from "./Table"
import Create_new_contract_in_customer from "./Create_new_contract_in_customer"
import * as api from "../../../service/Api_New_Customer"
import axios from "axios"
import { useHistory } from "react-router-dom"
import helpers from "../../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"

const { TabPane } = Tabs

export default function Contract(props) {
  const token = localStorage.getItem("token")
  const history = useHistory()
  const { t } = useTranslation()
  const [activeTab_contract, setActiveTab_contract] = useState("1")
  const [data_search_contract, setData_search_contract] = useState(null)
  const [data_contract, setData_contract] = useState([])
  const [page_limit_contract, setPage_limit_contract] = useState({
    page_contract: 1,
    limit_contract: 0,
    page_Size_contract: 10,
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (props.id) {
      search_data_contract()
    }
    return () => {
      setData_contract([])
    }
  }, [props.id])

  function search_data_contract() {
    setLoading(true)
    if (data_search_contract) {
      const search_contract = api.SEARCH_DATA_CONTRACT_IN_CUSTOMER(
        token,
        props.id,
        1,
        page_limit_contract.page_Size_contract,
        data_search_contract
      )
      axios(search_contract)
        .then(res => {
          setLoading(false)
          setPage_limit_contract({
            ...page_limit_contract,
            page_contract: 1,
            limit_contract: res.data.total_data,
          })
          setData_contract(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else {
      const search_no_data = api.SEARCH_DATA_CONTRACT_IN_CUSTOMER_NODATA(
        token,
        props.id,
        1,
        page_limit_contract.page_Size_contract
      )
      axios(search_no_data)
        .then(res => {
          setLoading(false)
          setPage_limit_contract({
            ...page_limit_contract,
            page_contract: 1,
            limit_contract: res.data.total_data,
          })
          setData_contract(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  function changePagination_contract(page, pageSize) {
    setLoading(true)
    if (data_search_contract) {
      const search_data = api.SEARCH_DATA_CONTRACT_IN_CUSTOMER(
        token,
        props.id,
        page,
        pageSize,
        data_search_contract
      )
      axios(search_data)
        .then(res => {
          setLoading(false)
          setPage_limit_contract({
            ...page_limit_contract,
            page_contract: page,
            page_Size_contract: pageSize,
            limit_contract: res.data.total_data,
          })
          setData_contract(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else {
      const search_no_data = api.SEARCH_DATA_CONTRACT_IN_CUSTOMER_NODATA(
        token,
        props.id,
        page,
        pageSize
      )
      axios(search_no_data)
        .then(res => {
          setLoading(false)
          setPage_limit_contract({
            ...page_limit_contract,
            page_contract: page,
            page_Size_contract: pageSize,
            limit_contract: res.data.total_data,
          })
          setData_contract(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  return (
    <Tabs
      defaultActiveKey="1"
      onTabClick={e => {
        setActiveTab_contract(e)
      }}
      activeKey={activeTab_contract}
    >
      <TabPane tab={t("Contract")} key="1">
        <Table
          id={props.id}
          setData_search_contract={setData_search_contract}
          search_data_contract={search_data_contract}
          changePagination_contract={changePagination_contract}
          page_limit_contract={page_limit_contract}
          data_contract={data_contract}
          loading={loading}
        />
      </TabPane>
      <TabPane tab={t("New contract")} key="2">
        <Create_new_contract_in_customer
          cus_id={props.id}
          setActiveTab_contract={setActiveTab_contract}
          search_data_contract={search_data_contract}
        />
      </TabPane>
    </Tabs>
  )
}
