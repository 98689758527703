import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { useHistory } from "react-router-dom"
import { Container, Card, CardBody, Row, Col } from "reactstrap"
import { MdKeyboardBackspace } from "react-icons/md"
import { Form, Button, Modal, notification, Tabs } from "antd"
import axios from "axios"
import * as api from "../../../service/Api_Create_new_dealer"
import Input_Comp from "./components/Input_Comp"
import Input_Number from "./components/Input_Number"
import Select_no_search from "./components/Select_no_search"
import Select_Postcode from "./components/Select_Postcode"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs
const { confirm } = Modal

export default function Contract(props) {
  const history = useHistory()
  const [form] = Form.useForm()
  const { t } = useTranslation();
  const token = localStorage.getItem("token")
  const [error_tax_id, setError_tax_id] = useState(null)
  const [data_dealer, setData_dealer] = useState({})
  const [location_select, setLocation_select] = useState({})
  const [postcode_select, setPostcode_select] = useState([])

  useEffect(() => {
    onFill()

    return () => {}
  }, [props.data])

  const onFill = () => {
    form.setFieldsValue({
      dea_address: props.data.addr,
      dea_code: props.data.gid,
      dea_name: props.data.nickname,
      dea_phone: props.data.phone,
      dea_email: props.data.email
    })
  }

  useEffect(() => {
    function check_dealer_duplicate() {
      if (data_dealer.dea_taxid) {
        const check_duplicate = api.CHECK_DEALER_TAX_ID_DUPLICATE(
          token,
          data_dealer.dea_taxid
        )
        axios(check_duplicate)
          .then(res => {
            if (res.data === "True") {
              setError_tax_id(t("TAX ID is duplicate"))
            } else {
              setError_tax_id(null)
            }
          })
          .catch(error => {
            // console.log(error)
          })
      }
    }
    check_dealer_duplicate()
    return () => {}
  }, [data_dealer.dea_taxid])

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  function cancelForm() {
    let data_all = []
    data_all = [...props.data_edit_dealer]
      data_all[props.index_state] = {
        dea_address: props.data.addr ,
        dea_code: props.data.gid ,
        dea_name: props.data.nickname ,
        dea_phone: props.data.phone ,
        dea_email: props.data.email ,
        dea_contact_email: null ,
        dea_contact_firstname: null ,
        dea_contact_lastname: null ,
        dea_contact_mobile: null ,
        dea_credit: null ,
        dea_fax: null ,
        dea_pos_id: null ,
        dea_taxid: null ,
        // zip_id: null
      }

    props.setData_edit_dealer(data_all)

    form.resetFields()
    setData_dealer({})
    setLocation_select({})
    setPostcode_select([])
    onFill()
  }

  return (
    <div>
      <p
        style={{
          textAlign: "center",
          fontWeight: "bold",
          marginTop: "10px",
          fontSize: "15px",
        }}
      >
        {t("Dealer form")}
      </p>
      <Row>
        <Col>
          <Button
            onClick={() => cancelForm()}
            style={{ float: "right", width: "150px" }}
          >
            {t("Cancel edit")}
          </Button>
        </Col>
      </Row>
      <br />
      <Form
        name="create_new_dealer"
        initialValues={{
          remember: true,
        }}
        form={form}
      >
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Dealer TAX ID")}
              placeholder={t("Dealer TAX ID")}
              headState={props.setData_edit_dealer}
              nameState={props.data_edit_dealer}
              index_state={props.index_state}
              type="text"
              keyState="dea_taxid"
              disabled={false}
            />
            {error_tax_id && (
              <p style={{ color: "red", fontSize: "15px" }}>{error_tax_id}</p>
            )}
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Dealer code")}
              placeholder={t("Dealer code")}
              headState={props.setData_edit_dealer}
              nameState={props.data_edit_dealer}
              index_state={props.index_state}
              type="text"
              keyState="dea_code"
              disabled={true}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Dealer name")}
              placeholder={t("Dealer name")}
              headState={props.setData_edit_dealer}
              nameState={props.data_edit_dealer}
              index_state={props.index_state}
              type="text"
              keyState="dea_name"
              disabled={false}
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Number
              header={t("Dealer credit (Day)")}
              placeholder={t("Dealer credit (Day)")}
              headState={props.setData_edit_dealer}
              nameState={props.data_edit_dealer}
              index_state={props.index_state}
              keyState="dea_credit"
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Dealer phone")}
              placeholder={t("Dealer phone")}
              headState={props.setData_edit_dealer}
              nameState={props.data_edit_dealer}
              index_state={props.index_state}
              type="text"
              keyState="dea_phone"
              disabled={false}
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Dealer email")}
              placeholder={t("Dealer email")}
              headState={props.setData_edit_dealer}
              nameState={props.data_edit_dealer}
              index_state={props.index_state}
              type="email"
              keyState="dea_email"
              disabled={false}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Dealer fax")}
              placeholder={t("Dealer fax")}
              headState={props.setData_edit_dealer}
              nameState={props.data_edit_dealer}
              index_state={props.index_state}
              type="text"
              keyState="dea_fax"
              disabled={false}
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Dealer position")}
              placeholder={t("Dealer position")}
              name_api="LIST_POSITION"
              map_name="pos_name"
              map_value="pos_id"
              keyState="dea_pos_id"
              headState={props.setData_edit_dealer}
              nameState={props.data_edit_dealer}
              index_state={props.index_state}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Contact firstname")}
              placeholder={t("Contact firstname")}
              headState={props.setData_edit_dealer}
              nameState={props.data_edit_dealer}
              index_state={props.index_state}
              type="text"
              keyState="dea_contact_firstname"
              disabled={false}
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Contact lastname")}
              placeholder={t("Contact lastname")}
              headState={props.setData_edit_dealer}
              nameState={props.data_edit_dealer}
              index_state={props.index_state}
              type="text"
              keyState="dea_contact_lastname"
              disabled={false}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Contact email")}
              placeholder={t("Contact email")}
              headState={props.setData_edit_dealer}
              nameState={props.data_edit_dealer}
              index_state={props.index_state}
              type="email"
              keyState="dea_contact_email"
              disabled={false}
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Contact mobile")}
              placeholder={t("Contact mobile")}
              headState={props.setData_edit_dealer}
              nameState={props.data_edit_dealer}
              index_state={props.index_state}
              type="text"
              keyState="dea_contact_mobile"
              disabled={false}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Select_Postcode
              location_select={location_select}
              setLocation_select={setLocation_select}
              postcode_select={postcode_select}
              setPostcode_select={setPostcode_select}
              setData_edit_dealer={props.setData_edit_dealer}
              data_edit_dealer={props.data_edit_dealer}
              index_state={props.index_state}

              setData_dealer={setData_dealer}
              data_dealer={data_dealer}
            />
          </Col>
        </Row>

      </Form>
    </div>
  )
}
