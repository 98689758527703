import React,{ useState, useEffect } from 'react'
import Table_data from './Table_data'
import Create_new_service from './Create_new_service'
import { Tabs } from "antd"
import axios from 'axios'
import * as api from '../../../service/Api_Edit_Contract'

const { TabPane } = Tabs

export default function Service(props) {
  const token = localStorage.getItem('token')
  const [data_service_search, setData_service_search] = useState(null)
  const [activeTab_service, setActiveTab_service] = useState("1")
  const [data_service, setData_service] = useState([])
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    search_service()
    return () => {
        
    }
}, [props.con_id])

  function search_service() {
    setLoading(true)
    if(data_service_search){
        const list_data_service_data = api.LIST_DATA_SERVICE_IN_CONTRACT_DATA(
            token, props.con_id, 1, page_limit.page_Size, data_service_search
        )
        axios(list_data_service_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setData_service(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          console.log(error)
        })
    } else {
        const list_data_service_no_data = api.LIST_DATA_SERVICE_IN_CONTRACT_NO_DATA(
            token, props.con_id, 1, page_limit.page_Size
        )
        axios(list_data_service_no_data)
        .then(res => {
          setLoading(false)
        setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
        })
        setData_service(res.data.data)
        })
        .catch(error => {
          setLoading(false)
        console.log(error)
        })
    }
}

function changePagination_service(page, pageSize) {
  setLoading(true)
    if(data_service_search){
        const list_data_service_data = api.LIST_DATA_SERVICE_IN_CONTRACT_DATA(
            token, props.con_id, page, pageSize, data_service_search
        )
        axios(list_data_service_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setData_service(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          console.log(error)
        })
    } else {
        const list_data_service_no_data = api.LIST_DATA_SERVICE_IN_CONTRACT_NO_DATA( token, props.con_id, page, pageSize )
        axios(list_data_service_no_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setData_service(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          console.log(error)
        })
    }
}

  return (
    <Tabs
      defaultActiveKey="1"
      onTabClick={e => {
        setActiveTab_service(e)
      }}
      activeKey={activeTab_service}
    >
      <TabPane tab="Service" key="1">
        <Table_data 
            con_id={props.con_id}
            data_service={data_service}
            search_service={search_service}
            setData_service_search={setData_service_search}
            changePagination_service={changePagination_service}
            page_limit={page_limit}
            loading={loading}
        />
      </TabPane>
      <TabPane tab="Create new service" key="2">
        <Create_new_service 
          cus_id={props.cus_id}
            setActiveTab_service={setActiveTab_service}
            con_id={parseInt(props.con_id)} 
            search_service={search_service}
        />
      </TabPane>
    </Tabs>
  )
}
