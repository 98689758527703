import React from 'react'
import { Form, Input, Button} from "antd"
import { Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next";

export default function Search_data(props) {
  const { t } = useTranslation();
    const onFinish = values => {
        props.search_data_device()
      }

    return (
        <Form
        name="Search_device_table_info"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Form.Item
              name="name_search_device_table_info"
              rules={[
                {
                  required: false,
                  message: "Please input data search",
                },
              ]}
            >
              <Input
                placeholder={t("name")}
                onChange={e => props.setData_search_device(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={1} lg={1} xl={1}>
            <Button type="primary" htmlType="submit" className="button-search-employee"  >
              {t("search")}
            </Button>
          </Col>
        </Row>
      </Form>
    )
}
