import React, { useEffect, useState } from "react"
import axios from "axios"
import { Row, Col } from "reactstrap"
import * as api from "../../service/Api_System_Settings"
import { Form, Button, notification, Modal } from "antd"
import Input_Comp from "./Components/Input_Comp"
import Input_TextArea_Comp from "./Components/Input_TextArea_Comp"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import no_permi from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png"
import helpers from "../Function_Redirect/Redirect_login"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"

const { confirm } = Modal

export default function Edit_Email_Noti(props) {
  const token = localStorage.getItem("token")
  const { t } = useTranslation()
  const [form_create] = Form.useForm()
  const history = useHistory()
  const [form_edit] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [data_content, setData_content] = useState(null)
  const [create_content, setCreate_content] = useState({})
  const [edit_content, setEdit_content] = useState({})

  const permi = localStorage.getItem("permi")
  const [permi_t_read, setPermi_t_read] = useState(false)
  const [permi_t_write, setPermi_t_write] = useState(false)
  const [permi_t_edit, setPermi_t_edit] = useState(false)
  const [permi_t_delete, setPermi_t_delete] = useState(false)

  useEffect(() => {
    if (permi) {
      if (JSON.parse(permi).system_setting) {
        setPermi_t_read(
          JSON.parse(permi).system_setting.read
            ? JSON.parse(permi).system_setting.read
            : false
        )
        setPermi_t_write(
          JSON.parse(permi).system_setting.write
            ? JSON.parse(permi).system_setting.write
            : false
        )
        setPermi_t_edit(
          JSON.parse(permi).system_setting.edit
            ? JSON.parse(permi).system_setting.edit
            : false
        )
        setPermi_t_delete(
          JSON.parse(permi).system_setting.delete
            ? JSON.parse(permi).system_setting.delete
            : false
        )
      }
    }
    return () => {}
  }, [permi])

  useEffect(() => {
    if (props.type_id) {
      check_content(props.type_id)
    }
    return () => {}
  }, [props.type_id])

  function check_content(type_id) {
    const list_all = api.LIST_CONTENT(token, type_id)
    axios(list_all)
      .then(res => {
        form_edit.resetFields()
        form_create.resetFields()
        setCreate_content({})

        setData_content(res.data.data)
        setEdit_content(res.data.data)
      })
      .catch(error => {
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }

        // console.log(error.response);
      })
  }

  const onFinish_create = values => {
    return confirm({
      title: t("Confirm create message"),
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: t("Confirm"),
      cancelText: t("Cancel"),
      onOk() {
        setLoading(true)
        const create_car_model = api.CREATE_NEW_MESSAGE(
          token,
          props.type_id,
          create_content
        )
        axios(create_car_model)
          .then(res => {
            check_content(props.type_id)
            setLoading(false)
            openNotificationWithIcon("success", t("Create new message success"))
          })
          .catch(error => {
            setLoading(false)
            if (error) {
              if (error.response) {
                if (error.response.status === 401) {
                  helpers.re_login()
                  history.push("/login")
                } else {
                  openNotificationWithIcon(
                    "error",
                    t("Create new message error")
                  )
                }
              }
            }
          })
      },
      onCancel() {},
    })
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  const onReset_all = () => {
    onReset_create()
    onReset_edit()
  }

  const onReset_edit = () => {
    props.setVisible_edit(false)
    props.setType_id(null)
    setEdit_content(data_content)
    onFill()
  }

  const onReset_create = () => {
    form_create.resetFields()
    setCreate_content({})
    props.setVisible_edit(false)
    props.setType_id(null)
  }

  const onFinish_edit = value => {
    return confirm({
      title: t("Confirm edit message"),
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: t("Confirm"),
      cancelText: t("Cancel"),
      onOk() {
        setLoading(true)
        let data_edit = {
          msg_context: edit_content.msg_context,
          msg_footer: edit_content.msg_footer,
          msg_header: edit_content.msg_header,
        }
        const edit_department = api.EDIT_MESSAGE(
          token,
          edit_content.id,
          data_edit
        )
        axios(edit_department)
          .then(res => {
            openNotificationWithIcon("success", t("Edit message success"))
            check_content(props.type_id)
            setLoading(false)
          })
          .catch(error => {
            setLoading(false)
            if (error) {
              if (error.response) {
                if (error.response.status === 401) {
                  helpers.re_login()
                  history.push("/login")
                } else {
                  openNotificationWithIcon("error", t("Edit message error"))
                }
              }
            }
          })
      },
      onCancel() {},
    })
  }

  useEffect(() => {
    if (data_content !== null) {
      onFill()
    }
    return () => {}
  }, [data_content])

  const onFill = () => {
    form_edit.setFieldsValue({
      msg_context: data_content.msg_context,
      msg_footer: data_content.msg_footer,
      msg_header: data_content.msg_header,
    })
  }

  return (
    <div>
      {permi_t_edit === true && (
        <div>
          {data_content === null && (
            <div>
              <p className="text-bold"> {t("Create message")} </p>
              <Form
                form={form_create}
                name="control-hooks"
                onFinish={onFinish_create}
              >
                <Input_Comp
                  placeholder={t("Header")}
                  header={t("Header")}
                  headState={setCreate_content}
                  nameState={create_content}
                  keyState="msg_header"
                  type="text"
                  required={true}
                />
                <Input_TextArea_Comp
                  placeholder={t("Context")}
                  header={t("Context")}
                  headState={setCreate_content}
                  nameState={create_content}
                  keyState="msg_context"
                  type="text"
                  required={true}
                />
                <Input_Comp
                  placeholder={t("Footer")}
                  header={t("Footer")}
                  headState={setCreate_content}
                  nameState={create_content}
                  keyState="msg_footer"
                  type="text"
                  required={true}
                />

                <br />
                <Row>
                  <Col sm={12} md={6} lg={6} xl={6}>
                    <Button
                      className="btn_cancel_create_new_car"
                      onClick={() => onReset_create()}
                    >
                      {t("Cancel")}
                    </Button>
                  </Col>
                  <Col sm={12} md={6} lg={6} xl={6}>
                    <Button
                      className="btn_submit_create_new_car"
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                    >
                      {t("Create")}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          )}

          {data_content !== null && (
            <div>
              <p className="text-bold"> {t("Edit message")} </p>
              <Form
                form={form_edit}
                name="control-hooks"
                onFinish={onFinish_edit}
              >
                <Input_Comp
                  placeholder={data_content.msg_header}
                  header={t("Header")}
                  headState={setEdit_content}
                  nameState={edit_content}
                  keyState="msg_header"
                  type="text"
                  required={true}
                />
                <Input_TextArea_Comp
                  placeholder={data_content.msg_context}
                  header={t("Context")}
                  headState={setEdit_content}
                  nameState={edit_content}
                  keyState="msg_context"
                  type="text"
                  required={true}
                />
                <Input_Comp
                  placeholder={data_content.msg_footer}
                  header={t("Footer")}
                  headState={setEdit_content}
                  nameState={edit_content}
                  keyState="msg_footer"
                  type="text"
                  required={true}
                />

                <br />
                <Row>
                  <Col sm={12} md={6} lg={6} xl={6}>
                    <Button
                      className="btn_cancel_create_new_car"
                      onClick={() => onReset_edit()}
                    >
                      {t("Cancel")}
                    </Button>
                  </Col>
                  <Col sm={12} md={6} lg={6} xl={6}>
                    <Button
                      className="btn_submit_create_new_car"
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                    >
                      {t("Edit")}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          )}
        </div>
      )}

      {permi_t_edit === false && (
        <div>
          <img
            src={no_permi}
            alt="no permission"
            style={{ display: "block", margin: "50px auto" }}
          />
          <p style={{ textAlign: "center", fontSize: "20px" }}>
            {t("no permission edit module")}
          </p>
          <p style={{ textAlign: "center", fontSize: "20px" }}>
            {" "}
            {t("please contract administrator")}{" "}
          </p>
        </div>
      )}
    </div>
  )
}
