import {
  ADD_PRIVATE_ID,
  ADD_NAME_USER ,
  ADD_IMAGE_AVATAR,
  IS_ADMIN_ROOT,
  FIND_IS_ROLE,
  EMAIL_USER_CUSTOMER,
  PERMISSION_ALL_SERVICE,
  _CUSTOMER_USER_ID,
  _DEALER_USER_ID,
  _EMP_ID
} from "./actionTypes"

export const addEmployeeUserID = emp_id => ({
  type: _EMP_ID,
  payload : emp_id
})

export const addDealerUserID = dealer_id => ({
  type: _DEALER_USER_ID,
  payload : dealer_id
})

export const addCustomerUserID = customer_id => ({
  type: _CUSTOMER_USER_ID,
  payload : customer_id
})

export const addPermissionAllServiceWeb = permission_all => ({
  type: PERMISSION_ALL_SERVICE,
  payload : permission_all
})

export const addEmailUserCustomer = emailUserCustomer => ({
  type: EMAIL_USER_CUSTOMER,
  payload : emailUserCustomer
})

export const addPrivateID = privateIDAdd => ({
  type: ADD_PRIVATE_ID,
  payload : privateIDAdd
})

export const addNameUser = nameUserRedux => ({
  type: ADD_NAME_USER,
  payload : nameUserRedux
})

export const addImageAvatar = imageAvatarRedux => ({
  type: ADD_IMAGE_AVATAR,
  payload : imageAvatarRedux
})

export const checkAdminIsRoot = checkAdminRoot => ({
  type: IS_ADMIN_ROOT,
  payload : checkAdminRoot
})

export const checkIsRole = checkRole => ({
  type: FIND_IS_ROLE,
  payload : checkRole
})