import React, { useState, useEffect } from "react"
import "./component/styles.module.sig.css"
import axios from "axios"
import * as api from "../../../../../../service/service_operator/Api_Service"
import { useTranslation } from "react-i18next";

export default function SignaturePad_text(props) {
  const [img, setImg] = useState(null)
  const token = localStorage.getItem("token")
  const { t } = useTranslation();

  useEffect(() => {
    if (props.value !== null) {
      get_image(props.value)
    }
    return () => {
      setImg(null)
    }
  }, [props.value])

  async function get_image(path_image) {
    const get_data_image = api.GET_IMAGE(token, path_image)
    await axios(get_data_image)
      .then(res => {
        const blob = new Blob([res.data], {
          type: "image/jpeg",
        })
        var objectURL = URL.createObjectURL(blob)
        setImg(objectURL)
      })
      .catch(error => {
        // console.log(error.response)
      })
  }

  return (
    <div>
      {props.value === null && (
        <div>
          <p style={{ fontSize:'20px' }}> {t("No signature")} </p>
        </div>
      )}

      {props.value !== null && <img src={img} alt="signature" style={{ width:'100%' , height:'400px' }} />}
    </div>
  )
}
