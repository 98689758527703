import React,{ useEffect, useState } from 'react'
import ReactApexChart from "react-apexcharts"
import { useTranslation } from "react-i18next";
import service_this_year from '../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/service_this_year.png'

export default function Month_This_Year(props) {
  const { t } = useTranslation();
    const [count_year, setCount_year] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [series, setSeries] = useState([])

      useEffect(() => {
        if(props.month_this_year[1]){
            setCount_year([
                props.month_this_year[1].count,
                props.month_this_year[2].count,
                props.month_this_year[3].count,
                props.month_this_year[4].count,
                props.month_this_year[5].count,
                props.month_this_year[6].count,
                props.month_this_year[7].count,
                props.month_this_year[8].count,
                props.month_this_year[9].count,
                props.month_this_year[10].count,
                props.month_this_year[11].count,
                props.month_this_year[12].count,
                
            ])
        }
        return () => {
           setCount_year([])
           setSeries([])
        }
    }, [props.month_this_year])
    
    useEffect(() => {
        setSeries([
            {
                name: t("service"),
                data: count_year
              }
        ])
        return () => {
            
        }
    }, [count_year])
  
      const options = {
        chart: {
          stacked: !0,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: !0
          }
        },
        plotOptions: {
          bar: {
            horizontal: !1,
            columnWidth: "15%"
          }
        },
        dataLabels: {
          enabled: !1
        },
        xaxis: {
          show: true,
          categories: [
            t("jan"),
            t("feb"),
            t("mar"),
            t("apr"),
            t("may"),
            t("jun"),
            t("jul"),
            t("aug"),
            t("sep"),
            t("oct"),
            t("nov"), 
            t("dec"), 
          ],
          labels: {
            show: true
          }
        },
        yaxis: [
          {
            labels: {
              formatter: function(val) {
                return val.toFixed(0);
              }
            }
          }
        ],
        colors: ["#556ee6"],
        legend: {
          position: "bottom"
        },
        fill: {
          opacity: 1
        }
      }
    return (
    <div>
    <p style={{ fontSize: "20px", marginBottom: "15px", color: "#495057" }}> 
    <img src={service_this_year} alt="contract" style={{ width:'25px', height:'25px', marginRight:'20px', marginTop:'-10px' }} /> {t("service this year")} </p>
        <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height="300"
        className="apex-charts"
      />
    </div>
    )
}
