import React,{ useState } from 'react'
import { useHistory } from 'react-router-dom';
import Expand from 'react-expand-animated';
import { FiChevronDown , FiChevronRight } from "react-icons/fi";
import { Button, Modal, notification } from "antd"
import axios from 'axios';
import { Row, Col } from "reactstrap"
import { ExclamationCircleOutlined } from '@ant-design/icons';
import * as api from '../../../service/Api_Edit_Operator'
import helpers from '../../Function_Redirect/Redirect_login'
import { useTranslation } from "react-i18next";

const { confirm } = Modal;

export default function Delete(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const token = localStorage.getItem('token')
    const [open, setOpen] = useState(false)
    const [icon, setIcon] = useState(<FiChevronRight size={20} style={{ marginBottom : '5px'}}/>)
    const [loading, setLoading] = useState(false)

    const setToggle = () =>{
        if(open===false){
            setOpen(true)
            setIcon(<FiChevronDown size={25} style={{ marginBottom : '5px'}}/>)
        } else {
            setOpen(false)
            setIcon(<FiChevronRight size={25} style={{ marginBottom : '5px'}}/>)
        }
    }
    const openNotificationWithIcon = (type, message) => {
        notification[type]({
          message: message,
          
        });
      };

    function delete_employee() {
        return confirm({
            title: t("Are you sure delete operator"),
            icon: <ExclamationCircleOutlined style={{ color:'red' }} />,
            centered : true,
            okText: t("Delete"),
            okType: "danger",
            cancelText: t("no"),
            onOk() {
              setLoading(true)
              const delete_a_operator = api.DELETE_OPERATOR(token, props.ope_id)
              axios(delete_a_operator)
              .then( res => {
                setLoading(false)
                //   console.log(res.data);
              })
              .catch( error => {
                setLoading(false)
                if(error){
                  if(error.response){
                    if(error.response.status === 401){
                      helpers.re_login()
                      history.push('/login')          
                      } else if(error.response.status === 404){
                        openNotificationWithIcon('success', t("Remove operator success"))
                        history.push(`/operator`)
                      } else {
                        openNotificationWithIcon("error", t("Remove operator error"))
                      }
                  }
                }
      

              })

            },
            onCancel() {
            //   console.log('Cancel');
            },
          });
    }

    return (
    <Row>
        <Col sm={12} md={6} lg={4} xl={4} onClick={() => setToggle()}>
            <a> <p style={{ fontSize:'20px' }}> {icon} {t("Delete operator")} </p> </a>
        </Col>
        <Col sm={12} md={6} lg={8} xl={8}>
            <p style={{ color:'rgb(108, 117, 125)' }}> {t("Make sure your want delete operator")} </p>

            <Expand open={open} duration={500}>
                <div style={{ width: '100%', borderRadius:'17px', padding:'10px' }}>
                        <p style={{ color:'red' }}> {t("this action can't be undo")} </p>
                        <Button type="primary" danger style={{width:'200px'}} onClick={() => delete_employee()} loading={loading} >{t("Delete operator")}</Button>
                </div>
            </Expand>
        </Col>
    </Row> 
    )
}
