var Ip = require('../service/Ip')

export const DETAIL_DEALER = (token, dea_id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/dealer/${dea_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_POSITION = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/position`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CHECK_POSTCODE = (postcode) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/location/zip/${postcode}`,
      headers: {
        'Content-Type': 'application/json'
      }
    };
  };

  export const EDIT_DEALER = (token, data, dea_id) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/dealer/${dea_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const DELETE_DEALER = (token,dea_id) => {
    return {
      method: 'delete',
      url: `${Ip.getIP()}api/v1/dealer/${dea_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const USER_IN_DEALER = (token, dea_id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/dealer/dealer-users/${dea_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CREATE_NEW_DEALER_USER_DATA = (token, dea_id , data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/dealer/user/${dea_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const UPLOAD_PROFILE = (token, formData) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}storage/upload`,
      headers: {
        "Content-type": "multipart/form-data",
        'x-access-token' : token
      },
      data : formData
    };
  };

  export const CHECK_DEALER_EMAIL_DUPLICATE = (token, email) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/dealer/checkmaildup/${email}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };