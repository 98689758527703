import React, { useState, useEffect } from "react"
import {
  Form,
  Input,
  Button,
  Select,
  Modal,
  notification,
  Checkbox,
} from "antd"
import { useHistory } from "react-router-dom"
import { Row, Col } from "reactstrap"
import * as api from "../../../service/Api_New_Car"
import axios from "axios"
import Select_customer_car from "./Select_customer_car"
import Select_branch_car from "./Select_branch_car"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import helpers from "../../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"
import Select_no_search from "./Select_no_search"

const { confirm } = Modal
const { Option } = Select

const style_req = {
  color: "red",
}
export default function Create_new_Car(props) {
  const history = useHistory()
  const { t } = useTranslation()
  const token = localStorage.getItem("token")
  const [form] = Form.useForm()
  const [province, setProvince] = useState([])
  const [data_car, setData_car] = useState({
    cus_id: null,
    cus_branch_id: null,
    car_name: null,
    car_license_number: null,
    car_license_province_id: null,
    car_brand_id: null,
    car_model_id: null,
    car_model_year_id: null,
    car_body_type_id: null,
    car_color_id: null,
    car_chassis_number: null,
    car_lpn_mode: false,
    car_group_id: null,
    car_remark : null
  })
  const [loading, setLoading] = useState(false)
  const [no_lpn_car, setNo_lpn_car] = useState(false)

  useEffect(() => {
    onSearch_Province()
    return () => {
      setProvince([])
      setData_car({})
      setLoading(false)
    }
  }, [])

  function onSearch_Province(val) {
    if (val) {
      const get_province_info = api.LIST_PROVINCE(token, val)
      axios(get_province_info)
        .then(res => {
          setProvince(res.data.message)
        })
        .catch(error => {
          // console.log(error)
        })
    } else {
      const get_province_info = api.LIST_PROVINCE_ALL(token)
      axios(get_province_info)
        .then(res => {
          setProvince(res.data.message)
        })
        .catch(error => {
          // console.log(error)
        })
    }
  }
  useEffect(() => {
    render_select_province()
    return () => {}
  }, [province])
  function render_select_province() {
    return province.map(data => {
      return (
        <Option key={data.pro_id} value={data.pro_id}>
          {data.pro_name}
        </Option>
      )
    })
  }
  function onChange_Province(value) {
    setData_car({
      ...data_car,
      car_license_province_id: value,
    })
  }

  {
    /* submit */
  }
  const onFinish = values => {
    if (data_car.cus_id === null) {
      error(t("Please select customer"))
    } else {
      confirm({
        title: t("Confirm create new car"),
        icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
        okText: t("Create"),
        cancelText: t("no"),
        centered: true,
        onOk() {
          setLoading(true)
          const create_new_car = api.CREATE_NEW_CAR(token, data_car)
          axios(create_new_car)
            .then(res => {
              setLoading(false)
              openNotificationWithIcon("success", t("Create new car success"))
              cancelForm()
            })
            .catch(error => {
              setLoading(false)
              if (error) {
                if (error.response) {
                  if (error.response.status === 401) {
                    helpers.re_login()
                    history.push("/login")
                  } else {
                    openNotificationWithIcon("error", t("Create new car error"))
                  }
                }
              }
            })
        },
        onCancel() {},
      })
    }
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  function error(message) {
    Modal.error({
      title: message,
      centered: true,
    })
  }

  const onReset = () => {
    form.resetFields()
  }

  function cancelForm() {
    onReset()
    setData_car({})
    history.push("/cars")
  }

  function onChange_car_no_lpn(e) {
    if (e.target.checked === true) {
      setNo_lpn_car(true)
      form.resetFields(["create_new_car_license_info"])
      setData_car({
        ...data_car,
        car_license_number: null,
        car_lpn_mode: true,
      })
    } else {
      setNo_lpn_car(false)
      setData_car({
        ...data_car,
        car_lpn_mode: false,
      })
    }
  }
  function reverse_boolean(boolean) {
    if (boolean === true) {
      return false
    } else {
      return true
    }
  }

  return (
    <div>
      <Form
        form={form}
        name="create_new_car_info"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Row>
              <Col sm={12} md={6} lg={6} xl={8}>
                <p className="text-bold">
                  {t("License no")} <span style={style_req}>*</span>
                </p>
                <Form.Item
                  name="create_new_car_license_info"
                  rules={[
                    {
                      required: reverse_boolean(no_lpn_car),
                      message: t("License no"),
                    },
                  ]}
                >
                  <Input
                    placeholder={t("License no")}
                    disabled={no_lpn_car}
                    onChange={e =>
                      setData_car({
                        ...data_car,
                        car_license_number: e.target.value,
                      })
                    }
                  />
                </Form.Item>
              </Col>
              <Col sm={12} md={6} lg={6} xl={4}>
                <Checkbox
                  checked={no_lpn_car}
                  onChange={onChange_car_no_lpn}
                  className="checkbox_location"
                >
                  {t("No license plate")}
                </Checkbox>
              </Col>
            </Row>
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <p className="text-bold">
              {t("Car name")} <span style={style_req}>*</span>
            </p>
            <Form.Item
              name="create_new_car_name_info"
              rules={[{ required: true, message: t("Car name") }]}
            >
              <Input
                placeholder={t("Car name")}
                onChange={e =>
                  setData_car({
                    ...data_car,
                    car_name: e.target.value,
                  })
                }
              />
            </Form.Item>
          </Col>
          
          <Col sm={12} md={6} lg={6} xl={6}>
            <p className="text-bold">
              {t("Province")} <span style={style_req}>*</span>
            </p>
            <Form.Item
              name="create_new_car_province_info"
              rules={[{ required: true, message: t("Province") }]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder={t("Province")}
                optionFilterProp="children"
                onChange={onChange_Province}
                onSearch={onSearch_Province}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {render_select_province()}
              </Select>
            </Form.Item>
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Color")}
              placeholder={t("Color")}
              name_api="LIST_CAR_COLOR"
              keyState="car_color_id"
              map_name="car_color_name"
              headState={setData_car}
              nameState={data_car}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <p className="text-bold">
              {t("Chassis number")} <span style={style_req}>*</span>
            </p>
            <Form.Item
              name="create_new_car_chassis_number_info"
              rules={[{ required: true, message: t("Chassis number") }]}
            >
              <Input
                placeholder={t("Chassis number")}
                onChange={e =>
                  setData_car({
                    ...data_car,
                    car_chassis_number: e.target.value,
                  })
                }
              />
            </Form.Item>
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Car group")}
              placeholder={t("Car group")}
              name_api="FIND_ALL_CAR_GROUP"
              keyState="car_group_id"
              map_name="car_group_name"
              headState={setData_car}
              nameState={data_car}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Car type")}
              placeholder={t("Car type")}
              name_api="LIST_CAR_BODY_TYPE"
              keyState="car_body_type_id"
              map_name="car_body_type_name"
              headState={setData_car}
              nameState={data_car}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Brand")}
              placeholder={t("Brand")}
              name_api="LIST_CAR_BRAND"
              keyState="car_brand_id"
              map_name="car_brand_name"
              headState={setData_car}
              nameState={data_car}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Model")}
              placeholder={t("Model")}
              name_api="LIST_CAR_MODEL"
              keyState="car_model_id"
              map_name="car_model_name"
              headState={setData_car}
              nameState={data_car}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Year")}
              placeholder={t("Year")}
              name_api="LIST_CAR_YEAR"
              keyState="car_model_year_id"
              map_name="car_model_year_name"
              headState={setData_car}
              nameState={data_car}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <p className="text-bold">
              {t("Car remark")} <span style={style_req}>*</span>
            </p>
            <Form.Item
              name="create_new_car_remark_info"
              rules={[{ required: true, message: t("Car remark") }]}
            >
              <Input
                placeholder={t("Car remark")}
                onChange={e =>
                  setData_car({
                    ...data_car,
                    car_remark: e.target.value,
                  })
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col>
            <Select_customer_car
              data_car={data_car}
              setData_car={setData_car}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Select_branch_car data_car={data_car} setData_car={setData_car} />
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div className="div_create_new_car">
              <Button
                className="btn_cancel_create_new_car"
                onClick={() => cancelForm()}
              >
                {" "}
                {t("Cancel")}{" "}
              </Button>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div className="div_create_new_car">
              <Button
                className="btn_submit_create_new_car"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {" "}
                {t("Create")}{" "}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
