const set_up = [
    {
      code: "xd7461",
      history: [
        {
          id: "1",
          date_setup: "01/02/64",
          name_customer: "บริษัท บุษราคัมภ์ จำกัด",
          license_plate: "กท-0001",
          contact_setup: "คุณบุญชู",
        },
        {
          id: "2",
          date_setup: "01/02/65",
          name_customer: "บริษัท บุษราคัมภ์ จำกัด",
          license_plate: "กท-0002",
          contact_setup: "คุณบุญชู",
        },
        {
          id: "3",
          date_setup: "01/02/66",
          name_customer: "บริษัท บุษราคัมภ์ จำกัด",
          license_plate: "กท-0003",
          contact_setup: "คุณบุญชู",
        },
        {
          id: "4",
          date_setup: "01/02/67",
          name_customer: "บริษัท บุษราคัมภ์ จำกัด",
          license_plate: "กท-0004",
          contact_setup: "คุณบุญชู",
        },
      ],
    },
    {
      code: "dd4648",
      history: [
          {
            id: "1",
            date_setup: "02/04/60",
            name_customer: "บริษัท บุษราคัมภ์ จำกัด",
            license_plate: "อง-0001",
            contact_setup: "คุณภัทรพล",
          },
          {
            id: "2",
            date_setup: "01/02/65",
            name_customer: "บริษัท บุษราคัมภ์ จำกัด",
            license_plate: "อง-0002",
            contact_setup: "คุณภัทรพล",
          },
          {
            id: "3",
            date_setup: "01/02/66",
            name_customer: "บริษัท บุษราคัมภ์ จำกัด",
            license_plate: "อง-0003",
            contact_setup: "คุณภัทรพล",
          },
          {
            id: "4",
            date_setup: "14/02/67",
            name_customer: "บริษัท บุษราคัมภ์ จำกัด",
            license_plate: "อง-0004",
            contact_setup: "คุณภัทรพล",
          },
        ],
    },
  ]
  
  export default set_up
  