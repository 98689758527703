import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { useTranslation } from "react-i18next"
import service_this_year from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/service_this_year.png"

export default function Month_This_Year({
  text_header,
  image_header,
  labels_status,
  data_status,
  text_bar,
}) {
  const { t } = useTranslation()
  const [series, setSeries] = useState([])

  useEffect(() => {
    setSeries([
      {
        name: text_bar,
        data: data_status,
      },
    ])
    return () => {}
  }, [data_status, text_bar])

  const options = {
    chart: {
      stacked: !0,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "15%",
      },
    },
    dataLabels: {
      enabled: !1,
    },
    xaxis: {
      show: true,
      categories: labels_status,
      labels: {
        show: true,
      },
    },
    yaxis: [
      {
        labels: {
          formatter: function (val) {
            return val.toFixed(0)
          },
        },
      },
    ],
    colors: ["#556ee6"],
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
  }

  return (
    <div>
      <p style={{ fontSize: "20px", marginBottom: "15px", color: "#495057" }}>
        <img
          src={image_header}
          alt="contract"
          style={{
            width: "25px",
            height: "25px",
            marginRight: "20px",
            marginTop: "-10px",
          }}
        />{" "}
        {text_header}
      </p>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height="300"
        className="apex-charts"
      />
    </div>
  )
}
