import React from "react"
import { Redirect } from "react-router-dom"

import Dashboard from '../pages/Operator_All/Dashboard'
import Edit_Service_Operator from '../pages/Operator_All/Edit_Service_Operator'
import Service_Operator from '../pages/Operator_All/Service_Operator'

const operator_Routes = [
  // ใช้จริง

  { path: "/dashboard", exact: true, component: Dashboard },
  { path: "/service/:ser_id", exact: true, component: Service_Operator },
  // { path: "/service/:ser_id", exact: true, component: Edit_Service_Operator },

  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

export { operator_Routes }