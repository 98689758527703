import React from "react"
import { Container } from "reactstrap"
import Table_data_assets from './mockup_demo/Table_data_assets'
import MetaTags from 'react-meta-tags';

const Assets = props => {

  return (
    <React.Fragment>
            <MetaTags>
          <title> Assets | Distar</title>
        </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3>Assets</h3>
          <br />
          <Table_data_assets />
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Assets;