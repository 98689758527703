import React,{ useEffect, useState } from 'react'
import * as api from '../../../../service/Api_Employee'
import { FaRegUser } from "react-icons/fa"
import axios from 'axios'
import { AiOutlineUser } from "react-icons/ai"
import { Avatar } from "antd"

export default function Render_Image_Data(props) {
    const [img, setImg] = useState(null)
    const token =localStorage.getItem('token')

    useEffect(() => {
        if(props.cus_avatar !== null && props.cus_avatar !== undefined && props.cus_avatar !== 'None'){
            get_image(props.cus_avatar)
        }
        return () => {
            setImg(null)
        }
    }, [props.cus_avatar])

    async function get_image(cus_avatar) {
        const get_data_image = api.GET_IMAGE(token, cus_avatar)
        await axios(get_data_image)
        .then( res => {
            const blob = new Blob([res.data], {
                type: 'image/jpeg',
            });
            var objectURL = URL.createObjectURL(blob)
            setImg(objectURL)
        })
        .catch( error => {
            // console.log(error.response);
        })
      }

    return (
        <div>
        {
            img === null && (
                <Avatar
                size={props.size ? props.size : 64}
                icon={<AiOutlineUser style={{ color: 'rgb(0, 132, 209)',marginBottom:'10px' }} />}
                style={{ position: "absolute", top: "5px", left: "20px", backgroundColor:'#F7F7F7' }}
              />
            )
        }
        {
            img !== null && (
                <Avatar    
                size={props.size ? props.size : 64}
                src={img} 
                style={{ position: "absolute", top: "5px", left: "20px" }}
                />
            )
        }
        </div>
    )
}
