import React, { useState, useEffect } from "react"
import { Form, Table, Pagination, Button, Input } from "antd"
import * as api from "../../../service/API_Edit_Car"
import axios from "axios"
import { Row, Col } from "reactstrap"
import { EditOutlined } from "@ant-design/icons"
import Customer_Search from "./Customer_Search"
import { FcDataSheet } from "react-icons/fc"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import helpers from "../../Function_Redirect/Redirect_login"

export default function Customer_Comp(props) {
  const token = localStorage.getItem("token")
  const history = useHistory()
  const { t } = useTranslation()
  const [search_data_customer, setSearch_data_customer] = useState(null)
  const [data_list, setData_list] = useState([])
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    search_data()
    return () => {
      setData_list([])
      setSearch_data_customer(null)
    }
  }, [])

  function search_data() {
    setLoading(true)
    if (search_data_customer) {
      const list_data_customer = api.LIST_DATA(
        token,
        1,
        page_limit.page_Size,
        search_data_customer
      )
      axios(list_data_customer)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          // console.log(res.data.data)
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else {
      const list_no_data_customer = api.LIST_NO_DATA(
        token,
        1,
        page_limit.page_Size
      )
      axios(list_no_data_customer)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          // console.log(res.data.data)
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  function changePagination(page, pageSize) {
    setLoading(true)
    if (search_data_customer) {
      const list_data_customer = api.LIST_DATA(
        token,
        page,
        pageSize,
        search_data_customer
      )
      axios(list_data_customer)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          // console.log(res.data.data)
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else {
      const list_data_customer = api.LIST_NO_DATA(token, page, pageSize)
      axios(list_data_customer)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          // console.log(res.data.data)
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  const columns = [
    {
      title: t("Customer code"),
      dataIndex: "cus_code",
      key: "cus_code",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Customer name"),
      dataIndex: "cus_name",
      key: "cus_name",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Customer dealer"),
      dataIndex: "dea_name",
      key: "dea_name",
      render: text => <p>{text}</p>,
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      props.setCustomer_Info({
        ...props.customer_Info,
        cus_id: selectedRows[0].cus_id,
        cus_name: selectedRows[0].cus_name,
      })
      props.setData_edit({
        ...props.data_edit,
        cus_id: selectedRows[0].cus_id,
      })
    },
    getCheckboxProps: record => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  }

  function edit_customer() {
    if (props.disabled === false) {
      setSearch_data_customer(null)
      props.setCustomer_Info({
        ...props.customer_Info,
        cus_id: null,
        cus_name: null,
        cus_branch_id: null,
        cus_branch_name: null,
      })
      props.setData_edit({
        ...props.data_edit,
        cus_id: null,
        cus_name: null,
        cus_branch_id: null,
        cus_branch_name: null,
      })
    }
  }

  return (
    <div>
      {props.customer_Info.cus_id === null && (
        <Form.Item
          // name="table_select_branch"
          rules={[
            {
              required: false,
              message: "Please select branch",
            },
          ]}
        >
          <div>
            <p className="text-bold "> {t("Select customer")} </p>
            <Customer_Search
              search_data={search_data}
              setSearch_data_customer={setSearch_data_customer}
            />
            <br />

            <Table
              rowSelection={{
                type: "radio",
                ...rowSelection,
              }}
              loading={loading}
              rowKey="cus_id"
              pagination={false}
              columns={columns}
              dataSource={data_list}
              scroll={{ x: 800 }}
            />

            <div style={{ float: "right", marginTop: "10px" }}>
              <Pagination
                current={page_limit.page}
                total={page_limit.limit}
                onChange={changePagination}
              />
            </div>
            <p style={{ fontSize: "15px", marginTop: "10px" }}>
              <FcDataSheet size={20} style={{ marginBottom: "5px" }} />
              {t("total data")} : {page_limit.limit}
            </p>
          </div>
        </Form.Item>
      )}

      {props.customer_Info.cus_id !== null && (
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div style={{ display: "flex" }}>
              <p className="text-bold " style={{ marginTop: "7px" }}>
                {t("Customer")}
              </p>
              <Input
                style={{ marginLeft: "27px", height: "35px" }}
                value={props.customer_Info.cus_name}
                placeholder={props.customer_Info.cus_name}
                disabled
              />
              <a>
                <EditOutlined
                  style={{ fontSize: "20px", marginLeft: "10px" }}
                  onClick={() => edit_customer()}
                />
              </a>
            </div>
          </Col>
        </Row>
      )}
    </div>
  )
}
