import React, { useEffect } from "react"
import { Container } from "reactstrap"
import { useLocation, useHistory, withRouter } from "react-router-dom"
import { Card, CardBody } from "reactstrap"
import { Tabs } from "antd"
import Detail_header from "./detail/Detail_header"
import Detail_setup from './detail/Detail_setup'
import Detail_claim from './detail/Detail_claim'
import Detail_ma from './detail/Detail_ma'
import MetaTags from 'react-meta-tags';

const { TabPane } = Tabs

const Asset_Detail = props => {
  let location = useLocation()
  let history = useHistory()
  const data_assets = location.detail_assets

  if (!data_assets) {
    history.push("/assets")
  }

  useEffect(() => {
    render_detail()
    return () => {}
  }, [data_assets])

  function render_detail() {
    if (data_assets) {
      return (
        <div>
             <MetaTags>
          <title> Asset Infomation | Distar</title>
        </MetaTags>
          <Card>
            <CardBody>
              <p
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  color: "#495057",
                  textAlign: "center",
                }}
              >
                Asset Infomation
              </p>
              <Detail_header detail={data_assets} />
              <hr />
              <Tabs defaultActiveKey="1">
                <TabPane tab="Setup record" key="1">
                  <Detail_setup detail={data_assets} />
                </TabPane>
                <TabPane tab="Claim record" key="2">
                <Detail_claim detail={data_assets} />
                </TabPane>
                <TabPane tab="Maintenance record" key="3">
                  <Detail_ma detail={data_assets} />
                </TabPane>
              </Tabs>
            </CardBody>
          </Card>
        </div>
      )
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {render_detail()}
        </Container>
      </div>
    </React.Fragment>
  )
}
export default withRouter(Asset_Detail)
