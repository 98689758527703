import React,{ useState, useEffect } from 'react'
import { Card, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const { Meta } = Card;

export default function Manager(props) {
    const [manager, setManager] = useState([])

    useEffect(() => {
        if(props.service_overview.manager){
            // console.log(props.service_overview.manager)
            setManager(props.service_overview.manager)
        }
        return () => {
            // setManager([])
        }
    }, [props.service_overview])

    useEffect(() => {
        if(manager){
            // console.log(manager);
            render_manager()
        }
        
        return () => {
            
        }
    }, [manager])

    function render_manager() {
        return manager.map( (data, index) => {
            return(
                <Card style={{ width: '100%', marginTop: 16 }} key={index} >
                <Meta
                  avatar={
                    <Avatar size={54} icon={<UserOutlined style={{ marginBottom:'13px' }} />} />
                  }
                  title={`${data.emp_firstname}   ${data.emp_lastname}`}
                  description={data.emp_code}
                />
              </Card>
            )
        })
    }

    return (
        <div style={{ padding:'10px', height:'250px', overflowY: "scroll", overflowX: "hidden", boxShadow:'rgb(99 99 99 / 20%) 0px 2px 8px 0px', padding:'10px', margin:'10px' }}>
        <p style={{ fontSize: "20px", marginBottom: "15px", color: "#495057" }}>
        Manager
      </p>

      {
        render_manager()
    }

        </div>
    )
}
