import React, { useState, useEffect } from "react"
import { Container, Card, CardBody } from "reactstrap"
import MetaTags from "react-meta-tags"
import Search_car from "./Search_car"
import Data_Table_Car from "./Data_Table_Car"
import { Pagination } from "antd"
import axios from "axios"
import * as api from "../../service/Api_Car"
import { FcDataSheet } from "react-icons/fc"
import no_permi from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png"
import { useHistory } from "react-router-dom"
import helpers from "../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const Car = props => {
  const { t } = useTranslation()
  const [search_car, setSearch_car] = useState(null)
  const token = localStorage.getItem("token")
  const history = useHistory()
  const [data_list, setData_list] = useState([])
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })
  const [loading, setLoading] = useState(false)
  const permi = localStorage.getItem("permi")
  const [permi_t_read, setPermi_t_read] = useState(false)
  const [search_car_info, setSearch_car_info] = useState({
    customer_id: null,
    province_id: null,
    car_body_type_id: null,
    car_brand_id: null,
  })
  const { permissionAllService } = useSelector(({ Distar }) => Distar)

  useEffect(() => {
    if (permissionAllService) {
      if (permissionAllService.car) {
        setPermi_t_read(permissionAllService.car.read)
      }
    }
    return () => {}
  }, [permissionAllService])

  useEffect(() => {
    search_data()
    return () => {}
  }, [search_car_info])

  useEffect(() => {
    search_data()
    return () => {
      setData_list([])
    }
  }, [])

  const check_data = (params, data) => {
    if (data) {
      return params + data
    } else {
      return ""
    }
  }

  function search_data() {
    setLoading(true)
    let url = `api/v1/car?page=1&limit=${
      page_limit.page_Size
    }&order_by=DESC${check_data("&q=", search_car)}${check_data(
      "&customer_id=",
      search_car_info.customer_id
    )}${check_data("&province_id=", search_car_info.province_id)}${check_data(
      "&car_brand_id=",
      search_car_info.car_brand_id
    )}${check_data("&car_body_type_id=", search_car_info.car_body_type_id)}`
    const search = api.SEARCH_ALL(token, url)
    axios(search)
      .then(res => {
        setLoading(false)
        setPage_limit({
          ...page_limit,
          page: 1,
          limit: res.data.total_data,
        })
        setData_list(res.data.data)
      })
      .catch(error => {
        setLoading(false)
        if(error){
          if(error.response){
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  function changePagination(page, pageSize) {
    setLoading(true)
    let url = `api/v1/car?page=${page}&limit=${pageSize}&order_by=DESC${check_data(
      "&q=",
      search_car
    )}${check_data("&customer_id=", search_car_info.customer_id)}${check_data(
      "&province_id=",
      search_car_info.province_id
    )}${check_data("&car_body_type_id=", search_car_info.car_body_type_id)}`
    const search = api.SEARCH_ALL(token, url)
    axios(search)
      .then(res => {
        setLoading(false)
        setPage_limit({
          ...page_limit,
          page: page,
          page_Size: pageSize,
          limit: res.data.total_data,
        })
        setData_list(res.data.data)
      })
      .catch(error => {
        setLoading(false)
        if(error){
          if(error.response){
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Cars")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>{t("Cars")}</h3>

          {permi_t_read === true && (
            <Card>
              <CardBody>
                <Search_car
                  search_car_info={search_car_info}
                  setSearch_car_info={setSearch_car_info}
                  setSearch_car={setSearch_car}
                  search_car={search_car}
                  search_data={search_data}
                />

                <Data_Table_Car data_list={data_list} loading={loading} />

                <div>
                  <Pagination
                    current={page_limit.page}
                    total={page_limit.limit}
                    onChange={changePagination}
                    style={{ marginTop: "20px", float: "right" }}
                  />
                </div>
                <p style={{ fontSize: "15px", marginTop: "20px" }}>
                  {" "}
                  <FcDataSheet size={20} style={{ marginBottom: "5px" }} />{" "}
                  {t("total data")} : {page_limit.limit}
                </p>
              </CardBody>
            </Card>
          )}
          {permi_t_read === false && (
            <div>
              <img
                src={no_permi}
                alt="no permission"
                style={{ display: "block", margin: "50px auto" }}
              />
              <p style={{ textAlign: "center", fontSize: "20px" }}>
                {t("no permission read module")}
              </p>
              <p style={{ textAlign: "center", fontSize: "20px" }}>
                {" "}
                {t("please contract administrator")}{" "}
              </p>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Car
