import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { Form } from "antd"
import axios from "axios"
import * as api from "../../../../service/Api_Create_new_dealer"
import Input_Comp from "./Components/Input_Comp"
import Input_Number from "./Components/Input_Number"
import Select_no_search from "./Components/Select_no_search"
import Select_Postcode from "./Components/Select_Postcode"
import { useTranslation } from "react-i18next";
import Input_Show_Only from './Components/Input_Show_Only'

export default function Form_Create_New_Dealer(props) {
  const [form] = Form.useForm()
  const token = localStorage.getItem("token")
  const { t } = useTranslation();
  const [error_tax_id, setError_tax_id] = useState(null)
  const [data_dealer, setData_dealer] = useState({})
  const [location_select, setLocation_select] = useState({})
  const [postcode_select, setPostcode_select] = useState([])

  // console.log(props.detail_dealer_distar);
  useEffect(() => {
    
    return () => {
      setError_tax_id(null)
    }
  }, [])

  useEffect(() => {
if(props.detail_dealer_distar !== undefined){
  if(props.select_radio === "DEFAULT") {
    form.setFieldsValue({
      dea_address: props.detail_dealer_distar.addr,
      dea_code: props.detail_dealer_distar.gid ,
      dea_email: props.detail_dealer_distar.email,
      dea_name: props.detail_dealer_distar.nickname ,
      dea_phone: props.detail_dealer_distar.phone,
    });
    props.setNew_dealer({
      dea_address: props.detail_dealer_distar.addr,
      dea_code: props.detail_dealer_distar.gid ,
      dea_contact_email: null,
      dea_contact_firstname: null,
      dea_contact_lastname: null,
      dea_contact_mobile: null,
      dea_credit: null,
      dea_email: props.detail_dealer_distar.email,
      dea_fax: null,
      dea_name: props.detail_dealer_distar.nickname ,
      dea_phone: props.detail_dealer_distar.phone,
      dea_pos_id: null,
      dea_taxid: null,
      zip_id: null
    })
  } else {
    
    props.setNew_dealer({
      dea_address: null,
      dea_contact_email: null,
      dea_contact_firstname: null,
      dea_contact_lastname: null,
      dea_contact_mobile: null,
      dea_credit: null,
      dea_email: null,
      dea_fax: null,
      dea_name: null,
      dea_phone: null,
      dea_pos_id: null,
      dea_taxid: null,
      zip_id: null
    })
  }
} else {
  props.setNew_dealer({
    dea_address: null,
    dea_contact_email: null,
    dea_contact_firstname: null,
    dea_contact_lastname: null,
    dea_contact_mobile: null,
    dea_credit: null,
    dea_email: null,
    dea_fax: null,
    dea_name: null,
    dea_phone: null,
    dea_pos_id: null,
    dea_taxid: null,
    zip_id: null
  })
}
      
      return () => {
        form.resetFields()
        setPostcode_select([])
        setLocation_select({})
      }
  }, [props.select_radio])

  function check_dealer_code(select_radio) {
    if(select_radio === "DEFAULT"){
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    function check_dealer_duplicate() {
      if (props.new_dealer.dea_taxid) {
        const check_duplicate = api.CHECK_DEALER_TAX_ID_DUPLICATE(
          token,
          props.new_dealer.dea_taxid
        )
        axios(check_duplicate)
          .then(res => {
            if (res.data === "True") {
              setError_tax_id(t("TAX ID is duplicate"))
            } else {
              setError_tax_id(null)
            }
          })
          .catch(error => {
            // console.log(error)
          })
      }
    }
    check_dealer_duplicate()
    return () => {}
  }, [props.new_dealer.dea_taxid])

    return (
        <div>
        <Form
        name="create_new_dealer"
        initialValues={{
          remember: true,
        }}
        form={form}
      >
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Dealer TAX ID")}
              placeholder={t("Dealer TAX ID")}
              headState={props.setNew_dealer}
              nameState={props.new_dealer}
              type="text"
              keyState="dea_taxid"
              disabled={false}
            />
            {error_tax_id && (
              <p style={{ color: "red", fontSize: "15px" }}>
                {error_tax_id}
              </p>
            )}
          </Col>
         
          {
            props.select_radio === "DEFAULT" && (
              <Col sm={12} md={6} lg={6} xl={6}>
              <Input_Show_Only
              header={t("Dealer code")}
              placeholder={t("Dealer code")}
              headState={props.setNew_dealer}
              nameState={props.new_dealer}
              type="text"
              keyState="dea_code"
              disabled={true}
            />
            </Col>
            )
          }
        

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Dealer name")}
              placeholder={t("Dealer name")}
              headState={props.setNew_dealer}
              nameState={props.new_dealer}
              type="text"
              keyState="dea_name"
              disabled={false}
            />
          </Col>
          <Col sm={12} md={2} lg={2} xl={2}>
            <Input_Number
              header={t("Dealer credit")}
              placeholder={t("Dealer credit")}
              headState={props.setNew_dealer}
              nameState={props.new_dealer}
              keyState="dea_credit"
            />
          </Col>
          <Col sm={0} md={4} lg={4} xl={4}></Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Dealer phone")}
              placeholder={t("Dealer phone")}
              headState={props.setNew_dealer}
              nameState={props.new_dealer}
              type="number"
              keyState="dea_phone"
              disabled={false}
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Dealer email")}
              placeholder={t("Dealer email")}
              headState={props.setNew_dealer}
              nameState={props.new_dealer}
              type="email"
              keyState="dea_email"
              disabled={false}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Dealer fax")}
              placeholder={t("Dealer fax")}
              headState={props.setNew_dealer}
              nameState={props.new_dealer}
              type="text"
              keyState="dea_fax"
              disabled={false}
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Dealer position")}
              placeholder={t("Dealer position")}
              name_api="LIST_POSITION"
              map_name="pos_name"
              map_value="pos_id"
              keyState="dea_pos_id"
              headState={props.setNew_dealer}
              nameState={props.new_dealer}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Contact firstname")}
              placeholder={t("Contact firstname")}
              headState={props.setNew_dealer}
              nameState={props.new_dealer}
              type="text"
              keyState="dea_contact_firstname"
              disabled={false}
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Contact lastname")}
              placeholder={t("Contact lastname")}
              headState={props.setNew_dealer}
              nameState={props.new_dealer}
              type="text"
              keyState="dea_contact_lastname"
              disabled={false}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Contact email")}
              placeholder={t("Contact email")}
              headState={props.setNew_dealer}
              nameState={props.new_dealer}
              type="email"
              keyState="dea_contact_email"
              disabled={false}
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Contact mobile")}
              placeholder={t("Contact mobile")}
              headState={props.setNew_dealer}
              nameState={props.new_dealer}
              type="number"
              keyState="dea_contact_mobile"
              disabled={false}
            />
          </Col>
          </Row>

          <Row>
          <Col>
            <Select_Postcode
              setData_dealer={props.setNew_dealer}
              data_dealer={props.new_dealer}
              location_select={location_select}
              setLocation_select={setLocation_select}
              postcode_select={postcode_select}
              setPostcode_select={setPostcode_select}
            />
          </Col>
        </Row>

      </Form>
        </div>
    )
}
