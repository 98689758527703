import React, { useState, useEffect } from "react"
import { Row, Col, Container, Card, CardBody } from "reactstrap"
import MetaTags from "react-meta-tags"
import { useHistory } from "react-router-dom"
import { Table, Modal, Pagination, Tag, Tooltip } from "antd"
import axios from "axios"
import * as api from "../../../service/Api_Service"
import { AiOutlineEye } from "react-icons/ai"
import { FiEdit2 } from "react-icons/fi"
import Search_Service from "./Search_Service"
import Detail_Service from "./Detail_Service"
import { FcSearch, FcSettings, FcDataSheet } from "react-icons/fc"
import helpers from "../../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux"

const Service = props => {
  const { t } = useTranslation();
  const token = localStorage.getItem("token")
  const cus_id = localStorage.getItem("cus_id")
  const history = useHistory()
  const [search_service, setSearch_service] = useState(null)
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })
  const [data_list, setData_list] = useState([])
  const [visible, setVisible] = useState(false)
  const [data_Service_modal, setData_Service_modal] = useState({})
  const [loading, setLoading] = useState(false)
  const { customer_user_id } = useSelector(({ Distar }) => Distar)

  useEffect(() => {
    search_data()
    return () => {
      setData_list([])
      setData_Service_modal({})
    }
  }, [customer_user_id])

  function search_data() {
    setLoading(true)
    if (search_service) {
      const list_data_service = api.LIST_ALL_SERVICE_DATA_CUSTOMER(
        token,
        1,
        page_limit.page_Size,
        search_service,
        customer_user_id
      )
      axios(list_data_service)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          // console.log(res.data.data)
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else {
      const list_no_data_service = api.LIST_ALL_SERVICE_NO_DATA_CUSTOMER(
        token,
        1,
        page_limit.page_Size,
        customer_user_id
      )
      axios(list_no_data_service)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  function changePagination(page, pageSize) {
    setLoading(true)
    if (search_service) {
      const list_data_service = api.LIST_ALL_SERVICE_DATA_CUSTOMER(
        token,
        page,
        pageSize,
        search_service,
        customer_user_id
      )
      axios(list_data_service)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          // console.log(res.data.data)
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else {
      const list_data_service = api.LIST_ALL_SERVICE_NO_DATA_CUSTOMER(
        token,
        page,
        pageSize,
        customer_user_id
      )
      axios(list_data_service)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          // console.log(res.data.data)
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  const columns = [
    {
      title: t("Service number"),
      dataIndex: "ser_no",
      key: "ser_no",
      render: text => checkData(text),
    },
    {
      title: t("Create on"),
      dataIndex: "created_on",
      key: "created_on",
      render: text => cutTimeZone(text),
    },
    // {
    //   title: t("Priority"),
    //   dataIndex: "pri_name",
    //   key: "pri_name",
    //   render: text => render_priority(text),
    // },
    {
      title: t("Customer name"),
      dataIndex: "cus_name",
      key: "cus_name",
      render: text => checkData(text),
    },
    {
      title: t("Car license number"),
      dataIndex: "car_license_number",
      key: "car_license_number",
      render: text => checkData(text),
    },
    {
      title: t("Service status"),
      dataIndex: "ser_status_name",
      key: "ser_status_name",
      render: text => render_status(text),
    },
    {
      title: "",
      render: text => (
        <Tooltip title={t("View service")} >
          <a>
            <FcSearch
              onClick={() => {
                setVisible(true)
                setData_Service_modal(text)
              }}
              size={20}
            />
          </a>
        </Tooltip>
      ),
    },
  ]

  function render_status(status) {
    switch (status) {
      case "cancel":
        return <Tag color="error"> {t(status)} </Tag>
        break
        case "reject":
          return <Tag color="error">{t(status)}</Tag>
          break
      case "overdue":
        return <Tag color="error">{t(status)}</Tag>
        break
      case "resolved":
        return <Tag color="success">{t(status)}</Tag>
        break
      case "complete":
        return <Tag color="success">{t(status)}</Tag>
        break
      case "inprogress":
        return <Tag color="processing">{t(status)}</Tag>
        break
      case "unassigned":
        return <Tag color="default">{t(status)}</Tag>
        break
      case "pending":
        return (
          <Tag
            style={{
              color: "rgb(234, 159, 230)",
              borderColor: "rgb(234, 159, 230)",
              backgroundColor: "#FFF",
            }}
          >
          {t(status)}
          </Tag>
        )
        break
      case "assigned":
        return (
          <Tag
            style={{
              color: "#DCC200",
              borderColor: "#DCC200",
              backgroundColor: "#FFF",
            }}
          >
          {t(status)}
          </Tag>
        )
        break
      case "waiting for admin approve":
        return (
          <Tag
            style={{
              color: "#0071FF",
              borderColor: "#0071FF",
              backgroundColor: "#DFEDFF",
            }}
          >
          {t(status)}
          </Tag>
        )
        break
      case "waiting for manager approve":
        return (
          <Tag
            style={{
              color: "#0071FF",
              borderColor: "#0071FF",
              backgroundColor: "#DFEDFF",
            }}
          >
          {t(status)}
          </Tag>
        )
        break
      default:
        return <Tag color="default">{t(status)}</Tag>
        break
    }
  }

  function render_priority(priority) {
    switch (priority) {
      case "urgent":
        return <Tag color="error"> {t(priority)} </Tag>
        break
      case "high":
        return <Tag color="warning">{t(priority)}</Tag>
        break
      case "medium":
        return <Tag color="processing">{t(priority)}</Tag>
        break
      case "low":
        return <Tag color="success">{t(priority)}</Tag>
        break
      default:
        return null
    }
  }

  function checkData(data) {
    if (data) {
      return data
    } else {
      return "N/A"
    }
  }

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return "N/A"
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>  {t("Service")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}> {t("Service")} </h3>
          <Card>
            <CardBody>
              <Search_Service
                setSearch_service={setSearch_service}
                search_data={search_data}
              />

              <Table
                loading={loading}
                columns={columns}
                dataSource={data_list}
                pagination={false}
                rowKey="ser_id"
                scroll={{ x: 800 }}
                // onRow={(i) => ({
                //     onClick: () =>
                //        console.log(i)
                // })}
              />

              <div>
                <Pagination
                  current={page_limit.page}
                  total={page_limit.limit}
                  onChange={changePagination}
                  style={{ marginTop: "20px", float: "right" }}
                />
              </div>
              <p style={{ fontSize: "15px", marginTop: "20px" }}>
                {" "}
                <FcDataSheet size={20} style={{ marginBottom: "5px" }} /> {t("total data")} : {page_limit.limit}
              </p>

              <Modal
                title={false}
                centered
                open={visible}
                onOk={() => setVisible(false)}
                onCancel={() => {
                  setVisible(false)
                  setData_Service_modal({})
                }}
                footer={false}
                height={500}
                width={1000}
              >
                <Detail_Service data_Service_modal={data_Service_modal} />
              </Modal>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Service
