import React, { useState, useEffect } from "react"
import { Container, Card, CardBody, Row, Col } from "reactstrap"
import MetaTags from "react-meta-tags"
import { useHistory, useParams } from "react-router-dom"
import { MdKeyboardBackspace } from "react-icons/md"
import { Button, Spin } from "antd"
import axios from "axios"
import * as api from "../../service/Api_Select_Car_From_DB_Distar"
import Step_Form_Import_car from "./Step_Form_Import_car"
import Overview_Confirm_Create from "./Overview_Confirm_Create"
import Error_No_data from "./Error_No_data"
import helpers from '../Function_Redirect/Redirect_login'
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux"

import './Select_Style.css'

const Select_Car_From_DB_Distar = props => {
  const history = useHistory()
  const { t } = useTranslation();
  const token = localStorage.getItem("token")
  const [customer_data, setCustomer_data] = useState({})
  const [branch, setBranch] = useState({})
  const [new_dealer, setNew_dealer] = useState({})
  const [select_branch_final, setSelect_branch_final] = useState({})
  const [new_Contract, setNew_Contract] = useState({})
  const [user, setUser] = useState([])
  const [list_device_all, setList_device_all] = useState([])
  const [user_in_db_distar, setUser_in_db_distar] = useState([])
  const [my_customer, setMy_customer] = useState({})
  const [show_undefind_car, setShow_undefind_car] = useState(false)
  const [confirm_car, setConfirm_car] = useState(false)
  const [found_my_customer, setFound_my_customer] = useState(false)
  const [found_my_dealer, setFound_my_dealer] = useState(false)
  const [distar_customer, setDistar_customer] = useState(null)
  const [car_infomation, setCar_infomation] = useState(null)
  const [car_detail, setCar_detail] = useState(null)
  const [lpn_car, setLpn_car] = useState(null)
  const [gps_car, setGps_car] = useState(null)
  const [type_car, setType_car] = useState(null)
  const [location_car, setLocation_car] = useState({
    lat: 13.2753119,
    lng: 101.4843104,
  })
  const [loading_icon, setLoading_icon] = useState(false)
  const [dealer_info, setDealer_info] = useState({})
  const [detail_dealer_distar, setDetail_dealer_distar] = useState({})
  const [car_already_in_my_db, setCar_already_in_my_db] = useState(false)
  const { permissionAllService } = useSelector(({ Distar }) => Distar)
  const permi = localStorage.getItem('permi')
  const [permi_t, setPermi_t] = useState(false)

  let { gid } = useParams()

useEffect(() => {
  if(permissionAllService){
    if(permissionAllService.import){
      setPermi_t(permissionAllService.import.write)
    }
  }
  return () => {

  }
}, [permissionAllService])
  
  // console.log('gid = '+gid)

  useEffect(() => {
    check_car_in_db_detail()
    list_Device_Grs_Car()
    ckeck_type_car()
    check_user_car()
    check_device_car()
    setBranch({
      cus_branch_address: null,
      cus_branch_code: "00001",
      cus_branch_contact_email: null,
      cus_branch_contact_firstname: null,
      cus_branch_contact_lastname: null,
      cus_branch_contact_mobile: null,
      cus_branch_contact_pos: null,
      cus_branch_email: null,
      cus_branch_fax: null,
      cus_branch_lat: null,
      cus_branch_long: null,
      cus_branch_mobile: null,
      cus_branch_name: 'สำนักงานใหญ่',
      cus_branch_phone: null,
      zip_id: null
    })
    return () => {
      setSelect_branch_final({})
      setMy_customer({})
      setUser_in_db_distar([])
      setConfirm_car(false)
      setFound_my_customer(false)
      setFound_my_dealer(false)
      setDistar_customer(null)
      setCar_detail(null)
      setGps_car(null)
      setCustomer_data({})
      setUser([])
      setBranch({})
      setType_car(null)
      setDealer_info({})
      setDetail_dealer_distar({})
      setLpn_car(null)
      setNew_dealer({})
      setCar_infomation(null)
      setShow_undefind_car(false)
      setLocation_car({
        ...location_car,
        lat: 13.2753119,
        lng: 101.4843104,
      })
    }
  }, [])
  
  function check_device_car() {
    const check_device = api.CHECK_DEVICE_CAR(token, gid)
    axios(check_device)
    .then( res => {
      setList_device_all(res.data.data)
    })
    .catch( error => {
      // console.log(error);
    })
  }

  function check_car_in_db_detail() {
    const car_info = api.CHECK_CAR_IN_DB_DISTAR_INFO(token, gid)
    axios(car_info)
      .then(res => {
        if(res.data.data === 'this car exist in db'){
          setCar_already_in_my_db(true)
        } else {
          setDealer_info(res.data.my_dealer[0])
          setFound_my_dealer(res.data.found_my_dealer)
          setFound_my_customer(res.data.found_my_customer)
          setDistar_customer(res.data.distar_customer)
          setCar_detail(res.data.car_details)
          setLpn_car(res.data.car_details.lpn)
          setMy_customer(res.data.my_customer_details)
          if(res.data.found_my_dealer === false){
            setDetail_dealer_distar(res.data.distar_dealer[0])
          }
        }
      })
      .catch(error => {
        if(error){
          if(error.response){
            if(error.response.status === 401){
              helpers.re_login()
              history.push('/login')          
            }
          }
        }

        // history.push("/import-data/car")
      })
  }

  function list_Device_Grs_Car() {
    setLoading_icon(true)
    const gps_car_info = api.FIND_DEVICE_GPS_LOCATION_CAR(token, gid)
    axios(gps_car_info)
      .then(res => {
        setLoading_icon(false)
        if(res.data){
          if (!res.data.data.device_details) {
          } else {
            setCar_infomation(res.data.data.device_details.car)
            setGps_car(res.data.data.device_details.gps)
            setLocation_car({
              ...location_car,
              lat: parseFloat(res.data.data.device_details.gps.lat),
              lng: parseFloat(res.data.data.device_details.gps.lng),
            })
          }
        } else {
        }
      })
      .catch(error => {
        // console.log(error)
        setLoading_icon(false)
      })
  }

  function check_user_car() {
    const check_user = api.CHECK_USER_IN_CAR_INFO(token,gid)
    axios(check_user)
    .then( res => {
      setUser_in_db_distar(res.data.all_old_users)
    })
    .catch( error => {
      // console.log(error);
    })
  }

  function ckeck_type_car() {
    const type_car = api.CHECK_TYPE_CAR_INFO(token, gid)
    axios(type_car)
    .then( res => {
      setType_car(res.data.car_type[0].imgurl);
    })
    .catch( error => {
      // console.log(error);
    })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Select car")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() =>
                  history.push("/import-data/car")
                }
              />
            </a>{" "}
            {t("Select car")}
          </h3>
          <br />
          <Card>
            <CardBody>
        {/*    <button onClick={() => console.log(customer_data)}>debug customer</button>
            <button onClick={() => console.log(branch)}>debug branch</button>
            <button onClick={() => console.log(user)}>debug user</button>
            <button onClick={() => console.log(new_dealer)}>debug dealer</button>
            <button onClick={() => console.log(dealer_info)}>debug dealer in db</button>
              <button onClick={() => console.log(new_Contract)}>debug contract</button>*/}
            
              {confirm_car === false && (
                <div>
       
                    <div>
                      <Overview_Confirm_Create
                      loading_icon={loading_icon}
                        type_car={type_car}
                        lpn_car={lpn_car}
                        car_detail={car_detail}
                        found_my_dealer={found_my_dealer}
                        car_infomation={car_infomation}
                        gps_car={gps_car}
                        location_car={location_car}
                      />
                      <br />
            
                      {
                        permi_t === true && (
                          <div>
                            {
                              car_already_in_my_db === false && (
                                <Row>
                                <Col sm={12} md={6} lg={6} xl={6}>
                                  <div className="div_create_new_car">
                                    <Button
                                      className="btn_cancel_create_new_car"
                                      onClick={() =>
                                        history.push("/import-data/car")
                                      }
                                    >
                                   
                                      {t("Cancel")}
                                    </Button>
                                  </div>
                                </Col>
                                <Col sm={12} md={6} lg={6} xl={6}>
                                  <div className="div_create_new_car">
                                    <Button
                                      className="btn_submit_create_new_car"
                                      type="primary"
                                      onClick={() => setConfirm_car(true)}
                                    >
                                      {t("Proceed")}
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                              )
                            }
                            {
                              car_already_in_my_db === true && (
                                <p style={{ color:'#4BB543' , fontSize:'20px', textAlign:'center' }}> {t("This car exist in database")} </p>
                              )
                            }

                          </div>
                        )
                      }
                          
             {
              permi_t === false && (
               <div>
                <p style={{ textAlign: "center", fontSize: "20px" }}>{t("no permission write module")}</p>
                <p style={{ textAlign:'center', fontSize:'20px' }}> {t("please contract administrator")} </p>
               </div>
              )
             }
                      
                    </div>
                    {/*<Spin size="large" style={{ display:'block', margin:'auto', marginTop:'50px' }} />*/}
                  {/*show_undefind_car === true && <Error_No_data />*/}
                </div>
              )}
              {confirm_car === true && (
                <Step_Form_Import_car
                  distar_customer={distar_customer}
                  found_my_customer={found_my_customer}
                  my_customer={my_customer}
                  setConfirm_car={setConfirm_car}
                  setCustomer_data={setCustomer_data}
                  customer_data={customer_data}
                  setBranch={setBranch}
                  branch={branch}
                  setUser={setUser}
                  user={user}
                  dealer_info={dealer_info}
                  user_in_db_distar={user_in_db_distar}
                  detail_dealer_distar={detail_dealer_distar}
                  found_my_dealer={found_my_dealer}
                  setNew_dealer={setNew_dealer}
                  new_dealer={new_dealer}
                  setNew_Contract={setNew_Contract}
                  new_Contract={new_Contract}
                  car_detail={car_detail}
                  list_device_all={list_device_all}
                  setSelect_branch_final={setSelect_branch_final}
                  select_branch_final={select_branch_final}
                />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Select_Car_From_DB_Distar
