import React, { useState, useEffect } from "react"
import axios from 'axios'
import { Tabs } from "antd"
import List_User_All from './List_User_All'
import * as api from '../../../service/Api_New_Customer'
import Create_new_user from './Create_new_user'

const { TabPane } = Tabs

export default function Users(props) {
  const token = localStorage.getItem('token')
  const [activeTab, setActiveTab] = useState("1")
  const [search_data_user, setSearch_data_user] = useState(null)
  const [data_User_All, setData_User_All] = useState([])
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })
  const [search_data_branch, setSearch_data_branch] = useState(null)
  const [data_branch_All, setData_branch_All] = useState([])
  const [page_limit_branch, setPage_limit_branch] = useState({
    page_branch: 1,
    limit_branch: 0,
    page_Size_branch: 10,
  })

  useEffect(() => {
    find_data_branch()
    return () => {
      setData_branch_All([])
    }
  }, [props.activeTab_all_Customer])

  useEffect(() => {
    search_data()
    find_data_branch()
    return () => {
      setData_User_All([])
      setData_branch_All([])
    }
  }, [])

  function search_data() {
    if(search_data_user){
      const search_user_data = api.SEARCH_USER_DATA(
        token,
        props.id,
        1, page_limit.page_Size,search_data_user
      )
      axios(search_user_data)
      .then( res => {
        setPage_limit({
          ...page_limit,
          page: 1,
          limit: res.data.total_data,
        })
        setData_User_All(res.data.data);
      })
      .catch( error => {
        console.log(error);
      })
    } else {
      const search_user_nodata = api.SEARCH_NO_USER_DATA(
        token,
        props.id,
        1,
        page_limit.page_Size
      )
      axios(search_user_nodata)
      .then( res => {
        setPage_limit({
          ...page_limit,
          page: 1,
          limit: res.data.total_data,
        })
        setData_User_All(res.data.data)
      })
      .catch( error => {
        console.log(error);
      })
    }
  }

  function changePagination(page, pageSize) {
    setPage_limit({
      ...page_limit,
      page: page,
      page_Size: pageSize,
    })
    if(search_data_user){
      const search_user_data = api.SEARCH_USER_DATA(
        token,
        props.id,
        page, pageSize,search_data_user
      )
      axios(search_user_data)
      .then( res => {
        setPage_limit({
          ...page_limit,
          page: page,
          page_Size: pageSize,
          limit: res.data.total_data,
        })
        setData_User_All(res.data.data);
      })
      .catch( error => {
        console.log(error);
      })
    } else {
      const search_user_nodata = api.SEARCH_NO_USER_DATA(
        token,
        props.id,
        page, pageSize
      )
      axios(search_user_nodata)
      .then( res => {
        setPage_limit({
          ...page_limit,
          page: page,
          page_Size: pageSize,
          limit: res.data.total_data,
        })
        setData_User_All(res.data.data)
      })
      .catch( error => {
        console.log(error);
      })
    }
  }

  function find_data_branch() {
    if(search_data_branch){
      const search_data = api.SEARCH_DATA(
        token,
        props.id,
        1,
        page_limit_branch.page_Size_branch,
        search_data_branch
      )
      axios(search_data)
        .then(res => {
          setPage_limit_branch({
            ...page_limit_branch,
            page_branch: 1,
            limit_branch: res.data.total_data,
          })
          setData_branch_All(res.data.data)
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      const search_branch_nodata = api.SEARCH_NODATA(
        token,
        props.id,
        1,
        page_limit_branch.page_Size_branch
      )
      axios(search_branch_nodata)
      .then( res => {
        setPage_limit_branch({
          ...page_limit_branch,
          page_branch: 1,
          limit_branch: res.data.total_data,
        })
        setData_branch_All(res.data.data)
      })
      .catch( error => {
        console.log(error);
      })
    }
  }

  function changePagination_branch(page, pageSize) {
    setPage_limit_branch({
      ...page_limit_branch,
      page_branch: page,
      page_Size_branch: pageSize,
    })
    if(search_data_branch){
      const search_data = api.SEARCH_DATA(
        token,
        props.id,
        page,
        pageSize,
        search_data_branch
      )
      axios(search_data)
        .then(res => {
          setPage_limit_branch({
            ...page_limit_branch,
            page_branch: page,
            page_Size_branch: pageSize,
            limit_branch: res.data.total_data,
          })
          setData_branch_All(res.data.data)
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      const search_no_data = api.SEARCH_NODATA(
        token,
        props.id,
        page,
        pageSize
      )
      axios(search_no_data)
        .then(res => {
          setPage_limit_branch({
            ...page_limit_branch,
            page_branch: page,
            page_Size_branch: pageSize,
            limit_branch: res.data.total_data,
          })
          setData_branch_All(res.data.data)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

// console.log(props.id);


  return (
    <div>
      <Tabs
        defaultActiveKey="1"
        onTabClick={e => {
            setActiveTab(e)
            // console.log('click tab'+e);
        }}
        activeKey={activeTab}
      >
        <TabPane tab="Users" key="1">
            <List_User_All 
              search_data={search_data}
              setSearch_data_user={setSearch_data_user}
              data_User_All={data_User_All}
              page_limit={page_limit}
              changePagination={changePagination}
            />
        </TabPane>
        <TabPane tab="Create users" key="2">

          <Create_new_user 
            cus_id={props.id}
            data_branch_All={data_branch_All}
            page_limit_branch={page_limit_branch}
            setSearch_data_branch={setSearch_data_branch}
            changePagination_branch={changePagination_branch}
            find_data_branch={find_data_branch}
            search_data={search_data}
            setActiveTab={setActiveTab}
          />

        </TabPane>
      </Tabs>
    </div>
  )
}
