import React, { useState, useEffect } from "react"
import { Select, Form } from "antd"

const { Option } = Select
export default function Select_no_search({
  header,
  keyState,
  headState,
  nameState,
  map_name,
  placeholder,
  map_value,
  data_select_array,
}) {
  const [data_select, setData_select] = useState([])

  useEffect(() => {
    setData_select(data_select_array)
    return () => {
      // setData_select([])
    }
  }, [data_select_array])

  function onChange(value) {
    if (value) {
      headState({ ...nameState, [keyState]: value })
    }
  }

  useEffect(() => {
    render_select_data()
    return () => {}
  }, [data_select])

  function render_select_data() {
    return data_select.map(data => {
      return (
        <Option key={data[map_value]} value={data[map_value]}>
          {data[map_name]}
        </Option>
      )
    })
  }
  return (
    <div>
      <p className="text-bold">
        {header} <span style={{ color: "red" }}>*</span>
      </p>
      <Form.Item
        name={keyState}
        rules={[
          {
            required: true,
            message: `${placeholder}`,
          },
        ]}
      >
        <Select
          showSearch
          placeholder={`${placeholder}`}
          style={{ width: "100%" }}
          optionFilterProp="children"
          onChange={onChange}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {render_select_data()}
        </Select>
      </Form.Item>
    </div>
  )
}
