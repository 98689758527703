import React, { useState, useEffect } from "react"
import { Container, Card, CardBody, Row, Col } from "reactstrap"
import MetaTags from "react-meta-tags"
import * as api from "../../service/Api_Dashboard"
import axios from "axios"
import "./Dashboard_style.css"
import Operator from "./components/Operator"
import Cars_Header from "./components/Cars_Header"
import Contract_count from "./components/Contract_count"
import Service_Status from "./components/Service_Status"
import Month_This_Year from "./components/Month_This_Year"
import Service_Priority from "./components/Service_Priority"
import Online_User from "./Online_User"
import Last_Service_On_This_Month from "./components/Last_Service_On_This_Month"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import helpers from "../Function_Redirect/Redirect_login"

const Dashboard = props => {
  const token = localStorage.getItem("token")
  const role = localStorage.getItem("role")
  const { t } = useTranslation()
  const history = useHistory()
  const [employee, setEmployee] = useState(0)
  const [customer, setCustomer] = useState(0)
  const [operator, setOperator] = useState(0)
  const [car, setCar] = useState(0)
  const [dealer, setDealer] = useState(0)
  const [service, setService] = useState(0)
  const [stock, setStock] = useState(0)
  const [contract, setContract] = useState(0)
  const [contract_expired, setContract_expired] = useState({
    available: 0,
    expired: 0,
  })
  const [service_status, setService_status] = useState({})
  const [month_this_year, setMonth_this_year] = useState({})
  const [service_priority, setService_priority] = useState({})
  const [service_by_geo, setService_by_geo] = useState({})
  const [last_service_on_this_month, setLast_service_on_this_month] = useState(
    []
  )

  const style_card = {
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  }

  useEffect(() => {
    function List_Dashboard() {
      const list_dashboard_info = api.LIST_DASHBOARD(token)
      axios(list_dashboard_info)
        .then(res => {
          setEmployee(res.data.data.count_employee.employee_count)
          setCustomer(res.data.data.count_customer.customer_count)
          setOperator(res.data.data.count_operator.operator_count)
          setCar(res.data.data.count_car.car_count)
          setDealer(res.data.data.count_dealer.dealer_count)
          setService(res.data.data.count_service.service_count)
          setStock(res.data.data.count_stock.stock_count)
          setContract(res.data.data.count_contract.contract_count)
          setContract_expired({
            ...contract_expired,
            available: res.data.data.contract_expired.available,
            expired: res.data.data.contract_expired.expired,
          })
          setService_status(res.data.data.count_service_status)
          setMonth_this_year(res.data.data.count_service_month_this_year)
          setService_priority(res.data.data.count_service_priority)
          setService_by_geo(res.data.data.count_service_by_geo)
          setLast_service_on_this_month(
            res.data.data.last_service_on_this_month
          )
    
          // console.log(res.data.data.last_service_on_this_month)
        })
        .catch(error => {
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
          // console.log(error.response)
        })
    }

    List_Dashboard()

    return () => {
      // console.log("clear dashboard")

      setEmployee(0)
      setCustomer(0)
      setOperator(0)
      setCar(0)
      setDealer(0)
      setService(0)
      setStock(0)
      setContract(0)
      setContract_expired({
        available: 0,
        expired: 0,
      })
      setService_status({})
      setMonth_this_year({})
      setService_priority({})
      setService_by_geo({})
      setLast_service_on_this_month([])
    }
  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("dashboard")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>{t("dashboard")}</h3>

          <Card style={style_card}>
            <CardBody>
              <br />
              <Row>
                <Col sm={12} md={6} lg={6} xl={3}>
                  <Cars_Header
                    header={t("contract")}
                    number={contract}
                    color_border_icon="#ff6961"
                    icon="AiOutlineFileDone"
                  />
                </Col>
                <Col sm={12} md={6} lg={6} xl={3}>
                  <Cars_Header
                    header={t("employee")}
                    number={employee}
                    color_border_icon="#EA9FE6"
                    icon="HiUserGroup"
                  />
                </Col>
                <Col sm={12} md={6} lg={6} xl={3}>
                  <Cars_Header
                    header={t("customer")}
                    number={customer}
                    color_border_icon="#7985E0"
                    icon="BiUserCircle"
                  />
                </Col>
                <Col sm={12} md={6} lg={6} xl={3}>
                  <Cars_Header
                    header={t("operator")}
                    number={operator}
                    color_border_icon="#76CE86"
                    icon="FaUsersCog"
                  />
                </Col>

                <Col sm={12} md={6} lg={6} xl={3}>
                  <Cars_Header
                    header={t("dealer")}
                    number={dealer}
                    color_border_icon="#6891C3"
                    icon="FaRegHandshake"
                  />
                </Col>

                <Col sm={12} md={6} lg={6} xl={3}>
                  <Cars_Header
                    header={t("car")}
                    number={car}
                    color_border_icon="#F29496"
                    icon="AiOutlineCar"
                  />
                </Col>

                <Col sm={12} md={6} lg={6} xl={3}>
                  <Cars_Header
                    header={t("service")}
                    number={service}
                    color_border_icon="#BE94E6"
                    icon="VscTools"
                  />
                </Col>

                <Col sm={12} md={6} lg={6} xl={3}>
                  <Cars_Header
                    header={t("stock")}
                    number={stock}
                    color_border_icon="#EBAE86"
                    icon="FiBox"
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>

          {role === "administrator" && (
            <Row>
              <Col>
                <Card style={style_card}>
                  <CardBody>
                    <Online_User />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          <Row>
            <Col sm={12} md={4} lg={4} xl={4}>
              <Card style={style_card}>
                <CardBody>
                  <Contract_count
                    contract={contract}
                    contract_expired={contract_expired}
                  />
                </CardBody>
              </Card>
            </Col>

            <Col sm={12} md={8} lg={8} xl={8}>
              <Card style={style_card}>
                <CardBody>
                  <Month_This_Year month_this_year={month_this_year} />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <Card style={style_card}>
                <CardBody>
                  <Service_Priority service_priority={service_priority} />
                </CardBody>
              </Card>
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
              <Card style={style_card}>
                <CardBody>
                  <Operator service_by_geo={service_by_geo} />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <Card style={style_card}>
                <CardBody>
                  <Service_Status service_status={service_status} />
                </CardBody>
              </Card>
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
              <Card style={style_card}>
                <CardBody>
                  <Last_Service_On_This_Month
                    last_service_on_this_month={last_service_on_this_month}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Dashboard
