import React from "react"
import { Row, Col } from "reactstrap"
import Select_Data_Filter from "./Select_Data_Filter"
import Select_Model from "./Select_Model"
import Select_Filter_Customer from '../../Cars/select_search/Select_Filter_Customer'
import Select_Filter_Dealer from './Select_Filter_Dealer'
import { useTranslation } from "react-i18next";

export default function Filter_Stock(props) {
  const { t } = useTranslation();

  return (
    <div>
      <Row>
        <Col sm={12} md={3} lg={3} xl={3}>
          <Select_Data_Filter
            header={t("Search car body")}
            placeholder={t("Search car body")}
            name_api="LIST_CAR_BODY_TYPE"
            headState={props.setFilter_stock}
            nameState={props.filter_stock}
            keyState="car_body_type_id"
            map_name="car_body_type_name"
            map_value="car_body_type_id"
          />
        </Col>
        <Col sm={12} md={3} lg={3} xl={3}>
          <Select_Data_Filter
            header={t("Search product group")}
            placeholder={t("Search product group")}
            name_api="LIST_PRODUCT_MODEL"
            headState={props.setFilter_stock}
            nameState={props.filter_stock}
            keyState="pro_group_id"
            map_name="pro_group_name"
            map_value="pro_group_id"
          />
        </Col>
        <Col sm={12} md={3} lg={3} xl={3}>
          <Select_Model
            setFilter_stock={props.setFilter_stock}
            filter_stock={props.filter_stock}
          />
        </Col>

        <Col sm={12} md={3} lg={3} xl={3}>
        <Select_Filter_Customer
            headState={props.setFilter_stock}
            nameState={props.filter_stock}
            keyState="cus_id"
        />
      </Col>

      <Col sm={12} md={3} lg={3} xl={3}>
      <Select_Filter_Dealer
          headState={props.setFilter_stock}
          nameState={props.filter_stock}
          keyState="dea_id"
      />
    </Col>

      </Row>
    </div>
  )
}
