import React, { useState } from "react"
import { Row, Col } from "reactstrap"
import { Button, Input, Form, Modal } from "antd"
import { UserAddOutlined } from "@ant-design/icons"
import { useHistory } from "react-router-dom"
import "./Search_Customer.css"
import { BiUserPlus} from "react-icons/bi"
import { useTranslation } from "react-i18next";

export default function Search(props) {
  const history = useHistory()
  const { t } = useTranslation();

  const search = values => {
    props.search_data()
  }

  return (
    <div>
      <Form
        name="Search_customer"
        initialValues={{
          remember: true,
        }}
        onFinish={search}
      >
        <Row>
          <Col sm={12} md={6} lg={6} xl={4}>
            <Form.Item
              name="search"
              rules={[
                {
                  required: false,
                  message: "Please input name customer" ,
                },
              ]}
            >
              <Input
                placeholder={` ${t("name")} , ${t("tax id")}`}
                style={{ marginBottom: "15px" }}
                onChange={e => props.setSearch_data_user(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={6} lg={4} xl={4}>
            <Button
              type="primary"
              className="button-search-employee"
              htmlType="submit"
              // style={{ 
              //   backgroundColor : '#0084d1', 
              //   borderColor:'#0084d1' 
              // }}
            >
             {t("search")}
            </Button>
          </Col>
        </Row>
      </Form>


    </div>
  )
}
