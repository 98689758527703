var Ip = require('../service/Ip')

export const CHECK_CAR_IN_DB_DISTAR_INFO = (token, gid) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}distar/cars/check-customer-is-import/${gid}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const FIND_DEVICE_GPS_LOCATION_CAR = (token, gid) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}distar/cars/gps/location/${gid}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CHECK_TYPE_CAR_INFO = (token, gid) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}distar/cars/types/${gid}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CHECK_USER_IN_CAR_INFO = (token, gid) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}distar/cars/customer-has-user/${gid}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CHECK_DEVICE_CAR = (token, gid) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}distar/cars/device/${gid}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const IMPORT_CAR = (token, data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}distar/import/car`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const CHECK_EMAIL_USER_DUPLICATE = (token, email_user) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer/check-email-is-duplicate/${email_user}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };