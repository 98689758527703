var Ip = require('../service/Ip')

  export const GET_INFO_USER = (token,id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/employee/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_DEPARTMENT = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/department`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_POSITION = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/position`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_PERMISSION_ROLE = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/employee/role-permission`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };
  
  export const LIST_ROLE_DETAIL = (token,id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/employee/list-role-permission/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const EDIT_DATA = (token,id,data) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/employee/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const REMOVE_EMPLOYEE = (token, id) => {
    return {
      method: 'delete',
      url: `${Ip.getIP()}api/v1/employee/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const GET_IMAGE = (token, image) => {
    return {
      method: 'get',
      responseType: 'arraybuffer',
      url: `${Ip.getIP()}storage/file/${image}`,
      headers: {
        'x-access-token' : token
      }
    };
  };

  export const UPLOAD_PROFILE = (token, formData) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}storage/upload`,
      headers: {
        "Content-type": "multipart/form-data",
        'x-access-token' : token
      },
      data : formData
    };
  };

  export const CHECK_ROLE_PERMISSION = (token, emp_id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/employee/role-permission/${emp_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CREATE_NEW_ROLE_PERMISSION = (token, data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/employee/create-role-permission`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };