import React,{ useEffect, useState } from "react"
import { Row, Col } from "reactstrap"
import { Button, Input, Form } from "antd"
import { useHistory } from "react-router-dom"
import { AiOutlineUserAdd } from 'react-icons/ai';
import Filter_Operator from './Filter_Operator'
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux"

const { Search } = Input;

export default function Search_data_operator_table(props) {
  const { t } = useTranslation();
    const history = useHistory()
    const permi = localStorage.getItem('permi')
    const [permi_t, setPermi_t] = useState(false)
    const { permissionAllService } = useSelector(({ Distar }) => Distar)
  useEffect(() => {
    if(permissionAllService){
      if(permissionAllService.operator){
        setPermi_t(permissionAllService.operator.write)
      }
    }
    return () => {
  
    }
  }, [permissionAllService])

    const onFinish = values => {
      props.search_operator_data()
    }

    return (
        <div>
        <Form
          name="Search_car"
          initialValues={{
            remember: false,
          }}
          onFinish={onFinish}
        >
          <Row>
            <Col sm={12} md={6} lg={6} xl={4}>
              <Form.Item
                name="search"
                rules={[
                  {
                    required: false,
                    message: "Please input name,department",
                  },
                ]}
              >
              <Input
                  placeholder={`${t("Name")} , ${t("code")}`}
                  style={{ marginBottom: "15px" }}
                  onChange={e => props.setSearch_operator(e.target.value)}
              /> 
              </Form.Item>
            </Col>
            <Col sm={12} md={6} lg={4} xl={4}>
              <Button
                type="primary"
                className="button-search-employee"
                htmlType="submit"
              >
                {t("Search")}
              </Button>
              {
                permi_t === true && (
                  <Button
                  className="button-search-New-Employee"
                  onClick={() => history.push("/new-operator")}
                  icon={<AiOutlineUserAdd style={{ fontSize: "15px", marginRight:'10px', marginBottom:'5px' }} />}
                >
                  {t("New operator")}
                </Button>
                )
              }
              {/*
                permi_t === true && (
                  <Button
                  className="button-search-New-Employee"
                  onClick={() => history.push("/new-operator")}
                  icon={<AiOutlineUserAdd style={{ fontSize: "15px", marginRight:'10px', marginBottom:'5px' }} />}
                >
                  New operator
                </Button>
                )
                */}

            </Col>
          </Row>
        </Form>

        <Filter_Operator 
        filter_operator={props.filter_operator}
        setFilter_operator={props.setFilter_operator}
        />
        <br />
      </div>
    )
}
