import React from "react"
import { Row, Col } from "reactstrap"
import { Tooltip, Card, Collapse } from "antd"
import car_info from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/car_info.png"
import { useTranslation } from "react-i18next"
import car from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/car.png"
import ambulance from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/ambulance.png"
import vespa from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/vespa.png"
import bus from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/bus.png"
import bicycle from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/bicycle.png"
import van from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/van.png"
import truck from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/truck.png"
import taxi from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/taxi.png"
import pickup from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/pickup.png"
import motorboat from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/motorboat.png"
import motor from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/motor.png"
import no_car from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_car.png"

const { Panel } = Collapse

export default function Car_Information(props) {
  const { t } = useTranslation()

    const text_header = {
        fontWeight: "bold",
        fontSize: "15px",
      }
    
      const icons_info_header = { 
        width:'25px', 
        marginRight:'20px' 
      }
    
      function cutTimeZone(date_T) {
        if (date_T) {
          var date = new Date(date_T)
          var month = pad2(date.getMonth() + 1)
          var day = pad2(date.getDate())
          var year = date.getFullYear()
    
          var formattedDate = day + "/" + month + "/" + year
          return formattedDate
        } else {
          return "N/A"
        }
      }
      function pad2(n) {
        return (n < 10 ? "0" : "") + n
      }
    
      function checkData(data) {
        if (data) {
          return data
        } else {
          return "N/A"
        }
      }
    
      const data_car_info = [
        {
          header1: t("Car license number"),
          dataContract1: checkData(props.data_all.car_license_number),
          header2: t("Car name"),
          dataContract2: checkData(props.data_all.car_name),
        },
        {
          header1: t("Car code"),
          dataContract1: checkData(props.data_all.car_code),
          header2: t("Car type"),
          dataContract2: render_Icon_Car(props.data_all.car_body_type_name),
        },
      ]
    
      const data_car = [
        {
          header1: t("Car color name"),
          dataContract1: checkData(props.data_all.car_color_name),
          header2: t("Car chassis number"),
          dataContract2: checkData(props.data_all.car_chassis_number),
        },
        {
          header1: t("Car model name"),
          dataContract1: checkData(props.data_all.car_model_name),
          header2: t("Car model year name"),
          dataContract2: checkData(props.data_all.car_model_year_name),
        },
        {
          header1: t("Car brand name"),
          dataContract1: checkData(props.data_all.car_brand_name),
          header2: "Car fuel hours",
          dataContract2: checkData(props.data_all.car_fuelhours),
        },
        {
          header1: "Car fuel tank",
          dataContract1: checkData(props.data_all.car_fueltank),
          header2: "Car fuel km",
          dataContract2: checkData(props.data_all.car_fuelkm),
        },
      ]

      function render_Icon_Car(type_car) {
        switch (type_car) {
          case "car":
            return (
              <Tooltip title="car">
                <img src={car} alt="icon" style={{ width: "50px" }} />
              </Tooltip>
            )
            break
          case "vespa":
            return (
              <Tooltip title="vespa">
                <img src={vespa} alt="icon" style={{ width: "50px" }} />
              </Tooltip>
            )
            break
          case "van":
            return (
              <Tooltip title="van">
                <img src={van} alt="icon" style={{ width: "50px" }} />
              </Tooltip>
            )
            break
          case "truck":
            return (
              <Tooltip title="truck">
                <img src={truck} alt="icon" style={{ width: "50px" }} />
              </Tooltip>
            )
            break
          case "taxi":
            return (
              <Tooltip title="taxi">
                <img src={taxi} alt="icon" style={{ width: "50px" }} />
              </Tooltip>
            )
            break
          case "bus":
            return (
              <Tooltip title="bus">
                <img src={bus} alt="icon" style={{ width: "50px" }} />
              </Tooltip>
            )
            break
          case "pickup":
            return (
              <Tooltip title="pickup">
                <img src={pickup} alt="icon" style={{ width: "50px" }} />
              </Tooltip>
            )
            break
          case "motorboat":
            return (
              <Tooltip title="motorboat">
                <img src={motorboat} alt="icon" style={{ width: "50px" }} />
              </Tooltip>
            )
            break
          case "motor":
            return (
              <Tooltip title="motor">
                <img src={motor} alt="icon" style={{ width: "50px" }} />
              </Tooltip>
            )
            break
          case "bicycle":
            return (
              <Tooltip title="bicycle">
                <img src={bicycle} alt="icon" style={{ width: "50px" }} />
              </Tooltip>
            )
            break
          case "ambulance":
            return (
              <Tooltip title="ambulance">
                <img src={ambulance} alt="icon" style={{ width: "50px" }} />
              </Tooltip>
            )
            break
          default:
            return (
              <Tooltip title="Invalid car type">
                <img src={no_car} alt="icon" style={{ width: "50px" }} />
              </Tooltip>
            )
            break
        }
      }

    return (
        <Card title={ <p> <img src={car_info} alt="car_info" style={icons_info_header} /> {t("Car information")} </p>}>
        {data_car_info.map((data, index) => {
          return (
            <Row key={index}>
              <Col sm={6} md={3} lg={3} xl={3}>
                <p style={text_header}>{data.header1}</p>
              </Col>
              <Col sm={6} md={3} lg={3} xl={3}>
                <p style={{ fontSize: "15px" }}>
                  {" "}
                  <span className="tet">:</span>{" "}
                  <span className="tetTwo">-</span> {data.dataContract1}
                </p>
              </Col>
              <Col sm={6} md={3} lg={3} xl={3}>
                <p style={text_header}>{data.header2}</p>
              </Col>
              <Col sm={6} md={3} lg={3} xl={3}>
                <p style={{ fontSize: "15px" }}>
                  {" "}
                  <span className="tet">:</span>{" "}
                  <span className="tetTwo">-</span> {data.dataContract2}
                </p>
              </Col>
            </Row>
          )
        })}
        <Collapse>
          <Panel header={t("More")} key="1">
            {data_car.map((data, index) => {
              return (
                <Row key={index}>
                  <Col sm={6} md={3} lg={3} xl={3}>
                    <p style={text_header}>{data.header1}</p>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={3}>
                    <p style={{ fontSize: "15px" }}>
                      {" "}
                      <span className="tet">:</span>{" "}
                      <span className="tetTwo">-</span> {data.dataContract1}
                    </p>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={3}>
                    <p style={text_header}>{data.header2}</p>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={3}>
                    <p style={{ fontSize: "15px" }}>
                      {" "}
                      <span className="tet">:</span>{" "}
                      <span className="tetTwo">-</span> {data.dataContract2}
                    </p>
                  </Col>
                </Row>
              )
            })}
          </Panel>
        </Collapse>
      </Card>
    )
}
