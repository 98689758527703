import React, { useState, useEffect, useCallback } from "react"
import { Row, Col } from "reactstrap"
import { Tabs, Button, Modal, notification } from "antd"
import * as api from "../../../../service/service_operator/Api_Edit_Service"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import axios from "axios"
import RenderForm_hast_form from "./renderForm_hast_form"

const { TabPane } = Tabs
const { confirm } = Modal

export default function CreateNewForm(props) {
  const token = localStorage.getItem("token")
  const dataLocationCheckIn = localStorage.getItem(`service_${props.ser_id}`)
  const [priority, setPriority] = useState([])
  const [before_test, setBefore_test] = useState([])
  const [task_hast_form, setTask_hast_form] = useState([])
  const [form_success, setForm_success] = useState([])

  useEffect(() => {
    list_priority()
    check_data_submit()
    Check_service_form()
    return () => {
      setPriority([])
      setBefore_test([])
      setTask_hast_form([])
    }
  }, [])

  function list_priority() {
    const list_priority_info = api.LIST_PRIORITY(token)
    axios(list_priority_info)
      .then(res => {
        setPriority(res.data.data)
      })
      .catch(error => {
        // console.log(error)
      })
  }

  function compareFirst(a, b) {
    if (a.task_index > b.task_index) {
      return 1
    }
    if (a.task_index < b.task_index) {
      return -1
    }
    return 0
  }

  function check_data_submit() {
    const check = api.CHECK_DATA_SUBMIT(token, props.ser_id)
    axios(check)
      .then(res => {
        // console.log(res.data.message);
        setForm_success(res.data.message)
      })
      .catch(error => {
        // console.log(error);
      })
  }

  function Check_service_form() {
    const create_task = api.CHECK_SERVICE_HAST_TASK(token, props.ser_id)
    axios(create_task)
      .then(res => {
        const sortDetailForm = res.data.data.sort(compareFirst)
        setBefore_test(sortDetailForm)
      })
      .catch(error => {
        // console.log(error)
      })
  }

  useEffect(() => {
    mapping_data_array()

    return () => {}
  }, [before_test, form_success])

  function mapping_data_array() {
    const arrayLocal = dataLocationCheckIn ? JSON.parse(dataLocationCheckIn) : []
    let stepField = []
    let form_value = []
    let number_page = 0
    before_test.map(data => {
      if (number_page <= data.task_stepper - 1) {
        stepField.push({
          fields: [],
        })
        number_page++
      }
    })

    for (let index = 0; index < number_page; index++) {
      if (form_success[index + 1] !== undefined) {
        if (form_success[index + 1].length !== undefined) {
          form_success[index + 1].map(data => {
            form_value.push(data)
          })
        } else {
          form_value.push(form_success[index + 1])
        }
      }
    }

    before_test.map(data => {
      var indexFever = form_value.findIndex(std => std.task_id === data.task_id)

      if (indexFever !== -1) {
        stepField[data.task_stepper - 1].fields.push({
          ...data,
          value: form_value[indexFever].value
            ? form_value[indexFever].value
            : null,
          pass_step: form_value[indexFever].value ? true : false,
          image: null,
          multi_image: [],
        })
      } else {
        if (
          data.task_type_module_name === "checkinField" ||
          data.task_type_module_name === "checkoutField"
        ) {
          var indexStepperCheckInOut = arrayLocal.findIndex(std => ((std.index_step + 1) === data.task_stepper) && (std.index_field === data.task_index))
          if (indexStepperCheckInOut !== -1 ) {
            stepField[data.task_stepper - 1].fields.push({
              ...data,
              value: [{
                lat: arrayLocal[indexStepperCheckInOut].lat,
                lng: arrayLocal[indexStepperCheckInOut].lng,
                date: arrayLocal[indexStepperCheckInOut].date,
              }],
              pass_step: false,
              image: null,
              multi_image: [],
            })
          } else {
            stepField[data.task_stepper - 1].fields.push({
              ...data,
              value: null,
              pass_step: false,
              image: null,
              multi_image: [],
            })
          }
        } else {
          stepField[data.task_stepper - 1].fields.push({
            ...data,
            value: null,
            pass_step: false,
            image: null,
            multi_image: [],
          })
        }
      }
    })
    setTask_hast_form(stepField)
  }

  function debugFunction() {
    console.log(task_hast_form);
  }

  return (
    <div>
      {/* <button onClick={() => debugFunction()}>debug all tab</button> */}
      {task_hast_form.length !== 0 && (
        <Row>
          <Col>
            <RenderForm_hast_form
              ser_id={props.ser_id}
              setTask_hast_form={setTask_hast_form}
              task_hast_form={task_hast_form}
              priority={priority}
              stepField={task_hast_form}
              check_data_submit={check_data_submit}
              Check_service_form={Check_service_form}
              setActiveTab={props.setActiveTab}
              check_new_data_edit={props.check_new_data_edit}
              check_activity_log={props.check_activity_log}
              list_service_overview={props.list_service_overview}
              mapping_data_array={mapping_data_array}
            />
          </Col>
        </Row>
      )}
    </div>
  )
}
