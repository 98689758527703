import React, { useState, useEffect } from "react"
import { Row, Col, Container, Card, CardBody } from "reactstrap"
import MetaTags from "react-meta-tags"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { MdKeyboardBackspace } from "react-icons/md"
import SLA_Components from "./SLA_Components"
import { useSelector } from "react-redux"
import no_permi from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png"

export default function SLA_Table() {
  const history = useHistory()
  const { t } = useTranslation()
  const { permissionAllService } = useSelector(({ Distar }) => Distar)
  const [permi_t_read, setPermi_t_read] = useState(false)
  const [permi_t_write, setPermi_t_write] = useState(false)
  const [permi_t_edit, setPermi_t_edit] = useState(false)
  const [permi_t_delete, setPermi_t_delete] = useState(false)

  useEffect(() => {
    if (permissionAllService) {
      if (permissionAllService.system_setting) {
        setPermi_t_read(permissionAllService.system_setting.read)
        setPermi_t_write(permissionAllService.system_setting.write)
        setPermi_t_edit(permissionAllService.system_setting.edit)
        setPermi_t_delete(permissionAllService.system_setting.delete)
      }
    }
    return () => {}
  }, [permissionAllService])
  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("SLA")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/system-setting")}
              />
            </a>{" "}
            {t("SLA")}
          </h3>
          {permi_t_read === true && (
            <Card>
              <CardBody>
                <SLA_Components
                  permi_t_write={permi_t_write}
                  permi_t_edit={permi_t_edit}
                  permi_t_delete={permi_t_delete}
                />
              </CardBody>
            </Card>
          )}

          {permi_t_read === false && (
            <div>
              <img
                src={no_permi}
                alt="no permission"
                style={{ display: "block", margin: "50px auto" }}
              />
              <p style={{ textAlign: "center", fontSize: "20px" }}>
                {t("no permission read module")}
              </p>
              <p style={{ textAlign: "center", fontSize: "20px" }}>
                {" "}
                {t("please contract administrator")}{" "}
              </p>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
