import React, { useRef, useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import axios from "axios"
import * as api from "../../../../service/Api_Edit_Dealer"
import { Form, Button, Switch, Modal, notification, Avatar } from "antd"
import Input_Comp from "./components/Input_Comp"
import Input_Number from "./components/Input_Number"
import Input_Comp_pass from "./components/Input_Comp_pass"
import Select_no_search from "./components/Select_no_search"
import { UserOutlined } from "@ant-design/icons"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import Input_Email from './components/Input_Email'
import { useHistory } from "react-router-dom";
import helpers from '../../../Function_Redirect/Redirect_login'
import { useTranslation } from "react-i18next";

const { confirm } = Modal

export default function Create_New_User(props) {
  const { t } = useTranslation();
  const token = localStorage.getItem("token")
  const [form] = Form.useForm()
  const history = useHistory()
  const imageUploader = useRef(null)
  const [loading, setLoading] = useState(false)
  const [new_user, setNew_user] = useState({
    dea_email_password_condition: false,
  })
  const [error_pass, setError_pass] = useState(null)
  const [select_image, setSelect_image] = useState(null)
  const [confirm_Pass, setConfirm_Pass] = useState({
    confirm_Pass: null,
  })
  const [disable_button, setDisable_button] = useState(false)
  const [email_duplicate, setEmail_duplicate] = useState(null)

  useEffect(() => {
    if(new_user.dea_user_email){
      check_email_dup()
    }
    return () => {
      
    }
  }, [new_user.dea_user_email])

  function check_email_dup() {
    if(new_user.dea_user_email){
      const check_email = api.CHECK_DEALER_EMAIL_DUPLICATE(token , new_user.dea_user_email)
      axios(check_email)
      .then( res => {
        if(res.data.data === true){
          setDisable_button(true)
          setEmail_duplicate(t("Email is duplicate"))
        } else {
          setDisable_button(false)
          setEmail_duplicate(null)
        }
       
      })
      .catch( error => {
        // console.log(error);
      })
    }
  }

  useEffect(() => {
    if (confirm_Pass.confirm_Pass !== new_user.dea_user_password) {
      setError_pass(t("Password mismatch"))
    } else {
      setError_pass(null)
    }
    return () => {}
  }, [confirm_Pass.confirm_Pass])

  function onChangeSwitchPass(checked) {
    if (checked) {
      setError_pass(null)
      form.resetFields(["dea_user_password", "confirm_Pass"])
      setNew_user({
        ...new_user,
        dea_user_password: null,
        dea_email_password_condition: checked,
      })
    } else {
      setNew_user({
        ...new_user,
        dea_email_password_condition: checked,
      })
    }
  }

  const handleImageUpload = e => {
    if (e.target.files[0] !== undefined) {
      if (e.target.files[0].type !== "image/jpeg") {
        openNotificationWithIcon("error", t("please upload jpg or jpeg image only"))
      } else {
        let img_upload = e.target.files[0]
        setSelect_image(img_upload)
      }
    }
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  const onFinish = values => {
    if (error_pass !== null) {
      return Modal.error({
        centered: true,
        title: t("Password mismatch"),
        okText: t("ok"),
      })
    } else {
      return confirm({
        title: t("Create new dealer user"),
        icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
        centered: true,
        okText: t("create"),
        cancelText: t("no"),        
        onOk() {
          setLoading(true)
          if (select_image !== null) {
            const formData = new FormData()
            formData.append("file", select_image)
            const upload_image = api.UPLOAD_PROFILE(token, formData)
            axios(upload_image)
              .then(res => {
                if (new_user.dea_email_password_condition === true) {
                  let data_info_pass = {}
                  data_info_pass = {
                    dea_user_firstname: new_user.dea_user_firstname,
                    dea_user_lastname: new_user.dea_user_lastname,
                    dea_user_avatar: res.data.path,
                    dea_user_phone: new_user.dea_user_phone,
                    dea_user_code: new_user.dea_user_code,
                    dep_id: new_user.dep_id,
                    pos_id: new_user.pos_id,
                    dea_user_email: new_user.dea_user_email,
                    dea_email_password_condition:
                      new_user.dea_email_password_condition,
                  }
                  create_new_dealer_user(data_info_pass)
                } else {
                  let data_pass = {}
                  data_pass = {
                    dea_user_avatar: res.data.path,
                    ...new_user,
                  }
                  create_new_dealer_user(data_pass)
                }
              })
              .catch(error => {
                setLoading(false)
                if(error){
                  if(error.response){
                    if(error.response.status === 401){
                      helpers.re_login()
                      history.push('/login')          
                      } else {
                        openNotificationWithIcon("error", t("Create dealer user error"))
                      }
                  }
                }
              })
          } else {
            if (new_user.dea_email_password_condition === true) {
              let data_info_pass = {}
              data_info_pass = {
                dea_user_firstname: new_user.dea_user_firstname,
                dea_user_lastname: new_user.dea_user_lastname,
                dea_user_avatar: "None",
                dea_user_phone: new_user.dea_user_phone,
                dea_user_code: new_user.dea_user_code,
                dep_id: new_user.dep_id,
                pos_id: new_user.pos_id,
                dea_user_email: new_user.dea_user_email,
                dea_email_password_condition:
                  new_user.dea_email_password_condition,
              }
              create_new_dealer_user(data_info_pass)
            } else {
              let data_pass = {}
              data_pass = {
                dea_user_avatar: "None",
                ...new_user,
              }
              create_new_dealer_user(data_pass)
            }
          }
        },
        onCancel() {
          // console.log('Cancel');
        },
      })
    }
  }

  function create_new_dealer_user(data_dealer_user) {
    const create_new_user_dealer_data = api.CREATE_NEW_DEALER_USER_DATA(
      token,
      props.dea_id,
      data_dealer_user
    )
    axios(create_new_user_dealer_data)
      .then(res => {
        openNotificationWithIcon("success", t("Create dealer user success"))
        onReset()
        setLoading(false)
        props.check_user_in_dealer()
      })
      .catch(error => {
        setLoading(false)
        
        if(error){
          if(error.response){
            if(error.response.status === 401){
              helpers.re_login()
              history.push('/login')          
              } else {
                openNotificationWithIcon("error", t("Create dealer user error"))
              }
          }
        }
      })
  }

  const onReset = () => {
    form.resetFields()
    setNew_user({
      dea_email_password_condition: false,
    })
    setConfirm_Pass({
      confirm_Pass: null,
    })
    setError_pass(null)
    props.setActiveTab("1")
    setSelect_image(null)
  }

  // "dea_user_avatar" :"dealer firstname" ,

  return (
    <div>
      <div className="image-upload" style={{ marginBottom: "30px" }}>
        <p
          style={{ textAlign: "center", fontSize: "15px", fontWeight: "bold" }}
        >
          {t("photo profile")}
        </p>
        {select_image === null && (
          <Avatar
            icon={<UserOutlined />}
            size={100}
            style={{ display: "block", margin: "auto" }}
          />
        )}

        {select_image !== null && (
          <Avatar
            src={URL.createObjectURL(select_image)}
            size={100}
            style={{ display: "block", margin: "auto" }}
          />
        )}

        <input
          type="file"
          accept="image/jpeg"
          onChange={handleImageUpload}
          ref={imageUploader}
          style={{
            display: "none",
          }}
        />
      </div>
      <div>
        <Button
          type="primary"
          style={{
            display: "block",
            margin: "0 auto 20px auto",
            width: "150px",
          }}
          onClick={() => imageUploader.current.click()}
        >
          {t("Upload photo")}
        </Button>
        <p style={{ color: "red", textAlign: "center" }}>
          {t("please upload jpg or jpeg image only")}
        </p>
      </div>

      <br />
      <Form form={form} name="new-user-in-dealer" onFinish={onFinish}>
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Dealer user firstname")}
              placeholder={t("Dealer user firstname")}
              headState={setNew_user}
              nameState={new_user}
              type="text"
              keyState="dea_user_firstname"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Dealer user lastname")}
              placeholder={t("Dealer user lastname")}
              headState={setNew_user}
              nameState={new_user}
              type="text"
              keyState="dea_user_lastname"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Dealer user code")}
              placeholder={t("Dealer user code")}
              headState={setNew_user}
              nameState={new_user}
              type="text"
              keyState="dea_user_code"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Email
              header={t("Dealer user email")}
              placeholder={t("Dealer user email")}
              headState={setNew_user}
              nameState={new_user}
              type="email"
              keyState="dea_user_email"
            />
            {
              email_duplicate !== null && (
                <p style={{ color:'red' }}>{email_duplicate}</p>
              )
            }
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Dealer user phone")}
              placeholder={t("Dealer user phone")}
              headState={setNew_user}
              nameState={new_user}
              type="number"
              keyState="dea_user_phone"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Dealer user position")}
              placeholder={t("Dealer user position")}
              name_api="LIST_POSITION"
              map_name="pos_name"
              map_value="pos_id"
              keyState="pos_id"
              headState={setNew_user}
              nameState={new_user}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Dealer user department")}
              placeholder={t("Dealer user department")}
              name_api="LIST_DEPARTMENT"
              map_name="dep_name"
              map_value="dep_id"
              keyState="dep_id"
              headState={setNew_user}
              nameState={new_user}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="mt-bottom-all">
              <p className="text-bold">
                {t("Sending signup password by email")}
                <Switch
                  checked={new_user.dea_email_password_condition}
                  onChange={onChangeSwitchPass}
                  style={{ marginLeft: "10px" }}
                />{" "}
              </p>
            </div>
          </Col>
        </Row>

        {!new_user.dea_email_password_condition && (
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <Input_Comp_pass
                header={t("Password")}
                placeholder={t("Password")}
                headState={setNew_user}
                nameState={new_user}
                keyState="dea_user_password"
                type="password"
              />
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
              <Input_Comp_pass
                header={t("Confirm Password")}
                placeholder={t("Confirm Password")}
                headState={setConfirm_Pass}
                nameState={confirm_Pass}
                keyState="confirm_Pass"
                type="password"
              />
            </Col>
          </Row>
        )}
        {new_user.dea_user_password && confirm_Pass.confirm_Pass !== null && (
          <p
            style={{
              textAlign: "center",
              color: "red",
              fontSize: "15px",
            }}
          >
            {error_pass}
          </p>
        )}
        <br />

        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div className="div_create_new_car">
              <Button
                className="btn_cancel_create_new_car"
                onClick={() => onReset()}
              >
               {t("Cancel")}
              </Button>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div className="div_create_new_car">
              <Button
                className="btn_submit_create_new_car"
                type="primary"
                htmlType="submit"
                disabled={disable_button}
                loading={loading}
              >
             {t("Create")}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
