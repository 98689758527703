import React, { useEffect, useState } from "react"
import * as api from "../../../service/API_Edit_Car"
import axios from "axios"
import { Row, Col } from "reactstrap"
import { Select } from "antd"
import { useTranslation } from "react-i18next";

const { Option } = Select

export default function List_Product_Group(props) {
  const token = localStorage.getItem("token")
  const [list_product_group, setList_product_group] = useState([])
  const { t } = useTranslation();

  useEffect(() => {
    ckeck_product_group_all()
    return () => {}
  }, [])

  function ckeck_product_group_all() {
    const check_product = api.LIST_PRODUCT_GROUP(token)
    axios(check_product)
      .then(res => {
        setList_product_group(res.data.data)
      })
      .catch(error => {
        // console.log(error.response)
      })
  }

  useEffect(() => {
    render_product_group_select()
    return () => {}
  }, [list_product_group])

  function render_product_group_select() {
    return list_product_group.map(data => {
      return (
        <Option value={data.pro_group_id} key={data.pro_group_id}>
          {data.pro_group_name}
        </Option>
      )
    })
  }

  function onChange(value) {
    if (value === undefined) {
        props.setSelect_product_group(null)
    } else {
        props.setSelect_product_group(value)
    }
  }

  return (

      <Row>
        <Col xs={12} sm={4} md={3} lg={3} xl={3}>
          <p style={{ fontSize: "15px", fontWeight: "bold" }}>
            {t("Select product group")}
          </p>
        </Col>
        <Col xs={12} sm={8} md={5} lg={3} xl={3}>
          <Select
            showSearch
            allowClear
            style={{ width: '100%' }}
            placeholder={t("Select product group")}
            optionFilterProp="children"
            onChange={onChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {render_product_group_select()}
          </Select>
        </Col>
      </Row>
  
  )
}
