var Ip = require('../service/Ip')

export const CHECK_USER_INFO = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}authorize/user_info`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CHECK_USER_OPERATOR = (token,ope_id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/operator/${ope_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CHECK_USER_CUSTOMER = (token,user_id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer/users/${user_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CHECK_USER_DEALER = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/dealer/what_is_my_dea_id`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const DETAIL_DEALER_INFO = (token,id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/dealer/user/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const GET_IMAGE = (token, image) => {
    return {
      method: 'get',
      responseType: 'arraybuffer',
      url: `${Ip.getIP()}storage/file/${image}`,
      headers: {
        'x-access-token' : token
      }
    };
  };

  export const LIST_NOTI_LIMIT = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}apinotification/`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CHECK_OLD_NOTIFICATION = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}apinotification/count`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_NOTIFICATION_ALL = (token, page, pageSize) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}apinotification/pagination?page=${page}&limit=${pageSize}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CLEAR_COUNT = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}apinotification/count/clear`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CHECK_ROLE_PERMISSION = (token, emp_id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/employee/role-permission/${emp_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  
export const UPLOAD_PROFILE = (token, formData) => {
  return {
    method: 'post',
    url: `${Ip.getIP()}storage/upload`,
    headers: {
      "Content-type": "multipart/form-data",
      'x-access-token' : token
    },
    data : formData
  };
};

export const EDIT_ADMIN = (token ,admin_id , data ) => {
  return {
    method: 'put',
    url: `${Ip.getIP()}api/v1/admin/${admin_id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    },
    data : data
  };
};

export const EDIT_EMPLOYEE = (token,id,data) => {
  return {
    method: 'put',
    url: `${Ip.getIP()}api/v1/employee/${id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    },
    data : data
  };
};

export const CHANGE_PASSWORD = (token,data) => {
  return {
    method: 'put',
    url: `${Ip.getIP()}password/changepassword`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    },
    data : data
  };
};

export const CHANGE_PASSWORD_BY_ADMIN = (token, id , data) => {
  return {
    method: 'put',
    url: `${Ip.getIP()}password/setpassword/${id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    },
    data : data
  };
};

export const RESET_PASS_BY_EMAIL = (token, data) => {
  return {
    method: 'post',
    url: `${Ip.getIP()}api/v1/admin/resetpassword`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    },
    data : data
  };
};