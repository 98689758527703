import React, { useState, useEffect } from "react"
import { Table, Button, Pagination, Tooltip, Modal } from "antd"
import { useHistory } from "react-router-dom"
import Render_Image_Data from "../../../Employee/Table/Render_Image_Data"
import { FcSearch, FcSettings, FcDataSheet } from "react-icons/fc"
import { useTranslation } from "react-i18next"
import Detail_Dealer from './Detail_Dealer'

export default function Table_User(props) {
  const { t } = useTranslation()
  const history = useHistory()
  const [detail_user_info, setDetail_user_info] = useState(null)
  const [visible, setVisible] = useState(false)

  const columns = [
    {
      title: "",
      dataIndex: "dea_user_avatar",
      key: "dea_user_avatar",
      render: text => <Render_Image_Data path_image={text} />,
    },
    {
      title: t("Dealer user code"),
      dataIndex: "dea_user_code",
      key: "dea_user_code",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Dealer user firstname"),
      dataIndex: "dea_user_firstname",
      key: "dea_user_firstname",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Dealer user lastname"),
      dataIndex: "dea_user_lastname",
      key: "dea_user_lastname",
      render: text => <p>{text}</p>,
    },
    {
      title: "",
      render: text => (
        <Tooltip title={t("View user")}>
          <a>
            <FcSearch
              onClick={() => {
                setVisible(true)
                setDetail_user_info(text)
              }}
              size={20}
            />
          </a>
        </Tooltip>
      ),
    },
    {
      title: "",
      dataIndex: "dea_user_id",
      key: "dea_user_id",
      render: text => (
        <Tooltip title={t("Edit dealer user")}>
          <a>
            <FcSettings
              onClick={() =>
                history.push(`/dealer/${props.dea_id}/edit-user/${text}`)
              }
              size={20}
            />
          </a>
        </Tooltip>
      ),
    },
  ]

  return (
    <div>
      <Table
        dataSource={props.data_user_all}
        columns={columns}
        rowKey="dea_user_id"
        scroll={{ x: 800 }}
      />

      <Modal
        centered
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={() => {
          setVisible(false)
          setDetail_user_info(null)
        }}
        footer={false}
        width={1000}
        height={500}
      >
        <Detail_Dealer detail_user_info={detail_user_info} />
      </Modal>
    </div>
  )
}
