import React, { useEffect, useState } from "react"
import { Form, Checkbox, Button, Select, Modal, notification } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Row, Col } from "reactstrap"
import { useHistory } from "react-router-dom"
import "../Profile/Profile_New_Employee.css"
import axios from "axios"
import Swal from "sweetalert2"
import * as api from "../../../service/Api_New_Employee"
import { useTranslation } from "react-i18next"
import helpers from "../../Function_Redirect/Redirect_login"

const { Option } = Select
const { confirm } = Modal

export default function Level(props) {
  const { t } = useTranslation()
  const token = localStorage.getItem("token")
  const r = localStorage.getItem("r")
  const history = useHistory()
  const [permission, setPermission] = useState([])
  const [loading, setLoading] = useState(false)
  const [select_all_button, setSelect_all_button] = useState(false)
  const [data_role_all, setData_role_all] = useState({
    // employee: {
    //   read: false,
    //   write: false,
    //   edit: false,
    //   delete: false,
    // },
    customer: {
      read: false,
      write: false,
      edit: false,
      delete: false,
    },
    car: {
      read: false,
      write: false,
      edit: false,
      delete: false,
    },
    import: {
      read: false,
      write: false,
      edit: false,
      delete: false,
    },
    contract: {
      read: false,
      write: false,
      edit: false,
      delete: false,
    },
    service: {
      read: false,
      write: false,
      edit: false,
      delete: false,
    },
    operator: {
      read: false,
      write: false,
      edit: false,
      delete: false,
    },
    dealer: {
      read: false,
      write: false,
      edit: false,
      delete: false,
    },
    stock: {
      read: false,
      write: false,
      edit: false,
      delete: false,
    },
    system_setting: {
      read: false,
      write: false,
      edit: false,
      delete: false,
    },
  })

  function select_all_role() {
    setSelect_all_button(true)
    setData_role_all({
      // employee: {
      //   read: true,
      //   write: true,
      //   edit: true,
      //   delete: true,
      // },
      customer: {
        read: true,
        write: true,
        edit: true,
        delete: true,
      },
      car: {
        read: true,
        write: true,
        edit: true,
        delete: true,
      },
      import: {
        read: true,
        write: true,
        edit: true,
        delete: true,
      },
      contract: {
        read: true,
        write: true,
        edit: true,
        delete: true,
      },
      service: {
        read: true,
        write: true,
        edit: true,
        delete: true,
      },
      operator: {
        read: true,
        write: true,
        edit: true,
        delete: true,
      },
      dealer: {
        read: true,
        write: true,
        edit: true,
        delete: true,
      },
      stock: {
        read: true,
        write: true,
        edit: true,
        delete: true,
      },
      system_setting: {
        read: true,
        write: true,
        edit: true,
        delete: true,
      },
    })
  }

  function cancel_select_all_role() {
    setSelect_all_button(false)
    setData_role_all({
      // employee: {
      //   read: false,
      //   write: false,
      //   edit: false,
      //   delete: false,
      // },
      customer: {
        read: false,
        write: false,
        edit: false,
        delete: false,
      },
      car: {
        read: false,
        write: false,
        edit: false,
        delete: false,
      },
      import: {
        read: false,
        write: false,
        edit: false,
        delete: false,
      },
      contract: {
        read: false,
        write: false,
        edit: false,
        delete: false,
      },
      service: {
        read: false,
        write: false,
        edit: false,
        delete: false,
      },
      operator: {
        read: false,
        write: false,
        edit: false,
        delete: false,
      },
      dealer: {
        read: false,
        write: false,
        edit: false,
        delete: false,
      },
      stock: {
        read: false,
        write: false,
        edit: false,
        delete: false,
      },
      system_setting: {
        read: false,
        write: false,
        edit: false,
        delete: false,
      },
    })
  }

  useEffect(() => {
    render_Check_Info()
    return () => {}
  }, [data_role_all])

  function render_Check_Info() {
    return Object.keys(data_role_all).map((key, index) => {
      return (
        <tr key={index}>
          <td>{t(key)}</td>
          <td>
            <Checkbox
              onChange={e => onChange(e, key, "read")}
              checked={data_role_all[key].read}
            />
          </td>
          <td>
            <Checkbox
              onChange={e => onChange(e, key, "write")}
              checked={data_role_all[key].write}
            />
          </td>
          <td>
            <Checkbox
              onChange={e => onChange(e, key, "edit")}
              checked={data_role_all[key].edit}
            />
          </td>
          <td>
            <Checkbox
              onChange={e => onChange(e, key, "delete")}
              checked={data_role_all[key].delete}
            />
          </td>
        </tr>
      )
    })
  }

  function onChange(e, key, module) {
    const test = { ...data_role_all }
    test[key][module] = e.target.checked
    setData_role_all(test)
  }

  const onFinish = value => {
    return confirm({
      title: t("create employee"),
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: t("ok"),
      cancelText: t("cancel"),
      onOk() {
        setLoading(true)
        let data_new_Role = {
          ...data_role_all,
          employee: {
            read: false,
            write: false,
            edit: false,
            delete: false,
          },
        }
        create_role_permission_new(data_new_Role)

        // create_role_permission_new(data_role_all)
      },
      onCancel() {},
    })
  }

  function create_role_permission_new(data_role) {
    const new_role = api.CREATE_NEW_ROLE_PERMISSION(token, data_role)
    axios(new_role)
      .then(res => {
        let data_info_pass = {}
        if (props.data_new_employee.emp_password) {
          data_info_pass = {
            emp_code: props.data_new_employee.emp_code,
            emp_firstname: props.data_new_employee.emp_firstname,
            emp_lastname: props.data_new_employee.emp_lastname,
            dep_id: props.data_new_employee.dep_id,
            pos_id: props.data_new_employee.pos_id,
            emp_email: props.data_new_employee.emp_email,
            emp_email_password_condition:
              props.data_new_employee.emp_email_password_condition,
            emp_level_id: res.data.emp_level_id,
            emp_password: props.data_new_employee.emp_password,
            emp_nickname: props.data_new_employee.emp_nickname,
            emp_group_id: props.data_new_employee.emp_group_id,
          }
        } else {
          data_info_pass = {
            emp_code: props.data_new_employee.emp_code,
            emp_firstname: props.data_new_employee.emp_firstname,
            emp_lastname: props.data_new_employee.emp_lastname,
            dep_id: props.data_new_employee.dep_id,
            pos_id: props.data_new_employee.pos_id,
            emp_email: props.data_new_employee.emp_email,
            emp_email_password_condition:
              props.data_new_employee.emp_email_password_condition,
            emp_level_id: res.data.emp_level_id,
            emp_nickname: props.data_new_employee.emp_nickname,
            emp_group_id: props.data_new_employee.emp_group_id,
          }
        }
        if (props.select_image !== null) {
          const formData = new FormData()
          formData.append("file", props.select_image)
          const upload_image = api.UPLOAD_PROFILE(token, formData)
          axios(upload_image)
            .then(res => {
              let add_image_path = {
                emp_image: res.data.path,
                ...data_info_pass,
              }
              create_new_employee(add_image_path)
            })
            .catch(error => {
              setLoading(false)
              if (error) {
                if (error.response) {
                  if (error.response.status === 401) {
                    helpers.re_login()
                    history.push("/login")
                  } else {
                    openNotificationWithIcon(
                      "error",
                      t("create employee error")
                    )
                  }
                }
              }
              // console.log(error.response)
            })
        } else {
          create_new_employee(data_info_pass)
        }
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            } else {
              openNotificationWithIcon("error", t("create employee error"))
            }
          }
        }
        // console.log(error.response);
      })
  }

  function create_new_employee(data) {
    if(r === "false"){
      const create_info = api.CREATE_EMPLOYEE(token, data)
      axios(create_info)
        .then(res => {
          history.push("/employee")
          setLoading(false)
          openNotificationWithIcon("success", t("create employee success"))
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              } else {
                openNotificationWithIcon("error", t("create employee error"))
              }
            }
          }
        })
    } else if(r === "true"){
      let data_from_admin_root = {
        ...data,
        admin_group_id : props.data_new_employee.admin_group_id
      }
      const create_info = api.ADMIN_ROOT_CREATE_EMPLOYEE(token, data_from_admin_root)
      axios(create_info)
        .then(res => {
          history.push("/employee")
          setLoading(false)
          openNotificationWithIcon("success", t("create employee success"))
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              } else {
                openNotificationWithIcon("error", t("create employee error"))
              }
            }
          }
        })
    }
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  return (
    <div>
      <Form
        name="basic_level"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <div className="mt-bottom-all" style={{ overflowX: "scroll" }}>
          <p className="text-bold">
            {" "}
            {t("role and Permission Setting")}{" "}
            <span style={{ color: "red" }}>*</span>
          </p>
          {select_all_button === false && (
            <Button
              type="primary"
              style={{ float: "right", marginBottom: "20px" }}
              onClick={() => select_all_role()}
            >
              {" "}
              {t("select all")}
            </Button>
          )}
          {select_all_button === true && (
            <Button
              style={{ float: "right", marginBottom: "20px" }}
              onClick={() => cancel_select_all_role()}
            >
              {" "}
              {t("cancel")}
              {t("select all")}{" "}
            </Button>
          )}

          <div>
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>{t("module")}</th>
                  <th>{t("read")}</th>
                  <th>{t("write")}</th>
                  <th>{t("edit")}</th>
                  <th>{t("delete")}</th>
                </tr>
              </thead>
              <tbody>{render_Check_Info()}</tbody>
            </table>
          </div>
        </div>

        <br />
        <div>
          <Button
            className="btn-back-level"
            style={{
              float: "left",
            }}
            onClick={() => props.setKey_Tabs("1")}
          >
            {t("back")}
          </Button>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            className="btn-submit-level"
            style={{
              wisth: "150px",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {t("submit")}
          </Button>
        </div>
      </Form>
    </div>
  )
}
