import React from 'react'
import { Tabs } from "antd";
import TextForm from './TextForm'
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs;

export default function index(props) {
    const { t } = useTranslation();

return (
<>
    <Tabs defaultActiveKey="0">
        {
        props.stepField.map( (data, index) => {
            return(
                <TabPane tab={`${t("Step")} ${index + 1}`} key={index}>
                    <TextForm fields={data.fields} priority={props.priority}/>
                </TabPane>
                )
            })
        }
    </Tabs>
</>
)
}
