import React, { useState, useEffect } from "react"
import { Container, Card, CardBody, Row , Col } from "reactstrap"
import MetaTags from "react-meta-tags"
import axios from 'axios'
import * as api from '../../../service/service_dealer/Api_Dashboard'
import Cars_Header from './components/Cars_Header'
import { useHistory } from "react-router-dom";
import helpers from '../../Function_Redirect/Redirect_login'
import { useTranslation } from "react-i18next";

const User = props => {
  const { t } = useTranslation();
  const token = localStorage.getItem("token")
  const [customer, setCustomer] = useState(0)
  const [dealer, setDealer] = useState(0)
  const [service, setService] = useState(0)
  const [stock, setStock] = useState(0)
  const history = useHistory()
  
  const style_card = {
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  }

  useEffect(() => {
    list_dashboard()
      return () => {
        setCustomer(0)
        setDealer(0)
        setService(0)
        setStock(0)
      }
  }, [])

  function list_dashboard() {
      const list_ = api.LIST_DASHBOARD(token)
      axios(list_)
      .then( res => {
          setCustomer(res.data.date.count_customer)
          setDealer(res.data.date.count_dealer_user)
          setService(res.data.date.count_service)
          setStock(res.data.date.count_stock)
      })
      .catch( error => {
          if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }

      })
  }
  
  
  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("dashboard")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>{t("dashboard")}</h3>

          <Card style={style_card}>
          <CardBody>
            <br />
            <Row>
            <Col sm={12} md={6} lg={6} xl={3}>
            <Cars_Header
              header={t("customer")}
              number={customer}
              color_border_icon="#ff6961"
              icon="BiUserCircle"
            />
          </Col>
              <Col sm={12} md={6} lg={6} xl={3}>
              <Cars_Header
                header={t("dealer")}
                number={dealer}
                color_border_icon="#6891C3"
                icon="FaRegHandshake"
              />
            </Col>
              <Col sm={12} md={6} lg={6} xl={3}>
                <Cars_Header
                  header={t("service")}
                  number={service}
                  color_border_icon="#7985E0"
                  icon="VscTools"
                />
              </Col>
              <Col sm={12} md={6} lg={6} xl={3}>
                <Cars_Header
                  header={t("stock")}
                  number={stock}
                  color_border_icon="#76CE86"
                  icon="FiBox"
                />
              </Col>
            </Row>
          </CardBody>
        </Card>

        </Container>
      </div>
    </React.Fragment>
  )
}
export default User
