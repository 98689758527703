import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import MetaTags from "react-meta-tags"
import { Container, Card, CardBody , Row , Col } from "reactstrap"
import { MdKeyboardBackspace } from "react-icons/md"
import { Pagination, Table, Button, Modal, Tag, Tooltip } from "antd"
import { FcDataSheet } from "react-icons/fc"
import {
  FcShipped,
  FcImport,
  FcViewDetails,
  FcSearch,
  FcAcceptDatabase,
  FcDeleteDatabase,
} from "react-icons/fc"
import axios from "axios"
import * as api from "../../service/Api_Import_Customer_Distar"
import no_permi from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png"
import helpers from "../Function_Redirect/Redirect_login"
import Search_data_customer from "./Search_data_customer"
import { useTranslation } from "react-i18next"
import FilterIsImportCar from "../Import_Car_From_DB_Distar/FilterIsImportCar"
import { useSelector } from "react-redux"

const { confirm } = Modal

export default function Table_Car_From_db(props) {
  const history = useHistory()
  const { t } = useTranslation()
  const token = localStorage.getItem("token")
  const [search_customer_data, setSearch_customer_data] = useState(null)
  const [loading, setLoading] = useState(false)
  const [filterIsImport, setFilterIsImport] = useState(null)
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })
  const [customer_distar, setCustomer_distar] = useState([])
  const { permissionAllService } = useSelector(({ Distar }) => Distar)

  const permi = localStorage.getItem("permi")
  const [permi_t_read, setPermi_t_read] = useState(false)

  useEffect(() => {
    if (permissionAllService) {
      if (permissionAllService.import) {
        setPermi_t_read(permissionAllService.import.read)
      }
    }
    return () => {}
  }, [permissionAllService])

  useEffect(() => {
    search_customer_distar()
    return () => {
      setFilterIsImport(null)
      setSearch_customer_data(null)
      setCustomer_distar([])
    }
  }, [])

  useEffect(() => {
    search_customer_distar() 
    return () => {
      
    }
  }, [filterIsImport])

  const check_data = (params, data) => {
    if (data) {
      return params + data
    } else {
      return ""
    }
  }

  function search_customer_distar() {
    setLoading(true)
    let url = `distar/customer?page=1&limit=${
      page_limit.page_Size
    }&order_by=DESC${check_data("&q=", search_customer_data)}${check_data("&is_import=",filterIsImport)}`
    const search = api.SEARCH_ALL_CUSTOMER(token, url)
    axios(search)
      .then(res => {
        setLoading(false)
        setPage_limit({
          ...page_limit,
          page: 1,
          limit: res.data.total_data,
        })
        // console.log(res.data.data)
        setCustomer_distar(res.data.data)
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  function changePagination(page, pageSize) {
    setLoading(true)
    let url = `distar/customer?page=${page}&limit=${pageSize}&order_by=DESC${check_data(
      "&q=",
      search_customer_data
    )}${check_data("&is_import=",filterIsImport)}`
    const search = api.SEARCH_ALL_CUSTOMER(token, url)
    axios(search)
      .then(res => {
        setLoading(false)
        setPage_limit({
          ...page_limit,
          page: page,
          page_Size: pageSize,
          limit: res.data.total_data,
        })
        setCustomer_distar(res.data.data)
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  const columns = [
    {
      title: "cid",
      dataIndex: "cid",
      key: "cid",
    },
    {
      title: "gid",
      dataIndex: "gid",
      key: "gid",
    },
    {
      title: "pid",
      dataIndex: "pid",
      key: "pid",
    },
    {
      title: "title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "remark",
      dataIndex: "remark",
      key: "remark",
    },
    {
      title: "",
      render: id => <div> {check_customer_import(id)}</div>,
    },
  ]

  function check_customer_import(data) {
    if (data.customer_in_db === false) {
      return (
        <Tooltip title={t("Import customer")}>
          <a>
            <FcImport
              size={20}
              onClick={() =>
                history.push({
                  pathname: "/import-data/customer/select-customer",
                  gid: data.gid,
                  data_customer: data,
                })
              }
            />
          </a>
        </Tooltip>
      )
    } else if (data.customer_in_db === true) {
      return (
        <Tooltip title={t("Customer exist in database")}>
          <FcAcceptDatabase size={20} />
        </Tooltip>
      )
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>{t("Import customer")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/import-data")}
              />
            </a>{" "}
            {t("Import customer")}
          </h3>
          <br />
          <Card>
            <CardBody>
              {permi_t_read === true && (
                <div>
                  <Search_data_customer
                    setSearch_customer_data={setSearch_customer_data}
                    search_customer_distar={search_customer_distar}
                  />
             <Row>
                    <Col sm={12} md={3} lg={3} xl={3}>
                      <FilterIsImportCar 
                      headState={setFilterIsImport} 
                      headerField={t("status import customer")}
                      />
                    </Col>
                  </Row>
                  <br />
            <div>
                    <p>
                      {" "}
                      <FcAcceptDatabase size={25} />{" "}
                      {t("Customer exist in database")}{" "}
                      <span style={{ marginLeft: "10px" }}>/</span>{" "}
                      <FcImport size={25} style={{ marginLeft: "10px" }} />
                      {t("Able to import to database")}
                    </p>
                  </div>
                  <br />
                  <Table
                    loading={loading}
                    rowKey="id"
                    pagination={false}
                    columns={columns}
                    dataSource={customer_distar}
                    scroll={{ x: 800 }}
                  />
                  <div>
                    <Pagination
                      current={page_limit.page}
                      total={page_limit.limit}
                      onChange={changePagination}
                      style={{ marginTop: "20px", float: "right" }}
                    />
                  </div>
                  <p style={{ fontSize: "15px", marginTop: "20px" }}>
                    {" "}
                    <FcDataSheet
                      size={20}
                      style={{ marginBottom: "5px" }}
                    />{" "}
                    {t("total data")} : {page_limit.limit}
                  </p>
                </div>
              )}

              {permi_t_read === false && (
                <div>
                  <img
                    src={no_permi}
                    alt="no permission"
                    style={{ display: "block", margin: "50px auto" }}
                  />
                  <p style={{ textAlign: "center", fontSize: "20px" }}>
                    {t("no permission read module")}
                  </p>
                  <p style={{ textAlign: "center", fontSize: "20px" }}>
                    {" "}
                    {t("please contract administrator")}{" "}
                  </p>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
