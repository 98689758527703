import React, { useState } from "react"
import { Row, Col } from "reactstrap"
import { Input, Button, Modal, Steps } from "antd"
import Create_New_Customer from "./Create_New_Customer"
import Create_New_Car from './Create_New_Car'
import Create_New_Contract from './Create_New_Contract'
import { BiUserCircle } from "react-icons/bi"
import { RiNewspaperLine } from "react-icons/ri"
import { AiOutlineCar } from "react-icons/ai"
import { useTranslation } from "react-i18next";
import './btn_next_pre.css'

const { Step } = Steps
const { Search } = Input

export default function Search_customer(props) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false)
  const [activeStep, setActiveStep] = useState(0)

  const mt_icon = {
    marginBottom: "7px",
  }

  function onSearch(value) {
    props.search_customer_data()
  }

  function render_Icon(icon) {
    switch (icon) {
      case "AiOutlineCar":
        return <AiOutlineCar style={mt_icon} />
        break
      case "BiUserCircle":
        return <BiUserCircle style={mt_icon} />
        break
      case "RiNewspaperLine":
        return <RiNewspaperLine style={mt_icon} />
        break
      default:
        return <BiUserCircle style={mt_icon} />
        break
    }
  }

  function next() {
    const nextStep = activeStep + 1
    setActiveStep(nextStep)
  }

  function prev() {
    const prevStep = activeStep - 1
    setActiveStep(prevStep)
  }

  const steps_data = [
    {
      step: 0,
      title: t("Customer"),
      icon: "BiUserCircle",
      content: (
        <Create_New_Customer
        customer_create={props.customer_create}
        setActiveStep={setActiveStep}
        next={next}
          setVisible={setVisible}
          setCustomer_create={props.setCustomer_create}
          setCreate_new_customer={props.setCreate_new_customer}
          create_new_customer={props.create_new_customer}
        />
      ),
    },
    {
      step: 1,
      title: t("Car"),
      icon: "AiOutlineCar",
      content: (
        <Create_New_Car 
        customer_create={props.customer_create}
        prev={prev}
        next={next}
        setCreate_new_car={props.setCreate_new_car}
        create_new_car={props.create_new_car}
        />
      ),
    },
    {
      step: 2,
      title: t("Contract"),
      icon: "RiNewspaperLine",
      content: (
        <Create_New_Contract 
        customer_create={props.customer_create}
        prev={prev}
        next={next}
        create_new_contract={props.create_new_contract}
        setCreate_new_contract={props.setCreate_new_contract}
        setVisible={setVisible}
        setCustomer_create={props.setCustomer_create}
        />
      ),
    },

  ]

  return (
    <Row>
      <Col sm={12} md={6} lg={6} xl={6}>
        <Search
          style={{ width: "100%" }}
          placeholder={t("name")}
          allowClear
          enterButton={t("Search")}
          size="medium"
          onSearch={onSearch}
          onChange={e => props.setData_search_table_customer(e.target.value)}
        />
      </Col>
      <Col sm={12} md={1} lg={1} xl={1}>
        <Button onClick={() => setVisible(true)}> {t("Create new customer")} </Button>
      </Col>

      <Modal
        centered
        open={visible}
        footer={false}
        onCancel={() => {
          setVisible(false)
        }}
        width={1000}
      >

        <Steps current={activeStep} responsive={true}>
          {steps_data.map((item, index) => (
            <Steps.Step
              key={index}
              title={item.title}
              icon={render_Icon(item.icon)}
            />
          ))}
        </Steps>

        {steps_data.map((item, index) => (
          <div
            style={{ overflowY : 'scroll', overflowX : 'hidden', height:'500px' }}
            key={index}
            className={`steps-content ${
              item.step !== activeStep  && "hidden"
            }`}
          >
            {item.content}
          </div>
        ))}

      </Modal>
    </Row>
  )
}
