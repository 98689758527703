import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { Modal, Button, Input, Form, Spin } from "antd"
import { useTranslation } from "react-i18next"
import "./Button_Import_Style.css"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import Table_Loader_All from './Table_Loader_All'
import axios from "axios"
import * as api from "../../service/Api_Customer"
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from "react-redux"

const { confirm } = Modal
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function Search_data_customer(props) {
  const { t } = useTranslation()
  const token = localStorage.getItem("token")
  const [search_data_customer, setSearch_data_customer] = useState(null)
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })
  const [data_list, setData_list] = useState([])
  const [visible, setVisible] = useState(false);
  const { permissionAllService } = useSelector(({ Distar }) => Distar)
  const [permi_t_write, setPermi_t_write] = useState(false)

  useEffect(() => {
    if (permissionAllService) {
      if (permissionAllService.import) {
        setPermi_t_write(permissionAllService.import.write)
      }
    }
    return () => {}
  }, [permissionAllService])

  const onFinish = values => {
    props.search_customer_distar()
  }

  function import_all_customer_from_distar() {
    return confirm({
      title: t("Confirm import all customer"),
      icon: <ExclamationCircleOutlined />,
      content: t("This action may take longer than 10 minute are you sure to do this action"),
      centered: true,
      okText: t("confirm"),
      cancelText: t("no"),
      onOk() {
        setVisible(true)
        search_data()
        let id = setInterval(() => {
          search_data()
        }, 15000)
        import_all(id)
      },
      onCancel() {

      },
    })
  }

  function import_all(id) {
    const import_data = api.IMPORT_CUSTOMER_ALL_DATA(token)
    axios(import_data)
    .then( res => {
      clearInterval(id)
      setVisible(false)
    })
    .catch( error => {
      if (error) {
        if (error.response) {
          if (error.response.status === 401) {
            helpers.re_login()
            history.push("/login")
          }
        }
      }
      // console.log(error);
      // console.log(error.response);
    })
    // setTimeout(() => {
    //   setVisible(false)
    //   console.log('clearInterval');
    //   clearInterval(id)
    // }, 30000)
  }

  function search_data() {
    let url = `api/v1/customer?page=${page_limit.page}&limit=${
      page_limit.page_Size
    }&order_by=desc`
    const check_all_customer = api.SEARCH_ALL(token, url)
    axios(check_all_customer)
      .then(res => {
        setPage_limit({
          ...page_limit,
          page: page_limit.page,
          limit: res.data.total_data,
        })
        setData_list(res.data.data)
      })
      .catch(error => {
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  function changePagination(page, pageSize) {
    let url = `api/v1/customer?page=${page}&limit=${pageSize}&order_by=desc`
    const check_all_customer = api.SEARCH_ALL(token, url)
    axios(check_all_customer)
      .then(res => {
        setPage_limit({
          ...page_limit,
          page: page,
          page_Size: pageSize,
          limit: res.data.total_data,
        })
        setData_list(res.data.data)
      })
      .catch(error => {
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  return (
    <div>
      <Form
        name="Search_dealer_info"
        initialValues={{
          remember: false,
        }}
        onFinish={onFinish}
      >
        <Row>
          <Col sm={12} md={4} lg={4} xl={4}>
            <Form.Item
              name="search"
              rules={[
                {
                  required: false,
                  message: "Please input name,department",
                },
              ]}
            >
              <Input
                placeholder={t("Search")}
                onChange={e => props.setSearch_customer_data(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={4} lg={4} xl={4}>
            <Button
              type="primary"
              className="button-search-employee"
              htmlType="submit"
            >
              {t("Search customer")}
            </Button>
          </Col>
          <Col sm={12} md={4} lg={4} xl={4}>
            {
              permi_t_write === true && (
                <Button
                type="primary"
                className="btn-import-all-customer"
                onClick={() => import_all_customer_from_distar()}
              >
                {t("Import All customer")}
              </Button>
              )
            }
          </Col>
        </Row>
      </Form>

      <Modal
      centered
      open={visible}
      footer={null}
      onOk={() => setVisible(true)}
      onCancel={() => setVisible(true)}
      closable={false}
      width={1000}
    >
      <div style={{ overflowY:'scroll', overflowX:'hidden', height:'500px' }}>
      <Spin indicator={antIcon} style={{ display:'block' , margin:'0 auto 20px' }} />
          <Table_Loader_All 
            page_limit={page_limit} 
            data_list={data_list} 
            setSearch_data_customer={setSearch_data_customer} 
            search_data={search_data} 
            changePagination={changePagination}
          />
      </div>
    </Modal>
    </div>
  )
}
