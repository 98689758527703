import React,{ useEffect } from 'react'
import { Row } from "reactstrap"
import Col_Device from './Components/Col_Device'
import { useTranslation } from "react-i18next";

export default function Detail_Found_My_Customer(props) {
    const { t } = useTranslation();

    useEffect(() => {
        props.setCustomer_data({
            cus_id : props.my_customer.cus_id,
            found_my_db : true
        })
        return () => {
            
        }
    }, [])

    return (
        <div className="border-card-info" style={{ marginBottom:'40px' }}>
        <p className="text-header-information"> {t("Customer information")} </p>
            <Row>
                <Col_Device 
                text_header={t("Customer name")}
                data={props.my_customer.cus_name}
                />
                <Col_Device 
                text_header={t("Customer TAX ID")}
                data={props.my_customer.cus_taxid}
                />
                <Col_Device 
                text_header={t("Customer code")}
                data={props.my_customer.cus_code}
                />
                <Col_Device 
                text_header={t("Customer credit")}
                data={props.my_customer.cus_credit}
                />
                <Col_Device 
                text_header={t("Customer number")}
                data={props.my_customer.cus_no}
                />
                <Col_Device 
                text_header={t("Customer phone")}
                data={props.my_customer.cus_phone}
                />
                <Col_Device 
                text_header={t("Customer email")}
                data={props.my_customer.cus_email}
                />
                <Col_Device 
                text_header={t("Customer fax")}
                data={props.my_customer.cus_fax}
                />
                <Col_Device 
                text_header={t("Customer remark")}
                data={props.my_customer.cus_remark}
                />
            </Row>
        </div>
    )
}
