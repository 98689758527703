import React, { useState, useEffect } from "react"
import * as api from "../../../service/Api_Scheduler"
import axios from "axios"
import { Table, notification, Tooltip, Modal, Button, Form } from "antd"
import { RiDeleteBin6Line } from "react-icons/ri"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Row, Col } from "reactstrap"
import "./Table_style.css"
import Select_data from "./components/Select_data"
import Input_comp from "./components/Input_comp"
import no_permi from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png"
import { useHistory } from "react-router-dom"
import helpers from "../../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const { confirm } = Modal

export default function Table_Scheduler() {
  const token = localStorage.getItem("token")
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const history = useHistory()
  const [scheduler_data, setScheduler_data] = useState([])
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [disable, setDisable] = useState(true)
  const [new_noti, setNew_noti] = useState({
    trig_type: null,
    trig_condition: null,
    trig_hour: null,
    trig_minute: null,
  })
  const { permissionAllService } = useSelector(({ Distar }) => Distar)
  const permi = localStorage.getItem("permi")
  const [permi_t_write, setPermi_t_write] = useState(false)
  const [permi_t_delete, setPermi_t_delete] = useState(false)

  useEffect(() => {
    if (permissionAllService) {
      if (permissionAllService.system_setting) {
        setPermi_t_write(permissionAllService.system_setting.write)
        setPermi_t_delete(permissionAllService.system_setting.delete)
      }
    }
    return () => {}
  }, [permissionAllService])

  useEffect(() => {
    list_scheduler()
    return () => {}
  }, [])

  function list_scheduler() {
    setLoading(true)
    const list_data = api.LIST_SCHEDULER_ALL(token)
    axios(list_data)
      .then(res => {
        setLoading(false)
        setScheduler_data(res.data.data)
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  function remove_data(id) {
    if (permi_t_delete === true) {
      return confirm({
        title: t("Confirm remove notification"),
        icon: <ExclamationCircleOutlined />,
        centered: true,
        okText: t("Confirm"),
        okType: "danger",
        cancelText: t("no"),
        onOk() {
          setLoading(true)
          const delete_data = api.DELETE_NOTIFY(token, id)
          axios(delete_data)
            .then(res => {
              setLoading(false)
              openNotificationWithIcon("error", t("Remove notification error"))
            })
            .catch(error => {
              setLoading(false)
              if (error) {
                if (error.response) {
                  if (error.response.status === 401) {
                    helpers.re_login()
                    history.push("/login")
                  } else if (error.response.status === 404) {
                    openNotificationWithIcon(
                      "success",
                      t("Remove notification success")
                    )
                    list_scheduler()
                  } else {
                    openNotificationWithIcon(
                      "error",
                      t("Remove notification error")
                    )
                  }
                }
              }
            })
        },
        onCancel() {},
      })
    } else if (permi_t_delete === false) {
      return Modal.error({
        title: t("no permission delete module"),
        centered: true,
      })
    }
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  const columns = [
    {
      title: t("Type"),
      dataIndex: "trig_type",
      key: "trig_type",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Hour ( 24hr )"),
      dataIndex: "trig_hour",
      key: "trig_hour",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Minute"),
      dataIndex: "trig_minute",
      key: "trig_minute",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Detail"),
      dataIndex: "trig_details",
      key: "trig_details",
      render: text => <p>{text}</p>,
    },
    {
      title: "",
      dataIndex: "trig_id",
      key: "trig_id",
      render: text => (
        <Tooltip title={t("Remove notification")}>
          <a>
            <RiDeleteBin6Line
              style={{ color: "red" }}
              size={20}
              onClick={() => remove_data(text)}
            />
          </a>
        </Tooltip>
      ),
    },
  ]

  const onFinish = values => {
    return confirm({
      title: t("Confirm create new notification"),
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: t("Confirm"),
      cancelText: t("no"),
      onOk() {
        if (new_noti.trig_type === "email") {
          let data_trig_email = {
            ...new_noti,
            trig_details: "this job call email in condition to send email",
            trig_function_do: "job_con_exp_email",
            trig_job_id: "con_exp_email",
            trig_name: "contract_expired_email",
            trig_active: 1,
          }
          Create_New_Notu(data_trig_email)
        } else {
          let data_trig_line = {
            ...new_noti,
            trig_details:
              "this job call lineservice in condition to send lineFlex",
            trig_function_do: "job_con_exp_line",
            trig_job_id: "con_exp_line",
            trig_name: "contract_expired_line",
            trig_active: 1,
          }
          Create_New_Notu(data_trig_line)
        }
      },
      onCancel() {},
    })
  }

  function Create_New_Notu(data) {
    setLoading(true)
    const create_car_color = api.CREATE_NEW_NOTI(token, data)
    axios(create_car_color)
      .then(res => {
        openNotificationWithIcon(
          "success",
          t("Create new notification success")
        )
        setLoading(false)
        list_scheduler()
        cancel_create_Form()
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            } else {
              openNotificationWithIcon(
                "error",
                t("Create new notification error")
              )
            }
          }
        }
      })
  }

  const cancel_create_Form = () => {
    form.resetFields()
    setVisible(false)
    setNew_noti({
      ...new_noti,
      trig_type: null,
      trig_condition: null,
      trig_hour: null,
      trig_minute: null,
    })
  }

  return (
    <div>
      <Row>
        <Col sm={12} md={6} lg={6} xl={6}>
          <p style={{ fontSize: "20px" }}>
            {t("Notification when contract nearly expired")}
          </p>
        </Col>
        {permi_t_write === true && (
          <Col sm={12} md={6} lg={6} xl={6}>
            <Button
              type="primary"
              className="btn-create-new-noti"
              onClick={() => setVisible(true)}
            >
              {t("Create new notification")}
            </Button>
          </Col>
        )}
        {permi_t_write === false && (
          <p style={{ textAlign: "right", fontSize: "20px", color: "red" }}>
            {t("no permission write module")}
          </p>
        )}
      </Row>

      <Table
        columns={columns}
        dataSource={scheduler_data}
        pagination={false}
        loading={loading}
        rowKey="trig_id"
        scroll={{ x: 800 }}
      />

      <Modal
        footer={false}
        centered
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={800}
      >
        <Form form={form} name="new-noti" onFinish={onFinish}>
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <Select_data
                new_noti={new_noti}
                setNew_noti={setNew_noti}
                setDisable={setDisable}
              />
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
              <Input_comp
                max={null}
                placeholder="contract expired (days) condition"
                header="Contract expired (days) condition"
                headState={setNew_noti}
                nameState={new_noti}
                keyState="trig_condition"
                disabled={disable}
              />
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
              <Input_comp
                max={24}
                placeholder="hour"
                header="Hour ( 24 )"
                headState={setNew_noti}
                nameState={new_noti}
                keyState="trig_hour"
                disabled={disable}
              />
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
              <Input_comp
                max={60}
                placeholder="minute"
                header="Minute"
                headState={setNew_noti}
                nameState={new_noti}
                keyState="trig_minute"
                disabled={disable}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <div className="div_create_new_car">
                <Button
                  className="btn_cancel_create_new_car"
                  onClick={() => cancel_create_Form()}
                >
                  {t("Cancel")}
                </Button>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
              <div className="div_create_new_car">
                <Button
                  className="btn_submit_create_new_car"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  {t("Create")}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  )
}
