import React, { useEffect } from "react"
import { Row, Col } from "reactstrap"
import { Table, Tag, Badge } from "antd"
import Render_Image_Data from "../../../Employee/Table/Render_Image_Data"
import Render_Image from "../../../New_Customer/Devices/Render_Image"
import { useTranslation } from "react-i18next";

export default function Activity_Log(props) {
  const { t } = useTranslation();

  function render_time(date_time) {
    const date_data = new Date(date_time)
    let expiryDate = new Date(
      new Date(date_data).setHours(date_data.getHours() - 7)
    )
    const date_all = new Date(expiryDate)
    return date_all.toLocaleTimeString("en-GB")
  }

  const columns = [
    {
      title: t("Created on date"),
      dataIndex: "created_on",
      key: "created_on",
      render: text => <p>{cutTimeZone(text)}</p>,
    },
    {
      title: t("Created on time"),
      dataIndex: "created_on",
      key: "created_on",
      render: text => <p>{render_time(text)}</p>,
    },
    {
      title: t("Action"),
      dataIndex: "",
      key: "",
      render: text => (
        <p>{render_badge(text.ser_log_icon, text.ser_log_name)}</p>
      ),
    },
    {
      title: t("Sub image"),
      dataIndex: "ser_log_sub_image",
      key: "ser_log_sub_image",
      render: text => <div>{render_sub_image(text)}</div>,
    },
    {
      title: t("Action detail"),
      dataIndex: "ser_log_detail",
      key: "ser_log_detail",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Action role"),
      dataIndex: "action_role",
      key: "action_role",
      render: text => <p>{text}</p>,
    },
    {
      title: t("User code"),
      dataIndex: "user_data",
      key: "user_data",
      render: text => <p>{check_user_code(text)}</p>,
    },
    {
      title: t("Action by"),
      dataIndex: "user_data",
      key: "user_data",
      render: text => <div>{check_user_image(text)}</div>,
    },
  ]

  function render_badge(ser_log_icon, ser_log_name) {
    switch (ser_log_icon) {
      case "default":
        return <Badge status="default" text={ser_log_name} />
        break
      case "warning":
        return <Badge status="warning" text={ser_log_name} />
        break
      case "processing":
        return <Badge status="processing" text={ser_log_name} />
        break
      case "error":
        return <Badge status="error" text={ser_log_name} />
        break
      case "success":
        return <Badge status="success" text={ser_log_name} />
        break
      default:
        return <Badge status="default" text={ser_log_name} />
        break
    }
  }

  function render_sub_image(image) {
    if (image !== null) return <Render_Image path_image={image} size={50} />
  }

  function check_user_image(data) {
    return <Render_Image_Data path_image={data.avatar} />
  }

  function check_user_code(data) {
    return data.code
  }

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return "N/A"
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  return (
    <div>
      <p className="text-bold"> {t("Activity log")} </p>
      <Table
        columns={columns}
        dataSource={props.activity_all}
        rowKey="ser_log_id"
        scroll={{ x: 800 }}
      />
    </div>
  )
}
