import React, { useState, useEffect } from "react"
import { Button, Form, Modal, notification } from "antd"
import { Row, Col } from "reactstrap"
import Input_Comp from "./components/Input_Comp"
import Select_Search_Comp from "./components/Select_Search_Comp"
import Select_no_search from "./components/Select_no_search"
import Select_branch_comp from "./components/Select_branch_comp"
import axios from "axios"
import * as api from "../../../../service/Api_New_Customer"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { useHistory } from "react-router-dom"
import helpers from "../../../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next";
import Input_Car_Lpn from './components/Input_Car_Lpn'

const { confirm } = Modal

export default function Edit_a_car(props) {
  const token = localStorage.getItem("token")
  const { t } = useTranslation();
  const [form] = Form.useForm()
  const history = useHistory()
  const [branch_name, setBranch_name] = useState(null)
  const [loading, setLoading] = useState(false)
  const [data_car, setData_car] = useState({
    cus_id: props.id,
    cus_branch_id: null,
    car_license_number : null,
    car_lpn_mode : false,
    car_group_id : null,
    car_remark : null
  })
  const [no_lpn_car, setNo_lpn_car] = useState(false)

  function onFinish(value) {
      return confirm({
        title: t("Create new car"),
        icon: <ExclamationCircleOutlined />,
        okText: t("Create"),
        cancelText: t("Cancel"),
        centered: true,
        onOk() {
          setLoading(true)
          const create_new_car = api.CREATE_NEW_CAR(token, data_car)
          axios(create_new_car)
            .then(res => {
              openNotificationWithIcon('success', t("Create new car success"))
              onReset()
              setLoading(false)
              props.setActiveTab_all("1")
              props.search_car()
            })
            .catch(error => {
              setLoading(false)
              // console.log(error.response);
              if(error){
                if(error.response){
                  if (error.response.status === 401) {
                    helpers.re_login()
                    history.push("/login")
                  } else {
                    return Modal.error({
                      title: t("Create new car error"),
                      centered: true,
                    })
                  }
                }
              }
            })
        },
        onCancel() {},
      })
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    });
  };

  const onReset = () => {
    form.resetFields()
    props.setData_search_branch(null)
    setData_car({
      ...data_car,
      cus_id: props.id,
      cus_branch_id: null,
      car_license_number : null,
      car_lpn_mode : false,
      car_group_id : null,
      car_remark : null
    })
    setNo_lpn_car(false)
    setBranch_name(null)
  }

  useEffect(() => {
    if(no_lpn_car === true){
      form.resetFields(["car_license_number"])
    } 
    return () => {
      
    }
  }, [no_lpn_car])
  
  return (
    <Form
      form={form}
      name="create_new_car_in_customer"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
    >
      
      <Row>
        <Col sm={12} md={6} lg={6} xl={6}>
          <Input_Car_Lpn
           no_lpn_car={no_lpn_car}
           setNo_lpn_car={setNo_lpn_car}
           setData_car={setData_car}
           data_car={data_car}
            // header={t("License no")}
            // placeholder={t("License no")}
            // headState={setData_car}
            // nameState={data_car}
            // keyState="car_license_number"
          />
        </Col>

        <Col sm={12} md={6} lg={6} xl={6}>
          <Input_Comp
            header={t("Car name")}
            placeholder={t("Car name")}
            headState={setData_car}
            nameState={data_car}
            keyState="car_name"
          />
        </Col>
        
        <Col sm={12} md={6} lg={6} xl={6}>
          <Select_Search_Comp
            header={t("Province")}
            placeholder={t("Province")}
            headState={setData_car}
            nameState={data_car}
            keyState="car_license_province_id"
          />
        </Col>

        <Col sm={12} md={6} lg={6} xl={6}>
          <Select_no_search
            header={t("Color")}
            placeholder={t("Color")}
            name_api="LIST_CAR_COLOR"
            keyState="car_color_id"
            map_name="car_color_name"
            headState={setData_car}
            nameState={data_car}
          />
        </Col>

        <Col sm={12} md={6} lg={6} xl={6}>
          <Input_Comp
            header={t("Chassis number")}
            placeholder={t("Chassis number")}
            headState={setData_car}
            nameState={data_car}
            keyState="car_chassis_number"
          />
        </Col>

        <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Car group")}
              placeholder={t("Car group")}
              name_api="FIND_ALL_CAR_GROUP"
              keyState="car_group_id"
              map_name="car_group_name"
              headState={setData_car}
              nameState={data_car}
            />
          </Col>

        <Col sm={12} md={6} lg={6} xl={6}>
          <Select_no_search
            header={t("Car type")}
            name_api="LIST_CAR_BODY_TYPE"
            map_name="car_body_type_name"
            keyState="car_body_type_id"
            placeholder={t("Car type")}
            headState={setData_car}
            nameState={data_car}
          />
        </Col>

        <Col sm={12} md={6} lg={6} xl={6}>
          <Select_no_search
            header={t("Brand")}
            name_api="LIST_CAR_BRAND"
            map_name="car_brand_name"
            keyState="car_brand_id"
            placeholder={t("Brand")}
            headState={setData_car}
            nameState={data_car}
          />
        </Col>

        <Col sm={12} md={6} lg={6} xl={6}>
          <Select_no_search
            header={t("Model")}
            name_api="LIST_CAR_MODEL"
            map_name="car_model_name"
            keyState="car_model_id"
            placeholder={t("Model")}
            headState={setData_car}
            nameState={data_car}
          />
        </Col>

        <Col sm={12} md={6} lg={6} xl={6}>
          <Select_no_search
            header={t("Year")}
            name_api="LIST_CAR_YEAR"
            map_name="car_model_year_name"
            keyState="car_model_year_id"
            placeholder={t("Year")}
            headState={setData_car}
            nameState={data_car}
          />
        </Col>

        <Col sm={12} md={6} lg={6} xl={6}>
          <Input_Comp
            header={t("Car remark")}
            placeholder={t("Car remark")}
            headState={setData_car}
            nameState={data_car}
            keyState="car_remark"
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Select_branch_comp
            setData_car={setData_car}
            data_car={data_car}
            setBranch_name={setBranch_name}
            branch_name={branch_name}
            branch_table={props.branch_table}
            setData_search_branch={props.setData_search_branch}
            page_limit_branch={props.page_limit_branch}
            search_data_branch={props.search_data_branch}
            changePagination={props.changePagination}
            loading_branch={props.loading_branch}
          />
        </Col>
      </Row>

      <br />
      <Row>
        <Col sm={12} md={6} lg={6} xl={6}>
          <div className="div_create_new_car">
            <Button
              className="btn_cancel_create_new_car"
              onClick={() => onReset()}
            >
           {t("Cancel")}
            </Button>
          </div>
        </Col>
        <Col sm={12} md={6} lg={6} xl={6}>
          <div className="div_create_new_car">
            <Button
              className="btn_submit_create_new_car"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
            {t("Create")}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}
