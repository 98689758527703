import React, { useState, useEffect } from "react"
import { Container, Card, CardBody } from "reactstrap"
import MetaTags from "react-meta-tags"
import { useHistory } from "react-router-dom"
import { MdKeyboardBackspace } from 'react-icons/md';
import Create_New_Service from './Create_New_Service'
import New_Create_New_Service from './New_Create_New_Service'
import no_permi from '../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png'
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux"

const New_Service = props => {
    const history = useHistory()
    const { t } = useTranslation();
    const permi = localStorage.getItem('permi')
    const [permi_t, setPermi_t] = useState(false)
    const { permissionAllService } = useSelector(({ Distar }) => Distar)

  useEffect(() => {
    if(permissionAllService){
      if(permissionAllService.service){
        setPermi_t(permissionAllService.service.write)
      }
    }
    return () => {
  
    }
  }, [permissionAllService])

  return (
    <React.Fragment>
    <MetaTags>
      <title> {t("New service")} | Distar</title>
    </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}> <a><MdKeyboardBackspace style={{ marginRight:'15px', marginBottom:'3px' }}
          onClick={() =>
            history.push("/service")
          } 
          /></a> {t("New service")} </h3>
          <br />
          <Card>
            <CardBody>
            {
              permi_t === true && (
                <New_Create_New_Service />
              )
            }
            {
              permi_t === false && (
                <div>
                <img src={no_permi} alt="no permission" style={{ display:'block' , margin:'50px auto' }} />
                <p style={{ textAlign: "center", fontSize: "20px" }}>{t("no permission write module")}</p>
                <p style={{ textAlign:'center', fontSize:'20px' }}> {t("please contract administrator")} </p>
                </div>
              )
            }

            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default New_Service