const claim_asset = [
    {
      code: "xd7461",
      history: [
        {
          id: "1",
          date_setup: "01/02/64",
          name_asset: "เครื่องบันทึกข้อมูล DVR",
          sn:'SN001',
          report: "เปิดไม่ติด",
          name_repair: "คุณบุญชู",
        },
        {
          id: "2",
          date_setup: "01/02/65",
          name_asset: "เครื่องบันทึกข้อมูล DVR",
          sn:'SN002',
          report: "ไม่แสดงตำแหน่ง",
          name_repair: "คุณบุญชู",
        },
        {
          id: "3",
          date_setup: "01/02/66",
          name_asset: "เครื่องบันทึกข้อมูล DVR",
          sn:'SN003',
          report: "มีเสียงดัง",
          name_repair: "คุณบุญชู",
        },
        {
          id: "4",
          date_setup: "01/02/67",
          name_asset: "เครื่องบันทึกข้อมูล DVR",
          sn:'SN004',
          report: "เปิดไม่ติด",
          name_repair: "คุณบุญชู",
        },
      ],
    },
    {
      code: "dd4648",
      history: [
          {
            id: "1",
            date_setup: "02/04/60",
            name_asset: "เครื่องบันทึกข้อมูล DVR",
            sn:'SN001',
            report: "เปิดไม่ติด",
            name_repair: "คุณภัทรพล",
          },
          {
            id: "2",
            date_setup: "01/02/65",
            name_asset: "เครื่องบันทึกข้อมูล DVR",
            sn:'SN002',
            report: "เปิดไม่ติด",
            name_repair: "คุณภัทรพล",
          },
          {
            id: "3",
            date_setup: "01/02/66",
            name_asset: "เครื่องบันทึกข้อมูล DVR",
            sn:'SN003',
            report: "มีเสียงดัง",
            name_repair: "คุณภัทรพล",
          },
          {
            id: "4",
            date_setup: "14/02/67",
            name_asset: "เครื่องบันทึกข้อมูล DVR",
            sn:'SN004',
            report: "เปิดไม่ติด",
            name_repair: "คุณภัทรพล",
          },
        ],
    },
  ]
  
  export default claim_asset
  