import React, { useState, useEffect, useCallback } from "react"
import { Row, Col } from "reactstrap"
import { Tabs, Button, Modal, notification } from "antd"
import * as api from "../../../../service/service_operator/Api_Edit_Service"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import axios from "axios"
import RenderForm_hast_form from './renderForm_hast_form'

const { TabPane } = Tabs
const { confirm } = Modal

export default function CreateNewForm(props) {
  const token = localStorage.getItem("token")
  const [stepField, setStepField] = useState([])
  const [priority, setPriority] = useState([])
  const [before_test, setBefore_test] = useState([])
  const [task_hast_form, setTask_hast_form] = useState([])

  useEffect(() => {
    list_priority()
    Check_service_form()
    return () => {
      setPriority([])
      setStepField([])
      setBefore_test([])
      setTask_hast_form([])
    }
  }, [])


  function list_priority() {
    const list_priority_info = api.LIST_PRIORITY(token)
    axios(list_priority_info)
      .then(res => {
        setPriority(res.data.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  function compareFirst(a, b) {
    if (new Date(a.task_stepper) > new Date(b.task_stepper)) {
      return 1
    }
    if (new Date(a.task_stepper) < new Date(b.task_stepper)) {
      return -1
    }
    return 0
  }

  function Check_service_form() {
    const create_task = api.CHECK_SERVICE_HAST_TASK(token, props.ser_id)
    axios(create_task)
      .then(res => {
        const sortDetailForm = res.data.data.sort(compareFirst)
        setBefore_test(sortDetailForm)
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    mapping_data_array()

    return () => {}
  }, [before_test])

  function mapping_data_array() {
    let stepField = []
    let number_page = 0
    before_test.map(data => {
      if (number_page <= data.task_stepper - 1) {
        stepField.push({
          fields: [],
        })
        number_page++
      }
    })
    before_test.map(data => {
      stepField[data.task_stepper - 1].fields.push({
          ...data,
          value : null
      })
    })
    setTask_hast_form(stepField)
  }

  return (
    <div>
      {task_hast_form.length !== 0 && (
        <Row>
          <Col>
            <RenderForm_hast_form
              priority={priority}
              stepField={task_hast_form}
            />
          </Col>
        </Row>
            )}
    </div>
  )
}
