var Ip = require('../service/Ip')

export const LIST_SCHEDULER_ALL = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/trig`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const DELETE_NOTIFY = (token , id) => {
    return {
      method: 'delete',
      url: `${Ip.getIP()}api/v1/trig/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CREATE_NEW_NOTI = (token , data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/trig`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };
  