import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import Expand from "react-expand-animated"
import { FiChevronDown, FiChevronRight } from "react-icons/fi"
import { Button, Modal, notification } from "antd"
import axios from "axios"
import { Row, Col } from "reactstrap"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next"
import { Switch } from "antd"
import en from "./image/en.png"
import th from "./image/th.png"

const { confirm } = Modal

export default function Delete_Service(props) {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const token = localStorage.getItem("token")
  const [open, setOpen] = useState(false)
  const [icon, setIcon] = useState(
    <FiChevronRight size={20} style={{ marginBottom: "5px" }} />
  )
  const check_la = localStorage.getItem('I18N_LANGUAGE')

  const setToggle = () => {
    if (open === false) {
      setOpen(true)
      setIcon(<FiChevronDown size={25} style={{ marginBottom: "5px" }} />)
    } else {
      setOpen(false)
      setIcon(<FiChevronRight size={25} style={{ marginBottom: "5px" }} />)
    }
  }

  const changeLanguage = lng => {
    i18n.changeLanguage(lng)
    localStorage.setItem("I18N_LANGUAGE", lng)
  }

  function setGender(event) {
    if (event.target.value === "en") {
      changeLanguage("en")
    } else {
      changeLanguage("th")
    }
  }

  return (
    <Row>
      <Col sm={12} md={6} lg={4} xl={4} onClick={() => setToggle()}>
        <a>
          {" "}
          <p style={{ fontSize: "20px" }}>
            {" "}
            {icon} {t("language")}{" "}
          </p>{" "}
        </a>
      </Col>
      <Col sm={12} md={6} lg={8} xl={8}>
        {i18n.language === "en" && (
          <p style={{ color: "rgb(108, 117, 125)" }}> English </p>
        )}
        {i18n.language === "th" && (
          <p style={{ color: "rgb(108, 117, 125)" }}> ภาษาไทย </p>
        )}

        <Expand open={open} duration={500}>
        {
            check_la === 'en' && (
                <div onChange={setGender}>
                <input
                  type="radio"
                  value="en"
                  name="select_branch"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginTop: "15px",
                    marginRight: "10px",
                  }}
                  defaultChecked
                />
                <img
                  src={en}
                  alt="en"
                  style={{ width: "50px", marginRight: "20px" }}
                />
    
                <input
                  type="radio"
                  value="th"
                  name="select_branch"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginTop: "15px",
                    marginLeft: "20px",
                  }}
                  
                />
                <img
                  src={th}
                  alt="th"
                  style={{ width: "50px", marginLeft: "10px" }}
                />
              </div>
            )
          }
          {
            check_la === 'th' && (
                <div onChange={setGender}>
                <input
                  type="radio"
                  value="en"
                  name="select_branch"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginTop: "15px",
                    marginRight: "10px",
                  }}
                  
                />
                <img
                  src={en}
                  alt="en"
                  style={{ width: "50px", marginRight: "20px" }}
                />
    
                <input
                  type="radio"
                  value="th"
                  name="select_branch"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginTop: "15px",
                    marginLeft: "20px",
                  }}
                  defaultChecked
                />
                <img
                  src={th}
                  alt="th"
                  style={{ width: "50px", marginLeft: "10px" }}
                />
              </div>
            )
          }
         
        </Expand>
      </Col>
    </Row>
  )
}
