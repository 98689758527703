import React, { useState, useEffect } from "react"
import { Container, Card, CardBody } from "reactstrap"
import MetaTags from "react-meta-tags"
import { MdKeyboardBackspace } from "react-icons/md"
import { useHistory, useParams } from "react-router-dom"
import * as api from "../../service/Api_Edit_Operator"
import axios from "axios"
import { Tabs } from "antd"
import Detail from "./Detail"
import Edit from "./Edit"
import Location from "./Location"
import Setting from "./Setting"
import no_permi from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png"
import helpers from "../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"
import ChangePasswordByAdmin from "../components/Profile/ChangePasswordByAdmin"
import { useSelector } from "react-redux"
import Button_ResetPassword from "../components/admin/Button_ResetPassword"

const { TabPane } = Tabs

export default function Edit_contract(props) {
  const { t } = useTranslation()
  const history = useHistory()
  const [activeTab_all, setActiveTab_all] = useState("1")
  const token = localStorage.getItem("token")
  const [detail_Operator, setDetail_Operator] = useState({})
  const [location_data, setLocation_data] = useState([])
  const [image_From_db, setImage_From_db] = useState(null)
  const [loading, setLoading] = useState(true)
  const { isRole, isAdminRoot, permissionAllService } = useSelector(
    ({ Distar }) => Distar
  )
  const permi = localStorage.getItem("permi")
  const [permi_t_read, setPermi_t_read] = useState(false)
  const [permi_t_edit, setPermi_t_edit] = useState(false)
  const [permi_t_delete, setPermi_t_delete] = useState(false)

  let { ope_id } = useParams()

  useEffect(() => {
    if (permissionAllService) {
      if (permissionAllService.operator) {
        setPermi_t_read(permissionAllService.operator.read)
        setPermi_t_edit(permissionAllService.operator.edit)
        setPermi_t_delete(permissionAllService.operator.delete)
      }
    }
    return () => {}
  }, [permissionAllService])

  // console.log(ope_id);

  useEffect(() => {
    return () => {
      setImage_From_db(null)
    }
  }, [])

  useEffect(() => {
    check_operator_detail()
    Check_location_operator()
    return () => {
      setDetail_Operator({})
    }
  }, [ope_id])

  function check_operator_detail() {
    const check_detail = api.LIST_DETAIL_OPERATOR(token, parseInt(ope_id))
    axios(check_detail)
      .then(res => {
        if (res.data.data[0] === undefined) {
          history.push("/operator")
        } else {
          setDetail_Operator(res.data.data[0])
          get_image_avatar(res.data.data[0].ope_avatar)
        }
      })
      .catch(error => {
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  function get_image_avatar(cus_avatar) {
    if (cus_avatar !== "None") {
      const get_data_image = api.GET_IMAGE(token, cus_avatar)
      axios(get_data_image)
        .then(res => {
          const blob = new Blob([res.data], {
            type: "image/jpeg",
          })
          var objectURL = URL.createObjectURL(blob)
          setImage_From_db(objectURL)
        })
        .catch(error => {
          // console.log(error.response)
        })
    }
  }

  function Check_location_operator() {
    const check_location = api.CHECK_LOCATION_OPERATOR(token, parseInt(ope_id))
    axios(check_location)
      .then(res => {
        setLoading(false)
        setLocation_data(res.data.data)
      })
      .catch(error => {
        // console.log(error)
      })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Edit operator")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/operator")}
              />
            </a>{" "}
            {t("Edit operator")}
          </h3>
          {permi_t_read === true && (
            <Card>
              <CardBody>
                <Tabs defaultActiveKey="1">
                  <TabPane tab={t("Overview")} key="1">
                    <Detail detail_Operator={detail_Operator} />
                  </TabPane>
                  {permi_t_edit === true && (
                    <TabPane tab={t("Edit")} key="2">
                      <Edit
                        detail_Operator={detail_Operator}
                        ope_id={parseInt(ope_id)}
                        check_operator_detail={check_operator_detail}
                        image_From_db={image_From_db}
                      />
                    </TabPane>
                  )}
                  {permi_t_edit === true && (
                    <TabPane tab={t("Location")} key="3">
                      <Location
                        loading={loading}
                        location_data={location_data}
                        Check_location_operator={Check_location_operator}
                        ope_id={parseInt(ope_id)}
                      />
                    </TabPane>
                  )}
                  {permi_t_delete === true && (
                    <TabPane tab={t("Setting")} key="4">
                      {isAdminRoot === true && isRole === "administrator" && (
                        <Button_ResetPassword
                          roleReset="operator"
                          idReset={parseInt(ope_id)}
                        />
                      )}
                      {isRole === "administrator" && (
                        <ChangePasswordByAdmin
                          idIsChange={parseInt(ope_id)}
                          roleIsChange="operator"
                        />
                      )}
                      <Setting ope_id={parseInt(ope_id)} />
                    </TabPane>
                  )}
                </Tabs>
              </CardBody>
            </Card>
          )}
          {permi_t_read === false && (
            <div>
              <img
                src={no_permi}
                alt="no permission"
                style={{ display: "block", margin: "50px auto" }}
              />
              <p style={{ textAlign: "center", fontSize: "20px" }}>
                {t("no permission read module")}
              </p>
              <p style={{ textAlign: "center", fontSize: "20px" }}>
                {" "}
                {t("please contract administrator")}{" "}
              </p>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
