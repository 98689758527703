import React, { useState, useCallback, useRef, useEffect } from "react"
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api"
import { AutoComplete } from "antd"
import Geocode from "react-geocode"

import ping from "../../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/ping.png"

const libraries = ["places"]
const mapContainerStyle = {
  height: "250px",
}

const options = {
  // styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
}

export default function Map_Service(props) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_APP,
    libraries,
  })
  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_APP)
  Geocode.setLanguage("th")
  const [map_marker, setMap_marker] = useState(null)
  const [zoom_gg_map, setZoom_gg_map] = useState(6)
  const [center, setCenter] = useState({
    lat: 13.2753119,
    lng: 101.4843104,
  })

  useEffect(() => {
    setZoom_gg_map(16)
    setMap_marker({
      lat: parseFloat(props.ser_lat),
      lng: parseFloat(props.ser_long),
      time: new Date(),
    })
    setCenter({
        lat: parseFloat(props.ser_lat),
        lng: parseFloat(props.ser_long),
    })
    return () => {
      setMap_marker(null)
    }
  }, [props.ser_lat , props.ser_long])


  const onMapClick = e => {}

  const mapRef = useRef()
  const onMapLoad = useCallback(map => {
    mapRef.current = map
  }, [])

  if (loadError) return "Error"
  if (!isLoaded) return "Loading..."

  return (
    <div className="border-card-info" >
      <GoogleMap
        id="map"
        mapContainerStyle={mapContainerStyle}
        zoom={zoom_gg_map}
        center={center}
        options={options}
        // onClick={onMapClick}
        onLoad={onMapLoad}
        defaultZoom={6}
        defaultCenter={{ lat: 13.2753119, lng: 101.4843104 }}
      >
      <div>
      {map_marker && (
        <Marker
          key={`${map_marker.lat}-${map_marker.lng}`}
          position={{ lat: map_marker.lat, lng: map_marker.lng }}
          icon={ping}
          // icon={{
          //   url: img_ping,
          //   origin: new window.google.maps.Point(0, 0),
          //   anchor: new window.google.maps.Point(0, 0),
          //   // scaledSize: new window.google.maps.Size(30, 30),
          // }}
        >
{/*        <div>
        {showingInfoWindow === true && (
          <InfoWindow
            position={{
              lat: map_marker.lat,
              lng: map_marker.lng,
            }}
            // options={{ pixelOffset: new window.google.maps.Point(15, 15) }}
          >
            <div>
              {devtype_title !== null && (
                <div>
                  <p>Device name : {devtype_title.devtype_title}</p>
                  <p>SN : {devtype_title.gid}</p>
                </div>
              )}
              <p>{address_car}</p>
            </div>
          </InfoWindow>
        )}
              </div> **/}
        </Marker>
      )}
      </div>
      </GoogleMap>
    </div>
  )
}
