import React from "react"
import { useTranslation } from "react-i18next"
import Card_Detail from "../../New_Service_Has_Stock_Id/components/Card_Detail"
import Map_Service from "./Map_Service"
import Detail_Repair from "./Detail_Repair"
import Car_Information from '../../Status_Service_Public/Car_Information'

import product_info from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/product_info.png"
import customer_info from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/customer_info.png"

export default function Detail_Request_Repair(props) {
  const { t } = useTranslation()

  const customer_data = [
    {
      header1: t("Customer name"),
      dataContract1: checkData(props.detail_repair.cus_name),
      header2: t("Customer number"),
      dataContract2: checkData(props.detail_repair.cus_no),
    },
    {
      header1: t("Customer code"),
      dataContract1: checkData(props.detail_repair.cus_code),
      header2: t("Customer credit"),
      dataContract2: checkData(props.detail_repair.cus_credit),
    },
    {
      header1: t("Customer TAX ID"),
      dataContract1: checkData(props.detail_repair.cus_taxid),
      header2: t("Customer phone"),
      dataContract2: checkData(props.detail_repair.cus_phone),
    },
    {
      header1: t("Customer email"),
      dataContract1: checkData(props.detail_repair.cus_email),
      header2: t("Customer fax"),
      dataContract2: checkData(props.detail_repair.cus_fax),
    },
    {
      header1: t("Customer address"),
      dataContract1: checkData(props.detail_repair.cus_address),
      header2: t("Customer remark"),
      dataContract2: checkData(props.detail_repair.cus_remark),
    },
  ]

  const data_product = [
    {
      header1: t("Product number"),
      dataContract1: checkData(props.detail_repair.pro_no),
      header2: t("Product group name"),
      dataContract2: checkData(props.detail_repair.pro_group_name),
    },
    {
      header1: t("Product model name"),
      dataContract1: checkData(props.detail_repair.pro_model_name),
      header2: t("Product serial number"),
      dataContract2: checkData(props.detail_repair.pro_serial_number),
    },
    {
      header1: t("Stock asset number"),
      dataContract1: checkData(props.detail_repair.sto_asset_number),
      header2: t("Stock date in"),
      dataContract2: cutTimeZone(props.detail_repair.sto_date_in),
    },
    {
      header1: t("Stock date install"),
      dataContract1: cutTimeZone(props.detail_repair.sto_date_install),
      header2: t("Stock remark"),
      dataContract2: checkData(props.detail_repair.sto_remark),
    },
    {
      header1: t("Product code"),
      dataContract1: checkData(props.detail_repair.pro_code),
      header2: t("Product model code"),
      dataContract2: checkData(props.detail_repair.pro_model_code),
    },
    {
      header1: t("Product remark"),
      dataContract1: checkData(props.detail_repair.pro_remark),
      header2: t("Product model factory"),
      dataContract2: checkData(props.detail_repair.pro_model_factory),
    },
  ]

  function checkData(data) {
    if (data) {
      return data
    } else {
      return "N/A"
    }
  }

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return "N/A"
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }
  // console.log(props.detail_repair)
  return (
    <div>
      <p className="text-bold"> {t("Required part")} </p>
      <Card_Detail
        image_header={product_info}
        alt_image_header="product_info"
        text_header={t("Product information")}
        title_data={checkData(props.detail_repair.pro_model_name)}
        title_data_tow={`${checkData(props.detail_repair.pro_serial_number)}`}
        path_image={props.detail_repair.pro_model_img}
        data_map={data_product}
        type_avatar="product"
        use_table={false}
        data_table={null}
      />
      <br />

      <p className="text-bold"> {t("Customer information")} </p>
      <Card_Detail
        image_header={customer_info}
        alt_image_header="customer_info"
        text_header={t("Customer information")}
        title_data={checkData(props.detail_repair.cus_name)}
        title_data_tow={null}
        path_image={props.detail_repair.cus_avatar}
        data_map={customer_data}
        type_avatar="user"
        use_table={false}
        data_table={null}
      />

      <br />
      <Detail_Repair detail_repair={props.detail_repair} />

      <br />

      <Map_Service detail_repair={props.detail_repair} />

      <br />
      <Car_Information data_all={props.detail_repair} />
    </div>
  )
}
