import React from 'react'
import { Spin } from 'antd';
import Step_Status_Car_Import from './Step_Status_Car_Import'
import Overview_Car from './Overview_Car'
import Map_Car from './Map_Car'

export default function Overview_Confirm_Create(props) {
    // console.log(props.gps_car);

    return (
        <div>
        <p style={{ fontWeight:'bold', fontSize:'30px', textAlign:'center' }}>{props.lpn_car}</p>

        <Step_Status_Car_Import />

        {
            props.loading_icon === true &&(
            <Spin size="large" style={{ display:'block', margin:'auto', marginTop:'50px', marginBottom:'50px' }} />
        )
            }

        {props.gps_car !== null &&(
            <Map_Car 
            type_car={props.type_car}
            car_infomation={props.car_infomation}
            location_car={props.location_car}
            />
        )}

        {props.car_detail !== null &&(
            <Overview_Car 
                found_my_dealer={props.found_my_dealer}
                car_detail={props.car_detail}
                car_infomation={props.car_infomation}
            />
        )}
        
        </div>
    )
}
