import React,{ useEffect, useState } from 'react'
import * as api from '../../../service/Api_Employee'
import { FaRegUser } from "react-icons/fa"
import axios from 'axios'
import { Avatar } from "antd"

export default function Render_Image_Data(props) {
    const [img, setImg] = useState(null)
    const token =localStorage.getItem('token')

    useEffect(() => {
        if(props.path_image !== null && props.path_image !== undefined && props.path_image !== 'None'){
            get_image(props.path_image)
        }
        return () => {
            setImg(null)
        }
    }, [props.path_image])

    async function get_image(path_image) {
        const get_data_image = api.GET_IMAGE(token, path_image)
        await axios(get_data_image)
        .then( res => {
            const blob = new Blob([res.data], {
                type: 'image/jpeg',
            });
            var objectURL = URL.createObjectURL(blob)
            setImg(objectURL)
        })
        .catch( error => {
            // console.log(error.response);
        })
      }

    return (
        <div>
        {
            img === null && (
                <Avatar size={props.size ? props.size : 80} icon={<FaRegUser style={{ marginBottom:'10px' }} />}   style={{ display: "block", margin: "auto" }} />
            )
        }
        {
            img !== null && (
                <Avatar size={props.size ? props.size : 80} src={img}   style={{ display: "block", margin: "auto" }} />
            )
        }
        </div>
    )
}
