import React from "react"
import { Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next"

export default function Col_Day_Hour_Minute({ day, hour, minute }) {
  const { t } = useTranslation()
  const text_header = {
    fontWeight: "bold",
    fontSize: "15px",
  }
  const text_data = {
    marginRight: "10px",
    fontWeight: "bold",
    fontSize: "15px",
  }
  return (
    <Row>
      <Col sm={6} md={2} lg={2} xl={2}>
        <p style={text_header}> {t("Processing time")} </p>
      </Col>
      <Col sm={6} md={4} lg={4} xl={4}>
        <p style={{ fontSize: "15px" }}>
          {" "}
          <span className="tet">:</span> <span className="tetTwo">-</span>{" "}
          <span style={text_data}>{day}</span> {t("day")}
        </p>
        <p style={{ fontSize: "15px" }}>
          {" "}
          <span className="tet">:</span> <span className="tetTwo">-</span>{" "}
          <span style={text_data}>{hour}</span> {t("hour")}
        </p>
        <p style={{ fontSize: "15px" }}>
          {" "}
          <span className="tet">:</span> <span className="tetTwo">-</span>{" "}
          <span style={text_data}>{minute}</span> {t("minute")}
        </p>
      </Col>
    </Row>
  )
}
