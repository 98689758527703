import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Pagination, Table, Button, Modal } from "antd"
import axios from "axios"
import * as api from "../../service/Api_Create_new_dealer"
import Search_data from "./Search_data"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { FcDataSheet } from "react-icons/fc"
import helpers from "../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next";

const { confirm } = Modal

export default function Table_Dealer_From_db(props) {
  const history = useHistory()
  const { t } = useTranslation();
  const token = localStorage.getItem("token")
  const [search_dealer, setSearch_dealer] = useState(null)
  const [data_list, setData_list] = useState([])
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })
  const [dealer_select, setDealer_select] = useState([])
  const [loading, setLoading] = useState(false)

  const permi = localStorage.getItem("permi")
  const [permi_t, setPermi_t] = useState(false)

  useEffect(() => {
    if (permi) {
      if (JSON.parse(permi).import) {
        setPermi_t(
          JSON.parse(permi).import.write
            ? JSON.parse(permi).import.write
            : false
        )
      }
    }
    return () => {
      // setPermi_t(false)
    }
  }, [permi])

  useEffect(() => {
    if (props.role_dealer_select) {
      search_dealer_all()
    }
    return () => {
      setData_list([])
      setDealer_select([])
    }
  }, [props.role_dealer_select])

  function search_dealer_all() {
    setLoading(true)
    if (search_dealer) {
      const data_dealer_data = api.LIST_DEALER_FROM_DB_DATA(
        token,
        props.role_dealer_select,
        1,
        page_limit.page_Size,
        search_dealer
      )
      axios(data_dealer_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          // console.log(error.response)
          if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }

        })
    } else {
      const data_dealer_no_data = api.LIST_DEALER_FROM_DB_NO_DATA(
        token,
        props.role_dealer_select,
        1,
        page_limit.page_Size
      )
      axios(data_dealer_no_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          // console.log(error.response)
          if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }

        })
    }
  }

  function changePagination(page, pageSize) {
    setLoading(true)
    if (search_dealer) {
      const data_dealer_data = api.LIST_DEALER_FROM_DB_DATA(
        token,
        props.role_dealer_select,
        page,
        pageSize,
        search_dealer
      )
      axios(data_dealer_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }

          // console.log(error)
        })
    } else {
      const data_dealer_no_data = api.LIST_DEALER_FROM_DB_NO_DATA(
        token,
        props.role_dealer_select,
        page,
        pageSize
      )
      axios(data_dealer_no_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }

          // console.log(error)
        })
    }
  }

  const columns = [
    {
      title: t("Dealer name"),
      dataIndex: "name",
      key: "name",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Dealer nickname"),
      dataIndex: "nickname",
      key: "nickname",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Dealer phone"),
      dataIndex: "phone",
      key: "phone",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Dealer email"),
      dataIndex: "email",
      key: "email",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Dealer remark"),
      dataIndex: "remark",
      key: "remark",
      render: text => <p>{text}</p>,
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setDealer_select(selectedRows)
    },
    getCheckboxProps: record => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  }

  function select_Dealer() {
    // /select-dealer
    if (dealer_select.length === 0) {
      return Modal.info({
        title: t("Please select dealer"),
        centered: true,
        onOk() {},
      })
    } else {
      return confirm({
        title: t("Confirm select dealer"),
        icon: <ExclamationCircleOutlined />,
        okText: t("Confirm"),
        cancelText: t("no"),
        centered: true,
        onOk() {
          history.push({
            pathname: "/import-data/dealer/select-dealer",
            data_dealer: dealer_select,
          })
        },
        onCancel() {},
      })
    }
  }

  return (
    <div style={{ marginTop: "20px" }}>
      <Search_data
        setSearch_dealer={setSearch_dealer}
        search_dealer_all={search_dealer_all}
      />
      <Table
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        loading={loading}
        rowKey="gid"
        pagination={false}
        columns={columns}
        dataSource={data_list}
        scroll={{ x: 800 }}
      />
      <div>
        <Pagination
          current={page_limit.page}
          total={page_limit.limit}
          onChange={changePagination}
          style={{ marginTop: "20px", float: "right" }}
        />
      </div>
      <p style={{ fontSize: "15px", marginTop: "20px" }}>
        {" "}
        <FcDataSheet size={20} style={{ marginBottom: "5px" }} /> {t("total data")} :{" "}
        {page_limit.limit}
      </p>

      {permi_t === true && (
        <Button
          style={{
            marginTop: "70px",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            width: "200px",
          }}
          type="primary"
          onClick={() => select_Dealer()}
        >
          {t("Select")}
        </Button>
      )}

      {permi_t === false && (
       <div>
       <p
       style={{
         color: "red",
         textAlign: "center",
         fontSize: "20px",
         marginTop: "20px",
       }}
     >
     {t("no permission write module")}
     </p>
     <p style={{ textAlign:'center', fontSize:'20px' }}> {t("please contract administrator")} </p>
       </div>
      )}
    </div>
  )
}
