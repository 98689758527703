import React from "react"
import { Input, Button, Table, Pagination } from "antd"
import { Row, Col } from "reactstrap"
import { FcDataSheet } from "react-icons/fc"
import { EditOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next";

export default function Select_Branch(props) {
  const { t } = useTranslation();

  const columns = [
    {
      title: t("Code"),
      dataIndex: "cus_branch_code",
      key: "cus_branch_code",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Name"),
      dataIndex: "cus_branch_name",
      key: "cus_branch_name",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Address"),
      dataIndex: "cus_branch_address",
      key: "cus_branch_address",
      render: text => <p>{text}</p>,
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      props.setSelect_role_branch(selectedRows[0])
      props.setSelect_branch_final(selectedRows[0])
      props.setBranch({
        default_branch: false,
        found_branch_db : true ,
        cus_branch_id : selectedRows[0].cus_branch_id
      })
    },
    getCheckboxProps: record => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  }

  return (
    <div>
      {!props.branch.cus_branch_id && (
        <div>
        <p style={{ fontSize: "15px", color:'rgb(73, 80, 87)', fontWeight:'bold' }}> {t("Current customer branch")} </p>
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <Input
                placeholder={`${t("code")} , ${t("name")}`}
                onChange={e => props.setData_search_branch(e.target.value)}
              />
            </Col>
            <Col sm={12} md={2} lg={2} xl={2}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                onClick={() => props.search_data_branch()}
              >
                {t("Search")}
              </Button>
            </Col>
          </Row>
          <br />
         
          <Table
            rowSelection={{
              type: "radio",
              ...rowSelection,
            }}
            loading={props.loading_branch}
            columns={columns}
            dataSource={props.branch_table}
            rowKey="cus_branch_code"
            scroll={{ x: 800 }}
            pagination={false}
          />
          <Pagination
            current={props.page_limit_branch.page_branch}
            total={props.page_limit_branch.limit_branch}
            onChange={props.changePagination}
            style={{ marginTop: "20px", float: "right" }}
          />
          <p style={{ fontSize: "15px", marginTop: "20px" }}>
            {" "}
            <FcDataSheet size={20} style={{ marginBottom: "5px" }} /> {t("total data")}
            : {props.page_limit_branch.limit_branch}
          </p>
        </div>
      )}

      {props.branch.cus_branch_id && (
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div style={{ display: "flex", marginBottom:'20px' }}>
              <p className="text-bold " style={{ marginTop: "7px" }}>
            
                {t("Branch")}
              </p>
              <Input
                style={{ marginLeft: "28px", height: "35px" }}
                value={props.select_role_branch.cus_branch_name}
                placeholder={props.select_role_branch.cus_branch_name}
                disabled
              />
              <a>
                <EditOutlined
                  style={{ fontSize: "20px", marginLeft: "10px" }}
                  onClick={() =>
                    {
                      props.setSelect_branch_final({})
                      props.setSelect_role_branch({})
                      props.setBranch({
                      cus_branch_address: null,
                      cus_branch_code: null,
                      cus_branch_contact_email: null,
                      cus_branch_contact_firstname: null,
                      cus_branch_contact_lastname: null,
                      cus_branch_contact_mobile: null,
                      cus_branch_contact_pos: null,
                      cus_branch_email: null,
                      cus_branch_fax: null,
                      cus_branch_lat: null,
                      cus_branch_long: null,
                      cus_branch_mobile: null,
                      cus_branch_name: null,
                      cus_branch_phone: null,
                      zip_id: null,
                      found_branch_db : false,
                      default_branch: false
                    })
                  }
                  }
                />
              </a>
            </div>
          </Col>
        </Row>
      )}
    </div>
  )
}
