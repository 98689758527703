import React, { useState, useEffect } from "react"
import { Form, Button, notification, Modal } from 'antd';
import { Row, Col } from "reactstrap"
import Input_Comp from "./Components/Input_Comp"
import Input_Number from "./Components/Input_Number"
import Select_no_search from "./Components/Select_no_search"
import Input_Comp_Show_Only from './Components/Input_Comp_Show_Only'
import Select_Postcode from "./Components/Select_Postcode"
import { ExclamationCircleOutlined } from '@ant-design/icons';
import * as api from '../../../service/Api_Edit_Dealer'
import axios from 'axios'
import { useHistory } from "react-router-dom";
import helpers from '../../Function_Redirect/Redirect_login'
import { useTranslation } from "react-i18next";

const { confirm } = Modal;

export default function Edit(props) {
  const [form] = Form.useForm()
  const { t } = useTranslation();
  const token = localStorage.getItem('token')
  const [loading_button, setLoading_button] = useState(false)
  const [edit, setEdit] = useState(true)
  const history = useHistory()
  const [data_edit, setData_edit] = useState({})
  const [location_select, setLocation_select] = useState({})
  const [postcode_select, setPostcode_select] = useState([])

  useEffect(() => {
    onFill()
    return () => {

    }
  }, [props.data_dealer])

  const onFinish = values => {
    return confirm({
      title: t("Confirm edit dealer"),
      icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
      centered: true,
      okText: t("Confirm"),
      cancelText: t("no"),
      onOk() {
        setLoading_button(true)
          let last_edit_operator = {}
          for (const [key, value] of Object.entries(data_edit)) {
            if (!value) {
            } else {
              last_edit_operator[key] = value
            }
          }
          if (Object.keys(last_edit_operator)[0] === undefined) {
              openNotificationWithIcon("info", t("No data is change"))
              setLoading_button(false)
          } else {
            const edit_operator = api.EDIT_DEALER(token , last_edit_operator, props.dea_id)
            axios(edit_operator)
            .then( res => {
              setLoading_button(false)
              Cancel_Edit()
              props.check_new_data_edit()
              openNotificationWithIcon("success", t("Edit dealer success"))
            })
            .catch( error => {
              setLoading_button(false)
              if(error){
                if(error.response){
                  if(error.response.status === 401){
                    helpers.re_login()
                    history.push('/login')          
                    } else {
                      openNotificationWithIcon("error", t("Edit dealer error"))
                    }
                }
              }
            })
          }
  
      },
      onCancel() {

      },
    })
  }

  const onFill = () => {
    if (props.data_dealer) {
      form.setFieldsValue({
        dea_taxid : props.data_dealer.dea_taxid,
        dea_code: props.data_dealer.dea_code,
        dea_name: props.data_dealer.dea_name,
        dea_credit: props.data_dealer.dea_credit,
        dea_phone: props.data_dealer.dea_phone,
        dea_email: props.data_dealer.dea_email,
        dea_fax: props.data_dealer.dea_fax,
        dea_pos_id: props.data_dealer.dea_pos_id,
        dea_contact_firstname: props.data_dealer.dea_contact_firstname,
        dea_contact_lastname: props.data_dealer.dea_contact_lastname,
        dea_contact_email: props.data_dealer.dea_contact_email,
        dea_contact_mobile: props.data_dealer.dea_contact_mobile,

        Branch_address: props.data_dealer.dea_address,
      })
      setLocation_select({
        dis_name: props.data_dealer.dis_name,
        pro_name: props.data_dealer.pro_name,
        subdis_name: props.data_dealer.subdis_name,
        zip_code: props.data_dealer.zip_code,
      })
    }
  }

  function Cancel_Edit() {
    onFill()
    setData_edit({})
    setEdit(!edit)
  }

  const openNotificationWithIcon = (type, description) => {
    notification[type]({
      message: description,
    })
  }

  return (
    <div>
      <Form form={form} name="control-hooks" onFinish={onFinish}>
        <Row>
          <Col>
            <div style={{ display: "inherit" }}>
              <p
                className="text-bold"
                style={{
                  color: "#495057",
                  textAlign: "center",
                  fontSize: "15px",
                }}
              >
                {t("Dealer information")}
              </p>
              {edit === true && (
                <Button
                  style={{ float: "right", width: "150px" }}
                  onClick={() => setEdit(!edit)}
                >
                  {t("Edit")}
                </Button>
              )}
              {edit === false && (
                <Button
                  type="primary"
                  style={{ float: "right", width: "150px" }}
                  onClick={() => Cancel_Edit()}
                >
                  {t("Cancel")}
                </Button>
              )}
            </div>
          </Col>
        </Row>
        <br />

        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Dealer TAX ID")}
              placeholder={props.data_dealer.dea_taxid}
              headState={setData_edit}
              nameState={data_edit}
              type="text"
              keyState="dea_taxid"
              disabled={edit}
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp_Show_Only
              header={t("Dealer code")}
              placeholder={props.data_dealer.dea_code}
              headState={setData_edit}
              nameState={data_edit}
              type="text"
              keyState="dea_code"
              disabled={true}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Dealer name")}
              placeholder={props.data_dealer.dea_name}
              headState={setData_edit}
              nameState={data_edit}
              type="text"
              keyState="dea_name"
              disabled={edit}
            />
          </Col>
          <Col sm={12} md={2} lg={2} xl={2}>
            <Input_Number
              header={t("Dealer credit (day)")}
              placeholder={props.data_dealer.dea_credit}
              headState={setData_edit}
              nameState={data_edit}
              keyState="dea_credit"
              disabled={edit}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Dealer phone")}
              placeholder={props.data_dealer.dea_phone}
              headState={setData_edit}
              nameState={data_edit}
              type="number"
              keyState="dea_phone"
              disabled={edit}
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Dealer email")}
              placeholder={props.data_dealer.dea_email}
              headState={setData_edit}
              nameState={data_edit}
              type="email"
              keyState="dea_email"
              disabled={edit}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Dealer fax")}
              placeholder={props.data_dealer.dea_fax}
              headState={setData_edit}
              nameState={data_edit}
              type="text"
              keyState="dea_fax"
              disabled={edit}
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Dealer position")}
              placeholder={t("Dealer position")}
              name_api="LIST_POSITION"
              map_name="pos_name"
              map_value="pos_id"
              keyState="dea_pos_id"
              headState={setData_edit}
              nameState={data_edit}
              disabled={edit}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Contact firstname")}
              placeholder={props.data_dealer.dea_contact_firstname}
              type="text"
              keyState="dea_contact_firstname"
              headState={setData_edit}
              nameState={data_edit}
              disabled={edit}
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Contact lastname")}
              placeholder={props.data_dealer.dea_contact_lastname}
              type="text"
              keyState="dea_contact_lastname"
              headState={setData_edit}
              nameState={data_edit}
              disabled={edit}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Contact email")}
              placeholder={props.data_dealer.dea_contact_email}
              headState={setData_edit}
              nameState={data_edit}
              disabled={edit}
              type="email"
              keyState="dea_contact_email"
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Contact mobile")}
              placeholder={props.data_dealer.dea_contact_mobile}
              headState={setData_edit}
              nameState={data_edit}
              disabled={edit}
              type="number"
              keyState="dea_contact_mobile"
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Select_Postcode
              setLocation_select={setLocation_select}
              location_select={location_select}
              setPostcode_select={setPostcode_select}
              postcode_select={postcode_select}
              setData_edit={setData_edit}
              data_edit={data_edit}
              edit={edit}
              data_dealer={props.data_dealer}
            />
          </Col>
        </Row>

        <br />
        {edit === false && (
          <Button
            type="primary"
            htmlType="submit"
            style={{ display: "block", margin: "auto", width: "150px" }}
            loading={loading_button}
          >
           {t("Edit")}
          </Button>
        )}
      </Form>
    </div>
  )
}
