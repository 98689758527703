import React, { useEffect, useState } from "react"
import axios from "axios"
import * as api from "../../../service/Api_Edit_Service"
import { Row, Col } from "reactstrap"
import Render_Image_Data from "../../Employee/Table/Render_Image_Data"
import { Card, Button, Tag, Modal, notification } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next";

const { confirm } = Modal
const { Meta } = Card

export default function Console_Service(props) {
  const token = localStorage.getItem("token")
  const { t } = useTranslation();
  const style_tag = { marginLeft: "5px", marginTop: "5px" }
  const [all_operator, setAll_operator] = useState([])
  const [operator_select, setOperator_select] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    return () => {
      setAll_operator([])
    }
  }, [])

  useEffect(() => {
    if (props.data_service) {
      check_operator_suggest(props.data_service.ser_id)
    }
    return () => {}
  }, [props.data_service])

  function check_operator_suggest(ser_id) {
    const check = api.LIST_OPERATOR_SUGGEST(token, ser_id)
    axios(check)
      .then(res => {
        setAll_operator(res.data.data)
      })
      .catch(error => {
        // console.log(error)
      })
  }

  useEffect(() => {
    render_operator()
    return () => {}
  }, [all_operator])

  function render_operator() {
    return all_operator.map(data => {
      return (
        <Col xs={12} sm={12} md={6} lg={4} xl={4} key={data.ope_id}>
          <div
            style={{
              border: "1px solid #f0f0f0",
              padding: "10px",
              margin: "10px auto 10px auto",
              height: "130px",
              backgroundColor:'#FFF'
            }}
          >
            <Row>
              <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                <div style={{ marginTop: "10px" }}>
                  <Render_Image_Data path_image={data.ope_avatar} />
                </div>
              </Col>
              <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                <div style={{ marginLeft: "10px" }}>
                  <p
                    style={{ fontWeight: "bold", marginTop: "5px" }}
                  >{`${data.ope_firstname}  ${data.ope_lastname}`}</p>
                  {check_All_Zone(data.all_geo)}
                  {render_tag(data.geo_name)}
                  {check_tag(data.pro_name)}
                  {check_tag(data.dis_name)}
                  {check_tag(data.subdis_name)}
                </div>
              </Col>
              <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                <input
                  type="checkbox"
                  id={data.ope_id}
                  value={data.ope_id}
                  name={data.ope_firstname}
                  style={{
                    width: "17px",
                    height: "17px",
                    marginTop: "40px",
                    marginLeft: "-20px",
                  }}
                />
              </Col>
            </Row>
          </div>
        </Col>
      )
    })
  }

  function check_All_Zone(all_geo) {
    if (all_geo === 1)
      return (
        <Tag color="gold" style={style_tag}>
          {t("All zone")}
        </Tag>
      )
  }

  function check_tag(tab_check) {
    if (tab_check !== null)
      return (
        <Tag color="default" style={style_tag}>
          {tab_check}
        </Tag>
      )
  }

  function render_tag(geo_name) {
    switch (geo_name) {
      case "ภาคเหนือ":
        return (
          <Tag color="red" style={style_tag}>
            {geo_name}
          </Tag>
        )
        break
      case "ภาคกลาง":
        return (
          <Tag color="volcano" style={style_tag}>
            {geo_name}
          </Tag>
        )
        break
      case "ภาคตะวันออกเฉียงเหนือ":
        return (
          <Tag color="cyan" style={style_tag}>
            {geo_name}
          </Tag>
        )
        break
      case "ภาคตะวันตก":
        return (
          <Tag color="green" style={style_tag}>
            {geo_name}
          </Tag>
        )
        break
      case "ภาคตะวันออก":
        return (
          <Tag color="blue" style={style_tag}>
            {geo_name}
          </Tag>
        )
        break
      case "ภาคใต้":
        return (
          <Tag color="warning" style={style_tag}>
            {geo_name}
          </Tag>
        )
        break
      default:
        return null
        break
    }
  }

  function setOperator(event) {
    if (event.target.checked === true) {
      const select_operator_data = [...operator_select]
      select_operator_data.push({
        ope_id: parseInt(event.target.value),
      })
      setOperator_select(select_operator_data)
    } else {
      const select_operator_data = [...operator_select]
      var index_operator = select_operator_data.findIndex(
        data => data.ope_id === parseInt(event.target.value)
      )
      select_operator_data.splice(index_operator, 1)
      setOperator_select(select_operator_data)
    }
  }

  function add_operator() {
    return confirm({
      title: t("Confirm select operator"),
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: t("Confirm"),
      cancelText: t("no"),
      onOk() {
        setLoading(true)
        operator_select.map(async data => {
          await fetchData(data.ope_id)
        })

        setTimeout(() => {
          props.check_activity_log()
          openNotificationWithIcon("success", t("Insert operator success"))
          props.list_service_overview()
          props.check_new_data_edit()
          setLoading(false)
        }, 2000)
      },
      onCancel() {},
    })
  }

  async function fetchData(ope_id) {
    const data = {
      ser_id: props.data_service.ser_id,
      ope_id: ope_id,
    }
    const create_operator = api.INSERT_OPERATOR(token, data)
    const fetcher = await axios(create_operator)
    if (fetcher.status === 201) {
      //  openNotificationWithIcon('success','Insert operator success')
    } else {
      openNotificationWithIcon("error", t("Insert operator error"))
      // console.log(error.response)
    }
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  return (
    <div>
    {
      all_operator.length !== 0 && (
        <div onChange={setOperator}>
        <Row>
          <Col>
            <p className="text-bold"> {t("Operator suggestion")} </p>
          </Col>
          <Col>
            <Button
              type="primary"
              style={{ float: "right", width: "150px" }}
              onClick={() => add_operator()}
              loading={loading}
            >
              {t("Select operator")}
            </Button>
          </Col>
        </Row>
        <Row>{render_operator()}</Row>
      </div>
      )
    }
    
    </div>
  )
}
