import React,{ useEffect , useState} from "react"
import { Container, Card, CardBody } from "reactstrap"
import MetaTags from "react-meta-tags"
import { useHistory } from "react-router-dom"
import Card_Comp from "./Card_Comp"
import "./Import_Data_Select_style.css"
import no_permi from '../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png'
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux"

const Create_Car = props => {
  const history = useHistory()
  const { t } = useTranslation();
  const permi = localStorage.getItem('permi')
  const [permi_t_read, setPermi_t_read] = useState(false)
  const { permissionAllService } = useSelector(({ Distar }) => Distar)

useEffect(() => {
  if(permissionAllService){
    if(permissionAllService.import){
      setPermi_t_read(permissionAllService.import.read)
    }
  }
  return () => {
    // setPermi_t_read(false)
  }
}, [permissionAllService])

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Import data")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <br />
          <p
            style={{
              textAlign: "center",
              fontSize: "25px",
              fontWeight: "bold",
              color: "#495057",
            }}
          >
          {t("Import data")}
          </p>
{
  permi_t_read === true && (
    <div className="outer">

    {/* <a onClick={() => history.push("/import-data/dealer")}>
      <Card_Comp
        text_Header={t("Dealer")}
        icon_card="FaRegHandshake"
        color="#EA9FE6"
      />
    </a> */}

    <a onClick={() => history.push("/import-data/car")}>
      <Card_Comp
        text_Header={t("Car")}
        icon_card="BiCar"
        color="#7985E0"
      />
    </a>
    
    <a onClick={() => history.push("/import-data/stock")}>
    <Card_Comp
      text_Header={t("Stock")}
      icon_card="BiCalculator"
      color="#F29496"
    />
  </a>

  <a onClick={() => history.push("/import-data/customer")}>
  <Card_Comp
    text_Header={t("Customer")}
    icon_card="BsPeople"
    color="#75C7EA"
  />
</a>
  
  </div>
  )
}
 
{
  permi_t_read === false && (
    <div>
    <img src={no_permi} alt="no permission" style={{ display:'block' , margin: '50px auto' }} />
    <p style={{ textAlign: "center", fontSize: "20px" }}>{t("no permission read module")}</p>
<p style={{ textAlign:'center', fontSize:'20px' }}> {t("please contract administrator")} </p>

    </div>
  )
}

        </Container>
      </div>
    </React.Fragment>
  )
}
export default Create_Car
