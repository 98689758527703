import React,{ useState, useEffect } from 'react'
import Render_img from './Render_img'
import { Row } from "reactstrap"

export default function Render_Image_After_Upload(props) {
    const [image_display, setImage_display] = useState([])
    useEffect(() => {
        if(props.value.length !== 0){
            setImage_display(props.value)
        }
        return () => {
   
        }
    }, [props.value])

    return (
        <div>
        <Row>
        {
            image_display.map( (data, index) => {
                return <Render_img value={data} key={index} />
            })
        }
        </Row>
        </div>
    )
}
