import React, { useState, useCallback, useRef, useEffect } from "react"
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api"
import { AutoComplete } from "antd"
import Geocode from "react-geocode"

import ping_2 from "./ping_2.png"

const libraries = ["places"]
const mapContainerStyle = {
  height: "250px",
}

const options = {
  // styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
}

export default function Edit_Map_location(props) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_APP,
    libraries,
  })
  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_APP)
  Geocode.setLanguage("th")
  const [map_marker, setMap_marker] = useState(null)
  const [zoom_gg_map, setZoom_gg_map] = useState(6)

  useEffect(() => {
    setZoom_gg_map(16)
    setMap_marker({
      lat: parseFloat(props.location_check.lat),
      lng: parseFloat(props.location_check.lng),
      time: new Date(),
    })
    return () => {
      setMap_marker(null)
      setZoom_gg_map(6)
    }
  }, [props.location_check])

  const onMapClick = e => {}

  const mapRef = useRef()
  const onMapLoad = useCallback(map => {
    mapRef.current = map
  }, [])

  if (loadError) return "Error"
  if (!isLoaded) return "Loading..."

  return (
    <div className="border-card-info" >
      <GoogleMap
        id="map"
        mapContainerStyle={mapContainerStyle}
        zoom={zoom_gg_map}
        center={props.location_check}
        options={options}
        // onClick={onMapClick}
        onLoad={onMapLoad}
        defaultZoom={6}
        defaultCenter={{ lat: 13.2753119, lng: 101.4843104 }}
      >
        {map_marker && (
          <Marker
            key={`${map_marker.lat}-${map_marker.lng}`}
            position={{ lat: map_marker.lat, lng: map_marker.lng }}
            icon={ping_2}
          >
          </Marker>
        )}
      </GoogleMap>
    </div>
  )
}
