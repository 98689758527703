import React,{ useState, useEffect } from 'react'
import { Card, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const { Meta } = Card;

export default function Admin(props) {
    const [admin, setAdmin] = useState([])

    useEffect(() => {
        if(props.service_overview.admin){
            // console.log(props.service_overview.admin)
            setAdmin(props.service_overview.admin)
        }
        return () => {
            // setAdmin([])
        }
    }, [props.service_overview])

    useEffect(() => {
        if(admin){
            // console.log(admin);
            render_admin()
        }
        
        return () => {
            
        }
    }, [admin])

    function render_admin() {
        return admin.map( (data, index) => {
            return(
                <Card style={{ width: '100%', marginTop: 16 }} key={index} >
                <Meta
                  avatar={
                    <Avatar size={54} icon={<UserOutlined style={{ marginBottom:'13px' }} />} />
                  }
                  title={`${data.emp_firstname}   ${data.emp_lastname}`}
                  description={data.emp_code}
                />
              </Card>
            )
        })
    }

    return (
        <div style={{ height:'250px', overflowY: "scroll", overflowX: "hidden" , boxShadow:'rgb(99 99 99 / 20%) 0px 2px 8px 0px', padding:'10px', margin:'10px'}}>
        <p style={{ fontSize: "20px", marginBottom: "15px", color: "#495057" }}>
        Admin
      </p>

      {
        render_admin()
    }

        </div>
    )
}
