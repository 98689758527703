import React from "react"
import { useHistory } from "react-router-dom"
import axios from "axios"
import * as api from "../../../../service/Api_New_Service"
import { useTranslation } from "react-i18next"
import SelectCarBtn from "./SelectCarBtn"
import TableCarSelected from "./TableCarSelected"

export default function SelectCarComp({ selectCar, setSelectCar }) {
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <div>
      <p className="text-bold ">
        {t("Select car")} <span style={{ color: "red" }}>*</span>
      </p>
      <SelectCarBtn selectCar={selectCar} setSelectCar={setSelectCar} />
      <br />
      <TableCarSelected selectCar={selectCar} setSelectCar={setSelectCar} />
    </div>
  )
}
