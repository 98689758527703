import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { useTranslation } from "react-i18next"
import { Container, Card, CardBody } from "reactstrap"
import { MdKeyboardBackspace } from "react-icons/md"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import no_permi from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png"
import Form from "./Form"
import axios from "axios"
import * as api from "../../service/Api_New_Service"

export default function CreateMultipleService() {
  const { t } = useTranslation()
  const history = useHistory()
  const token = localStorage.getItem("token")
  const [permi_write, setPermi_write] = useState(false)
  const { permissionAllService } = useSelector(({ Distar }) => Distar)
  const [type_received_all, setType_received_all] = useState([])
  const [fileUploadAll, setFileUploadAll] = useState([])
  const [createNewService, setCreateNewService] = useState({
    pri_id: null,
    ser_booking_date: null,
    ser_booking_time: null,
    ser_contact_name: null,
    ser_contact_phone: null,
    ser_issue_reported: null,
    ser_lat: null,
    ser_long: null,
    ser_notification_email: null,
    ser_notification_line: null,
    ser_notification_phone: null,
    ser_onsite_address: null,
    ser_received_type_id: null,
    ser_remark: null,
    ser_status_id: 1,
    ser_type_id: null,
    sla_service_id: null,
  })
  const [customerSelect, setCustomerSelect] = useState({
    cus_user_id: null, // ใช้
    cus_id: null, // ใช้
    cus_name: null,
  })
  const [location_select, setLocation_select] = useState({
    geo_id: null,
    pro_id: null,
    subdis_id: null,
    dis_id: null,
  })
  const [user_select, setUser_select] = useState(null)
  const [selectCar, setSelectCar] = useState([])

  function debugFC() {
    let dataAll = {
      ...createNewService,
      ...customerSelect,
      ...location_select,
    }
    console.log(dataAll)
    console.log(selectCar)
    console.log(fileUploadAll);
  }

  function check_received() {
    const check_ = api.LIST_RECEIVED_TYPE(token)
    axios(check_).then(res => {
      setType_received_all(res.data.data)
    })
  }

  useEffect(() => {
    check_received()

    return () => {}
  }, [])

  useEffect(() => {
    if (permissionAllService) {
      if (permissionAllService.service) {
        setPermi_write(permissionAllService.service.write)
      }
    }
    return () => {}
  }, [permissionAllService])

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("create multiple service")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/new-service")}
              />
            </a>{" "}
            {t("create multiple service")}{" "}
          </h3>
          <br />
          {/* <button onClick={() => debugFC()}>debug</button> */}
          {permi_write === true && (
            <Card>
              <CardBody>
                <Form
                  setFileUploadAll={setFileUploadAll}
                  fileUploadAll={fileUploadAll}
                  type_received_all={type_received_all}
                  setType_received_all={setType_received_all}
                  createNewService={createNewService}
                  setCreateNewService={setCreateNewService}
                  location_select={location_select}
                  setLocation_select={setLocation_select}
                  user_select={user_select}
                  setUser_select={setUser_select}
                  customerSelect={customerSelect}
                  setCustomerSelect={setCustomerSelect}
                  selectCar={selectCar} 
                  setSelectCar={setSelectCar}
                />
              </CardBody>
            </Card>
          )}

          {permi_write === false && (
            <div>
              <img
                src={no_permi}
                alt="no permission"
                style={{ display: "block", margin: "50px auto" }}
              />
              <p style={{ textAlign: "center", fontSize: "20px" }}>
                {t("no permission write module")}
              </p>
              <p style={{ textAlign: "center", fontSize: "20px" }}>
                {" "}
                {t("please contract administrator")}{" "}
              </p>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
