import React, { useRef, useEffect, useState } from "react"
import { Form, Button, notification, Modal, Avatar } from "antd"
import { Row, Col } from "reactstrap"
import Input_Comp from "./Components/Input_Comp"
import Input_Number from "./Components/Input_Number"
import * as api from "../../../../service/Api_Customer"
import Select_no_search from "./Components/Select_no_search"
import Postcode from "./Components/Postcode"
import { UserOutlined } from "@ant-design/icons"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import axios from "axios"

const { confirm } = Modal

export default function Edit(props) {
  const [form] = Form.useForm()
  const imageUploader = useRef(null)
  const token = localStorage.getItem("token")
  const [customer_edit, setCustomer_edit] = useState({})
  const [edit, setEdit] = useState(true)
  const [location_select, setLocation_select] = useState({})
  const [image_preview_before_upload, setImage_preview_before_upload] = useState(null)
  const [image_select_upload, setImage_select_upload] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    onFill()

    return () => {

    }
  }, [props.detail_customer])

  useEffect(() => {
    if(props.image_From_db !== null){
      setImage_preview_before_upload(props.image_From_db)
    }
    return () => {
      
    }
  }, [props.image_From_db])

  const handleImageUpload = e => {
    if (e.target.files[0] !== undefined) {
      if(e.target.files[0].type !== "image/jpeg"){
        openNotificationWithIcon("error", "Please upload photo type .JPEG only")
      } else {
        let img_upload = e.target.files[0]
        setImage_preview_before_upload(URL.createObjectURL(img_upload))
        setImage_select_upload(img_upload)
      }
    }
  }

  const onFinish = value => {
    let data_last_edit = {}
    for (const [key, value] of Object.entries(customer_edit)) {
      if (!value) {
      } else {
        data_last_edit[key] = value
      }
    }
    
    confirm({
      title: "Confirm edit customer infomation",
      icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
      centered: true,
      okText: "Confirm",
      onOk() {
        setLoading(true)
        if (Object.keys(data_last_edit)[0] === undefined) {
          setLoading(false)
          openNotificationWithIcon("info", "No data is change")
        } else if(image_select_upload !== null){
          const formData = new FormData();
          formData.append("file", image_select_upload)
          const upload_image = api.UPLOAD_PROFILE(token, formData);
          axios(upload_image)
          .then( res => {
            let add_image_path = {
              cus_avatar : res.data.path,
              ...data_last_edit
            }
            edit_data(add_image_path)
          })
          .catch( error => {
            setLoading(false)
            openNotificationWithIcon("error", "Edit error")
            console.log(error.response);
          })
        } else {
          edit_data(data_last_edit)
        }

      },
      onCancel() {},
    })

  }

  function edit_data(data) {
    const edit_customer = api.EDIT_CUSTOMER(
      token,
      data,
      props.detail_customer.cus_id
    )
    console.log(edit_customer);
    axios(edit_customer)
      .then(res => {
        setLoading(false)
        openNotificationWithIcon("success", "Edit success")
        props.check_profile_customer()
        setCustomer_edit({})
        setEdit(!edit)
      })
      .catch(error => {
        setLoading(false)
        openNotificationWithIcon("error", "Edit error")
        console.log(error.response)
      })
  }

  const openNotificationWithIcon = (type, description) => {
    notification[type]({
      message: description,
    })
  }

  const onFill = () => {
    form.setFieldsValue({
      cus_taxid: props.detail_customer.cus_taxid,
      cus_credit: props.detail_customer.cus_credit,
      cus_name: props.detail_customer.cus_name,
      cus_phone: props.detail_customer.cus_phone,
      cus_fax: props.detail_customer.cus_fax,
      cus_email: props.detail_customer.cus_email,
      dea_id: props.detail_customer.dea_id,
      cus_branch_address: props.detail_customer.cus_branch_address,
    })
  }

  function Cancel_Edit() {
    setCustomer_edit({})
    setEdit(!edit)
    setImage_select_upload(null)
    onFill()
    if(props.image_From_db !== null){
      setImage_preview_before_upload(props.image_From_db)
    } else {
      setImage_preview_before_upload(null)
    }
  }

  return (
    <div>
      <Row>
        <Col>
          <div style={{ display: "inherit" }}>
            <p
              className="text-bold"
              style={{
                color: "#495057",
                textAlign: "center",
                fontSize: "15px",
              }}
            >
              Customer information
            </p>
            {edit === true && (
              <Button
                style={{ float: "right", width: "150px" }}
                onClick={() => setEdit(!edit)}
              >
                Edit
              </Button>
            )}
            {edit === false && (
              <Button
                type="primary"
                style={{ float: "right", width: "150px" }}
                onClick={() => Cancel_Edit()}
              >
                Cancel
              </Button>
            )}
          </div>
        </Col>
      </Row>

      <div >
        <div className="image-upload" style={{ marginTop:'20px', marginBottom:'30px' }}>
        {
          image_preview_before_upload === null && (
            <Avatar
            icon={<UserOutlined />}
            size={100}
            style={{ display: "block", margin: "auto" }}
          />
          )
        }

        {
          image_preview_before_upload !== null && (
            <Avatar
            src={image_preview_before_upload}
            size={100}
            style={{ display: "block", margin: "auto" }}
          />
          )
        }

        </div> 

        <input
          type="file"
          accept="image/jpeg"
          onChange={handleImageUpload}
          ref={imageUploader}
          style={{
            display: "none",
          }}
        />

        {edit === false && (
        <>
        <Button
        type="primary"
        style={{ display:'block', margin:'10px auto 10px auto' }}
        onClick={() => imageUploader.current.click()}
      >
        Upload photo
      </Button>
      <p style={{ color:'red', textAlign:'center' }}> Please upload jpg or jpeg image only </p>
        </>
        )}
      </div>

      <Form form={form} name="control-hooks" onFinish={onFinish}>
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header="TAX ID"
              placeholder={props.detail_customer.cus_taxid}
              headState={setCustomer_edit}
              nameState={customer_edit}
              keyState="cus_taxid"
              type="text"
              maxLength={13}
              disabled={edit}
            />
          </Col>
          <Col sm={12} md={2} lg={2} xl={2}>
            <Input_Number
              header="Credit (Day)"
              placeholder={props.detail_customer.cus_credit}
              headState={setCustomer_edit}
              nameState={customer_edit}
              keyState="cus_credit"
              maxLength={null}
              disabled={edit}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header="Company name"
              placeholder={props.detail_customer.cus_name}
              headState={setCustomer_edit}
              nameState={customer_edit}
              keyState="cus_name"
              type="text"
              maxLength={null}
              disabled={edit}
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header="Company phone"
              placeholder={props.detail_customer.cus_phone}
              headState={setCustomer_edit}
              nameState={customer_edit}
              keyState="cus_phone"
              type="number"
              maxLength={null}
              disabled={edit}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header="Fax"
              placeholder={props.detail_customer.cus_fax}
              headState={setCustomer_edit}
              nameState={customer_edit}
              keyState="cus_fax"
              type="text"
              maxLength={null}
              disabled={edit}
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header="Email"
              placeholder={props.detail_customer.cus_email}
              headState={setCustomer_edit}
              nameState={customer_edit}
              keyState="cus_email"
              type="email"
              maxLength={null}
              disabled={edit}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Postcode
              customer_edit={customer_edit}
              setCustomer_edit={setCustomer_edit}
              setEdit={setEdit}
              edit={edit}
              location_select={location_select}
              setLocation_select={setLocation_select}
              detail_customer={props.detail_customer}
              disabled={edit}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header="Select dealer"
              placeholder={props.detail_customer.dea_id}
              name_api="LIST_DEALER"
              map_name="dea_name"
              map_value="dea_id"
              keyState="dea_id"
              headState={setCustomer_edit}
              nameState={customer_edit}
              disabled={edit}
            />
          </Col>
        </Row>

        <br />

        {edit === false && (
          <Button
            type="primary"
            htmlType="submit"
            style={{ display: "block", margin: "auto", width: "150px" }}
            loading={loading}
          >
            {" "}
            Edit{" "}
          </Button>
        )}
      </Form>
    </div>
  )
}
