import React, { useState, useEffect } from "react"
import Search_contract_table from "./Search_contract_table"
import { Table, Modal, Pagination, Tag, Tooltip } from "antd"
import { AiOutlineEye } from "react-icons/ai"
import { FiEdit2 } from "react-icons/fi"
import Detail_Contract from "./Detail_Contract"
import { useHistory } from "react-router-dom"
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons"
import { FcSearch, FcSettings, FcDataSheet } from "react-icons/fc"
import { useTranslation } from "react-i18next"

export default function Table_contract(props) {
  const history = useHistory()
  const { t } = useTranslation()
  const [contract_table, setContract_table] = useState([])
  const [visible, setVisible] = useState(false)
  const [data_Contract_modal, setData_Contract_modal] = useState({})

  useEffect(() => {
    setContract_table(props.data_contract)
    return () => {
      setContract_table([])
    }
  }, [props.data_contract])

  const columns = [
    {
      title: t("Contract"),
      dataIndex: "con_no",
      key: "con_no",
      render: text => checkData(text),
    },
    {
      title: t("Contract name"),
      dataIndex: "con_name",
      key: "con_name",
      render: text => checkData(text),
    },
    {
      title: t("Start date"),
      dataIndex: "con_start_date",
      key: "con_start_date",
      render: text => cutTimeZone(text),
    },
    {
      title: t("End date"),
      dataIndex: "con_end_date",
      key: "con_end_date",
      render: text => cutTimeZone(text),
    },
    {
      title: t("Status"),
      dataIndex: "con_expired",
      key: "con_expired",
      render: text => cutStatus(text),
    },
    {
      title: "",
      render: text => (
        <Tooltip title={t("View contract")}>
          <a>
            <FcSearch
              onClick={() => {
                setVisible(true)
                setData_Contract_modal(text)
              }}
              size={20}
            />
          </a>
        </Tooltip>
      ),
    },
    {
      title: "",
      render: text => (
        <Tooltip title={t("Edit contract")}>
          <a>
            <FcSettings
              size={20}
              onClick={() => {
                history.push(
                  `/customers/${props.id}/edit-contract/${text.con_id}`
                )
              }}
            />
          </a>
        </Tooltip>
      ),
    },
  ]

  function cutStatus(status) {
    if (status === true) {
      return (
        <Tag icon={<CloseCircleOutlined />} color="error">
          {t("Expired")}
        </Tag>
      )
    } else {
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          {t("Available")}
        </Tag>
      )
    }
  }

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return "N/A"
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  function checkData(data) {
    if (data) {
      return data
    } else {
      return "N/A"
    }
  }

  return (
    <div>
      <Search_contract_table
        search_data_contract={props.search_data_contract}
        setData_search_contract={props.setData_search_contract}
      />
      <br />
      <Table
        loading={props.loading}
        columns={columns}
        dataSource={contract_table}
        pagination={false}
        rowKey="con_id"
        scroll={{ x: 800 }}
      />

      <Pagination
        current={props.page_limit_contract.page_contract}
        total={props.page_limit_contract.limit_contract}
        onChange={props.changePagination_contract}
        style={{ marginTop: "20px", float: "right" }}
      />
      <p style={{ fontSize: "15px", marginTop: "20px" }}>
        {" "}
        <FcDataSheet size={20} style={{ marginBottom: "5px" }} />{" "}
        {t("total data")} : {props.page_limit_contract.limit_contract}
      </p>

      <Modal
        title={false}
        centered
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={() => {
          setVisible(false)
          setData_Contract_modal({})
        }}
        footer={false}
        height={500}
        width={1000}
      >
        <Detail_Contract data_Contract_modal={data_Contract_modal} />
      </Modal>
    </div>
  )
}
