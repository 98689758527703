import React, { useState, useEffect } from "react"
import * as api from "../../../service/Api_New_Customer"
import axios from "axios"
import { Row, Col } from "reactstrap"
import { Input, Tooltip, Table, Pagination } from "antd"
import Render_Image_Data from "../../Employee/Table/Render_Image_Data"
import { FcDataSheet } from "react-icons/fc"
import { EditOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import helpers from "../../Function_Redirect/Redirect_login"

import line_offline from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/line_offline.png"
import line_online from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/line_online.png"

const { Search } = Input
export default function List_User(props) {
  const token = localStorage.getItem("token")
  const { t } = useTranslation()
  const history = useHistory()
  const [data_User_All, setData_User_All] = useState([])
  const [search_data_user, setSearch_data_user] = useState(null)
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    search_data_user_table()
    return () => {
      setData_User_All([])
    }
  }, [])

  function onSearch(value) {
    search_data_user_table()
  }

  function search_data_user_table() {
    setLoading(true)
    if (search_data_user) {
      const search_user_data = api.SEARCH_USER_DATA(
        token,
        props.cus_id,
        1,
        page_limit.page_Size,
        search_data_user
      )
      axios(search_user_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setData_User_All(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else {
      const search_user_nodata = api.SEARCH_NO_USER_DATA(
        token,
        props.cus_id,
        1,
        page_limit.page_Size
      )
      axios(search_user_nodata)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setData_User_All(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  function changePagination_users(page, pageSize) {
    setLoading(true)
    if (search_data_user) {
      const search_user_data = api.SEARCH_USER_DATA(
        token,
        props.cus_id,
        page,
        pageSize,
        search_data_user
      )
      axios(search_user_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setData_User_All(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else {
      const search_user_nodata = api.SEARCH_NO_USER_DATA(
        token,
        props.cus_id,
        page,
        pageSize
      )
      axios(search_user_nodata)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setData_User_All(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      props.setCreate_service({
        ...props.create_service,
        cus_user_id: selectedRows[0].cus_user_id,
      })
      props.setUser_select(selectedRows[0].cus_user_firstname)
    },
    getCheckboxProps: record => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  }

  const columns = [
    {
      title: "",
      dataIndex: "cus_user_avatar",
      key: "cus_user_avatar",
      render: text => <Render_Image_Data path_image={text} />,
    },
    {
      title: t("Firstname"),
      dataIndex: "cus_user_firstname",
      key: "cus_user_firstname",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Lastname"),
      dataIndex: "cus_user_lastname",
      key: "cus_user_lastname",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Branch name"),
      dataIndex: "cus_branch_name",
      key: "cus_branch_name",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Phone"),
      dataIndex: "cus_user_phone",
      key: "cus_user_phone",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Email"),
      dataIndex: "cus_user_email",
      key: "cus_user_email",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Line"),
      dataIndex: "cus_line_uid",
      key: "cus_line_uid",
      render: text => <span>{status_line(text)}</span>,
    },
  ]

  function status_line(status) {
    if (status === null) {
      return (
        <Tooltip title={t("NOT CONNECTED")}>
          <img
            src={line_offline}
            alt="line_offline"
            style={{ width: "30px" }}
          />
        </Tooltip>
      )
    } else {
      return (
        <Tooltip title={t("CONNECTED")}>
          <img src={line_online} alt="line_online" style={{ width: "30px" }} />
        </Tooltip>
      )
    }
  }

  return (
    <div>
      {props.user_select === null && (
        <div>
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
            <p className="text-bold ">
                {t("select user")} <span style={{ color:'red' }}>*</span>
              </p>
              <Search
                style={{ width: "100%" }}
                placeholder={`${t("Search")}`}
                allowClear
                enterButton={t("Search")}
                size="medium"
                onSearch={onSearch}
                onChange={e => setSearch_data_user(e.target.value)}
              />
            </Col>
          </Row>
          <br />

          <Table
            loading={loading}
            rowSelection={{
              type: "radio",
              ...rowSelection,
            }}
            rowKey="cus_user_id"
            columns={columns}
            dataSource={data_User_All}
            pagination={false}
            scroll={{ x: 800 }}
          />

          <Pagination
            current={page_limit.page}
            total={page_limit.limit}
            onChange={changePagination_users}
            style={{ marginTop: "20px", float: "right" }}
          />
          <p style={{ fontSize: "15px", marginTop: "25px" }}>
            {" "}
            <FcDataSheet size={20} style={{ marginBottom: "5px" }} />{" "}
            {t("total data")} : {page_limit.limit}
          </p>
        </div>
      )}

      {props.user_select !== null && (
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div style={{ display: "flex" }}>
              <p className="text-bold " style={{ marginTop: "7px" , width:'75px' }}>
                {t("User")}
              </p>
              <Input
                style={{ marginLeft: "28px", height: "35px" }}
                value={props.user_select}
                placeholder={props.user_select}
                disabled
              />
              <a>
                <EditOutlined
                  style={{ fontSize: "20px", marginLeft: "10px" }}
                  onClick={() => {
                    props.setCreate_service({
                      ...props.create_service,
                      cus_user_id: null,
                    })
                    setSearch_data_user(null)
                    props.setUser_select(null)
                  }}
                />
              </a>
            </div>
          </Col>
        </Row>
      )}
    </div>
  )
}
