import React, { useState, useEffect } from "react"
import { Select, Form } from "antd"
import axios from "axios"
import { useTranslation } from "react-i18next";

const { Option } = Select
export default function Select_no_search({ 
  header, keyState, headState 
  ,nameState ,map_name, placeholder, map_value , disabled, required, data_array_map
}) {
  const { t } = useTranslation();
    const token = localStorage.getItem("token")
    const [data_select, setData_select] = useState([])

    useEffect(() => {
      
        return () => {
          setData_select([])
        }
    }, [])

    useEffect(() => {
      setData_select(data_array_map)
      return () => {
        
      }
    }, [data_array_map])

    function onChange(value) {
      if(value){
        headState({ ...nameState, [keyState]: value })
      }
    }

    useEffect(() => {
        render_select_data()
        return () => {

        }
    }, [data_select])

    function render_select_data() {
      return data_select.map( data => {
        return  <Option key={data[map_value]} value={data[map_value]}>
                    {data[map_name]}
                </Option>
    })
    }

    function check_required() {
      if(required === true) return <span style={{ color:'red' }}>*</span>
    }

    return (
        <div>
        <p className="text-bold">
          {header} {check_required()}
        </p>
        <Form.Item
          name={keyState}
          rules={[
            {
              required: required,
              message:  `${placeholder}`,
            },
          ]}
        >
          <Select
            showSearch
            disabled={disabled}
            placeholder={ `${placeholder}`}
            style={{ width: "100%" }}
            optionFilterProp="children"
            onChange={onChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            { render_select_data() }
          </Select>
        </Form.Item>
      </div>
    )
}
