import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import Expand from "react-expand-animated"
import { FiChevronDown, FiChevronRight } from "react-icons/fi"
import { Button, Modal, notification, Form, Progress } from "antd"
import axios from "axios"
import { Row, Col } from "reactstrap"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import * as api from "../../../service/Api_Profile_Menu"
import helpers from "../../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"
import InputPassword from "./InputPassword"

const { confirm } = Modal

export default function ChangePasswordByAdmin({ idIsChange, roleIsChange }) {
  const { t } = useTranslation()
  const history = useHistory()
  const token = localStorage.getItem("token")
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [open, setOpen] = useState(false)
  const [icon, setIcon] = useState(
    <FiChevronRight size={20} style={{ marginBottom: "5px" }} />
  )
  const [pass, setPass] = useState(null)
  const [retypePass, setRetypePass] = useState(null)
  const [passStrong, setPassStrong] = useState({
    passwordTet: "",
    passwordColor: "",
    passwordNumber: 0,
  })

  const setToggle = () => {
    if (open === false) {
      setOpen(true)
      setIcon(<FiChevronDown size={25} style={{ marginBottom: "5px" }} />)
    } else {
      setOpen(false)
      setIcon(<FiChevronRight size={25} style={{ marginBottom: "5px" }} />)
    }
  }

  useEffect(() => {
    CheckPasswordStrength()
    return () => {}
  }, [pass])

  function CheckPasswordStrength() {
    if (pass) {
      var regex = new Array()
      regex.push("[A-Z]")
      regex.push("[a-z]")
      regex.push("[0-9]")
      regex.push("[$@$!%*#?&]")
      var passed = 0
      for (var i = 0; i < regex.length; i++) {
        if (new RegExp(regex[i]).test(pass)) {
          passed++
        }
      }
      if (passed > 2 && pass.length > 3) {
        passed++
      }
      var color = ""
      var passwordStrength = ""
      var number = 0
      switch (passed) {
        case 0:
          break
        case 1:
          passwordStrength = "Password is Weak."
          color = "Red"
          number = 25
          break
        case 2:
          passwordStrength = "Password is Good."
          color = "darkorange"
          number = 50
          break
        case 3:
          break
        case 4:
          passwordStrength = "Password is Strong."
          color = "Green"
          number = 75
          break
        case 5:
          passwordStrength = "Very Strong."
          color = "darkgreen"
          number = 100
          break
      }
      setPassStrong({
        passwordTet: passwordStrength,
        passwordColor: color,
        passwordNumber: number,
      })
    }
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  useEffect(() => {
    if (pass && retypePass) {
      if (pass !== retypePass) {
        setError(t("Password mismatch"))
      } else {
        setError(null)
      }
    }
    return () => {}
  }, [pass, retypePass])

  const onReset = () => {
    form.resetFields()
  }

  function cancelResetPassword() {
    onReset()
    setToggle()
    setError(null)
    setPass(null)
    setRetypePass(null)
    setPassStrong({
      ...passStrong,
      passwordTet: "",
      passwordColor: "",
      passwordNumber: 0,
    })
  }

  const onFinish = values => {
    return confirm({
      title: t("Confirm change password"),
      icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
      centered: true,
      okText: t("Confirm"),
      cancelText: t("no"),
      onOk() {
        setLoading(true)
        let dataIsChange = {
            role : roleIsChange,
            new_pass : pass
        }
        const changePass = api.CHANGE_PASSWORD_BY_ADMIN(token , idIsChange , dataIsChange )
        axios(changePass)
       .then( res => {
        setLoading(false)
           openNotificationWithIcon("success", t("change password success"))
           cancelResetPassword()
       })
       .catch( error => {
        setLoading(false)
           if(error){
               if(error.response){
                if(error.response.status === 401){
                    helpers.re_login()
                    history.push('/login')          
                    } else {
                      openNotificationWithIcon("error", t("change password error"))
                    }
               }
           }
       })
      },
      onCancel() {},
    })
  }

  return (
    <Row>
      <Col sm={12} md={6} lg={4} xl={4} onClick={() => setToggle()}>
        <a>
          {" "}
          <p style={{ fontSize: "20px" }}>
            {" "}
            {icon} {t("change password")}{" "}
          </p>{" "}
        </a>
      </Col>
      <Col sm={12} md={6} lg={8} xl={8}>
        <p style={{ color: "rgb(108, 117, 125)" }}>{t("change password")}</p>

        <Expand open={open} duration={500}>
          <div style={{ width: "100%", borderRadius: "17px", padding: "10px" }}>
            <Row>
              <Col sm={12} md={12} lg={6} xl={6}>
                <Form form={form} name="control-hooks" onFinish={onFinish}>
                  <InputPassword
                    header={t("New password")}
                    keyForm="new_password"
                    setState={setPass}
                  />

                  {passStrong.passwordNumber !== 0 && (
                    <div>
                      <Progress
                        percent={passStrong.passwordNumber}
                        size="small"
                        status="active"
                      />
                      <p
                        style={{
                          color: passStrong.passwordColor,
                          textAlign: "right",
                        }}
                      >
                        {passStrong.passwordTet}
                      </p>
                    </div>
                  )}

                  <InputPassword
                    header={t("Retype new password")}
                    keyForm="retype_password"
                    setState={setRetypePass}
                  />

                  {error && (
                    <p style={{ textAlign: "center", color: "red" }}>{error}</p>
                  )}

                  <Row>
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <div className="div_create_new_car">
                        <Button
                          className="btn_cancel_create_new_car"
                          onClick={() => cancelResetPassword()}
                        >
                          {t("Cancel")}
                        </Button>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <div className="div_create_new_car">
                        <Button
                          className="btn_submit_create_new_car"
                          type="primary"
                          htmlType="submit"
                          disabled={error ? true : false}
                          loading={loading}
                        >
                          {t("Edit")}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col sm={0} md={0} lg={6} xl={6}></Col>
            </Row>
          </div>
        </Expand>
      </Col>
      <hr />
    </Row>
  )
}
