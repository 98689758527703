import React from 'react'
import Detail_Dealer_My_DB from './Detail_Dealer_My_DB'
import Detail_Dealer_Distar from './Detail_Dealer_Distar'

export default function Create_New_Dealer(props) {
    return (
        <div>
            {
                props.found_my_dealer === true && (
                    <Detail_Dealer_My_DB 
                        dealer_info={props.dealer_info}
                    />
                )
            }

            {
                props.found_my_dealer === false && (
                    <Detail_Dealer_Distar 
                        detail_dealer_distar={props.detail_dealer_distar}
                        setNew_dealer={props.setNew_dealer}
                        new_dealer={props.new_dealer}
                    />
                )
                }
        </div>
    )
}
