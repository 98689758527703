import React from "react"
import { Row, Col } from "reactstrap"
import Render_Center_Avatar from "../components/admin/Render_Center_Avatar"
import Colum from "../components/admin/Colum"
import { useTranslation } from "react-i18next"

export default function Datail_Operator({ detail_admin }) {
  const { t } = useTranslation()
  const text_header = {
    fontWeight: "bold",
    fontSize: "15px",
  }

  return (
    <div>
      {detail_admin !== null && (
        <div>
          <p
            style={{
              textAlign: "center",
              fontWeight: "bold",
              marginTop: "10px",
              fontSize: "15px",
            }}
          >
            {t("Admin information")}
          </p>

          <Row>
            <Col>
              <Render_Center_Avatar
                path_image={detail_admin.admin_avatar}
                size={120}
              />
              <br />
            </Col>
          </Row>
          <br />
          <Row>
            <Colum
              header_text={t("firstname")}
              value_text={detail_admin.admin_firstname}
            />
            <Colum
              header_text={t("lastname")}
              value_text={detail_admin.admin_lastname}
            />

            <Colum
              header_text={t("code")}
              value_text={detail_admin.admin_code}
            />
            <Colum
              header_text={t("email")}
              value_text={detail_admin.admin_email}
            />
            <Colum
              header_text={t("Admin group")}
              value_text={detail_admin.admin_group_name}
            />
          </Row>
        </div>
      )}
    </div>
  )
}
