import React, { useEffect, useState } from "react"
import { Row, Col } from "reactstrap"
import { Collapse } from "antd"
import { useTranslation } from "react-i18next";

const { Panel } = Collapse

export default function Detail_Stock(props) {
  const [stock, setStock] = useState([])
  const [sim, setSim] = useState([])
  const [name_sim, setName_sim] = useState(null)
  const [name_device, setName_device] = useState(null)
  const { t } = useTranslation();

  const text_header = {
    fontWeight: "bold",
    fontSize: "15px",
  }

  function checkData(data) {
    if (data) {
      return data
    } else {
      return "N/A"
    }
  }

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return "N/A"
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  useEffect(() => {
    setName_sim(props.data_device.sim_id)
    setName_device(props.data_device.device_group)
    setStock([
      {
        header1: t("Car id"),
        dataContract1: checkData(props.data_device.car_id),
        header2: t("Car title"),
        dataContract2: checkData(props.data_device.car_title),
      },
      {
        header1: t("Device create on"),
        dataContract1: cutTimeZone(props.data_device.device_createdon),
        header2: t("Device serial number"),
        dataContract2: checkData(props.data_device.device_serial_number),
      },
      {
        header1: t("Device group"),
        dataContract1: checkData(props.data_device.device_group),
        header2: t("Device id"),
        dataContract2: checkData(props.data_device.device_type_id),
      },
      {
        header1: t("Device type protocol"),
        dataContract1: checkData(props.data_device.device_type_protocol),
        header2: t("Device title"),
        dataContract2: checkData(props.data_device.device_title),
      },
      {
        header1: t("Device remark"),
        dataContract1: checkData(props.data_device.device_remark),
        header2: "iccid",
        dataContract2: checkData(props.data_device.iccid),
      },
    ])
    setSim([
      {
        header1: t("Sim id"),
        dataContract1: checkData(props.data_device.sim_id),
        header2: t("Sim phone"),
        dataContract2: checkData(props.data_device.sim_phone),
      },
      {
        header1: t("Sim create on"),
        dataContract1: cutTimeZone(props.data_device.sim_createdon),
        header2: t("Sim remark"),
        dataContract2: checkData(props.data_device.sim_remark),
      },
    ])
    return () => {}
  }, [props.data_device])

  return (
    <div>
      <p
        style={{
          textAlign: "center",
          fontWeight: "bold",
          marginTop: "10px",
          fontSize: "15px",
        }}
      >
        {t("Stock information")}
      </p>
      <hr />
      <Collapse>
        <Panel header={name_device ? `${t("Device")} : ${name_device}` : t("No device") } key="1">
          {stock.map((data, index) => {
            return (
              <Row key={index}>
                <Col sm={6} md={2} lg={2} xl={2}>
                  <p style={text_header}>{data.header1}</p>
                </Col>
                <Col sm={6} md={4} lg={4} xl={4}>
                  <p style={{ fontSize: "15px" }}>
                    {" "}
                    <span className="tet">:</span>{" "}
                    <span className="tetTwo">-</span> {data.dataContract1}
                  </p>
                </Col>
                <Col sm={6} md={2} lg={2} xl={2}>
                  <p style={text_header}>{data.header2}</p>
                </Col>
                <Col sm={6} md={4} lg={4} xl={4}>
                  <p style={{ fontSize: "15px" }}>
                    {" "}
                    <span className="tet">:</span>{" "}
                    <span className="tetTwo">-</span> {data.dataContract2}
                  </p>
                </Col>
              </Row>
            )
          })}
        </Panel>
        <Panel header={name_sim ? `${t("Sim")} : ${name_sim}` : t("No sim") } key="2">
          {sim.map((data, index) => {
            return (
              <Row key={index}>
                <Col sm={6} md={2} lg={2} xl={2}>
                  <p style={text_header}>{data.header1}</p>
                </Col>
                <Col sm={6} md={4} lg={4} xl={4}>
                  <p style={{ fontSize: "15px" }}>
                    {" "}
                    <span className="tet">:</span>{" "}
                    <span className="tetTwo">-</span> {data.dataContract1}
                  </p>
                </Col>
                <Col sm={6} md={2} lg={2} xl={2}>
                  <p style={text_header}>{data.header2}</p>
                </Col>
                <Col sm={6} md={4} lg={4} xl={4}>
                  <p style={{ fontSize: "15px" }}>
                    {" "}
                    <span className="tet">:</span>{" "}
                    <span className="tetTwo">-</span> {data.dataContract2}
                  </p>
                </Col>
              </Row>
            )
          })}
        </Panel>
      </Collapse>
    </div>
  )
}
