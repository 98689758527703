import React,{ useEffect, useState } from "react"
import { Row, Col } from "reactstrap"
import { Button, Input, Form, Space } from "antd"
import { useHistory } from "react-router-dom"
import { AiOutlineCar} from "react-icons/ai"
import { CarOutlined,  SearchOutlined } from "@ant-design/icons"
import Select_search from './select_search'
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux"

const { Search } = Input;

export default function Search_car(props) {
  const { t } = useTranslation();
  const permi = localStorage.getItem('permi')
  const [permi_t, setPermi_t] = useState(false)
  const { permissionAllService } = useSelector(({ Distar }) => Distar)

useEffect(() => {
  if(permissionAllService){
    if(permissionAllService.car){
      setPermi_t(permissionAllService.car.write)
    }
  }
  return () => {
    // setPermi_t(false)
  }
}, [permissionAllService])

    const history = useHistory()

    const onFinish = values => {
      props.search_data()
    }

    return (
        <div>
        <Form
          name="Search_car"
          initialValues={{
            remember: false,
          }}
          onFinish={onFinish}
        >
          <Row>
            <Col sm={12} md={6} lg={6} xl={4}>
              <Form.Item
                name="search"
                rules={[
                  {
                    required: false,
                    message: "Please input name,department",
                  },
                ]}
              >
              <Input
                  placeholder={t("Search")}
                  style={{ marginBottom: "15px" }}
                  onChange={e => props.setSearch_car(e.target.value)}
              /> 
              </Form.Item>
            </Col>
            <Col sm={12} md={6} lg={4} xl={4}>
              <Button
                type="primary"
                className="button-search-employee"
                htmlType="submit"
              >
              {t("Search")}
              </Button>
{
  permi_t === true && (
    <Button
    className="button-search-New-Employee"
    onClick={() => history.push("/new-cars")}
    icon={<AiOutlineCar style={{ fontSize: "20px", marginRight:'10px', marginBottom:'3px' }}/>}
  >
    {t("New car")}
  </Button>
  )
}

            </Col>
          </Row>
        </Form>

        <Select_search 
        search_car_info={props.search_car_info}
        setSearch_car_info={props.setSearch_car_info}
        />
        <br />
      </div>
    )
}
