import React, { useState } from "react"
import { Row, Col } from "reactstrap"
import { FaUserCheck, FaUserEdit } from "react-icons/fa"
import { useTranslation } from "react-i18next";

export default function Select_Dealer(props) {
    const [default_style, setDefault_style] = useState("rgb(99 99 99 / 20%) 0px 2px 8px 0px")
    const [custom_style, setCustom_style] = useState(null)
    const [default_icon_color, setDefault_icon_color] = useState("#1890ff")
    const [custom_icon_color, setCustom_icon_color] = useState("#C7C7C7")
    const { t } = useTranslation();

    function setGender(event) {
        if (event.target.value === "DEFAULT") {
          setDefault_icon_color("#1890ff")
          setCustom_icon_color("#C7C7C7")
          props.setSelect_radio("DEFAULT")
          setCustom_style(null)
          setDefault_style("rgb(99 99 99 / 20%) 0px 2px 8px 0px")
        } else {
          setDefault_icon_color("#C7C7C7")
          setCustom_icon_color("#1890ff")
          props.setSelect_radio("CUSTOM")
          setCustom_style("rgb(99 99 99 / 20%) 0px 2px 8px 0px")
          setDefault_style(null)
        }
      }

    return (
        <div>
        <p
          style={{
            fontSize: "15px",
            fontWeight: "bold",
            color: "#495057",
            marginTop: "20px",
          }}
        >
          {t("Select dealer")} <span style={{ color: "red" }}>*</span>{" "}
        </p>
        <div onChange={setGender}>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <div
                style={{
                  width: "100%",
                  height: "80px",
                  boxShadow: default_style
                }}
              >
                <Row>
                  <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                    <input
                      type="radio"
                      value="DEFAULT"
                      name="select_dealer"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginTop: "30px",
                        marginLeft: "20px",
                      }}
                      defaultChecked
                    />
                  </Col>
                  <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                    <FaUserCheck
                      size={50}
                      style={{ marginTop: "15px", color: default_icon_color }}
                    />
                  </Col>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: "#495057",
                        marginTop: "30px",
                      }}
                    >
                 
                      {t("Default dealer from distar")}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
  
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <div
                style={{
                  width: "100%",
                  height: "80px",
                  boxShadow:custom_style
                }}
              >
                <Row>
                  <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                    <input
                      type="radio"
                      value="CUSTOM"
                      name="select_dealer"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginTop: "30px",
                        marginLeft: "20px",
                      }}
                    />
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                    <FaUserEdit
                      size={50}
                      style={{ marginTop: "15px", color: custom_icon_color }}
                    />
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: "#495057",
                        marginTop: "30px",
                      }}
                    >
                   
                      {t("New dealer")}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    )
}
