import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { useHistory, withRouter, Link } from "react-router-dom"
import Swal from "sweetalert2"
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import logo_distar from "../../assets/Distar/logo_login.jpeg"
import { Form, Input, Button, Progress } from "antd"
import axios from "axios"
import * as api from "../../service/Api_Verification_Email"

const url_string = window.location.href
const url = new URL(url_string)
const token = url.searchParams.get("token_verification")

export default function Verification_Email() {
  const [error, setError] = useState(null)
  const [token_expire, setToken_expire] = useState(false)
  const history = useHistory()
  const [detail_info_email, setDetail_info_email] = useState(null)
  const [pass, setPass] = useState(null)
  const [passStrong, setPassStrong] = useState({
    passwordTet: "",
    passwordColor: "",
    passwordNumber: 0,
  })

  useEffect(() => {
    check_User_Info()
    return () => {}
  }, [])

  function check_User_Info() {
    const data = {
      token: token,
    }
    const check_info = api.CHECK_USER_INFO(data)
    axios(check_info)
      .then(res => {
        setDetail_info_email(res.data.email)
      })
      .catch(error => {
        // console.log('check_User_Info error');
        // console.log(error.response);
        setToken_expire(true)
        return_alert(
          "Error", 
          error.response.data.message, 
          "error", 
          false
          )
      })
  }

  const onFinish = values => {
    if (values.password === values.re_password) {
      return Swal.fire({
        title: "Please confirm create password",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#CFD4D6",
        confirmButtonText: "Confirm",
      }).then(result => {
        if (result.isConfirmed) {
          const data = {
            token: token,
            password: values.password,
          }

          const create_pass = api.CREATE_PASSWORD(data)
          axios(create_pass)
            .then(res => {
              // console.log('create pass success');
              const data = {
                email: detail_info_email,
                password: values.password,
              }
              const login_user = api.LOGIN_PASSWORD(data)
              axios(login_user)
                .then(res => {
                  // console.log('login success');
                  localStorage.setItem("token", res.data.token)
                  localStorage.setItem("role", res.data.role)
                  localStorage.setItem('_id',res.data.user_id)
                  return Swal.fire({
                    title: "Verification email success",
                    text: "Redirect to dashboard",
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "OK",
                    timer: 5000,
                    willClose: () => {
                      history.push("/dashboards")
                    },
                  }).then(result => {
                    if (result.isConfirmed) {
                      history.push("/dashboards")
                    }
                  })
                })
                .catch(error => {
                  // console.log('login error');
                  // console.log(error.response);

                  return_alert(
                    "Error", 
                    error.response.data.message, 
                    "error", 
                    false
                    )
                })
            })
            .catch(error => {
              // console.log('create pass error');
              // console.log(error.response.data.message);
              return_alert(
                "Error", 
                error.response.data.message, 
                "error", 
                false
                )
            })
        }
      })
    } else {
      setError("Password mismatch")
    }
  }

  function return_alert(title, text, icon, showCancel) {
    return Swal.fire({
      title: title,
      text: text,
      icon: icon,
      showCancelButton: showCancel,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "OK",
      timer: 5000,
      willClose: () => {
        // history.push('/login')
      },
    }).then(result => {
      if (result.isConfirmed) {
        // history.push('/login')
      }
    })
  }

  useEffect(() => {
    CheckPasswordStrength()
    return () => {}
  }, [pass])

  function CheckPasswordStrength() {
    if (pass) {
      var regex = new Array()
      regex.push("[A-Z]") //For Uppercase Alphabet
      regex.push("[a-z]") //For Lowercase Alphabet
      regex.push("[0-9]") //For Numeric Digits
      regex.push("[$@$!%*#?&]") //For Special Characters
      var passed = 0
      for (var i = 0; i < regex.length; i++) {
        if (new RegExp(regex[i]).test(pass)) {
          passed++
        }
      }
      if (passed > 2 && pass.length > 3) {
        passed++
      }
      var color = ""
      var passwordStrength = ""
      var number = 0
      switch (passed) {
        case 0:
          break
        case 1:
          passwordStrength = "Password is Weak."
          color = "Red"
          number = 25
          break
        case 2:
          passwordStrength = "Password is Good."
          color = "darkorange"
          number = 50
          break
        case 3:
          break
        case 4:
          passwordStrength = "Password is Strong."
          color = "Green"
          number = 75
          break
        case 5:
          passwordStrength = "Very Strong."
          color = "darkgreen"
          number = 100
          break
      }
      setPassStrong({
        passwordTet: passwordStrength,
        passwordColor: color,
        passwordNumber: number,
      })
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Verification Email | Distar</title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft">
                  <Row>
                    <img
                      src={logo_distar}
                      alt="logo"
                      style={{
                        width: "80%",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: "50px",
                        marginTop: "100px",
                      }}
                    />
                  </Row>
                </div>
                <CardBody className="pt-0">
                  {token_expire !== true && (
                    <Form
                      name="basic"
                      initialValues={{
                        remember: true,
                      }}
                      onFinish={onFinish}
                    >
                      <p style={{ fontWeight: "bold" }}>
                        Password <span style={{ color: "red" }}>*</span>
                      </p>
                      <Form.Item
                        label=""
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your password!",
                          },
                        ]}
                      >
                        <Input.Password
                          onChange={e => setPass(e.target.value)}
                        />
                      </Form.Item>

                      {passStrong.passwordNumber !== 0 && (
                        <div>
                          <Progress
                            percent={passStrong.passwordNumber}
                            size="small"
                            status="active"
                          />
                          <p
                            style={{
                              color: passStrong.passwordColor,
                              textAlign: "right",
                            }}
                          >
                            {passStrong.passwordTet}
                          </p>
                        </div>
                      )}

                      <p style={{ fontWeight: "bold" }}>
                        Confirm Password <span style={{ color: "red" }}>*</span>
                      </p>
                      <Form.Item
                        label=""
                        name="re_password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your password!",
                          },
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>

                      {error && (
                        <p style={{ textAlign: "center", color: "red" }}>
                          {error}
                        </p>
                      )}
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{ width: "100%" }}
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  )}

                  {token_expire && (
                    <Button
                      type="primary"
                      style={{ width: "100%" }}
                      onClick={() => history.push("/login")}
                    >
                      return to login
                    </Button>
                  )}
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>Design & Develop by NokSoft</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
