import React,{ useState, useEffect } from 'react'
import { Form, Button, Modal, notification } from 'antd';
import { Row, Col } from "reactstrap"
import Input_Comp from './components/Input_Comp'
import Input_Date_Comp from './components/Input_Date_Comp'
import Select_no_search from './components/Select_no_search'
import List_Stock from './components/List_Stock'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import * as api from '../../../../service/Api_New_Service'
import axios from 'axios'

const { confirm } = Modal;

export default function Create_new_service(props) {
    const token = localStorage.getItem('token')
    const [data_new, setData_new] = useState({})
    const [data_stock, setData_stock] = useState([])
    const [stock_name, setStock_name] = useState(null)
    const [check_code_dup, setCheck_code_dup] = useState(false)
    const [loading_generate, setLoading_generate] = useState(false)
    const [form] = Form.useForm();

    useEffect(() => {
        setData_new({
            con_id : props.con_id,
            ser_type_id : 1
            })
        return () => {
            setData_new({})
            setStock_name(null)
        }
    }, [props.con_id])

    useEffect(() => {
        function check_duplicate_service_number() {
            if(data_new.ser_no){
                const check_duplicate_ser_no = api.FIND_DUPLICATE_CODE(token, data_new.ser_no)
                axios(check_duplicate_ser_no)
                .then( res => {
                    if(res.data === 'True'){
                        setCheck_code_dup(true)
                       } else {
                        setCheck_code_dup(false)
                       }
                })
                .catch( error => {
                    console.log(error.response);
                })
            }
        }

        check_duplicate_service_number()
        return () => {
            setCheck_code_dup(false)
        }
    }, [data_new.ser_no])

    function generate() {
        setLoading_generate(true)
        const generate_code = api.GENERATE_SERVICE_CODE(token)
        axios(generate_code)
        .then( res => {
            setData_new({
                ...data_new,
                'ser_no': res.data.code
            })
            form.setFieldsValue({
                ser_no: res.data.code
              })
            setLoading_generate(false)
        })
        .catch( error => {
            console.log(error.response);
            setLoading_generate(false)
        })
    }

    const onFinish = (values) => {
        // if(!data_new.sto_id || data_new.sto_id === null){
        //     return   Modal.error({
        //         title: 'Please select stock',
        //         centered:true
        //       });
        // } else {
            return confirm({
                title: 'Confirm create new service',
                icon: <ExclamationCircleOutlined style={{ color:'#1890ff' }} />,
                okText: 'Confirm',
                centered:true ,
                onOk() {
                    const create_new_service = api.CREATE_NEW_SERVICE(token , data_new)
                    axios(create_new_service)
                    .then( res => {
                        openNotificationWithIcon('success', 'Create new service success')
                        props.setActiveTab_service('1')
                        props.search_service()
                        cancel()
                    })
                    .catch( error => {
                        console.log(error.response);
                        openNotificationWithIcon('error', 'Error create')
                    })
                },
                onCancel() {
            
                },
              });
        // }
    };

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
          message: message,
        });
      };

    function cancel() {
        setData_new({
        con_id : props.con_id,
        ser_type_id : 1
        })
        form.resetFields();
        setStock_name(null)
        props.setActiveTab_service('1')
    }
// console.log(props.cus_id);
    return (
        <div>
        <Form
            name="create_new_service_data_info"
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            form={form}
        >

        <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
            <Button type="primary" style={{ float:'right' }} onClick={() => generate()} loading={loading_generate}>Generate number</Button>
                <Input_Comp 
                    placeholder='service number'
                    header='Service number'
                    headState={setData_new}
                    nameState={data_new}
                    type="text"
                    keyState='ser_no'
                />
                {
                    check_code_dup && (
                        <p style={{ color:'red' }}>Service number in duplicate</p>
                    )
                }
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
                <Input_Comp 
                    placeholder='service name'
                    header='Service name'
                    headState={setData_new}
                    nameState={data_new}
                    keyState='ser_name'
                    type="text"
                />
            </Col>
        </Row>
        <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
                <Input_Comp 
                    placeholder='service contact name'
                    header='Service contact name'
                    headState={setData_new}
                    nameState={data_new}
                    type="text"
                    keyState='ser_contact_name'
                />
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
                <Input_Comp 
                    placeholder='service contact phone'
                    header='Service contact phone'
                    headState={setData_new}
                    nameState={data_new}
                    keyState='ser_contact_phone'
                    type="number"
                />
            </Col>
        </Row>
        <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
                <Input_Date_Comp 
                    header="Service date start" 
                    placeholder="service date start"
                    headState={setData_new}
                    nameState={data_new}
                    keyState="ser_plan_startdate"
                />
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
                <Input_Date_Comp 
                    header="Service date end" 
                    placeholder="service date end"
                    headState={setData_new}
                    nameState={data_new}
                    keyState="ser_plan_enddate"
                />
            </Col>
        </Row>
        <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
                <Select_no_search 
                    header="Priority"
                    placeholder="priority"
                    name_api='LIST_PRIORITY'
                    map_name="pri_name"
                    map_value="pri_id"
                    keyState="pri_id"
                    headState={setData_new}
                    nameState={data_new}
                />
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
                <Select_no_search 
                    header="Status"
                    placeholder="status"
                    name_api='LIST_STATUS'
                    map_name="ser_status_name"
                    map_value="ser_status_id"
                    keyState="ser_status_id"
                    headState={setData_new}
                    nameState={data_new}
                />
            </Col>
        </Row>
        <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
                <Select_no_search 
                    header="Receive type"
                    placeholder="receive type"
                    name_api='LIST_RECEIVED_TYPE'
                    map_name="ser_received_type_name"
                    map_value="ser_received_type_id"
                    keyState="ser_received_type_id"
                    headState={setData_new}
                    nameState={data_new}
                />
            </Col>
        </Row>

{/*        <Row>
            <Col>
                <List_Stock 
                    setStock_name={setStock_name}
                    stock_name={stock_name}
                    setData_stock={setData_stock}
                    data_stock={data_stock}
                    setData_new={setData_new}
                    data_new={data_new}
                    cus_id={props.cus_id}
                />
            </Col>
</Row> */}
        
      <br />
        <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
                <div className="div_create_new_car">
                    <Button className="btn_cancel_create_new_car" onClick={() => cancel()}> Cancel </Button>   
                </div>
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
                <div className="div_create_new_car">
                    <Button className="btn_submit_create_new_car" type="primary" htmlType="submit" disabled={check_code_dup} > Create </Button>
                </div>
            </Col>
        </Row>
      </Form>
        </div>
    )
}