import React, { useState } from 'react'
import { withRouter, useHistory  } from "react-router-dom"
import Layout from './components/HorizontalLayout'

const Auth = ({children}) => {
    const history = useHistory()
    const [userData, setUserData] = useState(localStorage.getItem('token'))
    if(!userData) {
        history.push('/login')
    }

    if(userData === undefined) return <p>Loading . . .</p>
    else {
        return <Layout>{children}</Layout>
    }

}
export default withRouter(Auth)