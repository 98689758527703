import React from "react"
import { Input } from "antd"

export default function Input_Comp({
  placeholder,
  header,
  keyState,
  type,
  index_user,
  user,
  setUser,
  disabled
}) {

    function handle_field(e) {
        const values = [...user]
        values[index_user][keyState] = e.target.value
        setUser(values)
    }
    
  return (
    <div>
      <p className="text-bold" style={{ marginTop:'20px' }}>
        {header} <span style={{ color:'red' }}>*</span>
      </p>
      <Input
          disabled={disabled}
          type={type}
          placeholder={placeholder}
          onChange={e => handle_field(e)}
          value={user[index_user][keyState]}
        />
    </div>
  )
}
