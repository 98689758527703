import React,{ useState, useEffect } from 'react'
import Demo_ma_assets from '../../Assets/mockup_demo/Demo_ma_assets'
import { Table } from "antd"

export default function Detail_ma({ detail }) {
    const [data_history, setData_history] = useState(null)
    const data_ma = Demo_ma_assets

    function render_detail_setup() {
        let test = null
        data_ma.map( data =>{
            if(data.code === detail.code){
                test = data.history
              
            }
        })
        setData_history(test)
    }

    useEffect(() => {
        render_detail_setup()
        return () => {
    
        }
    }, [data_ma, detail])

    useEffect(() => {
        render_table()

    }, [data_history])

    function render_table() {
    return (
        <div>
        <Table columns={columns} dataSource={data_history} rowKey="id"  scroll={{ x: 1000 }} />
        </div>
    )
       
    }

    const columns = [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
        },
        {
          title: "Date setup",
          dataIndex: "date_setup",
          key: "date_setup",
        },
        {
          title: "Name asset",
          dataIndex: "name_asset",
          key: "name_asset",
        },
        {
          title: "SN",
          dataIndex: "sn",
          key: "sn",
        },
        {
          title: "IMEI",
          dataIndex: "imei",
          key: "imei",
        },
        {
          title: "Name maintenance",
          dataIndex: "name_ma",
          key: "name_ma",
        },
      ]

    return (
        <div>
        {render_table()}
        </div>
    )
}
