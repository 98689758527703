import React from "react"
import { Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next"
import Colum from "../../components/admin/Colum"
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser"

export default function Detail_Repair(props) {
  const { t } = useTranslation()

  function checkData(data) {
    if (data) {
      return data
    } else {
      return "N/A"
    }
  }

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return "N/A"
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  function render_request_by(detail_repair) {
    if (detail_repair.request_by_role === "dealer") {
      return (
        <Colum
          header_text={t("Requested by")}
          value_text={detail_repair.request_by.dea_user_firstname}
        />
      )
    } else if (detail_repair.request_by_role === "customer") {
      return (
        <Colum
          header_text={t("Requested by")}
          value_text={detail_repair.request_by.cus_user_firstname}
        />
      )
    }
  }

  // ser_issue_reported: "<p>1234</p>"

  return (
    <div>
      <p className="text-bold"> {t("Repair information")} </p>
      <div
        style={{
          border: "1px solid #f0f0f0",
          padding: "20px",
        }}
      >
        <Row>
          <Colum
            header_text={t("Contact name")}
            value_text={props.detail_repair.ser_contact_name}
          />
          <Colum
            header_text={t("Contact phone")}
            value_text={props.detail_repair.ser_contact_phone}
          />
          <Colum
            header_text={t("Service Province")}
            value_text={props.detail_repair.pro_name}
          />
          <Colum
            header_text={t("Service District")}
            value_text={props.detail_repair.dis_name}
          />
          <Colum
            header_text={t("Service sub-district")}
            value_text={props.detail_repair.subdis_name}
          />
          <Colum
            header_text={t("On-Site address")}
            value_text={props.detail_repair.ser_onsite_address}
          />
          {render_request_by(props.detail_repair)}
          <Colum
            header_text={t("position")}
            value_text={t(props.detail_repair.request_by_role)}
          />
        </Row>
        <Row>
          <p className="text-bold"> {t("Issue reported")} </p>
          <Col>{ReactHtmlParser(props.detail_repair.ser_issue_reported)}</Col>
        </Row>
      </div>
    </div>
  )
}
