import React, { useState, useEffect } from "react"
import { Container, Card, CardBody } from "reactstrap"
import MetaTags from "react-meta-tags"
import Table_Scheduler from "./Table_Scheduler"
import { useHistory } from "react-router-dom"
import { MdKeyboardBackspace } from "react-icons/md"
import no_permi from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const Scheduler = props => {
  const history = useHistory()
  const { t } = useTranslation()
  const permi = localStorage.getItem("permi")
  const [permi_t_read, setPermi_t_read] = useState(false)
  const { permissionAllService } = useSelector(({ Distar }) => Distar)
  useEffect(() => {
    if (permissionAllService) {
      if (permissionAllService.system_setting) {
        setPermi_t_read(permissionAllService.system_setting.read)
      }
    }
    return () => {}
  }, [permissionAllService])

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Scheduler")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/system-setting")}
              />
            </a>{" "}
            {t("Scheduler")}
          </h3>
          <Card>
            <CardBody>
              {permi_t_read === true && <Table_Scheduler />}

              {permi_t_read === false && (
                <div>
                  <img
                    src={no_permi}
                    alt="no permission"
                    style={{ display: "block", margin: "50px auto" }}
                  />
                  <p style={{ textAlign: "center", fontSize: "20px" }}>
                    {t("no permission read module")}
                  </p>
                  <p style={{ textAlign: "center", fontSize: "20px" }}>
                    {" "}
                    {t("please contract administrator")}{" "}
                  </p>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Scheduler
