import React from "react"
import { InputNumber, Form, Input } from "antd"
import { useTranslation } from "react-i18next"

export default function Input_Comp({
  placeholder,
  header,
  headState,
  nameState,
  keyState,
  disabled,
  max
}) {
  const { t } = useTranslation()

  function onChange(value) {
    headState({
      ...nameState,
      [keyState]: value,
    })
  }

  return (
    <div>
      <p className="text-bold">
        {header} <span style={{ color: "red" }}>*</span>
      </p>
      <Form.Item
        name={keyState}
        rules={[
          {
            required: true,
            message: `Please input ${placeholder}`,
          },
        ]}
      >
        <InputNumber 
          min={0} 
          max={max}
          style={{ width: "100%" }}
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
        />
      </Form.Item>
    </div>
  )
}
