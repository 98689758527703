var Ip = require('../Ip')

export const GET_DASHBOARD_ALL = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}dashboard/operator`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const JOB_ASSIGNED = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/operator/job/job-assigned`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };