var Ip = require('../service/Ip')

export const CHECK_FILE = ( fup_path ) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}storage/file/${fup_path}`,
      responseType: 'arraybuffer',
      headers: {
        // 'Content-Type': 'application/json',
        // 'x-access-token' : token
      }
    };
  };