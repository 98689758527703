var Ip = require('../service/Ip')

export const CHECK_DEALER_TAX_ID_DUPLICATE = (token, dea_taxid) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/dealer/check-is-tax-duplicate/${dea_taxid}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CHECK_DEALER_EMAIL_DUPLICATE = (token, email) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/dealer/checkmaildup/${email}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_DEPARTMENT = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/department`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_POSITION = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/position`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CHECK_POSTCODE = (postcode) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/location/zip/${postcode}`,
      headers: {
        'Content-Type': 'application/json'
      }
    };
  };

  export const CREATE_DEALER = (token, data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/dealer`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const LIST_ROLE_ALL = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}distar/users/types`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  
  export const LIST_DEALER_FROM_DB_DATA = (token, dealer_id , page, pageSize, data) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}distar/users/types/${dealer_id}?page=${page}&limit=${pageSize}&order_by=DESC&q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_DEALER_FROM_DB_NO_DATA = (token, dealer_id , page, pageSize) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}distar/users/types/${dealer_id}?page=${page}&limit=${pageSize}&order_by=DESC&q=`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const FIND_ALL_GROUP = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/product/group`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };