var Ip = require('../service/Ip')

export const LOGIN = (data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}auth/login`,
      headers: {
        'Content-Type': 'application/json',
      },
      data : data
    };
  };

  export const LOGIN_LINE = (data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}auth/login/line`,
      headers: {
        'Content-Type': 'application/json',
      },
      data : data
    };
  };
  