import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import Render_Card from "./components/Render_Card"
import "../Dashboard_style.css"
import { List, Avatar, Space, Pagination } from "antd"
import { MessageOutlined, LikeOutlined, StarOutlined } from "@ant-design/icons"

export default function All_user(props) {
  const [data_pagination, setData_pagination] = useState({
    page: 1,
    minValue: 0,
    maxValue: 4,
  })
  useEffect(() => {
    setImageSize()
    return () => {
      setData_pagination({
        ...data_pagination,
        page: 1,
        minValue: 0,
        maxValue: 4,
      })
    }
  }, [])

  const setImageSize = () => {
    if (window.innerWidth < 768) {
      return "small"
    } else {
      return null
    }
  }

  const handleChange = (page, pageSize) => {
    setData_pagination({
      ...data_pagination,
      page: page,
      minValue: (page - 1) * pageSize,
      maxValue: page * pageSize,
    })
  }

  function render_card(data) {
    switch (data.role) {
      case "administrator":
        return (
          <Render_Card
            avatar={data.admin_avatar}
            firstname={data.admin_firstname}
            lastname={data.admin_lastname}
            role={data.role}
          />
        )
        break
      case "employee":
        return (
          <Render_Card
            avatar={data.emp_avatar}
            firstname={data.emp_firstname}
            lastname={data.emp_lastname}
            role={data.role}
          />
        )
        break
      case "customer_user":
        return (
          <Render_Card
            avatar={data.cus_user_avatar}
            firstname={data.cus_user_firstname}
            lastname={data.cus_user_lastname}
            role={data.role}
          />
        )
        break
      case "operator":
        return (
          <Render_Card
            avatar={data.ope_avatar}
            firstname={data.ope_firstname}
            lastname={data.ope_lastname}
            role={data.role}
          />
        )
        break
      case "dealer_user":
        return (
          <Render_Card
            avatar={data.dea_user_avatar}
            firstname={data.dea_user_firstname}
            lastname={data.dea_user_lastname}
            role={data.role}
          />
        )
        break
      default:
        break
    }
  }

  return (
    <div style={{ width: "100%" }}>
      <div>
        <Row>
          {props.all_user &&
            props.all_user.length > 0 &&
            props.all_user
              .slice(data_pagination.minValue, data_pagination.maxValue)
              .map((val, index) => (
                <Col xs={12} sm={12} md={4} lg={3} xl={3} key={index}>
                  {render_card(val)}
                </Col>
              ))}
        </Row>
        <Pagination
          size={setImageSize()}
          style={{ float: "right", marginTop: "20px" }}
          defaultPageSize={4}
          defaultCurrent={1}
          current={data_pagination.page}
          onChange={handleChange}
          total={props.all_user.length}
          pageSizeOptions={["4", "10", "20", "50", "100"]}
        />
      </div>
    </div>
  )
}
