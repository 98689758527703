import React, { useEffect, useState } from "react"
import { Row, Col } from "reactstrap"
import { Collapse } from "antd"
import Form_Create_New_Dealer from "./Form_Create_New_Dealer"
import Select_Dealer from "./Select_Dealer"
import { useTranslation } from "react-i18next";

const { Panel } = Collapse
// CUSTOM
// DEFAULT
export default function Detail_Dealer_Distar(props) {
  const [dealer_info, setDealer_info] = useState([])
  const [select_radio, setSelect_radio] = useState("DEFAULT")
  const { t } = useTranslation();

  useEffect(() => {
    if (props.detail_dealer_distar !== undefined) {
      setDealer_info([
        {
          head_data1: "name",
          value_data1: check_data(props.detail_dealer_distar.name),
          head_data2: "nickname",
          value_data2: check_data(props.detail_dealer_distar.nickname),
        },
        {
          head_data1: "pfid",
          value_data1: check_data(props.detail_dealer_distar.pfid),
          head_data2: "pid",
          value_data2: check_data(props.detail_dealer_distar.pid),
        },
        {
          head_data1: "gid",
          value_data1: check_data(props.detail_dealer_distar.gid),
          head_data2: "cid",
          value_data2: check_data(props.detail_dealer_distar.cid),
        },
        {
          head_data1: "email",
          value_data1: check_data(props.detail_dealer_distar.email),
          head_data2: "phone",
          value_data2: check_data(props.detail_dealer_distar.phone),
        },
        {
          head_data1: "addr",
          value_data1: check_data(props.detail_dealer_distar.addr),
          head_data2: "remark",
          value_data2: check_data(props.detail_dealer_distar.remark),
        },
        {
          head_data1: "ctime",
          value_data1: cutTimeZone(props.detail_dealer_distar.ctime),
          head_data2: "exp",
          value_data2: cutTimeZone(props.detail_dealer_distar.exp),
        },
      ])
    } else {
      setSelect_radio("CUSTOM")
    }

    return () => {
      setSelect_radio("DEFAULT")
      setDealer_info([])
    }
  }, [])

  function check_data(data) {
    if (data) {
      return data
    } else {
      return null
    }
  }

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return null
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  return (
    <div style={{ marginTop: "10px" }}>
      {props.detail_dealer_distar !== undefined && (
        <Select_Dealer setSelect_radio={setSelect_radio} />
      )}

      <br />
      {props.detail_dealer_distar !== undefined && (
        <Collapse>
          <Panel header={t("Dealer information from distar")} key="1">
            <p className="text-header-information">
              {t("Dealer information from distar")}
            </p>

            {dealer_info.map((data, index) => {
              return (
                <Row key={index}>
                  <Col sm={12} md={6} lg={2} xl={2}>
                    <p className="text-bold">{data.head_data1}</p>
                  </Col>
                  <Col sm={12} md={6} lg={4} xl={4}>
                    <p style={{ fontSize: "15px" }}>
                      {" "}
                      <span className="tet">:</span>{" "}
                      <span className="tetTwo">-</span> {data.value_data1}{" "}
                    </p>
                  </Col>
                  <Col sm={12} md={6} lg={2} xl={2}>
                    <p className="text-bold">{data.head_data2}</p>
                  </Col>
                  <Col sm={12} md={6} lg={4} xl={4}>
                    <p style={{ fontSize: "15px" }}>
                      {" "}
                      <span className="tet">:</span>{" "}
                      <span className="tetTwo">-</span> {data.value_data2}{" "}
                    </p>
                  </Col>
                </Row>
              )
            })}
          </Panel>
        </Collapse>
      )}

      <br />
      <Form_Create_New_Dealer
        select_radio={select_radio}
        detail_dealer_distar={props.detail_dealer_distar}
        setNew_dealer={props.setNew_dealer}
        new_dealer={props.new_dealer}
      />
      <br />
    </div>
  )
}
