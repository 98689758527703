import React from "react"
import { Input, Form } from "antd"
import { useTranslation } from "react-i18next";

export default function Input_Comp({
  placeholder,
  header,
  headState,
  nameState,
  keyState,
  type,
  maxLength
}) {
  const { t } = useTranslation();
  return (
    <div>
      <p className="text-bold">
        {header} 
      </p>
      <Form.Item
        name={keyState}
        rules={[
          {
            required: false,
            message: `${t('please input')} ${placeholder}`,
          },
        ]}
      >
        <Input
          maxLength={maxLength}
          type={type}
          placeholder={placeholder}
          onChange={e =>
            headState({ ...nameState, [keyState]: e.target.value })
          }
        />
      </Form.Item>
    </div>
  )
}
