import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Container, Card, CardBody, Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next"
import axios from "axios"
import { DatePicker, Button, Table, Tag, Modal, Input } from "antd"
import * as api from "../../service/Api_Report_All"
import { useHistory } from "react-router-dom"
import helpers from "../Function_Redirect/Redirect_login"
import Render_Total_And_DoughnutChart from "../components/report/Render_Total_And_DoughnutChart"
import Render_Bar_Chart from "../components/report/Render_Bar_Chart"
import image_region from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/region.png"
import graph from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/graph.png"
import process from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/process.png"
import accomplish from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/accomplish.png"
import Cars_Header from "../Dashboard/components/Cars_Header"
import central from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/central.png"
import eastern from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/eastern.png"
import northern from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/northern.png"
import northeastern from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/northeastern.png"
import southern from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/southern.png"
import western from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/western.png"
import { EditOutlined } from "@ant-design/icons"
import SelectOperator from "../Report_Admin/Operator/SelectOperator"
import Select_no_search_allow_clear from "../components/admin/Select_no_search_allow_clear"

export default function DashboardsSLA() {
  const { t } = useTranslation()
  const history = useHistory()
  const [visible, setVisible] = useState(false)
  const token = localStorage.getItem("token")
  const [date_select, setDate_select] = useState({
    date_from: null,
    date_to: null,
  })
  const [operatorSelect, setOperatorSelect] = useState({
    operatorName: null,
    ope_id: null,
  })
  const [selectStatusService, setSelectStatusService] = useState({
    ser_status_id: null,
  })
  const [loading, setLoading] = useState(false)
  const [table_service, setTable_service] = useState([])
  const [count_service_region, setCount_service_region] = useState({
    count_service_central: 0,
    count_service_eastern: 0,
    count_service_north: 0,
    count_service_northeast: 0,
    count_service_south: 0,
    count_service_western: 0,
  })
  const [count_service_complete, setCount_service_complete] = useState({
    count_complest_intime: 0,
    count_complest_overtime: 0,
    count_complest_total: 0,
  })
  const [count_service_inprocess, setCount_service_inprocess] = useState({
    count_inprocess_intime: 0,
    count_inprocess_overtime: 0,
    count_inprocess_total: 0,
  })
  const [service_central, setService_central] = useState({
    cancle: 0,
    complest: 0,
    inprocess: 0,
    reject: 0,
    total: 0,
  })
  const [service_eastern, setService_eastern] = useState({
    cancle: 0,
    complest: 0,
    inprocess: 0,
    reject: 0,
    total: 0,
  })
  const [service_north, setService_north] = useState({
    cancle: 0,
    complest: 0,
    inprocess: 0,
    reject: 0,
    total: 0,
  })
  const [service_northeast, setService_northeast] = useState({
    cancle: 0,
    complest: 0,
    inprocess: 0,
    reject: 0,
    total: 0,
  })
  const [service_south, setService_south] = useState({
    cancle: 0,
    complest: 0,
    inprocess: 0,
    reject: 0,
    total: 0,
  })
  const [service_western, setService_western] = useState({
    cancle: 0,
    complest: 0,
    inprocess: 0,
    reject: 0,
    total: 0,
  })
  const [totalCountSLA, setTotalCountSLA] = useState({
    count_allservice_sla : 0,
    count_allservice_sla_cancle: 0,
    count_allservice_sla_reject: 0,
    count_inprocess_total : 0
  })

  const style_DatePicker = {
    width: "100%",
  }

  const style_card = {
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  }

  useEffect(() => {
    search_data_sla()
    return () => {}
  }, [])

  function onChangeDateFrom(date, dateString) {
    if (dateString) {
      setDate_select({
        ...date_select,
        date_from: dateString,
      })
    } else {
      setDate_select({
        ...date_select,
        date_from: null,
      })
    }
  }

  function onChangeDateTo(date, dateString) {
    if (dateString) {
      setDate_select({
        ...date_select,
        date_to: dateString,
      })
    } else {
      setDate_select({
        ...date_select,
        date_to: null,
      })
    }
  }

  const check_data = (params, data) => {
    if (data) {
      return params + data
    } else {
      return ""
    }
  }

  const check_date_time = (data_date_start, data_date_end) => {
    let date_toString = new Date()
    let date_now = formatDate(date_toString)
    if (data_date_start !== null && data_date_end !== null) {
      return "start_time=" + data_date_start + "&end_time=" + data_date_end
    } else {
      return "start_time=" + "2000-01-01" + "&end_time=" + date_now
    }
  }

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day

    return [year, month, day].join("-")
  }

  function search_data_sla() {
    setLoading(true)
    let url = `dashboard/sla?${check_date_time(
      date_select.date_from,
      date_select.date_to
    )}${check_data("&operator_id=", operatorSelect.ope_id)}${check_data(
      "&status_id=",
      selectStatusService.ser_status_id
    )}`
    const check_als = api.NEW_CHECK_REPORT(token, url)
    axios(check_als)
      .then(res => {
        setLoading(false)
        setTable_service(res.data.data)
        setCount_service_region({
          ...count_service_region,
          count_service_central: res.data.count.count_service_central.total,
          count_service_eastern: res.data.count.count_service_eastern.total,
          count_service_north: res.data.count.count_service_north.total,
          count_service_northeast: res.data.count.count_service_northeast.total,
          count_service_south: res.data.count.count_service_south.total,
          count_service_western: res.data.count.count_service_western.total,
        })
        setCount_service_complete({
          ...count_service_complete,
          count_complest_intime:
            res.data.count.count_sla_service_complest.count_complest_intime,
          count_complest_overtime:
            res.data.count.count_sla_service_complest.count_complest_overtime,
          count_complest_total:
            res.data.count.count_sla_service_complest.count_complest_total,
        })
        setCount_service_inprocess({
          ...count_service_inprocess,
          count_inprocess_intime:
            res.data.count.count_sla_service_inprocess.count_inprocess_intime,
          count_inprocess_overtime:
            res.data.count.count_sla_service_inprocess.count_inprocess_overtime,
          count_inprocess_total:
            res.data.count.count_sla_service_inprocess.count_inprocess_total,
        })
        setService_central({
          ...service_central,
          cancle: res.data.count.count_service_central.cancle,
          complest: res.data.count.count_service_central.complest,
          inprocess: res.data.count.count_service_central.inprocess,
          reject: res.data.count.count_service_central.reject,
          total: res.data.count.count_service_central.total,
        })
        setService_eastern({
          ...service_eastern,
          cancle: res.data.count.count_service_eastern.cancle,
          complest: res.data.count.count_service_eastern.complest,
          inprocess: res.data.count.count_service_eastern.inprocess,
          reject: res.data.count.count_service_eastern.reject,
          total: res.data.count.count_service_eastern.total,
        })
        setService_north({
          ...service_north,
          cancle: res.data.count.count_service_north.cancle,
          complest: res.data.count.count_service_north.complest,
          inprocess: res.data.count.count_service_north.inprocess,
          reject: res.data.count.count_service_north.reject,
          total: res.data.count.count_service_north.total,
        })
        setService_northeast({
          ...service_northeast,
          cancle: res.data.count.count_service_northeast.cancle,
          complest: res.data.count.count_service_northeast.complest,
          inprocess: res.data.count.count_service_northeast.inprocess,
          reject: res.data.count.count_service_northeast.reject,
          total: res.data.count.count_service_northeast.total,
        })
        setService_south({
          ...service_south,
          cancle: res.data.count.count_service_south.cancle,
          complest: res.data.count.count_service_south.complest,
          inprocess: res.data.count.count_service_south.inprocess,
          reject: res.data.count.count_service_south.reject,
          total: res.data.count.count_service_south.total,
        })
        setService_western({
          ...service_western,
          cancle: res.data.count.count_service_western.cancle,
          complest: res.data.count.count_service_western.complest,
          inprocess: res.data.count.count_service_western.inprocess,
          reject: res.data.count.count_service_western.reject,
          total: res.data.count.count_service_western.total,
        })
        setTotalCountSLA({
          ...totalCountSLA,
          count_allservice_sla : res.data.count.count_allservice_sla ,
          count_allservice_sla_cancle: res.data.count.count_allservice_sla_cancle ,
          count_allservice_sla_reject: res.data.count.count_allservice_sla_reject ,
          count_inprocess_total : res.data.count.count_sla_service_inprocess.count_inprocess_total
        })
      })
      .catch(error => {
        setLoading(false)
        // if (error) {
        //   if (error.response) {
        //     if (error.response.status === 401) {
        //       helpers.re_login()
        //       history.push("/login")
        //     }
        //   }
        // }
      })
  }

  const columns = [
    {
      title: t("Service no."),
      dataIndex: "ser_no",
      key: "ser_no",
    },
    {
      title: t("Service status"),
      dataIndex: "ser_status_name",
      key: "ser_status_name",
      render: text => render_status(text),
    },
    {
      title: t("Operator"),
      dataIndex: "operator",
      key: "operator",
      render: text => renderOperator(text),
    },
    {
      title: t("Time status"),
      dataIndex: "time_status",
      key: "time_status",
      render: text => render_status_time(text),
    },
    {
      title: t("Overtime"),
      dataIndex: "overtime",
      key: "overtime",
    },
  ]

  function renderOperator(operatorList) {
    if (operatorList.length === 0) {
      return null
    } else {
      return operatorList.map((data, index) => {
        return <p key={index}>- {data.ope_firstname}</p>
      })
    }
  }

  function render_status_time(status) {
    if (status === "intime") {
      return <Tag color="success">{t("Process in time")}</Tag>
    } else {
      return <Tag color="error">{t("Process overtime")}</Tag>
    }
  }

  function render_status(status) {
    switch (status) {
      case "cancel":
        return <Tag color="error"> {t("cancel")} </Tag>
        break
      case "reject":
        return <Tag color="error"> {t("reject")} </Tag>
        break
      case "overdue":
        return <Tag color="error">{t("overdue")}</Tag>
        break
      case "resolved":
        return <Tag color="success">{t("resolved")}</Tag>
        break
      case "complete":
        return <Tag color="success">{t("complete")}</Tag>
        break
      case "inprogress":
        return <Tag color="processing">{t("inprogress")}</Tag>
        break
      case "unassigned":
        return <Tag color="default">{t("unassigned")}</Tag>
        break
      case "pending":
        return (
          <Tag
            style={{
              color: "rgb(234, 159, 230)",
              borderColor: "rgb(234, 159, 230)",
              backgroundColor: "#FFF",
            }}
          >
            {t("pending")}
          </Tag>
        )
        break
      case "assigned":
        return (
          <Tag
            style={{
              color: "#DCC200",
              borderColor: "#DCC200",
              backgroundColor: "#FFF",
            }}
          >
            {t("assigned")}
          </Tag>
        )
        break
      case "waiting for admin approve":
        return (
          <Tag
            style={{
              color: "#0071FF",
              borderColor: "#0071FF",
              backgroundColor: "#DFEDFF",
            }}
          >
            {t("waiting for admin approve")}
          </Tag>
        )
        break
      case "waiting for manager approve":
        return (
          <Tag
            style={{
              color: "#0071FF",
              borderColor: "#0071FF",
              backgroundColor: "#DFEDFF",
            }}
          >
            {t("waiting for manager approve")}
          </Tag>
        )
        break
      default:
        return <Tag color="default">{status}</Tag>
        break
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("SLA dashboard")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>{t("SLA dashboard")}</h3>
          <Card style={style_card}>
            <CardBody>
              <Row>
                <Col xs={12} sm={12} md={6} lg={2} xl={2}>
                  <p className="text-bold"> {t("Date from")} </p>
                  <DatePicker
                    onChange={onChangeDateFrom}
                    allowClear
                    style={style_DatePicker}
                  />
                </Col>
                <Col xs={12} sm={12} md={6} lg={2} xl={2}>
                  <p className="text-bold"> {t("Date to")} </p>
                  <DatePicker
                    onChange={onChangeDateTo}
                    allowClear
                    style={style_DatePicker}
                  />
                </Col>
                <Col xs={12} sm={12} md={6} lg={2} xl={2}>
                  <Select_no_search_allow_clear
                    header={t("Status")}
                    keyState="ser_status_id"
                    name_api="LIST_ALL_STATUS_LIST_SLA"
                    headState={setSelectStatusService}
                    nameState={selectStatusService}
                    map_name="ser_status_name"
                    placeholder={t("Status")}
                    map_value="ser_status_id"
                    Header_data="data"
                    disabled={false}
                  />
                </Col>
                <Col xs={12} sm={12} md={6} lg={2} xl={2}>
                  {operatorSelect.operatorName === null && (
                    <Button
                      type="primary"
                      onClick={() => setVisible(true)}
                      style={{
                        marginTop: "35px",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      className="btn-report-search-export"
                    >
                      {t("Select operator")}
                    </Button>
                  )}
                  {operatorSelect.operatorName !== null && (
                    <div style={{ display: "flex", marginTop: "35px" }}>
                      <p className="text-bold " style={{ marginTop: "7px" }}>
                        {t("Operator")}
                      </p>
                      <Input
                        style={{ marginLeft: "10px", height: "35px" }}
                        value={operatorSelect.operatorName}
                        placeholder={operatorSelect.operatorName}
                        disabled
                      />
                      <a>
                        <EditOutlined
                          style={{ fontSize: "20px", marginLeft: "10px" }}
                          onClick={() => {
                            setOperatorSelect({
                              ...operatorSelect,
                              operatorName: null,
                              ope_id: null,
                            })
                          }}
                        />
                      </a>
                    </div>
                  )}
                </Col>
                <Col xs={12} sm={12} md={6} lg={2} xl={2}>
                  <Button
                    className="btn-report-search-export btn-report-search-export-mg-right"
                    type="primary"
                    onClick={() => search_data_sla()}
                    loading={loading}
                  >
                    {t("Search")}
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Row>
          <Col sm={12} md={12} lg={12} xl={12}>
          <Card style={style_card}>
          <CardBody>
          <p
                    style={{
                      fontSize: "20px",
                      marginBottom: "15px",
                      color: "#495057",
                    }}
                  >
                    <img
                      src={graph}
                      alt="graph"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginRight: "20px",
                        marginTop: "-10px",
                      }}
                    />
                    {t("Total service status")}
                  </p>
                  <br />
                  <Row>
                    <Col sm={12} md={6} lg={4} xl={4}>
                      <Cars_Header
                        header={t("All service")}
                        number={totalCountSLA.count_allservice_sla}
                        color_border_icon="#E2A223"
                        icon="AiOutlineFileSearch"
                      />
                    </Col>
                          <Col sm={12} md={6} lg={4} xl={4}>
                      <Cars_Header
                        header={t("cancel")}
                        number={totalCountSLA.count_allservice_sla_cancle}
                        color_border_icon="#ff6961"
                        icon="AiOutlineFileExcel"
                      />
                    </Col>
                          <Col sm={12} md={6} lg={4} xl={4}>
                      <Cars_Header
                        header={t("reject")}
                        number={totalCountSLA.count_allservice_sla_reject}
                        color_border_icon="#A43D3D"
                        icon="AiOutlineFileExclamation"
                      />
                    </Col>
                  </Row>
          </CardBody>
          </Card>
          </Col>
          </Row>

          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <Card style={style_card}>
                <CardBody>
                  <p
                    style={{
                      fontSize: "20px",
                      marginBottom: "15px",
                      color: "#495057",
                    }}
                  >
                    <img
                      src={accomplish}
                      alt="graph"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginRight: "20px",
                        marginTop: "-10px",
                      }}
                    />
                    {t("Service complete")}
                  </p>
                  <br />
                  <Row>
                    <Col sm={12} md={12} lg={6} xl={6}>
                      <Cars_Header
                        header={t("In time")}
                        number={count_service_complete.count_complest_intime}
                        color_border_icon="#76CE86"
                        icon="AiOutlineFileDone"
                      />
                    </Col>
                    <Col sm={12} md={12} lg={6} xl={6}>
                      <Cars_Header
                        header={t("Overtime")}
                        number={count_service_complete.count_complest_overtime}
                        color_border_icon="#ff6961"
                        icon="BiTimer"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col sm={12} md={6} lg={6} xl={6}>
              <Card style={style_card}>
                <CardBody>
                  <p
                    style={{
                      fontSize: "20px",
                      marginBottom: "15px",
                      color: "#495057",
                    }}
                  >
                    <img
                      src={process}
                      alt="graph"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginRight: "20px",
                        marginTop: "-10px",
                      }}
                    />
                    {t("Service In process")}
                  </p>
                  <br />
                  <Row>
                    <Col sm={12} md={12} lg={6} xl={6}>
                      <Cars_Header
                        header={t("In time")}
                        number={count_service_inprocess.count_inprocess_intime}
                        color_border_icon="#6891C3"
                        icon="AiOutlineFileDone"
                      />
                    </Col>
                    <Col sm={12} md={12} lg={6} xl={6}>
                      <Cars_Header
                        header={t("Overtime")}
                        number={
                          count_service_inprocess.count_inprocess_overtime
                        }
                        color_border_icon="#ff6961"
                        icon="BiTimer"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/*<Row>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <Render_Total_And_DoughnutChart
                text_header={t("Service complete")}
                img_header={accomplish}
                labels_status={[t("Complete in time"), t("Complete overtime")]}
                data_status={[
                  count_service_complete.count_complest_intime,
                  count_service_complete.count_complest_overtime,
                ]}
                color_chart={["#76CE86", "#ff6961"]}
                total={count_service_complete.count_complest_total}
                data_lineChart={[
                  {
                    text_header: t("Total"),
                    total_count: count_service_complete.count_complest_total,
                    color: "#BE94E6",
                  },
                  {
                    text_header: t("Complete in time"),
                    total_count: count_service_complete.count_complest_intime,
                    color: "#76CE86",
                  },
                  {
                    text_header: t("Complete overtime"),
                    total_count: count_service_complete.count_complest_overtime,
                    color: "#ff6961",
                  },
                ]}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <Render_Total_And_DoughnutChart
                text_header={t("Service In process")}
                img_header={process}
                labels_status={[t("Process in time"), t("Process overtime")]}
                data_status={[
                  count_service_inprocess.count_inprocess_intime,
                  count_service_inprocess.count_inprocess_overtime,
                ]}
                color_chart={["#76CE86", "#ff6961"]}
                total={count_service_inprocess.count_inprocess_total}
                data_lineChart={[
                  {
                    text_header: t("Total"),
                    total_count: count_service_inprocess.count_inprocess_total,
                    color: "#BE94E6",
                  },
                  {
                    text_header: t("Process in time"),
                    total_count: count_service_inprocess.count_inprocess_intime,
                    color: "#76CE86",
                  },
                  {
                    text_header: t("Process overtime"),
                    total_count:
                      count_service_inprocess.count_inprocess_overtime,
                    color: "#ff6961",
                  },
                ]}
              />
            </Col>
              </Row>*/}

          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card style={style_card}>
                <CardBody>
                  <Render_Bar_Chart
                    text_header={t("Total service by region")}
                    text_bar={t("service")}
                    image_header={image_region}
                    labels_status={[
                      t("central"),
                      t("eastern"),
                      t("northern"),
                      t("northeastern"),
                      t("southern"),
                      t("western"),
                    ]}
                    data_status={[
                      count_service_region.count_service_central,
                      count_service_region.count_service_eastern,
                      count_service_region.count_service_north,
                      count_service_region.count_service_northeast,
                      count_service_region.count_service_south,
                      count_service_region.count_service_western,
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
              <Render_Total_And_DoughnutChart
                text_header={t("central")}
                img_header={central}
                labels_status={[
                  t("Cancel"),
                  t("Complete"),
                  t("inprogress"),
                  t("reject"),
                ]}
                data_status={[
                  service_central.cancle,
                  service_central.complest,
                  service_central.inprocess,
                  service_central.reject,
                ]}
                color_chart={["#ff6961", "#76CE86", "#6891C3", "#EA9FE6"]}
                total={service_central.total}
                data_lineChart={[
                  {
                    text_header: t("Total"),
                    total_count: service_central.total,
                    color: "#BE94E6",
                  },
                  {
                    text_header: t("Cancel"),
                    total_count: service_central.cancle,
                    color: "#ff6961",
                  },
                  {
                    text_header: t("Complete"),
                    total_count: service_central.complest,
                    color: "#76CE86",
                  },
                  {
                    text_header: t("inprogress"),
                    total_count: service_central.inprocess,
                    color: "#6891C3",
                  },
                  {
                    text_header: t("reject"),
                    total_count: service_central.reject,
                    color: "#EA9FE6",
                  },
                ]}
              />
              <br />
            </Col>

            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
              <Render_Total_And_DoughnutChart
                text_header={t("eastern")}
                img_header={eastern}
                labels_status={[
                  t("Cancel"),
                  t("Complete"),
                  t("inprogress"),
                  t("reject"),
                ]}
                data_status={[
                  service_eastern.cancle,
                  service_eastern.complest,
                  service_eastern.inprocess,
                  service_eastern.reject,
                ]}
                color_chart={["#ff6961", "#76CE86", "#6891C3", "#EA9FE6"]}
                total={service_eastern.total}
                data_lineChart={[
                  {
                    text_header: t("Total"),
                    total_count: service_eastern.total,
                    color: "#BE94E6",
                  },
                  {
                    text_header: t("Cancel"),
                    total_count: service_eastern.cancle,
                    color: "#ff6961",
                  },
                  {
                    text_header: t("Complete"),
                    total_count: service_eastern.complest,
                    color: "#76CE86",
                  },
                  {
                    text_header: t("inprogress"),
                    total_count: service_eastern.inprocess,
                    color: "#6891C3",
                  },
                  {
                    text_header: t("reject"),
                    total_count: service_eastern.reject,
                    color: "#EA9FE6",
                  },
                ]}
              />
              <br />
            </Col>

            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
              <Render_Total_And_DoughnutChart
                text_header={t("northern")}
                img_header={northern}
                labels_status={[
                  t("Cancel"),
                  t("Complete"),
                  t("inprogress"),
                  t("reject"),
                ]}
                data_status={[
                  service_north.cancle,
                  service_north.complest,
                  service_north.inprocess,
                  service_north.reject,
                ]}
                color_chart={["#ff6961", "#76CE86", "#6891C3", "#EA9FE6"]}
                total={service_north.total}
                data_lineChart={[
                  {
                    text_header: t("Total"),
                    total_count: service_north.total,
                    color: "#BE94E6",
                  },
                  {
                    text_header: t("Cancel"),
                    total_count: service_north.cancle,
                    color: "#ff6961",
                  },
                  {
                    text_header: t("Complete"),
                    total_count: service_north.complest,
                    color: "#76CE86",
                  },
                  {
                    text_header: t("inprogress"),
                    total_count: service_north.inprocess,
                    color: "#6891C3",
                  },
                  {
                    text_header: t("reject"),
                    total_count: service_north.reject,
                    color: "#EA9FE6",
                  },
                ]}
              />
              <br />
            </Col>

            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
              <Render_Total_And_DoughnutChart
                text_header={t("northeastern")}
                img_header={northeastern}
                labels_status={[
                  t("Cancel"),
                  t("Complete"),
                  t("inprogress"),
                  t("reject"),
                ]}
                data_status={[
                  service_northeast.cancle,
                  service_northeast.complest,
                  service_northeast.inprocess,
                  service_northeast.reject,
                ]}
                color_chart={["#ff6961", "#76CE86", "#6891C3", "#EA9FE6"]}
                total={service_northeast.total}
                data_lineChart={[
                  {
                    text_header: t("Total"),
                    total_count: service_northeast.total,
                    color: "#BE94E6",
                  },
                  {
                    text_header: t("Cancel"),
                    total_count: service_northeast.cancle,
                    color: "#ff6961",
                  },
                  {
                    text_header: t("Complete"),
                    total_count: service_northeast.complest,
                    color: "#76CE86",
                  },
                  {
                    text_header: t("inprogress"),
                    total_count: service_northeast.inprocess,
                    color: "#6891C3",
                  },
                  {
                    text_header: t("reject"),
                    total_count: service_northeast.reject,
                    color: "#EA9FE6",
                  },
                ]}
              />
              <br />
            </Col>

            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
              <Render_Total_And_DoughnutChart
                text_header={t("southern")}
                img_header={southern}
                labels_status={[
                  t("Cancel"),
                  t("Complete"),
                  t("inprogress"),
                  t("reject"),
                ]}
                data_status={[
                  service_south.cancle,
                  service_south.complest,
                  service_south.inprocess,
                  service_south.reject,
                ]}
                color_chart={["#ff6961", "#76CE86", "#6891C3", "#EA9FE6"]}
                total={service_south.total}
                data_lineChart={[
                  {
                    text_header: t("Total"),
                    total_count: service_south.total,
                    color: "#BE94E6",
                  },
                  {
                    text_header: t("Cancel"),
                    total_count: service_south.cancle,
                    color: "#ff6961",
                  },
                  {
                    text_header: t("Complete"),
                    total_count: service_south.complest,
                    color: "#76CE86",
                  },
                  {
                    text_header: t("inprogress"),
                    total_count: service_south.inprocess,
                    color: "#6891C3",
                  },
                  {
                    text_header: t("reject"),
                    total_count: service_south.reject,
                    color: "#EA9FE6",
                  },
                ]}
              />
              <br />
            </Col>

            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
              <Render_Total_And_DoughnutChart
                text_header={t("western")}
                img_header={western}
                labels_status={[
                  t("Cancel"),
                  t("Complete"),
                  t("inprogress"),
                  t("reject"),
                ]}
                data_status={[
                  service_western.cancle,
                  service_western.complest,
                  service_western.inprocess,
                  service_western.reject,
                ]}
                color_chart={["#ff6961", "#76CE86", "#6891C3", "#EA9FE6"]}
                total={service_western.total}
                data_lineChart={[
                  {
                    text_header: t("Total"),
                    total_count: service_western.total,
                    color: "#BE94E6",
                  },
                  {
                    text_header: t("Cancel"),
                    total_count: service_western.cancle,
                    color: "#ff6961",
                  },
                  {
                    text_header: t("Complete"),
                    total_count: service_western.complest,
                    color: "#76CE86",
                  },
                  {
                    text_header: t("inprogress"),
                    total_count: service_western.inprocess,
                    color: "#6891C3",
                  },
                  {
                    text_header: t("reject"),
                    total_count: service_western.reject,
                    color: "#EA9FE6",
                  },
                ]}
              />
              <br />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Card style={style_card}>
                <CardBody>
                  <p
                    style={{
                      fontSize: "20px",
                      marginBottom: "15px",
                      color: "#495057",
                    }}
                  >
                    <img
                      src={graph}
                      alt="graph"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginRight: "20px",
                        marginTop: "-10px",
                      }}
                    />
                    SLA
                  </p>
                  <Table
                    columns={columns}
                    dataSource={table_service}
                    loading={loading}
                    rowKey="ser_id"
                    scroll={{ x: 800 }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            centered
            open={visible}
            onCancel={() => {
              setVisible(false)
            }}
            width={1000}
            footer={null}
          >
            <SelectOperator
              setVisible={setVisible}
              setOperatorSelect={setOperatorSelect}
              operatorSelect={operatorSelect}
            />
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}
