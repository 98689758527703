import React from "react"
import { Row, Col } from "reactstrap"
import { Input } from "antd"

const { Search } = Input

export default function Search_Product(props) {

  function onSearch(value) {
    props.search_product()
  }

  return (
    <Row>
    <Col sm={12} md={6} lg={6} xl={6}>
      <Search
        style={{ width: "100%" }}
        placeholder="name , search"
        allowClear
        enterButton="Search"
        size="medium"
        onSearch={onSearch}
        onChange={e => props.setData_search_table_product(e.target.value)}
      />
    </Col>
  </Row>
  )
}
