import React, { useState, useEffect } from "react"
import { Form, Table, Pagination, Button, Input } from "antd"
import * as api from "../../../service/API_Edit_Car"
import axios from "axios"
import { Row, Col } from "reactstrap"
import Branch_Search from "./Branch_Search"
import { EditOutlined } from "@ant-design/icons"
import { FcDataSheet } from "react-icons/fc"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import helpers from "../../Function_Redirect/Redirect_login"

export default function Branch_Comp(props) {
  const { t } = useTranslation()
  const history = useHistory()
  const token = localStorage.getItem("token")
  const [search_data_branch, setSearch_data_branch] = useState(null)
  const [customer_table, setCustomer_table] = useState([])
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    search_customer_created()
    return () => {
      setCustomer_table([])
      setSearch_data_branch(null)
    }
  }, [props.cus_id])

  function search_customer_created() {
    setLoading(true)
    if (search_data_branch) {
      const search_data = api.SEARCH_DATA(
        token,
        props.cus_id,
        1,
        page_limit.page_Size,
        search_data_branch
      )
      axios(search_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setCustomer_table(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else {
      const search_no_data = api.SEARCH_NODATA(
        token,
        props.cus_id,
        1,
        page_limit.page_Size
      )
      axios(search_no_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setCustomer_table(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  function changePagination(page, pageSize) {
    setLoading(true)
    if (search_data_branch) {
      const search_data = api.SEARCH_DATA(
        token,
        props.cus_id,
        page,
        pageSize,
        search_data_branch
      )
      axios(search_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setCustomer_table(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else {
      const search_no_data = api.SEARCH_NODATA(
        token,
        props.cus_id,
        page,
        pageSize
      )
      axios(search_no_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setCustomer_table(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  const columns = [
    {
      title: t("Branch code"),
      dataIndex: "cus_branch_code",
      key: "cus_branch_code",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Branch name"),
      dataIndex: "cus_branch_name",
      key: "cus_branch_name",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Branch address"),
      dataIndex: "cus_branch_address",
      key: "cus_branch_address",
      render: text => <p>{text}</p>,
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      props.setCustomer_Info({
        ...props.customer_Info,
        cus_branch_id: selectedRows[0].cus_branch_id,
        cus_branch_name: selectedRows[0].cus_branch_name,
      })
      props.setData_edit({
        ...props.data_edit,
        cus_branch_id: selectedRows[0].cus_branch_id,
      })
    },
    getCheckboxProps: record => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  }

  function edit_data() {
    if (props.disabled === false) {
      setSearch_data_branch(null)
      props.setCustomer_Info({
        ...props.customer_Info,
        cus_branch_id: null,
        cus_branch_name: null,
      })
      props.setData_edit({
        ...props.data_edit,
        cus_branch_id: null,
      })
    }
  }

  return (
    <div>
      {props.customer_Info.cus_branch_id === null && (
        <div>
          <p className="text-bold "> {t("Select branch")} </p>
          <Branch_Search
            search_customer_created={search_customer_created}
            setSearch_data_branch={setSearch_data_branch}
          />
          <br />
          <Table
            rowSelection={{
              type: "radio",
              ...rowSelection,
            }}
            loading={loading}
            rowKey="cus_branch_code"
            pagination={false}
            columns={columns}
            dataSource={customer_table}
            scroll={{ x: 800 }}
          />

          <div style={{ float: "right", marginTop: "10px" }}>
            <Pagination
              current={page_limit.page}
              total={page_limit.limit}
              onChange={changePagination}
            />
          </div>
          <p style={{ fontSize: "15px", marginTop: "10px" }}>
            {" "}
            <FcDataSheet size={20} style={{ marginBottom: "5px" }} /> {t("total data")}
            : {page_limit.limit}
          </p>
        </div>
      )}

      {props.customer_Info.cus_branch_id !== null && (
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div style={{ display: "flex" }}>
              <p className="text-bold " style={{ marginTop: "7px" }}>
                {t("Branch")}
              </p>
              <Input
                style={{ marginLeft: "28px", height: "35px" }}
                value={props.customer_Info.cus_branch_name}
                placeholder={props.customer_Info.cus_branch_name}
                disabled
              />
              <a>
                <EditOutlined
                  style={{ fontSize: "20px", marginLeft: "10px" }}
                  onClick={() => edit_data()}
                />
              </a>
            </div>
          </Col>
        </Row>
      )}
    </div>
  )
}
