import React from "react"
import { Row, Col } from "reactstrap"
import { FiFileText, FiBox } from "react-icons/fi"
import { BiUserCircle } from "react-icons/bi"
import { HiUserGroup } from "react-icons/hi"
import { AiOutlineCar, AiOutlineFileDone } from "react-icons/ai"
import { FaRegHandshake, FaUsersCog } from "react-icons/fa"
import { VscTools } from "react-icons/vsc"

import "../Dashboard_style.css"

export default function Cars_Header({
  number,
  header,
  color_border_icon,
  icon,
}) {
  function render_icon() {
    switch (icon) {
      case "FiFileText":
        return <FiFileText size={40} className="icon-header-dashboard" />
        break
      case "BiUserCircle":
        return <BiUserCircle size={40} className="icon-header-dashboard" />
        break
      case "HiUserGroup":
        return <HiUserGroup size={40} className="icon-header-dashboard" />
        break
      case "AiOutlineCar":
        return <AiOutlineCar size={40} className="icon-header-dashboard" />
        break
      case "FaRegHandshake":
        return <FaRegHandshake size={40} className="icon-header-dashboard" />
        break
      case "VscTools":
        return <VscTools size={40} className="icon-header-dashboard" />
        break
      case "FiBox":
        return <FiBox size={40} className="icon-header-dashboard" />
        break
      case "FaUsersCog":
        return <FaUsersCog size={40} className="icon-header-dashboard" />
        break
        case "AiOutlineFileDone":
          return <AiOutlineFileDone size={40} className="icon-header-dashboard" />
          break
      default:
        return <FiFileText size={40} className="icon-header-dashboard" />
        break
    }
  }

  return (
    <Row>
      <Col xs={4} sm={4} md={6} lg={5} xl={5}>
        <div style={{ width: "100%", height: "100%", float: "right" }}>
          <div
            style={{ backgroundColor: `${color_border_icon}`, color: "#FFF" }}
            className="div-border-icon"
          >
            {render_icon()}
          </div>
        </div>
      </Col>
      <Col xs={8} sm={8} md={6} lg={7} xl={7}>
        <div style={{ marginTop: "-5px" }}>
          <p
            style={{ fontSize: "20px", color: "#A5A5A5", marginBottom: "0px" }}
          >
            {header}
          </p>
          <p style={{ fontSize: "30px", fontWeight: "bold" }}>
            {number ? number : 0}
          </p>
        </div>
      </Col>
    </Row>
  )
}
