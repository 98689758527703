import React, { useState, useEffect } from "react"
import { Button, Table, Modal, Tooltip  } from "antd"
import { AiOutlineEye } from "react-icons/ai"
import { FiEdit2 } from "react-icons/fi"
import Detail_car from "./Detail_car"
import { FcSearch, FcSettings } from "react-icons/fc"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next";

export default function Table_list_car(props) {
  const history = useHistory()
  const [list_data_table, setList_data_table] = useState([])
  const [data_car, setData_car] = useState({})
  const [visible, setVisible] = useState(false)
  const { t } = useTranslation();

  useEffect(() => {
    setList_data_table(props.data_list)
    return () => {
      setList_data_table([])
    }
  }, [props.data_list])

  const columns = [
    {
      title: t("Car code"),
      dataIndex: "car_code",
      key: "car_code",
      render: text => checkData(text),
    },
    {
      title: t("License"),
      dataIndex: "car_license_number",
      key: "car_license_number",
      render: text => checkData(text),
    },
    {
      title: t("Province"),
      dataIndex: "pro_name",
      key: "pro_name",
      render: text => checkData(text),
    },
    {
      title: t("Name"),
      dataIndex: "car_name",
      key: "car_name",
      render: text => checkData(text),
    },
    {
      title: t("Brand"),
      dataIndex: "car_brand_name",
      key: "car_brand_name",
      render: text => checkData(text),
    },
    {
      title: t("Color"),
      dataIndex: "car_color_name",
      key: "car_color_name",
      render: text => checkData(text),
    },
    {
      title: t("Branch"),
      dataIndex: "cus_branch_name",
      key: "cus_branch_name",
      render: text => checkData(text),
    },
    {
      title: t("Car remark"),
      dataIndex: "car_remark",
      key: "car_remark",
      render: text => checkData(text),
    },
    {
      title: "",
      render: text => (
        <Tooltip title={t("View detail")} >
        <a>
        <FcSearch
          onClick={() => {
            setVisible(true)
            setData_car(text)
          }}
          size={20}
        />
      </a>
      </Tooltip>
      ),
    },
    {
      title: "",
      render: text => (
        <Tooltip title={t("View car")} >
        <a>
          <FcSettings
            onClick={() =>
              history.push(`/cars/${text.car_id}`)
            }
            size={20}
          />
        </a>
        </Tooltip>
      ),
    },
  ]

  function checkData(data) {
    if (data) {
      return data
    } else {
      return "N/A"
    }
  }

  return (
    <div>
      <Table
        columns={columns}
        dataSource={list_data_table}
        pagination={false}
        loading={props.loading}
        rowKey="car_id"
        scroll={{ x: 800 }}
        // onRow={(i) => ({
        //     onClick: () =>
        //        console.log(i)
        // })}
      />

      <Modal
        title={false}
        centered
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={() => {
          setVisible(false)
          setData_car({})
        }}
        footer={false}
        height={500}
        width={1000}
      >
        <Detail_car data_car={data_car} />
      </Modal>
    </div>
  )
}
