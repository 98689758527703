import React, { useState, useEffect } from "react"
import { Row, Col, Container, Card, CardBody } from "reactstrap"
import MetaTags from "react-meta-tags"
import { useHistory } from "react-router-dom"
import { Table, Modal, Pagination, Tag, Tooltip } from "antd"
import axios from "axios"
import * as api from "../../service/Api_Service"
import { AiOutlineEye } from "react-icons/ai"
import { FiEdit2 } from "react-icons/fi"
import Search_Service from "./Search_Service"
import Detail_Service from "./Detail_Service"
import {
  FcSearch,
  FcSettings,
  FcDataSheet,
  FcApproval,
  FcCancel,
  FcClock,
  FcHighPriority,
} from "react-icons/fc"
import no_permi from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png"
import helpers from "../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const Service = props => {
  const history = useHistory()
  const { t } = useTranslation()
  const [search_service, setSearch_service] = useState(null)
  const token = localStorage.getItem("token")
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })
  const [data_list, setData_list] = useState([])
  const [visible, setVisible] = useState(false)
  const [data_Service_modal, setData_Service_modal] = useState({})
  const [loading, setLoading] = useState(false)
  const { permissionAllService } = useSelector(({ Distar }) => Distar)

  const [filter_service, setFilter_service] = useState({
    priority_id: null,
    customer_id: null,
    ser_status_id: null,
    ser_type_id: null,
  })
  const [filter_Role, setFilter_Role] = useState({
    request_by_role: null,
    request_by_id: null,
    request_by_name: null,
  })
  const permi = localStorage.getItem("permi")
  const [permi_t, setPermi_t] = useState(false)

  useEffect(() => {
    if (permissionAllService) {
      if (permissionAllService.service) {
        setPermi_t(permissionAllService.service.read)
      }
    }
    return () => {}
  }, [permissionAllService])

  useEffect(() => {
    search_data()
    return () => {}
  }, [filter_service, filter_Role])

  useEffect(() => {
    search_data()
    return () => {
      setData_list([])
      setData_Service_modal({})
    }
  }, [])

  const check_data = (params, data) => {
    if (data) {
      return params + data
    } else {
      return ""
    }
  }

  function search_data() {
    setLoading(true)
    let url = `api/v1/service?page=1&limit=${
      page_limit.page_Size
    }&order_by=DESC${check_data("&q=", search_service)}${check_data(
      "&priority_id=",
      filter_service.priority_id
    )}${check_data("&customer_id=", filter_service.customer_id)}${check_data(
      "&ser_status_id=",
      filter_service.ser_status_id
    )}${check_data("&ser_type_id=", filter_service.ser_type_id)}${check_data(
      "&request_by_role=",
      filter_Role.request_by_role
    )}${check_data("&request_by_id=", filter_Role.request_by_id)}`
    const search = api.SEARCH_ALL(token, url)
    axios(search)
      .then(res => {
        setLoading(false)
        setPage_limit({
          ...page_limit,
          page: 1,
          limit: res.data.total_data,
        })
        // console.log(res.data.data)
        setData_list(res.data.data)
      })
      .catch(error => {
        setLoading(false)
        // console.log(error.response)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  function changePagination(page, pageSize) {
    setLoading(true)
    let url = `api/v1/service?page=${page}&limit=${pageSize}&order_by=DESC${check_data(
      "&q=",
      search_service
    )}${check_data("&priority_id=", filter_service.priority_id)}${check_data(
      "&customer_id=",
      filter_service.customer_id
    )}${check_data(
      "&ser_status_id=",
      filter_service.ser_status_id
    )}${check_data("&ser_type_id=", filter_service.ser_type_id)}${check_data(
      "&request_by_role=",
      filter_Role.request_by_role
    )}${check_data("&request_by_id=", filter_Role.request_by_id)}`
    const search = api.SEARCH_ALL(token, url)
    axios(search)
      .then(res => {
        setLoading(false)
        setPage_limit({
          ...page_limit,
          page: page,
          page_Size: pageSize,
          limit: res.data.total_data,
        })
        // console.log(res.data.data)
        setData_list(res.data.data)
      })
      .catch(error => {
        setLoading(false)
        // console.log(error)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  // var unix = Math.round(new Date('2021-12-12')/1000);
  // var date = new Date('2021-12-12')
  // console.log(date);
  // console.log(unix);

  const columns = [
    {
      title: t("Service number"),
      dataIndex: "ser_no",
      key: "ser_no",
      render: text => checkData(text),
      width: "100px",
    },
    {
      title: t("Date created"),
      dataIndex: "created_on",
      key: "created_on",
      render: text => cutTimeZone(text),
      width: "100px",
    },
    {
      title: t("Priority"),
      dataIndex: "pri_name",
      key: "pri_name",
      render: text => render_priority(text),
      width: "80px",
    },
    {
      title: t("Car name"),
      dataIndex: "car_name",
      key: "car_name",
      render: text => checkData(text),
      width: "150px",
    },
    {
      title: t("Car license"),
      dataIndex: "car_license_number",
      key: "car_license_number",
      render: text => checkData(text),
      width: "130px",
    },
    {
      title: t("Customer name"),
      dataIndex: "cus_name",
      key: "cus_name",
      render: text => checkData(text),
      width: "130px",
    },
    {
      title: t("Product serial number"),
      dataIndex: "pro_serial_number",
      key: "pro_serial_number",
      render: text => checkData(text),
      width: "130px",
    },
    {
      title: t("Confirmed by"),
      dataIndex: "created_by_firstname",
      key: "created_by_firstname",
      render: text => checkData(text),
      width: "100px",
    },
    {
      title: t("Position"),
      dataIndex: "created_by_role",
      key: "created_by_role",
      render: text => t(text),
      width: "130px",
    },
    {
      title: t("Service status"),
      dataIndex: "ser_status_name",
      key: "ser_status_name",
      render: text => render_status(text),
      width: "80px",
    },
    {
      title: t("Customer confirmation status"),
      render: text => statusUser(text),
      width: "120px",
    },
    {
      title: t("Waiting for customer confirmation"),
      render: text => <div>{check_waiting_user_confirm(text)}</div>,
      width: "200px",
    },
    {
      title: t("Confirmed on"),
      render: text => <div>{check_date_time_confirm(text)}</div>,
      width: "150px",
    },
    {
      title: "",
      fixed: 'right',
      render: text => (
        <Tooltip title={t("View service")}>
          <a>
            <FcSearch
              onClick={() => {
                setVisible(true)
                setData_Service_modal(text)
              }}
              size={20}
            />
          </a>
        </Tooltip>
      ),
      width: "50px",
    },
    {
      title: "",
      fixed: 'right',
      render: text => (
        <Tooltip title={t("Edit service")}>
          <a>
            <FcSettings
              size={20}
              onClick={() => history.push(`/service/${text.ser_id}`)}
            />
          </a>
        </Tooltip>
      ),
      width: "50px",
    },
  ]

  function render_time(date_time) {
    const date_data = new Date(date_time)
    let expiryDate = new Date(
      new Date(date_data).setHours(date_data.getHours() + 7)
    )
    const date_all = new Date(expiryDate)
    return date_all.toLocaleTimeString("en-GB")
  }

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return "N/A"
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  function check_waiting_user_confirm(data) {
    // console.log(data.ser_status_name);
    if (data.ser_status_name === "cancel") {
      if (data.is_user_cancel === 1) {
        return <p style={{ color: "red" }}> {t("Customer cancel")} </p>
      } else {
        return <p style={{ color: "red" }}> {t("Admin cancel")} </p>
      }
    } else {
      if (data.is_user_active_at === null) {
        let dateTime = timeDiffCalc(new Date(data.created_on), new Date())
        return <p>{dateTime}</p>
      } else {
        return <p style={{ color: "green" }}> {t("Customer confirm")} </p>
      }
    }
  }

  function timeDiffCalc(dateFuture, dateNow) {
    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000

    const days = Math.floor(diffInMilliSeconds / 86400)
    diffInMilliSeconds -= days * 86400

    const hours = Math.floor(diffInMilliSeconds / 3600) % 24
    diffInMilliSeconds -= hours * 3600

    const minutes = Math.floor(diffInMilliSeconds / 60) % 60
    diffInMilliSeconds -= minutes * 60

    let difference = ""
    if (days > 0) {
      difference +=
        days === 1 ? `${days} ${t("day")}, ` : `${days} ${t("days")}, `
    }

    difference +=
      hours === 0 || hours === 1
        ? `${hours} ${t("hour")}, `
        : `${hours} ${t("hours")}, `

    difference +=
      minutes === 0 || hours === 1
        ? `${minutes} ${t("minutes")}`
        : `${minutes} ${t("minutes")}`

    return difference
  }

  function check_date_time_confirm(data) {
    if (data.is_user_active_at === null) {
      return <p>N/A</p>
    } else {
      return (
        <div>
          <p>
            {t("date")} : {cutTimeZone(data.is_user_active_at)}
          </p>
          <p>
            {t("time")} : {render_time(data.is_user_active_at)}
          </p>
        </div>
      )
    }
  }

  function statusUser(status) {
    const styleDivIcon = { textAlign: "center" }
    if (status.ser_status_name === "cancel") {
      if (status.is_user_cancel === 1 && status.is_user_confirm === 0) {
        return (
          <div style={styleDivIcon}>
            <Tooltip title={t("Customer cancel")}>
              <FcCancel size={30} />
            </Tooltip>
          </div>
        )
      } else if (status.is_user_cancel === 0 && status.is_user_confirm === 1) {
        return (
          <div style={styleDivIcon}>
            <Tooltip title={t("Admin cancel")}>
              <FcHighPriority size={30} />
            </Tooltip>
          </div>
        )
      } else if (status.is_user_cancel === 0 && status.is_user_confirm === 0) {
        return (
          <div style={styleDivIcon}>
            <Tooltip title={t("Admin cancel")}>
              <FcHighPriority size={30} />
            </Tooltip>
          </div>
        )
      }
    } else if (status.is_user_cancel === 0 && status.is_user_confirm === 1) {
      return (
        <div style={styleDivIcon}>
          <Tooltip title={t("Customer confirm")}>
            <FcApproval size={30} />
          </Tooltip>
        </div>
      )
    } else {
      return (
        <div style={styleDivIcon}>
          <Tooltip title={t("Waiting for customer confirmation")}>
            <FcClock size={30} />
          </Tooltip>
        </div>
      )
    }
  }

  function render_status(status) {
    switch (status) {
      case "cancel":
        return <Tag color="error"> {t("cancel")} </Tag>
        break
      case "Cancel":
        return <Tag color="error"> {t("cancel")} </Tag>
        break
      case "reject":
        return <Tag color="error"> {t("reject")} </Tag>
        break
      case "overdue":
        return <Tag color="error">{t("overdue")}</Tag>
        break
      case "resolved":
        return <Tag color="success">{t("resolved")}</Tag>
        break
      case "complete":
        return <Tag color="success">{t("complete")}</Tag>
        break
      case "inprogress":
        return <Tag color="processing">{t("inprogress")}</Tag>
        break
      case "unassigned":
        return <Tag color="default">{t("unassigned")}</Tag>
        break
      case "pending":
        return (
          <Tag
            style={{
              color: "rgb(234, 159, 230)",
              borderColor: "rgb(234, 159, 230)",
              backgroundColor: "#FFF",
            }}
          >
            {t("pending")}
          </Tag>
        )
        break
      case "assigned":
        return (
          <Tag
            style={{
              color: "#DCC200",
              borderColor: "#DCC200",
              backgroundColor: "#FFF",
            }}
          >
            {t("assigned")}
          </Tag>
        )
        break
      case "waiting for admin approve":
        return (
          <Tag
            style={{
              color: "#0071FF",
              borderColor: "#0071FF",
              backgroundColor: "#DFEDFF",
            }}
          >
            {t("waiting for admin approve")}
          </Tag>
        )
        break
      case "waiting for manager approve":
        return (
          <Tag
            style={{
              color: "#0071FF",
              borderColor: "#0071FF",
              backgroundColor: "#DFEDFF",
            }}
          >
            {t("waiting for manager approve")}
          </Tag>
        )
        break
      default:
        return <Tag color="default">{status}</Tag>
        break
    }
  }

  function render_priority(priority) {
    switch (priority) {
      case "urgent":
        return <Tag color="error">{t("urgent")}</Tag>
        break
      case "high":
        return <Tag color="warning">{t("high")}</Tag>
        break
      case "medium":
        return <Tag color="processing">{t("medium")}</Tag>
        break
      case "low":
        return <Tag color="success">{t("low")}</Tag>
        break
      default:
        return <Tag color="success">{priority}</Tag>
    }
  }

  function checkData(data) {
    if (data) {
      return data
    } else {
      return "N/A"
    }
  }

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return "N/A"
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Service")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>{t("Service")}</h3>
          {permi_t === true && (
            <Card>
              <CardBody>
                <Search_Service
                  filter_service={filter_service}
                  setFilter_service={setFilter_service}
                  setSearch_service={setSearch_service}
                  search_data={search_data}
                  filter_Role={filter_Role}
                  setFilter_Role={setFilter_Role}
                />

                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={data_list}
                  pagination={false}
                  rowKey="ser_id"
                  scroll={{ x: 2500 }}
                />

                <div>
                  <Pagination
                    current={page_limit.page}
                    total={page_limit.limit}
                    onChange={changePagination}
                    style={{ marginTop: "20px", float: "right" }}
                  />
                </div>
                <p style={{ fontSize: "15px", marginTop: "20px" }}>
                  {" "}
                  <FcDataSheet size={20} style={{ marginBottom: "5px" }} />{" "}
                  {t("total data")} : {page_limit.limit}
                </p>

                <Modal
                  title={false}
                  centered
                  open={visible}
                  onOk={() => setVisible(false)}
                  onCancel={() => {
                    setVisible(false)
                    setData_Service_modal({})
                  }}
                  footer={false}
                  height={500}
                  width={1000}
                >
                  <Detail_Service data_Service_modal={data_Service_modal} />
                </Modal>
              </CardBody>
            </Card>
          )}
          {permi_t === false && (
            <div>
              <img
                src={no_permi}
                alt="no permission"
                style={{ display: "block", margin: "50px auto" }}
              />
              <p style={{ textAlign: "center", fontSize: "20px" }}>
                {t("no permission read module")}
              </p>
              <p style={{ textAlign: "center", fontSize: "20px" }}>
                {" "}
                {t("please contract administrator")}{" "}
              </p>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Service
