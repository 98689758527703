import React, { useEffect, useState } from "react"
import create_color from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/create_color.png"
import create_no_color from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/create_no_color.png"
import progress_color from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/progress_color.png"
import progress_no_color from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/progress_no_color.png"
import success_color from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/success_color.png"
import success_no_color from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/success_no_color.png"
import cancel_color from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/cancel_color.png"
import cancel_no_color from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/cancel_no_color.png"
import { useTranslation } from "react-i18next"

import "./Status_style.css"

export default function Step_Service(props) {
  const { t } = useTranslation()
  const [status_show_img, setStatus_show_img] = useState(null)
useEffect(() => {
  
  return () => {
    setStatus_show_img(null)
  }
}, [])
  useEffect(() => {
    if (props.data_all) {
      render_image_status(props.data_all.ser_status_name)
    }
    return () => {}
  }, [props.data_all])

  function render_image_status(service_status) {
    switch (service_status) {
        case "unassigned":
          return setStatus_show_img('Create')
          break
        case "assigned": 
          return setStatus_show_img('Create')
          break
        case "inprogress":
          return setStatus_show_img('Progress')
          break
        case "pending":
          return setStatus_show_img('Progress')
          break
          case "cancel":
            return setStatus_show_img('Cancel')
            break
            case "reject":
                return setStatus_show_img('Cancel')
                break
        case "waiting for admin approve":
          return setStatus_show_img('Progress')
          break
        case "waiting for manager approve":
          return setStatus_show_img('Progress')
          break
        case "complete":
          return setStatus_show_img('Complete')
          break
        case "resolved":
          return setStatus_show_img('Complete')
          break
        default:
          return setStatus_show_img('Create')
          break;
    }
  }


  return (
    <div
      style={{
        boxShadow: "rgb(99 99 99 / 20%) 0px 2px 8px 0px",
        padding: "10px",
        textAlign: "center",
      }}
    >
    {
        status_show_img === "Create" && (
            <div>
            <div className="div-status-name">
              <img src={create_color} alt="create_color" />
              <p className="text-status-service-pass"> {t("Create")} </p>
            </div>
            <div className="div-status-name">
              <img src={progress_no_color} alt="progress_no_color" className="img-no-pass" />
              <p className="text-status-service-not-pass"> {t("Progress")} </p>
            </div>
            <div className="div-status-name">
              <img src={success_no_color} alt="success_no_color" className="img-no-pass"/>
              <p className="text-status-service-not-pass"> {t("Complete")} </p>
            </div>
          </div>
        )
      }

      {
        status_show_img === "Progress" && (
            <div>
            <div className="div-status-name">
              <img src={create_color} alt="create_color" />
              <p className="text-status-service-pass">{t("Create")}</p>
            </div>
            <div className="div-status-name">
              <img src={progress_color} alt="progress_no_color" />
              <p className="text-status-service-pass">{t("Progress")}</p>
            </div>
            <div className="div-status-name">
              <img src={success_no_color} alt="success_no_color" className="img-no-pass"/>
              <p className="text-status-service-not-pass">{t("Complete")}</p>
            </div>
          </div>
        )
      }

      {
        status_show_img === "Complete" && (
            <div>
            <div className="div-status-name">
              <img src={create_color} alt="create_color" />
              <p className="text-status-service-pass">{t("Create")}</p>
            </div>
            <div className="div-status-name">
              <img src={progress_color} alt="progress_no_color" />
              <p className="text-status-service-pass">{t("Progress")}</p>
            </div>
            <div className="div-status-name">
              <img src={success_color} alt="success_no_color"/>
              <p className="text-status-service-pass">{t("Complete")}</p>
            </div>
          </div>
        )
      }

      {
        status_show_img === "Cancel" && (
            <div>
            <div className="div-status-name">
              <img src={create_color} alt="create_color" />
              <p className="text-status-service-pass">{t("Create")}</p>
            </div>
            <div className="div-status-name">
              <img src={progress_color} alt="progress_no_color" />
              <p className="text-status-service-pass">{t("Progress")}</p>
            </div>
            <div className="div-status-name">
              <img src={cancel_color} alt="cancel_color"/>
              <p className="text-status-service-pass">{t("Cancel")}</p>
            </div>
          </div>
        )
      }

    </div>
  )
}
