import React, { useRef, useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { useHistory } from "react-router-dom"
import { Form, Button, notification, Modal, Avatar } from "antd"
import axios from "axios"
import * as api from "../../../service/Api_Admin"
import { useTranslation } from "react-i18next"
import Input_Comp from "../../components/admin/Input_Comp"
import Select_no_search from "../../components/admin/Select_no_search"
import Render_Center_Avatar from "../../Operator/Table_data/Render_Center_Avatar"
import helpers from "../../Function_Redirect/Redirect_login"
import { QuestionOutlined } from "@ant-design/icons"

const { confirm } = Modal

export default function Edit(props) {
  const token = localStorage.getItem("token")
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const history = useHistory()
  const imageUploader = useRef(null)
  const [edit, setEdit] = useState(true)
  const [loading, setLoading] = useState(false)
  const [image_select_upload, setImage_select_upload] = useState(null)
  const [admin_edit, setAdmin_edit] = useState({
    admin_firstname: props.detail_admin.admin_firstname,
    admin_lastname: props.detail_admin.admin_lastname,
    admin_code: props.detail_admin.admin_code,
    admin_group_id: props.detail_admin.admin_group_id,
  })

  useEffect(() => {
    return () => {
      setEdit(true)
      setLoading(false)
      setImage_select_upload(null)
      setAdmin_edit(null)
    }
  }, [])

  useEffect(() => {
    onFill()
    return () => {}
  }, [props.detail_admin])

  const onFill = () => {
    form.setFieldsValue({
      admin_firstname: props.detail_admin.admin_firstname,
      admin_lastname: props.detail_admin.admin_lastname,
      admin_code: props.detail_admin.admin_code,
      admin_group_id: props.detail_admin.admin_group_id,
    })
    setAdmin_edit({
      ...admin_edit,
      admin_firstname: props.detail_admin.admin_firstname,
      admin_lastname: props.detail_admin.admin_lastname,
      admin_code: props.detail_admin.admin_code,
      admin_group_id: props.detail_admin.admin_group_id,
    })
  }

  const onFinish = value => {
    return confirm({
      title: t("Edit admin information"),
      icon: <QuestionOutlined style={{ color: "#1890ff" }} />,
      centered: true,
      okText: t("edit"),
      cancelText: t("no"),
      onOk() {
        setLoading(true)
        if (image_select_upload !== null) {
          const formData = new FormData()
          formData.append("file", image_select_upload)
          const upload_image = api.UPLOAD_PROFILE(token, formData)
          axios(upload_image)
            .then(res => {
              let add_image_path = {
                admin_avatar: res.data.path,
                ...admin_edit,
              }
              edit_admin_info(add_image_path)
            })
            .catch(error => {
              setLoading(false)
              if (error) {
                if (error.response) {
                  if (error.response.status === 401) {
                    helpers.re_login()
                    history.push("/login")
                  } else {
                    openNotificationWithIcon(
                      "error",
                      t("edit admin information error")
                    )
                  }
                }
              }
            })
        } else {
          edit_admin_info(admin_edit)
        }
      },
      onCancel() {},
    })
  }

  function edit_admin_info(data) {
    const edit_admin = api.EDIT_ADMIN(token, props.admin_id, data)
    axios(edit_admin)
      .then(res => {
        Cancel_Edit()
        setLoading(false)
        props.find_one_admin()
        openNotificationWithIcon("success", t("edit admin information success"))
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            } else {
              openNotificationWithIcon(
                "error",
                t("edit admin information error")
              )
            }
          }
        }
      })
  }

  const openNotificationWithIcon = (type, description) => {
    notification[type]({
      message: description,
    })
  }

  function Cancel_Edit() {
    setEdit(!edit)
    onFill()
    setImage_select_upload(null)
  }

  const handleImageUpload = e => {
    if (e.target.files[0] !== undefined) {
      if (e.target.files[0].type !== "image/jpeg") {
        openNotificationWithIcon(
          "error",
          t("please upload jpg or jpeg image only")
        )
      } else {
        let img_upload = e.target.files[0]
        setImage_select_upload(img_upload)
      }
    }
  }

  return (
    <div>
      <Form form={form} name="control-hooks" onFinish={onFinish}>
        <Row>
          <Col>
            <div style={{ display: "inherit" }}>
              <p
                className="text-bold"
                style={{
                  color: "#495057",
                  textAlign: "center",
                  fontSize: "15px",
                }}
              >
                {t("Admin information")}
              </p>
              {edit === true && (
                <Button
                  style={{ float: "right", width: "150px" }}
                  onClick={() => setEdit(!edit)}
                >
                  {t("Edit")}
                </Button>
              )}
              {edit === false && (
                <Button
                  type="primary"
                  style={{ float: "right", width: "150px" }}
                  onClick={() => Cancel_Edit()}
                >
                  {t("Cancel")}
                </Button>
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <input
              type="file"
              accept="image/jpeg"
              onChange={handleImageUpload}
              ref={imageUploader}
              style={{
                display: "none",
              }}
            />
            {image_select_upload === null && (
              <Render_Center_Avatar
                path_image={props.detail_admin.admin_avatar}
                size={120}
              />
            )}
            {image_select_upload !== null && (
              <div className="image-upload">
                <Avatar
                  src={URL.createObjectURL(image_select_upload)}
                  size={120}
                  style={{ display: "block", margin: "auto" }}
                />
              </div>
            )}

            {edit !== true && (
              <Button
                type="primary"
                style={{
                  width: "150px",
                  display: "block",
                  margin: "20px auto 20px auto",
                }}
                onClick={() => imageUploader.current.click()}
              >
                {t("upload photo")}
              </Button>
            )}
            <br />
          </Col>
        </Row>
        <br />
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("firstname")}
              placeholder={props.detail_admin.admin_firstname}
              headState={setAdmin_edit}
              nameState={admin_edit}
              disabled={edit}
              keyState="admin_firstname"
              type="text"
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("lastname")}
              placeholder={props.detail_admin.admin_lastname}
              headState={setAdmin_edit}
              nameState={admin_edit}
              disabled={edit}
              keyState="admin_lastname"
              type="text"
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("code")}
              placeholder={props.detail_admin.admin_code}
              headState={setAdmin_edit}
              nameState={admin_edit}
              disabled={edit}
              keyState="admin_code"
              type="text"
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Admin group")}
              placeholder={t("Admin group")}
              name_api="FIND_ALL_ADMIN_GROUP"
              map_name="admin_group_name"
              map_value="admin_group_id"
              keyState="admin_group_id"
              Header_data="data"
              headState={setAdmin_edit}
              nameState={admin_edit}
              disabled={edit}
            />
          </Col>
        </Row>
        <br />
        {edit === false && (
          <Button
            type="primary"
            htmlType="submit"
            style={{ display: "block", margin: "auto", width: "150px" }}
            loading={loading}
          >
            {t("Edit")}
          </Button>
        )}
      </Form>
    </div>
  )
}
