import React from "react"
import { Row, Col } from "reactstrap"
import { Button, Modal, Tabs, Collapse } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import Overview_User_Info from "./Overview_User_Info"
import Overview_Customer_And_Branch_Info from "./Overview_Customer_And_Branch_Info"
import Form_user from "./Form_user"
import { useTranslation } from "react-i18next";

const { Panel } = Collapse
const { confirm } = Modal
const { TabPane } = Tabs

export default function Step_Create_User(props) {
  const { t } = useTranslation();

  function removeStep(index) {
    const values = [...props.user]
    const values_now = [...props.select_user_now]
    const values_confirm_pass = [...props.confirm_pass_all]
    const error_data = [...props.error_all]
    const error_email_duplicate = [...props.error_email]
    const list_role = [...props.list_checkbox]
    if(values.length === 1){
      props.setHandle_button(false)
      props.setUser([])
      props.setSelect_user_now([])
      props.setSelect_user([])
      props.setConfirm_pass_all([])
      props.setError_all([])
      props.setError_email([])
      props.setList_Checkbox([])
      
    } else {
      values.splice(index, 1)
      values_now.splice(index, 1)
      values_confirm_pass.splice(index, 1)
      error_data.splice(index, 1)
      error_email_duplicate.splice(index, 1)
      list_role.splice(index, 1)
      props.setUser(values)
      props.setSelect_user_now(values_now)
      props.setConfirm_pass_all(values_confirm_pass)
      props.setError_all(error_data)
      props.setError_email(error_email_duplicate)
      props.setList_Checkbox(list_role)
    }
  }

  function cancel_edit_user_data() {
    return confirm({
      title: t("Do you want to cancel select user"),
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: t("Confirm"),
      cancelText: t("no"),
      onOk() {
        props.setHandle_button(false)
        props.setUser([])
        props.setSelect_user_now([])
        props.setSelect_user([])
        props.setConfirm_pass_all([])
        props.setError_all([])
        props.setError_email([])
        props.setList_Checkbox([])
      },
      onCancel() {},
    })
  }

  function add_new_user_data() {
    const values = [...props.user]
    const values_confirm_pass = [...props.confirm_pass_all]
    const error_data = [...props.error_all]
    const error_email_duplicate = [...props.error_email]
    const list_role = [...props.list_checkbox]
    values.push({
      cus_email_password_condition: false,
      // cus_user_code: null,
      cus_user_email: null,
      cus_user_firstname: null,
      cus_user_lastname: null,
      cus_user_password: null,
      cus_user_phone: null,
      dep_id: null,
      pos_id: null,
      not_send_email_notification : false ,
      not_send_line_notification : false ,
    })
    values_confirm_pass.push({
      confirm_pass : null
    })
    error_data.push({
      error : null  
    })
    error_email_duplicate.push({
      error : null 
    })
    list_role.push({
      listRole : null
    })
    props.setUser(values)
    props.setConfirm_pass_all(values_confirm_pass)
    props.setError_all(error_data)
    props.setError_email(error_email_duplicate)
    props.setList_Checkbox(list_role)
  }

  return (
    <div>
      <br />
      <Row>
        <Col>
          <Button
            className="btn-Cancel-select-user"
            onClick={() => cancel_edit_user_data()}
          >
            {t("Cancel select user")}
          </Button>

          <Button
            className="btn-add-new-user"
            type="primary"
            onClick={() => add_new_user_data()}
          >
            {t("Add new user")}
          </Button>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Tabs defaultActiveKey="0">
            {props.user.map((step, index) => {
              return (
                <TabPane
                  tab={
                    step.cus_user_firstname
                      ? step.cus_user_firstname
                      : t("New user")
                  }
                  key={index}
                >
                  <Button
                    danger
                    onClick={() => removeStep(index)}
                    style={{
                      marginLeft: "auto",
                      marginRight: "0px",
                      display: "flex",
                    }}
                  >
                    {t("Remove")} {step.cus_user_firstname}
                  </Button>
                  <hr />
                  <Collapse>
                    <Panel header={t("Customer and branch information")} key="1">
                      <Overview_Customer_And_Branch_Info
                        customer_data={props.customer_data}
                        branch={props.branch}
                      />
                    </Panel>

                    { props.select_user_now[index] && (
                        <Panel header={t("User information")} key="2">
                          <Overview_User_Info
                            select_user={props.select_user_now[index]}
                          />
                        </Panel>
                      )}
                  </Collapse>
                  <hr />
                  <Form_user
                    setUser={props.setUser}
                    user={props.user}
                    index_user={index}
                    detail_user={step}
                    confirm_pass_all={props.confirm_pass_all}
                    setConfirm_pass_all={props.setConfirm_pass_all}
                    setError_all={props.setError_all}
                    error_all={props.error_all}
                    setError_email={props.setError_email}
                    error_email={props.error_email}
                    setList_Checkbox={props.setList_Checkbox}
                    list_checkbox={props.list_checkbox}
                    setError_form={props.setError_form}
                  />
                </TabPane>
              )
            })}
          </Tabs>
        </Col>
      </Row>
    </div>
  )
}
