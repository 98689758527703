import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { Button, Input, Form } from "antd"
import { useTranslation } from "react-i18next"

export default function Input_Search({
  setSearch_report,
  check_report,
  downloadExcel,
  loading_excel,
}) {
  const { t } = useTranslation()

  const onFinish = values => {
    check_report()
  }

  return (
    <div>
      <Form
        name="Search_employee"
        initialValues={{
          remember: false,
        }}
        onFinish={onFinish}
      >
        <Row>
          <Col sm={12} md={6} lg={6} xl={4}>
            <Form.Item
              name="search"
              rules={[
                {
                  required: false,
                  message: "Please input name,department",
                },
              ]}
            >
              <Input
                placeholder={t("search")}
                style={{ marginBottom: "15px" }}
                onChange={e => setSearch_report(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={6} lg={4} xl={4}>
            <Button
              type="primary"
              className="button-search-employee"
              htmlType="submit"
            >
              {t("search")}
            </Button>
            {
              downloadExcel !== null && (
                <Button
                className="button-search-New-Employee"
                onClick={() => downloadExcel()}
                loading={loading_excel}
              >
                {t("Export excel")}
              </Button>
              )
            }
          </Col>
        </Row>
      </Form>
    </div>
  )
}
