import React, { useState, useEffect } from "react"
import { Container, Card, CardBody, Row, Col } from "reactstrap"
import MetaTags from "react-meta-tags"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { MdKeyboardBackspace } from "react-icons/md"
import helpers from "../../Function_Redirect/Redirect_login"
import axios from "axios"
import * as api from "../../../service/service_dealer/Api_Service"
import { FcDataSheet } from "react-icons/fc"
import { Pagination, Button, Input, Form, Table } from "antd"
import { EditOutlined } from "@ant-design/icons"
import "../Customer/Search/Search_Customer.css"
import Render_Image from "../../Customer_All/Device/Render_Image"
import Select_Device from "./Select_Device"
import { useSelector } from "react-redux"

const Create_New_Repair = props => {
  const { t } = useTranslation()
  const history = useHistory()
  const token = localStorage.getItem("token")
  const dea_id = localStorage.getItem("dea_id")
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })
  const [data_list, setData_list] = useState([])
  const [loading, setLoading] = useState(false)
  const [search_data_customer, setSearch_data_customer] = useState(null)
  const [select_customer, setSelect_customer] = useState({
    cus_id: null,
    cus_name: null,
  })
  const { dealer_user_id } = useSelector(({ Distar }) => Distar)

  useEffect(() => {
    search_data()
    return () => {
      setData_list([])
      setLoading(false)
      setSearch_data_customer(null)
    }
  }, [dealer_user_id])

  const check_data = (params, data) => {
    if (data) {
      return params + data
    } else {
      return ""
    }
  }

  const search = values => {
    search_data()
  }

  function search_data() {
    setLoading(true)
    let url = `api/v1/dealer/customer/${dealer_user_id}?page=1&limit=${
      page_limit.page_Size
    }&order_by=asc${check_data("&q=", search_data_customer)}`
    const search = api.SEARCH_ALL(token, url)
    axios(search)
      .then(res => {
        setLoading(false)
        setPage_limit({
          ...page_limit,
          page: 1,
          limit: res.data.total_data,
        })
        setData_list(res.data.data)
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  function changePagination(page, pageSize) {
    setLoading(true)
    let url = `api/v1/dealer/customer/${dealer_user_id}?page=${page}&limit=${pageSize}&order_by=asc${check_data(
      "&q=",
      search_data_customer
    )}`
    const search = api.SEARCH_ALL(token, url)
    axios(search)
      .then(res => {
        setLoading(false)
        setPage_limit({
          ...page_limit,
          page: page,
          page_Size: pageSize,
          limit: res.data.total_data,
        })
        // console.log(res.data.data)
        setData_list(res.data.data)
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
        // console.log(error)
      })
  }

  function checkData(data) {
    if (data) {
      return data
    } else {
      return "N/A"
    }
  }

  const columns = [
    {
      title: "",
      dataIndex: "cus_avatar",
      key: "cus_avatar",
      render: text => <Render_Image path_image={text} size={80} />,
    },
    {
      title: t("Customer name"),
      dataIndex: "cus_name",
      key: "cus_name",
      render: text => checkData(text),
    },
    {
      title: t("Customer phone"),
      dataIndex: "cus_phone",
      key: "cus_phone",
      render: text => checkData(text),
    },
    {
      title: t("Customer email"),
      dataIndex: "cus_email",
      key: "cus_email",
      render: text => checkData(text),
    },
    {
      title: t("tax id"),
      dataIndex: "cus_taxid",
      key: "cus_taxid",
      render: text => checkData(text),
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelect_customer({
        ...select_customer,
        cus_id: selectedRows[0].cus_id,
        cus_name: selectedRows[0].cus_name,
      })
    },
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Create new repair")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/repair")}
              />
            </a>{" "}
            {t("Create new repair")}
          </h3>

          {select_customer.cus_id === null && (
            <Card>
              <CardBody>
                <div>
                  <Form
                    name="Search_customer"
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={search}
                  >
                    <Row>
                      <Col sm={12} md={6} lg={6} xl={4}>
                        <Form.Item
                          name="search"
                          rules={[
                            {
                              required: false,
                              message: "Please input name customer",
                            },
                          ]}
                        >
                          <Input
                            placeholder={` ${t("name")} , ${t("tax id")}`}
                            style={{ marginBottom: "15px" }}
                            onChange={e =>
                              setSearch_data_customer(e.target.value)
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col sm={12} md={6} lg={4} xl={4}>
                        <Button
                          type="primary"
                          className="button-search-employee"
                          htmlType="submit"
                        >
                          {t("search")}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
                <Table
                  rowSelection={{
                    type: "radio",
                    ...rowSelection,
                  }}
                  columns={columns}
                  loading={loading}
                  dataSource={data_list}
                  pagination={false}
                  rowKey="cus_id"
                  scroll={{ x: 800 }}
                />
                <div>
                  <Pagination
                    defaultPageSize={12}
                    pageSizeOptions={["12", "20", "52", "100"]}
                    current={page_limit.page}
                    total={page_limit.limit}
                    onChange={changePagination}
                    style={{ marginTop: "20px", float: "right" }}
                  />
                </div>
                <p style={{ fontSize: "15px", marginTop: "20px" }}>
                  {" "}
                  <FcDataSheet size={20} style={{ marginBottom: "5px" }} />{" "}
                  {t("total data")}: {page_limit.limit}
                </p>
              </CardBody>
            </Card>
          )}

          {select_customer.cus_id !== null && (
            <Card>
              <CardBody>
                <Row>
                  <Col sm={12} md={6} lg={6} xl={6}>
                    <div style={{ display: "flex" }}>
                      <p className="text-bold " style={{ marginTop: "7px" }}>
                        {t("Customer")}
                      </p>
                      <Input
                        style={{ marginLeft: "40px", height: "35px" }}
                        value={select_customer.cus_name}
                        placeholder={select_customer.cus_name}
                        disabled
                      />
                      <a>
                        <EditOutlined
                          style={{ fontSize: "20px", marginLeft: "10px" }}
                          onClick={() => {
                            setSelect_customer({
                              ...select_customer,
                              cus_id: null,
                              cus_name: null,
                            })
                          }}
                        />
                      </a>
                    </div>
                  </Col>
                </Row>

                <Select_Device cus_id={select_customer.cus_id} />
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Create_New_Repair
