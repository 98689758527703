import React, { useState } from "react"
import { Row, Card, CardBody } from "reactstrap"
import { useHistory } from "react-router-dom"
import { Form, Input, Button, Select } from "antd"
import Detail_Search from "./Detail_Search"

const { Option } = Select

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
}

export default function Report_page() {
  const [show_search, setShow_search] = useState(false)
  const [data_demo_select, setData_demo_select] = useState(null)
  const history = useHistory()
  const onFinish = values => {
    history.push({ 
      pathname: '/create-new-job/'+data_demo_select.sn,
      job:data_demo_select
      })
   
  }

  const onFinishFailed = errorInfo => {
    console.log("Failed:", errorInfo)
  }

  const style_button = {
    marginLeft: "5px",
    backgroundColor: "#0084d1",
    color: "#FFF",
    borderRadius: "7px",
  }
  const style_button_submit = {
    backgroundColor: "#0084d1",
    color: "#FFF",
    borderRadius: "7px",
  }

  return (
    <div>
      <Card>
        <CardBody>
          <Row>
            <h5 style={{ color: "#495057" }}>Create a new job</h5>
          </Row>
          <Row>
            <Form
              {...layout}
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >

            <Form.Item
            label="Job name"
            name="job_name"
            rules={[
              {
                required: true,
                message: "Please input your Job name!",
              },
            ]}
          >
            <Input style={{ width:'200px' }} />
          </Form.Item>

              <Form.Item
                label="Customer"
                name="customer"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Select a type job"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="1">คุณภัทรพล</Option>
                  <Option value="2">คุณภานุเดช</Option>
                </Select>
              </Form.Item>

              <Form.Item
              label="Branch"
              name="branch"
              rules={[
                {
                  required: true,
                  message: "Please input your username!",
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Select a Branch"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value="1">Branch</Option>
              </Select>
            </Form.Item>

              <Form.Item
                label="License plate"
                name="license_plate"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Select a License plate"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                <Option value="1">กท-5214</Option>
                <Option value="2">งย-8541</Option>
                <Option value="3">กง-8514</Option>
                <Option value="4">กร-8888</Option>
                </Select>
              </Form.Item>

              <Form.Item label="Product">
                <Form.Item name="product" noStyle>
                  {" "}
                  <Input
                    placeholder="IMEI, SN , etc"
                    style={{ width: "200px" }}
                  />
                </Form.Item>
                <Button
                  style={style_button}
                  onClick={() => setShow_search(true)}
                >
                  search
                </Button>
              </Form.Item>

              <Row>{show_search && <Detail_Search setData_demo_select={setData_demo_select} />}</Row>

              <Form.Item {...tailLayout}>
                <Button htmlType="submit" style={style_button_submit}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Row>
        </CardBody>
      </Card>
    </div>
  )
}
