import React, { useState, useEffect } from "react"
import { Button, Modal, Form, Input, Table, Pagination } from "antd"
import { Row, Col } from "reactstrap"
import * as api from "../../../../service/Api_Employee"
import { useTranslation } from "react-i18next"
import { EditOutlined } from "@ant-design/icons"
import axios from "axios"
import { FcDataSheet } from "react-icons/fc"
import { useHistory } from "react-router-dom"
import helpers from "../../../Function_Redirect/Redirect_login"

export default function FilterEmployee(props) {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const [search_data_user, setSearch_data_user] = useState(null)
  const token = localStorage.getItem("token")
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })
  const [data_list, setData_list] = useState([])
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  useEffect(() => {
    search_data()
    setImageSize()
    return () => {
      setData_list([])
    }
  }, [])

  const setImageSize = () => {
    if (window.innerWidth < 768) {
      return "small"
    } else {
      return null
    }
  }

  const check_data = (params, data) => {
    if (data) {
      return params + data
    } else {
      return ""
    }
  }

  const searchFiled = value => {
    search_data()
  }

  function search_data() {
    setLoading(true)
    let url = `api/v1/employee?page=1&limit=${
      page_limit.page_Size
    }&order_by=asc${check_data("&q=", search_data_user)}`
    const search = api.SEARCH_ALL_EMPLOYEE(token, url)
    axios(search)
      .then(res => {
        setLoading(false)
        setPage_limit({
          ...page_limit,
          page: 1,
          limit: res.data.total_data,
        })
        // console.log(res.data.data)
        setData_list(res.data.data)
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  function changePagination(page, pageSize) {
    setLoading(true)
    let url = `api/v1/employee?page=${page}&limit=${pageSize}&order_by=asc${check_data(
      "&q=",
      search_data_user
    )}`
    const search = api.SEARCH_ALL_EMPLOYEE(token, url)
    axios(search)
      .then(res => {
        setLoading(false)
        setPage_limit({
          ...page_limit,
          page: page,
          page_Size: pageSize,
          limit: res.data.total_data,
        })
        // console.log(res.data.data)
        setData_list(res.data.data)
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
        // console.log(error)
      })
  }

  const columns = [
    {
      title: t("code"),
      dataIndex: "emp_code",
      key: "emp_code",
    },
    {
      title: t("firstname"),
      dataIndex: "emp_firstname",
      key: "emp_firstname",
    },
    {
      title: t("email"),
      dataIndex: "emp_email",
      key: "emp_email",
    },
    {
      title: t("nickname"),
      dataIndex: "emp_nickname",
      key: "emp_nickname",
    },
    {
      title: t("department"),
      dataIndex: "dep_name",
      key: "dep_name",
    },
    {
      title: t("employee group"),
      dataIndex: "emp_group_name",
      key: "emp_group_name",
    },
    {
      title: t("Admin group"),
      dataIndex: "admin_group_name",
      key: "admin_group_name",
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      props.setFilter_Role({
        ...props.filter_Role,
        request_by_id: selectedRows[0].emp_id,
        request_by_name: selectedRows[0].emp_firstname,
      })
      setVisible(false)
    },
  }

  return (
    <div>
      <p className="text-bold header-filter header-mt-filter-service"> {t("Select employee")} </p>
      {props.filter_Role.request_by_id === null &&
        props.filter_Role.request_by_name === null && (
          <div>
            <Button
              onClick={() => setVisible(true)}
              className="btn-select-customer"
            >
              {" "}
              {t("Select employee")}{" "}
            </Button>
          </div>
        )}

      {props.filter_Role.request_by_id !== null &&
        props.filter_Role.request_by_name !== null && (
          <Row>
            <Col sm={12} md={12} lg={12} xl={12}>
              <div style={{ display: "flex" }}>
                <p
                  className="text-bold "
                  style={{ marginTop: "7px", width: "150px" }}
                >
                  {t("employee")}
                </p>
                <Input
                  style={{ marginLeft: "0px", height: "35px" }}
                  value={props.filter_Role.request_by_name}
                  placeholder={props.filter_Role.request_by_name}
                  disabled
                />
                <a>
                  <EditOutlined
                    style={{ fontSize: "20px", marginLeft: "10px" }}
                    onClick={() => {
                      props.setFilter_Role({
                        ...props.filter_Role,
                        request_by_id: null,
                        request_by_name: null,
                      })
                    }}
                  />
                </a>
              </div>
            </Col>
          </Row>
        )}

      <Modal
        centered
        open={visible}
        footer={false}
        width={1000}
        onOk={() => {
          setVisible(false)
        }}
        onCancel={() => {
          setVisible(false)
        }}
      >
        <div
          style={{ height: "500px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <p className="text-bold"> {t("Select employee")} </p>
          <Form name="Search_customer_info" onFinish={searchFiled}>
            <Row>
              <Col sm={12} md={9} lg={9} xl={9}>
                <Form.Item
                  name="search"
                  rules={[
                    {
                      required: false,
                      message: "Please input name customer",
                    },
                  ]}
                >
                  <Input
                    placeholder={t("Search")}
                    style={{ marginBottom: "15px", width: "100%" }}
                    onChange={e => setSearch_data_user(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col sm={12} md={3} lg={3} xl={3}>
                <Button
                  type="primary"
                  className="button-search-employee"
                  htmlType="submit"
                >
                  {t("search")}
                </Button>
              </Col>
            </Row>
          </Form>
          <Table
            rowSelection={{
              type: "radio",
              ...rowSelection,
            }}
            loading={loading}
            pagination={false}
            scroll={{ x: 400 }}
            rowKey="emp_id"
            columns={columns}
            dataSource={data_list}
          />
          <div>
            <Pagination
              size={setImageSize()}
              current={page_limit.page}
              total={page_limit.limit}
              onChange={changePagination}
              style={{ marginTop: "20px", float: "right" }}
            />
          </div>
          <p style={{ fontSize: "15px", marginTop: "20px" }}>
            {" "}
            <FcDataSheet size={20} style={{ marginBottom: "5px" }} />{" "}
            {t("total data")} : {page_limit.limit}
          </p>
        </div>
      </Modal>
    </div>
  )
}
