import React, { useEffect, useState } from "react"
import axios from "axios"
import { Row, Col } from "reactstrap"
import * as api from "../../../service/Api_New_Stock"
import { Form, Select } from "antd"
import Find_All_Product_Form_Group from "./Find_All_Product_Form_Group"

const { Option } = Select

export default function Find_All_Product(props) {
  const token = localStorage.getItem("token")
  const [list_all_group_product, setList_all_group_product] = useState([])

  useEffect(() => {
    List_All_Product_Group()
    return () => {
      setList_all_group_product([])
    }
  }, [])

  function List_All_Product_Group() {
    const list_all_group = api.LIST_ALL_PRODUCT_GROUP(token)
    axios(list_all_group)
      .then(res => {
        setList_all_group_product(res.data.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    render_all_product_group()
    return () => {}
  }, [list_all_group_product])

  function render_all_product_group() {
    return list_all_group_product.map(data => {
      return (
        <Option key={data.pro_group_id} value={data.pro_group_id}>
          {data.pro_group_name}
        </Option>
      )
    })
  }

  function onChange(value) {
    if(value){
      props.setPro_group_id(value)
    } else {
      props.setPro_group_id(null)
      props.setName_product(null)
      props.setStock_new({
        ...props.stock_new,
        pro_id : null
    })
    }
    
  }

  return (
    <div>
      <Row>
        <Col sm={12} md={6} lg={6} xl={6}>
          <p className="text-bold">Select product group <span style={{ color:'red' }}>*</span></p>
          <Form.Item
            name="select_product_group_data_info"
            rules={[
              {
                required: false,
                message: `Please input product group`,
              },
            ]}
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select a product group"
              optionFilterProp="children"
              onChange={onChange}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {render_all_product_group()}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      {props.pro_group_id !== null && (
        <Find_All_Product_Form_Group
          setName_product={props.setName_product}
          name_product={props.name_product}
          pro_group_id={props.pro_group_id}
          setStock_new={props.setStock_new}
          stock_new={props.stock_new}
        />
      )}
    </div>
  )
}
