import React, { useEffect, useState } from "react"
import { Container, Card, CardBody } from "reactstrap"
import { useHistory, useParams } from "react-router-dom"
import MetaTags from "react-meta-tags"
import { MdKeyboardBackspace } from "react-icons/md"
import axios from "axios"
import * as api from "../../service/Api_Edit_Dealer"
import { Tabs } from "antd"
import Detail_Dealer from "./Detail_Dealer"
import Edit from "./Edit"
import Setting from "./Setting"
import User_Dealer from './User_Dealer'
import no_permi from '../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png'
import helpers from '../Function_Redirect/Redirect_login'
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux"

const { TabPane } = Tabs

const Edit_dealer = props => {
  const { t } = useTranslation();
  const history = useHistory()
  const [data_dealer, setData_dealer] = useState({})
  const token = localStorage.getItem("token")
  const [activeTab_all, setActiveTab_all] = useState("1")
  const permi = localStorage.getItem('permi')
  const [permi_t_read, setPermi_t_read] = useState(false)
  const [permi_t_edit, setPermi_t_edit] = useState(false)
  const [permi_t_delete, setPermi_t_delete] = useState(false)
  const { permissionAllService } = useSelector(({ Distar }) => Distar)

  let { dea_id } = useParams()
  // console.log('dea_id = '+dea_id);

useEffect(() => {
  if(permissionAllService){
    if(permissionAllService.dealer){
      setPermi_t_read(permissionAllService.dealer.read)
      setPermi_t_edit(permissionAllService.dealer.edit)
      setPermi_t_delete(permissionAllService.dealer.delete)
    }
  }
  return () => {

  }
}, [permissionAllService])

  useEffect(() => {
    if(props.location.page_local){
      setActiveTab_all(props.location.page_local)
    }
    return () => {
      
    }
  }, [props.location])

  useEffect(() => {
    check_new_data_edit()
    return () => {
      setData_dealer({})
    }
  }, [dea_id])

  function check_new_data_edit() {
    const new_data = api.DETAIL_DEALER(token, parseInt(dea_id))
    axios(new_data)
      .then(res => {
        if (res.data.data === null) {
          history.push("/dealer")
        } else {
          setData_dealer(res.data.data)
        }
      })
      .catch(error => {
        if(error){
          if(error.response){
            if(error.response.status === 401){
              helpers.re_login()
              history.push('/login')          
              }
          }
        }

      })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Edit dealer")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/dealer")}
              />
            </a>{" "}
            {t("Edit dealer")}
          </h3>
          {
            permi_t_read === true && (
              <Card>
              <CardBody>
                <Tabs defaultActiveKey="1"
                onTabClick={e => {
                  setActiveTab_all(e)
                }}
                activeKey={activeTab_all}
                >
                  <TabPane tab={t("Overview")} key="1">
                    <Detail_Dealer data_dealer={data_dealer} />
                  </TabPane>
                  {
                    permi_t_edit === true && (
                      <TabPane tab={t("Edit")} key="2">
                      <Edit
                        check_new_data_edit={check_new_data_edit}
                        data_dealer={data_dealer}
                        dea_id={parseInt(dea_id)}
                      />
                    </TabPane>
                    )
                  }
                  {
                    permi_t_edit === true && (
                      <TabPane tab={t("Users")} key="3">
                      <User_Dealer dea_id={parseInt(dea_id)} />
                    </TabPane>
                    )
                  }
       
                  {
                    permi_t_delete === true && (
                      <TabPane tab={t("Setting")} key="4">
                      <Setting dea_id={parseInt(dea_id)} />
                    </TabPane>
                    )
                  }
       
                </Tabs>
              </CardBody>
            </Card>
            )
          }
          {
            permi_t_read === false && (
              <div>
                <img src={no_permi} alt="no permission" style={{ display:'block' , margin: '50px auto' }} />
                  <p style={{ textAlign: "center", fontSize: "20px" }}>{t("no permission read module")}</p>
                  <p style={{ textAlign:'center', fontSize:'20px' }}> {t("please contract administrator")} </p>
                </div>
            )
          }
          
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Edit_dealer
