import React from "react"
import { InputNumber , Form, Input } from "antd"
import { useTranslation } from "react-i18next";

const { TextArea } = Input;

export default function Input_TextArea({ 
  placeholder,
    header,
    headState,
    nameState,
    keyState,
 }) {
        const { t } = useTranslation();
    return (
        <div >
        <p className="text-bold">
          {header} <span style={{ color:'red' }}>*</span>
        </p>
        <Form.Item
          name={keyState}
          rules={[
            {
              required: true,
              message: `${placeholder}`,
            },
          ]}
        >
        <TextArea 
        style={{ width:'100%' }}
          placeholder={placeholder}
          onChange={value =>
            headState({ ...nameState, [keyState]: value.target.value })
          }
        />
        </Form.Item>
      </div>
    )
}
