import React, { useState } from "react"
import { Row, Col } from "reactstrap"
import { Menu, Dropdown, Button, Space, notification, Modal, Form } from "antd"
import { EllipsisOutlined, UserOutlined } from "@ant-design/icons"
import axios from "axios"
import * as api from "../../../service/Api_Edit_Service"
import { useTranslation } from "react-i18next"
import Input_Comp from "../../Operator_All/Service_Operator/Detail_Service/components/Input_Comp"

import { ExclamationCircleOutlined } from "@ant-design/icons"
import { useHistory } from "react-router-dom"
import helpers from "../../Function_Redirect/Redirect_login"

const { confirm } = Modal

export default function Control_Service(props) {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loading_cancel, setLoading_cancel] = useState(false)
  const token = localStorage.getItem("token")
  const history = useHistory()
  const [Cancel_service, setCancel_service] = useState({
    data_cancel: null,
  })

  function handleMenuClick(e) {
    setVisible(true)
    // return confirm({
    //     title: t("Confirm cancel service"),
    //     icon: <ExclamationCircleOutlined />,
    //    centered: true ,
    //     okType: "danger",
    //     okText: t("Confirm"),
    //     cancelText: t("no"),
    //     onOk() {
    //     const cancel_service = api.CHANGE_STATUS_SERVICE(token, props.ser_id , 'cancel')
    //     axios(cancel_service)
    //     .then( res => {
    // openNotificationWithIcon('success', t("Cancel service success"))
    // props.list_service_overview()
    // props.check_new_data_edit()
    //     })
    //     .catch( error => {
    //         // console.log(error.response);
    //         openNotificationWithIcon('error', t("Cancel service error"))
    // if(error){
    //   if(error.response){
    //     if(error.response.status === 401){
    //       helpers.re_login()
    //       history.push('/login')
    //       }
    //   }
    // }

    //     })
    //     },
    //     onCancel() {

    //     },
    //   });
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item danger key="1">
        {t("Cancel service")}
      </Menu.Item>
    </Menu>
  )

  function resolved_status() {
    return confirm({
      title: t("Confirm resolved service"),
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: t("Confirm"),
      cancelText: t("no"),
      onOk() {
        setLoading(true)
        const change_status = api.CHANGE_STATUS_ACCEPT_SERVICE_HAS_DATA(
          token,
          props.ser_id,
          "complete",
          "complete service job"
        )
        axios(change_status)
          .then(res => {
            setLoading(false)
            props.check_activity_log()
            props.list_service_overview()
            props.check_new_data_edit()
            openNotificationWithIcon("success", t("Resolved service success"))
          })
          .catch(error => {
            // console.log(error.response);
            setLoading(false)
            if (error) {
              if (error.response) {
                if (error.response.status === 401) {
                  helpers.re_login()
                  history.push("/login")
                } else {
                  openNotificationWithIcon("error", t("Resolved service error"))
                }
              }
            }
          })
      },
      onCancel() {},
    })
  }

  function cancel_form() {
    form.resetFields()
    setVisible(false)
    setCancel_service({
      ...Cancel_service,
      data_cancel: null,
    })
  }

  const onFinish = value => {
    return confirm({
      title: t("Confirm cancel service"),
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okType: "danger",
      okText: t("Confirm"),
      cancelText: t("no"),
      onOk() {
        setLoading_cancel(true)
        if (Cancel_service.data_cancel === null) {
          const cancel_service = api.CHANGE_STATUS_SERVICE(
            token,
            props.ser_id,
            "cancel"
          )
          axios(cancel_service)
            .then(res => {
              setLoading_cancel(false)
              setVisible(false)
              openNotificationWithIcon("success", t("Cancel service success"))
              props.list_service_overview()
              props.check_new_data_edit()
              props.check_activity_log()
            })
            .catch(error => {
              setLoading_cancel(false)
              if (error) {
                if (error.response) {
                  if (error.response.status === 401) {
                    helpers.re_login()
                    history.push("/login")
                  } else {
                    openNotificationWithIcon(
                      "error",
                      t("Resolved service error")
                    )
                  }
                }
              }
            })
        } else {
          const cancel_service_remark = api.CHANGE_STATUS_SERVICE_HAS_DATA(
            token,
            props.ser_id,
            "cancel",
            Cancel_service.data_cancel
          )
          axios(cancel_service_remark)
            .then(res => {
              setLoading_cancel(false)
              setVisible(false)
              openNotificationWithIcon("success", t("Cancel service success"))
              props.list_service_overview()
              props.check_new_data_edit()
              props.check_activity_log()
            })
            .catch(error => {
              setLoading_cancel(false)
              if (error) {
                if (error.response) {
                  if (error.response.status === 401) {
                    helpers.re_login()
                    history.push("/login")
                  } else {
                    openNotificationWithIcon(
                      "error",
                      t("Resolved service error")
                    )
                  }
                }
              }
            })
        }
      },
      onCancel() {},
    })
  }

  return (
    <div style={{ float: "right" }}>
      {props.service_status_name !== "pending" &&
        props.service_status_name !== "complete" &&
        props.service_status_name !== "cancel" &&
        props.service_status_name !== "reject" && (
          <Space wrap>
            <Button
              disabled
              style={{
                marginRight: "-8px",
                width: "150px",
                backgroundColor: "#CAE1CB",
                color: "#FFF",
                borderColor: "#CAE1CB",
              }}
            >
              {t("Resolved")}
            </Button>
            <Dropdown overlay={menu}>
              <Button>
                <EllipsisOutlined />
              </Button>
            </Dropdown>
          </Space>
        )}

      {props.service_status_name === "pending" && (
        <Space wrap>
          <Button
            style={{
              marginRight: "-8px",
              width: "150px",
              backgroundColor: "#03A70A",
              color: "#FFF",
              borderColor: "#03A70A",
            }}
            onClick={() => resolved_status()}
            loading={loading}
          >
            {t("Resolved")}
          </Button>
          <Dropdown overlay={menu}>
            <Button>
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        </Space>
      )}

      {props.service_status_name === "complete" && (
        <p style={{ color: "#03A70A", fontSize: "25px" }}>
          {" "}
          {t("Service complete")}{" "}
        </p>
      )}

      {props.service_status_name === "cancel" && (
        <p style={{ color: "red", fontSize: "25px" }}>
          {" "}
          {t("Cancel service")}{" "}
        </p>
      )}

      {props.service_status_name === "reject" && (
        <p style={{ color: "red", fontSize: "25px" }}>
          {" "}
          {t("reject service")}{" "}
        </p>
      )}

      <Modal
        centered
        open={visible}
        onCancel={() => {
          setVisible(false)
          cancel_form()
        }}
        footer={false}
      >
        <Form form={form} name="cancel-service" onFinish={onFinish}>
          <Row>
            <Input_Comp
              header={t("Remark cancel service")}
              placeholder={t("Remark cancel service")}
              headState={setCancel_service}
              nameState={Cancel_service}
              keyState="data_cancel"
              type="text"
              maxLength={null}
            />
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <div className="div_create_new_car">
                <Button
                  className="btn_cancel_create_new_car"
                  onClick={() => cancel_form()}
                >
                  {t("Cancel")}
                </Button>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
              <div className="div_create_new_car">
                <Button
                  className="btn_submit_create_new_car"
                  type="primary"
                  htmlType="submit"
                  loading={loading_cancel}
                >
                  {t("Submit")}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  )
}
