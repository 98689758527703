import React from "react"
import { Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next"

export default function Detail_car(props) {
  const { t } = useTranslation()

  const text_header = {
    fontWeight: "bold",
    fontSize: "15px",
  }

  const data_map = [
    {
      header: t("license no"),
      dataCar: props.data_car.car_license_number,
    },
    {
      header: t("car name"),
      dataCar: props.data_car.car_name,
    },
    {
      header: t("province"),
      dataCar: props.data_car.pro_name,
    },
    {
      header: t("color"),
      dataCar: props.data_car.car_color_name,
    },
    {
      header: t("chassis number"),
      dataCar: props.data_car.car_chassis_number,
    },
    {
      header: t("Car type"),
      dataCar: props.data_car.car_body_type_name,
    },
    {
      header: t("Car group"),
      dataCar: props.data_car.car_group_name,
    },
    {
      header: t("brand"),
      dataCar: props.data_car.car_brand_name,
    },
    {
      header: t("model"),
      dataCar: props.data_car.car_model_name,
    },
    {
      header: t("year"),
      dataCar: props.data_car.car_model_year_name,
    },
    {
      header: t("branch code"),
      dataCar: props.data_car.cus_branch_code,
    },
    {
      header: t("branch"),
      dataCar: props.data_car.cus_branch_name,
    },
    {
      header: t("branch contact firstname"),
      dataCar: props.data_car.cus_branch_contact_firstname,
    },
    {
      header: t("branch contact lastname"),
      dataCar: props.data_car.cus_branch_contact_lastname,
    },
    {
      header: t("branch address"),
      dataCar: props.data_car.cus_branch_address,
    },
    {
      header: t("branch phone"),
      dataCar: props.data_car.cus_branch_phone,
    },
    {
      header: t("branch contact email"),
      dataCar: props.data_car.cus_branch_email,
    },
    {
      header: t("branch contact fax"),
      dataCar: props.data_car.cus_branch_fax,
    },
    {
      header: t("car remark"),
      dataCar: props.data_car.car_remark,
    },
  ]

  return (
    <div className="scroll-modal-display-mobile-app">
      <p
        style={{
          textAlign: "center",
          fontWeight: "bold",
          marginTop: "10px",
          fontSize: "15px",
        }}
      >
        {" "}
        {t("car information")}{" "}
      </p>
      <hr />

      <Row>
        {data_map.map((data, index) => {
          return (
            <>
              <Col sm={6} md={3} lg={3} xl={3}>
                <p style={text_header}>{data.header}</p>
              </Col>
              <Col sm={6} md={3} lg={3} xl={3}>
                <p style={{ fontSize: "15px" }}>
                  {" "}
                  <span className="tet">:</span>{" "}
                  <span className="tetTwo">-</span> {data.dataCar}
                </p>
              </Col>
            </>
          )
        })}
      </Row>
    </div>
  )
}
