import React, { useRef, useState } from "react"
import { Row, Col } from "reactstrap"
import { Tag } from "antd"
import SignaturePad from "./SignaturePad"
import "./component/styles.module.sig.css"
import { FcApproval } from "react-icons/fc";
import Render_Image_After_Upload from './component/Render_Image_After_Upload'
import Render_Check_In_Out from './component/Render_Check_In_Out'
import { useTranslation } from "react-i18next";
import PreviewFileUpload from '../../../Task/renderForm_hast_form/component/PreviewFileUpload'

export default function TextForm(props) {
  const { t } = useTranslation();

  const color_detail = {
    color: "#737373",
  }

  const font_size_data = {
    fontSize:'20px'
  }

  function render_priority(priority) {
    switch (priority) {
      case "urgent":
        return (
          <Tag color="error" style={{ marginBottom: "10px" }}>
            {t(priority)}
          </Tag>
        )
        break
      case "high":
        return (
          <Tag color="warning" style={{ marginBottom: "10px" }}>
          {t(priority)}
          </Tag>
        )
        break
      case "medium":
        return (
          <Tag color="processing" style={{ marginBottom: "10px" }}>
          {t(priority)}
          </Tag>
        )
        break
      case "low":
        return (
          <Tag color="success" style={{ marginBottom: "10px" }}>
          {t(priority)}
          </Tag>
        )
        break
      default:
        return null
    }
  }
  
  function check_task_success(task_name , pass_step) {
    switch (pass_step) {
      case true:
        return <p style={{ fontSize:'20px' }}>{task_name}  <FcApproval size={25} /></p>
        break; 
        case false:
          return <p style={{ fontSize:'20px' }}>{task_name} </p>
          break; 
      default:
        return <p style={{ fontSize:'20px' }}>{task_name} </p>
        break;
    }
  }

  function renderForm() {
    return props.fields.map((form, index) => {
      switch (form.task_type_module_name) {
        case "inputField":
          return (
            <Row key={index}>
              <Col sm={12} xs={12} md={6} lg={6}>
              {check_task_success(form.task_name , form.pass_step)}
                {render_priority(form.task_priority_name)}
              </Col>
              <Col sm={12} xs={12} md={6} lg={6}>
                <p style={{ fontSize:'20px' }}>{form.value ? form.value : t("No data in text field")}</p>
                <p style={color_detail}>{form.task_detail}</p>
              </Col>
            </Row>
          )
          break
        case "checkinField":
          return (
            <Row key={index}>
              <Col sm={12} xs={12} md={6} lg={6}>
              {check_task_success(form.task_name , form.pass_step)}
                {render_priority(form.task_priority_name)}
              </Col>
              <Col sm={12} xs={12} md={6} lg={6}>
                <Render_Check_In_Out
                  check_data_submit={props.check_data_submit}
                  Check_service_form={props.Check_service_form}
                  ser_id={props.ser_id}
                  setTask_hast_form={props.setTask_hast_form}
                  task_hast_form={props.task_hast_form}
                  index_step={props.index_step}
                  index_field={index}
                  value={form.value}
                  type="check_in"
                  text_button={t("Check in here")}
                  text_success={t("Check in success")}
                  color="#2FAC0A"
                />
                <p style={color_detail}>{form.task_detail}</p>
              </Col>
            </Row>
          )
          break
        case "checkoutField":
          return (
            <Row key={index}>
              <Col sm={12} xs={12} md={6} lg={6}>
              {check_task_success(form.task_name , form.pass_step)}
                {render_priority(form.task_priority_name)}
              </Col>
              <Col sm={12} xs={12} md={6} lg={6}>
                <Render_Check_In_Out
                  check_data_submit={props.check_data_submit}
                  Check_service_form={props.Check_service_form}
                  ser_id={props.ser_id}
                  setTask_hast_form={props.setTask_hast_form}
                  task_hast_form={props.task_hast_form}
                  index_step={props.index_step}
                  index_field={index}
                  value={form.value}
                  type="check_out"
                  text_button={t("Check out here")}
                  text_success={t("Check out success")}
                  color="#D83A56"
                />
                <p style={color_detail}>{form.task_detail}</p>
              </Col>
            </Row>
          )
          break
        case "signatureField":
          return (
            <Row key={index}>
              <Col sm={12} xs={12} md={6} lg={6}>
              {check_task_success(form.task_name , form.pass_step)}
                {render_priority(form.task_priority_name)}
              </Col>
              <Col sm={12} xs={12} md={6} lg={6}>
              {
                form.value === null && (
                 <p style={font_size_data}> {t("No signature")} </p>
                )
              }

              {
                form.value !== null && (
                  <div
                  style={{
                    marginBottom: "80px",
                    height: "400px",
                    backgroundColor: "#F1F0F0",
                  }}
                >
                  <SignaturePad
                    setTask_hast_form={props.setTask_hast_form}
                    task_hast_form={props.task_hast_form}
                    index_step={props.index_step}
                    index_field={index}
                    value={form.value}
                  />
                </div>
                )
              }
                
                <p style={color_detail}>{form.task_detail}</p>
              </Col>
            </Row>
          )
          break
        case "imageField":
          return (
            <Row key={index}>
              <Col sm={12} xs={12} md={6} lg={6}>
              {check_task_success(form.task_name , form.pass_step)}
                {render_priority(form.task_priority_name)}
              </Col>
              <Col sm={12} xs={12} md={6} lg={6}>
                <div style={{ marginBottom: "10px" }}>
                {
                  form.value === null && (
                   <p style={font_size_data}> {t("No photo")} </p>
                  )
                }

                {
                  form.value !== null && (
                    <Render_Image_After_Upload 
                      value={form.value}
                    />
                  )
                }
                </div>
                <p style={color_detail}>{form.task_detail}</p>
              </Col>
            </Row>
          )
          break
          case "uploadFileField":
            return (
              <Row key={index}>
                <Col sm={12} xs={12} md={6} lg={6}>
                {check_task_success(form.task_name , form.pass_step)}
                  {render_priority(form.task_priority_name)}
                </Col>
                <Col sm={12} xs={12} md={6} lg={6}>
                  <div style={{ marginBottom: "10px" }}>
                  {
                    form.value === null && (
                     <p style={font_size_data}> {t("No file upload")} </p>
                    )
                  }
  
                  {
                    form.value !== null && (
                      <PreviewFileUpload 
                        value={form.value}
                      />
                    )
                  }
                  </div>
                  <p style={color_detail}>{form.task_detail}</p>
                </Col>
              </Row>
            )
            break
        default:
          return null
      }
    })
  }

  function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(",")
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
  }

  return (
    <div>
      {renderForm()}
    </div>
  )
}
