import React from "react";
import { Row, Col } from "reactstrap"
import { Input, Button, Tag } from "antd";
import SignatureCanvas from 'react-signature-canvas'
import UploadImage from './component/UploadImage'
import { useTranslation } from "react-i18next";

import './component/styles.module.sig.css'

export default function TextForm(props) {
  const { t } = useTranslation();

  function render_priority(priority) {
    switch (priority) {
      case "urgent":
        return <Tag color="error" > {t(priority)} </Tag>
        break
      case "high":
        return <Tag color="warning" > {t(priority)} </Tag>
        break
      case "medium":
        return <Tag color="processing" > {t(priority)} </Tag>
        break
      case "low":
        return <Tag color="success" > {t(priority)} </Tag>
        break
      default:
        return null
    }
  }

  function renderForm() {
    return props.fields.map((form, index) => {
      switch (form.task_type_module_name) {
        case "inputField":
          return (
            <Row key={index}>
              <Col sm={6} xs={6} md={6} lg={6}>
                <p>{form.task_name}</p>
                {render_priority(form.task_priority_name)}
              </Col>
              <Col sm={6} xs={6} md={6} lg={6}>
                <Input type={form.type} style={{ marginBottom: "10px" }} />
                <p>{form.task_detail}</p>
              </Col>
            </Row>
          );
          break;
        case "checkinField":
          return (
            <Row key={index}>
              <Col sm={6} xs={6} md={6} lg={6}>
                <p>{form.task_name}</p>
                {render_priority(form.task_priority_name)}
              </Col>
              <Col sm={6} xs={6} md={6} lg={6}>
                <Button
                  style={{
                    background: "#2FAC0A",
                    borderColor: "#2FAC0A",
                    color: "#FFFF",
                    borderRadius: "25px",
                    marginBottom: "10px",
                  }}
                >
                  {t("Check in here")}
                </Button>
                <p>{form.task_detail}</p>
              </Col>
            </Row>
          );
          break;
        case "checkoutField":
          return (
            <Row key={index}>
              <Col sm={6} xs={6} md={6} lg={6}>
                <p>{form.task_name}</p>
                {render_priority(form.task_priority_name)}
              </Col>
              <Col sm={6} xs={6} md={6} lg={6}>
                <Button
                  style={{
                    background: "#D83A56",
                    borderColor: "#D83A56",
                    color: "#FFFF",
                    borderRadius: "25px",
                    marginBottom: "10px",
                  }}
                >
                  {t("Check out here")}
                </Button>
                <p>{form.task_detail}</p>
              </Col>
            </Row>
          );
          break;
          case "uploadFileField":
            return (
              <Row key={index}>
                <Col sm={6} xs={6} md={6} lg={6}>
                  <p>{form.task_name}</p>
                  {render_priority(form.task_priority_name)}
                </Col>
                <Col sm={6} xs={6} md={6} lg={6}>
                  <Button
                  >
                    {t("upload file")}
                  </Button>
                  <p>{form.task_detail}</p>
                </Col>
              </Row>
            );
            break;
        case "signatureField":
          return (
            <Row key={index}>
              <Col sm={6} xs={6} md={6} lg={6}>
                <p>{form.task_name}</p>
                {render_priority(form.task_priority_name)}
              </Col>
              <Col sm={6} xs={6} md={6} lg={6}>
                <div style={{ marginBottom:'20px' }} >
                  <SignatureCanvas
                    clearOnResize={false}
                    canvasProps={{ className: "sigPad", height: 300 }}
                    backgroundColor='#F1F0F0'
                  />
                </div>
                <p>{form.task_detail}</p>
              </Col>
            </Row>
          );
          break;
            case "imageField":
              return (
                <Row key={index}>
                  <Col sm={6} xs={6} md={6} lg={6}>
                    <p>{form.task_name}</p>
                    {render_priority(form.task_priority_name)}
                  </Col>
                  <Col sm={6} xs={6} md={6} lg={6}>
                 <div style={{ marginBottom:'10px' }}>
                 <UploadImage />
                 </div>
                 <p>{form.task_detail}</p>
                  </Col>
       
                </Row>
              );
              break;
        default:
          return null;
      }
    });
  }

  return <>{renderForm()}</>;
}