import React, { useEffect, useState } from "react"
import { Container, Card, CardBody } from "reactstrap"
import { useHistory, useParams } from "react-router-dom"
import MetaTags from "react-meta-tags"
import { MdKeyboardBackspace } from "react-icons/md"
import * as api from "../../service/Api_Edit_Service"
import axios from "axios"
import { Tabs } from "antd"
import Detail_Service from "./Detail_Service"
import Manager from "./Manager"
import Admin from "./Admin"
import Operator from "./Operator"
import Task from "./Task"
import Setting from "./Setting"
import no_permi from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png"
import helpers from "../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const { TabPane } = Tabs

const Edit_Service = props => {
  const history = useHistory()
  const { t } = useTranslation()
  const [data_service, setData_service] = useState(null)
  const [data_location, setData_location] = useState({})
  const token = localStorage.getItem("token")
  const _id = localStorage.getItem("_id")
  const [manager, setManager] = useState([])
  const [admin, setAdmin] = useState([])
  const [operator, setOperator] = useState([])
  const [service_overview, setService_overview] = useState({})
  const [count_task_status, setCount_task_status] = useState({})
  const [manager_check, setManager_check] = useState(0)
  const [admin_check, setAdmin_check] = useState(0)
  const [operator_check, setOperator_check] = useState(0)
  const [activity_all, setActivity_all] = useState([])
  const [task_status_check, setTask_status_check] = useState(0)
  const [service_status_name, setService_status_name] = useState(null)
  const [service_location, setService_location] = useState({
    ser_lat: null,
    ser_long: null,
  })
  const [user_Cancel, setUser_Cancel] = useState(false)
  const [fileUpload, setFileUpload] = useState([])
  const { permissionAllService , emp_user_id } = useSelector(({ Distar }) => Distar)
  let { ser_id } = useParams()
  
  const permi = localStorage.getItem("permi")
  const [permi_t_edit, setPermi_t_edit] = useState(false)
  const [permi_t_delete, setPermi_t_delete] = useState(false)
  const [permi_t_read, setPermi_t_read] = useState(false)

  useEffect(() => {
    if (permissionAllService) {
      if (permissionAllService.service) {
        setPermi_t_read(permissionAllService.service.read)
        setPermi_t_edit(permissionAllService.service.edit)
        setPermi_t_delete(permissionAllService.service.delete)
      }
    }
    return () => {}
  }, [permissionAllService])
  //   console.log("ser_id = " + ser_id)

  useEffect(() => {
    if (props.location) {
      if (props.location.import_admin && props.location.import_admin === true) {
        import_admin_auto()
      }
    }
    return () => {}
  }, [props.location])

  useEffect(() => {
    return () => {
      setFileUpload([])
      setData_service(null)
      setManager([])
      setAdmin([])
      setOperator([])
      setService_overview({})
      setCount_task_status({})
      setManager_check(0)
      setAdmin_check(0)
      setOperator_check(0)
      setActivity_all([])
      setTask_status_check(0)
      setService_status_name(null)
      setData_location({})
      setService_location({
        ser_lat: null,
        ser_long: null,
      })
    }
  }, [])

  useEffect(() => {
    check_new_data_edit()
    list_service_overview()
    check_activity_log()
    check_new_data_edit_location()
    return () => {
      setData_service(null)
    }
  }, [ser_id])

  function import_admin_auto() {
    const data = {
      ser_id: parseInt(ser_id),
      emp_id: emp_user_id,
    }
    const import_admin = api.INSERT_ADMIN(token, data)
    axios(import_admin)
      .then(res => {
        // console.log(res.data);
      })
      .catch(error => {
        // console.log(error.response);
      })
  }

  function check_new_data_edit_location() {
    const new_data = api.GET_DETAIL_SERVICE(token, parseInt(ser_id))
    axios(new_data)
      .then(res => {
        if (res.data.data[0] === undefined) {
          history.push("/service")
        } else {
          setFileUpload(res.data.data[0].fileupload)
          setData_location(res.data.data[0])
          setService_location({
            ...service_location,
            ser_lat: res.data.data[0].ser_lat,
            ser_long: res.data.data[0].ser_long,
          })
        }
      })
      .catch(error => {
        // console.log(error)
        // console.log(error.response)
      })
  }

  function check_new_data_edit() {
    const new_data = api.GET_DETAIL_SERVICE(token, parseInt(ser_id))
    axios(new_data)
      .then(res => {
        setData_service(res.data.data[0])
        setService_status_name(res.data.data[0].ser_status_name)
        if (res.data.data[0].is_user_cancel === 1) {
          setUser_Cancel(true)
        }
      })
      .catch(error => {
        // console.log(error.response)
      })
  }

  function list_service_overview() {
    const service_overview = api.SERVICE_OVERVIEW(token, parseInt(ser_id))
    axios(service_overview).then(res => {
      setService_overview(res.data)
      setCount_task_status(res.data.service.count_task_status)
      setAdmin_check(res.data.admin.length)
      setManager_check(res.data.manager.length)
      setOperator_check(res.data.operator.length)
      setTask_status_check(
        res.data.service.count_task_status.assigned +
          res.data.service.count_task_status.cancle +
          res.data.service.count_task_status.complete +
          res.data.service.count_task_status.inprogress +
          res.data.service.count_task_status.pending +
          res.data.service.count_task_status.resolved
      )
    })
  }

  useEffect(() => {
    let id = setInterval(() => {
      check_activity_log()
      check_new_data_edit()
    }, 10000)
    return () => {
      clearInterval(id)
    }
  })

  function check_activity_log() {
    const check_activity = api.LIST_ACTIVITY_LOG(token, parseInt(ser_id))
    axios(check_activity)
      .then(res => {
        setActivity_all(res.data.data)
      })
      .catch(error => {
        // console.log(error.response)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }
  //   console.log(data_service);
  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Edit service")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/service")}
              />
            </a>{" "}
            {t("Edit service")}
          </h3>

          {permi_t_read === true &&
            data_service !== null &&
            data_service !== undefined && (
              <Card>
                <CardBody>
                  <Tabs defaultActiveKey="1">
                    <TabPane tab={t("Overview")} key="1">
                      <Detail_Service
                        fileUpload={fileUpload}
                        data_location={data_location}
                        ser_id={parseInt(ser_id)}
                        count_task_status={count_task_status}
                        data_service={data_service}
                        service_overview={service_overview}
                        task_status_check={task_status_check}
                        manager_check={manager_check}
                        admin_check={admin_check}
                        operator_check={operator_check}
                        check_new_data_edit={check_new_data_edit}
                        list_service_overview={list_service_overview}
                        check_activity_log={check_activity_log}
                        activity_all={activity_all}
                        service_status_name={service_status_name}
                        service_location={service_location}
                      />
                    </TabPane>
                    {/*
                  service_status_name !== "cancel" &&
                    service_status_name !== "complete" &&
                    service_status_name !== "reject" &&
                    permi_t_edit === true &&
                    user_Cancel === false && (
                      <TabPane tab={t("Manager")} key="2">
                        <Manager
                          ser_id={parseInt(ser_id)}
                          setManager={setManager}
                          manager={manager}
                          list_service_overview={list_service_overview}
                          check_activity_log={check_activity_log}
                        />
                      </TabPane>
                    )
                    */}

                    {service_status_name !== "cancel" &&
                      service_status_name !== "complete" &&
                      service_status_name !== "reject" &&
                      permi_t_edit === true &&
                      user_Cancel === false && (
                        <TabPane tab={t("Admin")} key="3">
                          <Admin
                            setAdmin={setAdmin}
                            admin={admin}
                            ser_id={parseInt(ser_id)}
                            list_service_overview={list_service_overview}
                            check_activity_log={check_activity_log}
                          />
                        </TabPane>
                      )}

                    {service_status_name !== "cancel" &&
                      service_status_name !== "complete" &&
                      service_status_name !== "reject" &&
                      permi_t_edit === true &&
                      user_Cancel === false && (
                        <TabPane tab={t("Operator")} key="4">
                          <Operator
                            setOperator={setOperator}
                            operator={operator}
                            ser_id={parseInt(ser_id)}
                            list_service_overview={list_service_overview}
                            check_activity_log={check_activity_log}
                          />
                        </TabPane>
                      )}

                    {service_status_name !== "cancel" &&
                      service_status_name !== "complete" &&
                      service_status_name !== "reject" &&
                      permi_t_edit === true &&
                      user_Cancel === false && (
                        <TabPane tab={t("Task")} key="5">
                          <Task
                            ser_id={parseInt(ser_id)}
                            list_service_overview={list_service_overview}
                          />
                        </TabPane>
                      )}

                    {permi_t_delete === true && (
                      <TabPane tab={t("Setting")} key="6">
                        <Setting ser_id={parseInt(ser_id)} />
                      </TabPane>
                    )}
                  </Tabs>
                </CardBody>
              </Card>
            )}
          {permi_t_read === false && (
            <div>
              <img
                src={no_permi}
                alt="no permission"
                style={{ display: "block", margin: "50px auto" }}
              />
              <p style={{ textAlign: "center", fontSize: "20px" }}>
                {t("no permission read module")}
              </p>
              <p style={{ textAlign: "center", fontSize: "20px" }}>
                {" "}
                {t("please contract administrator")}{" "}
              </p>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Edit_Service

// Insert service -> manager          ใช้ GETEmployeeList ไปก่อน -> 127.0.0.1:5002/api/v1/employee?page=1&limit=12&order_by=asc&q=pa
// POSTInsert service -> admin        ใช้ GETEmployeeList ไปก่อน -> 127.0.0.1:5002/api/v1/employee?page=1&limit=12&order_by=asc&q=pa
// POSTInsert service -> Opeartor     GETFindAllOperator
