import React from "react"
import { Table, Input, Pagination } from "antd"
import { useTranslation } from "react-i18next"
import { FcDataSheet } from "react-icons/fc"

export default function Table_Loader_All(props) {
  const { t } = useTranslation()

  function checkData(data) {
    if (data) {
      return data
    } else {
      return "N/A"
    }
  }

  const columns = [
    {
      title: t("Customer code"),
      dataIndex: "cus_code",
      key: "cus_code",
      render: text => <p>{checkData(text)}</p>,
    },
    {
      title: t("Customer name"),
      dataIndex: "cus_name",
      key: "cus_name",
      render: text => <p>{checkData(text)}</p>,
    },
    {
      title: t("Customer dealer"),
      dataIndex: "dea_name",
      key: "dea_name",
      render: text => <p>{checkData(text)}</p>,
    },
  ]

  return (
    <>
      <Table 
      rowKey="cus_id" 
      columns={columns} 
      dataSource={props.data_list} 
      pagination={false}
      scroll={{ x: 800 }}
      />

      <div>
        <Pagination
          current={props.page_limit.page}
          total={props.page_limit.limit}
          onChange={props.changePagination}
          style={{ marginTop: "20px", float: "right" }}
        />
      </div>
      <p style={{ fontSize: "15px", marginTop: "20px" }}>
        {" "}
        <FcDataSheet size={20} style={{ marginBottom: "5px" }} />{" "}
        {t("total data")}: {props.page_limit.limit}
      </p>
    </>
  )
}
