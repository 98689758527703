import React, { useState, useEffect } from "react"
import { Container, Card, CardBody } from "reactstrap"
import MetaTags from 'react-meta-tags';
import { useHistory, useParams } from "react-router-dom"
import { MdKeyboardBackspace } from 'react-icons/md';
import Table_Data from './Table_Data'
import { useTranslation } from "react-i18next";

const Sub_Product = props => {
    const history = useHistory()
    const { t } = useTranslation();
    const token = localStorage.getItem("token")
  
    let { car_id, product_id , profile_id } = useParams()

  return (
    <React.Fragment>
    <MetaTags>
      <title> {t("Sub product")} | Distar</title>
    </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}> <a><MdKeyboardBackspace style={{ marginRight:'15px', marginBottom:'3px' }}
          onClick={() =>
            history.push({
              pathname: `/customers/${profile_id}/edit-car/${car_id}`,
              // data: props.location.data.cus_id,
              page_local: "2",
            })
          } 
          /></a> {t("Sub product")} </h3>

          <Card>
            <CardBody>
              <Table_Data 
              product_id={parseInt(product_id)}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Sub_Product
