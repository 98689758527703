var Ip = require('../service/Ip')
  
 // Role admin
export const CHECK_REPORT = (type ,date_from , date_to) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}apireport/${type}?start_time=${date_from}&end_time=${date_to}`
    };
  };

  export const DOWNLOAD_REPORT = (type , date_from , date_to) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}apireport/${type}/excel?start_time=${date_from}&end_time=${date_to}`,
      responseType: 'blob',
    };
  };

  export const CHECK_REPORT_OPERATOR = (date_from , date_to , ope_id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}apireport/operator?start_time=${date_from}&end_time=${date_to}&ope_id=${ope_id}`
    };
  };

  export const NEW_CHECK_REPORT_OPERATOR = (date_from , date_to , ope_id , token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}apireport/operator?start_time=${date_from}&end_time=${date_to}&ope_id=${ope_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const DOWNLOAD_REPORT_OPERATOR = (date_from , date_to , ope_id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}apireport/operator/excel?start_time=${date_from}&end_time=${date_to}&ope_id=${ope_id}`,
      responseType: 'blob',
    };
  };

  export const NEW_DOWNLOAD_REPORT_OPERATOR = (date_from , date_to , ope_id , token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}apireport/operator/excel?start_time=${date_from}&end_time=${date_to}&ope_id=${ope_id}`,
      responseType: 'blob',
      headers: {
        'x-access-token' : token
      }
    };
  };
  
 // Role customer
  export const CHECK_REPORT_CUSTOMER = (type, cus_id ,date_from , date_to) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}apireport/${type}/customer?cus_id=${cus_id}&start_time=${date_from}&end_time=${date_to}`
    };
  };

  export const DOWNLOAD_REPORT_CUSTOMER = (type, cus_id ,date_from , date_to) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}apireport/${type}/customer/excel?cus_id=${cus_id}&start_time=${date_from}&end_time=${date_to}`,
      responseType: 'blob'
    };
  };

  export const DOWNLOAD_REPORT_REPAIR_CUSTOMER = (cus_id ,date_from , date_to) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}apireport/customer/excel?cus_id=${cus_id}&start_time=${date_from}&end_time=${date_to}`,
      responseType: 'blob'
    };
  };
  
 // Role dealer
  export const CHECK_REPORT_DEALER = (type, dea_id ,date_from , date_to) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}apireport/${type}/dealer?dea_id=${dea_id}&start_time=${date_from}&end_time=${date_to}`
    };
  };

  export const DOWNLOAD_REPORT_DEALER = (type, dea_id ,date_from , date_to) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}apireport/${type}/dealer/excel?dea_id=${dea_id}&start_time=${date_from}&end_time=${date_to}`,
      responseType: 'blob'
    };
  };

  export const DOWNLOAD_REPORT_REPAIR_DEALER = (dea_id ,date_from , date_to) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}apireport/dealer/excel?dea_id=${dea_id}&start_time=${date_from}&end_time=${date_to}`,
      responseType: 'blob'
    };
  };
  
 // Role operator
  export const CHECK_REPORT_OPERATOR_FOR_OPERATOR = (type, ope_id ,date_from , date_to) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}apireport/${type}/operator?ope_id=${ope_id}&start_time=${date_from}&end_time=${date_to}`
    };
  };

  export const NEW_CHECK_REPORT = (token, url) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}${url}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const NEW_DOWNLOAD_REPORT_EXCEL = (token , url ) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}${url}`,
      responseType: 'blob',
      headers: {
        // 'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };