import React, { useState } from "react"
import { Card, Button, Table, Tag } from "antd"
import { Row, Col } from "reactstrap"
import Expand from "react-expand-animated"
import { useTranslation } from "react-i18next"
import Colum from "../../System_Settings/Components/Colum"
import Col_Resolution_SLA from "../../System_Settings/Components/Col_Resolution_SLA"
import Col_Day_Hour_Minute from "../../System_Settings/SLA_Components/Col_Day_Hour_Minute"
import sla from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/sla.png"

export default function Detail_SLA_Data({ data_service }) {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const icons_info_header = {
    width: "25px",
    marginRight: "20px",
  }
  return (
    <div>
      {data_service.sla_service_name !== null && (
        <div>
          <p className="text-bold"> {t("SLA information")} </p>
          <Card>
            {open === false && (
              <Button
                type="link"
                onClick={() => setOpen(!open)}
                style={{ float: "right" }}
              >
                {t("More")}
              </Button>
            )}
            {open === true && (
              <Button
                type="link"
                onClick={() => setOpen(!open)}
                style={{ float: "right" }}
              >
                {t("Close")}
              </Button>
            )}
            <p>
              <img src={sla} alt="image sla" style={icons_info_header} />
              {t("SLA name")}
              <span style={{ fontWeight: "bold", marginLeft: "20px" }}>
                {data_service.sla_service_name}
              </span>
            </p>
            <Expand open={open} duration={500}>
              <br />
              <Row>
                <Colum
                  header_text={t("SLA name")}
                  value_text={data_service.sla_service_name}
                />
                <Colum
                  header_text={t("Priority")}
                  value_text={t(data_service.pri_name)}
                />
                <Col_Resolution_SLA
                  header_text={t("SLA resolution")}
                  value_text={t(data_service.sla_resolution)}
                />
                <Colum
                  header_text={t("province")}
                  value_text={data_service.pro_name}
                />

                <Colum
                  header_text={t("remark")}
                  value_text={data_service.sla_service_remark}
                />

                <Col_Day_Hour_Minute
                  day={data_service.sla_service_day}
                  hour={data_service.sla_service_hr}
                  minute={data_service.sla_service_min}
                />
              </Row>
            </Expand>
          </Card>
        </div>
      )}
    </div>
  )
}
