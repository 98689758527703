var Ip = require('../service/Ip')

export const FIND_ALL_ADMIN_GROUP = (token) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/admin/group`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const FIND_ONE_ADMIN = (token , admin_id) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/admin/${admin_id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const CREATE_NEW_ADMIN_GROUP = (token , data) => {
  return {
    method: 'post',
    url: `${Ip.getIP()}api/v1/admin/group`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    },
    data : data
  };
};

export const DELETE_ADMIN_GROUP = (token , admin_group_id) => {
  return {
    method: 'delete',
    url: `${Ip.getIP()}api/v1/admin/group/${admin_group_id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const EDIT_ADMIN_GROUP = (token , admin_group_id , data) => {
  return {
    method: 'put',
    url: `${Ip.getIP()}api/v1/admin/group/${admin_group_id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    },
    data : data
  };
};

export const LIST_ALL_ADMIN = (token) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/admin`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const GET_IMAGE = (token, image) => {
  return {
    method: 'get',
    responseType: 'arraybuffer',
    url: `${Ip.getIP()}storage/file/${image}`,
    headers: {
      'x-access-token' : token
    }
  };
};

export const UPLOAD_PROFILE = (token, formData) => {
  return {
    method: 'post',
    url: `${Ip.getIP()}storage/upload`,
    headers: {
      "Content-type": "multipart/form-data",
      'x-access-token' : token
    },
    data : formData
  };
};

export const EDIT_ADMIN = (token ,admin_id , data ) => {
  return {
    method: 'put',
    url: `${Ip.getIP()}api/v1/admin/${admin_id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    },
    data : data
  };
};

export const DELETE_ADMIN = (token ,admin_id ) => {
  return {
    method: 'delete',
    url: `${Ip.getIP()}api/v1/admin/${admin_id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_ADMIN_ALL_IN_GROUP = (token , admin_group_id) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/admin/group/get-admin/${admin_group_id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_ALL_STATUS_LIST_SLA = (token) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}dashboard/sla/statuslist`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};