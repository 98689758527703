var Ip = require('../service/Ip')

export const FIND_ALL_PRODUCT_GROUP = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/stock/product-group`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const FIND_MODEL_IN_PRODUCT_GROUP = (token, product_id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/stock/find-model-in-group/${product_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const UPDATE_PRODUCT = (token, pro_id ,data) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/stock/product/${pro_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const UPDATE_STOCK = (token, sto_id ,data) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/stock/${sto_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const CHECK_DETAIL_STOCK = (token, sto_id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/stock/${sto_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };