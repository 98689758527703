import React,{ useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { Tooltip, Tag, Table, Card, Collapse } from "antd"
import Render_Image from "../../New_Customer/Devices/Render_Image"
import Render_Image_Data from "../../Customers/Table/Render_Image_Data"
import Render_Customer_Avatar from "./Render_Customer_Avatar"
import { useTranslation } from "react-i18next";

import car from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/car.png"
import ambulance from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/ambulance.png"
import vespa from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/vespa.png"
import bus from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/bus.png"
import bicycle from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/bicycle.png"
import van from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/van.png"
import truck from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/truck.png"
import taxi from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/taxi.png"
import pickup from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/pickup.png"
import motorboat from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/motorboat.png"
import motor from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/motor.png"
import no_car from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_car.png"

import dealer_info from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/dealer_info.png"
import car_info from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/car_info.png"
import product_info from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/product_info.png"
import sub_product_info from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/sub_product_info.png"
import customer_info from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/customer_info.png"
import contract_info from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/contract_info.png"

const { Panel } = Collapse

export default function Detail_Stock_modal(props) {
  const { t } = useTranslation();
  const [data_sub_product_info, setData_sub_product_info] = useState([])
  const [data_sub_product, setData_sub_product] = useState([])
  
  useEffect(() => {
    if(props.sim){
      if(props.sim.pro_code){
        setData_sub_product_info([
          {
            header1: t("Sim code"),
            dataContract1: checkData(props.sim.pro_code),
            header2: t("Sim number"),
            dataContract2: checkData(props.sim.pro_no),
          }
        ])

        setData_sub_product([
          {
            header1: t("Sim name"),
            dataContract1: checkData(props.sim.pro_model_name),
            header2: t("Sim model code"),
            dataContract2: checkData(props.sim.pro_model_code),
          },
          {
            header1: t("Sim group name"),
            dataContract1: checkData(props.sim.pro_group_name),
            header2: t("Sim serial number"),
            dataContract2: checkData(props.sim.pro_serial_number),
          },
          {
            header1: t("Sim model factory"),
            dataContract1: checkData(props.sim.pro_model_factory),
            header2: t("Sim model protocal"),
            dataContract2: checkData(props.sim.pro_model_protocol),
          },
          {
            header1: t("Sim model remark"),
            dataContract1: checkData(props.sim.pro_model_remark),
            header2: t("Sim remark"),
            dataContract2: checkData(props.sim.pro_remark),
          }
        ])
      }
    }
    return () => {
      
    }
  }, [props.sim])

  const text_header = {
    fontWeight: "bold",
    fontSize: "15px",
  }

  const icons_info_header = { 
    width:'25px', 
    marginRight:'20px' 
  }

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return "N/A"
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  function checkData(data) {
    if (data) {
      return data
    } else {
      return "N/A"
    }
  }

  const data_product = [
    {
      header1: t("Stock asset number"),
      dataContract1: checkData(props.data_stock.sto_asset_number),
      header2: t("Stock date in"),
      dataContract2: cutTimeZone(props.data_stock.sto_date_in),
    },
    {
      header1: t("Stock date install"),
      dataContract1: cutTimeZone(props.data_stock.sto_date_install),
      header2: t("Stock remark"),
      dataContract2: checkData(props.data_stock.sto_remark),
    },
    {
      header1: t("Product code"),
      dataContract1: checkData(props.data_stock.pro_code),
      header2: t("Product model code"),
      dataContract2: checkData(props.data_stock.pro_model_code),
    },
    {
      header1: t("Product remark"),
      dataContract1: checkData(props.data_stock.pro_remark),
      header2: t("Product model factory"),
      dataContract2: checkData(props.data_stock.pro_model_factory),
    },
  ]

  const data_car_info = [
    {
      header1: t("Car license number"),
      dataContract1: checkData(props.data_stock.car_license_number),
      header2: t("Car name"),
      dataContract2: checkData(props.data_stock.car_name),
    },
    {
      header1: t("Car code"),
      dataContract1: checkData(props.data_stock.car_code),
      header2: t("Car type"),
      dataContract2: render_Icon_Car(props.data_stock.car_body_type_name),
    },
  ]

  const data_car = [
    {
      header1: t("Car color name"),
      dataContract1: checkData(props.data_stock.car_color_name),
      header2: t("Car chassis number"),
      dataContract2: checkData(props.data_stock.car_chassis_number),
    },
    {
      header1: t("Car model name"),
      dataContract1: checkData(props.data_stock.car_model_name),
      header2: t("Car model year name"),
      dataContract2: checkData(props.data_stock.car_model_year_name),
    },
    {
      header1: t("Car brand name"),
      dataContract1: checkData(props.data_stock.car_brand_name),
      header2: t("Car fuel hours"),
      dataContract2: checkData(props.data_stock.car_fuelhours),
    },
    {
      header1: t("Car fuel tank"),
      dataContract1: checkData(props.data_stock.car_fueltank),
      header2: t("Car fuel km"),
      dataContract2: checkData(props.data_stock.car_fuelkm),
    },
  ]

  const data_dealer_info = [
    {
      header1: t("Dealer name"),
      dataContract1: checkData(props.data_stock.dea_name),
      header2: t("Dealer code"),
      dataContract2: checkData(props.data_stock.dea_code),
    },
    {
      header1: t("Dealer TAX ID"),
      dataContract1: checkData(props.data_stock.dea_taxid),
      header2: t("Dealer remark"),
      dataContract2: checkData(props.data_stock.dea_remark),
    },
  ]

  const data_dealer = [
    {
      header1: t("Dealer fax"),
      dataContract1: checkData(props.data_stock.dea_fax),
      header2: t("Dealer phone"),
      dataContract2: checkData(props.data_stock.dea_phone),
    },

    {
      header1: t("Dealer email"),
      dataContract1: checkData(props.data_stock.dea_email),
      header2: t("Dealer address"),
      dataContract2: checkData(props.data_stock.dea_address),
    },

    {
      header1: t("Dealer contact firstname"),
      dataContract1: checkData(props.data_stock.dea_contact_firstname),
      header2: t("Dealer contact lastname"),
      dataContract2: checkData(props.data_stock.dea_contact_lastname),
    },
    {
      header1: t("Dealer contact mobile"),
      dataContract1: checkData(props.data_stock.dea_contact_mobile),
      header2: t("Dealer contact email"),
      dataContract2: checkData(props.data_stock.dea_contact_email),
    },
  ]

  function render_Icon_Car(type_car) {
    switch (type_car) {
      case "car":
        return (
          <Tooltip title="car">
            <img src={car} alt="icon" style={{ width: "50px" }} />
          </Tooltip>
        )
        break
      case "vespa":
        return (
          <Tooltip title="vespa">
            <img src={vespa} alt="icon" style={{ width: "50px" }} />
          </Tooltip>
        )
        break
      case "van":
        return (
          <Tooltip title="van">
            <img src={van} alt="icon" style={{ width: "50px" }} />
          </Tooltip>
        )
        break
      case "truck":
        return (
          <Tooltip title="truck">
            <img src={truck} alt="icon" style={{ width: "50px" }} />
          </Tooltip>
        )
        break
      case "taxi":
        return (
          <Tooltip title="taxi">
            <img src={taxi} alt="icon" style={{ width: "50px" }} />
          </Tooltip>
        )
        break
      case "bus":
        return (
          <Tooltip title="bus">
            <img src={bus} alt="icon" style={{ width: "50px" }} />
          </Tooltip>
        )
        break
      case "pickup":
        return (
          <Tooltip title="pickup">
            <img src={pickup} alt="icon" style={{ width: "50px" }} />
          </Tooltip>
        )
        break
      case "motorboat":
        return (
          <Tooltip title="motorboat">
            <img src={motorboat} alt="icon" style={{ width: "50px" }} />
          </Tooltip>
        )
        break
      case "motor":
        return (
          <Tooltip title="motor">
            <img src={motor} alt="icon" style={{ width: "50px" }} />
          </Tooltip>
        )
        break
      case "bicycle":
        return (
          <Tooltip title="bicycle">
            <img src={bicycle} alt="icon" style={{ width: "50px" }} />
          </Tooltip>
        )
        break
      case "ambulance":
        return (
          <Tooltip title="ambulance">
            <img src={ambulance} alt="icon" style={{ width: "50px" }} />
          </Tooltip>
        )
        break
      default:
        return (
          <Tooltip title="Invalid car type">
            <img src={no_car} alt="icon" style={{ width: "50px" }} />
          </Tooltip>
        )
        break
    }
  }

  function render_status(status) {
    if (status === 1) {
      return (
        <Tag color="green" style={{ float: "right" }}>
          Active
        </Tag>
      )
    } else {
      return <Tag style={{ float: "right" }}>Disable</Tag>
    }
  }

  const columns = [
    {
      title: t("Contract name"),
      dataIndex: "con_name",
      key: "con_name",
      render: text => checkData(text),
    },
    {
      title: t("Contract number"),
      dataIndex: "con_no",
      key: "con_no",
      render: text => checkData(text),
    },
    {
      title: t("Contract start date"),
      dataIndex: "con_start_date",
      key: "con_start_date",
      render: text => cutTimeZone(text),
    },
    {
      title: t("Contract end date"),
      dataIndex: "con_end_date",
      key: "con_end_date",
      render: text => cutTimeZone(text),
    },
    {
      title: t("Contract status"),
      dataIndex: "contract_expired",
      key: "contract_expired",
      render: text => render_status_contract(text),
    },
  ]

  const customer_data = [
    {
      header1: t("Customer code"),
      dataContract1: checkData(props.data_stock.cus_code),
      header2: t("Customer credit"),
      dataContract2: checkData(props.data_stock.cus_credit),
    },
    {
      header1: t("Customer TAX ID"),
      dataContract1: checkData(props.data_stock.cus_taxid),
      header2: t("Customer phone"),
      dataContract2: checkData(props.data_stock.cus_phone),
    },
    {
      header1: t("Customer email"),
      dataContract1: checkData(props.data_stock.cus_email),
      header2: t("Customer fax"),
      dataContract2: checkData(props.data_stock.cus_fax),
    },
    {
      header1: t("Customer address"),
      dataContract1: checkData(props.data_stock.cus_address),
      header2: t("Customer remark"),
      dataContract2: checkData(props.data_stock.cus_remark),
    },
  ]

  function render_status_contract(status) {
    switch (status) {
      case false:
        return <Tag color="green"> {t("Active")} </Tag>
        break
      case true:
        return <Tag color="error"> {t("Expire")} </Tag>
        break
      default:
        break
    }
  }

  return (
    <div>
      <p
        style={{
          textAlign: "center",
          fontWeight: "bold",
          marginTop: "10px",
          fontSize: "25px",
        }}
      >
        {" "}
        {props.data_stock.sto_asset_number}{" "}
      </p>
      <hr />
      <Card title={ <p> <img src={product_info} alt="product_info" style={icons_info_header} /> {t("Product information")} </p>}>
        <Render_Image path_image={props.data_stock.pro_model_img} size={100} />
        <br />
        <Row>
          <Col sm={6} md={3} lg={3} xl={3}>
            <p style={text_header}> {t("Product number")} </p>
          </Col>
          <Col sm={6} md={3} lg={3} xl={3}>
            <p style={{ fontSize: "15px" }}>
              {" "}
              <span className="tet">
                :
              </span> <span className="tetTwo">-</span>{" "}
              {props.data_stock.pro_no}
            </p>
          </Col>
          <Col sm={6} md={3} lg={3} xl={3}>
            <p style={text_header}> {t("Product group name")} </p>
          </Col>
          <Col sm={6} md={3} lg={3} xl={3}>
            <p style={{ fontSize: "15px" }}>
              {" "}
              <span className="tet">
                :
              </span> <span className="tetTwo">-</span>{" "}
              {props.data_stock.pro_group_name}
            </p>
          </Col>
          <Col sm={6} md={3} lg={3} xl={3}>
            <p style={text_header}> {t("Product model name")} </p>
          </Col>
          <Col sm={6} md={3} lg={3} xl={3}>
            <p style={{ fontSize: "15px" }}>
              {" "}
              <span className="tet">
                :
              </span> <span className="tetTwo">-</span>{" "}
              {props.data_stock.pro_model_name}
            </p>
          </Col>
          <Col sm={6} md={3} lg={3} xl={3}>
            <p style={text_header}> {t("Product serial number")} </p>
          </Col>
          <Col sm={6} md={3} lg={3} xl={3}>
            <p style={{ fontSize: "15px" }}>
              {" "}
              <span className="tet">
                :
              </span> <span className="tetTwo">-</span>{" "}
              {props.data_stock.pro_serial_number}
            </p>
          </Col>
        </Row>
        <Collapse>
          <Panel header={t("More")} key="1">
            <br />
            {data_product.map((data, index) => {
              return (
                <Row key={index}>
                  <Col sm={6} md={3} lg={3} xl={3}>
                    <p style={text_header}>{data.header1}</p>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={3}>
                    <p style={{ fontSize: "15px" }}>
                      {" "}
                      <span className="tet">:</span>{" "}
                      <span className="tetTwo">-</span> {data.dataContract1}
                    </p>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={3}>
                    <p style={text_header}>{data.header2}</p>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={3}>
                    <p style={{ fontSize: "15px" }}>
                      {" "}
                      <span className="tet">:</span>{" "}
                      <span className="tetTwo">-</span> {data.dataContract2}
                    </p>
                  </Col>
                </Row>
              )
            })}
          </Panel>
        </Collapse>
      </Card>
      <br />

      <Card title={ <p> <img src={car_info} alt="car_info" style={icons_info_header} /> {t("Car information")} </p>}>
        {data_car_info.map((data, index) => {
          return (
            <Row key={index}>
              <Col sm={6} md={3} lg={3} xl={3}>
                <p style={text_header}>{data.header1}</p>
              </Col>
              <Col sm={6} md={3} lg={3} xl={3}>
                <p style={{ fontSize: "15px" }}>
                  {" "}
                  <span className="tet">:</span>{" "}
                  <span className="tetTwo">-</span> {data.dataContract1}
                </p>
              </Col>
              <Col sm={6} md={3} lg={3} xl={3}>
                <p style={text_header}>{data.header2}</p>
              </Col>
              <Col sm={6} md={3} lg={3} xl={3}>
                <p style={{ fontSize: "15px" }}>
                  {" "}
                  <span className="tet">:</span>{" "}
                  <span className="tetTwo">-</span> {data.dataContract2}
                </p>
              </Col>
            </Row>
          )
        })}
        <Collapse>
          <Panel header={t("More")} key="1">
            {data_car.map((data, index) => {
              return (
                <Row key={index}>
                  <Col sm={6} md={3} lg={3} xl={3}>
                    <p style={text_header}>{data.header1}</p>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={3}>
                    <p style={{ fontSize: "15px" }}>
                      {" "}
                      <span className="tet">:</span>{" "}
                      <span className="tetTwo">-</span> {data.dataContract1}
                    </p>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={3}>
                    <p style={text_header}>{data.header2}</p>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={3}>
                    <p style={{ fontSize: "15px" }}>
                      {" "}
                      <span className="tet">:</span>{" "}
                      <span className="tetTwo">-</span> {data.dataContract2}
                    </p>
                  </Col>
                </Row>
              )
            })}
          </Panel>
        </Collapse>
      </Card>
      <br />

      <Card title={ <p> <img src={dealer_info} alt="dealer_info" style={icons_info_header} /> {t("Dealer information")} </p>}>
        {data_dealer_info.map((data, index) => {
          return (
            <Row key={index}>
              <Col sm={6} md={3} lg={3} xl={3}>
                <p style={text_header}>{data.header1}</p>
              </Col>
              <Col sm={6} md={3} lg={3} xl={3}>
                <p style={{ fontSize: "15px" }}>
                  {" "}
                  <span className="tet">:</span>{" "}
                  <span className="tetTwo">-</span> {data.dataContract1}
                </p>
              </Col>
              <Col sm={6} md={3} lg={3} xl={3}>
                <p style={text_header}>{data.header2}</p>
              </Col>
              <Col sm={6} md={3} lg={3} xl={3}>
                <p style={{ fontSize: "15px" }}>
                  {" "}
                  <span className="tet">:</span>{" "}
                  <span className="tetTwo">-</span> {data.dataContract2}
                </p>
              </Col>
            </Row>
          )
        })}
        <Collapse>
          <Panel header={t("More")} key="1">
            {data_dealer.map((data, index) => {
              return (
                <Row key={index}>
                  <Col sm={6} md={3} lg={3} xl={3}>
                    <p style={text_header}>{data.header1}</p>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={3}>
                    <p style={{ fontSize: "15px" }}>
                      {" "}
                      <span className="tet">:</span>{" "}
                      <span className="tetTwo">-</span> {data.dataContract1}
                    </p>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={3}>
                    <p style={text_header}>{data.header2}</p>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={3}>
                    <p style={{ fontSize: "15px" }}>
                      {" "}
                      <span className="tet">:</span>{" "}
                      <span className="tetTwo">-</span> {data.dataContract2}
                    </p>
                  </Col>
                </Row>
              )
            })}
          </Panel>
        </Collapse>
      </Card>

      <br />
      <Card title={ <p> <img src={contract_info} alt="contract_info" style={icons_info_header} /> {t("Contract information")} </p>}>
        <Table
          rowKey="con_id"
          columns={columns}
          dataSource={props.contract_table}
          scroll={{ x: 800 }}
        />
      </Card>
      <br />

      <Card title={ <p> <img src={customer_info} alt="customer_info" style={icons_info_header} /> {t("Customer information")} </p>}>
        <Render_Customer_Avatar
          path_image={props.data_stock.cus_avatar}
          size={80}
        />
        <br />
        <Row>
          <Col sm={6} md={3} lg={3} xl={3}>
            <p style={text_header}> {t("Customer name")} </p>
          </Col>
          <Col sm={6} md={3} lg={3} xl={3}>
            <p style={{ fontSize: "15px" }}>
              {" "}
              <span className="tet">
                :
              </span> <span className="tetTwo">-</span>{" "}
              {props.data_stock.cus_name}
            </p>
          </Col>
        </Row>
        <Collapse>
          <Panel header={t("More")} key="1">
            {customer_data.map((data, index) => {
              return (
                <Row key={index}>
                  <Col sm={6} md={3} lg={3} xl={3}>
                    <p style={text_header}>{data.header1}</p>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={3}>
                    <p style={{ fontSize: "15px" }}>
                      {" "}
                      <span className="tet">:</span>{" "}
                      <span className="tetTwo">-</span> {data.dataContract1}
                    </p>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={3}>
                    <p style={text_header}>{data.header2}</p>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={3}>
                    <p style={{ fontSize: "15px" }}>
                      {" "}
                      <span className="tet">:</span>{" "}
                      <span className="tetTwo">-</span> {data.dataContract2}
                    </p>
                  </Col>
                </Row>
              )
            })}
          </Panel>
        </Collapse>
      </Card>
      <br />

      {
        data_sub_product_info.length !== 0 && (
          <Card title={ <p> <img src={sub_product_info} alt="sub_product_info" style={icons_info_header} /> {t("Sub product information")} </p>}>
          {data_sub_product_info.map((data, index) => {
            return (
              <Row key={index}>
                <Col sm={6} md={3} lg={3} xl={3}>
                  <p style={text_header}>{data.header1}</p>
                </Col>
                <Col sm={6} md={3} lg={3} xl={3}>
                  <p style={{ fontSize: "15px" }}>
                    {" "}
                    <span className="tet">:</span>{" "}
                    <span className="tetTwo">-</span> {data.dataContract1}
                  </p>
                </Col>
                <Col sm={6} md={3} lg={3} xl={3}>
                  <p style={text_header}>{data.header2}</p>
                </Col>
                <Col sm={6} md={3} lg={3} xl={3}>
                  <p style={{ fontSize: "15px" }}>
                    {" "}
                    <span className="tet">:</span>{" "}
                    <span className="tetTwo">-</span> {data.dataContract2}
                  </p>
                </Col>
              </Row>
            )
          })}
          <Collapse>
            <Panel header={t("More")} key="1">
              {data_sub_product.map((data, index) => {
                return (
                  <Row key={index}>
                    <Col sm={6} md={3} lg={3} xl={3}>
                      <p style={text_header}>{data.header1}</p>
                    </Col>
                    <Col sm={6} md={3} lg={3} xl={3}>
                      <p style={{ fontSize: "15px" }}>
                        {" "}
                        <span className="tet">:</span>{" "}
                        <span className="tetTwo">-</span> {data.dataContract1}
                      </p>
                    </Col>
                    <Col sm={6} md={3} lg={3} xl={3}>
                      <p style={text_header}>{data.header2}</p>
                    </Col>
                    <Col sm={6} md={3} lg={3} xl={3}>
                      <p style={{ fontSize: "15px" }}>
                        {" "}
                        <span className="tet">:</span>{" "}
                        <span className="tetTwo">-</span> {data.dataContract2}
                      </p>
                    </Col>
                  </Row>
                )
              })}
            </Panel>
          </Collapse>
        </Card>
        )
      }
      
    </div>
  )
}
