import React, { useState, useEffect } from "react"
import { Button, Form, notification, Modal } from "antd"
import { Row, Col } from "reactstrap"
import * as api from "../../../service/Api_Edit_device"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import Input_Comp from "./Input_Comp"
import axios from "axios"
import { useHistory } from "react-router-dom"
import Input_Date_Comp from "./Input_Date_Comp"
import Input_Comp_Show_Only from "./Input_Comp_Show_Only"
import helpers from "../../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"

const { confirm } = Modal

const dateFormat = "YYYY/MM/DD"

export default function Edit(props) {
  const { t } = useTranslation()
  const history = useHistory()
  const token = localStorage.getItem("token")
  const [form] = Form.useForm()
  const [edit, setEdit] = useState(true)
  const [loading, setLoading] = useState(false)
  const [data_edit_stock, setData_edit_stock] = useState({})

  const onFinish = () => {
    return confirm({
      title: t("Confirm edit"),
      icon: <ExclamationCircleOutlined />,
      okText: t("Edit"),
      cancelText: t("Cancel"),
      centered: true,
      onOk() {
        setLoading(true)
        let last_edit_stock = {}
        for (const [key, value] of Object.entries(data_edit_stock)) {
          if (!value) {
          } else {
            last_edit_stock[key] = value
          }
        }
        if (Object.keys(last_edit_stock)[0] === undefined) {
          openNotificationWithIcon("info", t("No data is change"))
          setLoading(false)
        } else {
          const update_stoke = api.UPDATE_STOCK(
            token,
            props.detail_device.sto_id,
            last_edit_stock
          )
          axios(update_stoke)
            .then(res => {
              openNotificationWithIcon("success", t("Edit stock success"))
              Cancel_Edit()
              const check_Sto = api.CHECK_DETAIL_STOCK(token, props.sto_id)
              axios(check_Sto)
                .then(res => {
                  setLoading(false)
                  props.setDetail_device(res.data.data)
                })
                .catch(error => {
                  setLoading(false)
                  console.log(error.response)
                })
            })
            .catch(error => {
              setLoading(false)
              if (error) {
                if (error.response) {
                  if (error.response.status === 401) {
                    helpers.re_login()
                    history.push("/login")
                  } else {
                    openNotificationWithIcon("error", t("Edit stock error"))
                  }
                }
              }
            })
        }
      },
      onCancel() {
        // console.log("Cancel")
      },
    })
  }

  const openNotificationWithIcon = (type, description) => {
    notification[type]({
      message: description,
    })
  }

  const onFill = () => {
    if (props.detail_device) {
      form.setFieldsValue({
        pro_serial_number: props.detail_device.pro_serial_number,
        sto_asset_number: props.detail_device.sto_asset_number,
        sto_date_in: null,
        sto_date_out: null,
        sto_date_install: null,
      })
    }
  }

  function Cancel_Edit() {
    setEdit(!edit)
    onFill()
    setData_edit_stock({})
  }

  useEffect(() => {
    onFill()
    return () => {}
  }, [props.detail_device])

  return (
    <div>
      <Form form={form} name="edit_car_form" onFinish={onFinish}>
        <Row>
          <Col>
            <div style={{ display: "inherit" }}>
              <p
                className="text-bold"
                style={{
                  color: "#495057",
                  textAlign: "center",
                  fontSize: "15px",
                }}
              >
                {t("Stock information")}
              </p>
              {edit === true && (
                <Button
                  style={{ float: "right", width: "150px" }}
                  onClick={() => setEdit(!edit)}
                >
                  {t("Edit")}
                </Button>
              )}
              {edit === false && (
                <Button
                  type="primary"
                  style={{ float: "right", width: "150px" }}
                  onClick={() => Cancel_Edit()}
                >
                  {t("Cancel")}
                </Button>
              )}
            </div>
          </Col>
        </Row>
        <br />

        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp_Show_Only
              header={t("Asset number")}
              placeholder={props.detail_device.sto_asset_number}
              headState={setData_edit_stock}
              nameState={data_edit_stock}
              keyState="sto_asset_number"
              disabled={true}
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp_Show_Only
              header={t("Serial number")}
              placeholder={props.detail_device.pro_serial_number}
              headState={setData_edit_stock}
              nameState={data_edit_stock}
              keyState="pro_serial_number"
              disabled={true}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Date_Comp
              header={t("Stock date in")}
              keyState="sto_date_in"
              placeholder={props.detail_device.sto_date_in}
              headState={setData_edit_stock}
              nameState={data_edit_stock}
              disabled={edit}
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Date_Comp
              header={t("Stock date out")}
              keyState="sto_date_out"
              placeholder={props.detail_device.sto_date_out}
              headState={setData_edit_stock}
              nameState={data_edit_stock}
              disabled={edit}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Date_Comp
              header={t("Stock date install")}
              keyState="sto_date_install"
              placeholder={props.detail_device.sto_date_install}
              headState={setData_edit_stock}
              nameState={data_edit_stock}
              disabled={edit}
            />
          </Col>
        </Row>

        <br />
        {edit === false && (
          <Button
            type="primary"
            htmlType="submit"
            style={{ display: "block", margin: "auto", width: "150px" }}
            loading={loading}
          >
          {t("Edit")}
          </Button>
        )}
      </Form>
    </div>
  )
}
