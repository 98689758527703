var Ip = require('../service/Ip')

export const SEARCH_ALL_CUSTOMER = (token, url) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}${url}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const FIND_ALL_CAR_CUSTOMER = (token, gid) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}distar/customer/cars/${gid}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};