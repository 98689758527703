import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { Button, Input, Form , Modal , notification } from "antd"
import { useHistory } from "react-router-dom"
import { UserAddOutlined } from "@ant-design/icons"
import { BiUserPlus } from "react-icons/bi"
import { AiOutlineUsergroupAdd } from "react-icons/ai"
import "./Search_Employee.css"
import { useTranslation } from "react-i18next"
import Select_Data_Filter from "./components/Select_Data_Filter"
import { QuestionOutlined } from "@ant-design/icons"
import axios from 'axios'
import * as api from '../../../service/Api_Employee'
import helpers from '../../Function_Redirect/Redirect_login'
import { useSelector } from "react-redux"

const { confirm } = Modal 

export default function Search(props) {
  const history = useHistory()
  const { t } = useTranslation()
  const token = localStorage.getItem('token')
  const permi = localStorage.getItem("permi")
  const [permi_t, setPermi_t] = useState(false)
  const [loading, setLoading] = useState(false)
  const { permissionAllService } = useSelector(({ Distar }) => Distar)

  useEffect(() => {
    if (permi) {
      if (JSON.parse(permi).employee) {
        setPermi_t(
          JSON.parse(permi).employee.write
            ? JSON.parse(permi).employee.write
            : false
        )
      }
    }
    return () => {}
  }, [permi])

  const onFinish = values => {
    props.search_data()
  }

  function moveEmployeeAllInGroup() {
    return confirm({
      title: t("confirm move all employee in Distar International Co.,Ltd. (DSI) group"),
      icon: <QuestionOutlined style={{ color: "#1890ff" }} />,
      centered: true,
      okText: t("confirm"),
      cancelText: t("cancel"),
      onOk() {
        setLoading(true)
        const setAll = api.MOVE_EMPLOYEE_ALL_IN_GROUP(token)
        axios(setAll)
        .then( res => {
      
          setLoading(false)
          props.search_data()
          openNotificationWithIcon(
            "success",
            t("Move all employee in group success")
          )
        })
        .catch( error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              } else {
                openNotificationWithIcon(
                  "error",
                  t("Move all employee in group error")
                )
              }
            }
          }
        })
      },
      onCancel() {},
    })
  } 

  const openNotificationWithIcon = (type, description) => {
    notification[type]({
      message: description,
    })
}

  return (
    <div>
      <Form
        name="Search_employee"
        initialValues={{
          remember: false,
        }}
        onFinish={onFinish}
      >
        <Row>
          <Col sm={12} md={4} lg={4} xl={4}>
            <Form.Item
              name="search"
              rules={[
                {
                  required: false,
                  message: "Please input name,department",
                },
              ]}
            >
              <Input
                placeholder={t("search")}
                style={{ marginBottom: "15px" }}
                onChange={e => props.setSearch_data_user(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8} lg={8} xl={8}>
            <Button
              type="primary"
              className="button-search-employee"
              htmlType="submit"
            >
              {t("search")}
            </Button>
            <Button
              className="button-search-New-Employee"
              onClick={() => history.push("/new-employee")}
              icon={<BiUserPlus size={20} style={{ marginRight: "10px", marginBottom:'3px' }} />}
            >
              {t("new Employee")}
            </Button>
            <Button
              className="button-search-New-Employee"
              onClick={() => moveEmployeeAllInGroup()}
              icon={<AiOutlineUsergroupAdd size={20} style={{ marginRight: "10px", marginBottom:'3px' }} />}
              loading={loading}
            >
             {t("Move all employee in group")}
            </Button>
          </Col>
        </Row>
      </Form>

      <Row>
        <Col sm={12} md={3} lg={3} xl={3}>
          <Select_Data_Filter
            header={t("search department")}
            placeholder={t("department")}
            name_api="LIST_DEPARTMENT"
            map_name="dep_name"
            map_value="dep_id"
            keyState="dep_id"
            headState={props.setSearch_department_id}
            nameState={props.search_department_id}
          />
        </Col>
        <Col sm={12} md={3} lg={3} xl={3}>
          <Select_Data_Filter
            header={t("employee group")}
            placeholder={t("employee group")}
            name_api="LIST_EMPLOYEE_GROUP"
            map_name="emp_group_name"
            map_value="emp_group_id"
            keyState="emp_group_id"
            headState={props.setSearch_group_id}
            nameState={props.search_group_id}
          />
        </Col>
      </Row>
    </div>
  )
}
