import React from 'react'
import { Row, Col } from "reactstrap"
import Render_Image from '../New_Customer/Devices/Render_Image'
import { useTranslation } from "react-i18next";

export default function Detail_Stock_modal(props) {
    const { t } = useTranslation();

    const text_header = {
        fontWeight:'bold',
        fontSize:'15px'
    }

    function cutTimeZone(date_T) {
        if(date_T){
            var date = new Date(date_T);
            var month = pad2(date.getMonth()+1);
            var day = pad2(date.getDate());
            var year= date.getFullYear();
    
            var formattedDate =  day+"/"+month+"/"+year;
            return formattedDate
        } else {
            return 'N/A'
        }
    }
    function pad2(n) {
        return (n < 10 ? '0' : '') + n;
      }
    
    function checkData(data) {
      if(data){
          return data
      } else {
          return 'N/A'
      }
    }

    const data_map = [
        {
            header1 : t("Product model protocol"),
            dataContract1 : checkData(props.data_stock.pro_model_protocol) ,
            header2 : t("Product model"),
            dataContract2 : checkData(props.data_stock.pro_model_name)
        }, {
            header1 : t("Product code"),
            dataContract1 : checkData(props.data_stock.pro_code) ,
            header2 : t("Product group name"),
            dataContract2 : checkData(props.data_stock.pro_group_name)
        }, {
            header1 : t("Product model code"),
            dataContract1 : checkData(props.data_stock.pro_model_code) ,
            header2 : t("Product remark"),
            dataContract2 : checkData(props.data_stock.pro_remark)
        }, {
            header1 : t("Stock serial number"),
            dataContract1 : checkData(props.data_stock.sto_serial_number) ,
            header2 : t("Stock asset number"),
            dataContract2 : checkData(props.data_stock.sto_asset_number)
        },{
            header1 : t("Stock date install"),
            dataContract1 : cutTimeZone(props.data_stock.sto_date_install) ,
            header2 : t("Warranty"),
            dataContract2 : checkData(props.data_stock.warranty_name)
        },
        {
            header1 : t("Contract start date"),
            dataContract1 : cutTimeZone(props.data_stock.con_start_date) ,
            header2 : t("Contract end date"),
            dataContract2 : cutTimeZone(props.data_stock.con_end_date)
        },
        {
            header1 : t("Customer name"),
            dataContract1 : checkData(props.data_stock.cus_name) ,
            header2 : t("Customer code"),
            dataContract2 : checkData(props.data_stock.cus_code)
        },{
            header1 : t("Customer phone"),
            dataContract1 : checkData(props.data_stock.cus_phone) ,
            header2 : t("Customer email"),
            dataContract2 : checkData(props.data_stock.cus_email)
        },{
            header1 : t("Dealer name"),
            dataContract1 : checkData(props.data_stock.dea_name) ,
            header2 : t("Dealer phone"),
            dataContract2 : checkData(props.data_stock.dea_phone)
        },{
            header1 : t("Dealer code"),
            dataContract1 : checkData(props.data_stock.dea_code) ,
            header2 : t("Dealer TAX ID"),
            dataContract2 : checkData(props.data_stock.dea_taxid)
        },{
            header1 : t("Dealer credit (day)"),
            dataContract1 : checkData(props.data_stock.dea_credit) ,
            header2 : t("Dealer email"),
            dataContract2 : checkData(props.data_stock.dea_email)
        },{
            header1 : t("Dealer contact firstname"),
            dataContract1 : checkData(props.data_stock.dea_contact_firstname) ,
            header2 : t("Dealer contact lastname"),
            dataContract2 : checkData(props.data_stock.dea_contact_lastname)
        },{
            header1 : t("Dealer contact email"),
            dataContract1 : checkData(props.data_stock.dea_contact_email) ,
            header2 : t("Dealer contact mobile"),
            dataContract2 : checkData(props.data_stock.dea_contact_mobile)
        },{
            header1 : t("Car name"),
            dataContract1 : checkData(props.data_stock.car_name) ,
            header2 : t("Car license"),
            dataContract2 : checkData(props.data_stock.car_license_number)
        },{
            header1 : t("Car chassis number"),
            dataContract1 : checkData(props.data_stock.car_chassis_number) ,
            header2 : t("Car code"),
            dataContract2 : checkData(props.data_stock.car_code)
        },
        // {
        //     header1 : 'Contract number',
        //     dataContract1 : checkData(props.data_stock.con_no) ,
        //     header2 : 'Contract code',
        //     dataContract2 : checkData(props.data_stock.con_code)
        // },{
        //     header1 : 'Contract date start',
        //     dataContract1 : cutTimeZone(props.data_stock.con_start_date) ,
        //     header2 : 'Contract date end',
        //     dataContract2 : cutTimeZone(props.data_stock.con_end_date)
        // },
    ]

    // console.log(props.data_stock);
    return (
        <div style={{ overflowY : 'scroll', height:'500px', overflowX : 'hidden' }}>
        <p style={{ textAlign:'center', fontWeight:'bold', marginTop:'10px', fontSize:'15px' }}> {t("Stock information")} </p>
        <hr />
        <Render_Image path_image={props.data_stock.pro_model_img} size={140} />
        <br />
        {
            data_map.map( (data, index) => {
                return(
                   <Row key={index}>
                   <Col sm={6} md={3} lg={3} xl={3} >
                   <p style={text_header}>{data.header1}</p>
                   </Col>
                   <Col sm={6} md={3} lg={3} xl={3}>
                   <p style={{ fontSize:'15px'}}> <span className="tet">:</span> <span className="tetTwo">-</span> {data.dataContract1}</p>
                   </Col>
                   <Col sm={6} md={3} lg={3} xl={3} >
                   <p style={text_header}>{data.header2}</p>
                   </Col>
                   <Col sm={6} md={3} lg={3} xl={3}>
                   <p style={{ fontSize:'15px'}}> <span className="tet">:</span> <span className="tetTwo">-</span> {data.dataContract2}</p>
                   </Col>
                   </Row>
                )
            })
        }

        </div>
    )
}
