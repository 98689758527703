import React, { useState, useEffect } from "react"
import Dropzone from "react-dropzone"
import { Row, Col } from "reactstrap"
import { InboxOutlined } from "@ant-design/icons"
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons"
import { Modal } from "antd"
import { useTranslation } from "react-i18next";

export default function Train_image(props) {
  const [img_preview_modal, setImg_preview_modal] = useState(null)
  const [visible, setVisible] = useState(false)
  const { t } = useTranslation();

  function onChangeImage(e) {
    const image_import = [...props.task_hast_form]
    e.map(f => {
      if (f.type === "image/jpeg") {
        image_import[props.index_step].fields[props.index_field].multi_image.push(f)
      }
    })
   
    props.setTask_hast_form(image_import)
  }

  function remove_image(index) {
    const img_cut = [...props.task_hast_form]
    img_cut[props.index_step].fields[props.index_field].multi_image.splice(index, 1)
    props.setTask_hast_form(img_cut)
  }

  useEffect(() => {
    renderImagePreview()
    return () => {}
  }, [props.task_hast_form[props.index_step].fields[props.index_field].multi_image])

  function renderImagePreview() {
    return props.task_hast_form[props.index_step].fields[props.index_field].multi_image.map((img, index) => {
      const imgPre = URL.createObjectURL(img)
      return (
        <Col xs={12} sm={6} md={6} lg={4} xl={4} key={index}>
          <img
            style={{
              width: "150px",
              height: "150px",
              display: "flex",
              marginLeft: "auto",
              marginRight: "auto",
              borderRadius: "7px",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
            src={imgPre}
            alt={img.name}
          />
          <div style={{ marginTop: "10px", marginBottom: "20px" }}>
            <Row>
              <Col>
                <EyeOutlined
                  style={{ fontSize: "25px", color: "#08c", float: "right" }}
                  onClick={() => {
                    setImg_preview_modal(imgPre)
                    setVisible(!visible)
                  }}
                />
              </Col>
              <Col>
                <DeleteOutlined
                  style={{ fontSize: "25px", color: "#FF2D2D", float: "left" }}
                  onClick={() => remove_image(index)}
                />
              </Col>
            </Row>
          </div>
        </Col>
      )
    })
  }

  return (
    <Row>
      <Col xs={12} sm={6} md={6} lg={4} xl={4}>
        <Dropzone onDrop={acceptedFiles => onChangeImage(acceptedFiles)}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div
                {...getRootProps()}
                style={{
                  height: "150px",
                  backgroundColor: "#F1F1F1",
                  padding: "10px",
                  borderStyle: "dotted",
                  borderColor: "#D4D2D2",
                  marginBottom: "20px",
                }}
              >
                <input {...getInputProps()} accept="image/jpeg" />
                <p
                  className="ant-upload-drag-icon"
                  style={{ textAlign: "center" }}
                >
                  <InboxOutlined
                    style={{ fontSize: "50px", color: "#1890ff" }}
                  />
                </p>
                <p
                  className="ant-upload-text"
                  style={{ textAlign: "center", fontSize: "20px" }}
                >
                 {t("Upload photo")}
                </p>
              </div>
            </section>
          )}
        </Dropzone>
      </Col>

      {renderImagePreview()}

      <Modal
        footer={null}
        centered
        open={visible}
        onOk={() => {
          setImg_preview_modal(null)
          setVisible(false)
        }}
        onCancel={() => {
          setImg_preview_modal(null)
          setVisible(false)
        }}
      >
        <div>
          <img
            src={img_preview_modal}
            alt="info"
            style={{
              width: "100%",
              height: "auto",
              maxWidth: "400px",
              display: "flex",
              margin: "auto",
            }}
          />
        </div>
      </Modal>
    </Row>
  )
}
