import React, { useEffect, useState } from "react"
import { Form, Pagination, Table, Button, Input } from "antd"
import axios from "axios"
import { Row, Col } from "reactstrap"
import { EditOutlined } from "@ant-design/icons"
import Search_Contract_In_Customer from "./Search_Contract_In_Customer"
import * as api from "../../../service/Api_New_Stock"
import { FcDataSheet } from "react-icons/fc"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import helpers from "../../Function_Redirect/Redirect_login"

export default function Select_Contract_In_Customer(props) {
  const history = useHistory()
  const { t } = useTranslation()
  const [
    data_search_contract_in_customer,
    setData_search_contract_in_customer,
  ] = useState(null)
  const token = localStorage.getItem("token")
  const [contract_table, setContract_table] = useState([])
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 10,
    page_Size: 10,
  })

  useEffect(() => {
    if (props.customer_data.cus_id !== undefined) {
      search_data_contract_in()
    }

    return () => {
      setContract_table([])
    }
  }, [props.customer_data])

  function search_data_contract_in() {
    if (data_search_contract_in_customer) {
      const list_customer_data = api.LIST_CUSTOMER_CONTRACT_DATA(
        token,
        props.customer_data.cus_id,
        1,
        page_limit.page_Size,
        data_search_contract_in_customer
      )
      axios(list_customer_data)
        .then(res => {
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setContract_table(res.data.data)
        })
        .catch(error => {
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else {
      const list_customer_no_data = api.LIST_CUSTOMER_CONTRACT_NO_DATA(
        token,
        props.customer_data.cus_id,
        1,
        page_limit.page_Size
      )
      axios(list_customer_no_data)
        .then(res => {
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setContract_table(res.data.data)
        })
        .catch(error => {
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  function changePagination(page, pageSize) {
    if (data_search_contract_in_customer) {
      const list_customer_data = api.LIST_CUSTOMER_CONTRACT_DATA(
        token,
        props.customer_data.cus_id,
        page,
        pageSize,
        data_search_contract_in_customer
      )
      axios(list_customer_data)
        .then(res => {
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setContract_table(res.data.data)
        })
        .catch(error => {
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else {
      const list_customer_no_data = api.LIST_CUSTOMER_CONTRACT_NO_DATA(
        token,
        props.customer_data.cus_id,
        page,
        pageSize
      )
      axios(list_customer_no_data)
        .then(res => {
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setContract_table(res.data.data)
        })
        .catch(error => {
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  const columns = [
    {
      title: t("Contract code"),
      dataIndex: "con_code",
      key: "con_code",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Contract name"),
      dataIndex: "con_name",
      key: "con_name",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Contract start date (DD/MM/YY)"),
      dataIndex: "con_start_date",
      key: "con_start_date",
      render: text => <p>{cutTimeZone(text)}</p>,
    },
    {
      title: t("Contract end date (DD/MM/YY)"),
      dataIndex: "con_end_date",
      key: "con_end_date",
      render: text => <p>{cutTimeZone(text)}</p>,
    },
  ]

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return "N/A"
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      props.setStock_new({
        ...props.stock_new,
        con_id: selectedRows[0].con_id,
      })
      props.setContract_data({
        ...props.contract_data,
        con_id: selectedRows[0].con_id,
        con_name: selectedRows[0].con_name,
      })
    },
    getCheckboxProps: record => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  }

  return (
    <div>
      {props.contract_data.con_id === null &&
        props.contract_create_only === false && (
          <div>
            <p className="text-bold ">
              - {t("Contract")} <span style={{ color: "red" }}>*</span>
            </p>
            <Search_Contract_In_Customer
              create_new_contract={props.create_new_contract}
              setCreate_new_contract={props.setCreate_new_contract}
              contract_create_only={props.contract_create_only}
              setContract_create_only={props.setContract_create_only}
              search_data_contract_in={search_data_contract_in}
              setData_search_contract_in_customer={
                setData_search_contract_in_customer
              }
            />
            <br />

            <Table
              rowSelection={{
                type: "radio",
                ...rowSelection,
              }}
              rowKey="con_id"
              pagination={false}
              columns={columns}
              dataSource={contract_table}
              scroll={{ x: 800 }}
            />

            <div style={{ float: "right", marginTop: "10px" }}>
              <Pagination
                current={page_limit.page}
                total={page_limit.limit}
                onChange={changePagination}
              />
            </div>
            <p style={{ fontSize: "15px", marginTop: "20px" }}>
              {" "}
              <FcDataSheet size={20} style={{ marginBottom: "5px" }} />{" "}
              {t("total data")} : {page_limit.limit}
            </p>
          </div>
        )}
      {props.contract_data.con_id !== null && (
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div style={{ display: "flex" }}>
              <p
                className="text-bold "
                style={{ marginTop: "7px", marginRight: "8px" }}
              >
                {t("Contract")}
              </p>
              <Input
                style={{ marginLeft: "45px", height: "35px" }}
                value={props.contract_data.con_name}
                placeholder={props.contract_data.con_name}
                disabled
              />
              <a>
                <EditOutlined
                  style={{ fontSize: "20px", marginLeft: "10px" }}
                  onClick={() => {
                    setData_search_contract_in_customer(null)
                    props.setStock_new({
                      ...props.stock_new,
                      con_id: null,
                    })
                    props.setContract_data({
                      ...props.contract_data,
                      con_id: null,
                      con_name: null,
                    })
                  }}
                />
              </a>
            </div>
          </Col>
        </Row>
      )}

      {props.contract_create_only === true && (
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div style={{ display: "flex" }}>
              <p
                className="text-bold "
                style={{ marginTop: "7px", marginRight: "8px" }}
              >
                {t("Contract")}
              </p>
              <Input
                style={{ marginLeft: "45px", height: "35px" }}
                value={props.create_new_contract.con_name}
                placeholder={props.create_new_contract.con_name}
                disabled
              />
              <a>
                <EditOutlined
                  style={{ fontSize: "20px", marginLeft: "10px" }}
                  onClick={() => {
                    props.setCreate_new_contract({})
                    props.setContract_create_only(false)
                  }}
                />
              </a>
            </div>
          </Col>
        </Row>
      )}
    </div>
  )
}
