import React,{ useState, useCallback, useRef, useEffect } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { AutoComplete, Form } from 'antd';
import img_ping from './ping.png'

const { Option } = AutoComplete;
const libraries = ["places"];
const mapContainerStyle = {
  height: "300px"
};
const options = {
  disableDefaultUI: true,
  zoomControl: true,
};

export default function Map(props) {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_APP,
        libraries,
      });
      const [center, setCenter] = useState({
        lat: 13.2753119,
        lng: 101.4843104,
      })
      const [map_marker, setMap_marker] = useState(null)
      const [zoom_gg_map, setZoom_gg_map] = useState(6)

      useEffect(() => {
        if(props.branch.cus_branch_long === null && props.branch.cus_branch_lat === null){
          setMap_marker(null)
          setZoom_gg_map(6)
          setCenter({
            ...center,
            lat: 13.2753119,
            lng: 101.4843104,
          })
        }
        return () => {
          
        }
      }, [props.branch.cus_branch_lat,props.branch.cus_branch_long])

    const onMapClick = (e) => {
      let lat = e.latLng.lat()
      let lng = e.latLng.lng()

      setMap_marker({
        lat: lat,
        lng: lng,
        time: new Date()
      })

      props.setBranch({
        ...props.branch,
        cus_branch_lat: lat.toString(),
        cus_branch_long: lng.toString()
      })
    }
    
      const mapRef = useRef();
      const onMapLoad = useCallback((map) => {
        mapRef.current = map;
      }, []);
    
      const panTo = useCallback(({ lat, lng }) => {
        mapRef.current.panTo({ lat, lng });
        mapRef.current.setZoom(14);
      }, []);

      if (loadError) return "Error";
      if (!isLoaded) return "Loading...";

    return (
<div>
      <Search 
        panTo={panTo} 
        setMap_marker={setMap_marker}
        setZoom_gg_map={setZoom_gg_map}
        setBranch={props.setBranch}
        branch={props.branch}
      />
<br />
      <GoogleMap
        id="map"
        mapContainerStyle={mapContainerStyle}
        zoom={zoom_gg_map}
        center={center}
        options={options}
        onClick={onMapClick}
        onLoad={onMapLoad}
        defaultZoom={6}
        defaultCenter={{ lat: 13.2753119, lng: 101.4843104 }}
      >

        { map_marker && (
          <Marker
            key={`${map_marker.lat}-${map_marker.lng}`}
            position={{ lat: map_marker.lat, lng: map_marker.lng }}
            icon={{
              url: img_ping,
            }}
          />
        )
        }

      </GoogleMap>
    </div>
    )
}

function Search({ panTo, setMap_marker, setZoom_gg_map, setBranch, branch }) {
    const {
      ready,
      value,
      suggestions: { status, data },
      setValue,
      clearSuggestions,
    } = usePlacesAutocomplete({
      requestOptions: {
        location: { lat: () => 43.6532, lng: () => -79.3832 },
        radius: 100 * 1000,
      },
    });
  
    const handleInputTwo = (e) => {
      setValue(e);
    };
  
    const handleSelect = async (address) => {
      setValue(address, false);
      clearSuggestions();
  
      try {
        const results = await getGeocode({ address });
        const { lat, lng } = await getLatLng(results[0]);
        panTo({ lat, lng });
        setMap_marker({
          lat: lat,
          lng: lng,
          time: new Date()
        })
        setZoom_gg_map(16)
        setBranch({
          ...branch,
          cus_branch_lat: lat.toString(),
          cus_branch_long: lng.toString()
        })
      } catch (error) {
        console.log(error);
      }
    };
  
  
    return (
  <div>
  <Form.Item
            name="search_google_map"
            rules={[
              {
                required: false,
                message: "Please search location",
              },
            ]}
          >
          <AutoComplete
          style={{
            width: '100%',
          }}
          onSearch={handleInputTwo}
          onSelect={handleSelect}
          placeholder="Search location"
        >
          {
              data.map((detail, index) => (
              <Option key={index} value={detail.description}>
                {detail.description}
              </Option>
            ))
          }
          </AutoComplete>
          </Form.Item> 
        
      </div>
    );
  }
  