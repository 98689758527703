var Ip = require('../service/Ip')

export const GET_DETAIL_STOCK = (token,sto_id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/stock/${sto_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_STOCK_HAST_PRODUCT = (token,sto_id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/stock/stock-has-contract/${sto_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_SUB_PRODUCT = (token,pro_id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/stock/product/is-product-has-sup-product/${pro_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CHECK_SERVICE_NUMBER = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/service/generate-ser-no`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CHECK_USER_INFO = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}authorize/user_info`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const FIND_DEVICE_GPS_LOCATION_CAR = (token, gid) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}distar/cars/gps/location/${gid}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };