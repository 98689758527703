var Ip = require('../service/Ip')

export const GET_DETAIL_SERVICE = (token, ser_id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/service/${ser_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

export const LIST_DATA_EMPLOYEE_DATA = (token,ser_id, page, pageSize, data) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/service/manager/list-avaliable-employee/${ser_id}?page=${page}&limit=${pageSize}&order_by=asc&q=${data}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_DATA_EMPLOYEE_NO_DATA = (token,ser_id, page, pageSize) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/service/manager/list-avaliable-employee/${ser_id}?page=${page}&limit=${pageSize}&order_by=asc`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_DATA_MANAGER_DATA = (token,ser_id, page, pageSize, data) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/service/manager/list-avaliable-employee/${ser_id}?page=${page}&limit=${pageSize}&order_by=asc&q=${data}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_DATA_MANAGER_NO_DATA = (token,ser_id, page, pageSize) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/service/manager/list-avaliable-employee/${ser_id}?page=${page}&limit=${pageSize}&order_by=asc`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_DATA_ADMIN_DATA = (token,ser_id, page, pageSize, data) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/service/admin/list-avaliable-admin/${ser_id}?page=${page}&limit=${pageSize}&order_by=asc&q=${data}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_DATA_ADMIN_NO_DATA = (token,ser_id, page, pageSize) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/service/admin/list-avaliable-admin/${ser_id}?page=${page}&limit=${pageSize}&order_by=asc`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const INSERT_MANAGER = (token, data) => {
  return {
    method: 'post',
    url: `${Ip.getIP()}api/v1/service/manager`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    },
    data : data
  };
};

export const INSERT_ADMIN = (token, data) => {
  return {
    method: 'post',
    url: `${Ip.getIP()}api/v1/service/admin`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    },
    data : data
  };
};

export const INSERT_OPERATOR = (token, data) => {
  return {
    method: 'post',
    url: `${Ip.getIP()}api/v1/service/operator`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    },
    data : data
  };
};

export const LIST_DATA_OPERATOR_DATA = (token, ser_id ,page, pageSize, data) => {
  return {
    method: 'get',  
    url: `${Ip.getIP()}api/v1/service/operator/list-avaliable-operator/${ser_id}?page=${page}&limit=${pageSize}&order_by=asc&q=${data}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_DATA_OPERATOR_NO_DATA = (token, ser_id ,page, pageSize) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/service/operator/list-avaliable-operator/${ser_id}?page=${page}&limit=${pageSize}&order_by=asc`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_SERVICE_HAST_MANAGER = (token, ser_id) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/service/manager/${ser_id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_SERVICE_HAST_ADMIN = (token, ser_id) => {
  return {
    method: 'get',
    url: `${Ip.getIP()}api/v1/service/admin/${ser_id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_SERVICE_HAST_OPERATOR = (token, ser_id) => {
  return {
    method: 'get', 
    url: `${Ip.getIP()}api/v1/service/operator/${ser_id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const REMOVE_MANAGER = (token, spm_id) => {
  return {
    method: 'delete',
    url: `${Ip.getIP()}api/v1/service/manager/${spm_id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const REMOVE_ADMIN = (token, spa_id) => {
  return {
    method: 'delete',
    url: `${Ip.getIP()}api/v1/service/admin/${spa_id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const REMOVE_OPERATOR = (token, so_id) => {
  return {
    method: 'delete',       
    url: `${Ip.getIP()}api/v1/service/operator/${so_id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};
 
export const LIST_MODULE = (token) => {
  return {
    method: 'get',       
    url: `${Ip.getIP()}api/v1/service/task/module`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_TASK_TEMPLATE = (token) => {
  return {
    method: 'get',       
    url: `${Ip.getIP()}api/v1/service/task_template`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_TASK_GROUP = (token , group_id) => {
  return {
    method: 'get',       
    url: `${Ip.getIP()}api/v1/service/get-task-by-group-id/${group_id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_PRIORITY = (token) => {
  return {
    method: 'get',       
    url: `${Ip.getIP()}api/v1/service/task/priority`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const INSERT_TASK = (token, ser_id, data) => {
  return {
    method: 'post',       
    url: `${Ip.getIP()}api/v1/service/task/${ser_id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    },
    data : data
  };
};

export const CHECK_SERVICE_HAST_TASK = (token, ser_id) => {
  return {
    method: 'get',       
    url: `${Ip.getIP()}api/v1/service/get-task-by-ser-id/${ser_id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const REMOVE_TASK = (token, task_id) => {
  return {
    method: 'delete',       
    url: `${Ip.getIP()}api/v1/service/task/${task_id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const DELETE_SERVICE = (token, ser_id) => {
  return {
    method: 'delete',       
    url: `${Ip.getIP()}api/v1/service/${ser_id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const SERVICE_OVERVIEW = (token, ser_id) => {
  return {
    method: 'get',       
    url: `${Ip.getIP()}api/v1/service/overview/${ser_id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_OPERATOR_SUGGEST = (token, ser_id) => {
  return {
    method: 'get',       
    url: `${Ip.getIP()}api/v1/service/task/suggest-operator/${ser_id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const LIST_ACTIVITY_LOG = (token, ser_id) => {
  return {
    method: 'get',       
    url: `${Ip.getIP()}api/v1/service/logs/${ser_id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const CHANGE_STATUS_SERVICE = (token, ser_id, ser_status_name) => {
  return {
    method: 'post',       
    url: `${Ip.getIP()}api/v1/service/change-service-status/${ser_id}/ser_status_name/${ser_status_name}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const CHANGE_STATUS_SERVICE_HAS_DATA = (token, ser_id, ser_status_name, data) => {
  return {
    method: 'post',       
    url: `${Ip.getIP()}api/v1/service/change-service-status/${ser_id}/ser_status_name/${ser_status_name}?remark=${data}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const CHANGE_STATUS_ACCEPT_SERVICE_HAS_DATA = (token, ser_id, ser_status_name, data) => {
  return {
    method: 'post',       
    url: `${Ip.getIP()}api/v1/service/change-service-status/${ser_id}/ser_status_name/${ser_status_name}?log_details=${data}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};

export const DOWNLOAD_FILE_ONE = (token, fup_path) => {
  return {
    method: 'get',       
    url: `${Ip.getIP()}storage/file/${fup_path}`,
    responseType: 'arraybuffer',
    headers: {
      // 'Content-Type': 'application/json',
      'x-access-token' : token
    }
  };
};