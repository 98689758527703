import React from 'react'
import { Progress } from "antd"
import { useTranslation } from "react-i18next";

export default function Service_Status(props) {
    const { t } = useTranslation();
    const Header_text = {
        fontSize: "20px",
        marginBottom: "0px",
        color: "#A5A5A5",
      }

      function render_percent(all_status, status_list) {
        let number = 0
        number = (status_list / all_status) * 100
        return number
      }

    return (
        <div>
        <p style={{ fontSize: "20px", marginBottom: "15px", color: "#495057" }}>
         {t("service status")}
        </p>
    
        <p style={Header_text}> {t("assigned")} <span style={{ float: "right", color: "#495057", fontWeight: "bold" }} > {props.assigned} </span>{" "} </p>
            <Progress
              percent={render_percent(
                props.count_all_status,
                props.assigned,
              )}
              showInfo={false}
              status="active"
              strokeColor="#76CE86"
            />
    
      <p style={Header_text}> {t("cancel")} <span style={{ float: "right", color: "#495057", fontWeight: "bold" }} > {props.cancle} </span>{" "} </p>
            <Progress
              percent={render_percent(
                props.count_all_status,
                props.cancle,
              )}
              showInfo={false}
              status="active"
              strokeColor="#ff6961"
            />
    
            <p style={Header_text}> {t("complete")}  <span style={{ float: "right", color: "#495057", fontWeight: "bold" }} > {props.complete} </span>{" "} </p>
            <Progress
              percent={render_percent(
                props.count_all_status,
                props.complete,
              )}
              showInfo={false}
              status="active"
              strokeColor="#EA9FE6"
            />
            
            <p style={Header_text}> {t("inprogress")} <span style={{ float: "right", color: "#495057", fontWeight: "bold" }} > {props.inprogress} </span>{" "} </p>
            <Progress
              percent={render_percent(
                props.count_all_status,
                props.inprogress,
              )}
              showInfo={false}
              status="active"
              strokeColor="#7985E0"
            />
    
            <p style={Header_text}> {t("overdue")} <span style={{ float: "right", color: "#495057", fontWeight: "bold" }} > {props.overdue} </span>{" "} </p>
            <Progress
              percent={render_percent(
                props.count_all_status,
                props.overdue,
              )}
              showInfo={false}
              status="active"
              strokeColor="#6891C3"
            />
    
            <p style={Header_text}> {t("pending for approval admin")} <span style={{ float: "right", color: "#495057", fontWeight: "bold" }} > {props.pending_for_approval_admin} </span>{" "} </p>
            <Progress
              percent={render_percent(
                props.count_all_status,
                props.pending_for_approval_admin,
              )}
              showInfo={false}
              status="active"
              strokeColor="#F29496"
            />
            
            <p style={Header_text}> {t("pending for approval manager")} <span style={{ float: "right", color: "#495057", fontWeight: "bold" }} > {props.pending_for_approval_manager} </span>{" "} </p>
            <Progress
              percent={render_percent(
                props.count_all_status,
                props.pending_for_approval_manager,
              )}
              showInfo={false}
              status="active"
              strokeColor="#EBAE86"
            />
    
            <p style={Header_text}> {t("resolved")} <span style={{ float: "right", color: "#495057", fontWeight: "bold" }} > {props.resolved} </span>{" "} </p>
            <Progress
              percent={render_percent(
                props.count_all_status,
                props.resolved,
              )}
              showInfo={false}
              status="active"
              strokeColor="#EA9FE6"
            />
    
            <p style={Header_text}> {t("unassigned")}  <span style={{ float: "right", color: "#495057", fontWeight: "bold" }} > {props.unassigned} </span>{" "} </p>
            <Progress
              percent={render_percent(
                props.count_all_status,
                props.unassigned,
              )}
              showInfo={false}
              status="active"
              strokeColor="#495057"
            />
      </div>
    )
}
