import React, { useRef, useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { useHistory } from "react-router-dom"
import { MdKeyboardBackspace } from "react-icons/md"
import { Form, Button, notification, Modal, Avatar } from "antd"
import Input_Comp from "./components/Input_Comp"
import Select_no_search from "./components/Select_no_search"
import Level from "./components/Level"
import { UserOutlined } from "@ant-design/icons"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import * as api from "../../../service/Api_Edit_Operator"
import axios from "axios"
import Input_No_Change from "./components/Input_No_Change"
import helpers from "../../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"

const { confirm } = Modal

const Operator = props => {
  const { t } = useTranslation()
  const token = localStorage.getItem("token")
  const [form] = Form.useForm()
  const history = useHistory()
  const imageUploader = useRef(null)
  const [edit, setEdit] = useState(true)
  const [loading_button, setLoading_button] = useState(false)
  const [
    image_preview_before_upload,
    setImage_preview_before_upload,
  ] = useState(null)
  const [image_select_upload, setImage_select_upload] = useState(null)
  const [operator_edit, setOperator_Edit] = useState({})

  useEffect(() => {
    return () => {
      setImage_preview_before_upload(null)
      setImage_select_upload(null)
    }
  }, [])

  useEffect(() => {
    if (props.image_From_db !== null) {
      setImage_preview_before_upload(props.image_From_db)
    }
    return () => {}
  }, [props.image_From_db])

  useEffect(() => {
    onFill()
    setOperator_Edit({
      ope_firstname: props.detail_Operator.ope_firstname,
    })
    return () => {}
  }, [props.detail_Operator])

  const onFill = () => {
    form.setFieldsValue({
      ope_firstname: props.detail_Operator.ope_firstname,
      ope_lastname: props.detail_Operator.ope_lastname,
      ope_code: props.detail_Operator.ope_code,
      ope_email: props.detail_Operator.ope_email,
      ope_mobile: props.detail_Operator.ope_mobile,
      ope_department_id: props.detail_Operator.dep_id,
      ope_nickname: props.detail_Operator.ope_nickname,
      ope_group_id: props.detail_Operator.ope_group_id,
      ope_position_id: props.detail_Operator.pos_id,
    })
  }

  const onReset = () => {
    form.resetFields()
    setOperator_Edit({
      ope_firstname: props.detail_Operator.ope_firstname,
    })
  }

  function Cancel_Edit() {
    setEdit(!edit)
    onFill()
    setImage_select_upload(null)
    if (props.image_From_db !== null) {
      setImage_preview_before_upload(props.image_From_db)
    }
    setOperator_Edit({
      ope_firstname: props.detail_Operator.ope_firstname,
    })
  }

  const handleImageUpload = e => {
    if (e.target.files[0] !== undefined) {
      if (e.target.files[0].type !== "image/jpeg") {
        openNotificationWithIcon(
          "error",
          t("please upload jpg or jpeg image only")
        )
      } else {
        let img_upload = e.target.files[0]
        setImage_preview_before_upload(URL.createObjectURL(img_upload))
        setImage_select_upload(img_upload)
      }
    }
  }

  const onFinish = values => {
    return confirm({
      title: t("Confirm edit operator"),
      icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
      centered: true,
      okText: t("Confirm"),
      cancelText: t("no"),
      onOk() {
        setLoading_button(true)
        let last_edit_operator = {}
        let no_change = false
        for (const [key, value] of Object.entries(operator_edit)) {
          if (!value) {
            no_change = true
          } else {
            last_edit_operator[key] = value
          }
        }

        if (no_change === true) {
          setLoading_button(false)
          openNotificationWithIcon("info", t("No data is change"))
        } else {
          if (image_select_upload !== null) {
            const formData = new FormData()
            formData.append("file", image_select_upload)
            const upload_image = api.UPLOAD_PROFILE(token, formData)
            axios(upload_image)
              .then(res => {
                let add_image_path = {
                  ope_avatar: res.data.path,
                  ...last_edit_operator,
                }
                edit_operator_data_info(add_image_path)
              })
              .catch(error => {
                setLoading_button(false)
                if (error) {
                  if (error.response) {
                    if (error.response.status === 401) {
                      helpers.re_login()
                      history.push("/login")
                    } else {
                      openNotificationWithIcon(
                        "error",
                        t("Edit operator error")
                      )
                    }
                  }
                }
              })
          } else {
            edit_operator_data_info(last_edit_operator)
          }
        }
      },
      onCancel() {},
    })
  }

  function edit_operator_data_info(last_edit_operator) {
    const edit_operator = api.EDIT_OPERATOR(
      token,
      last_edit_operator,
      props.ope_id
    )
    axios(edit_operator)
      .then(res => {
        setLoading_button(false)
        Cancel_Edit()
        props.check_operator_detail()
        openNotificationWithIcon("success", t("Edit operator success"))
      })
      .catch(error => {
        setLoading_button(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            } else {
              openNotificationWithIcon("error", t("Edit operator error"))
            }
          }
        }
      })
  }

  const openNotificationWithIcon = (type, description) => {
    notification[type]({
      message: description,
    })
  }

  return (
    <div>
      <Form form={form} name="control-hooks" onFinish={onFinish}>
        <Row>
          <Col>
            <div style={{ display: "inherit" }}>
              <p
                className="text-bold"
                style={{
                  color: "#495057",
                  textAlign: "center",
                  fontSize: "15px",
                }}
              >
                {t("Operator information")}
              </p>
              {edit === true && (
                <Button
                  style={{ float: "right", width: "150px" }}
                  onClick={() => setEdit(!edit)}
                >
                  {t("Edit")}
                </Button>
              )}
              {edit === false && (
                <Button
                  type="primary"
                  style={{ float: "right", width: "150px" }}
                  onClick={() => Cancel_Edit()}
                >
                  {t("Cancel")}
                </Button>
              )}
            </div>
          </Col>
        </Row>
        <br />

        <div
          className="image-upload"
          style={{ marginTop: "10px", marginBottom: "30px" }}
        >
          {image_preview_before_upload === null && (
            <Avatar
              icon={<UserOutlined />}
              size={100}
              style={{ display: "block", margin: "auto" }}
            />
          )}

          {image_preview_before_upload !== null && (
            <Avatar
              src={image_preview_before_upload}
              size={100}
              style={{ display: "block", margin: "auto" }}
            />
          )}
        </div>

        <input
          type="file"
          accept="image/jpeg"
          onChange={handleImageUpload}
          ref={imageUploader}
          style={{
            display: "none",
          }}
        />

        {edit === false && (
          <div>
            <Button
              type="primary"
              style={{ display: "block", margin: "10px auto 10px auto" }}
              onClick={() => imageUploader.current.click()}
            >
              {t("Upload photo")}
            </Button>
            <p style={{ color: "red", textAlign: "center" }}>
              {" "}
              {t("please upload jpg or jpeg image only")}{" "}
            </p>
          </div>
        )}

        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Operator firstname")}
              placeholder={props.detail_Operator.ope_firstname}
              headState={setOperator_Edit}
              nameState={operator_edit}
              disabled={edit}
              keyState="ope_firstname"
              type="text"
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Operator lastname")}
              placeholder={props.detail_Operator.ope_lastname}
              headState={setOperator_Edit}
              nameState={operator_edit}
              disabled={edit}
              keyState="ope_lastname"
              type="text"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Operator code")}
              placeholder={props.detail_Operator.ope_code}
              headState={setOperator_Edit}
              nameState={operator_edit}
              disabled={edit}
              keyState="ope_code"
              type="text"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Operator nickname")}
              placeholder={props.detail_Operator.ope_nickname}
              headState={setOperator_Edit}
              nameState={operator_edit}
              disabled={edit}
              keyState="ope_nickname"
              type="text"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_No_Change
              header={t("Operator email")}
              placeholder={props.detail_Operator.ope_email}
              headState={setOperator_Edit}
              nameState={operator_edit}
              disabled={true}
              keyState="ope_email"
              type="email"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Operator mobile")}
              placeholder={props.detail_Operator.ope_mobile}
              headState={setOperator_Edit}
              nameState={operator_edit}
              disabled={edit}
              keyState="ope_mobile"
              type="number"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Operator group")}
              placeholder={t("Operator group")}
              name_api="LIST_OPERATOR_GROUP"
              map_name="ope_group_name"
              map_value="ope_group_id"
              keyState="ope_group_id"
              Header_data="data"
              headState={setOperator_Edit}
              nameState={operator_edit}
              disabled={edit}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Operator department")}
              placeholder={t("Operator department")}
              name_api="LIST_DEPARTMENT"
              map_name="dep_name"
              map_value="dep_id"
              keyState="ope_department_id"
              Header_data="data"
              headState={setOperator_Edit}
              nameState={operator_edit}
              disabled={edit}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Operator position")}
              placeholder={t("Operator position")}
              name_api="LIST_POSITION"
              map_name="pos_name"
              map_value="pos_id"
              keyState="ope_position_id"
              Header_data="data"
              headState={setOperator_Edit}
              nameState={operator_edit}
              disabled={edit}
            />
          </Col>
        </Row>

        {/*<Level
          setOperator_Edit={setOperator_Edit}
          operator_edit={operator_edit}
          edit={edit}
          ope_level_id={props.detail_Operator.ope_level_id}
        />*/}

        <br />
        {edit === false && (
          <Button
            type="primary"
            htmlType="submit"
            style={{ display: "block", margin: "auto", width: "150px" }}
            loading={loading_button}
          >
            {t("Edit")}
          </Button>
        )}
      </Form>
    </div>
  )
}
export default Operator
