import React, { useState, useEffect } from "react"
import { Progress } from "antd"
import { Doughnut } from "react-chartjs-2"

export default function Render_Total_And_DoughnutChart({
  text_header,
  img_header,
  labels_status,
  data_status,
  color_chart,
  total,
  data_lineChart,
}) {
  const [chart_count, setChart_count] = useState({})
  const [data_mapping, setData_mapping] = useState([])
  const Header_text = {
    fontSize: "20px",
    marginBottom: "0px",
    color: "#A5A5A5",
  }
  const style_card = {
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    padding: "20px"
  }
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      labels: {
        usePointStyle: true,
      },
      display: true,
      position: "right",
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  }

  useEffect(() => {
    setChart_count({
      labels: labels_status,
      datasets: [
        {
          data: data_status,
          backgroundColor: color_chart,
          hoverBackgroundColor: color_chart,
        },
      ],
    })
    setData_mapping(data_lineChart)
    return () => {}
  }, [labels_status, data_status, data_lineChart])

  function render_percent(all_status, status_list) {
    let number = 0
    number = (status_list / all_status) * 100
    return number
  }

  return (
    <div style={style_card}>
      <p style={{ fontSize: "20px", marginBottom: "15px", color: "#495057" }}>
        <img
          src={img_header}
          alt="contract"
          style={{
            width: "25px",
            height: "25px",
            marginRight: "20px",
            marginTop: "-10px",
          }}
        />
        {text_header}
      </p>
      {data_mapping.map((data, index) => {
        return (
          <div key={index}>
            <p style={Header_text}>
              {data.text_header}
              <span
                style={{ float: "right", color: "#495057", fontWeight: "bold" }}
              >
                {data.total_count}
              </span>
            </p>
            <Progress
              percent={render_percent(total, data.total_count)}
              showInfo={false}
              status="active"
              strokeColor={data.color}
            />
          </div>
        )
      })}
      <br /> <br />
      <div>
        <Doughnut data={chart_count} options={options} />
      </div>
    </div>
  )
}
