import React from "react"
import { Row, Col } from "reactstrap"
import Select_Data_Filter from "./components/Select_Data_Filter"
import { useTranslation } from "react-i18next";

export default function Filter_Operator(props) {
  const { t } = useTranslation();

  return (
    <div>
      <Row>
        <Col sm={12} md={3} lg={3} xl={3}>
          <Select_Data_Filter
            header={t("Operator group")}
            placeholder={t("Operator group")}
            keyState="operator_group_id"
            name_api="LIST_OPERATOR_GROUP"
            headState={props.setFilter_operator}
            nameState={props.filter_operator}
            map_name="ope_group_name"
            map_value="ope_group_id"
          />
        </Col>
        <Col sm={12} md={3} lg={3} xl={3}>
          <Select_Data_Filter
            header={t("Operator department")}
            placeholder={t("Operator department")}
            keyState="operator_dep_id"
            name_api="LIST_DEPARTMENT"
            headState={props.setFilter_operator}
            nameState={props.filter_operator}
            map_name="dep_name"
            map_value="dep_id"
          />
        </Col>
      </Row>
    </div>
  )
}
