import React from "react"
import { Col } from "reactstrap"

export default function Col_Device({ text_header, data , xsH , smH , mdH , lgH , xlH , xsD , smD , mdD , lgD , xlD }) {
  return (
    <>
      <Col xs={xsH} sm={smH} md={mdH} lg={lgH} xl={xlH}>
        <p className="text-bold"> {text_header} </p>
      </Col>
      <Col xs={xsD} sm={smD} md={mdD} lg={lgD} xl={xlD}>
        <p style={{ fontSize: "15px" }}>
          <span className="tet">:</span> <span className="tetTwo">-</span>{" "}
          {data}
        </p>
      </Col>
    </>
  )
}
