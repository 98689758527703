import React,{ useEffect, useState } from "react"
import { Row, Col } from "reactstrap"
import { Button, Input, Form } from "antd"
import { useHistory } from "react-router-dom"
import { VscTools } from "react-icons/vsc"
import Filter_service from './Filter_service'
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux"

export default function Search_Service(props) {
  const history = useHistory()
  const { t } = useTranslation();
  const permi = localStorage.getItem('permi')
  const [permi_t, setPermi_t] = useState(false)

  const { permissionAllService } = useSelector(({ Distar }) => Distar)

useEffect(() => {
  if(permissionAllService){
    if(permissionAllService.service){
      setPermi_t(permissionAllService.service.write)
    }
  }
  return () => {

  }
}, [permissionAllService])

  const onFinish = values => {
    props.search_data()
  }

  return (
    <div>
      <Form
        name="Search_Service_data_info"
        initialValues={{
          remember: false,
        }}
        onFinish={onFinish}
      >
        <Row>
          <Col sm={12} md={6} lg={6} xl={4}>
            <Form.Item
              name="search"
              rules={[
                {
                  required: false,
                  message: "Please input name,department",
                },
              ]}
            >
              <Input
                placeholder={`${t("Service number")} , ${t("Car license")}`}
                style={{ marginBottom: "15px" }}
                onChange={e => props.setSearch_service(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={6} lg={4} xl={4}>
            <Button
              type="primary"
              className="button-search-employee"
              htmlType="submit"
            >
              {t("Search")}
            </Button>
            {
              permi_t === true && (
                <Button
                className="button-search-New-Employee"
                onClick={() => history.push("/new-service")}
                icon={
                  <VscTools
                    style={{
                      fontSize: "15px",
                      marginRight: "10px",
                      marginBottom: "5px",
                    }}
                  />
                }
              >
                {t("New service")}
              </Button>
              )
            }

          </Col>
        </Row>
      </Form>

      <Filter_service 
      filter_service={props.filter_service}
      setFilter_service={props.setFilter_service}
      filter_Role={props.filter_Role}
      setFilter_Role={props.setFilter_Role}
      />
<br />
    </div>
  )
}
