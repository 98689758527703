import React, { useState, useEffect } from "react"
import Search_device from "./Search_device"
import * as api from "../../../service/Api_Edit_Contract"
import { Pagination, Table, Modal,Tooltip  } from "antd"
import { AiOutlineEye } from "react-icons/ai"
import axios from "axios"
import { FcSearch, FcDataSheet } from "react-icons/fc"
import Overview_device from "./Overview_device"
import { useHistory } from "react-router-dom";
import helpers from '../../Function_Redirect/Redirect_login'
import { useTranslation } from "react-i18next";

export default function Device(props) {
  const token = localStorage.getItem("token")
  const { t } = useTranslation();
  const [data_search_device, setData_search_device] = useState(null)
  const [visible, setVisible] = useState(false)
  const history = useHistory()
  const [data_device, setData_device] = useState([])
  const [detail_device, setDetail_device] = useState({})
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    search_data()
    return () => {}
  }, [props.con_id])

  function search_data() {
    setLoading(true)
    if (data_search_device) {
      const list_device_data = api.LIST_DEVICE_DATA(
        token,
        props.con_id,
        1,
        page_limit.page_Size,
        data_search_device
      )
      axios(list_device_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setData_device(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }
  
          // console.log(error)
        })
    } else {
      const list_device_no_data = api.LIST_DEVICE_NO_DATA(
        token,
        props.con_id,
        1,
        page_limit.page_Size,
        props.con_id
      )
      axios(list_device_no_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setData_device(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if(error){
            if(error.response){
              if(error.response.status === 401){
                helpers.re_login()
                history.push('/login')          
                }
            }
          }
  
        })
    }
  }

  function changePagination(page, pageSize) {
    setLoading(true)
    if (data_search_device) {
      const list_device_data = api.LIST_DEVICE_DATA(
        token,
        props.con_id,
        page,
        pageSize,
        data_search_device,
        props.con_id
      )
      axios(list_device_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setData_device(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          // console.log(error)
        })
    } else {
      const list_device_no_data = api.LIST_DEVICE_NO_DATA(
        token,
        props.con_id,
        page,
        pageSize,
        props.con_id
      )
      axios(list_device_no_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setData_device(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          // console.log(error)
        })
    }
  }

  const columns = [
    {
      title: t("Group"),
      dataIndex: "pro_group_name",
      key: "pro_group_name",
      render: text => checkData(text),
    },
    {
      title: t("Model name"),
      dataIndex: "pro_model_name",
      key: "pro_model_name",
      render: text => checkData(text),
    },
    {
      title: t("Product name"),
      dataIndex: "pro_name",
      key: "pro_name",
      render: text => checkData(text),
    },
    {
      title: t("Asset number"),
      dataIndex: "sto_asset_number",
      key: "sto_asset_number",
      render: text => checkData(text),
    },
    {
      title: "Stock remark",
      dataIndex: "sto_remark",
      key: "sto_remark",
      render: text => <p>{text}</p>,
    },
    {
      title: "Product remark",
      dataIndex: "pro_remark",
      key: "pro_remark",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Serial number"),
      dataIndex: "sto_serial_number",
      key: "sto_serial_number",
      render: text => checkData(text),
    },
    {
      title: "",
      render: text => (
        <Tooltip title={t("View device")} >
        <a>
        <FcSearch
          onClick={() => {
            setVisible(true)
            setDetail_device(text)
          }}
          size={20}
        />
      </a>
      </Tooltip>
      ),
    },
  ]

  function cutTimeZone(data) {
    if (data) {
      const currentDate = new Date(data).toLocaleDateString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
      return currentDate
    } else {
      return "N/A"
    }
  }

  function checkData(data) {
    if (data) {
      return data
    } else {
      return "N/A"
    }
  }

  return (
    <div>
      <Search_device
        search_data={search_data}
        setData_search_device={setData_search_device}
      />

      <Table
      loading={loading}
        columns={columns}
        dataSource={data_device}
        pagination={false}
        rowKey="con_id"
        scroll={{ x: 800 }}
      />

      <Pagination
        current={page_limit.page}
        total={page_limit.limit}
        onChange={changePagination}
        style={{ marginTop: "20px", float: "right" }}
      />
      <p style={{ fontSize:'15px' , marginTop:'20px'}}> <FcDataSheet size={20} style={{ marginBottom:'5px' }} /> {t("total data")} : {page_limit.limit}</p>

      <Modal
        title={false}
        centered
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={() => {
          setVisible(false)
          setDetail_device({})
        }}
        footer={false}
        height={500}
        width={1000}
      >
        <Overview_device detail_device={detail_device} />
      </Modal>
    </div>
  )
}
