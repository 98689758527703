import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Container, Card, CardBody } from "reactstrap"
import { Tabs } from "antd"
import axios from "axios"
import * as api from "../../service/Api_Profile_Menu"
import { useHistory } from "react-router-dom"
import helpers from "../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"
import Detail_Profile from "../components/Profile/Detail_Profile"
import Edit_User from './Edit_User'

import {useDispatch , useSelector } from "react-redux"
import { ADD_NAME_USER , ADD_IMAGE_AVATAR } from '../../store/Distar/actionTypes';
import ChangePassword from "../components/Profile/ChangePassword"

const { TabPane } = Tabs;

const Profile_Operator = props => {
  const { t } = useTranslation()
  const token = localStorage.getItem("token")
  const history = useHistory()
  const [data_user, setData_user] = useState(null)
  const [img, setImg] = useState(null)
  const [detailUser, setDetailUser] = useState([])
  const [currentTab, setCurrentTab] = useState("1")
  const dispatch = useDispatch()
  const action = (type,payload)=>dispatch({type, payload})
  const { privateID } = useSelector(({ Distar }) => Distar)

  useEffect(() => {
    if(privateID !== null){
      checkDetailOperator()
    }
    return () => {}
  }, [privateID])

  function checkDetailOperator() {
    const get_user_dealer = api.CHECK_USER_OPERATOR(token, privateID)
    axios(get_user_dealer)
    .then( res => {
      action(ADD_NAME_USER, res.data.data[0].ope_firstname)
      action(ADD_IMAGE_AVATAR, res.data.data[0].ope_avatar)
      get_image(res.data.data[0].ope_avatar)
      setData_user(res.data.data[0])
      setDetailUser([
        {
          headerDetail: t("firstname"),
          data: check_data(res.data.data[0].ope_firstname),
        },
        {
          headerDetail: t("lastname"),
          data: check_data(res.data.data[0].ope_lastname),
        },
        {
          headerDetail: t("nickname"),
          data: check_data(res.data.data[0].ope_nickname),
        },
        {
          headerDetail: t("email"),
          data: check_data(res.data.data[0].ope_email),
        },
        {
          headerDetail: t("code"),
          data: check_data(res.data.data[0].ope_code),
        },
        {
          headerDetail: t("phone"),
          data: check_data(res.data.data[0].ope_mobile),
        },
        {
          headerDetail: t("department"),
          data: check_data(res.data.data[0].dep_name),
        },
        {
          headerDetail: t("position"),
          data: check_data(res.data.data[0].pos_name),
        },
      ])
    })
    .catch( error => {
      if (error) {
        if (error.response) {
          if (error.response.status === 401) {
            helpers.re_login()
            history.push("/login")
          }
        }
      }
    })
  }

  async function get_image(path_image) {
    const get_data_image = api.GET_IMAGE(token, path_image)
    await axios(get_data_image)
      .then(res => {
        const blob = new Blob([res.data], {
          type: "image/jpeg",
        })
        var objectURL = URL.createObjectURL(blob)
        setImg(objectURL)
      })
      .catch(error => {
        // console.log(error.response);
      })
  }

  function check_data(data) {
    if (data === null) {
      return "N/A"
    } else {
      return data
    }
  }

  return (
    <React.Fragment>
    <MetaTags>
      <title> {t("Profile")} | Distar</title>
    </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}> {t("Profile")} </h3>

          {data_user !== null && (
            <Card>
              <CardBody>
                <Tabs
                    defaultActiveKey="1"
                    onTabClick={e => {
                      setCurrentTab(e)
                    }}
                    activeKey={currentTab}
                >
                  <TabPane tab={t("Overview")} key="1">
                    <Detail_Profile
                      imageProfile={img}
                      detailUser={detailUser}
                      sizeImage={120}
                      nameHeader={t("Profile")}
                    />
                  </TabPane>
                  <TabPane tab={t("Edit")} key="2">
                  <Edit_User
                    data_user={data_user}
                    user_id={privateID}
                    check_detail_user={checkDetailOperator}
                  />
                  </TabPane>
                  <TabPane tab={t("change password")} key="3">
                    <ChangePassword setCurrentTab={setCurrentTab} />
                  </TabPane>
                </Tabs>
              </CardBody>
            </Card>
          )}

        </Container>
      </div>
    </React.Fragment>
  )
}
export default Profile_Operator;