import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { Form, Input, Button, Modal, notification } from "antd"
import Form_Edit_data from "./Form_Edit_data"
import axios from "axios"
import * as api from "../../../service/Api_New_Customer"
import { QuestionCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

export default function Edit_Data(props) {
  const token = localStorage.getItem("token")
  const [branch, setBranch] = useState({})
  const [location_select, setLocation_select] = useState({})
  const [postcode_, setPostcode_] = useState(null)
  const [_list_location, set_list_location] = useState([])

  useEffect(() => {
    function get_customer_detail() {
      const list_detail = api.SEARCH_CUSTOMER_DETAIL(token, props.branch_id)
      axios(list_detail)
        .then(res => {
          setBranch(res.data.data[0])
        })
        .catch(error => {
          console.log(error)
        })
    }

    get_customer_detail()
    return () => {}
  }, [props.branch_id])
  
  useEffect(() => {
    function check_postcode_info() {
      if(branch.zip_code){
        if(branch.zip_code.length === 0){
          setLocation_select({})
        } else if(branch.zip_code.length >= 4 ){
          const check_postcode_ = api.CHECK_POSTCODE(branch.zip_code)
          axios(check_postcode_)
          .then( res => {
            let data = res.data.message
            data.map( data => {
              if(data.zip_id === branch.zip_id){
                setLocation_select(data)
              }
            })
          })
          .catch( error => {
            console.log(error);
          })
        }
      } else {
        setLocation_select({})
      }
    }
    check_postcode_info()
    return () => {
      
    }
  }, [branch.zip_code])

  function edit_postcode(postcode) {
    setPostcode_(postcode)
    setBranch({
      ...branch,
      zip_code : postcode
    })
    if(postcode){
      if(postcode.length === 0){
        setPostcode_(null)
        set_list_location([])
        setLocation_select({})
      } else if(postcode.length >= 4 ){
        const _postcode_ = api.CHECK_POSTCODE(postcode)
        axios(_postcode_)
        .then( res => {
          set_list_location(res.data.message)
        })
        .catch( error => {
          console.log(error);
        })
      }
    } else {
      setPostcode_(null)
      set_list_location([])
      setLocation_select({})
      if(props.branch_edit.zip_id){
        props.setBranch_edit({
          ...props.branch_edit,
          zip_id: null,
      })
      }
    }
  }

  const onFinish = values => {
    let data_last_edit = {}
    for (const [key, value] of Object.entries(props.branch_edit)) {
      if(!value){
      } else {
        data_last_edit[key] = value
      }
  }
if(Object.keys(data_last_edit)[0] === undefined){
  openNotificationWithIcon('warning','No data edit')
} else {
  confirm({
    title: 'Edit branch',
    icon: <QuestionCircleOutlined style={{ color:'#1890ff' }} />,
    okText: 'Edit',
    centered: true,
    onOk() {
      data_last_edit["cus_branch_id"] = branch.cus_branch_id
      const edit_ = api.EDIT_BRANCH(token, branch.cus_branch_id, data_last_edit)
      axios(edit_)
      .then( res => {
        openNotificationWithIcon('success','Edit success')
        props.search_customer_created()
        props.cancelForm()
      })
      .catch( error => {
        console.log(error.response);
      })
    },
    onCancel() {
     
    },
  });
}
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    });
  };

  return (
    <div>
      <Form
        name="edit_data"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Form_Edit_data 
          branch={branch} 
          setBranch={setBranch} 
          branch_edit={props.branch_edit}
          setBranch_edit={props.setBranch_edit}
          location_select={location_select}
          setPostcode_={setPostcode_}
          postcode_={postcode_}
          edit_postcode={edit_postcode}
          _list_location={_list_location}
          setLocation_select={setLocation_select}
          set_list_location={set_list_location}
        />
        <hr />

        <Row>
        <Col sm={12} md={6} lg={6} xl={6}>
        <Button onClick={() => props.cancelForm()} style={{ width:'100%' }} className="mt-4">
        Cancel
      </Button>
        </Col>
        <Col sm={12} md={6} lg={6} xl={6}>
        <Button type="primary" htmlType="submit" style={{ width:'100%' }} className="mt-4">
        Edit
      </Button>
        </Col>
        </Row>

      </Form>
    </div>
  )
}
