import React from "react"
import { Row, Col } from "reactstrap"
import Render_Image from '../../New_Customer/Devices/Render_Image'
import { useTranslation } from "react-i18next";

export default function Detail_device(props) {
  const { t } = useTranslation();
  const text_header = {
    fontWeight: "bold",
    fontSize: "15px",
  }

  function cutTimeZone(date_T) {
    if(date_T){
        var date = new Date(date_T);
        var month = pad2(date.getMonth()+1);
        var day = pad2(date.getDate());
        var year= date.getFullYear();

        var formattedDate =  day+"/"+month+"/"+year;
        return formattedDate
    } else {
        return 'N/A'
    }
}
function pad2(n) {
    return (n < 10 ? '0' : '') + n;
  }

  function checkData(data) {
    if (data) {
      return data
    } else {
      return "N/A"
    }
  }

  const data_map = [
    {
      header1: t("Group name"),
      dataContract1: checkData(props.detail_device.pro_group_name),
      header2: t("Model name"),
      dataContract2: checkData(props.detail_device.pro_model_name),
    },
    {
      header1: t("Product code"),
      dataContract1: checkData(props.detail_device.pro_code),
      header2: t("Product model code"),
      dataContract2: checkData(props.detail_device.pro_model_code),
    },{
      header1: t("Product model factory"),
      dataContract1: checkData(props.detail_device.pro_model_factory),
      header2: t("Product model protocol"),
      dataContract2: checkData(props.detail_device.pro_model_protocol),
    },{
      header1: t("Product number"),
      dataContract1: checkData(props.detail_device.pro_no),
      header2: t("Product remark"),
      dataContract2: checkData(props.detail_device.pro_remark),
    },{
      header1: t("Product model remark"),
      dataContract1: checkData(props.detail_device.pro_model_remark),
      header2: t("Product sub serial number"),
      dataContract2: checkData(props.detail_device.pro_sub_serial_number),
    },

    {
      header1: t("Car license"),
      dataContract1: checkData(props.detail_device.car_license_number),
      header2: t("Car name"),
      dataContract2: checkData(props.detail_device.car_name),
    },
    {
      header1: t("Product model"),
      dataContract1: checkData(props.detail_device.pro_model_name),
      header2: t("Stock asset"),
      dataContract2: checkData(props.detail_device.sto_asset_number),
    },
    {
      header1: t("Stock install"),
      dataContract1: cutTimeZone(props.detail_device.sto_date_install),
      header2: t("Stock date in"),
      dataContract2: cutTimeZone(props.detail_device.sto_date_in),
    },
    {
      header1: t("Stock date out"),
      dataContract1: cutTimeZone(props.detail_device.sto_date_out),
      header2: t("Serial number"),
      dataContract2: checkData(props.detail_device.pro_serial_number),
    },
  ]

  return (
    <div style={{ height:"600px" , overflowY :'scroll', overflowX : 'hidden' }}>
      <p
        style={{
          textAlign: "center",
          fontWeight: "bold",
          marginTop: "10px",
          fontSize: "15px",
        }}
      >
        {t("Device information")}
      </p>
      <hr />

      <Render_Image 
      path_image={props.detail_device.pro_model_img} 
      size={200} 
    />
<br />
      {data_map.map((data, index) => {
        return (
          <Row key={index}>
            <Col sm={6} md={2} lg={2} xl={2}>
              <p style={text_header}>{data.header1}</p>
            </Col>
            <Col sm={6} md={4} lg={4} xl={4}>
              <p style={{ fontSize: "15px" }}>
                {" "}
                <span className="tet">
                  :
                </span> <span className="tetTwo">-</span> {data.dataContract1}
              </p>
            </Col>
            <Col sm={6} md={2} lg={2} xl={2}>
              <p style={text_header}>{data.header2}</p>
            </Col>
            <Col sm={6} md={4} lg={4} xl={4}>
              <p style={{ fontSize: "15px" }}>
                {" "}
                <span className="tet">
                  :
                </span> <span className="tetTwo">-</span> {data.dataContract2}
              </p>
            </Col>
          </Row>
        )
      })}
    </div>
  )
}
