import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Container, Card, CardBody } from "reactstrap"
import Search_data from "./Search_data"
import { Pagination } from "antd"
import * as api from "../../service/Api_Dealer"
import Table_Dealer from "./Table_Dealer"
import axios from "axios"
import { FcDataSheet } from "react-icons/fc"
import no_permi from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png"
import { useHistory } from "react-router-dom"
import helpers from "../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

export default function Contract(props) {
  const { t } = useTranslation()
  const [search_dealer, setSearch_dealer] = useState(null)
  const history = useHistory()
  const token = localStorage.getItem("token")
  const [data_list, setData_list] = useState([])
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })
  const [loading, setLoading] = useState(false)
  const { permissionAllService } = useSelector(({ Distar }) => Distar)
  const permi = localStorage.getItem("permi")
  const [permi_t, setPermi_t] = useState(false)

  useEffect(() => {
    if (permissionAllService) {
      if (permissionAllService.dealer) {
        setPermi_t(permissionAllService.dealer.read)
      }
    }
    return () => {}
  }, [permissionAllService])

  useEffect(() => {
    search_data()
    return () => {
      setData_list([])
    }
  }, [])

  function search_data() {
    setLoading(true)
    if (search_dealer) {
      const search_dealer_data = api.LIST_DEALER_DATA(
        token,
        1,
        page_limit.page_Size,
        search_dealer
      )
      axios(search_dealer_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else {
      const search_dealer_no_data = api.LIST_DEALER_NO_DATA(
        token,
        1,
        page_limit.page_Size
      )
      axios(search_dealer_no_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })

          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  function changePagination(page, pageSize) {
    setLoading(true)
    if (search_dealer) {
      const search_dealer_data = api.LIST_DEALER_DATA(
        token,
        page,
        pageSize,
        search_dealer
      )
      axios(search_dealer_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else {
      const search_dealer_no_data = api.LIST_DEALER_NO_DATA(
        token,
        page,
        pageSize
      )
      axios(search_dealer_no_data)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Dealer")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}> {t("Dealer")} </h3>
          {permi_t === true && (
            <Card>
              <CardBody>
                <Search_data
                  search_data={search_data}
                  setSearch_dealer={setSearch_dealer}
                />

                <Table_Dealer data_list={data_list} loading={loading} />

                <div>
                  <Pagination
                    current={page_limit.page}
                    total={page_limit.limit}
                    onChange={changePagination}
                    style={{ marginTop: "20px", float: "right" }}
                  />
                </div>
                <p style={{ fontSize: "15px", marginTop: "20px" }}>
                  {" "}
                  <FcDataSheet size={20} style={{ marginBottom: "5px" }} />{" "}
                  {t("total data")} : {page_limit.limit}
                </p>
              </CardBody>
            </Card>
          )}
          {permi_t === false && (
            <div>
              <img
                src={no_permi}
                alt="no permission"
                style={{ display: "block", margin: "50px auto" }}
              />
              <p style={{ textAlign: "center", fontSize: "20px" }}>{t("no permission read module")}</p>
              <p style={{ textAlign:'center', fontSize:'20px' }}> {t("please contract administrator")} </p>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
