import React, { useEffect, useState } from "react"
import { Form, Input, Select } from "antd"
import { Row, Col } from "reactstrap"
import * as api from "../../../../../service/Api_New_Customer"
import axios from "axios"
import Map_location from "./Map_location"
import { useTranslation } from "react-i18next"

const { Option } = Select
const { TextArea } = Input

export default function Form_Data(props) {
  const token = localStorage.getItem("token")
  const { t } = useTranslation()
  const [position_, setPosition_] = useState([])
  const style_req = {
    color: "red",
  }

  useEffect(() => {
    function check_position_list() {
      const list_position = api.CHECK_POSITION(token)
      axios(list_position)
        .then(res => {
          setPosition_(res.data.data)
        })
        .catch(error => {
          console.log(error)
        })
    }

    check_position_list()
    return () => {}
  }, [])

  function getFind(obj) {
    if (isNaN(obj)) {
      props.setError_Branch(t("branch code is number only"))
    } else {
      props.setError_Branch(null)
      props.setBranch({
        ...props.branch,
        cus_branch_code: obj,
      })
    }
  }

  useEffect(() => {
    render_select_postcode()
    return () => {}
  }, [props.postcode_select])

  function render_select_postcode() {
    return props.postcode_select.map(data => {
      return (
        <div
          key={data.zip_id}
          style={{ borderBottom: "1px solid #f0f0f0", padding: "10px" }}
        >
          <a>
            <p
              style={{ color: "#1890ff" }}
              onClick={() => {
                props.setBranch({
                  ...props.branch,
                  zip_id: data.zip_id,
                })
                props.setPostcode_select([])
                props.setLocation_select({
                  ...props.location_select,
                  dis_name: data.dis_name,
                  pro_name: data.pro_name,
                  subdis_name: data.subdis_name,
                  zip_code: data.zip_code,
                })
              }}
            >
              {data.subdis_name} - {data.dis_name} - {data.pro_name} -{" "}
              {data.zip_code}
            </p>
          </a>
        </div>
      )
    })
  }
  useEffect(() => {
    render_location_select()
    render_location_address()
    return () => {}
  }, [props.location_select])

  useEffect(() => {
    render_select_position()
    return () => {}
  }, [position_])

  function render_select_position() {
    return position_.map(data => {
      return (
        <Option key={data.pos_id} value={data.pos_id}>
          {data.pos_name}
        </Option>
      )
    })
  }

  function onChange_position(value) {
    props.setBranch({
      ...props.branch,
      cus_branch_contact_pos: value,
    })
  }

  function render_location_select() {
    if (
      props.location_select.dis_name ||
      props.location_select.pro_name ||
      props.location_select.subdis_name ||
      props.location_select.zip_code
    ) {
      return (
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <p className="text-bold">
              {t("sub-district")} <span style={style_req}>*</span>
            </p>
            <Input placeholder={props.location_select.subdis_name} disabled />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <p className="text-bold">
              {t("district")} <span style={style_req}>*</span>
            </p>
            <Input placeholder={props.location_select.dis_name} disabled />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <p className="text-bold mt-3">
              {t("province")} <span style={style_req}>*</span>
            </p>
            <Input placeholder={props.location_select.pro_name} disabled />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <p className="text-bold mt-3">
              {t("postcode")} <span style={style_req}>*</span>
            </p>
            <Input placeholder={props.location_select.zip_code} disabled />
          </Col>
        </Row>
      )
    } else {
      return null
    }
  }

  function render_location_address() {
    if (
      props.location_select.dis_name ||
      props.location_select.pro_name ||
      props.location_select.subdis_name ||
      props.location_select.zip_code
    ) {
      return (
        <Row>
          <Col>
            <p className="text-bold  mt-3">
              {t("address")} <span style={style_req}>*</span>
            </p>
            <Form.Item
              name="Branch_address"
              rules={[
                {
                  required: true,
                  message: t("Please input address"),
                },
              ]}
            >
              <TextArea
                placeholder={t("address")}
                onChange={e =>
                  props.setBranch({
                    ...props.branch,
                    cus_branch_address: e.target.value,
                  })
                }
              />
            </Form.Item>
          </Col>
        </Row>
      )
    } else {
      return null
    }
  }

  return (
    <>
      <Row>
        <Col sm={12} md={3} lg={3} xl={3}>
          <p className="text-bold">
          {t("branch code")} <span style={style_req}>*</span>
          </p>
          <Form.Item
            name="Branch_code"
            rules={[
              {
                required: true,
                message: `${t('please input')} ${t('branch code')}`,
              },
            ]}
          >
            <Input
              maxLength={5}
              // maxLength={8}
              placeholder={t('branch code')}
              onChange={e => getFind(e.target.value)}
            />
          </Form.Item>
          {props.error_Branch && <p style={style_req}>{props.error_Branch}</p>}
        </Col>

        <Col sm={12} md={9} lg={9} xl={9}>
          <p className="text-bold">
          {t("branch name")} <span style={style_req}>*</span>
          </p>
          <Form.Item
            name="Branch_name"
            rules={[
              {
                required: true,
                message: `${t('please input')} ${t('branch name')}`,
              },
            ]}
          >
            <Input
              placeholder={t('branch name')}
              onChange={e =>
                props.setBranch({
                  ...props.branch,
                  cus_branch_name: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col>
          <p className="text-bold">
           {t('postcode')} <span style={style_req}>*</span>
          </p>
          <Form.Item
            name="Branch_postcode"
            rules={[
              {
                required: true,
                message: `${t('please input')} ${t('postcode')}`,
              },
            ]}
          >
            <Input
              type="number"
              placeholder={t('postcode')}
              onChange={e => props.check_postcode(e.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>

      {render_select_postcode()}
      {render_location_select()}
      {render_location_address()}

      <Row>
        <Col sm={12} md={6} lg={6} xl={6}>
          <p className="text-bold">
          {t('phone')} <span style={style_req}>*</span>
          </p>
          <Form.Item
            name="Branch_phone_number"
            rules={[
              {
                required: true,
                message: `${t('please input')} ${t('phone')}`,
              },
            ]}
          >
            <Input
              type="number"
              placeholder={t('phone')}
              onChange={e =>
                props.setBranch({
                  ...props.branch,
                  cus_branch_phone: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
        <Col sm={12} md={6} lg={6} xl={6}>
        <p className="text-bold">
        {t('mobile')} <span style={style_req}>*</span>
        </p>
        <Form.Item
          name="Branch_mobile_number"
          rules={[
            {
              required: true,
              message:  `${t('please input')} ${t('mobile')}`,
            },
          ]}
        >
          <Input
            type="number"
            placeholder={t('mobile')}
            onChange={e =>
              props.setBranch({
                ...props.branch,
                cus_branch_mobile: e.target.value,
              })
            }
          />
        </Form.Item>
      </Col>
        
      </Row>

      <Row>
      <Col sm={12} md={6} lg={6} xl={6}>
      <p className="text-bold">
      {t('fax')} <span style={style_req}>*</span>
      </p>
      <Form.Item
        name="Branch_fax_number"
        rules={[
          {
            required: true,
            message: `${t('please input')} ${t('fax')}`,
          },
        ]}
      >
        <Input
          placeholder={t('fax')}
          onChange={e =>
            props.setBranch({
              ...props.branch,
              cus_branch_fax: e.target.value,
            })
          }
        />
      </Form.Item>
    </Col>
        <Col sm={12} md={6} lg={6} xl={6}>
          <p className="text-bold">
          {t('email')} <span style={style_req}>*</span>
          </p>
          <Form.Item
            name="Branch_email_number"
            rules={[
              {
                required: true,
                message: `${t('please input')} ${t('email')}`,
              },
            ]}
          >
            <Input
              type="email"
              placeholder={t('email')}
              onChange={e =>
                props.setBranch({
                  ...props.branch,
                  cus_branch_email: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col sm={12} md={6} lg={6} xl={6}>
          <p className="text-bold">
            {t('contact firstname')} <span style={style_req}>*</span>
          </p>
          <Form.Item
            name="Branch_contact_firstname"
            rules={[
              {
                required: true,
                message: `${t('please input')} ${t('contact firstname')}`,
              },
            ]}
          >
            <Input
              placeholder={t('contact firstname')}
              onChange={e =>
                props.setBranch({
                  ...props.branch,
                  cus_branch_contact_firstname: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
        <Col sm={12} md={6} lg={6} xl={6}>
          <p className="text-bold">
            Contact lastname <span style={style_req}>*</span>
          </p>
          <Form.Item
            name="Branch_contact_lastname"
            rules={[
              {
                required: true,
                message: "Please input contact lastname",
              },
            ]}
          >
            <Input
              placeholder="contact lastname"
              onChange={e =>
                props.setBranch({
                  ...props.branch,
                  cus_branch_contact_lastname: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col sm={12} md={6} lg={6} xl={6}>
          <p className="text-bold">
            Contact position <span style={style_req}>*</span>
          </p>
          <Form.Item
            name="Branch_contact_position"
            rules={[
              {
                required: true,
                message: "Please input contact position",
              },
            ]}
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select a position"
              optionFilterProp="children"
              onChange={onChange_position}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {render_select_position()}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col sm={12} md={6} lg={6} xl={6}>
          <p className="text-bold">
            Contact mobile number <span style={style_req}>*</span>
          </p>
          <Form.Item
            name="Branch_contact_mobile_number"
            rules={[
              {
                required: true,
                message: "Please input contact mobile number",
              },
            ]}
          >
            <Input
              type="number"
              placeholder="contact mobile number"
              onChange={e =>
                props.setBranch({
                  ...props.branch,
                  cus_branch_contact_mobile: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
        <Col sm={12} md={6} lg={6} xl={6}>
          <p className="text-bold">
            Contact email <span style={style_req}>*</span>
          </p>
          <Form.Item
            name="Branch_contact_email"
            rules={[
              {
                required: true,
                message: "Please input contact email",
              },
            ]}
          >
            <Input
              type="email"
              placeholder="contact email"
              onChange={e =>
                props.setBranch({
                  ...props.branch,
                  cus_branch_contact_email: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <p className="text-bold">
        Search location <span style={style_req}>*</span>
      </p>
      <p style={style_req}>* search or click on map to select location</p>
      <Map_location setBranch={props.setBranch} branch={props.branch} />
      <br />
      <Row>
        <Col sm={12} md={6} lg={6} xl={6}>
          <p className="text-bold">
            Latitude <span style={style_req}>*</span>
          </p>
          <p style={style_req}>* search or click on map to select location</p>
          <Form.Item
            name="Branch_location_latitude"
            rules={[
              {
                required: false,
                message: "Please input latitude",
              },
            ]}
          >
            <Input
              disabled
              value={props.branch.cus_branch_lat}
              placeholder={props.branch.cus_branch_lat}
            />
          </Form.Item>
        </Col>
        <Col sm={12} md={6} lg={6} xl={6}>
          <p className="text-bold">
            Longitude <span style={style_req}>*</span>
          </p>
          <p style={style_req}>* search or click on map to select location</p>
          <Form.Item
            name="Branch_location_longitude"
            rules={[
              {
                required: false,
                message: "Please input longitude",
              },
            ]}
          >
            <Input
              disabled
              value={props.branch.cus_branch_long}
              placeholder={props.branch.cus_branch_long}
            />
          </Form.Item>
        </Col>
      </Row>
      <br />
    </>
  )
}
