import React, { useEffect, useState } from "react"
import { Row, Col } from "reactstrap"
import { Form, Button, Modal, notification } from "antd"
import Input_Comp from "./Components_edit/Input_Comp"
import Select_data from "./Components_edit/Select_data"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import * as api from "../../service/Api_Edit_Sub_Product_In_Car"
import Find_Model_InGroup from "./Components_edit/Find_Model_InGroup"
import axios from "axios"
import { useHistory } from "react-router-dom"
import helpers from "../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next";
import Input_Comp_Show_Only from './Components_edit/Input_Comp_Show_Only'

const { confirm } = Modal

export default function Edit_Sub_Product(props) {
  const [form] = Form.useForm()
  const { t } = useTranslation();
  const token = localStorage.getItem("token")
  const [edit_sub_product, setEdit_sub_product] = useState({})
  const [group_id, setGroup_id] = useState(null)
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  useEffect(() => {
    setEdit_sub_product({
      pro_serial_number: props.edit_sub_product_data.pro_serial_number,
      pro_sub_serial_number: props.edit_sub_product_data.pro_sub_serial_number,
      pro_model_id: props.edit_sub_product_data.pro_model_id,
      pro_remark: props.edit_sub_product_data.pro_remark,
      warranty_id: props.edit_sub_product_data.warranty_id,
    })
    onFill()
    return () => {
      setEdit_sub_product({})
    }
  }, [props.edit_sub_product_data])

  const onReset = () => {
    form.resetFields()
  }

  useEffect(() => {
    if (group_id) {
      if (group_id.group_id !== null) {
        form.resetFields(["product_model"])
        setEdit_sub_product({
          ...edit_sub_product,
          pro_model_id: null,
        })
      }
    }
    return () => {}
  }, [group_id])

  const onFill = () => {
    form.setFieldsValue({
      pro_serial_number: props.edit_sub_product_data.pro_serial_number,
      pro_sub_serial_number: props.edit_sub_product_data.pro_sub_serial_number,
      group_id: props.edit_sub_product_data.pro_group_id,
      product_model: props.edit_sub_product_data.pro_model_id,
      warranty_id: props.edit_sub_product_data.warranty_id,
      pro_remark: props.edit_sub_product_data.pro_remark,
    })
  }

  function cancelForm() {
    onReset()
    setEdit_sub_product({})
    props.setVisible_edit(false)
    props.setEdit_sub_product_data({})
  }

  const onFinish = values => {
    return confirm({
      title: t("Confirm edit sub product"),
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: t("Confirm"),
      cancelText: t("Cancel"),
      onOk() {
        setLoading(true)
        const edit_data = api.EDIT_SUB_PRODUCT(
          token,
          props.edit_sub_product_data.pro_id,
          edit_sub_product
        )
        axios(edit_data)
          .then(res => {
            setLoading(false)
            openNotificationWithIcon("success", t("Edit sub product success"))
            props.list_sub_product_data()
            cancelForm()
          })
          .catch(error => {
            setLoading(false)
            if(error){
              if(error.response){
                if (error.response.status === 401) {
                  helpers.re_login()
                  history.push("/login")
                } else {
                  openNotificationWithIcon("error", t("Edit sub product error"))
                }
              }
            }
          })
      },
      onCancel() {},
    })
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  return (
    <div>
      <Form form={form} name="control-hooks" onFinish={onFinish}>
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp_Show_Only
              header={t("Product serial number")}
              placeholder={props.edit_sub_product_data.pro_serial_number}
              headState={setEdit_sub_product}
              nameState={edit_sub_product}
              type="text"
              keyState="pro_serial_number"
              disable={true}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp_Show_Only
              header={t("Product sub serial number")}
              placeholder={props.edit_sub_product_data.pro_sub_serial_number}
              headState={setEdit_sub_product}
              nameState={edit_sub_product}
              type="text"
              keyState="pro_sub_serial_number"
              disable={true}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_data
              data_map={props.list_product_model_all}
              header={t("Product group")}
              placeholder={t("Product group")}
              keyState="group_id"
              headState={setGroup_id}
              nameState={group_id}
              map_value="pro_group_id"
              map_name="pro_group_name"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Find_Model_InGroup
              setGroup_id={setGroup_id}
              group_id={group_id}
              setEdit_sub_product={setEdit_sub_product}
              edit_sub_product={edit_sub_product}
              visible_edit={props.visible_edit}
              edit_sub_product_data={props.edit_sub_product_data}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_data
              data_map={props.list_warranty_all}
              header={t("Warranty")}
              placeholder="warranty"
              keyState="warranty_id"
              headState={setEdit_sub_product}
              nameState={edit_sub_product}
              map_value="warranty_id"
              map_name="warranty_name"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Product remark")}
              placeholder={props.edit_sub_product_data.pro_remark}
              headState={setEdit_sub_product}
              nameState={edit_sub_product}
              type="text"
              keyState="pro_remark"
            />
          </Col>
        </Row>

        <br />
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div className="div_create_new_car">
              <Button
                className="btn_cancel_create_new_car"
                onClick={() => cancelForm()}
              >
                {" "}
                {t("Cancel")}
              </Button>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div className="div_create_new_car">
              <Button
                className="btn_submit_create_new_car"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
              {t("Edit")}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
