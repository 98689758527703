import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import customer_info from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/customer_info.png"
import Card_Detail from "../../New_Service_Has_Stock_Id/components/Card_Detail"
import { useTranslation } from "react-i18next";

export default function Customer(props) {
  const { t } = useTranslation();
  const [customer, setCustomer] = useState({})
  const [customer_new, setCustomer_new] = useState([])
  const [path_image, setPath_image] = useState(null)
  const [title_data, setTitle_data] = useState(null)

  useEffect(() => {
    
    return () => {
      setCustomer_new([])
      setPath_image(null)
      setTitle_data(null)
    }
  }, [])

  
  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return "N/A"
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  function checkData(data) {
    if (data) {
      return data
    } else {
      return "N/A"
    }
  }

  useEffect(() => {
    if (props.data_service) {
      // setCustomer(props.service_overview.customer[0])
      setPath_image(props.data_service.cus_avatar)
      setTitle_data(checkData(props.data_service.cus_name))
      setCustomer_new([
        {
          header1: t("Customer name"),
          dataContract1: checkData(props.data_service.cus_name),
          header2: t("Customer number"),
          dataContract2: checkData(props.data_service.cus_no),
        },
        {
          header1: t("Customer code"),
          dataContract1: checkData(props.data_service.cus_code),
          header2: t("Customer credit"),
          dataContract2: checkData(props.data_service.cus_credit),
        },
        {
          header1: t("Customer TAX ID"),
          dataContract1: checkData(props.data_service.cus_taxid),
          header2: t("Customer phone"),
          dataContract2: checkData(props.data_service.cus_phone),
        },
        {
          header1: t("Customer email"),
          dataContract1: checkData(props.data_service.cus_email),
          header2: t("Customer fax"),
          dataContract2: checkData(props.data_service.cus_fax),
        },
        {
          header1: t("Customer address"),
          dataContract1: checkData(props.data_service.cus_address),
          header2: t("Customer remark"),
          dataContract2: checkData(props.data_service.cus_remark),
        }
      ])
    }
    return () => {

    }
  }, [props.data_service])

  return (
    <div>
    <p className="text-bold"> {t("Customer information")} </p>
      <Card_Detail
        image_header={customer_info}
        alt_image_header="customer_info"
        text_header={t("Customer information")}
        path_image={path_image}
        title_data={title_data}
        title_data_tow={null}
        data_map={customer_new}
        type_avatar="user"
        use_table={false}
        data_table={null}
      />
    </div>
  )
}
