import React, { useState, useEffect } from "react"
import {
  Input,
  Pagination,
  Table,
  Tooltip,
  Modal,
  Button,
  notification,
} from "antd"
import { useTranslation } from "react-i18next"
import * as api from "../../../../service/Api_New_Service"
import axios from "axios"
import Render_Image from "../../../New_Customer/Devices/Render_Image"
import { Row, Col } from "reactstrap"
import { RiDeleteBin6Line } from "react-icons/ri"

import car from "../../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/car.png"
import ambulance from "../../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/ambulance.png"
import vespa from "../../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/vespa.png"
import bus from "../../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/bus.png"
import bicycle from "../../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/bicycle.png"
import van from "../../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/van.png"
import truck from "../../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/truck.png"
import taxi from "../../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/taxi.png"
import pickup from "../../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/pickup.png"
import motorboat from "../../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/motorboat.png"
import motor from "../../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/motor.png"
import no_car from "../../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_car.png"

const { Search } = Input

export default function TableCarSelected({ selectCar, setSelectCar }) {
  const { t } = useTranslation()

  function render_Icon_Car(type_car) {
    switch (type_car) {
      case "car":
        return car
        break
      case "vespa":
        return vespa
        break
      case "van":
        return van
        break
      case "truck":
        return truck
        break
      case "taxi":
        return taxi
        break
      case "bus":
        return bus
        break
      case "pickup":
        return pickup
        break
      case "motorboat":
        return motorboat
        break
      case "motor":
        return motor
        break
      case "bicycle":
        return bicycle
        break
      case "ambulance":
        return ambulance
        break
      default:
        return no_car
        break
    }
  }

  const columns_my_db = [
    {
      title: "",
      dataIndex: "pro_model_img",
      key: "pro_model_img",
      render: text => <Render_Image path_image={text} size={60} />,
    },
    {
      title: t("Product model code"),
      dataIndex: "pro_model_code",
      key: "pro_model_code",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Product model name"),
      dataIndex: "pro_model_name",
      key: "pro_model_name",
      render: text => <p>{text}</p>,
      width: "150px",
    },
    {
      title: t("Product serial number"),
      dataIndex: "pro_serial_number",
      key: "pro_serial_number",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Car license number"),
      dataIndex: "car_license_number",
      key: "car_license_number",
      render: text => <p>{text}</p>,
      width: "150px",
    },
    {
      title: t("Car type"),
      dataIndex: "car_body_type_name",
      key: "car_body_type_name",
      render: text => (
        <Tooltip title={text ? text : t("Invalid car type")}>
          <img
            src={render_Icon_Car(text)}
            alt="icon"
            style={{ width: "50px" }}
          />
        </Tooltip>
      ),
    },
    {
      title: t("Customer name"),
      dataIndex: "cus_name",
      key: "cus_name",
      render: text => <p>{text}</p>,
    },
    {
      render: data => (
        <a>
          <RiDeleteBin6Line
            size="25px"
            style={{ color: "#ff4d4f" }}
            onClick={() => removeCar(data)}
          />
        </a>
      ),
    },
  ]

  function removeCar(data) {
    const dataSelectedCar = [...selectCar]
    var indexID = dataSelectedCar.findIndex(id => id.sto_id === data.sto_id)
    dataSelectedCar.splice(indexID, 1)
    setSelectCar(dataSelectedCar)
  }

  return (
    <div>
      <Table
        rowKey="sto_id"
        columns={columns_my_db}
        dataSource={selectCar}
        scroll={{ x: 800 }}
      />
    </div>
  )
}
