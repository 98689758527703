import React,{ useState, useEffect } from 'react'
import { Progress } from "antd"
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import service_priority from '../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/service_priority.png'

export default function Service_Priority(props) {
  const { t } = useTranslation();
    const [high, setHigh] = useState(0)
    const [low, setLow] = useState(0)
    const [medium, setMedium] = useState(0)
    const [urgent, setUrgent] = useState(0)
    const [all_priority, setAll_priority] = useState(0)
    const [chart_count, setChart_count] = useState({})

    const Header_text = {
        fontSize: "20px",
        marginBottom: "0px",
        color: "#A5A5A5",
      }

      const options = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            labels: {
                usePointStyle: true 
             },
          display: true,
          position: "right"
        },
        elements: {
          arc: {
            borderWidth: 0
          }
        }
      };

    useEffect(() => {
        if(props.service_priority){
            // console.log(props.service_priority);
            setHigh(props.service_priority.high)
            setLow(props.service_priority.low)
            setMedium(props.service_priority.medium)
            setUrgent(props.service_priority.urgent)
            setAll_priority(
                props.service_priority.high+props.service_priority.low+props.service_priority.medium+props.service_priority.urgent
            )
            setChart_count({
                labels: [ t("urgent") , t("high"), t("medium"), t("low")],
                datasets: [
                  {
                    data: [ props.service_priority.urgent , props.service_priority.high, props.service_priority.medium, props.service_priority.low],
                    backgroundColor: ["#ff6961", "#EBAE86","#6891C3", "#76CE86"],
                    hoverBackgroundColor: ["#ff6961", "#EBAE86","#6891C3", "#76CE86"]
                  }
                ]
              })
        }
        return () => {
            setHigh(0)
            setLow(0)
            setMedium(0)
            setUrgent(0)
            setAll_priority(0)
            setChart_count({})
        }
    }, [props.service_priority])

    function render_percent(all_status, status_list) {
        let number = 0
        number = (status_list / all_status) * 100
        return number
      }

    return (
    <div>
        <p style={{ fontSize: "20px", marginBottom: "15px", color: "#495057" }}>
        <img src={service_priority} alt="contract" style={{ width:'25px', height:'25px', marginRight:'20px', marginTop:'-10px' }} />
            {t("service priority")}
        </p>

        <p style={Header_text}> {t("urgent")} <span style={{ float: "right", color: "#495057", fontWeight: "bold" }} > {urgent} </span>{" "} </p>
        <Progress
          percent={render_percent(
            all_priority,
            urgent,
          )}
          showInfo={false}
          status="active"
          strokeColor="#ff6961"
        />

        <p style={Header_text}> {t("high")} <span style={{ float: "right", color: "#495057", fontWeight: "bold" }} > {high} </span>{" "} </p>
        <Progress
          percent={render_percent(
            all_priority,
            high,
          )}
          showInfo={false}
          status="active"
          strokeColor="#EBAE86"
        />

        <p style={Header_text}> {t("medium")} <span style={{ float: "right", color: "#495057", fontWeight: "bold" }} > {medium} </span>{" "} </p>
        <Progress
          percent={render_percent(
            all_priority,
            medium,
          )}
          showInfo={false}
          status="active"
          strokeColor="#6891C3"
        />

        <p style={Header_text}> {t("low")} <span style={{ float: "right", color: "#495057", fontWeight: "bold" }} > {low} </span>{" "} </p>
        <Progress
          percent={render_percent(
            all_priority,
            low,
          )}
          showInfo={false}
          status="active"
          strokeColor="#76CE86"
        />

        <br />   <br />

        <div>
        <Doughnut data={chart_count} options={options} />
        </div>
    </div>
    )
}
