import React from "react"
import { InputNumber, Form, Input } from "antd"
import { useTranslation } from "react-i18next";

export default function Input_Comp({
  placeholder,
  header,
  headState,
  nameState,
  keyState,
  maxLength,
}) {
  const { t } = useTranslation();
  return (
    <div>
      <p className="text-bold">
        {header} <span style={{ color: "red" }}>*</span>
      </p>
      <Form.Item
        name={keyState}
        rules={[
          {
            required: false,
            message: `Please input ${placeholder}`,
          },
        ]}
      >
        <Input
          addonAfter={t("Day")}
          type="number"
          style={{ width: "100%" }}
          maxLength={maxLength}
          placeholder={placeholder}
          onChange={value =>
            headState({
              ...nameState,
              [keyState]: parseInt(value.target.value),
            })
          }
        />
      </Form.Item>
    </div>
  )
}
