import React, { useRef } from "react"
import { useTranslation } from "react-i18next"
import { Button } from "antd"
import { DeleteOutlined } from "@ant-design/icons"

export default function UploadFileComp(props) {
const { t } = useTranslation()
  const fileUpload = useRef(null)

  const handleImageUpload = e => {
    const FileUpload = [...props.task_hast_form]
    const newUpload = [...FileUpload[props.index_step].fields[props.index_field].multi_image, ...e.target.files]
    FileUpload[props.index_step].fields[props.index_field].multi_image = newUpload
    props.setTask_hast_form(FileUpload)
    e.target.value=null
  }

  function removeFile(index) {
    const fileRemove = [...props.task_hast_form]
    fileRemove[props.index_step].fields[props.index_field].multi_image.splice(index, 1)
    props.setTask_hast_form(fileRemove)
  }

    return (
        <div>
        <p className="text-bold">
          {t("upload file")}{" "}
          <span style={{ color: "gray", marginLeft: "10px" }}>
            Word , Excel , PDF
          </span>{" "}
        </p>
        <input
          type="file"
          multiple
          onChange={handleImageUpload}
          ref={fileUpload}
          style={{
            display: "none",
          }}
        />
        <Button onClick={() => fileUpload.current.click()}>
          {t("upload file")}
        </Button>
        {props.task_hast_form[props.index_step].fields[props.index_field].multi_image.map((data, index) => {
          return (
            <p key={index} style={{ marginTop: "15px" }}>
              <a>
                <DeleteOutlined
                  style={{
                    fontSize: "20px",
                    color: "#FF2D2D",
                    marginRight: "10px",
                    marginBottom: "10px",
                  }}
                  onClick={() => removeFile(index)}
                />
              </a>
              {data.name}
            </p>
          )
        })}
      </div>
    )
}
