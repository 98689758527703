var Ip = require('../service/Ip')

export const GET_INFO_SERVICE = (token,ser_id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/service/${ser_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_PRIORITY = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/service/priority`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_STATUS = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/service/status`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_RECEIVED_TYPE = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/service/received_type`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const GENERATE_SERVICE_CODE = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/service/generate-ser-no`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const FIND_DUPLICATE_CODE = (token, data) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/service/check-is-serno-duplicate/${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_CUSTOMER_HAS_STOCK_NO_DATA = (token, cus_id, page, pageSize) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer/customer-has-stock/${cus_id}?page=${page}&limit=${pageSize}&order_by=asc`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_CUSTOMER_HAS_STOCK_DATA = (token, cus_id, page, pageSize, data) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer/customer-has-stock/${cus_id}?page=${page}&limit=${pageSize}&order_by=asc&q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const EDIT_SERVICE = (token, data, ser_id) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/service/${ser_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const DELETE_SERVICE = (token, ser_id) => {
    return {
      method: 'delete',
      url: `${Ip.getIP()}api/v1/service/${ser_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };