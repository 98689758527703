import React from "react"
import { Row } from "reactstrap"
import { Collapse, Card } from "antd"
import Col_Device from "./components/Col_Device"
import contract_info from "../../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/contract_info.png"
import { useTranslation } from "react-i18next";

const { Panel } = Collapse

export default function Contract_Info(props) {
  const { t } = useTranslation();

  const icons_info_header = {
    width: "25px",
    marginRight: "20px",
  }

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day

    return [day, month, year].join("-")
  }

  return (
    <div>
      <Card
        title={
          <p>
            {" "}
            <img
              src={contract_info}
              alt="contract_info"
              style={icons_info_header}
            />{" "}
            {t("Contract")}
          </p>
        }
      >
        {props.new_Contract && (
          <Collapse>
            <Panel header={props.new_Contract.con_name} key="1">
              <Row>
                {props.new_Contract.con_name && (
                  <Col_Device
                    text_header={t("Contract name")}
                    data={props.new_Contract.con_name}
                  />
                )}
                {props.new_Contract.con_code && (
                  <Col_Device
                    text_header={t("Contract code")}
                    data={props.new_Contract.con_code}
                  />
                )}
                {props.new_Contract.con_start_date && (
                  <Col_Device
                    text_header={t("Contract start date")}
                    data={formatDate(props.new_Contract.con_start_date)}
                  />
                )}
                {props.new_Contract.con_end_date && (
                  <Col_Device
                    text_header={t("Contract end date")}
                    data={formatDate(props.new_Contract.con_end_date)}
                  />
                )}
              </Row>
            </Panel>
          </Collapse>
        )}
      </Card>
    </div>
  )
}
