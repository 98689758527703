import React, { useState } from "react"
import Search_data_operator_table from "./Search_data_operator_table"
import { Pagination, Table, Modal, Avatar, Tooltip } from "antd"
import { AiOutlineEye } from "react-icons/ai"
import { useHistory } from "react-router-dom"
import { FiEdit2 } from "react-icons/fi"
import Datail_Operator from "./Datail_Operator"
import { UserOutlined } from "@ant-design/icons"
import { FcSearch, FcSettings, FcDataSheet } from "react-icons/fc"
import Render_Image_Data from "../../Employee/Table/Render_Image_Data"
import { useTranslation } from "react-i18next"

export default function Table_operator(props) {
  const { t } = useTranslation()
  const history = useHistory()
  const [data_Operator, setData_Operator] = useState({})
  const [visible, setVisible] = useState(false)

  const columns = [
    {
      title: "",
      dataIndex: "ope_avatar",
      key: "ope_avatar",
      render: text => <Render_Image_Data path_image={text} />,
    },
    {
      title: t("Operator code"),
      dataIndex: "ope_code",
      key: "ope_code",
      render: text => checkData(text),
    },
    {
      title: t("Operator nickname"),
      dataIndex: "ope_nickname",
      key: "ope_nickname",
      render: text => checkData(text),
    },
    {
      title: t("Mobile"),
      dataIndex: "ope_mobile",
      key: "ope_mobile",
      render: text => checkData(text),
    },
    {
      title: t("Group"),
      dataIndex: "ope_group_name",
      key: "ope_group_name",
      render: text => checkData(text),
    },
    {
      title: t("Department"),
      dataIndex: "dep_name",
      key: "dep_name",
      render: text => checkData(text),
    },
    {
      title: t("Position"),
      dataIndex: "pos_name",
      key: "pos_name",
      render: text => checkData(text),
    },
    {
      title: "",
      render: text => (
        <Tooltip title={t("View operator")}>
          <a>
            <FcSearch
              onClick={() => {
                setVisible(true)
                setData_Operator(text)
              }}
              size={20}
            />
          </a>
        </Tooltip>
      ),
    },
    {
      title: "",
      render: text => (
        <Tooltip title={t("Edit operator")}>
          <a>
            <FcSettings
              size={20}
              onClick={() => history.push(`/operator/${text.ope_id}`)}
            />
          </a>
        </Tooltip>
      ),
    },
  ]

  function check_avatar(avatar) {
    return <Avatar size={50} icon={<UserOutlined />} />
  }

  function checkData(data) {
    if (data) {
      return data
    } else {
      return "N/A"
    }
  }

  return (
    <div>
      <Search_data_operator_table
        filter_operator={props.filter_operator}
        setFilter_operator={props.setFilter_operator}
        search_operator_data={props.search_operator_data}
        setSearch_operator={props.setSearch_operator}
      />

      <Table
        columns={columns}
        loading={props.loading}
        dataSource={props.data_operator_all}
        pagination={false}
        rowKey="ope_id"
        scroll={{ x: 800 }}
      />

      <Pagination
        current={props.page_limit.page}
        total={props.page_limit.limit}
        onChange={props.changePagination}
        style={{ marginTop: "20px", float: "right" }}
      />
      <p style={{ fontSize: "15px", marginTop: "20px" }}>
        {" "}
        <FcDataSheet size={20} style={{ marginBottom: "5px" }} />{" "}
        {t("total data")} : {props.page_limit.limit}
      </p>

      <Modal
        title={false}
        centered
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={() => {
          setVisible(false)
          setData_Operator({})
        }}
        footer={false}
        height={500}
        width={1000}
      >
        <Datail_Operator data_Operator={data_Operator} />
      </Modal>
    </div>
  )
}
