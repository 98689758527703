import React from "react"
import { Row, Col } from "reactstrap"
import { Tag } from 'antd';
import { useTranslation } from "react-i18next";

export default function Old_DB(props) {
  const { t } = useTranslation();

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return null
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  function check_data(data) {
      if(data){
        return data
      } else {
        return null
      }
  }
  
  function check_dealer(saleid,dealer) {
      if(dealer === true){
        return <span>{saleid} <Tag color="success"> {t("Already exists in the database")} </Tag></span>
      } else {
        return <span>{saleid} <Tag color="default"> {t("This Dealer was Not found in the database")} </Tag></span>
      }
  }

  const data = [
    {
        head_data1 : 'lpn',
        value_data1 : check_data(props.data.lpn),
        head_data2 : 'title',
        value_data2 : check_data(props.data.title)
    },
    {
        head_data1 : 'gid',
        value_data1 : check_data(props.data.gid),
        head_data2 : 'grpid',
        value_data2 : check_data(props.data.grpid)
    },
    {
        head_data1 : 'license',
        value_data1 : check_data(props.data.license),
        head_data2 : 'saleid',
        value_data2 : check_dealer(props.data.saleid, props.data.is_dealer_in_db)
    },
    {
        head_data1 : 'uid',
        value_data1 : check_data(props.data.uid),
        head_data2 : 'userid',
        value_data2 : check_data(props.data.userid)
    },
    {
        head_data1 : 'cid',
        value_data1 : check_data(props.data.cid),
        head_data2 : 'vin',
        value_data2 : check_data(props.data.vin)
    },
    {
        head_data1 : 'fueltank',
        value_data1 : check_data(props.data.fueltank),
        head_data2 : 'fuelkm',
        value_data2 : check_data(props.data.fuelkm)
    },
    {
        head_data1 : 'fuelhours',
        value_data1 : check_data(props.data.fuelhours),
        head_data2 : 'remark',
        value_data2 : check_data(props.data.remark)
    },
    {
        head_data1 : 'installtime',
        value_data1 : cutTimeZone(props.data.installtime),
        head_data2 : 'offtime',
        value_data2 : cutTimeZone(props.data.offtime)
    },
    {
        head_data1 : 'btime',
        value_data1 : cutTimeZone(props.data.btime),
        head_data2 : 'ctime',
        value_data2 : cutTimeZone(props.data.ctime)
    },
    {
        head_data1 : 'regtime',
        value_data1 : cutTimeZone(props.data.regtime),
        head_data2 : 'saleduration',
        value_data2 : check_data(props.data.saleduration)
    }
]



  return (
    <div className="scroll-modal-display-mobile-app">
      <p
        style={{
          textAlign: "center",
          fontWeight: "bold",
          marginTop: "10px",
          fontSize: "15px",
        }}
      >
        {t("Car information")}
      </p>

      {
        data.map( (data, index) =>{
            return (
                <Row key={index}>
                    <Col sm={12} md={6} lg={2} xl={2}>
                        <p className="text-bold">{data.head_data1}</p>
                        </Col>
                        <Col sm={12} md={6} lg={4} xl={4}>
                        <p style={{ fontSize: "15px" }}>
                            {" "}
                            <span className="tet">
                            :
                            </span> <span className="tetTwo">-</span> {data.value_data1} {" "}
                        </p>
                        </Col>
                        <Col sm={12} md={6} lg={2} xl={2}>
                        <p className="text-bold">{data.head_data2}</p>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <p style={{ fontSize: "15px" }}>
                        {" "}
                        <span className="tet">
                            :
                        </span> <span className="tetTwo">-</span> {data.value_data2} {" "}
                        </p>
                    </Col>
                </Row>
            )
        })
      }
    </div>
  )
}
