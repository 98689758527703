import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { useHistory } from "react-router-dom"
import { Row, Col, CardBody, Card, Container } from "reactstrap"
import { Modal , notification } from 'antd'
import { Form, Input, Button, Progress } from "antd"
import axios from "axios"
import * as api from "../../service/Api_Verification_Email"
import logo_distar from "../../assets/Distar/logo_login.jpeg"
import { ExclamationCircleOutlined } from "@ant-design/icons"

const { confirm } = Modal

const url_string = window.location.href
const url = new URL(url_string)
const token = url.searchParams.get("token_verification")

export default function ResetPasswordFromToken() {
  const [error, setError] = useState(null)
  const [token_expire, setToken_expire] = useState(false)
  const history = useHistory()
  const [detail_info_email, setDetail_info_email] = useState(null)
  const [pass, setPass] = useState(null)
  const [loading, setLoading] = useState(false)
  const [passStrong, setPassStrong] = useState({
    passwordTet: "",
    passwordColor: "",
    passwordNumber: 0,
  })

  useEffect(() => {
    check_User_Info()
    return () => {

    }
  }, [])

  function check_User_Info() {
    const data = {
      token: token,
    }
    const check_info = api.CHECK_USER_INFO(data)
    axios(check_info)
      .then(res => {
        setToken_expire(false)
        setDetail_info_email(res.data.email)
      })
      .catch(error => {
        setToken_expire(true)
      })
  }

  // console.log(token);

  useEffect(() => {
    CheckPasswordStrength()
    setError(null)
    return () => {}
  }, [pass])

  function CheckPasswordStrength() {
    if (pass) {
      var regex = new Array()
      regex.push("[A-Z]") //For Uppercase Alphabet
      regex.push("[a-z]") //For Lowercase Alphabet
      regex.push("[0-9]") //For Numeric Digits
      regex.push("[$@$!%*#?&]") //For Special Characters
      var passed = 0
      for (var i = 0; i < regex.length; i++) {
        if (new RegExp(regex[i]).test(pass)) {
          passed++
        }
      }
      if (passed > 2 && pass.length > 3) {
        passed++
      }
      var color = ""
      var passwordStrength = ""
      var number = 0
      switch (passed) {
        case 0:
          break
        case 1:
          passwordStrength = "Password is Weak."
          color = "Red"
          number = 25
          break
        case 2:
          passwordStrength = "Password is Good."
          color = "darkorange"
          number = 50
          break
        case 3:
          break
        case 4:
          passwordStrength = "Password is Strong."
          color = "Green"
          number = 75
          break
        case 5:
          passwordStrength = "Very Strong."
          color = "darkgreen"
          number = 100
          break
      }
      setPassStrong({
        passwordTet: passwordStrength,
        passwordColor: color,
        passwordNumber: number,
      })
    }
  }

  const onFinish = value => {
  if(value.password !== value.re_password){
    setError("Password mismatch")
  } else {
    return confirm({
        title: "Confirm reset password",
        icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
        centered: true,
        okText: "Confirm",
        cancelText: "No",
        onOk() {
          setLoading(true)
          let dataReset = {
            token : token,
            new_pass : value.password
          }
          const sendNewPass = api.RESET_NEW_PASSWORD_BY_EMAIL(dataReset)
          axios(sendNewPass)
          .then( res => {
            openNotificationWithIcon("success","Reset password success")
            login(detail_info_email , value.password)
          })
          .catch( error => {
            openNotificationWithIcon("error","Reset password error")
            setLoading(false)
          })
        //  console.log('reset password');
        //  console.log(value);
        //  console.log(detail_info_email);
        },
        onCancel() {},
      })
  }
  }

  function login(mail , password) {
    let valuesData = {
      email: mail,
      password: password
    }
    const reLogin = api.LOGIN(valuesData)
    axios(reLogin)
    .then( res => {
      setLoading(false)
      localStorage.setItem('token',res.data.token)
      localStorage.setItem('role',res.data.role)
      localStorage.setItem('_id',res.data.user_id)
      if(res.data.root !== undefined){
        localStorage.setItem('r',res.data.root)
      }

      history.push("/dashboards");
    })
    .catch( error => {
      setLoading(false)
      openNotificationWithIcon("error","Network error")
    })
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Reset password | Distar</title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft">
                  <Row>
                    <img
                      src={logo_distar}
                      alt="logo"
                      style={{
                        width: "80%",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: "50px",
                        marginTop: "100px",
                      }}
                    />
                  </Row>
                </div>
                <CardBody className="pt-0">
                  {token_expire === false && (
                    <Form
                      name="basic"
                      initialValues={{
                        remember: true,
                      }}
                      onFinish={onFinish}
                    >
                      <p style={{ fontWeight: "bold" }}>
                        Password <span style={{ color: "red" }}>*</span>
                      </p>
                      <Form.Item
                        label=""
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your password!",
                          },
                        ]}
                      >
                        <Input.Password
                          onChange={e => setPass(e.target.value)}
                        />
                      </Form.Item>

                      {passStrong.passwordNumber !== 0 && (
                        <div>
                          <Progress
                            percent={passStrong.passwordNumber}
                            size="small"
                            status="active"
                          />
                          <p
                            style={{
                              color: passStrong.passwordColor,
                              textAlign: "right",
                            }}
                          >
                            {passStrong.passwordTet}
                          </p>
                        </div>
                      )}

                      <p style={{ fontWeight: "bold" }}>
                        Confirm Password <span style={{ color: "red" }}>*</span>
                      </p>
                      <Form.Item
                        label=""
                        name="re_password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your password!",
                          },
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>

                      {error && (
                        <p style={{ textAlign: "center", color: "red" }}>
                          {error}
                        </p>
                      )}
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{ width: "100%" }}
                          loading={loading}
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  )}

                  {
                      token_expire === true && (
                          <p style={{ fontSize:'20px' }}>The access token has expired. Please request a new one or contract administrator.</p>
                      )
                  }
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>Design & Develop by NokSoft</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
