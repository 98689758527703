import React, { useRef, useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { Button, Avatar, Input, Select, Modal, notification, Form } from "antd"
import { UserOutlined, QuestionOutlined } from "@ant-design/icons"
import Input_Comp from "./components/Input_Comp"
import Input_Comp_Email from "./components/Input_Comp_Email"
import Select_no_search from "./components/Select_no_search"
import * as api from "../../../service/Api_Edit_Employee"
import axios from "axios"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import helpers from "../../Function_Redirect/Redirect_login"
import { useSelector } from "react-redux"

import "./Profile_Edit.css"

const { Option } = Select
const { confirm } = Modal

export default function Profile(props) {
  const token = localStorage.getItem("token")
  const [form] = Form.useForm()
  const imageUploader = useRef(null)
  const { t } = useTranslation()
  const [edit_image_employee, setEdit_image_employee] = useState(null)
  const [select_image_upload, setSelect_image_upload] = useState(null)
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [edit, setEdit] = useState(true)
  const history = useHistory()
  const { isAdminRoot } = useSelector(({ Distar }) => Distar)
  const permi = localStorage.getItem("permi")
  const [permi_t, setPermi_t] = useState(false)

  useEffect(() => {
    if (permi) {
      if (JSON.parse(permi).employee) {
        setPermi_t(
          JSON.parse(permi).employee.edit
            ? JSON.parse(permi).employee.edit
            : false
        )
      }
    }
    return () => {
      // setPermi_t(false)
    }
  }, [permi])

  useEffect(() => {
    return () => {
      setEdit_image_employee(null)
      setSelect_image_upload(null)
    }
  }, [])

  const handleImageUpload = e => {
    if (e.target.files[0] !== undefined) {
      if (e.target.files[0].type !== "image/jpeg") {
        openNotificationWithIcon(
          "error",
          t("please upload jpg or jpeg image only")
        )
      } else {
        let img_upload = e.target.files[0]
        setSelect_image_upload(img_upload)
        setEdit_image_employee(URL.createObjectURL(img_upload))
      }
    }
  }

  function submit_edit_info() {
    let data_put = {}
    if (props.user_save.emp_code) {
      data_put = {
        ...data_put,
        emp_code: props.user_save.emp_code,
      }
    }
    if (props.user_save.emp_firstname) {
      data_put = {
        ...data_put,
        emp_firstname: props.user_save.emp_firstname,
      }
    }
    if (props.user_save.emp_lastname) {
      data_put = {
        ...data_put,
        emp_lastname: props.user_save.emp_lastname,
      }
    }
    if (props.user_save.dep_id) {
      data_put = {
        ...data_put,
        dep_id: props.user_save.dep_id,
      }
    }
    if (props.user_save.pos_id) {
      data_put = {
        ...data_put,
        pos_id: props.user_save.pos_id,
      }
    }
    if (props.user_save.emp_nickname) {
      data_put = {
        ...data_put,
        emp_nickname: props.user_save.emp_nickname,
      }
    }
    if (props.user_save.emp_group_id) {
      data_put = {
        ...data_put,
        emp_group_id: props.user_save.emp_group_id,
      }
    }
    if (props.user_save.admin_group_id) {
      data_put = {
        ...data_put,
        admin_group_id: props.user_save.admin_group_id,
      }
    }
    put(data_put)
  }

  function put(data) {
    if (
      data.emp_code ||
      data.emp_firstname ||
      data.emp_lastname ||
      data.dep_id ||
      data.pos_id ||
      data.emp_nickname
    ) {
      return confirm({
        title: t("edit employee info"),
        icon: <QuestionOutlined style={{ color: "#1890ff" }} />,
        centered: true,
        okText: t("edit"),
        cancelText: t("no"),
        onOk() {
          setLoading(true)
          if (select_image_upload !== null) {
            const formData = new FormData()
            formData.append("file", select_image_upload)
            const upload_image = api.UPLOAD_PROFILE(token, formData)
            axios(upload_image)
              .then(res => {
                let add_image_path = {
                  emp_avatar: res.data.path,
                  ...data,
                }
                edit_data(add_image_path)
              })
              .catch(error => {
                setLoading(false)
                // console.log(error.response)
                if (error) {
                  if (error.response) {
                    if (error.response.status === 401) {
                      helpers.re_login()
                      history.push("/login")
                    }
                  }
                }
              })
          } else {
            edit_data(data)
          }
        },
        onCancel() {},
      })
    } else {
      openNotificationWithIcon("warning", t("cancel"))
    }
  }

  function edit_data(data) {
    // console.log(data);
    const edit_ = api.EDIT_DATA(token, props.id, data)
    axios(edit_)
      .then(res => {
        setLoading(false)
        openNotificationWithIcon("success", t("edit success"))
        props.check_detail_user()
        Cancel_Edit()
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            } else {
              openNotificationWithIcon(
                "error",
                t("edit error please try again")
              )
            }
          }
        }
      })
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  const onFinish = values => {
    submit_edit_info()
  }

  const Cancel_Edit = () => {
    // form.resetFields()
    setEdit_image_employee(null)
    setSelect_image_upload(null)
    onFill()
    setEdit(!edit)
    props.setUser_save({
      ...props.user_save,
      emp_code: props.user_info.emp_code,
      emp_firstname: props.user_info.emp_firstname,
      emp_lastname: props.user_info.emp_lastname,
      dep_id: props.user_info.dep_id,
      pos_id: props.user_info.pos_id,
      emp_level_id: props.user_info.emp_level_id,
      emp_nickname: props.user_info.emp_nickname,
      emp_group_id: props.user_info.emp_group_id,
      admin_group_id: props.user_info.admin_group_id,
    })
  }

  useEffect(() => {
    onFill()
    return () => {}
  }, [props.user_info])

  useEffect(() => {
    setEdit_image_employee(props.image_preview)
    return () => {}
  }, [props.image_preview])

  const onFill = () => {
    setEdit_image_employee(props.image_preview)
    form.setFieldsValue({
      emp_code: props.user_info.emp_code,
      emp_firstname: props.user_info.emp_firstname,
      emp_lastname: props.user_info.emp_lastname,
      emp_nickname: props.user_info.emp_nickname,
      dep_id: props.user_info.dep_id,
      pos_id: props.user_info.pos_id,
      emp_group_id: props.user_info.emp_group_id,
      admin_group_id: props.user_info.admin_group_id,
    })
  }

  return (
    <div>
      <Row>
        <Col>
          <div style={{ display: "inherit" }}>
            {edit === true && (
              <Button
                style={{ float: "right", width: "150px" }}
                onClick={() => setEdit(!edit)}
              >
                {t("edit")}
              </Button>
            )}
            {edit === false && (
              <Button
                type="primary"
                style={{ float: "right", width: "150px" }}
                onClick={() => Cancel_Edit()}
              >
                {t("cancel")}
              </Button>
            )}
          </div>
        </Col>
      </Row>
      <p style={{ textAlign: "center", fontSize: "15px", fontWeight: "bold" }}>
        {t("photo Profile")}
      </p>
      <div className="image-upload">
        <div>
          {props.image_preview && edit_image_employee === null && (
            <div className="image-upload">
              <Avatar
                src={props.image_preview}
                size={100}
                style={{ display: "block", margin: "auto" }}
              />
            </div>
          )}
          {props.image_preview && edit_image_employee !== null && (
            <div className="image-upload">
              <Avatar
                src={edit_image_employee}
                size={100}
                style={{ display: "block", margin: "auto" }}
              />
            </div>
          )}

          {!props.image_preview && edit_image_employee !== null && (
            <div className="image-upload">
              <Avatar
                src={edit_image_employee}
                size={100}
                style={{ display: "block", margin: "auto" }}
              />
            </div>
          )}

          {!props.image_preview && edit_image_employee === null && (
            <div className="image-upload">
              <Avatar
                icon={<UserOutlined />}
                size={100}
                style={{ display: "block", margin: "auto" }}
              />
            </div>
          )}
        </div>
      </div>
      <div>
        <input
          type="file"
          accept="image/jpeg"
          onChange={handleImageUpload}
          ref={imageUploader}
          style={{
            display: "none",
          }}
        />
        {edit === false && (
          <div>
            <Button
              type="primary"
              className="button-edit-employee"
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "10px",
                width: "150px",
              }}
              onClick={() => imageUploader.current.click()}
            >
              {t("edit photo")}
            </Button>
            <p style={{ color: "red", textAlign: "center", marginTop: "10px" }}>
              {" "}
              {t("please upload jpg or jpeg image only")}{" "}
            </p>
          </div>
        )}
      </div>

      <br />
      <Form form={form} name="edit-user-info" onFinish={onFinish}>
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("code")}
              placeholder={props.user_info.emp_code}
              headState={props.setUser_save}
              nameState={props.user_save}
              keyState="emp_code"
              type="text"
              disabled={edit}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <div className="mt-bottom-all">
              <p className="text-bold">{t("email")} </p>
              <Input placeholder={props.user_info.emp_email} disabled />
            </div>
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("firstname")}
              placeholder={props.user_info.emp_firstname}
              headState={props.setUser_save}
              nameState={props.user_save}
              keyState="emp_firstname"
              type="text"
              disabled={edit}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("lastname")}
              placeholder={props.user_info.emp_lastname}
              headState={props.setUser_save}
              nameState={props.user_save}
              keyState="emp_lastname"
              type="text"
              disabled={edit}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("nickname")}
              placeholder={props.user_info.emp_nickname}
              headState={props.setUser_save}
              nameState={props.user_save}
              keyState="emp_nickname"
              type="text"
              disabled={edit}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("employee group")}
              placeholder={t("employee group")}
              name_api="LIST_EMPLOYEE_GROUP"
              map_name="emp_group_name"
              map_value="emp_group_id"
              keyState="emp_group_id"
              headState={props.setUser_save}
              nameState={props.user_save}
              disabled={edit}
            />
          </Col>

          {isAdminRoot === true && (
            <Col sm={12} md={6} lg={6} xl={6}>
              <Select_no_search
                header={t("Admin group")}
                placeholder={t("Admin group")}
                name_api="FIND_ALL_ADMIN_GROUP"
                map_name="admin_group_name"
                map_value="admin_group_id"
                keyState="admin_group_id"
                Header_data="data"
                headState={props.setUser_save}
                nameState={props.user_save}
                disabled={edit}
              />
            </Col>
          )}

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("department")}
              placeholder={t("department")}
              name_api="LIST_DEPARTMENT"
              map_name="dep_name"
              map_value="dep_id"
              keyState="dep_id"
              headState={props.setUser_save}
              nameState={props.user_save}
              disabled={edit}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("position")}
              placeholder={t("position")}
              name_api="LIST_POSITION"
              map_name="pos_name"
              map_value="pos_id"
              keyState="pos_id"
              headState={props.setUser_save}
              nameState={props.user_save}
              disabled={edit}
            />
          </Col>
        </Row>

        <br />
            {
              edit === false && (
                <Button
                type="primary"
                htmlType="submit"
                style={{ display: "block", margin: "auto", width: "150px" }}
                loading={loading}
              >
                {t("edit")}
              </Button>
              )
            }
    
      </Form>
      <div></div>

      <Modal
        title=""
        centered
        open={visible}
        onOk={() => console.log("Chang Password")}
        onCancel={() => setVisible(false)}
        okText="Chang Password"
        cancelText="Cancel"
      >
        <br />
        <div className="mt-bottom-all">
          <p className="text-bold">Old Password</p>
          <Input.Password placeholder="Old Password" />
        </div>
        <div className="mt-bottom-all">
          <p className="text-bold">New Password</p>
          <Input.Password placeholder="New Password" />
        </div>
        <div className="mt-bottom-all">
          <p className="text-bold">Re New Password</p>
          <Input.Password placeholder="Re New Password" />
        </div>
      </Modal>
    </div>
  )
}
