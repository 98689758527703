import React, { useEffect, useState } from "react"
import { Form, Select } from "antd"
import axios from "axios"
import * as api from "../../../service/Api_Edit_Sub_Product_In_Car"
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import helpers from '../../Function_Redirect/Redirect_login'

const { Option } = Select

export default function Select_Model(props) {
  const [form] = Form.useForm()
  const { t } = useTranslation();
  const history = useHistory()
  const token = localStorage.getItem("token")
  const [data_product_model, setData_product_model] = useState([])

  useEffect(() => {
    return () => {
      setData_product_model([])
    }
  }, [])

  useEffect(() => {
    onReset()
    if (props.filter_stock.pro_group_id !== null) {
      find_product_model()
    }
    return () => {}
  }, [props.filter_stock.pro_group_id])

  function find_product_model() {
    const find_data = api.FIND_PRODUCT_MODEL(
      token,
      props.filter_stock.pro_group_id
    )
    axios(find_data)
      .then(res => {
        setData_product_model(res.data.data)
      })
      .catch(error => {
        if(error){
          if(error.response){
            if(error.response.status === 401){
              helpers.re_login()
              history.push('/login')          
              }
          }
        }
      })
  }

  useEffect(() => {
    render_select_data()
    return () => {}
  }, [data_product_model])

  function render_select_data() {
    return data_product_model.map(data => {
      return (
        <Option key={data.pro_model_id} value={data.pro_model_id}>
          {data.pro_model_name}
        </Option>
      )
    })
  }

  function check_disabled() {
    if (props.filter_stock.pro_group_id === null) {
      return true
    } else {
      return false
    }
  }

  function onChange(value) {
    if (value) {
      props.setFilter_stock({
        ...props.filter_stock,
        pro_model_id: value,
      })
    } else {
      props.setFilter_stock({
        ...props.filter_stock,
        pro_model_id: null,
      })
    }
  }

  const onReset = () => {
    form.resetFields()
    props.setFilter_stock({
      ...props.filter_stock,
      pro_model_id: null,
    })
  }

  return (
    <div>
      <Form form={form} name="filter-select-model-stock">
        <p className="text-bold"> {t("Product model")} </p>
        <Form.Item
          name="product_model"
          rules={[
            {
              required: false,
              message: `Please select product model`,
            },
          ]}
        >
          <Select
            showSearch
            allowClear
            disabled={check_disabled()}
            placeholder={t("Product model")}
            style={{ width: "100%" }}
            optionFilterProp="children"
            onChange={onChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {render_select_data()}
          </Select>
        </Form.Item>
      </Form>
    </div>
  )
}
