import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import MetaTags from "react-meta-tags"
import { Container, Card, CardBody, Row, Col } from "reactstrap"
import { MdKeyboardBackspace } from "react-icons/md"
import { Tabs, Button } from "antd"
import axios from "axios"
import * as api from "../../service/Api_Import_Customer_Distar"
import no_permi from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png"
import helpers from "../Function_Redirect/Redirect_login"
import Table_Car_In_Customer from "./Table_Car_In_Customer"
import Form_Customer from "./Form_Customer"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const { TabPane } = Tabs

export default function Select_Customer_From_Distar_DB(props) {
  const history = useHistory()
  const { t } = useTranslation()
  const token = localStorage.getItem("token")
  const [loading, setLoading] = useState(false)
  const [car_customer, setCar_customer] = useState([])
  const [data_customer, setData_customer] = useState(null)
  const [activeTab, setActiveTab] = useState("1")
  const { permissionAllService } = useSelector(({ Distar }) => Distar)
  const permi = localStorage.getItem("permi")
  const [permi_t_write, setPermi_t_write] = useState(false)

  useEffect(() => {
    if (permissionAllService) {
      if (permissionAllService.import) {
        setPermi_t_write(permissionAllService.import.write)
      }
    }
    return () => {}
  }, [permissionAllService])

  useEffect(() => {
    if (props.location.gid === undefined) {
      history.push("/import-data/customer")
    } else {
      setData_customer(props.location.data_customer)
      find_all_car()
    }
    return () => {}
  }, [props.location.gid])

  function find_all_car() {
    setLoading(true)
    const find_data = api.FIND_ALL_CAR_CUSTOMER(token, props.location.gid)
    axios(find_data)
      .then(res => {
        setLoading(false)
        setCar_customer(res.data.data)
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Select customer")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/import-data/customer")}
              />
            </a>{" "}
            {t("Select customer")}
          </h3>
          <br />
          <Card>
            <CardBody>
              {permi_t_write === true && (
                <div>
                  <Row>
                    <Col>
                      {activeTab === "1" && (
                        <Button
                          type="primary"
                          onClick={() => setActiveTab("2")}
                          style={{ float: "right", width: "150px" }}
                        >
                          {" "}
                          {t("Next")}{" "}
                        </Button>
                      )}

                      {activeTab === "2" && (
                        <Button
                          type="primary"
                          onClick={() => setActiveTab("1")}
                          style={{ width: "150px" }}
                        >
                          {" "}
                          {t("Previous")}{" "}
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Tabs
                        defaultActiveKey="1"
                        onTabClick={e => {
                          setActiveTab(e)
                        }}
                        activeKey={activeTab}
                      >
                        <TabPane tab="" key="1">
                          <Table_Car_In_Customer
                            car_customer={car_customer}
                            loading={loading}
                          />
                        </TabPane>
                        <TabPane tab="" key="2">
                          <Form_Customer data_customer={data_customer} />
                        </TabPane>
                      </Tabs>
                    </Col>
                  </Row>
                </div>
              )}

              {permi_t_write === false && (
                <div>
                  <img
                    src={no_permi}
                    alt="no permission"
                    style={{ display: "block", margin: "50px auto" }}
                  />
                  <p style={{ textAlign: "center", fontSize: "20px" }}>
                    {t("no permission write module")}
                  </p>
                  <p style={{ textAlign: "center", fontSize: "20px" }}>
                    {" "}
                    {t("please contract administrator")}{" "}
                  </p>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
