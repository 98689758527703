import React, { useState, useEffect } from "react"
import { Container, Card, CardBody, Row , Col } from "reactstrap"
import { useHistory, useParams } from "react-router-dom"
import MetaTags from "react-meta-tags"
import { MdKeyboardBackspace } from 'react-icons/md';
import List_device from './List_device'
import Modal_comp from './Modal_comp'
import { Modal, Button } from 'antd';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux"

const Device = props => {
  const { t } = useTranslation();
    const [data_device, setData_device] = useState([])
    const [visibleDetail, setVisibleDetail] = useState(false);
    const [detail_modal, setDetail_modal] = useState({})
  const token = localStorage.getItem('token')
  const cus_id = localStorage.getItem('cus_id')
  const { customer_user_id } = useSelector(({ Distar }) => Distar)

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Device")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>
          {t("Device")} </h3>
          <Card>
          <CardBody>
        
      
          <List_device
           cus_id={customer_user_id}
           setData_device={setData_device}
           data_device={data_device}
           setVisibleDetail={setVisibleDetail}
           setDetail_modal={setDetail_modal}
          />
   
   
          <Modal
           centered
           open={visibleDetail}
           onOk={() => setVisibleDetail(false)}
           onCancel={() => {
                   setVisibleDetail(false)
                   setDetail_modal({})
           }}
           footer={false}
           width={1000}
           height={500}
           >
               <Modal_comp 
                   detail_modal={detail_modal}
               />
           </Modal>
 

        </CardBody>
        </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Device
