import React from "react"
import { Input, Form } from "antd"

export default function Input_Comp({
  placeholder,
  header,
  keyState,
  required,
  value,
  description
}) {
  function render_require(required) {
    if(required === true) return <span style={{ color:'red' }}>*</span>
  }
  return (
    <div>
      <p className="text-bold">
        {header} {render_require(required)}
      </p>
      <p>{description}</p>
      <Form.Item
        name={keyState}
        rules={[
          {
            required: required,
            message: `${placeholder}`,
          },
        ]}
      >
        <Input
          value={value}
          placeholder={value}
          disabled
        />
      </Form.Item>
    </div>
  )
}
