import React, { useEffect, useState } from "react"
import axios from "axios"
import * as api from "../../../service/Api_System_Settings"
import { Row, Col } from "reactstrap"
import { Select, Form } from "antd"
import { useTranslation } from "react-i18next"
import Detail_SLA from "../../System_Settings/SLA_Components/Detail_SLA"

const { Option } = Select

export default function SelectSLA({ create_service, setCreate_service }) {
  const { t } = useTranslation()
  const [slaAll, setSlaAll] = useState([])
  const token = localStorage.getItem("token")
  const [detailSLASelect, setDetailSLASelect] = useState(null)

  useEffect(() => {
    listAllSLA()
    return () => {
      setSlaAll([])
    }
  }, [])

  function listAllSLA() {
    const listSLA = api.LIST_ALL_SLA(token)
    axios(listSLA)
      .then(res => {
        setSlaAll(res.data.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  function onChange(value) {
    if (value) {
      setCreate_service({ ...create_service, sla_service_id: value })
    } else {
      setCreate_service({ ...create_service, sla_service_id: null })
    }
  }

  useEffect(() => {
    render_select_data()
    return () => {}
  }, [slaAll])

  function render_select_data() {
    return slaAll.map(data => {
      return (
        <Option key={data.sla_service_id} value={data.sla_service_id}>
          {data.sla_service_name}
        </Option>
      )
    })
  }

  useEffect(() => {
    if (create_service.sla_service_id !== null) {
      slaAll.map(data => {
        if (data.sla_service_id === create_service.sla_service_id) {
          setDetailSLASelect(data)
        }
      })
    } else {
      setDetailSLASelect(null)
    }
    return () => {}
  }, [create_service.sla_service_id])

  return (
    <div>
      <Row>
        <Col sm={12} md={6} lg={6} xl={6}>
          <p className="text-bold">{t("Service SLA")}</p>
          <Form.Item
            name="sla_service_id"
            rules={[
              {
                required: false,
                message: t("Service SLA"),
              },
            ]}
          >
            <Select
              showSearch
              allowClear
              placeholder={t("Service SLA")}
              style={{ width: "100%" }}
              optionFilterProp="children"
              onChange={onChange}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {render_select_data()}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {detailSLASelect !== null && (
        <Row>
          <Col>
            <div style={{ border:'1px solid #f0f0f0', padding:'10px', marginBottom:'20px' }}>
            <Detail_SLA sla_detail={detailSLASelect} />
            </div>
          </Col>
        </Row>
      )}
    </div>
  )
}
