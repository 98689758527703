import React, { useEffect } from "react"
import { useHistory, withRouter } from "react-router-dom"
import { Row, Col } from "reactstrap"

function Detail_header({ detail }) {
  const data_car = detail
  let history = useHistory()

  if (!detail) {
    history.push("/cars")
  }

  useEffect(() => {
    render_data()
    return () => {}
  }, [data_car])

  const text_table = [
    {
      header: "License car",
      dataText: detail.license_plate,
    },
    {
      header: "Province",
      dataText: detail.country,
    },
    {
      header: "Customer",
      dataText: detail.firstname + ' '+ detail.lastname,
    },
    {
      header: "Address",
      dataText: detail.address,
    },
    {
      header: "Contact",
      dataText: detail.contact,
    },
    {
      header: "Phone",
      dataText: detail.phone,
    },
    {
      header: "Branch",
      dataText: detail.car_branch,
    },{
      header: "Type",
      dataText: detail.car_type,
    },{
      header: "Chassis",
      dataText: detail.car_chassis,
    },{
      header: "Model",
      dataText: detail.car_model,
    },{
      header: "Register",
      dataText: detail.car_register_date,
    },{
      header: "Product",
      dataText: detail.car_product,
    },{
      header: "Installation note",
      dataText: detail.car_install_node,
    },{
      header: "Installation status",
      dataText: detail.car_install_status,
    }
  ]

  function render_data() {
    return (
      <div>
        <div style={{ fontSize: "15px",color:'#495057' }}>
          <Row>
          {text_table.map((data, index) => {
            return (
              <>
                <Col xl="3" lg="3" md="3" key={index}>
                  <p style={{ fontWeight: "bold" }}>{data.header}</p>
                </Col>
                <Col xl="3" lg="3" md="3">
                  {" "}
                  <p>{data.dataText}</p>
                </Col>
              </>
            )
          })}
          </Row>
        
        </div>
      </div>
    )
  }
  return <div>{render_data()}</div>
}

export default withRouter(Detail_header)
