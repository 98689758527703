var Ip = require('../service/Ip')

export const FIND_ALL_GROUP = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/product/group`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CREATE_NEW_MESSAGE = (token , id , data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/email/messagecontext/noti_type/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const EDIT_MESSAGE = (token , id , data) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/email/messagecontext/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const EDIT_SLA = (token , sla_service_id , data) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/service/sla/${sla_service_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const LIST_CONTENT = (token , id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/email/messagecontext/noti_type/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const DELETE_EMAIL_NOTIFICATION = (token , id) => {
    return {
      method: 'delete',
      url: `${Ip.getIP()}api/v1/email/notification/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CREATE_NEW_EMAIL_NOTIFICATION = (token, data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/email/notification`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const CREATE_PRODUCT_GROUP = (token, data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/product/group`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const CREATE_WARRANTY_NAME = (token, data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/product/warranty`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const FIND_ALL_OPERATOR_GROUP = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/operator/group`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CREATE_OPERATOR_GROUP = (token, data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/operator/group`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const EDIT_OPERATOR_GROUP = (token, id ,data) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/operator/group/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const DELETE_OPERATOR_GROUP = (token, id) => {
    return {
      method: 'delete',
      url: `${Ip.getIP()}api/v1/operator/group/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const FIND_ALL_EMAIL_NOTIFICATION = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/email/notification `,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const FIND_ALL_EMPLOYEE_GROUP = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/employee/group`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CREATE_EMPLOYEE_GROUP = (token, data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/employee/group`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const EDIT_EMPLOYEE_GROUP = (token, id ,data) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/employee/group/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const FIND_ALL_TASK = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/service/task_template`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const DELETE_TASK = (token , id) => {
    return {
      method: 'delete',
      url: `${Ip.getIP()}api/v1/service/task_template/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const DELETE_EMPLOYEE_GROUP = (token, id) => {
    return {
      method: 'delete',
      url: `${Ip.getIP()}api/v1/employee/group/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CREATE_TRIGGER_EVENT = (token, data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/trig`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const CREATE_NEW_TASK = (token, data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/service/task_template`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const EDIT_NAME_TASK = (token , id , data) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/service/task_template/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const CREATE_SERVICE_TYPE = (token, data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/sertype`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const CREATE_POSITION = (token, data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/position`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const CREATE_DEPARTMENT = (token, data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/department`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const CREATE_CAR_MODEL_YEAR = (token, data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/car/year`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const CREATE_CAR_MODEL = (token, data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/car/model`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const CREATE_CAR_GROUP = (token, data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/car/group`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const CREATE_CAR_COLOR = (token, data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/car/color`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const CREATE_CAR_BRAND = (token, data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/car/brand`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const CREATE_CAR_BODY_TYPE = (token, data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/car/body`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const EDIT_PRODUCT_GROUP = (token ,id , data) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/product/group/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const EDIT_WARRANTY_NAME = (token ,id , data) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/product/warranty/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const EDIT_TRIGGER_EVENT = (token ,id , data) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/trig/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const EDIT_SERVICE_TYPE = (token ,id , data) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/sertype/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const EDIT_POSITION = (token ,id , data) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/position/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const EDIT_DEPARTMENT = (token ,id , data) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/department/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const EDIT_CAR_MODEL_YEAR = (token ,id , data) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/car/year/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const EDIT_CAR_MODEL = (token ,id , data) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/car/model/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const EDIT_CAR_GROUP = (token ,id , data) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/car/group/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const EDIT_CAR_COLOR = (token ,id , data) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/car/color/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const EDIT_CAR_BRAND = (token ,id , data) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/car/brand/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const EDIT_CAR_BODY_TYPE = (token ,id , data) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/car/body/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const DELETE_PRODUCT_GROUP = (token, data) => {
    return {
      method: 'delete',
      url: `${Ip.getIP()}api/v1/product/group/${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const DELETE_WARRANTY_NAME = (token, data) => {
    return {
      method: 'delete',
      url: `${Ip.getIP()}api/v1/product/warranty/${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const DELETE_TRIGGER_EVENT = (token, data) => {
    return {
      method: 'delete',
      url: `${Ip.getIP()}api/v1/trig/${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const DELETE_SERVICE_TYPE = (token, data) => {
    return {
      method: 'delete',
      url: `${Ip.getIP()}api/v1/sertype/${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const DELETE_POSITION = (token, data) => {
    return {
      method: 'delete',
      url: `${Ip.getIP()}api/v1/position/${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const DELETE_DEPARTMENT = (token, data) => {
    return {
      method: 'delete',
      url: `${Ip.getIP()}api/v1/department/${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const DELETE_CAR_MODEL_YEAR = (token, data) => {
    return {
      method: 'delete',
      url: `${Ip.getIP()}api/v1/car/year/${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const DELETE_CAR_MODEL = (token, data) => {
    return {
      method: 'delete',
      url: `${Ip.getIP()}api/v1/car/model/${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const DELETE_CAR_GROUP = (token, data) => {
    return {
      method: 'delete',
      url: `${Ip.getIP()}api/v1/car/group/${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const DELETE_CAR_COLOR = (token, data) => {
    return {
      method: 'delete',
      url: `${Ip.getIP()}api/v1/car/color/${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const DELETE_CAR_BRAND = (token, data) => {
    return {
      method: 'delete',
      url: `${Ip.getIP()}api/v1/car/brand/${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const DELETE_CAR_BODY_TYPE = (token, data) => {
    return {
      method: 'delete',
      url: `${Ip.getIP()}api/v1/car/body/${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const FIND_ALL_MODEL = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/product/model`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const UPLOAD_IMAGE = (token, formData) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}storage/upload`,
      headers: {
        "Content-type": "multipart/form-data",
        'x-access-token' : token
      },
      data : formData
    };
  };

  export const CREATE_PRODUCT_MODEL = (token, data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/product/model`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const DELETE_PRODUCT_MODEL = (token, data) => {
    return {
      method: 'delete',
      url: `${Ip.getIP()}api/v1/product/model/${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const GET_IMAGE = (token, image) => {
    return {
      method: 'get',
      responseType: 'arraybuffer',
      url: `${Ip.getIP()}storage/file/${image}`,
      headers: {
        'x-access-token' : token
      }
    };
  };

  export const EDIT_PRODUCT_MODEL = (token ,pro_model_id , data) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/product/model/${pro_model_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const FIND_ALL_WARRANTY = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/product/warranty`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };
  
  export const FIND_ALL_TRIGGER_EVENT = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/trig`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const FIND_ALL_FILE_UPLOAD = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/fup`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const FIND_ALL_SERVICE_TYPE = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/sertype`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const FIND_ALL_POSITION = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/position`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const FIND_ALL_DEPARTMENT = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/department`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const FIND_ALL_CAR_MODEL_YEAR = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/car/year`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const FIND_ALL_CAR_MODEL = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/car/model`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const FIND_ALL_CAR_GROUP = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/car/group`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const FIND_ALL_CAR_COLOR = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/car/color`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const FIND_ALL_CAR_BRAND = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/car/brand`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const FIND_ALL_CAR_BODY_TYPE = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/car/body`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_ALL_SLA = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/service/sla`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_PRIORITY = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/service/priority`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_PROVINCE = (token,data) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/location/province?q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_PROVINCE_ALL = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/location/province`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CREATE_NEW_SLA = (token , data) => {
    return {
      method: 'post',
      url: `${Ip.getIP()}api/v1/service/sla`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const REMOVE_SLA = (token , sla_service_id) => {
    return {
      method: 'delete',
      url: `${Ip.getIP()}api/v1/service/sla/${sla_service_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_LINE_SETTING = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}line/show`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const EDIT_LINE_SETTING = (token , data) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}line/setting`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };