import React, { useState, useEffect } from "react"
import { Container, Card, CardBody, Row, Col } from "reactstrap"
import MetaTags from "react-meta-tags"
import { useHistory, useParams } from "react-router-dom"
import { Button, Modal, Input, notification, Tooltip } from "antd"
import { useTranslation } from "react-i18next"
import { MdKeyboardBackspace } from "react-icons/md"
import helpers from "../Function_Redirect/Redirect_login"
import axios from "axios"
import * as api from "../../service/Api_Service"
import Form_Request from "./Form_Request"
import Detail_Request_Repair from "./Detail_Request_Repair"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { FcSearch } from "react-icons/fc"

const { confirm } = Modal

const Check_Request_Repair = props => {
  const { t } = useTranslation()
  const token = localStorage.getItem("token")
  const history = useHistory()
  const { rrs_id } = useParams()
  const [detail_repair, setDetail_repair] = useState(null)
  const [visible, setVisible] = useState(false)
  const [remark_reject, setRemark_reject] = useState(null)
  const [reject_repair_status, setReject_repair_status] = useState(false)
  const [confirm_repair_status, setConfirm_repair_status] = useState(false)

  const text_detail = {
    textAlign: "center",
    fontSize: "20px",
  }

  useEffect(() => {
    check_detail_repair()
    return () => {}
  }, [rrs_id])

  function check_detail_repair() {
    const check_detail = api.CHECK_DETAIL_REPAIR(token, rrs_id)
    axios(check_detail)
      .then(res => {
        if (res.data.data[0] === undefined) {
          history.push("/requested-repair")
        } else {
          setDetail_repair(res.data.data[0])
          if (res.data.data[0].rrs_status_name === "reject") {
            setReject_repair_status(true)
          } else if (res.data.data[0].rrs_status_name === "confirmed") {
            setConfirm_repair_status(true)
          }
        }
      })
      .catch(error => {
        // console.log(error);
        // console.log(error.response);
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  function reject_repair() {
    return confirm({
      title: t("Confirm reject repair"),
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okType: "danger",
      okText: t("Confirm"),
      cancelText: t("no"),
      onOk() {
        let data_reject = {
          rrs_reject_detail: remark_reject,
        }
        const reject_api = api.REJECT_REPAIR(token, data_reject, rrs_id)
        axios(reject_api)
          .then(res => {
            openNotificationWithIcon("success", t("Reject repair success"))
            history.push("/requested-repair")
          })
          .catch(error => {
            if (error) {
              if (error.response) {
                if (error.response.status === 401) {
                  helpers.re_login()
                  history.push("/login")
                } else {
                  openNotificationWithIcon("error", t("Reject repair error"))
                }
              }
            }
          })
      },
      onCancel() {
        setRemark_reject(null)
        setVisible(false)
      },
    })
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  useEffect(() => {
    render_approved_user()
    render_date_time_approved()
    return () => {}
  }, [detail_repair])

  function render_approved_user() {
    if (detail_repair !== null) {
      if (detail_repair.active_by_role === "administrator") {
        return (
          detail_repair.active_by.admin_firstname +
          " " +
          detail_repair.active_by.admin_lastname
        )
      } else if (detail_repair.active_by_role === "employee") {
        return (
          detail_repair.active_by.emp_firstname +
          " " +
          detail_repair.active_by.emp_lastname
        )
      }
    }
  }

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return "N/A"
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  function render_date_time_approved() {
    if (detail_repair !== null) {
      return (
        <p style={text_detail}>
          {t("date")} : {cutTimeZone(detail_repair.active_at)}
        </p>
      )
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Detail repair")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/requested-repair")}
              />
            </a>{" "}
            {t("Detail repair")}
          </h3>

          {detail_repair !== null &&
            reject_repair_status === false &&
            confirm_repair_status === false && (
              <Card>
                <CardBody>
                  <Detail_Request_Repair detail_repair={detail_repair} />
                  <br />
                  <Row>
                    <Col>
                      <Button
                        danger
                        style={{ float: "right" }}
                        onClick={() => setVisible(true)}
                      >
                        {" "}
                        {t("Reject repair")}{" "}
                      </Button>
                    </Col>
                  </Row>
                  <Form_Request detail_repair={detail_repair} rrs_id={rrs_id} />

                  <Modal
                    title={null}
                    centered
                    open={visible}
                    footer={null}
                    onOk={() => setVisible(false)}
                    onCancel={() => setVisible(false)}
                  >
                    <Row>
                      <Col>
                        <p className="text-bold"> {t("Remark reject")} </p>
                        <Input
                          onChange={e => setRemark_reject(e.target.value)}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col sm={12} md={6} lg={6} xl={6}>
                        <div className="div_create_new_car">
                          <Button
                            className="btn_cancel_create_new_car"
                            onClick={() => {
                              setRemark_reject(null)
                              setVisible(false)
                            }}
                          >
                            {t("Cancel")}
                          </Button>
                        </div>
                      </Col>
                      <Col sm={12} md={6} lg={6} xl={6}>
                        <div className="div_create_new_car">
                          <Button
                            className="btn_submit_create_new_car"
                            type="primary"
                            onClick={() => reject_repair()}
                          >
                            {t("Confirm")}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Modal>
                </CardBody>
              </Card>
            )}

          {detail_repair !== null && reject_repair_status === true && (
            <Card>
              <CardBody>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "30px",
                    color: "red",
                  }}
                >
                  {" "}
                  {t("Reject")}{" "}
                </p>
                <p style={{ textAlign: "center", fontSize: "25px" }}>
                  {" "}
                  {t("remark")} : {detail_repair.rrs_reject_detail}
                </p>
                <p style={text_detail}>
                  {" "}
                  {t("Reject by")} : {render_approved_user()}{" "}
                </p>
                {render_date_time_approved()}
                <br />
                <Detail_Request_Repair detail_repair={detail_repair} />
              </CardBody>
            </Card>
          )}

          {detail_repair !== null && confirm_repair_status === true && (
            <Card>
              <CardBody>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "30px",
                    color: "green",
                  }}
                >
                  {" "}
                  {t("Approved")}{" "}
                </p>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                  }}
                >
                  {" "}
                  {t("Approved by")} : {render_approved_user()}{" "}
                </p>
                {render_date_time_approved()}
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                  }}
                >
                  {" "}
                  {t("Service number")} : {detail_repair.ser_no} {"  "}
                  <Tooltip title={t("View service")}>
                    <a>
                      <FcSearch
                        onClick={() =>
                          history.push(`/service/${detail_repair.ser_id}`)
                        }
                        size={20}
                      />
                    </a>
                  </Tooltip>
                </p>
                <br />
                <Detail_Request_Repair detail_repair={detail_repair} />
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Check_Request_Repair
