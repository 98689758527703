import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Card, CardBody, Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next"
import { MdKeyboardBackspace } from "react-icons/md"
import { DatePicker, Table } from "antd"
import "../Style_Report.css"
import axios from "axios"
import * as api from "../../../service/Api_Report_All"
const FileDownload = require("js-file-download")
import Input_Search from "../../components/report/Input_Search"
import { useHistory } from "react-router-dom"
import helpers from "../../Function_Redirect/Redirect_login"
import Render_Total_And_DoughnutChart from "../../components/report/Render_Total_And_DoughnutChart"
import service_info from "../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/service_info.png"
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser"

export default function ReportRepair() {
  const { t } = useTranslation()
  const history = useHistory()
  const [date_select, setDate_select] = useState({
    date_from: null,
    date_to: null,
  })
  const [data_report, setData_report] = useState([])
  const [search_report, setSearch_report] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loading_excel, setLoading_excel] = useState(false)
  const token = localStorage.getItem("token")
  const [total_status, setTotal_status] = useState({
    assigned: 0,
    cancel: 0,
    complete: 0,
    inprogress: 0,
    overdue: 0,
    pending: 0,
    reject: 0,
    total: 0,
    unassigned: 0,
  })

  const style_DatePicker = {
    width: "100%",
  }

  useEffect(() => {
    check_report()
    return () => {
      setData_report([])
      setLoading(false)
      setDate_select({
        date_from: null,
        date_to: null,
      })
    }
  }, [])

  function onChangeDateFrom(date, dateString) {
    if (dateString) {
      setDate_select({
        ...date_select,
        date_from: dateString,
      })
    } else {
      setDate_select({
        ...date_select,
        date_from: null,
      })
    }
  }

  function onChangeDateTo(date, dateString) {
    if (dateString) {
      setDate_select({
        ...date_select,
        date_to: dateString,
      })
    } else {
      setDate_select({
        ...date_select,
        date_to: null,
      })
    }
  }

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day

    return [year, month, day].join("-")
  }

  const check_data = (params, data) => {
    if (data) {
      return params + data
    } else {
      return ""
    }
  }

  const check_date_time = (data_date_start, data_date_end) => {
    let date_toString = new Date()
    let date_now = formatDate(date_toString)
    if (data_date_start !== null && data_date_end !== null) {
      return "start_time=" + data_date_start + "&end_time=" + data_date_end
    } else {
      return "start_time=" + "2000-01-01" + "&end_time=" + date_now
    }
  }

  function check_report() {
    setLoading(true)
    let url = `apireport/report/dealer?${check_date_time(
      date_select.date_from,
      date_select.date_to
    )}${check_data("&q=", search_report)}`
    const check_report = api.NEW_CHECK_REPORT(token, url)
    axios(check_report)
      .then(res => {
        setLoading(false)
        setData_report(res.data.data)
        setTotal_status({
          ...total_status,
          assigned: res.data.total_repair.assigned,
          cancel: res.data.total_repair.cancel,
          complete: res.data.total_repair.complete,
          inprogress: res.data.total_repair.inprogress,
          overdue: res.data.total_repair.overdue,
          pending: res.data.total_repair.pending,
          reject: res.data.total_repair.reject,
          total: res.data.total_repair.total,
          unassigned: res.data.total_repair.unassigned,
        })
      })
      .catch(error => {
        setLoading(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  function checkData(data) {
    if (data) {
      return data
    } else {
      return null
    }
  }

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T + "+7")
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return null
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  function downloadExcel() {
    setLoading_excel(true)
    let url = `apireport/dealer/excel?${check_date_time(
      date_select.date_from,
      date_select.date_to
    )}${check_data("&q=", search_report)}`
    const excel_report = api.NEW_DOWNLOAD_REPORT_EXCEL(token, url)
    axios(excel_report)
      .then(res => {
        setLoading_excel(false)
        FileDownload(res.data, "report repair.csv")
      })
      .catch(error => {
        setLoading_excel(false)
        if (error) {
          if (error.response) {
            if (error.response.status === 401) {
              helpers.re_login()
              history.push("/login")
            }
          }
        }
      })
  }

  function renderOperator(operatorList) {
    if (operatorList.length === 0) {
      return null
    } else {
      return operatorList.map((data, index) => {
        return <p key={index}>- {data.ope_firstname}</p>
      })
    }
  }

  const columns = [
    {
      title: t("Service number"),
      dataIndex: "ser_no",
      key: "ser_no",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Created on"),
      dataIndex: "created_on",
      key: "created_on",
      render: text => <p>{cutTimeZone(text)}</p>,
    },
    {
      title: t("Customer"),
      dataIndex: "cus_name",
      key: "cus_name",
      render: text => <p>{checkData(text)}</p>,

      align: "center",
    },
    {
      title: t("Car license"),
      dataIndex: "car_license_number",
      key: "car_license_number",
      render: text => <p>{text}</p>,
    },
    {
      title: t("chassis number"),
      dataIndex: "car_chassis_number",
      key: "car_chassis_number",
      render: text => <p>{checkData(text)}</p>,
    },
    {
      title: t("Product model"),
      dataIndex: "pro_model_name",
      key: "pro_model_name",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Product serial number"),
      dataIndex: "pro_serial_number",
      key: "pro_serial_number",
      render: text => <p>{checkData(text)}</p>,
    },
    {
      title: t("detail"),
      dataIndex: "ser_detail",
      key: "ser_detail",
      render: text => <p>{checkData(text)}</p>,
    },
    {
      title: t("Issue reported"),
      dataIndex: "ser_issue_reported",
      key: "ser_issue_reported",
      render: text => <div> {ReactHtmlParser(text)}</div>,
    },
    {
      title: t("remark"),
      dataIndex: "ser_remark",
      key: "ser_remark",
      render: text => <p>{checkData(text)}</p>,
    },
    {
      title: t("Priority"),
      dataIndex: "pri_name",
      key: "pri_name",
      render: text => <p>{t(text)}</p>,
    },
    {
      title: t("service status"),
      dataIndex: "ser_status_name",
      key: "ser_status_name",
      render: text => <p>{t(text)}</p>,
    },
    {
      title: t("operator"),
      dataIndex: "operator",
      key: "operator",
      render: text => renderOperator(text),
    },
    {
      title: t("End date"),
      dataIndex: "ser_completed_date",
      key: "ser_completed_date",
      render: text => <p>{cutTimeZone(text)}</p>,
    },
  ]

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("report repair")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <h3 style={{ color: "#495057" }}>
            {" "}
            <a>
              <MdKeyboardBackspace
                style={{ marginRight: "15px", marginBottom: "3px" }}
                onClick={() => history.push("/reports")}
              />
            </a>{" "}
            {t("report repair")}
          </h3>

          <Card>
            <CardBody>
              <Row>
                <Col>
                  <Input_Search
                    setSearch_report={setSearch_report}
                    check_report={check_report}
                    downloadExcel={downloadExcel}
                    loading_excel={loading_excel}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                  <p className="text-bold"> {t("Date from")} </p>
                  <DatePicker
                    onChange={onChangeDateFrom}
                    allowClear
                    style={style_DatePicker}
                  />
                </Col>
                <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                  <p className="text-bold"> {t("Date to")} </p>
                  <DatePicker
                    onChange={onChangeDateTo}
                    allowClear
                    style={style_DatePicker}
                  />
                </Col>
              </Row>
              <br />
              <br />
              <Row>
                <Col>
                  <Render_Total_And_DoughnutChart
                    text_header={t("total status repair")}
                    img_header={service_info}
                    labels_status={[
                      t("unassigned"),
                      t("assigned"),
                      t("inprogress"),
                      t("complete"),
                      t("pending"),
                      t("reject"),
                      t("cancel"),
                      t("overdue"),
                    ]}
                    data_status={[
                      total_status.unassigned,
                      total_status.assigned,
                      total_status.inprogress,
                      total_status.complete,
                      total_status.pending,
                      total_status.reject,
                      total_status.cancel,
                      total_status.overdue,
                    ]}
                    color_chart={[
                      "#9C9EA4",
                      "#B5EAD7",
                      "#F29496",
                      "#76CE86",
                      "#EA9FE6",
                      "#ff6961",
                      "#EBAE86",
                      "#6891C3",
                    ]}
                    total={total_status.total}
                    data_lineChart={[
                      {
                        text_header: t("Total"),
                        total_count: total_status.total,
                        color: "#39A7DE",
                      },
                      {
                        text_header: t("unassigned"),
                        total_count: total_status.unassigned,
                        color: "#9C9EA4",
                      },
                      {
                        text_header: t("assigned"),
                        total_count: total_status.assigned,
                        color: "#B5EAD7",
                      },
                      {
                        text_header: t("inprogress"),
                        total_count: total_status.inprogress,
                        color: "#F29496",
                      },
                      {
                        text_header: t("complete"),
                        total_count: total_status.complete,
                        color: "#76CE86",
                      },
                      {
                        text_header: t("pending"),
                        total_count: total_status.pending,
                        color: "#EA9FE6",
                      },
                      {
                        text_header: t("reject"),
                        total_count: total_status.reject,
                        color: "#ff6961",
                      },
                      {
                        text_header: t("cancel"),
                        total_count: total_status.cancel,
                        color: "#EBAE86",
                      },
                      {
                        text_header: t("overdue"),
                        total_count: total_status.overdue,
                        color: "#6891C3",
                      },
                    ]}
                  />
                </Col>
              </Row>

              <hr />

              <Table
                loading={loading}
                rowKey="ser_id"
                columns={columns}
                dataSource={data_report}
                scroll={{ x: 3000 }}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
