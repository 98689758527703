import React, { useRef, useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import axios from "axios"
import * as api from "../../../service/Api_Edit_Dealer_User"
import { Form, Button, Switch, Modal, notification, Avatar } from "antd"
import Input_Comp from "./components/Input_Comp"
import Input_Number from "./components/Input_Number"
import Input_Comp_pass from "./components/Input_Comp_pass"
import Select_no_search from "./components/Select_no_search"
import Input_Comp_No_Edit from "./components/Input_Comp_No_Edit"
import { UserOutlined } from "@ant-design/icons"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { useHistory } from "react-router-dom";
import helpers from '../../Function_Redirect/Redirect_login'
import { useTranslation } from "react-i18next";

const { confirm } = Modal

export default function Edit(props) {
  const [form] = Form.useForm()
  const { t } = useTranslation();
  const history = useHistory()
  const token = localStorage.getItem("token")
  const [edit, setEdit] = useState(true)
  const imageUploader = useRef(null)
  const [loading_button, setLoading_button] = useState(false)
  const [edit_dealer_user, setEdit_dealer_user] = useState({})
  const [ image_preview_before_upload, setImage_preview_before_upload ] = useState(null)
  const [image_select_upload, setImage_select_upload] = useState(null)

  const onFinish = values => {
    return confirm({
        title: t("Confirm edit dealer user"),
        icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
        centered: true,
        okText: t("Confirm"),
        cancelText: t("no"),
        onOk() {
            setLoading_button(true)
          let last_edit_dea_user = {}
          for (const [key, value] of Object.entries(edit_dealer_user)) {
            if (!value) {
            } else {
                last_edit_dea_user[key] = value
            }
          }
          
          if (image_select_upload !== null) {
            const formData = new FormData();
            formData.append("file", image_select_upload)
            const upload_image = api.UPLOAD_PROFILE(token, formData);
            axios(upload_image)
            .then( res => {
              let add_image_path = {
                dea_user_avatar : res.data.path,
                ...last_edit_dea_user
              }
              edit_dealer_user_data_info(add_image_path)
            })
            .catch( error => {
                setLoading_button(false)
                if(error){
                  if(error.response){
                    if(error.response.status === 401){
                      helpers.re_login()
                      history.push('/login')          
                      } else {
                        openNotificationWithIcon("error", t("Edit dealer user error"))
                      }
                  }
                }
            })
          } else {
            edit_dealer_user_data_info(last_edit_dea_user)
          }
        },
        onCancel() {},
      })
  }

  useEffect(() => {
    if (props.image_From_db !== null) {
      setImage_preview_before_upload(props.image_From_db)
    }
    return () => {}
  }, [props.image_From_db])

  useEffect(() => {
    onFill()
    setEdit_dealer_user({
        dea_user_firstname: props.data_dealer_user.dea_user_firstname
      })
    return () => {}
  }, [props.data_dealer_user])

  const onFill = () => {
    form.setFieldsValue({
      dea_user_code: props.data_dealer_user.dea_user_code,
      dea_user_email: props.data_dealer_user.dea_user_email,
      dea_user_firstname: props.data_dealer_user.dea_user_firstname,
      dea_user_lastname: props.data_dealer_user.dea_user_lastname,
      dea_user_phone: props.data_dealer_user.dea_user_phone,
      dep_id: props.data_dealer_user.dep_id,
      pos_id: props.data_dealer_user.pos_id,
    })
  }

  function Cancel_Edit() {
    setImage_select_upload(null)
    setEdit(!edit)
    onFill()
    if (props.image_From_db !== null) {
        setImage_preview_before_upload(props.image_From_db)
      }
      setEdit_dealer_user({
        dea_user_firstname: props.data_dealer_user.dea_user_firstname
      })
  }

  const handleImageUpload = e => {
    if (e.target.files[0] !== undefined) {
      if(e.target.files[0].type !== "image/jpeg"){
        openNotificationWithIcon("error", t("please upload jpg or jpeg image only"))
      } else {
        let img_upload = e.target.files[0]
        setImage_preview_before_upload(URL.createObjectURL(img_upload))
        setImage_select_upload(img_upload)
      }
    }
  }

  const openNotificationWithIcon = (type, description) => {
    notification[type]({
      message: description,
    })
  }

  function edit_dealer_user_data_info(last_edit_dealer_user) {
    const edit_operator = api.EDIT_DEALER_USER(
      token,
      props.dea_user_id,
      last_edit_dealer_user
    )
    axios(edit_operator)
      .then(res => {
        Cancel_Edit()
        setLoading_button(false)
        props.check_dealer_data_info()
        openNotificationWithIcon("success", t("Edit dealer user success"))
      })
      .catch(error => {
        setLoading_button(false)
        if(error){
          if(error.response){
            if(error.response.status === 401){
              helpers.re_login()
              history.push('/login')          
              } else {
                openNotificationWithIcon("error", t("Edit dealer user error"))
              }
          }
        }
      })
  }

  return (
    <div>
    <Form form={form} name="edit-dealer-user-info-data" onFinish={onFinish}>
      <Row>
        <Col>
          <div style={{ display: "inherit" }}>
            <p
              className="text-bold"
              style={{
                color: "#495057",
                textAlign: "center",
                fontSize: "15px",
              }}
            >
              {t("Dealer user information")}
            </p>
            {edit === true && (
              <Button
                style={{ float: "right", width: "150px" }}
                onClick={() => setEdit(!edit)}
              >
                {t("Edit")}
              </Button>
            )}
            {edit === false && (
              <Button
                type="primary"
                style={{ float: "right", width: "150px" }}
                onClick={() => Cancel_Edit()}
              >
                {t("Cancel")}
              </Button>
            )}
          </div>
        </Col>
      </Row>
      <br />

      <div
          className="image-upload"
          style={{ marginTop: "10px", marginBottom: "30px" }}
        >
          {image_preview_before_upload === null && (
            <Avatar
              icon={<UserOutlined />}
              size={100}
              style={{ display: "block", margin: "auto" }}
            />
          )}

          {image_preview_before_upload !== null && (
            <Avatar
              src={image_preview_before_upload}
              size={100}
              style={{ display: "block", margin: "auto" }}
            />
          )}
        </div>

        <input
          type="file"
          accept="image/jpeg"
          onChange={handleImageUpload}
          ref={imageUploader}
          style={{
            display: "none",
          }}
        />

        {edit === false && (
          <div>
          <Button
          type="primary"
          style={{ display: "block", margin: "10px auto 10px auto" }}
          onClick={() => imageUploader.current.click()}
        >
          {t("upload photo")}
        </Button>
        <p style={{ color:'red', textAlign:'center' }}> {t("please upload jpg or jpeg image only")} </p>
          </div>
        )}

      
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Dealer user firstname")}
              placeholder={props.data_dealer_user.dea_user_firstname}
              headState={setEdit_dealer_user}
              nameState={edit_dealer_user}
              type="text"
              keyState="dea_user_firstname"
              disabled={edit}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Dealer user lastname")}
              placeholder={props.data_dealer_user.dea_user_lastname}
              headState={setEdit_dealer_user}
              nameState={edit_dealer_user}
              type="text"
              keyState="dea_user_lastname"
              disabled={edit}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Dealer user code")}
              placeholder={props.data_dealer_user.dea_user_code}
              headState={setEdit_dealer_user}
              nameState={edit_dealer_user}
              type="text"
              keyState="dea_user_code"
              disabled={edit}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp_No_Edit
              header={t("Dealer user email")}
              placeholder={props.data_dealer_user.dea_user_email}
              headState={setEdit_dealer_user}
              nameState={edit_dealer_user}
              type="email"
              keyState="dea_user_email"
              disabled={true}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Dealer user phone")}
              placeholder={props.data_dealer_user.dea_user_phone}
              headState={setEdit_dealer_user}
              nameState={edit_dealer_user}
              type="number"
              keyState="dea_user_phone"
              disabled={edit}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Dealer user position")}
              placeholder={t("Dealer user position")}
              name_api="LIST_POSITION"
              map_name="pos_name"
              map_value="pos_id"
              keyState="pos_id"
              headState={setEdit_dealer_user}
              nameState={edit_dealer_user}
              disabled={edit}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Dealer user department")}
              placeholder={t("Dealer user department")}
              name_api="LIST_DEPARTMENT"
              map_name="dep_name"
              map_value="dep_id"
              keyState="dep_id"
              headState={setEdit_dealer_user}
              nameState={edit_dealer_user}
              disabled={edit}
            />
          </Col>
        </Row>

        <br />
        {edit === false && (
          <Button
            type="primary"
            htmlType="submit"
            style={{ display: "block", margin: "auto", width: "150px" }}
            loading={loading_button}
          >
        {t("Edit")}
          </Button>
        )}
      </Form>
    </div>
  )
}
