var Ip = require('./Ip')

export const LIST_DATA = (token, page, pageSize, data) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer?page=${page}&limit=${pageSize}&order_by=asc&q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_NO_DATA = (token, page, pageSize) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/customer?page=${page}&limit=${pageSize}&order_by=asc`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_POSITION = (token) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/position`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const EDIT_CONTRACT = (token, data, con_id) => {
    return {
      method: 'put',
      url: `${Ip.getIP()}api/v1/contract/${con_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      },
      data : data
    };
  };

  export const CHECK_CONTRACT = (token, con_id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/contract/${con_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_DEVICE_DATA = (token, con_id, page, pageSize, data) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/contract/device/${con_id}?page=${page}&limit=${pageSize}&order_by=asc&q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_DEVICE_NO_DATA = (token, con_id, page, pageSize) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/contract/device/${con_id}?page=${page}&limit=${pageSize}&order_by=asc`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_DATA_SERVICE_IN_CONTRACT_DATA = (token, con_id, page, pageSize, data) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/contract/find-contract-service/${con_id}?page=${page}&limit=${pageSize}&order_by=asc&q=${data}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const LIST_DATA_SERVICE_IN_CONTRACT_NO_DATA = (token, con_id, page, pageSize) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/contract/find-contract-service/${con_id}?page=${page}&limit=${pageSize}&order_by=asc`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const CHECK_DUPLICATE_CONTRACT = (token, con_id) => {
    return {
      method: 'get',
      url: `${Ip.getIP()}api/v1/contract/check-is-code-duplicate/${con_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };

  export const DELETE_CONTRACT = (token, con_id) => {
    return {
      method: 'delete',
      url: `${Ip.getIP()}api/v1/contract/${con_id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token
      }
    };
  };