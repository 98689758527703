import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import FileViewer from "react-file-viewer"
import axios from "axios"
import * as api from "../../service/Api_Preview_File"
import { Spin } from "antd"
import noFileUpload from "../../assets/images/noFileUpload.svg"

var Ip = require("../../service/Ip")

export default function PDF() {
  const { fup_path } = useParams()
  const [haveFile, setHaveFile] = useState(false)
  const [isCheck, setIsCheck] = useState(false)
  // console.log(fup_path);

  useEffect(() => {
    const checkFile = api.CHECK_FILE(fup_path)
    axios(checkFile)
      .then(res => {
        setHaveFile(true)
        setIsCheck(true)
      })
      .catch(error => {
        setHaveFile(false)
        setIsCheck(true)
      })
    return () => {}
  }, [fup_path])

  const onError = e => {
    // console.log(e)
    // console.log("error")
  }

  return (
    <div>
      {isCheck === false && (
        <Spin
          size="large"
          style={{ display: "block", margin: "20px auto 20px auto" }}
        />
      )}
      {haveFile === true && isCheck === true && (
        <FileViewer
          fileType="pdf"
          filePath={`${Ip.getIP()}storage/file/${fup_path}`}
          onError={onError}
        />
      )}
      {haveFile === false && isCheck === true && (
        <div>
          <img
            src={noFileUpload}
            alt="no file upload"
            style={{
              height: "250px",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "100px",
            }}
          />
          <p
            style={{ textAlign: "center", fontSize: "30px", marginTop: "20px" }}
          >
            No data file
          </p>
        </div>
      )}
    </div>
  )
}
