import React, { useState, useEffect } from "react"
import { Table, Modal, Tooltip } from "antd"
import { useHistory } from "react-router-dom"
import { AiOutlineEye } from "react-icons/ai"
import { FiEdit2 } from "react-icons/fi"
import Detail_car from "./Detail_car"
import { FcSearch, FcSettings } from "react-icons/fc"
import { useTranslation } from "react-i18next";

import car from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/car.png"
import ambulance from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/ambulance.png"
import vespa from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/vespa.png"
import bus from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/bus.png"
import bicycle from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/bicycle.png"
import van from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/van.png"
import truck from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/truck.png"
import taxi from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/taxi.png"
import pickup from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/pickup.png"
import motorboat from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/motorboat.png"
import motor from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/motor.png"
import no_car from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_car.png"

export default function Data_Table_Car(props) {
  const { t } = useTranslation();
  const history = useHistory()
  const [visible, setVisible] = useState(false)
  const [data_car, setData_car] = useState({})

  function render_Icon_Car(type_car) {
    switch (type_car) {
      case "car":
        return car
        break
      case "vespa":
        return vespa
        break
      case "van":
        return van
        break
      case "truck":
        return truck
        break
      case "taxi":
        return taxi
        break
      case "bus":
        return bus
        break
      case "pickup":
        return pickup
        break
      case "motorboat":
        return motorboat
        break
      case "motor":
        return motor
        break
      case "bicycle":
        return bicycle
        break
      case "ambulance":
        return ambulance
        break
      default:
        return no_car
        break
    }
}

  const columns = [
    {
      title: t("Car type"),
      dataIndex: "car_body_type_name",
      key: "car_body_type_name",
      render: text => (
        <Tooltip title={text ? text : t("Invalid car type")}>
          <img
            src={render_Icon_Car(text)}
            alt="icon"
            style={{ width: "50px" }}
          />
        </Tooltip>
      ),
    },
    {
      title: t("Car code"),
      dataIndex: "car_code",
      key: "car_code",
      render: text => checkData(text),
    },
    {
      title: t("License"),
      dataIndex: "car_license_number",
      key: "car_license_number",
      render: text => checkData(text),
      width : "150px"
    },
    {
      title: t("Province"),
      dataIndex: "pro_name",
      key: "pro_name",
      render: text => checkData(text),
    },
    {
      title: t("Name"),
      dataIndex: "car_name",
      key: "car_name",
      width : '150px',
      render: text => checkData(text),
    },
    {
      title: t("Car group"),
      dataIndex: "car_group_name",
      key: "car_group_name",
      render: text => checkData(text),
      width:'150px'
    },
    {
      title: t("Brand"),
      dataIndex: "car_brand_name",
      key: "car_brand_name",
      render: text => checkData(text),
    },
    {
      title: t("Color"),
      dataIndex: "car_color_name",
      key: "car_color_name",
      render: text => checkData(text),
    },
    {
      title: t("Customer"),
      dataIndex: "cus_name",
      key: "cus_name",
      render: text => checkData(text),
    },
    {
      title: t("Branch"),
      dataIndex: "cus_branch_name",
      key: "cus_branch_name",
      render: text => checkData(text),
    },
    {
      title: t("Car remark"),
      dataIndex: "car_remark",
      key: "car_remark",
      render: text => checkData(text),
    },
    {
      title: "",
      render: text => (
        <Tooltip title={t("View car")}>
        <a>
        <FcSearch
          onClick={() => {
            setVisible(true)
            setData_car(text)
          }}
          size={20}
        />
      </a>
      </Tooltip>
      ),
    },
    {
      title: "",
      render: text => (
        <Tooltip title={t("Edit car")} >
        <a>
          <FcSettings
            onClick={() => history.push(`/cars/${text.car_id}`)}
            size={20}
          />
        </a>
        </Tooltip>
      ),
    },
  ]

  function checkData(data) {
    if (data) {
      return data
    } else {
      return "N/A"
    }
  }

  return (
    <div>
      <Table
        columns={columns}
        dataSource={props.data_list}
        pagination={false}
        loading={props.loading}
        rowKey="car_id"
        scroll={{ x: 800 }}
      />

      <Modal
        title={false}
        centered
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={() => {
          setVisible(false)
          setData_car({})
        }}
        footer={false}
        height={500}
        width={1000}
      >
        <Detail_car data_car={data_car} />
      </Modal>
    </div>
  )
}
