import React, { useState, useEffect } from "react"
import Search_List_Customer from "./Search_List_Customer"
import * as api from "../../../../service/API_New_Contract"
import { Form, Table, Pagination, Button, Input } from "antd"
import axios from "axios"
import { FcDataSheet } from "react-icons/fc"
import { Row, Col } from "reactstrap"
import { EditOutlined } from "@ant-design/icons"
import { useHistory } from "react-router-dom"
import helpers from "../../../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"

export default function List_Customer(props) {
  const { t } = useTranslation()
  const token = localStorage.getItem("token")
  const [data_search_table_customer, setData_search_table_customer] = useState(
    null
  )
  const [name_customer, setName_customer] = useState(null)
  const [customer_table, setCustomer_table] = useState([])
  const history = useHistory()
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    search_customer()
    return () => {
      setName_customer(null)
      setData_search_table_customer(null)
      setCustomer_table([])
    }
  }, [])

  function search_customer() {
    setLoading(true)
    if (data_search_table_customer) {
      const list_data_customer = api.LIST_DATA(
        token,
        1,
        page_limit.page_Size,
        data_search_table_customer
      )
      axios(list_data_customer)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          // console.log(res.data.data)
          setCustomer_table(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          // console.log(error.response)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    } else {
      const list_no_data_customer = api.LIST_NO_DATA(
        token,
        1,
        page_limit.page_Size
      )
      axios(list_no_data_customer)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          // console.log(res.data.data)
          setCustomer_table(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
        })
    }
  }

  function changePagination(page, pageSize) {
    setLoading(true)
    if (data_search_table_customer) {
      const list_data_customer_pagination = api.LIST_DATA(
        token,
        page,
        pageSize,
        data_search_table_customer
      )
      axios(list_data_customer_pagination)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          // console.log(res.data.data)
          setCustomer_table(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
          // console.log(error)
        })
    } else {
      const list_data_customer_pagination = api.LIST_NO_DATA(
        token,
        page,
        pageSize
      )
      axios(list_data_customer_pagination)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          // console.log(res.data.data)
          setCustomer_table(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
          // console.log(error)
        })
    }
  }

  const columns = [
    {
      title: t("Customer code"),
      dataIndex: "cus_code",
      key: "cus_code",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Customer name"),
      dataIndex: "cus_name",
      key: "cus_name",
      render: text => <p>{text}</p>,
    },
    {
      title: t("Customer dealer"),
      dataIndex: "dea_name",
      key: "dea_name",
      render: text => <p>{text}</p>,
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      props.setData_new_contract({
        ...props.data_new_contract,
        cus_id: selectedRows[0].cus_id,
      })
      setName_customer(selectedRows[0].cus_name)
    },
    getCheckboxProps: record => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  }

  return (
    <div>
      {props.data_new_contract.cus_id === null && (
        <Form.Item
          // name="table_select_branch"
          rules={[
            {
              required: false,
              message: "Please select branch",
            },
          ]}
        >
          <div>
            <p className="text-bold ">
              {t("Select customer")} <span style={{ color: "red" }}>*</span>
            </p>
            <Search_List_Customer
              setData_search_table_customer={setData_search_table_customer}
              search_customer={search_customer}
            />
            <br />
            <Table
              loading={loading}
              rowSelection={{
                type: "radio",
                ...rowSelection,
              }}
              rowKey="cus_id"
              pagination={false}
              columns={columns}
              dataSource={customer_table}
              scroll={{ x: 800 }}
            />
            <div style={{ float: "right", marginTop: "10px" }}>
              <Pagination
                current={page_limit.page}
                total={page_limit.limit}
                onChange={changePagination}
              />
            </div>
            <p style={{ fontSize: "15px", marginTop: "10px" }}>
              {" "}
              <FcDataSheet size={20} style={{ marginBottom: "5px" }} />{" "}
              {t("total data")} : {page_limit.limit}
            </p>
          </div>
        </Form.Item>
      )}
      {props.data_new_contract.cus_id !== null && (
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div style={{ display: "flex" }}>
              <p className="text-bold " style={{ marginTop: "7px" }}>
                {t("Customer")}
              </p>
              <Input
                style={{ marginLeft: "10px", height: "35px" }}
                value={name_customer}
                placeholder={name_customer}
                disabled
              />
              <a>
                <EditOutlined
                  style={{ fontSize: "20px", marginLeft: "10px" }}
                  onClick={() => {
                    props.setData_new_contract({
                      ...props.data_new_contract,
                      cus_id: null,
                    })
                    setData_search_table_customer(null)
                    setName_customer(null)
                  }}
                />
              </a>
            </div>
          </Col>
        </Row>
      )}
    </div>
  )
}
