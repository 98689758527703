import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Container, Card, CardBody } from "reactstrap"
import List_contract from "./List_contract"
import no_permi from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

export default function Contract(props) {
  const { t } = useTranslation()
  const [permi_t_read, setPermi_t_read] = useState(false)
  const permi = localStorage.getItem("permi")
  const { permissionAllService } = useSelector(({ Distar }) => Distar)

  useEffect(() => {
    if (permissionAllService) {
      if (permissionAllService.contract) {
        setPermi_t_read(permissionAllService.contract.read)
      }
    }
    return () => {}
  }, [permissionAllService])

  return (
    <React.Fragment>
      <MetaTags>
        <title> {t("Contract")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}> {t("Contract")} </h3>

          {permi_t_read === true && (
            <Card>
              <CardBody>
                <List_contract />
              </CardBody>
            </Card>
          )}
          {permi_t_read === false && (
            <div>
              <img
                src={no_permi}
                alt="no permission"
                style={{ display: "block", margin: "50px auto" }}
              />
              <p style={{ textAlign: "center", fontSize: "20px" }}>
                {t("no permission read module")}
              </p>
              <p style={{ textAlign: "center", fontSize: "20px" }}>
                {" "}
                {t("please contract administrator")}{" "}
              </p>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
