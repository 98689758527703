import React, { useState } from "react"
import { Button, Steps, Modal, Result } from "antd"
import { BiUserCircle, BiCalculator, BiBuildings } from "react-icons/bi"
import { RiNewspaperLine } from "react-icons/ri"
import { useHistory } from "react-router-dom"
import { BsPeople, BsCardChecklist } from "react-icons/bs"
import { HiUserGroup } from "react-icons/hi"
import { AiOutlineFundView } from "react-icons/ai"
import Create_New_Customer from "./Create_New_Customer"
import Create_New_Branch from "./Create_New_Branch"
import Create_New_User from "./Create_New_User"
import Create_New_Dealer from "./Create_New_Dealer"
import Create_New_Contract from "./Create_New_Contract"
import Create_New_Device from "./Create_New_Device"
import Final_Step from "./Final_Step"
import * as api from "../../../service/Api_Select_Car_From_DB_Distar"
import axios from "axios"
import Detail_Found_My_Customer from "./Create_New_Customer/Detail_Found_My_Customer"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import helpers from '../../Function_Redirect/Redirect_login'
import { useTranslation } from "react-i18next";

const { confirm } = Modal

import "./Step.css"

const { Step } = Steps

export default function Step_Form_Import_car(props) {
  const token = localStorage.getItem("token")
  const history = useHistory()
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(1)
  const [select_radio, setSelect_radio] = useState("DEFAULT")
  const [found_Branch_In_DB, setFound_Branch_In_DB] = useState("SELECT")
  const [error_form, setError_form] = useState(false)
  // create new user
  const [handle_button, setHandle_button] = useState(false)
  const [select_user, setSelect_user] = useState([])
  const [select_user_now, setSelect_user_now] = useState([])
  const [confirm_pass_all, setConfirm_pass_all] = useState([])
  const [error_all, setError_all] = useState([])
  const [error_email, setError_email] = useState([])
  const [list_checkbox, setList_Checkbox] = useState([])
  // end create new user
  const [visible_modal, setVisible_modal] = useState(false)
  const [cus_id, setCus_id] = useState(null)
  const [loading, setLoading] = useState(false)

  const mt_icon = {
    marginBottom: "7px",
  }

  const steps_data = [
    {
      step: 1,
      title: t("Overview"),
      icon: "AiOutlineFundView",
      content: <div>Overview</div>,
    },
    {
      step: 2,
      title: t("Dealer"),
      icon: "BsPeople",
      content: (
        <Create_New_Dealer
          dealer_info={props.dealer_info}
          detail_dealer_distar={props.detail_dealer_distar}
          found_my_dealer={props.found_my_dealer}
          setNew_dealer={props.setNew_dealer}
          new_dealer={props.new_dealer}
        />
      ),
    },
    {
      step: 3,
      title: t("Customer"),
      icon: "BiUserCircle",
      content: (
        <div>
          {props.found_my_customer === false && (
            <Create_New_Customer
              my_customer={props.my_customer}
              new_dealer={props.new_dealer}
              dealer_info={props.dealer_info}
              found_my_dealer={props.found_my_dealer}
              setConfirm_car={props.setConfirm_car}
              distar_customer={props.distar_customer}
              setCustomer_data={props.setCustomer_data}
              customer_data={props.customer_data}
              setError_form={setError_form}
            />
          )}
          {props.found_my_customer === true && (
            <Detail_Found_My_Customer
              my_customer={props.my_customer}
              setCustomer_data={props.setCustomer_data}
            />
          )}
        </div>
      ),
    },
    {
      step: 4,
      title: t("Branch"),
      icon: "BiBuildings",
      content: (
        <Create_New_Branch
          found_my_customer={props.found_my_customer}
          setSelect_radio={setSelect_radio}
          select_radio={select_radio}
          setBranch={props.setBranch}
          branch={props.branch}
          my_customer={props.my_customer}
          setFound_Branch_In_DB={setFound_Branch_In_DB}
          found_Branch_In_DB={found_Branch_In_DB}
          setSelect_branch_final={props.setSelect_branch_final}
        />
      ),
    },
    {
      step: 5,
      title: t("User"),
      icon: "HiUserGroup",
      content: (
        <Create_New_User
          user_in_db_distar={props.user_in_db_distar}
          setUser={props.setUser}
          user={props.user}
          customer_data={props.customer_data}
          branch={props.branch}
          setError_form={setError_form}
          setHandle_button={setHandle_button}
          handle_button={handle_button}
          setSelect_user={setSelect_user}
          select_user={select_user}
          setSelect_user_now={setSelect_user_now}
          select_user_now={select_user_now}
          setConfirm_pass_all={setConfirm_pass_all}
          confirm_pass_all={confirm_pass_all}
          setError_all={setError_all}
          error_all={error_all}
          setError_email={setError_email}
          error_email={error_email}
          setList_Checkbox={setList_Checkbox}
          list_checkbox={list_checkbox}
        />
      ),
    },
    {
      step: 6,
      title: t("Contract"),
      icon: "RiNewspaperLine",
      content: (
        <Create_New_Contract
          setError_form={setError_form}
          setNew_Contract={props.setNew_Contract}
          new_Contract={props.new_Contract}
          car_detail={props.car_detail}
        />
      ),
    },
    {
      step: 7,
      title: t("Device"),
      icon: "BiCalculator",
      content: <Create_New_Device list_device_all={props.list_device_all} />,
    },
    {
      step: 8,
      title: t("Finalize"),
      icon: "BsCardChecklist",
      content: (
        <Final_Step
          found_my_dealer={props.found_my_dealer}
          dealer_info={props.dealer_info}
          new_dealer={props.new_dealer}
          customer_data={props.customer_data}
          branch={props.branch}
          user={props.user}
          new_Contract={props.new_Contract}
          list_device_all={props.list_device_all}
          my_customer={props.my_customer}
          setSelect_branch_final={props.setSelect_branch_final}
          select_branch_final={props.select_branch_final}
        />
      ),
    },
  ]

  function render_Icon(icon) {
    switch (icon) {
      case "BiUserCircle":
        return <BiUserCircle style={mt_icon} />
        break
      case "HiUserGroup":
        return <HiUserGroup style={mt_icon} />
        break
      case "BiBuildings":
        return <BiBuildings style={mt_icon} />
        break
      case "BsPeople":
        return <BsPeople style={mt_icon} />
        break
      case "RiNewspaperLine":
        return <RiNewspaperLine style={mt_icon} />
        break
      case "BiCalculator":
        return <BiCalculator style={mt_icon} />
        break
      case "AiOutlineFundView":
        return <AiOutlineFundView style={mt_icon} />
        break
      case "BsCardChecklist":
        return <BsCardChecklist style={mt_icon} />
        break
      default:
        return <BiUserCircle style={mt_icon} />
        break
    }
  }

  function click_select_user() {
    let data_mapping = []
    let data_confirm_pass = []
    let error_password_mismatch = []
    let error_email_duplicate = []
    let list_checkbox_role = []
    select_user.map(async data => {
      await data_mapping.push({
        cus_email_password_condition: false,
        cus_user_code: data.gid ? data.gid : null,
        cus_user_email: data.email,
        cus_user_firstname: data.nickname,
        cus_user_lastname: null,
        cus_user_password: null,
        cus_user_phone: data.phone,
        dep_id: null,
        pos_id: null,
        not_send_email_notification: false,
        not_send_line_notification: false,
      })
      data_confirm_pass.push({
        confirm_pass: null,
      })
      error_password_mismatch.push({
        error: null,
      })
      error_email_duplicate.push({
        error: null,
      })
      list_checkbox_role.push({
        listRole: null,
      })
    })
    props.setUser(data_mapping)
    setConfirm_pass_all(data_confirm_pass)
    setError_all(error_password_mismatch)
    setError_email(error_email_duplicate)
    setList_Checkbox(list_checkbox_role)
    setHandle_button(true)
  }

  function create_user() {
    let data_mapping = []
    let data_confirm_pass = []
    let error_password_mismatch = []
    let list_checkbox_role = []
    let error_email_duplicate = []

    data_mapping.push({
      cus_email_password_condition: false,
      cus_user_email: null,
      cus_user_firstname: null,
      cus_user_lastname: null,
      cus_user_password: null,
      cus_user_phone: null,
      dep_id: null,
      pos_id: null,
      not_send_email_notification: false,
      not_send_line_notification: false,
    })
    data_confirm_pass.push({
      confirm_pass: null,
    })
    error_password_mismatch.push({
      error: null,
    })
    error_email_duplicate.push({
      error: null,
    })
    list_checkbox_role.push({
      listRole: null,
    })

    props.setUser(data_mapping)
    setConfirm_pass_all(data_confirm_pass)
    setError_all(error_password_mismatch)
    setError_email(error_email_duplicate)
    setList_Checkbox(list_checkbox_role)
    setHandle_button(true)
  }

  function next() {
    if (activeStep === 1) {
      let data_last_edit_dealer = false
      for (const [key, value] of Object.entries(props.new_dealer)) {
        if (value === null) {
          data_last_edit_dealer = true
        }
      }
      if (data_last_edit_dealer === true) {
        return Modal.error({
          title:
            t("You do not seem to have filled out all the fields. Please try again") ,
          centered: true,
        })
      } else {
        const nextStep = activeStep + 1
        setActiveStep(nextStep)
      }
    } else if (activeStep === 2) {
      if (props.found_my_customer === false) {
        let data_last_edit_customer = false
        for (const [key, value] of Object.entries(props.customer_data)) {
          if (value === null) {
            data_last_edit_customer = true
          }
        }
        if (data_last_edit_customer === true) {
          return Modal.error({
            title:
            t("You do not seem to have filled out all the fields. Please try again"),
            centered: true,
          })
        } else {
          const nextStep = activeStep + 1
          setActiveStep(nextStep)
        }
      } else {
        const nextStep = activeStep + 1
        setActiveStep(nextStep)
      }
    } else if (activeStep === 3) {
      if (select_radio === "CUSTOM" && props.found_my_customer === false) {
        let data_last_edit = false
        for (const [key, value] of Object.entries(props.branch)) {
          if (value === null) {
            data_last_edit = true
          }
        }
        if (data_last_edit === true) {
          return Modal.error({
            title:
              t("You do not seem to have filled out all the fields. Please try again"),
            centered: true,
          })
        } else {
          const nextStep = activeStep + 1
          setActiveStep(nextStep)
        }
      } else if (
        select_radio === "DEFAULT" &&
        props.found_my_customer === false
      ) {
        const nextStep = activeStep + 1
        setActiveStep(nextStep)
      } else if (
        found_Branch_In_DB === "SELECT" &&
        props.found_my_customer === true
      ) {
        if (!props.branch.cus_branch_id) {
          return Modal.error({
            title: t("Please select branch") ,
            centered: true,
          })
        } else {
          const nextStep = activeStep + 1
          setActiveStep(nextStep)
        }
      } else if (
        found_Branch_In_DB === "NEW" &&
        props.found_my_customer === true
      ) {
        let data_last_edit_new = false
        for (const [key, value] of Object.entries(props.branch)) {
          if (value === null) {
            data_last_edit_new = true
          }
        }
        if (data_last_edit_new === true) {
          return Modal.error({
            title:
              t("You do not seem to have filled out all the fields. Please try again"),
            centered: true,
          })
        } else {
          const nextStep = activeStep + 1
          setActiveStep(nextStep)
        }
      }
    } else if (activeStep === 4) {
      if (props.user.length === 0) {
        if (select_user.length === 0) {
          create_user()
        } else {
          click_select_user()
        }
      } else {
        let check_data_user_comp = false
        let check_email_dup = false
        props.user.map(data => {
          for (const [key, value] of Object.entries(data)) {
            if (key !== "cus_user_code" && value === null)
              check_data_user_comp = true
          }
        })
        error_email.map(data => {
          for (const [key, value] of Object.entries(data)) {
            if (value !== null) check_email_dup = true
          }
        })
        if (check_data_user_comp === true) {
          return Modal.error({
            title:
              t("You do not seem to have filled out all the fields. Please try again"),
            centered: true,
          })
        } else if (check_email_dup === true) {
          return Modal.error({
            title: t("Email user is duplicate. Please input new email again") ,
            centered: true,
          })
        } else {
          const nextStep = activeStep + 1
          setActiveStep(nextStep)
        }
      }
    } else if (activeStep === 5) {
      let data_last_Contract = false
      for (const [key, value] of Object.entries(props.new_Contract)) {
        if (value === null) {
          data_last_Contract = true
        }
      }
      if (data_last_Contract === true) {
        return Modal.error({
          title:
            t("You do not seem to have filled out all the fields. Please try again"),
          centered: true,
        })
      } else {
        const nextStep = activeStep + 1
        setActiveStep(nextStep)
      }
    } else {
      const nextStep = activeStep + 1
      setActiveStep(nextStep)
    }
  }

  function prev() {
    const prevStep = activeStep - 1
    setActiveStep(prevStep)
  }

  function confirm_import_car() {
    return confirm({
      title: t("Confirm import car") ,
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: t("Confirm") ,
      cancelText: t("no"),
      onOk() {
        import_car_in_db()
      },
      onCancel() {},
    })
  }

  function import_car_in_db() {
    setLoading(true)
    let data_import_car = {}
    if (props.found_my_dealer === true) {
      data_import_car = {
        dealer: {
          dea_code: props.dealer_info.dea_code,
          dea_id: props.dealer_info.dea_id,
          found_my_db: true,
          is_import: true,
        },
        customer: {
          is_import: true,
          ...props.customer_data,
        },
        contract: {
          is_import: true,
          ...props.new_Contract,
        },
        car: props.car_detail,
        branch: props.branch,
        user: props.user,
        device: props.list_device_all,
      }
    } else if (props.found_my_dealer === false) {
      data_import_car = {
        dealer: {
          ...props.new_dealer,
          found_my_db: false,
          is_import: true,
        },
        customer: {
          ...props.customer_data,
          is_import: true,
        },
        contract: {
          ...props.new_Contract,
          is_import: true,
        },
        car: props.car_detail,
        branch: props.branch,
        user: props.user,
        device: props.list_device_all,
      }
    }

    const import_car_to_db = api.IMPORT_CAR(token, data_import_car)
    axios(import_car_to_db)
      .then(res => {
        setCus_id(res.data.customer_id)
        setVisible_modal(true)
        setLoading(false)
      })
      .catch(error => {
        // console.log(error.response)
        setLoading(false)
        if(error.response.status === 401){
          helpers.re_login()
          history.push('/login')          
}

      })
  }

  return (
    <div>
      <Steps current={activeStep} responsive={true}>
        {steps_data.map((item, index) => (
          <Steps.Step
            key={index}
            title={item.title}
            icon={render_Icon(item.icon)}
          />
        ))}
      </Steps>

      {steps_data.map((item, index) => (
        <div
          key={index}
          className={`steps-content ${
            item.step !== activeStep + 1 && "hidden"
          }`}
        >
          {item.content}
        </div>
      ))}

      <div className="steps-action">
        {activeStep === 1 && (
          <Button
            style={{ width: "150px" }}
            className="btn-previous-step-import-car"
            onClick={() => props.setConfirm_car(false)}
          >
            {t("Back to overview")}
          </Button>
        )}

        {activeStep > 1 && (
          <Button
            className="btn-previous-step-import-car"
            onClick={() => prev()}
          >
            {t("Previous")}
          </Button>
        )}

        {activeStep < steps_data.length - 1 && (
          <Button
            className="btn-next-step-import-car"
            type="primary"
            onClick={() => next()}
            disabled={error_form}
          >
            {t("Next")}
          </Button>
        )}

        {activeStep === steps_data.length - 1 && (
          <Button
            className="btn-submit-step-import-car"
            type="primary"
            style={{ display: "block", margin: "auto" }}
            onClick={() => confirm_import_car()}
            loading={loading}
          >
            {t("Submit")}
          </Button>
        )}

        <Modal
          centered
          open={visible_modal}
          onOk={() => {
            setVisible_modal(false)
            history.push("/import-data/car")
          }}
          onCancel={() => {
            setVisible_modal(false)
            history.push("/import-data/car")
          }}
          footer={false}
          width={1000}
        >
          <Result
            status="success"
            title="Import car success"
            extra={[
              <Button
                type="primary"
                key="console"
                onClick={() => history.push(`/customers/${cus_id}`)}
              >
                {t("Go console")}
              </Button>,
              <Button
                key="buy"
                onClick={() => history.push("/import-data/car")}
              >
                {t("Back to data table")}
              </Button>,
            ]}
          />
        </Modal>
      </div>
    </div>
  )
}
