import React,{ useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { Button, Input, Form, Space } from "antd"
import { useHistory } from "react-router-dom"
import { CarOutlined,  SearchOutlined } from "@ant-design/icons"
import { BsPeople } from "@react-icons/all-files/bs/BsPeople"
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux"

const { Search } = Input;

export default function Search_car(props) {
    const history = useHistory()
    const { t } = useTranslation();
    const permi = localStorage.getItem('permi')
    const [permi_t, setPermi_t] = useState(false)
    const { permissionAllService } = useSelector(({ Distar }) => Distar)
  useEffect(() => {
    if(permissionAllService){
      if(permissionAllService.dealer){
        setPermi_t(permissionAllService.dealer.write)
      }
    }
    return () => {
  
    }
  }, [permissionAllService])

    const onFinish = values => {
      props.search_data()
    }

    return (
        <>
        <Form
          name="Search_car"
          initialValues={{
            remember: false,
          }}
          onFinish={onFinish}
        >
          <Row>
            <Col sm={12} md={6} lg={6} xl={4}>
              <Form.Item
                name="search"
                rules={[
                  {
                    required: false,
                    message: "Please input name,department",
                  },
                ]}
              >
              <Input
                  placeholder={t("Name")}
                  style={{ marginBottom: "15px" }}
                  onChange={e => props.setSearch_dealer(e.target.value)}
              /> 
              </Form.Item>
            </Col>
            <Col sm={12} md={6} lg={4} xl={4}>
              <Button
                type="primary"
                className="button-search-employee"
                htmlType="submit"
              >
                {t("Search")}
              </Button>
              {
                permi_t === true && (
                  <Button
                  className="button-search-New-Employee"
                  onClick={() => history.push("/new-dealer")}
                  icon={<BsPeople style={{ fontSize: "15px", marginRight:'10px', marginBottom:'5px' }}/>}
                >
                  {t("New dealer")}
                </Button>
                )
              }

            </Col>
          </Row>
        </Form>
      </>
    )
}
