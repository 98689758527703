import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import Search from "./Search"
import Table from "./Table"
import { Pagination, Spin } from "antd"
import axios from "axios"
import * as api from "../../service/Api_Customer"
import { FcDataSheet } from "react-icons/fc"
import { useTranslation } from "react-i18next"
import no_permi from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/no_permi.png"
import { useHistory } from "react-router-dom"
import helpers from "../Function_Redirect/Redirect_login"
import { useSelector } from "react-redux"

const Customer = props => {
  const { t } = useTranslation()
  const history = useHistory()
  const token = localStorage.getItem("token")
  const [search_data_user, setSearch_data_user] = useState(null)
  const [data_list, setData_list] = useState([])
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 12,
  })
  const [loading, setLoading] = useState(false)

  const permi = localStorage.getItem("permi")
  const [permi_t, setPermi_t] = useState(false)
  const { permissionAllService } = useSelector(({ Distar }) => Distar)

  useEffect(() => {
    if (permissionAllService) {
      if (permissionAllService.customer) {
        setPermi_t(permissionAllService.customer.read)
      }
    }
    return () => {}
  }, [permissionAllService])

  useEffect(() => {
    search_data()
    return () => {
      setData_list([])
    }
  }, [])

  function search_data() {
    setLoading(true)
    if (search_data_user) {
      const list_data_customer = api.LIST_DATA(
        token,
        1,
        page_limit.page_Size,
        search_data_user
      )
      axios(list_data_customer)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          // console.log(res.data.data)
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }

          // console.log(error)
        })
    } else {
      const list_no_data_customer = api.LIST_NO_DATA(
        token,
        1,
        page_limit.page_Size
      )
      axios(list_no_data_customer)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: 1,
            limit: res.data.total_data,
          })
          // console.log(res.data.data)
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }

          // console.log(error)
        })
    }
  }

  function changePagination(page, pageSize) {
    setLoading(true)
    setPage_limit({
      ...page_limit,
      page: page,
      page_Size: pageSize,
    })
    if (search_data_user) {
      const list_data_customer = api.LIST_DATA(
        token,
        page,
        pageSize,
        search_data_user
      )
      axios(list_data_customer)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          // console.log(res.data.data)
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
          // console.log(error)
        })
    } else {
      const list_data_customer = api.LIST_NO_DATA(token, page, pageSize)
      axios(list_data_customer)
        .then(res => {
          setLoading(false)
          setPage_limit({
            ...page_limit,
            page: page,
            page_Size: pageSize,
            limit: res.data.total_data,
          })
          // console.log(res.data.data)
          setData_list(res.data.data)
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response) {
              if (error.response.status === 401) {
                helpers.re_login()
                history.push("/login")
              }
            }
          }
          // console.log(error)
        })
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>{t("customer")} | Distar</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 style={{ color: "#495057" }}> {t("customer")} </h3>
          {permi_t === true && (
            <div>
              <div>
                <br />
                <Search
                  setSearch_data_user={setSearch_data_user}
                  setData_list={setData_list}
                  search_data={search_data}
                />
              </div>
              <div>
                {loading === false && (
                  <Table data_list={data_list} search_data={search_data} />
                )}
                {loading === true && (
                  <Spin
                    style={{ display: "block", margin: "20px auto 20px auto" }}
                  />
                )}
              </div>
              <div>
                <Pagination
                  defaultPageSize={12}
                  pageSizeOptions={["12", "20", "52", "100"]}
                  current={page_limit.page}
                  total={page_limit.limit}
                  onChange={changePagination}
                  style={{ marginTop: "20px", float: "right" }}
                />
              </div>
              <p style={{ fontSize: "15px", marginTop: "20px" }}>
                {" "}
                <FcDataSheet size={20} style={{ marginBottom: "5px" }} />{" "}
                {t("total data")}: {page_limit.limit}
              </p>
            </div>
          )}
          {permi_t === false && (
            <div>
              <img
                src={no_permi}
                alt="no permission"
                style={{ display: "block", margin: "50px auto" }}
              />
              <p style={{ textAlign: "center", fontSize: "20px" }}>
                {t("no permission read module")}
              </p>
              <p style={{ textAlign: "center", fontSize: "20px" }}>
                {" "}
                {t("please contract administrator")}{" "}
              </p>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Customer
