import React from "react"
import { Row, Col } from "reactstrap"
import { Button, Input, Form } from "antd"
import { useHistory } from "react-router-dom"
import { BiCalculator } from "@react-icons/all-files/bi/BiCalculator"
import { VscTools } from "react-icons/vsc"
import { CarOutlined, SearchOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next";

export default function Search_Service(props) {
  const history = useHistory()
  const { t } = useTranslation();

  const onFinish = values => {
    props.search_data()
  }

  return (
    <>
      <Form
        name="Search_Service_data_info"
        initialValues={{
          remember: false,
        }}
        onFinish={onFinish}
      >
        <Row>
          <Col sm={12} md={6} lg={6} xl={4}>
            <Form.Item
              name="search"
              rules={[
                {
                  required: false,
                  message: "Please input name,department",
                },
              ]}
            >
              <Input
                placeholder={`${t("Service number")} , ${t("Service name")}`}
                style={{ marginBottom: "15px" }}
                onChange={e => props.setSearch_service(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={6} lg={4} xl={4}>
            <Button
              type="primary"
              className="button-search-employee"
              htmlType="submit"
            >
              {t("Search")}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}
