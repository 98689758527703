import React,{ useState } from "react"
import { Modal, Button, notification } from "antd"
import axios from "axios"
import * as api from "../../../../../../service/service_operator/Api_Service"
import './styles.module.sig.css'
import { FcApproval } from "react-icons/fc";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import helpers from '../../../../../Function_Redirect/Redirect_login'
import { useTranslation } from "react-i18next";

const { confirm } = Modal;

export default function Button_Check_In_Out(props) {
  const { t } = useTranslation();
    const token = localStorage.getItem("token")
    const history = useHistory()
    const [loading, setState] = useState(false)

  function check_function() {
      return confirm({
        title: `${t("Confirm")} ${props.text_button}`,
        icon: <ExclamationCircleOutlined />,
       centered: true ,
       cancelText: t("Cancel"),
       okText: t("Confirm"),
        onOk() {
            setState(true)
            const date = new Date()
            const image_import = [...props.task_hast_form]
             navigator.geolocation.getCurrentPosition(function (position) {
              const dataOld = localStorage.getItem(`service_${props.ser_id}`)
              let arrayLocal = dataOld ? JSON.parse(dataOld) : []

              let dataCheckInButton =  {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
                date: date.toString(),

                ser_id:props.ser_id,
                index_step : props.index_step,
                index_field : props.index_field,

                task_name : props.task_name
              }
              arrayLocal.push(dataCheckInButton)
              const convertToString = JSON.stringify(arrayLocal);
              localStorage.setItem(`service_${props.ser_id}`, convertToString);

              image_import[props.index_step].fields[props.index_field].value = [
                {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                  date: date.toString(),
                },
              ]

              setTimeout(() => {
                openNotificationWithIcon('success', `${t(checkTextButton(props.type))}` )
                setState(false)
                props.setTask_hast_form(image_import)
              }, 1000);

              // submit_data(
              //   props.index_step + 1,
              //   // image_import[props.index_step].fields[props.index_field]
              //   image_import[props.index_step].fields
              // )

            })
        },
        onCancel() {
    
        },
      });
  }

  function submit_data(number_step, data) {
    const send = api.SUBMIT_FORM_DATA(token, props.ser_id, number_step, data)
    axios(send)
      .then(res => {
        openNotificationWithIcon('success', `${props.text_button} ${t("success")}` )
        setState(false)
        props.check_data_submit()
        props.Check_service_form()
      })
      .catch(error => {
        if(error){
          if(error.response){
            if(error.response.status === 401){
              helpers.re_login()
              history.push('/login')          
              } else {
                openNotificationWithIcon('error', `${props.text_button} ${t("error")}` )
                setState(false)
              }
          }
        }

      })
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    });
  };

  function checkTextButton(typeButton) {
    if( typeButton === "check_in") {
      return "Check in saved"
    } else {
      return "Check out saved"
    }
  }

  function checkAlertWarningTextButton(typeButton , step) {
    if( typeButton === "check_in") {
      return t("Check in will not be successful until the steps") + ` ${step} ` +t("are submitted")
    } else {
      return t("Check out will not be successful until the steps") + ` ${step} ` +t("are submitted")
    }
  }

    return (
  <div>
    {/* <button onClick={() => console.log(props.task_hast_form[props.index_step].fields[props.index_field])}> task_hast_form </button>
    <button onClick={() => console.log(props.task_hast_form[props.index_step].fields[props.index_field].value)}> index </button>
    <button onClick={() => console.log(props.value)}> value </button>
    <button onClick={() => console.log(props.task_hast_form[props.index_step].fields[props.index_field].pass_step)}> pass_step </button> */}

    {
    props.value !== null && props.task_hast_form[props.index_step].fields[props.index_field].pass_step === false && (
       <>
        <Button
          className='btn-check-in-out-form'
          onClick={() => check_function()}
          type="primary"
          icon={<FcApproval size={20} style={{ marginRight:'5px' }} />}
          disabled={true}
        >
          {`${t(checkTextButton(props.type))}`}
        </Button>
        <p style={{ marginTop:"10px" , color:'red' }}> 
    {checkAlertWarningTextButton(props.type,(props.index_step+1))}
        </p>
       </>
    )
  }
  {
    props.value !== null && props.task_hast_form[props.index_step].fields[props.index_field].pass_step === true && (
        <Button
        className='btn-check-in-out-form'
          onClick={() => check_function()}
          type="primary"
          icon={<FcApproval size={20} style={{ marginRight:'5px' }} />}
          disabled={true}
        >
         {props.text_success}
        </Button>
    )
  }
  {
    props.value === null && props.type === "check_in" && (
        <Button
        className='btn-check-in-out-form'
          onClick={() => check_function()}
          style={{ color:'#FFF', backgroundColor:'#2FAC0A', borderColor:'#2FAC0A' }}
          loading={loading}
        >
         {props.text_button}
        </Button>
    )
  }
  {
    props.value === null && props.type === "check_out" && (
        <Button
        className='btn-check-in-out-form'
          onClick={() => check_function()}
          style={{ color:'#FFF', backgroundColor:'#D83A56', borderColor:'#D83A56' }}
          loading={loading}
        >
         {props.text_button}
        </Button>
    )
  }
  </div>
    )
}
