import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import Expand from "react-expand-animated"
import { FiChevronDown, FiChevronRight } from "react-icons/fi"
import { Button } from "antd"
import Swal from "sweetalert2"
import axios from "axios"
import { Row, Col } from "reactstrap"
import * as api from "../../../service/Api_Edit_Employee"
import { useTranslation } from "react-i18next"

import helpers from "../../Function_Redirect/Redirect_login"

export default function Delete_Employee(props) {
  const history = useHistory()
  const { t } = useTranslation()
  const token = localStorage.getItem("token")
  const [open, setOpen] = useState(false)
  const [icon, setIcon] = useState(
    <FiChevronRight size={20} style={{ marginBottom: "5px" }} />
  )
  const [loading, setLoading] = useState(false)

  const setToggle = () => {
    if (open === false) {
      setOpen(true)
      setIcon(<FiChevronDown size={25} style={{ marginBottom: "5px" }} />)
    } else {
      setOpen(false)
      setIcon(<FiChevronRight size={25} style={{ marginBottom: "5px" }} />)
    }
  }

  function delete_employee() {
    return Swal.fire({
      title: t("delete employee"),
      text: t("this action cannot be undone"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff4d4f",
      confirmButtonText: t("delete"),
      cancelButtonText: t("cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        setLoading(true)
        const remove_employee_action = api.REMOVE_EMPLOYEE(token, props.id)
        axios(remove_employee_action)
          .then(res => {
            setLoading(false)
          })
          .catch(error => {
            setLoading(false)
            if(error){
              if(error.response){
                if (error.response.status === 404) {
                  return Swal.fire({
                    title: t("delete success"),
                    text: error.response.data.message,
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: t("ok"),
                    timer: 3000,
                    willClose: () => {
                      history.push("/employee")
                    },
                  }).then(result => {
                    if (result.isConfirmed) {
                      history.push("/employee")
                    }
                  })
                } else if (error.response.status === 401) {
                  helpers.re_login()
                  history.push("/login")
                }
              }
            }
          })
      } else {
        setLoading(false)
      }
    })
  }

  return (
    <Row>
      <Col sm={12} md={6} lg={4} xl={4} onClick={() => setToggle()}>
        <a>
          {" "}
          <p style={{ fontSize: "20px" }}>
            {" "}
            {icon} {t("delete employee")}{" "}
          </p>{" "}
        </a>
      </Col>
      <Col sm={12} md={6} lg={8} xl={8}>
        <p style={{ color: "rgb(108, 117, 125)" }}>
          {" "}
          {t("make sure your want delete employee")}{" "}
        </p>

        <Expand open={open} duration={500}>
          <div style={{ width: "100%", borderRadius: "17px", padding: "10px" }}>
            <p style={{ color: "red" }}>
              {" "}
              {t("this action cannot be undone")}{" "}
            </p>
            <Button
              type="primary"
              danger
              style={{ width: "200px" }}
              onClick={() => delete_employee()}
              loading={loading}
            >
              {" "}
              {t("delete employee")}{" "}
            </Button>
          </div>
        </Expand>
      </Col>
    </Row>
  )
}
