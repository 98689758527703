import React, { useState, useEffect } from "react"
import { Form, Button, Modal, notification, Input } from "antd"
import { Row, Col } from "reactstrap"
import Input_Comp from "./components/Input_Comp"
import Input_Date_Comp from "./components/Input_Date_Comp"
import Input_Time from "./components/Input_Time"
import Select_no_search from "./components/Select_no_search"
import axios from "axios"
import { useHistory } from "react-router-dom"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import * as api from "../../service/Api_New_Service"
import List_User from "./components/List_User"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import Select_no_search_Location from "./components/Select_no_search_Location"
import Select_after_select_geo from "./components/Select_after_select_geo"
import helpers from "../Function_Redirect/Redirect_login"
import { useTranslation } from "react-i18next"
import Map_location from "./components/Map_location"
import Input_Show_Only from "./components/Input_Show_Only"
import SelectSLA from "./components/SelectSLA"
import UploadDocsFile from "./components/UploadDocsFile"
import { useSelector } from "react-redux"

const { confirm } = Modal
const { TextArea } = Input

export default function Create_New_Service(props) {
  const token = localStorage.getItem("token")
  const r = localStorage.getItem("r")
  const role = localStorage.getItem("role")
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [form] = Form.useForm()
  const [user_in_customer, setUser_in_customer] = useState(false)
  const [create_service, setCreate_service] = useState({
    ser_status_id: 1,
    ser_booking_date: null,
    ser_booking_time: null,
    ser_received_type_id: null,
    cus_user_id: null,
    ser_notification_email: null,
    ser_notification_line: null,
    ser_notification_phone: null,
    ser_issue_reported: null,
    cus_id: props.data_stock.cus_id,
    sto_id: props.data_stock.sto_id,
    ser_contact_name: null,
    ser_lat: null,
    ser_long: null,
    sla_service_id: null,
  })
  const [user_select, setUser_select] = useState(null)
  const [type_filter, setType_filter] = useState(null)
  const [type_received_all, setType_received_all] = useState([])
  const [fileUploadAll, setFileUploadAll] = useState([])
  const [location_select, setLocation_select] = useState({
    geo_id: null,
    pro_id: null,
    subdis_id: null,
    dis_id: null,
  })
  const { isAdminRoot , isRole } = useSelector(({ Distar }) => Distar)

  useEffect(() => {
    function check_type_received(type) {
      type_received_all.map(data => {
        if (data.ser_received_type_id === type)
          return setType_filter(data.ser_received_type_name)
      })
      form.resetFields([
        "ser_notification_email",
        "ser_notification_phone",
        "ser_notification_line",
      ])
    }

    if (create_service.ser_received_type_id) {
      check_type_received(create_service.ser_received_type_id)
      setCreate_service({
        ...create_service,
        ser_notification_email: null,
        ser_notification_line: null,
        ser_notification_phone: null,
      })
    }
    return () => {}
  }, [create_service.ser_received_type_id])

  useEffect(() => {
    check_received()
    return () => {
      setUser_select(null)
      setCreate_service({})
      setUser_in_customer(false)
      setLoading(false)
      setType_filter(null)
      setType_received_all([])
    }
  }, [])

  function check_received() {
    const check_ = api.LIST_RECEIVED_TYPE(token)
    axios(check_).then(res => {
      setType_received_all(res.data.data)
    })
  }

  useEffect(() => {
    if (props.data_stock) {
      check_user_in_customer()
      setCreate_service({
        ...create_service,
        cus_id: props.data_stock.cus_id,
        sto_id: props.data_stock.sto_id,
        ser_contact_name: props.data_stock.cus_name,
      })
      form.setFieldsValue({
        ser_contact_name: props.data_stock.cus_name,
      })
    }
    return () => {}
  }, [props.data_stock])

  function check_user_in_customer() {
    const check_user = api.CHECK_USER_IN_CUSTOMER(
      token,
      props.data_stock.cus_id
    )
    axios(check_user)
      .then(res => {
        if (res.data === "True") {
          setUser_in_customer(true)
        } else if (res.data === "False") {
          setUser_in_customer(false)
        }
      })
      .catch(error => {
        // console.log(error)
      })
  }

  const onFinish = values => {
    if (create_service.ser_issue_reported === null) {
      return Modal.error({
        title: t("Please input issue reported"),
        centered: true,
      })
    } else {
      // console.log(create_service);
      return confirm({
        title: t("Confirm create new service"),
        icon: <ExclamationCircleOutlined style={{ color: "#1890ff" }} />,
        centered: true,
        okText: t("create"),
        cancelText: t("no"),
        onOk() {
          setLoading(true)
          const data_new_service = {
            ...create_service,
            ...location_select,
          }
          const create_service_data = api.CREATE_NEW_SERVICE(
            token,
            data_new_service
          )
          axios(create_service_data)
            .then(res => {
              if (fileUploadAll.length === 0) {
                cancelForm()
                openNotificationWithIcon(
                  "success",
                  t("Create new service success")
                )
                setLoading(false)
                if (isRole !== "administrator") {
                  history.push({
                    pathname: `/service/${res.data.ser_id}`,
                    import_admin: true,
                  })
                } else {
                  history.push(`/service/${res.data.ser_id}`)
                }
              } else {
                loopUpload(res.data.ser_id)
              }
            })
            .catch(error => {
              setLoading(false)
              // console.log(error.response);
              if (error) {
                if (error.response) {
                  if (error.response.status === 401) {
                    helpers.re_login()
                    history.push("/login")
                  } else {
                    openNotificationWithIcon(
                      "error",
                      t("Create new service error")
                    )
                  }
                }
              }
            })
        },
        onCancel() {},
      })
    }
  }

  async function loopUpload(ser_id) {
    const promises = fileUploadAll.map(data => uploadFileService(data, ser_id))
    await Promise.all(promises)
    cancelForm()
    openNotificationWithIcon("success", t("Create new service success"))
    setLoading(false)
    if (isRole !== "administrator") {
      history.push({
        pathname: `/service/${ser_id}`,
        import_admin: true,
      })
    } else {
      history.push(`/service/${ser_id}`)
    }
  }

  async function uploadFileService(file, ser_id) {
    const formData = new FormData()
    formData.append("file", file)
    const uploadFileOne = api.UPLOAD_FILE_SERVICE(token, formData, ser_id)
    await axios(uploadFileOne)
      .then(res => {
        return res.data
      })
      .catch(error => {
        openNotificationWithIcon("error", t("upload file error"))
        return error
      })
  }

  const cancelForm = () => {
    form.resetFields()
    setUser_select(null)
    setCreate_service({
      ser_status_id: 1,
      ser_booking_date: null,
      ser_booking_time: null,
      cus_user_id: null,
      ser_issue_reported: null,
      cus_id: props.data_stock.cus_id,
      sto_id: props.data_stock.sto_id,
      ser_contact_name: null,
      ser_lat: null,
      ser_long: null,
      sla_service_id: null,

      ser_received_type_id: null,
      ser_notification_email: null,
      ser_notification_line: null,
      ser_notification_phone: null,
    })

    setLocation_select({
      geo_id: null,
      pro_id: null,
      subdis_id: null,
      dis_id: null,
    })
    setType_filter(null)
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    })
  }

  useEffect(() => {
    return () => {
      form.resetFields()
      setCreate_service({})
    }
  }, [])

  useEffect(() => {
    form.resetFields(["pro_id", "dis_id", "subdis_id", "service_address"])
    setLocation_select({
      ...location_select,
      pro_id: null,
      subdis_id: null,
      dis_id: null,
    })
    setCreate_service({
      ...create_service,
      ser_onsite_address: null,
    })
    return () => {}
  }, [location_select.geo_id])

  useEffect(() => {
    form.resetFields(["dis_id", "subdis_id", "service_address"])
    setLocation_select({
      ...location_select,
      subdis_id: null,
      dis_id: null,
    })
    setCreate_service({
      ...create_service,
      ser_onsite_address: null,
    })
    return () => {}
  }, [location_select.pro_id])

  useEffect(() => {
    form.resetFields(["subdis_id", "service_address"])
    setLocation_select({
      ...location_select,
      subdis_id: null,
    })
    setCreate_service({
      ...create_service,
      ser_onsite_address: null,
    })
    return () => {}
  }, [location_select.dis_id])

  function debug() {
    const data_new_service = {
      ...create_service,
      ...location_select,
    }
    console.log(data_new_service);
  }

  return (
    <div>
      {/* <button onClick={() => debug()} >
        debug
      </button> */}
      <Form
        form={form}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Contact name")}
              placeholder={t("Contact name")}
              headState={setCreate_service}
              nameState={create_service}
              type="text"
              keyState="ser_contact_name"
              required={true}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Contact phone")}
              placeholder={t("Contact phone")}
              headState={setCreate_service}
              nameState={create_service}
              type="number"
              keyState="ser_contact_phone"
              required={true}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Service type")}
              placeholder={t("Service type")}
              name_api="LIST_SERVICE_TYPE"
              map_name="ser_type_name"
              map_value="ser_type_id"
              keyState="ser_type_id"
              headState={setCreate_service}
              nameState={create_service}
              name_array_data="data"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Service priority")}
              placeholder={t("Service priority")}
              name_api="LIST_PRIORITY"
              map_name="pri_name"
              map_value="pri_id"
              keyState="pri_id"
              headState={setCreate_service}
              nameState={create_service}
              name_array_data="data"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Date_Comp
              header={t("Service appointment date")}
              placeholder={t("Service appointment date")}
              headState={setCreate_service}
              nameState={create_service}
              keyState="ser_booking_date"
              required={true}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Time
              header={t("Service appointment time")}
              placeholder={t("Service appointment time")}
              headState={setCreate_service}
              nameState={create_service}
              keyState="ser_booking_time"
              required={true}
            />
          </Col>

          {isAdminRoot === true && (
            <Col sm={12} md={6} lg={6} xl={6}>
              <Select_no_search
                header={t("Admin group")}
                placeholder={t("Admin group")}
                name_api="FIND_ALL_ADMIN_GROUP"
                map_name="admin_group_name"
                map_value="admin_group_id"
                keyState="admin_group_id"
                headState={setCreate_service}
                nameState={create_service}
                name_array_data="data"
              />
            </Col>
          )}

          <Col sm={12} md={6} lg={6} xl={6}>
            <UploadDocsFile setFileUploadAll={setFileUploadAll} />
          </Col>

          <Col sm={12} md={12} lg={12} xl={12}>
            <p className="text-bold">
              {t("Issue reported")} <span style={{ color: "red" }}>*</span>
            </p>
            <CKEditor
              editor={ClassicEditor}
              config={{
                removePlugins: [
                  "ImageUpload",
                  "EasyImage",
                  "MediaEmbed",
                  "Table",
                ],
              }}
              data={
                create_service.ser_issue_reported
                  ? create_service.ser_issue_reported
                  : ""
              }
              onChange={(event, editor) => {
                const data = editor.getData()
                // console.log( { event, editor, data } );
                if (data) {
                  setCreate_service({
                    ...create_service,
                    ser_issue_reported: data,
                  })
                } else {
                  setCreate_service({
                    ...create_service,
                    ser_issue_reported: null,
                  })
                }
              }}
            />
          </Col>
          <br />
          <Col sm={12} md={12} lg={12} xl={12}>
            <SelectSLA
              setCreate_service={setCreate_service}
              create_service={create_service}
            />
          </Col>

          {/* location */}

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search_Location
              header={t("Service zone")}
              placeholder={t("Service zone")}
              name_api="LIST_ALL_GEO_LOCATION"
              map_name="geo_name"
              map_value="geo_id"
              keyState="geo_id"
              data_response="message"
              headState={setLocation_select}
              nameState={location_select}
              disabled={false}
              require={true}
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_after_select_geo
              header={t("Service Province")}
              placeholder={t("Service Province")}
              name_api="LIST_LOCATION_PROVINCE_BY_GEOID"
              map_name="pro_name"
              map_value="pro_id"
              keyState="pro_id"
              data_response="message"
              headState={setLocation_select}
              nameState={location_select}
              data_select_before={location_select.geo_id}
              disabled={location_select.geo_id ? false : true}
              require={true}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_after_select_geo
              header={t("Service District")}
              placeholder={t("Service District")}
              name_api="LIST_LOCATION_DIS_BY_PROVINCE_ID"
              map_name="dis_name"
              map_value="dis_id"
              keyState="dis_id"
              data_response="message"
              headState={setLocation_select}
              nameState={location_select}
              data_select_before={location_select.pro_id}
              disabled={location_select.pro_id ? false : true}
              require={true}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_after_select_geo
              header={t("Service sub-district")}
              placeholder={t("Service sub-district")}
              name_api="LIST_LOCATION_SUB_DISTRICT"
              map_name="subdis_name"
              map_value="subdis_id"
              keyState="subdis_id"
              data_response="message"
              headState={setLocation_select}
              nameState={location_select}
              data_select_before={location_select.dis_id}
              disabled={location_select.dis_id ? false : true}
              require={true}
            />
          </Col>

          <Col sm={12} md={12} lg={12} xl={12}>
            <p className="text-bold">
              {t("On-Site address")} <span style={{ color: "red" }}>*</span>
            </p>
            <Form.Item
              name="service_address"
              rules={[
                {
                  required: true,
                  message: t("On-Site address"),
                },
              ]}
            >
              <TextArea
                placeholder={t("On-Site address")}
                disabled={location_select.subdis_id ? false : true}
                onChange={e =>
                  setCreate_service({
                    ...create_service,
                    ser_onsite_address: e.target.value,
                  })
                }
              />
            </Form.Item>
          </Col>

          <Col sm={12} md={12} lg={12} xl={12}>
            <p className="text-bold">{t("search location")}</p>
            <p> {t("search or click on map to select location")} </p>
            <Map_location
              setCreate_service={setCreate_service}
              create_service={create_service}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Show_Only
              placeholder={t("latitude")}
              description={null}
              header={t("latitude")}
              keyState="ser_lat"
              required={false}
              value={create_service.ser_lat}
            />
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Show_Only
              placeholder={t("longitude")}
              description={null}
              header={t("longitude")}
              keyState="ser_long"
              required={false}
              value={create_service.ser_long}
            />
          </Col>

          {/*
                  location_select.subdis_id === null && (
                    <Col sm={12} md={12} lg={12} xl={12}>
                    <p className="text-bold">
                    Address <span style={{ color:'red' }}>*</span>
                    </p>
                    <Form.Item
                      name="service_address"
                      rules={[
                        {
                          required: true,
                          message: "Please input address",
                        },
                      ]}
                    >
                      <TextArea
                      disabled={true}
                        placeholder="address"
                        onChange={e =>
                          setCreate_service({
                            ...create_service,
                            ser_onsite_address: e.target.value,
                          })
                        }
                      />
                    </Form.Item>
                  </Col>
                   )
                      */}
          <br />
          {/* location */}
          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_no_search
              header={t("Service received type")}
              placeholder={t("Service received type")}
              name_api="LIST_RECEIVED_TYPE"
              map_name="ser_received_type_name"
              map_value="ser_received_type_id"
              keyState="ser_received_type_id"
              headState={setCreate_service}
              nameState={create_service}
              name_array_data="data"
            />
          </Col>
          {user_in_customer === true &&
            create_service.ser_received_type_id !== null && (
              <List_User
                cus_id={props.data_stock.cus_id}
                setCreate_service={setCreate_service}
                create_service={create_service}
                setUser_select={setUser_select}
                user_select={user_select}
              />
            )}
          {user_in_customer === false && type_filter === "email" && (
            <Col sm={12} md={6} lg={6} xl={6}>
              <Input_Comp
                header={t("Email")}
                placeholder={t("Email")}
                headState={setCreate_service}
                nameState={create_service}
                type="text"
                keyState="ser_notification_email"
                required={true}
              />
            </Col>
          )}
          {user_in_customer === false && type_filter === "phone" && (
            <Col sm={12} md={6} lg={6} xl={6}>
              <Input_Comp
                header={t("phone")}
                placeholder={t("phone")}
                headState={setCreate_service}
                nameState={create_service}
                type="text"
                keyState="ser_notification_phone"
                required={true}
              />
            </Col>
          )}
          {user_in_customer === false && type_filter === "line" && (
            <Col sm={12} md={6} lg={6} xl={6}>
              <Input_Comp
                header={t("Line")}
                placeholder={t("Line")}
                headState={setCreate_service}
                nameState={create_service}
                type="text"
                keyState="ser_notification_line"
                required={true}
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Service remark")}
              placeholder={t("Service remark")}
              headState={setCreate_service}
              nameState={create_service}
              type="text"
              keyState="ser_remark"
              required={true}
            />
          </Col>
        </Row>

        <br />
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div className="div_create_new_car">
              <Button
                className="btn_cancel_create_new_car"
                onClick={() => cancelForm()}
              >
                {t("Cancel")}
              </Button>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div className="div_create_new_car">
              <Button
                className="btn_submit_create_new_car"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {t("Create")}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
