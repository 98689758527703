import React from "react"
import { Row, Col } from "reactstrap"
import { Card, Collapse } from "antd"
import last_service from "../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/last_service.png"
import { useTranslation } from "react-i18next"
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser"

const { Panel } = Collapse

export default function Service_Information(props) {
  const { t } = useTranslation()

  const text_header = {
    fontWeight: "bold",
    fontSize: "15px",
  }

  const icons_info_header = {
    width: "25px",
    marginRight: "20px",
  }

  function cutTimeZone(date_T) {
    if (date_T) {
      var date = new Date(date_T)
      var month = pad2(date.getMonth() + 1)
      var day = pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + "/" + month + "/" + year
      return formattedDate
    } else {
      return "N/A"
    }
  }
  function pad2(n) {
    return (n < 10 ? "0" : "") + n
  }

  function checkData(data) {
    if (data) {
      return data
    } else {
      return "N/A"
    }
  }

  const data_service = [
    {
      header1: t("Service contact name"),
      dataContract1: checkData(props.data_all.ser_contact_name),
      header2: t("Service contact phone"),
      dataContract2: checkData(props.data_all.ser_contact_phone),
    },
    {
      header1: t("Service appointment date"),
      dataContract1: cutTimeZone(props.data_all.ser_booking_date),
      header2: t("Service appointment time"),
      dataContract2: checkData(props.data_all.ser_booking_time),
    },
    {
      header1: t("Service zone"),
      dataContract1: checkData(props.data_all.geo_name),
      header2: t("Service province"),
      dataContract2: checkData(props.data_all.pro_name),
    },
    {
      header1: t("Service district"),
      dataContract1: checkData(props.data_all.dis_name),
      header2: t("Service sub-district"),
      dataContract2: checkData(props.data_all.subdis_name),
    },
    {
      header1: t("On-Site address"),
      dataContract1: checkData(props.data_all.ser_onsite_address),
      header2: t("Service type"),
      dataContract2: checkData(props.data_all.ser_type_name),
    },
    {
      header1: t("Service received"),
      dataContract1: checkData(props.data_all.ser_received_data),
      header2: t("Service remark"),
      dataContract2: checkData(props.data_all.ser_remark),
    },
  ]

  return (
    <div>
      <Card
        title={
          <p>
            {" "}
            <img
              src={last_service}
              alt="service_info"
              style={icons_info_header}
            />{" "}
            {t("Service information")}{" "}
          </p>
        }
      >
        <Row>
          <Col sm={6} md={3} lg={3} xl={3}>
            <p style={text_header}> {t("Service no.")} </p>
          </Col>
          <Col sm={6} md={3} lg={3} xl={3}>
            <p style={{ fontSize: "15px" }}>
              {" "}
              <span className="tet">
                :
              </span> <span className="tetTwo">-</span> {props.data_all.ser_no}
            </p>
          </Col>

          <Col sm={6} md={3} lg={3} xl={3}>
            <p style={text_header}> {t("Service status")} </p>
          </Col>
          <Col sm={6} md={3} lg={3} xl={3}>
            <p style={{ fontSize: "15px" }}>
              {" "}
              <span className="tet">
                :
              </span> <span className="tetTwo">-</span>{" "}
              {props.data_all.ser_status_name}
            </p>
          </Col>

          <Col sm={12} md={12} lg={12} xl={12}>
            <p className="text-bold"> {t("Service issue reported")} </p>
            {ReactHtmlParser(props.data_all.ser_issue_reported)}
          </Col>
        </Row>
        <Collapse>
          <Panel header={t("More")} key="1">
            <br />
            {data_service.map((data, index) => {
              return (
                <Row key={index}>
                  <Col sm={6} md={3} lg={3} xl={3}>
                    <p style={text_header}>{data.header1}</p>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={3}>
                    <p style={{ fontSize: "15px" }}>
                      {" "}
                      <span className="tet">:</span>{" "}
                      <span className="tetTwo">-</span> {data.dataContract1}
                    </p>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={3}>
                    <p style={text_header}>{data.header2}</p>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={3}>
                    <p style={{ fontSize: "15px" }}>
                      {" "}
                      <span className="tet">:</span>{" "}
                      <span className="tetTwo">-</span> {data.dataContract2}
                    </p>
                  </Col>
                </Row>
              )
            })}
          </Panel>
        </Collapse>
      </Card>
    </div>
  )
}
