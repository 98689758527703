import React, { useEffect, useState } from "react"
import { Row, Col } from "reactstrap"
import { Form, Button, Modal, notification } from "antd"
import Input_Comp from "./components/Input_Comp"
import Select_data from "./components/Select_data"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import * as api from "../../service/Api_Edit_Sub_Product_In_Car"
import Find_Model_InGroup from "./components/Find_Model_InGroup"
import axios from "axios"
import { useHistory } from "react-router-dom";
import helpers from '../Function_Redirect/Redirect_login'
import { useTranslation } from "react-i18next";

const { confirm } = Modal

export default function Create_New_Sub_Product(props) {
  const [form] = Form.useForm()
  const { t } = useTranslation();
  const token = localStorage.getItem("token")
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  useEffect(() => {
    if (
      props.create_new_sub_product_data.pro_serial_number === null &&
      props.create_new_sub_product_data.pro_sub_serial_number === null &&
      props.create_new_sub_product_data.pro_model_id === null &&
      props.create_new_sub_product_data.pro_remark === null &&
      props.create_new_sub_product_data.warranty_id === null && props.group_id === null
    ) {
      onReset()
    }
    return () => {}
  }, [props.create_new_sub_product_data])

  useEffect(() => {
    form.resetFields(["product_model"])
    props.setCreate_new_sub_product_data({
      ...props.create_new_sub_product_data,
      pro_model_id: null,
    })
    return () => {}
  }, [props.group_id])

  const onFinish = values => {
    return confirm({
      title: t("Confirm create new sub product"),
      icon: <ExclamationCircleOutlined />,
      okText: t("Confirm"),
      cancelText: t("no"),
      centered: true,
      onOk() {
        setLoading(true)
        const create_new_sub_product = api.CREATE_NEW_SUB_PRODUCT(
          token,
          props.product_id,
          props.create_new_sub_product_data
        )
        axios(create_new_sub_product)
          .then(res => {
            openNotificationWithIcon('success', t("Create new sub product success"))
            setLoading(false)
            props.setGroup_id(null)
            props.list_sub_product_data()
            props.setVisible_create(false)
          })
          .catch(error => {
            setLoading(false)
            if(error.response.status === 401){
              helpers.re_login()
              history.push('/login')          
            } else {
              openNotificationWithIcon('error', t("Create new sub product error"))
            }

          })
      },
      onCancel() {},
    })
  }

  const onReset = () => {
    form.resetFields()
  }

  function cancelForm() {
    onReset()
    props.setCreate_new_sub_product_data({})
    props.setVisible_create(false)
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    });
  };

  return (
    <div>
      <Form form={form} name="create-new-sub-product" onFinish={onFinish}>
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Product serial number")}
              placeholder="product serial number"
              headState={props.setCreate_new_sub_product_data}
              nameState={props.create_new_sub_product_data}
              type="text"
              keyState="pro_serial_number"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Product sub serial number")}
              placeholder="product sub serial number"
              headState={props.setCreate_new_sub_product_data}
              nameState={props.create_new_sub_product_data}
              type="text"
              keyState="pro_sub_serial_number"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_data
              data_map={props.list_product_model_all}
              header={t("Product group")}
              placeholder="product group"
              keyState="group_id"
              headState={props.setGroup_id}
              nameState={props.group_id}
              map_value="pro_group_id"
              map_name="pro_group_name"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Find_Model_InGroup
              setGroup_id={props.setGroup_id}
              group_id={props.group_id}
              setCreate_new_sub_product_data={
                props.setCreate_new_sub_product_data
              }
              create_new_sub_product_data={props.create_new_sub_product_data}
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Select_data
              data_map={props.list_warranty_all}
              header={t("Warranty")}
              placeholder={t("Warranty")}
              keyState="warranty_id"
              headState={props.setCreate_new_sub_product_data}
              nameState={props.create_new_sub_product_data}
              map_value="warranty_id"
              map_name="warranty_name"
            />
          </Col>

          <Col sm={12} md={6} lg={6} xl={6}>
            <Input_Comp
              header={t("Product remark")}
              placeholder={t("Product remark")}
              headState={props.setCreate_new_sub_product_data}
              nameState={props.create_new_sub_product_data}
              type="text"
              keyState="pro_remark"
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div className="div_create_new_car">
              <Button
                className="btn_cancel_create_new_car"
                onClick={() => cancelForm()}
              >
              
               {t("Cancel")}
              </Button>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div className="div_create_new_car">
              <Button
                className="btn_submit_create_new_car"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {t("Create")}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
