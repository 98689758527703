import React from "react"
import { Input } from "antd"

export default function Input_Pass({
  header,
  placeholder,
  keyState,
  index_user,
  user,
  setUser,
}) {
  function handle_field_password(e) {
    const values = [...user]
    values[index_user][keyState] = e.target.value
    setUser(values)
  }

  return (
    <div>
      <p className="text-bold" style={{ marginTop: "20px" }}>
        {header} <span style={{ color: "red" }}>*</span>
      </p>
      <Input.Password
        type="password"
        placeholder={placeholder}
        onChange={e => handle_field_password(e)}
        value={user[index_user][keyState]}
      />
    </div>
  )
}
