import React, { useState, useEffect } from "react"
import axios from "axios"
import { Spin } from "antd"
import * as api from "../../../../service/Api_New_Service_Has_Stock"
import Map_Car from "./components/Map_Car"

export default function Show_Gps_Car(props) {
  const token = localStorage.getItem("token")
  const [car_infomation, setCar_infomation] = useState(null)
  const [gps_car, setGps_car] = useState(null)
  const [loading, setLoading] = useState(false)
  const [location_car, setLocation_car] = useState({
    lat: 13.2753119,
    lng: 101.4843104,
  })

  useEffect(() => {
    check_location_car()
    return () => {}
  }, [props.data_location])

  function check_location_car() {
    setLoading(true)
    const location = api.FIND_DEVICE_GPS_LOCATION_CAR(
      token,
      props.data_location.car_code
    )
    axios(location)
      .then(res => {
        setLoading(false)
        if (res.data) {
          if (!res.data.data.device_details) {
          } else {
            setCar_infomation(res.data.data.device_details.car)
            setGps_car(res.data.data.device_details.gps)
            setLocation_car({
              ...location_car,
              lat: parseFloat(res.data.data.device_details.gps.lat),
              lng: parseFloat(res.data.data.device_details.gps.lng),
            })
          }
        }
      })
      .catch(error => {
        setLoading(false)
      })
  }

  return (
    <div>
      {gps_car !== null && (
        <Map_Car
          type_car={props.data_location.car_body_type_name}
          car_infomation={car_infomation}
          location_car={location_car}
        />
      )}
      {loading === true && (
        <Spin
          size="large"
          style={{ display: "block", margin: "20px auto 20px auto" }}
        />
      )}
    </div>
  )
}
