import React,{ useEffect, useState } from "react"
import { Progress } from "antd"
import { useTranslation } from "react-i18next";
import service_status from '../../Select_Car_From_DB_Distar/Overview_Confirm_Create/image_type_car/service_status.png'

export default function Service_Status(props) {
  const { t } = useTranslation();
  const [assigned, setAssigned] = useState(0)
  const [cancle, setCancle] = useState(0)
  const [complete, setComplete] = useState(0)
  const [inprogress, setInprogress] = useState(0)
  const [overdue, setOverdue] = useState(0)
  const [pending_for_approval_admin, setPending_for_approval_admin] = useState(0)
  const [pending_for_approval_manager, setPending_for_approval_manager] = useState(0)
  const [resolved, setResolved] = useState(0)
  const [unassigned, setUnassigned] = useState(0)
  const [count_all_status, setCount_all_status] = useState(0)
  const [pending, setPending] = useState(0)
  const [reject, setReject] = useState(0)

  const Header_text = {
    fontSize: "20px",
    marginBottom: "0px",
    color: "#A5A5A5",
  }

  useEffect(() => {
    setReject(props.service_status.reject ? props.service_status.reject : 0);
    setPending(props.service_status.pending ? props.service_status.pending : 0);
    setAssigned(props.service_status.assigned)
    setCancle(props.service_status.cancel)
    setComplete(props.service_status.complete)
    setInprogress(props.service_status.inprogress)
    setOverdue(props.service_status.overdue)
    setPending_for_approval_admin(props.service_status.pending_for_approval_admin ? props.service_status.pending_for_approval_admin : 0)
    setPending_for_approval_manager(props.service_status.pending_for_approval_manager ? props.service_status.pending_for_approval_manager : 0)
    setResolved(props.service_status.resolved)
    setUnassigned(props.service_status.unassigned)

    setCount_all_status(
      props.service_status.assigned+props.service_status.cancel+props.service_status.complete+props.service_status.inprogress+props.service_status.overdue+
      props.service_status.pending_for_approval_admin+props.service_status.pending_for_approval_manager+props.service_status.resolved+props.service_status.unassigned
    )

    return () => {
      setAssigned(0)
      setCancle(0)
      setComplete(0)
      setInprogress(0)
      setOverdue(0)
      setReject(0)
      setPending_for_approval_admin(0)
      setPending_for_approval_manager(0)
      setResolved(0)
      setPending(0)
      setUnassigned(0)
      setCount_all_status(0)
    }
  }, [props.service_status])

  function render_percent(all_status, status_list) {
    let number = 0
    number = (status_list / all_status) * 100
    return number
  }

  // console.log(count_all_status);

  return (
  <div>
    <p style={{ fontSize: "20px", marginBottom: "15px", color: "#495057" }}>
    <img src={service_status} alt="contract" style={{ width:'25px', height:'25px', marginRight:'20px', marginTop:'-10px' }} />
     {t("service status")}
    </p>

    <p style={Header_text}> {t("assigned")} <span style={{ float: "right", color: "#495057", fontWeight: "bold" }} > {assigned} </span>{" "} </p>
        <Progress
          percent={render_percent(
            count_all_status,
            assigned,
          )}
          showInfo={false}
          status="active"
          strokeColor="#76CE86"
        />

  <p style={Header_text}> {t("cancel")} <span style={{ float: "right", color: "#495057", fontWeight: "bold" }} > {cancle +reject} </span>{" "} </p>
        <Progress
          percent={render_percent(
            count_all_status,
            cancle +reject ,
          )}
          showInfo={false}
          status="active"
          strokeColor="#ff6961"
        />

        <p style={Header_text}> {t("complete")}  <span style={{ float: "right", color: "#495057", fontWeight: "bold" }} > {complete} </span>{" "} </p>
        <Progress
          percent={render_percent(
            count_all_status,
            complete,
          )}
          showInfo={false}
          status="active"
          strokeColor="#EA9FE6"
        />
        
        <p style={Header_text}> {t("inprogress")} <span style={{ float: "right", color: "#495057", fontWeight: "bold" }} > {inprogress} </span>{" "} </p>
        <Progress
          percent={render_percent(
            count_all_status,
            inprogress,
          )}
          showInfo={false}
          status="active"
          strokeColor="#7985E0"
        />

        <p style={Header_text}> {t("overdue")} <span style={{ float: "right", color: "#495057", fontWeight: "bold" }} > {overdue} </span>{" "} </p>
        <Progress
          percent={render_percent(
            count_all_status,
            overdue,
          )}
          showInfo={false}
          status="active"
          strokeColor="#6891C3"
        />

        <p style={Header_text}> {t("pending")} <span style={{ float: "right", color: "#495057", fontWeight: "bold" }} > {pending_for_approval_admin + pending + pending_for_approval_manager} </span>{" "} </p>
        <Progress
          percent={render_percent(
            count_all_status,
            pending_for_approval_admin + pending + pending_for_approval_manager,
          )}
          showInfo={false}
          status="active"
          strokeColor="#F29496"
        />
        
        <p style={Header_text}> {t("resolved")} <span style={{ float: "right", color: "#495057", fontWeight: "bold" }} > {resolved} </span>{" "} </p>
        <Progress
          percent={render_percent(
            count_all_status,
            resolved,
          )}
          showInfo={false}
          status="active"
          strokeColor="#EA9FE6"
        />

        <p style={Header_text}> {t("unassigned")}  <span style={{ float: "right", color: "#495057", fontWeight: "bold" }} > {unassigned} </span>{" "} </p>
        <Progress
          percent={render_percent(
            count_all_status,
            unassigned,
          )}
          showInfo={false}
          status="active"
          strokeColor="#495057"
        />
  </div>
  )
}
